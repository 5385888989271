import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { ReactChild } from "react";

interface ThemeProps {
  children: ReactChild | ReactChild[];
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0677F9",
    },
    secondary: {
      main: "#4A5A6A",
    },
    background: {
      default: "#121418",
    },
  },
});

const Theme = ({ children }: ThemeProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
