import React, { useEffect, useRef } from "react";
import {
  Select as MaterialSelect,
  MenuItem,
  FormControl,
  InputLabel,
  withStyles,
  makeStyles,
} from "@material-ui/core";
import { v4 as uuid } from "uuid";

import "./index.css";

interface SelectProps {
  label: string;
  value?: string | number;
  options: (string | number | React.ReactChild)[];
  onChange?: (event: any) => void;
}

const StyledSelect = withStyles(() => ({
  root: {
    backgroundColor: "#080A0B",
    borderRadius: "1.2rem 1.2rem",
  },
}))(MaterialSelect);

const StyledLabel = withStyles(() => ({
  root: {
    fontSize: "1.5rem",
    zIndex: 2,
  },
  underline: {
    opacity: "0 !important",
  },
}))(InputLabel);

const useStyles = makeStyles(() => ({
  label: {
    color: "#fff",
    opacity: 0.64,
    fontFamily: "Be Vietnam",
    fontSize: "1.5rem",
    marginLeft: "0.75rem",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
}));

const Select = ({ label, value, options, onChange }: SelectProps) => {
  const inputID = useRef("");
  const classes = useStyles();

  useEffect(() => {
    inputID.current = uuid();
  }, []);

  return (
    <FormControl
      style={{
        width: "100%",
        borderRadius: "1.2rem !important",
        borderBottom: "none !important",
      }}
      variant="filled"
    >
      <StyledLabel
        id={`${inputID.current}--label`}
        style={{ color: "#fff" }}
        className={classes.label}
      >
        {label}
      </StyledLabel>
      <StyledSelect
        label={label}
        placeholder={label}
        labelId={`${inputID.current}--label`}
        id={inputID.current}
        variant="filled"
        color="primary"
        style={{
          width: "100%",
          color: "#fff",
          fontFamily: "Be Vietnam",
          fontSize: "1.4rem",
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          getContentAnchorEl: null,
        }}
        onChange={onChange}
        value={value}
      >
        {options.map((option, i) => {
          if (typeof option === "string") {
            return (
              <MenuItem
                value={option}
                style={{
                  backgroundColor: "#010203",
                  color: "#fff",
                  fontFamily: "Be Vietnam",
                  fontSize: "1.5rem",
                  fontWeight: 600,
                }}
                key={`Option__${i}`}
              >
                {option}
              </MenuItem>
            );
          } else return <div key={uuid()}>{option}</div>;
        })}
      </StyledSelect>
    </FormControl>
  );
};

export default Select;
