import { CircularProgress, withStyles } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

interface SecondaryButtonProps {
  label: string;
  loading?: boolean;
  onClick?: () => void;
}

const StyledButton = styled.button`
  background-color: transparent;
  border: 2px solid #0677f9;

  padding: 1.3rem 0;
  outline: none;
  cursor: pointer;
  border-radius: 9px;

  width: 100%;

  font-family: "Be Vietnam";
  font-size: 2rem;
  color: #0677f9;
  font-weight: 600;

  transition: all 0.2s;

  &:hover {
  }

  &:active {
    opacity: 0.8;
  }
`;

const StyledProgress = withStyles({
  root: {
    color: "#fff",
  },
})(CircularProgress);

const SecondaryButton = ({ label, onClick, loading }: SecondaryButtonProps) => {
  if (loading) {
    return (
      <StyledButton onClick={onClick}>
        <StyledProgress color="secondary" />
      </StyledButton>
    );
  }
  return <StyledButton onClick={onClick}>{label}</StyledButton>;
};

export default SecondaryButton;
