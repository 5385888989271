export const statePolygons = {
  Maharashtra: [
    [73.7422943, 15.7678052],
    [74.401474, 15.8946405],
    [74.9727631, 16.822288],
    [76.2032318, 17.1585055],
    [77.2579193, 18.3721209],
    [77.6534271, 18.28869],
    [78.3565521, 19.6600472],
    [79.4551849, 19.3286388],
    [80.3780365, 19.9081555],
    [80.6417084, 20.8145319],
    [80.1143646, 21.5520913],
    [79.0596771, 21.6746578],
    [78.5323334, 21.429421],
    [77.5215912, 21.429421],
    [77.213974, 21.7563111],
    [76.3790131, 21.1427901],
    [74.2236328, 21.819911],
    [74.1817474, 21.4703226],
    [73.698349, 21.1837715],
    [73.9620209, 20.5267179],
    [73.4786224, 20.732355],
    [73.3907318, 20.3620089],
    [73.1270599, 20.1146158],
    [72.9073334, 20.1558753],
    [72.7315521, 19.9907721],
    [73.3652401, 16.2930371],
    [73.7422943, 15.7678052],
  ],
  Karnataka: [
    [
      [
        [74.67097473144554, 13.199861526489315],
        [74.67097473144554, 13.19958400726324],
        [74.67152404785162, 13.19958400726324],
        [74.67152404785162, 13.19930553436285],
        [74.6720809936524, 13.199310302734318],
        [74.6720809936524, 13.199029922485352],
        [74.67263793945312, 13.199029922485352],
        [74.67263793945312, 13.198748588562069],
        [74.67292022705107, 13.198748588562069],
        [74.67292022705107, 13.197921752929744],
        [74.67263793945312, 13.197921752929744],
        [74.67263793945312, 13.196809768676815],
        [74.67318725585955, 13.196809768676815],
        [74.67318725585955, 13.19736099243164],
        [74.67375183105486, 13.19736099243164],
        [74.67375183105486, 13.197921752929744],
        [74.67402648925804, 13.197921752929744],
        [74.67402648925804, 13.197639465332031],
        [74.67430877685564, 13.197639465332031],
        [74.67430877685564, 13.197080612182674],
        [74.67458343505865, 13.197083473205566],
        [74.67458343505865, 13.196526527404785],
        [74.67485809326183, 13.196530342102164],
        [74.67485809326183, 13.19597053527832],
        [74.67514038085943, 13.195973396301383],
        [74.67514038085943, 13.195694923400993],
        [74.67542266845714, 13.195691108703613],
        [74.67541503906278, 13.195417404174918],
        [74.67569732666016, 13.19542026519781],
        [74.67569732666016, 13.19514179229742],
        [74.67597198486357, 13.19514179229742],
        [74.67597198486357, 13.194861412048454],
        [74.67624664306658, 13.194861412048454],
        [74.67624664306658, 13.1937513351441],
        [74.67652893066435, 13.1937513351441],
        [74.67652893066435, 13.192921638488883],
        [74.67624664306658, 13.192921638488883],
        [74.67624664306658, 13.192081451416072],
        [74.67597198486357, 13.192083358764648],
        [74.67597198486357, 13.191527366638184],
        [74.67569732666016, 13.191527366638184],
        [74.67569732666016, 13.192083358764648],
        [74.67542266845714, 13.192081451416072],
        [74.67542266845714, 13.192641258239746],
        [74.67514038085943, 13.192641258239746],
        [74.67514038085943, 13.192921638488883],
        [74.67485809326183, 13.192916870117244],
        [74.67485809326183, 13.193470954895133],
        [74.67457580566412, 13.193470954895133],
        [74.67457580566412, 13.194031715393066],
        [74.67430877685564, 13.194031715393066],
        [74.67430877685564, 13.194310188293457],
        [74.67402648925804, 13.194305419921989],
        [74.67402648925804, 13.194861412048454],
        [74.67375183105486, 13.194861412048454],
        [74.67375183105486, 13.19542026519781],
        [74.67346954345709, 13.195417404174918],
        [74.67346954345709, 13.195693016052246],
        [74.6720809936524, 13.195693016052246],
        [74.6720809936524, 13.196249008178711],
        [74.67180633544933, 13.196240425109863],
        [74.67180633544933, 13.196530342102164],
        [74.67152404785162, 13.196526527404785],
        [74.67152404785162, 13.197083473205566],
        [74.67124938964838, 13.197080612182674],
        [74.67124938964838, 13.19736099243164],
        [74.67096710205101, 13.19736099243164],
        [74.67096710205101, 13.197639465332031],
        [74.67069244384783, 13.197639465332031],
        [74.67069244384783, 13.198196411132812],
        [74.67041778564459, 13.198196411132812],
        [74.67041778564459, 13.198471069335994],
        [74.67014312744158, 13.198471069335994],
        [74.67014312744158, 13.199029922485352],
        [74.66986083984403, 13.19902706146246],
        [74.66986083984403, 13.19958400726324],
        [74.67013549804705, 13.19958400726324],
        [74.67013549804705, 13.199861526489315],
        [74.67097473144554, 13.199861526489315],
      ],
    ],
    [
      [
        [74.72846984863276, 13.28292179107666],
        [74.72846984863276, 13.282640457153377],
        [74.72875213623053, 13.282640457153377],
        [74.72875213623053, 13.282080650329533],
        [74.72846984863276, 13.282084465026912],
        [74.72846984863276, 13.281805992126522],
        [74.72820281982428, 13.28180980682373],
        [74.72820281982428, 13.281530380249023],
        [74.72763824462896, 13.281530380249023],
        [74.72763824462896, 13.281251907348633],
        [74.72735595703142, 13.281251907348633],
        [74.72735595703142, 13.2806911468507],
        [74.72763824462896, 13.280693054199276],
        [74.72763824462896, 13.280139923095703],
        [74.7279129028322, 13.280141830444279],
        [74.7279129028322, 13.279581069946346],
        [74.72819519042991, 13.279583930969238],
        [74.72819519042991, 13.279026985168457],
        [74.7279129028322, 13.279031753540096],
        [74.7279129028322, 13.278470993041992],
        [74.72735595703142, 13.278470993041992],
        [74.72735595703142, 13.278749465942383],
        [74.726806640625, 13.278749465942383],
        [74.726806640625, 13.279031753540096],
        [74.7265167236331, 13.279031753540096],
        [74.7265167236331, 13.279310226440487],
        [74.72597503662121, 13.279305458068848],
        [74.72597503662121, 13.279583930969238],
        [74.7256927490235, 13.279583930969238],
        [74.7256927490235, 13.279862403869629],
        [74.72513580322288, 13.279862403869629],
        [74.72513580322288, 13.280141830444279],
        [74.72541809082026, 13.280141830444279],
        [74.72541809082026, 13.28042030334467],
        [74.7256927490235, 13.280414581298885],
        [74.7256927490235, 13.280693054199276],
        [74.72597503662121, 13.280693054199276],
        [74.72597503662121, 13.280971527099666],
        [74.72624969482428, 13.280971527099666],
        [74.72624969482428, 13.281251907348633],
        [74.7265167236331, 13.281251907348633],
        [74.7265167236331, 13.281530380249023],
        [74.726806640625, 13.281528472900447],
        [74.726806640625, 13.281971931457633],
        [74.726806640625, 13.282084465026912],
        [74.72708129882841, 13.282080650329533],
        [74.72708129882841, 13.282361984252987],
        [74.72735595703142, 13.282361984252987],
        [74.72738647460938, 13.282640457153377],
        [74.7279129028322, 13.282640457153377],
        [74.7279129028322, 13.28292179107666],
        [74.72846984863276, 13.28292179107666],
      ],
    ],
    [
      [
        [74.70041656494152, 13.284310340881404],
        [74.70041656494152, 13.28345966339117],
        [74.70069122314459, 13.28347206115717],
        [74.70069122314459, 13.282917976379451],
        [74.70097351074247, 13.282917976379451],
        [74.70097351074247, 13.283193588256779],
        [74.70124816894531, 13.283193588256779],
        [74.70124816894531, 13.279305458068848],
        [74.70096588134794, 13.279310226440487],
        [74.70096588134794, 13.278749465942383],
        [74.69930267334001, 13.278749465942383],
        [74.69930267334001, 13.279031753540096],
        [74.69902801513678, 13.279026985168457],
        [74.69902801513678, 13.279583930969238],
        [74.69875335693376, 13.279581069946346],
        [74.69875335693376, 13.281530380249023],
        [74.69958496093773, 13.281528472900447],
        [74.69958496093773, 13.282084465026912],
        [74.69930267334001, 13.282084465026912],
        [74.69930267334001, 13.283193588256779],
        [74.6995773315432, 13.283190727233887],
        [74.6995773315432, 13.284029960632438],
        [74.69986724853533, 13.284029960632438],
        [74.69986724853533, 13.284310340881404],
        [74.70041656494152, 13.284310340881404],
      ],
    ],
    [
      [
        [74.72374725341797, 13.289590835571232],
        [74.72374725341797, 13.289311408996582],
        [74.72429656982439, 13.289311408996582],
        [74.72429656982439, 13.289031028747615],
        [74.7248611450197, 13.289031028747615],
        [74.7248611450197, 13.288749694824332],
        [74.72541809082026, 13.288749694824332],
        [74.72541809082026, 13.288470268249455],
        [74.7256927490235, 13.288472175598258],
        [74.7256927490235, 13.288193702697868],
        [74.72624969482428, 13.288193702697868],
        [74.72624969482428, 13.287916183471793],
        [74.726806640625, 13.287920951843262],
        [74.726806640625, 13.287639617919979],
        [74.72735595703142, 13.287639617919979],
        [74.72735595703142, 13.287360191345329],
        [74.72763824462896, 13.287362098693904],
        [74.72763824462896, 13.287083625793514],
        [74.72804260253935, 13.287083625793514],
        [74.72846984863276, 13.287083625793514],
        [74.72846984863276, 13.286527633667049],
        [74.72820281982428, 13.286529541015625],
        [74.72820281982428, 13.285969734191951],
        [74.7279357910158, 13.285971641540584],
        [74.72763824462896, 13.285971641540584],
        [74.72763824462896, 13.285694122314453],
        [74.72652435302763, 13.285694122314453],
        [74.72652435302763, 13.28541564941412],
        [74.72624969482428, 13.285420417785758],
        [74.72624969482428, 13.285140037536621],
        [74.72541809082026, 13.285140037536621],
        [74.72541809082026, 13.285420417785758],
        [74.7248611450197, 13.285420417785758],
        [74.7248611450197, 13.285140037536621],
        [74.72458648681646, 13.285140037536621],
        [74.72458648681646, 13.285420417785758],
        [74.72430419921892, 13.28541564941412],
        [74.72430419921892, 13.285971641540584],
        [74.72403717041044, 13.285969734191951],
        [74.72403717041044, 13.286250114440975],
        [74.72374725341797, 13.286250114440975],
        [74.72374725341797, 13.286529541015625],
        [74.72347259521513, 13.286529541015625],
        [74.72347259521513, 13.286820411682186],
        [74.72319793701178, 13.28680610656744],
        [74.72319793701178, 13.287083625793514],
        [74.72291564941418, 13.287083625793514],
        [74.72291564941418, 13.287362098693904],
        [74.72270202636747, 13.287360191345329],
        [74.722640991211, 13.287639617919979],
        [74.72235870361322, 13.287639617919979],
        [74.72235870361322, 13.289311408996582],
        [74.72265625000023, 13.289311408996582],
        [74.72319793701178, 13.289311408996582],
        [74.72319793701178, 13.289590835571232],
        [74.72374725341797, 13.289590835571232],
      ],
    ],
    [
      [
        [74.69069671630854, 13.334030151367188],
        [74.69069671630854, 13.333751678466797],
        [74.69097137451178, 13.333751678466797],
        [74.69097137451178, 13.333190917968864],
        [74.69125366210949, 13.333195686340332],
        [74.69125366210949, 13.332915306091365],
        [74.6915283203125, 13.332921981811637],
        [74.6915283203125, 13.3323593139649],
        [74.69180297851591, 13.332361221313477],
        [74.69180297851591, 13.332082748413086],
        [74.69208526611328, 13.332082748413086],
        [74.69208526611328, 13.331641197204704],
        [74.69208526611328, 13.331526756286621],
        [74.69235992431669, 13.331529617309684],
        [74.69235992431669, 13.330691337585506],
        [74.68985748291038, 13.330693244934082],
        [74.68985748291038, 13.332500457763786],
        [74.68985748291038, 13.333751678466797],
        [74.6895828247072, 13.333751678466797],
        [74.6895828247072, 13.334030151367188],
        [74.69069671630854, 13.334030151367188],
      ],
    ],
    [
      [
        [74.68902587890642, 13.340971946716422],
        [74.68902587890642, 13.339305877685604],
        [74.68930816650419, 13.339310646057243],
        [74.68930816650419, 13.338751792907715],
        [74.6895828247072, 13.338751792907715],
        [74.6895828247072, 13.338191032409782],
        [74.68985748291038, 13.338193893432617],
        [74.68985748291038, 13.337200164794979],
        [74.68985748291038, 13.335417747497615],
        [74.68902587890642, 13.335419654846248],
        [74.68902587890642, 13.335140228271541],
        [74.68866729736328, 13.335140228271541],
        [74.68763732910168, 13.335140228271541],
        [74.68763732910168, 13.33569431304943],
        [74.68819427490263, 13.33569431304943],
        [74.68819427490263, 13.335971832275334],
        [74.68846893310547, 13.335971832275334],
        [74.68846893310547, 13.336810111999569],
        [74.68791961669928, 13.33680629730219],
        [74.68791961669928, 13.337083816528263],
        [74.6873626708985, 13.337083816528263],
        [74.6873626708985, 13.33680629730219],
        [74.68569183349638, 13.33680629730219],
        [74.68569183349638, 13.337083816528263],
        [74.68597412109392, 13.337083816528263],
        [74.68597412109392, 13.337362289428654],
        [74.6865310668947, 13.337360382080078],
        [74.6865310668947, 13.337639808654785],
        [74.68681335449224, 13.337639808654785],
        [74.68681335449224, 13.339030265808105],
        [74.68708038330072, 13.339030265808105],
        [74.68708038330072, 13.340422630310172],
        [74.6873626708985, 13.340415954589957],
        [74.6873626708985, 13.340694427490348],
        [74.68763732910168, 13.340694427490348],
        [74.68763732910168, 13.340971946716422],
        [74.68902587890642, 13.340971946716422],
      ],
    ],
    [
      [
        [74.68264007568388, 13.348472595214787],
        [74.68264007568388, 13.348195075988883],
        [74.68291473388689, 13.348195075988883],
        [74.68291473388689, 13.347639083862418],
        [74.68319702148443, 13.347640991210994],
        [74.68319702148443, 13.34708023071289],
        [74.68347167968767, 13.347082138061467],
        [74.68347167968767, 13.346529006958065],
        [74.68374633789085, 13.34653091430664],
        [74.68374633789085, 13.345890045165959],
        [74.68374633789085, 13.345693588256893],
        [74.68402862548822, 13.345693588256893],
        [74.68402862548822, 13.345137596130428],
        [74.68486022949224, 13.345141410827637],
        [74.68486022949224, 13.344860076904354],
        [74.68514251709013, 13.344860076904354],
        [74.68514251709013, 13.344579696655387],
        [74.68541717529297, 13.344584465026855],
        [74.68541717529297, 13.34402847290039],
        [74.68569183349638, 13.344031333923454],
        [74.68569183349638, 13.343741416931152],
        [74.68597412109392, 13.34375],
        [74.68597412109392, 13.34347152709961],
        [74.68624877929693, 13.34347152709961],
        [74.68624877929693, 13.343194007873535],
        [74.6865310668947, 13.343194007873535],
        [74.6865310668947, 13.342915534973145],
        [74.68681335449224, 13.3429212570191],
        [74.68681335449224, 13.34235954284668],
        [74.68708038330072, 13.342362403869743],
        [74.68708038330072, 13.342082023620605],
        [74.6873626708985, 13.342082023620605],
        [74.6873626708985, 13.341527938842887],
        [74.68708038330072, 13.341532707214355],
        [74.68708038330072, 13.341250419616813],
        [74.6865310668947, 13.341250419616813],
        [74.6865310668947, 13.340971946716422],
        [74.68569183349638, 13.340971946716422],
        [74.68569183349638, 13.340694427490348],
        [74.68486022949224, 13.340680122375545],
        [74.68486022949224, 13.341532707214355],
        [74.68514251709013, 13.341527938842887],
        [74.68514251709013, 13.342362403869743],
        [74.68374633789085, 13.34235954284668],
        [74.68374633789085, 13.342639923095817],
        [74.68347167968767, 13.342639923095817],
        [74.68347167968767, 13.3429212570191],
        [74.68282318115263, 13.342915534973145],
        [74.68152618408214, 13.342915534973145],
        [74.68152618408214, 13.343194007873535],
        [74.68180084228516, 13.343190193176326],
        [74.68180084228516, 13.344031333923454],
        [74.68152618408214, 13.344031333923454],
        [74.68152618408214, 13.344310760498104],
        [74.68125152587896, 13.344305992126465],
        [74.68125152587896, 13.344584465026855],
        [74.6809692382812, 13.344579696655387],
        [74.6809692382812, 13.344860076904354],
        [74.68235778808594, 13.344860076904354],
        [74.68235778808594, 13.345141410827637],
        [74.6820831298831, 13.345137596130428],
        [74.6820831298831, 13.345693588256893],
        [74.68180084228516, 13.34568977355957],
        [74.68180847167974, 13.34735965728754],
        [74.68152618408214, 13.34735965728754],
        [74.68152618408214, 13.348472595214787],
        [74.68264007568388, 13.348472595214787],
      ],
    ],
    [
      [
        [74.67152404785162, 13.383193016052246],
        [74.67152404785162, 13.382917404174918],
        [74.67180633544933, 13.382917404174918],
        [74.67180633544933, 13.382638931274528],
        [74.6720809936524, 13.382640838623104],
        [74.6720809936524, 13.382361412048454],
        [74.67236328125028, 13.382361412048454],
        [74.67236328125028, 13.382082939148063],
        [74.67272186279325, 13.382082939148063],
        [74.67346954345709, 13.382082939148063],
        [74.67346954345709, 13.381805419921989],
        [74.67363739013695, 13.381805419921989],
        [74.67375183105486, 13.381805419921989],
        [74.67375183105486, 13.381526947021598],
        [74.67391204833979, 13.381528854370174],
        [74.67402648925804, 13.381531715393066],
        [74.67402648925804, 13.381269454956055],
        [74.67430877685564, 13.3812513351441],
        [74.67430877685564, 13.380970954895133],
        [74.67458343505865, 13.380970954895133],
        [74.67458343505865, 13.380695343017635],
        [74.67485809326183, 13.380695343017635],
        [74.67485809326183, 13.379591941833496],
        [74.67485809326183, 13.37791728973383],
        [74.67457580566412, 13.377919197082633],
        [74.67457580566412, 13.377361297607365],
        [74.67485809326183, 13.377361297607365],
        [74.67485809326183, 13.376528739929313],
        [74.67514038085943, 13.376530647277889],
        [74.67514038085943, 13.375690460205078],
        [74.67346954345709, 13.375690460205078],
        [74.67346954345709, 13.375972747802791],
        [74.67318725585955, 13.375972747802791],
        [74.67318725585955, 13.376530647277889],
        [74.67292022705107, 13.376530647277889],
        [74.67292022705107, 13.376811027526855],
        [74.67263793945312, 13.37680530548107],
        [74.67263793945312, 13.377082824706974],
        [74.67236328125028, 13.377082824706974],
        [74.67236328125028, 13.377361297607365],
        [74.6720809936524, 13.377361297607365],
        [74.6720809936524, 13.377919197082633],
        [74.67180633544933, 13.37791728973383],
        [74.67180633544933, 13.378473281860295],
        [74.67151641845709, 13.378471374511719],
        [74.67151641845709, 13.379031181335563],
        [74.67124938964838, 13.379031181335563],
        [74.67124938964838, 13.37931156158453],
        [74.67097473144554, 13.379304885864315],
        [74.67097473144554, 13.379583358764648],
        [74.67041778564459, 13.379583358764648],
        [74.67041778564459, 13.37986087799078],
        [74.67014312744158, 13.379850387573356],
        [74.67014312744158, 13.380141258239746],
        [74.67041778564459, 13.38013935089117],
        [74.67041778564459, 13.380695343017635],
        [74.67013549804705, 13.380695343017635],
        [74.67013549804705, 13.380970954895133],
        [74.67096710205101, 13.380970954895133],
        [74.67096710205101, 13.381531715393066],
        [74.67069244384783, 13.381526947021598],
        [74.67069244384783, 13.381711959838924],
        [74.67069244384783, 13.382082939148063],
        [74.67041778564459, 13.382082939148063],
        [74.67041778564459, 13.382917404174918],
        [74.67069244384783, 13.382917404174918],
        [74.67069244384783, 13.383193016052246],
        [74.67152404785162, 13.383193016052246],
      ],
    ],
    [
      [
        [74.48291778564459, 14.011249542236328],
        [74.48291778564459, 14.010972023010254],
        [74.48319244384783, 14.010972023010254],
        [74.48319244384783, 14.010417938232365],
        [74.48346710205084, 14.010419845581168],
        [74.48346710205084, 14.009309768676815],
        [74.48319244384783, 14.009309768676815],
        [74.48319244384783, 14.007920265197754],
        [74.48264312744158, 14.007920265197754],
        [74.48263549804705, 14.007710456848201],
        [74.4829025268557, 14.007641792297363],
        [74.48291778564459, 14.006529808044434],
        [74.48264312744158, 14.006529808044434],
        [74.48264312744158, 14.006249427795467],
        [74.48208618164062, 14.006249427795467],
        [74.48208618164062, 14.00541973114025],
        [74.48152923583984, 14.00541973114025],
        [74.48152923583984, 14.004860877990723],
        [74.48042297363304, 14.004860877990723],
        [74.48042297363304, 14.00514030456543],
        [74.47985839843773, 14.00514030456543],
        [74.47985839843773, 14.00541973114025],
        [74.47958374023455, 14.005417823791504],
        [74.47958374023455, 14.005693435669002],
        [74.47930908203153, 14.00569057464611],
        [74.47930908203153, 14.006249427795467],
        [74.47902679443376, 14.006249427795467],
        [74.47902679443376, 14.007362365722713],
        [74.47875213623075, 14.007358551025334],
        [74.47875213623075, 14.007920265197754],
        [74.47846984863281, 14.007920265197754],
        [74.47846984863281, 14.009309768676815],
        [74.47875213623075, 14.009309768676815],
        [74.47875213623075, 14.0098619461059],
        [74.47902679443376, 14.0098619461059],
        [74.47902679443376, 14.010693550109863],
        [74.47985839843773, 14.010693550109863],
        [74.47985839843773, 14.010972023010254],
        [74.48042297363304, 14.010972023010254],
        [74.48041534423834, 14.011249542236328],
        [74.48052215576189, 14.011249542236328],
        [74.48291778564459, 14.011249542236328],
      ],
    ],
    [
      [
        [74.32624816894548, 14.02069473266613],
        [74.32624816894548, 14.020417213440055],
        [74.32682037353533, 14.020421028137207],
        [74.32682037353533, 14.019860267639274],
        [74.32707977294945, 14.019860267639274],
        [74.32707977294945, 14.019584655761776],
        [74.32736206054705, 14.019584655761776],
        [74.32736206054705, 14.019304275512809],
        [74.32763671875006, 14.019310951232853],
        [74.32763671875006, 14.019030570983887],
        [74.3279190063476, 14.019030570983887],
        [74.3279190063476, 14.018751144409237],
        [74.32958984375028, 14.018751144409237],
        [74.32958221435575, 14.01847171783453],
        [74.3298645019533, 14.01847171783453],
        [74.3298645019533, 14.018194198608455],
        [74.33041381835955, 14.018194198608455],
        [74.33041381835955, 14.016805648803711],
        [74.3301391601563, 14.016811370849666],
        [74.3301391601563, 14.0165309906007],
        [74.32985687255876, 14.0165309906007],
        [74.32985687255876, 14.015420913696346],
        [74.32958984375028, 14.015420913696346],
        [74.32958984375028, 14.015139579773063],
        [74.32930755615234, 14.015139579773063],
        [74.32930755615234, 14.014850616455078],
        [74.32902526855497, 14.014860153198185],
        [74.32902526855497, 14.014583587646598],
        [74.32847595214855, 14.014583587646598],
        [74.32847595214855, 14.013750076294059],
        [74.32875061035156, 14.013750076294059],
        [74.32875061035156, 14.012920379638672],
        [74.32847595214855, 14.012920379638672],
        [74.32847595214855, 14.012639999389705],
        [74.32875061035156, 14.012639999389705],
        [74.32875061035156, 14.012359619140682],
        [74.32847595214855, 14.012361526489315],
        [74.32847595214855, 14.01180553436285],
        [74.3279190063476, 14.011811256408748],
        [74.3279190063476, 14.011529922485352],
        [74.32763671875006, 14.011529922485352],
        [74.32763671875006, 14.011249542236328],
        [74.32653045654325, 14.011249542236328],
        [74.32653045654325, 14.011529922485352],
        [74.32596588134794, 14.011529922485352],
        [74.32596588134794, 14.011811256408748],
        [74.32569122314453, 14.01180553436285],
        [74.32569122314453, 14.012361526489315],
        [74.32541656494169, 14.012359619140682],
        [74.32541656494169, 14.012920379638672],
        [74.32569122314453, 14.012915611267204],
        [74.32569122314453, 14.013194084167537],
        [74.32541656494169, 14.013189315795898],
        [74.32541656494169, 14.013750076294059],
        [74.32514190673834, 14.013750076294059],
        [74.32514190673834, 14.014310836791992],
        [74.32458496093756, 14.014306068420524],
        [74.32458496093756, 14.014583587646598],
        [74.32430267333979, 14.014581680297852],
        [74.32430267333979, 14.015139579773063],
        [74.32375335693376, 14.015139579773063],
        [74.32375335693376, 14.015420913696346],
        [74.3234558105471, 14.015420913696346],
        [74.3234558105471, 14.0173597335816],
        [74.32319641113298, 14.0173597335816],
        [74.32319641113298, 14.017641067504883],
        [74.323471069336, 14.01763820648199],
        [74.323471069336, 14.01847171783453],
        [74.32375335693376, 14.01847171783453],
        [74.32375335693376, 14.019584655761776],
        [74.32402801513695, 14.019581794738883],
        [74.32402801513695, 14.020421028137207],
        [74.32458496093756, 14.020417213440055],
        [74.32458496093756, 14.02069473266613],
        [74.32624816894548, 14.02069473266613],
      ],
    ],
    [
      [
        [74.40319824218756, 14.315401077270508],
        [74.40319824218756, 14.31513786315918],
        [74.40402984619135, 14.315140724182072],
        [74.40402984619135, 14.314860343933105],
        [74.40430450439459, 14.314860343933105],
        [74.40430450439459, 14.314584732055607],
        [74.4045867919923, 14.314584732055607],
        [74.4045867919923, 14.31430435180664],
        [74.4048690795899, 14.314311027526912],
        [74.4048690795899, 14.314030647277889],
        [74.40513610839872, 14.314030647277889],
        [74.40513610839872, 14.313190460205135],
        [74.40486145019537, 14.313194274902457],
        [74.40486145019537, 14.312915802001896],
        [74.4045867919923, 14.312915802001896],
        [74.4045867919923, 14.312638282775993],
        [74.40402984619135, 14.312638282775993],
        [74.40402984619135, 14.312915802001896],
        [74.40374755859398, 14.312915802001896],
        [74.40374755859398, 14.312638282775993],
        [74.40291595459001, 14.312640190124569],
        [74.40291595459001, 14.312361717224178],
        [74.40235900878906, 14.312361717224178],
        [74.40235900878906, 14.312080383300781],
        [74.40013885498053, 14.312080383300781],
        [74.40013885498053, 14.312361717224178],
        [74.3997192382813, 14.312361717224178],
        [74.39930725097656, 14.312361717224178],
        [74.39930725097656, 14.312640190124569],
        [74.39901733398466, 14.312640190124569],
        [74.39901733398466, 14.313471794128361],
        [74.39958190917997, 14.313471794128361],
        [74.39958190917997, 14.313750267028752],
        [74.40010070800787, 14.313750267028752],
        [74.400405883789, 14.313750267028752],
        [74.400405883789, 14.314030647277889],
        [74.40069580078148, 14.314030647277889],
        [74.40069580078148, 14.314311027526912],
        [74.40097045898432, 14.31430435180664],
        [74.40097045898432, 14.314860343933105],
        [74.40152740478527, 14.314860343933105],
        [74.40152740478527, 14.31513786315918],
        [74.40164947509788, 14.315148353576603],
        [74.40180969238287, 14.31516075134283],
        [74.40180969238287, 14.315421104431266],
        [74.40245056152372, 14.315421104431266],
        [74.40299224853538, 14.315421104431266],
        [74.40319824218756, 14.315401077270508],
      ],
    ],
    [
      [
        [74.3651428222658, 14.552639961242733],
        [74.3651428222658, 14.552359580993596],
        [74.36597442626959, 14.552362442016658],
        [74.36597442626959, 14.552082061767692],
        [74.36770629882835, 14.552082061767692],
        [74.37208557128929, 14.552082061767692],
        [74.37208557128929, 14.552362442016658],
        [74.37291717529308, 14.552362442016658],
        [74.37291717529308, 14.552082061767692],
        [74.3731918334961, 14.552082061767692],
        [74.3731918334961, 14.551806449890194],
        [74.3734664916995, 14.551811218261662],
        [74.3734664916995, 14.551532745361271],
        [74.37374877929688, 14.551532745361271],
        [74.37374877929688, 14.551250457763729],
        [74.37403106689482, 14.551250457763729],
        [74.37403106689482, 14.550971984863338],
        [74.37430572509783, 14.550971984863338],
        [74.37430572509783, 14.550415992736873],
        [74.37458038330084, 14.550419807434025],
        [74.37458038330084, 14.550140380859375],
        [74.37486267089861, 14.550140380859375],
        [74.37486267089861, 14.549861907958984],
        [74.37513732910179, 14.549861907958984],
        [74.37513732910179, 14.549578666687125],
        [74.37541961669916, 14.54958438873291],
        [74.37541961669916, 14.54930591583252],
        [74.37568664550787, 14.549309730529899],
        [74.37568664550787, 14.549030303955192],
        [74.37596893310558, 14.549030303955192],
        [74.37596893310558, 14.548749923706055],
        [74.37625122070318, 14.548749923706055],
        [74.37625122070318, 14.546810150146484],
        [74.37790679931658, 14.546810150146484],
        [74.37790679931658, 14.545971870422477],
        [74.37819671630882, 14.545971870422477],
        [74.37819671630882, 14.545417785644588],
        [74.37708282470732, 14.545419692993164],
        [74.37708282470732, 14.545140266418457],
        [74.37596893310558, 14.545140266418457],
        [74.37596893310558, 14.544861793518123],
        [74.37568664550787, 14.544861793518123],
        [74.37568664550787, 14.544569969177246],
        [74.37513732910179, 14.544584274291992],
        [74.37513732910179, 14.544305801391602],
        [74.37486267089861, 14.54430961608898],
        [74.37486267089861, 14.544030189514103],
        [74.37431335449236, 14.544030189514103],
        [74.37431335449236, 14.543471336364746],
        [74.37403106689482, 14.543471336364746],
        [74.37403106689482, 14.542915344238281],
        [74.37431335449236, 14.542922019958553],
        [74.37431335449236, 14.542641639709586],
        [74.37458038330084, 14.542641639709586],
        [74.37458038330084, 14.542361259460392],
        [74.37486267089861, 14.542361259460392],
        [74.37486267089861, 14.542082786560059],
        [74.37513732910179, 14.542082786560059],
        [74.37513732910179, 14.541805267333928],
        [74.37404632568388, 14.541813850402946],
        [74.3734664916995, 14.541820526123047],
        [74.3734664916995, 14.5415296554566],
        [74.3712463378908, 14.5415296554566],
        [74.3712463378908, 14.541820526123047],
        [74.370414733887, 14.541805267333928],
        [74.370414733887, 14.542082786560059],
        [74.36791992187528, 14.542082786560059],
        [74.36791992187528, 14.541805267333928],
        [74.36763763427751, 14.541820526123047],
        [74.36763763427751, 14.5415296554566],
        [74.3673629760745, 14.5415296554566],
        [74.3673629760745, 14.541249275207463],
        [74.36680603027372, 14.541249275207463],
        [74.36680603027372, 14.5415296554566],
        [74.36653137207037, 14.5415296554566],
        [74.36653137207037, 14.541820526123047],
        [74.36597442626959, 14.541805267333928],
        [74.36597442626959, 14.542082786560059],
        [74.36541748046898, 14.542082786560059],
        [74.36541748046898, 14.542361259460392],
        [74.3651428222658, 14.542361259460392],
        [74.3651428222658, 14.542641639709586],
        [74.36458587646501, 14.542641639709586],
        [74.36458587646501, 14.542922019958553],
        [74.36430358886747, 14.542915344238281],
        [74.36430358886747, 14.543195724487248],
        [74.36398315429705, 14.543192863464412],
        [74.36374664306669, 14.54319095611578],
        [74.36374664306669, 14.54375171661377],
        [74.36347198486334, 14.543748855590877],
        [74.36347198486334, 14.544584274291992],
        [74.36319732666027, 14.544569969177246],
        [74.36319732666027, 14.544861793518123],
        [74.36347198486334, 14.544861793518123],
        [74.36347198486334, 14.545694351196346],
        [74.36374664306669, 14.545690536499023],
        [74.36374664306669, 14.546529769897518],
        [74.36402893066406, 14.546529769897518],
        [74.36402893066406, 14.549309730529899],
        [74.36374664306669, 14.54930591583252],
        [74.36374664306669, 14.552082061767692],
        [74.36402893066406, 14.552080154418945],
        [74.36402893066406, 14.552639961242733],
        [74.3651428222658, 14.552639961242733],
      ],
    ],
    [
      [
        [74.25375366210955, 14.70597362518322],
        [74.25375366210955, 14.705693244934082],
        [74.25430297851557, 14.705693244934082],
        [74.25430297851557, 14.705417633056754],
        [74.25541687011747, 14.70541954040533],
        [74.25541687011747, 14.705142021179256],
        [74.25514221191412, 14.705142021179256],
        [74.25514221191412, 14.70486164093029],
        [74.25485992431652, 14.70486164093029],
        [74.25485992431652, 14.704320907592887],
        [74.25514221191412, 14.704320907592887],
        [74.25514221191412, 14.703751564025936],
        [74.25374603271501, 14.703751564025936],
        [74.25374603271501, 14.704320907592887],
        [74.25347137451178, 14.704320907592887],
        [74.25347137451178, 14.704031944274902],
        [74.25319671630876, 14.704031944274902],
        [74.25319671630876, 14.703751564025936],
        [74.25235748291044, 14.703751564025936],
        [74.25235748291044, 14.704031944274902],
        [74.25180816650402, 14.704031944274902],
        [74.25180816650402, 14.704320907592887],
        [74.2515258789063, 14.704320907592887],
        [74.2515258789063, 14.70486164093029],
        [74.25180816650402, 14.70486164093029],
        [74.25180816650402, 14.705142021179256],
        [74.25208282470709, 14.705142021179256],
        [74.25208282470709, 14.70541954040533],
        [74.25235748291044, 14.705417633056754],
        [74.25235748291044, 14.705693244934082],
        [74.25263977050781, 14.705693244934082],
        [74.25263977050781, 14.70597362518322],
        [74.25375366210955, 14.70597362518322],
      ],
    ],
    [
      [
        [74.24069213867193, 14.711250305175895],
        [74.24069213867193, 14.710971832275504],
        [74.2409744262697, 14.710971832275504],
        [74.2409744262697, 14.70986175537115],
        [74.24041748046892, 14.70986175537115],
        [74.24041748046892, 14.709305763244686],
        [74.24014282226591, 14.709309577941895],
        [74.24014282226591, 14.709030151367188],
        [74.23958587646513, 14.709030151367188],
        [74.23958587646513, 14.708749771118221],
        [74.23847198486322, 14.708749771118221],
        [74.23847198486322, 14.709030151367188],
        [74.2379226684572, 14.709030151367188],
        [74.2379226684572, 14.710419654846248],
        [74.23819732666038, 14.71041584014904],
        [74.23819732666038, 14.71069431304943],
        [74.23874664306646, 14.71069431304943],
        [74.23874664306646, 14.710971832275504],
        [74.23958587646513, 14.710971832275504],
        [74.23958587646513, 14.711250305175895],
        [74.24069213867193, 14.711250305175895],
      ],
    ],
    [
      [
        [74.15541839599632, 14.741810798645076],
        [74.15541839599632, 14.74153137207037],
        [74.15598297119163, 14.74153137207037],
        [74.15598297119163, 14.741250038146916],
        [74.1565170288086, 14.741250038146916],
        [74.1565170288086, 14.74096965789795],
        [74.15680694580107, 14.740971565246582],
        [74.15680694580107, 14.74041557312006],
        [74.1570816040039, 14.74043178558344],
        [74.1570816040039, 14.74013996124279],
        [74.15735626220732, 14.74013996124279],
        [74.15735626220732, 14.739859580993596],
        [74.15763854980486, 14.739862442016658],
        [74.15763854980486, 14.739583969116268],
        [74.15791320800787, 14.739583969116268],
        [74.15791320800787, 14.739306449890194],
        [74.1582107543947, 14.739311218261662],
        [74.1582107543947, 14.738750457763729],
        [74.15789794921898, 14.738750457763729],
        [74.15789794921898, 14.738191604614371],
        [74.15763854980486, 14.738194465637264],
        [74.15763854980486, 14.737915992736873],
        [74.15735626220732, 14.737919807434082],
        [74.15735626220732, 14.737640380859375],
        [74.1565170288086, 14.737640380859375],
        [74.1565170288086, 14.737919807434082],
        [74.15625000000011, 14.737915992736873],
        [74.15625000000011, 14.738194465637264],
        [74.15598297119163, 14.738191604614371],
        [74.15598297119163, 14.738750457763729],
        [74.15569305419916, 14.738750457763729],
        [74.15569305419916, 14.739032745361271],
        [74.15541839599632, 14.739032745361271],
        [74.15541839599632, 14.739311218261662],
        [74.15486145019537, 14.739306449890194],
        [74.15486145019537, 14.739583969116268],
        [74.15430450439482, 14.739583969116268],
        [74.15430450439482, 14.739862442016658],
        [74.1540298461914, 14.739859580993596],
        [74.1540298461914, 14.74013996124279],
        [74.15319824218761, 14.74013996124279],
        [74.15319824218761, 14.741250038146916],
        [74.15347290039068, 14.741250038146916],
        [74.15347290039068, 14.74153137207037],
        [74.15374755859403, 14.74153137207037],
        [74.15374755859403, 14.741810798645076],
        [74.15541839599632, 14.741810798645076],
      ],
    ],
    [
      [
        [74.11125183105463, 14.761541366577262],
        [74.11125183105463, 14.761249542236385],
        [74.11152648925787, 14.761249542236385],
        [74.11152648925787, 14.759580612182674],
        [74.11180877685558, 14.759584426879826],
        [74.11180877685558, 14.759026527404785],
        [74.11207580566406, 14.759030342102164],
        [74.11207580566406, 14.758751869201774],
        [74.112358093262, 14.758751869201774],
        [74.112358093262, 14.75847053527832],
        [74.11264038085938, 14.75847053527832],
        [74.11264038085938, 14.758195877075309],
        [74.11290740966825, 14.75817966461193],
        [74.11291503906278, 14.757918357849178],
        [74.11347198486334, 14.75792026519781],
        [74.11347198486334, 14.75764179229742],
        [74.11374664306658, 14.75764179229742],
        [74.11374664306658, 14.757361412048454],
        [74.11402893066429, 14.757361412048454],
        [74.11402893066429, 14.757082939148063],
        [74.11430358886713, 14.757082939148063],
        [74.11430358886713, 14.756805419921989],
        [74.11486053466808, 14.756810188293457],
        [74.11486053466808, 14.756531715393066],
        [74.11541748046903, 14.756531715393066],
        [74.11541748046903, 14.7562513351441],
        [74.11569213867188, 14.7562513351441],
        [74.11569213867188, 14.75569057464594],
        [74.11597442626982, 14.755695343017635],
        [74.11597442626982, 14.755416870117244],
        [74.1165313720706, 14.755416870117244],
        [74.1165313720706, 14.755139350891113],
        [74.1187515258789, 14.755139350891113],
        [74.1187515258789, 14.75486087799078],
        [74.11975097656273, 14.75486087799078],
        [74.11985778808611, 14.75486087799078],
        [74.11985778808611, 14.75470161437994],
        [74.11985778808611, 14.754304885864258],
        [74.12014007568365, 14.754309654235954],
        [74.12014007568365, 14.753471374511719],
        [74.11985778808611, 14.753473281860295],
        [74.11985778808611, 14.753194808959904],
        [74.11902618408232, 14.753194808959904],
        [74.11902618408232, 14.753473281860295],
        [74.1187515258789, 14.753471374511719],
        [74.1187515258789, 14.753748893737793],
        [74.11650848388695, 14.753748893737793],
        [74.1145858764649, 14.753748893737793],
        [74.1145858764649, 14.75402927398676],
        [74.11402893066429, 14.75402927398676],
        [74.11402893066429, 14.754309654235954],
        [74.11347198486334, 14.754304885864258],
        [74.11347198486334, 14.754583358764648],
        [74.11290740966825, 14.754581451416072],
        [74.11290740966825, 14.75486087799078],
        [74.11264038085938, 14.75486087799078],
        [74.11264038085938, 14.755139350891113],
        [74.11152648925787, 14.755139350891113],
        [74.11152648925787, 14.754581451416072],
        [74.11096954345726, 14.754583358764648],
        [74.11096954345726, 14.754304885864258],
        [74.11068725585955, 14.754309654235954],
        [74.11068725585955, 14.75402927398676],
        [74.109848022461, 14.75402927398676],
        [74.109848022461, 14.75486087799078],
        [74.1101379394533, 14.75486087799078],
        [74.1101379394533, 14.755139350891113],
        [74.11042022705084, 14.755139350891113],
        [74.11042022705084, 14.756531715393066],
        [74.1101379394533, 14.756531715393066],
        [74.1101379394533, 14.756810188293457],
        [74.10986328125028, 14.756805419921989],
        [74.10986328125028, 14.757082939148063],
        [74.10958099365234, 14.757082939148063],
        [74.10958099365234, 14.757361412048454],
        [74.10901641845703, 14.757361412048454],
        [74.10901641845703, 14.75764179229742],
        [74.10874938964855, 14.75764179229742],
        [74.10874938964855, 14.758751869201774],
        [74.10791778564476, 14.758749008178711],
        [74.10791778564476, 14.759584426879826],
        [74.1081924438476, 14.759580612182674],
        [74.1081924438476, 14.759861946105957],
        [74.10846710205084, 14.759861946105957],
        [74.10846710205084, 14.760140419006348],
        [74.10874938964855, 14.760140419006348],
        [74.10874938964855, 14.760421752929744],
        [74.10902404785156, 14.760417938232422],
        [74.10902404785156, 14.760692596435604],
        [74.1093063354495, 14.760692596435604],
        [74.1093063354495, 14.76097202301031],
        [74.10958099365234, 14.76097202301031],
        [74.10958099365234, 14.761249542236385],
        [74.1101379394533, 14.761249542236385],
        [74.1101379394533, 14.761541366577262],
        [74.11125183105463, 14.761541366577262],
      ],
    ],
    [
      [
        [74.08791351318365, 14.79597187042242],
        [74.08791351318365, 14.795694351196289],
        [74.0887527465822, 14.795694351196289],
        [74.0887527465822, 14.795415878295955],
        [74.08902740478521, 14.795420646667594],
        [74.08902740478521, 14.793751716613883],
        [74.0873565673831, 14.793751716613883],
        [74.0873565673831, 14.79431056976324],
        [74.08708190917969, 14.794305801391602],
        [74.08708190917969, 14.794584274291935],
        [74.08680725097685, 14.794580459594783],
        [74.08680725097685, 14.794861793518066],
        [74.0862503051759, 14.794859886169434],
        [74.0862503051759, 14.795694351196289],
        [74.08652496337896, 14.795694351196289],
        [74.08652496337896, 14.79597187042242],
        [74.08791351318365, 14.79597187042242],
      ],
    ],
    [
      [
        [74.0565261840822, 14.817084312439079],
        [74.0565261840822, 14.816805839538688],
        [74.05680847167991, 14.816809654235897],
        [74.05680847167991, 14.81653022766119],
        [74.0565261840822, 14.81653022766119],
        [74.0565261840822, 14.816249847412223],
        [74.05625152587896, 14.816249847412223],
        [74.05625152587896, 14.815969467163086],
        [74.05596923828142, 14.815969467163086],
        [74.05596923828142, 14.816249847412223],
        [74.05569458007841, 14.816249847412223],
        [74.05569458007841, 14.815972328186149],
        [74.05458068847668, 14.815969467163086],
        [74.05458068847668, 14.816249847412223],
        [74.0543060302735, 14.816249847412223],
        [74.0543060302735, 14.81653022766119],
        [74.05458068847668, 14.81653022766119],
        [74.05458068847668, 14.816809654235897],
        [74.05486297607428, 14.816805839538688],
        [74.05486297607428, 14.817084312439079],
        [74.0565261840822, 14.817084312439079],
      ],
    ],
    [
      [
        [74.0640258789063, 14.821249961853141],
        [74.0640258789063, 14.820969581603947],
        [74.06458282470703, 14.82097244262701],
        [74.06458282470703, 14.82069396972662],
        [74.06513977050781, 14.82068920135498],
        [74.06513977050781, 14.820416450500545],
        [74.06540679931646, 14.820421218872013],
        [74.06540679931646, 14.820141792297363],
        [74.06569671630876, 14.820141792297363],
        [74.06569671630876, 14.81986045837408],
        [74.06541442871122, 14.819862365722656],
        [74.06541442871122, 14.81958198547369],
        [74.06485748291044, 14.81958198547369],
        [74.06485748291044, 14.819306373596191],
        [74.06291961669928, 14.819306373596191],
        [74.06291961669928, 14.81958198547369],
        [74.06263732910173, 14.81958198547369],
        [74.06263732910173, 14.82068920135498],
        [74.06291961669928, 14.82069396972662],
        [74.06291961669928, 14.82097244262701],
        [74.06318664550776, 14.820969581603947],
        [74.06318664550776, 14.821249961853141],
        [74.0640258789063, 14.821249961853141],
      ],
    ],
    [
      [
        [74.05930328369146, 14.821528434753475],
        [74.05930328369146, 14.821249961853141],
        [74.06179809570341, 14.821249961853141],
        [74.06179809570341, 14.820700645446891],
        [74.06208038330095, 14.82068920135498],
        [74.06208038330095, 14.820416450500545],
        [74.06179809570341, 14.820421218872013],
        [74.06179809570341, 14.820141792297363],
        [74.06153106689453, 14.820141792297363],
        [74.06153106689453, 14.81986045837408],
        [74.0609741210938, 14.819862365722656],
        [74.0609741210938, 14.81958198547369],
        [74.05958557128923, 14.81958198547369],
        [74.05958557128923, 14.819306373596191],
        [74.05819702148466, 14.81931114196783],
        [74.05819702148466, 14.819029808044434],
        [74.057357788086, 14.8190279006958],
        [74.057357788086, 14.81958198547369],
        [74.05708312988276, 14.81958198547369],
        [74.05708312988276, 14.820141792297363],
        [74.057357788086, 14.820141792297363],
        [74.057357788086, 14.820421218872013],
        [74.05791473388678, 14.820416450500545],
        [74.05790710449219, 14.82068920135498],
        [74.05819702148466, 14.820700645446891],
        [74.05819702148466, 14.821249961853141],
        [74.0584716796875, 14.821249961853141],
        [74.0584716796875, 14.821528434753475],
        [74.05930328369146, 14.821528434753475],
      ],
    ],
    [
      [
        [74.09402465820341, 14.838471412658805],
        [74.09402465820341, 14.838195800781307],
        [74.09430694580095, 14.838195800781307],
        [74.09430694580095, 14.837638854980526],
        [74.09458160400396, 14.837641716003418],
        [74.09458160400396, 14.837080955505485],
        [74.09486389160173, 14.83708286285406],
        [74.09486389160173, 14.836805343627987],
        [74.09401702880865, 14.836820602417049],
        [74.09401702880865, 14.836251258850098],
        [74.09375, 14.836251258850098],
        [74.09375, 14.835970878601131],
        [74.09291839599621, 14.835970878601131],
        [74.09291839599621, 14.83708286285406],
        [74.09319305419922, 14.83708286285406],
        [74.09319305419922, 14.838195800781307],
        [74.09347534179716, 14.838195800781307],
        [74.09347534179716, 14.838471412658805],
        [74.09402465820341, 14.838471412658805],
      ],
    ],
    [
      [
        [74.09847259521479, 14.84597206115717],
        [74.09847259521479, 14.845693588256779],
        [74.09874725341803, 14.845693588256779],
        [74.09874725341803, 14.845417976379451],
        [74.09929656982422, 14.845422744750977],
        [74.09929656982422, 14.845140457153377],
        [74.09986114501953, 14.845140457153377],
        [74.09986114501953, 14.844861984252987],
        [74.10041809082048, 14.844861984252987],
        [74.10041809082048, 14.84458160400402],
        [74.10208129882824, 14.844584465026912],
        [74.10208129882824, 14.843194007873592],
        [74.10190582275385, 14.843194007873592],
        [74.10180664062506, 14.843194007873592],
        [74.10180664062506, 14.842915534973201],
        [74.10151672363276, 14.84292030334467],
        [74.10151672363276, 14.842631340026855],
        [74.10097503662126, 14.842639923095703],
        [74.10097503662126, 14.842083930969238],
        [74.10041809082048, 14.842083930969238],
        [74.10041809082048, 14.841806411743164],
        [74.09874725341803, 14.841806411743164],
        [74.09874725341803, 14.842083930969238],
        [74.09791564941423, 14.842083930969238],
        [74.09791564941423, 14.842362403869629],
        [74.097640991211, 14.842362403869629],
        [74.097640991211, 14.842639923095703],
        [74.09735870361345, 14.842631340026855],
        [74.09735870361345, 14.84292030334467],
        [74.09708404541044, 14.842915534973201],
        [74.09708404541044, 14.843471527099666],
        [74.0968017578125, 14.843471527099666],
        [74.0968017578125, 14.844861984252987],
        [74.09652709960966, 14.844861984252987],
        [74.09652709960966, 14.84597206115717],
        [74.09847259521479, 14.84597206115717],
      ],
    ],
    [
      [
        [74.09708404541044, 14.878472328186092],
        [74.09708404541044, 14.877916336059627],
        [74.09735870361345, 14.877921104431266],
        [74.09735870361345, 14.877079963684139],
        [74.09680938720703, 14.877083778381348],
        [74.0968017578125, 14.876806259155273],
        [74.0962524414063, 14.876811027526912],
        [74.0962524414063, 14.876530647277946],
        [74.09570312500006, 14.876530647277946],
        [74.09570312500006, 14.876250267028809],
        [74.09513854980491, 14.876250267028809],
        [74.09513854980491, 14.875971794128418],
        [74.09430694580095, 14.875971794128418],
        [74.09430694580095, 14.876530647277946],
        [74.09402465820341, 14.876527786254883],
        [74.09402465820341, 14.877083778381348],
        [74.09430694580095, 14.877079963684139],
        [74.09430694580095, 14.877640724182129],
        [74.09458160400396, 14.877640724182129],
        [74.09458160400396, 14.877921104431266],
        [74.09513854980491, 14.877916336059627],
        [74.09513854980491, 14.878193855285701],
        [74.09569549560553, 14.878193855285701],
        [74.09569549560553, 14.878472328186092],
        [74.09708404541044, 14.878472328186092],
      ],
    ],
    [
      [
        [77.33731079101591, 18.435440063476562],
        [77.34207916259794, 18.43029022216797],
        [77.3452911376956, 18.4343719482423],
        [77.35407257080107, 18.448741912841854],
        [77.36962890625, 18.447650909423942],
        [77.36535644531261, 18.438089370727482],
        [77.36441040039074, 18.429849624633903],
        [77.36975097656261, 18.400709152221737],
        [77.39263916015636, 18.39702987670904],
        [77.40049743652361, 18.397800445556697],
        [77.41011047363304, 18.388530731201286],
        [77.40788269042997, 18.378490447998047],
        [77.40067291259783, 18.37017059326172],
        [77.39182281494146, 18.365930557251033],
        [77.39824676513672, 18.353530883789176],
        [77.39833068847685, 18.347499847412223],
        [77.39594268798828, 18.34464073181158],
        [77.39277648925787, 18.345670700073185],
        [77.39282989501964, 18.34054946899414],
        [77.38922882080095, 18.340789794921875],
        [77.38617706298845, 18.33819007873535],
        [77.37930297851557, 18.339101791381893],
        [77.38089752197271, 18.331930160522575],
        [77.37800598144548, 18.32868957519537],
        [77.3739471435548, 18.32950973510748],
        [77.36701965332037, 18.322322845459098],
        [77.3554229736331, 18.318519592285156],
        [77.35881042480491, 18.31064987182623],
        [77.36252593994158, 18.30829048156744],
        [77.3812866210938, 18.305950164794865],
        [77.3925704956057, 18.300930023193416],
        [77.39628601074236, 18.30298042297369],
        [77.40780639648443, 18.299560546874943],
        [77.42500305175787, 18.304670333862248],
        [77.42780303955078, 18.30917930603033],
        [77.43637084960955, 18.306491851806754],
        [77.43461608886713, 18.29298019409174],
        [77.43692779541033, 18.28203964233404],
        [77.4448928833009, 18.277240753173885],
        [77.44522094726568, 18.27012062072754],
        [77.45079040527372, 18.26596069335949],
        [77.4653930664063, 18.25996017456066],
        [77.46923065185553, 18.263160705566406],
        [77.4748077392581, 18.2622909545899],
        [77.48034667968778, 18.268659591674805],
        [77.49223327636736, 18.26588058471691],
        [77.4977416992187, 18.273349761962834],
        [77.51020812988281, 18.278440475463924],
        [77.51264190673845, 18.28198051452631],
        [77.50843048095697, 18.302169799804744],
        [77.509506225586, 18.306730270385856],
        [77.51978302001976, 18.30736923217779],
        [77.52388763427751, 18.30303192138672],
        [77.53958129882812, 18.293830871582145],
        [77.54995727539091, 18.291349411010742],
        [77.56098175048851, 18.283109664916935],
        [77.5706558227539, 18.29047012329113],
        [77.57463836669939, 18.28931045532238],
        [77.5743331909182, 18.28684806823736],
        [77.58381652832031, 18.283081054687557],
        [77.59784698486357, 18.28121948242199],
        [77.60301971435558, 18.2779998779298],
        [77.59902954101557, 18.272911071777344],
        [77.59194183349638, 18.261770248413086],
        [77.58149719238298, 18.256320953369254],
        [77.57253265380876, 18.240560531616325],
        [77.57676696777344, 18.233100891113338],
        [77.58106994628935, 18.222738265991268],
        [77.5849609375, 18.21674156188959],
        [77.57438659667997, 18.199920654296932],
        [77.56430816650385, 18.200550079345703],
        [77.56431579589838, 18.189979553222713],
        [77.57002258300793, 18.189159393310604],
        [77.57247161865263, 18.1789493560791],
        [77.5775375366211, 18.168270111083984],
        [77.57936096191418, 18.16212081909191],
        [77.58915710449219, 18.15345954895031],
        [77.59777069091803, 18.151840209960994],
        [77.59350585937517, 18.127861022949332],
        [77.59661102294922, 18.121030807495117],
        [77.60056304931658, 18.099399566650334],
        [77.59745788574247, 18.08428955078125],
        [77.58979797363287, 18.0802898406983],
        [77.577445983887, 18.076869964599723],
        [77.5673370361331, 18.076290130615348],
        [77.56048583984386, 18.071130752563477],
        [77.55670928955095, 18.06217002868658],
        [77.54743194580084, 18.06363105773937],
        [77.54574584960943, 18.053760528564567],
        [77.54807281494169, 18.04799079895031],
        [77.55206298828153, 18.04693984985346],
        [77.55311584472656, 18.034669876098746],
        [77.55782318115229, 18.035200119018555],
        [77.5744857788086, 18.03074073791504],
        [77.58496856689453, 18.024789810180664],
        [77.58724975585938, 18.01333045959467],
        [77.5922470092774, 18.010168075561467],
        [77.59948730468756, 18.015739440918082],
        [77.60666656494158, 18.01801109313965],
        [77.6100616455081, 18.01048088073736],
        [77.6158523559572, 18.006088256835938],
        [77.62229156494135, 18.00465965270996],
        [77.62390136718756, 18.001159667968807],
        [77.6214523315432, 17.998340606689453],
        [77.62879943847673, 17.992519378662166],
        [77.63272857666044, 17.995140075683707],
        [77.63822174072271, 17.996540069580192],
        [77.6422882080081, 17.995901107788086],
        [77.64617919921892, 17.993200302123967],
        [77.64775085449224, 17.987321853637752],
        [77.64978027343767, 17.985862731933594],
        [77.6488876342774, 17.97841072082514],
        [77.643608093262, 17.97344970703125],
        [77.64742279052763, 17.970439910888786],
        [77.65339660644548, 17.969352722168082],
        [77.65204620361322, 17.964300155639705],
        [77.64425659179705, 17.962690353393498],
        [77.64282989501959, 17.95729064941412],
        [77.64742279052763, 17.953189849853572],
        [77.64398956298857, 17.946371078491268],
        [77.64061737060564, 17.94962120056158],
        [77.63561248779325, 17.950519561767635],
        [77.63182067871105, 17.953531265258846],
        [77.62972259521496, 17.962970733642692],
        [77.62374114990257, 17.957750320434627],
        [77.62899017334001, 17.95384025573736],
        [77.62886047363287, 17.948511123657227],
        [77.62428283691412, 17.943691253662166],
        [77.6200103759765, 17.944459915161076],
        [77.61679840087908, 17.938100814819336],
        [77.61952972412115, 17.930070877075252],
        [77.618667602539, 17.92180824279785],
        [77.61894989013678, 17.913480758666935],
        [77.61657714843744, 17.90286827087408],
        [77.60444641113287, 17.905490875244197],
        [77.5997924804687, 17.903671264648438],
        [77.58516693115234, 17.908229827880973],
        [77.58307647705084, 17.898380279541016],
        [77.58521270751976, 17.892520904541072],
        [77.59249114990263, 17.87949943542492],
        [77.59777832031256, 17.87706947326666],
        [77.59668731689482, 17.8704509735108],
        [77.59275817871111, 17.869779586792106],
        [77.58127593994152, 17.87574958801281],
        [77.57772827148438, 17.871229171752987],
        [77.57274627685564, 17.872810363769588],
        [77.56743621826178, 17.8661403656007],
        [77.56604003906267, 17.849559783935547],
        [77.56215667724638, 17.849130630493164],
        [77.5634689331057, 17.84200096130371],
        [77.55918884277361, 17.838640213012695],
        [77.55432128906256, 17.830490112304744],
        [77.54973602294945, 17.81802940368658],
        [77.54479217529303, 17.817010879516715],
        [77.543716430664, 17.826269149780387],
        [77.52600097656267, 17.82681846618658],
        [77.52607727050804, 17.811689376831055],
        [77.518051147461, 17.807720184326286],
        [77.51087951660185, 17.801441192627067],
        [77.5070190429688, 17.792959213256836],
        [77.50448608398438, 17.78259086608898],
        [77.50934600830107, 17.780080795288143],
        [77.52304077148443, 17.779621124267635],
        [77.52760314941429, 17.776660919189396],
        [77.53449249267584, 17.77469062805187],
        [77.5369720458985, 17.77045249938965],
        [77.55445098876953, 17.761259078979435],
        [77.56595611572271, 17.764110565185547],
        [77.562026977539, 17.74711036682129],
        [77.55464172363281, 17.74113082885748],
        [77.55187988281278, 17.73438072204584],
        [77.54412078857439, 17.733390808105526],
        [77.53936004638689, 17.725421905517578],
        [77.53398895263666, 17.72520256042492],
        [77.52755737304688, 17.72711753845209],
        [77.52258300781267, 17.724029541015682],
        [77.50946807861334, 17.719961166381893],
        [77.50544738769537, 17.714519500732422],
        [77.49427795410185, 17.716039657592887],
        [77.49173736572271, 17.712860107421818],
        [77.48932647705107, 17.706800460815487],
        [77.48446655273443, 17.703649520874137],
        [77.48108673095709, 17.706850051879883],
        [77.4786758422851, 17.703029632568473],
        [77.47415161132812, 17.701391220092773],
        [77.47155761718778, 17.704010009765682],
        [77.45786285400402, 17.698961257934684],
        [77.45462036132824, 17.694097518921012],
        [77.45034790039068, 17.691299438476676],
        [77.45140838623064, 17.687740325927848],
        [77.4509963989259, 17.680860519409237],
        [77.44909667968761, 17.66611099243164],
        [77.44564819335966, 17.65746116638195],
        [77.4450073242188, 17.648420333862305],
        [77.44753265380888, 17.64711952209467],
        [77.45081329345714, 17.632360458374023],
        [77.4532165527346, 17.630769729614258],
        [77.44882202148443, 17.622789382934627],
        [77.44580078125006, 17.612300872802734],
        [77.44650268554688, 17.607240676879826],
        [77.44130706787126, 17.606630325317496],
        [77.44050598144543, 17.60409164428711],
        [77.44207000732439, 17.595811843872013],
        [77.43992614746122, 17.58147048950201],
        [77.46121978759771, 17.577030181884766],
        [77.46515655517595, 17.574659347534237],
        [77.46911621093761, 17.56818008422863],
        [77.47207641601568, 17.56913185119629],
        [77.47408294677746, 17.56511116027832],
        [77.48999786376959, 17.56627082824707],
        [77.49563598632824, 17.550029754638786],
        [77.49555206298834, 17.54652976989746],
        [77.49681854248064, 17.54337120056158],
        [77.50161743164062, 17.540880203247013],
        [77.50965881347662, 17.549169540405217],
        [77.51390075683622, 17.557249069213867],
        [77.52742004394554, 17.573221206665096],
        [77.53208160400385, 17.573089599609375],
        [77.53669738769548, 17.571329116821346],
        [77.54395294189453, 17.554599761963004],
        [77.54839324951195, 17.549459457397518],
        [77.5528106689456, 17.55000114440918],
        [77.55612945556658, 17.552150726318473],
        [77.56083679199247, 17.553520202636776],
        [77.5853881835938, 17.558361053466854],
        [77.58541870117193, 17.56204032897955],
        [77.58982849121122, 17.562759399414006],
        [77.5918579101563, 17.555059432983512],
        [77.59245300292991, 17.54907989501953],
        [77.5917282104495, 17.532680511474723],
        [77.5992507934572, 17.53400039672863],
        [77.60325622558611, 17.530691146850586],
        [77.60797119140636, 17.53107261657715],
        [77.61657714843744, 17.529350280761832],
        [77.6212158203125, 17.52225112915039],
        [77.63315582275402, 17.523080825805778],
        [77.645317077637, 17.518791198730526],
        [77.64987945556646, 17.521070480346737],
        [77.65661621093761, 17.52133941650402],
        [77.66120910644526, 17.51481056213379],
        [77.66606903076178, 17.509950637817496],
        [77.67893981933588, 17.50510025024414],
        [77.68202972412126, 17.506668090820426],
        [77.68325042724632, 17.504020690917912],
        [77.68562316894526, 17.50564956665039],
        [77.68799591064459, 17.49854278564453],
        [77.68696594238298, 17.491569519043082],
        [77.68467712402361, 17.485229492187557],
        [77.68401336669945, 17.472850799560547],
        [77.66488647460938, 17.476289749145565],
        [77.64808654785173, 17.481178283691406],
        [77.64897155761747, 17.491552352905387],
        [77.6527633666995, 17.503250122070256],
        [77.64794921875006, 17.49936294555664],
        [77.6442260742187, 17.494899749755973],
        [77.64259338378912, 17.483449935913143],
        [77.64042663574236, 17.476011276245174],
        [77.63403320312523, 17.472230911254883],
        [77.6265716552735, 17.471200942993278],
        [77.61564636230486, 17.471401214599666],
        [77.6109466552735, 17.469940185546875],
        [77.61010742187517, 17.46463966369629],
        [77.61144256591814, 17.452909469604492],
        [77.61061096191435, 17.444232940673828],
        [77.60447692871116, 17.443090438842887],
        [77.59590148925787, 17.442680358886776],
        [77.59041595459013, 17.448102951049805],
        [77.57319641113304, 17.45420074462902],
        [77.57195281982439, 17.438550949096623],
        [77.57256317138672, 17.42951965332037],
        [77.55777740478544, 17.435270309448356],
        [77.54884338378918, 17.43492126464855],
        [77.5334701538086, 17.440950393676758],
        [77.5233764648438, 17.436689376831055],
        [77.5101470947265, 17.427259445190543],
        [77.51068878173857, 17.41254043579113],
        [77.52671813964855, 17.405990600585938],
        [77.53357696533232, 17.40610122680664],
        [77.53550720214838, 17.404100418090934],
        [77.53357696533232, 17.398849487304744],
        [77.52133178710943, 17.386331558227653],
        [77.52658843994158, 17.378829956054744],
        [77.5301818847658, 17.377880096435547],
        [77.52802276611357, 17.37211036682129],
        [77.52081298828136, 17.36205101013178],
        [77.50975799560541, 17.35927009582531],
        [77.49912261962908, 17.35407066345215],
        [77.49237823486357, 17.355510711670036],
        [77.48261260986334, 17.354459762573242],
        [77.47447204589838, 17.35917091369629],
        [77.47241210937517, 17.363178253173828],
        [77.47534179687523, 17.368150711059627],
        [77.47931671142584, 17.36961174011236],
        [77.45159912109392, 17.370491027831974],
        [77.44988250732439, 17.36306190490734],
        [77.4497680664063, 17.350048065185547],
        [77.4529800415039, 17.34880828857422],
        [77.45635223388666, 17.344989776611442],
        [77.45510101318365, 17.337909698486385],
        [77.45250701904325, 17.327930450439453],
        [77.4513702392581, 17.327230453491268],
        [77.4496383666995, 17.31489944458002],
        [77.44798278808611, 17.313451766967773],
        [77.44043731689476, 17.297809600830135],
        [77.43776702880882, 17.296689987182617],
        [77.43402099609386, 17.288259506225586],
        [77.44699859619152, 17.288829803466797],
        [77.45279693603516, 17.286630630493107],
        [77.45450592041016, 17.275890350341854],
        [77.44967651367182, 17.269861221313477],
        [77.4424819946289, 17.268590927123967],
        [77.4373626708985, 17.261369705200252],
        [77.42257690429699, 17.253250122070312],
        [77.4204788208009, 17.24933052062994],
        [77.42002105712896, 17.2416095733642],
        [77.41776275634771, 17.232789993286246],
        [77.41493988037138, 17.229879379272404],
        [77.40545654296892, 17.23740959167486],
        [77.39882659912115, 17.23188972473139],
        [77.39585113525419, 17.232851028442383],
        [77.39225006103527, 17.228559494018555],
        [77.38401794433622, 17.22331047058111],
        [77.38035583496116, 17.219770431518498],
        [77.3812866210938, 17.216321945190543],
        [77.38458251953153, 17.212961196899528],
        [77.39318847656278, 17.21147918701172],
        [77.39901733398443, 17.21153068542492],
        [77.39881134033203, 17.208778381347656],
        [77.39344787597673, 17.206939697265682],
        [77.39148712158214, 17.192369461059684],
        [77.38173675537138, 17.19372940063488],
        [77.37671661376959, 17.193000793457088],
        [77.37075042724626, 17.190071105956974],
        [77.3675308227539, 17.183200836181697],
        [77.36837005615263, 17.176481246948185],
        [77.36354827880865, 17.169481277465934],
        [77.36490631103538, 17.164949417114258],
        [77.35999298095732, 17.161621093750114],
        [77.36686706542997, 17.151330947876033],
        [77.37541961669928, 17.148700714111385],
        [77.3763275146485, 17.13788986206049],
        [77.39890289306669, 17.131919860839844],
        [77.40373229980486, 17.123210906982365],
        [77.41178894042969, 17.12051963806158],
        [77.42352294921903, 17.11405754089367],
        [77.43376922607445, 17.111150741577205],
        [77.4386291503908, 17.11458015441889],
        [77.44841766357428, 17.11149978637701],
        [77.45237731933611, 17.10761070251459],
        [77.46263885498058, 17.104070663452205],
        [77.46031188964872, 17.092840194702205],
        [77.46386718750006, 17.08671188354498],
        [77.46842956542969, 17.081111907958984],
        [77.47692108154325, 17.07623291015625],
        [77.47783660888678, 17.07226943969738],
        [77.47543334960932, 17.06989097595215],
        [77.47805786132841, 17.060989379882812],
        [77.48306274414057, 17.05344009399414],
        [77.48676300048834, 17.04467964172369],
        [77.49186706542986, 17.038841247558594],
        [77.49323272705078, 17.032989501953182],
        [77.491996765137, 17.021650314331055],
        [77.49819946289068, 17.015199661254826],
        [77.49938964843756, 17.00732040405279],
        [77.49357604980486, 17.00584030151373],
        [77.48323059082043, 16.998678207397575],
        [77.48198699951178, 16.99719047546381],
        [77.47837066650396, 16.99024963378912],
        [77.47998046875028, 16.97517013549816],
        [77.47918701171903, 16.968032836914176],
        [77.47374725341814, 16.959470748901367],
        [77.46917724609392, 16.95384025573742],
        [77.4640731811524, 16.952690124511776],
        [77.46465301513678, 16.946371078491268],
        [77.46430206298834, 16.93980026245123],
        [77.46597290039068, 16.934570312500057],
        [77.46354675292997, 16.929040908813477],
        [77.46459960937517, 16.924898147583065],
        [77.46787261962896, 16.924198150634822],
        [77.46774291992216, 16.91711044311529],
        [77.46537017822266, 16.91407966613781],
        [77.46010589599615, 16.912929534912166],
        [77.45339965820318, 16.909032821655387],
        [77.45391845703142, 16.906272888183594],
        [77.4558181762697, 16.904659271240234],
        [77.4542617797851, 16.886129379272575],
        [77.45223999023443, 16.881599426269645],
        [77.45759582519537, 16.879280090332088],
        [77.46031188964872, 16.85453033447277],
        [77.46039581298845, 16.844110488891545],
        [77.4566268920899, 16.82374954223627],
        [77.4594116210937, 16.81776237487793],
        [77.46272277832037, 16.80451965332037],
        [77.46575927734375, 16.800830841064567],
        [77.4717559814456, 16.796289443969727],
        [77.47162628173845, 16.78518104553217],
        [77.47113800048834, 16.779130935668945],
        [77.46733856201195, 16.776599884033317],
        [77.44454193115251, 16.780042648315487],
        [77.43753814697266, 16.783210754394645],
        [77.43527984619146, 16.777620315551758],
        [77.43721008300787, 16.763130187988338],
        [77.43534088134794, 16.749549865722713],
        [77.43128967285179, 16.73644065856928],
        [77.4278411865235, 16.729482650756893],
        [77.42569732666016, 16.727060317993164],
        [77.42633056640648, 16.722749710083065],
        [77.4275512695312, 16.721139907836914],
        [77.42672729492193, 16.71837997436529],
        [77.42413330078142, 16.717800140380916],
        [77.42341613769531, 16.714530944824332],
        [77.42543029785185, 16.7117595672608],
        [77.4300079345706, 16.711700439453068],
        [77.43325805664074, 16.70911026000988],
        [77.43737030029303, 16.70754814147955],
        [77.44541168212896, 16.713800430297965],
        [77.4484558105471, 16.713579177856502],
        [77.45627593994163, 16.710689544677734],
        [77.46103668212913, 16.71316146850586],
        [77.46679687500028, 16.711940765380973],
        [77.46877288818376, 16.708709716796875],
        [77.46746063232439, 16.702379226684684],
        [77.46750640869146, 16.696800231933707],
        [77.46504211425804, 16.67828178405756],
        [77.46170043945307, 16.672359466552734],
        [77.44642639160168, 16.674160003662166],
        [77.4371566772461, 16.66658020019537],
        [77.41657257080084, 16.662811279296932],
        [77.41684722900419, 16.654750823974723],
        [77.42119598388678, 16.65199089050293],
        [77.42809295654325, 16.65007972717291],
        [77.43026733398455, 16.644729614257926],
        [77.43247222900419, 16.643060684204215],
        [77.43622589111334, 16.642530441284237],
        [77.43293762207048, 16.637638092041016],
        [77.43836975097685, 16.63620185852062],
        [77.4440612792971, 16.63579177856451],
        [77.44851684570324, 16.638830184936523],
        [77.45478057861357, 16.64487075805664],
        [77.4614181518557, 16.648830413818473],
        [77.46939086914062, 16.64691162109375],
        [77.47133636474632, 16.643531799316463],
        [77.46586608886747, 16.635940551757926],
        [77.46247100830095, 16.62920951843273],
        [77.46132659912126, 16.62478065490717],
        [77.45556640625028, 16.620010375976562],
        [77.45382690429716, 16.613798141479435],
        [77.45388031005854, 16.607419967651424],
        [77.4636306762697, 16.604810714721793],
        [77.46467590332037, 16.597440719604492],
        [77.46420288085955, 16.595140457153377],
        [77.46034240722668, 16.595550537109318],
        [77.45877838134794, 16.59205055236822],
        [77.46134185791033, 16.590658187866268],
        [77.46418762207048, 16.5871906280517],
        [77.4641723632812, 16.584209442138672],
        [77.46038055419939, 16.58346939086914],
        [77.45639038085955, 16.58099937438959],
        [77.4432601928711, 16.58205986022955],
        [77.43672943115251, 16.581209182739315],
        [77.4209594726563, 16.56517028808605],
        [77.41728973388689, 16.563909530639705],
        [77.40213012695341, 16.566291809082088],
        [77.39753723144537, 16.563350677490348],
        [77.3914413452149, 16.565200805664176],
        [77.39029693603521, 16.559749603271598],
        [77.39261627197294, 16.55945014953619],
        [77.39480590820312, 16.558240890502987],
        [77.39653778076178, 16.555540084838924],
        [77.39705657959001, 16.55289268493658],
        [77.40043640136747, 16.550230026245174],
        [77.40157318115263, 16.546779632568473],
        [77.41565704345709, 16.54400062561041],
        [77.41532135009794, 16.517829895019588],
        [77.41197967529297, 16.513811111450195],
        [77.40061187744169, 16.515951156616154],
        [77.39351654052757, 16.514860153198242],
        [77.38871002197266, 16.512109756469727],
        [77.381156921387, 16.511539459228516],
        [77.3790817260745, 16.510721206664982],
        [77.37290191650396, 16.506019592285213],
        [77.36908721923834, 16.502109527587947],
        [77.36730957031267, 16.493770599365348],
        [77.367172241211, 16.486930847168026],
        [77.3662872314456, 16.484109878540096],
        [77.35816955566423, 16.483150482177734],
        [77.3516311645509, 16.48332023620617],
        [77.34790039062506, 16.48551750183111],
        [77.34362030029308, 16.484880447387752],
        [77.3436584472658, 16.482059478759822],
        [77.3299560546875, 16.480691909790096],
        [77.32440948486345, 16.480930328369197],
        [77.31362152099615, 16.48755073547369],
        [77.30909729003918, 16.489339828491268],
        [77.29664611816412, 16.491470336914062],
        [77.29688262939464, 16.48588943481451],
        [77.29580688476562, 16.48134040832514],
        [77.29199981689453, 16.4736385345459],
        [77.2853622436524, 16.471511840820426],
        [77.25151062011713, 16.46790122985834],
        [77.24452209472662, 16.472219467163143],
        [77.23886108398466, 16.472909927368278],
        [77.23517608642584, 16.46808052062994],
        [77.252456665039, 16.45329093933111],
        [77.2582092285158, 16.447309494018555],
        [77.26438903808616, 16.430051803588924],
        [77.26925659179705, 16.42308044433605],
        [77.27475738525402, 16.417739868164176],
        [77.27820587158197, 16.410709381103572],
        [77.28466033935575, 16.402999877929744],
        [77.29457855224638, 16.39960289001459],
        [77.30696868896513, 16.394020080566463],
        [77.33273315429716, 16.3882999420166],
        [77.34294891357428, 16.379720687866325],
        [77.35253906250028, 16.376609802246094],
        [77.37319946289057, 16.379920959472713],
        [77.38813781738281, 16.371799468994197],
        [77.40112304687506, 16.36602973937994],
        [77.41174316406267, 16.362680435180778],
        [77.42220306396496, 16.36284255981451],
        [77.42778015136736, 16.365079879760685],
        [77.43519592285173, 16.37267112731945],
        [77.4381103515625, 16.374429702758846],
        [77.44896697998047, 16.375801086425895],
        [77.46131134033197, 16.37544059753418],
        [77.48137664794939, 16.377769470214844],
        [77.49449157714855, 16.375789642333984],
        [77.5101470947265, 16.370180130004883],
        [77.51885986328153, 16.37010955810547],
        [77.5286331176759, 16.3583602905274],
        [77.53515625000017, 16.352939605712947],
        [77.54432678222673, 16.349758148193473],
        [77.54949188232422, 16.344680786132926],
        [77.55452728271513, 16.341560363769588],
        [77.57184600830084, 16.33595085144043],
        [77.586441040039, 16.33793067932129],
        [77.59160614013689, 16.337511062622184],
        [77.58438873291016, 16.328041076660213],
        [77.58663940429705, 16.320449829101676],
        [77.59084320068376, 16.3119792938233],
        [77.59099578857433, 16.304611206054744],
        [77.58835601806658, 16.295650482177734],
        [77.59227752685553, 16.29010963439947],
        [77.59030151367188, 16.28685188293457],
        [77.58068084716825, 16.286060333252067],
        [77.57434082031273, 16.288940429687557],
        [77.5692596435548, 16.29477119445812],
        [77.56498718261741, 16.297710418701115],
        [77.54950714111334, 16.290670394897575],
        [77.54321289062506, 16.287059783935547],
        [77.53975677490234, 16.28263282775879],
        [77.53378295898449, 16.262630462646484],
        [77.52581787109392, 16.267021179199276],
        [77.52442169189482, 16.2697811126709],
        [77.51933288574236, 16.270881652832145],
        [77.51309204101568, 16.270719528198242],
        [77.50122833251953, 16.267810821533203],
        [77.50020599365246, 16.26068115234375],
        [77.49897003173834, 16.25786018371582],
        [77.48755645751976, 16.24982070922863],
        [77.48890686035162, 16.241830825805664],
        [77.48876190185558, 16.22434997558605],
        [77.49362182617193, 16.223020553588867],
        [77.50222778320318, 16.22478103637701],
        [77.51075744628923, 16.223339080810547],
        [77.5111694335938, 16.217189788818473],
        [77.50878143310575, 16.214942932128963],
        [77.50232696533197, 16.215700149536133],
        [77.49532318115234, 16.213001251220817],
        [77.49369812011747, 16.2061710357666],
        [77.49494171142578, 16.19535064697277],
        [77.49491882324236, 16.187120437622127],
        [77.48837280273466, 16.178680419921932],
        [77.48362731933588, 16.15855026245123],
        [77.48680877685553, 16.153078079223576],
        [77.4880981445313, 16.147390365600643],
        [77.4885864257812, 16.126461029052734],
        [77.48667144775402, 16.115760803222713],
        [77.4902267456057, 16.11615943908697],
        [77.49543762207043, 16.11465072631836],
        [77.5027160644533, 16.10947036743164],
        [77.50939941406267, 16.10353088378912],
        [77.50347137451166, 16.101520538330135],
        [77.49788665771513, 16.097219467163143],
        [77.4928894042971, 16.08377075195324],
        [77.49301910400408, 16.079629898071403],
        [77.5029373168947, 16.07323265075695],
        [77.49952697753906, 16.05426025390625],
        [77.49621582031244, 16.05477905273449],
        [77.49447631835966, 16.049779891967887],
        [77.49566650390642, 16.047710418701172],
        [77.49072265625017, 16.030630111694393],
        [77.49207305908203, 16.025569915771484],
        [77.49881744384771, 16.017160415649414],
        [77.49857330322266, 16.013891220092887],
        [77.50028228759766, 16.011350631713924],
        [77.50168609619146, 16.005540847778377],
        [77.51010894775419, 16.002250671386776],
        [77.50839996337919, 15.995829582214412],
        [77.50549316406256, 15.978761672973576],
        [77.50551605224638, 15.972668647766227],
        [77.50901031494158, 15.967440605163631],
        [77.51487731933622, 15.963151931762695],
        [77.5158996582033, 15.9574613571167],
        [77.51457977294922, 15.943429946899528],
        [77.50945281982428, 15.920860290527457],
        [77.49732208251982, 15.926300048828125],
        [77.48332214355474, 15.928059577942008],
        [77.46697998046903, 15.927580833435059],
        [77.45250701904325, 15.931520462036133],
        [77.4364776611331, 15.939031600952148],
        [77.4264221191408, 15.942278861999569],
        [77.42193603515648, 15.941299438476676],
        [77.41644287109403, 15.937960624694767],
        [77.40763092041038, 15.935651779174862],
        [77.40003204345732, 15.935460090637264],
        [77.3927001953125, 15.936761856079215],
        [77.38559722900385, 15.934451103210506],
        [77.37622070312528, 15.93325042724615],
        [77.36814117431646, 15.936040878295955],
        [77.3598709106447, 15.941550254821777],
        [77.35360717773438, 15.943701744079647],
        [77.3351669311524, 15.943270683288688],
        [77.31478881835966, 15.9451904296875],
        [77.30021667480474, 15.950499534606934],
        [77.29178619384783, 15.949969291686955],
        [77.28244781494158, 15.9473619461059],
        [77.26317596435564, 15.95138931274414],
        [77.2581329345706, 15.95397090911871],
        [77.24891662597662, 15.957740783691406],
        [77.24405670166027, 15.956761360168457],
        [77.2360916137697, 15.952711105346623],
        [77.22711944580095, 15.951609611511287],
        [77.21866607666044, 15.951642036438045],
        [77.20295715332037, 15.94987964630127],
        [77.18782806396501, 15.950710296630973],
        [77.1806259155274, 15.95006275177002],
        [77.17070007324219, 15.945549964904728],
        [77.15712738037126, 15.94448089599615],
        [77.14343261718756, 15.94060039520275],
        [77.13677215576178, 15.936219215393066],
        [77.12993621826178, 15.930391311645565],
        [77.12065887451166, 15.928820610046387],
        [77.10460662841825, 15.924531936645621],
        [77.0900573730471, 15.917869567871037],
        [77.0767974853515, 15.90974044799816],
        [77.06595611572283, 15.899311065673828],
        [77.05821228027366, 15.887161254882756],
        [77.0524520874024, 15.87628269195568],
        [77.04104614257835, 15.863959312439022],
        [77.033187866211, 15.853080749511719],
        [77.02902984619163, 15.84490108489996],
        [77.02227020263689, 15.831780433654842],
        [77.02442169189459, 15.829489707946891],
        [77.02815246582026, 15.825590133667049],
        [77.03388214111322, 15.824399948120117],
        [77.03836822509788, 15.825510025024471],
        [77.04154205322283, 15.821660995483512],
        [77.04991912841814, 15.820142745971793],
        [77.05226135253912, 15.82433891296398],
        [77.06388854980469, 15.823169708251896],
        [77.06151580810558, 15.79792118072504],
        [77.06723785400402, 15.796050071716365],
        [77.06247711181652, 15.778390884399471],
        [77.0597686767581, 15.778840065002385],
        [77.05660247802751, 15.7722806930542],
        [77.05372619628923, 15.763649940490723],
        [77.04972076416033, 15.76410102844244],
        [77.04850769042997, 15.760760307311955],
        [77.05316925048845, 15.758879661560115],
        [77.05171966552757, 15.750129699707088],
        [77.04669189453142, 15.75133132934576],
        [77.043228149414, 15.734481811523494],
        [77.0472488403322, 15.733171463012752],
        [77.04708862304688, 15.725011825561523],
        [77.06299591064459, 15.723671913147086],
        [77.0762710571289, 15.721410751342773],
        [77.07625579834001, 15.710371017456112],
        [77.08436584472685, 15.708959579467717],
        [77.0816497802735, 15.695330619812125],
        [77.0779266357423, 15.693361282348746],
        [77.07592773437506, 15.690878868103027],
        [77.1008377075197, 15.678879737854118],
        [77.1104660034182, 15.671262741088867],
        [77.11905670166038, 15.662089347839412],
        [77.12497711181646, 15.659050941467228],
        [77.12092590332037, 15.637201309204102],
        [77.11620330810553, 15.6251096725465],
        [77.10389709472673, 15.633931159973258],
        [77.10056304931646, 15.639161109924373],
        [77.09593200683594, 15.64414978027338],
        [77.08889770507818, 15.647521018982047],
        [77.08143615722656, 15.652840614318904],
        [77.06887054443365, 15.640909194946403],
        [77.03601074218767, 15.632371902465763],
        [77.03266143798834, 15.632008552551326],
        [77.02928161621105, 15.63383865356451],
        [77.02710723876959, 15.629349708557129],
        [77.02839660644531, 15.606849670410213],
        [77.02889251709013, 15.598259925842285],
        [77.02443695068365, 15.58436203002924],
        [77.02371215820341, 15.57638168334961],
        [77.028861999512, 15.525121688842773],
        [77.02874755859392, 15.508560180664062],
        [77.02659606933622, 15.499990463256893],
        [77.00543212890653, 15.502771377563533],
        [76.99603271484398, 15.499731063842717],
        [76.99085235595709, 15.501070976257381],
        [76.98165130615251, 15.504770278930607],
        [76.97189331054682, 15.506331443786678],
        [76.97013854980497, 15.494951248169002],
        [76.97039031982439, 15.490921974182186],
        [76.97608184814447, 15.482012748718319],
        [76.98898315429693, 15.473151206970272],
        [76.99600219726568, 15.467459678650016],
        [77.00074005126953, 15.461701393127441],
        [77.00203704834001, 15.455381393432674],
        [77.00609588623047, 15.448230743408203],
        [77.01528167724615, 15.441069602966309],
        [77.02384185791021, 15.438561439514103],
        [77.02739715576166, 15.4351806640625],
        [77.03048706054705, 15.426330566406193],
        [77.03100585937511, 15.418339729309139],
        [77.0268630981447, 15.407752037048397],
        [77.02854156494135, 15.401302337646484],
        [77.033256530762, 15.396260261535701],
        [77.04100799560553, 15.391510009765682],
        [77.04382324218767, 15.383741378784293],
        [77.04228973388689, 15.374799728393498],
        [77.03803253173857, 15.358781814575252],
        [77.04260253906278, 15.352240562439022],
        [77.057846069336, 15.343190193176326],
        [77.0628967285158, 15.337909698486442],
        [77.06314849853521, 15.328080177307243],
        [77.06157684326189, 15.321860313415527],
        [77.06909179687506, 15.319471359252873],
        [77.09929656982416, 15.326621055603027],
        [77.10968780517584, 15.327281951904297],
        [77.11023712158203, 15.320730209350643],
        [77.11342620849626, 15.319120407104492],
        [77.1154174804688, 15.289850234985352],
        [77.12374877929705, 15.287569999694824],
        [77.13883972167969, 15.285479545593262],
        [77.14791107177763, 15.285670280456543],
        [77.14872741699236, 15.277850151062012],
        [77.1480331420899, 15.270951271057129],
        [77.14491271972673, 15.269280433654842],
        [77.14517211914068, 15.266051292419547],
        [77.14985656738298, 15.26693058013916],
        [77.15766906738276, 15.260379791259822],
        [77.16414642333979, 15.25907039642334],
        [77.16525268554716, 15.250971794128418],
        [77.15793609619146, 15.251350402832088],
        [77.14668273925787, 15.250290870666618],
        [77.14025115966825, 15.251550674438477],
        [77.13265991210949, 15.234609603881779],
        [77.12345886230474, 15.233320236206055],
        [77.12090301513672, 15.221240997314453],
        [77.14138031005865, 15.218132019043026],
        [77.1470794677735, 15.208809852600154],
        [77.1499633789063, 15.207901000976676],
        [77.15577697753923, 15.194339752197266],
        [77.15815734863304, 15.193819999694881],
        [77.16153717041016, 15.182210922241211],
        [77.1644821166995, 15.16847038269043],
        [77.16320037841791, 15.16133117675787],
        [77.15879058837902, 15.157869338989258],
        [77.1592864990235, 15.152120590210018],
        [77.15770721435564, 15.148950576782283],
        [77.15305328369169, 15.148820877075309],
        [77.14549255371111, 15.150700569152832],
        [77.13697052001959, 15.149251937866268],
        [77.13208007812528, 15.142681121826172],
        [77.13536071777361, 15.136010169983024],
        [77.15276336669922, 15.131621360778752],
        [77.15403747558588, 15.120520591735954],
        [77.14771270751982, 15.107330322265682],
        [77.13938903808611, 15.09988117218029],
        [77.12760925292974, 15.093641281127873],
        [77.12552642822271, 15.068209648132267],
        [77.12091064453125, 15.055070877075195],
        [77.11839294433611, 15.050630569457951],
        [77.11606597900402, 15.040102005004996],
        [77.11161041259788, 15.038129806518668],
        [77.1095733642581, 15.028569221496696],
        [77.10167694091803, 15.027340888977108],
        [77.09078216552734, 15.02772235870367],
        [77.08825683593767, 15.02303791046154],
        [77.08206939697271, 15.022290229797306],
        [77.08049774169928, 15.00791072845459],
        [77.0788192749024, 15.000009536743221],
        [77.06858825683588, 15.001110076904297],
        [77.06147003173834, 15.001549720764274],
        [77.05092620849615, 15.004289627075138],
        [77.04100799560553, 15.005481719970646],
        [77.04638671875023, 15.01601791381836],
        [77.04878997802751, 15.023860931396484],
        [77.0468826293947, 15.029211997985783],
        [77.03745269775419, 15.027570724487362],
        [77.02295684814482, 15.02765083312994],
        [77.00929260253923, 15.029581069946346],
        [77.0006790161134, 15.029589653015194],
        [76.99862670898455, 15.0270614624024],
        [76.99565124511741, 15.023680686950627],
        [76.98351287841808, 15.020421981811523],
        [76.98178100585966, 15.010360717773438],
        [76.966796875, 15.01316928863531],
        [76.95066833496122, 15.024431228637752],
        [76.94274902343767, 15.027030944824276],
        [76.9316711425783, 15.026310920715332],
        [76.91935729980491, 15.028521537780875],
        [76.91352081298822, 15.030950546264648],
        [76.90544128417974, 15.029290199279785],
        [76.89620971679705, 15.031881332397461],
        [76.88868713378935, 15.03175067901617],
        [76.87670135498058, 15.028678894042969],
        [76.86975097656256, 15.044019699096793],
        [76.864067077637, 15.050000190734863],
        [76.86057281494146, 15.05671024322504],
        [76.84320831298828, 15.066159248351994],
        [76.83740997314464, 15.072469711303654],
        [76.82943725585955, 15.07429122924816],
        [76.8212661743164, 15.074851989746094],
        [76.81188201904314, 15.080230712890682],
        [76.79693603515653, 15.090760231018123],
        [76.78756713867193, 15.080439567565918],
        [76.78423309326189, 15.080780029296875],
        [76.78224945068388, 15.078820228576774],
        [76.78391265869146, 15.07567024230957],
        [76.77860260009771, 15.07157039642334],
        [76.77636718750011, 15.073292732238826],
        [76.77422332763678, 15.071380615234318],
        [76.7757415771485, 15.069161415100098],
        [76.77256011962902, 15.065119743347282],
        [76.77082061767607, 15.05828857421875],
        [76.77112579345709, 15.054091453552303],
        [76.77501678466825, 15.043959617614746],
        [76.7807922363283, 15.03423976898199],
        [76.78179168701172, 15.023329734802246],
        [76.78457641601574, 15.016670227050781],
        [76.7797470092774, 15.005889892578182],
        [76.76953125000017, 15.000581741333065],
        [76.76805877685547, 14.995639801025447],
        [76.76622009277372, 14.990811347961369],
        [76.76518249511719, 14.98906135559082],
        [76.76235961914068, 14.989461898803825],
        [76.75923919677751, 14.98507022857666],
        [76.75698852539062, 14.982971191406307],
        [76.75863647460949, 14.979460716247559],
        [76.76185607910185, 14.97528076171875],
        [76.76377105712919, 14.969869613647404],
        [76.77208709716803, 14.968640327453727],
        [76.79688262939459, 14.962921142578125],
        [76.81545257568388, 14.963809967041072],
        [76.82028198242205, 14.9591703414917],
        [76.82364654541044, 14.95361137390148],
        [76.82789611816423, 14.951009750366211],
        [76.83255004882818, 14.95348072052002],
        [76.83587646484386, 14.959080696106014],
        [76.8411560058596, 14.961171150207576],
        [76.8644561767581, 14.964019775390625],
        [76.86367034912115, 14.955210685730037],
        [76.86547851562517, 14.93760013580328],
        [76.8634872436524, 14.915642738342285],
        [76.86389923095732, 14.911741256713867],
        [76.86271667480474, 14.901769638061467],
        [76.86030578613276, 14.891189575195312],
        [76.84613800048857, 14.890331268310547],
        [76.8437728881836, 14.886420249939022],
        [76.84326171875006, 14.881471633911133],
        [76.84905242919916, 14.87993144989025],
        [76.85087585449241, 14.872850418090934],
        [76.85167694091825, 14.862521171569881],
        [76.8541183471682, 14.861371994018555],
        [76.84938812255888, 14.838699340820312],
        [76.84436035156256, 14.833251953125057],
        [76.84008789062494, 14.830040931701717],
        [76.84026336669933, 14.828071594238281],
        [76.84298706054705, 14.824301719665641],
        [76.84445190429716, 14.815590858459586],
        [76.84854125976562, 14.814250946044979],
        [76.84960174560575, 14.811809539794979],
        [76.84873962402344, 14.804031372070312],
        [76.84691619873075, 14.80231094360363],
        [76.83721160888683, 14.804141044616756],
        [76.8349685668947, 14.80013179779047],
        [76.83377075195341, 14.784909248352108],
        [76.83136749267578, 14.784111976623649],
        [76.826416015625, 14.786980628967399],
        [76.82073211669928, 14.78660964965826],
        [76.81735229492216, 14.785440444946346],
        [76.8167114257813, 14.782490730285758],
        [76.81096649169922, 14.781431198120117],
        [76.7803497314456, 14.779261589050293],
        [76.78128814697294, 14.774330139160213],
        [76.78642272949224, 14.761911392211914],
        [76.78813934326178, 14.751840591430721],
        [76.79502868652372, 14.741131782531852],
        [76.79807281494169, 14.734590530395451],
        [76.7855377197265, 14.707091331481934],
        [76.78108215332037, 14.690470695495662],
        [76.77127838134783, 14.673839569091797],
        [76.76950836181652, 14.656311035156364],
        [76.76297760009794, 14.611921310424805],
        [76.76017761230463, 14.602010726928768],
        [76.76091003417997, 14.596281051635742],
        [76.7727508544923, 14.587201118469352],
        [76.77435302734375, 14.590570449829215],
        [76.77628326416021, 14.590330123901367],
        [76.77815246582031, 14.579331398010254],
        [76.78405761718767, 14.561980247497615],
        [76.78617095947266, 14.553059577942008],
        [76.794692993164, 14.540730476379395],
        [76.79972839355474, 14.527731895446891],
        [76.80509185791021, 14.524870872497672],
        [76.81089782714855, 14.523799896240291],
        [76.81973266601562, 14.520339965820256],
        [76.82758331298834, 14.5230588912965],
        [76.828422546387, 14.522240638733024],
        [76.83207702636713, 14.515070915222111],
        [76.84393310546892, 14.509149551391545],
        [76.85421752929705, 14.498880386352539],
        [76.86044311523466, 14.487131118774471],
        [76.86380767822266, 14.474671363830623],
        [76.87020111084013, 14.468440055847225],
        [76.8803024291995, 14.470529556274471],
        [76.88549041748041, 14.47594070434576],
        [76.89427185058611, 14.480751991271973],
        [76.9065170288086, 14.484161376953125],
        [76.94541168212908, 14.48215103149414],
        [76.95580291748053, 14.47958946228033],
        [76.9690933227539, 14.479089736938533],
        [76.97339630126964, 14.478281021118164],
        [76.97419738769548, 14.470360755920524],
        [76.96437072753929, 14.451741218566951],
        [76.95858764648438, 14.445390701294059],
        [76.9437866210938, 14.445101737976074],
        [76.94271087646479, 14.43984127044672],
        [76.944320678711, 14.42523193359375],
        [76.9446792602539, 14.408920288086051],
        [76.94359588623053, 14.403261184692496],
        [76.9415664672851, 14.399541854858512],
        [76.93678283691418, 14.401309967041072],
        [76.91896820068382, 14.41145038604742],
        [76.91310119628935, 14.413552284240666],
        [76.90116119384783, 14.398320198059139],
        [76.89663696289068, 14.394479751587028],
        [76.89232635498075, 14.392870903015137],
        [76.88677978515653, 14.392451286315918],
        [76.88314056396513, 14.390291213989371],
        [76.87861633300776, 14.351392745971737],
        [76.87909698486334, 14.346389770507756],
        [76.88536071777344, 14.33959102630621],
        [76.89897155761747, 14.31924057006836],
        [76.90615844726568, 14.315340995788688],
        [76.91680908203142, 14.312259674072322],
        [76.92314910888678, 14.309650421142692],
        [76.93656158447294, 14.308409690856877],
        [76.94293975830095, 14.305378913879395],
        [76.9467010498048, 14.300449371337947],
        [76.95011138916044, 14.2940292358399],
        [76.94873809814459, 14.286761283874455],
        [76.94375610351568, 14.281681060791016],
        [76.93548583984392, 14.276641845703125],
        [76.92485809326178, 14.272180557251033],
        [76.92582702636724, 14.264399528503532],
        [76.93363189697271, 14.253500938415527],
        [76.9359893798831, 14.248771667480469],
        [76.93784332275396, 14.240120887756404],
        [76.94346618652338, 14.236310005187988],
        [76.95139312744163, 14.234151840210075],
        [76.96157073974621, 14.235901832580623],
        [76.96971893310553, 14.235211372375545],
        [76.97560882568365, 14.237420082092342],
        [76.98248291015648, 14.238151550293026],
        [76.98937988281278, 14.23640060424816],
        [76.9927902221682, 14.232989311218205],
        [76.9945602416995, 14.23556041717535],
        [77.00411987304682, 14.231569290161246],
        [77.0125122070312, 14.235091209411678],
        [77.02458953857422, 14.236680984497127],
        [77.0330429077149, 14.240489959716797],
        [77.04064941406256, 14.24075889587408],
        [77.04962921142601, 14.239542007446346],
        [77.05532073974615, 14.232300758361873],
        [77.06022644042986, 14.224160194396916],
        [77.06761932373058, 14.217860221862907],
        [77.07534027099615, 14.20934963226324],
        [77.08486938476562, 14.208450317382926],
        [77.09522247314459, 14.209480285644531],
        [77.1050796508789, 14.21328163146984],
        [77.10994720459013, 14.221480369567871],
        [77.1104660034182, 14.231980323791447],
        [77.11299896240263, 14.239919662475529],
        [77.11157989501959, 14.248461723327637],
        [77.1155166625976, 14.256819725036678],
        [77.10692596435541, 14.261760711670036],
        [77.1105422973634, 14.274930000305233],
        [77.11192321777361, 14.287530899047852],
        [77.11730957031256, 14.291410446166935],
        [77.12577056884794, 14.301531791687069],
        [77.13092803955107, 14.313810348510799],
        [77.13262939453153, 14.32404994964594],
        [77.13191986083979, 14.33059120178234],
        [77.1355361938476, 14.334012031555233],
        [77.14054107666033, 14.336111068725586],
        [77.14644622802751, 14.332191467285213],
        [77.15215301513689, 14.337740898132324],
        [77.15975952148432, 14.336238861083984],
        [77.16422271728521, 14.330460548400879],
        [77.17188262939476, 14.330230712890625],
        [77.17674255371088, 14.326290130615234],
        [77.17951202392601, 14.326370239257756],
        [77.19635772705107, 14.314299583435172],
        [77.20979309082031, 14.313010215759277],
        [77.22292327880876, 14.314981460571232],
        [77.23195648193365, 14.311070442199707],
        [77.24508666992188, 14.314530372619743],
        [77.25129699707043, 14.320602416992301],
        [77.25785064697266, 14.325701713562012],
        [77.26732635498058, 14.327010154724178],
        [77.27330780029297, 14.33175086975092],
        [77.28114318847662, 14.331110954284725],
        [77.2826766967774, 14.30228042602539],
        [77.28189086914062, 14.290459632873592],
        [77.27958679199236, 14.27608966827404],
        [77.28549957275408, 14.274749755859375],
        [77.29545593261724, 14.276471138000602],
        [77.30748748779303, 14.273839950561637],
        [77.3205871582033, 14.273450851440543],
        [77.3433685302735, 14.27052021026617],
        [77.35340881347656, 14.27067947387701],
        [77.35926055908214, 14.269330024719238],
        [77.35826873779325, 14.260320663452092],
        [77.35923767089872, 14.243600845337028],
        [77.35540771484403, 14.230039596557617],
        [77.36431121826178, 14.223310470581168],
        [77.37661743164074, 14.21671199798584],
        [77.3816223144533, 14.210180282592773],
        [77.38011169433594, 14.202550888061523],
        [77.38378906250023, 14.191821098327637],
        [77.3912200927735, 14.190881729126033],
        [77.41487884521507, 14.20322132110607],
        [77.4157028198245, 14.211961746215934],
        [77.41470336914068, 14.217060089111385],
        [77.40905761718744, 14.223581314087028],
        [77.40618133544933, 14.236710548400993],
        [77.40479278564459, 14.248600959777889],
        [77.40101623535185, 14.260410308837947],
        [77.38986968994146, 14.261389732360897],
        [77.3843383789063, 14.265610694885254],
        [77.38504791259783, 14.272450447082576],
        [77.38120269775385, 14.282991409301758],
        [77.37500762939459, 14.29518890380865],
        [77.3738632202149, 14.305109977722168],
        [77.37882232666021, 14.317079544067326],
        [77.38565826416016, 14.32487010955822],
        [77.39488983154325, 14.329159736633244],
        [77.40928649902361, 14.32710170745844],
        [77.42072296142595, 14.321991920471305],
        [77.42587280273455, 14.320811271667537],
        [77.42752838134794, 14.304849624633903],
        [77.44233703613287, 14.3097505569458],
        [77.44243621826166, 14.290910720825309],
        [77.4436264038086, 14.277550697326774],
        [77.45469665527344, 14.272190093994197],
        [77.46842956542969, 14.279439926147461],
        [77.47912597656256, 14.283740997314396],
        [77.47934722900413, 14.284770965576229],
        [77.484390258789, 14.275259971618652],
        [77.49214172363287, 14.27128124237072],
        [77.49626159667986, 14.27175140380865],
        [77.49936676025396, 14.260510444641113],
        [77.49878692626959, 14.249540328979549],
        [77.49627685546875, 14.240409851074219],
        [77.49008178710966, 14.227630615234375],
        [77.49285125732422, 14.220060348510856],
        [77.49308776855491, 14.213970184326172],
        [77.49971008300781, 14.20446968078619],
        [77.5010833740235, 14.193221092224064],
        [77.5041427612307, 14.180660247802791],
        [77.50942230224626, 14.176950454711914],
        [77.51035308837908, 14.172019958496207],
        [77.50679016113287, 14.164240837097225],
        [77.50267028808622, 14.157120704650936],
        [77.50044250488298, 14.150960922241268],
        [77.48854064941435, 14.153079986572209],
        [77.46333312988304, 14.164230346679801],
        [77.45392608642578, 14.162981033325195],
        [77.42347717285162, 14.165080070495549],
        [77.41631317138689, 14.169301033020133],
        [77.40972137451178, 14.169719696044922],
        [77.39745330810564, 14.166500091552734],
        [77.38971710205084, 14.165260314941406],
        [77.38955688476591, 14.153540611267033],
        [77.38262176513678, 14.147720336914062],
        [77.37873077392572, 14.138279914855957],
        [77.3766860961914, 14.129600524902344],
        [77.36501312255876, 14.127109527588004],
        [77.35485839843778, 14.123749732971248],
        [77.34773254394537, 14.124341011047363],
        [77.34651184082048, 14.117850303650016],
        [77.34651184082048, 14.110440254211426],
        [77.344535827637, 14.096011161804313],
        [77.34851074218773, 14.099261283874625],
        [77.35246276855469, 14.105351448059139],
        [77.35893249511736, 14.108721733093319],
        [77.37325286865234, 14.112580299377385],
        [77.38035583496116, 14.11662960052496],
        [77.38973999023466, 14.118570327758789],
        [77.39444732666021, 14.114641189575252],
        [77.39643096923857, 14.1105699539184],
        [77.3954467773438, 14.103441238403263],
        [77.3856811523438, 14.093461036682243],
        [77.37966156005871, 14.09039115905756],
        [77.37238311767607, 14.071851730346793],
        [77.3613662719726, 14.065951347351074],
        [77.35855865478516, 14.058929443359489],
        [77.35012054443354, 14.053381919860954],
        [77.33796691894531, 14.053831100463867],
        [77.33454895019537, 14.047790527343807],
        [77.33126068115251, 14.03984165191656],
        [77.32618713378929, 14.022939682006893],
        [77.34406280517578, 14.019060134887752],
        [77.35009765625011, 14.016450881958121],
        [77.36232757568354, 14.015990257263297],
        [77.3750762939456, 14.012100219726562],
        [77.38336181640625, 14.007358551025334],
        [77.37988281250017, 13.993281364440918],
        [77.37320709228533, 13.981710433959961],
        [77.37269592285162, 13.975510597229004],
        [77.3756561279298, 13.969111442565975],
        [77.38651275634794, 13.97400188446045],
        [77.41018676757824, 13.976922035217399],
        [77.42050170898432, 13.9768705368042],
        [77.42661285400408, 13.973281860351676],
        [77.43064117431658, 13.968779563903809],
        [77.4309005737307, 13.961991310119686],
        [77.42904663085966, 13.956381797790527],
        [77.42870330810541, 13.943341255187988],
        [77.42257690429699, 13.939001083374023],
        [77.40770721435558, 13.936100006103516],
        [77.40843963623075, 13.921610832214412],
        [77.41015625000028, 13.914601325988826],
        [77.42250061035162, 13.91195011138916],
        [77.42740631103533, 13.909219741821346],
        [77.42562866210949, 13.902051925659237],
        [77.41896820068354, 13.894908905029354],
        [77.40850830078142, 13.888810157775993],
        [77.39705657959001, 13.88054084777832],
        [77.39626312255871, 13.880440711975211],
        [77.39186096191435, 13.879890441894531],
        [77.39041900634766, 13.897689819335994],
        [77.35884857177763, 13.895561218261719],
        [77.3483428955081, 13.896540641784668],
        [77.34590148925793, 13.903330802917537],
        [77.343505859375, 13.905981063842773],
        [77.3426132202149, 13.912241935730037],
        [77.35263824462908, 13.928450584411735],
        [77.3626098632813, 13.942300796508789],
        [77.36200714111328, 13.945468902587947],
        [77.3437423706057, 13.950892448425236],
        [77.34720611572294, 13.955351829528922],
        [77.34693908691406, 13.960589408874625],
        [77.35338592529314, 13.976289749145508],
        [77.35378265380876, 13.982320785522518],
        [77.34567260742216, 13.985580444335938],
        [77.33347320556669, 13.988051414489746],
        [77.33032989501953, 13.990359306335392],
        [77.32977294921875, 13.993351936340389],
        [77.3296661376956, 13.993351936340389],
        [77.33011627197283, 13.994880676269474],
        [77.32409667968773, 14.005310058593864],
        [77.32022094726562, 14.009250640869254],
        [77.31700897216803, 14.015322685241813],
        [77.31648254394537, 14.02145957946783],
        [77.313491821289, 14.024820327758903],
        [77.30355834960949, 14.026729583740291],
        [77.29802703857433, 14.023031234741325],
        [77.29228210449247, 14.014499664306697],
        [77.28518676757835, 14.011529922485352],
        [77.27851104736351, 14.006900787353572],
        [77.27260589599615, 14.008020401001033],
        [77.2742080688476, 14.016181945800895],
        [77.27150726318388, 14.028101921081657],
        [77.26451873779314, 14.026529312133903],
        [77.25575256347656, 14.020958900451717],
        [77.24256896972673, 14.00631141662609],
        [77.22727203369152, 14.007951736450195],
        [77.21884918212919, 14.007910728454647],
        [77.20767974853533, 14.004871368408317],
        [77.19455718994146, 14.002860069274846],
        [77.17138671875011, 14.008599281311149],
        [77.16168975830095, 14.006249427795467],
        [77.16278076171903, 13.997759819030875],
        [77.15815734863304, 13.99351978302002],
        [77.15836334228521, 13.99351978302002],
        [77.15444183349626, 13.990610122680721],
        [77.14733886718778, 13.991621971130371],
        [77.14459991455084, 13.99351978302002],
        [77.14437103271484, 13.99351978302002],
        [77.13949584960966, 13.997190475463924],
        [77.13434600830107, 13.998130798339844],
        [77.13288116455095, 14.004210472106934],
        [77.13403320312517, 14.00967025756836],
        [77.12783050537132, 14.01865005493164],
        [77.12468719482439, 14.03474140167242],
        [77.12110137939453, 14.042180061340389],
        [77.1101531982423, 14.04204082489025],
        [77.1039657592774, 14.040571212768668],
        [77.09665679931658, 14.040700912475643],
        [77.09075927734392, 14.03826045989996],
        [77.07649230957037, 14.038049697875977],
        [77.0695266723634, 14.039441108703556],
        [77.06903076171875, 14.047189712524357],
        [77.0512008666995, 14.049592018127555],
        [77.04322052001947, 14.05167007446289],
        [77.03485870361345, 14.051440238952637],
        [77.02848052978544, 14.051980018615723],
        [77.02369689941412, 14.053258895874023],
        [77.02024078369146, 14.061441421508903],
        [77.02453613281244, 14.077169418335075],
        [77.02398681640642, 14.08337116241455],
        [77.0147094726563, 14.088699340820426],
        [77.01043701171892, 14.092049598693848],
        [77.008316040039, 14.09813117980957],
        [77.01658630371094, 14.10609245300293],
        [77.01703643798857, 14.111950874328613],
        [77.01635742187517, 14.117521286010742],
        [77.02027130126959, 14.122321128845215],
        [77.01920318603533, 14.12529087066656],
        [77.01380157470732, 14.127401351928711],
        [77.01493835449247, 14.132181167602596],
        [77.01600646972656, 14.152690887451286],
        [77.02105712890653, 14.16432952880865],
        [77.0213394165039, 14.168398857116756],
        [77.01982879638672, 14.172701835632267],
        [77.01403808593744, 14.173041343688965],
        [77.00605010986345, 14.17485141754156],
        [76.99439239501947, 14.173749923706055],
        [76.9862670898438, 14.172531127929744],
        [76.9781875610351, 14.172920227050838],
        [76.97276306152366, 14.17428970336914],
        [76.9656829833985, 14.174320220947266],
        [76.95849609375028, 14.175509452819881],
        [76.95378112792986, 14.181030273437443],
        [76.9490814208985, 14.183810234069824],
        [76.94404602050776, 14.188320159912223],
        [76.94164276123053, 14.189311981201286],
        [76.93923950195341, 14.18680858612072],
        [76.94153594970732, 14.178030014038143],
        [76.93924713134794, 14.167710304260197],
        [76.93698120117182, 14.16589164733898],
        [76.92296600341803, 14.163399696350154],
        [76.91718292236345, 14.157670974731445],
        [76.8967590332033, 14.161901473998967],
        [76.89142608642595, 14.1589994430542],
        [76.88919067382818, 14.150878906250114],
        [76.88691711425787, 14.136240005493278],
        [76.88368225097679, 14.13103199005127],
        [76.89090728759771, 14.127840995788688],
        [76.8984680175783, 14.12687969207775],
        [76.91250610351568, 14.122109413146916],
        [76.91712951660168, 14.124480247497615],
        [76.92241668701178, 14.12903976440441],
        [76.92697906494163, 14.127209663391113],
        [76.93739318847673, 14.11515140533453],
        [76.94291687011736, 14.111901283264217],
        [76.94658660888672, 14.102971076965332],
        [76.94822692871105, 14.09658145904541],
        [76.9485473632813, 14.091411590576229],
        [76.94629669189482, 14.085260391235408],
        [76.95143127441435, 14.085371971130428],
        [76.96012115478516, 14.082969665527457],
        [76.96808624267595, 14.079739570617676],
        [76.97184753417997, 14.075711250305176],
        [76.97225189208996, 14.068401336669865],
        [76.97500610351562, 14.065270423889103],
        [76.97421264648455, 14.057709693908691],
        [76.97152709960955, 14.05239105224615],
        [76.94972991943388, 14.04784107208252],
        [76.94328308105463, 14.047219276428223],
        [76.93797302246111, 14.047599792480526],
        [76.93371582031278, 14.040671348571777],
        [76.93109893798845, 14.022731781005916],
        [76.94078063964855, 14.020601272583065],
        [76.94232940673822, 14.01548004150385],
        [76.94474029541021, 14.010680198669377],
        [76.94857025146513, 14.006779670715446],
        [76.96420288085966, 14.002240180969181],
        [76.97518157958984, 13.996589660644474],
        [76.97764587402361, 13.993860244751033],
        [76.97734832763678, 13.993870735168457],
        [76.98501586914091, 13.987520217895508],
        [76.99437713623058, 13.981491088867301],
        [76.99133300781261, 13.975811958313045],
        [76.99019622802729, 13.970279693603572],
        [76.98899841308622, 13.95669174194336],
        [76.99433135986334, 13.95337963104248],
        [76.9950561523438, 13.952721595764274],
        [76.99942016601562, 13.95044040679943],
        [77.00810241699247, 13.947369575500488],
        [77.0139770507813, 13.939481735229606],
        [77.0186004638673, 13.93479156494135],
        [77.03584289550781, 13.925591468811092],
        [77.04128265380888, 13.897399902343693],
        [77.02780914306658, 13.89717006683361],
        [77.01728057861322, 13.893370628356877],
        [77.01661682128912, 13.887729644775504],
        [77.01169586181669, 13.871440887451172],
        [77.01259613037115, 13.867239952087346],
        [77.01230621337902, 13.864090919494629],
        [77.00802612304693, 13.862900733947697],
        [77.00711822509794, 13.854171752929688],
        [77.00598907470732, 13.850090026855582],
        [76.99413299560553, 13.838970184326172],
        [76.98992919921898, 13.839509963989258],
        [76.98625946044922, 13.839229583740291],
        [76.98398590087896, 13.827740669250488],
        [76.97653961181646, 13.821360588073844],
        [76.9715576171875, 13.818861007690487],
        [76.96839141845709, 13.81439018249506],
        [76.96882629394543, 13.810241699218693],
        [76.98002624511724, 13.807191848754996],
        [76.98371887207026, 13.804189682006893],
        [76.98577880859403, 13.793000221252498],
        [76.98484802246105, 13.788701057434139],
        [76.98123168945307, 13.785799980163631],
        [76.984359741211, 13.776570320129508],
        [76.98634338378935, 13.766521453857422],
        [76.98898315429693, 13.760180473327637],
        [76.99012756347662, 13.753089904785156],
        [76.99212646484392, 13.749508857726994],
        [76.99167633056669, 13.745341300964355],
        [76.99259185791038, 13.742550849914608],
        [76.99392700195335, 13.741510391235408],
        [77.00193786621116, 13.736661911010799],
        [77.01142120361328, 13.732721328735352],
        [77.01384735107416, 13.747471809387207],
        [77.0184402465822, 13.761699676513615],
        [77.0198669433596, 13.76986026763916],
        [77.02636718750006, 13.771441459655762],
        [77.03518676757841, 13.769281387329045],
        [77.047348022461, 13.75607967376709],
        [77.0547485351563, 13.744500160217285],
        [77.06004333496111, 13.738861083984489],
        [77.07148742675798, 13.738750457763729],
        [77.08058929443371, 13.73739147186285],
        [77.07927703857416, 13.74602031707775],
        [77.08036041259794, 13.753422737121639],
        [77.0852127075197, 13.754550933837947],
        [77.08577728271501, 13.758111000061092],
        [77.095268249512, 13.760880470275879],
        [77.11094665527361, 13.754620552063102],
        [77.12351989746088, 13.750890731811523],
        [77.13410949707037, 13.753030776977653],
        [77.13751983642584, 13.755652427673454],
        [77.14673614501982, 13.754751205444336],
        [77.15815734863304, 13.754931449890194],
        [77.1665802001956, 13.754669189453182],
        [77.16964721679716, 13.761369705200252],
        [77.1713409423831, 13.772049903869686],
        [77.16918945312506, 13.775560379028434],
        [77.16407012939476, 13.780350685119629],
        [77.15849304199219, 13.787100791931266],
        [77.15599822998047, 13.79331016540533],
        [77.15573883056652, 13.79814243316656],
        [77.15409851074219, 13.80056190490717],
        [77.15632629394526, 13.803759574890194],
        [77.15537261962885, 13.807390213012809],
        [77.15619659423828, 13.811340332031364],
        [77.16194152832037, 13.815461158752441],
        [77.1622467041015, 13.82074069976818],
        [77.15791320800781, 13.825470924377555],
        [77.14789581298857, 13.828850746154842],
        [77.14576721191412, 13.834939956665039],
        [77.1404571533206, 13.837889671325684],
        [77.12526702880876, 13.843010902404842],
        [77.12388610839855, 13.84939002990717],
        [77.13015747070341, 13.84993934631359],
        [77.13938903808611, 13.859040260315055],
        [77.14821624755865, 13.859801292419377],
        [77.16249084472656, 13.856980323791618],
        [77.16491699218744, 13.866218566894588],
        [77.16237640380888, 13.876291275024357],
        [77.16171264648455, 13.882431030273494],
        [77.15702056884771, 13.891301155090332],
        [77.15593719482433, 13.899811744689998],
        [77.15956115722668, 13.90846061706543],
        [77.17006683349604, 13.916341781616268],
        [77.17703247070335, 13.917400360107422],
        [77.18161773681663, 13.910770416259822],
        [77.18221282959013, 13.901300430297852],
        [77.18134307861345, 13.89118957519537],
        [77.17920684814464, 13.883280754089412],
        [77.17959594726557, 13.878331184387207],
        [77.17822265625028, 13.871391296386776],
        [77.17513275146507, 13.86140060424816],
        [77.17880249023466, 13.85984039306635],
        [77.19271087646513, 13.856221199035701],
        [77.19689178466803, 13.859530448913517],
        [77.20095825195307, 13.866519927978572],
        [77.20256805419928, 13.874840736389217],
        [77.20494079589872, 13.881160736083984],
        [77.20884704589844, 13.878431320190543],
        [77.2081222534182, 13.86040115356451],
        [77.21150970458984, 13.861418724060115],
        [77.21700286865251, 13.86926174163824],
        [77.22093200683622, 13.881760597229118],
        [77.22189331054716, 13.88883113861084],
        [77.22547912597679, 13.895469665527457],
        [77.23384094238287, 13.894931793212947],
        [77.24009704589861, 13.894080162048397],
        [77.24156188964872, 13.888851165771541],
        [77.23882293701178, 13.881509780883846],
        [77.23925018310575, 13.873590469360465],
        [77.24073791503929, 13.865881919860954],
        [77.24373626708984, 13.860290527343807],
        [77.25303649902338, 13.856121063232365],
        [77.25044250488287, 13.839470863342285],
        [77.25595092773438, 13.839450836181697],
        [77.27514648437511, 13.842801094055176],
        [77.28292846679716, 13.84097957611084],
        [77.2855224609375, 13.8460311889649],
        [77.28970336914062, 13.85002231597906],
        [77.29602813720709, 13.853561401367188],
        [77.30693054199241, 13.857239723205566],
        [77.31088256835938, 13.851131439209098],
        [77.31616210937494, 13.839731216430664],
        [77.32000732421892, 13.825751304626522],
        [77.32724761962908, 13.82479190826416],
        [77.33468627929705, 13.829420089721793],
        [77.33988189697266, 13.834849357605037],
        [77.34697723388695, 13.839580535888672],
        [77.35220336914074, 13.844381332397461],
        [77.35946655273432, 13.845670700073185],
        [77.36753845214844, 13.843209266662711],
        [77.3731307983399, 13.838191032409668],
        [77.37285614013689, 13.831289291381893],
        [77.38111114501959, 13.831251144409237],
        [77.38983917236328, 13.832929611206055],
        [77.39920043945312, 13.832420349121094],
        [77.40393066406267, 13.83142185211193],
        [77.41873931884794, 13.833530426025447],
        [77.42453002929705, 13.835129737854004],
        [77.42624664306658, 13.829730033874625],
        [77.42472076416033, 13.826689720153809],
        [77.41506195068365, 13.815360069274846],
        [77.41275787353521, 13.811750411987305],
        [77.41368103027344, 13.807380676269474],
        [77.40820312500023, 13.80247020721447],
        [77.40866088867199, 13.799251556396598],
        [77.41158294677734, 13.798891067504883],
        [77.41470336914068, 13.800190925598201],
        [77.41925811767584, 13.799710273742676],
        [77.41954040527338, 13.79580116271967],
        [77.41689300537126, 13.79094028472906],
        [77.42005920410185, 13.788961410522518],
        [77.42865753173857, 13.787989616393986],
        [77.43546295166021, 13.79923057556158],
        [77.44056701660173, 13.802291870117188],
        [77.44676971435575, 13.802029609680176],
        [77.45559692382807, 13.799810409545955],
        [77.4574508666995, 13.797050476074332],
        [77.45273590087885, 13.791319847107047],
        [77.45079040527372, 13.785531044006348],
        [77.45240020751953, 13.780401229858512],
        [77.45395660400396, 13.778041839599666],
        [77.45873260498053, 13.749810218810978],
        [77.46212005615251, 13.749051094055233],
        [77.46261596679716, 13.743578910827637],
        [77.46006011962896, 13.73388957977295],
        [77.45809936523455, 13.730330467224121],
        [77.4580688476562, 13.723038673400936],
        [77.45684814453148, 13.712639808654785],
        [77.45340728759788, 13.70409965515148],
        [77.44600677490263, 13.700639724731445],
        [77.44481658935553, 13.695760726928654],
        [77.44975280761724, 13.675790786743278],
        [77.47648620605491, 13.677599906921387],
        [77.48294067382818, 13.67970180511486],
        [77.48513793945324, 13.686690330505371],
        [77.48352050781256, 13.691700935363883],
        [77.48239898681663, 13.698830604553336],
        [77.479721069336, 13.703210830688533],
        [77.48734283447271, 13.706791877746525],
        [77.50276947021484, 13.704409599304313],
        [77.50679016113287, 13.700470924377441],
        [77.50691986084001, 13.692661285400334],
        [77.50797271728521, 13.688799858093375],
        [77.51232910156256, 13.685680389404354],
        [77.52152252197271, 13.68787002563488],
        [77.5255661010745, 13.697210311889705],
        [77.52783966064482, 13.707031250000114],
        [77.52754211425798, 13.719030380248967],
        [77.52659606933594, 13.725250244140682],
        [77.52268981933622, 13.731071472168082],
        [77.51512145996111, 13.737039566039982],
        [77.51966857910168, 13.74384975433344],
        [77.5295028686524, 13.745850563049316],
        [77.53951263427729, 13.743720054626465],
        [77.541557312012, 13.734931945800781],
        [77.54514312744146, 13.728180885314998],
        [77.54814147949236, 13.720409393310604],
        [77.55607604980497, 13.716460227966365],
        [77.56661987304705, 13.729389190673885],
        [77.57363128662138, 13.72665023803711],
        [77.58154296875, 13.730571746826286],
        [77.586898803711, 13.736789703369197],
        [77.5955963134765, 13.743578910827637],
        [77.60427093505865, 13.742550849914608],
        [77.61564636230486, 13.728361129760685],
        [77.62124633789068, 13.727401733398494],
        [77.62851715087896, 13.729941368103027],
        [77.63356018066423, 13.732839584350643],
        [77.63674926757841, 13.735241889953613],
        [77.63375091552751, 13.741291046142521],
        [77.61264801025396, 13.746870040893498],
        [77.61046600341791, 13.755439758300838],
        [77.61434936523438, 13.760310173034782],
        [77.64908599853521, 13.775839805603141],
        [77.65659332275419, 13.77666091918951],
        [77.6640167236331, 13.775870323181266],
        [77.67022705078125, 13.777499198913517],
        [77.67176818847662, 13.77013111114502],
        [77.66979217529291, 13.762559890747184],
        [77.66371154785173, 13.752260208129996],
        [77.66850280761719, 13.752281188964844],
        [77.67881774902344, 13.755681037902832],
        [77.68467712402361, 13.758621215820312],
        [77.69552612304699, 13.761431694030875],
        [77.70218658447294, 13.730991363525334],
        [77.70736694335932, 13.734180450439567],
        [77.70874786376953, 13.732739448547306],
        [77.71100616455095, 13.73770999908453],
        [77.71346282958979, 13.746150016784725],
        [77.71314239501953, 13.750740051269474],
        [77.71420288085966, 13.760790824890137],
        [77.71275329589844, 13.774709701538029],
        [77.71512603759794, 13.783271789550838],
        [77.71286010742216, 13.790519714355526],
        [77.71849822998053, 13.792721748352108],
        [77.72841644287115, 13.801739692688102],
        [77.73359680175804, 13.80344104766857],
        [77.7410278320313, 13.803791999816951],
        [77.74817657470697, 13.806209564208984],
        [77.7553329467774, 13.807540893554801],
        [77.76921081542969, 13.80854129791254],
        [77.7784881591798, 13.8128919601441],
        [77.78044128417986, 13.816691398620662],
        [77.78026580810564, 13.820020675659237],
        [77.77742767333984, 13.824060440063477],
        [77.77410125732433, 13.831730842590275],
        [77.77603912353533, 13.83591079711914],
        [77.786376953125, 13.83992004394537],
        [77.79453277587885, 13.840959548950309],
        [77.80278015136719, 13.840669631958008],
        [77.80784606933622, 13.846671104431152],
        [77.81080627441406, 13.852111816406307],
        [77.82772064208979, 13.860619544982853],
        [77.81920623779303, 13.866021156311035],
        [77.82724761962913, 13.871600151062012],
        [77.82504272460932, 13.879900932312125],
        [77.82099151611357, 13.889231681823844],
        [77.81468963623075, 13.89111042022705],
        [77.81108093261747, 13.894701957702694],
        [77.807373046875, 13.903229713439998],
        [77.80635833740229, 13.908640861511287],
        [77.80635070800776, 13.917550086975154],
        [77.80848693847673, 13.922589302063045],
        [77.8142318725586, 13.931001663208121],
        [77.81829071044945, 13.934881210327262],
        [77.821586608887, 13.930541038513297],
        [77.83011627197288, 13.927151679992676],
        [77.846000671387, 13.92832088470459],
        [77.85407257080095, 13.927079200744686],
        [77.86242675781244, 13.926800727844295],
        [77.88698577880888, 13.931181907653809],
        [77.88786315917969, 13.927721977233944],
        [77.88732147216803, 13.917740821838436],
        [77.88968658447277, 13.911351203918514],
        [77.89570617675787, 13.907719612121639],
        [77.90045166015625, 13.90234088897705],
        [77.90593719482439, 13.899451255798454],
        [77.91160583496122, 13.897240638732853],
        [77.91980743408232, 13.898701667785645],
        [77.92973327636747, 13.908809661865234],
        [77.93564605712919, 13.917909622192383],
        [77.93885803222685, 13.92461967468273],
        [77.9460220336914, 13.928430557251033],
        [77.94905090332048, 13.926739692687988],
        [77.95075988769548, 13.927920341491756],
        [77.95426940917974, 13.93704986572277],
        [77.95532226562494, 13.941900253295955],
        [77.96127319335955, 13.949971199035588],
        [77.9664306640625, 13.95055198669445],
        [77.97857666015636, 13.947650909423942],
        [77.97795104980474, 13.937620162963867],
        [77.97351074218756, 13.92422962188715],
        [77.97336578369146, 13.919881820678711],
        [77.97460174560564, 13.912251472473145],
        [77.97466278076178, 13.905331611633414],
        [77.97802734375017, 13.89334964752203],
        [77.9716186523438, 13.88858890533453],
        [77.94951629638695, 13.884470939636344],
        [77.9424667358399, 13.880230903625488],
        [77.94184112548828, 13.871050834655819],
        [77.94571685791021, 13.839801788330135],
        [77.94805145263683, 13.829360961914062],
        [77.94814300537115, 13.824801445007267],
        [77.94458007812528, 13.81997108459484],
        [77.96578216552763, 13.820159912109318],
        [77.97038269042963, 13.826642036437988],
        [77.98123931884777, 13.848799705505371],
        [77.98226928710938, 13.856801986694336],
        [77.98516082763678, 13.861669540405387],
        [77.99149322509771, 13.862410545349178],
        [78.00477600097656, 13.861710548400993],
        [78.0103378295899, 13.856490135192928],
        [78.01558685302729, 13.85438156127941],
        [78.03782653808616, 13.852849960327205],
        [78.04133605957026, 13.858051300048828],
        [78.04292297363281, 13.863780975341797],
        [78.04270172119158, 13.873379707336483],
        [78.0411529541015, 13.886729240417537],
        [78.04831695556663, 13.884530067443848],
        [78.05471038818376, 13.879351615905705],
        [78.06034851074224, 13.876781463623047],
        [78.0679626464846, 13.874520301818961],
        [78.07092285156267, 13.871959686279297],
        [78.07491302490229, 13.85491943359375],
        [78.08837890625006, 13.85491943359375],
        [78.0973663330081, 13.852761268615836],
        [78.10604095459001, 13.8535413742066],
        [78.11353302001959, 13.840890884399471],
        [78.11725616455072, 13.821771621704102],
        [78.10765838623075, 13.803600311279354],
        [78.10900115966825, 13.798362731933707],
        [78.11517333984392, 13.787679672241154],
        [78.11823272705095, 13.776900291442871],
        [78.11100769042969, 13.761751174926815],
        [78.10649871826178, 13.756791114807186],
        [78.10119628906278, 13.752989768981877],
        [78.09532165527338, 13.750280380249137],
        [78.08927154541016, 13.743049621582145],
        [78.0849227905274, 13.733491897583008],
        [78.09327697753906, 13.727621078491211],
        [78.09738922119169, 13.723151206970272],
        [78.10044097900419, 13.718011856079102],
        [78.10550689697271, 13.714632034301815],
        [78.10938262939482, 13.710230827331543],
        [78.10988616943365, 13.703951835632324],
        [78.10721588134771, 13.69835186004633],
        [78.10537719726591, 13.684400558471737],
        [78.09986877441435, 13.682561874389762],
        [78.08515167236357, 13.680631637573356],
        [78.06405639648455, 13.688179969787711],
        [78.0626068115235, 13.685600280761776],
        [78.06537628173822, 13.680060386657772],
        [78.0640869140625, 13.674559593200797],
        [78.06092071533209, 13.671770095825309],
        [78.05834197998064, 13.666970252990836],
        [78.06585693359398, 13.66449069976818],
        [78.07280731201166, 13.655171394348145],
        [78.07998657226568, 13.650389671325627],
        [78.0796585083009, 13.644541740417594],
        [78.08072662353521, 13.63534069061285],
        [78.08634948730463, 13.633501052856445],
        [78.09060668945312, 13.633000373840332],
        [78.09390258789085, 13.64026069641119],
        [78.10736083984392, 13.647091865539494],
        [78.11589050292997, 13.64563083648693],
        [78.12322998046875, 13.648900985717773],
        [78.12908935546892, 13.648269653320426],
        [78.14617156982428, 13.649660110473746],
        [78.15576171875011, 13.648710250854492],
        [78.15899658203153, 13.645171165466365],
        [78.16053771972668, 13.623430252075195],
        [78.16555786132812, 13.620970726013297],
        [78.18583679199241, 13.621179580688533],
        [78.19393920898455, 13.616159439086914],
        [78.19536590576195, 13.601550102233944],
        [78.1937408447265, 13.594902038574162],
        [78.18856811523449, 13.588401794433707],
        [78.17946624755876, 13.578941345214844],
        [78.18105316162115, 13.573700904846305],
        [78.18012237548834, 13.564171791076774],
        [78.18161010742193, 13.559150695800838],
        [78.18327331542986, 13.556380271911621],
        [78.18900299072283, 13.556380271911621],
        [78.1958389282226, 13.560681343078556],
        [78.20240020751959, 13.567969322204704],
        [78.20849609375006, 13.581880569458065],
        [78.21479034423834, 13.58010101318365],
        [78.2184066772461, 13.57800197601324],
        [78.22261047363287, 13.5770902633667],
        [78.22769165039057, 13.58722114562994],
        [78.236557006836, 13.584490776061955],
        [78.24967956542969, 13.574300765991211],
        [78.25827026367188, 13.575309753417969],
        [78.26539611816435, 13.57822132110607],
        [78.28058624267578, 13.57767200469965],
        [78.29183959960938, 13.578820228576717],
        [78.31247711181663, 13.583501815795955],
        [78.3228302001956, 13.582990646362418],
        [78.34366607666033, 13.577780723571777],
        [78.36139678955072, 13.578872680664006],
        [78.36709594726574, 13.580300331115723],
        [78.38031768798845, 13.58130931854248],
        [78.3907623291015, 13.578100204467773],
        [78.39389801025396, 13.57065200805664],
        [78.39775848388678, 13.566191673278865],
        [78.38600921630876, 13.543951988220158],
        [78.38546752929705, 13.531669616699276],
        [78.37309265136719, 13.512250900268668],
        [78.37419128417986, 13.505510330200195],
        [78.36958312988293, 13.499930381774902],
        [78.36728668212902, 13.495171546935978],
        [78.36828613281267, 13.489769935607967],
        [78.36744689941435, 13.481970787048283],
        [78.36728668212902, 13.466851234436092],
        [78.36357879638678, 13.459240913391113],
        [78.3625564575197, 13.452071189880371],
        [78.36419677734403, 13.445611953735295],
        [78.36416625976585, 13.437911033630314],
        [78.36463165283197, 13.436850547790584],
        [78.36702728271507, 13.431270599365291],
        [78.36531066894554, 13.42439079284668],
        [78.3673171997072, 13.415000915527457],
        [78.36946105957031, 13.408949851989746],
        [78.37127685546903, 13.39717960357666],
        [78.37127685546903, 13.391000747680778],
        [78.3687133789063, 13.379811286926326],
        [78.36444854736322, 13.370491027832088],
        [78.3598175048831, 13.364321708679313],
        [78.35515594482422, 13.355071067810172],
        [78.35640716552763, 13.346719741821346],
        [78.3583984375, 13.346840858459473],
        [78.36051177978521, 13.346961975097656],
        [78.36402130126982, 13.348301887512264],
        [78.36818695068365, 13.348311424255371],
        [78.37477111816423, 13.346409797668514],
        [78.38366699218778, 13.342350959777832],
        [78.3849105834961, 13.338200569152889],
        [78.39253234863281, 13.335540771484375],
        [78.39737701416021, 13.332331657409611],
        [78.39523315429693, 13.322569847106934],
        [78.39125061035179, 13.32371044158947],
        [78.38838958740257, 13.318451881408748],
        [78.38456726074224, 13.323540687561035],
        [78.37899017333984, 13.324521064758414],
        [78.37538909912115, 13.326401710510254],
        [78.37754058837896, 13.331319808959961],
        [78.37129211425793, 13.332408905029297],
        [78.36791229248064, 13.328489303588924],
        [78.36699676513678, 13.320039749145565],
        [78.37512207031244, 13.31859111785883],
        [78.37914276123064, 13.316879272460938],
        [78.38291168212919, 13.314070701599235],
        [78.39125061035179, 13.30370998382574],
        [78.40206146240251, 13.307379722595272],
        [78.4070663452149, 13.310091972351131],
        [78.41526031494146, 13.317130088806266],
        [78.4207763671875, 13.319091796874943],
        [78.42446899414091, 13.319101333618278],
        [78.42682647705095, 13.318079948425293],
        [78.42906951904325, 13.31711196899414],
        [78.43031311035156, 13.307828903198242],
        [78.43598175048845, 13.298350334167594],
        [78.43785858154308, 13.298760414123535],
        [78.44130706787104, 13.299501419067326],
        [78.44507598876982, 13.301399230957031],
        [78.44790649414085, 13.302819252014217],
        [78.45417785644537, 13.304149627685547],
        [78.46053314209013, 13.307920455932674],
        [78.4664001464846, 13.31012153625494],
        [78.47174835205101, 13.307920455932674],
        [78.47017669677734, 13.293901443481502],
        [78.47255706787138, 13.291131973266602],
        [78.48352813720703, 13.288080215454102],
        [78.49521636962896, 13.279211997985783],
        [78.50115966796903, 13.277211189270133],
        [78.5039291381836, 13.272450447082633],
        [78.50833129882818, 13.269471168518123],
        [78.51197052001959, 13.25995063781744],
        [78.51550292968744, 13.257149696350098],
        [78.52079772949247, 13.25559139251709],
        [78.52229309082054, 13.258111953735295],
        [78.52377319335938, 13.260621070861816],
        [78.52561950683588, 13.269290924072266],
        [78.52876281738287, 13.275861740112362],
        [78.53327178955095, 13.276000976562443],
        [78.53557586669939, 13.276080131530762],
        [78.54361724853521, 13.27323246002203],
        [78.54717254638666, 13.27267074584961],
        [78.55060577392572, 13.276029586792049],
        [78.55323028564482, 13.284620285034237],
        [78.55413818359398, 13.298301696777287],
        [78.55565643310553, 13.299429893493596],
        [78.56009674072294, 13.293349266052246],
        [78.56156158447271, 13.288039207458496],
        [78.56443786621116, 13.283410072326774],
        [78.56716918945341, 13.271771430969238],
        [78.57611083984403, 13.267129898071289],
        [78.5774688720706, 13.26204967498785],
        [78.57215118408197, 13.248600959777946],
        [78.56854248046892, 13.243530273437614],
        [78.56321716308611, 13.238410949706974],
        [78.5598907470706, 13.233371734619084],
        [78.55941009521501, 13.230381965637207],
        [78.55887603759771, 13.227130889892578],
        [78.55719757080101, 13.212020874023494],
        [78.56433105468744, 13.208050727844238],
        [78.5686798095706, 13.204262733459586],
        [78.56588745117205, 13.200889587402344],
        [78.5601272583009, 13.198200225830135],
        [78.55837249755888, 13.192180633544922],
        [78.55326080322277, 13.189241409301871],
        [78.54094696044928, 13.187251091003418],
        [78.54247283935553, 13.180170059204102],
        [78.54688262939459, 13.178620338439941],
        [78.5534973144533, 13.179870605468693],
        [78.5564727783206, 13.179841995239315],
        [78.55940246582048, 13.179800987243766],
        [78.56333923339872, 13.177400588989371],
        [78.5647277832033, 13.174040794372615],
        [78.5656814575197, 13.17175102233898],
        [78.56907653808622, 13.156482696533317],
        [78.5653686523438, 13.156810760498104],
        [78.56125640869169, 13.15570068359375],
        [78.55805969238298, 13.15234184265131],
        [78.546859741211, 13.153430938720817],
        [78.53878021240229, 13.151571273803825],
        [78.53369903564459, 13.146109580993652],
        [78.53269958496111, 13.142091751098746],
        [78.53669738769548, 13.136930465698242],
        [78.53544616699241, 13.13074016571045],
        [78.52143096923845, 13.12905120849615],
        [78.51291656494146, 13.12023067474371],
        [78.50875091552757, 13.119589805603027],
        [78.50869750976562, 13.113269805908203],
        [78.5111389160158, 13.106329917907772],
        [78.5161972045899, 13.100819587707463],
        [78.5223770141601, 13.09966182708746],
        [78.52796173095703, 13.097359657287598],
        [78.51480865478516, 13.091110229492244],
        [78.51380920410173, 13.08468055725109],
        [78.50681304931646, 13.08419132232666],
        [78.50192260742216, 13.081661224365291],
        [78.49958038330084, 13.075821876525936],
        [78.49857330322283, 13.069790840148926],
        [78.50479888916044, 13.059379577636719],
        [78.50457763671903, 13.052762031555119],
        [78.48792266845709, 13.049720764160213],
        [78.48750305175798, 13.04939079284668],
        [78.48108673095726, 13.044389724731502],
        [78.47738647460955, 13.043221473693904],
        [78.4710769653322, 13.043858528137207],
        [78.46704864501964, 13.04137134552002],
        [78.46472167968778, 13.034899711608944],
        [78.46105194091803, 13.032010078430176],
        [78.4550094604495, 13.032590866088867],
        [78.45157623291044, 13.031532287597656],
        [78.45060729980497, 13.028240203857479],
        [78.44991302490251, 13.025811195373535],
        [78.45036315917974, 13.014870643615836],
        [78.45263671875006, 13.008581161499023],
        [78.45231628417997, 13.003760337829704],
        [78.45542144775385, 12.990090370178336],
        [78.46142578125006, 12.979120254516602],
        [78.45939636230497, 12.975751876831055],
        [78.45759582519537, 12.970660209655875],
        [78.4579010009765, 12.963241577148438],
        [78.43358612060553, 12.968779563903865],
        [78.42565155029308, 12.969771385192928],
        [78.42265319824236, 12.968600273132267],
        [78.42191314697266, 12.962748527526855],
        [78.42282104492205, 12.957731246948299],
        [78.42384338378912, 12.954401016235408],
        [78.42735290527372, 12.950531005859432],
        [78.42057800292969, 12.947649955749512],
        [78.41977691650408, 12.946681976318303],
        [78.42459106445312, 12.93820953369135],
        [78.42328643798834, 12.933799743652457],
        [78.42035675048845, 12.928958892822209],
        [78.42135620117193, 12.923529624938965],
        [78.42594909667974, 12.917130470275936],
        [78.43447113037126, 12.908270835876465],
        [78.44123840332037, 12.905632019042969],
        [78.44290161132812, 12.904111862182674],
        [78.44905090332054, 12.896658897399902],
        [78.4561309814456, 12.892752647400016],
        [78.45833587646484, 12.879720687866268],
        [78.4569473266601, 12.866059303283748],
        [78.45423889160185, 12.85316181182867],
        [78.45211791992193, 12.855721473693904],
        [78.45266723632835, 12.865491867065373],
        [78.44644165039074, 12.87473201751709],
        [78.43849182128923, 12.879740715026855],
        [78.43830108642595, 12.88624095916748],
        [78.43570709228521, 12.888859748840389],
        [78.43582916259766, 12.900670051574764],
        [78.42640686035185, 12.9020614624024],
        [78.42489624023449, 12.898170471191406],
        [78.42465972900419, 12.893410682678166],
        [78.41734313964844, 12.889031410217285],
        [78.41152954101591, 12.890899658203068],
        [78.40291595459013, 12.89132118225092],
        [78.39953613281244, 12.896201133728141],
        [78.3883972167971, 12.90552043914795],
        [78.38508605957048, 12.896250724792537],
        [78.38337707519531, 12.895409584045467],
        [78.37834167480497, 12.895191192626896],
        [78.3686828613283, 12.901780128479118],
        [78.36097717285185, 12.91161918640148],
        [78.34542846679688, 12.928060531616211],
        [78.34076690673857, 12.924920082092285],
        [78.34041595458979, 12.921989440917912],
        [78.34398651123075, 12.918091773986873],
        [78.33827209472685, 12.91575145721447],
        [78.33773040771496, 12.91312122344982],
        [78.33833312988298, 12.906499862670842],
        [78.32718658447271, 12.889230728149357],
        [78.3221664428711, 12.889600753784237],
        [78.31645965576178, 12.892610549926871],
        [78.31347656250028, 12.888621330261287],
        [78.3124084472658, 12.88330078125],
        [78.313285827637, 12.87977123260498],
        [78.30996704101568, 12.876482009887695],
        [78.31093597412115, 12.8738307952882],
        [78.31617736816418, 12.872201919555778],
        [78.32141876220709, 12.871659278869572],
        [78.32271575927751, 12.876190185546989],
        [78.3274917602539, 12.874040603637695],
        [78.32271575927751, 12.867851257324219],
        [78.32158660888672, 12.862850189209041],
        [78.31105041503923, 12.865370750427246],
        [78.30409240722679, 12.865760803222656],
        [78.30622100830107, 12.856471061706543],
        [78.30310058593756, 12.847200393676815],
        [78.27568817138678, 12.849160194396916],
        [78.27056884765653, 12.850549697875977],
        [78.26262664794939, 12.856590270996094],
        [78.25814819335966, 12.855629920959416],
        [78.2538223266601, 12.849590301513786],
        [78.24874877929693, 12.849849700927734],
        [78.24465942382807, 12.851100921630973],
        [78.24109649658203, 12.847420692443961],
        [78.24186706542969, 12.812331199645996],
        [78.24021911621122, 12.803141593933162],
        [78.23542785644554, 12.787579536438045],
        [78.22949981689459, 12.762009620666504],
        [78.22512054443382, 12.75493144989025],
        [78.22064971923845, 12.75259017944336],
        [78.21248626708984, 12.755069732666016],
        [78.20233917236345, 12.75507926940918],
        [78.19892120361345, 12.75509071350109],
        [78.19538116455084, 12.757540702819881],
        [78.19024658203153, 12.761079788208008],
        [78.18395996093778, 12.76260852813732],
        [78.17871856689453, 12.759920120239201],
        [78.17530822753912, 12.763311386108398],
        [78.16615295410162, 12.765000343322868],
        [78.16156005859375, 12.762261390686092],
        [78.1581420898438, 12.760220527648926],
        [78.14771270751982, 12.763299942016715],
        [78.14302825927751, 12.76664066314703],
        [78.13649749755871, 12.769160270690918],
        [78.13163757324224, 12.769669532775879],
        [78.12711334228544, 12.770159721374569],
        [78.12066650390636, 12.770011901855469],
        [78.11783599853533, 12.77989196777338],
        [78.114486694336, 12.7870712280274],
        [78.10946655273455, 12.791401863098258],
        [78.10205078125, 12.79339790344244],
        [78.09765625, 12.795929908752385],
        [78.09514617919939, 12.800230979919547],
        [78.08647918701178, 12.803339958190975],
        [78.08246612548834, 12.809151649475098],
        [78.07612609863287, 12.820510864257756],
        [78.07367706298851, 12.821310997009277],
        [78.06594848632807, 12.818930625915641],
        [78.06639862060553, 12.826681137085018],
        [78.06456756591825, 12.830169677734432],
        [78.05139923095732, 12.83230972290039],
        [78.05060577392601, 12.838582038879395],
        [78.0467300415039, 12.842370033264217],
        [78.04202270507835, 12.842101097106934],
        [78.04000854492182, 12.838211059570426],
        [78.0221557617188, 12.841220855712834],
        [78.0195770263673, 12.846209526062012],
        [78.01492309570318, 12.845920562744197],
        [78.01435852050804, 12.842989921569881],
        [78.01685333251959, 12.838360786438102],
        [78.01677703857439, 12.833529472351188],
        [78.00907897949247, 12.833700180053711],
        [78.00711822509788, 12.828201293945312],
        [78.00804901123053, 12.822960853576717],
        [78.00712585449224, 12.820040702819824],
        [78.00382232666016, 12.817949295043945],
        [77.9998703002932, 12.819041252136344],
        [77.99330139160173, 12.813671112060604],
        [78.00329589843773, 12.805830955505371],
        [78.005599975586, 12.80326938629156],
        [78.001480102539, 12.795439720153865],
        [77.98972320556646, 12.79719066619873],
        [77.9860229492187, 12.795301437377873],
        [77.9813995361331, 12.79564094543457],
        [77.98134613037115, 12.810420989990291],
        [77.97457885742182, 12.81444168090826],
        [77.97360229492216, 12.818169593811092],
        [77.97129821777372, 12.82264041900629],
        [77.95746612548828, 12.821530342102108],
        [77.9550170898438, 12.825160026550236],
        [77.95800018310564, 12.8363418579101],
        [77.95522308349615, 12.838709831237736],
        [77.95134735107439, 12.837862014770565],
        [77.94684600830101, 12.838081359863281],
        [77.94856262207054, 12.848119735717717],
        [77.94555664062528, 12.849330902099666],
        [77.93860626220709, 12.849691390991211],
        [77.9334564208985, 12.844780921936035],
        [77.9325561523438, 12.839631080627441],
        [77.93612670898443, 12.833809852600154],
        [77.93990325927751, 12.829348564148063],
        [77.93297576904325, 12.812920570373478],
        [77.92479705810541, 12.813720703125],
        [77.92076110839872, 12.816219329833928],
        [77.91825103759771, 12.819211959838924],
        [77.91047668457043, 12.819710731506404],
        [77.90923309326178, 12.822019577026367],
        [77.91182708740229, 12.832430839538574],
        [77.91487884521479, 12.83899021148693],
        [77.91533660888678, 12.844270706176758],
        [77.918441772461, 12.847360610961971],
        [77.92780303955107, 12.850469589233398],
        [77.92878723144543, 12.854250907898006],
        [77.93105316162138, 12.856210708618278],
        [77.93444824218773, 12.856040000915527],
        [77.93356323242199, 12.861689567565975],
        [77.91967773437517, 12.86349964141857],
        [77.91838073730469, 12.868871688842887],
        [77.92871856689476, 12.871431350708065],
        [77.92900848388689, 12.874770164489803],
        [77.92428588867188, 12.877840042114258],
        [77.91774749755854, 12.878371238708553],
        [77.91436767578125, 12.873541831970215],
        [77.90933227539074, 12.872952461242676],
        [77.90219879150396, 12.873371124267635],
        [77.90052032470732, 12.86788272857666],
        [77.89427947998041, 12.868228912353572],
        [77.8874588012697, 12.866921424865723],
        [77.8822631835937, 12.858399391174373],
        [77.87800598144537, 12.859280586242733],
        [77.86689758300787, 12.85861968994152],
        [77.86219024658232, 12.856920242309627],
        [77.85884857177751, 12.85762023925787],
        [77.85449981689459, 12.856731414794922],
        [77.84944152832043, 12.857860565185547],
        [77.8440704345706, 12.855890274047852],
        [77.83963012695318, 12.855851173400879],
        [77.83644866943388, 12.857630729675293],
        [77.83094024658214, 12.857860565185547],
        [77.82559967041027, 12.858989715576172],
        [77.8222427368164, 12.854619979858342],
        [77.82209014892584, 12.849849700927734],
        [77.8170471191408, 12.848981857299862],
        [77.8136291503908, 12.844011306762809],
        [77.80902862548822, 12.842729568481445],
        [77.80493927001976, 12.842750549316463],
        [77.79981994628935, 12.837841033935547],
        [77.7919616699221, 12.839831352234],
        [77.78948974609398, 12.838770866394043],
        [77.78859710693371, 12.835350036621207],
        [77.78488159179693, 12.833809852600154],
        [77.78356170654303, 12.828250885009709],
        [77.7880325317384, 12.823641777038517],
        [77.7923202514649, 12.821370124817008],
        [77.80101776123041, 12.820809364318904],
        [77.79868316650419, 12.811760902404728],
        [77.8006668090822, 12.805629730224666],
        [77.79876708984392, 12.804919242859],
        [77.79441070556646, 12.805809974670524],
        [77.7913665771485, 12.796319961547965],
        [77.79650115966797, 12.789619445800724],
        [77.79949188232416, 12.786858558654842],
        [77.79791259765653, 12.782920837402457],
        [77.7935333251956, 12.7838716506958],
        [77.78793334960943, 12.781320571899414],
        [77.78304290771513, 12.776120185852108],
        [77.78150939941435, 12.77103137969982],
        [77.77162933349621, 12.759831428527832],
        [77.77163696289057, 12.754777908325195],
        [77.77384948730491, 12.751132011413688],
        [77.77829742431652, 12.747870445251465],
        [77.78497314453136, 12.745051383972111],
        [77.78292083740251, 12.738821983337516],
        [77.77807617187528, 12.733671188354606],
        [77.7669525146485, 12.729630470275879],
        [77.75836944580084, 12.729319572448787],
        [77.75306701660162, 12.725098609924373],
        [77.75166320800781, 12.718400955200195],
        [77.75557708740263, 12.715570449829158],
        [77.76303863525402, 12.714460372924805],
        [77.76152801513666, 12.707710266113338],
        [77.757339477539, 12.703989982605037],
        [77.75598144531261, 12.697050094604606],
        [77.75023651123075, 12.687161445617676],
        [77.7470932006836, 12.686640739441032],
        [77.74001312255876, 12.68855094909668],
        [77.73355865478516, 12.691479682922477],
        [77.72725677490234, 12.682871818542424],
        [77.7308959960938, 12.677970886230526],
        [77.73320007324224, 12.671891212463436],
        [77.73140716552751, 12.663979530334473],
        [77.72321319580095, 12.66321182250988],
        [77.71839904785185, 12.661980628967342],
        [77.71531677246122, 12.663010597229004],
        [77.71412658691412, 12.66539096832281],
        [77.71411132812506, 12.670278549194393],
        [77.7038192749024, 12.67374134063732],
        [77.70178985595732, 12.658249855041504],
        [77.69547271728521, 12.651239395141602],
        [77.68753051757807, 12.651900291442871],
        [77.67835998535168, 12.649020195007381],
        [77.6733016967774, 12.650429725646973],
        [77.6678085327149, 12.650639533996696],
        [77.66738128662115, 12.652830123901367],
        [77.6692962646485, 12.661939620971793],
        [77.66590881347662, 12.664360046386719],
        [77.67030334472685, 12.67123889923107],
        [77.65428924560575, 12.674169540405217],
        [77.63878631591803, 12.6701402664184],
        [77.63294219970732, 12.670130729675293],
        [77.60943603515648, 12.663351058959961],
        [77.59783935546903, 12.65902042388916],
        [77.59033966064459, 12.657541275024414],
        [77.5892791748048, 12.6482515335083],
        [77.58796691894526, 12.642689704894963],
        [77.58937072753912, 12.63664245605463],
        [77.59169006347679, 12.632751464843807],
        [77.5955200195313, 12.63043117523199],
        [77.59484100341814, 12.627109527587947],
        [77.59781646728521, 12.621020317077694],
        [77.59352874755876, 12.6165513992309],
        [77.58539581298851, 12.611930847168026],
        [77.58212280273443, 12.60508918762207],
        [77.58145904541027, 12.584231376648063],
        [77.57422637939447, 12.567231178283805],
        [77.5711975097658, 12.563541412353516],
        [77.57005310058611, 12.557931900024357],
        [77.57235717773455, 12.54042053222662],
        [77.57644653320318, 12.53683090209961],
        [77.57763671875028, 12.523470878601074],
        [77.57982635498047, 12.511481285095329],
        [77.57850646972662, 12.507011413574219],
        [77.59516143798857, 12.503669738769645],
        [77.60520172119146, 12.503330230712947],
        [77.60636901855474, 12.49979114532482],
        [77.60473632812511, 12.494881629943904],
        [77.60177612304693, 12.491661071777457],
        [77.5824966430664, 12.476310729980469],
        [77.5753631591798, 12.468379974365234],
        [77.5966491699221, 12.464610099792537],
        [77.61080169677763, 12.478040695190487],
        [77.61728668212919, 12.485509872436637],
        [77.62520599365234, 12.47726154327404],
        [77.62169647216825, 12.454449653625488],
        [77.61815643310547, 12.443132400512695],
        [77.620101928711, 12.438090324401912],
        [77.61694335937511, 12.424640655517521],
        [77.61712646484386, 12.418661117553711],
        [77.62065124511724, 12.413760185241642],
        [77.62008666992193, 12.408479690551815],
        [77.61582946777361, 12.40488052368164],
        [77.61329650878935, 12.39780044555664],
        [77.6109466552735, 12.385649681091309],
        [77.60987854003918, 12.373649597167969],
        [77.60753631591803, 12.367659568786735],
        [77.60633850097656, 12.360260009765739],
        [77.60382843017595, 12.353751182556266],
        [77.60024261474615, 12.351221084594727],
        [77.5912017822265, 12.349199295044002],
        [77.58616638183616, 12.336301803588924],
        [77.57530975341803, 12.326251029968262],
        [77.56859588623053, 12.324190139770565],
        [77.56603240966791, 12.316700935363883],
        [77.5560989379884, 12.29716014862072],
        [77.54515075683616, 12.29269027709961],
        [77.54270935058622, 12.283711433410588],
        [77.53954315185564, 12.282390594482422],
        [77.52352142334001, 12.281001091003361],
        [77.51834106445307, 12.269530296325684],
        [77.50208282470732, 12.26630020141613],
        [77.49796295166033, 12.268389701843375],
        [77.49318695068376, 12.272790908813477],
        [77.49063110351591, 12.273059844970703],
        [77.48423004150408, 12.26978969573986],
        [77.4784469604495, 12.269651412963867],
        [77.47521209716825, 12.265970230102539],
        [77.4701766967774, 12.261870384216309],
        [77.46723175048822, 12.253182411193904],
        [77.46666717529314, 12.2484712600708],
        [77.46350097656256, 12.244039535522575],
        [77.46295166015648, 12.235771179199219],
        [77.4667663574221, 12.233980178833065],
        [77.47366333007841, 12.224941253662053],
        [77.47229003906273, 12.21726036071783],
        [77.46836090087902, 12.210199356079215],
        [77.46566772460966, 12.20226097106945],
        [77.46923828125023, 12.196511268615723],
        [77.4749374389649, 12.195829391479492],
        [77.47875213623053, 12.193981170654354],
        [77.4824829101562, 12.190119743347225],
        [77.49246215820312, 12.187191963195914],
        [77.51130676269548, 12.183971405029297],
        [77.51979827880865, 12.188661575317326],
        [77.5244522094726, 12.188240051269474],
        [77.52805328369152, 12.190011024475098],
        [77.53102111816412, 12.190140724182243],
        [77.53341674804682, 12.193831443786678],
        [77.53852081298857, 12.194488525390739],
        [77.54667663574241, 12.190370559692326],
        [77.55583190917991, 12.187111854553166],
        [77.56533813476568, 12.190450668335075],
        [77.56955718994146, 12.188071250915527],
        [77.58421325683594, 12.188841819763184],
        [77.59113311767584, 12.190560340881348],
        [77.59659576416016, 12.19530105590826],
        [77.60411834716825, 12.195161819458008],
        [77.61051940917969, 12.191590309143123],
        [77.61582183837908, 12.189781188964844],
        [77.6213684082033, 12.191701889038143],
        [77.62561035156256, 12.189719200134391],
        [77.62890625000006, 12.186321258544979],
        [77.63301086425798, 12.186421394348258],
        [77.64466857910156, 12.188570022583065],
        [77.6554489135745, 12.187749862671012],
        [77.66813659667969, 12.18371963500988],
        [77.67282104492199, 12.181388854980469],
        [77.70125579833979, 12.172599792480412],
        [77.71077728271496, 12.17334079742443],
        [77.71951293945341, 12.17152023315441],
        [77.72635650634788, 12.167320251464844],
        [77.7290802001956, 12.159959793090877],
        [77.73195648193371, 12.154610633850154],
        [77.73906707763689, 12.147859573364258],
        [77.74046325683616, 12.142581939697266],
        [77.74024963378912, 12.129289627075309],
        [77.74421691894548, 12.126691818237362],
        [77.7472915649414, 12.122320175170955],
        [77.75282287597656, 12.117970466613713],
        [77.76700592041044, 12.109140396118164],
        [77.76844787597656, 12.105591773986873],
        [77.76638793945318, 12.100990295410213],
        [77.76280975341825, 12.097481727600098],
        [77.75826263427763, 12.094401359558162],
        [77.75634002685575, 12.09195995330822],
        [77.75052642822266, 12.080510139465389],
        [77.7461013793947, 12.069320678710994],
        [77.73396301269537, 12.060220718383846],
        [77.7203674316408, 12.053352355957145],
        [77.71562194824219, 12.048501968383789],
        [77.71264648437528, 12.0413818359375],
        [77.70722961425781, 12.033281326293945],
        [77.70155334472679, 12.027140617370549],
        [77.69410705566412, 12.015192031860408],
        [77.68945312500017, 12.009949684143066],
        [77.68115234375023, 11.991230964660701],
        [77.68103790283197, 11.991230964660701],
        [77.67719268798857, 11.984271049499512],
        [77.6670532226563, 11.958931922912654],
        [77.66371154785173, 11.952960014343262],
        [77.66471099853521, 11.939700126648006],
        [77.66044616699213, 11.938140869140739],
        [77.65482330322288, 11.937358856201172],
        [77.64675903320312, 11.938711166381779],
        [77.64341735839872, 11.944171905517692],
        [77.63949584960955, 11.942831993103084],
        [77.63672637939459, 11.94026088714594],
        [77.6302185058596, 11.937970161437988],
        [77.6151733398438, 11.934149742126408],
        [77.6037216186524, 11.930269241333008],
        [77.59596252441418, 11.929280281067008],
        [77.591361999512, 11.926330566406364],
        [77.58573150634766, 11.929459571838379],
        [77.57895660400419, 11.928299903869629],
        [77.57328796386736, 11.924011230468864],
        [77.54799652099615, 11.93074989318859],
        [77.53132629394548, 11.92649936676031],
        [77.52365112304716, 11.921481132507324],
        [77.51402282714844, 11.925681114196834],
        [77.51095581054688, 11.925341606140137],
        [77.50063323974626, 11.927230834960994],
        [77.49043273925787, 11.929739952087516],
        [77.48439788818354, 11.93239974975586],
        [77.48282623291044, 11.928691864013786],
        [77.48394012451178, 11.92147159576416],
        [77.48219299316412, 11.912140846252441],
        [77.47949981689453, 11.900370597839412],
        [77.4773330688476, 11.895271301269474],
        [77.47596740722685, 11.889550209045524],
        [77.47721862792974, 11.881779670715389],
        [77.47690582275419, 11.876011848449764],
        [77.47367095947294, 11.877800941467285],
        [77.47043609619169, 11.883610725402832],
        [77.46743011474626, 11.883210182189998],
        [77.46333312988304, 11.887270927429313],
        [77.45392608642578, 11.890550613403263],
        [77.45279693603516, 11.888530731201172],
        [77.45385742187511, 11.881941795349121],
        [77.45302581787132, 11.87589073181158],
        [77.4532928466798, 11.87089061737072],
        [77.45516204834013, 11.867251396179313],
        [77.453872680664, 11.857338905334416],
        [77.4521026611331, 11.853230476379338],
        [77.45252990722668, 11.849100112915153],
        [77.45096588134794, 11.844311714172363],
        [77.45536041259794, 11.842681884765625],
        [77.45629882812528, 11.83912181854248],
        [77.45159149169916, 11.823411941528263],
        [77.446586608887, 11.816260337829704],
        [77.44650268554688, 11.811491966247672],
        [77.44246673584001, 11.806470870971793],
        [77.4408721923831, 11.791341781616268],
        [77.43622589111334, 11.781769752502555],
        [77.43209075927763, 11.778941154480094],
        [77.43028259277344, 11.776671409606934],
        [77.4310531616211, 11.771681785583496],
        [77.42384338378929, 11.766089439392204],
        [77.42070770263689, 11.760291099548397],
        [77.4192504882813, 11.750671386718864],
        [77.41349029541016, 11.751152038574276],
        [77.4125061035158, 11.756129264831543],
        [77.41246795654308, 11.762281417846623],
        [77.406547546387, 11.762339591980094],
        [77.40145111084001, 11.764031410217399],
        [77.3818969726563, 11.776161193847713],
        [77.36223602294945, 11.775800704956168],
        [77.35948944091814, 11.778791427612362],
        [77.35515594482428, 11.778371810913143],
        [77.3509292602539, 11.7685804367066],
        [77.34565734863287, 11.764069557189885],
        [77.32611846923845, 11.758939743042049],
        [77.31356048583984, 11.769980430603027],
        [77.30623626708996, 11.778701782226676],
        [77.29045104980486, 11.794160842895565],
        [77.28481292724615, 11.798361778259277],
        [77.28235626220732, 11.79286003112793],
        [77.27829742431646, 11.789739608764592],
        [77.27227783203136, 11.791179656982536],
        [77.24848175048845, 11.800860404968319],
        [77.24173736572294, 11.802459716796932],
        [77.24125671386719, 11.793999671935978],
        [77.23707580566406, 11.788191795349235],
        [77.22705078125028, 11.783749580383414],
        [77.21642303466814, 11.784760475158748],
        [77.20392608642601, 11.784470558166447],
        [77.18966674804716, 11.782819747924918],
        [77.1784210205081, 11.779520034790153],
        [77.17230224609403, 11.775730133056754],
        [77.1659164428711, 11.77423000335699],
        [77.15541076660173, 11.774271011352596],
        [77.143798828125, 11.771299362182617],
        [77.13723754882824, 11.771971702575797],
        [77.13459014892572, 11.766410827636776],
        [77.13102722167969, 11.762380599975643],
        [77.12738037109392, 11.760931968689079],
        [77.11972045898455, 11.76338195800787],
        [77.11389160156256, 11.761830329895133],
        [77.1109695434572, 11.763149261474723],
        [77.10282897949224, 11.763119697570858],
        [77.10530090332037, 11.759480476379451],
        [77.10610961914091, 11.756220817565975],
        [77.10573577880888, 11.750640869140739],
        [77.1032333374024, 11.745880126953239],
        [77.10540008544916, 11.73158073425293],
        [77.1083068847658, 11.722430229187069],
        [77.10929870605474, 11.712800979614258],
        [77.10367584228527, 11.71554946899414],
        [77.09626770019548, 11.723120689392147],
        [77.07588958740234, 11.723891258239803],
        [77.07163238525402, 11.725009918213004],
        [77.07385253906273, 11.72954082489025],
        [77.05010986328142, 11.757749557495117],
        [77.05438995361351, 11.764961242675895],
        [77.0618515014649, 11.772061347961483],
        [77.05654144287138, 11.777119636535588],
        [77.05253601074247, 11.779231071472168],
        [77.04569244384777, 11.779159545898438],
        [77.04125976562528, 11.777589797973746],
        [77.02757263183605, 11.761699676513672],
        [77.02233886718756, 11.76470088958746],
        [77.03026580810564, 11.778301239013672],
        [77.0316772460938, 11.78395080566412],
        [77.02767944335943, 11.786689758300895],
        [77.02855682373064, 11.789119720458928],
        [77.022758483887, 11.794981956481934],
        [77.01857757568388, 11.797320365905762],
        [77.01344299316435, 11.799171447753906],
        [77.008842468262, 11.796961784362793],
        [77.00443267822271, 11.803019523620549],
        [76.99035644531278, 11.799319267273006],
        [76.9821166992188, 11.799539566040153],
        [76.97277069091803, 11.801069259643612],
        [76.97341156005854, 11.795111656188908],
        [76.974838256836, 11.791021347046012],
        [76.96539306640636, 11.776479721069393],
        [76.96330261230463, 11.77079963684082],
        [76.95960235595726, 11.765199661254996],
        [76.95316314697294, 11.771269798278752],
        [76.95025634765648, 11.775430679321346],
        [76.94444274902361, 11.781340599060172],
        [76.94032287597662, 11.781020164489746],
        [76.93580627441435, 11.776741981506404],
        [76.93239593505871, 11.777371406555176],
        [76.92666625976574, 11.780890464782772],
        [76.91348266601591, 11.7803697586059],
        [76.906791687012, 11.781231880188045],
        [76.89968872070318, 11.783271789550895],
        [76.89335632324219, 11.777302742004508],
        [76.89150238037132, 11.770112037658805],
        [76.88796997070307, 11.765899658203239],
        [76.88934326171892, 11.760601043701229],
        [76.88851165771513, 11.750432014465275],
        [76.88600921630888, 11.74448108673107],
        [76.88172912597656, 11.730090141296444],
        [76.88086700439482, 11.724591255188045],
        [76.87558746337908, 11.717899322509822],
        [76.86457061767578, 11.710531234741325],
        [76.85356140136724, 11.698781967163086],
        [76.855728149414, 11.694339752197266],
        [76.84910583496094, 11.68721961975092],
        [76.84617614746105, 11.682478904724178],
        [76.8447265625, 11.677371978759766],
        [76.84137725830107, 11.669771194458065],
        [76.8383865356447, 11.664669990539608],
        [76.83566284179716, 11.656070709228572],
        [76.8323059082033, 11.65266132354742],
        [76.83003234863281, 11.649040222167969],
        [76.82847595214861, 11.644109725952205],
        [76.82775115966797, 11.638429641723633],
        [76.82921600341791, 11.634970664978084],
        [76.8296966552735, 11.629341125488395],
        [76.83309173583984, 11.62529087066656],
        [76.83882141113281, 11.623419761657829],
        [76.83798217773449, 11.618770599365348],
        [76.83825683593756, 11.615841865539608],
        [76.84155273437506, 11.613221168518066],
        [76.84581756591791, 11.611640930175724],
        [76.8458862304688, 11.606051445007324],
        [76.8509063720706, 11.60182952880865],
        [76.84733581542963, 11.588640213012695],
        [76.8437576293947, 11.586030960083065],
        [76.8372802734375, 11.574480056762809],
        [76.83596801757818, 11.584030151367188],
        [76.83349609375006, 11.587031364440975],
        [76.82993316650419, 11.589820861816463],
        [76.82360839843773, 11.59130001068121],
        [76.8165130615235, 11.597209930420036],
        [76.80832672119146, 11.596181869506836],
        [76.79075622558594, 11.599700927734432],
        [76.78082275390625, 11.603570938110408],
        [76.77377319335938, 11.603429794311523],
        [76.75139617919916, 11.606708526611385],
        [76.74626922607439, 11.610541343688965],
        [76.7410430908206, 11.60580921173107],
        [76.73542785644531, 11.605230331420955],
        [76.72020721435553, 11.605050086975098],
        [76.70749664306669, 11.605938911438045],
        [76.69309997558594, 11.604430198669434],
        [76.67904663085943, 11.60414123535162],
        [76.6644363403322, 11.60509967803955],
        [76.63372802734392, 11.603529930114803],
        [76.62565612792997, 11.603818893432617],
        [76.6078720092774, 11.600441932678223],
        [76.60144042968778, 11.601221084594727],
        [76.59671020507807, 11.603380203247127],
        [76.58827972412138, 11.60435962677002],
        [76.58615875244146, 11.607990264892578],
        [76.58096313476568, 11.609400749206657],
        [76.5748291015625, 11.605719566345215],
        [76.56006622314482, 11.61012077331543],
        [76.55362701416033, 11.612919807434082],
        [76.55322265624994, 11.619531631469727],
        [76.54968261718756, 11.626971244811955],
        [76.5470581054688, 11.635770797729606],
        [76.54521942138666, 11.648250579834041],
        [76.54308319091825, 11.656480789184684],
        [76.54300689697271, 11.662969589233398],
        [76.54042053222685, 11.670509338378963],
        [76.53531646728533, 11.677041053772086],
        [76.52468872070318, 11.686010360717773],
        [76.51307678222662, 11.69419193267828],
        [76.5042114257812, 11.698040962219238],
        [76.48781585693376, 11.6854505538941],
        [76.48532104492182, 11.682349205017204],
        [76.47934722900396, 11.682401657104492],
        [76.47377777099615, 11.684220314025936],
        [76.4736785888673, 11.67813968658453],
        [76.466606140137, 11.672321319580135],
        [76.4513626098634, 11.655119895935059],
        [76.43129730224638, 11.65657043457037],
        [76.42346191406256, 11.659320831298885],
        [76.41757965087919, 11.663420677185115],
        [76.41020965576189, 11.664569854736271],
        [76.40740203857428, 11.669530868530387],
        [76.40219879150408, 11.68311977386486],
        [76.40293884277361, 11.699481964111271],
        [76.39640808105497, 11.701079368591252],
        [76.40310668945324, 11.706778526306266],
        [76.40628814697271, 11.703599929809684],
        [76.40867614746111, 11.704149246215934],
        [76.4110870361331, 11.709950447082633],
        [76.41793823242216, 11.713580131530762],
        [76.41889190673857, 11.717081069946346],
        [76.41899108886736, 11.721500396728572],
        [76.4172668457033, 11.725931167602482],
        [76.41197967529303, 11.7342910766601],
        [76.4055862426759, 11.733361244201603],
        [76.4066162109375, 11.740639686584473],
        [76.41084289550787, 11.746481895446777],
        [76.40767669677746, 11.753331184387207],
        [76.40441894531278, 11.749850273132324],
        [76.39071655273432, 11.742869377136287],
        [76.38376617431663, 11.745040893554744],
        [76.37233734130865, 11.736201286315975],
        [76.35604858398455, 11.739911079406852],
        [76.35114288330084, 11.734430313110408],
        [76.34625244140653, 11.730501174926871],
        [76.34091949462902, 11.731100082397518],
        [76.33497619628935, 11.730629920959586],
        [76.32434844970697, 11.738731384277344],
        [76.31758117675804, 11.740371704101676],
        [76.31050109863287, 11.743861198425407],
        [76.30311584472685, 11.769570350647086],
        [76.2999572753908, 11.776151657104606],
        [76.29469299316429, 11.778071403503418],
        [76.2836532592774, 11.784869194030875],
        [76.27509307861357, 11.800760269165039],
        [76.27201080322271, 11.80354022979742],
        [76.26544952392595, 11.800411224365234],
        [76.25312805175787, 11.799799919128418],
        [76.24439239501982, 11.795310020446777],
        [76.23210906982428, 11.796469688415527],
        [76.21852874755876, 11.800039291381779],
        [76.21431732177751, 11.80420970916748],
        [76.21199035644543, 11.81427001953125],
        [76.20551300048822, 11.819581031799316],
        [76.2072067260745, 11.831760406494197],
        [76.20510864257841, 11.83865928649908],
        [76.20342254638666, 11.849061012268123],
        [76.20088958740263, 11.85412979125988],
        [76.19608306884788, 11.855541229248047],
        [76.18403625488287, 11.863761901855582],
        [76.17369079589861, 11.862442016601676],
        [76.16259002685558, 11.860031127929688],
        [76.14942169189464, 11.86101055145258],
        [76.1390228271485, 11.857110977172965],
        [76.12564849853516, 11.844770431518612],
        [76.12043762207043, 11.844290733337516],
        [76.11653137207037, 11.843040466308594],
        [76.1080627441408, 11.851181030273551],
        [76.10932159423834, 11.858590126037654],
        [76.1087493896485, 11.870590209960994],
        [76.10743713378935, 11.87633991241455],
        [76.1086502075197, 11.928090095520076],
        [76.10791015625, 11.970639228820858],
        [76.09127044677729, 11.961588859558105],
        [76.08100128173845, 11.96067047119152],
        [76.07280731201189, 11.961280822753906],
        [76.06674957275396, 11.958271026611442],
        [76.04775238037138, 11.944470405578613],
        [76.04194641113287, 11.943301200866756],
        [76.0385131835938, 11.939000129699764],
        [76.02958679199224, 11.932781219482422],
        [76.02301788330095, 11.934650421142521],
        [76.01843261718767, 11.93502140045166],
        [76.013900756836, 11.933261871338004],
        [76.00396728515625, 11.93192100524908],
        [75.99819183349604, 11.923621177673454],
        [75.9944458007813, 11.923301696777287],
        [75.98336791992188, 11.925441741943473],
        [75.97985839843761, 11.924669265747184],
        [75.97627258300798, 11.924969673156738],
        [75.97264862060564, 11.92755031585699],
        [75.9679183959961, 11.926971435546875],
        [75.95887756347668, 11.928609848022461],
        [75.95490264892595, 11.931510925292912],
        [75.94838714599621, 11.933911323547306],
        [75.93718719482439, 11.932830810546818],
        [75.93112182617193, 11.931110382080135],
        [75.92518615722656, 11.932871818542594],
        [75.91963195800798, 11.935621261596793],
        [75.9154357910158, 11.934029579162598],
        [75.90379333496111, 11.934769630432072],
        [75.89444732666033, 11.936540603637695],
        [75.88968658447283, 11.938140869140739],
        [75.88330078125006, 11.938912391662711],
        [75.87674713134766, 11.940711975097656],
        [75.86589813232428, 11.94563007354742],
        [75.85108947753935, 11.957041740417537],
        [75.84657287597673, 11.96465015411377],
        [75.84443664550793, 11.970270156860295],
        [75.83969116210932, 11.972810745239258],
        [75.8305892944336, 11.974630355834961],
        [75.82697296142578, 11.977971076965275],
        [75.82438659667997, 11.98427963256836],
        [75.8235931396485, 11.99456977844244],
        [75.82375335693365, 11.99456977844244],
        [75.8275222778322, 12.002849578857479],
        [75.8266296386721, 12.005790710449276],
        [75.82353210449219, 12.00933933258051],
        [75.81191253662115, 12.016110420226994],
        [75.81199645996122, 12.021961212158317],
        [75.81019592285162, 12.02406024932867],
        [75.79995727539091, 12.030290603637752],
        [75.79815673828125, 12.032670974731445],
        [75.79857635498053, 12.039669990539608],
        [75.79785919189482, 12.04164123535162],
        [75.79325866699241, 12.04751014709467],
        [75.79618072509777, 12.049490928650016],
        [75.80169677734403, 12.06797885894781],
        [75.7996826171875, 12.071181297302303],
        [75.79534912109398, 12.07423114776617],
        [75.78958129882812, 12.074610710144157],
        [75.78370666503912, 12.07643032073969],
        [75.76788330078148, 12.077520370483512],
        [75.76296234130888, 12.078581809997559],
        [75.75813293457037, 12.07721042633068],
        [75.7519073486331, 12.077409744262809],
        [75.7475891113283, 12.073439598083496],
        [75.74294281005888, 12.071781158447209],
        [75.73890686035162, 12.072181701660213],
        [75.73435974121122, 12.06770133972168],
        [75.72805023193388, 12.064100265502987],
        [75.71705627441423, 12.062089920044002],
        [75.71465301513678, 12.06534194946289],
        [75.71420288085955, 12.069211959838867],
        [75.71753692626976, 12.077450752258358],
        [75.71714782714866, 12.078431129455566],
        [75.70652770996122, 12.082809448242188],
        [75.70066070556658, 12.083530426025447],
        [75.70033264160162, 12.088030815124512],
        [75.7015991210937, 12.100331306457576],
        [75.69196319580084, 12.10258960723877],
        [75.68419647216825, 12.102240562438965],
        [75.68163299560547, 12.096579551696834],
        [75.67830657958996, 12.093061447143555],
        [75.67273712158232, 12.090669631958008],
        [75.66760253906278, 12.090000152587947],
        [75.66242980957037, 12.091180801391715],
        [75.65822601318365, 12.094301223754883],
        [75.65278625488298, 12.101300239563045],
        [75.6515502929688, 12.103961944580135],
        [75.64807891845709, 12.105580329894963],
        [75.64745330810564, 12.114750862121582],
        [75.64298248291044, 12.119930267334041],
        [75.64433288574247, 12.123710632324332],
        [75.64385223388672, 12.127070426940861],
        [75.64250946044939, 12.13077163696289],
        [75.6406860351563, 12.133151054382438],
        [75.63761901855491, 12.13377952575695],
        [75.63678741455095, 12.140420913696289],
        [75.63508605957031, 12.142391204833984],
        [75.6293716430664, 12.141619682312012],
        [75.62686920166033, 12.145050048828182],
        [75.61708831787121, 12.148932456970215],
        [75.611312866211, 12.145861625671444],
        [75.60814666748064, 12.148670196533203],
        [75.60208129882812, 12.14675140380865],
        [75.59760284423857, 12.146529197692928],
        [75.59471893310553, 12.145359992981014],
        [75.59317779541038, 12.14295959472662],
        [75.59144592285162, 12.143049240112305],
        [75.58715057373041, 12.148290634155387],
        [75.58271026611357, 12.150430679321346],
        [75.57684326171886, 12.150750160217285],
        [75.57038879394548, 12.165459632873592],
        [75.56384277343778, 12.166831970214957],
        [75.55961608886724, 12.168670654296932],
        [75.5552520751956, 12.176330566406307],
        [75.55240631103544, 12.176141738891602],
        [75.55113220214861, 12.184440612792969],
        [75.54875183105474, 12.188389778137207],
        [75.5438613891601, 12.19197082519537],
        [75.54036712646513, 12.192370414733887],
        [75.5379180908206, 12.195391654968262],
        [75.5337371826173, 12.196091651916504],
        [75.53298950195307, 12.199090957641658],
        [75.53688049316412, 12.203521728515625],
        [75.53804779052757, 12.206781387329045],
        [75.53793334960949, 12.211051940918026],
        [75.52532958984403, 12.219630241394043],
        [75.52482604980486, 12.225031852722225],
        [75.52336120605491, 12.22887039184576],
        [75.51850128173845, 12.230600357055778],
        [75.51994323730474, 12.233171463012695],
        [75.51928710937517, 12.235939979553166],
        [75.51667022705084, 12.24375057220459],
        [75.51307678222662, 12.249320983886719],
        [75.5114059448245, 12.25043964385992],
        [75.5111083984375, 12.25332069396967],
        [75.50386810302734, 12.2597017288208],
        [75.50392913818365, 12.263159751892033],
        [75.50200653076195, 12.264041900634709],
        [75.4940032958985, 12.265130996704215],
        [75.49252319335966, 12.266819953918514],
        [75.48670196533203, 12.281460762023926],
        [75.4827270507813, 12.286181449890137],
        [75.46720886230486, 12.295379638671989],
        [75.45836639404325, 12.288552284240836],
        [75.44140625000028, 12.290010452270508],
        [75.4360809326173, 12.291810989379996],
        [75.4307327270509, 12.292001724243278],
        [75.42737579345703, 12.290671348571777],
        [75.42366790771513, 12.287039756774902],
        [75.41912841796892, 12.28639984130865],
        [75.42164611816406, 12.290961265564079],
        [75.43039703369158, 12.299521446228084],
        [75.42990875244163, 12.302230834960994],
        [75.42308044433622, 12.30814075469965],
        [75.41996002197271, 12.314460754394645],
        [75.4198532104495, 12.319460868835506],
        [75.41633605957037, 12.32147121429449],
        [75.41436767578142, 12.333750724792537],
        [75.41117858886724, 12.337821960449219],
        [75.41005706787115, 12.341341018676815],
        [75.41427612304693, 12.343930244445858],
        [75.41774749755888, 12.348430633544979],
        [75.41935729980469, 12.35254955291748],
        [75.42143249511736, 12.361901283264217],
        [75.42138671875011, 12.366729736328239],
        [75.41886901855497, 12.369520187378043],
        [75.41146087646513, 12.374570846557617],
        [75.40336608886724, 12.372559547424316],
        [75.40113830566435, 12.375700950622502],
        [75.39711761474615, 12.375241279602164],
        [75.39433288574236, 12.379831314086914],
        [75.39071655273455, 12.383440971374512],
        [75.38578796386719, 12.385589599609489],
        [75.38117218017595, 12.384790420532227],
        [75.37858581542974, 12.386671066284237],
        [75.37616729736357, 12.392461776733342],
        [75.37219238281244, 12.395730018615836],
        [75.36972045898432, 12.401799201965332],
        [75.36560058593756, 12.407040596008358],
        [75.37053680419922, 12.428941726684684],
        [75.36943817138695, 12.445631980896053],
        [75.3658905029298, 12.449080467224235],
        [75.36662292480497, 12.45166015625],
        [75.3858261108399, 12.450700759887809],
        [75.3919296264649, 12.445089340210075],
        [75.40349578857439, 12.44756889343273],
        [75.40908050537115, 12.449850082397575],
        [75.41467285156278, 12.454092025756836],
        [75.41677093505888, 12.458880424499569],
        [75.42250823974638, 12.460061073303166],
        [75.42428588867205, 12.466251373291016],
        [75.42053985595709, 12.471541404724178],
        [75.42018127441429, 12.479661941528377],
        [75.41448974609403, 12.496711730957031],
        [75.40892028808616, 12.494191169738826],
        [75.4042358398437, 12.48960113525385],
        [75.40099334716791, 12.490571975708121],
        [75.39731597900419, 12.497810363769531],
        [75.3949279785158, 12.497559547424373],
        [75.3918762207033, 12.492541313171387],
        [75.38656616210938, 12.491921424865723],
        [75.383071899414, 12.492659568786621],
        [75.3810729980471, 12.491490364074707],
        [75.37950897216797, 12.48787975311285],
        [75.37721252441406, 12.486431121826286],
        [75.37223815917986, 12.485509872436637],
        [75.37000274658209, 12.483469963073787],
        [75.37055206298834, 12.480990409851131],
        [75.37300109863298, 12.477161407470703],
        [75.37206268310547, 12.468781471252555],
        [75.36273956298834, 12.46489143371582],
        [75.3594131469726, 12.459980964660645],
        [75.35563659667986, 12.458021163940543],
        [75.34086608886747, 12.456049919128532],
        [75.33676147460955, 12.457781791687125],
        [75.34053039550776, 12.464631080627385],
        [75.33833312988287, 12.47334194183361],
        [75.33936309814482, 12.479649543762207],
        [75.33898925781261, 12.482940673828125],
        [75.33557891845697, 12.485049247741813],
        [75.3348464965822, 12.490241050720272],
        [75.32805633544945, 12.491770744323674],
        [75.32064056396513, 12.491250991821346],
        [75.31418609619169, 12.492020606994686],
        [75.31009674072271, 12.495820999145565],
        [75.30735015869135, 12.497411727905273],
        [75.30494689941412, 12.496752738952637],
        [75.30430603027361, 12.497511863708553],
        [75.30465698242199, 12.517290115356388],
        [75.30004882812528, 12.516890525817871],
        [75.29611968994158, 12.519659996032829],
        [75.2917709350586, 12.520690917968693],
        [75.28581237792986, 12.519861221313533],
        [75.28114318847662, 12.518139839172306],
        [75.27961730957037, 12.523380279541072],
        [75.27240753173857, 12.539880752563533],
        [75.27091979980463, 12.55354022979742],
        [75.27258300781278, 12.558861732482853],
        [75.27683258056635, 12.56408977508545],
        [75.2834930419923, 12.564171791076774],
        [75.28676605224626, 12.56499004364025],
        [75.29139709472662, 12.574881553649902],
        [75.29496765136736, 12.574929237365723],
        [75.30314636230463, 12.57349967956543],
        [75.30977630615263, 12.571632385253906],
        [75.31619262695318, 12.568661689758414],
        [75.31936645507807, 12.573741912841854],
        [75.32630920410162, 12.574441909790096],
        [75.32846069335966, 12.577111244201774],
        [75.32785797119163, 12.58518123626709],
        [75.31852722167974, 12.585140228271541],
        [75.30825042724638, 12.587869644164982],
        [75.30561828613293, 12.594920158386344],
        [75.3014907836914, 12.59906101226818],
        [75.29833984375028, 12.598410606384334],
        [75.29390716552757, 12.595541954040584],
        [75.29113769531244, 12.59661960601801],
        [75.28885650634783, 12.604580879211426],
        [75.2810363769533, 12.60802936553955],
        [75.27672576904303, 12.611660003662166],
        [75.270317077637, 12.608980178833065],
        [75.26785278320324, 12.605680465698356],
        [75.2621459960937, 12.593001365661621],
        [75.25881958007835, 12.59097003936779],
        [75.2561798095706, 12.585721015930176],
        [75.25019836425787, 12.57918930053711],
        [75.24948883056646, 12.574881553649902],
        [75.24003601074236, 12.573969841003361],
        [75.23867797851557, 12.57250022888195],
        [75.23818969726568, 12.564112663269043],
        [75.23629760742193, 12.561840057373047],
        [75.23129272460938, 12.560821533203182],
        [75.222900390625, 12.562640190124569],
        [75.21691131591825, 12.571070671081657],
        [75.2140426635745, 12.57341194152832],
        [75.2108459472658, 12.583979606628475],
        [75.20752716064459, 12.582990646362418],
        [75.20340728759783, 12.580570220947322],
        [75.20455169677751, 12.586650848388672],
        [75.20749664306646, 12.588501930236873],
        [75.20897674560547, 12.591540336608887],
        [75.20954132080095, 12.597271919250602],
        [75.20729827880865, 12.5999498367309],
        [75.20244598388666, 12.602721214294434],
        [75.19870758056646, 12.606160163879451],
        [75.19946289062506, 12.6131210327149],
        [75.20279693603544, 12.623071670532283],
        [75.20143127441435, 12.625110626220646],
        [75.19438171386747, 12.625431060791072],
        [75.1898574829101, 12.627051353454704],
        [75.18257904052763, 12.627441406250114],
        [75.17748260498064, 12.622209548950138],
        [75.17000579833996, 12.616559982299918],
        [75.16279602050798, 12.617639541625977],
        [75.15917205810564, 12.617050170898438],
        [75.15487670898443, 12.619580268859977],
        [75.14766693115263, 12.62865066528326],
        [75.14296722412126, 12.633310317993164],
        [75.14122009277338, 12.637820243835563],
        [75.15032958984403, 12.641889572143668],
        [75.1512374877932, 12.643440246582088],
        [75.15164947509794, 12.649360656738338],
        [75.155082702637, 12.653679847717285],
        [75.16129302978516, 12.664071083068904],
        [75.16113281250006, 12.665679931640625],
        [75.15571594238276, 12.666030883789006],
        [75.15069580078153, 12.668858528137207],
        [75.15064239501959, 12.676342010498104],
        [75.1445083618164, 12.672900199890194],
        [75.13919830322271, 12.672750473022518],
        [75.13667297363298, 12.671171188354492],
        [75.13188934326189, 12.670840263366813],
        [75.1268997192384, 12.673311233520451],
        [75.12078857421886, 12.67356967926031],
        [75.1126708984375, 12.675910949707145],
        [75.10865783691406, 12.684941291809139],
        [75.10441589355486, 12.691551208496207],
        [75.0992584228515, 12.69335079193121],
        [75.09163665771501, 12.694711685180664],
        [75.0860977172851, 12.69778156280529],
        [75.08242797851591, 12.693229675293026],
        [75.08497619628923, 12.68968200683588],
        [75.08743286132807, 12.675960540771541],
        [75.0867004394533, 12.670850753784237],
        [75.08490753173857, 12.670479774475098],
        [75.08309173583984, 12.666408538818416],
        [75.07942199707026, 12.666580200195426],
        [75.07520294189464, 12.665431976318416],
        [75.07026672363276, 12.658020019531193],
        [75.06600952148466, 12.657952308654842],
        [75.06089019775419, 12.661481857299862],
        [75.05827331542986, 12.658631324768066],
        [75.05531311035162, 12.660061836242676],
        [75.04780578613281, 12.660088539123649],
        [75.04476165771484, 12.663301467895565],
        [75.04309082031273, 12.670571327209586],
        [75.04467773437528, 12.687440872192326],
        [75.04859924316412, 12.688321113586426],
        [75.05213165283214, 12.687007904052791],
        [75.0545501708985, 12.683580398559684],
        [75.05679321289068, 12.686720848083553],
        [75.05216979980486, 12.698170661926383],
        [75.05574798583996, 12.703411102294979],
        [75.05285644531278, 12.709821701049918],
        [75.04966735839838, 12.714699745178223],
        [75.04624176025408, 12.716349601745662],
        [75.04308319091803, 12.715529441833553],
        [75.03811645507818, 12.7185897827149],
        [75.0313796997072, 12.716952323913631],
        [75.02569580078125, 12.71431922912592],
        [75.02295684814447, 12.715620994567871],
        [75.01905822753929, 12.714750289917106],
        [75.01429748535179, 12.70780086517334],
        [75.00708007812528, 12.709209442138786],
        [75.00402832031278, 12.7117214202882],
        [75.00306701660185, 12.713691711425895],
        [75.0108566284182, 12.719289779663143],
        [75.0121688842774, 12.72284126281744],
        [75.00023651123075, 12.727400779724235],
        [74.9930496215822, 12.733139991760368],
        [74.9901504516601, 12.731100082397518],
        [74.98838043212919, 12.731929779052678],
        [74.98693847656267, 12.728797912597656],
        [74.98477935791044, 12.729881286621207],
        [74.98442840576166, 12.735940933227539],
        [74.98606109619163, 12.738479614257926],
        [74.98986053466803, 12.737280845642204],
        [74.99175262451178, 12.738169670105094],
        [74.99529266357433, 12.744840621948299],
        [75.000259399414, 12.751550674438477],
        [75.00427246093744, 12.753990173339844],
        [75.00711059570324, 12.757061958313045],
        [75.00860595703153, 12.760431289672965],
        [75.007797241211, 12.76509094238287],
        [75.00522613525385, 12.769330978393668],
        [75.0041275024414, 12.773551940918082],
        [75.00527954101562, 12.785491943359375],
        [75.00393676757812, 12.785619735717773],
        [75.00029754638689, 12.78348159790039],
        [74.99600219726568, 12.787981033325195],
        [74.99228668212908, 12.790459632873535],
        [74.98945617675781, 12.790810585021973],
        [74.98883056640642, 12.78923034667963],
        [74.99257659912138, 12.7836208343507],
        [74.98729705810541, 12.777920722961369],
        [74.98192596435575, 12.775239944458008],
        [74.97067260742216, 12.772560119628963],
        [74.9690017700197, 12.772929191589355],
        [74.96862792968767, 12.776261329650993],
        [74.96726226806635, 12.776851654052791],
        [74.96536254882807, 12.774801254272518],
        [74.9633712768557, 12.774829864501896],
        [74.96025848388672, 12.779040336608887],
        [74.95420074462896, 12.779280662536735],
        [74.93733215332048, 12.7721910476684],
        [74.9293518066408, 12.771331787109375],
        [74.90911102294939, 12.763031005859432],
        [74.9080429077149, 12.762571334838924],
        [74.90509796142595, 12.759930610656852],
        [74.90141296386747, 12.759530067443848],
        [74.8977661132813, 12.755400657653922],
        [74.89453125000006, 12.756099700927848],
        [74.89176177978533, 12.75452995300293],
        [74.88867950439447, 12.752371788025016],
        [74.88559722900419, 12.748770713806096],
        [74.87991333007807, 12.74927043914795],
        [74.87802886962908, 12.750241279602164],
        [74.87766265869158, 12.752348899841422],
        [74.87589263916027, 12.755290985107479],
        [74.86708068847685, 12.754451751708984],
        [74.86708068847685, 12.754582405090389],
        [74.86736297607439, 12.754579544067326],
        [74.86736297607439, 12.7562513351441],
        [74.86708068847685, 12.7562513351441],
        [74.86708068847685, 12.75764083862316],
        [74.86680603027344, 12.757638931274414],
        [74.86680603027344, 12.75819015502941],
        [74.8665313720706, 12.758201599121094],
        [74.8665313720706, 12.758750915527344],
        [74.86624908447271, 12.758750915527344],
        [74.86624908447271, 12.759311676025447],
        [74.86597442626964, 12.759306907653809],
        [74.86597442626964, 12.759860992431697],
        [74.86569213867193, 12.75984859466547],
        [74.86569213867193, 12.760418891906738],
        [74.8654174804687, 12.760416984558162],
        [74.8654174804687, 12.760695457458553],
        [74.86486053466814, 12.760695457458553],
        [74.86486053466814, 12.760139465332088],
        [74.8645858764649, 12.760141372680664],
        [74.8645858764649, 12.75984859466547],
        [74.86429595947283, 12.75984859466547],
        [74.86429595947283, 12.760418891906738],
        [74.8637466430664, 12.760416984558162],
        [74.8637466430664, 12.760972976684627],
        [74.86347198486357, 12.760971069336051],
        [74.86347198486357, 12.761809349060059],
        [74.86319732666021, 12.761809349060059],
        [74.86319732666021, 12.762640953064022],
        [74.86291503906261, 12.762639045715446],
        [74.86291503906261, 12.76319503784174],
        [74.86264038085943, 12.763191223144588],
        [74.86264038085943, 12.764028549194336],
        [74.86235809326166, 12.764026641845703],
        [74.86235809326166, 12.764582633972168],
        [74.86207580566412, 12.764580726623592],
        [74.86207580566412, 12.76542186737072],
        [74.86180877685564, 12.76542186737072],
        [74.86180877685564, 12.76597976684576],
        [74.86152648925787, 12.765971183776912],
        [74.86152648925787, 12.7661514282226],
        [74.86152648925787, 12.766810417175236],
        [74.86125183105486, 12.766804695129508],
        [74.86125183105486, 12.767050743103141],
        [74.86125183105486, 12.767642021179256],
        [74.86096954345732, 12.767640113830623],
        [74.86096954345732, 12.767960548400879],
        [74.86096954345732, 12.768193244934025],
        [74.8606948852539, 12.768193244934025],
        [74.86066436767595, 12.768858909606934],
        [74.86065673828142, 12.769041061401367],
        [74.86042022705107, 12.769026756286621],
        [74.86042022705107, 12.769359588623047],
        [74.86042022705107, 12.76958084106451],
        [74.86015319824224, 12.769590377807617],
        [74.86013793945312, 12.770420074463004],
        [74.85986328125011, 12.770415306091365],
        [74.85986328125011, 12.77097225189209],
        [74.8595809936524, 12.770969390869254],
        [74.8595809936524, 12.771809577941838],
        [74.85930633544916, 12.771805763244686],
        [74.85930633544916, 12.77236175537115],
        [74.85902404785162, 12.77236175537115],
        [74.85902404785162, 12.77319431304943],
        [74.85874938964861, 12.773191452026367],
        [74.85874938964861, 12.774029731750545],
        [74.85847473144537, 12.77402782440197],
        [74.85847473144537, 12.774581909179688],
        [74.85819244384783, 12.774581909179688],
        [74.85819244384783, 12.775420188903865],
        [74.85791778564482, 12.775420188903865],
        [74.85791778564482, 12.776251792907658],
        [74.8576431274414, 12.776251792907658],
        [74.8576431274414, 12.776810646057243],
        [74.85736083984403, 12.776810646057243],
        [74.85736083984403, 12.777640342712402],
        [74.85708618164062, 12.777640342712402],
        [74.85708618164062, 12.778194427490291],
        [74.85679626464872, 12.778189659118652],
        [74.85679626464872, 12.778750419616756],
        [74.8565292358399, 12.778750419616756],
        [74.8565292358399, 12.779582023620549],
        [74.85623931884777, 12.779582023620549],
        [74.85623931884777, 12.780140876770076],
        [74.85597229003912, 12.780140876770076],
        [74.85597229003912, 12.780982017517204],
        [74.85569763183611, 12.780982017517204],
        [74.85569763183611, 12.781531333923454],
        [74.85541534423851, 12.78152942657465],
        [74.85541534423851, 12.782081604003963],
        [74.85514068603533, 12.782079696655387],
        [74.85514068603533, 12.78264141082758],
        [74.85485839843756, 12.782637596130428],
        [74.85485839843756, 12.78319454193121],
        [74.85457611084001, 12.783189773559513],
        [74.85457611084001, 12.783750534057674],
        [74.85430908203153, 12.783750534057674],
        [74.85430908203153, 12.784311294555778],
        [74.8540267944336, 12.784304618835563],
        [74.8540267944336, 12.784860610962028],
        [74.85375213623075, 12.784860610962028],
        [74.85375213623075, 12.785421371459961],
        [74.85346984863287, 12.785416603088493],
        [74.85346984863287, 12.785972595214957],
        [74.85320281982416, 12.785970687866211],
        [74.85320281982416, 12.786531448364315],
        [74.85291290283209, 12.786526679992676],
        [74.85291290283209, 12.78708267211914],
        [74.85263824462885, 12.787077903747672],
        [74.85263824462885, 12.787640571594181],
        [74.85235595703148, 12.787640571594181],
        [74.85235595703148, 12.787921905517635],
        [74.8520812988283, 12.787917137145996],
        [74.8520812988283, 12.78819465637207],
        [74.85180664062506, 12.788192749023494],
        [74.85180664062506, 12.788750648498535],
        [74.85151672363298, 12.788750648498535],
        [74.85151672363298, 12.789031028747502],
        [74.85098266601562, 12.789031028747502],
        [74.85098266601562, 12.78931045532238],
        [74.85069274902372, 12.789304733276424],
        [74.85069274902372, 12.789583206176815],
        [74.85043334960943, 12.789581298828239],
        [74.85043334960943, 12.79015064239502],
        [74.85013580322277, 12.79015064239502],
        [74.85013580322277, 12.790418624877987],
        [74.84958648681635, 12.790416717529354],
        [74.84958648681635, 12.790970802307072],
        [74.84930419921898, 12.790972709655875],
        [74.84930419921898, 12.792082786560002],
        [74.8490295410158, 12.792082786560002],
        [74.8490295410158, 12.792361259460392],
        [74.84874725341803, 12.792361259460392],
        [74.84874725341803, 12.792641639709586],
        [74.84847259521501, 12.792638778686523],
        [74.84847259521501, 12.793194770812988],
        [74.8479232788086, 12.79319095611578],
        [74.8479232788086, 12.793751716613713],
        [74.84764099121122, 12.793751716613713],
        [74.84764099121122, 12.794030189514103],
        [74.84736633300787, 12.794030189514103],
        [74.84736633300787, 12.79430961608898],
        [74.84708404541027, 12.794304847717342],
        [74.84708404541027, 12.794583320617733],
        [74.84652709960932, 12.7945814132691],
        [74.84652709960932, 12.795140266418457],
        [74.84625244140648, 12.795140266418457],
        [74.84625244140648, 12.79597091674816],
        [74.84597015380876, 12.795960426330566],
        [74.84597015380876, 12.796529769897518],
        [74.84570312500006, 12.796529769897518],
        [74.84570312500006, 12.796810150146484],
        [74.84541320800798, 12.796806335449276],
        [74.84541320800798, 12.797083854675407],
        [74.84569549560553, 12.797083854675407],
        [74.84569549560553, 12.797362327575797],
        [74.84541320800798, 12.797359466552734],
        [74.84541320800798, 12.797639846801871],
        [74.84513854980497, 12.797639846801871],
        [74.84513854980497, 12.798195838928336],
        [74.84486389160156, 12.798195838928336],
        [74.84486389160156, 12.799028396606445],
        [74.84458160400419, 12.799018859863281],
        [74.84458160400419, 12.800140380859375],
        [74.84429931640625, 12.800140380859375],
        [74.84429931640625, 12.800419807434025],
        [74.84402465820341, 12.80041790008545],
        [74.84402465820341, 12.800971984863338],
        [74.84375, 12.800971984863338],
        [74.84375, 12.80209064483654],
        [74.84348297119135, 12.80209064483654],
        [74.84348297119135, 12.803750038146916],
        [74.84319305419928, 12.803750038146916],
        [74.84319305419928, 12.804583549499512],
        [74.84291839599626, 12.804579734802303],
        [74.84291839599626, 12.80542087554943],
        [74.84263610839866, 12.805416107177734],
        [74.84263610839866, 12.8059720993042],
        [74.84236145019548, 12.805970191955623],
        [74.84236145019548, 12.80681133270275],
        [74.84208679199247, 12.80681133270275],
        [74.84208679199247, 12.80764102935791],
        [74.8418045043947, 12.807638168335075],
        [74.8418045043947, 12.808471679687614],
        [74.84152984619169, 12.808469772338867],
        [74.84152984619169, 12.809030532836971],
        [74.84124755859375, 12.809028625488395],
        [74.84124755859375, 12.80958461761486],
        [74.84097290039091, 12.809579849243221],
        [74.84097290039091, 12.810140609741325],
        [74.84069824218756, 12.810138702392578],
        [74.84069824218756, 12.810973167419434],
        [74.84041595458996, 12.810960769653377],
        [74.84041595458996, 12.811531066894645],
        [74.84014129638678, 12.811529159545898],
        [74.84014129638678, 12.812083244323787],
        [74.839859008789, 12.812083244323787],
        [74.839859008789, 12.812360763549862],
        [74.84014129638678, 12.81235790252697],
        [74.84014129638678, 12.812920570373478],
        [74.839859008789, 12.812920570373478],
        [74.839859008789, 12.813472747802791],
        [74.83957672119163, 12.813472747802791],
        [74.83957672119163, 12.813751220703182],
        [74.83930206298845, 12.813751220703182],
        [74.83930206298845, 12.814861297607536],
        [74.83902740478521, 12.814861297607536],
        [74.83902740478521, 12.81652927398676],
        [74.8387527465822, 12.81652927398676],
        [74.8387527465822, 12.817639350891113],
        [74.83819580078125, 12.817639350891113],
        [74.83819580078125, 12.817921638488883],
        [74.83790588378935, 12.817921638488883],
        [74.83790588378935, 12.819031715393066],
        [74.83763885498053, 12.819026947021541],
        [74.83763885498053, 12.819861412048397],
        [74.83735656738293, 12.819861412048397],
        [74.83735656738293, 12.820420265197754],
        [74.83708190917974, 12.820420265197754],
        [74.83708190917974, 12.821251869201774],
        [74.8368072509765, 12.821251869201774],
        [74.8368072509765, 12.821530342102108],
        [74.83653259277366, 12.821530342102108],
        [74.83653259277366, 12.822921752929744],
        [74.83625030517595, 12.822921752929744],
        [74.83625030517595, 12.824029922485352],
        [74.83597564697271, 12.824028015136776],
        [74.83597564697271, 12.82458400726324],
        [74.83569335937517, 12.824579238891545],
        [74.83569335937517, 12.825420379638672],
        [74.83541870117216, 12.825415611267204],
        [74.83541870117216, 12.825971603393668],
        [74.83513641357422, 12.825971603393668],
        [74.83513641357422, 12.826251983642635],
        [74.83542633056669, 12.826251983642635],
        [74.83542633056669, 12.826530456543026],
        [74.83513641357422, 12.826527595520133],
        [74.83513641357422, 12.827362060546989],
        [74.83486175537138, 12.827362060546989],
        [74.83486175537138, 12.828472137451172],
        [74.83458709716803, 12.828470230102596],
        [74.83458709716803, 12.82932186126709],
        [74.83429718017607, 12.82932186126709],
        [74.83429718017607, 12.8298597335816],
        [74.83403015136724, 12.8298597335816],
        [74.83403015136724, 12.830694198608455],
        [74.83374786376947, 12.830694198608455],
        [74.83374786376947, 12.831291198730526],
        [74.83374786376947, 12.83181095123291],
        [74.83347320556663, 12.831806182861385],
        [74.83347320556663, 12.832083702087516],
        [74.83325195312506, 12.832112312316895],
        [74.83319091796892, 12.83226203918457],
        [74.83319091796892, 12.8331937789917],
        [74.83291625976568, 12.833191871643066],
        [74.83291625976568, 12.834031105041618],
        [74.83264160156267, 12.834028244018555],
        [74.83264160156267, 12.834582328796387],
        [74.83206939697283, 12.83458042144781],
        [74.83206939697283, 12.835420608520621],
        [74.83152770996094, 12.835415840148983],
        [74.83152770996094, 12.835972785949764],
        [74.83125305175793, 12.835972785949764],
        [74.83125305175793, 12.837361335754451],
        [74.83097076416021, 12.837361335754451],
        [74.83097076416021, 12.837640762329158],
        [74.83069610595697, 12.837638854980582],
        [74.83069610595697, 12.838194847107047],
        [74.8304061889649, 12.838190078735408],
        [74.8304061889649, 12.839310646057186],
        [74.83013916015642, 12.839310646057186],
        [74.83013916015642, 12.840141296386832],
        [74.82958221435564, 12.840139389038086],
        [74.82958221435564, 12.84069538116455],
        [74.82929992675787, 12.840689659118766],
        [74.82929992675787, 12.841259956359863],
        [74.82903289794922, 12.841259956359863],
        [74.82903289794922, 12.841809272766056],
        [74.82875061035185, 12.841805458068904],
        [74.82875061035185, 12.843194961547908],
        [74.82847595214844, 12.843194961547908],
        [74.82847595214844, 12.843473434448299],
        [74.82902526855486, 12.843473434448299],
        [74.82902526855486, 12.843194961547908],
        [74.82930755615263, 12.843194961547908],
        [74.82930755615263, 12.842638969421444],
        [74.82985687255882, 12.84264087677002],
        [74.82985687255882, 12.84291934967041],
        [74.82958221435564, 12.84291934967041],
        [74.82958221435564, 12.844029426574764],
        [74.82929992675787, 12.844029426574764],
        [74.82930755615263, 12.844861030578556],
        [74.82902526855486, 12.844861030578556],
        [74.82902526855486, 12.845694541931152],
        [74.82875061035185, 12.845690727233944],
        [74.82875061035185, 12.84653186798107],
        [74.82903289794922, 12.84653186798107],
        [74.82903289794922, 12.846810340881461],
        [74.82763671875011, 12.846804618835506],
        [74.82763671875011, 12.847083091735897],
        [74.82653045654314, 12.847083091735897],
        [74.82653045654314, 12.847360610961971],
        [74.82563781738287, 12.847360610961971],
        [74.8254165649414, 12.847360610961971],
        [74.8254165649414, 12.847083091735897],
        [74.82485961914068, 12.847083091735897],
        [74.82485961914068, 12.846804618835506],
        [74.8243026733399, 12.846810340881461],
        [74.8243026733399, 12.84653186798107],
        [74.82375335693382, 12.846527099609375],
        [74.82375335693382, 12.847083091735897],
        [74.82347106933611, 12.847083091735897],
        [74.82347106933611, 12.84819316864025],
        [74.82319641113287, 12.848191261291447],
        [74.82319641113287, 12.849309921264648],
        [74.82291412353533, 12.84930515289318],
        [74.82291412353533, 12.84958362579357],
        [74.82263946533232, 12.849578857421875],
        [74.82263946533232, 12.850419998169002],
        [74.8223648071289, 12.850418090820426],
        [74.8223648071289, 12.850972175598088],
        [74.82180786132818, 12.850969314575252],
        [74.82180786132818, 12.851249694824219],
        [74.82152557373058, 12.851249694824219],
        [74.82152557373058, 12.852084159851074],
        [74.8212509155274, 12.852081298828182],
        [74.8212509155274, 12.852361679077148],
        [74.82096862792963, 12.852361679077148],
        [74.82096862792963, 12.85291957855219],
        [74.8212509155274, 12.85291957855219],
        [74.8212509155274, 12.85681056976324],
        [74.82096862792963, 12.856805801391545],
        [74.82096862792963, 12.858471870422363],
        [74.82069396972679, 12.858471870422363],
        [74.82069396972679, 12.859306335449219],
        [74.82041931152361, 12.859298706054688],
        [74.82041931152361, 12.860421180725098],
        [74.82013702392584, 12.860416412353572],
        [74.82013702392584, 12.860972404480094],
        [74.81986236572283, 12.860969543457031],
        [74.81986236572283, 12.862641334533805],
        [74.81958007812528, 12.862641334533805],
        [74.81958007812528, 12.862920761108455],
        [74.81986236572283, 12.862915992736816],
        [74.81986236572283, 12.863195419311467],
        [74.81958007812528, 12.863189697265739],
        [74.81958007812528, 12.863751411437931],
        [74.81930541992188, 12.863751411437931],
        [74.81930541992188, 12.864583015441951],
        [74.81903076171903, 12.864580154419059],
        [74.81903076171903, 12.866808891296387],
        [74.8187484741211, 12.86680698394781],
        [74.8187484741211, 12.867082595825309],
        [74.81846618652372, 12.867079734802246],
        [74.81846618652372, 12.867641448974666],
        [74.81819152832037, 12.867641448974666],
        [74.81819152832037, 12.868750572204533],
        [74.81838226318365, 12.868748664855957],
        [74.81847381591808, 12.868748664855957],
        [74.81846618652372, 12.870141029357853],
        [74.81819152832037, 12.870141029357853],
        [74.81819152832037, 12.870418548583984],
        [74.81791687011713, 12.870416641235352],
        [74.81791687011713, 12.870695114135742],
        [74.81819152832037, 12.870695114135742],
        [74.81819152832037, 12.872082710266227],
        [74.81791687011713, 12.872082710266227],
        [74.81791687011713, 12.87236118316656],
        [74.81764221191429, 12.87236118316656],
        [74.81764221191429, 12.87292194366455],
        [74.81735992431658, 12.87292194366455],
        [74.81735992431658, 12.873750686645621],
        [74.81764221191429, 12.873748779296875],
        [74.81764221191429, 12.875693321228084],
        [74.81735992431658, 12.875693321228084],
        [74.81735992431658, 12.876629829406795],
        [74.81735992431658, 12.876810073852653],
        [74.81708526611334, 12.876806259155273],
        [74.81709289550787, 12.877210617065487],
        [74.81709289550787, 12.877370834350586],
        [74.8168029785158, 12.877370834350586],
        [74.8168029785158, 12.87763977050787],
        [74.81652832031278, 12.87763977050787],
        [74.81652832031278, 12.878371238708553],
        [74.81652832031278, 12.87903022766119],
        [74.81624603271501, 12.87903022766119],
        [74.81624603271501, 12.880140304565543],
        [74.815971374512, 12.880140304565543],
        [74.815971374512, 12.882083892822266],
        [74.8156967163086, 12.88208198547369],
        [74.8156967163086, 12.884310722351074],
        [74.81540679931669, 12.884310722351074],
        [74.81540679931669, 12.885420799255428],
        [74.81513977050787, 12.885416030883732],
        [74.81513977050787, 12.885693550109863],
        [74.81541442871122, 12.885693550109863],
        [74.81541442871122, 12.885972023010197],
        [74.81513977050787, 12.885972023010197],
        [74.81513977050787, 12.886249542236328],
        [74.81485748291027, 12.88624095916748],
        [74.81485748291027, 12.886811256408748],
        [74.81458282470709, 12.886805534362793],
        [74.81458282470709, 12.887361526489258],
        [74.81431579589861, 12.887359619140682],
        [74.81431579589861, 12.887640953063908],
        [74.81402587890648, 12.887638092041072],
        [74.81402587890648, 12.889584541320858],
        [74.8137512207033, 12.889579772949219],
        [74.8137512207033, 12.890420913696289],
        [74.81402587890648, 12.890417098999137],
        [74.81402587890648, 12.890973091125602],
        [74.8137512207033, 12.890973091125602],
        [74.8137512207033, 12.892083168029785],
        [74.81346893310553, 12.892083168029785],
        [74.81346893310553, 12.893472671508789],
        [74.81318664550798, 12.89346790313732],
        [74.81318664550798, 12.89403057098383],
        [74.8129196166995, 12.89403057098383],
        [74.8129196166995, 12.896529197692928],
        [74.81263732910156, 12.896529197692928],
        [74.81263732910156, 12.897639274597111],
        [74.81236267089872, 12.897639274597111],
        [74.81236267089872, 12.898195266723576],
        [74.81208038330084, 12.89818191528326],
        [74.81208038330084, 12.899031639099235],
        [74.81180572509777, 12.899026870727539],
        [74.81180572509777, 12.90069484710699],
        [74.81153106689459, 12.900691032409611],
        [74.81153106689459, 12.901809692382812],
        [74.81124877929682, 12.901804924011344],
        [74.81124877929682, 12.902083396911735],
        [74.81096649169945, 12.902080535888672],
        [74.81096649169945, 12.90264034271246],
        [74.81069183349626, 12.90264034271246],
        [74.81069183349626, 12.903470993041992],
        [74.81096649169945, 12.903470993041992],
        [74.81096649169945, 12.903749465942383],
        [74.81069183349626, 12.903749465942383],
        [74.81069183349626, 12.905139923095703],
        [74.81097412109398, 12.905139923095703],
        [74.81097412109398, 12.905971527099666],
        [74.81041717529303, 12.905971527099666],
        [74.81041717529303, 12.907361984252987],
        [74.80986022949247, 12.907361984252987],
        [74.80986022949247, 12.910971641540527],
        [74.8095932006836, 12.910969734191951],
        [74.8095932006836, 12.911250114440918],
        [74.80930328369169, 12.911250114440918],
        [74.80930328369169, 12.911810874939079],
        [74.80902862548834, 12.911810874939079],
        [74.80902862548834, 12.913480758667049],
        [74.80874633789074, 12.913480758667049],
        [74.80874633789074, 12.913749694824332],
        [74.80847167968756, 12.913749694824332],
        [74.80847167968756, 12.914859771728516],
        [74.80818939208979, 12.914859771728516],
        [74.80818939208979, 12.916251182556096],
        [74.80791473388695, 12.916251182556096],
        [74.80791473388695, 12.917084693908691],
        [74.80764007568376, 12.917084693908691],
        [74.80764007568376, 12.91736030578619],
        [74.80791473388695, 12.91736030578619],
        [74.80791473388695, 12.918194770813045],
        [74.80764007568376, 12.918190002441406],
        [74.80764007568376, 12.91875076293951],
        [74.807357788086, 12.91875076293951],
        [74.807357788086, 12.919031143188477],
        [74.80708312988298, 12.919031143188477],
        [74.80708312988298, 12.919310569763184],
        [74.80680847167997, 12.919306755065975],
        [74.80680847167997, 12.920972824096793],
        [74.80708312988298, 12.920970916748047],
        [74.80708312988298, 12.921251296997184],
        [74.807357788086, 12.921251296997184],
        [74.807357788086, 12.921530723571891],
        [74.80764007568376, 12.921526908874512],
        [74.80764007568376, 12.922082901000977],
        [74.80791473388695, 12.922082901000977],
        [74.80791473388695, 12.922361373901367],
        [74.80764007568376, 12.922361373901367],
        [74.80764007568376, 12.92458343505865],
        [74.80902862548834, 12.92458343505865],
        [74.80902862548834, 12.92430496215826],
        [74.8095932006836, 12.924309730529728],
        [74.8095932006836, 12.924029350280762],
        [74.80986022949247, 12.924029350280762],
        [74.80986022949247, 12.923471450805721],
        [74.81041717529303, 12.923473358154297],
        [74.81041717529303, 12.922638893127441],
        [74.81069183349626, 12.922638893127441],
        [74.81069183349626, 12.9220809936524],
        [74.81097412109398, 12.922082901000977],
        [74.81097412109398, 12.921526908874512],
        [74.81125640869158, 12.921530723571891],
        [74.81125640869158, 12.920689582824764],
        [74.81153106689459, 12.92069530487072],
        [74.81153106689459, 12.920139312744254],
        [74.81181335449213, 12.92014122009283],
        [74.81181335449213, 12.919580459594727],
        [74.81208038330084, 12.91958332061779],
        [74.81208038330084, 12.919027328491325],
        [74.81236267089872, 12.919031143188477],
        [74.81236267089872, 12.91875076293951],
        [74.8129196166995, 12.91875076293951],
        [74.8129196166995, 12.918470382690543],
        [74.8137512207033, 12.918470382690543],
        [74.8137512207033, 12.919031143188477],
        [74.81402587890648, 12.919027328491325],
        [74.81402587890648, 12.92069530487072],
        [74.81430816650408, 12.92069530487072],
        [74.81431579589861, 12.921251296997184],
        [74.81402587890648, 12.921251296997184],
        [74.81402587890648, 12.921530723571891],
        [74.8137512207033, 12.921526908874512],
        [74.8137512207033, 12.922361373901367],
        [74.81431579589861, 12.92235183715826],
        [74.81431579589861, 12.922919273376408],
        [74.81541442871122, 12.922917366027832],
        [74.81541442871122, 12.923194885253906],
        [74.8156967163086, 12.923194885253906],
        [74.8156967163086, 12.923473358154297],
        [74.815971374512, 12.923471450805721],
        [74.815971374512, 12.923750877380371],
        [74.81624603271501, 12.923750877380371],
        [74.81624603271501, 12.924029350280762],
        [74.81652832031278, 12.924029350280762],
        [74.81652832031278, 12.924309730529728],
        [74.8168029785158, 12.92430496215826],
        [74.8168029785158, 12.92458343505865],
        [74.81735992431658, 12.924580574035588],
        [74.81735992431658, 12.924860954284725],
        [74.81764221191429, 12.924860954284725],
        [74.81764221191429, 12.925141334533748],
        [74.81791687011713, 12.925141334533748],
        [74.81791687011713, 12.925421714782715],
        [74.81819152832037, 12.925416946411246],
        [74.81819152832037, 12.92569541931158],
        [74.81847381591808, 12.92569541931158],
        [74.81847381591808, 12.925971031188908],
        [74.8187484741211, 12.925971031188908],
        [74.8187484741211, 12.926251411438102],
        [74.8193130493164, 12.926251411438102],
        [74.8193130493164, 12.926531791687069],
        [74.81958007812528, 12.926531791687069],
        [74.81958007812528, 12.92681026458746],
        [74.81986236572283, 12.926804542541504],
        [74.81986236572283, 12.927083015441895],
        [74.82013702392584, 12.927083015441895],
        [74.82013702392584, 12.927360534667969],
        [74.82041931152361, 12.927360534667969],
        [74.82041931152361, 12.927641868591422],
        [74.82068634033209, 12.927641868591422],
        [74.82068634033209, 12.927920341491813],
        [74.82096862792963, 12.927920341491813],
        [74.82096862792963, 12.929311752319393],
        [74.82068634033209, 12.929311752319393],
        [74.82068634033209, 12.92986202239996],
        [74.82041931152361, 12.92986202239996],
        [74.82041931152361, 12.930419921875],
        [74.82013702392584, 12.930418014526424],
        [74.82013702392584, 12.930695533752498],
        [74.81930541992188, 12.930695533752498],
        [74.81930541992188, 12.930418014526424],
        [74.81819152832037, 12.930418014526424],
        [74.81819152832037, 12.930971145629996],
        [74.81791687011713, 12.93096923828125],
        [74.81791687011713, 12.931249618530387],
        [74.81735992431658, 12.931249618530387],
        [74.81735992431658, 12.93096923828125],
        [74.81708526611334, 12.930971145629996],
        [74.81708526611334, 12.930695533752498],
        [74.8168029785158, 12.930695533752498],
        [74.8168029785158, 12.930418014526424],
        [74.81709289550787, 12.930419921875],
        [74.81709289550787, 12.92986202239996],
        [74.8168029785158, 12.92986202239996],
        [74.8168029785158, 12.929580688476676],
        [74.81624603271501, 12.929580688476676],
        [74.81624603271501, 12.92986202239996],
        [74.81540679931669, 12.92986202239996],
        [74.81540679931669, 12.930139541626033],
        [74.81485748291027, 12.930139541626033],
        [74.81485748291027, 12.930419921875],
        [74.81458282470709, 12.930418014526424],
        [74.81458282470709, 12.930695533752498],
        [74.8137512207033, 12.930695533752498],
        [74.8137512207033, 12.930971145629996],
        [74.81319427490251, 12.930971145629996],
        [74.81319427490251, 12.930695533752498],
        [74.81236267089872, 12.930695533752498],
        [74.81236267089872, 12.930418014526424],
        [74.81153106689459, 12.930419921875],
        [74.81153106689459, 12.930139541626033],
        [74.81096649169945, 12.930139541626033],
        [74.81096649169945, 12.92986202239996],
        [74.81069183349626, 12.92986202239996],
        [74.81069183349626, 12.929580688476676],
        [74.80902862548834, 12.929582595825252],
        [74.80902862548834, 12.929305076599178],
        [74.80847167968756, 12.929311752319393],
        [74.80847167968756, 12.92903041839594],
        [74.80764007568376, 12.92903041839594],
        [74.80764007568376, 12.928751945495605],
        [74.80708312988298, 12.928751945495605],
        [74.80708312988298, 12.928191184997615],
        [74.80680847167997, 12.928191184997615],
        [74.80680847167997, 12.92848014831543],
        [74.80652618408203, 12.92848014831543],
        [74.80652618408203, 12.92903041839594],
        [74.80625152587919, 12.92903041839594],
        [74.80625152587919, 12.92986202239996],
        [74.80596923828125, 12.92986202239996],
        [74.80596923828125, 12.930695533752498],
        [74.80569458007824, 12.930695533752498],
        [74.80569458007824, 12.930971145629996],
        [74.80513000488293, 12.93096923828125],
        [74.80513000488293, 12.931249618530387],
        [74.8043060302735, 12.931249618530387],
        [74.8043060302735, 12.93096923828125],
        [74.80347442626953, 12.930971145629996],
        [74.80347442626953, 12.930695533752498],
        [74.80291748046875, 12.930688858032227],
        [74.80291748046875, 12.931249618530387],
        [74.80236053466803, 12.931249618530387],
        [74.80236053466803, 12.93181037902832],
        [74.80291748046875, 12.931805610656852],
        [74.80291748046875, 12.932084083557243],
        [74.80319213867216, 12.932079315185604],
        [74.80319213867216, 12.932361602783317],
        [74.80347442626953, 12.932361602783317],
        [74.80347442626953, 12.933194160461426],
        [74.80374908447294, 12.933194160461426],
        [74.80374908447294, 12.9334716796875],
        [74.80403137207048, 12.9334716796875],
        [74.80403137207048, 12.93375015258789],
        [74.8043060302735, 12.93375015258789],
        [74.8043060302735, 12.935139656066895],
        [74.80403137207048, 12.935137748718319],
        [74.80403137207048, 12.937084197998104],
        [74.80374908447294, 12.937080383300781],
        [74.80374908447294, 12.938750267028752],
        [74.80346679687517, 12.938750267028752],
        [74.80346679687517, 12.940139770507812],
        [74.80319213867216, 12.940139770507812],
        [74.80319213867216, 12.94153118133545],
        [74.80291748046875, 12.941528320312557],
        [74.80291748046875, 12.943194389343375],
        [74.80263519287138, 12.943194389343375],
        [74.80263519287138, 12.944581985473633],
        [74.80236053466803, 12.944581985473633],
        [74.80236053466803, 12.94597339630127],
        [74.80208587646479, 12.94596958160406],
        [74.80208587646479, 12.947641372680664],
        [74.80179595947271, 12.947641372680664],
        [74.80179595947271, 12.949030876159725],
        [74.80152893066423, 12.949028968811092],
        [74.80152893066423, 12.95069503784174],
        [74.80124664306663, 12.95069503784174],
        [74.80124664306663, 12.952082633972225],
        [74.80097198486345, 12.952082633972225],
        [74.80097198486345, 12.953471183776912],
        [74.80069732666044, 12.953471183776912],
        [74.80069732666044, 12.953978538513297],
        [74.80069732666044, 12.955142021179256],
        [74.80041503906267, 12.955139160156364],
        [74.80041503906267, 12.955550193786621],
        [74.80042266845703, 12.956529617309684],
        [74.80014038085966, 12.956526756286621],
        [74.80014038085966, 12.956780433654785],
        [74.80014038085966, 12.957920074463004],
        [74.79985809326172, 12.957915306091365],
        [74.79985809326172, 12.958049774169979],
        [74.79985809326172, 12.959584236145076],
        [74.79958343505888, 12.959584236145076],
        [74.79958343505888, 12.960971832275504],
        [74.79931640625006, 12.960971832275504],
        [74.79931640625006, 12.962639808654728],
        [74.79902648925793, 12.962639808654728],
        [74.79902648925793, 12.964030265808049],
        [74.79875183105474, 12.964028358459473],
        [74.79875183105474, 12.965694427490291],
        [74.79846954345697, 12.965694427490291],
        [74.79846954345697, 12.967082023620549],
        [74.79819488525413, 12.967082023620549],
        [74.79819488525413, 12.96847152709961],
        [74.79792022705095, 12.968469619751033],
        [74.79792022705095, 12.970141410827637],
        [74.79763793945318, 12.970141410827637],
        [74.79763793945318, 12.97153091430664],
        [74.79736328125017, 12.971529006958065],
        [74.79736328125017, 12.973195075988883],
        [74.79708099365263, 12.973190307617244],
        [74.79708099365263, 12.974311828613281],
        [74.79680633544922, 12.974311828613281],
        [74.79680633544922, 12.975140571594181],
        [74.79652404785185, 12.975138664245605],
        [74.79652404785185, 12.975973129272461],
        [74.7962493896485, 12.975973129272461],
        [74.7962493896485, 12.977083206176815],
        [74.79597473144543, 12.977083206176815],
        [74.79597473144543, 12.978470802307072],
        [74.79569244384771, 12.978470802307072],
        [74.79569244384771, 12.9793119430542],
        [74.79541778564447, 12.979305267333928],
        [74.79541778564447, 12.980971336364746],
        [74.7951354980471, 12.980971336364746],
        [74.7951354980471, 12.982639312744197],
        [74.79486083984392, 12.982629776001033],
        [74.79486083984392, 12.984310150146484],
        [74.79458618164068, 12.984306335449332],
        [74.79458618164068, 12.98597240447998],
        [74.79430389404314, 12.98597240447998],
        [74.79430389404314, 12.988194465637264],
        [74.79402923584013, 12.988190650939885],
        [74.79402923584013, 12.989031791687012],
        [74.79374694824219, 12.989031791687012],
        [74.79374694824219, 12.990139961242733],
        [74.79347229003935, 12.990139961242733],
        [74.79347229003935, 12.991250038146916],
        [74.7931900024414, 12.991250038146916],
        [74.793197631836, 12.99236011505127],
        [74.7929153442384, 12.99236011505127],
        [74.7929153442384, 12.993193626403865],
        [74.79264068603521, 12.993191719055233],
        [74.79264068603521, 12.99431133270275],
        [74.79250335693388, 12.994305610656795],
        [74.79235839843744, 12.99431133270275],
        [74.79235839843744, 12.995421409606934],
        [74.7920761108399, 12.995421409606934],
        [74.7920761108399, 12.996250152588004],
        [74.79180908203142, 12.996250152588004],
        [74.79180908203142, 12.997360229492188],
        [74.79152679443365, 12.997360229492188],
        [74.79152679443365, 12.998473167419434],
        [74.79125213623064, 12.998473167419434],
        [74.79125213623064, 12.999583244323787],
        [74.7909698486331, 12.999580383300895],
        [74.7909698486331, 13.000141143798828],
        [74.79070281982422, 13.000141143798828],
        [74.79070281982422, 13.000420570373535],
        [74.79041290283232, 13.000420570373535],
        [74.79041290283232, 13.000701904296932],
        [74.7901458740235, 13.000701904296932],
        [74.7901458740235, 13.001530647277889],
        [74.79041290283232, 13.00152683258068],
        [74.79041290283232, 13.002361297607536],
        [74.7901382446289, 13.002361297607536],
        [74.7901382446289, 13.003473281860295],
        [74.78958129882818, 13.003471374511719],
        [74.78958129882818, 13.003760337829704],
        [74.78930664062494, 13.003748893737793],
        [74.78930664062494, 13.005695343017578],
        [74.78901672363287, 13.00569057464594],
        [74.78901672363287, 13.00764179229742],
        [74.78874969482439, 13.00764179229742],
        [74.78874969482439, 13.008751869201774],
        [74.78847503662115, 13.008749008178711],
        [74.78847503662115, 13.010692596435604],
        [74.7879180908206, 13.010692596435604],
        [74.7879180908206, 13.013471603393668],
        [74.78763580322283, 13.013471603393668],
        [74.78763580322283, 13.014030456543026],
        [74.78775787353544, 13.014030456543026],
        [74.7879180908206, 13.014027595520133],
        [74.7879180908206, 13.014583587646428],
        [74.78763580322283, 13.014581680297852],
        [74.78763580322283, 13.015701293945312],
        [74.78736114501982, 13.015693664550781],
        [74.78736114501982, 13.017084121704102],
        [74.7870864868164, 13.01708030700695],
        [74.7870864868164, 13.017640113830566],
        [74.7867965698245, 13.017640113830566],
        [74.7867965698245, 13.01931095123291],
        [74.78652954101568, 13.019306182861442],
        [74.78652954101568, 13.019862174987907],
        [74.78624725341808, 13.019862174987907],
        [74.78624725341808, 13.022082328796444],
        [74.7859725952149, 13.02208042144781],
        [74.7859725952149, 13.022920608520621],
        [74.78569793701189, 13.022915840148983],
        [74.78569793701189, 13.024581909179801],
        [74.78542327880865, 13.024580001831055],
        [74.78542327880865, 13.026250839233512],
        [74.78514099121111, 13.026250839233512],
        [74.78514099121111, 13.027360916137695],
        [74.78485870361334, 13.027360916137695],
        [74.78485870361334, 13.028751373291016],
        [74.78458404541033, 13.028751373291016],
        [74.78458404541033, 13.030694961547908],
        [74.78430938720732, 13.030680656433162],
        [74.78430175781278, 13.031811714172363],
        [74.78402709960938, 13.031811714172363],
        [74.78402709960938, 13.032921791076717],
        [74.78375244140653, 13.032921791076717],
        [74.78375244140653, 13.03403186798107],
        [74.78347015380865, 13.034027099609432],
        [74.78347015380865, 13.034860610961971],
        [74.78320312499994, 13.034860610961971],
        [74.78320312499994, 13.036529541015682],
        [74.78291320800787, 13.03652667999279],
        [74.78291320800787, 13.038471221923828],
        [74.78263854980463, 13.038471221923828],
        [74.78263854980463, 13.039584159851074],
        [74.78235626220726, 13.039581298828182],
        [74.78235626220726, 13.04041957855219],
        [74.78208160400408, 13.040417671203613],
        [74.78208160400408, 13.040971755981502],
        [74.78180694580084, 13.04096889495861],
        [74.78180694580084, 13.041250228881893],
        [74.78208160400408, 13.041250228881893],
        [74.78208160400408, 13.041529655456543],
        [74.78180694580084, 13.041529655456543],
        [74.78180694580084, 13.041810035705566],
        [74.78208160400408, 13.041806221008358],
        [74.78208160400408, 13.042083740234432],
        [74.78180694580084, 13.042083740234432],
        [74.78180694580084, 13.043472290039176],
        [74.7815246582033, 13.043472290039176],
        [74.7815246582033, 13.044584274291935],
        [74.78125000000028, 13.044580459594783],
        [74.78125000000028, 13.0451402664184],
        [74.7809829711914, 13.0451402664184],
        [74.7809829711914, 13.045420646667594],
        [74.78125000000028, 13.045415878295898],
        [74.78125000000028, 13.045694351196289],
        [74.78097534179688, 13.045694351196289],
        [74.78097534179688, 13.047081947326774],
        [74.7806930541995, 13.047081947326774],
        [74.7806930541995, 13.048193931579704],
        [74.78041839599615, 13.0481920242309],
        [74.78041839599615, 13.049860000610352],
        [74.78013610839855, 13.049860000610352],
        [74.78013610839855, 13.050694465637207],
        [74.77986145019537, 13.050694465637207],
        [74.77986145019537, 13.050972938537598],
        [74.78013610839855, 13.050972938537598],
        [74.78013610839855, 13.052360534668026],
        [74.77958679199213, 13.052360534668026],
        [74.77958679199213, 13.052640914916992],
        [74.77929687500006, 13.052640914916992],
        [74.77929687500006, 13.052921295165959],
        [74.77986145019537, 13.05291652679449],
        [74.77986145019537, 13.053473472595272],
        [74.77958679199213, 13.053469657897892],
        [74.77958679199213, 13.054031372070312],
        [74.77929687500006, 13.054031372070312],
        [74.77929687500006, 13.054308891296387],
        [74.77902984619158, 13.05430698394781],
        [74.77902984619158, 13.057050704956055],
        [74.77902984619158, 13.057916641235408],
        [74.7787475585938, 13.057916641235408],
        [74.7787475585938, 13.058472633361873],
        [74.7784729003908, 13.05847072601324],
        [74.7784729003908, 13.06042194366455],
        [74.77819824218778, 13.060417175293082],
        [74.77819824218778, 13.06208324432373],
        [74.77791595458984, 13.062081336975098],
        [74.77791595458984, 13.062639236450195],
        [74.77820587158232, 13.062639236450195],
        [74.77820587158232, 13.064310073852653],
        [74.77791595458984, 13.064305305481014],
        [74.77791595458984, 13.064862251281795],
        [74.777641296387, 13.064859390258903],
        [74.777641296387, 13.065420150756836],
        [74.77735900878906, 13.065415382385368],
        [74.77735900878906, 13.065972328186149],
        [74.77707672119169, 13.065972328186149],
        [74.77707672119169, 13.06653022766119],
        [74.77680206298834, 13.06653022766119],
        [74.77680206298834, 13.067640304565543],
        [74.7765274047851, 13.067640304565543],
        [74.7765274047851, 13.06931018829357],
        [74.77625274658226, 13.069306373596191],
        [74.77625274658226, 13.070779800415153],
        [74.77625274658226, 13.07097244262701],
        [74.77597045898455, 13.07097244262701],
        [74.77597045898455, 13.071161270141715],
        [74.77597045898455, 13.073193550109863],
        [74.7756958007813, 13.07319164276123],
        [74.7756958007813, 13.073749542236328],
        [74.77541351318376, 13.073749542236328],
        [74.77541351318376, 13.075694084167537],
        [74.77513885498075, 13.075694084167537],
        [74.77513885498075, 13.077084541320858],
        [74.77485656738298, 13.077079772949219],
        [74.77485656738298, 13.077640533447322],
        [74.77458190917997, 13.07763767242443],
        [74.77458190917997, 13.078194618225211],
        [74.7765274047851, 13.078194618225211],
        [74.7765274047851, 13.077360153198356],
        [74.77680206298834, 13.077360153198356],
        [74.77680206298834, 13.077084541320858],
        [74.7765274047851, 13.077084541320858],
        [74.7765274047851, 13.076527595520076],
        [74.77680206298834, 13.076531410217285],
        [74.77680206298834, 13.076250076294002],
        [74.77707672119169, 13.076250076294002],
        [74.77707672119169, 13.075139999389648],
        [74.77735900878906, 13.075139999389648],
        [74.77735900878906, 13.074030876159782],
        [74.777641296387, 13.074030876159782],
        [74.777641296387, 13.07319164276123],
        [74.77791595458984, 13.073193550109863],
        [74.77791595458984, 13.072637557983398],
        [74.77819061279325, 13.072640419006461],
        [74.77820587158232, 13.072360038757267],
        [74.7784729003908, 13.072360038757267],
        [74.7784729003908, 13.0720796585083],
        [74.7787475585938, 13.07208442687994],
        [74.7787475585938, 13.071528434753475],
        [74.77902984619158, 13.071530342102108],
        [74.77902984619158, 13.071249961853084],
        [74.77929687500006, 13.071249961853084],
        [74.77929687500006, 13.07068920135498],
        [74.77958679199213, 13.07069396972662],
        [74.77958679199213, 13.064921379089299],
        [74.77958679199213, 13.064583778381404],
        [74.7797164916995, 13.064583778381404],
        [74.77986145019537, 13.064583778381404],
        [74.77986145019537, 13.064191818237418],
        [74.77986145019537, 13.062639236450195],
        [74.78013610839855, 13.062639236450195],
        [74.78013610839855, 13.062081336975098],
        [74.78041839599615, 13.06208324432373],
        [74.78041839599615, 13.061527252197266],
        [74.7806930541995, 13.061532020568904],
        [74.7806930541995, 13.061250686645621],
        [74.78096008300798, 13.061220169067496],
        [74.78097534179688, 13.060971260070744],
        [74.78125000000028, 13.060971260070744],
        [74.78125000000028, 13.060417175293082],
        [74.78180694580084, 13.06042194366455],
        [74.78180694580084, 13.060140609741268],
        [74.78208160400408, 13.060140609741268],
        [74.78208160400408, 13.059861183166618],
        [74.78236389160179, 13.059861183166618],
        [74.78236389160179, 13.059582710266227],
        [74.78263854980463, 13.059582710266227],
        [74.78263854980463, 13.059305191040096],
        [74.78320312499994, 13.059311866760368],
        [74.78320312499994, 13.05903244018549],
        [74.7842407226563, 13.05903244018549],
        [74.78430938720732, 13.059311866760368],
        [74.7845764160158, 13.059311866760368],
        [74.7845764160158, 13.06042194366455],
        [74.78443145751959, 13.060417175293082],
        [74.78430938720732, 13.060417175293082],
        [74.78430175781278, 13.060971260070744],
        [74.78402709960938, 13.060971260070744],
        [74.78402709960938, 13.061250686645621],
        [74.78375244140653, 13.061250686645621],
        [74.78375244140653, 13.061532020568904],
        [74.78320312499994, 13.061532020568904],
        [74.78320312499994, 13.061809539794979],
        [74.78291320800787, 13.061809539794979],
        [74.78291320800787, 13.062360763549805],
        [74.78263854980463, 13.062360763549805],
        [74.78263854980463, 13.062639236450195],
        [74.78236389160179, 13.062639236450195],
        [74.78236389160179, 13.063193321228084],
        [74.78208160400408, 13.063191413879451],
        [74.78208160400408, 13.063749313354549],
        [74.78151702880876, 13.063749313354549],
        [74.78151702880876, 13.064041137695426],
        [74.7809829711914, 13.064041137695426],
        [74.7809829711914, 13.064310073852653],
        [74.7806930541995, 13.064310073852653],
        [74.7806930541995, 13.06513977050787],
        [74.78125000000028, 13.06513977050787],
        [74.78125000000028, 13.065972328186149],
        [74.7815246582033, 13.065972328186149],
        [74.7815246582033, 13.067084312439079],
        [74.78125000000028, 13.067081451416016],
        [74.78125000000028, 13.067640304565543],
        [74.78097534179688, 13.067640304565543],
        [74.78097534179688, 13.068471908569336],
        [74.7806930541995, 13.068471908569336],
        [74.7806930541995, 13.069862365722656],
        [74.78041839599615, 13.069862365722656],
        [74.78041839599615, 13.07097244262701],
        [74.78013610839855, 13.070969581603947],
        [74.78013610839855, 13.071530342102108],
        [74.77986145019537, 13.071528434753475],
        [74.77986145019537, 13.07208442687994],
        [74.77958679199213, 13.0720796585083],
        [74.77958679199213, 13.072640419006461],
        [74.77930450439476, 13.072637557983398],
        [74.77930450439476, 13.073193550109863],
        [74.77958679199213, 13.073193550109863],
        [74.77958679199213, 13.073472023010254],
        [74.77929687500006, 13.073472023010254],
        [74.77929687500006, 13.074311256408748],
        [74.77902984619158, 13.074305534362793],
        [74.77902984619158, 13.075694084167537],
        [74.7787475585938, 13.075690269470158],
        [74.7787475585938, 13.075979232788143],
        [74.7784729003908, 13.075971603393612],
        [74.7784729003908, 13.078194618225211],
        [74.7787475585938, 13.078194618225211],
        [74.7787475585938, 13.078473091125602],
        [74.77902984619158, 13.078473091125602],
        [74.77902984619158, 13.079583168029785],
        [74.77929687500006, 13.079580307006893],
        [74.77929687500006, 13.07986068725586],
        [74.77902984619158, 13.07986068725586],
        [74.77902984619158, 13.08125114440918],
        [74.77930450439476, 13.08125114440918],
        [74.77930450439476, 13.082082748413143],
        [74.77902984619158, 13.082082748413143],
        [74.77902984619158, 13.082361221313533],
        [74.77930450439476, 13.082361221313533],
        [74.77930450439476, 13.083194732666072],
        [74.77958679199213, 13.083194732666072],
        [74.77958679199213, 13.083473205566463],
        [74.77986145019537, 13.083471298217887],
        [74.77986145019537, 13.083750724792594],
        [74.78013610839855, 13.08374881744379],
        [74.78013610839855, 13.084583282470646],
        [74.78041839599615, 13.084583282470646],
        [74.78041839599615, 13.08486080169672],
        [74.7806930541995, 13.08486080169672],
        [74.7806930541995, 13.085970878601074],
        [74.7809829711914, 13.085970878601074],
        [74.7809829711914, 13.086531639099235],
        [74.78112030029314, 13.086528778076172],
        [74.78125000000028, 13.086526870727596],
        [74.78125000000028, 13.08708286285406],
        [74.7815246582033, 13.08708286285406],
        [74.7815246582033, 13.087361335754451],
        [74.78180694580084, 13.087361335754451],
        [74.78180694580084, 13.087920188903809],
        [74.78208160400408, 13.087917327880916],
        [74.78208160400408, 13.08819484710699],
        [74.78236389160179, 13.08819484710699],
        [74.78235626220726, 13.08909130096447],
        [74.78235626220726, 13.089860916137809],
        [74.78208160400408, 13.089860916137809],
        [74.78208160400408, 13.09097957611084],
        [74.78180694580084, 13.09097957611084],
        [74.78180694580084, 13.091249465942383],
        [74.78151702880876, 13.091249465942383],
        [74.78151702880876, 13.091810226440487],
        [74.78125000000028, 13.091806411743164],
        [74.78125000000028, 13.092362403869629],
        [74.7809829711914, 13.092362403869629],
        [74.7809829711914, 13.092641830444279],
        [74.78041839599615, 13.092639923095703],
        [74.78041839599615, 13.093194007873592],
        [74.78013610839855, 13.093194007873592],
        [74.78013610839855, 13.093471527099666],
        [74.77986145019537, 13.093471527099666],
        [74.77986145019537, 13.094584465026912],
        [74.77958679199213, 13.09458160400402],
        [74.77958679199213, 13.096811294555664],
        [74.77986145019537, 13.096811294555664],
        [74.77986145019537, 13.097920417785758],
        [74.78013610839855, 13.097915649414062],
        [74.78013610839855, 13.098194122314453],
        [74.78041839599615, 13.098189353942985],
        [74.78041839599615, 13.098750114440918],
        [74.7806930541995, 13.098750114440918],
        [74.7806930541995, 13.099089622497615],
        [74.7806930541995, 13.099310874939079],
        [74.78125000000028, 13.09930610656744],
        [74.78125000000028, 13.099583625793514],
        [74.78272247314459, 13.099583625793514],
        [74.78391265869169, 13.099583625793514],
        [74.78430175781278, 13.099583625793514],
        [74.78430175781278, 13.09930610656744],
        [74.78485870361334, 13.099310874939079],
        [74.78485870361334, 13.099029541015625],
        [74.78542327880865, 13.099029541015625],
        [74.78542327880865, 13.098750114440918],
        [74.78624725341808, 13.098750114440918],
        [74.78624725341808, 13.098469734191951],
        [74.78680419921903, 13.098471641540584],
        [74.78680419921903, 13.098194122314453],
        [74.78736114501982, 13.098194122314453],
        [74.78736114501982, 13.097915649414062],
        [74.78958129882818, 13.097920417785758],
        [74.78958129882818, 13.098750114440918],
        [74.78736114501982, 13.098750114440918],
        [74.78736114501982, 13.099029541015625],
        [74.7867965698245, 13.099029541015625],
        [74.7867965698245, 13.099310874939079],
        [74.78624725341808, 13.09930610656744],
        [74.78624725341808, 13.099571228027287],
        [74.78541564941429, 13.099583625793514],
        [74.78541564941429, 13.099862098693904],
        [74.78485870361334, 13.099860191345272],
        [74.78485870361334, 13.100152015685978],
        [74.78430938720732, 13.100152015685978],
        [74.78430175781278, 13.100420951843262],
        [74.78125000000028, 13.100420951843262],
        [74.78125000000028, 13.100152015685978],
        [74.7806930541995, 13.100152015685978],
        [74.7806930541995, 13.099860191345272],
        [74.78013610839855, 13.099862098693904],
        [74.78013610839855, 13.099583625793514],
        [74.77986145019537, 13.099583625793514],
        [74.77986145019537, 13.09930610656744],
        [74.7784729003908, 13.099310874939079],
        [74.7784729003908, 13.098750114440918],
        [74.77820587158232, 13.098750114440918],
        [74.77819061279325, 13.098189353942985],
        [74.77791595458984, 13.098194122314453],
        [74.77791595458984, 13.09736156463623],
        [74.77803802490246, 13.09736156463623],
        [74.77819061279325, 13.097359657287598],
        [74.77819061279325, 13.096249580383244],
        [74.77791595458984, 13.096249580383244],
        [74.77791595458984, 13.095140457153377],
        [74.77819061279325, 13.095140457153377],
        [74.77819061279325, 13.094861984252987],
        [74.77791595458984, 13.094861984252987],
        [74.77791595458984, 13.0931911468507],
        [74.77819061279325, 13.093194007873592],
        [74.77819824218778, 13.092639923095703],
        [74.7784729003908, 13.092641830444279],
        [74.7784729003908, 13.092081069946346],
        [74.77902984619158, 13.092083930969238],
        [74.77902984619158, 13.091806411743164],
        [74.77929687500006, 13.091810226440487],
        [74.77929687500006, 13.09097957611084],
        [74.77958679199213, 13.09097957611084],
        [74.77958679199213, 13.090690612793026],
        [74.77986145019537, 13.090693473815918],
        [74.77986145019537, 13.089860916137809],
        [74.78013610839855, 13.089860916137809],
        [74.78013610839855, 13.089580535888672],
        [74.77986145019537, 13.089583396911735],
        [74.77986145019537, 13.089304924011344],
        [74.77958679199213, 13.089309692382812],
        [74.77958679199213, 13.088191032409611],
        [74.77930450439476, 13.08819484710699],
        [74.77930450439476, 13.086251258850098],
        [74.77902984619158, 13.086251258850098],
        [74.77902984619158, 13.085691452026424],
        [74.7784729003908, 13.085695266723576],
        [74.7784729003908, 13.085416793823242],
        [74.77819824218778, 13.085416793823242],
        [74.77819824218778, 13.085695266723576],
        [74.77791595458984, 13.085695266723576],
        [74.77791595458984, 13.085970878601074],
        [74.77708435058605, 13.085970878601074],
        [74.77708435058605, 13.085416793823242],
        [74.7765274047851, 13.085421562194881],
        [74.7765274047851, 13.08458137512207],
        [74.77625274658226, 13.084583282470646],
        [74.77625274658226, 13.081526756286678],
        [74.77597045898455, 13.081530570983887],
        [74.77597045898455, 13.081811904907283],
        [74.7756958007813, 13.081805229187069],
        [74.7756958007813, 13.082082748413143],
        [74.77514648437528, 13.082082748413143],
        [74.77514648437528, 13.08264064788824],
        [74.77430725097656, 13.082638740539608],
        [74.77430725097656, 13.082830429077205],
        [74.77430725097656, 13.082917213439998],
        [74.77402496337919, 13.082917213439998],
        [74.77402496337919, 13.083194732666072],
        [74.77348327636713, 13.083189964294434],
        [74.77348327636713, 13.083750724792594],
        [74.77319335937506, 13.08374881744379],
        [74.77319335937506, 13.084583282470646],
        [74.77291870117205, 13.08458137512207],
        [74.77291870117205, 13.085421562194881],
        [74.77263641357445, 13.085421562194881],
        [74.77263641357445, 13.086251258850098],
        [74.77236175537126, 13.086251258850098],
        [74.77236175537126, 13.087361335754451],
        [74.77208709716825, 13.087361335754451],
        [74.77208709716825, 13.089583396911735],
        [74.77179718017595, 13.089580535888672],
        [74.77179718017595, 13.0901393890382],
        [74.77153015136747, 13.0901393890382],
        [74.77153015136747, 13.090693473815918],
        [74.77124786376953, 13.090690612793026],
        [74.77124786376953, 13.091810226440487],
        [74.77097320556669, 13.091806411743164],
        [74.77097320556669, 13.092362403869629],
        [74.7706909179688, 13.092362403869629],
        [74.7706909179688, 13.093751907348633],
        [74.77041625976574, 13.093751907348633],
        [74.77041625976574, 13.09430980682373],
        [74.77014160156256, 13.09430980682373],
        [74.77014160156256, 13.094861984252987],
        [74.76985931396479, 13.094861984252987],
        [74.76985931396479, 13.09542274475092],
        [74.76958465576195, 13.095417976379451],
        [74.76958465576195, 13.0970840454101],
        [74.76930236816423, 13.097079277038631],
        [74.76930236816423, 13.099310874939079],
        [74.76902008056646, 13.099310874939079],
        [74.76902008056646, 13.100420951843262],
        [74.76875305175798, 13.100420951843262],
        [74.76875305175798, 13.101249694824332],
        [74.76847076416044, 13.101249694824332],
        [74.76847076416044, 13.101531028747615],
        [74.76819610595703, 13.101528167724723],
        [74.76819610595703, 13.102082252502441],
        [74.76847076416044, 13.102082252502441],
        [74.76847076416044, 13.103194236755371],
        [74.76819610595703, 13.103190422058162],
        [74.76819610595703, 13.103750228881836],
        [74.76790618896513, 13.103750228881836],
        [74.76790618896513, 13.105140686035156],
        [74.7676391601563, 13.105137825012264],
        [74.7676391601563, 13.10708332061779],
        [74.76736450195324, 13.10708332061779],
        [74.76736450195324, 13.10819530487072],
        [74.76708221435553, 13.108189582824764],
        [74.76708221435553, 13.109309196472282],
        [74.76680755615229, 13.109305381774902],
        [74.76680755615229, 13.110694885253906],
        [74.76653289794945, 13.110690116882267],
        [74.76653289794945, 13.111811637878532],
        [74.76625061035173, 13.11180496215826],
        [74.76625061035173, 13.113195419311637],
        [74.7659759521485, 13.113190650939941],
        [74.7659759521485, 13.11431026458746],
        [74.76568603515642, 13.11431026458746],
        [74.76568603515642, 13.115420341491813],
        [74.76541900634794, 13.115416526794434],
        [74.76541900634794, 13.115973472595215],
        [74.76513671875, 13.115970611572322],
        [74.76513671875, 13.116811752319393],
        [74.76486206054716, 13.116811752319393],
        [74.76486206054716, 13.117361068725643],
        [74.76457977294922, 13.117361068725643],
        [74.76457977294922, 13.119584083557243],
        [74.76431274414074, 13.11958122253418],
        [74.76431274414074, 13.121809959411735],
        [74.76403045654303, 13.121809959411735],
        [74.76403045654303, 13.122639656066895],
        [74.76374816894526, 13.122639656066895],
        [74.76374816894526, 13.123749732971248],
        [74.76347351074241, 13.123749732971248],
        [74.76347351074241, 13.124584197998104],
        [74.7631912231447, 13.124579429626465],
        [74.7631912231447, 13.125419616699276],
        [74.76291656494146, 13.125419616699276],
        [74.76291656494146, 13.12652969360363],
        [74.76264190673845, 13.126527786254883],
        [74.76264190673845, 13.127362251281738],
        [74.76235961914091, 13.127362251281738],
        [74.76235961914091, 13.130694389343375],
        [74.7620849609375, 13.130694389343375],
        [74.7620849609375, 13.13097190856945],
        [74.76180267334013, 13.130970001220703],
        [74.76180267334013, 13.131251335144157],
        [74.76152801513672, 13.131251335144157],
        [74.76152801513672, 13.13347339630127],
        [74.76125335693371, 13.13346958160406],
        [74.76125335693371, 13.134310722351188],
        [74.760971069336, 13.134310722351188],
        [74.760971069336, 13.134860992431697],
        [74.76069641113276, 13.134860992431697],
        [74.76069641113276, 13.136251449585018],
        [74.76040649414068, 13.136251449585018],
        [74.76040649414068, 13.138751029968262],
        [74.76014709472673, 13.138751029968262],
        [74.76014709472673, 13.139321327209473],
        [74.75986480712896, 13.139305114746094],
        [74.75986480712896, 13.14097118377697],
        [74.75958251953142, 13.14097118377697],
        [74.75958251953142, 13.142083168029899],
        [74.75930786132841, 13.142083168029899],
        [74.75930786132841, 13.143193244934082],
        [74.759033203125, 13.14319133758545],
        [74.759033203125, 13.144029617309684],
        [74.75875091552763, 13.144026756286621],
        [74.75875091552763, 13.144582748413086],
        [74.75846862792969, 13.144582748413086],
        [74.75846862792969, 13.147084236145076],
        [74.7579193115235, 13.147084236145076],
        [74.7579193115235, 13.150693893432617],
        [74.7576370239259, 13.150691986084041],
        [74.7576370239259, 13.151530265808105],
        [74.75736236572271, 13.151530265808105],
        [74.75736236572271, 13.152359962463436],
        [74.75708007812494, 13.152359962463436],
        [74.75708007812494, 13.152540206909123],
        [74.75708007812494, 13.153471946716422],
        [74.75695800781256, 13.153471946716422],
        [74.7568054199221, 13.153471946716422],
        [74.7568054199221, 13.154680252075309],
        [74.7568054199221, 13.154862403869743],
        [74.75653076171892, 13.154862403869743],
        [74.75653076171892, 13.155694007873535],
        [74.75624847412115, 13.155690193176326],
        [74.75624847412115, 13.156531333923454],
        [74.75596618652361, 13.156531333923454],
        [74.75596618652361, 13.157920837402287],
        [74.7556915283206, 13.157920837402287],
        [74.7556915283206, 13.158750534057674],
        [74.75541687011719, 13.158750534057674],
        [74.75541687011719, 13.159860610962028],
        [74.75514221191435, 13.159860610962028],
        [74.75514221191435, 13.160695075988883],
        [74.75485992431646, 13.160690307617244],
        [74.75485992431646, 13.162920951843375],
        [74.75459289550776, 13.162920951843375],
        [74.75459289550776, 13.163750648498592],
        [74.75430297851568, 13.16374874114996],
        [74.75430297851568, 13.164583206176815],
        [74.75402069091791, 13.164581298828239],
        [74.75402069091791, 13.165422439575138],
        [74.75374603271507, 13.165422439575138],
        [74.75374603271507, 13.166259765625057],
        [74.75347137451189, 13.166251182556266],
        [74.75347137451189, 13.16736125946045],
        [74.75319671630865, 13.16736125946045],
        [74.75319671630865, 13.168194770812988],
        [74.75291442871111, 13.168194770812988],
        [74.75291442871111, 13.169304847717342],
        [74.7526397705081, 13.169301033019963],
        [74.7526397705081, 13.169860839843807],
        [74.75235748291016, 13.169860839843807],
        [74.75235748291016, 13.170139312744197],
        [74.75208282470732, 13.170139312744197],
        [74.75208282470732, 13.172362327575797],
        [74.75180816650396, 13.172362327575797],
        [74.75180816650396, 13.173195838928336],
        [74.75152587890636, 13.173191070556697],
        [74.75152587890636, 13.174309730529899],
        [74.75125122070318, 13.174309730529899],
        [74.75125122070318, 13.175140380859375],
        [74.75097656249994, 13.175140380859375],
        [74.75097656249994, 13.176531791687012],
        [74.75041961669939, 13.176531791687012],
        [74.75041961669939, 13.176810264587402],
        [74.75013732910162, 13.176810264587402],
        [74.75013732910162, 13.17903041839611],
        [74.74986267089861, 13.179027557373047],
        [74.74986267089861, 13.179583549499512],
        [74.74958038330107, 13.179579734802303],
        [74.74958038330107, 13.17986011505127],
        [74.74931335449219, 13.17986011505127],
        [74.74931335449219, 13.181530952453613],
        [74.74903106689482, 13.181528091430721],
        [74.74903106689482, 13.18208217620861],
        [74.74874877929688, 13.182080268859806],
        [74.74874877929688, 13.18264007568365],
        [74.74847412109386, 13.182638168335075],
        [74.7484664916995, 13.18346118927002],
        [74.7484664916995, 13.183750152588004],
        [74.74819183349615, 13.183750152588004],
        [74.74819183349615, 13.184304237365723],
        [74.74791717529291, 13.184300422668514],
        [74.74791717529291, 13.185140609741325],
        [74.74764251709007, 13.185137748718262],
        [74.74764251709007, 13.185973167419434],
        [74.74736022949236, 13.185970306396541],
        [74.74736022949236, 13.186531066894474],
        [74.74709320068365, 13.186531066894474],
        [74.74709320068365, 13.186811447143612],
        [74.74680328369158, 13.186811447143612],
        [74.74680328369158, 13.187370300292969],
        [74.74652862548857, 13.187380790710392],
        [74.74652862548857, 13.187920570373535],
        [74.74635314941435, 13.187916755676326],
        [74.7462463378908, 13.187916755676326],
        [74.7462463378908, 13.188195228576717],
        [74.74597167968778, 13.188190460205078],
        [74.74597167968778, 13.188751220703182],
        [74.74569702148438, 13.188751220703182],
        [74.74569702148438, 13.189582824706974],
        [74.745414733887, 13.189582824706974],
        [74.745414733887, 13.189769744873104],
        [74.74540710449247, 13.190140724182243],
        [74.74514007568365, 13.19013881683344],
        [74.74514007568365, 13.190240859985352],
        [74.74514007568365, 13.190694808959904],
        [74.74497985839866, 13.190694808959904],
        [74.74485778808605, 13.190694808959904],
        [74.74485778808605, 13.190973281860295],
        [74.74458312988287, 13.190971374511719],
        [74.74458312988287, 13.191248893737793],
        [74.74430847167986, 13.191248893737793],
        [74.74430847167986, 13.19181156158453],
        [74.74402618408226, 13.19181156158453],
        [74.74402618408226, 13.192921638488883],
        [74.74375152587908, 13.192921638488883],
        [74.74375152587908, 13.1937513351441],
        [74.7434692382813, 13.1937513351441],
        [74.7434692382813, 13.19491100311285],
        [74.7434692382813, 13.198196411132812],
        [74.74318695068376, 13.198190689087028],
        [74.74318695068376, 13.200141906738281],
        [74.74291992187528, 13.200141906738281],
        [74.74291992187528, 13.201530456543026],
        [74.74263763427734, 13.201530456543026],
        [74.74263763427734, 13.202639579772892],
        [74.7423629760745, 13.202639579772892],
        [74.7423629760745, 13.203749656677246],
        [74.74208068847662, 13.203749656677246],
        [74.74208068847662, 13.204584121704102],
        [74.74180603027355, 13.204584121704102],
        [74.74180603027355, 13.205694198608455],
        [74.74153137207037, 13.205689430236816],
        [74.74153137207037, 13.20681095123291],
        [74.7412490844726, 13.20681095123291],
        [74.7412490844726, 13.20763969421398],
        [74.74097442626976, 13.207637786865234],
        [74.74097442626976, 13.20847225189209],
        [74.74069213867205, 13.208470344543457],
        [74.74069213867205, 13.209031105041618],
        [74.7404174804688, 13.209031105041618],
        [74.7404174804688, 13.209310531616268],
        [74.74013519287126, 13.209305763244629],
        [74.74013519287126, 13.212081909179801],
        [74.73986053466825, 13.212080001831055],
        [74.73986053466825, 13.213750839233512],
        [74.73958587646484, 13.213750839233512],
        [74.73958587646484, 13.214860916137695],
        [74.73930358886747, 13.214860916137695],
        [74.73930358886747, 13.21569538116455],
        [74.73902893066412, 13.21569538116455],
        [74.73902893066412, 13.217082977294979],
        [74.73874664306652, 13.217080116271916],
        [74.73874664306652, 13.21875095367443],
        [74.73847198486334, 13.21875095367443],
        [74.73847198486334, 13.22014141082775],
        [74.7381973266601, 13.220138549804688],
        [74.7381973266601, 13.220694541931152],
        [74.73792266845726, 13.220690727233944],
        [74.73792266845726, 13.22153186798107],
        [74.73764038085955, 13.221527099609432],
        [74.73764038085955, 13.222360610961971],
        [74.73735809326178, 13.222360610961971],
        [74.73735809326178, 13.222641944885254],
        [74.73707580566423, 13.222641944885254],
        [74.73707580566423, 13.222920417785645],
        [74.73680877685575, 13.222916603088436],
        [74.73680877685575, 13.22319316864025],
        [74.73652648925781, 13.223191261291447],
        [74.73652648925781, 13.223480224609432],
        [74.73625183105497, 13.223480224609432],
        [74.73625183105497, 13.223752021789608],
        [74.73597717285162, 13.223752021789608],
        [74.73597717285162, 13.224029541015682],
        [74.73569488525402, 13.22402667999279],
        [74.73569488525402, 13.228471755981502],
        [74.73542022705084, 13.22846889495844],
        [74.73542022705084, 13.229310035705566],
        [74.73513793945307, 13.229310035705566],
        [74.73513793945307, 13.23042011260992],
        [74.73486328125023, 13.23042011260992],
        [74.73486328125023, 13.231530189514274],
        [74.73458099365251, 13.231530189514274],
        [74.73458099365251, 13.232640266418457],
        [74.73430633544928, 13.232640266418457],
        [74.73430633544928, 13.23375034332281],
        [74.7340164184572, 13.23375034332281],
        [74.7340164184572, 13.235139846801815],
        [74.73374938964872, 13.235137939453239],
        [74.73374938964872, 13.235693931579704],
        [74.7340164184572, 13.235692024230957],
        [74.7340164184572, 13.236249923706168],
        [74.73374938964872, 13.236249923706168],
        [74.73374938964872, 13.237084388733024],
        [74.73346710205095, 13.237079620361385],
        [74.73346710205095, 13.238751411437988],
        [74.73319244384794, 13.238751411437988],
        [74.73319244384794, 13.240421295165959],
        [74.73291015625, 13.240421295165959],
        [74.73291015625, 13.241808891296444],
        [74.73263549804716, 13.24180698394781],
        [74.73263549804716, 13.243473052978459],
        [74.7323608398438, 13.243471145629883],
        [74.7323608398438, 13.24514102935791],
        [74.73208618164057, 13.245139122009277],
        [74.73208618164057, 13.247361183166618],
        [74.7317962646485, 13.247361183166618],
        [74.7317962646485, 13.249309539794979],
        [74.73152923584001, 13.249309539794979],
        [74.73152923584001, 13.250419616699332],
        [74.73124694824241, 13.250419616699332],
        [74.73124694824241, 13.251529693603516],
        [74.73097229003923, 13.251529693603516],
        [74.73097229003923, 13.252641677856445],
        [74.73069763183622, 13.252641677856445],
        [74.73069763183622, 13.253749847412223],
        [74.73041534423845, 13.253749847412223],
        [74.73042297363281, 13.254861831664982],
        [74.73014068603544, 13.254861831664982],
        [74.73014068603544, 13.256250381469727],
        [74.72985076904297, 13.256250381469727],
        [74.72985076904297, 13.257641792297363],
        [74.72957611084013, 13.257641792297363],
        [74.72957611084013, 13.258749961853141],
        [74.7293090820313, 13.258749961853141],
        [74.7293090820313, 13.260140419006461],
        [74.72902679443371, 13.260140419006461],
        [74.72902679443371, 13.261249542236328],
        [74.72875213623053, 13.261249542236328],
        [74.72875213623053, 13.262361526489258],
        [74.72846984863276, 13.262361526489258],
        [74.72846984863276, 13.263471603393612],
        [74.72819519042991, 13.263471603393612],
        [74.72819519042991, 13.264584541320858],
        [74.7279129028322, 13.264584541320858],
        [74.7279129028322, 13.265693664550724],
        [74.72763824462896, 13.265689849853572],
        [74.72763824462896, 13.26681137084961],
        [74.72735595703142, 13.26681137084961],
        [74.72735595703142, 13.267921447753963],
        [74.72708129882841, 13.267921447753963],
        [74.72708129882841, 13.269030570983887],
        [74.726806640625, 13.269030570983887],
        [74.726806640625, 13.27014064788824],
        [74.72652435302763, 13.270138740539608],
        [74.72652435302763, 13.27069473266613],
        [74.72624969482428, 13.270689964294434],
        [74.72624969482428, 13.271250724792594],
        [74.72598266601557, 13.271250724792594],
        [74.72598266601557, 13.271812438964844],
        [74.7256927490235, 13.271804809570256],
        [74.7256927490235, 13.272360801696777],
        [74.72541809082026, 13.272360801696777],
        [74.72541809082026, 13.272921562194881],
        [74.72513580322288, 13.272916793823242],
        [74.72513580322288, 13.273470878601131],
        [74.7248611450197, 13.273470878601131],
        [74.7248611450197, 13.274031639099235],
        [74.72458648681646, 13.274031639099235],
        [74.72458648681646, 13.274589538574276],
        [74.72429656982439, 13.274589538574276],
        [74.72429656982439, 13.275420188903809],
        [74.72403717041044, 13.275420188903809],
        [74.72403717041044, 13.276529312133846],
        [74.72374725341797, 13.276529312133846],
        [74.72374725341797, 13.277360916137809],
        [74.72347259521513, 13.277360916137809],
        [74.72347259521513, 13.27819633483898],
        [74.72319793701178, 13.278190612793026],
        [74.72319793701178, 13.278749465942383],
        [74.72292327880871, 13.278749465942383],
        [74.72292327880871, 13.279310226440487],
        [74.722640991211, 13.279310226440487],
        [74.722640991211, 13.280141830444279],
        [74.72235870361322, 13.280139923095703],
        [74.72235870361322, 13.280693054199276],
        [74.72207641601568, 13.2806911468507],
        [74.72207641601568, 13.281251907348633],
        [74.7218093872072, 13.281251907348633],
        [74.72180175781267, 13.28180980682373],
        [74.72152709960943, 13.28180980682373],
        [74.72152709960943, 13.282640457153377],
        [74.72125244140642, 13.282637596130314],
        [74.72125244140642, 13.283193588256779],
        [74.72097015380888, 13.283190727233887],
        [74.72097015380888, 13.284029960632438],
        [74.72069549560547, 13.284028053283635],
        [74.72069549560547, 13.287083625793514],
        [74.7204132080081, 13.287079811096135],
        [74.7204132080081, 13.287639617919979],
        [74.72013854980469, 13.287637710571403],
        [74.72013854980469, 13.288193702697868],
        [74.71986389160168, 13.288193702697868],
        [74.71986389160168, 13.290694236755371],
        [74.71958160400396, 13.290690422058162],
        [74.71958160400396, 13.294310569763184],
        [74.71985626220732, 13.294310569763184],
        [74.71985626220732, 13.296251296997013],
        [74.71958160400396, 13.296251296997013],
        [74.71958160400396, 13.296809196472282],
        [74.71930694580072, 13.296805381774902],
        [74.71930694580072, 13.297361373901367],
        [74.71902465820318, 13.297361373901367],
        [74.71902465820318, 13.29958343505865],
        [74.71875000000017, 13.29958343505865],
        [74.71875000000017, 13.302360534667969],
        [74.71848297119146, 13.302360534667969],
        [74.71848297119146, 13.303750991821289],
        [74.71819305419939, 13.303750991821289],
        [74.71819305419939, 13.305138587951717],
        [74.71791076660162, 13.305138587951717],
        [74.71791076660162, 13.30681037902832],
        [74.71763610839861, 13.306805610656852],
        [74.71763610839861, 13.308194160461426],
        [74.7173614501956, 13.308191299438533],
        [74.7173614501956, 13.309309959411735],
        [74.71763610839861, 13.309306144714355],
        [74.71763610839861, 13.30986213684082],
        [74.7173614501956, 13.309860229492244],
        [74.7173614501956, 13.310420036315918],
        [74.71708679199219, 13.31041622161871],
        [74.71708679199219, 13.310972213745174],
        [74.71679687500028, 13.310969352722111],
        [74.71679687500028, 13.311809539794922],
        [74.71652984619146, 13.311809539794922],
        [74.71652984619146, 13.312640190124625],
        [74.71624755859386, 13.312640190124625],
        [74.71624755859386, 13.313760757446403],
        [74.71597290039068, 13.313750267028809],
        [74.71597290039068, 13.314583778381348],
        [74.71569824218767, 13.314583778381348],
        [74.71569824218767, 13.315693855285701],
        [74.71541595459007, 13.315693855285701],
        [74.71541595459007, 13.316805839538631],
        [74.71514129638689, 13.316802024841309],
        [74.71514129638689, 13.31735992431652],
        [74.71486663818388, 13.31735992431652],
        [74.71486663818388, 13.317920684814453],
        [74.71458435058611, 13.317915916442985],
        [74.71458435058611, 13.318471908569279],
        [74.71430206298857, 13.318470001220703],
        [74.71430206298857, 13.319030761718807],
        [74.71402740478516, 13.319030761718807],
        [74.71402740478516, 13.32153129577631],
        [74.71375274658232, 13.321525573730582],
        [74.71375274658232, 13.322083473205623],
        [74.71347045898443, 13.322079658508244],
        [74.71347045898443, 13.322641372680664],
        [74.71319580078136, 13.322639465332088],
        [74.71319580078136, 13.323195457458553],
        [74.71290588378912, 13.323189735412598],
        [74.71290588378912, 13.323751449585018],
        [74.71263885498041, 13.323751449585018],
        [74.71263885498041, 13.324309349060115],
        [74.71235656738304, 13.324309349060115],
        [74.71235656738304, 13.325421333313045],
        [74.71208190917986, 13.325416564941406],
        [74.71208190917986, 13.327082633972225],
        [74.71180725097662, 13.327082633972225],
        [74.71180725097662, 13.328194618225154],
        [74.71151733398455, 13.328190803527775],
        [74.71151733398455, 13.329031944274902],
        [74.71125030517607, 13.329027175903434],
        [74.71125030517607, 13.329583168029899],
        [74.71098327636719, 13.329581260681152],
        [74.71098327636719, 13.330142021179256],
        [74.71069335937528, 13.330139160156364],
        [74.71069335937528, 13.330693244934082],
        [74.71041870117193, 13.330691337585506],
        [74.71041870117193, 13.331249237060547],
        [74.71013641357433, 13.331249237060547],
        [74.71013641357433, 13.331805229187012],
        [74.70986175537115, 13.331805229187012],
        [74.70986175537115, 13.332361221313477],
        [74.70958709716791, 13.3323593139649],
        [74.70958709716791, 13.332921981811637],
        [74.70930480957054, 13.332915306091365],
        [74.70930480957054, 13.333195686340332],
        [74.70903015136736, 13.333190917968864],
        [74.70903015136736, 13.333751678466797],
        [74.70874023437523, 13.333751678466797],
        [74.70874023437523, 13.334030151367188],
        [74.70847320556658, 13.334030151367188],
        [74.70847320556658, 13.334309577941895],
        [74.70819091796903, 13.334309577941895],
        [74.70819091796903, 13.334871292114315],
        [74.70791625976562, 13.334871292114315],
        [74.70791625976562, 13.335140228271541],
        [74.70764160156278, 13.335140228271541],
        [74.70764160156278, 13.33569431304943],
        [74.70735931396484, 13.335691452026367],
        [74.70735931396484, 13.336250305175895],
        [74.70707702636747, 13.336250305175895],
        [74.70707702636747, 13.336529731750602],
        [74.70680236816412, 13.336527824401799],
        [74.70680236816412, 13.337083816528263],
        [74.70652770996088, 13.337083816528263],
        [74.70652770996088, 13.337362289428654],
        [74.70625305175804, 13.337360382080078],
        [74.70625305175804, 13.337639808654785],
        [74.70597076416033, 13.337639808654785],
        [74.70597076416033, 13.337931632995605],
        [74.70569610595709, 13.337916374206543],
        [74.70569610595709, 13.338193893432617],
        [74.70541381835955, 13.338193893432617],
        [74.70541381835955, 13.338472366333008],
        [74.70513916015653, 13.338470458984432],
        [74.70513916015653, 13.338751792907715],
        [74.70485687255876, 13.338751792907715],
        [74.70485687255876, 13.339030265808105],
        [74.7046966552735, 13.339030265808105],
        [74.70458221435575, 13.339030265808105],
        [74.70458221435575, 13.339310646057243],
        [74.70402526855497, 13.339305877685604],
        [74.70402526855497, 13.339584350585994],
        [74.70375061035162, 13.339578628540039],
        [74.70375061035162, 13.339859962463493],
        [74.7031860351563, 13.339859962463493],
        [74.7031860351563, 13.34014034271246],
        [74.70292663574236, 13.34014034271246],
        [74.70292663574236, 13.340422630310172],
        [74.70236206054705, 13.340415954589957],
        [74.70236206054705, 13.340694427490348],
        [74.70180511474626, 13.340694427490348],
        [74.70180511474626, 13.340971946716422],
        [74.70153045654325, 13.340971946716422],
        [74.70153045654325, 13.341250419616813],
        [74.70096588134794, 13.341250419616813],
        [74.70096588134794, 13.341532707214355],
        [74.70069122314459, 13.341532707214355],
        [74.70069122314459, 13.341811180114746],
        [74.70014190673834, 13.341806411743278],
        [74.70014190673834, 13.342082023620605],
        [74.69958496093773, 13.342082023620605],
        [74.69958496093773, 13.342362403869743],
        [74.69930267334001, 13.34235954284668],
        [74.69930267334001, 13.342639923095817],
        [74.69875335693376, 13.342639923095817],
        [74.69875335693376, 13.3429212570191],
        [74.69847106933622, 13.342915534973145],
        [74.69847106933622, 13.343194007873535],
        [74.69791412353544, 13.343194007873535],
        [74.69791412353544, 13.34347152709961],
        [74.69736480712902, 13.34347152709961],
        [74.69736480712902, 13.34375],
        [74.6970825195313, 13.343741416931152],
        [74.6970825195313, 13.344031333923454],
        [74.69653320312523, 13.344031333923454],
        [74.69653320312523, 13.344310760498104],
        [74.69596862792974, 13.344305992126465],
        [74.69596862792974, 13.344584465026855],
        [74.6956863403322, 13.344579696655387],
        [74.6956863403322, 13.344860076904354],
        [74.69513702392578, 13.344860076904354],
        [74.69513702392578, 13.345141410827637],
        [74.69486236572294, 13.345141410827637],
        [74.69486236572294, 13.345420837402287],
        [74.694580078125, 13.345416069030819],
        [74.694580078125, 13.345693588256893],
        [74.69430541992199, 13.345693588256893],
        [74.69430541992199, 13.345972061157283],
        [74.6940307617188, 13.345970153808707],
        [74.6940307617188, 13.346250534057674],
        [74.69374084472668, 13.346250534057674],
        [74.69374084472668, 13.34653091430664],
        [74.6934661865235, 13.34653091430664],
        [74.6934661865235, 13.346811294555607],
        [74.69291687011724, 13.346806526184139],
        [74.69291687011724, 13.347082138061467],
        [74.69264221191423, 13.347082138061467],
        [74.69264221191423, 13.34735965728754],
        [74.69235992431669, 13.34735965728754],
        [74.69235992431669, 13.347640991210994],
        [74.69180297851591, 13.347640991210994],
        [74.69180297851591, 13.347921371459961],
        [74.69125366210949, 13.347916603088322],
        [74.69125366210949, 13.348195075988883],
        [74.69097137451178, 13.348195075988883],
        [74.69097137451178, 13.348472595214787],
        [74.69040679931646, 13.348469734191895],
        [74.69040679931646, 13.349031448364315],
        [74.69124603271513, 13.349031448364315],
        [74.69124603271513, 13.348751068115178],
        [74.69180297851591, 13.348751068115178],
        [74.69180297851591, 13.348469734191895],
        [74.69235992431669, 13.348472595214787],
        [74.69235992431669, 13.348195075988883],
        [74.69291687011724, 13.348195075988883],
        [74.69291687011724, 13.347916603088322],
        [74.69319152832048, 13.347921371459961],
        [74.69319152832048, 13.347640991210994],
        [74.69374084472668, 13.347640991210994],
        [74.69374084472668, 13.34735965728754],
        [74.69430541992199, 13.34735965728754],
        [74.69430541992199, 13.347082138061467],
        [74.69486236572294, 13.347082138061467],
        [74.69486236572294, 13.346806526184139],
        [74.69541931152372, 13.346811294555607],
        [74.69541931152372, 13.34653091430664],
        [74.69596862792974, 13.34653091430664],
        [74.69596862792974, 13.346250534057674],
        [74.69653320312523, 13.346250534057674],
        [74.69653320312523, 13.345970153808707],
        [74.69680786132807, 13.345972061157283],
        [74.69680786132807, 13.345693588256893],
        [74.69736480712902, 13.345693588256893],
        [74.69736480712902, 13.345416069030819],
        [74.69763946533209, 13.345420837402287],
        [74.69763946533209, 13.345141410827637],
        [74.69819641113281, 13.345141410827637],
        [74.69819641113281, 13.344860076904354],
        [74.69847106933622, 13.344860076904354],
        [74.69847106933622, 13.344579696655387],
        [74.69902801513678, 13.344584465026855],
        [74.69902801513678, 13.344305992126465],
        [74.69930267334001, 13.344310760498104],
        [74.69930267334001, 13.344031333923454],
        [74.69986724853533, 13.344031333923454],
        [74.69986724853533, 13.343741416931152],
        [74.70014190673834, 13.34375],
        [74.70014190673834, 13.34347152709961],
        [74.70069122314459, 13.34347152709961],
        [74.70069122314459, 13.343194007873535],
        [74.70124816894531, 13.343194007873535],
        [74.70124816894531, 13.342915534973145],
        [74.70181274414062, 13.3429212570191],
        [74.70181274414062, 13.342639923095817],
        [74.70236206054705, 13.342639923095817],
        [74.70236206054705, 13.34235954284668],
        [74.70263671875023, 13.342362403869743],
        [74.70263671875023, 13.342082023620605],
        [74.70319366455084, 13.342082023620605],
        [74.70319366455084, 13.341806411743278],
        [74.70375061035162, 13.341811180114746],
        [74.70375061035162, 13.341532707214355],
        [74.70430755615234, 13.341532707214355],
        [74.70430755615234, 13.341250419616813],
        [74.70513916015653, 13.341250419616813],
        [74.70513916015653, 13.34014034271246],
        [74.70526123046875, 13.34014034271246],
        [74.70540618896501, 13.34014034271246],
        [74.70540618896501, 13.339578628540039],
        [74.70597076416033, 13.339584350585994],
        [74.70597076416033, 13.339305877685604],
        [74.70614624023432, 13.33930778503418],
        [74.70625305175804, 13.339310646057243],
        [74.70625305175804, 13.339120864868278],
        [74.70625305175804, 13.339028358459473],
        [74.70652770996088, 13.339030265808105],
        [74.70652770996088, 13.338751792907715],
        [74.70707702636747, 13.338751792907715],
        [74.70707702636747, 13.338470458984432],
        [74.70652770996088, 13.338472366333008],
        [74.70652770996088, 13.338193893432617],
        [74.70680236816412, 13.338193893432617],
        [74.70680236816412, 13.337916374206543],
        [74.70691680908197, 13.337920188903922],
        [74.70735931396484, 13.337931632995605],
        [74.70735931396484, 13.337639808654785],
        [74.70764160156278, 13.337639808654785],
        [74.70764160156278, 13.337360382080078],
        [74.70819091796903, 13.337362289428654],
        [74.70819091796903, 13.337083816528263],
        [74.70847320556658, 13.337083816528263],
        [74.70847320556658, 13.33680629730219],
        [74.70903015136736, 13.336810111999569],
        [74.70903015136736, 13.336529731750602],
        [74.70929718017584, 13.336529731750602],
        [74.70929718017584, 13.335140228271541],
        [74.70958709716791, 13.335140228271541],
        [74.70958709716791, 13.334871292114315],
        [74.70986175537115, 13.334871292114315],
        [74.70986175537115, 13.334309577941895],
        [74.71013641357433, 13.334309577941895],
        [74.71013641357433, 13.334030151367188],
        [74.71041870117193, 13.334030151367188],
        [74.71041870117193, 13.333751678466797],
        [74.71069335937528, 13.333751678466797],
        [74.71069335937528, 13.33347129821783],
        [74.71097564697266, 13.33347129821783],
        [74.71097564697266, 13.333195686340332],
        [74.71125030517607, 13.333195686340332],
        [74.71125030517607, 13.332639694213867],
        [74.71151733398455, 13.332639694213867],
        [74.71151733398455, 13.3323593139649],
        [74.71180725097662, 13.332361221313477],
        [74.71180725097662, 13.332082748413086],
        [74.71208190917986, 13.332082748413086],
        [74.71208190917986, 13.331805229187012],
        [74.71236419677757, 13.331805229187012],
        [74.71236419677757, 13.331526756286621],
        [74.71263885498041, 13.331529617309684],
        [74.71263885498041, 13.330970764160156],
        [74.71291351318365, 13.330970764160156],
        [74.71290588378912, 13.330691337585506],
        [74.71319580078136, 13.330693244934082],
        [74.71319580078136, 13.329583168029899],
        [74.71347045898443, 13.329583168029899],
        [74.71347045898443, 13.328473091125545],
        [74.71375274658232, 13.328473091125545],
        [74.71375274658232, 13.32763862609869],
        [74.71402740478516, 13.327640533447266],
        [74.71402740478516, 13.326531410217399],
        [74.71430206298857, 13.326531410217399],
        [74.71430206298857, 13.326251029968262],
        [74.71457672119158, 13.326251029968262],
        [74.71457672119158, 13.325701713562012],
        [74.71486663818388, 13.325701713562012],
        [74.71486663818388, 13.325421333313045],
        [74.71514129638689, 13.325421333313045],
        [74.71514129638689, 13.324860572814941],
        [74.71541595459007, 13.324860572814941],
        [74.71541595459007, 13.323748588561955],
        [74.71569061279291, 13.323751449585018],
        [74.71569824218767, 13.322930335998478],
        [74.71597290039068, 13.322930335998478],
        [74.71597290039068, 13.322381019592285],
        [74.71624755859386, 13.322360992431697],
        [74.71624755859386, 13.32184982299816],
        [74.71624755859386, 13.321250915527344],
        [74.71649932861328, 13.321250915527344],
        [74.71649932861328, 13.321139335632324],
        [74.71652984619146, 13.319581985473633],
        [74.71680450439482, 13.319581985473633],
        [74.71680450439482, 13.317915916442985],
        [74.71708679199219, 13.317920684814453],
        [74.71708679199219, 13.314581871032772],
        [74.7173614501956, 13.314583778381348],
        [74.7173614501956, 13.313471794128418],
        [74.71763610839861, 13.313471794128418],
        [74.71763610839861, 13.311809539794922],
        [74.71791076660162, 13.311809539794922],
        [74.71791076660162, 13.310969352722111],
        [74.71819305419939, 13.310972213745174],
        [74.71819305419939, 13.310693740844783],
        [74.71791076660162, 13.310701370239371],
        [74.71791076660162, 13.309860229492244],
        [74.71819305419939, 13.30986213684082],
        [74.71819305419939, 13.309027671813965],
        [74.71848297119146, 13.309029579162598],
        [74.71848297119146, 13.308191299438533],
        [74.71875000000017, 13.308194160461426],
        [74.71875000000017, 13.307084083557243],
        [74.71902465820318, 13.307084083557243],
        [74.71902465820318, 13.305971145629996],
        [74.71930694580072, 13.305971145629996],
        [74.71930694580072, 13.305138587951717],
        [74.71958160400396, 13.305138587951717],
        [74.71958160400396, 13.304570198059082],
        [74.71986389160168, 13.304582595825252],
        [74.71986389160168, 13.301251411438102],
        [74.71958160400396, 13.301251411438102],
        [74.71958160400396, 13.300141334533748],
        [74.71985626220732, 13.300141334533748],
        [74.71985626220732, 13.298471450805721],
        [74.72013854980469, 13.298473358154297],
        [74.72013854980469, 13.296528816223088],
        [74.7204132080081, 13.296530723571891],
        [74.7204132080081, 13.291250228881836],
        [74.720703125, 13.291250228881836],
        [74.72069549560547, 13.289028167724723],
        [74.72097015380888, 13.289031028747615],
        [74.72097015380888, 13.287360191345329],
        [74.72125244140642, 13.287362098693904],
        [74.72125244140642, 13.286250114440975],
        [74.72152709960943, 13.286250114440975],
        [74.72152709960943, 13.285140037536621],
        [74.72180175781267, 13.285140037536621],
        [74.72180175781267, 13.284029960632438],
        [74.72207641601568, 13.284029960632438],
        [74.72207641601568, 13.28345966339117],
        [74.72235870361322, 13.28347206115717],
        [74.72235870361322, 13.283193588256779],
        [74.722640991211, 13.283193588256779],
        [74.722640991211, 13.282637596130314],
        [74.72292327880871, 13.282640457153377],
        [74.72292327880871, 13.282080650329533],
        [74.72319793701178, 13.282084465026912],
        [74.72319793701178, 13.281528472900447],
        [74.72347259521513, 13.281530380249023],
        [74.72347259521513, 13.2806911468507],
        [74.72374725341797, 13.280693054199276],
        [74.72374725341797, 13.279862403869629],
        [74.72402954101591, 13.279862403869629],
        [74.72402954101591, 13.279583930969238],
        [74.72430419921892, 13.279583930969238],
        [74.72430419921892, 13.279026985168457],
        [74.72458648681646, 13.279031753540096],
        [74.72458648681646, 13.278749465942383],
        [74.7248611450197, 13.278749465942383],
        [74.7248611450197, 13.278470993041992],
        [74.72513580322288, 13.278470993041992],
        [74.72513580322288, 13.27819633483898],
        [74.7256927490235, 13.27819633483898],
        [74.7256927490235, 13.277916908264103],
        [74.72598266601557, 13.277919769287166],
        [74.72598266601557, 13.27765083312994],
        [74.7265167236331, 13.27765083312994],
        [74.7265167236331, 13.277360916137809],
        [74.726806640625, 13.277360916137809],
        [74.726806640625, 13.277080535888672],
        [74.72708129882841, 13.277083396911735],
        [74.72708129882841, 13.276804924011344],
        [74.72846984863276, 13.276804924011344],
        [74.72846984863276, 13.277083396911735],
        [74.72902679443371, 13.277080535888672],
        [74.72902679443371, 13.277919769287166],
        [74.72875213623053, 13.277919769287166],
        [74.72875213623053, 13.279031753540096],
        [74.72902679443371, 13.279031753540096],
        [74.72902679443371, 13.28042030334467],
        [74.7293090820313, 13.28042030334467],
        [74.7293090820313, 13.281530380249023],
        [74.72957611084013, 13.281530380249023],
        [74.72957611084013, 13.282361984252987],
        [74.73014068603544, 13.282361984252987],
        [74.73014068603544, 13.283193588256779],
        [74.73041534423845, 13.283193588256779],
        [74.73041534423845, 13.28347206115717],
        [74.73014068603544, 13.28345966339117],
        [74.73014068603544, 13.284310340881404],
        [74.7298583984375, 13.284305572509766],
        [74.7298583984375, 13.2845840454101],
        [74.7293090820313, 13.2845840454101],
        [74.7293090820313, 13.28486156463623],
        [74.72902679443371, 13.284859657287598],
        [74.72902679443371, 13.285420417785758],
        [74.7293090820313, 13.28541564941412],
        [74.7293090820313, 13.285694122314453],
        [74.73014068603544, 13.285694122314453],
        [74.73014068603544, 13.285971641540584],
        [74.73152923584001, 13.285969734191951],
        [74.73152923584001, 13.286250114440975],
        [74.73208618164057, 13.286250114440975],
        [74.73208618164057, 13.286529541015625],
        [74.7323608398438, 13.286529541015625],
        [74.7323608398438, 13.286820411682186],
        [74.73291778564453, 13.28680610656744],
        [74.73291778564453, 13.287083625793514],
        [74.73347473144531, 13.287083625793514],
        [74.73347473144531, 13.287362098693904],
        [74.73374938964872, 13.287360191345329],
        [74.73374938964872, 13.287639617919979],
        [74.7340164184572, 13.287639617919979],
        [74.7340164184572, 13.287920951843262],
        [74.73430633544928, 13.287916183471793],
        [74.73430633544928, 13.288193702697868],
        [74.73486328125023, 13.288193702697868],
        [74.73486328125023, 13.288472175598258],
        [74.73542022705084, 13.288470268249455],
        [74.73542022705084, 13.288749694824332],
        [74.73568725585966, 13.288749694824332],
        [74.73568725585966, 13.289311408996582],
        [74.73597717285162, 13.289311408996582],
        [74.73597717285162, 13.289590835571232],
        [74.73568725585966, 13.289590835571232],
        [74.73568725585966, 13.290140151977653],
        [74.73513793945307, 13.290140151977653],
        [74.73513793945307, 13.29042053222662],
        [74.73374938964872, 13.29042053222662],
        [74.73374938964872, 13.290140151977653],
        [74.73291015625, 13.290140151977653],
        [74.73291015625, 13.289859771728516],
        [74.73263549804716, 13.289859771728516],
        [74.73263549804716, 13.289582252502441],
        [74.7323608398438, 13.289590835571232],
        [74.7323608398438, 13.289311408996582],
        [74.73208618164057, 13.289311408996582],
        [74.73208618164057, 13.288749694824332],
        [74.7317962646485, 13.288749694824332],
        [74.7317962646485, 13.288191795349121],
        [74.73152923584001, 13.288193702697868],
        [74.73152923584001, 13.287637710571403],
        [74.73124694824241, 13.287639617919979],
        [74.73124694824241, 13.287360191345329],
        [74.7293090820313, 13.287360191345329],
        [74.7293090820313, 13.287639617919979],
        [74.72957611084013, 13.287639617919979],
        [74.72957611084013, 13.287920951843262],
        [74.72875213623053, 13.287916183471793],
        [74.72875213623053, 13.288193702697868],
        [74.7279129028322, 13.288193702697868],
        [74.7279129028322, 13.288472175598258],
        [74.72759246826178, 13.288472175598258],
        [74.72735595703142, 13.288470268249455],
        [74.72735595703142, 13.288749694824332],
        [74.7265167236331, 13.288749694824332],
        [74.7265167236331, 13.289031028747615],
        [74.72598266601557, 13.289031028747615],
        [74.72598266601557, 13.289311408996582],
        [74.72513580322288, 13.289311408996582],
        [74.72513580322288, 13.289590835571232],
        [74.72458648681646, 13.289590835571232],
        [74.72458648681646, 13.289859771728516],
        [74.72429656982439, 13.289859771728516],
        [74.72429656982439, 13.290140151977653],
        [74.72374725341797, 13.290138244628906],
        [74.72374725341797, 13.290971755981445],
        [74.72347259521513, 13.29096984863287],
        [74.72347259521513, 13.29181098937994],
        [74.72319793701178, 13.291804313659725],
        [74.72319793701178, 13.292637825012264],
        [74.72291564941418, 13.292637825012264],
        [74.72291564941418, 13.293473243713436],
        [74.722640991211, 13.293470382690543],
        [74.722640991211, 13.294031143188477],
        [74.72235870361322, 13.294031143188477],
        [74.72235870361322, 13.29514122009283],
        [74.72251892089872, 13.29514122009283],
        [74.722640991211, 13.29514122009283],
        [74.722640991211, 13.296530723571891],
        [74.72292327880871, 13.296530723571891],
        [74.72292327880871, 13.297638893127441],
        [74.72319793701178, 13.297638893127441],
        [74.72319793701178, 13.298760414123535],
        [74.72347259521513, 13.298760414123535],
        [74.72347259521513, 13.299031257629395],
        [74.72319793701178, 13.299027442932186],
        [74.72319793701178, 13.29958343505865],
        [74.72291564941418, 13.29958343505865],
        [74.72292327880871, 13.299860954284782],
        [74.722640991211, 13.299860954284782],
        [74.722640991211, 13.300421714782715],
        [74.72291564941418, 13.300416946411246],
        [74.72291564941418, 13.300971031188965],
        [74.72319793701178, 13.300971031188965],
        [74.72319793701178, 13.301820755004883],
        [74.72347259521513, 13.301804542541504],
        [74.72347259521513, 13.302083015441895],
        [74.72319793701178, 13.302081108093319],
        [74.72319793701178, 13.302920341491813],
        [74.72291564941418, 13.302916526794434],
        [74.72291564941418, 13.303473472595215],
        [74.722640991211, 13.303473472595215],
        [74.722640991211, 13.304582595825252],
        [74.72235870361322, 13.304570198059082],
        [74.72235870361322, 13.304861068725643],
        [74.7218093872072, 13.304861068725643],
        [74.7218093872072, 13.305000305175781],
        [74.72180175781267, 13.305138587951717],
        [74.72152709960943, 13.305138587951717],
        [74.72152709960943, 13.305695533752498],
        [74.7217559814456, 13.305695533752498],
        [74.72208404541038, 13.305695533752498],
        [74.72208404541038, 13.305971145629996],
        [74.72235870361322, 13.30596923828125],
        [74.72235870361322, 13.306251525878963],
        [74.72207641601568, 13.306251525878963],
        [74.72207641601568, 13.30653190612793],
        [74.72235870361322, 13.30653190612793],
        [74.72235870361322, 13.307361602783146],
        [74.72208404541038, 13.307361602783146],
        [74.72208404541038, 13.308194160461426],
        [74.7218093872072, 13.308191299438533],
        [74.72180175781267, 13.30875015258789],
        [74.72152709960943, 13.30875015258789],
        [74.72152709960943, 13.309309959411735],
        [74.72125244140642, 13.309309959411735],
        [74.72125244140642, 13.310139656066951],
        [74.72097015380888, 13.310137748718319],
        [74.72097015380888, 13.312359809875431],
        [74.72069549560547, 13.312359809875431],
        [74.720703125, 13.313471794128418],
        [74.7204132080081, 13.313471794128418],
        [74.7204132080081, 13.314583778381348],
        [74.72013854980469, 13.314581871032772],
        [74.72013854980469, 13.315421104431266],
        [74.71986389160168, 13.315416336059627],
        [74.71986389160168, 13.315693855285701],
        [74.72013854980469, 13.315693855285701],
        [74.72013854980469, 13.315972328186092],
        [74.71986389160168, 13.315972328186092],
        [74.71986389160168, 13.316805839538631],
        [74.71958160400396, 13.316802024841309],
        [74.71958160400396, 13.319030761718807],
        [74.71930694580072, 13.319030761718807],
        [74.71930694580072, 13.32014083862299],
        [74.71901702880865, 13.32014083862299],
        [74.71901702880865, 13.320421218872127],
        [74.71875000000017, 13.320421218872127],
        [74.71875000000017, 13.32153129577631],
        [74.71847534179693, 13.321525573730582],
        [74.71847534179693, 13.322083473205623],
        [74.71875000000017, 13.322083473205623],
        [74.71875000000017, 13.323195457458553],
        [74.71848297119146, 13.323189735412598],
        [74.71848297119146, 13.323751449585018],
        [74.71875000000017, 13.323751449585018],
        [74.71875000000017, 13.324309349060115],
        [74.71847534179693, 13.32430458068842],
        [74.71847534179693, 13.32458305358881],
        [74.71875000000017, 13.32458305358881],
        [74.71875000000017, 13.324860572814941],
        [74.71848297119146, 13.324860572814941],
        [74.71848297119146, 13.325421333313045],
        [74.71819305419939, 13.325416564941406],
        [74.71819305419939, 13.325972557067871],
        [74.71763610839861, 13.325972557067871],
        [74.71763610839861, 13.327361106872615],
        [74.7173614501956, 13.327361106872615],
        [74.7173614501956, 13.327640533447266],
        [74.71708679199219, 13.32763862609869],
        [74.71708679199219, 13.328194618225154],
        [74.71679687500028, 13.328190803527775],
        [74.71679687500028, 13.329031944274902],
        [74.71652984619146, 13.329027175903434],
        [74.71652984619146, 13.329583168029899],
        [74.71597290039068, 13.329581260681152],
        [74.71597290039068, 13.329860687255973],
        [74.71569824218767, 13.329860687255973],
        [74.71569824218767, 13.330142021179256],
        [74.71514129638689, 13.330139160156364],
        [74.71514129638689, 13.330693244934082],
        [74.71485900878912, 13.330693244934082],
        [74.71486663818388, 13.331249237060547],
        [74.71458435058611, 13.331249237060547],
        [74.71458435058611, 13.332082748413086],
        [74.71430206298857, 13.33208084106451],
        [74.71430206298857, 13.332639694213867],
        [74.71402740478516, 13.332639694213867],
        [74.71402740478516, 13.333195686340332],
        [74.71430206298857, 13.333195686340332],
        [74.71430206298857, 13.334584236145076],
        [74.71402740478516, 13.334581375122184],
        [74.71402740478516, 13.334871292114315],
        [74.71375274658232, 13.334871292114315],
        [74.71375274658232, 13.335419654846248],
        [74.71347045898443, 13.335417747497615],
        [74.71347045898443, 13.33569431304943],
        [74.71290588378912, 13.335691452026367],
        [74.71290588378912, 13.336250305175895],
        [74.71263885498041, 13.336250305175895],
        [74.71263885498041, 13.336529731750602],
        [74.71208190917986, 13.336527824401799],
        [74.71208190917986, 13.337083816528263],
        [74.71180725097662, 13.337081909179688],
        [74.71180725097662, 13.337639808654785],
        [74.71152496337908, 13.337639808654785],
        [74.71152496337908, 13.338193893432617],
        [74.71125030517607, 13.338191032409782],
        [74.71125030517607, 13.340422630310172],
        [74.71151733398455, 13.340422630310172],
        [74.71151733398455, 13.341250419616813],
        [74.71125030517607, 13.341250419616813],
        [74.71125030517607, 13.341811180114746],
        [74.71041870117193, 13.341811180114746],
        [74.71041870117193, 13.341532707214355],
        [74.70986175537115, 13.341532707214355],
        [74.70986175537115, 13.340971946716422],
        [74.70958709716791, 13.340971946716422],
        [74.70958709716791, 13.340415954589957],
        [74.70929718017584, 13.340422630310172],
        [74.70929718017584, 13.34014034271246],
        [74.70903015136736, 13.34014034271246],
        [74.70903015136736, 13.340422630310172],
        [74.70874786376959, 13.340415954589957],
        [74.70874786376959, 13.340694427490348],
        [74.70819091796903, 13.340694427490348],
        [74.70819091796903, 13.340971946716422],
        [74.70791625976562, 13.340971946716422],
        [74.70791625976562, 13.341250419616813],
        [74.70735931396484, 13.341250419616813],
        [74.70735931396484, 13.341532707214355],
        [74.70680236816412, 13.341532707214355],
        [74.70680236816412, 13.341811180114746],
        [74.70625305175804, 13.341806411743278],
        [74.70625305175804, 13.342082023620605],
        [74.70597076416033, 13.342082023620605],
        [74.70597076416033, 13.342639923095817],
        [74.70540618896501, 13.342639923095817],
        [74.70540618896501, 13.3429212570191],
        [74.70513916015653, 13.342915534973145],
        [74.70513916015653, 13.343194007873535],
        [74.70486450195312, 13.343194007873535],
        [74.70486450195312, 13.34347152709961],
        [74.70458221435575, 13.34347152709961],
        [74.70458221435575, 13.34375],
        [74.70430755615234, 13.343741416931152],
        [74.70430755615234, 13.344310760498104],
        [74.70319366455084, 13.344305992126465],
        [74.70319366455084, 13.344584465026855],
        [74.70180511474626, 13.344584465026855],
        [74.70181274414062, 13.344860076904354],
        [74.70069122314459, 13.344860076904354],
        [74.70069122314459, 13.345141410827637],
        [74.6995773315432, 13.345141410827637],
        [74.6995773315432, 13.345420837402287],
        [74.69902801513678, 13.345416069030819],
        [74.69902801513678, 13.345693588256893],
        [74.69847106933622, 13.345693588256893],
        [74.69847106933622, 13.345972061157283],
        [74.69819641113281, 13.345970153808707],
        [74.69819641113281, 13.346250534057674],
        [74.69763946533209, 13.346250534057674],
        [74.69763946533209, 13.34653091430664],
        [74.6970825195313, 13.34653091430664],
        [74.6970825195313, 13.346811294555607],
        [74.6965255737307, 13.346806526184139],
        [74.6965255737307, 13.347082138061467],
        [74.69625091552751, 13.347082138061467],
        [74.69625091552751, 13.34735965728754],
        [74.6956863403322, 13.34735965728754],
        [74.6956863403322, 13.347640991210994],
        [74.69513702392578, 13.347640991210994],
        [74.69513702392578, 13.347921371459961],
        [74.69541931152372, 13.347916603088322],
        [74.69541931152372, 13.348195075988883],
        [74.69596862792974, 13.348195075988883],
        [74.69596862792974, 13.348472595214787],
        [74.69625091552751, 13.348469734191895],
        [74.69625091552751, 13.348751068115178],
        [74.69653320312523, 13.348751068115178],
        [74.69653320312523, 13.349310874939022],
        [74.69680786132807, 13.349305152893066],
        [74.69680786132807, 13.34958267211914],
        [74.69735717773449, 13.349577903747672],
        [74.69735717773449, 13.349869728088379],
        [74.69763946533209, 13.349861145019531],
        [74.69763946533209, 13.350973129272461],
        [74.69785308837896, 13.350971221923885],
        [74.69790649414091, 13.352641105651912],
        [74.69819641113281, 13.352639198303336],
        [74.69819641113281, 13.354582786560059],
        [74.69790649414091, 13.354580879211483],
        [74.69790649414091, 13.356529235839844],
        [74.69763946533209, 13.356529235839844],
        [74.69763946533209, 13.359029769897518],
        [74.69791412353544, 13.359026908874455],
        [74.69791412353544, 13.35958290100092],
        [74.69819641113281, 13.35958290100092],
        [74.69819641113281, 13.359862327575797],
        [74.69847106933622, 13.359862327575797],
        [74.69847106933622, 13.360139846801871],
        [74.69875335693376, 13.360139846801871],
        [74.69875335693376, 13.360971450805664],
        [74.69847106933622, 13.360971450805664],
        [74.69847106933622, 13.36208438873291],
        [74.69819641113281, 13.362080574035758],
        [74.69819641113281, 13.362361907958984],
        [74.69790649414091, 13.362361907958984],
        [74.69790649414091, 13.364031791687012],
        [74.69763946533209, 13.364031791687012],
        [74.69763946533209, 13.367362022399902],
        [74.69736480712902, 13.367362022399902],
        [74.69736480712902, 13.37069416046154],
        [74.6970825195313, 13.37069034576416],
        [74.6970825195313, 13.374039649963493],
        [74.69680786132807, 13.374029159545898],
        [74.69680786132807, 13.374582290649471],
        [74.6965255737307, 13.374582290649471],
        [74.6965255737307, 13.375695228576717],
        [74.69625091552751, 13.375690460205078],
        [74.69625091552751, 13.376811027526855],
        [74.69596862792974, 13.376811027526855],
        [74.69596862792974, 13.379031181335563],
        [74.69569396972673, 13.379027366638184],
        [74.69569396972673, 13.382917404174918],
        [74.69596862792974, 13.382917404174918],
        [74.69596862792974, 13.38708400726324],
        [74.69625091552751, 13.387081146240348],
        [74.69625091552751, 13.390419960022086],
        [74.69653320312523, 13.390419960022086],
        [74.69653320312523, 13.392919540405273],
        [74.69680786132807, 13.392915725708121],
        [74.69680786132807, 13.393050193786735],
        [74.69680023193354, 13.39513969421398],
        [74.6970825195313, 13.39513969421398],
        [74.6970825195313, 13.397640228271484],
        [74.69735717773449, 13.397640228271484],
        [74.69735717773449, 13.402360916137695],
        [74.6970825195313, 13.402360916137695],
        [74.6970825195313, 13.406531333923397],
        [74.69680023193354, 13.406531333923397],
        [74.69680023193354, 13.409310340881461],
        [74.6965255737307, 13.409304618835506],
        [74.6965255737307, 13.412082672119084],
        [74.69625091552751, 13.412082672119084],
        [74.69625091552751, 13.417083740234489],
        [74.69596862792974, 13.417083740234489],
        [74.69596862792974, 13.418472290039006],
        [74.69569396972673, 13.418472290039006],
        [74.69569396972673, 13.419584274291992],
        [74.69541931152372, 13.419584274291992],
        [74.69541931152372, 13.42097187042242],
        [74.69513702392578, 13.42097187042242],
        [74.69513702392578, 13.423193931579704],
        [74.69486236572294, 13.423192024230957],
        [74.69486236572294, 13.426250457763672],
        [74.694580078125, 13.426250457763672],
        [74.694580078125, 13.429582595825138],
        [74.69430541992199, 13.429582595825138],
        [74.69431304931652, 13.438470840454158],
        [74.6940307617188, 13.438470840454158],
        [74.6940307617188, 13.446120262145996],
        [74.6940307617188, 13.446530342102108],
        [74.69384765625006, 13.446528434753532],
        [74.69374847412104, 13.446528434753532],
        [74.69374847412104, 13.446750640869254],
        [74.69374084472668, 13.447640419006461],
        [74.6940307617188, 13.447637557983398],
        [74.6940307617188, 13.448472023010254],
        [74.69431304931652, 13.448472023010254],
        [74.69431304931652, 13.449321746826172],
        [74.694580078125, 13.449305534362793],
        [74.694580078125, 13.449861526489258],
        [74.69431304931652, 13.449859619140682],
        [74.69431304931652, 13.454030990600643],
        [74.6940307617188, 13.454029083252067],
        [74.6940307617188, 13.454582214355469],
        [74.69374084472668, 13.454580307006893],
        [74.69374084472668, 13.4548597335816],
        [74.6934661865235, 13.4548597335816],
        [74.6934661865235, 13.45542144775402],
        [74.69319152832048, 13.455416679382324],
        [74.69319152832048, 13.455972671508789],
        [74.69291687011724, 13.45596790313715],
        [74.69291687011724, 13.456530570983887],
        [74.69264221191423, 13.45652866363531],
        [74.69264221191423, 13.457082748413143],
        [74.69235992431669, 13.457082748413143],
        [74.69235992431669, 13.457361221313533],
        [74.69209289550781, 13.457361221313533],
        [74.69209289550781, 13.457921028137207],
        [74.69180297851591, 13.457921028137207],
        [74.69180297851591, 13.458198547363281],
        [74.6915283203125, 13.458198547363281],
        [74.6915283203125, 13.458750724792594],
        [74.69124603271513, 13.458750724792594],
        [74.69124603271513, 13.45903110504156],
        [74.69097137451178, 13.459029197692985],
        [74.69097137451178, 13.459583282470646],
        [74.69069671630854, 13.459583282470646],
        [74.69069671630854, 13.460695266723633],
        [74.690414428711, 13.460695266723633],
        [74.69040679931646, 13.462860107421989],
        [74.69013977050798, 13.462917327880916],
        [74.69013977050798, 13.463609695434627],
        [74.69013977050798, 13.464860916137638],
        [74.68985748291038, 13.464860916137638],
        [74.68985748291038, 13.467082977294922],
        [74.6895828247072, 13.467069625854435],
        [74.6895828247072, 13.469030380249023],
        [74.68930816650419, 13.469030380249023],
        [74.68930816650419, 13.4712495803833],
        [74.68902587890642, 13.4712495803833],
        [74.68902587890642, 13.474310874939079],
        [74.68875122070341, 13.474310874939079],
        [74.68875122070341, 13.478750228881836],
        [74.68846893310547, 13.478750228881836],
        [74.68846893310547, 13.482920646667537],
        [74.6881866455081, 13.482920646667537],
        [74.6881866455081, 13.486250877380428],
        [74.68791961669928, 13.486250877380428],
        [74.68791961669928, 13.489860534667969],
        [74.68763732910168, 13.489860534667969],
        [74.68763732910168, 13.492638587951774],
        [74.6873626708985, 13.492638587951774],
        [74.6873626708985, 13.494583129882756],
        [74.68708038330072, 13.49458122253418],
        [74.68708038330072, 13.496809959411735],
        [74.68681335449224, 13.496809959411735],
        [74.68681335449224, 13.498749732971305],
        [74.6865310668947, 13.498749732971305],
        [74.6865310668947, 13.500694274902344],
        [74.68624877929693, 13.500692367553711],
        [74.68624877929693, 13.502921104431266],
        [74.68597412109392, 13.502916336059627],
        [74.68597412109392, 13.505694389343375],
        [74.68569183349638, 13.505689620971737],
        [74.68569183349638, 13.509310722351188],
        [74.68541717529297, 13.509306907653809],
        [74.68541717529297, 13.513472557067871],
        [74.68514251709013, 13.513472557067871],
        [74.68514251709013, 13.517083168029899],
        [74.68486022949224, 13.517081260681152],
        [74.68486022949224, 13.518749237060547],
        [74.68459320068354, 13.518749237060547],
        [74.68459320068354, 13.520421981811637],
        [74.68430328369146, 13.520421981811637],
        [74.68430328369146, 13.522919654846248],
        [74.68402862548822, 13.522917747497672],
        [74.68402862548822, 13.52569580078125],
        [74.68374633789085, 13.52569580078125],
        [74.68374633789085, 13.528471946716422],
        [74.68347167968767, 13.528471946716422],
        [74.68347167968767, 13.530694007873535],
        [74.68319702148443, 13.530690193176383],
        [74.68319702148443, 13.53181076049816],
        [74.68290710449236, 13.53181076049816],
        [74.68290710449236, 13.533749580383414],
        [74.68264007568388, 13.533749580383414],
        [74.68264007568388, 13.536540031433162],
        [74.68235778808594, 13.536528587341309],
        [74.68235778808594, 13.538220405578613],
        [74.68235778808594, 13.539312362671012],
        [74.6820831298831, 13.53930473327648],
        [74.6820831298831, 13.542082786560059],
        [74.68180084228516, 13.542080879211483],
        [74.68180084228516, 13.544860839843807],
        [74.68152618408214, 13.544860839843807],
        [74.68152618408214, 13.54736137390131],
        [74.68125152587896, 13.54736137390131],
        [74.68125152587896, 13.549030303955021],
        [74.6809692382812, 13.549027442932129],
        [74.6809692382812, 13.550694465637264],
        [74.68068695068365, 13.550690650939941],
        [74.68068695068365, 13.55263996124279],
        [74.68041992187517, 13.55263996124279],
        [74.68041992187517, 13.554310798645076],
        [74.6801376342774, 13.554306030273438],
        [74.6801376342774, 13.555972099304256],
        [74.67986297607439, 13.55597019195568],
        [74.67986297607439, 13.557921409606934],
        [74.67958068847685, 13.557915687560978],
        [74.67958068847685, 13.55958366394043],
        [74.67930603027344, 13.559579849243221],
        [74.67930603027344, 13.561249732971248],
        [74.6790313720706, 13.561249732971248],
        [74.6790313720706, 13.563195228576717],
        [74.67874145507812, 13.563190460205078],
        [74.67874145507812, 13.565140724182243],
        [74.67847442626964, 13.565138816833496],
        [74.67847442626964, 13.567083358764705],
        [74.67819213867193, 13.567081451416072],
        [74.67819213867193, 13.56903076171875],
        [74.6779174804687, 13.569026947021598],
        [74.6779174804687, 13.570973396301383],
        [74.67764282226585, 13.57097053527832],
        [74.67764282226585, 13.572921752929801],
        [74.67736053466814, 13.572916984558105],
        [74.67736053466814, 13.574860572815055],
        [74.6770858764649, 13.574860572815055],
        [74.6770858764649, 13.576530456543026],
        [74.67680358886736, 13.576527595519963],
        [74.67680358886736, 13.578472137451172],
        [74.67652893066435, 13.578459739685172],
        [74.67652893066435, 13.580419540405273],
        [74.67624664306658, 13.580415725708121],
        [74.67624664306658, 13.580860137939567],
        [74.67624664306658, 13.582362174987907],
        [74.6761093139649, 13.582362174987907],
        [74.67597198486357, 13.582362174987907],
        [74.6759567260745, 13.583930015564022],
        [74.67594909667974, 13.584310531616325],
        [74.67569732666016, 13.584310531616325],
        [74.67569732666016, 13.586250305175838],
        [74.67541503906278, 13.586250305175838],
        [74.67541503906278, 13.588193893432617],
        [74.67514038085943, 13.588191986083984],
        [74.67514038085943, 13.589859962463436],
        [74.67485809326183, 13.589859962463436],
        [74.67485809326183, 13.591528892517147],
        [74.67457580566412, 13.591528892517147],
        [74.67457580566412, 13.592921257019043],
        [74.67430877685564, 13.592917442321891],
        [74.67430877685564, 13.594582557678223],
        [74.67402648925804, 13.594582557678223],
        [74.67402648925804, 13.595973014831543],
        [74.67375183105486, 13.595973014831543],
        [74.67375183105486, 13.597360610961971],
        [74.67346954345709, 13.597360610961971],
        [74.67346954345709, 13.599028587341422],
        [74.67318725585955, 13.599028587341422],
        [74.67318725585955, 13.600421905517635],
        [74.67292022705107, 13.60041713714594],
        [74.67292022705107, 13.602083206176815],
        [74.67263793945312, 13.602083206176815],
        [74.67263793945312, 13.603470802307243],
        [74.67236328125028, 13.60346889495844],
        [74.67236328125028, 13.605139732360954],
        [74.6720809936524, 13.605139732360954],
        [74.6720809936524, 13.606241226196289],
        [74.6720809936524, 13.606530189514274],
        [74.67180633544933, 13.606528282165527],
        [74.67180633544933, 13.607209205627498],
        [74.67180633544933, 13.608194351196346],
        [74.67152404785162, 13.608194351196346],
        [74.67152404785162, 13.608599662780819],
        [74.67152404785162, 13.609581947326774],
        [74.67124938964838, 13.609581947326774],
        [74.67124938964838, 13.610000610351562],
        [74.67124938964838, 13.611249923705998],
        [74.67106628417986, 13.611249923705998],
        [74.67097473144554, 13.611249923705998],
        [74.67096710205101, 13.612640380859318],
        [74.67069244384783, 13.612640380859318],
        [74.67069244384783, 13.614030838012638],
        [74.67041778564459, 13.614027976989803],
        [74.67041778564459, 13.615694046020621],
        [74.67013549804705, 13.615694046020621],
        [74.67013549804705, 13.617081642150879],
        [74.67002105712913, 13.617081642150879],
        [74.66986083984403, 13.617081642150879],
        [74.66986083984403, 13.6175794601441],
        [74.66986083984403, 13.61875057220459],
        [74.66958618164062, 13.61875057220459],
        [74.66958618164062, 13.62014102935791],
        [74.66929626464872, 13.62014102935791],
        [74.66929626464872, 13.621811866760197],
        [74.6690292358399, 13.621805191040153],
        [74.6690292358399, 13.623194694518986],
        [74.6687469482423, 13.623190879821834],
        [74.6687469482423, 13.624860763549805],
        [74.66847229003912, 13.624860763549805],
        [74.66847229003912, 13.626531600952148],
        [74.66819763183588, 13.626526832580623],
        [74.66819763183588, 13.628010749816838],
        [74.66819763183588, 13.628195762634334],
        [74.66792297363304, 13.628190994262695],
        [74.66792297363304, 13.629309654235897],
        [74.66819763183588, 13.629309654235897],
        [74.66819763183588, 13.629861831664982],
        [74.66847229003912, 13.629861831664982],
        [74.66847229003912, 13.63041973114025],
        [74.6687469482423, 13.630417823791504],
        [74.6687469482423, 13.630971908569336],
        [74.6690292358399, 13.630971908569336],
        [74.6690292358399, 13.631529808044434],
        [74.66930389404325, 13.631527900695858],
        [74.66930389404325, 13.632083892822322],
        [74.66958618164062, 13.632083892822322],
        [74.66958618164062, 13.632362365722713],
        [74.66986083984403, 13.63236045837408],
        [74.66986083984403, 13.632641792297363],
        [74.67041778564459, 13.632641792297363],
        [74.67041778564459, 13.632931709289664],
        [74.67069244384783, 13.632931709289664],
        [74.67069244384783, 13.633749961853141],
        [74.67041778564459, 13.633749961853141],
        [74.67041778564459, 13.63486194610607],
        [74.67013549804705, 13.63486194610607],
        [74.67013549804705, 13.635972023010254],
        [74.66986083984403, 13.635972023010254],
        [74.66986083984403, 13.637084007263184],
        [74.66958618164062, 13.637084007263184],
        [74.66958618164062, 13.638194084167537],
        [74.66930389404325, 13.638194084167537],
        [74.66930389404325, 13.638471603393612],
        [74.66819763183588, 13.638471603393612],
        [74.66819763183588, 13.638750076294002],
        [74.66792297363304, 13.638740539550895],
        [74.66792297363304, 13.639310836791992],
        [74.66764068603533, 13.639304161071891],
        [74.66764068603533, 13.639583587646598],
        [74.66735839843756, 13.639583587646598],
        [74.66735839843756, 13.639860153198185],
        [74.66708374023455, 13.639860153198185],
        [74.66708374023455, 13.640693664550781],
        [74.666801452637, 13.640689849853572],
        [74.666801452637, 13.641249656677246],
        [74.6665267944336, 13.641249656677246],
        [74.6665267944336, 13.6415309906007],
        [74.66625213623075, 13.641528129577637],
        [74.66625213623075, 13.64180660247797],
        [74.6656951904298, 13.64180660247797],
        [74.6656951904298, 13.642082214355469],
        [74.66541290283209, 13.642080307006893],
        [74.66541290283209, 13.6423597335816],
        [74.66513824462885, 13.6423597335816],
        [74.66513824462885, 13.64292144775402],
        [74.6648559570313, 13.64292144775402],
        [74.6648559570313, 13.644030570983887],
        [74.6645812988283, 13.64402866363531],
        [74.6645812988283, 13.648195266723633],
        [74.66430664062506, 13.648189544677848],
        [74.66430664062506, 13.649030685424748],
        [74.66402435302751, 13.649026870727596],
        [74.66402435302751, 13.64958286285406],
        [74.66375732421903, 13.649580955505485],
        [74.66375732421903, 13.650419235229492],
        [74.66348266601562, 13.650419235229492],
        [74.66348266601562, 13.651250839233455],
        [74.66319274902372, 13.65124893188488],
        [74.66319274902372, 13.652083396911735],
        [74.66291809082031, 13.652080535888672],
        [74.66291809082031, 13.652919769287166],
        [74.66263580322294, 13.652916908264103],
        [74.66263580322294, 13.653470993041992],
        [74.66236114501959, 13.653470993041992],
        [74.66236114501959, 13.654310226440543],
        [74.66208648681635, 13.654305458068848],
        [74.66208648681635, 13.654861450195312],
        [74.66179656982428, 13.654861450195312],
        [74.66179656982428, 13.65542030334467],
        [74.6615295410158, 13.655418395996094],
        [74.6615295410158, 13.655693054199332],
        [74.6612472534182, 13.655693054199332],
        [74.6612472534182, 13.655970573425407],
        [74.66097259521501, 13.655970573425407],
        [74.66097259521501, 13.656530380249023],
        [74.660697937012, 13.656530380249023],
        [74.660697937012, 13.65680980682373],
        [74.65985870361328, 13.656805992126522],
        [74.65985870361328, 13.657084465026912],
        [74.65957641601591, 13.65708065032959],
        [74.65957641601591, 13.657361984253043],
        [74.65930938720709, 13.657361984253043],
        [74.65930175781256, 13.657640457153377],
        [74.65902709960949, 13.657640457153377],
        [74.65902709960949, 13.65792179107666],
        [74.6587524414063, 13.657917976379508],
        [74.6587524414063, 13.658193588256836],
        [74.65847015380854, 13.658193588256836],
        [74.65847015380854, 13.65847206115717],
        [74.65820312500006, 13.658469200134334],
        [74.65820312500006, 13.6587495803833],
        [74.65791320800798, 13.6587495803833],
        [74.65791320800798, 13.659029960632438],
        [74.65763854980474, 13.659028053283691],
        [74.65763854980474, 13.659584045410156],
        [74.6573562622072, 13.659579277038688],
        [74.6573562622072, 13.659871101379338],
        [74.65708160400419, 13.659871101379338],
        [74.65708160400419, 13.660140037536621],
        [74.65680694580078, 13.660140037536621],
        [74.65680694580078, 13.660420417785758],
        [74.65652465820341, 13.66041564941412],
        [74.65652465820341, 13.66069412231451],
        [74.65625, 13.660689353942814],
        [74.65625, 13.66125202178955],
        [74.65598297119135, 13.66125202178955],
        [74.65598297119135, 13.661529541015625],
        [74.65569305419928, 13.661529541015625],
        [74.65569305419928, 13.661810874939079],
        [74.65541839599604, 13.66180610656744],
        [74.65541839599604, 13.662083625793514],
        [74.65513610839866, 13.662083625793514],
        [74.65513610839866, 13.662362098693904],
        [74.65486145019548, 13.662360191345329],
        [74.65486145019548, 13.66292953491211],
        [74.65458679199224, 13.66291809082037],
        [74.65458679199224, 13.663193702697868],
        [74.6543045043947, 13.663193702697868],
        [74.6543045043947, 13.663472175598258],
        [74.65402984619169, 13.663470268249512],
        [74.65402984619169, 13.663749694824162],
        [74.65374755859375, 13.663749694824162],
        [74.65374755859375, 13.664030075073299],
        [74.65347290039091, 13.664028167724723],
        [74.65347290039091, 13.664584159851017],
        [74.65319824218756, 13.664580345153865],
        [74.65319824218756, 13.664861679077092],
        [74.65291595458996, 13.664861679077092],
        [74.65291595458996, 13.665140151977482],
        [74.65208435058616, 13.665140151977482],
        [74.65208435058616, 13.665694236755371],
        [74.65180206298845, 13.665694236755371],
        [74.65180206298845, 13.665971755981445],
        [74.65152740478521, 13.66596984863287],
        [74.65152740478521, 13.666250228881836],
        [74.6512527465822, 13.666250228881836],
        [74.6512527465822, 13.666810989379996],
        [74.65097045898466, 13.666810989379996],
        [74.65097045898466, 13.667640686035156],
        [74.65069580078125, 13.667640686035156],
        [74.65069580078125, 13.669310569763184],
        [74.65041351318388, 13.669306755066032],
        [74.65041351318388, 13.67097282409668],
        [74.65013885498047, 13.67097282409668],
        [74.65013885498047, 13.672082901001033],
        [74.6498565673831, 13.672080039978141],
        [74.6498565673831, 13.672640800476074],
        [74.64958190917974, 13.672638893127498],
        [74.64958190917974, 13.673194885253963],
        [74.6493072509765, 13.673190116882324],
        [74.6493072509765, 13.673750877380428],
        [74.64901733398443, 13.673750877380428],
        [74.64901733398443, 13.674311637878361],
        [74.64875793457048, 13.674311637878361],
        [74.64875793457048, 13.67486953735363],
        [74.64848327636724, 13.67486953735363],
        [74.64848327636724, 13.675421714782715],
        [74.64819335937517, 13.675416946411246],
        [74.64819335937517, 13.675972938537711],
        [74.64791870117216, 13.675971031188965],
        [74.64791870117216, 13.676530838012809],
        [74.64763641357439, 13.67652702331543],
        [74.64763641357439, 13.677083015441895],
        [74.64736175537138, 13.677081108093319],
        [74.64736175537138, 13.677640914916935],
        [74.64708709716797, 13.67763900756836],
        [74.64708709716797, 13.678193092346248],
        [74.64679718017607, 13.678191184997672],
        [74.64679718017607, 13.678750991821289],
        [74.64653015136724, 13.678750991821289],
        [74.64653015136724, 13.67931175231945],
        [74.64624786376964, 13.67931175231945],
        [74.64624786376964, 13.680142402648926],
        [74.64597320556646, 13.680142402648926],
        [74.64597320556646, 13.68125152587902],
        [74.6456909179687, 13.681248664855957],
        [74.6456909179687, 13.682083129882812],
        [74.64541625976585, 13.68208122253418],
        [74.64541625976585, 13.68292045593273],
        [74.64514160156267, 13.68292045593273],
        [74.64514160156267, 13.684041023254508],
        [74.6448593139649, 13.684027671814022],
        [74.6448593139649, 13.684862136840877],
        [74.64458465576189, 13.684862136840877],
        [74.64458465576189, 13.68597221374523],
        [74.64430236816435, 13.68597221374523],
        [74.64430236816435, 13.689583778381348],
        [74.64402770996094, 13.689583778381348],
        [74.64402770996094, 13.691510200500602],
        [74.64402770996094, 13.69375038146967],
        [74.6437530517581, 13.69375038146967],
        [74.6437530517581, 13.695421218872184],
        [74.64347076416021, 13.695416450500488],
        [74.64347076416021, 13.697081565857047],
        [74.64319610595714, 13.697081565857047],
        [74.64318847656261, 13.697579383850098],
        [74.64317321777372, 13.698751449585075],
        [74.6429061889649, 13.698751449585075],
        [74.6429061889649, 13.700421333313045],
        [74.6426391601562, 13.700416564941406],
        [74.6426391601562, 13.702082633972225],
        [74.64235687255882, 13.702080726623649],
        [74.64235687255882, 13.70375061035162],
        [74.64208221435564, 13.703748703003043],
        [74.64208221435564, 13.705695152282658],
        [74.6418075561524, 13.705695152282658],
        [74.6418075561524, 13.707361221313477],
        [74.64153289794939, 13.707361221313477],
        [74.64153289794939, 13.709309577941895],
        [74.64125061035185, 13.709309577941895],
        [74.64125061035185, 13.710981369018668],
        [74.64097595214844, 13.710981369018668],
        [74.64097595214844, 13.712920188903922],
        [74.64069366455107, 13.712916374206543],
        [74.64069366455107, 13.714584350585994],
        [74.64041900634771, 13.714582443237418],
        [74.64041900634771, 13.716250419616813],
        [74.64013671875011, 13.716250419616813],
        [74.64013671875011, 13.717082023620605],
        [74.64013671875011, 13.7179212570191],
        [74.63986206054693, 13.717915534973145],
        [74.63986206054693, 13.719584465026855],
        [74.6395874023437, 13.719579696655387],
        [74.6395874023437, 13.721249580383414],
        [74.63931274414085, 13.721249580383414],
        [74.63931274414085, 13.722921371460018],
        [74.63903045654314, 13.722915649414062],
        [74.63903045654314, 13.724584579467773],
        [74.63874816894537, 13.724577903747502],
        [74.63874816894537, 13.726250648498592],
        [74.63846588134783, 13.726250648498592],
        [74.63846588134783, 13.72792053222662],
        [74.63819122314482, 13.72791671752941],
        [74.63819122314482, 13.729582786560059],
        [74.6379165649414, 13.729580879211483],
        [74.6379165649414, 13.731250762939453],
        [74.63764190673857, 13.731250762939453],
        [74.63764190673857, 13.732919692993164],
        [74.63735961914062, 13.732916831970329],
        [74.63735961914062, 13.734582901000977],
        [74.63707733154325, 13.734580993652344],
        [74.63707733154325, 13.736251831054688],
        [74.6368026733399, 13.736248970031795],
        [74.6368026733399, 13.738192558288688],
        [74.63652801513666, 13.738192558288688],
        [74.63652801513666, 13.739862442016715],
        [74.63625335693382, 13.739859580993652],
        [74.63625335693382, 13.74153041839611],
        [74.63597106933611, 13.741527557373047],
        [74.63597106933611, 13.743472099304256],
        [74.63569641113287, 13.74347019195568],
        [74.63569641113287, 13.74514007568365],
        [74.6354064941408, 13.74514007568365],
        [74.6354064941408, 13.746810913085994],
        [74.63513946533232, 13.746810913085994],
        [74.63513946533232, 13.747920989990348],
        [74.63485717773455, 13.747920989990348],
        [74.63485717773455, 13.749311447143668],
        [74.63458251953153, 13.74930667877203],
        [74.63458251953153, 13.750695228576717],
        [74.63430786132812, 13.750690460205078],
        [74.63430786132812, 13.751811027526855],
        [74.63402557373075, 13.75180435180664],
        [74.63402557373075, 13.753194808959961],
        [74.6337509155274, 13.753194808959961],
        [74.6337509155274, 13.754583358764705],
        [74.6334686279298, 13.754583358764705],
        [74.63346099853527, 13.75568962097168],
        [74.63319396972662, 13.755695343017635],
        [74.63319396972662, 13.757082939148063],
        [74.63291931152361, 13.757082939148063],
        [74.63291931152361, 13.7575302124024],
        [74.63291931152361, 13.758473396301213],
        [74.63276672363281, 13.758473396301213],
        [74.63263702392601, 13.758473396301213],
        [74.63263702392601, 13.759380340576286],
        [74.63263702392601, 13.759583473205566],
        [74.63236236572283, 13.759583473205566],
        [74.63236236572283, 13.761190414428711],
        [74.63236236572283, 13.762920379638729],
        [74.63208007812506, 13.762918472290096],
        [74.63206481933616, 13.76378059387207],
        [74.63205718994163, 13.764030456543082],
        [74.63180541992205, 13.764026641845703],
        [74.63180541992205, 13.764583587646484],
        [74.63153076171903, 13.764583587646484],
        [74.63153076171903, 13.76513957977295],
        [74.6312484741211, 13.76513957977295],
        [74.6312484741211, 13.765693664550838],
        [74.63096618652372, 13.765688896179142],
        [74.63096618652372, 13.766810417175407],
        [74.63069152832037, 13.766805648803768],
        [74.63069152832037, 13.768471717834586],
        [74.6304168701173, 13.76846981048584],
        [74.6304168701173, 13.77013969421381],
        [74.63014221191412, 13.77013969421381],
        [74.63014221191412, 13.771810531616325],
        [74.62985992431635, 13.771805763244629],
        [74.62985992431635, 13.773471832275504],
        [74.62959289550787, 13.773469924926701],
        [74.62959289550787, 13.775142669677791],
        [74.6293029785158, 13.775142669677791],
        [74.6293029785158, 13.776810646057186],
        [74.62902832031256, 13.776810646057186],
        [74.62902832031256, 13.780421257019043],
        [74.62875366210955, 13.780417442321891],
        [74.62875366210955, 13.780694961547965],
        [74.628471374512, 13.780690193176326],
        [74.628471374512, 13.781531333923397],
        [74.6281967163086, 13.781531333923397],
        [74.6281967163086, 13.783200263977108],
        [74.62791442871122, 13.783194541931152],
        [74.62791442871122, 13.784860610961971],
        [74.62763977050787, 13.784860610961971],
        [74.62763977050787, 13.785972595214957],
        [74.62735748291027, 13.785970687866154],
        [74.62735748291027, 13.786811828613281],
        [74.62708282470709, 13.786811828613281],
        [74.62708282470709, 13.787921905517635],
        [74.62680816650385, 13.787921905517635],
        [74.62680816650385, 13.788751602172852],
        [74.62652587890648, 13.78874874114996],
        [74.62652587890648, 13.789583206176815],
        [74.6262512207033, 13.789581298828182],
        [74.6262512207033, 13.790419578552246],
        [74.62596893310553, 13.790419578552246],
        [74.62596893310553, 13.7915296554566],
        [74.62569427490251, 13.791527748108024],
        [74.62569427490251, 13.79236221313488],
        [74.6254196166995, 13.79236221313488],
        [74.6254196166995, 13.793195724487418],
        [74.62568664550798, 13.79319095611578],
        [74.62568664550798, 13.79430961608898],
        [74.62596893310553, 13.794305801391602],
        [74.62596893310553, 13.795261383056584],
        [74.62596893310553, 13.795429229736442],
        [74.6262512207033, 13.795429229736442],
        [74.6262512207033, 13.79625034332281],
        [74.62596893310553, 13.79625034332281],
        [74.62596893310553, 13.797081947326774],
        [74.62569427490251, 13.797081947326774],
        [74.62569427490251, 13.796527862548885],
        [74.62538146972679, 13.796529769897461],
        [74.62513732910156, 13.796532630920353],
        [74.62513732910156, 13.796811103820744],
        [74.62458038330078, 13.796806335449276],
        [74.62458038330078, 13.797081947326774],
        [74.6243133544923, 13.797081947326774],
        [74.6243133544923, 13.798200607299805],
        [74.62403106689459, 13.798200607299805],
        [74.62403106689459, 13.798749923705998],
        [74.62374877929682, 13.798749923705998],
        [74.62374877929682, 13.799030303955192],
        [74.62346649169928, 13.799030303955192],
        [74.62346649169928, 13.800140380859318],
        [74.62319183349626, 13.800138473510742],
        [74.62319183349626, 13.801250457763672],
        [74.62291717529303, 13.801240921020565],
        [74.62291717529303, 13.802640914917049],
        [74.62264251709001, 13.802640914917049],
        [74.62264251709001, 13.80403137207037],
        [74.62236022949247, 13.80403137207037],
        [74.62236022949247, 13.805420875549373],
        [74.6220932006836, 13.805420875549373],
        [74.6220932006836, 13.806811332702694],
        [74.62180328369169, 13.806804656982479],
        [74.62180328369169, 13.808195114135799],
        [74.62152862548828, 13.808195114135799],
        [74.62152862548828, 13.809582710266227],
        [74.62124633789091, 13.809582710266227],
        [74.62124633789091, 13.810973167419377],
        [74.62097167968756, 13.8109712600708],
        [74.62097167968756, 13.812360763549805],
        [74.62069702148432, 13.812360763549805],
        [74.62069702148432, 13.813751220703182],
        [74.62040710449224, 13.813751220703182],
        [74.62040710449224, 13.815141677856502],
        [74.62014007568376, 13.815141677856502],
        [74.62014007568376, 13.816530227661246],
        [74.61985778808616, 13.816530227661246],
        [74.61985778808616, 13.81791973114025],
        [74.61958312988298, 13.81791973114025],
        [74.61958312988298, 13.81931018829357],
        [74.61930847167997, 13.819306373596248],
        [74.61930847167997, 13.820689201355037],
        [74.6190261840822, 13.820695877075252],
        [74.6190261840822, 13.822084426879996],
        [74.61875152587919, 13.822084426879996],
        [74.61875152587919, 13.823472023010254],
        [74.61846923828125, 13.823472023010254],
        [74.61846923828125, 13.824861526489315],
        [74.61818695068388, 13.824859619140682],
        [74.61818695068388, 13.826250076294002],
        [74.61791992187506, 13.826250076294002],
        [74.61791992187506, 13.826810836791992],
        [74.6170806884765, 13.826810836791992],
        [74.6170806884765, 13.827920913696346],
        [74.61680603027366, 13.827920913696346],
        [74.61680603027366, 13.830141067504883],
        [74.61653137207048, 13.830141067504883],
        [74.61653137207048, 13.831810951232853],
        [74.61624908447271, 13.831810951232853],
        [74.61624908447271, 13.832921028137207],
        [74.61596679687517, 13.832921028137207],
        [74.61596679687517, 13.83403110504156],
        [74.61569213867216, 13.83403110504156],
        [74.61569213867216, 13.835141181945914],
        [74.61541748046875, 13.835139274597168],
        [74.61541748046875, 13.835972785949707],
        [74.61513519287138, 13.835972785949707],
        [74.61513519287138, 13.83708286285406],
        [74.61486053466803, 13.83708286285406],
        [74.61486053466803, 13.83819484710699],
        [74.61458587646496, 13.838191032409668],
        [74.61458587646496, 13.839029312133846],
        [74.61429595947271, 13.839029312133846],
        [74.61429595947271, 13.840139389038029],
        [74.614028930664, 13.840139389038029],
        [74.614028930664, 13.841251373291016],
        [74.61374664306663, 13.841251373291016],
        [74.61374664306663, 13.842241287231502],
        [74.61374664306663, 13.84236145019537],
        [74.61347198486345, 13.84236145019537],
        [74.61347198486345, 13.843193054199332],
        [74.61319732666021, 13.843193054199332],
        [74.61318206787132, 13.844180107116756],
        [74.61318206787132, 13.84430980682373],
        [74.61291503906267, 13.844305038452262],
        [74.6129226684572, 13.845171928405875],
        [74.6129226684572, 13.84542179107666],
        [74.61264038085966, 13.845417976379508],
        [74.61264801025419, 13.846139907836857],
        [74.61264801025419, 13.846541404724178],
        [74.61252593994158, 13.846528053283691],
        [74.61235809326172, 13.846528053283691],
        [74.61235809326172, 13.847131729125977],
        [74.61235809326172, 13.847641944885197],
        [74.61219787597662, 13.847640037536621],
        [74.61208343505888, 13.847640037536621],
        [74.61208343505888, 13.848099708557129],
        [74.61207580566435, 13.84875202178955],
        [74.61180877685553, 13.848750114440975],
        [74.61180877685553, 13.84907054901123],
        [74.61180877685553, 13.849862098693904],
        [74.61152648925793, 13.849862098693904],
        [74.61152648925793, 13.850060462951717],
        [74.61152648925793, 13.850972175598258],
        [74.61125183105474, 13.850972175598258],
        [74.61125183105474, 13.852084159851017],
        [74.61096954345697, 13.852084159851017],
        [74.61096954345697, 13.853194236755371],
        [74.61068725585943, 13.85319042205822],
        [74.61068725585943, 13.85403060913086],
        [74.61042022705095, 13.854027748107967],
        [74.61042022705095, 13.85486030578619],
        [74.61013793945318, 13.85486030578619],
        [74.61013793945318, 13.8550901412965],
        [74.61013793945318, 13.855972290039176],
        [74.60986328125017, 13.855970382690373],
        [74.60986328125017, 13.856810569763184],
        [74.60958099365263, 13.856810569763184],
        [74.60958099365263, 13.857641220092887],
        [74.60930633544922, 13.857641220092887],
        [74.60930633544922, 13.85875129699707],
        [74.60902404785185, 13.85875129699707],
        [74.60902404785185, 13.859582901001033],
        [74.60874938964844, 13.859580039978141],
        [74.60874938964844, 13.86042118072504],
        [74.60846710205107, 13.86042118072504],
        [74.60846710205107, 13.861250877380428],
        [74.60819244384771, 13.861250877380428],
        [74.60819244384771, 13.862360954284782],
        [74.60791778564447, 13.862360954284782],
        [74.60791778564447, 13.863195419311637],
        [74.60764312744163, 13.863189697265682],
        [74.60764312744163, 13.864030838012809],
        [74.60736083984392, 13.864030838012809],
        [74.60736083984392, 13.865140914916992],
        [74.60708618164068, 13.86513900756836],
        [74.60708618164068, 13.865973472595215],
        [74.60679626464861, 13.865970611572322],
        [74.60679626464861, 13.86652946472168],
        [74.60791778564447, 13.86652946472168],
        [74.60791778564447, 13.865970611572322],
        [74.60819244384771, 13.865973472595215],
        [74.60819244384771, 13.86513900756836],
        [74.60846710205107, 13.865140914916992],
        [74.60846710205107, 13.8643093109132],
        [74.60874938964844, 13.8643093109132],
        [74.60874938964844, 13.863471031188965],
        [74.60902404785185, 13.86347293853754],
        [74.60902404785185, 13.862639427185172],
        [74.60930633544922, 13.862641334533748],
        [74.60930633544922, 13.862079620361328],
        [74.60958099365263, 13.862083435058707],
        [74.60958099365263, 13.861806869506893],
        [74.60986328125017, 13.861811637878361],
        [74.60986328125017, 13.861250877380428],
        [74.61013793945318, 13.861250877380428],
        [74.61013793945318, 13.860971450805778],
        [74.61042022705095, 13.860973358154354],
        [74.61042022705095, 13.860694885253963],
        [74.61096954345697, 13.860694885253963],
        [74.61096954345697, 13.860417366027889],
        [74.61125183105474, 13.86042118072504],
        [74.61125183105474, 13.860140800476074],
        [74.61180877685553, 13.860140800476074],
        [74.61180877685553, 13.858478546142635],
        [74.61207580566435, 13.858478546142635],
        [74.61207580566435, 13.855139732360897],
        [74.61235809326172, 13.855139732360897],
        [74.61235809326172, 13.85486030578619],
        [74.61264038085966, 13.85486030578619],
        [74.61264038085966, 13.854583740234432],
        [74.61291503906267, 13.854583740234432],
        [74.61291503906267, 13.854027748107967],
        [74.61319732666021, 13.85403060913086],
        [74.61319732666021, 13.85346984863287],
        [74.61347198486345, 13.853471755981502],
        [74.61347198486345, 13.853159904480037],
        [74.61347198486345, 13.85291576385498],
        [74.61374664306663, 13.852920532226676],
        [74.61374664306663, 13.852351188659725],
        [74.614028930664, 13.852359771728516],
        [74.614028930664, 13.852084159851017],
        [74.61430358886724, 13.852084159851017],
        [74.61430358886724, 13.851528167724553],
        [74.61458587646496, 13.851530075073356],
        [74.61458587646496, 13.851249694824162],
        [74.61541748046875, 13.851249694824162],
        [74.61541748046875, 13.850970268249512],
        [74.61624908447271, 13.850972175598258],
        [74.61624908447271, 13.850693702697697],
        [74.6171493530274, 13.850693702697697],
        [74.61736297607428, 13.850693702697697],
        [74.61736297607428, 13.850529670715389],
        [74.61736297607428, 13.85041809082037],
        [74.61749267578142, 13.85041809082037],
        [74.61846160888672, 13.850419998169002],
        [74.61846160888672, 13.850139617919979],
        [74.6190261840822, 13.850139617919979],
        [74.6190261840822, 13.849860191345329],
        [74.61985778808616, 13.849862098693904],
        [74.61985778808616, 13.849583625793514],
        [74.62097167968756, 13.849583625793514],
        [74.62097167968756, 13.84930610656744],
        [74.62180328369169, 13.849309921264648],
        [74.62180328369169, 13.849029541015625],
        [74.62291717529303, 13.849029541015625],
        [74.62291717529303, 13.84875202178955],
        [74.62403106689459, 13.84875202178955],
        [74.62403106689459, 13.848471641540584],
        [74.6254196166995, 13.848471641540584],
        [74.6254196166995, 13.84819412231451],
        [74.62652587890648, 13.84819412231451],
        [74.62652587890648, 13.84791564941412],
        [74.62770080566435, 13.84791564941412],
        [74.6304168701173, 13.84791564941412],
        [74.6304168701173, 13.84819412231451],
        [74.6312484741211, 13.84819412231451],
        [74.6312484741211, 13.848471641540584],
        [74.63208007812506, 13.848471641540584],
        [74.63208007812506, 13.84875202178955],
        [74.63318634033209, 13.84875202178955],
        [74.63318634033209, 13.849029541015625],
        [74.63291931152361, 13.849027633667049],
        [74.63291931152361, 13.849862098693904],
        [74.63069152832037, 13.849862098693904],
        [74.63069152832037, 13.849583625793514],
        [74.63014221191412, 13.849583625793514],
        [74.63014221191412, 13.84930610656744],
        [74.6293029785158, 13.849309921264648],
        [74.6293029785158, 13.849029541015625],
        [74.62874603271501, 13.849029541015625],
        [74.62874603271501, 13.84875202178955],
        [74.6281967163086, 13.84875202178955],
        [74.6281967163086, 13.849029541015625],
        [74.62790679931669, 13.849029541015625],
        [74.62790679931669, 13.849309921264648],
        [74.62741088867205, 13.84930610656744],
        [74.62486267089872, 13.84930610656744],
        [74.62486267089872, 13.849583625793514],
        [74.62458038330078, 13.849583625793514],
        [74.62458038330078, 13.849862098693904],
        [74.62403106689459, 13.849860191345329],
        [74.62403106689459, 13.850139617919979],
        [74.62374877929682, 13.850139617919979],
        [74.62374877929682, 13.850419998169002],
        [74.62319183349626, 13.85041809082037],
        [74.62319183349626, 13.850693702697697],
        [74.62291717529303, 13.850693702697697],
        [74.62291717529303, 13.850972175598258],
        [74.62124633789091, 13.850972175598258],
        [74.62124633789091, 13.850693702697697],
        [74.62097167968756, 13.850693702697697],
        [74.62097167968756, 13.85041809082037],
        [74.61985778808616, 13.85041809082037],
        [74.61985778808616, 13.850693702697697],
        [74.61930847167997, 13.850693702697697],
        [74.61930847167997, 13.850972175598258],
        [74.61846160888672, 13.850970268249512],
        [74.61846160888672, 13.851530075073356],
        [74.6170806884765, 13.851530075073356],
        [74.6170806884765, 13.851811408996582],
        [74.61579895019531, 13.851805686950627],
        [74.61569213867216, 13.851805686950627],
        [74.61569213867216, 13.852084159851017],
        [74.61541748046875, 13.852080345153865],
        [74.61541748046875, 13.852640151977482],
        [74.61513519287138, 13.852640151977482],
        [74.61513519287138, 13.853194236755371],
        [74.61486053466803, 13.853194236755371],
        [74.61486053466803, 13.853471755981502],
        [74.61458587646496, 13.85346984863287],
        [74.61458587646496, 13.85403060913086],
        [74.61429595947271, 13.85403060913086],
        [74.61429595947271, 13.854310989379996],
        [74.614028930664, 13.854306221008358],
        [74.614028930664, 13.85486030578619],
        [74.61429595947271, 13.85486030578619],
        [74.61429595947271, 13.85542106628418],
        [74.614028930664, 13.855416297912711],
        [74.614028930664, 13.855693817138786],
        [74.61374664306663, 13.855693817138786],
        [74.61374664306663, 13.855972290039176],
        [74.61347198486345, 13.855970382690373],
        [74.61347198486345, 13.856252670288143],
        [74.61319732666021, 13.856252670288143],
        [74.61319732666021, 13.857641220092887],
        [74.6129226684572, 13.857641220092887],
        [74.6129226684572, 13.859030723571891],
        [74.61347198486345, 13.859030723571891],
        [74.61347198486345, 13.859309196472282],
        [74.61763763427746, 13.859304428100643],
        [74.61763763427746, 13.859582901001033],
        [74.61736297607428, 13.859582901001033],
        [74.61736297607428, 13.859719276428223],
        [74.61736297607428, 13.859861373901424],
        [74.6160507202149, 13.859861373901424],
        [74.61264801025419, 13.859861373901424],
        [74.61264801025419, 13.86042118072504],
        [74.61235809326172, 13.860417366027889],
        [74.61235809326172, 13.860973358154354],
        [74.61180877685553, 13.860971450805778],
        [74.61180877685553, 13.861811637878361],
        [74.61208343505888, 13.861806869506893],
        [74.61207580566435, 13.862659454345703],
        [74.61207580566435, 13.862920761108455],
        [74.61180877685553, 13.862916946411246],
        [74.61180877685553, 13.86347293853754],
        [74.61152648925793, 13.863471031188965],
        [74.61152648925793, 13.863751411438102],
        [74.61125183105474, 13.863751411438102],
        [74.61125183105474, 13.864271163940487],
        [74.61096954345697, 13.864304542541504],
        [74.61096954345697, 13.864471435546875],
        [74.61096954345697, 13.864860534667969],
        [74.61069488525413, 13.864860534667969],
        [74.61069488525413, 13.865973472595215],
        [74.61042022705095, 13.865970611572322],
        [74.61042022705095, 13.8673610687257],
        [74.61013793945318, 13.8673610687257],
        [74.61013793945318, 13.867642402648926],
        [74.60986328125017, 13.867642402648926],
        [74.60986328125017, 13.867921829223633],
        [74.60902404785185, 13.867917060852164],
        [74.60902404785185, 13.868194580078239],
        [74.60847473144543, 13.868194580078239],
        [74.60847473144543, 13.868471145629826],
        [74.60764312744163, 13.868471145629826],
        [74.60764312744163, 13.868750572204704],
        [74.60708618164068, 13.868750572204704],
        [74.60708618164068, 13.869031906127987],
        [74.60668182373053, 13.869031906127987],
        [74.60597229003935, 13.869031906127987],
        [74.60597229003935, 13.869310379028377],
        [74.60569763183594, 13.869304656982422],
        [74.60569763183594, 13.869583129882812],
        [74.60541534423857, 13.869583129882812],
        [74.60541534423857, 13.869860649108887],
        [74.60514068603521, 13.869860649108887],
        [74.60514068603521, 13.87014198303234],
        [74.60485839843761, 13.870139122009277],
        [74.60485839843761, 13.870693206787166],
        [74.60458374023443, 13.870693206787166],
        [74.60458374023443, 13.870973587036133],
        [74.60430908203142, 13.87097072601324],
        [74.60430908203142, 13.871249198913631],
        [74.60402679443382, 13.871249198913631],
        [74.60402679443382, 13.872083663940487],
        [74.60319519042986, 13.872083663940487],
        [74.60319519042986, 13.872362136840877],
        [74.6026382446289, 13.872359275817985],
        [74.6026382446289, 13.872639656066951],
        [74.60180664062511, 13.872639656066951],
        [74.60180664062511, 13.874030113220272],
        [74.60151672363287, 13.874030113220272],
        [74.60151672363287, 13.874861717224235],
        [74.60124969482416, 13.874861717224235],
        [74.60124969482416, 13.875694274902344],
        [74.60097503662132, 13.875694274902344],
        [74.60097503662132, 13.876527786254883],
        [74.60069274902361, 13.876527786254883],
        [74.60069274902361, 13.877362251281738],
        [74.60041809082037, 13.877360343933162],
        [74.60041809082037, 13.877920150756836],
        [74.60013580322283, 13.877916336059627],
        [74.60013580322283, 13.878472328186092],
        [74.59986114501982, 13.878470420837516],
        [74.59986114501982, 13.87903022766119],
        [74.5995864868164, 13.879028320312557],
        [74.5995864868164, 13.879584312439022],
        [74.5992965698245, 13.879579544067383],
        [74.5992965698245, 13.87985992431635],
        [74.59902954101568, 13.87985992431635],
        [74.59902954101568, 13.88042068481451],
        [74.59874725341808, 13.880415916442814],
        [74.59874725341808, 13.880971908569336],
        [74.5984725952149, 13.880970001220703],
        [74.5984725952149, 13.881530761718864],
        [74.59819793701166, 13.8815279006958],
        [74.59819793701166, 13.88208198547369],
        [74.59791564941429, 13.88208198547369],
        [74.59791564941429, 13.882362365722656],
        [74.59764099121111, 13.882360458374023],
        [74.59764099121111, 13.882921218872184],
        [74.59735870361334, 13.882916450500545],
        [74.59735870361334, 13.88347244262701],
        [74.5970764160158, 13.883469581604118],
        [74.5970764160158, 13.884031295776367],
        [74.59680938720732, 13.884029388427734],
        [74.59680175781278, 13.884581565857047],
        [74.59652709960938, 13.8845796585083],
        [74.59652709960938, 13.885141372680721],
        [74.59625244140653, 13.885137557983512],
        [74.59625244140653, 13.88569545745861],
        [74.5959701538086, 13.885689735412654],
        [74.5959701538086, 13.886251449585075],
        [74.59570312500011, 13.886251449585075],
        [74.59570312500011, 13.886811256408691],
        [74.59541320800787, 13.886804580688477],
        [74.59541320800787, 13.887360572814941],
        [74.59513854980463, 13.887360572814941],
        [74.59513854980463, 13.887921333313045],
        [74.59486389160179, 13.887916564941406],
        [74.59486389160179, 13.888472557067871],
        [74.59458160400408, 13.888459205627385],
        [74.59458160400408, 13.890140533447322],
        [74.59430694580084, 13.89013862609869],
        [74.59430694580084, 13.892083168029728],
        [74.5940246582033, 13.892083168029728],
        [74.5940246582033, 13.893195152282658],
        [74.59375000000028, 13.893191337585506],
        [74.59375000000028, 13.894031524658317],
        [74.5934829711914, 13.894031524658317],
        [74.5934829711914, 13.8951416015625],
        [74.5931930541995, 13.8951416015625],
        [74.5931930541995, 13.896251678466854],
        [74.5929183959961, 13.896251678466854],
        [74.5929183959961, 13.897650718689022],
        [74.59263610839872, 13.897650718689022],
        [74.59263610839872, 13.899310111999569],
        [74.59236145019537, 13.899306297302246],
        [74.59236145019537, 13.900972366333065],
        [74.59208679199213, 13.900968551635856],
        [74.59208679199213, 13.90264034271246],
        [74.59179687500006, 13.90264034271246],
        [74.59179687500006, 13.904311180114746],
        [74.59152984619158, 13.904306411743278],
        [74.59152984619158, 13.905971527099666],
        [74.59124755859398, 13.905971527099666],
        [74.59124755859398, 13.907084465026855],
        [74.5909729003908, 13.907079696655217],
        [74.5909729003908, 13.907640457153377],
        [74.59069824218778, 13.907637596130485],
        [74.59069824218778, 13.90847206115734],
        [74.59041595459001, 13.908470153808537],
        [74.59041595459001, 13.909311294555664],
        [74.590141296387, 13.90930557250988],
        [74.590141296387, 13.90986347198492],
        [74.58985900878906, 13.90986347198492],
        [74.58985900878906, 13.910694122314453],
        [74.58957672119169, 13.910690307617244],
        [74.58957672119169, 13.911251068115234],
        [74.58930206298834, 13.911251068115234],
        [74.58930206298834, 13.912084579467773],
        [74.58902740478527, 13.912084579467773],
        [74.58902740478527, 13.912638664245662],
        [74.58875274658209, 13.91263198852539],
        [74.58875274658209, 13.913750648498592],
        [74.58847808837908, 13.913750648498592],
        [74.58847808837908, 13.914311408996525],
        [74.58819580078148, 13.914311408996525],
        [74.58819580078148, 13.914860725402946],
        [74.58791351318376, 13.914860725402946],
        [74.58791351318376, 13.915695190429801],
        [74.58763885498053, 13.915690422058162],
        [74.58763885498053, 13.916530609130973],
        [74.58736419677751, 13.916526794433594],
        [74.58736419677751, 13.91736125946045],
        [74.58708190917997, 13.91736125946045],
        [74.58708190917997, 13.918194770812988],
        [74.58680725097656, 13.91819000244135],
        [74.58680725097656, 13.918760299682617],
        [74.58651733398466, 13.918760299682617],
        [74.58651733398466, 13.919309616088867],
        [74.58625030517584, 13.919309616088867],
        [74.58625030517584, 13.919860839843864],
        [74.58598327636713, 13.919860839843864],
        [74.58598327636713, 13.920421600341797],
        [74.58569335937506, 13.920416831970329],
        [74.58569335937506, 13.92097091674799],
        [74.58541870117182, 13.92097091674799],
        [74.58541870117182, 13.92153167724615],
        [74.58513641357445, 13.921526908874512],
        [74.58513641357445, 13.922082901000977],
        [74.58486175537126, 13.922080993652344],
        [74.58486175537126, 13.922641754150504],
        [74.58458709716803, 13.922638893127441],
        [74.58458709716803, 13.923194885253906],
        [74.58430480957048, 13.923194885253906],
        [74.58430480957048, 13.923474311828556],
        [74.58403015136747, 13.923474311828556],
        [74.58403015136747, 13.923601150512695],
        [74.58403015136747, 13.924030303955078],
        [74.58374786376953, 13.924030303955078],
        [74.58374786376953, 13.924309730529899],
        [74.58347320556669, 13.92430496215826],
        [74.58347320556669, 13.92458343505865],
        [74.58319091796875, 13.92457199096691],
        [74.58319091796875, 13.924861907959041],
        [74.58300781250006, 13.924861907959041],
        [74.58291625976574, 13.924861907959041],
        [74.58291625976574, 13.925140380859432],
        [74.58235168457043, 13.925140380859432],
        [74.58235168457043, 13.925419807434082],
        [74.57930755615229, 13.925417900085506],
        [74.57930755615229, 13.925692558288688],
        [74.57902526855474, 13.925692558288688],
        [74.57903289794945, 13.926250457763786],
        [74.57875061035173, 13.926250457763786],
        [74.57875061035173, 13.927083969116325],
        [74.5784759521485, 13.927083969116325],
        [74.5784759521485, 13.92763996124279],
        [74.57819366455095, 13.92763996124279],
        [74.57819366455095, 13.928471565246582],
        [74.57791900634794, 13.928471565246582],
        [74.57791900634794, 13.92903041839611],
        [74.57763671875017, 13.92903041839611],
        [74.57763671875017, 13.929862022399902],
        [74.57736206054716, 13.929862022399902],
        [74.57736206054716, 13.930419921875],
        [74.57707977294922, 13.930419921875],
        [74.57707977294922, 13.93124961853033],
        [74.57681274414074, 13.93124961853033],
        [74.57681274414074, 13.93181133270258],
        [74.57653808593756, 13.93181133270258],
        [74.57653808593756, 13.932640075683707],
        [74.57624816894543, 13.932640075683707],
        [74.57624816894543, 13.933194160461369],
        [74.57596588134771, 13.9331893920899],
        [74.57596588134771, 13.933761596679744],
        [74.57569122314447, 13.933761596679744],
        [74.57569122314447, 13.934310913085994],
        [74.57541656494163, 13.934306144714355],
        [74.57541656494163, 13.934860229492188],
        [74.57514190673845, 13.934860229492188],
        [74.57514190673845, 13.935420989990348],
        [74.57485961914068, 13.93541622161871],
        [74.57485961914068, 13.935972213745174],
        [74.57459259033203, 13.935970306396541],
        [74.57459259033203, 13.936531066894531],
        [74.57430267334013, 13.936528205871639],
        [74.57430267334013, 13.937082290649528],
        [74.57402801513672, 13.937080383300724],
        [74.57402801513672, 13.937641143798885],
        [74.57375335693388, 13.937638282775993],
        [74.57375335693388, 13.938194274902457],
        [74.573471069336, 13.938194274902457],
        [74.57347869873053, 13.938751220703239],
        [74.57319641113293, 13.938751220703239],
        [74.57319641113293, 13.939311027526855],
        [74.57291412353521, 13.93930435180664],
        [74.57291412353521, 13.939861297607422],
        [74.57263946533197, 13.939861297607422],
        [74.57263946533197, 13.94042110443121],
        [74.57236480712913, 13.940417289733887],
        [74.57236480712913, 13.940973281860352],
        [74.57208251953142, 13.94097042083746],
        [74.57208251953142, 13.941531181335392],
        [74.57180786132818, 13.94152736663824],
        [74.57180786132818, 13.942083358764705],
        [74.57153320312517, 13.942079544067326],
        [74.57153320312517, 13.942931175232047],
        [74.57125091552763, 13.942931175232047],
        [74.57125091552763, 13.9437513351441],
        [74.57096862792969, 13.9437513351441],
        [74.57096862792969, 13.944309234619197],
        [74.57068634033232, 13.944309234619197],
        [74.57068634033232, 13.94514083862316],
        [74.5704193115235, 13.945138931274357],
        [74.5704193115235, 13.945261955261287],
        [74.5704193115235, 13.945973396301213],
        [74.5701370239259, 13.94597053527832],
        [74.5701370239259, 13.946529388427678],
        [74.56986236572271, 13.946529388427678],
        [74.56986236572271, 13.946809768676871],
        [74.56958007812494, 13.946805000305176],
        [74.56958007812494, 13.947083473205566],
        [74.56903076171892, 13.947080612182674],
        [74.56903076171892, 13.947641372680664],
        [74.56846618652361, 13.947641372680664],
        [74.56846618652361, 13.947921752929801],
        [74.5681915283206, 13.947916984558162],
        [74.5681915283206, 13.948195457458553],
        [74.56791687011719, 13.948195457458553],
        [74.56791687011719, 13.948471069336051],
        [74.5673599243164, 13.948471069336051],
        [74.5673599243164, 13.948748588562125],
        [74.56680297851568, 13.948740005493278],
        [74.56680297851568, 13.949031829833984],
        [74.56597137451189, 13.949031829833984],
        [74.56597137451189, 13.948740005493278],
        [74.56569671630865, 13.948748588562125],
        [74.56569671630865, 13.948471069336051],
        [74.5651397705081, 13.948471069336051],
        [74.5651397705081, 13.948195457458553],
        [74.56485748291033, 13.948195457458553],
        [74.56485748291033, 13.947916984558162],
        [74.5643081665039, 13.947921752929801],
        [74.5643081665039, 13.947641372680664],
        [74.56402587890653, 13.947641372680664],
        [74.56402587890653, 13.947160720825252],
        [74.56402587890653, 13.947083473205566],
        [74.56375122070318, 13.947083473205566],
        [74.56375122070318, 13.946249008178711],
        [74.56346893310558, 13.946250915527344],
        [74.56346893310558, 13.945699691772518],
        [74.56318664550787, 13.945699691772518],
        [74.56318664550787, 13.945419311523551],
        [74.56153106689482, 13.945419311523551],
        [74.56153106689482, 13.945699691772518],
        [74.56041717529308, 13.945694923400822],
        [74.56041717529308, 13.945973396301213],
        [74.5601425170899, 13.94597053527832],
        [74.5601425170899, 13.946250915527344],
        [74.56041717529308, 13.946250915527344],
        [74.56041717529308, 13.946529388427678],
        [74.5601425170899, 13.946529388427678],
        [74.5601425170899, 13.947360992431697],
        [74.55986022949213, 13.947360992431697],
        [74.55986022949213, 13.948231697082633],
        [74.55986022949213, 13.949031829833984],
        [74.55959320068365, 13.949031829833984],
        [74.55959320068365, 13.949310302734375],
        [74.55930328369158, 13.94930458068859],
        [74.55930328369158, 13.94958305358898],
        [74.55902862548834, 13.94958305358898],
        [74.55902862548834, 13.949860572815055],
        [74.5587463378908, 13.949860572815055],
        [74.5587463378908, 13.950141906738338],
        [74.55847167968778, 13.950141906738338],
        [74.55847167968778, 13.950420379638729],
        [74.55819702148438, 13.95041656494152],
        [74.55819702148438, 13.950693130493164],
        [74.557914733887, 13.950693130493164],
        [74.557914733887, 13.950974464416618],
        [74.55764007568365, 13.950970649719238],
        [74.55764007568365, 13.951251983642692],
        [74.55541229248075, 13.951251983642692],
        [74.55541229248075, 13.950970649719238],
        [74.55430603027355, 13.950974464416618],
        [74.55430603027355, 13.950693130493164],
        [74.55319213867205, 13.950693130493164],
        [74.55319213867205, 13.95041656494152],
        [74.5529174804688, 13.950420379638729],
        [74.5529174804688, 13.950141906738338],
        [74.5526428222658, 13.950141906738338],
        [74.55263519287126, 13.950048446655273],
        [74.5526199340822, 13.949860572815055],
        [74.55208587646484, 13.949860572815055],
        [74.55208587646484, 13.94958305358898],
        [74.55180358886747, 13.94958305358898],
        [74.55180358886747, 13.94930458068859],
        [74.55152893066406, 13.949310302734375],
        [74.55152893066406, 13.949031829833984],
        [74.55124664306669, 13.949031829833984],
        [74.55124664306669, 13.948740005493278],
        [74.5506973266601, 13.948748588562125],
        [74.5506973266601, 13.948471069336051],
        [74.55041503906256, 13.948471069336051],
        [74.55041503906256, 13.948195457458553],
        [74.54985809326195, 13.948195457458553],
        [74.54985809326195, 13.948471069336051],
        [74.54875183105497, 13.948471069336051],
        [74.54875183105497, 13.948748588562125],
        [74.54846954345703, 13.948751449585018],
        [74.54846954345703, 13.950974464416618],
        [74.54819488525419, 13.950974464416618],
        [74.54819488525419, 13.951805114746094],
        [74.54792022705084, 13.951805114746094],
        [74.54792022705084, 13.952821731567383],
        [74.54792022705084, 13.95291805267334],
        [74.5477828979495, 13.952919960022086],
        [74.54763793945324, 13.952919960022086],
        [74.54763793945324, 13.953749656677303],
        [74.54736328125006, 13.953749656677303],
        [74.54736328125006, 13.954310417175236],
        [74.54708099365229, 13.954310417175236],
        [74.54708099365229, 13.954871177673397],
        [74.54680633544945, 13.954871177673397],
        [74.54680633544945, 13.95541954040533],
        [74.54652404785173, 13.955415725708121],
        [74.54652404785173, 13.955971717834586],
        [74.54624176025396, 13.955968856811523],
        [74.54624176025396, 13.956529617309684],
        [74.5448608398438, 13.956529617309684],
        [74.5448608398438, 13.95680999755865],
        [74.54347229003923, 13.956806182861442],
        [74.54347229003923, 13.957083702087346],
        [74.54208374023466, 13.957083702087346],
        [74.54208374023466, 13.957362174987736],
        [74.5418090820313, 13.95736026763916],
        [74.5418090820313, 13.95763969421381],
        [74.54152679443371, 13.95763969421381],
        [74.54152679443371, 13.957928657531795],
        [74.54141998291016, 13.957928657531795],
        [74.54125213623053, 13.957928657531795],
        [74.54125213623053, 13.958751678466797],
        [74.54096984863276, 13.958749771118164],
        [74.54096984863276, 13.95958423614502],
        [74.54069519042991, 13.95958423614502],
        [74.54069519042991, 13.961771965026855],
        [74.54069519042991, 13.962362289428825],
        [74.54058074951183, 13.962362289428825],
        [74.5404129028322, 13.962360382080021],
        [74.5404129028322, 13.962921142578182],
        [74.53986358642595, 13.962916374206543],
        [74.53986358642595, 13.963472366333008],
        [74.53958129882841, 13.963470458984375],
        [74.53958129882841, 13.964310646057186],
        [74.5390167236331, 13.964310646057186],
        [74.5390167236331, 13.964859962463436],
        [74.53874969482422, 13.964859962463436],
        [74.53874969482422, 13.965141296386719],
        [74.53848266601574, 13.965141296386719],
        [74.53848266601574, 13.965420722961369],
        [74.53791809082026, 13.9654159545899],
        [74.53791809082026, 13.965695381164608],
        [74.53763580322271, 13.965695381164608],
        [74.53763580322271, 13.965972900390682],
        [74.53708648681646, 13.96597003936779],
        [74.53708648681646, 13.966251373291072],
        [74.53679656982439, 13.966251373291072],
        [74.53679656982439, 13.966530799865723],
        [74.53597259521513, 13.966530799865723],
        [74.53597259521513, 13.96681118011469],
        [74.53542327880871, 13.96681118011469],
        [74.53542327880871, 13.966530799865723],
        [74.5348587036134, 13.966530799865723],
        [74.5348587036134, 13.966251373291072],
        [74.53457641601568, 13.966251373291072],
        [74.53457641601568, 13.965689659118652],
        [74.53430175781244, 13.965695381164608],
        [74.53430175781244, 13.965138435363826],
        [74.5340270996096, 13.965141296386719],
        [74.5340270996096, 13.96457958221447],
        [74.5318069458009, 13.96457958221447],
        [74.5318069458009, 13.964859962463436],
        [74.53151702880865, 13.964859962463436],
        [74.53151702880865, 13.969031333923397],
        [74.53124237060541, 13.969031333923397],
        [74.53124237060541, 13.969869613647461],
        [74.53098297119146, 13.969869613647461],
        [74.53098297119146, 13.97014141082758],
        [74.53069305419939, 13.97014141082758],
        [74.53069305419939, 13.970420837402457],
        [74.53041839599615, 13.970417022705078],
        [74.53041839599615, 13.970973014831543],
        [74.53013610839861, 13.970971107482967],
        [74.53013610839861, 13.971250534057674],
        [74.5298614501956, 13.971250534057674],
        [74.5298614501956, 13.971809387207031],
        [74.52958679199219, 13.971804618835506],
        [74.52958679199219, 13.972083091735897],
        [74.52930450439482, 13.972083091735897],
        [74.52930450439482, 13.972360610962028],
        [74.52902984619146, 13.972360610962028],
        [74.52902984619146, 13.972640991210994],
        [74.52874755859386, 13.972640991210994],
        [74.52874755859386, 13.972930908203068],
        [74.52819824218744, 13.972916603088493],
        [74.52819824218744, 13.973195075988883],
        [74.52735900878912, 13.973195075988883],
        [74.52735900878912, 13.973472595214957],
        [74.52652740478516, 13.973470687866154],
        [74.52652740478516, 13.973751068115348],
        [74.52540588378912, 13.973751068115348],
        [74.52540588378912, 13.974311828613281],
        [74.52513885498041, 13.97430515289301],
        [74.52513885498041, 13.97458267211914],
        [74.52485656738287, 13.974580764770508],
        [74.52485656738287, 13.975141525268668],
        [74.52458190917986, 13.975141525268668],
        [74.52458190917986, 13.975421905517635],
        [74.52430725097662, 13.975417137145996],
        [74.52430725097662, 13.97569465637207],
        [74.52403259277361, 13.975679397583008],
        [74.52403259277361, 13.976250648498535],
        [74.52375030517607, 13.976250648498535],
        [74.52375030517607, 13.976531982421989],
        [74.52348327636719, 13.976531982421989],
        [74.52348327636719, 13.97763919830328],
        [74.52291870117188, 13.97763919830328],
        [74.52291870117188, 13.977919578552246],
        [74.5226364135745, 13.97791767120367],
        [74.5226364135745, 13.978470802307243],
        [74.52208709716791, 13.978468894958496],
        [74.52208709716791, 13.9790296554566],
        [74.52180480957037, 13.979026794433707],
        [74.52180480957037, 13.979583740234489],
        [74.52153015136736, 13.979583740234489],
        [74.52153015136736, 13.97986221313488],
        [74.52124786376976, 13.979859352111816],
        [74.52124786376976, 13.98042011260992],
        [74.52097320556658, 13.980415344238281],
        [74.52097320556658, 13.980972290039062],
        [74.5206909179688, 13.98096942901617],
        [74.5206909179688, 13.981251716613713],
        [74.5204162597658, 13.981251716613713],
        [74.5204162597658, 13.98180961608898],
        [74.52014160156278, 13.98180961608898],
        [74.52014160156278, 13.982361793518066],
        [74.51985931396484, 13.982361793518066],
        [74.51985931396484, 13.982640266418457],
        [74.51902770996105, 13.982640266418457],
        [74.51902770996105, 13.982919692993164],
        [74.51819610595726, 13.982917785644531],
        [74.51819610595726, 13.983194351196346],
        [74.51791381835955, 13.983194351196346],
        [74.51791381835955, 13.98347187042242],
        [74.5176391601563, 13.98347187042242],
        [74.5176391601563, 13.984029769897518],
        [74.51735687255876, 13.984029769897518],
        [74.51735687255876, 13.984310150146484],
        [74.51708221435575, 13.984306335449276],
        [74.51708221435575, 13.98458385467535],
        [74.5165328979495, 13.984581947326774],
        [74.5165328979495, 13.984870910644588],
        [74.51625823974615, 13.984870910644588],
        [74.51625823974615, 13.985139846801871],
        [74.51597595214855, 13.985139846801871],
        [74.51597595214855, 13.985420227050838],
        [74.51569366455084, 13.98541641235363],
        [74.51569366455084, 13.985693931579533],
        [74.51513671875023, 13.985693931579533],
        [74.51513671875023, 13.985972404479924],
        [74.51486206054705, 13.985969543457031],
        [74.51486206054705, 13.986249923705998],
        [74.51457977294928, 13.986249923705998],
        [74.51457977294928, 13.987640380859375],
        [74.5143127441408, 13.987640380859375],
        [74.5143127441408, 13.990140914917049],
        [74.51403045654325, 13.990140914917049],
        [74.51403045654325, 13.99181079864502],
        [74.51374816894531, 13.99181079864502],
        [74.51374816894531, 13.99236011505127],
        [74.51346588134794, 13.99236011505127],
        [74.51346588134794, 13.992920875549373],
        [74.51319122314453, 13.99291706085205],
        [74.51319122314453, 13.995695114135799],
        [74.51291656494152, 13.99569034576416],
        [74.51291656494152, 13.996251106262264],
        [74.51264190673834, 13.996251106262264],
        [74.51264190673834, 13.996811866760197],
        [74.51235961914057, 13.996805191040153],
        [74.51235961914057, 13.997082710266056],
        [74.51208496093773, 13.997082710266056],
        [74.51208496093773, 13.997361183166618],
        [74.51180267334001, 13.997361183166618],
        [74.51180267334001, 13.997920989990291],
        [74.51152801513678, 13.997920989990291],
        [74.51152801513678, 13.998750686645508],
        [74.51125335693376, 13.998750686645508],
        [74.51125335693376, 13.999031066894645],
        [74.51097106933622, 13.999031066894645],
        [74.51097106933622, 13.99930858612072],
        [74.50985717773466, 13.99930858612072],
        [74.50985717773466, 13.9999294281007],
        [74.50985717773466, 14.000139236450195],
        [74.5101318359375, 14.000139236450195],
        [74.5101318359375, 14.000970840454215],
        [74.50985717773466, 14.000970840454215],
        [74.50985717773466, 14.001251220703182],
        [74.5095825195313, 14.001251220703182],
        [74.5095825195313, 14.001811981201286],
        [74.50930786132807, 14.00180530548107],
        [74.50930786132807, 14.002082824707145],
        [74.50903320312523, 14.002080917358342],
        [74.50903320312523, 14.002641677856502],
        [74.50875091552751, 14.00263881683361],
        [74.50875091552751, 14.004583358764648],
        [74.50902557373053, 14.004583358764648],
        [74.50902557373053, 14.007083892822322],
        [74.50875091552751, 14.00708103179943],
        [74.50875091552751, 14.007641792297363],
        [74.50846862792991, 14.007639884948787],
        [74.50846862792991, 14.008191108703613],
        [74.5081863403322, 14.008199691772461],
        [74.5081863403322, 14.009040832519531],
        [74.50791931152372, 14.009028434753532],
        [74.50791931152372, 14.009584426879996],
        [74.50763702392595, 14.009581565856934],
        [74.50763702392595, 14.010140419006461],
        [74.50736236572294, 14.010137557983398],
        [74.50736236572294, 14.010972023010254],
        [74.50707244873047, 14.010972023010254],
        [74.50707244873047, 14.011529922485352],
        [74.50680541992216, 14.011528015136719],
        [74.50680541992216, 14.012361526489315],
        [74.5065307617188, 14.012359619140682],
        [74.5065307617188, 14.012920379638672],
        [74.50624847412121, 14.012915611267204],
        [74.50624847412121, 14.013471603393668],
        [74.5059661865235, 14.013469696044865],
        [74.5059661865235, 14.014310836791992],
        [74.50569152832026, 14.014306068420524],
        [74.50569152832026, 14.014860153198185],
        [74.50541687011741, 14.014850616455078],
        [74.50541687011741, 14.015420913696346],
        [74.50514221191423, 14.015416145324707],
        [74.50514221191423, 14.015972137451172],
        [74.50485992431646, 14.015970230102539],
        [74.50485992431646, 14.0165309906007],
        [74.50458526611345, 14.016528129577637],
        [74.50458526611345, 14.017082214355526],
        [74.50430297851591, 14.017080307006893],
        [74.50430297851591, 14.017641067504883],
        [74.5040283203125, 14.01763820648199],
        [74.5040283203125, 14.018194198608455],
        [74.50374603271513, 14.018190383911076],
        [74.50374603271513, 14.018751144409237],
        [74.50347137451178, 14.018751144409237],
        [74.50347137451178, 14.019310951232853],
        [74.50319671630871, 14.019304275512809],
        [74.50319671630871, 14.019860267639274],
        [74.50290679931646, 14.019860267639274],
        [74.50290679931646, 14.020421028137207],
        [74.50263977050776, 14.020421028137207],
        [74.50263977050776, 14.020981788635368],
        [74.50235748291038, 14.020981788635368],
        [74.50235748291038, 14.02153110504156],
        [74.5020828247072, 14.021529197692985],
        [74.5020828247072, 14.022083282470703],
        [74.50180816650396, 14.02208042144781],
        [74.50180816650396, 14.022641181945744],
        [74.50152587890642, 14.022641181945744],
        [74.50152587890642, 14.023751258850098],
        [74.50125122070341, 14.023751258850098],
        [74.50125122070341, 14.024861335754451],
        [74.50152587890642, 14.024861335754451],
        [74.50152587890642, 14.025140762329158],
        [74.5020828247072, 14.025140762329158],
        [74.5020828247072, 14.025419235229549],
        [74.50235748291038, 14.025419235229549],
        [74.50235748291038, 14.026250839233512],
        [74.50290679931646, 14.026250839233512],
        [74.50290679931646, 14.026529312133903],
        [74.50485992431646, 14.026529312133903],
        [74.50485992431646, 14.026250839233512],
        [74.50569152832026, 14.026250839233512],
        [74.50569152832026, 14.026529312133903],
        [74.5059661865235, 14.026529312133903],
        [74.5059661865235, 14.02680969238287],
        [74.50624847412121, 14.026804924011174],
        [74.50624847412121, 14.027083396911564],
        [74.5065307617188, 14.027080535888672],
        [74.5065307617188, 14.027360916137695],
        [74.50681304931652, 14.027360916137695],
        [74.50681304931652, 14.027641296386832],
        [74.50763702392595, 14.027641296386832],
        [74.50763702392595, 14.027921676635799],
        [74.51097106933622, 14.027921676635799],
        [74.51097106933622, 14.027641296386832],
        [74.51125335693376, 14.027641296386832],
        [74.51125335693376, 14.027921676635799],
        [74.51188659667974, 14.02791690826416],
        [74.51209259033226, 14.027921676635799],
        [74.51210021972662, 14.027641296386832],
        [74.51346588134794, 14.027641296386832],
        [74.51346588134794, 14.027360916137695],
        [74.51403045654325, 14.027360916137695],
        [74.51403045654325, 14.027080535888672],
        [74.51457977294928, 14.027083396911564],
        [74.51457977294928, 14.026804924011174],
        [74.51513671875023, 14.02680969238287],
        [74.51513671875023, 14.026529312133903],
        [74.5156860351563, 14.026529312133903],
        [74.5156860351563, 14.026250839233512],
        [74.5176391601563, 14.026250839233512],
        [74.5176391601563, 14.02680969238287],
        [74.51791381835955, 14.026804924011174],
        [74.51791381835955, 14.027083396911564],
        [74.5176391601563, 14.027080535888672],
        [74.5176391601563, 14.027360916137695],
        [74.51735687255876, 14.027360916137695],
        [74.51735687255876, 14.027641296386832],
        [74.51680755615234, 14.027641296386832],
        [74.51680755615234, 14.027921676635799],
        [74.51652526855497, 14.02791690826416],
        [74.51652526855497, 14.02819538116455],
        [74.51597595214855, 14.02819538116455],
        [74.51597595214855, 14.028470993042049],
        [74.5156860351563, 14.028470993042049],
        [74.5156860351563, 14.028751373291016],
        [74.5154190063476, 14.028751373291016],
        [74.5154190063476, 14.029031753540153],
        [74.51513671875023, 14.029031753540153],
        [74.51513671875023, 14.029310226440543],
        [74.51457977294928, 14.029305458068904],
        [74.51457977294928, 14.029582977294979],
        [74.51374816894531, 14.029582977294979],
        [74.51374816894531, 14.02986145019537],
        [74.51345825195341, 14.02986145019537],
        [74.51291656494152, 14.02986145019537],
        [74.51291656494152, 14.030151367187443],
        [74.51235961914057, 14.030151367187443],
        [74.51235961914057, 14.030420303344727],
        [74.51180267334001, 14.030417442321834],
        [74.51180267334001, 14.030693054199332],
        [74.51152801513678, 14.030693054199332],
        [74.51152801513678, 14.030974388122615],
        [74.51097106933622, 14.030970573425407],
        [74.51097106933622, 14.03125190734869],
        [74.51040649414091, 14.03125190734869],
        [74.51040649414091, 14.03153038024908],
        [74.50903320312523, 14.03153038024908],
        [74.50903320312523, 14.03180980682373],
        [74.50846862792991, 14.031805038452262],
        [74.50846862792991, 14.032082557678166],
        [74.50814819335949, 14.03208065032959],
        [74.50791931152372, 14.03208065032959],
        [74.50791931152372, 14.032361984253043],
        [74.50736236572294, 14.032361984253043],
        [74.50736236572294, 14.03292179107666],
        [74.507080078125, 14.032917976379508],
        [74.507080078125, 14.033196449279899],
        [74.50681304931652, 14.033190727233944],
        [74.50681304931652, 14.0337495803833],
        [74.5065307617188, 14.0337495803833],
        [74.5065307617188, 14.034310340881404],
        [74.507080078125, 14.034305572509766],
        [74.507080078125, 14.034584045410156],
        [74.50736236572294, 14.034579277038688],
        [74.50736236572294, 14.035420417785588],
        [74.50763702392595, 14.035420417785588],
        [74.50763702392595, 14.03625202178955],
        [74.50791931152372, 14.036250114440975],
        [74.50791931152372, 14.037083625793514],
        [74.5081863403322, 14.037078857421875],
        [74.5081863403322, 14.037919998169002],
        [74.50846862792991, 14.03791809082037],
        [74.50846862792991, 14.038193702697697],
        [74.50875091552751, 14.038191795349121],
        [74.50875091552751, 14.038749694824162],
        [74.50902557373053, 14.038749694824162],
        [74.50902557373053, 14.039028167724553],
        [74.507080078125, 14.039028167724553],
        [74.507080078125, 14.038193702697697],
        [74.50680541992216, 14.038193702697697],
        [74.50680541992216, 14.037637710571232],
        [74.50624847412121, 14.037639617920036],
        [74.50624847412121, 14.037360191345329],
        [74.5059661865235, 14.037360191345329],
        [74.5059661865235, 14.037639617920036],
        [74.50569152832026, 14.037637710571232],
        [74.50569152832026, 14.038193702697697],
        [74.50541687011741, 14.038191795349121],
        [74.50541687011741, 14.038749694824162],
        [74.50514221191423, 14.038749694824162],
        [74.50514221191423, 14.039311408996582],
        [74.50485992431646, 14.039305686950684],
        [74.50485992431646, 14.039590835571289],
        [74.50485992431646, 14.039861679077148],
        [74.50458526611345, 14.039859771728516],
        [74.50458526611345, 14.040641784667912],
        [74.50459289550781, 14.041810989379996],
        [74.50428009033209, 14.041806221008358],
        [74.5040283203125, 14.041810989379996],
        [74.5040283203125, 14.041250228881893],
        [74.50374603271513, 14.041250228881893],
        [74.50374603271513, 14.040689468383732],
        [74.50347137451178, 14.040694236755371],
        [74.50347137451178, 14.039028167724553],
        [74.50375366210966, 14.039028167724553],
        [74.50375366210966, 14.037637710571232],
        [74.5040283203125, 14.037639617920036],
        [74.5040283203125, 14.037360191345329],
        [74.50430297851591, 14.037362098693904],
        [74.50430297851591, 14.03680610656744],
        [74.50459289550781, 14.036809921264648],
        [74.50459289550781, 14.036529541015682],
        [74.50485992431646, 14.036529541015682],
        [74.50485992431646, 14.035960197448674],
        [74.50458526611345, 14.035971641540584],
        [74.50458526611345, 14.03541564941412],
        [74.50430297851591, 14.035420417785588],
        [74.50430297851591, 14.034029960632438],
        [74.50485992431646, 14.034029960632438],
        [74.50485992431646, 14.033469200134334],
        [74.50569152832026, 14.03347110748291],
        [74.50569152832026, 14.032917976379508],
        [74.5059661865235, 14.03292179107666],
        [74.5059661865235, 14.032640457153434],
        [74.50514221191423, 14.032640457153434],
        [74.50514221191423, 14.03208065032959],
        [74.50541687011741, 14.032082557678166],
        [74.50541687011741, 14.031526565551871],
        [74.50569152832026, 14.03153038024908],
        [74.50569152832026, 14.03125190734869],
        [74.5059661865235, 14.03125190734869],
        [74.5059661865235, 14.030420303344727],
        [74.50624847412121, 14.030420303344727],
        [74.50624847412121, 14.02986145019537],
        [74.50569152832026, 14.02986145019537],
        [74.50569152832026, 14.029582977294979],
        [74.50541687011741, 14.029582977294979],
        [74.50541687011741, 14.029305458068904],
        [74.50485992431646, 14.029310226440543],
        [74.50485992431646, 14.029031753540153],
        [74.50430297851591, 14.029031753540153],
        [74.50430297851591, 14.028751373291016],
        [74.5040283203125, 14.028751373291016],
        [74.5040283203125, 14.028470993042049],
        [74.50347137451178, 14.028470993042049],
        [74.50347137451178, 14.02819538116455],
        [74.50319671630871, 14.02819538116455],
        [74.50319671630871, 14.02791690826416],
        [74.50263977050776, 14.027921676635799],
        [74.50263977050776, 14.027641296386832],
        [74.50235748291038, 14.027641296386832],
        [74.50235748291038, 14.027360916137695],
        [74.5020828247072, 14.027360916137695],
        [74.5020828247072, 14.027080535888672],
        [74.50180816650396, 14.027080535888672],
        [74.50180816650396, 14.027360916137695],
        [74.50125122070341, 14.027360916137695],
        [74.50125122070341, 14.027083396911564],
        [74.50096893310547, 14.027080535888672],
        [74.50096893310547, 14.029031753540153],
        [74.5006866455081, 14.029031753540153],
        [74.5006866455081, 14.03180980682373],
        [74.50096893310547, 14.03180980682373],
        [74.50096893310547, 14.036529541015682],
        [74.50069427490263, 14.036527633667049],
        [74.50069427490263, 14.040694236755371],
        [74.50041961669928, 14.040694236755371],
        [74.50041961669928, 14.043193817138786],
        [74.50013732910168, 14.043191909790039],
        [74.50013732910168, 14.044862747192496],
        [74.4998626708985, 14.044862747192496],
        [74.4998626708985, 14.04653072357172],
        [74.49958038330072, 14.046528816223145],
        [74.49958038330072, 14.048194885253963],
        [74.49930572509788, 14.048194885253963],
        [74.49931335449241, 14.049860954284782],
        [74.4990310668947, 14.049860954284782],
        [74.4990310668947, 14.051251411437931],
        [74.49874877929693, 14.051251411437931],
        [74.49874877929693, 14.052360534668026],
        [74.49847412109392, 14.052360534668026],
        [74.49847412109392, 14.053473472595215],
        [74.49819183349638, 14.053473472595215],
        [74.49819183349638, 14.054582595825309],
        [74.49791717529297, 14.054582595825309],
        [74.49791717529297, 14.055694580078239],
        [74.49764251709013, 14.05569076538086],
        [74.49764251709013, 14.056810379028377],
        [74.49736022949219, 14.056810379028377],
        [74.49736022949219, 14.05792045593273],
        [74.49709320068371, 14.05792045593273],
        [74.49709320068371, 14.059029579162598],
        [74.49680328369146, 14.059026718139705],
        [74.49680328369146, 14.060139656066951],
        [74.49652862548822, 14.060130119323844],
        [74.49652862548822, 14.061249732971305],
        [74.49624633789068, 14.061249732971305],
        [74.49624633789068, 14.062361717224064],
        [74.49597167968767, 14.062361717224064],
        [74.49597167968767, 14.063471794128418],
        [74.49569702148443, 14.063471794128418],
        [74.49569702148443, 14.064310073852653],
        [74.49540710449236, 14.064310073852653],
        [74.49540710449236, 14.065420150756836],
        [74.49513244628912, 14.065420150756836],
        [74.49513244628912, 14.06653022766119],
        [74.49485778808611, 14.06653022766119],
        [74.49485778808611, 14.067640304565543],
        [74.4945831298831, 14.067640304565543],
        [74.4945831298831, 14.06903266906744],
        [74.49430847167969, 14.06903266906744],
        [74.49430847167969, 14.070421218872184],
        [74.49402618408232, 14.070421218872184],
        [74.49402618408232, 14.071810722351017],
        [74.49375152587896, 14.071806907653865],
        [74.49375152587896, 14.07319545745861],
        [74.49346923828136, 14.073189735412654],
        [74.49346923828136, 14.074311256408691],
        [74.49319458007818, 14.07430362701416],
        [74.49319458007818, 14.075695037841797],
        [74.49291992187517, 14.075695037841797],
        [74.49291992187517, 14.077082633972225],
        [74.49263763427757, 14.077082633972225],
        [74.49263763427757, 14.078473091125545],
        [74.49236297607439, 14.07847118377697],
        [74.49236297607439, 14.079309463501033],
        [74.49207305908209, 14.079309463501033],
        [74.49207305908209, 14.07986068725586],
        [74.49180603027361, 14.07986068725586],
        [74.49180603027361, 14.08041858673107],
        [74.4915313720706, 14.080416679382324],
        [74.4915313720706, 14.080972671508789],
        [74.49124908447266, 14.08096885681158],
        [74.49124908447266, 14.081531524658317],
        [74.49097442626982, 14.081526756286678],
        [74.49097442626982, 14.082082748413143],
        [74.49069213867193, 14.082080841064567],
        [74.49069213867193, 14.0826416015625],
        [74.49041748046886, 14.0826416015625],
        [74.49041748046886, 14.082921981811637],
        [74.49013519287115, 14.082917213439998],
        [74.49013519287115, 14.083471298217887],
        [74.48986053466791, 14.083471298217887],
        [74.48986053466791, 14.084030151367244],
        [74.48958587646507, 14.084027290344352],
        [74.48958587646507, 14.084583282470817],
        [74.48929595947283, 14.08458137512207],
        [74.48929595947283, 14.085139274597282],
        [74.48902893066412, 14.085139274597282],
        [74.48902893066412, 14.085693359375],
        [74.48874664306658, 14.085691452026424],
        [74.48874664306658, 14.086249351501465],
        [74.48847198486357, 14.086249351501465],
        [74.48847198486357, 14.086810111999569],
        [74.48819732666016, 14.08680534362793],
        [74.48819732666016, 14.088472366333065],
        [74.48847198486357, 14.088472366333065],
        [74.48847198486357, 14.090694427490348],
        [74.48819732666016, 14.090690612792969],
        [74.48819732666016, 14.091810226440487],
        [74.48792266845732, 14.091810226440487],
        [74.48792266845732, 14.092639923095703],
        [74.48735809326183, 14.092639923095703],
        [74.48735809326183, 14.09292030334484],
        [74.48707580566412, 14.09292030334484],
        [74.48707580566412, 14.093200683593807],
        [74.48652648925804, 14.093194007873535],
        [74.48652648925804, 14.093471527099666],
        [74.48458099365234, 14.093471527099666],
        [74.48458099365234, 14.092915534973201],
        [74.48430633544933, 14.09292030334484],
        [74.48430633544933, 14.092082023620605],
        [74.48374938964838, 14.092083930969181],
        [74.48374938964838, 14.092362403869572],
        [74.48346710205084, 14.09235954284668],
        [74.48346710205084, 14.092639923095703],
        [74.48291778564459, 14.092639923095703],
        [74.48291778564459, 14.09292030334484],
        [74.48264312744158, 14.09292030334484],
        [74.48264312744158, 14.093200683593807],
        [74.48152923583984, 14.093200683593807],
        [74.48152923583984, 14.09292030334484],
        [74.48069763183588, 14.09292030334484],
        [74.48069763183588, 14.093200683593807],
        [74.48042297363304, 14.093200683593807],
        [74.48042297363304, 14.09375],
        [74.48069763183588, 14.09375],
        [74.48069763183588, 14.09431076049816],
        [74.48097229003912, 14.09431076049816],
        [74.48097229003912, 14.09486007690441],
        [74.48264312744158, 14.09486007690441],
        [74.48264312744158, 14.095140457153377],
        [74.48291778564459, 14.095140457153377],
        [74.48291778564459, 14.09542274475109],
        [74.48319244384783, 14.09542274475109],
        [74.48319244384783, 14.095140457153377],
        [74.48374938964838, 14.095140457153377],
        [74.48374938964838, 14.09486007690441],
        [74.48513793945312, 14.09486007690441],
        [74.48513793945312, 14.094579696655217],
        [74.48596954345709, 14.094579696655217],
        [74.48596954345709, 14.095140457153377],
        [74.48625183105486, 14.095137596130485],
        [74.48625183105486, 14.09597206115734],
        [74.48652648925804, 14.095970153808537],
        [74.48652648925804, 14.096530914306697],
        [74.48680877685541, 14.096530914306697],
        [74.48680877685541, 14.097359657287598],
        [74.48708343505865, 14.097361564636174],
        [74.48708343505865, 14.099584579467773],
        [74.48735809326183, 14.099581718444881],
        [74.48735809326183, 14.102641105651912],
        [74.48764038085943, 14.102641105651912],
        [74.48764038085943, 14.1054306030274],
        [74.48792266845732, 14.1054306030274],
        [74.48792266845732, 14.110420227050895],
        [74.48764038085943, 14.110420227050895],
        [74.48764038085943, 14.111809730529728],
        [74.48680877685541, 14.111809730529728],
        [74.48680877685541, 14.110691070556754],
        [74.48652648925804, 14.110694885253906],
        [74.48652648925804, 14.109861373901367],
        [74.48625183105486, 14.109861373901367],
        [74.48625183105486, 14.110420227050895],
        [74.48596954345709, 14.110417366027832],
        [74.48596954345709, 14.110973358154297],
        [74.48568725585955, 14.110971450805721],
        [74.48568725585955, 14.111809730529728],
        [74.48542022705107, 14.111809730529728],
        [74.48542022705107, 14.112360954284725],
        [74.48513793945312, 14.112360954284725],
        [74.48513793945312, 14.113471031189079],
        [74.48486328125028, 14.113471031189079],
        [74.48486328125028, 14.115141868591365],
        [74.48458099365234, 14.115141868591365],
        [74.48458099365234, 14.11653041839594],
        [74.48430633544933, 14.116527557373047],
        [74.48430633544933, 14.118193626403865],
        [74.48401641845709, 14.118188858032227],
        [74.48402404785162, 14.119859695434513],
        [74.48374938964838, 14.119859695434513],
        [74.48374938964838, 14.12125015258789],
        [74.48346710205084, 14.12125015258789],
        [74.48346710205084, 14.122920989990348],
        [74.48319244384783, 14.122920989990348],
        [74.48319244384783, 14.124311447143668],
        [74.48291778564459, 14.124311447143668],
        [74.48291778564459, 14.125971794128361],
        [74.48264312744158, 14.125971794128361],
        [74.48264312744158, 14.127640724182072],
        [74.48236083984403, 14.127640724182072],
        [74.48236083984403, 14.129031181335392],
        [74.48208618164062, 14.129026412963924],
        [74.48208618164062, 14.130695343017635],
        [74.48179626464872, 14.13068962097168],
        [74.48179626464872, 14.131809234619197],
        [74.48152923583984, 14.131809234619197],
        [74.48152923583984, 14.13264083862316],
        [74.48124694824247, 14.132638931274357],
        [74.48124694824247, 14.133473396301213],
        [74.48097229003912, 14.133470535278377],
        [74.48097229003912, 14.134311676025447],
        [74.48069763183588, 14.134311676025447],
        [74.48069763183588, 14.135141372680664],
        [74.48042297363304, 14.135141372680664],
        [74.48041534423834, 14.136248588562125],
        [74.48014068603533, 14.136251449585018],
        [74.48014068603533, 14.13695144653326],
        [74.48014068603533, 14.13708305358898],
        [74.47985839843773, 14.137081146240234],
        [74.47985839843773, 14.137920379638729],
        [74.47957611084001, 14.137920379638729],
        [74.47957611084001, 14.138751983642692],
        [74.47930908203153, 14.138751983642692],
        [74.47930908203153, 14.139311790466365],
        [74.47902679443376, 14.139305114746094],
        [74.47902679443376, 14.139861106872559],
        [74.47820281982433, 14.139859199523983],
        [74.47820281982433, 14.14013957977295],
        [74.4773559570313, 14.14013957977295],
        [74.4773559570313, 14.140419960021916],
        [74.47652435302751, 14.14041805267334],
        [74.47652435302751, 14.140695571899414],
        [74.47680664062523, 14.140695571899414],
        [74.47680664062523, 14.140971183776912],
        [74.47708892822283, 14.140969276428336],
        [74.47708892822283, 14.141540527343693],
        [74.47736358642584, 14.141528129577694],
        [74.47736358642584, 14.142084121704158],
        [74.47763824462902, 14.142081260681266],
        [74.47763824462902, 14.1426420211792],
        [74.47791290283209, 14.142640113830623],
        [74.47791290283209, 14.143194198608512],
        [74.47819519042997, 14.143194198608512],
        [74.47819519042997, 14.143750190734977],
        [74.47846984863281, 14.143750190734977],
        [74.47846984863281, 14.147359848022575],
        [74.47820281982433, 14.147351264953727],
        [74.47820281982433, 14.149029731750545],
        [74.47791290283209, 14.14902782440197],
        [74.47791290283209, 14.149862289428825],
        [74.47846984863281, 14.149862289428825],
        [74.47846984863281, 14.149581909179688],
        [74.47902679443376, 14.149581909179688],
        [74.47902679443376, 14.150142669677791],
        [74.47875213623075, 14.150137901306152],
        [74.47875213623075, 14.150693893432617],
        [74.47846984863281, 14.150691986084041],
        [74.47846984863281, 14.151252746582145],
        [74.47820281982433, 14.151252746582145],
        [74.47820281982433, 14.151810646057186],
        [74.47791290283209, 14.151810646057186],
        [74.47791290283209, 14.152359962463436],
        [74.47763824462902, 14.152359962463436],
        [74.47763824462902, 14.152920722961369],
        [74.4773559570313, 14.152920722961369],
        [74.4773559570313, 14.153482437133789],
        [74.47708892822283, 14.153482437133789],
        [74.47708892822283, 14.154030799865723],
        [74.47680664062523, 14.154030799865723],
        [74.47680664062523, 14.155140876770076],
        [74.47651672363298, 14.155140876770076],
        [74.47651672363298, 14.156539916992244],
        [74.47624969482428, 14.156539916992244],
        [74.47624969482428, 14.157920837402457],
        [74.47598266601562, 14.157920837402457],
        [74.47598266601562, 14.159309387207031],
        [74.47569274902372, 14.159309387207031],
        [74.47569274902372, 14.160419464111385],
        [74.47541809082031, 14.160419464111385],
        [74.47541809082031, 14.161811828613281],
        [74.47513580322294, 14.161805152893066],
        [74.47513580322294, 14.16319465637207],
        [74.47486114501959, 14.16319465637207],
        [74.47486114501959, 14.164583206176815],
        [74.47458648681652, 14.164581298828239],
        [74.47458648681652, 14.165419578552246],
        [74.4743041992188, 14.165416717529354],
        [74.4743041992188, 14.165970802307243],
        [74.47402954101557, 14.165973663330135],
        [74.47402954101557, 14.167082786560002],
        [74.47429656982428, 14.167080879211426],
        [74.47429656982428, 14.168751716613713],
        [74.47402954101557, 14.168751716613713],
        [74.47402954101557, 14.16930961608898],
        [74.4737472534182, 14.16930961608898],
        [74.4737472534182, 14.169861793518066],
        [74.47347259521501, 14.169861793518066],
        [74.47347259521501, 14.17097187042242],
        [74.47319793701178, 14.17097187042242],
        [74.47319793701178, 14.172083854675407],
        [74.47291564941423, 14.172083854675407],
        [74.47291564941423, 14.173193931579533],
        [74.47264099121122, 14.173192024230957],
        [74.47264099121122, 14.174310684204158],
        [74.47235870361328, 14.174310684204158],
        [74.47235870361328, 14.175422668457088],
        [74.47207641601591, 14.175422668457088],
        [74.47207641601591, 14.176532745361442],
        [74.47180938720709, 14.176527976989803],
        [74.4718017578125, 14.177362442016658],
        [74.47152709960949, 14.177359580993766],
        [74.47152709960949, 14.177921295166016],
        [74.4712524414063, 14.17791557312023],
        [74.4712524414063, 14.178471565246525],
        [74.47097015380854, 14.178471565246525],
        [74.47097015380854, 14.180693626403809],
        [74.4706954956057, 14.180693626403809],
        [74.4706954956057, 14.183195114135799],
        [74.47097015380854, 14.183195114135799],
        [74.47097015380854, 14.183472633361873],
        [74.4706954956057, 14.183472633361873],
        [74.47070312500023, 14.183751106262264],
        [74.47041320800798, 14.183751106262264],
        [74.47041320800798, 14.184310913085938],
        [74.47013854980474, 14.184310913085938],
        [74.47013854980474, 14.185420989990291],
        [74.4698562622072, 14.185420989990291],
        [74.4698562622072, 14.186250686645508],
        [74.46958160400419, 14.186248779296875],
        [74.46958160400419, 14.18708324432373],
        [74.46930694580078, 14.18708324432373],
        [74.46930694580078, 14.188195228576717],
        [74.46901702880888, 14.188191413879508],
        [74.46901702880888, 14.188751220703182],
        [74.46875, 14.188751220703182],
        [74.46875, 14.189031600952148],
        [74.46847534179699, 14.18902683258068],
        [74.46847534179699, 14.189582824707145],
        [74.46819305419928, 14.189580917358398],
        [74.46819305419928, 14.190141677856502],
        [74.46791839599604, 14.19013881683361],
        [74.46791839599604, 14.190973281860465],
        [74.4676361083985, 14.190971374511719],
        [74.4676361083985, 14.191530227661246],
        [74.46736145019548, 14.191527366638184],
        [74.46736145019548, 14.192083358764648],
        [74.46708679199224, 14.192081451416072],
        [74.46708679199224, 14.19291973114008],
        [74.4668045043947, 14.192916870117188],
        [74.4668045043947, 14.193470954895076],
        [74.46652984619169, 14.193470954895076],
        [74.46652984619169, 14.196251869201717],
        [74.46679687500017, 14.196251869201717],
        [74.46679687500017, 14.196809768676815],
        [74.46652984619169, 14.196809768676815],
        [74.46652984619169, 14.1973619461059],
        [74.46624755859392, 14.1973619461059],
        [74.46624755859392, 14.19764041900629],
        [74.46597290039091, 14.197637557983398],
        [74.46597290039091, 14.198193550109863],
        [74.4656982421875, 14.198193550109863],
        [74.4656982421875, 14.198472023010254],
        [74.46541595459013, 14.198459625244254],
        [74.46540069580107, 14.199029922485352],
        [74.4651489257813, 14.199029922485352],
        [74.4651489257813, 14.199310302734489],
        [74.46485900878918, 14.19930553436285],
        [74.46485900878918, 14.19958400726324],
        [74.464584350586, 14.19958400726324],
        [74.464584350586, 14.199861526489315],
        [74.46402740478538, 14.199859619140739],
        [74.46402740478538, 14.200139999389705],
        [74.4637527465822, 14.200139999389705],
        [74.4637527465822, 14.200420379638672],
        [74.46347045898443, 14.200415611267204],
        [74.46347045898443, 14.200694084167594],
        [74.46319580078142, 14.200694084167594],
        [74.46319580078142, 14.200971603393668],
        [74.46013641357439, 14.200971603393668],
        [74.46013641357439, 14.2008314132691],
        [74.46013641357439, 14.200694084167594],
        [74.45986175537138, 14.200694084167594],
        [74.45986175537138, 14.200139999389705],
        [74.45958709716797, 14.200139999389705],
        [74.45958709716797, 14.199859619140739],
        [74.4593048095706, 14.199861526489315],
        [74.4593048095706, 14.19958400726324],
        [74.45903015136724, 14.19958400726324],
        [74.45903015136724, 14.19930553436285],
        [74.45874786376964, 14.199310302734489],
        [74.45874786376964, 14.199029922485352],
        [74.45847320556646, 14.199029922485352],
        [74.45847320556646, 14.198749542236385],
        [74.4581909179687, 14.198749542236385],
        [74.4581909179687, 14.198459625244254],
        [74.45791625976585, 14.198459625244254],
        [74.45791625976585, 14.198749542236385],
        [74.45764160156267, 14.198749542236385],
        [74.45764160156267, 14.199029922485352],
        [74.45707702636736, 14.199029922485352],
        [74.45707702636736, 14.199310302734489],
        [74.45680236816435, 14.19930553436285],
        [74.45680236816435, 14.19958400726324],
        [74.4562530517581, 14.19958400726324],
        [74.4562530517581, 14.199861526489315],
        [74.45596313476562, 14.199859619140739],
        [74.45596313476562, 14.200139999389705],
        [74.45485687255865, 14.200139999389705],
        [74.45485687255865, 14.199859619140739],
        [74.45458221435564, 14.199861526489315],
        [74.45458221435564, 14.19958400726324],
        [74.4543075561524, 14.19958400726324],
        [74.4543075561524, 14.19930553436285],
        [74.45402526855486, 14.19930553436285],
        [74.45402526855486, 14.199861526489315],
        [74.45375061035185, 14.199859619140739],
        [74.45375061035185, 14.200420379638672],
        [74.45347595214844, 14.200415611267204],
        [74.45347595214844, 14.200971603393668],
        [74.45319366455107, 14.200971603393668],
        [74.45319366455107, 14.201527595520133],
        [74.45347595214844, 14.201520919799862],
        [74.45347595214844, 14.201810836791992],
        [74.45375061035185, 14.201806068420524],
        [74.45375061035185, 14.202362060546989],
        [74.45403289794939, 14.202360153198242],
        [74.45403289794939, 14.202639579773063],
        [74.4543075561524, 14.202639579773063],
        [74.4543075561524, 14.202920913696346],
        [74.45402526855486, 14.202916145324707],
        [74.45402526855486, 14.203193664550781],
        [74.45375061035185, 14.203193664550781],
        [74.45375061035185, 14.203472137451172],
        [74.45347595214844, 14.203470230102596],
        [74.45347595214844, 14.203749656677246],
        [74.45318603515653, 14.203749656677246],
        [74.45318603515653, 14.2040309906007],
        [74.45291900634766, 14.2040309906007],
        [74.45291900634766, 14.204311370849666],
        [74.45263671875028, 14.204311370849666],
        [74.45263671875028, 14.2048597335816],
        [74.45236206054693, 14.2048597335816],
        [74.45236206054693, 14.20542144775402],
        [74.45263671875028, 14.20542144775402],
        [74.45263671875028, 14.206530570983887],
        [74.45291900634766, 14.206527709960994],
        [74.45291900634766, 14.207360267639274],
        [74.45236206054693, 14.207360267639274],
        [74.45236206054693, 14.207650184631404],
        [74.45153045654314, 14.207650184631404],
        [74.45153045654314, 14.207921028137207],
        [74.45097351074236, 14.207916259765568],
        [74.45097351074236, 14.20819473266613],
        [74.45069122314459, 14.20818996429449],
        [74.45069122314459, 14.208750724792424],
        [74.45041656494158, 14.208750724792424],
        [74.45041656494158, 14.209860801696777],
        [74.45069122314459, 14.209860801696777],
        [74.45069122314459, 14.210141181945744],
        [74.45097351074236, 14.210139274597168],
        [74.45097351074236, 14.210695266723633],
        [74.45069122314459, 14.210695266723633],
        [74.45069122314459, 14.210972785949707],
        [74.45041656494158, 14.210970878601131],
        [74.45041656494158, 14.211530685424805],
        [74.4501495361331, 14.211530685424805],
        [74.4501495361331, 14.211812019348258],
        [74.44902801513683, 14.211812019348258],
        [74.44902801513683, 14.212919235229549],
        [74.4493026733399, 14.212917327880916],
        [74.4493026733399, 14.213473320007438],
        [74.44958496093778, 14.213473320007438],
        [74.44958496093778, 14.21569538116455],
        [74.4493026733399, 14.215690612793082],
        [74.4493026733399, 14.216531753540153],
        [74.44847106933588, 14.216526985168514],
        [74.44847106933588, 14.21736145019537],
        [74.44875335693365, 14.21736145019537],
        [74.44875335693365, 14.218193054199332],
        [74.44819641113304, 14.218193054199332],
        [74.44819641113304, 14.218473434448299],
        [74.4479064941408, 14.218470573425407],
        [74.4479064941408, 14.21875190734869],
        [74.44763946533209, 14.21875190734869],
        [74.44763946533209, 14.21903038024908],
        [74.44735717773455, 14.21903038024908],
        [74.44735717773455, 14.21930980682373],
        [74.44708251953153, 14.21930980682373],
        [74.44708251953153, 14.219590187072697],
        [74.4459686279298, 14.219590187072697],
        [74.4459686279298, 14.219861030578556],
        [74.44541931152338, 14.219861030578556],
        [74.44541931152338, 14.22013854980463],
        [74.44458007812506, 14.22013854980463],
        [74.44458007812506, 14.220421791076717],
        [74.44403076171903, 14.220417976379508],
        [74.44403076171903, 14.22236156463623],
        [74.4437484741211, 14.22236156463623],
        [74.4437484741211, 14.222640037536621],
        [74.44346618652372, 14.222631454467773],
        [74.44346618652372, 14.222920417785588],
        [74.44319152832031, 14.22291469573986],
        [74.44319152832031, 14.22319412231451],
        [74.4429168701173, 14.22319412231451],
        [74.4429168701173, 14.223471641540584],
        [74.44264221191412, 14.223471641540584],
        [74.44264221191412, 14.22375202178955],
        [74.4418029785158, 14.223750114440975],
        [74.4418029785158, 14.224862098693904],
        [74.44152832031256, 14.224860191345329],
        [74.44152832031256, 14.226249694824219],
        [74.44209289550804, 14.226249694824219],
        [74.44209289550804, 14.226530075073356],
        [74.44235992431635, 14.226530075073356],
        [74.44235992431635, 14.226811408996639],
        [74.44264221191412, 14.226805686950684],
        [74.44264221191412, 14.227084159851074],
        [74.4429168701173, 14.227079391479549],
        [74.4429168701173, 14.22791957855219],
        [74.44264221191412, 14.22791957855219],
        [74.44264221191412, 14.229029655456543],
        [74.44235992431635, 14.229027748107967],
        [74.44235992431635, 14.229862213134822],
        [74.44208526611351, 14.229862213134822],
        [74.44208526611351, 14.230693817138786],
        [74.4418029785158, 14.230691909790039],
        [74.4418029785158, 14.231531143188533],
        [74.44152832031256, 14.231531143188533],
        [74.44152832031256, 14.23236179351801],
        [74.44125366210955, 14.232359886169434],
        [74.44125366210955, 14.233194351196289],
        [74.44097900390653, 14.23318958282482],
        [74.44097900390653, 14.23403072357172],
        [74.4406967163086, 14.234028816223145],
        [74.4406967163086, 14.234860420227108],
        [74.44041442871122, 14.234860420227108],
        [74.44041442871122, 14.235694885253963],
        [74.44013977050781, 14.235690116882324],
        [74.44013977050781, 14.236531257629395],
        [74.43985748291044, 14.236531257629395],
        [74.43985748291044, 14.237360954284611],
        [74.43958282470709, 14.237360954284611],
        [74.43958282470709, 14.238195419311467],
        [74.43930816650385, 14.238189697265682],
        [74.43930816650385, 14.239030838012809],
        [74.4390258789063, 14.239028930664062],
        [74.4390258789063, 14.239860534668026],
        [74.4387512207033, 14.239860534668026],
        [74.4387512207033, 14.240701675415096],
        [74.4384689331057, 14.240701675415096],
        [74.4384689331057, 14.241250991821346],
        [74.43819427490251, 14.241250991821346],
        [74.43819427490251, 14.242082595825309],
        [74.4379196166995, 14.242080688476562],
        [74.4379196166995, 14.242921829223633],
        [74.43763732910173, 14.242917060852164],
        [74.43763732910173, 14.243471145629883],
        [74.43736267089872, 14.243471145629883],
        [74.43736267089872, 14.244310379028377],
        [74.43708038330078, 14.244310379028377],
        [74.43708038330078, 14.24514198303234],
        [74.43680572509794, 14.245139122009277],
        [74.43680572509794, 14.245693206787166],
        [74.43653106689459, 14.24569129943859],
        [74.43653106689459, 14.246529579162598],
        [74.43624877929699, 14.246529579162598],
        [74.43624877929699, 14.247639656066951],
        [74.43596649169928, 14.247639656066951],
        [74.43596649169928, 14.248751640319881],
        [74.43569183349604, 14.248748779296818],
        [74.43569183349604, 14.24958419799816],
        [74.4354171752932, 14.24958419799816],
        [74.4354171752932, 14.250694274902344],
        [74.43514251709001, 14.250691413879451],
        [74.43514251709001, 14.251529693603516],
        [74.43485260009771, 14.251529693603516],
        [74.43485260009771, 14.25263977050787],
        [74.43458557128923, 14.252637863159237],
        [74.4345932006836, 14.253749847412223],
        [74.43430328369169, 14.253749847412223],
        [74.43430328369169, 14.254584312439079],
        [74.43402862548828, 14.254579544067383],
        [74.43402862548828, 14.25542068481451],
        [74.43374633789091, 14.255415916442871],
        [74.43374633789091, 14.255971908569336],
        [74.43347167968756, 14.255971908569336],
        [74.43347167968756, 14.25681114196783],
        [74.43319702148449, 14.25681114196783],
        [74.43319702148449, 14.257642745971793],
        [74.43291473388678, 14.257637977600154],
        [74.43290710449224, 14.25819015502924],
        [74.43264007568354, 14.258200645446891],
        [74.43264007568354, 14.259031295776367],
        [74.43235778808616, 14.259031295776367],
        [74.43235778808616, 14.260141372680721],
        [74.43208312988298, 14.260141372680721],
        [74.43208312988298, 14.261530876159725],
        [74.43180847167974, 14.261528968811149],
        [74.43180847167974, 14.26208209991455],
        [74.4315261840822, 14.262080192565975],
        [74.4315261840822, 14.262640953063908],
        [74.43125152587919, 14.262639045715332],
        [74.43125152587919, 14.263195037841797],
        [74.43096923828125, 14.263190269470329],
        [74.43096923828125, 14.263751029968262],
        [74.43069458007841, 14.263751029968262],
        [74.43069458007841, 14.264582633972225],
        [74.43041992187506, 14.264579772949162],
        [74.43041992187506, 14.265140533447322],
        [74.43013763427746, 14.265138626098746],
        [74.43013763427746, 14.265694618225211],
        [74.42986297607428, 14.265689849853516],
        [74.42986297607428, 14.266250610351676],
        [74.4295806884765, 14.266248703002873],
        [74.4295806884765, 14.267083168029728],
        [74.42930603027366, 14.267083168029728],
        [74.42930603027366, 14.268195152282715],
        [74.42903900146501, 14.268191337585506],
        [74.42903900146501, 14.269031524658317],
        [74.42874908447271, 14.269026756286678],
        [74.42874908447271, 14.269861221313533],
        [74.4284744262697, 14.269861221313533],
        [74.4284744262697, 14.270973205566463],
        [74.42819213867216, 14.270971298217887],
        [74.42819213867216, 14.271809577941895],
        [74.42791748046875, 14.271809577941895],
        [74.42791748046875, 14.272639274597282],
        [74.42763519287138, 14.272639274597282],
        [74.42763519287138, 14.273749351501465],
        [74.42736053466797, 14.273749351501465],
        [74.42736053466797, 14.274582862854004],
        [74.42708587646496, 14.274582862854004],
        [74.42708587646496, 14.27569580078125],
        [74.42679595947271, 14.275691032409782],
        [74.42679595947271, 14.276530265808105],
        [74.426528930664, 14.276530265808105],
        [74.426528930664, 14.277361869812069],
        [74.42624664306646, 14.277361869812069],
        [74.42624664306646, 14.278471946716252],
        [74.42597961425798, 14.278471946716252],
        [74.42597961425798, 14.279310226440487],
        [74.42624664306646, 14.279310226440487],
        [74.42624664306646, 14.280139923095703],
        [74.426528930664, 14.280139923095703],
        [74.426528930664, 14.28125],
        [74.42624664306646, 14.28125],
        [74.42624664306646, 14.28236007690441],
        [74.42597961425798, 14.28236007690441],
        [74.42597961425798, 14.282640457153377],
        [74.42569732666021, 14.282640457153377],
        [74.42569732666021, 14.282930374145451],
        [74.42541503906267, 14.282916069030875],
        [74.42541503906267, 14.28347206115734],
        [74.42514038085966, 14.283470153808594],
        [74.42514038085966, 14.283749580383414],
        [74.42485809326189, 14.283749580383414],
        [74.42485809326189, 14.284861564636174],
        [74.42457580566435, 14.284859657287598],
        [74.42457580566435, 14.286250114440918],
        [74.42430877685547, 14.286250114440918],
        [74.42430877685547, 14.287360191345272],
        [74.4240264892581, 14.287360191345272],
        [74.4240264892581, 14.288473129272518],
        [74.42375183105474, 14.288473129272518],
        [74.42375183105474, 14.289582252502385],
        [74.42346954345714, 14.289582252502385],
        [74.42346954345714, 14.29069519042963],
        [74.42375183105474, 14.290690422058162],
        [74.42375183105474, 14.29181098937994],
        [74.4240264892581, 14.29181098937994],
        [74.4240264892581, 14.292640686035156],
        [74.42430877685547, 14.29263877868658],
        [74.42430877685547, 14.293473243713436],
        [74.42458343505888, 14.293473243713436],
        [74.42458343505888, 14.29458332061779],
        [74.42485809326189, 14.294581413268986],
        [74.42485809326189, 14.295421600341797],
        [74.42457580566435, 14.295421600341797],
        [74.42457580566435, 14.29653167724615],
        [74.42430877685547, 14.296526908874512],
        [74.42430877685547, 14.297082901000977],
        [74.4240264892581, 14.2970809936524],
        [74.4240264892581, 14.297641754150504],
        [74.42375183105474, 14.297638893127441],
        [74.42375183105474, 14.297830581665039],
        [74.42375183105474, 14.298194885253906],
        [74.42362976074247, 14.298194885253906],
        [74.42346954345714, 14.298194885253906],
        [74.42346954345714, 14.298681259155273],
        [74.4229125976562, 14.298750877380371],
        [74.4229125976562, 14.299029350280762],
        [74.4220809936524, 14.299029350280762],
        [74.4220809936524, 14.299309730529728],
        [74.42124938964844, 14.29930496215826],
        [74.42124938964844, 14.29958343505865],
        [74.42096710205107, 14.299580574035758],
        [74.42096710205107, 14.300139427185115],
        [74.42069244384771, 14.300139427185115],
        [74.42069244384771, 14.300696372985897],
        [74.42041778564464, 14.300690650939941],
        [74.42041778564464, 14.301248550414982],
        [74.42014312744146, 14.301248550414982],
        [74.42014312744146, 14.301531791687069],
        [74.4198608398437, 14.301527023315373],
        [74.4198608398437, 14.302083969116325],
        [74.41958618164085, 14.302081108093262],
        [74.41958618164085, 14.302641868591422],
        [74.41930389404314, 14.302639961242619],
        [74.41930389404314, 14.303193092346191],
        [74.4190292358399, 14.303191184997615],
        [74.4190292358399, 14.303751945495549],
        [74.41874694824236, 14.303751945495549],
        [74.41874694824236, 14.304309844970817],
        [74.41847229003935, 14.304306030273438],
        [74.41847229003935, 14.304583549499569],
        [74.41819763183594, 14.304580688476676],
        [74.41819763183594, 14.305139541626033],
        [74.41791534423857, 14.3051376342774],
        [74.41791534423857, 14.305693626403922],
        [74.41764068603521, 14.305688858032227],
        [74.41764068603521, 14.306249618530387],
        [74.41735839843761, 14.306249618530387],
        [74.41735839843761, 14.30681037902832],
        [74.4170761108399, 14.30681037902832],
        [74.41708374023443, 14.30735969543457],
        [74.4168090820312, 14.30735969543457],
        [74.4168090820312, 14.307920455932674],
        [74.41652679443382, 14.307915687561035],
        [74.41652679443382, 14.3084716796875],
        [74.41625213623064, 14.308469772338924],
        [74.41625213623064, 14.309310913085994],
        [74.41652679443382, 14.309306144714355],
        [74.41652679443382, 14.30958366394043],
        [74.41625213623064, 14.30958366394043],
        [74.41625213623064, 14.310693740844783],
        [74.41596984863287, 14.310691833496207],
        [74.41596984863287, 14.311249732971248],
        [74.41570281982439, 14.311249732971248],
        [74.41570281982439, 14.311811447143668],
        [74.41541290283232, 14.311811447143668],
        [74.41541290283232, 14.312361717224178],
        [74.4151382446289, 14.312361717224178],
        [74.4151382446289, 14.312640190124569],
        [74.41486358642607, 14.312638282775993],
        [74.41486358642607, 14.313194274902457],
        [74.41458129882812, 14.313190460205135],
        [74.41458129882812, 14.313750267028752],
        [74.41430664062511, 14.313750267028752],
        [74.41430664062511, 14.314311027526912],
        [74.4140243530274, 14.31430435180664],
        [74.4140243530274, 14.314860343933105],
        [74.41374969482416, 14.314860343933105],
        [74.41374969482416, 14.315694808959961],
        [74.41347503662132, 14.315694808959961],
        [74.41348266601585, 14.31736087799078],
        [74.41319274902361, 14.31736087799078],
        [74.41319274902361, 14.322083473205623],
        [74.41348266601585, 14.32208061218273],
        [74.41348266601585, 14.325141906738338],
        [74.41319274902361, 14.325139045715446],
        [74.41319274902361, 14.327361106872559],
        [74.41291809082037, 14.327361106872559],
        [74.41291809082037, 14.329584121704158],
        [74.41263580322283, 14.329581260681096],
        [74.41263580322283, 14.33098125457775],
        [74.41236114501982, 14.33098125457775],
        [74.41236114501982, 14.33180999755865],
        [74.4120864868164, 14.331806182861271],
        [74.4120864868164, 14.332362174987736],
        [74.41180419921903, 14.332362174987736],
        [74.41180419921903, 14.333193778991756],
        [74.41152954101562, 14.333193778991756],
        [74.41152954101562, 14.334028244018612],
        [74.41124725341825, 14.334028244018612],
        [74.41124725341825, 14.334859848022575],
        [74.4109725952149, 14.334859848022575],
        [74.4109725952149, 14.335419654846191],
        [74.41069793701166, 14.335419654846191],
        [74.41069793701166, 14.336250305175895],
        [74.41041564941412, 14.336250305175895],
        [74.41041564941412, 14.337081909179688],
        [74.41014099121111, 14.337083816528434],
        [74.41014099121111, 14.338193893432617],
        [74.40985870361351, 14.338191986084041],
        [74.40985870361351, 14.339310646057243],
        [74.4095764160158, 14.339310646057243],
        [74.4095764160158, 14.340151786804313],
        [74.40930938720732, 14.340138435363826],
        [74.40930175781256, 14.341070175170898],
        [74.40930175781256, 14.341250419616756],
        [74.40902709960955, 14.341250419616756],
        [74.40902709960955, 14.34235954284668],
        [74.40875244140653, 14.34235954284668],
        [74.40875244140653, 14.343473434448356],
        [74.4084701538086, 14.343469619751033],
        [74.4084701538086, 14.344861030578613],
        [74.40820312500011, 14.344861030578613],
        [74.40820312500011, 14.346809387207031],
        [74.40791320800787, 14.346809387207031],
        [74.40791320800787, 14.348751068115348],
        [74.4076385498048, 14.348751068115348],
        [74.4076385498048, 14.35263919830328],
        [74.40735626220709, 14.35263919830328],
        [74.40735626220709, 14.353749275207633],
        [74.40708160400385, 14.353749275207633],
        [74.40708160400385, 14.354861259460392],
        [74.40680694580101, 14.354859352111816],
        [74.40680694580101, 14.355422019958553],
        [74.4065246582033, 14.355415344238281],
        [74.4065246582033, 14.355695724487248],
        [74.40625, 14.355695724487248],
        [74.40625, 14.355971336364746],
        [74.4059829711914, 14.355971336364746],
        [74.4059829711914, 14.356251716613713],
        [74.4054183959961, 14.356251716613713],
        [74.4054183959961, 14.356530189514103],
        [74.40513610839872, 14.356530189514103],
        [74.40513610839872, 14.35680961608898],
        [74.40486145019537, 14.356805801391602],
        [74.40486145019537, 14.357084274291992],
        [74.4045867919923, 14.357084274291992],
        [74.4045867919923, 14.358194351196346],
        [74.40513610839872, 14.358194351196346],
        [74.40513610839872, 14.35847187042242],
        [74.4054183959961, 14.35847187042242],
        [74.4054183959961, 14.35875034332281],
        [74.4056930541995, 14.35875034332281],
        [74.4056930541995, 14.359029769897518],
        [74.4059829711914, 14.359029769897518],
        [74.4059829711914, 14.359310150146484],
        [74.40625, 14.359306335449276],
        [74.40625, 14.36069393157959],
        [74.4059829711914, 14.360692024230957],
        [74.4059829711914, 14.361249923706055],
        [74.4054183959961, 14.361249923706055],
        [74.4054183959961, 14.361530303955192],
        [74.40513610839872, 14.361528396606445],
        [74.40513610839872, 14.36208438873291],
        [74.4048690795899, 14.362079620361442],
        [74.4048690795899, 14.362640380859375],
        [74.4045867919923, 14.362640380859375],
        [74.4045867919923, 14.363750457763729],
        [74.4048690795899, 14.363750457763729],
        [74.4048690795899, 14.365421295166016],
        [74.4056930541995, 14.365421295166016],
        [74.4056930541995, 14.365139961242733],
        [74.40625, 14.365139961242733],
        [74.40625, 14.367091178894043],
        [74.4056930541995, 14.367091178894043],
        [74.4056930541995, 14.366810798645076],
        [74.4054183959961, 14.366810798645076],
        [74.4054183959961, 14.36653137207037],
        [74.40513610839872, 14.36653137207037],
        [74.40513610839872, 14.366250038146916],
        [74.4048690795899, 14.366250038146916],
        [74.4048690795899, 14.36596965789795],
        [74.4045867919923, 14.36596965789795],
        [74.4045867919923, 14.366250038146916],
        [74.40429687500006, 14.366250038146916],
        [74.40429687500006, 14.36653137207037],
        [74.40402984619135, 14.36653137207037],
        [74.40402984619135, 14.366810798645076],
        [74.4034729003908, 14.366810798645076],
        [74.4034729003908, 14.367091178894043],
        [74.40319824218756, 14.367091178894043],
        [74.40319824218756, 14.36736011505127],
        [74.40291595459001, 14.36736011505127],
        [74.40291595459001, 14.36792087554943],
        [74.402641296387, 14.367916107177734],
        [74.402641296387, 14.3684720993042],
        [74.40235900878906, 14.368470191955623],
        [74.40235900878906, 14.36931133270275],
        [74.402641296387, 14.369306564331055],
        [74.402641296387, 14.370139122009334],
        [74.40291595459001, 14.370139122009334],
        [74.40291595459001, 14.37097263336193],
        [74.40319824218756, 14.370969772338867],
        [74.40319824218756, 14.371530532836971],
        [74.40513610839872, 14.371528625488395],
        [74.40513610839872, 14.372082710266056],
        [74.4054183959961, 14.372079849243221],
        [74.4054183959961, 14.372920989990291],
        [74.40513610839872, 14.372920989990291],
        [74.40513610839872, 14.374031066894645],
        [74.40486145019537, 14.374029159545898],
        [74.4048690795899, 14.374860763549862],
        [74.4045867919923, 14.374860763549862],
        [74.4045867919923, 14.375970840454215],
        [74.40429687500006, 14.375970840454215],
        [74.40429687500006, 14.376811981201115],
        [74.40402984619135, 14.376811981201115],
        [74.40402984619135, 14.377640724182186],
        [74.40374755859398, 14.37763881683361],
        [74.40374755859398, 14.378473281860465],
        [74.4034729003908, 14.378473281860465],
        [74.4034729003908, 14.379583358764648],
        [74.40319824218756, 14.379581451416072],
        [74.40319824218756, 14.38041973114008],
        [74.40291595459001, 14.38041973114008],
        [74.40291595459001, 14.381249427795467],
        [74.402641296387, 14.381249427795467],
        [74.402641296387, 14.382362365722713],
        [74.40235900878906, 14.382362365722713],
        [74.40235900878906, 14.38347053527832],
        [74.40208435058622, 14.38347053527832],
        [74.40208435058622, 14.384584426879826],
        [74.40180206298828, 14.384584426879826],
        [74.40180206298828, 14.385972023010254],
        [74.40152740478527, 14.385972023010254],
        [74.40152740478527, 14.38708400726324],
        [74.40125274658209, 14.38708400726324],
        [74.40125274658209, 14.388471603393668],
        [74.40097045898432, 14.388471603393668],
        [74.40097045898432, 14.389583587646598],
        [74.40069580078148, 14.389583587646598],
        [74.40069580078148, 14.390693664550781],
        [74.400405883789, 14.390688896179313],
        [74.400405883789, 14.391811370849666],
        [74.40013885498053, 14.391811370849666],
        [74.40013885498053, 14.39292144775402],
        [74.39985656738298, 14.39292144775402],
        [74.39985656738298, 14.394030570983887],
        [74.39958190917997, 14.394027709961051],
        [74.39958190917997, 14.394860267639103],
        [74.39930725097656, 14.394860267639103],
        [74.39930725097656, 14.39597320556652],
        [74.39901733398466, 14.395970344543457],
        [74.39901733398466, 14.396810531616268],
        [74.39874267578125, 14.396810531616268],
        [74.39874267578125, 14.397920608520621],
        [74.3984832763673, 14.397920608520621],
        [74.3984832763673, 14.399030685424805],
        [74.39819335937506, 14.399028778076229],
        [74.39819335937506, 14.399861335754451],
        [74.39791870117182, 14.399861335754451],
        [74.39791870117182, 14.400694847107047],
        [74.39763641357428, 14.400690078735408],
        [74.39763641357428, 14.401811599731445],
        [74.39736175537126, 14.401811599731445],
        [74.39736175537126, 14.402641296386832],
        [74.39708709716803, 14.402639389038086],
        [74.39708709716803, 14.403470993042049],
        [74.39680480957048, 14.403470993042049],
        [74.39680480957048, 14.404582977294979],
        [74.39653015136747, 14.404581069946403],
        [74.39653015136747, 14.405420303344727],
        [74.3962478637697, 14.405420303344727],
        [74.3962478637697, 14.406261444091797],
        [74.39597320556669, 14.406261444091797],
        [74.39597320556669, 14.406811714172363],
        [74.39568328857422, 14.406811714172363],
        [74.39568328857422, 14.407638549804688],
        [74.39597320556669, 14.407638549804688],
        [74.39597320556669, 14.408752441406364],
        [74.3962478637697, 14.408748626709041],
        [74.3962478637697, 14.409584045410156],
        [74.39597320556669, 14.409581184387264],
        [74.39597320556669, 14.410141944885254],
        [74.39569091796875, 14.410140037536621],
        [74.39569091796875, 14.41069316864025],
        [74.39541625976591, 14.410691261291447],
        [74.39541625976591, 14.411252021789608],
        [74.39514160156256, 14.411252021789608],
        [74.39514160156256, 14.411809921264648],
        [74.39485931396496, 14.411809921264648],
        [74.39485931396496, 14.412639617920036],
        [74.39458465576178, 14.412637710571232],
        [74.39458465576178, 14.413193702697754],
        [74.394302368164, 14.413190841674862],
        [74.394302368164, 14.413749694824219],
        [74.39402770996116, 14.413749694824219],
        [74.39402770996116, 14.414859771728572],
        [74.39375305175798, 14.414859771728572],
        [74.39375305175798, 14.416250228881893],
        [74.39347076416021, 14.416250228881893],
        [74.39347076416021, 14.416810989379996],
        [74.3931961059572, 14.416810989379996],
        [74.3931961059572, 14.417639732360897],
        [74.39291381835966, 14.41763782501232],
        [74.39289093017584, 14.418126106262264],
        [74.39263916015625, 14.418201446533146],
        [74.39263916015625, 14.418751716613883],
        [74.39212036132835, 14.41874980926508],
        [74.38819122314447, 14.418751716613883],
        [74.38819122314447, 14.41847038269043],
        [74.38708496093767, 14.418472290039176],
        [74.38708496093767, 14.418193817138786],
        [74.38735961914068, 14.418201446533146],
        [74.38735961914068, 14.41792106628418],
        [74.38764190673845, 14.41792106628418],
        [74.38764190673845, 14.417639732360897],
        [74.38791656494163, 14.417639732360897],
        [74.38791656494163, 14.417360305786246],
        [74.38819122314447, 14.417362213134822],
        [74.38819122314447, 14.417083740234432],
        [74.38680267334013, 14.417083740234432],
        [74.38680267334013, 14.417362213134822],
        [74.38652801513672, 14.417360305786246],
        [74.38652801513672, 14.417639732360897],
        [74.38597106933594, 14.417639732360897],
        [74.38597106933594, 14.41792106628418],
        [74.38569641113293, 14.41792106628418],
        [74.38569641113293, 14.418201446533146],
        [74.38541412353521, 14.418193817138786],
        [74.38541412353521, 14.418472290039176],
        [74.38403320312517, 14.41847038269043],
        [74.38403320312517, 14.418751716613883],
        [74.38346862792986, 14.41874980926508],
        [74.38346862792986, 14.419584274291935],
        [74.38318634033232, 14.419580459594783],
        [74.38318634033232, 14.420420646667594],
        [74.38236236572271, 14.420415878295898],
        [74.38236236572271, 14.420694351196289],
        [74.38208007812511, 14.420694351196289],
        [74.38208007812511, 14.421250343322754],
        [74.38180541992193, 14.421250343322754],
        [74.38180541992193, 14.422360420227108],
        [74.38153076171892, 14.422360420227108],
        [74.38153076171892, 14.422921180725098],
        [74.38124847412132, 14.422916412353572],
        [74.38124847412132, 14.423473358154354],
        [74.38096618652361, 14.423469543457031],
        [74.38096618652361, 14.424031257629451],
        [74.38124847412132, 14.424025535583496],
        [74.38124847412132, 14.424583435058707],
        [74.38180541992193, 14.424583435058707],
        [74.38180541992193, 14.424860954284611],
        [74.38236236572271, 14.424860954284611],
        [74.38236236572271, 14.424579620361385],
        [74.38291931152344, 14.424583435058707],
        [74.38291931152344, 14.42430686950695],
        [74.38402557373064, 14.42430686950695],
        [74.38402557373064, 14.424860954284611],
        [74.38430786132818, 14.424860954284611],
        [74.38430786132818, 14.426251411437931],
        [74.38458251953142, 14.426251411437931],
        [74.38458251953142, 14.42792129516613],
        [74.38430786132818, 14.42791652679449],
        [74.38430786132818, 14.428194999694881],
        [74.38375091552763, 14.428194999694881],
        [74.38375091552763, 14.428473472595272],
        [74.38346862792986, 14.42847061157238],
        [74.38346862792986, 14.428750991821346],
        [74.38318634033232, 14.428750991821346],
        [74.38318634033232, 14.429031372070312],
        [74.38263702392607, 14.429031372070312],
        [74.38263702392607, 14.429308891296387],
        [74.38236236572271, 14.42930698394781],
        [74.38236236572271, 14.429582595825309],
        [74.37959289550793, 14.429580688476562],
        [74.37959289550793, 14.431531906127987],
        [74.38014221191435, 14.431531906127987],
        [74.38014221191435, 14.431810379028377],
        [74.38041687011736, 14.431804656982422],
        [74.38041687011736, 14.432083129882812],
        [74.38096618652361, 14.432081222534237],
        [74.38096618652361, 14.432360649108887],
        [74.38124847412132, 14.432360649108887],
        [74.38124847412132, 14.432081222534237],
        [74.38153076171892, 14.432083129882812],
        [74.38153076171892, 14.431804656982422],
        [74.38181304931663, 14.431810379028377],
        [74.38181304931663, 14.431531906127987],
        [74.38263702392607, 14.431527137756348],
        [74.38263702392607, 14.432083129882812],
        [74.38291931152344, 14.432083129882812],
        [74.38291931152344, 14.433193206787166],
        [74.38318634033232, 14.433178901672363],
        [74.38318634033232, 14.434029579162598],
        [74.38291931152344, 14.434029579162598],
        [74.38291931152344, 14.434309959411564],
        [74.38263702392607, 14.434305191040096],
        [74.38263702392607, 14.43486118316656],
        [74.38236236572271, 14.43486118316656],
        [74.38236236572271, 14.435971260070744],
        [74.38208007812511, 14.435971260070744],
        [74.38208007812511, 14.436809539794979],
        [74.38180541992193, 14.4368057250976],
        [74.38180541992193, 14.43708419799799],
        [74.38208007812511, 14.437081336975098],
        [74.38208007812511, 14.437640190124455],
        [74.38236236572271, 14.437640190124455],
        [74.38236236572271, 14.439862251281795],
        [74.38208007812511, 14.439860343933162],
        [74.38208007812511, 14.441810607910156],
        [74.38181304931663, 14.441810607910156],
        [74.38181304931663, 14.44237136840826],
        [74.38153076171892, 14.44237136840826],
        [74.38153076171892, 14.442919731140194],
        [74.38124847412132, 14.442915916442871],
        [74.38124847412132, 14.443471908569336],
        [74.38096618652361, 14.443471908569336],
        [74.38096618652361, 14.44403266906744],
        [74.38069152832037, 14.4440279006958],
        [74.38069152832037, 14.44458198547369],
        [74.38041687011736, 14.44458198547369],
        [74.38041687011736, 14.44569396972662],
        [74.38069152832037, 14.44569396972662],
        [74.38069152832037, 14.44708442687994],
        [74.38041687011736, 14.4470796585083],
        [74.38041687011736, 14.447920799255428],
        [74.38014221191435, 14.447920799255428],
        [74.38014221191435, 14.448749542236328],
        [74.3798599243164, 14.448749542236328],
        [74.3798599243164, 14.44958209991455],
        [74.37959289550793, 14.449580192565975],
        [74.37959289550793, 14.450140953063908],
        [74.37930297851568, 14.450138092041072],
        [74.37930297851568, 14.450972557067928],
        [74.37902832031261, 14.450969696045036],
        [74.37902832031261, 14.451810836792106],
        [74.37875366210943, 14.45180511474615],
        [74.37875366210943, 14.452361106872615],
        [74.37847137451166, 14.452361106872615],
        [74.37847137451166, 14.453194618225211],
        [74.37819671630882, 14.453189849853572],
        [74.37819671630882, 14.454030990600643],
        [74.37791442871111, 14.454029083252067],
        [74.37791442871111, 14.454583168029785],
        [74.37763977050787, 14.454581260681152],
        [74.37763977050787, 14.455141067504996],
        [74.37735748291033, 14.45513916015625],
        [74.37735748291033, 14.455695152282715],
        [74.37708282470732, 14.455690383911246],
        [74.37708282470732, 14.45625114440918],
        [74.3768081665039, 14.45625114440918],
        [74.3768081665039, 14.456811904907283],
        [74.37652587890653, 14.456805229187069],
        [74.37652587890653, 14.457361221313533],
        [74.37625122070318, 14.457349777221623],
        [74.37625122070318, 14.458750724792537],
        [74.37596893310558, 14.458748817443961],
        [74.37596893310558, 14.460695266723576],
        [74.37568664550787, 14.460691452026424],
        [74.37568664550787, 14.461529731750431],
        [74.37541961669916, 14.461526870727539],
        [74.37541961669916, 14.46208286285406],
        [74.37513732910179, 14.462080955505428],
        [74.37513732910179, 14.462920188903752],
        [74.37486267089861, 14.462920188903752],
        [74.37486267089861, 14.463751792907772],
        [74.37458038330084, 14.46374893188488],
        [74.37458038330084, 14.464584350585994],
        [74.37431335449236, 14.464580535888672],
        [74.37431335449236, 14.465419769287166],
        [74.37403106689482, 14.465419769287166],
        [74.37403106689482, 14.46654129028326],
        [74.37374877929688, 14.466527938842773],
        [74.37374877929688, 14.467362403869629],
        [74.37347412109403, 14.467362403869629],
        [74.37347412109403, 14.468194007873592],
        [74.3731918334961, 14.468189239501896],
        [74.3731918334961, 14.469030380249023],
        [74.37291717529308, 14.469030380249023],
        [74.37291717529308, 14.469861984252987],
        [74.3726425170899, 14.469861984252987],
        [74.3726425170899, 14.47042274475092],
        [74.37236022949213, 14.470416069030875],
        [74.37236022949213, 14.47097206115734],
        [74.37209320068382, 14.470970153808594],
        [74.37209320068382, 14.471529960632381],
        [74.37180328369158, 14.471528053283805],
        [74.37180328369158, 14.472361564636174],
        [74.37152862548834, 14.47235107421875],
        [74.37152862548834, 14.472921371460018],
        [74.3712463378908, 14.472915649414062],
        [74.3712463378908, 14.473471641540527],
        [74.37097167968778, 14.473469734191951],
        [74.37097167968778, 14.474031448364371],
        [74.37069702148438, 14.474027633667049],
        [74.37069702148438, 14.474860191345272],
        [74.37040710449247, 14.474860191345272],
        [74.37040710449247, 14.475420951843262],
        [74.3701400756836, 14.475416183471737],
        [74.3701400756836, 14.475972175598258],
        [74.36985778808622, 14.475970268249455],
        [74.36985778808622, 14.476811408996582],
        [74.36958312988287, 14.476811408996582],
        [74.36958312988287, 14.477359771728516],
        [74.36930847167963, 14.477359771728516],
        [74.36930847167963, 14.47792053222662],
        [74.36902618408209, 14.47792053222662],
        [74.36902618408209, 14.478480339050293],
        [74.36873626708979, 14.478480339050293],
        [74.36873626708979, 14.47931098937994],
        [74.36846923828148, 14.479305267333984],
        [74.36846923828148, 14.47986125946045],
        [74.36818695068376, 14.47986125946045],
        [74.36818695068376, 14.480421066284293],
        [74.36791992187528, 14.480417251586971],
        [74.36791992187528, 14.480973243713436],
        [74.36763763427751, 14.480971336364803],
        [74.36763763427751, 14.481811523437443],
        [74.3673629760745, 14.481811523437443],
        [74.3673629760745, 14.482360839843864],
        [74.36708068847656, 14.482360839843864],
        [74.36708068847656, 14.482921600341797],
        [74.36680603027372, 14.482921600341797],
        [74.36680603027372, 14.483751296997184],
        [74.36653137207037, 14.483751296997184],
        [74.36653137207037, 14.484310150146541],
        [74.36624908447277, 14.484310150146541],
        [74.36624908447277, 14.485140800476017],
        [74.36597442626959, 14.485138893127441],
        [74.36597442626959, 14.485694885253906],
        [74.36569976806658, 14.485691070556754],
        [74.36569976806658, 14.486250877380371],
        [74.36541748046898, 14.486248970031795],
        [74.36541748046898, 14.48708343505865],
        [74.3651428222658, 14.487080574035588],
        [74.3651428222658, 14.487639427185115],
        [74.36486053466803, 14.487639427185115],
        [74.36486053466803, 14.488471031188908],
        [74.36458587646501, 14.488471031188908],
        [74.36458587646501, 14.489031791687069],
        [74.36430358886747, 14.489027023315373],
        [74.36430358886747, 14.489583015441895],
        [74.36402893066406, 14.489581108093262],
        [74.36402893066406, 14.490420341491813],
        [74.36374664306669, 14.49041843414301],
        [74.36374664306669, 14.490970611572322],
        [74.36347198486334, 14.490970611572322],
        [74.36347198486334, 14.491809844970817],
        [74.36319732666027, 14.491809844970817],
        [74.36319732666027, 14.49236202239996],
        [74.36292266845709, 14.49236202239996],
        [74.36292266845709, 14.492919921875],
        [74.36264038085932, 14.492919921875],
        [74.36264038085932, 14.493749618530387],
        [74.36235809326195, 14.493749618530387],
        [74.36235809326195, 14.49431037902832],
        [74.36207580566423, 14.49431037902832],
        [74.36207580566423, 14.495140075683707],
        [74.36180877685553, 14.495140075683707],
        [74.36180877685553, 14.4959716796875],
        [74.36152648925798, 14.4959716796875],
        [74.36152648925798, 14.496527671813965],
        [74.36125183105497, 14.496527671813965],
        [74.36125183105497, 14.49736213684082],
        [74.36096954345703, 14.49736213684082],
        [74.36096954345703, 14.498193740844783],
        [74.36068725585966, 14.498191833496207],
        [74.36068725585966, 14.499030113220215],
        [74.36042022705084, 14.499030113220215],
        [74.36042022705084, 14.499861717224235],
        [74.36013793945324, 14.499861717224235],
        [74.36013793945324, 14.500420570373592],
        [74.35986328125006, 14.500420570373592],
        [74.35986328125006, 14.501250267028752],
        [74.35957336425793, 14.501250267028752],
        [74.35957336425793, 14.501811027526912],
        [74.35847473144548, 14.50180435180664],
        [74.35847473144548, 14.502084732055607],
        [74.35763549804716, 14.502084732055607],
        [74.35763549804716, 14.50180435180664],
        [74.35736083984375, 14.501811027526912],
        [74.35736083984375, 14.501250267028752],
        [74.35708618164074, 14.501250267028752],
        [74.35708618164074, 14.500971794128361],
        [74.35541534423845, 14.500971794128361],
        [74.35542297363298, 14.501250267028752],
        [74.35514068603544, 14.501250267028752],
        [74.35514068603544, 14.501530647277946],
        [74.35485839843767, 14.501530647277946],
        [74.35485839843767, 14.501811027526912],
        [74.35458374023466, 14.50180435180664],
        [74.35458374023466, 14.502084732055607],
        [74.35430908203125, 14.502084732055607],
        [74.35430908203125, 14.502360343933105],
        [74.35402679443388, 14.502360343933105],
        [74.35402679443388, 14.502921104431266],
        [74.35375976562506, 14.502921104431266],
        [74.35375976562506, 14.503752708435059],
        [74.35402679443388, 14.503750801086426],
        [74.35402679443388, 14.504582405090446],
        [74.35430908203125, 14.504579544067383],
        [74.35430908203125, 14.50486087799078],
        [74.35402679443388, 14.50486087799078],
        [74.35402679443388, 14.50708293914795],
        [74.35430908203125, 14.50708293914795],
        [74.35430908203125, 14.507361412048283],
        [74.35485839843767, 14.507361412048283],
        [74.35485839843767, 14.50819015502941],
        [74.35457611084013, 14.508201599121094],
        [74.35457611084013, 14.508750915527344],
        [74.35514068603544, 14.508750915527344],
        [74.35514068603544, 14.50903129577648],
        [74.35597229003923, 14.50903129577648],
        [74.35597229003923, 14.509311676025447],
        [74.35652923583979, 14.509305000305233],
        [74.35652923583979, 14.509583473205623],
        [74.35708618164074, 14.50958061218273],
        [74.35708618164074, 14.509860992431697],
        [74.35764312744169, 14.509860992431697],
        [74.35764312744169, 14.510141372680664],
        [74.35819244384794, 14.510141372680664],
        [74.35819244384794, 14.510418891906738],
        [74.35930633544945, 14.510416984558162],
        [74.35930633544945, 14.510972976684627],
        [74.35902404785173, 14.510972976684627],
        [74.35902404785173, 14.51208305358898],
        [74.3587493896485, 14.51208305358898],
        [74.3587493896485, 14.513193130493164],
        [74.35846710205095, 14.513191223144588],
        [74.35846710205095, 14.51542186737072],
        [74.3587493896485, 14.51541709899908],
        [74.3587493896485, 14.515971183776912],
        [74.35901641845697, 14.515971183776912],
        [74.35901641845697, 14.516531944274902],
        [74.35930633544945, 14.516531944274902],
        [74.35930633544945, 14.516810417175293],
        [74.35957336425793, 14.516810417175293],
        [74.35957336425793, 14.51736164093029],
        [74.35986328125006, 14.51736164093029],
        [74.35986328125006, 14.517650604248047],
        [74.36013793945324, 14.517640113830623],
        [74.36013793945324, 14.518193244934082],
        [74.36042022705084, 14.51819133758545],
        [74.36042022705084, 14.518750190734806],
        [74.36068725585966, 14.518750190734806],
        [74.36068725585966, 14.519029617309684],
        [74.36125183105497, 14.519029617309684],
        [74.36125183105497, 14.51930999755865],
        [74.36069488525419, 14.519306182861271],
        [74.36069488525419, 14.519862174987793],
        [74.36096954345703, 14.51986026763916],
        [74.36096954345703, 14.520139694213867],
        [74.36152648925798, 14.520139694213867],
        [74.36152648925798, 14.520420074463004],
        [74.36208343505876, 14.520416259765625],
        [74.36208343505876, 14.520693778991756],
        [74.36264038085932, 14.520693778991756],
        [74.36264038085932, 14.520972251892147],
        [74.36319732666027, 14.520969390869254],
        [74.36319732666027, 14.521249771118221],
        [74.36429595947294, 14.521249771118221],
        [74.36429595947294, 14.521530151367188],
        [74.36458587646501, 14.521528244018612],
        [74.36458587646501, 14.522084236145076],
        [74.36486053466803, 14.522084236145076],
        [74.36486053466803, 14.522359848022575],
        [74.3651428222658, 14.522359848022575],
        [74.3651428222658, 14.522640228271541],
        [74.36541748046898, 14.522640228271541],
        [74.36541748046898, 14.522919654846191],
        [74.36597442626959, 14.52291584014904],
        [74.36597442626959, 14.52319431304943],
        [74.36624908447277, 14.52319431304943],
        [74.36624908447277, 14.523471832275504],
        [74.3673629760745, 14.52346134185791],
        [74.3673629760745, 14.524029731750545],
        [74.36763763427751, 14.524029731750545],
        [74.36763763427751, 14.524311065673828],
        [74.36791992187528, 14.524311065673828],
        [74.36791992187528, 14.525142669677791],
        [74.3681945800783, 14.525137901306152],
        [74.3681945800783, 14.525693893432617],
        [74.36846923828148, 14.525691986084041],
        [74.36846923828148, 14.526251792907715],
        [74.36873626708979, 14.526251792907715],
        [74.36873626708979, 14.52682018280035],
        [74.36902618408209, 14.52682018280035],
        [74.36902618408209, 14.527359962463436],
        [74.36930847167963, 14.527359962463436],
        [74.36930847167963, 14.527640342712402],
        [74.37069702148438, 14.527640342712402],
        [74.37069702148438, 14.527920722961426],
        [74.37236022949213, 14.527920722961426],
        [74.37236022949213, 14.527640342712402],
        [74.3726425170899, 14.527640342712402],
        [74.3726425170899, 14.527359962463436],
        [74.37291717529308, 14.527359962463436],
        [74.37291717529308, 14.52707958221447],
        [74.3731918334961, 14.527084350585938],
        [74.3731918334961, 14.526805877685547],
        [74.3734664916995, 14.52682018280035],
        [74.3734664916995, 14.526531219482536],
        [74.37374877929688, 14.526531219482536],
        [74.37374877929688, 14.525970458984432],
        [74.37403106689482, 14.525972366333008],
        [74.37403106689482, 14.525693893432617],
        [74.37430572509783, 14.525693893432617],
        [74.37430572509783, 14.525416374206543],
        [74.37458038330084, 14.525421142578182],
        [74.37458038330084, 14.524860382080078],
        [74.37486267089861, 14.524862289428825],
        [74.37486267089861, 14.524581909179688],
        [74.37513732910179, 14.524581909179688],
        [74.37513732910179, 14.52430629730236],
        [74.37541961669916, 14.524311065673828],
        [74.37541961669916, 14.523750305175895],
        [74.37568664550787, 14.523750305175895],
        [74.37568664550787, 14.52346134185791],
        [74.37596893310558, 14.523471832275504],
        [74.37596893310558, 14.52291584014904],
        [74.37625122070318, 14.522919654846191],
        [74.37625122070318, 14.522359848022575],
        [74.37652587890653, 14.522359848022575],
        [74.37652587890653, 14.522640228271541],
        [74.3768081665039, 14.522640228271541],
        [74.3768081665039, 14.522919654846191],
        [74.37735748291033, 14.52291584014904],
        [74.37735748291033, 14.52319431304943],
        [74.37763977050787, 14.52319431304943],
        [74.37763977050787, 14.523471832275504],
        [74.37790679931658, 14.52346134185791],
        [74.37790679931658, 14.523750305175895],
        [74.37847137451166, 14.523750305175895],
        [74.37847137451166, 14.524029731750545],
        [74.3787460327149, 14.524029731750545],
        [74.3787460327149, 14.524311065673828],
        [74.37930297851568, 14.52430629730236],
        [74.37930297851568, 14.524581909179688],
        [74.37959289550793, 14.524581909179688],
        [74.37959289550793, 14.525421142578182],
        [74.37930297851568, 14.525416374206543],
        [74.37930297851568, 14.525972366333008],
        [74.37902832031261, 14.525970458984432],
        [74.37902832031261, 14.526251792907715],
        [74.3787460327149, 14.526251792907715],
        [74.3787460327149, 14.52682018280035],
        [74.37847137451166, 14.526805877685547],
        [74.37847137451166, 14.527084350585938],
        [74.37819671630882, 14.52707958221447],
        [74.37819671630882, 14.527640342712402],
        [74.37791442871111, 14.527640342712402],
        [74.37791442871111, 14.528194427490291],
        [74.37763977050787, 14.528189659118652],
        [74.37763977050787, 14.528750419616756],
        [74.37735748291033, 14.528750419616756],
        [74.37735748291033, 14.52985954284668],
        [74.37708282470732, 14.52985954284668],
        [74.37708282470732, 14.530973434448185],
        [74.3768081665039, 14.530969619751033],
        [74.3768081665039, 14.531531333923454],
        [74.37652587890653, 14.531525611877498],
        [74.37652587890653, 14.53208255767828],
        [74.37625122070318, 14.53208255767828],
        [74.37625122070318, 14.532638549804744],
        [74.37596893310558, 14.532638549804744],
        [74.37596893310558, 14.53319454193121],
        [74.37568664550787, 14.53318977355957],
        [74.37568664550787, 14.533750534057674],
        [74.37541961669916, 14.533750534057674],
        [74.37541961669916, 14.534309387207031],
        [74.37486267089861, 14.534304618835563],
        [74.37486267089861, 14.534583091735954],
        [74.37458038330084, 14.534583091735954],
        [74.37458038330084, 14.534860610962028],
        [74.37431335449236, 14.534860610962028],
        [74.37431335449236, 14.535421371459961],
        [74.37374877929688, 14.535416603088493],
        [74.37374877929688, 14.535695075988883],
        [74.3734664916995, 14.535690307617244],
        [74.3734664916995, 14.536251068115348],
        [74.3731918334961, 14.536251068115348],
        [74.3731918334961, 14.53708267211914],
        [74.37374877929688, 14.53708267211914],
        [74.37374877929688, 14.537361145019531],
        [74.37458038330084, 14.537361145019531],
        [74.37458038330084, 14.536805152893066],
        [74.37486267089861, 14.536811828613281],
        [74.37486267089861, 14.536251068115348],
        [74.37513732910179, 14.536251068115348],
        [74.37513732910179, 14.535690307617244],
        [74.37541961669916, 14.535695075988883],
        [74.37541961669916, 14.535139083862418],
        [74.37568664550787, 14.535140991210994],
        [74.37568664550787, 14.53458023071289],
        [74.37596893310558, 14.534583091735954],
        [74.37596893310558, 14.534029006958065],
        [74.37625122070318, 14.53403091430664],
        [74.37625122070318, 14.533750534057674],
        [74.37652587890653, 14.533750534057674],
        [74.37652587890653, 14.533471107482967],
        [74.3768081665039, 14.5334730148316],
        [74.3768081665039, 14.53319454193121],
        [74.37708282470732, 14.53319454193121],
        [74.37708282470732, 14.532917022705135],
        [74.37735748291033, 14.532920837402457],
        [74.37735748291033, 14.532631874084473],
        [74.37930297851568, 14.532638549804744],
        [74.37930297851568, 14.532361030578613],
        [74.37959289550793, 14.532361030578613],
        [74.37958526611357, 14.531806945800781],
        [74.3798599243164, 14.531810760498104],
        [74.3798599243164, 14.531531333923454],
        [74.38014221191435, 14.531531333923454],
        [74.38014221191435, 14.53125095367426],
        [74.38041687011736, 14.53125095367426],
        [74.38041687011736, 14.530690193176326],
        [74.38069152832037, 14.530694961547795],
        [74.38069152832037, 14.530415534973145],
        [74.38096618652361, 14.5304212570191],
        [74.38096618652361, 14.530140876770133],
        [74.38124847412132, 14.530140876770133],
        [74.38124847412132, 14.529590606689396],
        [74.38153076171892, 14.529590606689396],
        [74.38153076171892, 14.529311180114746],
        [74.38181304931663, 14.529311180114746],
        [74.38181304931663, 14.52903079986578],
        [74.38208007812511, 14.52903079986578],
        [74.38208007812511, 14.528470039367619],
        [74.38236236572271, 14.528471946716422],
        [74.38236236572271, 14.528194427490291],
        [74.38263702392607, 14.528194427490291],
        [74.38263702392607, 14.527638435363826],
        [74.38291931152344, 14.527640342712402],
        [74.38291931152344, 14.52707958221447],
        [74.38319396972685, 14.527084350585938],
        [74.38319396972685, 14.526249885559082],
        [74.38346862792986, 14.526251792907715],
        [74.38346862792986, 14.525691986084041],
        [74.38375091552763, 14.525693893432617],
        [74.38375091552763, 14.524862289428825],
        [74.38402557373064, 14.524862289428825],
        [74.38402557373064, 14.52430629730236],
        [74.38430786132818, 14.524311065673828],
        [74.38430786132818, 14.523750305175895],
        [74.38652801513672, 14.523750305175895],
        [74.38652801513672, 14.52479171752941],
        [74.38652801513672, 14.528750419616756],
        [74.38707733154314, 14.528750419616756],
        [74.38707733154314, 14.52903079986578],
        [74.38735961914068, 14.52903079986578],
        [74.38735961914068, 14.529311180114746],
        [74.38791656494163, 14.529311180114746],
        [74.38791656494163, 14.529590606689396],
        [74.38931274414091, 14.529590606689396],
        [74.38931274414091, 14.529311180114746],
        [74.39068603515642, 14.529311180114746],
        [74.39068603515642, 14.52903079986578],
        [74.39290618896513, 14.52903079986578],
        [74.39290618896513, 14.529311180114746],
        [74.39347076416021, 14.529311180114746],
        [74.39347076416021, 14.5304212570191],
        [74.3931961059572, 14.5304212570191],
        [74.3931961059572, 14.53125095367426],
        [74.39068603515642, 14.53125095367426],
        [74.39068603515642, 14.531531333923454],
        [74.39041900634771, 14.531525611877498],
        [74.39041900634771, 14.53208255767828],
        [74.39013671875017, 14.53208255767828],
        [74.39013671875017, 14.532638549804744],
        [74.38986206054716, 14.532638549804744],
        [74.38986206054716, 14.53319454193121],
        [74.38957977294922, 14.53318977355957],
        [74.38957977294922, 14.533750534057674],
        [74.38931274414091, 14.533750534057674],
        [74.38931274414091, 14.53403091430664],
        [74.38735961914068, 14.53403091430664],
        [74.38735961914068, 14.533750534057674],
        [74.38707733154314, 14.533750534057674],
        [74.38707733154314, 14.53318977355957],
        [74.38680267334013, 14.53319454193121],
        [74.38680267334013, 14.532917022705135],
        [74.38652801513672, 14.532920837402457],
        [74.38652801513672, 14.532361030578613],
        [74.38625335693388, 14.532361030578613],
        [74.38625335693388, 14.531810760498104],
        [74.38597106933594, 14.531810760498104],
        [74.38597106933594, 14.531531333923454],
        [74.38569641113293, 14.531531333923454],
        [74.38569641113293, 14.530969619751033],
        [74.38541412353521, 14.530973434448185],
        [74.38541412353521, 14.530694961547795],
        [74.38403320312517, 14.530690193176326],
        [74.38403320312517, 14.53125095367426],
        [74.38430786132818, 14.53125095367426],
        [74.38430786132818, 14.531810760498104],
        [74.38458251953142, 14.531810760498104],
        [74.38458251953142, 14.532361030578613],
        [74.38485717773443, 14.532361030578613],
        [74.38485717773443, 14.532920837402457],
        [74.38513946533197, 14.532917022705135],
        [74.38513946533197, 14.5334730148316],
        [74.38540649414068, 14.533471107482967],
        [74.38540649414068, 14.534309387207031],
        [74.38513946533197, 14.534309387207031],
        [74.38513946533197, 14.535140991210994],
        [74.38458251953142, 14.535140991210994],
        [74.38458251953142, 14.535421371459961],
        [74.38430786132818, 14.535416603088493],
        [74.38430786132818, 14.535695075988883],
        [74.38375091552763, 14.535695075988883],
        [74.38375091552763, 14.535972595214957],
        [74.38346862792986, 14.535970687866211],
        [74.38346862792986, 14.536251068115348],
        [74.38321685791027, 14.536251068115348],
        [74.38291931152344, 14.536251068115348],
        [74.38291931152344, 14.53708267211914],
        [74.38304901123041, 14.537080764770565],
        [74.38318634033232, 14.537077903747672],
        [74.38318634033232, 14.5387601852417],
        [74.38346862792986, 14.5387601852417],
        [74.38346862792986, 14.540419578552246],
        [74.38375091552763, 14.54041671752941],
        [74.38375091552763, 14.543471336364746],
        [74.38513946533197, 14.543471336364746],
        [74.38513946533197, 14.54375171661377],
        [74.38540649414068, 14.54375171661377],
        [74.38540649414068, 14.544030189514103],
        [74.38597106933594, 14.544030189514103],
        [74.38597106933594, 14.54430961608898],
        [74.38625335693388, 14.544305801391602],
        [74.38625335693388, 14.544584274291992],
        [74.38652801513672, 14.544569969177246],
        [74.38652801513672, 14.544861793518123],
        [74.38680267334013, 14.544861793518123],
        [74.38680267334013, 14.545140266418457],
        [74.38708496093767, 14.545140266418457],
        [74.38708496093767, 14.545694351196346],
        [74.38735961914068, 14.545690536499023],
        [74.38735961914068, 14.546810150146484],
        [74.38708496093767, 14.546806335449332],
        [74.38708496093767, 14.547083854675407],
        [74.38680267334013, 14.547083854675407],
        [74.38680267334013, 14.547362327575797],
        [74.38652801513672, 14.547362327575797],
        [74.38652801513672, 14.547639846801871],
        [74.38625335693388, 14.547631263733024],
        [74.38625335693388, 14.547920227050838],
        [74.38597106933594, 14.547916412353459],
        [74.38597106933594, 14.54847240447998],
        [74.38569641113293, 14.548468589782772],
        [74.38569641113293, 14.548749923706055],
        [74.38540649414068, 14.548749923706055],
        [74.38540649414068, 14.549030303955192],
        [74.38513946533197, 14.549030303955192],
        [74.38513946533197, 14.549309730529899],
        [74.38485717773443, 14.549309730529899],
        [74.38485717773443, 14.549861907958984],
        [74.38458251953142, 14.549861907958984],
        [74.38458251953142, 14.550140380859375],
        [74.38430786132818, 14.550140380859375],
        [74.38430786132818, 14.550419807434025],
        [74.38403320312517, 14.550419807434025],
        [74.38403320312517, 14.550701141357479],
        [74.38375091552763, 14.550694465637264],
        [74.38375091552763, 14.550971984863338],
        [74.38346862792986, 14.550971984863338],
        [74.38346862792986, 14.551532745361271],
        [74.38318634033232, 14.551532745361271],
        [74.38318634033232, 14.551811218261662],
        [74.38236236572271, 14.551811218261662],
        [74.38236236572271, 14.551250457763729],
        [74.38208007812511, 14.551250457763729],
        [74.38208007812511, 14.550971984863338],
        [74.38180541992193, 14.550971984863338],
        [74.38180541992193, 14.550415992736873],
        [74.38153076171892, 14.550419807434025],
        [74.38153076171892, 14.550140380859375],
        [74.38124847412132, 14.550140380859375],
        [74.38124847412132, 14.549861907958984],
        [74.38096618652361, 14.549861907958984],
        [74.38096618652361, 14.549309730529899],
        [74.37959289550793, 14.549309730529899],
        [74.37959289550793, 14.549030303955192],
        [74.37902832031261, 14.549030303955192],
        [74.37902832031261, 14.548749923706055],
        [74.37847137451166, 14.548749923706055],
        [74.37847137451166, 14.548468589782772],
        [74.37790679931658, 14.548468589782772],
        [74.37790679931658, 14.549030303955192],
        [74.37763977050787, 14.549030303955192],
        [74.37763977050787, 14.549309730529899],
        [74.37735748291033, 14.54930591583252],
        [74.37735748291033, 14.54958438873291],
        [74.37708282470732, 14.549578666687125],
        [74.37708282470732, 14.550140380859375],
        [74.3768081665039, 14.550140380859375],
        [74.3768081665039, 14.550419807434025],
        [74.37652587890653, 14.550419807434025],
        [74.37652587890653, 14.550701141357479],
        [74.37625122070318, 14.550701141357479],
        [74.37625122070318, 14.551250457763729],
        [74.37596893310558, 14.551250457763729],
        [74.37596893310558, 14.551532745361271],
        [74.37541961669916, 14.551532745361271],
        [74.37541961669916, 14.551811218261662],
        [74.37513732910179, 14.551806449890194],
        [74.37513732910179, 14.552082061767692],
        [74.37458038330084, 14.552082061767692],
        [74.37458038330084, 14.552362442016658],
        [74.37403106689482, 14.552359580993596],
        [74.37403106689482, 14.552639961242733],
        [74.37374877929688, 14.552639961242733],
        [74.37374877929688, 14.552921295166016],
        [74.3731918334961, 14.55291557312006],
        [74.3731918334961, 14.553194046020451],
        [74.37291717529308, 14.553194046020451],
        [74.37291717529308, 14.553471565246525],
        [74.3726425170899, 14.55346965789795],
        [74.3726425170899, 14.553762435913143],
        [74.37152862548834, 14.553762435913143],
        [74.37152862548834, 14.55346965789795],
        [74.3712463378908, 14.553471565246525],
        [74.3712463378908, 14.553194046020451],
        [74.37069702148438, 14.553194046020451],
        [74.37069702148438, 14.55291557312006],
        [74.3701400756836, 14.552921295166016],
        [74.3701400756836, 14.552639961242733],
        [74.36791992187528, 14.552639961242733],
        [74.36653137207037, 14.552639961242733],
        [74.36653137207037, 14.552921295166016],
        [74.36597442626959, 14.55291557312006],
        [74.36597442626959, 14.553194046020451],
        [74.36541748046898, 14.553194046020451],
        [74.36541748046898, 14.553471565246525],
        [74.36486053466803, 14.55346965789795],
        [74.36486053466803, 14.553762435913143],
        [74.36458587646501, 14.553762435913143],
        [74.36458587646501, 14.55403137207037],
        [74.36486053466803, 14.554027557373047],
        [74.36486053466803, 14.554583549499512],
        [74.3651428222658, 14.554579734802303],
        [74.3651428222658, 14.555141448974723],
        [74.36541748046898, 14.555141448974723],
        [74.36541748046898, 14.55542087554943],
        [74.36569213867182, 14.555416107177734],
        [74.36569213867182, 14.555693626403865],
        [74.36624908447277, 14.555693626403865],
        [74.36624908447277, 14.555972099304256],
        [74.36653137207037, 14.555970191955623],
        [74.36653137207037, 14.55624961853033],
        [74.36680603027372, 14.55624961853033],
        [74.36680603027372, 14.556530952453613],
        [74.36708068847656, 14.55652904510498],
        [74.36708068847656, 14.556806564331112],
        [74.3673629760745, 14.556806564331112],
        [74.3673629760745, 14.55708217620861],
        [74.36763763427751, 14.557080268859806],
        [74.36763763427751, 14.557359695434684],
        [74.36818695068376, 14.557359695434684],
        [74.36818695068376, 14.557641029357967],
        [74.36846923828148, 14.557641029357967],
        [74.36846923828148, 14.557921409606934],
        [74.36875152587908, 14.557916641235465],
        [74.36875152587908, 14.55847263336193],
        [74.36902618408209, 14.558469772338867],
        [74.36902618408209, 14.559310913085938],
        [74.36930847167963, 14.559310913085938],
        [74.36930847167963, 14.560140609741325],
        [74.36958312988287, 14.560138702392578],
        [74.36958312988287, 14.560973167419434],
        [74.36985778808622, 14.5609712600708],
        [74.36985778808622, 14.561812400817928],
        [74.3701400756836, 14.561812400817928],
        [74.3701400756836, 14.562360763549862],
        [74.37040710449247, 14.562360763549862],
        [74.37040710449247, 14.562641143798828],
        [74.37097167968778, 14.562641143798828],
        [74.37097167968778, 14.56293106079113],
        [74.3712463378908, 14.562916755676326],
        [74.3712463378908, 14.563195228576717],
        [74.37152862548834, 14.563190460205078],
        [74.37152862548834, 14.563470840454215],
        [74.37180328369158, 14.563470840454215],
        [74.37180328369158, 14.563751220703182],
        [74.37236022949213, 14.563751220703182],
        [74.37236022949213, 14.564030647277889],
        [74.3726425170899, 14.564030647277889],
        [74.3726425170899, 14.564311981201115],
        [74.3734664916995, 14.564311981201115],
        [74.3734664916995, 14.564030647277889],
        [74.37403106689482, 14.564030647277889],
        [74.37403106689482, 14.563751220703182],
        [74.37486267089861, 14.563751220703182],
        [74.37486267089861, 14.563470840454215],
        [74.37596893310558, 14.563470840454215],
        [74.37596893310558, 14.563751220703182],
        [74.37625122070318, 14.563751220703182],
        [74.37625122070318, 14.564311981201115],
        [74.3756942749024, 14.56430530548107],
        [74.3756942749024, 14.564582824707145],
        [74.37513732910179, 14.564582824707145],
        [74.37513732910179, 14.564861297607536],
        [74.37458038330084, 14.564861297607536],
        [74.37458038330084, 14.565140724182243],
        [74.37486267089861, 14.565140724182243],
        [74.37486267089861, 14.5656995773316],
        [74.37513732910179, 14.5656995773316],
        [74.37513732910179, 14.56652927398676],
        [74.37541961669916, 14.56652927398676],
        [74.37541961669916, 14.567639350891113],
        [74.3756942749024, 14.567639350891113],
        [74.3756942749024, 14.568195343017578],
        [74.37596893310558, 14.568195343017578],
        [74.37596893310558, 14.568749427795467],
        [74.37625122070318, 14.568749427795467],
        [74.37625122070318, 14.57097053527832],
        [74.37763977050787, 14.57097053527832],
        [74.37763977050787, 14.572361946105957],
        [74.37735748291033, 14.572361946105957],
        [74.37735748291033, 14.57264041900629],
        [74.37708282470732, 14.57264041900629],
        [74.37708282470732, 14.572919845581168],
        [74.37652587890653, 14.572917938232422],
        [74.37652587890653, 14.57319355010992],
        [74.37625122070318, 14.57319355010992],
        [74.37625122070318, 14.57347202301031],
        [74.37596893310558, 14.57347202301031],
        [74.37596893310558, 14.573749542236385],
        [74.37374877929688, 14.573749542236385],
        [74.37374877929688, 14.57347202301031],
        [74.37152862548834, 14.57347202301031],
        [74.37152862548834, 14.57319355010992],
        [74.3712463378908, 14.57319355010992],
        [74.3712463378908, 14.572917938232422],
        [74.37097167968778, 14.572919845581168],
        [74.37097167968778, 14.572361946105957],
        [74.37069702148438, 14.572361946105957],
        [74.37069702148438, 14.572080612182674],
        [74.370414733887, 14.572084426879826],
        [74.370414733887, 14.571805953979435],
        [74.3701400756836, 14.571805953979435],
        [74.3701400756836, 14.571528434753361],
        [74.36985778808622, 14.571530342102164],
        [74.36985778808622, 14.57097053527832],
        [74.36958312988287, 14.57097053527832],
        [74.36958312988287, 14.570695877075309],
        [74.36930847167963, 14.570691108703613],
        [74.36930847167963, 14.570418357849178],
        [74.36902618408209, 14.57042026519781],
        [74.36902618408209, 14.57014179229742],
        [74.36873626708979, 14.57014179229742],
        [74.36873626708979, 14.56958103179926],
        [74.36846923828148, 14.569582939148063],
        [74.36846923828148, 14.569305419921932],
        [74.36818695068376, 14.569310188293457],
        [74.36818695068376, 14.569031715393066],
        [74.3673629760745, 14.569031715393066],
        [74.3673629760745, 14.568741798400936],
        [74.36653137207037, 14.568749427795467],
        [74.36653137207037, 14.568470954895076],
        [74.36569213867182, 14.568470954895076],
        [74.36569213867182, 14.567916870117244],
        [74.36596679687506, 14.56791973114008],
        [74.36596679687506, 14.567639350891113],
        [74.36569976806658, 14.567639350891113],
        [74.36569976806658, 14.567360877990723],
        [74.36541748046898, 14.567360877990723],
        [74.36541748046898, 14.566809654235954],
        [74.3651428222658, 14.566809654235954],
        [74.3651428222658, 14.56652927398676],
        [74.36486053466803, 14.56652927398676],
        [74.36486053466803, 14.566250801086369],
        [74.36068725585966, 14.566250801086369],
        [74.36068725585966, 14.565971374511719],
        [74.36042022705084, 14.565973281860295],
        [74.36042022705084, 14.565694808959904],
        [74.36013793945324, 14.5656995773316],
        [74.36013793945324, 14.565419197082576],
        [74.35986328125006, 14.565419197082576],
        [74.35986328125006, 14.565140724182243],
        [74.35957336425793, 14.565140724182243],
        [74.35957336425793, 14.564861297607536],
        [74.35930633544945, 14.564861297607536],
        [74.35930633544945, 14.56430530548107],
        [74.35901641845697, 14.564311981201115],
        [74.35901641845697, 14.564030647277889],
        [74.3587493896485, 14.564030647277889],
        [74.3587493896485, 14.563751220703182],
        [74.35846710205095, 14.563751220703182],
        [74.35846710205095, 14.563470840454215],
        [74.35819244384794, 14.563470840454215],
        [74.35819244384794, 14.563190460205078],
        [74.35791778564453, 14.563195228576717],
        [74.35791778564453, 14.562916755676326],
        [74.35736083984375, 14.56293106079113],
        [74.35736083984375, 14.562641143798828],
        [74.35652923583979, 14.562641143798828],
        [74.35652923583979, 14.562080383300895],
        [74.35680389404303, 14.562083244323787],
        [74.35680389404303, 14.561529159545898],
        [74.35708618164074, 14.561531066894645],
        [74.35708618164074, 14.5609712600708],
        [74.35736083984375, 14.560973167419434],
        [74.35736083984375, 14.560417175292969],
        [74.35764312744169, 14.560420989990291],
        [74.35764312744169, 14.559861183166447],
        [74.35791778564453, 14.559861183166447],
        [74.35791778564453, 14.559305191039982],
        [74.35819244384794, 14.559310913085938],
        [74.35819244384794, 14.55875110626232],
        [74.35846710205095, 14.55875110626232],
        [74.35846710205095, 14.55819034576416],
        [74.3587493896485, 14.558195114135799],
        [74.3587493896485, 14.557638168335075],
        [74.35901641845697, 14.557641029357967],
        [74.35901641845697, 14.557080268859806],
        [74.35930633544945, 14.55708217620861],
        [74.35930633544945, 14.55652904510498],
        [74.35957336425793, 14.556530952453613],
        [74.35957336425793, 14.555970191955623],
        [74.35986328125006, 14.555972099304256],
        [74.35986328125006, 14.553194046020451],
        [74.35958099365229, 14.553194046020451],
        [74.35958099365229, 14.551806449890194],
        [74.35986328125006, 14.551811218261662],
        [74.35986328125006, 14.550701141357479],
        [74.36013793945324, 14.550701141357479],
        [74.36013793945324, 14.550419807434025],
        [74.36042022705084, 14.550419807434025],
        [74.36042022705084, 14.550140380859375],
        [74.36013793945324, 14.550140380859375],
        [74.36013793945324, 14.549578666687125],
        [74.35986328125006, 14.54958438873291],
        [74.35986328125006, 14.549028396606445],
        [74.35957336425793, 14.549030303955192],
        [74.35957336425793, 14.548468589782772],
        [74.35930633544945, 14.54847240447998],
        [74.35930633544945, 14.547916412353459],
        [74.35901641845697, 14.547920227050838],
        [74.35901641845697, 14.547360420226994],
        [74.3587493896485, 14.547362327575797],
        [74.3587493896485, 14.546806335449332],
        [74.35846710205095, 14.546810150146484],
        [74.35846710205095, 14.54625034332281],
        [74.35819244384794, 14.54625034332281],
        [74.35819244384794, 14.545971870422477],
        [74.35763549804716, 14.545971870422477],
        [74.35763549804716, 14.545694351196346],
        [74.35708618164074, 14.545694351196346],
        [74.35708618164074, 14.545417785644588],
        [74.35652923583979, 14.545419692993164],
        [74.35652923583979, 14.545140266418457],
        [74.35597229003923, 14.545140266418457],
        [74.35597229003923, 14.544861793518123],
        [74.35542297363298, 14.544861793518123],
        [74.35542297363298, 14.544569969177246],
        [74.35514068603544, 14.544584274291992],
        [74.35514068603544, 14.544305801391602],
        [74.35485839843767, 14.54430961608898],
        [74.35485839843767, 14.54375171661377],
        [74.35457611084013, 14.54375171661377],
        [74.35457611084013, 14.543471336364746],
        [74.35430908203125, 14.543471336364746],
        [74.35430908203125, 14.542915344238281],
        [74.35402679443388, 14.542915344238281],
        [74.35402679443388, 14.543195724487248],
        [74.35346984863293, 14.543195724487248],
        [74.35346984863293, 14.543471336364746],
        [74.35291290283197, 14.543471336364746],
        [74.35291290283197, 14.54375171661377],
        [74.35235595703142, 14.54375171661377],
        [74.35235595703142, 14.544030189514103],
        [74.35180664062517, 14.544030189514103],
        [74.35180664062517, 14.54430961608898],
        [74.35124969482422, 14.544305801391602],
        [74.35124969482422, 14.544584274291992],
        [74.35070037841803, 14.544569969177246],
        [74.35070037841803, 14.544861793518123],
        [74.35013580322271, 14.544861793518123],
        [74.35013580322271, 14.545140266418457],
        [74.34958648681663, 14.545140266418457],
        [74.34958648681663, 14.54625034332281],
        [74.34929656982439, 14.54625034332281],
        [74.34929656982439, 14.546810150146484],
        [74.34902954101568, 14.546806335449332],
        [74.34902954101568, 14.547362327575797],
        [74.34874725341814, 14.547360420226994],
        [74.34874725341814, 14.547920227050838],
        [74.34847259521513, 14.547916412353459],
        [74.34847259521513, 14.54847240447998],
        [74.34819793701172, 14.548468589782772],
        [74.34819793701172, 14.549030303955192],
        [74.34791564941435, 14.549028396606445],
        [74.34791564941435, 14.54958438873291],
        [74.3476333618164, 14.549578666687125],
        [74.3476333618164, 14.549861907958984],
        [74.3473587036134, 14.549861907958984],
        [74.3473587036134, 14.550419807434025],
        [74.34708404541021, 14.550415992736873],
        [74.34708404541021, 14.550971984863338],
        [74.34680938720697, 14.550971984863338],
        [74.34680175781244, 14.551532745361271],
        [74.3465270996096, 14.551527976989803],
        [74.3465270996096, 14.552082061767692],
        [74.34625244140642, 14.552080154418945],
        [74.34625244140642, 14.552639961242733],
        [74.34597015380865, 14.552638053894157],
        [74.34597015380865, 14.553194046020451],
        [74.34570312500017, 14.553190231323299],
        [74.34570312500017, 14.554310798645076],
        [74.3454132080081, 14.554310798645076],
        [74.3454132080081, 14.556530952453613],
        [74.34513854980469, 14.556530952453613],
        [74.34513854980469, 14.557921409606934],
        [74.3454132080081, 14.557916641235465],
        [74.3454132080081, 14.558195114135799],
        [74.34569549560564, 14.558195114135799],
        [74.34569549560564, 14.55875110626232],
        [74.34597015380865, 14.55875110626232],
        [74.34597015380865, 14.559030532836971],
        [74.34625244140642, 14.559030532836971],
        [74.34625244140642, 14.559310913085938],
        [74.3465270996096, 14.559305191039982],
        [74.3465270996096, 14.559861183166447],
        [74.34680175781244, 14.559861183166447],
        [74.34680175781244, 14.560694694519043],
        [74.3465270996096, 14.560694694519043],
        [74.3465270996096, 14.560973167419434],
        [74.34597015380865, 14.5609712600708],
        [74.34597015380865, 14.561250686645508],
        [74.34570312500017, 14.561250686645508],
        [74.34570312500017, 14.561531066894645],
        [74.34513854980469, 14.561531066894645],
        [74.34513854980469, 14.561812400817928],
        [74.34486389160185, 14.561804771423397],
        [74.34486389160185, 14.562083244323787],
        [74.3443069458009, 14.562080383300895],
        [74.3443069458009, 14.562360763549862],
        [74.34401702880865, 14.562360763549862],
        [74.34401702880865, 14.562641143798828],
        [74.34375, 14.562641143798828],
        [74.34375, 14.56293106079113],
        [74.34319305419939, 14.562916755676326],
        [74.34319305419939, 14.563195228576717],
        [74.34291839599615, 14.563190460205078],
        [74.34291839599615, 14.563470840454215],
        [74.3423614501956, 14.563470840454215],
        [74.3423614501956, 14.563751220703182],
        [74.34208679199219, 14.563751220703182],
        [74.34208679199219, 14.564311981201115],
        [74.34179687500028, 14.564311981201115],
        [74.34179687500028, 14.565140724182243],
        [74.3415298461914, 14.56513881683344],
        [74.3415298461914, 14.565973281860295],
        [74.34124755859403, 14.565971374511719],
        [74.34124755859403, 14.56652927398676],
        [74.34097290039068, 14.56652927398676],
        [74.34097290039068, 14.567360877990723],
        [74.34069824218744, 14.567360877990723],
        [74.34069824218744, 14.568195343017578],
        [74.3404159545899, 14.568195343017578],
        [74.3404159545899, 14.568749427795467],
        [74.3373641967774, 14.568749427795467],
        [74.3373641967774, 14.568195343017578],
        [74.33708190917963, 14.568195343017578],
        [74.33708190917963, 14.567639350891113],
        [74.33680725097679, 14.567639350891113],
        [74.33680725097679, 14.567081451416072],
        [74.33652496337908, 14.567083358764648],
        [74.33652496337908, 14.566527366638184],
        [74.33625030517584, 14.56652927398676],
        [74.33625030517584, 14.565971374511719],
        [74.33597564697283, 14.565973281860295],
        [74.33597564697283, 14.565417289734],
        [74.33569335937528, 14.565419197082576],
        [74.33569335937528, 14.564861297607536],
        [74.33541870117188, 14.564861297607536],
        [74.33541870117188, 14.563751220703182],
        [74.3351364135745, 14.563751220703182],
        [74.3351364135745, 14.562641143798828],
        [74.33541870117188, 14.562641143798828],
        [74.33541870117188, 14.562080383300895],
        [74.33569335937528, 14.562083244323787],
        [74.33569335937528, 14.561804771423397],
        [74.33598327636719, 14.561812400817928],
        [74.33598327636719, 14.561250686645508],
        [74.33625030517584, 14.561250686645508],
        [74.33625030517584, 14.560689926147575],
        [74.33652496337908, 14.560694694519043],
        [74.33652496337908, 14.560417175292969],
        [74.33680725097679, 14.560420989990291],
        [74.33680725097679, 14.559861183166447],
        [74.33708190917963, 14.559861183166447],
        [74.33708190917963, 14.559582710266113],
        [74.3373641967774, 14.559582710266113],
        [74.3373641967774, 14.559028625488395],
        [74.33763885498058, 14.559030532836971],
        [74.33763885498058, 14.558469772338867],
        [74.33791351318365, 14.55847263336193],
        [74.33791351318365, 14.558195114135799],
        [74.33819580078153, 14.558195114135799],
        [74.33819580078153, 14.557638168335075],
        [74.33847045898438, 14.557641029357967],
        [74.33847045898438, 14.556801795959416],
        [74.33875274658232, 14.556806564331112],
        [74.33875274658232, 14.555416107177734],
        [74.33902740478533, 14.55542087554943],
        [74.33902740478533, 14.554579734802303],
        [74.33875274658232, 14.554583549499512],
        [74.33875274658232, 14.554027557373047],
        [74.33847045898438, 14.55403137207037],
        [74.33847045898438, 14.553762435913143],
        [74.33819580078153, 14.553762435913143],
        [74.33819580078153, 14.55346965789795],
        [74.33847045898438, 14.553471565246525],
        [74.33847045898438, 14.553194046020451],
        [74.33875274658232, 14.553194046020451],
        [74.33875274658232, 14.55291557312006],
        [74.33930206298834, 14.552921295166016],
        [74.33930206298834, 14.552639961242733],
        [74.33957672119158, 14.552639961242733],
        [74.33957672119158, 14.552359580993596],
        [74.34014129638689, 14.552362442016658],
        [74.34014129638689, 14.552082061767692],
        [74.3404159545899, 14.552082061767692],
        [74.3404159545899, 14.551806449890194],
        [74.34097290039068, 14.551811218261662],
        [74.34097290039068, 14.551532745361271],
        [74.34124755859403, 14.551532745361271],
        [74.34124755859403, 14.551250457763729],
        [74.3415298461914, 14.551250457763729],
        [74.3415298461914, 14.550971984863338],
        [74.34180450439482, 14.550971984863338],
        [74.34180450439482, 14.550415992736873],
        [74.34208679199219, 14.550419807434025],
        [74.34208679199219, 14.550140380859375],
        [74.3423614501956, 14.550140380859375],
        [74.3423614501956, 14.549861907958984],
        [74.34263610839861, 14.549861907958984],
        [74.34263610839861, 14.549578666687125],
        [74.34291839599615, 14.54958438873291],
        [74.34291839599615, 14.54930591583252],
        [74.34319305419939, 14.549309730529899],
        [74.34319305419939, 14.548749923706055],
        [74.34348297119163, 14.548749923706055],
        [74.34348297119163, 14.548468589782772],
        [74.34375, 14.54847240447998],
        [74.34375, 14.548195838928336],
        [74.34402465820318, 14.548195838928336],
        [74.34402465820318, 14.547639846801871],
        [74.3443069458009, 14.547639846801871],
        [74.3443069458009, 14.547362327575797],
        [74.3445816040039, 14.547362327575797],
        [74.3445816040039, 14.547083854675407],
        [74.34486389160185, 14.547083854675407],
        [74.34486389160185, 14.546527862548942],
        [74.34513854980469, 14.546529769897518],
        [74.34513854980469, 14.54625034332281],
        [74.3454132080081, 14.54625034332281],
        [74.3454132080081, 14.545971870422477],
        [74.34569549560564, 14.545971870422477],
        [74.34569549560564, 14.545417785644588],
        [74.34625244140642, 14.545419692993164],
        [74.34625244140642, 14.546810150146484],
        [74.3465270996096, 14.546806335449332],
        [74.3465270996096, 14.547083854675407],
        [74.34680175781244, 14.547083854675407],
        [74.34680938720697, 14.547362327575797],
        [74.3473587036134, 14.547362327575797],
        [74.3473587036134, 14.547639846801871],
        [74.34791564941435, 14.547639846801871],
        [74.34791564941435, 14.546527862548942],
        [74.34819793701172, 14.546529769897518],
        [74.34819793701172, 14.54625034332281],
        [74.34707641601568, 14.54625034332281],
        [74.34707641601568, 14.544861793518123],
        [74.3473587036134, 14.544861793518123],
        [74.3473587036134, 14.544030189514103],
        [74.34986114501947, 14.544030189514103],
        [74.34986114501947, 14.543471336364746],
        [74.35013580322271, 14.543471336364746],
        [74.35013580322271, 14.542361259460392],
        [74.35041809082043, 14.542361259460392],
        [74.35041809082043, 14.541805267333928],
        [74.35098266601574, 14.541820526123047],
        [74.35098266601574, 14.5415296554566],
        [74.3515167236331, 14.5415296554566],
        [74.3515167236331, 14.541249275207463],
        [74.35208129882818, 14.541249275207463],
        [74.35208129882818, 14.540968894958496],
        [74.35263824462913, 14.540970802307072],
        [74.35263824462913, 14.540695190429744],
        [74.35236358642595, 14.540695190429744],
        [74.35236358642595, 14.54013919830328],
        [74.35208129882818, 14.54013919830328],
        [74.35208129882818, 14.539860725402889],
        [74.35180664062517, 14.539860725402889],
        [74.35180664062517, 14.53931045532238],
        [74.3515167236331, 14.53931045532238],
        [74.3515167236331, 14.5387601852417],
        [74.35180664062517, 14.5387601852417],
        [74.35180664062517, 14.537921905517635],
        [74.35208129882818, 14.537921905517635],
        [74.35208129882818, 14.537640571594181],
        [74.35180664062517, 14.537640571594181],
        [74.35180664062517, 14.537361145019531],
        [74.35152435302763, 14.537361145019531],
        [74.35152435302763, 14.536251068115348],
        [74.35124969482422, 14.536251068115348],
        [74.35124969482422, 14.533471107482967],
        [74.35152435302763, 14.5334730148316],
        [74.35152435302763, 14.532917022705135],
        [74.35180664062517, 14.532920837402457],
        [74.35180664062517, 14.532079696655387],
        [74.35236358642595, 14.53208255767828],
        [74.35236358642595, 14.531525611877498],
        [74.35291290283197, 14.531531333923454],
        [74.35291290283197, 14.53125095367426],
        [74.35320281982445, 14.53125095367426],
        [74.35320281982445, 14.530690193176326],
        [74.35346984863293, 14.530694961547795],
        [74.35346984863293, 14.53013801574707],
        [74.35375976562506, 14.530140876770133],
        [74.35375976562506, 14.529590606689396],
        [74.35402679443388, 14.529590606689396],
        [74.35402679443388, 14.52903079986578],
        [74.35430908203125, 14.52903079986578],
        [74.35430908203125, 14.528470039367619],
        [74.35458374023466, 14.528471946716422],
        [74.35458374023466, 14.528194427490291],
        [74.35485839843767, 14.528194427490291],
        [74.35485839843767, 14.527638435363826],
        [74.35514068603544, 14.527640342712402],
        [74.35514068603544, 14.52682018280035],
        [74.35542297363298, 14.52682018280035],
        [74.35542297363298, 14.526251792907715],
        [74.355697631836, 14.526251792907715],
        [74.355697631836, 14.525142669677791],
        [74.35597229003923, 14.525142669677791],
        [74.35597229003923, 14.524029731750545],
        [74.35624694824224, 14.524029731750545],
        [74.35624694824224, 14.522919654846191],
        [74.35597229003923, 14.522919654846191],
        [74.35597229003923, 14.522079467773438],
        [74.35541534423845, 14.522084236145076],
        [74.35541534423845, 14.521805763244686],
        [74.35457611084013, 14.521809577941838],
        [74.35457611084013, 14.521530151367188],
        [74.35402679443388, 14.521530151367188],
        [74.35402679443388, 14.521249771118221],
        [74.34986114501947, 14.521249771118221],
        [74.34986114501947, 14.521530151367188],
        [74.34958648681663, 14.521530151367188],
        [74.34958648681663, 14.521249771118221],
        [74.34929656982439, 14.521249771118221],
        [74.34929656982439, 14.520690917968864],
        [74.34902954101568, 14.520693778991756],
        [74.34902954101568, 14.520416259765625],
        [74.34513854980469, 14.520420074463004],
        [74.34513854980469, 14.520139694213867],
        [74.34485626220732, 14.520139694213867],
        [74.34485626220732, 14.51986026763916],
        [74.3445816040039, 14.519862174987793],
        [74.3445816040039, 14.519583702087402],
        [74.3443069458009, 14.519583702087402],
        [74.3443069458009, 14.518750190734806],
        [74.34401702880865, 14.518750190734806],
        [74.34401702880865, 14.517650604248047],
        [74.34375, 14.517650604248047],
        [74.34375, 14.51736164093029],
        [74.3434753417971, 14.51736164093029],
        [74.3434753417971, 14.517084121704158],
        [74.34319305419939, 14.517084121704158],
        [74.34319305419939, 14.516527175903434],
        [74.34291839599615, 14.516531944274902],
        [74.34291839599615, 14.515971183776912],
        [74.34263610839861, 14.515971183776912],
        [74.34263610839861, 14.51541709899908],
        [74.3423614501956, 14.51542186737072],
        [74.3423614501956, 14.514859199523983],
        [74.34208679199219, 14.514861106872559],
        [74.34208679199219, 14.514305114746094],
        [74.34179687500028, 14.514311790466365],
        [74.34179687500028, 14.514028549194336],
        [74.34124755859403, 14.514028549194336],
        [74.34124755859403, 14.513751029968205],
        [74.33985900878929, 14.513751029968205],
        [74.33985900878929, 14.514028549194336],
        [74.33957672119158, 14.514028549194336],
        [74.33957672119158, 14.514311790466365],
        [74.33930206298834, 14.514311790466365],
        [74.33930206298834, 14.514590263366756],
        [74.33902740478533, 14.514582633972225],
        [74.33902740478533, 14.514861106872559],
        [74.33876037597685, 14.514859199523983],
        [74.33876037597685, 14.51513862609869],
        [74.33847045898438, 14.51513862609869],
        [74.33847045898438, 14.51542186737072],
        [74.33819580078153, 14.51541709899908],
        [74.33819580078153, 14.515695571899414],
        [74.33791351318365, 14.515695571899414],
        [74.33791351318365, 14.517084121704158],
        [74.33819580078153, 14.517081260681096],
        [74.33819580078153, 14.51736164093029],
        [74.33763885498058, 14.51736164093029],
        [74.33763885498058, 14.517650604248047],
        [74.33735656738287, 14.517650604248047],
        [74.33735656738287, 14.51791954040533],
        [74.3331909179688, 14.51791954040533],
        [74.3331909179688, 14.51736164093029],
        [74.3329162597658, 14.51736164093029],
        [74.3329162597658, 14.517081260681096],
        [74.33235931396484, 14.517081260681096],
        [74.33235931396484, 14.51736164093029],
        [74.33180236816406, 14.51736164093029],
        [74.33180236816406, 14.517650604248047],
        [74.3301391601563, 14.517650604248047],
        [74.3301391601563, 14.516251564025936],
        [74.32785034179693, 14.516248703003043],
        [74.32763671875006, 14.516251564025936],
        [74.32763671875006, 14.515971183776912],
        [74.32736206054705, 14.515971183776912],
        [74.32736206054705, 14.515695571899414],
        [74.32707977294945, 14.515695571899414],
        [74.32707977294945, 14.51541709899908],
        [74.32682037353533, 14.51542186737072],
        [74.32682037353533, 14.51513862609869],
        [74.32653045654325, 14.51513862609869],
        [74.32653045654325, 14.514859199523983],
        [74.32624816894548, 14.514861106872559],
        [74.32624816894548, 14.514582633972225],
        [74.32597351074247, 14.514582633972225],
        [74.32597351074247, 14.514861106872559],
        [74.32569122314453, 14.514861106872559],
        [74.32569122314453, 14.516930580139103],
        [74.32569122314453, 14.517650604248047],
        [74.32541656494169, 14.517650604248047],
        [74.32541656494169, 14.518750190734806],
        [74.32514190673834, 14.518750190734806],
        [74.32514190673834, 14.519583702087402],
        [74.32291412353544, 14.519583702087402],
        [74.32291412353544, 14.51736164093029],
        [74.32180786132824, 14.51736164093029],
        [74.32180786132824, 14.51791954040533],
        [74.3220825195313, 14.51791954040533],
        [74.3220825195313, 14.518750190734806],
        [74.32180786132824, 14.518750190734806],
        [74.32180786132824, 14.51930999755865],
        [74.32152557373053, 14.519306182861271],
        [74.32152557373053, 14.519583702087402],
        [74.31874847412121, 14.519583702087402],
        [74.31874847412121, 14.519027709960938],
        [74.3184661865235, 14.519029617309684],
        [74.3184661865235, 14.518471717834416],
        [74.31874847412121, 14.518471717834416],
        [74.31874847412121, 14.518193244934082],
        [74.3190307617188, 14.518193244934082],
        [74.3190307617188, 14.517917633056754],
        [74.31931304931669, 14.51791954040533],
        [74.31931304931669, 14.517081260681096],
        [74.3190307617188, 14.517084121704158],
        [74.3190307617188, 14.516248703003043],
        [74.31874847412121, 14.516251564025936],
        [74.31874847412121, 14.516810417175293],
        [74.3184661865235, 14.516810417175293],
        [74.3184661865235, 14.517650604248047],
        [74.31819152832026, 14.517640113830623],
        [74.31819152832026, 14.51847362518322],
        [74.31791687011741, 14.518471717834416],
        [74.31791687011741, 14.519029617309684],
        [74.31624603271513, 14.519029617309684],
        [74.31624603271513, 14.51930999755865],
        [74.31597137451172, 14.519306182861271],
        [74.31597137451172, 14.519583702087402],
        [74.31569671630871, 14.519583702087402],
        [74.31569671630871, 14.519862174987793],
        [74.31540679931646, 14.51986026763916],
        [74.31540679931646, 14.520139694213867],
        [74.31513977050776, 14.520139694213867],
        [74.31513977050776, 14.520420074463004],
        [74.31485748291021, 14.520416259765625],
        [74.31485748291021, 14.520690917968864],
        [74.31430816650396, 14.520693778991756],
        [74.31430816650396, 14.520972251892147],
        [74.31402587890642, 14.520969390869254],
        [74.31402587890642, 14.521249771118221],
        [74.3120803833009, 14.521249771118221],
        [74.3120803833009, 14.520969390869254],
        [74.31041717529314, 14.520972251892147],
        [74.31041717529314, 14.520693778991756],
        [74.30959320068371, 14.520690917968864],
        [74.30959320068371, 14.521249771118221],
        [74.30986022949219, 14.521249771118221],
        [74.30986022949219, 14.522084236145076],
        [74.31014251709013, 14.522079467773438],
        [74.31014251709013, 14.522640228271541],
        [74.31041717529314, 14.522640228271541],
        [74.31041717529314, 14.52319431304943],
        [74.31014251709013, 14.523192405700627],
        [74.31014251709013, 14.523750305175895],
        [74.31041717529314, 14.523750305175895],
        [74.31041717529314, 14.524029731750545],
        [74.31069183349615, 14.52402782440197],
        [74.31069183349615, 14.524581909179688],
        [74.31097412109392, 14.524581909179688],
        [74.31097412109392, 14.524862289428825],
        [74.31151580810541, 14.524860382080078],
        [74.31151580810541, 14.525142669677791],
        [74.3123626708985, 14.525142669677791],
        [74.3123626708985, 14.525421142578182],
        [74.31291961669922, 14.525416374206543],
        [74.31291961669922, 14.525693893432617],
        [74.3145828247072, 14.525693893432617],
        [74.3145828247072, 14.525972366333008],
        [74.31485748291021, 14.525970458984432],
        [74.31485748291021, 14.526251792907715],
        [74.31513977050776, 14.526251792907715],
        [74.31513977050776, 14.52682018280035],
        [74.31540679931646, 14.52682018280035],
        [74.31540679931646, 14.527359962463436],
        [74.31569671630871, 14.527359962463436],
        [74.31569671630871, 14.528750419616756],
        [74.315414428711, 14.528750419616756],
        [74.31539916992188, 14.530401229858342],
        [74.31485748291021, 14.530415534973145],
        [74.31485748291021, 14.530694961547795],
        [74.31430816650396, 14.530694961547795],
        [74.31430816650396, 14.530973434448185],
        [74.3131866455081, 14.530969619751033],
        [74.3131866455081, 14.53125095367426],
        [74.3123626708985, 14.53125095367426],
        [74.3123626708985, 14.531531333923454],
        [74.31181335449241, 14.531531333923454],
        [74.31181335449241, 14.531810760498104],
        [74.3115310668947, 14.531806945800781],
        [74.3115310668947, 14.53208255767828],
        [74.31097412109392, 14.53208255767828],
        [74.31097412109392, 14.5334730148316],
        [74.31069183349615, 14.533471107482967],
        [74.31069183349615, 14.536251068115348],
        [74.31041717529314, 14.536251068115348],
        [74.31041717529314, 14.537640571594181],
        [74.31014251709013, 14.537638664245605],
        [74.31014251709013, 14.53819465637207],
        [74.30986022949219, 14.538192749023494],
        [74.30986022949219, 14.5387601852417],
        [74.31014251709013, 14.5387601852417],
        [74.31014251709013, 14.539031982421818],
        [74.31041717529314, 14.539031982421818],
        [74.31041717529314, 14.53931045532238],
        [74.31069183349615, 14.539304733276424],
        [74.31069183349615, 14.539583206176815],
        [74.3112487792971, 14.539581298828239],
        [74.3112487792971, 14.539860725402889],
        [74.31181335449241, 14.539860725402889],
        [74.31181335449241, 14.54013919830328],
        [74.31375122070341, 14.54013919830328],
        [74.31375122070341, 14.540419578552246],
        [74.31402587890642, 14.54041671752941],
        [74.31402587890642, 14.542361259460392],
        [74.31375122070341, 14.542361259460392],
        [74.31375122070341, 14.544584274291992],
        [74.31346893310564, 14.544569969177246],
        [74.31346893310564, 14.545419692993164],
        [74.3131866455081, 14.545419692993164],
        [74.3131866455081, 14.54625034332281],
        [74.31291961669922, 14.54625034332281],
        [74.31291961669922, 14.547083854675407],
        [74.31263732910185, 14.547083854675407],
        [74.31263732910185, 14.547639846801871],
        [74.3123626708985, 14.547639846801871],
        [74.3123626708985, 14.54847240447998],
        [74.3120803833009, 14.548468589782772],
        [74.3120803833009, 14.549309730529899],
        [74.31181335449241, 14.549309730529899],
        [74.31181335449241, 14.550140380859375],
        [74.3115310668947, 14.550138473510799],
        [74.3115310668947, 14.550971984863338],
        [74.3112487792971, 14.550971984863338],
        [74.3112487792971, 14.551811218261662],
        [74.31097412109392, 14.551806449890194],
        [74.31097412109392, 14.552362442016658],
        [74.31069183349615, 14.552359580993596],
        [74.31069183349615, 14.552921295166016],
        [74.31041717529314, 14.55291557312006],
        [74.31041717529314, 14.553471565246525],
        [74.31014251709013, 14.55346965789795],
        [74.31014251709013, 14.55403137207037],
        [74.30986022949219, 14.554027557373047],
        [74.30986022949219, 14.554583549499512],
        [74.30959320068371, 14.554579734802303],
        [74.30959320068371, 14.555141448974723],
        [74.30930328369146, 14.5551376342774],
        [74.30930328369146, 14.555972099304256],
        [74.3090286254884, 14.555970191955623],
        [74.3090286254884, 14.556530952453613],
        [74.30874633789068, 14.55652904510498],
        [74.30874633789068, 14.55708217620861],
        [74.30845642089855, 14.557080268859806],
        [74.30845642089855, 14.557641029357967],
        [74.3081970214846, 14.557638168335075],
        [74.3081970214846, 14.558195114135799],
        [74.30790710449236, 14.55819034576416],
        [74.30790710449236, 14.55875110626232],
        [74.30764007568365, 14.55875110626232],
        [74.30764007568365, 14.559310913085938],
        [74.30735778808611, 14.559310913085938],
        [74.30735778808611, 14.560140609741325],
        [74.3070831298831, 14.560138702392578],
        [74.3070831298831, 14.560694694519043],
        [74.30680847167969, 14.560689926147575],
        [74.30680847167969, 14.561250686645508],
        [74.30652618408232, 14.561250686645508],
        [74.30652618408232, 14.561812400817928],
        [74.30625152587896, 14.561812400817928],
        [74.30625152587896, 14.562641143798828],
        [74.30596923828136, 14.562639236450252],
        [74.30596923828136, 14.563195228576717],
        [74.30568695068365, 14.563190460205078],
        [74.30568695068365, 14.563751220703182],
        [74.30541992187494, 14.563751220703182],
        [74.30541992187494, 14.564311981201115],
        [74.30513763427757, 14.564311981201115],
        [74.30513763427757, 14.565140724182243],
        [74.30486297607439, 14.565140724182243],
        [74.30486297607439, 14.5656995773316],
        [74.30458068847662, 14.5656995773316],
        [74.30458068847662, 14.566250801086369],
        [74.30430603027361, 14.566250801086369],
        [74.30430603027361, 14.566809654235954],
        [74.3040313720706, 14.566809654235954],
        [74.3040313720706, 14.567360877990723],
        [74.30374908447266, 14.567360877990723],
        [74.30374908447266, 14.568195343017578],
        [74.30347442626982, 14.568195343017578],
        [74.30347442626982, 14.568749427795467],
        [74.30319213867188, 14.568741798400936],
        [74.30319213867188, 14.569310188293457],
        [74.30291748046886, 14.569305419921932],
        [74.30291748046886, 14.569861412048454],
        [74.30263519287115, 14.569861412048454],
        [74.30265045166021, 14.570691108703613],
        [74.30236053466791, 14.570699691772461],
        [74.30236053466791, 14.571251869201774],
        [74.30208587646507, 14.571249008178711],
        [74.30208587646507, 14.572084426879826],
        [74.3017959594726, 14.572080612182674],
        [74.3017959594726, 14.572361946105957],
        [74.30152893066412, 14.572361946105957],
        [74.30152893066412, 14.57347202301031],
        [74.30124664306658, 14.57347202301031],
        [74.30124664306658, 14.573749542236385],
        [74.30097198486357, 14.573749542236385],
        [74.30097198486357, 14.574310302734489],
        [74.30069732666016, 14.574310302734489],
        [74.30069732666016, 14.574871063232422],
        [74.30041503906278, 14.574861526489315],
        [74.30041503906278, 14.577083587646428],
        [74.30014038085938, 14.577081680297852],
        [74.30014038085938, 14.577639579772892],
        [74.299858093262, 14.577637672424316],
        [74.299858093262, 14.578193664550781],
        [74.29957580566412, 14.578188896179313],
        [74.29957580566412, 14.578749656677246],
        [74.29930877685541, 14.578749656677246],
        [74.29930877685541, 14.579584121704102],
        [74.29902648925787, 14.57958030700695],
        [74.29902648925787, 14.580140113830566],
        [74.29875183105486, 14.58013820648199],
        [74.29875183105486, 14.580694198608455],
        [74.29846954345726, 14.580680847167969],
        [74.29846954345726, 14.58125019073492],
        [74.29818725585955, 14.58125019073492],
        [74.29818725585955, 14.58181095123291],
        [74.29792022705107, 14.581804275512638],
        [74.29792022705107, 14.582360267639103],
        [74.2976379394533, 14.582360267639103],
        [74.2976379394533, 14.582921028137264],
        [74.29736328125028, 14.582916259765568],
        [74.29736328125028, 14.58347225189209],
        [74.29708099365234, 14.583470344543457],
        [74.29708099365234, 14.584031105041618],
        [74.2968063354495, 14.584029197692814],
        [74.2968063354495, 14.584582328796444],
        [74.29651641845703, 14.58458042144781],
        [74.29651641845703, 14.5851411819458],
        [74.29624938964855, 14.585139274597168],
        [74.29624938964855, 14.585695266723633],
        [74.29596710205084, 14.585689544677734],
        [74.29596710205084, 14.586251258850154],
        [74.2956924438476, 14.586251258850154],
        [74.2956924438476, 14.586811065673771],
        [74.29541778564476, 14.586805343627987],
        [74.29541778564476, 14.587361335754508],
        [74.29514312744158, 14.587361335754508],
        [74.29514312744158, 14.587919235229549],
        [74.2948608398438, 14.587917327880973],
        [74.2948608398438, 14.588473320007438],
        [74.2945861816408, 14.588471412658635],
        [74.2945861816408, 14.589031219482479],
        [74.29429626464872, 14.589031219482479],
        [74.29429626464872, 14.589870452880803],
        [74.29402923583984, 14.589870452880803],
        [74.29402923583984, 14.590421676635799],
        [74.29374694824247, 14.590421676635799],
        [74.29374694824247, 14.591251373291016],
        [74.29347991943365, 14.591251373291016],
        [74.29347991943365, 14.591810226440543],
        [74.29319763183605, 14.591805458068904],
        [74.29319763183605, 14.59236145019537],
        [74.29292297363287, 14.59236145019537],
        [74.29292297363287, 14.59293079376232],
        [74.29235839843773, 14.592917442321834],
        [74.29235839843773, 14.593473434448299],
        [74.29207611084001, 14.593470573425236],
        [74.29207611084001, 14.594029426574764],
        [74.2918090820313, 14.594026565551701],
        [74.2918090820313, 14.594582557678223],
        [74.29152679443376, 14.59458065032959],
        [74.29152679443376, 14.594861030578556],
        [74.29125213623075, 14.594861030578556],
        [74.29125213623075, 14.595421791076717],
        [74.29096984863281, 14.595417022705078],
        [74.29096984863281, 14.59597110748291],
        [74.29069519042997, 14.59597110748291],
        [74.29069519042997, 14.597083091735897],
        [74.29096984863281, 14.597081184387264],
        [74.29096984863281, 14.597920417785645],
        [74.29292297363287, 14.597920417785645],
        [74.29292297363287, 14.597641944885254],
        [74.29347991943365, 14.597641944885254],
        [74.29347991943365, 14.597361564636287],
        [74.29402923583984, 14.597361564636287],
        [74.29402923583984, 14.597081184387264],
        [74.2948608398438, 14.597083091735897],
        [74.2948608398438, 14.597361564636287],
        [74.2945861816408, 14.597361564636287],
        [74.2945861816408, 14.60060024261486],
        [74.2945861816408, 14.601530075073356],
        [74.29319763183605, 14.601530075073356],
        [74.29319763183605, 14.601810455322322],
        [74.2918090820313, 14.601805686950684],
        [74.2918090820313, 14.602084159851074],
        [74.29125213623075, 14.602079391479606],
        [74.29125213623075, 14.602359771728572],
        [74.29096984863281, 14.602359771728572],
        [74.29096984863281, 14.602640151977539],
        [74.29042053222662, 14.602640151977539],
        [74.29042053222662, 14.60291957855219],
        [74.28986358642584, 14.602915763855037],
        [74.28986358642584, 14.603194236755428],
        [74.28958129882807, 14.603194236755428],
        [74.28958129882807, 14.603471755981502],
        [74.28901672363276, 14.603469848632926],
        [74.28902435302751, 14.603750228881893],
        [74.2884826660158, 14.603750228881893],
        [74.2884826660158, 14.604029655456543],
        [74.28819274902372, 14.604029655456543],
        [74.28819274902372, 14.604310989379826],
        [74.28763580322294, 14.604306221008358],
        [74.28763580322294, 14.604583740234432],
        [74.28736114501953, 14.604581832885856],
        [74.28736114501953, 14.60487079620367],
        [74.28679656982428, 14.60487079620367],
        [74.28679656982428, 14.605139732360897],
        [74.28652954101557, 14.605139732360897],
        [74.28652954101557, 14.60542106628418],
        [74.28624725341803, 14.605416297912711],
        [74.28624725341803, 14.605693817138615],
        [74.28569793701178, 14.605693817138615],
        [74.28569793701178, 14.605972290039176],
        [74.28542327880876, 14.60597038269043],
        [74.28542327880876, 14.606251716613883],
        [74.28485870361345, 14.606251716613883],
        [74.28485870361345, 14.606530189514274],
        [74.28457641601591, 14.606530189514274],
        [74.28457641601591, 14.60681056976324],
        [74.28430938720703, 14.606805801391545],
        [74.2843017578125, 14.607084274291935],
        [74.2837524414063, 14.607080459594783],
        [74.2837524414063, 14.607361793518066],
        [74.28347015380871, 14.607361793518066],
        [74.28347015380871, 14.6076402664184],
        [74.28319549560553, 14.6076402664184],
        [74.28319549560553, 14.607915878295898],
        [74.28263854980491, 14.607915878295898],
        [74.28263854980491, 14.608194351196289],
        [74.28236389160173, 14.608194351196289],
        [74.28236389160173, 14.60847187042242],
        [74.28208160400396, 14.608469963073787],
        [74.28208160400396, 14.608750343322754],
        [74.28180694580095, 14.608750343322754],
        [74.28180694580095, 14.609030723571777],
        [74.28151702880888, 14.609030723571777],
        [74.28151702880888, 14.609311103820914],
        [74.28097534179716, 14.609306335449276],
        [74.28097534179716, 14.609581947326774],
        [74.28069305419928, 14.609581947326774],
        [74.28069305419928, 14.609860420227108],
        [74.28041839599621, 14.609860420227108],
        [74.28041839599621, 14.610140800476131],
        [74.2801361083985, 14.610140800476131],
        [74.2801361083985, 14.610421180725098],
        [74.27986145019526, 14.61041641235363],
        [74.27986145019526, 14.610973358154354],
        [74.27958679199241, 14.610969543457031],
        [74.27958679199241, 14.611810684204102],
        [74.27929687500017, 14.611810684204102],
        [74.27929687500017, 14.612920761108455],
        [74.27902984619146, 14.612920761108455],
        [74.27902984619146, 14.613751411437988],
        [74.27874755859392, 14.613751411437988],
        [74.27874755859392, 14.616811752319279],
        [74.27847290039091, 14.61680698394781],
        [74.27848052978544, 14.6173610687257],
        [74.2781982421875, 14.6173610687257],
        [74.2781982421875, 14.617921829223633],
        [74.27791595459013, 14.617921829223633],
        [74.27791595459013, 14.618750572204533],
        [74.27764129638678, 14.618750572204533],
        [74.27764129638678, 14.619310379028377],
        [74.27735900878918, 14.619310379028377],
        [74.27735900878918, 14.62014102935791],
        [74.277084350586, 14.620139122009277],
        [74.277084350586, 14.620693206787166],
        [74.27680206298822, 14.62069129943859],
        [74.27680206298822, 14.621249198913631],
        [74.2762527465822, 14.621249198913631],
        [74.2762527465822, 14.621528625488338],
        [74.27597045898443, 14.621526718139762],
        [74.27597045898443, 14.622361183166618],
        [74.27569580078142, 14.622361183166618],
        [74.27569580078142, 14.622915267944279],
        [74.27542114257841, 14.622911453247127],
        [74.27542114257841, 14.623751640319938],
        [74.27513885498047, 14.623751640319938],
        [74.27513885498047, 14.624032020568904],
        [74.2748565673831, 14.624032020568904],
        [74.2748565673831, 14.624309539794979],
        [74.27458190917969, 14.62430477142334],
        [74.27458190917969, 14.62458419799799],
        [74.27401733398443, 14.624581336975098],
        [74.27401733398443, 14.624861717224121],
        [74.27375030517572, 14.624861717224121],
        [74.27375030517572, 14.626529693603516],
        [74.27347564697288, 14.62652778625494],
        [74.27347564697288, 14.627083778381404],
        [74.27263641357439, 14.627083778381404],
        [74.27263641357439, 14.62680625915533],
        [74.27179718017607, 14.626810073852653],
        [74.27179718017607, 14.626529693603516],
        [74.27153015136719, 14.626529693603516],
        [74.27153015136719, 14.626250267028809],
        [74.27041625976568, 14.626250267028809],
        [74.27041625976568, 14.626529693603516],
        [74.26985931396507, 14.626529693603516],
        [74.26985931396507, 14.626810073852653],
        [74.26957702636736, 14.626810073852653],
        [74.26957702636736, 14.628749847412223],
        [74.26985931396507, 14.628749847412223],
        [74.26985931396507, 14.62904167175293],
        [74.27014160156267, 14.629028320312614],
        [74.27014160156267, 14.629584312439079],
        [74.27069091796886, 14.629578590393123],
        [74.27069091796886, 14.630140304565543],
        [74.27041625976568, 14.630140304565543],
        [74.27041625976568, 14.630694389343262],
        [74.27069091796886, 14.630694389343262],
        [74.27069091796886, 14.630971908569336],
        [74.27208709716797, 14.630971908569336],
        [74.27208709716797, 14.630415916442871],
        [74.27234649658209, 14.63041973114025],
        [74.27234649658209, 14.630140304565543],
        [74.27291870117193, 14.630140304565543],
        [74.27291870117193, 14.629861831665153],
        [74.27319335937517, 14.629861831665153],
        [74.27319335937517, 14.629578590393123],
        [74.27347564697288, 14.629584312439079],
        [74.27347564697288, 14.629305839538688],
        [74.27375030517572, 14.629309654235897],
        [74.27375030517572, 14.62904167175293],
        [74.27430725097668, 14.62904167175293],
        [74.27430725097668, 14.628749847412223],
        [74.27542114257841, 14.628749847412223],
        [74.27541351318388, 14.628472328186149],
        [74.2762527465822, 14.628472328186149],
        [74.2762527465822, 14.628195762634334],
        [74.27652740478538, 14.628195762634334],
        [74.27652740478538, 14.627916336059684],
        [74.27707672119146, 14.627920150756836],
        [74.27707672119146, 14.62763977050787],
        [74.27791595459013, 14.62763977050787],
        [74.27791595459013, 14.627359390258903],
        [74.27847290039091, 14.627362251281795],
        [74.27847290039091, 14.627083778381404],
        [74.27902984619146, 14.627083778381404],
        [74.27902984619146, 14.62680625915533],
        [74.2801361083985, 14.62680625915533],
        [74.2801361083985, 14.627083778381404],
        [74.28125, 14.627083778381404],
        [74.28125, 14.627362251281795],
        [74.28151702880888, 14.627359390258903],
        [74.28151702880888, 14.627920150756836],
        [74.28180694580095, 14.627916336059684],
        [74.28180694580095, 14.628472328186149],
        [74.28208160400396, 14.628469467163086],
        [74.28208160400396, 14.62904167175293],
        [74.28236389160173, 14.629028320312614],
        [74.28236389160173, 14.629584312439079],
        [74.28263854980491, 14.629578590393123],
        [74.28263854980491, 14.630140304565543],
        [74.28291320800776, 14.630140304565543],
        [74.28291320800776, 14.63041973114025],
        [74.28319549560553, 14.630415916442871],
        [74.28319549560553, 14.630694389343262],
        [74.2837524414063, 14.630694389343262],
        [74.2837524414063, 14.630971908569336],
        [74.28402709960966, 14.630971908569336],
        [74.28402709960966, 14.634031295776367],
        [74.2843017578125, 14.634028434753475],
        [74.28430938720703, 14.635972023010254],
        [74.28458404541044, 14.635972023010254],
        [74.28458404541044, 14.638195037841797],
        [74.28485870361345, 14.638190269470158],
        [74.28485870361345, 14.640140533447322],
        [74.28457641601591, 14.640140533447322],
        [74.28457641601591, 14.64236068725586],
        [74.28430938720703, 14.64236068725586],
        [74.28428649902361, 14.644311904907283],
        [74.28402709960966, 14.644311904907283],
        [74.28402709960966, 14.645421981811467],
        [74.2837524414063, 14.645417213439998],
        [74.2837524414063, 14.645973205566463],
        [74.28347015380871, 14.645971298217887],
        [74.28347015380871, 14.646529197692928],
        [74.28320312500023, 14.646529197692928],
        [74.28320312500023, 14.646809577941951],
        [74.28263854980491, 14.646804809570256],
        [74.28263854980491, 14.64736080169672],
        [74.28236389160173, 14.64736080169672],
        [74.28236389160173, 14.648470878601074],
        [74.28208160400396, 14.648470878601074],
        [74.28208160400396, 14.649031639099235],
        [74.27902984619146, 14.649026870727596],
        [74.27902984619146, 14.64958286285406],
        [74.2793045043947, 14.64958286285406],
        [74.2793045043947, 14.649861335754451],
        [74.27986145019526, 14.649861335754451],
        [74.27986145019526, 14.650151252746696],
        [74.2801361083985, 14.650151252746696],
        [74.2801361083985, 14.650420188903809],
        [74.28069305419928, 14.650415420532283],
        [74.28069305419928, 14.650695800781307],
        [74.28097534179716, 14.650695800781307],
        [74.28097534179716, 14.650971412658805],
        [74.28125, 14.650971412658805],
        [74.28125, 14.651251792907772],
        [74.28151702880888, 14.651251792907772],
        [74.28151702880888, 14.651530265808162],
        [74.28180694580095, 14.65152740478527],
        [74.28180694580095, 14.652084350585994],
        [74.28208160400396, 14.652080535888672],
        [74.28208160400396, 14.652361869812125],
        [74.2823562622072, 14.652361869812125],
        [74.2823562622072, 14.652640342712516],
        [74.28208160400396, 14.652640342712516],
        [74.28208160400396, 14.653194427490178],
        [74.28180694580095, 14.653194427490178],
        [74.28180694580095, 14.653471946716309],
        [74.28151702880888, 14.653471946716309],
        [74.28151702880888, 14.65402984619152],
        [74.28125, 14.654027938842773],
        [74.28125, 14.654583930969238],
        [74.28097534179716, 14.654583930969238],
        [74.28097534179716, 14.654862403869629],
        [74.28069305419928, 14.654859542846737],
        [74.28069305419928, 14.65542030334484],
        [74.28041839599621, 14.655415534973201],
        [74.28041839599621, 14.655694007873592],
        [74.2801361083985, 14.655694007873592],
        [74.2801361083985, 14.655971527099666],
        [74.27986145019526, 14.655960083007756],
        [74.27986145019526, 14.65625],
        [74.27958679199241, 14.65625],
        [74.27958679199241, 14.656530380249023],
        [74.27902984619146, 14.656530380249023],
        [74.27902984619146, 14.65625],
        [74.27874755859392, 14.65625],
        [74.27874755859392, 14.65680980682373],
        [74.27848052978544, 14.65680980682373],
        [74.27848052978544, 14.657640457153377],
        [74.2781982421875, 14.657640457153377],
        [74.2781982421875, 14.65792274475092],
        [74.27791595459013, 14.657917976379451],
        [74.27791595459013, 14.658193588256779],
        [74.2781982421875, 14.658191680908203],
        [74.2781982421875, 14.658749580383244],
        [74.27848052978544, 14.658749580383244],
        [74.27848052978544, 14.659311294555664],
        [74.27874755859392, 14.659311294555664],
        [74.27874755859392, 14.659859657287598],
        [74.27902984619146, 14.659859657287598],
        [74.27902984619146, 14.660421371460018],
        [74.27958679199241, 14.660415649414062],
        [74.27958679199241, 14.660694122314453],
        [74.2801361083985, 14.660694122314453],
        [74.2801361083985, 14.660971641540584],
        [74.28125, 14.660969734191951],
        [74.28125, 14.661250114440918],
        [74.28151702880888, 14.661250114440918],
        [74.28151702880888, 14.661810874939079],
        [74.28180694580095, 14.66183090209961],
        [74.28180694580095, 14.662091255188045],
        [74.28208160400396, 14.662091255188045],
        [74.28208160400396, 14.662640571594295],
        [74.2823562622072, 14.662640571594295],
        [74.2823562622072, 14.662920951843262],
        [74.28291320800776, 14.662916183471793],
        [74.28291320800776, 14.663472175598258],
        [74.28263854980491, 14.663470268249455],
        [74.28263854980491, 14.663749694824332],
        [74.28098297119152, 14.663749694824332],
        [74.28098297119152, 14.663191795349064],
        [74.2808609008789, 14.663191795349064],
        [74.28069305419928, 14.663193702697868],
        [74.28069305419928, 14.662916183471793],
        [74.28041839599621, 14.662920951843262],
        [74.28041839599621, 14.662640571594295],
        [74.2801361083985, 14.662640571594295],
        [74.2801361083985, 14.662091255188045],
        [74.27986145019526, 14.662091255188045],
        [74.27986145019526, 14.661810874939079],
        [74.27958679199241, 14.661810874939079],
        [74.27958679199241, 14.661531448364201],
        [74.27902984619146, 14.661531448364201],
        [74.27902984619146, 14.661250114440918],
        [74.27848052978544, 14.661250114440918],
        [74.27848052978544, 14.660969734191951],
        [74.27791595459013, 14.660971641540584],
        [74.27791595459013, 14.660694122314453],
        [74.27735900878918, 14.660694122314453],
        [74.27735900878918, 14.660415649414062],
        [74.27707672119146, 14.660421371460018],
        [74.27707672119146, 14.660140037536564],
        [74.27652740478538, 14.660140037536564],
        [74.27652740478538, 14.659859657287598],
        [74.2762527465822, 14.659859657287598],
        [74.2762527465822, 14.660140037536564],
        [74.27569580078142, 14.660140037536564],
        [74.27569580078142, 14.660421371460018],
        [74.27541351318388, 14.660415649414062],
        [74.27541351318388, 14.660694122314453],
        [74.27569580078142, 14.660694122314453],
        [74.27569580078142, 14.660971641540584],
        [74.2762527465822, 14.660969734191951],
        [74.2762527465822, 14.661250114440918],
        [74.27652740478538, 14.661250114440918],
        [74.27652740478538, 14.661531448364201],
        [74.27680206298822, 14.661531448364201],
        [74.27680206298822, 14.661810874939079],
        [74.27848052978544, 14.661810874939079],
        [74.27848052978544, 14.662091255188045],
        [74.27902984619146, 14.662091255188045],
        [74.27902984619146, 14.662640571594295],
        [74.27874755859392, 14.662640571594295],
        [74.27874755859392, 14.663749694824332],
        [74.27848052978544, 14.663749694824332],
        [74.27848052978544, 14.664311408996582],
        [74.2781982421875, 14.664311408996582],
        [74.2781982421875, 14.664859771728516],
        [74.27791595459013, 14.664859771728516],
        [74.27791595459013, 14.66542053222662],
        [74.27764129638678, 14.665416717529297],
        [74.27764129638678, 14.66569519042963],
        [74.277084350586, 14.66569519042963],
        [74.277084350586, 14.665972709655762],
        [74.27541351318388, 14.665972709655762],
        [74.27541351318388, 14.666251182556152],
        [74.27569580078142, 14.666251182556152],
        [74.27569580078142, 14.66681098937994],
        [74.27597045898443, 14.666805267333984],
        [74.27597045898443, 14.667084693908691],
        [74.2762527465822, 14.667084693908691],
        [74.2762527465822, 14.667361259460506],
        [74.27652740478538, 14.667361259460506],
        [74.27652740478538, 14.667640686035156],
        [74.27680206298822, 14.667640686035156],
        [74.27680206298822, 14.667921066284123],
        [74.277084350586, 14.667917251586971],
        [74.277084350586, 14.668473243713436],
        [74.27735900878918, 14.66847133636486],
        [74.27735900878918, 14.66875076293951],
        [74.27764129638678, 14.66875076293951],
        [74.27764129638678, 14.669031143188477],
        [74.27735900878918, 14.6690292358399],
        [74.27735900878918, 14.66958332061779],
        [74.27652740478538, 14.669580459594727],
        [74.27652740478538, 14.669860839843864],
        [74.27597045898443, 14.669860839843864],
        [74.27597045898443, 14.67014122009283],
        [74.2762527465822, 14.670139312744254],
        [74.2762527465822, 14.67069530487072],
        [74.27652740478538, 14.670689582824764],
        [74.27652740478538, 14.671251296997184],
        [74.27680206298822, 14.671251296997184],
        [74.27680206298822, 14.671809196472282],
        [74.277084350586, 14.671805381774902],
        [74.277084350586, 14.672361373901367],
        [74.27735900878918, 14.672361373901367],
        [74.27735900878918, 14.672638893127441],
        [74.27764129638678, 14.672638893127441],
        [74.27764129638678, 14.672361373901367],
        [74.27791595459013, 14.672361373901367],
        [74.27791595459013, 14.672082901000977],
        [74.27819061279297, 14.672082901000977],
        [74.2781982421875, 14.671805381774902],
        [74.27874755859392, 14.671809196472282],
        [74.27874755859392, 14.671530723571891],
        [74.27902984619146, 14.671530723571891],
        [74.27902984619146, 14.671251296997184],
        [74.27929687500017, 14.671251296997184],
        [74.27929687500017, 14.670959472656307],
        [74.28041839599621, 14.670959472656307],
        [74.28041839599621, 14.671251296997184],
        [74.28069305419928, 14.671251296997184],
        [74.28069305419928, 14.671809196472282],
        [74.28097534179716, 14.671805381774902],
        [74.28097534179716, 14.672082901000977],
        [74.28125, 14.6720809936524],
        [74.28125, 14.672638893127441],
        [74.28152465820341, 14.672638893127441],
        [74.28152465820341, 14.673194885253906],
        [74.28180694580095, 14.673191070556754],
        [74.28180694580095, 14.677920341491813],
        [74.28152465820341, 14.67791843414301],
        [74.28152465820341, 14.678470611572322],
        [74.28125, 14.678470611572322],
        [74.28125, 14.679030418395996],
        [74.28097534179716, 14.679027557373104],
        [74.28097534179716, 14.679583549499569],
        [74.28069305419928, 14.679583549499569],
        [74.28069305419928, 14.680139541626033],
        [74.28041839599621, 14.680139541626033],
        [74.28041839599621, 14.680693626403922],
        [74.2801361083985, 14.680688858032283],
        [74.2801361083985, 14.681249618530387],
        [74.27986145019526, 14.681249618530387],
        [74.27986145019526, 14.682084083557243],
        [74.27958679199241, 14.682079315185604],
        [74.27958679199241, 14.682640075683707],
        [74.2793045043947, 14.682640075683707],
        [74.2793045043947, 14.683194160461426],
        [74.27902984619146, 14.683189392089957],
        [74.27902984619146, 14.68375015258789],
        [74.27874755859392, 14.68375015258789],
        [74.27874755859392, 14.684310913085994],
        [74.27847290039091, 14.684306144714355],
        [74.27847290039091, 14.68486213684082],
        [74.2781982421875, 14.684860229492244],
        [74.2781982421875, 14.685420036315918],
        [74.27791595459013, 14.68541622161871],
        [74.27791595459013, 14.685972213745174],
        [74.27764129638678, 14.685970306396598],
        [74.27764129638678, 14.686530113220272],
        [74.27735900878918, 14.686530113220272],
        [74.27735900878918, 14.686811447143498],
        [74.27707672119146, 14.686811447143498],
        [74.27707672119146, 14.687361717224235],
        [74.27680206298822, 14.687361717224235],
        [74.27680206298822, 14.687920570373592],
        [74.27652740478538, 14.687915802001896],
        [74.27652740478538, 14.688194274902287],
        [74.2762527465822, 14.688190460205135],
        [74.2762527465822, 14.688750267028752],
        [74.27597045898443, 14.688750267028752],
        [74.27597045898443, 14.68931961059576],
        [74.27569580078142, 14.689306259155273],
        [74.27569580078142, 14.689860343933105],
        [74.27542114257841, 14.689860343933105],
        [74.27542114257841, 14.690421104431266],
        [74.27513885498047, 14.690416336059627],
        [74.27513885498047, 14.690972328186092],
        [74.2748565673831, 14.69097042083746],
        [74.2748565673831, 14.69153118133545],
        [74.27458190917969, 14.69153118133545],
        [74.27458190917969, 14.69235992431652],
        [74.27430725097668, 14.69235992431652],
        [74.27430725097668, 14.692920684814453],
        [74.27402496337896, 14.692916870117301],
        [74.27402496337896, 14.693472862243766],
        [74.27375030517572, 14.693470001220703],
        [74.27375030517572, 14.694030761718807],
        [74.27347564697288, 14.69402885437023],
        [74.27347564697288, 14.69458293914795],
        [74.27319335937517, 14.69458293914795],
        [74.27319335937517, 14.69486141204834],
        [74.27291870117193, 14.69486141204834],
        [74.27291870117193, 14.695138931274414],
        [74.27263641357439, 14.695130348205566],
        [74.27263641357439, 14.695421218872127],
        [74.27236175537138, 14.695417404174805],
        [74.27236175537138, 14.69597339630127],
        [74.27208709716797, 14.69597339630127],
        [74.27208709716797, 14.696400642395076],
        [74.27208709716797, 14.69653129577631],
        [74.2718048095706, 14.696526527404842],
        [74.2718048095706, 14.697083473205623],
        [74.27153015136719, 14.69708061218273],
        [74.27153015136719, 14.697641372680664],
        [74.27124786376982, 14.697641372680664],
        [74.27124786376982, 14.698200225830021],
        [74.27097320556646, 14.698200225830021],
        [74.27097320556646, 14.698751449585018],
        [74.27069091796886, 14.698751449585018],
        [74.27069091796886, 14.69958305358881],
        [74.27041625976568, 14.699581146240234],
        [74.27041625976568, 14.700140953064079],
        [74.27014160156267, 14.700139045715275],
        [74.27014160156267, 14.700693130493164],
        [74.26985931396507, 14.700691223144588],
        [74.26985931396507, 14.701259613037053],
        [74.26957702636736, 14.701259613037053],
        [74.26957702636736, 14.701811790466365],
        [74.26930236816412, 14.701805114746094],
        [74.26930236816412, 14.702361106872615],
        [74.26902770996111, 14.702359199523983],
        [74.26902770996111, 14.702642440795842],
        [74.2687530517581, 14.702642440795842],
        [74.2687530517581, 14.70292186737072],
        [74.26847076416016, 14.70291709899908],
        [74.26847076416016, 14.703194618225154],
        [74.26819610595732, 14.703194618225154],
        [74.26819610595732, 14.70347118377697],
        [74.26790618896484, 14.70347118377697],
        [74.26790618896484, 14.703751564025936],
        [74.26763916015636, 14.703751564025936],
        [74.26763916015636, 14.704031944274902],
        [74.26735687255865, 14.704031944274902],
        [74.26735687255865, 14.704320907592887],
        [74.26708221435541, 14.704304695129508],
        [74.26708221435541, 14.704583168029899],
        [74.26680755615257, 14.704581260681096],
        [74.26680755615257, 14.70486164093029],
        [74.26654052734392, 14.70486164093029],
        [74.26654052734392, 14.705142021179256],
        [74.26374816894554, 14.705142021179256],
        [74.26374816894554, 14.70486164093029],
        [74.26291656494158, 14.70486164093029],
        [74.26291656494158, 14.70541954040533],
        [74.26264190673857, 14.705417633056754],
        [74.26264190673857, 14.705693244934082],
        [74.26208496093778, 14.705693244934082],
        [74.26208496093778, 14.70597362518322],
        [74.26180267333984, 14.705970764160156],
        [74.26180267333984, 14.706250190734806],
        [74.26125335693365, 14.706250190734806],
        [74.26125335693365, 14.706529617309684],
        [74.26097106933588, 14.706527709960938],
        [74.26097106933588, 14.707083702087402],
        [74.25958251953153, 14.707083702087402],
        [74.25958251953153, 14.706806182861328],
        [74.25875091552734, 14.70680999755865],
        [74.25875091552734, 14.706529617309684],
        [74.25846862792997, 14.706529617309684],
        [74.25846862792997, 14.70680999755865],
        [74.25763702392584, 14.70680999755865],
        [74.25763702392584, 14.706529617309684],
        [74.25734710693354, 14.706529617309684],
        [74.25734710693354, 14.706250190734806],
        [74.25708007812523, 14.706250190734806],
        [74.25708007812523, 14.705970764160156],
        [74.25681304931658, 14.705970764160156],
        [74.25681304931658, 14.706529617309684],
        [74.25653076171903, 14.706529617309684],
        [74.25653076171903, 14.707920074463004],
        [74.25846862792997, 14.707920074463004],
        [74.25846862792997, 14.708749771118221],
        [74.25985717773455, 14.708749771118221],
        [74.25985717773455, 14.709030151367188],
        [74.26040649414057, 14.709030151367188],
        [74.26040649414057, 14.709309577941895],
        [74.26069641113304, 14.709305763244686],
        [74.26069641113304, 14.709584236145076],
        [74.26097106933588, 14.709584236145076],
        [74.26097106933588, 14.70986175537115],
        [74.26152801513683, 14.709859848022575],
        [74.26152801513683, 14.710140228271541],
        [74.26180267333984, 14.710140228271541],
        [74.26180267333984, 14.710419654846248],
        [74.26235961914062, 14.71041584014904],
        [74.26235961914062, 14.71069431304943],
        [74.26264190673857, 14.71069431304943],
        [74.26264190673857, 14.710971832275504],
        [74.26319122314459, 14.710971832275504],
        [74.26319122314459, 14.711250305175895],
        [74.26348114013689, 14.711250305175895],
        [74.26348114013689, 14.711529731750602],
        [74.26403045654291, 14.711529731750602],
        [74.26403045654291, 14.711811065673828],
        [74.26457977294933, 14.71180629730236],
        [74.26457977294933, 14.712081909179688],
        [74.26597595214861, 14.712081909179688],
        [74.26597595214861, 14.712362289428654],
        [74.26708221435541, 14.712360382080078],
        [74.26708221435541, 14.712639808654728],
        [74.26735687255865, 14.712639808654728],
        [74.26735687255865, 14.713200569152889],
        [74.26708221435541, 14.713200569152889],
        [74.26708221435541, 14.713751792907715],
        [74.26680755615257, 14.713751792907715],
        [74.26680755615257, 14.714310646057243],
        [74.26652526855486, 14.714305877685604],
        [74.26654052734392, 14.714859962463436],
        [74.26625061035162, 14.714859962463436],
        [74.26625061035162, 14.71514034271246],
        [74.26597595214861, 14.71514034271246],
        [74.26597595214861, 14.715420722961426],
        [74.26569366455107, 14.715415954589957],
        [74.26569366455107, 14.715694427490291],
        [74.26486206054693, 14.715694427490291],
        [74.26486206054693, 14.714305877685604],
        [74.26457977294933, 14.714310646057243],
        [74.26457977294933, 14.714030265808105],
        [74.26374816894554, 14.714028358459473],
        [74.26374816894554, 14.717082023620605],
        [74.26348114013689, 14.717080116271973],
        [74.26348114013689, 14.717640876770133],
        [74.26319122314459, 14.717640876770133],
        [74.26319122314459, 14.7179212570191],
        [74.26291656494158, 14.717915534973145],
        [74.26291656494158, 14.718473434448185],
        [74.26264190673857, 14.718469619751033],
        [74.26264190673857, 14.718750953674316],
        [74.26235961914062, 14.718750953674316],
        [74.26235961914062, 14.719306945800781],
        [74.26208496093778, 14.719306945800781],
        [74.26208496093778, 14.719581604003963],
        [74.26180267333984, 14.719579696655387],
        [74.26180267333984, 14.720141410827637],
        [74.26152801513683, 14.720141410827637],
        [74.26152801513683, 14.720420837402287],
        [74.26125335693365, 14.720417022705135],
        [74.26125335693365, 14.7209730148316],
        [74.26097106933588, 14.720970153808707],
        [74.26097106933588, 14.72153091430664],
        [74.26069641113304, 14.721529006958065],
        [74.26069641113304, 14.722083091735954],
        [74.26041412353533, 14.722083091735954],
        [74.26041412353533, 14.722360610962028],
        [74.26013946533209, 14.722360610962028],
        [74.26013946533209, 14.722921371459961],
        [74.25986480712908, 14.722916603088493],
        [74.25986480712908, 14.723472595214787],
        [74.25958251953153, 14.723470687866211],
        [74.25958251953153, 14.724031448364315],
        [74.25930786132812, 14.724031448364315],
        [74.25930786132812, 14.724311828613281],
        [74.25902557373075, 14.724305152893066],
        [74.25902557373075, 14.724861145019531],
        [74.25875091552734, 14.724861145019531],
        [74.25875091552734, 14.725431442260799],
        [74.25846862792997, 14.725417137145996],
        [74.25846862792997, 14.725973129272461],
        [74.25818634033209, 14.725971221923885],
        [74.25818634033209, 14.726250648498592],
        [74.25791931152338, 14.726250648498592],
        [74.25791931152338, 14.726810455322209],
        [74.25763702392584, 14.726804733276424],
        [74.25763702392584, 14.727083206176815],
        [74.25734710693354, 14.727081298828239],
        [74.25734710693354, 14.727360725402946],
        [74.25708007812523, 14.727360725402946],
        [74.25708007812523, 14.727919578552303],
        [74.25681304931658, 14.727919578552303],
        [74.25681304931658, 14.72819995880127],
        [74.25653076171903, 14.72819995880127],
        [74.25653076171903, 14.728749275207463],
        [74.25624847412126, 14.728749275207463],
        [74.25624847412126, 14.729031562805233],
        [74.25597381591825, 14.729026794433594],
        [74.25597381591825, 14.729582786560059],
        [74.25569152832031, 14.729582786560059],
        [74.25569152832031, 14.72986125946045],
        [74.25541687011747, 14.72986125946045],
        [74.25541687011747, 14.730141639709586],
        [74.25514221191412, 14.730138778686523],
        [74.25514221191412, 14.730695724487305],
        [74.25485992431652, 14.730695724487305],
        [74.25485992431652, 14.730971336364803],
        [74.25458526611334, 14.730971336364803],
        [74.25460052490257, 14.731520652770996],
        [74.25431060791033, 14.73153018951416],
        [74.25431060791033, 14.73180961608898],
        [74.25402832031273, 14.731804847717342],
        [74.25402832031273, 14.732084274291992],
        [74.25374603271501, 14.732081413269157],
        [74.25374603271501, 14.732361793518123],
        [74.25319671630876, 14.732361793518123],
        [74.25319671630876, 14.732640266418514],
        [74.25290679931669, 14.732640266418514],
        [74.25290679931669, 14.732919692993164],
        [74.25263977050781, 14.732917785644588],
        [74.25263977050781, 14.733193397522086],
        [74.25235748291044, 14.733193397522086],
        [74.25235748291044, 14.73347091674816],
        [74.25180816650402, 14.73347091674816],
        [74.25180816650402, 14.733750343322868],
        [74.2515258789063, 14.733750343322868],
        [74.2515258789063, 14.734029769897518],
        [74.25125122070307, 14.734027862548942],
        [74.25125122070307, 14.734306335449332],
        [74.2509689331057, 14.734306335449332],
        [74.2509689331057, 14.734583854675407],
        [74.25069427490251, 14.734583854675407],
        [74.25069427490251, 14.734862327575797],
        [74.25013732910173, 14.734859466552734],
        [74.25013732910173, 14.735139846801871],
        [74.24986267089872, 14.735139846801871],
        [74.24986267089872, 14.735420227050838],
        [74.24958038330078, 14.735416412353516],
        [74.24958038330078, 14.739221572875977],
        [74.24958038330078, 14.739583969116268],
        [74.24913787841808, 14.739583969116268],
        [74.24903106689459, 14.739583969116268],
        [74.24903106689459, 14.739859580993596],
        [74.24874877929699, 14.739879608154354],
        [74.24874877929699, 14.74013996124279],
        [74.24845123291016, 14.740138053893986],
        [74.24819183349604, 14.740138053893986],
        [74.24819183349604, 14.740339279174862],
        [74.24819183349604, 14.74041557312006],
        [74.2479171752932, 14.74041557312006],
        [74.2479171752932, 14.740694046020451],
        [74.24736022949224, 14.740694046020451],
        [74.24736022949224, 14.740971565246582],
        [74.24709320068376, 14.74096965789795],
        [74.24709320068376, 14.741250038146916],
        [74.24652862548828, 14.741250038146916],
        [74.24652862548828, 14.74153137207037],
        [74.24624633789091, 14.74153137207037],
        [74.24624633789091, 14.741810798645076],
        [74.24569702148449, 14.741806030273438],
        [74.24569702148449, 14.742083549499512],
        [74.24540710449224, 14.742079734802303],
        [74.24540710449224, 14.74236011505127],
        [74.244857788086, 14.74236011505127],
        [74.244857788086, 14.742639541625977],
        [74.24458312988298, 14.742639541625977],
        [74.24458312988298, 14.74292087554943],
        [74.24430847167974, 14.742916107177791],
        [74.24430847167974, 14.743193626403865],
        [74.24375152587919, 14.743193626403865],
        [74.24375152587919, 14.743472099304256],
        [74.24318695068388, 14.743470191955623],
        [74.24318695068388, 14.74374961853033],
        [74.242919921875, 14.74374961853033],
        [74.242919921875, 14.744030952453613],
        [74.2423706054688, 14.744030952453613],
        [74.2423706054688, 14.74431133270275],
        [74.24208068847668, 14.744306564331112],
        [74.24208068847668, 14.74458217620861],
        [74.24153137207048, 14.74458217620861],
        [74.24153137207048, 14.744859695434684],
        [74.24124908447288, 14.744859695434684],
        [74.24124908447288, 14.745141029357967],
        [74.24069213867193, 14.745141029357967],
        [74.24069213867193, 14.745421409606934],
        [74.24013519287138, 14.745415687561149],
        [74.24013519287138, 14.745695114135856],
        [74.23958587646513, 14.745695114135856],
        [74.23958587646513, 14.74597263336193],
        [74.23930358886724, 14.74597263336193],
        [74.23930358886724, 14.74625110626232],
        [74.23874664306646, 14.746240615844727],
        [74.23874664306646, 14.746530532836971],
        [74.23819732666038, 14.746530532836971],
        [74.23819732666038, 14.746810913085938],
        [74.23791503906267, 14.746804237365723],
        [74.23791503906267, 14.74708461761486],
        [74.23735809326189, 14.74708461761486],
        [74.23735809326189, 14.747361183166504],
        [74.23596954345714, 14.747361183166504],
        [74.23596954345714, 14.747640609741325],
        [74.2345809936524, 14.747640609741325],
        [74.2345809936524, 14.747920989990291],
        [74.23264312744146, 14.747920989990291],
        [74.23264312744146, 14.747640609741325],
        [74.23208618164085, 14.747640609741325],
        [74.23208618164085, 14.747361183166504],
        [74.23124694824236, 14.747361183166504],
        [74.23124694824236, 14.74708461761486],
        [74.23069763183594, 14.74708461761486],
        [74.23069763183594, 14.746804237365723],
        [74.22985839843778, 14.746810913085938],
        [74.22985839843778, 14.746530532836971],
        [74.2293090820312, 14.746530532836971],
        [74.2293090820312, 14.746240615844727],
        [74.22875213623064, 14.74625110626232],
        [74.22875213623064, 14.74597263336193],
        [74.22819519042986, 14.74597263336193],
        [74.22819519042986, 14.745695114135856],
        [74.22763824462908, 14.745695114135856],
        [74.22763824462908, 14.745138168335075],
        [74.2273712158206, 14.745141029357967],
        [74.2273712158206, 14.744580268859806],
        [74.22763824462908, 14.74458217620861],
        [74.22763824462908, 14.744306564331112],
        [74.2273712158206, 14.74431133270275],
        [74.2273712158206, 14.744030952453613],
        [74.22763824462908, 14.744030952453613],
        [74.22763824462908, 14.743181228637638],
        [74.22791290283209, 14.743193626403865],
        [74.22791290283209, 14.7426376342774],
        [74.22820281982439, 14.742639541625977],
        [74.22820281982439, 14.74236011505127],
        [74.22846984863304, 14.74236011505127],
        [74.22846984863304, 14.741250038146916],
        [74.22902679443365, 14.741250038146916],
        [74.22902679443365, 14.74096965789795],
        [74.22875213623064, 14.740971565246582],
        [74.22875213623064, 14.740694046020451],
        [74.22902679443365, 14.740694046020451],
        [74.22902679443365, 14.74041557312006],
        [74.22875213623064, 14.74043178558344],
        [74.22875213623064, 14.739311218261662],
        [74.22846984863304, 14.739311218261662],
        [74.22846984863304, 14.738191604614371],
        [74.22875213623064, 14.738194465637264],
        [74.22875213623064, 14.737638473510799],
        [74.22820281982439, 14.737640380859375],
        [74.22820281982439, 14.737081527710018],
        [74.22624969482433, 14.73708438873291],
        [74.22624969482433, 14.73680591583252],
        [74.22319793701183, 14.736809730529899],
        [74.22319793701183, 14.736530303955192],
        [74.22292327880865, 14.736530303955192],
        [74.22292327880865, 14.735968589782772],
        [74.22264099121088, 14.73597240447998],
        [74.22264099121088, 14.735416412353516],
        [74.22152709960955, 14.735416412353516],
        [74.22152709960955, 14.735695838928336],
        [74.22125244140653, 14.735695838928336],
        [74.22125244140653, 14.73597240447998],
        [74.22070312500028, 14.735968589782772],
        [74.22070312500028, 14.736249923706055],
        [74.2201385498048, 14.736249923706055],
        [74.2201385498048, 14.736530303955192],
        [74.21958160400385, 14.736530303955192],
        [74.21958160400385, 14.736809730529899],
        [74.21820068359403, 14.736809730529899],
        [74.21820068359403, 14.736530303955192],
        [74.21763610839872, 14.736530303955192],
        [74.21763610839872, 14.736249923706055],
        [74.21652984619135, 14.736249923706055],
        [74.21652984619135, 14.735968589782772],
        [74.2162475585938, 14.73597240447998],
        [74.2162475585938, 14.735416412353516],
        [74.21569824218756, 14.735420227050838],
        [74.2156906127932, 14.735139846801871],
        [74.215141296387, 14.735139846801871],
        [74.215141296387, 14.734859466552734],
        [74.21485900878923, 14.734862327575797],
        [74.21485900878923, 14.734583854675407],
        [74.21291351318354, 14.734583854675407],
        [74.21291351318354, 14.734306335449332],
        [74.2126388549807, 14.734306335449332],
        [74.2126388549807, 14.734027862548942],
        [74.21235656738298, 14.734029769897518],
        [74.21235656738298, 14.733750343322868],
        [74.21206665039085, 14.733750343322868],
        [74.21206665039085, 14.73347091674816],
        [74.21180725097673, 14.73347091674816],
        [74.21180725097673, 14.733193397522086],
        [74.21125030517578, 14.733193397522086],
        [74.21125030517578, 14.732917785644588],
        [74.21097564697294, 14.732917785644588],
        [74.21097564697294, 14.733193397522086],
        [74.21041870117199, 14.733193397522086],
        [74.21041870117199, 14.73347091674816],
        [74.20986175537104, 14.73347091674816],
        [74.20986175537104, 14.733750343322868],
        [74.2095870971682, 14.733750343322868],
        [74.2095870971682, 14.734029769897518],
        [74.20930480957048, 14.734027862548942],
        [74.20930480957048, 14.734862327575797],
        [74.20903015136724, 14.734859466552734],
        [74.20903015136724, 14.736809730529899],
        [74.2087478637697, 14.73680591583252],
        [74.2087478637697, 14.73708438873291],
        [74.20847320556669, 14.737081527710018],
        [74.20847320556669, 14.737371444702148],
        [74.20735931396496, 14.737371444702148],
        [74.20735931396496, 14.737640380859375],
        [74.20707702636724, 14.737640380859375],
        [74.20707702636724, 14.737371444702148],
        [74.20652770996116, 14.737371444702148],
        [74.20652770996116, 14.737081527710018],
        [74.20597076416021, 14.73708438873291],
        [74.20597076416021, 14.73680591583252],
        [74.2056961059572, 14.736809730529899],
        [74.2056961059572, 14.736530303955192],
        [74.20513916015625, 14.736530303955192],
        [74.20513916015625, 14.735968589782772],
        [74.20486450195341, 14.73597240447998],
        [74.20486450195341, 14.735416412353516],
        [74.20430755615246, 14.735420227050838],
        [74.20430755615246, 14.735139846801871],
        [74.20403289794928, 14.735139846801871],
        [74.20403289794928, 14.734859466552734],
        [74.2037506103515, 14.734862327575797],
        [74.2037506103515, 14.734583854675407],
        [74.20347595214866, 14.734583854675407],
        [74.20347595214866, 14.732917785644588],
        [74.20320129394548, 14.732919692993164],
        [74.20320129394548, 14.732640266418514],
        [74.20349121093756, 14.732640266418514],
        [74.20349121093756, 14.732361793518123],
        [74.2037506103515, 14.732361793518123],
        [74.2037506103515, 14.73124027252203],
        [74.20402526855474, 14.731248855590877],
        [74.20402526855474, 14.730971336364803],
        [74.20458221435547, 14.730971336364803],
        [74.20458221435547, 14.730695724487305],
        [74.20513916015625, 14.730695724487305],
        [74.20513916015625, 14.730138778686523],
        [74.2056961059572, 14.730141639709586],
        [74.2056961059572, 14.72986125946045],
        [74.20597076416021, 14.72986125946045],
        [74.20597076416021, 14.729305267333928],
        [74.20626068115251, 14.7293119430542],
        [74.20626068115251, 14.727360725402946],
        [74.20652770996116, 14.727360725402946],
        [74.20652770996116, 14.726250648498592],
        [74.206802368164, 14.726250648498592],
        [74.206802368164, 14.725140571594238],
        [74.20707702636724, 14.725140571594238],
        [74.20707702636724, 14.724311828613281],
        [74.20735931396496, 14.724311828613281],
        [74.20735931396496, 14.723751068115348],
        [74.20764160156256, 14.723751068115348],
        [74.20764160156256, 14.723470687866211],
        [74.20735931396496, 14.723472595214787],
        [74.20735931396496, 14.723195075988883],
        [74.20791625976591, 14.723195075988883],
        [74.20791625976591, 14.722083091735954],
        [74.20819091796875, 14.722083091735954],
        [74.20819091796875, 14.721529006958065],
        [74.20791625976591, 14.72153091430664],
        [74.20791625976591, 14.721250534057674],
        [74.20819091796875, 14.721250534057674],
        [74.20819091796875, 14.720970153808707],
        [74.20847320556669, 14.7209730148316],
        [74.20847320556669, 14.719860076904354],
        [74.2087478637697, 14.719860076904354],
        [74.2087478637697, 14.717640876770133],
        [74.20847320556669, 14.717640876770133],
        [74.20847320556669, 14.71735954284668],
        [74.20819091796875, 14.71735954284668],
        [74.20819091796875, 14.717640876770133],
        [74.20791625976591, 14.717640876770133],
        [74.20791625976591, 14.71735954284668],
        [74.20764160156256, 14.71735954284668],
        [74.20764160156256, 14.717640876770133],
        [74.20652770996116, 14.717640876770133],
        [74.20652770996116, 14.7179212570191],
        [74.20590209960938, 14.717919349670524],
        [74.20486450195341, 14.717915534973145],
        [74.20486450195341, 14.718194007873535],
        [74.20458221435547, 14.718194007873535],
        [74.20458221435547, 14.718473434448185],
        [74.20403289794928, 14.718469619751033],
        [74.20403289794928, 14.718750953674316],
        [74.20347595214866, 14.718750953674316],
        [74.20347595214866, 14.719306945800781],
        [74.20319366455095, 14.719306945800781],
        [74.20319366455095, 14.719581604003963],
        [74.20263671875017, 14.719579696655387],
        [74.20263671875017, 14.719860076904354],
        [74.20207977294939, 14.719860076904354],
        [74.20207977294939, 14.720141410827637],
        [74.20153045654297, 14.720141410827637],
        [74.20153045654297, 14.720420837402287],
        [74.2012481689456, 14.720417022705135],
        [74.2012481689456, 14.72069454193121],
        [74.20069122314464, 14.72068977355957],
        [74.20069122314464, 14.721250534057674],
        [74.20012664794916, 14.721250534057674],
        [74.20012664794916, 14.721811294555607],
        [74.19985961914085, 14.721804618835563],
        [74.19985961914085, 14.722083091735954],
        [74.19958496093767, 14.722083091735954],
        [74.19958496093767, 14.722360610962028],
        [74.1993026733399, 14.722360610962028],
        [74.1993026733399, 14.722640991210994],
        [74.19902801513689, 14.722639083862418],
        [74.19902801513689, 14.723195075988883],
        [74.19847106933594, 14.723195075988883],
        [74.19847106933594, 14.723472595214787],
        [74.1981964111331, 14.723470687866211],
        [74.1981964111331, 14.724031448364315],
        [74.19763946533214, 14.724031448364315],
        [74.19763946533214, 14.724311828613281],
        [74.19735717773443, 14.724311828613281],
        [74.19735717773443, 14.724031448364315],
        [74.1970672607423, 14.724031448364315],
        [74.1970672607423, 14.724311828613281],
        [74.19652557373064, 14.724305152893066],
        [74.19652557373064, 14.72458267211914],
        [74.19541931152344, 14.72458267211914],
        [74.19541931152344, 14.724861145019531],
        [74.1940307617187, 14.724861145019531],
        [74.1940307617187, 14.725140571594238],
        [74.19374847412132, 14.725140571594238],
        [74.19374847412132, 14.725431442260799],
        [74.19319152832037, 14.725417137145996],
        [74.19319152832037, 14.72569465637207],
        [74.1923599243164, 14.725692749023494],
        [74.1923599243164, 14.727360725402946],
        [74.19264221191435, 14.727360725402946],
        [74.19264221191435, 14.727919578552303],
        [74.19291687011736, 14.727919578552303],
        [74.19291687011736, 14.72819995880127],
        [74.19319152832037, 14.728195190429801],
        [74.19319152832037, 14.728470802307072],
        [74.19346618652361, 14.728468894958496],
        [74.19346618652361, 14.728749275207463],
        [74.19374847412132, 14.728749275207463],
        [74.19374847412132, 14.729031562805233],
        [74.1940307617187, 14.729026794433594],
        [74.1940307617187, 14.729582786560059],
        [74.19430541992193, 14.729582786560059],
        [74.19430541992193, 14.72986125946045],
        [74.19458007812511, 14.72986125946045],
        [74.19458007812511, 14.730141639709586],
        [74.19430541992193, 14.730138778686523],
        [74.19430541992193, 14.730695724487305],
        [74.1940307617187, 14.730695724487305],
        [74.1940307617187, 14.731248855590877],
        [74.19374847412132, 14.73124027252203],
        [74.19374847412132, 14.73153018951416],
        [74.19347381591814, 14.731527328491268],
        [74.19347381591814, 14.731889724731559],
        [74.19346618652361, 14.735139846801871],
        [74.19291687011736, 14.735139846801871],
        [74.19291687011736, 14.735695838928336],
        [74.19264221191435, 14.735691070556697],
        [74.19264221191435, 14.736249923706055],
        [74.1923599243164, 14.736249923706055],
        [74.1923599243164, 14.736530303955192],
        [74.1920928955081, 14.736530303955192],
        [74.1920928955081, 14.736809730529899],
        [74.19180297851562, 14.73680591583252],
        [74.19180297851562, 14.73708438873291],
        [74.19152832031261, 14.737081527710018],
        [74.19152832031261, 14.737640380859375],
        [74.19126129150396, 14.737640380859375],
        [74.19126129150396, 14.737919807434082],
        [74.19097137451166, 14.737915992736873],
        [74.19097137451166, 14.738194465637264],
        [74.19069671630882, 14.738194465637264],
        [74.19069671630882, 14.738471984863338],
        [74.19040679931635, 14.738471984863338],
        [74.19040679931635, 14.738750457763729],
        [74.19013977050787, 14.738750457763729],
        [74.19013977050787, 14.739032745361271],
        [74.18985748291033, 14.739027976989803],
        [74.18985748291033, 14.739583969116268],
        [74.18958282470732, 14.739583969116268],
        [74.18958282470732, 14.739862442016658],
        [74.1893081665039, 14.739859580993596],
        [74.1893081665039, 14.74013996124279],
        [74.18902587890653, 14.74013996124279],
        [74.18902587890653, 14.74043178558344],
        [74.18875122070312, 14.74041557312006],
        [74.18875122070312, 14.740971565246582],
        [74.18846893310575, 14.74096965789795],
        [74.18846893310575, 14.741250038146916],
        [74.18818664550787, 14.741250038146916],
        [74.18818664550787, 14.74153137207037],
        [74.18791961669916, 14.741527557373047],
        [74.18791961669916, 14.742083549499512],
        [74.18763732910162, 14.742079734802303],
        [74.18763732910162, 14.74236011505127],
        [74.18708038330101, 14.74236011505127],
        [74.18708038330101, 14.742639541625977],
        [74.18653106689482, 14.742639541625977],
        [74.18653106689482, 14.74292087554943],
        [74.18605804443376, 14.742918968200627],
        [74.18597412109403, 14.742916107177791],
        [74.18597412109403, 14.743193626403865],
        [74.1857528686524, 14.743193626403865],
        [74.18541717529325, 14.743193626403865],
        [74.18541717529325, 14.743499755859318],
        [74.18541717529325, 14.74374961853033],
        [74.1851425170899, 14.74374961853033],
        [74.1851425170899, 14.74458217620861],
        [74.1848602294923, 14.744580268859806],
        [74.1848602294923, 14.744859695434684],
        [74.18459320068382, 14.744859695434684],
        [74.18459320068382, 14.745141029357967],
        [74.18430328369135, 14.745141029357967],
        [74.18430328369135, 14.745421409606934],
        [74.18402862548851, 14.745415687561149],
        [74.18402862548851, 14.745695114135856],
        [74.1837463378908, 14.745695114135856],
        [74.1837463378908, 14.74597263336193],
        [74.18347167968756, 14.74597263336193],
        [74.18347167968756, 14.74625110626232],
        [74.18319702148455, 14.746240615844727],
        [74.18319702148455, 14.746530532836971],
        [74.18290710449247, 14.746530532836971],
        [74.18290710449247, 14.746810913085938],
        [74.1826400756836, 14.746804237365723],
        [74.1826400756836, 14.74708461761486],
        [74.18235778808622, 14.74708461761486],
        [74.18235778808622, 14.747361183166504],
        [74.18206787109375, 14.747361183166504],
        [74.18206787109375, 14.747640609741325],
        [74.1818084716798, 14.747640609741325],
        [74.1818084716798, 14.747920989990291],
        [74.18152618408209, 14.747917175292969],
        [74.18152618408209, 14.748194694519043],
        [74.18125152587885, 14.748194694519043],
        [74.18125152587885, 14.748473167419434],
        [74.18068695068376, 14.748470306396541],
        [74.18068695068376, 14.748750686645508],
        [74.18041992187506, 14.748750686645508],
        [74.18041992187506, 14.749031066894474],
        [74.18013763427751, 14.749031066894474],
        [74.18013763427751, 14.749311447143612],
        [74.17958068847656, 14.749304771423397],
        [74.17958068847656, 14.749583244323787],
        [74.17930603027372, 14.749583244323787],
        [74.17932128906261, 14.749860763549862],
        [74.17624664306669, 14.749860763549862],
        [74.17624664306669, 14.749580383300895],
        [74.17569732666027, 14.749580383300895],
        [74.17569732666027, 14.750141143798828],
        [74.17542266845709, 14.750141143798828],
        [74.17542266845709, 14.750420570373535],
        [74.17514038085932, 14.750416755676326],
        [74.17514038085932, 14.750695228576717],
        [74.17485809326178, 14.750690460205078],
        [74.17485809326178, 14.750970840454215],
        [74.174575805664, 14.750970840454215],
        [74.17458343505876, 14.751251220703182],
        [74.17318725585966, 14.751251220703182],
        [74.17263793945341, 14.751251220703182],
        [74.17263793945341, 14.750970840454215],
        [74.17180633544928, 14.750970840454215],
        [74.17180633544928, 14.751251220703182],
        [74.17151641845697, 14.751251220703182],
        [74.17151641845697, 14.751530647277889],
        [74.17096710205095, 14.751530647277889],
        [74.17096710205095, 14.751811981201115],
        [74.17069244384771, 14.75180530548107],
        [74.17069244384771, 14.752082824706974],
        [74.1704177856447, 14.752080917358398],
        [74.1704177856447, 14.75237178802496],
        [74.16819763183616, 14.75237178802496],
        [74.16819763183616, 14.752640724182243],
        [74.16735839843767, 14.752640724182243],
        [74.16735839843767, 14.752919197082633],
        [74.1666870117188, 14.752919197082633],
        [74.16374969482422, 14.75291728973383],
        [74.16374969482422, 14.753194808959904],
        [74.16347503662138, 14.753194808959904],
        [74.16347503662138, 14.753748893737793],
        [74.16374969482422, 14.753748893737793],
        [74.16374969482422, 14.75402927398676],
        [74.16403198242216, 14.75402927398676],
        [74.16403198242216, 14.754309654235954],
        [74.16430664062517, 14.754304885864258],
        [74.16430664062517, 14.754583358764648],
        [74.16458129882818, 14.754581451416072],
        [74.16458129882818, 14.75486087799078],
        [74.16486358642595, 14.75486087799078],
        [74.16486358642595, 14.755695343017635],
        [74.16513824462913, 14.755695343017635],
        [74.16513824462913, 14.755970954895133],
        [74.16541290283197, 14.755970954895133],
        [74.16541290283197, 14.756810188293457],
        [74.16513824462913, 14.756805419921989],
        [74.16513824462913, 14.757082939148063],
        [74.16541290283197, 14.75708103179926],
        [74.16541290283197, 14.759309768676815],
        [74.16513824462913, 14.759309768676815],
        [74.16513824462913, 14.760140419006348],
        [74.16486358642595, 14.760136604309139],
        [74.16486358642595, 14.76097202301031],
        [74.16513824462913, 14.76097202301031],
        [74.16513824462913, 14.761541366577262],
        [74.16541290283197, 14.761528015136776],
        [74.16541290283197, 14.76208400726324],
        [74.16570281982428, 14.762079238891602],
        [74.16570281982428, 14.762639999389705],
        [74.16596984863293, 14.762639999389705],
        [74.16596984863293, 14.762920379638672],
        [74.16625213623053, 14.762915611267204],
        [74.16625213623053, 14.763194084167594],
        [74.16708374023466, 14.763194084167594],
        [74.16708374023466, 14.763471603393668],
        [74.16735839843767, 14.763471603393668],
        [74.16735839843767, 14.762915611267204],
        [74.16791534423845, 14.762915611267204],
        [74.16791534423845, 14.763194084167594],
        [74.16819763183616, 14.763194084167594],
        [74.16819763183616, 14.763471603393668],
        [74.168472290039, 14.763471603393668],
        [74.168472290039, 14.764030456543026],
        [74.16874694824224, 14.764030456543026],
        [74.16874694824224, 14.764312744140625],
        [74.168472290039, 14.764306068420524],
        [74.168472290039, 14.764583587646428],
        [74.16819763183616, 14.764583587646428],
        [74.16819763183616, 14.764862060546818],
        [74.16792297363298, 14.764860153198242],
        [74.16792297363298, 14.765139579772892],
        [74.16764068603521, 14.765139579772892],
        [74.16764068603521, 14.765419960022086],
        [74.16735839843767, 14.765418052673283],
        [74.16735839843767, 14.765693664550781],
        [74.16709136962919, 14.765688896179313],
        [74.16709136962919, 14.766249656677246],
        [74.16680908203125, 14.766249656677246],
        [74.16680908203125, 14.76653003692627],
        [74.16625213623053, 14.76653003692627],
        [74.16625213623053, 14.766249656677246],
        [74.16570281982428, 14.766249656677246],
        [74.16570281982428, 14.765970230102596],
        [74.16541290283197, 14.765972137451172],
        [74.16541290283197, 14.765693664550781],
        [74.16513824462913, 14.765693664550781],
        [74.16513824462913, 14.764583587646428],
        [74.16486358642595, 14.764583587646428],
        [74.16486358642595, 14.763194084167594],
        [74.16458129882818, 14.763194084167594],
        [74.16458129882818, 14.762915611267204],
        [74.16430664062517, 14.762920379638672],
        [74.16430664062517, 14.762359619140739],
        [74.16402435302763, 14.762361526489315],
        [74.16402435302763, 14.76180553436285],
        [74.16374969482422, 14.761810302734318],
        [74.16374969482422, 14.760140419006348],
        [74.16403198242216, 14.760140419006348],
        [74.16403198242216, 14.759580612182674],
        [74.16374969482422, 14.759584426879826],
        [74.16374969482422, 14.757361412048454],
        [74.16402435302763, 14.757361412048454],
        [74.16402435302763, 14.755970954895133],
        [74.16374969482422, 14.755970954895133],
        [74.16374969482422, 14.755416870117244],
        [74.16347503662138, 14.755416870117244],
        [74.16347503662138, 14.75486087799078],
        [74.16319274902344, 14.75486087799078],
        [74.16319274902344, 14.754581451416072],
        [74.16263580322271, 14.754581451416072],
        [74.16263580322271, 14.75486087799078],
        [74.15985870361357, 14.75486087799078],
        [74.15985870361357, 14.754581451416072],
        [74.15958404541021, 14.754583358764648],
        [74.15958404541021, 14.754304885864258],
        [74.15902709960943, 14.754309654235954],
        [74.15902709960943, 14.75402927398676],
        [74.15875244140642, 14.75402927398676],
        [74.15875244140642, 14.752919197082633],
        [74.15847015380882, 14.752919197082633],
        [74.15847015380882, 14.752080917358398],
        [74.1601333618164, 14.752082824706974],
        [74.16041564941435, 14.752082824706974],
        [74.16041564941435, 14.75180530548107],
        [74.16014862060553, 14.751811981201115],
        [74.16014099121094, 14.751530647277889],
        [74.15985870361357, 14.751530647277889],
        [74.15985870361357, 14.751251220703182],
        [74.15957641601568, 14.751251220703182],
        [74.15957641601568, 14.750970840454215],
        [74.15930175781261, 14.750970840454215],
        [74.15930175781261, 14.750690460205078],
        [74.15902709960943, 14.750695228576717],
        [74.15902709960943, 14.750416755676326],
        [74.15875244140642, 14.750420570373535],
        [74.15875244140642, 14.749031066894474],
        [74.15902709960943, 14.749031066894474],
        [74.15902709960943, 14.746240615844727],
        [74.15875244140642, 14.74625110626232],
        [74.15875244140642, 14.745695114135856],
        [74.15791320800787, 14.745695114135856],
        [74.15791320800787, 14.745415687561149],
        [74.15735626220732, 14.745421409606934],
        [74.15735626220732, 14.745141029357967],
        [74.15680694580107, 14.745141029357967],
        [74.15680694580107, 14.745421409606934],
        [74.15597534179693, 14.745415687561149],
        [74.15597534179693, 14.745695114135856],
        [74.15541839599632, 14.745695114135856],
        [74.15541839599632, 14.74597263336193],
        [74.15319824218761, 14.745969772338867],
        [74.15319824218761, 14.746530532836971],
        [74.1529159545899, 14.746528625488395],
        [74.1529159545899, 14.74708461761486],
        [74.15208435058611, 14.74708461761486],
        [74.15208435058611, 14.747361183166504],
        [74.15180206298834, 14.747361183166504],
        [74.15180206298834, 14.747640609741325],
        [74.15152740478533, 14.747640609741325],
        [74.15152740478533, 14.747920989990291],
        [74.15097045898438, 14.747917175292969],
        [74.15097045898438, 14.748194694519043],
        [74.15069580078153, 14.748189926147575],
        [74.15069580078153, 14.748750686645508],
        [74.15097045898438, 14.748750686645508],
        [74.15097045898438, 14.749860763549862],
        [74.15069580078153, 14.749860763549862],
        [74.15069580078153, 14.751530647277889],
        [74.15097045898438, 14.751530647277889],
        [74.15097045898438, 14.751811981201115],
        [74.15125274658232, 14.75180530548107],
        [74.15125274658232, 14.752082824706974],
        [74.15097045898438, 14.752080917358398],
        [74.15097045898438, 14.75237178802496],
        [74.15125274658232, 14.75237178802496],
        [74.15125274658232, 14.752919197082633],
        [74.15152740478533, 14.75291728973383],
        [74.15152740478533, 14.753473281860295],
        [74.15180206298834, 14.753471374511719],
        [74.15180206298834, 14.753748893737793],
        [74.15209197998064, 14.753748893737793],
        [74.15209197998064, 14.754309654235954],
        [74.15235900878929, 14.754309654235954],
        [74.15235900878929, 14.75486087799078],
        [74.1529159545899, 14.75486087799078],
        [74.1529159545899, 14.755695343017635],
        [74.15319824218761, 14.755695343017635],
        [74.15319824218761, 14.755970954895133],
        [74.15347290039068, 14.755970954895133],
        [74.15347290039068, 14.756531715393066],
        [74.1540298461914, 14.756531715393066],
        [74.1540298461914, 14.756810188293457],
        [74.15430450439482, 14.756805419921989],
        [74.15430450439482, 14.757361412048454],
        [74.15458679199236, 14.757361412048454],
        [74.15458679199236, 14.75792026519781],
        [74.15486145019537, 14.757918357849178],
        [74.15486145019537, 14.75847053527832],
        [74.15597534179693, 14.75847053527832],
        [74.15597534179693, 14.758193016052246],
        [74.15652465820318, 14.758193016052246],
        [74.15652465820318, 14.75847053527832],
        [74.1570816040039, 14.75847053527832],
        [74.1570816040039, 14.758751869201774],
        [74.15736389160185, 14.758749008178711],
        [74.15736389160185, 14.760692596435604],
        [74.1570816040039, 14.760690689087028],
        [74.1570816040039, 14.761249542236385],
        [74.15680694580107, 14.761249542236385],
        [74.15680694580107, 14.761810302734318],
        [74.15652465820318, 14.76180553436285],
        [74.15652465820318, 14.762361526489315],
        [74.15625000000011, 14.762359619140739],
        [74.15625000000011, 14.762920379638672],
        [74.15597534179693, 14.762915611267204],
        [74.15597534179693, 14.763194084167594],
        [74.15569305419916, 14.763194084167594],
        [74.15569305419916, 14.763471603393668],
        [74.15541839599632, 14.763471603393668],
        [74.15541839599632, 14.763751983642635],
        [74.15515136718767, 14.763751983642635],
        [74.15515136718767, 14.764030456543026],
        [74.15486145019537, 14.764030456543026],
        [74.15486145019537, 14.764312744140625],
        [74.15458679199236, 14.764306068420524],
        [74.15458679199236, 14.764583587646428],
        [74.15430450439482, 14.764583587646428],
        [74.15430450439482, 14.764862060546818],
        [74.1540298461914, 14.764860153198242],
        [74.1540298461914, 14.765139579772892],
        [74.15374755859403, 14.765139579772892],
        [74.15374755859403, 14.765419960022086],
        [74.15347290039068, 14.765418052673283],
        [74.15347290039068, 14.765693664550781],
        [74.15319824218761, 14.765693664550781],
        [74.15319824218761, 14.765972137451172],
        [74.1529159545899, 14.765970230102596],
        [74.1529159545899, 14.766249656677246],
        [74.15264129638666, 14.766249656677246],
        [74.15264129638666, 14.76653003692627],
        [74.15235900878929, 14.76653003692627],
        [74.15235900878929, 14.766811370849666],
        [74.15208435058611, 14.766805648803768],
        [74.15208435058611, 14.767084121704102],
        [74.15180206298834, 14.767084121704102],
        [74.15180206298834, 14.7673597335816],
        [74.15152740478533, 14.767351150512809],
        [74.15152740478533, 14.767640113830623],
        [74.15125274658232, 14.767640113830623],
        [74.15125274658232, 14.76792144775385],
        [74.15097045898438, 14.767915725708121],
        [74.15097045898438, 14.768194198608455],
        [74.15069580078153, 14.768194198608455],
        [74.15069580078153, 14.768471717834586],
        [74.15040588378906, 14.768471717834586],
        [74.15040588378906, 14.769030570983944],
        [74.15013885498058, 14.769030570983944],
        [74.15013885498058, 14.76931095123291],
        [74.1498641967774, 14.769306182861442],
        [74.1498641967774, 14.769583702087516],
        [74.14958190917963, 14.769583702087516],
        [74.14958190917963, 14.769860267639103],
        [74.14930725097679, 14.769860267639103],
        [74.14930725097679, 14.770140647888297],
        [74.14902496337908, 14.770137786865234],
        [74.14902496337908, 14.770416259765625],
        [74.14875030517584, 14.770416259765625],
        [74.14875030517584, 14.7706937789917],
        [74.14847564697283, 14.7706937789917],
        [74.14847564697283, 14.77097225189209],
        [74.14819335937528, 14.770970344543457],
        [74.14819335937528, 14.771249771118164],
        [74.14791870117188, 14.771249771118164],
        [74.14791870117188, 14.771531105041618],
        [74.1476364135745, 14.771531105041618],
        [74.1476364135745, 14.771810531616268],
        [74.1473617553711, 14.771806716918945],
        [74.1473617553711, 14.772082328796444],
        [74.14708709716808, 14.772082328796444],
        [74.14708709716808, 14.772359848022518],
        [74.14679718017584, 14.772359848022518],
        [74.14679718017584, 14.772920608520451],
        [74.14653015136713, 14.772915840148983],
        [74.14653015136713, 14.77319526672369],
        [74.14624786376959, 14.773189544677734],
        [74.14624786376959, 14.773479461669979],
        [74.14569091796898, 14.773472785949764],
        [74.14569091796898, 14.77378940582281],
        [74.14569091796898, 14.774311065673771],
        [74.1454162597658, 14.774304389953727],
        [74.1454162597658, 14.774861335754508],
        [74.14514160156278, 14.774861335754508],
        [74.14514160156278, 14.775140762329158],
        [74.14485931396501, 14.775140762329158],
        [74.14485931396501, 14.775421142578125],
        [74.144584655762, 14.775417327880973],
        [74.144584655762, 14.775973320007438],
        [74.14402770996122, 14.775971412658691],
        [74.14402770996122, 14.776811599731445],
        [74.14375305175787, 14.776806831359806],
        [74.14375305175787, 14.777083396911621],
        [74.14319610595709, 14.777083396911621],
        [74.14319610595709, 14.777360916137695],
        [74.14292144775408, 14.777360916137695],
        [74.14292144775408, 14.77792072296154],
        [74.1423645019533, 14.77791690826416],
        [74.1423645019533, 14.77819538116455],
        [74.14180755615251, 14.77819538116455],
        [74.14180755615251, 14.778472900390625],
        [74.1415328979495, 14.778470993042049],
        [74.1415328979495, 14.77902889251709],
        [74.14097595214872, 14.77902889251709],
        [74.14097595214872, 14.779309272766113],
        [74.14041900634777, 14.779305458068904],
        [74.14041900634777, 14.779582977294979],
        [74.14015197753929, 14.779581069946403],
        [74.14015197753929, 14.780140876770076],
        [74.13957977294945, 14.780140876770076],
        [74.13957977294945, 14.780419349670467],
        [74.13874816894548, 14.780417442321834],
        [74.13874816894548, 14.780693054199162],
        [74.13847351074247, 14.780693054199162],
        [74.13847351074247, 14.780417442321834],
        [74.13791656494169, 14.780419349670467],
        [74.13791656494169, 14.780140876770076],
        [74.13764190673834, 14.780140876770076],
        [74.13764190673834, 14.77986145019537],
        [74.13735961914074, 14.77986145019537],
        [74.13735961914074, 14.779582977294979],
        [74.13708496093756, 14.779582977294979],
        [74.13708496093756, 14.779305458068904],
        [74.1367874145509, 14.779309272766113],
        [74.1367874145509, 14.778751373291016],
        [74.13652801513695, 14.778751373291016],
        [74.13652801513695, 14.778470993042049],
        [74.135971069336, 14.778472900390625],
        [74.135971069336, 14.77819538116455],
        [74.13569641113298, 14.77819538116455],
        [74.13569641113298, 14.77791690826416],
        [74.13540649414068, 14.77792072296154],
        [74.13540649414068, 14.777641296386662],
        [74.13458251953125, 14.777641296386662],
        [74.13458251953125, 14.77792072296154],
        [74.13402557373053, 14.77791690826416],
        [74.13402557373053, 14.778472900390625],
        [74.13375091552729, 14.778470993042049],
        [74.13375091552729, 14.77902889251709],
        [74.13346862792974, 14.779026985168514],
        [74.13346862792974, 14.779582977294979],
        [74.13319396972673, 14.779582977294979],
        [74.13319396972673, 14.77986145019537],
        [74.13263702392595, 14.77986145019537],
        [74.13263702392595, 14.780140876770076],
        [74.13236236572294, 14.780140876770076],
        [74.13236236572294, 14.780419349670467],
        [74.12821197509777, 14.780419349670467],
        [74.12821197509777, 14.780140876770076],
        [74.12763977050793, 14.780140876770076],
        [74.12763977050793, 14.779581069946403],
        [74.12735748291021, 14.779582977294979],
        [74.12735748291021, 14.779305458068904],
        [74.12708282470697, 14.779309272766113],
        [74.12708282470697, 14.77902889251709],
        [74.12686920166027, 14.77902889251709],
        [74.12652587890642, 14.77902889251709],
        [74.12652587890642, 14.778470993042049],
        [74.12625122070318, 14.778472900390625],
        [74.12625122070318, 14.77791690826416],
        [74.12596893310564, 14.77792072296154],
        [74.12596893310564, 14.777641296386662],
        [74.1256866455081, 14.777641296386662],
        [74.1256866455081, 14.776811599731445],
        [74.12541961669922, 14.776811599731445],
        [74.12541961669922, 14.776542663574219],
        [74.1256866455081, 14.776542663574219],
        [74.1256866455081, 14.776250839233512],
        [74.12541961669922, 14.776250839233512],
        [74.12541961669922, 14.775971412658691],
        [74.12569427490263, 14.775973320007438],
        [74.12569427490263, 14.774861335754508],
        [74.12541961669922, 14.774861335754508],
        [74.12541961669922, 14.774584770202694],
        [74.1218032836914, 14.774582862854118],
        [74.1218032836914, 14.774304389953727],
        [74.1215286254884, 14.774304389953727],
        [74.1215286254884, 14.774582862854118],
        [74.12014007568365, 14.774584770202694],
        [74.12014007568365, 14.774861335754508],
        [74.11904144287138, 14.774861335754508],
        [74.11904144287138, 14.775421142578125],
        [74.1187515258789, 14.775417327880973],
        [74.1187515258789, 14.775694847107047],
        [74.11902618408232, 14.775694847107047],
        [74.11902618408232, 14.775973320007438],
        [74.1187515258789, 14.775971412658691],
        [74.1187515258789, 14.776250839233512],
        [74.11904144287138, 14.776250839233512],
        [74.11904144287138, 14.776542663574219],
        [74.1187515258789, 14.776542663574219],
        [74.1187515258789, 14.77792072296154],
        [74.11902618408232, 14.77791690826416],
        [74.11902618408232, 14.77819538116455],
        [74.1195831298831, 14.77819538116455],
        [74.1195831298831, 14.778472900390625],
        [74.12014007568365, 14.778470993042049],
        [74.12014007568365, 14.77902889251709],
        [74.12041473388689, 14.779026985168514],
        [74.12041473388689, 14.779582977294979],
        [74.12097167968744, 14.779581069946403],
        [74.12097167968744, 14.780140876770076],
        [74.12124633789068, 14.780138969421444],
        [74.12124633789068, 14.780693054199162],
        [74.1215286254884, 14.780691146850586],
        [74.1215286254884, 14.78152942657482],
        [74.12124633789068, 14.781550407409668],
        [74.12124633789068, 14.781811714172363],
        [74.12097167968744, 14.781805038452092],
        [74.12097167968744, 14.782361030578613],
        [74.1206970214846, 14.782349586486873],
        [74.1206970214846, 14.782921791076717],
        [74.12041473388689, 14.782917022705078],
        [74.12041473388689, 14.783471107482967],
        [74.12014007568365, 14.783471107482967],
        [74.12014007568365, 14.783752441406193],
        [74.11985778808611, 14.783752441406193],
        [74.11985778808611, 14.784310340881461],
        [74.1195831298831, 14.784304618835506],
        [74.1195831298831, 14.784860610961971],
        [74.11930847167969, 14.784860610961971],
        [74.11930847167969, 14.785420417785645],
        [74.11902618408232, 14.785417556762752],
        [74.11902618408232, 14.785973548889217],
        [74.1187515258789, 14.785970687866325],
        [74.1187515258789, 14.786252021789608],
        [74.11846923828153, 14.786252021789608],
        [74.11846923828153, 14.786529541015682],
        [74.11818695068365, 14.786529541015682],
        [74.11818695068365, 14.786809921264648],
        [74.1176376342774, 14.786806106567496],
        [74.1176376342774, 14.78708362579357],
        [74.11736297607439, 14.78708362579357],
        [74.11736297607439, 14.787362098693961],
        [74.11708068847679, 14.787359237670898],
        [74.11708068847679, 14.787639617920036],
        [74.11680603027361, 14.787639617920036],
        [74.11680603027361, 14.787919998169002],
        [74.1165313720706, 14.787918090820426],
        [74.1165313720706, 14.788193702697754],
        [74.11597442626982, 14.788193702697754],
        [74.11597442626982, 14.787918090820426],
        [74.1149826049807, 14.787919998169002],
        [74.1145858764649, 14.787919998169002],
        [74.1145858764649, 14.787639617920036],
        [74.11402893066429, 14.787639617920036],
        [74.11402893066429, 14.787078857421932],
        [74.11374664306658, 14.78708362579357],
        [74.11374664306658, 14.78652667999279],
        [74.11319732666033, 14.786529541015682],
        [74.11319732666033, 14.786252021789608],
        [74.11290740966825, 14.786252021789608],
        [74.11290740966825, 14.785970687866325],
        [74.112358093262, 14.785973548889217],
        [74.112358093262, 14.78569316864025],
        [74.11208343505865, 14.78569316864025],
        [74.11208343505865, 14.784860610961971],
        [74.11096954345726, 14.784860610961971],
        [74.11096954345726, 14.784583091735897],
        [74.1101379394533, 14.784583091735897],
        [74.1101379394533, 14.784860610961971],
        [74.109848022461, 14.784860610961971],
        [74.109848022461, 14.785141944885254],
        [74.10958099365234, 14.785140037536678],
        [74.10958099365234, 14.78708362579357],
        [74.10986328125028, 14.78708362579357],
        [74.10986328125028, 14.788193702697754],
        [74.1101379394533, 14.788190841674862],
        [74.1101379394533, 14.789030075073356],
        [74.11042022705084, 14.789030075073356],
        [74.11042022705084, 14.789859771728572],
        [74.1101379394533, 14.789859771728572],
        [74.1101379394533, 14.790419578552246],
        [74.10986328125028, 14.790415763855037],
        [74.10986328125028, 14.790694236755428],
        [74.10958099365234, 14.790694236755428],
        [74.10958099365234, 14.790971755981502],
        [74.1093063354495, 14.790971755981502],
        [74.1093063354495, 14.79143047332775],
        [74.1093063354495, 14.791810989379826],
        [74.10902404785156, 14.791806221008358],
        [74.10902404785156, 14.793193817138615],
        [74.10847473144537, 14.793193817138615],
        [74.10847473144537, 14.793472290039006],
        [74.1073608398438, 14.793472290039006],
        [74.1073608398438, 14.793193817138615],
        [74.10652923583984, 14.793193817138615],
        [74.10652923583984, 14.792916297912711],
        [74.10597229003906, 14.79292011260992],
        [74.10597229003906, 14.792639732360954],
        [74.10549926757841, 14.792639732360954],
        [74.10485839843756, 14.792639732360954],
        [74.10485839843756, 14.792360305786246],
        [74.10346984863298, 14.792360305786246],
        [74.10346984863298, 14.792639732360954],
        [74.1032028198245, 14.792639732360954],
        [74.1032028198245, 14.792360305786246],
        [74.10291290283203, 14.792360305786246],
        [74.10291290283203, 14.792639732360954],
        [74.10208129882824, 14.792639732360954],
        [74.10208129882824, 14.79292011260992],
        [74.10180664062506, 14.792916297912711],
        [74.10180664062506, 14.793472290039006],
        [74.10208129882824, 14.79347038269043],
        [74.10208129882824, 14.793751716613883],
        [74.10291290283203, 14.793751716613883],
        [74.10291290283203, 14.794030189514274],
        [74.10263824462919, 14.79402828216547],
        [74.10263824462919, 14.794190406799373],
        [74.10263824462919, 14.794584274291935],
        [74.1023559570313, 14.794580459594783],
        [74.1023559570313, 14.795140266418457],
        [74.10208129882824, 14.795140266418457],
        [74.10208129882824, 14.795420646667594],
        [74.10180664062506, 14.795415878295955],
        [74.10180664062506, 14.795694351196289],
        [74.10152435302729, 14.795694351196289],
        [74.10152435302729, 14.79597187042242],
        [74.10124969482445, 14.795969963073787],
        [74.10124969482445, 14.79625034332281],
        [74.10096740722673, 14.79625034332281],
        [74.10096740722673, 14.796530723571777],
        [74.1006927490235, 14.796527862548885],
        [74.1006927490235, 14.79736328125],
        [74.10041809082048, 14.797360420227164],
        [74.10041809082048, 14.797642707824707],
        [74.10013580322294, 14.797637939453239],
        [74.10013580322294, 14.798193931579704],
        [74.09986114501953, 14.798193931579704],
        [74.09986114501953, 14.798472404480094],
        [74.09958648681669, 14.798472404480094],
        [74.09958648681669, 14.798193931579704],
        [74.0993041992188, 14.798193931579704],
        [74.0993041992188, 14.798472404480094],
        [74.09874725341803, 14.798469543457031],
        [74.09874725341803, 14.798750877380485],
        [74.09707641601591, 14.798750877380485],
        [74.09707641601591, 14.798469543457031],
        [74.09652709960966, 14.798469543457031],
        [74.09652709960966, 14.798750877380485],
        [74.09541320800776, 14.798750877380485],
        [74.09541320800776, 14.799310684204102],
        [74.09513854980491, 14.799310684204102],
        [74.09513854980491, 14.799860000610352],
        [74.09487152099626, 14.799860000610352],
        [74.09487152099626, 14.800141334533805],
        [74.09458160400396, 14.800141334533805],
        [74.09458160400396, 14.800420761108455],
        [74.09430694580095, 14.800416946411133],
        [74.09430694580095, 14.800972938537598],
        [74.09401702880865, 14.800970077514705],
        [74.09401702880865, 14.801251411437988],
        [74.09375, 14.801251411437988],
        [74.09375, 14.801530838012638],
        [74.09347534179716, 14.801528930664062],
        [74.09347534179716, 14.802360534668026],
        [74.09319305419922, 14.802360534668026],
        [74.09319305419922, 14.802640914916992],
        [74.09291839599621, 14.802639007568416],
        [74.09291839599621, 14.803194999694881],
        [74.0926361083985, 14.803190231323242],
        [74.0926361083985, 14.804031372070312],
        [74.09291839599621, 14.804031372070312],
        [74.09291839599621, 14.805141448974666],
        [74.09319305419922, 14.805141448974666],
        [74.09319305419922, 14.805421829223633],
        [74.09347534179716, 14.805417060851994],
        [74.09347534179716, 14.805973052978459],
        [74.09401702880865, 14.805971145629883],
        [74.09401702880865, 14.80625057220459],
        [74.09458160400396, 14.806248664855957],
        [74.09458160400396, 14.806529045104924],
        [74.0962524414063, 14.806520462036133],
        [74.0962524414063, 14.806810379028377],
        [74.0968017578125, 14.806810379028377],
        [74.0968017578125, 14.806520462036133],
        [74.097640991211, 14.806520462036133],
        [74.097640991211, 14.806810379028377],
        [74.09986114501953, 14.806804656982422],
        [74.09986114501953, 14.807083129882812],
        [74.10013580322294, 14.807083129882812],
        [74.10013580322294, 14.806804656982422],
        [74.10041809082048, 14.806804656982422],
        [74.10041809082048, 14.807083129882812],
        [74.10151672363276, 14.807081222534237],
        [74.10151672363276, 14.807360649108944],
        [74.10208129882824, 14.807360649108944],
        [74.10208129882824, 14.807081222534237],
        [74.10236358642584, 14.807083129882812],
        [74.10236358642584, 14.806804656982422],
        [74.10263824462919, 14.806810379028377],
        [74.10263824462919, 14.806520462036133],
        [74.10291290283203, 14.806529045104924],
        [74.10291290283203, 14.805973052978459],
        [74.10346984863298, 14.805973052978459],
        [74.10346984863298, 14.805694580078068],
        [74.1043090820313, 14.805694580078068],
        [74.1043090820313, 14.805417060851994],
        [74.10458374023455, 14.805417060851994],
        [74.10458374023455, 14.805694580078068],
        [74.1051406860351, 14.805694580078068],
        [74.1051406860351, 14.805973052978459],
        [74.10542297363287, 14.805971145629883],
        [74.10542297363287, 14.80625057220459],
        [74.10624694824247, 14.80625057220459],
        [74.10624694824247, 14.806810379028377],
        [74.10791778564476, 14.806804656982422],
        [74.10791778564476, 14.807083129882812],
        [74.10847473144537, 14.807083129882812],
        [74.10847473144537, 14.806529045104924],
        [74.1093063354495, 14.806529045104924],
        [74.1093063354495, 14.806248664855957],
        [74.109848022461, 14.80625057220459],
        [74.109848022461, 14.805971145629883],
        [74.11042022705084, 14.805973052978459],
        [74.11042022705084, 14.805694580078068],
        [74.11069488525408, 14.805694580078068],
        [74.11069488525408, 14.805417060851994],
        [74.11208343505865, 14.805417060851994],
        [74.11208343505865, 14.805973052978459],
        [74.112358093262, 14.805971145629883],
        [74.112358093262, 14.807360649108944],
        [74.11264038085938, 14.807360649108944],
        [74.11264038085938, 14.80764102935791],
        [74.11319732666033, 14.80764102935791],
        [74.11319732666033, 14.806520462036133],
        [74.11347198486334, 14.806529045104924],
        [74.11347198486334, 14.805138587951603],
        [74.11402893066429, 14.805141448974666],
        [74.11402893066429, 14.804580688476562],
        [74.11430358886713, 14.804582595825138],
        [74.11430358886713, 14.80430698394781],
        [74.1145858764649, 14.804311752319279],
        [74.1145858764649, 14.802080154419059],
        [74.11402893066429, 14.802083015441951],
        [74.11402893066429, 14.801251411437988],
        [74.11374664306658, 14.801251411437988],
        [74.11374664306658, 14.800970077514705],
        [74.11347198486334, 14.800972938537598],
        [74.11347198486334, 14.800695419311523],
        [74.11319732666033, 14.800695419311523],
        [74.11319732666033, 14.800416946411133],
        [74.11290740966825, 14.800420761108455],
        [74.11290740966825, 14.800141334533805],
        [74.11264038085938, 14.800141334533805],
        [74.11264038085938, 14.799930572509766],
        [74.11264038085938, 14.799581527709961],
        [74.112358093262, 14.799581527709961],
        [74.112358093262, 14.79930686950695],
        [74.11207580566406, 14.799310684204102],
        [74.11207580566406, 14.799031257629451],
        [74.11152648925787, 14.799031257629451],
        [74.11152648925787, 14.798750877380485],
        [74.11125183105463, 14.798750877380485],
        [74.11125183105463, 14.798469543457031],
        [74.11096954345726, 14.798472404480094],
        [74.11096954345726, 14.798193931579704],
        [74.11125183105463, 14.798193931579704],
        [74.11125183105463, 14.797637939453239],
        [74.11152648925787, 14.797642707824707],
        [74.11152648925787, 14.79708003997797],
        [74.11180877685558, 14.797081947326774],
        [74.11180877685558, 14.796806335449276],
        [74.11347198486334, 14.796811103820914],
        [74.11347198486334, 14.797921180725098],
        [74.11291503906278, 14.79791641235363],
        [74.11291503906278, 14.798472404480094],
        [74.11319732666033, 14.798469543457031],
        [74.11319732666033, 14.799031257629451],
        [74.11347198486334, 14.799031257629451],
        [74.11347198486334, 14.799310684204102],
        [74.11374664306658, 14.79930686950695],
        [74.11374664306658, 14.799581527709961],
        [74.11402893066429, 14.799579620361385],
        [74.11402893066429, 14.799860000610352],
        [74.1142959594726, 14.799860000610352],
        [74.1142959594726, 14.800141334533805],
        [74.1145858764649, 14.800141334533805],
        [74.1145858764649, 14.800420761108455],
        [74.11486053466808, 14.800416946411133],
        [74.11486053466808, 14.800695419311523],
        [74.11513519287115, 14.800695419311523],
        [74.11513519287115, 14.800972938537598],
        [74.11541748046903, 14.800970077514705],
        [74.11541748046903, 14.801251411437988],
        [74.11569213867188, 14.801251411437988],
        [74.11569213867188, 14.801530838012638],
        [74.11597442626982, 14.801528930664062],
        [74.11597442626982, 14.802083015441951],
        [74.11624908447283, 14.802083015441951],
        [74.11624908447283, 14.802360534668026],
        [74.11736297607439, 14.802360534668026],
        [74.11736297607439, 14.802083015441951],
        [74.1176376342774, 14.802083015441951],
        [74.1176376342774, 14.80180454254156],
        [74.11846923828153, 14.801811218261776],
        [74.11846923828153, 14.801530838012638],
        [74.12014007568365, 14.801530838012638],
        [74.12014007568365, 14.801811218261776],
        [74.12041473388689, 14.80180454254156],
        [74.12041473388689, 14.802083015441951],
        [74.12097167968744, 14.802083015441951],
        [74.12097167968744, 14.802360534668026],
        [74.12124633789068, 14.802360534668026],
        [74.12124633789068, 14.802640914916992],
        [74.1215286254884, 14.802639007568416],
        [74.1215286254884, 14.803194999694881],
        [74.12178802490234, 14.803190231323242],
        [74.12178802490234, 14.803750991821346],
        [74.12207794189482, 14.803750991821346],
        [74.12207794189482, 14.804311752319279],
        [74.12236022949219, 14.80430698394781],
        [74.12236022949219, 14.804582595825138],
        [74.12264251709013, 14.804580688476562],
        [74.12264251709013, 14.8048610687257],
        [74.12346649169939, 14.8048610687257],
        [74.12346649169939, 14.805421829223633],
        [74.1237487792971, 14.805417060851994],
        [74.1237487792971, 14.805694580078068],
        [74.12403106689447, 14.805687904358024],
        [74.12403106689447, 14.80625057220459],
        [74.12430572509771, 14.806248664855957],
        [74.12430572509771, 14.806529045104924],
        [74.1245803833009, 14.806520462036133],
        [74.1245803833009, 14.80764102935791],
        [74.12431335449224, 14.80764102935791],
        [74.12431335449224, 14.807918548583984],
        [74.1245803833009, 14.807916641235408],
        [74.1245803833009, 14.808195114135799],
        [74.12431335449224, 14.80819129943859],
        [74.12431335449224, 14.809311866760368],
        [74.1245803833009, 14.809305191040153],
        [74.1245803833009, 14.809861183166618],
        [74.1248626708985, 14.809861183166618],
        [74.1248626708985, 14.8109712600708],
        [74.12541961669922, 14.8109712600708],
        [74.12541961669922, 14.811809539794979],
        [74.1256866455081, 14.811809539794979],
        [74.1256866455081, 14.813749313354549],
        [74.12596893310564, 14.813749313354549],
        [74.12596893310564, 14.815972328186149],
        [74.12625122070318, 14.815972328186149],
        [74.12625122070318, 14.819862365722656],
        [74.12596893310564, 14.81986045837408],
        [74.12596893310564, 14.824590682983398],
        [74.1256866455081, 14.824590682983398],
        [74.1256866455081, 14.825140953063965],
        [74.12541961669922, 14.825138092041072],
        [74.12541961669922, 14.825694084167537],
        [74.12513732910185, 14.825690269470158],
        [74.12513732910185, 14.826251029968319],
        [74.12541961669922, 14.826251029968319],
        [74.12541961669922, 14.826810836791935],
        [74.12569427490263, 14.826804161071891],
        [74.12569427490263, 14.827360153198356],
        [74.12541961669922, 14.827360153198356],
        [74.12541961669922, 14.828194618225211],
        [74.12513732910185, 14.828189849853572],
        [74.12513732910185, 14.82931137084961],
        [74.12484741210938, 14.82931137084961],
        [74.12484741210938, 14.83181190490734],
        [74.1245803833009, 14.831805229187069],
        [74.1245803833009, 14.837361335754451],
        [74.12541961669922, 14.837361335754451],
        [74.12541961669922, 14.837491035461426],
        [74.12541961669922, 14.837641716003418],
        [74.1256866455081, 14.837641716003418],
        [74.1256866455081, 14.837920188903809],
        [74.12596893310564, 14.837917327880916],
        [74.12596893310564, 14.838195800781307],
        [74.12625122070318, 14.838195800781307],
        [74.12625122070318, 14.838471412658805],
        [74.12652587890642, 14.838471412658805],
        [74.12652587890642, 14.839030265808162],
        [74.12680816650413, 14.839030265808162],
        [74.12680816650413, 14.839309692382812],
        [74.12708282470697, 14.839309692382812],
        [74.12708282470697, 14.839590072631779],
        [74.12735748291021, 14.839590072631779],
        [74.12735748291021, 14.839861869812125],
        [74.12792205810553, 14.839861869812125],
        [74.12792205810553, 14.840140342712516],
        [74.12959289550798, 14.840140342712516],
        [74.12959289550798, 14.839861869812125],
        [74.13041687011724, 14.839861869812125],
        [74.13041687011724, 14.84015083312994],
        [74.13041687011724, 14.842362403869629],
        [74.13069152832043, 14.842362403869629],
        [74.13069152832043, 14.844410896301326],
        [74.13069152832043, 14.844584465026912],
        [74.13091278076189, 14.84458160400402],
        [74.13098144531256, 14.846249580383244],
        [74.12821197509777, 14.846249580383244],
        [74.12821197509777, 14.846529960632438],
        [74.12792205810553, 14.846529960632438],
        [74.12792205810553, 14.846811294555664],
        [74.12763977050793, 14.846805572509766],
        [74.12763977050793, 14.8470840454101],
        [74.12569427490263, 14.8470840454101],
        [74.12569427490263, 14.84736156463623],
        [74.1245803833009, 14.847359657287598],
        [74.1245803833009, 14.847640037536621],
        [74.12431335449224, 14.847640037536621],
        [74.12431335449224, 14.847921371460018],
        [74.1237487792971, 14.84791564941412],
        [74.1237487792971, 14.848194122314453],
        [74.12347412109392, 14.848194122314453],
        [74.12347412109392, 14.848471641540584],
        [74.12291717529314, 14.848469734191951],
        [74.12291717529314, 14.848761558532658],
        [74.12264251709013, 14.848761558532658],
        [74.12264251709013, 14.849031448364201],
        [74.12236022949219, 14.849031448364201],
        [74.12236022949219, 14.849310874939079],
        [74.1218032836914, 14.84930610656744],
        [74.1218032836914, 14.849583625793514],
        [74.12124633789068, 14.849583625793514],
        [74.12124633789068, 14.849860191345329],
        [74.12097167968744, 14.849860191345329],
        [74.12097167968744, 14.850139617919979],
        [74.11985778808611, 14.850139617919979],
        [74.11985778808611, 14.850420951843262],
        [74.11930847167969, 14.850416183471793],
        [74.11930847167969, 14.850693702697868],
        [74.1187515258789, 14.850693702697868],
        [74.1187515258789, 14.850972175598258],
        [74.11818695068365, 14.850970268249455],
        [74.11818695068365, 14.851249694824332],
        [74.1176376342774, 14.851249694824332],
        [74.1176376342774, 14.851531028747615],
        [74.11715698242182, 14.851528167724723],
        [74.11708068847679, 14.851528167724723],
        [74.11708068847679, 14.85181999206543],
        [74.1165313720706, 14.851806640625114],
        [74.1165313720706, 14.852082252502441],
        [74.11597442626982, 14.852082252502441],
        [74.11598205566435, 14.852359771728516],
        [74.11541748046903, 14.852359771728516],
        [74.11541748046903, 14.85264110565197],
        [74.11486053466808, 14.85264110565197],
        [74.11486053466808, 14.85292053222662],
        [74.1145858764649, 14.85291576385498],
        [74.1145858764649, 14.853194236755371],
        [74.11402893066429, 14.853194236755371],
        [74.11402893066429, 14.85291576385498],
        [74.1142959594726, 14.85292053222662],
        [74.1142959594726, 14.85264110565197],
        [74.11402893066429, 14.85264110565197],
        [74.11402893066429, 14.852359771728516],
        [74.1142959594726, 14.852359771728516],
        [74.1142959594726, 14.852080345153809],
        [74.1145858764649, 14.852082252502441],
        [74.1145858764649, 14.851806640625114],
        [74.11486053466808, 14.85181999206543],
        [74.11486053466808, 14.851531028747615],
        [74.11541748046903, 14.851531028747615],
        [74.11541748046903, 14.851249694824332],
        [74.11569213867188, 14.851249694824332],
        [74.11569213867188, 14.850970268249455],
        [74.11597442626982, 14.850972175598258],
        [74.11597442626982, 14.850693702697868],
        [74.11624908447283, 14.850693702697868],
        [74.11624908447283, 14.850416183471793],
        [74.11680603027361, 14.850420951843262],
        [74.11680603027361, 14.850139617919979],
        [74.11708068847679, 14.850139617919979],
        [74.11708068847679, 14.849860191345329],
        [74.1176376342774, 14.849860191345329],
        [74.1176376342774, 14.849761962890625],
        [74.1176376342774, 14.849583625793514],
        [74.11791992187494, 14.849583625793514],
        [74.11791992187494, 14.84930610656744],
        [74.11846923828153, 14.849310874939079],
        [74.11846923828153, 14.849031448364201],
        [74.1187515258789, 14.849031448364201],
        [74.1187515258789, 14.848761558532658],
        [74.11904144287138, 14.848761558532658],
        [74.11904144287138, 14.848469734191951],
        [74.1195831298831, 14.848471641540584],
        [74.1195831298831, 14.848194122314453],
        [74.12014007568365, 14.848194122314453],
        [74.12014007568365, 14.84791564941412],
        [74.1206970214846, 14.847921371460018],
        [74.1206970214846, 14.847640037536621],
        [74.12178802490234, 14.847640037536621],
        [74.12178802490234, 14.847359657287598],
        [74.12236022949219, 14.847359657287598],
        [74.12236022949219, 14.847080230712947],
        [74.12319183349615, 14.8470840454101],
        [74.12319183349615, 14.846805572509766],
        [74.1237487792971, 14.846811294555664],
        [74.1237487792971, 14.846529960632438],
        [74.12431335449224, 14.846529960632438],
        [74.12431335449224, 14.846249580383244],
        [74.12484741210938, 14.846249580383244],
        [74.12484741210938, 14.845970153808594],
        [74.1245803833009, 14.84597206115717],
        [74.1245803833009, 14.845137596130314],
        [74.12403106689447, 14.845140457153377],
        [74.12403106689447, 14.844861984252987],
        [74.12291717529314, 14.844861984252987],
        [74.12291717529314, 14.84458160400402],
        [74.12264251709013, 14.844584465026912],
        [74.12264251709013, 14.844305992126522],
        [74.12207794189482, 14.84430980682373],
        [74.12207794189482, 14.844030380249023],
        [74.12040710449213, 14.844030380249023],
        [74.12040710449213, 14.843751907348633],
        [74.11923980712902, 14.843751907348633],
        [74.11680603027361, 14.843751907348633],
        [74.11680603027361, 14.844030380249023],
        [74.11624908447283, 14.844030380249023],
        [74.11624908447283, 14.84430980682373],
        [74.11541748046903, 14.844305992126522],
        [74.11541748046903, 14.844584465026912],
        [74.11486053466808, 14.84458160400402],
        [74.11486053466808, 14.844861984252987],
        [74.11402893066429, 14.844861984252987],
        [74.11402893066429, 14.845140457153377],
        [74.11347198486334, 14.845140457153377],
        [74.11347198486334, 14.845422744750977],
        [74.11319732666033, 14.845417976379451],
        [74.11319732666033, 14.845693588256779],
        [74.11291503906278, 14.845693588256779],
        [74.11291503906278, 14.84597206115717],
        [74.11264038085938, 14.845970153808594],
        [74.11264038085938, 14.846529960632438],
        [74.112358093262, 14.846529960632438],
        [74.112358093262, 14.846811294555664],
        [74.11207580566406, 14.846811294555664],
        [74.11207580566406, 14.847640037536621],
        [74.11180877685558, 14.847640037536621],
        [74.11180877685558, 14.848761558532658],
        [74.11152648925787, 14.848761558532658],
        [74.11152648925787, 14.85181999206543],
        [74.11180877685558, 14.851806640625114],
        [74.11180877685558, 14.855973243713436],
        [74.11152648925787, 14.855970382690543],
        [74.11152648925787, 14.858751296997013],
        [74.11125183105463, 14.858751296997013],
        [74.11125183105463, 14.859582901000977],
        [74.11096954345726, 14.8595809936524],
        [74.11096954345726, 14.860419273376465],
        [74.11068725585955, 14.860419273376465],
        [74.11068725585955, 14.861250877380428],
        [74.11042022705084, 14.861250877380428],
        [74.11042022705084, 14.861809730529785],
        [74.1101379394533, 14.861809730529785],
        [74.1101379394533, 14.862642288208008],
        [74.10986328125028, 14.862639427185172],
        [74.10986328125028, 14.863471031188965],
        [74.10958099365234, 14.863471031188965],
        [74.10958099365234, 14.864031791687069],
        [74.1093063354495, 14.86402702331543],
        [74.1093063354495, 14.864583015441895],
        [74.10901641845703, 14.864581108093319],
        [74.10901641845703, 14.865141868591422],
        [74.10874938964855, 14.86513900756836],
        [74.10874938964855, 14.865693092346248],
        [74.10846710205084, 14.865691184997672],
        [74.10846710205084, 14.866530418395996],
        [74.1081924438476, 14.866530418395996],
        [74.1081924438476, 14.86736202239996],
        [74.10791778564476, 14.86736202239996],
        [74.10791778564476, 14.868192672729435],
        [74.10764312744158, 14.868188858032283],
        [74.10764312744158, 14.869029998779354],
        [74.1073608398438, 14.869029998779354],
        [74.1073608398438, 14.86985969543457],
        [74.1070861816408, 14.869861602783146],
        [74.1070861816408, 14.870694160461426],
        [74.10680389404325, 14.870694160461426],
        [74.10681152343778, 14.87125015258789],
        [74.10652923583984, 14.87125015258789],
        [74.10652923583984, 14.871529579162598],
        [74.10624694824247, 14.871529579162598],
        [74.10624694824247, 14.871809959411735],
        [74.10597229003906, 14.871806144714355],
        [74.10597229003906, 14.87236213684082],
        [74.10569763183605, 14.872360229492244],
        [74.10569763183605, 14.872639656066951],
        [74.10542297363287, 14.872639656066951],
        [74.10542297363287, 14.872929573059196],
        [74.1051406860351, 14.87291622161871],
        [74.1051406860351, 14.873193740844783],
        [74.10485839843756, 14.873193740844783],
        [74.10485839843756, 14.873472213745174],
        [74.10457611083979, 14.873470306396598],
        [74.10457611083979, 14.874030113220272],
        [74.1043090820313, 14.874030113220272],
        [74.1043090820313, 14.874311447143498],
        [74.10404205322283, 14.874311447143498],
        [74.10404205322283, 14.875420570373592],
        [74.10375213623075, 14.875420570373592],
        [74.10375213623075, 14.876811027526912],
        [74.10346984863298, 14.876806259155273],
        [74.10346984863298, 14.877083778381348],
        [74.10319519042997, 14.877083778381348],
        [74.10319519042997, 14.877360343933162],
        [74.10291290283203, 14.877360343933162],
        [74.10291290283203, 14.877640724182129],
        [74.10263824462919, 14.877640724182129],
        [74.10263824462919, 14.877921104431266],
        [74.10236358642584, 14.877916336059627],
        [74.10236358642584, 14.878193855285701],
        [74.10208129882824, 14.878193855285701],
        [74.10208129882824, 14.878472328186092],
        [74.10180664062506, 14.878472328186092],
        [74.10180664062506, 14.878749847412166],
        [74.10151672363276, 14.878741264343319],
        [74.10151672363276, 14.87903118133545],
        [74.10124969482445, 14.87903118133545],
        [74.10124969482445, 14.8793106079101],
        [74.1006927490235, 14.879306793212947],
        [74.1006927490235, 14.879582405090446],
        [74.10041809082048, 14.879579544067383],
        [74.10041809082048, 14.87985992431652],
        [74.10013580322294, 14.87985992431652],
        [74.10013580322294, 14.880141258239803],
        [74.09958648681669, 14.880141258239803],
        [74.09958648681669, 14.880420684814453],
        [74.09929656982422, 14.880420684814453],
        [74.09929656982422, 14.881251335144157],
        [74.09958648681669, 14.881251335144157],
        [74.09958648681669, 14.88236141204834],
        [74.09986114501953, 14.88236141204834],
        [74.09986114501953, 14.883194923400879],
        [74.10013580322294, 14.883194923400879],
        [74.10013580322294, 14.88708305358881],
        [74.10041809082048, 14.88708305358881],
        [74.10041809082048, 14.89097118377697],
        [74.10013580322294, 14.89097118377697],
        [74.10013580322294, 14.891531944274902],
        [74.09986114501953, 14.891527175903434],
        [74.09986114501953, 14.89186000823986],
        [74.09986114501953, 14.892083168029899],
        [74.09958648681669, 14.892081260681152],
        [74.09958648681669, 14.892642021179256],
        [74.0993041992188, 14.892639160156364],
        [74.0993041992188, 14.893193244934082],
        [74.09902954101574, 14.893193244934082],
        [74.09902954101574, 14.893749237060547],
        [74.09874725341803, 14.89373970031744],
        [74.09874725341803, 14.89430999755865],
        [74.09847259521479, 14.894306182861328],
        [74.09847259521479, 14.894862174987793],
        [74.0979080200197, 14.8948593139649],
        [74.0979080200197, 14.895139694213867],
        [74.097640991211, 14.895139694213867],
        [74.097640991211, 14.895420074462834],
        [74.09735870361345, 14.895415306091365],
        [74.09735870361345, 14.895659446716422],
        [74.10331726074224, 14.901479721069393],
        [74.1041717529298, 14.903750419616813],
        [74.11308288574224, 14.911160469055176],
        [74.11733245849621, 14.909611701965332],
        [74.12274169921875, 14.912640571594238],
        [74.12652587890642, 14.91321086883545],
        [74.13204193115229, 14.912650108337516],
        [74.1334228515625, 14.915947914123478],
        [74.13622283935558, 14.919660568237418],
        [74.1393966674807, 14.92046070098877],
        [74.14570617675787, 14.917431831359863],
        [74.14922332763689, 14.9185209274292],
        [74.15389251709007, 14.917269706726131],
        [74.1633911132813, 14.913251876831055],
        [74.16982269287126, 14.909270286560059],
        [74.1753921508789, 14.908919334411621],
        [74.17735290527366, 14.913200378418026],
        [74.17516326904314, 14.916151046752987],
        [74.1738357543947, 14.917940139770508],
        [74.16596221923857, 14.936760902404785],
        [74.16609954834007, 14.939441680908317],
        [74.16966247558594, 14.942180633544922],
        [74.16989898681646, 14.947299957275334],
        [74.17215728759766, 14.951009750366211],
        [74.18036651611334, 14.95304965972906],
        [74.18656158447294, 14.95338058471691],
        [74.19027709960938, 14.95171928405773],
        [74.19248199462902, 14.945009231567383],
        [74.19741821289091, 14.943269729614258],
        [74.20416259765642, 14.937850952148551],
        [74.20405578613304, 14.932100296020565],
        [74.2030868530274, 14.927420616149902],
        [74.21183776855474, 14.921420097351131],
        [74.21926879882812, 14.931380271911621],
        [74.22471618652355, 14.941340446472282],
        [74.24167633056669, 14.946450233459586],
        [74.24386596679688, 14.957489967346305],
        [74.24639892578148, 14.958661079406795],
        [74.25100708007841, 14.960820198059196],
        [74.2565994262697, 14.958421707153377],
        [74.26206970214872, 14.957551956176701],
        [74.2643432617188, 14.963249206542969],
        [74.266586303711, 14.966820716857967],
        [74.27011871337902, 14.966641426086426],
        [74.27095031738281, 14.97153186798107],
        [74.27391052246122, 14.97522163391119],
        [74.27095794677734, 14.980429649353027],
        [74.26171112060575, 14.990760803222656],
        [74.2664413452149, 14.992951393127498],
        [74.27065277099638, 14.994891166687069],
        [74.2715911865235, 14.997741699218864],
        [74.27126312255871, 14.997741699218864],
        [74.27102661132841, 14.998807907104606],
        [74.27291107177757, 15.001461029052848],
        [74.27184295654291, 15.00249195098877],
        [74.2764129638673, 15.009640693664608],
        [74.28186035156256, 15.015571594238338],
        [74.28978729248064, 15.021611213684139],
        [74.28936767578153, 15.026561737060604],
        [74.2890625, 15.030121803283748],
        [74.29310607910162, 15.0337495803833],
        [74.30233764648466, 15.039859771728516],
        [74.30120086669933, 15.044070243835506],
        [74.29499053955078, 15.054291725158691],
        [74.29525756835966, 15.062692642211914],
        [74.28900909423822, 15.065849304199162],
        [74.28721618652366, 15.069360733032227],
        [74.28379058837896, 15.094010353088436],
        [74.27834320068376, 15.095899581909293],
        [74.28106689453125, 15.10182952880865],
        [74.28572845459013, 15.103731155395508],
        [74.2882461547851, 15.105581283569393],
        [74.29061889648443, 15.116630554199219],
        [74.29309082031256, 15.119962692260685],
        [74.29144287109403, 15.123298645019531],
        [74.28971099853521, 15.129969596862907],
        [74.29122924804693, 15.135380744934196],
        [74.28956604003918, 15.143130302429313],
        [74.29258728027372, 15.147679328918514],
        [74.29782867431658, 15.146309852600211],
        [74.30142974853533, 15.149881362915039],
        [74.31086730957054, 15.162031173706112],
        [74.31813049316412, 15.17533016204834],
        [74.32190704345709, 15.184069633483944],
        [74.31697082519554, 15.191069602966365],
        [74.3157730102539, 15.196311950683537],
        [74.3155593872072, 15.203212738037166],
        [74.31445312500006, 15.205610275268498],
        [74.31163024902366, 15.207051277160701],
        [74.31066131591803, 15.208951950073299],
        [74.31005859375, 15.213430404663029],
        [74.30670928955107, 15.218999862670898],
        [74.30668640136724, 15.223200798034725],
        [74.30213165283232, 15.228141784668082],
        [74.29427337646507, 15.231980323791618],
        [74.27867126464872, 15.237190246582031],
        [74.270751953125, 15.23850059509283],
        [74.26351928710938, 15.241881370544377],
        [74.2617492675783, 15.243721961975154],
        [74.25926208496088, 15.249990463256893],
        [74.25752258300793, 15.257629394531307],
        [74.26793670654303, 15.258391380310115],
        [74.27024078369163, 15.260931968689079],
        [74.27275085449247, 15.26777172088623],
        [74.27661895751947, 15.27444934844965],
        [74.282958984375, 15.281299591064453],
        [74.28914642334013, 15.279071807861385],
        [74.29554748535156, 15.283109664916992],
        [74.30002593994169, 15.28230094909668],
        [74.30230712890653, 15.285861015319824],
        [74.30980682373041, 15.283230781555176],
        [74.31841278076189, 15.281920433044377],
        [74.33412933349632, 15.282861709594783],
        [74.34165191650385, 15.2929306030274],
        [74.34044647216803, 15.296800613403377],
        [74.32797241210938, 15.31326961517334],
        [74.32334136962885, 15.329590797424373],
        [74.32840728759788, 15.343400001525822],
        [74.32595062255865, 15.34724044799816],
        [74.32261657714861, 15.357250213623047],
        [74.32364654541038, 15.362031936645565],
        [74.32859802246116, 15.365470886230582],
        [74.328369140625, 15.367251396179313],
        [74.31282806396513, 15.37488174438488],
        [74.30568695068365, 15.380501747131404],
        [74.28996276855463, 15.381219863891602],
        [74.28624725341803, 15.382600784301815],
        [74.28263854980491, 15.388620376586857],
        [74.28454589843773, 15.40151119232172],
        [74.28144836425781, 15.409041404724178],
        [74.27735900878918, 15.41486930847168],
        [74.27561950683622, 15.42390060424816],
        [74.28267669677763, 15.4332914352417],
        [74.27999877929716, 15.435931205749625],
        [74.282958984375, 15.437480926513786],
        [74.2825164794923, 15.443120956420898],
        [74.27989959716814, 15.450760841369743],
        [74.27255249023466, 15.465239524841309],
        [74.256332397461, 15.489520072937125],
        [74.25724792480474, 15.496761322021598],
        [74.26082611084001, 15.50084114074707],
        [74.26814270019554, 15.505921363830566],
        [74.28189849853544, 15.51295185089117],
        [74.2837524414063, 15.51945018768322],
        [74.2827987670899, 15.520541191101131],
        [74.2872772216798, 15.52600002288824],
        [74.28559875488298, 15.53417110443121],
        [74.28292846679705, 15.541570663452205],
        [74.27894592285173, 15.548700332641602],
        [74.27258300781278, 15.554261207580623],
        [74.26988983154325, 15.561150550842342],
        [74.26763916015636, 15.563439369201717],
        [74.26457977294933, 15.560791015625114],
        [74.25431060791033, 15.565830230713004],
        [74.25441741943365, 15.57250118255621],
        [74.25741577148438, 15.575499534606934],
        [74.25637054443388, 15.582560539245662],
        [74.26515960693371, 15.590160369873104],
        [74.26545715332037, 15.60160064697277],
        [74.26854705810575, 15.609588623046989],
        [74.26747131347673, 15.617049217224064],
        [74.26249694824213, 15.62291145324707],
        [74.25942993164074, 15.62446117401123],
        [74.25435638427734, 15.623131752014217],
        [74.25253295898466, 15.623820304870549],
        [74.25451660156267, 15.62961196899414],
        [74.25723266601568, 15.633939743042049],
        [74.25647735595709, 15.646800994873047],
        [74.25525665283197, 15.654210090637207],
        [74.24504089355474, 15.665631294250431],
        [74.24191284179705, 15.66585922241211],
        [74.23683929443365, 15.6618909835816],
        [74.23158264160168, 15.666520118713379],
        [74.22405242919928, 15.658870697021541],
        [74.22843170166021, 15.654450416565055],
        [74.22879791259766, 15.652960777282715],
        [74.22409057617193, 15.650311470031795],
        [74.21324920654325, 15.649029731750602],
        [74.20986175537104, 15.654130935668888],
        [74.21196746826189, 15.658499717712402],
        [74.21154022216825, 15.660979270935059],
        [74.19632720947283, 15.664911270141658],
        [74.19447326660156, 15.672140121459904],
        [74.19811248779303, 15.673650741577262],
        [74.19769287109392, 15.67893028259283],
        [74.19013214111351, 15.680980682373104],
        [74.18302917480469, 15.677350044250545],
        [74.1786727905274, 15.671420097351131],
        [74.17543792724632, 15.670160293579102],
        [74.17343139648432, 15.652051925659237],
        [74.16593933105497, 15.652379989624023],
        [74.16323089599632, 15.648590087890625],
        [74.15497589111328, 15.649770736694393],
        [74.14685058593756, 15.648660659790039],
        [74.14277648925781, 15.647110939025879],
        [74.12896728515636, 15.650239944458065],
        [74.12602996826178, 15.650289535522518],
        [74.1213684082033, 15.653670310974064],
        [74.11205291748064, 15.655031204223746],
        [74.10453033447294, 15.65920162200922],
        [74.09802246093756, 15.665970802307129],
        [74.09652709960966, 15.670849800109863],
        [74.10056304931635, 15.672349929809627],
        [74.10624694824247, 15.670640945434627],
        [74.11752319335949, 15.670780181884766],
        [74.12700653076178, 15.673740386962947],
        [74.13359832763689, 15.678400993347168],
        [74.13887786865263, 15.686229705810547],
        [74.14042663574213, 15.69456005096447],
        [74.13889312744152, 15.699031829833984],
        [74.1415328979495, 15.700361251831168],
        [74.13891601562517, 15.706340789794979],
        [74.13553619384766, 15.70925235748291],
        [74.13475036621111, 15.716032028198242],
        [74.13062286376976, 15.717921257019043],
        [74.13485717773466, 15.72218132019043],
        [74.14045715332048, 15.72167110443121],
        [74.14186859130882, 15.723178863525504],
        [74.1429672241211, 15.727190971374455],
        [74.14737701416021, 15.730071067810115],
        [74.15051269531278, 15.731700897216854],
        [74.15534210205095, 15.732281684875545],
        [74.1601333618164, 15.734349250793457],
        [74.16226959228521, 15.742071151733455],
        [74.16767120361357, 15.749250411987305],
        [74.1711502075197, 15.749950408935547],
        [74.1970672607423, 15.750221252441406],
        [74.20439910888678, 15.753400802612248],
        [74.21132659912138, 15.758589744567871],
        [74.21462249755854, 15.765991210937614],
        [74.21570587158209, 15.773870468139648],
        [74.21740722656273, 15.778760910034123],
        [74.22247314453142, 15.781701087951603],
        [74.227348327637, 15.785671234130916],
        [74.23549652099615, 15.786830902099666],
        [74.23892974853521, 15.784540176391715],
        [74.24034118652372, 15.78075027465826],
        [74.23516845703153, 15.759611129760799],
        [74.2352371215822, 15.7539701461792],
        [74.23898315429716, 15.752140045166072],
        [74.24386596679688, 15.74440002441412],
        [74.25006866455107, 15.741180419921932],
        [74.25892639160156, 15.743391036987362],
        [74.2660675048831, 15.74702072143566],
        [74.27086639404325, 15.751910209655819],
        [74.27400970458984, 15.753120422363281],
        [74.28494262695318, 15.743310928344783],
        [74.29216766357445, 15.739311218261832],
        [74.31299591064482, 15.748299598693961],
        [74.31465148925787, 15.75214862823492],
        [74.31864929199224, 15.753410339355582],
        [74.32408142089872, 15.756529808044434],
        [74.32495117187506, 15.76130008697504],
        [74.32756805419939, 15.768200874328727],
        [74.32975006103538, 15.770380020141658],
        [74.33454132080107, 15.767679214477539],
        [74.34227752685547, 15.765521049499625],
        [74.34677124023466, 15.763110160827637],
        [74.3509368896485, 15.763360023498649],
        [74.3648529052735, 15.773651123046989],
        [74.36789703369163, 15.781599998474235],
        [74.37084197998041, 15.782181739807186],
        [74.37551116943365, 15.796719551086426],
        [74.37580108642572, 15.801200866699219],
        [74.37454986572271, 15.804521560669002],
        [74.374008178711, 15.810441017150936],
        [74.36915588378923, 15.812161445617676],
        [74.36637115478538, 15.81549072265625],
        [74.37074279785162, 15.817220687866268],
        [74.37336730957031, 15.825832366943303],
        [74.37454986572271, 15.827500343322754],
        [74.38626861572277, 15.83326244354248],
        [74.38944244384788, 15.836311340332088],
        [74.38931274414091, 15.84414005279541],
        [74.38741302490246, 15.847111701965389],
        [74.35376739501959, 15.848100662231388],
        [74.34639739990263, 15.849349975586051],
        [74.34453582763689, 15.852050781249943],
        [74.35340118408226, 15.855731964111442],
        [74.35295867919922, 15.859240531921387],
        [74.3640975952149, 15.870161056518555],
        [74.36702728271479, 15.87109088897705],
        [74.36743164062517, 15.87321853637701],
        [74.3699798583985, 15.875680923461971],
        [74.37061309814482, 15.880002021789664],
        [74.37052154541016, 15.890390396118278],
        [74.37412261962908, 15.891610145568848],
        [74.37448120117205, 15.889941215515137],
        [74.37277984619158, 15.887689590454158],
        [74.37655639648432, 15.884941101074276],
        [74.38313293457037, 15.877761840820426],
        [74.38556671142595, 15.87323093414318],
        [74.38794708251982, 15.863941192627067],
        [74.38849639892601, 15.857219696044979],
        [74.39228820800787, 15.857449531555233],
        [74.39343261718756, 15.851770401000977],
        [74.40312194824219, 15.848959922790641],
        [74.406509399414, 15.851900100708121],
        [74.4115829467774, 15.852590560913029],
        [74.41221618652355, 15.857639312744254],
        [74.39919281005865, 15.874349594116211],
        [74.40254974365234, 15.878260612487736],
        [74.40164184570318, 15.887269973754883],
        [74.39930725097656, 15.895771026611385],
        [74.39836120605469, 15.897710800170955],
        [74.39234924316435, 15.899261474609432],
        [74.3918685913086, 15.90115928649908],
        [74.40203857421898, 15.911050796508732],
        [74.41226959228533, 15.925200462341365],
        [74.43522644042991, 15.95116996765148],
        [74.44229125976568, 15.965478897094783],
        [74.4439468383789, 15.974330902099666],
        [74.44995880126982, 15.983230590820426],
        [74.44847106933588, 15.98640060424816],
        [74.44843292236357, 15.988290786743107],
        [74.45017242431652, 15.992601394653377],
        [74.44966888427734, 15.998531341552791],
        [74.45336914062528, 16.005809783935604],
        [74.45643615722685, 16.008909225463924],
        [74.46391296386713, 16.02256011962902],
        [74.46710205078153, 16.030269622802848],
        [74.46765899658209, 16.033649444580135],
        [74.47027587890625, 16.040540695190487],
        [74.46795654296892, 16.046699523925838],
        [74.4638366699221, 16.04773139953619],
        [74.45587158203153, 16.052160263061523],
        [74.44803619384794, 16.052400588989258],
        [74.44048309326189, 16.057020187378043],
        [74.43633270263678, 16.056730270385742],
        [74.43213653564459, 16.055131912231502],
        [74.4229125976562, 16.053190231323356],
        [74.41554260253912, 16.051059722900504],
        [74.41121673584013, 16.049011230468807],
        [74.40960693359375, 16.04709815979004],
        [74.40931701660185, 16.045330047607536],
        [74.39607238769531, 16.039600372314567],
        [74.39005279541044, 16.03512954711914],
        [74.37858581542974, 16.036621093750057],
        [74.378227233887, 16.03926086425787],
        [74.37888336181658, 16.043399810791072],
        [74.38173675537126, 16.053569793701115],
        [74.38014221191435, 16.054767608642578],
        [74.37075042724632, 16.051019668579215],
        [74.36815643310558, 16.052959442138786],
        [74.37098693847685, 16.059570312499943],
        [74.3751068115235, 16.063369750976676],
        [74.38008117675787, 16.069580078125057],
        [74.38060760498064, 16.07302093505865],
        [74.37959289550793, 16.076820373535213],
        [74.38099670410156, 16.07826042175293],
        [74.38680267334013, 16.078090667724666],
        [74.3904571533206, 16.07992935180664],
        [74.39724731445318, 16.078388214111328],
        [74.39830017089838, 16.080118179321346],
        [74.39731597900419, 16.087129592895508],
        [74.4040603637697, 16.090978622436637],
        [74.40760803222685, 16.0906982421875],
        [74.41520690917974, 16.091819763183537],
        [74.41607666015642, 16.096000671386662],
        [74.41845703125006, 16.100891113281307],
        [74.4227600097658, 16.102569580078182],
        [74.4345932006836, 16.110620498657227],
        [74.43795013427763, 16.10816001892084],
        [74.44452667236328, 16.10518074035656],
        [74.4549102783206, 16.10267066955572],
        [74.46000671386724, 16.102910995483455],
        [74.46454620361328, 16.102111816406193],
        [74.4779663085938, 16.091899871826115],
        [74.47891235351568, 16.087890625000057],
        [74.48185729980486, 16.08696937561035],
        [74.48908996582026, 16.08732032775879],
        [74.49044799804705, 16.090370178222713],
        [74.49279785156256, 16.09283828735363],
        [74.49263000488304, 16.09553909301752],
        [74.49349975585955, 16.098592758178825],
        [74.49640655517578, 16.10157966613764],
        [74.4971084594726, 16.112369537353516],
        [74.49095153808622, 16.14200019836437],
        [74.48710632324241, 16.143091201782283],
        [74.48580932617216, 16.14698982238764],
        [74.48996734619163, 16.147222518921012],
        [74.49466705322283, 16.15504074096674],
        [74.4953231811524, 16.16900062561041],
        [74.49781799316435, 16.18133926391613],
        [74.5017700195313, 16.1854305267334],
        [74.509086608887, 16.187839508056584],
        [74.51287841796903, 16.188262939453182],
        [74.51288604736357, 16.189399719238338],
        [74.5125274658206, 16.19584083557129],
        [74.51081085205107, 16.198818206787166],
        [74.51322937011741, 16.199220657348746],
        [74.51329803466808, 16.201229095458984],
        [74.50316619873075, 16.21156120300293],
        [74.50211334228516, 16.21413993835455],
        [74.50296020507841, 16.216220855713004],
        [74.50772094726568, 16.221281051635685],
        [74.50833129882841, 16.22294044494629],
        [74.5031127929688, 16.231040954589787],
        [74.49507141113281, 16.235969543457145],
        [74.49482727050798, 16.23801994323742],
        [74.48412322998058, 16.246730804443473],
        [74.4807968139649, 16.244329452514762],
        [74.47606658935575, 16.239030838012752],
        [74.47318267822288, 16.238519668579215],
        [74.46343231201178, 16.241830825805664],
        [74.4614562988283, 16.243778228759766],
        [74.4612274169923, 16.24843025207531],
        [74.4427795410158, 16.249559402465934],
        [74.44184112548828, 16.254150390625],
        [74.44094085693382, 16.25266075134283],
        [74.43633270263678, 16.25476837158203],
        [74.42253112792963, 16.25745010375988],
        [74.42082214355486, 16.26387977600109],
        [74.42159271240251, 16.271459579467773],
        [74.42122650146501, 16.280590057373047],
        [74.41802215576178, 16.280929565429744],
        [74.40075683593778, 16.2762508392334],
        [74.3912658691408, 16.27560043334961],
        [74.39038085937506, 16.290241241455192],
        [74.38742065429699, 16.290348052978572],
        [74.3800659179688, 16.29350090026861],
        [74.37377166748053, 16.290319442748967],
        [74.36960601806663, 16.290721893310547],
        [74.36257171630865, 16.287139892578125],
        [74.35853576660162, 16.288801193237248],
        [74.35611724853544, 16.290920257568416],
        [74.35459136962919, 16.28976058959961],
        [74.353286743164, 16.29183959960949],
        [74.35169982910162, 16.28820991516119],
        [74.36033630371105, 16.281909942626953],
        [74.35986328125006, 16.279609680175838],
        [74.35514068603544, 16.274490356445426],
        [74.35212707519543, 16.274482727050895],
        [74.35074615478521, 16.27625846862793],
        [74.34935760498053, 16.275060653686467],
        [74.34945678710949, 16.273269653320312],
        [74.34390258789091, 16.272121429443473],
        [74.3404159545899, 16.267570495605526],
        [74.33705139160185, 16.26572036743164],
        [74.33383941650385, 16.268070220947322],
        [74.33065795898438, 16.27293968200695],
        [74.32868957519526, 16.279430389404297],
        [74.3293228149414, 16.281061172485295],
        [74.33215332031267, 16.28409957885748],
        [74.331787109375, 16.286180496215934],
        [74.33637237548828, 16.290170669555778],
        [74.34268188476568, 16.288770675659293],
        [74.34871673583979, 16.29146957397461],
        [74.34484863281278, 16.303268432617244],
        [74.34115600585943, 16.30282020568842],
        [74.3401260375976, 16.303989410400504],
        [74.34233093261741, 16.305091857910156],
        [74.34059143066412, 16.31162071228033],
        [74.33210754394543, 16.31076049804699],
        [74.33101654052751, 16.315021514892692],
        [74.33152008056669, 16.31641006469721],
        [74.33569335937528, 16.315000534057674],
        [74.34001159667974, 16.31693077087408],
        [74.33727264404291, 16.32339286804205],
        [74.3333587646485, 16.323741912841854],
        [74.32993316650413, 16.322362899780217],
        [74.3230209350586, 16.325122833252067],
        [74.32401275634771, 16.32742118835455],
        [74.32727813720709, 16.329200744628963],
        [74.32911682128906, 16.332590103149414],
        [74.32480621337896, 16.336669921875114],
        [74.3234558105471, 16.339891433715877],
        [74.3282318115235, 16.341100692749023],
        [74.3343963623048, 16.340522766113224],
        [74.33156585693376, 16.346490859985295],
        [74.32370758056652, 16.35361099243164],
        [74.33152008056669, 16.355569839477596],
        [74.3322372436524, 16.35695075988781],
        [74.32762908935553, 16.368110656738338],
        [74.3277664184572, 16.370340347289982],
        [74.32546234130876, 16.37924957275385],
        [74.33255004882812, 16.375570297241154],
        [74.33474731445341, 16.3828582763673],
        [74.33500671386736, 16.388719558715877],
        [74.33596038818376, 16.3896427154541],
        [74.34033203125017, 16.390962600708008],
        [74.34306335449224, 16.3906707763673],
        [74.34577178955101, 16.38952064514166],
        [74.34771728515653, 16.386011123657227],
        [74.34858703613281, 16.380382537841854],
        [74.35108184814459, 16.376760482788086],
        [74.35189819335966, 16.366300582885742],
        [74.36165618896513, 16.364749908447322],
        [74.35961151123064, 16.378820419311467],
        [74.36868286132841, 16.391170501708928],
        [74.36911010742199, 16.39432907104498],
        [74.36782073974626, 16.398469924926815],
        [74.36457061767595, 16.403238296508846],
        [74.35954284667997, 16.40398979187006],
        [74.34916687011724, 16.403940200805664],
        [74.3492813110351, 16.399400711059627],
        [74.34265136718767, 16.40049171447754],
        [74.34194183349615, 16.405210494995117],
        [74.33818054199247, 16.413370132446346],
        [74.33728027343767, 16.420209884643498],
        [74.34375, 16.425540924072266],
        [74.34052276611345, 16.432979583740234],
        [74.34062194824241, 16.436170578002987],
        [74.34223175048822, 16.439569473266545],
        [74.34246063232439, 16.442329406738395],
        [74.33904266357422, 16.444860458374023],
        [74.33876037597685, 16.44664192199707],
        [74.3399887084961, 16.448129653930778],
        [74.33886718750006, 16.452161788940487],
        [74.33555603027338, 16.45308113098156],
        [74.3305282592774, 16.452791213989258],
        [74.32193756103521, 16.4500408172608],
        [74.31482696533203, 16.453199386596623],
        [74.30709075927763, 16.459590911865348],
        [74.30464935302729, 16.460790634155387],
        [74.3023986816408, 16.46108055114746],
        [74.30139923095714, 16.45906066894537],
        [74.29631805419922, 16.459011077880916],
        [74.2964324951173, 16.466711044311637],
        [74.29483795166038, 16.47435188293457],
        [74.29757690429699, 16.48161125183117],
        [74.30055236816412, 16.499631881713924],
        [74.29989624023455, 16.50263023376465],
        [74.28894805908209, 16.50378036499029],
        [74.28330993652361, 16.49603271484375],
        [74.28051757812506, 16.489360809326286],
        [74.28099822998041, 16.485790252685547],
        [74.28253936767595, 16.48257064819336],
        [74.2784271240235, 16.479639053344727],
        [74.26877593994152, 16.476030349731445],
        [74.26516723632807, 16.482400894165096],
        [74.26452636718756, 16.48545074462902],
        [74.26214599609392, 16.48867034912115],
        [74.26008605957031, 16.490907669067383],
        [74.25315856933588, 16.49488067626953],
        [74.25570678710943, 16.496770858764705],
        [74.26313018798828, 16.4989528656007],
        [74.27864074707037, 16.50102043151861],
        [74.28575897216808, 16.503370285034123],
        [74.28668975830107, 16.506771087646484],
        [74.28440093994169, 16.516479492187557],
        [74.28190612792997, 16.520551681518555],
        [74.27662658691423, 16.52061080932623],
        [74.27551269531267, 16.52515983581543],
        [74.27197265625006, 16.53343009948736],
        [74.27155303955084, 16.538660049438533],
        [74.27636718750028, 16.544532775878963],
        [74.28431701660162, 16.543092727661246],
        [74.28822326660185, 16.53947067260748],
        [74.28672790527338, 16.529409408569393],
        [74.28713226318376, 16.527320861816406],
        [74.28955841064482, 16.5252628326416],
        [74.28961944580095, 16.52256965637207],
        [74.29483795166038, 16.52929115295416],
        [74.29447937011724, 16.53154182434082],
        [74.29762268066423, 16.531930923461914],
        [74.30692291259777, 16.52917098999029],
        [74.3089218139649, 16.523998260498104],
        [74.30845642089855, 16.521871566772404],
        [74.31157684326172, 16.506927490234432],
        [74.31346893310564, 16.50325965881359],
        [74.31411743164068, 16.502098083496094],
        [74.32145690917986, 16.502670288085938],
        [74.32390594482433, 16.506298065185604],
        [74.32267761230474, 16.519001007080078],
        [74.3232192993164, 16.532209396362305],
        [74.32216644287138, 16.539051055908203],
        [74.32222747802751, 16.544500350952205],
        [74.32377624511719, 16.551460266113395],
        [74.32940673828153, 16.55347061157238],
        [74.33215332031267, 16.553699493408203],
        [74.34020233154303, 16.550132751464957],
        [74.34612274169928, 16.546449661254883],
        [74.35507202148438, 16.553110122680664],
        [74.35974884033232, 16.55368995666504],
        [74.36308288574219, 16.556201934814453],
        [74.36561584472662, 16.557069778442326],
        [74.3692321777346, 16.55327033996582],
        [74.36287689208984, 16.548561096191463],
        [74.36464691162132, 16.543680191040153],
        [74.36258697509794, 16.53874015808111],
        [74.36611938476562, 16.537639617920036],
        [74.37110137939459, 16.537651062011776],
        [74.38543701171898, 16.52740287780756],
        [74.3899230957033, 16.52790069580078],
        [74.39289855957037, 16.531070709228572],
        [74.39565277099626, 16.535671234130916],
        [74.39550018310547, 16.559522628784237],
        [74.40113067626982, 16.56088829040533],
        [74.40077209472668, 16.563472747802734],
        [74.3983993530274, 16.5654296875],
        [74.40120697021501, 16.57094001770031],
        [74.40216064453142, 16.574729919433707],
        [74.40348052978533, 16.578670501708928],
        [74.41371917724638, 16.58023834228527],
        [74.42228698730497, 16.5835018157959],
        [74.42883300781267, 16.58272933959961],
        [74.43354034423857, 16.594360351562614],
        [74.44081878662121, 16.59869194030773],
        [74.44698333740251, 16.600339889526424],
        [74.47245788574247, 16.604810714721793],
        [74.47080230712908, 16.614950180053654],
        [74.47136688232439, 16.62178039550787],
        [74.46981048583996, 16.623479843139762],
        [74.46446990966808, 16.633989334106445],
        [74.46283721923828, 16.644489288330078],
        [74.46518707275396, 16.646898269653263],
        [74.46479797363287, 16.65295028686529],
        [74.47013854980474, 16.65518951416027],
        [74.47738647460943, 16.66077995300293],
        [74.48133850097656, 16.654069900512752],
        [74.4858703613283, 16.639120101928825],
        [74.48983764648449, 16.6360702514649],
        [74.49178314209001, 16.632339477539176],
        [74.49550628662115, 16.627670288085994],
        [74.49800872802763, 16.626640319824162],
        [74.50167083740246, 16.62713813781744],
        [74.51029205322283, 16.631450653076286],
        [74.53009033203136, 16.63060951232916],
        [74.55143737792997, 16.633211135864258],
        [74.54937744140642, 16.615520477294922],
        [74.55024719238287, 16.609020233154297],
        [74.54985046386741, 16.592180252075195],
        [74.55459594726568, 16.592420578003043],
        [74.56555938720714, 16.590379714965877],
        [74.56981658935564, 16.581220626831055],
        [74.56510162353521, 16.574630737304688],
        [74.5597000122072, 16.571371078491268],
        [74.55908966064447, 16.56710815429699],
        [74.55966949462885, 16.559749603271598],
        [74.56220245361328, 16.55803108215332],
        [74.56552886962919, 16.557790756225586],
        [74.56907653808594, 16.55639839172369],
        [74.57405090332037, 16.551969528198356],
        [74.58152008056663, 16.55183982849121],
        [74.58152008056663, 16.555000305175838],
        [74.59195709228516, 16.55554962158203],
        [74.59253692626953, 16.557270050048942],
        [74.59162139892601, 16.558830261230526],
        [74.59179687500006, 16.560720443725643],
        [74.59335327148443, 16.563829421997127],
        [74.59588623046886, 16.56439018249523],
        [74.60484313964872, 16.562141418457145],
        [74.61699676513695, 16.567970275878963],
        [74.62264251709001, 16.57364082336437],
        [74.6243896484375, 16.577550888061637],
        [74.6239395141601, 16.581920623779297],
        [74.62695312500028, 16.580930709838924],
        [74.63066101074236, 16.577650070190487],
        [74.63636779785173, 16.57574081420904],
        [74.64051818847668, 16.581939697265682],
        [74.63938140869152, 16.594352722168082],
        [74.63974761962919, 16.599531173706055],
        [74.64113616943376, 16.604690551757756],
        [74.64280700683605, 16.60677146911621],
        [74.64771270751959, 16.608070373535213],
        [74.65174865722685, 16.60697937011713],
        [74.66387176513672, 16.6010799407959],
        [74.6688232421875, 16.602680206298942],
        [74.67529296875017, 16.605989456176815],
        [74.67855072021484, 16.606559753418082],
        [74.69037628173828, 16.604459762573185],
        [74.69338226318376, 16.607271194458008],
        [74.69462585449241, 16.61082077026373],
        [74.69431304931652, 16.61519050598156],
        [74.69122314453153, 16.618141174316463],
        [74.68531036376982, 16.619880676269588],
        [74.67386627197294, 16.628459930419922],
        [74.666000366211, 16.628721237182617],
        [74.66304779052729, 16.632169723510742],
        [74.66050720214872, 16.637580871582088],
        [74.66880035400408, 16.65555191040039],
        [74.67173767089866, 16.663530349731445],
        [74.69610595703125, 16.674980163574276],
        [74.694580078125, 16.6786403656007],
        [74.68727874755876, 16.67901039123535],
        [74.6875228881836, 16.681661605835075],
        [74.68895721435575, 16.684120178222713],
        [74.695610046387, 16.68583106994629],
        [74.69503784179716, 16.692441940307617],
        [74.69593811035162, 16.71054077148449],
        [74.69819641113281, 16.715200424194393],
        [74.70783233642607, 16.71309089660639],
        [74.72244262695318, 16.713291168213004],
        [74.73661804199247, 16.715839385986328],
        [74.74820709228521, 16.722120285034293],
        [74.75409698486334, 16.726190567016715],
        [74.75614166259783, 16.729629516601506],
        [74.7638168334961, 16.736801147460994],
        [74.76940917968756, 16.739469528198356],
        [74.77504730224626, 16.740779876708928],
        [74.7755584716798, 16.743999481201286],
        [74.77900695800776, 16.74859046936035],
        [74.79110717773443, 16.74814987182623],
        [74.80040740966814, 16.74565124511713],
        [74.80410766601585, 16.75076293945324],
        [74.85034179687494, 16.76032066345215],
        [74.85497283935547, 16.770769119262752],
        [74.86836242675787, 16.771080017089844],
        [74.89263916015653, 16.768690109252873],
        [74.9070129394533, 16.76979064941412],
        [74.92382049560564, 16.769950866699276],
        [74.91586303710943, 16.785680770874023],
        [74.91581726074219, 16.81022071838379],
        [74.91306304931669, 16.83074951171875],
        [74.91330718994158, 16.841449737548942],
        [74.91195678710932, 16.85593986511236],
        [74.92778778076166, 16.86334991455078],
        [74.9315032958985, 16.869541168212834],
        [74.93708038330107, 16.869161605835075],
        [74.93984985351562, 16.8723201751709],
        [74.93966674804688, 16.876520156860465],
        [74.94513702392572, 16.877122879028263],
        [74.9486160278322, 16.880090713501033],
        [74.95606994628906, 16.87729072570812],
        [74.964096069336, 16.87788009643566],
        [74.96726226806635, 16.905380249023494],
        [74.9528427124024, 16.906480789184513],
        [74.94803619384783, 16.909170150756893],
        [74.944076538086, 16.91327095031744],
        [74.93820190429693, 16.91646957397461],
        [74.93694305419916, 16.920730590820426],
        [74.9270324707033, 16.925622940063477],
        [74.928520202637, 16.935201644897404],
        [74.93839263916021, 16.940990447998104],
        [74.94834136962919, 16.935819625854492],
        [74.95243072509783, 16.936220169067496],
        [74.95453643798828, 16.930000305175838],
        [74.95802307128929, 16.929140090942496],
        [74.95999908447277, 16.93293190002447],
        [74.96671295166033, 16.93564987182623],
        [74.96949768066435, 16.93591117858898],
        [74.97528839111345, 16.934600830078182],
        [74.98142242431658, 16.930610656738395],
        [74.9858169555664, 16.92901992797863],
        [74.99449157714872, 16.93503952026373],
        [74.9995422363283, 16.941898345947322],
        [75.0037002563476, 16.945827484130803],
        [75.01140594482428, 16.94258117675787],
        [75.03029632568365, 16.941530227661246],
        [75.0301132202149, 16.93114280700695],
        [75.0319061279298, 16.925670623779297],
        [75.03449249267584, 16.928739547729492],
        [75.04535675048834, 16.93425941467291],
        [75.04814910888689, 16.938711166381893],
        [75.05078887939464, 16.93966102600109],
        [75.05523681640642, 16.93885993957531],
        [75.06540679931663, 16.939260482788086],
        [75.0829467773438, 16.94659805297846],
        [75.0904159545899, 16.948650360107536],
        [75.0941467285158, 16.947229385376033],
        [75.09471130371111, 16.92115020751953],
        [75.09796905517578, 16.913869857788086],
        [75.10692596435564, 16.90952110290533],
        [75.11875915527344, 16.90997123718273],
        [75.12200164794922, 16.907230377197322],
        [75.12357330322271, 16.90043830871582],
        [75.12763214111357, 16.897180557250977],
        [75.13839721679705, 16.892921447753906],
        [75.13710784912115, 16.884599685668945],
        [75.13838958740251, 16.878269195556754],
        [75.14112854003929, 16.873540878296012],
        [75.15052795410185, 16.869409561157283],
        [75.16438293457037, 16.865579605102596],
        [75.17175292968767, 16.8647975921632],
        [75.18084716796903, 16.867511749267635],
        [75.18965911865229, 16.873500823974723],
        [75.1925201416015, 16.8695583343507],
        [75.18900299072288, 16.861898422241325],
        [75.18319702148455, 16.842041015625],
        [75.188285827637, 16.840890884399414],
        [75.21585845947266, 16.840339660644588],
        [75.23001098632835, 16.840919494628963],
        [75.23291015625028, 16.845542907714957],
        [75.23565673828142, 16.854190826416072],
        [75.2409973144533, 16.86296081542963],
        [75.2434463500976, 16.86449050903326],
        [75.24867248535162, 16.864528656005973],
        [75.25797271728533, 16.86213111877447],
        [75.26830291748047, 16.86086082458496],
        [75.28277587890642, 16.874240875244197],
        [75.28565216064453, 16.878799438476562],
        [75.28749847412121, 16.8832111358642],
        [75.29177856445318, 16.901138305664062],
        [75.2855758666995, 16.909431457519588],
        [75.28359985351568, 16.942941665649414],
        [75.2846221923831, 16.953550338745117],
        [75.28843688964872, 16.954772949218807],
        [75.2947463989259, 16.954128265380916],
        [75.30612945556646, 16.954669952392578],
        [75.33796691894554, 16.95354080200201],
        [75.34532928466814, 16.952520370483512],
        [75.3595733642581, 16.95688819885254],
        [75.36435699462902, 16.963609695434627],
        [75.37168884277344, 16.968690872192383],
        [75.39476013183594, 16.976392745971623],
        [75.41683197021479, 16.969499588012695],
        [75.43102264404303, 16.96346092224121],
        [75.43885040283209, 16.97088050842291],
        [75.44380187988287, 16.979490280151367],
        [75.45353698730474, 16.982650756835994],
        [75.46791076660185, 16.98533058166504],
        [75.4685897827149, 16.979570388793945],
        [75.48249816894531, 16.975950241088924],
        [75.48969268798822, 16.96538925170904],
        [75.49178314208979, 16.957830429077205],
        [75.49263000488287, 16.95150947570795],
        [75.49301147460943, 16.945180892944336],
        [75.49249267578153, 16.94289970397955],
        [75.5018768310548, 16.94540977478033],
        [75.50929260253935, 16.948591232299805],
        [75.51065063476574, 16.94730949401861],
        [75.51700592041033, 16.951932907104606],
        [75.52406311035173, 16.958740234375],
        [75.53000640869146, 16.965829849243164],
        [75.53286743164068, 16.966651916503963],
        [75.5428161621096, 16.963140487670955],
        [75.55847167968756, 16.9643497467041],
        [75.57034301757841, 16.96362113952631],
        [75.5692977905274, 16.99885940551752],
        [75.56991577148443, 17.00617027282715],
        [75.57579803466803, 17.00779914855957],
        [75.59944152832037, 17.005220413208065],
        [75.6095199584961, 17.003110885620117],
        [75.6096572875976, 16.9989395141601],
        [75.61065673828142, 16.969619750976562],
        [75.62101745605491, 16.967750549316463],
        [75.63094329834007, 16.967149734497013],
        [75.63633728027372, 16.965810775756893],
        [75.63938903808622, 16.959159851074276],
        [75.64502716064459, 16.951032638549748],
        [75.6496276855471, 16.95199966430664],
        [75.65561676025402, 16.95611000061035],
        [75.66374969482445, 16.957881927490234],
        [75.6713790893557, 16.95853805541998],
        [75.67460632324224, 16.96026992797863],
        [75.67842102050787, 16.963941574096793],
        [75.67459106445318, 16.969350814819336],
        [75.67263031005876, 16.974081039428825],
        [75.66938018798845, 16.975629806518498],
        [75.6688308715822, 16.97776031494152],
        [75.66867828369146, 16.98321914672846],
        [75.6705398559572, 16.998830795288143],
        [75.67310333251959, 17.026170730590877],
        [75.67224121093767, 17.035730361938533],
        [75.66216278076172, 17.032449722290096],
        [75.65399932861334, 17.0306396484375],
        [75.64819335937494, 17.032690048217773],
        [75.6437530517581, 17.036399841308594],
        [75.64787292480469, 17.04657173156744],
        [75.65439605712913, 17.05508995056158],
        [75.66268920898432, 17.06178283691412],
        [75.67096710205084, 17.06157112121582],
        [75.6784057617188, 17.062860488891715],
        [75.68107604980474, 17.071140289306584],
        [75.6801376342774, 17.07826805114746],
        [75.67739105224626, 17.087749481201172],
        [75.67848968505888, 17.094171524047795],
        [75.67550659179705, 17.10112953186041],
        [75.67410278320341, 17.113550186157283],
        [75.65363311767601, 17.113130569458065],
        [75.65273284912138, 17.13273048400879],
        [75.64968872070341, 17.13318061828619],
        [75.64871215820341, 17.13880157470703],
        [75.64273071289068, 17.14763832092285],
        [75.63138580322277, 17.160070419311467],
        [75.62802124023438, 17.17012023925787],
        [75.62851715087919, 17.18074989318859],
        [75.6301879882813, 17.181449890136832],
        [75.63069915771484, 17.18616104125971],
        [75.6426467895509, 17.18815994262701],
        [75.64359283447277, 17.19408035278326],
        [75.6574325561524, 17.2037811279298],
        [75.66211700439447, 17.208330154419002],
        [75.66387939453125, 17.213750839233455],
        [75.66281890869169, 17.22793960571289],
        [75.66091918945341, 17.23999977111822],
        [75.6598892211914, 17.261440277099723],
        [75.65747833251982, 17.270570755004996],
        [75.63231658935575, 17.272220611572266],
        [75.63336944580078, 17.2773895263673],
        [75.63109588623053, 17.27984809875494],
        [75.6278686523438, 17.288120269775447],
        [75.6246871948245, 17.29380035400402],
        [75.60595703125023, 17.303260803222713],
        [75.61374664306646, 17.31944084167486],
        [75.61604309082037, 17.32876014709484],
        [75.61310577392595, 17.3422908782959],
        [75.5981063842774, 17.345119476318416],
        [75.58994293212896, 17.349689483642635],
        [75.58483123779325, 17.34977912902832],
        [75.58405303955107, 17.35134124755865],
        [75.57853698730497, 17.38293075561529],
        [75.58371734619135, 17.382200241088924],
        [75.58817291259766, 17.383020401001033],
        [75.59815979003935, 17.386100769043082],
        [75.60331726074247, 17.389860153198356],
        [75.61057281494169, 17.392009735107422],
        [75.61580657959001, 17.39185905456543],
        [75.61672210693354, 17.39863967895502],
        [75.61495208740263, 17.409561157226676],
        [75.61055755615263, 17.417119979858455],
        [75.61013793945318, 17.419540405273438],
        [75.61543273925781, 17.42202949523937],
        [75.61873626709007, 17.439111709594783],
        [75.62532806396501, 17.458110809326286],
        [75.63127136230469, 17.461179733276424],
        [75.6328887939456, 17.469640731811467],
        [75.63558959960949, 17.47751045227062],
        [75.64955902099626, 17.473409652710075],
        [75.65618133544933, 17.467510223388672],
        [75.66230010986322, 17.459680557250977],
        [75.67044830322294, 17.459320068359432],
        [75.67755889892572, 17.457971572875977],
        [75.6808166503908, 17.453971862793026],
        [75.68080902099604, 17.436162948608455],
        [75.68711853027361, 17.41730117797863],
        [75.69119262695318, 17.412611007690373],
        [75.69721221923822, 17.410568237304688],
        [75.70806121826183, 17.408319473266545],
        [75.71517944335955, 17.408931732177678],
        [75.72236633300793, 17.41177940368658],
        [75.72901153564482, 17.419509887695256],
        [75.73398590087919, 17.42165946960455],
        [75.73703765869169, 17.421899795532227],
        [75.75052642822288, 17.412900924682674],
        [75.75875854492216, 17.408411026001033],
        [75.76412200927763, 17.40842056274414],
        [75.76976776123053, 17.406841278076115],
        [75.77661895751953, 17.388202667236328],
        [75.78231811523455, 17.378812789917106],
        [75.79102325439482, 17.372980117797965],
        [75.80233764648455, 17.37010192871105],
        [75.81133270263672, 17.36993980407715],
        [75.81505584716825, 17.373849868774414],
        [75.81759643554705, 17.380729675293026],
        [75.8176879882813, 17.388040542602653],
        [75.81627655029297, 17.397811889648438],
        [75.81729125976585, 17.401210784912166],
        [75.82025146484392, 17.40656089782715],
        [75.82199096679705, 17.41967964172369],
        [75.82472229003929, 17.42284965515148],
        [75.82779693603521, 17.42371940612793],
        [75.83889007568388, 17.420541763305664],
        [75.84787750244152, 17.41534042358404],
        [75.85600280761741, 17.407682418823242],
        [75.85910797119169, 17.388969421386832],
        [75.86196899414091, 17.38585090637207],
        [75.8648757934572, 17.38644027709961],
        [75.8766632080081, 17.39697074890131],
        [75.87890625000006, 17.400329589843807],
        [75.87909698486334, 17.404939651489258],
        [75.87712860107422, 17.411350250244197],
        [75.8784484863283, 17.41654014587408],
        [75.88243103027361, 17.419000625610295],
        [75.88723754882812, 17.417329788208008],
        [75.89025878906267, 17.413600921630916],
        [75.89435577392584, 17.405902862548828],
        [75.89643096923834, 17.394979476928768],
        [75.89697265625, 17.377000808715934],
        [75.89639282226562, 17.3699893951416],
        [75.89198303222673, 17.360799789428768],
        [75.89366149902338, 17.35362052917492],
        [75.89808654785173, 17.3507595062257],
        [75.91071319580084, 17.3458194732666],
        [75.91622161865234, 17.338970184326172],
        [75.93115234375023, 17.324110031127873],
        [75.94174957275408, 17.32485961914074],
        [75.95981597900419, 17.33383178710949],
        [75.97152709960955, 17.336460113525334],
        [75.98071289062506, 17.336200714111385],
        [76.00026702880871, 17.337749481201286],
        [76.00417327880865, 17.342790603637752],
        [76.0091400146485, 17.347522735595646],
        [76.01545715332054, 17.350610733032283],
        [76.02127838134783, 17.349311828613395],
        [76.03666687011747, 17.34896278381359],
        [76.0545272827149, 17.354299545288086],
        [76.06046295166021, 17.35393142700201],
        [76.06320190429716, 17.351900100708008],
        [76.07347869873075, 17.3323211669923],
        [76.07874298095703, 17.330070495605412],
        [76.0849914550783, 17.332120895385685],
        [76.08685302734403, 17.333730697631893],
        [76.08696746826178, 17.338447570800724],
        [76.0925216674807, 17.343679428100643],
        [76.09407806396513, 17.347091674804688],
        [76.09046936035185, 17.350561141967887],
        [76.09276580810575, 17.35444068908697],
        [76.09596252441412, 17.357812881469727],
        [76.10199737548828, 17.362260818481502],
        [76.11667633056658, 17.366220474243164],
        [76.12120819091791, 17.36634063720703],
        [76.13758850097685, 17.352569580078068],
        [76.14566802978533, 17.347932815551815],
        [76.16314697265653, 17.340909957885742],
        [76.16422271728521, 17.319259643554688],
        [76.16380310058594, 17.300331115722656],
        [76.17188262939459, 17.29758071899414],
        [76.17575836181635, 17.29721069335949],
        [76.18383026123047, 17.31104278564453],
        [76.18260192871122, 17.314300537109375],
        [76.17891693115257, 17.313919067382812],
        [76.17633819580072, 17.32146072387701],
        [76.17350769042986, 17.332239151000977],
        [76.17233276367182, 17.348730087280273],
        [76.17603302001959, 17.35238075256359],
        [76.18290710449224, 17.35650062561035],
        [76.19320678710943, 17.358591079711914],
        [76.20352172851585, 17.358789443969727],
        [76.22899627685564, 17.36322975158697],
        [76.23037719726562, 17.370260238647404],
        [76.24436187744146, 17.3746795654298],
        [76.24671173095697, 17.367889404296875],
        [76.25044250488304, 17.366670608520565],
        [76.24890899658209, 17.362131118774528],
        [76.25000000000017, 17.357900619506836],
        [76.25627899169939, 17.3573894500733],
        [76.26494598388695, 17.35816192626953],
        [76.2701797485351, 17.356531143188533],
        [76.27263641357433, 17.347949981689453],
        [76.27587890625011, 17.330530166625977],
        [76.28576660156278, 17.330350875854606],
        [76.29589843750028, 17.328638076782227],
        [76.30502319335966, 17.328451156616325],
        [76.31720733642601, 17.32944107055664],
        [76.31836700439459, 17.33184051513672],
        [76.31301116943382, 17.343271255493107],
        [76.31704711914091, 17.34540939331049],
        [76.33473968505865, 17.349849700927734],
        [76.34361267089838, 17.34993171691906],
        [76.35060119628912, 17.327720642089957],
        [76.35227203369146, 17.310590744018555],
        [76.35936737060553, 17.31028938293457],
        [76.38170623779325, 17.311809539795036],
        [76.38389587402361, 17.319541931152457],
        [76.3929901123048, 17.336280822753963],
        [76.40204620361334, 17.34399032592779],
        [76.41063690185553, 17.347690582275504],
        [76.41050720214872, 17.354139328002987],
        [76.40806579589855, 17.36928939819336],
        [76.3619995117188, 17.375080108642635],
        [76.37004852294928, 17.407899856567496],
        [76.36988067626982, 17.423040390014762],
        [76.37317657470697, 17.43441963195812],
        [76.3638916015625, 17.435550689697322],
        [76.34195709228516, 17.433830261230412],
        [76.33365631103544, 17.43229293823248],
        [76.3308410644533, 17.461620330810547],
        [76.33080291748075, 17.468818664550838],
        [76.33776855468756, 17.470409393310547],
        [76.34278869628935, 17.47037124633789],
        [76.35000610351585, 17.472129821777457],
        [76.372299194336, 17.485780715942383],
        [76.37503814697294, 17.493520736694336],
        [76.36659240722662, 17.49529266357422],
        [76.35518646240263, 17.49416923522955],
        [76.345947265625, 17.49159812927246],
        [76.3434829711914, 17.494350433349723],
        [76.34372711181646, 17.508119583129883],
        [76.3463363647461, 17.511480331420955],
        [76.35601806640642, 17.513370513916072],
        [76.36640930175804, 17.51671028137207],
        [76.36612701416033, 17.52217292785639],
        [76.36460876464861, 17.528520584106445],
        [76.3609008789063, 17.5362606048584],
        [76.3548126220706, 17.538730621337947],
        [76.34583282470709, 17.540210723876953],
        [76.34002685546892, 17.5425701141358],
        [76.3372421264649, 17.55517959594738],
        [76.33423614501982, 17.56348991394043],
        [76.33290863037121, 17.573831558227653],
        [76.33167266845709, 17.594869613647518],
        [76.33641052246094, 17.596040725708008],
        [76.34870147705107, 17.595399856567496],
        [76.35948181152361, 17.596292495727596],
        [76.37301635742205, 17.597938537597713],
        [76.38207244873053, 17.60078048706066],
        [76.41567230224638, 17.604200363159237],
        [76.41818237304682, 17.605831146240234],
        [76.42074584960943, 17.614240646362305],
        [76.42410278320307, 17.621540069580135],
        [76.42579650878918, 17.628320693969783],
        [76.427230834961, 17.63978004455572],
        [76.42872619628906, 17.644411087036246],
        [76.43477630615229, 17.644531250000114],
        [76.44275665283232, 17.641870498657283],
        [76.4408493041995, 17.64937019348156],
        [76.45153808593778, 17.651510238647518],
        [76.45230102539091, 17.655548095703182],
        [76.48929595947294, 17.660669326782227],
        [76.48464965820318, 17.669630050659237],
        [76.48442840576172, 17.68442916870123],
        [76.48562622070335, 17.69269943237316],
        [76.48636627197271, 17.713520050048942],
        [76.49225616455078, 17.714941024780273],
        [76.51095581054693, 17.71591949462885],
        [76.5236358642581, 17.715230941772518],
        [76.52482604980486, 17.72290992736822],
        [76.52503967285173, 17.73467254638672],
        [76.52369689941423, 17.758890151977482],
        [76.52632141113293, 17.759361267089957],
        [76.53159332275413, 17.758529663085994],
        [76.5382080078125, 17.758939743042106],
        [76.55977630615234, 17.763139724731445],
        [76.56754302978533, 17.76288986206066],
        [76.57330322265625, 17.760000228881836],
        [76.56936645507818, 17.75638008117687],
        [76.56459045410179, 17.753660202026367],
        [76.56810760498041, 17.73979949951172],
        [76.56942749023449, 17.703741073608455],
        [76.57322692871122, 17.70216941833496],
        [76.57720947265653, 17.703140258789006],
        [76.58702850341814, 17.707889556884822],
        [76.59324645996122, 17.717559814453182],
        [76.5988006591798, 17.727970123291072],
        [76.60405731201178, 17.75583076477045],
        [76.60690307617193, 17.759580612182617],
        [76.60951232910173, 17.765979766845646],
        [76.61444854736322, 17.76831245422369],
        [76.6233673095706, 17.75789070129406],
        [76.62695312500023, 17.755960464477596],
        [76.62979888916021, 17.74187088012701],
        [76.63065338134777, 17.729000091552734],
        [76.637435913086, 17.72410964965826],
        [76.6508331298831, 17.72083091735834],
        [76.65521240234403, 17.716400146484432],
        [76.65812683105463, 17.708530426025504],
        [76.6598358154298, 17.69919013977045],
        [76.66313934326172, 17.68786048889166],
        [76.67164611816435, 17.689140319824276],
        [76.6781463623048, 17.68734169006359],
        [76.68826293945307, 17.678510665893555],
        [76.69055175781244, 17.679952621460018],
        [76.69313049316412, 17.693761825561523],
        [76.6912231445313, 17.698141098022404],
        [76.69087219238287, 17.701940536498967],
        [76.69235992431646, 17.70526123046875],
        [76.70079803466825, 17.70726966857916],
        [76.7034530639649, 17.71862030029297],
        [76.70210266113304, 17.723711013794002],
        [76.69815063476591, 17.726039886474666],
        [76.70347595214872, 17.729120254516545],
        [76.72760772705078, 17.736999511718807],
        [76.7265014648438, 17.740369796752987],
        [76.72193145751959, 17.74802970886236],
        [76.7179718017581, 17.747869491577262],
        [76.71909332275396, 17.761331558227653],
        [76.72096252441412, 17.767589569091854],
        [76.71720123291044, 17.769849777221623],
        [76.71280670166021, 17.77136039733898],
        [76.71244812011747, 17.775091171264705],
        [76.71570587158232, 17.781919479370117],
        [76.71938323974626, 17.785299301147404],
        [76.72463226318365, 17.78507804870617],
        [76.73943328857422, 17.779130935668945],
        [76.74355316162138, 17.780790328979492],
        [76.74401092529297, 17.78346061706543],
        [76.74777221679682, 17.783609390258846],
        [76.74887847900419, 17.786350250244254],
        [76.76275634765648, 17.787109375],
        [76.76632690429688, 17.796649932861442],
        [76.77002716064459, 17.797019958496094],
        [76.77628326416021, 17.799381256103516],
        [76.77979278564482, 17.805919647216797],
        [76.77947998046892, 17.810289382934627],
        [76.78141784667974, 17.81512069702154],
        [76.78481292724626, 17.81963920593256],
        [76.78816986084013, 17.82647132873535],
        [76.78546905517584, 17.831462860107422],
        [76.7797470092774, 17.836330413818473],
        [76.76573944091814, 17.850799560546875],
        [76.76434326171903, 17.849500656127987],
        [76.7597274780274, 17.85206031799322],
        [76.75456237792991, 17.856588363647575],
        [76.74578857421898, 17.858310699462834],
        [76.73745727539074, 17.857059478759822],
        [76.73964691162115, 17.883621215820312],
        [76.73958587646501, 17.892049789428768],
        [76.7406768798831, 17.898010253906307],
        [76.742973327637, 17.899021148681697],
        [76.76595306396484, 17.898429870605582],
        [76.7701416015625, 17.892360687255916],
        [76.77310943603544, 17.885150909423942],
        [76.77379608154303, 17.8822402954101],
        [76.77326202392584, 17.878660202026424],
        [76.77456665039068, 17.87696075439453],
        [76.7974472045899, 17.869340896606445],
        [76.80280303955084, 17.868320465087947],
        [76.80625152587913, 17.870492935180664],
        [76.8053512573245, 17.87420845031744],
        [76.80822753906261, 17.883090972900334],
        [76.8110809326173, 17.88408851623541],
        [76.81713104248053, 17.883090972900334],
        [76.82036590576195, 17.884452819824276],
        [76.83307647705095, 17.88274955749523],
        [76.83175659179688, 17.890672683715763],
        [76.84691619873075, 17.896400451660156],
        [76.85383605957048, 17.897838592529297],
        [76.87185668945335, 17.894741058349553],
        [76.87526702880865, 17.892730712890568],
        [76.88117980957037, 17.893051147460994],
        [76.8832092285158, 17.89707946777355],
        [76.88938140869146, 17.897180557250977],
        [76.89240264892578, 17.902851104736385],
        [76.89366912841808, 17.907371520996207],
        [76.89659881591797, 17.90802001953125],
        [76.90020751953142, 17.914688110351562],
        [76.91320037841797, 17.913799285888615],
        [76.91644287109375, 17.915090560913143],
        [76.91834259033203, 17.923109054565487],
        [76.91918945312528, 17.936660766601562],
        [76.91322326660162, 17.937208175659237],
        [76.91726684570318, 17.960479736328125],
        [76.91823577880888, 17.972850799560604],
        [76.91958618164091, 17.985380172729435],
        [76.91967010498064, 17.99064064025879],
        [76.91763305664085, 17.996259689330998],
        [76.91848754882807, 17.998689651489258],
        [76.91816711425781, 18.001871109008732],
        [76.91767120361357, 18.012340545654354],
        [76.92024993896484, 18.013759613037053],
        [76.91940307617216, 18.02201080322277],
        [76.91771697998041, 18.03322982788086],
        [76.93863677978521, 18.03722953796398],
        [76.95205688476557, 18.037710189819336],
        [76.95464324951195, 18.039680480957088],
        [76.95430755615263, 18.042730331420955],
        [76.94863128662126, 18.04572105407715],
        [76.94844818115251, 18.04866027832037],
        [76.95060729980474, 18.05826950073248],
        [76.9526672363283, 18.06340980529791],
        [76.94948577880882, 18.068269729614258],
        [76.94702911376959, 18.073760986328125],
        [76.94973754882841, 18.082330703735465],
        [76.9548263549807, 18.085359573364315],
        [76.96431732177734, 18.088211059570426],
        [76.96734619140642, 18.09263992309576],
        [76.96827697753923, 18.09711074829096],
        [76.95857238769548, 18.106430053710994],
        [76.95339202880854, 18.110111236572266],
        [76.94252777099626, 18.111410140991325],
        [76.93215179443365, 18.110982894897518],
        [76.92256164550787, 18.116109848022518],
        [76.9222869873048, 18.12018966674799],
        [76.92417907714872, 18.12446022033697],
        [76.92999267578125, 18.132251739501953],
        [76.93176269531256, 18.13657951354992],
        [76.92765808105486, 18.139860153198356],
        [76.92536926269548, 18.14481925964367],
        [76.93335723876947, 18.15790939331066],
        [76.94375610351568, 18.15473937988287],
        [76.95095825195312, 18.17247962951666],
        [76.95410919189482, 18.184890747070426],
        [76.95881652832037, 18.18614006042486],
        [76.9709014892581, 18.18686103820812],
        [76.97985076904325, 18.187400817871207],
        [76.9805526733399, 18.189399719238338],
        [76.98789215087902, 18.188478469848633],
        [76.99146270751959, 18.1866512298584],
        [76.99295043945318, 18.182050704956055],
        [76.99547576904303, 18.178390502929744],
        [76.9952926635745, 18.173330307006893],
        [76.99775695800787, 18.166849136352653],
        [77.02398681640642, 18.162679672241268],
        [77.02274322509771, 18.171249389648438],
        [77.02171325683611, 18.17822074890131],
        [77.03032684326178, 18.179340362548828],
        [77.03238677978533, 18.183000564575252],
        [77.03283691406273, 18.183780670166072],
        [77.03810119628923, 18.181310653686523],
        [77.04944610595709, 18.17320823669445],
        [77.05031585693376, 18.16127967834484],
        [77.057670593262, 18.1559295654298],
        [77.057670593262, 18.152362823486442],
        [77.06910705566435, 18.149440765380916],
        [77.08618164062517, 18.150270462036076],
        [77.0892562866211, 18.155960083007812],
        [77.09268188476562, 18.156978607177848],
        [77.09661865234386, 18.158130645751896],
        [77.10888671875, 18.15269088745123],
        [77.1163330078125, 18.167608261108455],
        [77.10292816162104, 18.186309814453125],
        [77.09886169433622, 18.19114112854004],
        [77.09554290771484, 18.1951007843017],
        [77.10659027099626, 18.201810836792106],
        [77.11662292480497, 18.202409744262695],
        [77.12098693847685, 18.206701278686523],
        [77.13114929199213, 18.20618057250988],
        [77.13949584960966, 18.207241058349666],
        [77.14221954345697, 18.216049194335938],
        [77.14998626709013, 18.216560363769645],
        [77.1506423950197, 18.22153091430664],
        [77.14879608154303, 18.221452713012695],
        [77.14768981933622, 18.221401214599666],
        [77.14776611328142, 18.23764991760254],
        [77.15141296386736, 18.237939834594783],
        [77.1571960449221, 18.25309181213379],
        [77.16567993164057, 18.25592041015625],
        [77.16738128662121, 18.27345085144043],
        [77.17053222656256, 18.280870437622184],
        [77.17784118652372, 18.281850814819336],
        [77.19165039062517, 18.27429008483898],
        [77.19882965087919, 18.27923011779785],
        [77.2061767578125, 18.27795982360834],
        [77.20446014404297, 18.290479660034237],
        [77.19764709472662, 18.295520782470703],
        [77.19541168212902, 18.312709808349666],
        [77.2001495361331, 18.319169998169002],
        [77.20997619628906, 18.313741683960018],
        [77.21691131591803, 18.324890136718864],
        [77.22297668457037, 18.337800979614258],
        [77.22727203369152, 18.3449001312257],
        [77.22136688232416, 18.347209930419922],
        [77.23081207275396, 18.365579605102653],
        [77.24163055419939, 18.366382598877067],
        [77.24503326416033, 18.37058830261236],
        [77.23287200927734, 18.386127471923828],
        [77.23639678955107, 18.399810791015682],
        [77.24022674560575, 18.408647537231502],
        [77.24912261962896, 18.408252716064453],
        [77.25836181640636, 18.410419464111385],
        [77.26455688476568, 18.4183807373048],
        [77.29476928710966, 18.43268013000494],
        [77.30226135253923, 18.438470840454045],
        [77.31327056884794, 18.43938064575201],
        [77.31741333007835, 18.45379066467285],
        [77.32557678222656, 18.455120086669865],
        [77.32601928710943, 18.44885063171398],
        [77.33271026611357, 18.44845962524414],
        [77.33338165283203, 18.44189071655279],
        [77.33731079101591, 18.435440063476562],
      ],
    ],
  ],
  "Andhra Pradesh": [
    [
      [
        [80.27458190917974, 13.459583282470646],
        [80.27458190917974, 13.459029197692985],
        [80.27485656738298, 13.45903110504156],
        [80.27485656738298, 13.458750724792594],
        [80.2751388549807, 13.458750724792594],
        [80.2751388549807, 13.458198547363281],
        [80.27540588378918, 13.458198547363281],
        [80.27540588378918, 13.457921028137207],
        [80.2756958007813, 13.457921028137207],
        [80.2756958007813, 13.45764064788824],
        [80.27597045898449, 13.45764064788824],
        [80.27597045898449, 13.457082748413143],
        [80.27625274658209, 13.457082748413143],
        [80.27625274658209, 13.456805229187069],
        [80.27652740478544, 13.456810951233024],
        [80.27652740478544, 13.45625114440918],
        [80.27680969238281, 13.45625114440918],
        [80.27680206298828, 13.455972671508789],
        [80.27708435058622, 13.455972671508789],
        [80.27708435058622, 13.455695152282715],
        [80.27764129638678, 13.455695152282715],
        [80.27764129638678, 13.455416679382324],
        [80.27791595459001, 13.45542144775402],
        [80.27791595459001, 13.455131530761719],
        [80.27847290039057, 13.455138206481934],
        [80.27847290039057, 13.4548597335816],
        [80.2787475585938, 13.4548597335816],
        [80.2787475585938, 13.454580307006893],
        [80.27930450439459, 13.454582214355469],
        [80.27930450439459, 13.45430660247797],
        [80.27958679199247, 13.454311370849666],
        [80.27958679199247, 13.454030990600643],
        [80.28041839599626, 13.454030990600643],
        [80.28041839599626, 13.453749656677246],
        [80.28125000000023, 13.453749656677246],
        [80.28125000000023, 13.453470230102539],
        [80.28208160400402, 13.453472137451115],
        [80.28208160400402, 13.453193664550724],
        [80.28319549560575, 13.453193664550724],
        [80.28319549560575, 13.45291614532465],
        [80.28402709960955, 13.452920913696289],
        [80.28402709960955, 13.452640533447322],
        [80.28485870361334, 13.452640533447322],
        [80.28485870361334, 13.452360153198356],
        [80.28514099121088, 13.452360153198356],
        [80.28514099121088, 13.451804161071891],
        [80.28542327880865, 13.451810836791935],
        [80.28542327880865, 13.451250076294002],
        [80.28569793701183, 13.451250076294002],
        [80.28569793701183, 13.450969696045036],
        [80.28597259521484, 13.450971603393612],
        [80.28597259521484, 13.450415611267147],
        [80.28624725341825, 13.450421333313102],
        [80.28624725341825, 13.449859619140682],
        [80.28652954101562, 13.449861526489258],
        [80.28652954101562, 13.449028015136719],
        [80.28624725341825, 13.449029922485295],
        [80.28624725341825, 13.448472023010254],
        [80.28569793701183, 13.448472023010254],
        [80.28569793701183, 13.448749542236328],
        [80.28542327880865, 13.448749542236328],
        [80.28542327880865, 13.449321746826172],
        [80.28514099121088, 13.449305534362793],
        [80.28514099121088, 13.449582099914608],
        [80.28485870361334, 13.449582099914608],
        [80.28485870361334, 13.449861526489258],
        [80.28457641601557, 13.449859619140682],
        [80.28457641601557, 13.450139999389648],
        [80.28430175781273, 13.450138092041072],
        [80.28430175781273, 13.450694084167537],
        [80.28402709960955, 13.450694084167537],
        [80.28402709960955, 13.450971603393612],
        [80.28375244140653, 13.450969696045036],
        [80.28375244140653, 13.451250076294002],
        [80.28291320800781, 13.451250076294002],
        [80.28291320800781, 13.451531410217285],
        [80.28208160400402, 13.451531410217285],
        [80.28208160400402, 13.451810836791935],
        [80.28152465820307, 13.451804161071891],
        [80.28152465820307, 13.452083587646598],
        [80.28069305419928, 13.452083587646598],
        [80.28069305419928, 13.452360153198356],
        [80.28013610839872, 13.452360153198356],
        [80.28013610839872, 13.452640533447322],
        [80.27986907958984, 13.452640533447322],
        [80.27986907958984, 13.452920913696289],
        [80.27930450439459, 13.45291614532465],
        [80.27930450439459, 13.453193664550724],
        [80.2787475585938, 13.453193664550724],
        [80.2787475585938, 13.453472137451115],
        [80.27819824218773, 13.453470230102539],
        [80.2781906127932, 13.453749656677246],
        [80.27791595459001, 13.453749656677246],
        [80.27791595459001, 13.454030990600643],
        [80.27735900878923, 13.454030990600643],
        [80.27735900878923, 13.454311370849666],
        [80.27708435058622, 13.45430660247797],
        [80.27708435058622, 13.454582214355469],
        [80.27652740478544, 13.454580307006893],
        [80.27652740478544, 13.4548597335816],
        [80.27625274658209, 13.4548597335816],
        [80.27625274658209, 13.455138206481934],
        [80.2756958007813, 13.455131530761719],
        [80.2756958007813, 13.45542144775402],
        [80.27541351318354, 13.455416679382324],
        [80.27541351318354, 13.455695152282715],
        [80.2751388549807, 13.455690383911246],
        [80.2751388549807, 13.45625114440918],
        [80.27485656738298, 13.45625114440918],
        [80.27485656738298, 13.456810951233024],
        [80.27458190917974, 13.456805229187069],
        [80.27458190917974, 13.457361221313533],
        [80.27430725097673, 13.457361221313533],
        [80.27430725097673, 13.457921028137207],
        [80.27402496337919, 13.457917213439998],
        [80.27402496337919, 13.45847320556652],
        [80.27374267578142, 13.458471298217717],
        [80.27374267578142, 13.459312438964844],
        [80.27402496337919, 13.459304809570312],
        [80.27402496337919, 13.459583282470646],
        [80.27458190917974, 13.459583282470646],
      ],
    ],
    [
      [
        [80.2273559570312, 13.494031906127987],
        [80.2273559570312, 13.493471145629826],
        [80.22763824462896, 13.493471145629826],
        [80.22763824462896, 13.492917060852108],
        [80.22791290283214, 13.492921829223633],
        [80.22791290283214, 13.492361068725643],
        [80.22763824462896, 13.492361068725643],
        [80.22763824462896, 13.49181175231945],
        [80.2273559570312, 13.49181175231945],
        [80.2273559570312, 13.491239547729606],
        [80.22708129882835, 13.491249084472713],
        [80.22708129882835, 13.490693092346248],
        [80.22513580322271, 13.490693092346248],
        [80.22513580322271, 13.490416526794434],
        [80.22486114501964, 13.490420341491813],
        [80.22486114501964, 13.490140914916935],
        [80.22402954101585, 13.490140914916935],
        [80.22402954101585, 13.49181175231945],
        [80.22458648681646, 13.491805076599178],
        [80.22458648681646, 13.492082595825252],
        [80.22486114501964, 13.492080688476676],
        [80.22486114501964, 13.492361068725643],
        [80.2254180908206, 13.492361068725643],
        [80.2254180908206, 13.492638587951774],
        [80.22598266601591, 13.492638587951774],
        [80.22598266601591, 13.492921829223633],
        [80.22624969482439, 13.492917060852108],
        [80.22624969482439, 13.493195533752498],
        [80.22680664062517, 13.49319076538086],
        [80.22680664062517, 13.494031906127987],
        [80.2273559570312, 13.494031906127987],
      ],
    ],
    [
      [
        [80.23236083984375, 13.494583129882756],
        [80.23236083984375, 13.494304656982422],
        [80.2329177856447, 13.494304656982422],
        [80.2329177856447, 13.49402713775629],
        [80.23319244384771, 13.494031906127987],
        [80.23319244384771, 13.493751525878963],
        [80.23374938964866, 13.493751525878963],
        [80.23374938964866, 13.493471145629826],
        [80.23430633544928, 13.493471145629826],
        [80.23430633544928, 13.492917060852108],
        [80.2345733642581, 13.492921829223633],
        [80.2345733642581, 13.492361068725643],
        [80.23486328125006, 13.492361068725643],
        [80.23486328125006, 13.492080688476676],
        [80.23458099365246, 13.492082595825252],
        [80.23458099365246, 13.491805076599178],
        [80.23430633544928, 13.49181175231945],
        [80.23430633544928, 13.491239547729606],
        [80.2340240478515, 13.491249084472713],
        [80.2340240478515, 13.490973472595215],
        [80.23374938964866, 13.490973472595215],
        [80.23374938964866, 13.490693092346248],
        [80.23041534423845, 13.490693092346248],
        [80.23041534423845, 13.491249084472713],
        [80.23014068603521, 13.491239547729606],
        [80.23014068603521, 13.49152946472168],
        [80.22985839843767, 13.491526603698787],
        [80.22985839843767, 13.492082595825252],
        [80.2295761108399, 13.492080688476676],
        [80.2295761108399, 13.492638587951774],
        [80.22930908203125, 13.492638587951774],
        [80.22930908203125, 13.492921829223633],
        [80.22958374023466, 13.492917060852108],
        [80.22958374023466, 13.493471145629826],
        [80.22985839843767, 13.493471145629826],
        [80.22985839843767, 13.493751525878963],
        [80.23042297363298, 13.493751525878963],
        [80.23042297363298, 13.494031906127987],
        [80.23069763183616, 13.49402713775629],
        [80.23069763183616, 13.494304656982422],
        [80.23124694824224, 13.494304656982422],
        [80.23124694824224, 13.494583129882756],
        [80.23236083984375, 13.494583129882756],
      ],
    ],
    [
      [
        [80.26541900634788, 13.519582748413086],
        [80.26541900634788, 13.519305229187012],
        [80.2656860351562, 13.51930999755865],
        [80.2656860351562, 13.519029617309513],
        [80.2659759521485, 13.519029617309513],
        [80.2659759521485, 13.518749237060547],
        [80.26653289794928, 13.518749237060547],
        [80.26652526855474, 13.518193244934082],
        [80.26680755615263, 13.518193244934082],
        [80.26680755615263, 13.517639160156364],
        [80.26708221435547, 13.517642021179256],
        [80.26708221435547, 13.517081260681152],
        [80.26680755615263, 13.517083168029899],
        [80.26680755615263, 13.516527175903434],
        [80.26653289794928, 13.516531944274902],
        [80.26653289794928, 13.515690803527832],
        [80.26625061035168, 13.515694618225154],
        [80.26625061035168, 13.514582633972225],
        [80.26652526855474, 13.514582633972225],
        [80.26652526855474, 13.513472557067871],
        [80.26680755615263, 13.513472557067871],
        [80.26680755615263, 13.512360572814941],
        [80.26708221435547, 13.512372016906795],
        [80.26708221435547, 13.511809349060115],
        [80.26735687255888, 13.511809349060115],
        [80.26735687255888, 13.510970115661621],
        [80.26763916015642, 13.510972976684684],
        [80.26763916015642, 13.510416984558162],
        [80.2679061889649, 13.510418891906795],
        [80.2679061889649, 13.509310722351188],
        [80.26763916015642, 13.509310722351188],
        [80.26763916015642, 13.507921218872184],
        [80.26735687255888, 13.507921218872184],
        [80.26735687255888, 13.506530761718807],
        [80.26708221435547, 13.506530761718807],
        [80.26708221435547, 13.50485992431652],
        [80.26680755615263, 13.50485992431652],
        [80.26680755615263, 13.503179550170898],
        [80.26652526855474, 13.503193855285701],
        [80.26652526855474, 13.501527786254883],
        [80.26625061035168, 13.501529693603459],
        [80.26625061035168, 13.500140190124625],
        [80.2659759521485, 13.500140190124625],
        [80.2659759521485, 13.499579429626465],
        [80.26541900634788, 13.49958419799816],
        [80.26541900634788, 13.499859809875488],
        [80.26513671875017, 13.499859809875488],
        [80.26513671875017, 13.501250267028809],
        [80.26486206054693, 13.501250267028809],
        [80.26486206054693, 13.503193855285701],
        [80.26513671875017, 13.503179550170898],
        [80.26513671875017, 13.505140304565487],
        [80.26541900634788, 13.505140304565487],
        [80.26541900634788, 13.505971908569279],
        [80.26569366455072, 13.505971908569279],
        [80.26569366455072, 13.507081985473633],
        [80.2659759521485, 13.507081985473633],
        [80.2659759521485, 13.50819015502941],
        [80.26625061035168, 13.508200645446834],
        [80.26625061035168, 13.509310722351188],
        [80.26653289794928, 13.509310722351188],
        [80.26653289794928, 13.510418891906795],
        [80.26625061035168, 13.510418891906795],
        [80.26625061035168, 13.511251449585018],
        [80.2659759521485, 13.511248588561955],
        [80.2659759521485, 13.51208305358881],
        [80.26569366455072, 13.51208305358881],
        [80.26569366455072, 13.513195037841797],
        [80.26541900634788, 13.513190269470329],
        [80.26541900634788, 13.514031410217399],
        [80.26513671875017, 13.51402664184576],
        [80.26513671875017, 13.514861106872615],
        [80.26486206054693, 13.514861106872615],
        [80.26486206054693, 13.51541709899908],
        [80.26457977294939, 13.515410423278809],
        [80.26457977294939, 13.51625061035162],
        [80.26430511474638, 13.516248703003043],
        [80.26430511474638, 13.517360687255803],
        [80.26457977294939, 13.517360687255803],
        [80.26457977294939, 13.519029617309513],
        [80.26486206054693, 13.519026756286621],
        [80.26486206054693, 13.519582748413086],
        [80.26541900634788, 13.519582748413086],
      ],
    ],
    [
      [
        [80.192642211914, 13.520695686340332],
        [80.192642211914, 13.520415306091365],
        [80.19374847412138, 13.520421981811637],
        [80.19374847412138, 13.520141601562443],
        [80.19403076171875, 13.520141601562443],
        [80.19403076171875, 13.51958084106451],
        [80.19430541992216, 13.519582748413086],
        [80.19430541992216, 13.519305229187012],
        [80.19458007812517, 13.51930999755865],
        [80.19458007812517, 13.518749237060547],
        [80.19486236572271, 13.518749237060547],
        [80.19486236572271, 13.518470764160156],
        [80.19513702392595, 13.518472671508732],
        [80.19513702392595, 13.518193244934082],
        [80.19541931152366, 13.518193244934082],
        [80.19541931152366, 13.517639160156364],
        [80.19568634033197, 13.517642021179256],
        [80.19568634033197, 13.516531944274902],
        [80.19596862792974, 13.516531944274902],
        [80.19596862792974, 13.515140533447266],
        [80.19625091552746, 13.515140533447266],
        [80.19625091552746, 13.514861106872615],
        [80.19652557373053, 13.514861106872615],
        [80.19652557373053, 13.51430511474615],
        [80.19680786132841, 13.514311790466365],
        [80.19680786132841, 13.513751029968262],
        [80.19708251953125, 13.513751029968262],
        [80.19708251953125, 13.512921333313045],
        [80.19596862792974, 13.512916564941406],
        [80.19596862792974, 13.513195037841797],
        [80.19541931152366, 13.513195037841797],
        [80.19541931152366, 13.513472557067871],
        [80.19291687011724, 13.513470649719295],
        [80.19291687011724, 13.513751029968262],
        [80.19209289550798, 13.513751029968262],
        [80.19209289550798, 13.514031410217399],
        [80.19124603271513, 13.514031410217399],
        [80.19124603271513, 13.514311790466365],
        [80.19069671630888, 13.51430511474615],
        [80.19069671630888, 13.514582633972225],
        [80.18985748291021, 13.514582633972225],
        [80.18985748291021, 13.514861106872615],
        [80.18930816650413, 13.514861106872615],
        [80.18930816650413, 13.515140533447266],
        [80.18846893310564, 13.515140533447266],
        [80.18846893310564, 13.51625061035162],
        [80.18819427490263, 13.516248703003043],
        [80.18819427490263, 13.517083168029899],
        [80.18791961669922, 13.517083168029899],
        [80.18791961669922, 13.518193244934082],
        [80.18763732910185, 13.518181800842342],
        [80.18763732910185, 13.51930999755865],
        [80.18791961669922, 13.51930999755865],
        [80.18791961669922, 13.520141601562443],
        [80.18985748291021, 13.520141601562443],
        [80.18985748291021, 13.520421981811637],
        [80.19180297851568, 13.520415306091365],
        [80.19180297851568, 13.520695686340332],
        [80.192642211914, 13.520695686340332],
      ],
    ],
    [
      [
        [80.18486022949236, 13.535421371460018],
        [80.18486022949236, 13.535140991210994],
        [80.18514251709013, 13.535140991210994],
        [80.18514251709013, 13.53458023071289],
        [80.18541717529314, 13.534582138061467],
        [80.18541717529314, 13.534306526184139],
        [80.18514251709013, 13.534311294555664],
        [80.18514251709013, 13.53403091430664],
        [80.18486022949236, 13.53403091430664],
        [80.18486022949236, 13.533481597900447],
        [80.18459320068388, 13.533481597900447],
        [80.18459320068388, 13.53318977355957],
        [80.1843032836914, 13.53319358825695],
        [80.1843032836914, 13.532916069030819],
        [80.18374633789068, 13.532920837402287],
        [80.18374633789068, 13.532641410827637],
        [80.1806869506836, 13.532641410827637],
        [80.1806869506836, 13.532920837402287],
        [80.18041992187511, 13.532916069030819],
        [80.18041992187511, 13.53319358825695],
        [80.1801376342774, 13.53318977355957],
        [80.1801376342774, 13.533481597900447],
        [80.17986297607416, 13.533481597900447],
        [80.17986297607416, 13.53403091430664],
        [80.18152618408232, 13.53403091430664],
        [80.18152618408232, 13.534311294555664],
        [80.18180847167986, 13.534306526184139],
        [80.18180847167986, 13.534582138061467],
        [80.18319702148443, 13.534582138061467],
        [80.18319702148443, 13.53486347198492],
        [80.18374633789068, 13.534859657287598],
        [80.18374633789068, 13.535140991210994],
        [80.18402862548857, 13.535140991210994],
        [80.18402862548857, 13.535421371460018],
        [80.18486022949236, 13.535421371460018],
      ],
    ],
    [
      [
        [80.053466796875, 13.589310646057186],
        [80.053466796875, 13.589031219482536],
        [80.05403137207031, 13.589031219482536],
        [80.05403137207031, 13.588752746582145],
        [80.0559692382813, 13.588752746582145],
        [80.0559692382813, 13.588470458984375],
        [80.05625152587902, 13.588472366332951],
        [80.05625152587902, 13.588193893432617],
        [80.05652618408209, 13.588193893432617],
        [80.05652618408209, 13.587360382080021],
        [80.05680847167997, 13.587360382080021],
        [80.05680847167997, 13.586806297302303],
        [80.05652618408209, 13.586811065673828],
        [80.05652618408209, 13.586530685424805],
        [80.05458068847673, 13.586530685424805],
        [80.05458068847673, 13.586811065673828],
        [80.05430603027372, 13.586806297302303],
        [80.05430603027372, 13.58708190917963],
        [80.05403137207031, 13.58708190917963],
        [80.05403137207031, 13.587360382080021],
        [80.05374908447294, 13.587360382080021],
        [80.05374908447294, 13.587650299072322],
        [80.053466796875, 13.587650299072322],
        [80.053466796875, 13.587921142578182],
        [80.0529174804688, 13.587916374206486],
        [80.0529174804688, 13.588193893432617],
        [80.0523605346682, 13.588191986083984],
        [80.0523605346682, 13.588752746582145],
        [80.05264282226557, 13.588752746582145],
        [80.05264282226557, 13.589031219482536],
        [80.0529174804688, 13.589031219482536],
        [80.0529174804688, 13.589310646057186],
        [80.053466796875, 13.589310646057186],
      ],
    ],
    [
      [
        [80.1490249633789, 13.618473052978516],
        [80.1490249633789, 13.618194580078125],
        [80.14930725097685, 13.618194580078125],
        [80.14930725097685, 13.616529464721737],
        [80.14958190917969, 13.61653137207037],
        [80.14958190917969, 13.614859580993766],
        [80.14930725097685, 13.614863395690918],
        [80.14930725097685, 13.614306449890137],
        [80.14901733398438, 13.614311218261832],
        [80.14901733398438, 13.613750457763672],
        [80.1487503051759, 13.613750457763672],
        [80.1487503051759, 13.613189697265739],
        [80.14847564697271, 13.613194465637207],
        [80.14847564697271, 13.612915992736816],
        [80.14819335937494, 13.612920761108512],
        [80.14819335937494, 13.612360000610352],
        [80.1479187011721, 13.612360000610352],
        [80.1479187011721, 13.612079620361385],
        [80.14763641357439, 13.612084388732853],
        [80.14763641357439, 13.611805915832463],
        [80.14734649658226, 13.611820220947266],
        [80.14734649658226, 13.611249923705998],
        [80.14708709716814, 13.611249923705998],
        [80.14708709716814, 13.610969543457031],
        [80.14653015136719, 13.610972404480094],
        [80.14653015136719, 13.610693931579704],
        [80.1468048095706, 13.610693931579704],
        [80.1468048095706, 13.61041641235363],
        [80.14763641357439, 13.61041641235363],
        [80.14763641357439, 13.610693931579704],
        [80.14819335937494, 13.610693931579704],
        [80.14819335937494, 13.610972404480094],
        [80.14848327636724, 13.610969543457031],
        [80.14848327636724, 13.611249923705998],
        [80.1487503051759, 13.611249923705998],
        [80.1487503051759, 13.611531257629451],
        [80.14901733398438, 13.611531257629451],
        [80.14901733398438, 13.611820220947266],
        [80.15069580078142, 13.611820220947266],
        [80.15069580078142, 13.611531257629451],
        [80.1509704589846, 13.611531257629451],
        [80.1509704589846, 13.611249923705998],
        [80.15125274658197, 13.611249923705998],
        [80.15125274658197, 13.610969543457031],
        [80.15152740478521, 13.610972404480094],
        [80.15152740478521, 13.610693931579704],
        [80.152084350586, 13.610693931579704],
        [80.152084350586, 13.61041641235363],
        [80.15235900878935, 13.610421180725098],
        [80.15235900878935, 13.610139846801815],
        [80.15264129638672, 13.610139846801815],
        [80.15264129638672, 13.609860420227164],
        [80.15291595459013, 13.60986232757574],
        [80.15291595459013, 13.609581947326774],
        [80.15347290039068, 13.609581947326774],
        [80.15347290039068, 13.609306335449276],
        [80.15374755859392, 13.609311103820914],
        [80.15374755859392, 13.609032630920353],
        [80.15429687499994, 13.609032630920353],
        [80.15429687499994, 13.608760833740234],
        [80.15458679199224, 13.608760833740234],
        [80.15458679199224, 13.60847187042242],
        [80.1551361083985, 13.60847187042242],
        [80.1551361083985, 13.608194351196346],
        [80.15541839599638, 13.608194351196346],
        [80.15541839599638, 13.607915878295955],
        [80.15598297119169, 13.607919692993107],
        [80.15598297119169, 13.607640266418457],
        [80.15928649902361, 13.607640266418457],
        [80.15930938720703, 13.607361793518066],
        [80.16042327880876, 13.607361793518066],
        [80.16042327880876, 13.607078552246207],
        [80.16124725341803, 13.607084274291992],
        [80.16124725341803, 13.606805801391602],
        [80.16152954101574, 13.606809616088924],
        [80.16152954101574, 13.606249809265137],
        [80.16179656982422, 13.606249809265137],
        [80.16179656982422, 13.60569095611578],
        [80.16208648681669, 13.605693817138672],
        [80.16208648681669, 13.60541629791254],
        [80.16234588623064, 13.60542011260992],
        [80.16234588623064, 13.604859352111816],
        [80.16263580322294, 13.60486221313488],
        [80.16263580322294, 13.602361679077148],
        [80.16234588623064, 13.602361679077148],
        [80.16234588623064, 13.599590301513672],
        [80.16208648681669, 13.599590301513672],
        [80.16208648681669, 13.599309921264648],
        [80.16152954101574, 13.599309921264648],
        [80.16152954101574, 13.596250534057617],
        [80.16179656982422, 13.596250534057617],
        [80.16179656982422, 13.595690727234],
        [80.16208648681669, 13.595694541931152],
        [80.16208648681669, 13.595417022705078],
        [80.16234588623064, 13.595421791076717],
        [80.16234588623064, 13.594861030578613],
        [80.16263580322294, 13.594861030578613],
        [80.16263580322294, 13.594580650329647],
        [80.16291809082048, 13.594582557678223],
        [80.16291809082048, 13.594026565551758],
        [80.1631927490235, 13.594031333923397],
        [80.1631927490235, 13.59375095367443],
        [80.1634826660158, 13.59375095367443],
        [80.1634826660158, 13.593190193176326],
        [80.16374969482445, 13.593194961547965],
        [80.16374969482445, 13.592917442321891],
        [80.16401672363293, 13.592921257019043],
        [80.16401672363293, 13.59236145019537],
        [80.16625213623053, 13.59236145019537],
        [80.16625213623053, 13.592082977295036],
        [80.16708374023432, 13.592082977295036],
        [80.16708374023432, 13.591528892517147],
        [80.16735839843756, 13.591528892517147],
        [80.16735839843756, 13.59097003936779],
        [80.16764068603527, 13.590972900390682],
        [80.16764068603527, 13.589859962463436],
        [80.16735839843756, 13.589859962463436],
        [80.16735839843756, 13.588752746582145],
        [80.16707611083979, 13.588752746582145],
        [80.16707611083979, 13.587650299072322],
        [80.16680908203148, 13.587650299072322],
        [80.16680908203148, 13.587080001831055],
        [80.16652679443376, 13.58708190917963],
        [80.16652679443376, 13.58652782440197],
        [80.16625213623053, 13.586530685424805],
        [80.16625213623053, 13.585969924926871],
        [80.16596984863298, 13.585971832275447],
        [80.16596984863298, 13.585415840148983],
        [80.1657028198245, 13.585420608520451],
        [80.1657028198245, 13.584859848022518],
        [80.16541290283203, 13.584859848022518],
        [80.16541290283203, 13.584305763244629],
        [80.16513824462919, 13.584310531616325],
        [80.16513824462919, 13.58403015136713],
        [80.1634826660158, 13.58403015136713],
        [80.1634826660158, 13.58375167846674],
        [80.1631927490235, 13.58375167846674],
        [80.1631927490235, 13.583470344543514],
        [80.16291809082048, 13.58347225189209],
        [80.16291809082048, 13.5831937789917],
        [80.16263580322294, 13.5831937789917],
        [80.16263580322294, 13.582916259765625],
        [80.16208648681669, 13.582921028137264],
        [80.16208648681669, 13.58263969421381],
        [80.16179656982422, 13.58263969421381],
        [80.16179656982422, 13.58236026763916],
        [80.16152954101574, 13.582362174987907],
        [80.16152954101574, 13.582083702087516],
        [80.16124725341803, 13.582083702087516],
        [80.16124725341803, 13.581806182861442],
        [80.16097259521479, 13.58181095123291],
        [80.16097259521479, 13.581521034240836],
        [80.16041564941423, 13.581527709961051],
        [80.16041564941423, 13.581250190734977],
        [80.160140991211, 13.581250190734977],
        [80.160140991211, 13.579310417175407],
        [80.16042327880876, 13.579310417175407],
        [80.16042327880876, 13.578459739685172],
        [80.16069793701195, 13.578472137451172],
        [80.16069793701195, 13.57791805267334],
        [80.16042327880876, 13.577919960022086],
        [80.16042327880876, 13.577359199523983],
        [80.160140991211, 13.577362060546818],
        [80.160140991211, 13.577083587646484],
        [80.15985870361345, 13.577083587646484],
        [80.15985870361345, 13.576249122619629],
        [80.15957641601568, 13.576251983642692],
        [80.15957641601568, 13.574581146240178],
        [80.15930938720703, 13.57458305358898],
        [80.15930938720703, 13.573471069335994],
        [80.15958404541044, 13.573471069335994],
        [80.15958404541044, 13.572639465332031],
        [80.15985870361345, 13.572650909423942],
        [80.15985870361345, 13.571809768676815],
        [80.160140991211, 13.571809768676815],
        [80.160140991211, 13.571250915527287],
        [80.15985870361345, 13.571250915527287],
        [80.15985870361345, 13.57069110870367],
        [80.15958404541044, 13.570693016052246],
        [80.15958404541044, 13.570138931274528],
        [80.15930938720703, 13.570140838623104],
        [80.15928649902361, 13.569581031799316],
        [80.15902709960966, 13.569582939148063],
        [80.15902709960966, 13.569026947021598],
        [80.15875244140625, 13.56903076171875],
        [80.15875244140625, 13.56818962097168],
        [80.15847015380888, 13.568195343017635],
        [80.15847015380888, 13.56763935089117],
        [80.15820312500006, 13.567641258239746],
        [80.15820312500006, 13.567081451416072],
        [80.15791320800793, 13.567083358764705],
        [80.15791320800793, 13.566250801086426],
        [80.15763854980474, 13.566250801086426],
        [80.15763854980474, 13.565690040588493],
        [80.15736389160173, 13.565694808959961],
        [80.15736389160173, 13.564584732055778],
        [80.15708160400413, 13.564584732055778],
        [80.15708160400413, 13.563472747802848],
        [80.15680694580095, 13.563472747802848],
        [80.15680694580095, 13.562359809875602],
        [80.15651702880865, 13.562359809875602],
        [80.15651702880865, 13.561249732971248],
        [80.15625000000017, 13.561249732971248],
        [80.15625000000017, 13.560140609741154],
        [80.15598297119169, 13.560140609741154],
        [80.15598297119169, 13.559579849243221],
        [80.15569305419922, 13.55958366394043],
        [80.15569305419922, 13.559027671813908],
        [80.15541839599638, 13.559030532836971],
        [80.15541839599638, 13.558469772338867],
        [80.1551361083985, 13.558471679687443],
        [80.1551361083985, 13.557915687560978],
        [80.15486145019543, 13.557921409606934],
        [80.15486145019543, 13.557359695434684],
        [80.15458679199224, 13.557359695434684],
        [80.15458679199224, 13.55681133270275],
        [80.15429687499994, 13.55681133270275],
        [80.15429687499994, 13.55624961853033],
        [80.15402984619163, 13.55624961853033],
        [80.15402984619163, 13.555688858032227],
        [80.15374755859392, 13.555693626403865],
        [80.15374755859392, 13.555418014526367],
        [80.15348052978544, 13.555419921874943],
        [80.15348052978544, 13.554862022399902],
        [80.15319824218767, 13.554862022399902],
        [80.15319824218767, 13.554581642150936],
        [80.15291595459013, 13.554583549499512],
        [80.15291595459013, 13.554027557373047],
        [80.15264129638672, 13.55403041839611],
        [80.15264129638672, 13.553469657898006],
        [80.15235900878935, 13.553471565246582],
        [80.15235900878935, 13.553194046020508],
        [80.152084350586, 13.553194046020508],
        [80.152084350586, 13.55263996124279],
        [80.1518020629884, 13.55263996124279],
        [80.1518020629884, 13.552359580993652],
        [80.15152740478521, 13.552362442016715],
        [80.15152740478521, 13.551806449890194],
        [80.15125274658197, 13.551810264587402],
        [80.15125274658197, 13.551250457763729],
        [80.1509704589846, 13.551250457763729],
        [80.1509704589846, 13.550971984863338],
        [80.15069580078142, 13.550971984863338],
        [80.15069580078142, 13.550694465637264],
        [80.15041351318365, 13.550694465637264],
        [80.15041351318365, 13.550417900085506],
        [80.15013885498064, 13.550419807434082],
        [80.15013885498064, 13.550140380859375],
        [80.1498565673831, 13.550140380859375],
        [80.1498565673831, 13.549861907958984],
        [80.14958190917969, 13.549861907958984],
        [80.14958190917969, 13.549580574035758],
        [80.14930725097685, 13.54958438873291],
        [80.14930725097685, 13.54930591583252],
        [80.14901733398438, 13.549309730529899],
        [80.14901733398438, 13.549030303955021],
        [80.1487503051759, 13.549030303955021],
        [80.1487503051759, 13.54875183105463],
        [80.14848327636724, 13.54875183105463],
        [80.14848327636724, 13.548480033874512],
        [80.14819335937494, 13.548480033874512],
        [80.14819335937494, 13.548191070556697],
        [80.1479187011721, 13.548195838928166],
        [80.1479187011721, 13.547918319702262],
        [80.14763641357439, 13.547920227050838],
        [80.14763641357439, 13.547641754150504],
        [80.14734649658226, 13.547641754150504],
        [80.14734649658226, 13.54736137390131],
        [80.14708709716814, 13.54736137390131],
        [80.14708709716814, 13.54708290100092],
        [80.1468048095706, 13.54708290100092],
        [80.1468048095706, 13.546805381774846],
        [80.14653015136719, 13.546810150146484],
        [80.14653015136719, 13.54653167724615],
        [80.14624786376982, 13.54653167724615],
        [80.14624786376982, 13.546249389648551],
        [80.14569091796903, 13.546249389648551],
        [80.14569091796903, 13.54597091674799],
        [80.14514160156244, 13.54597091674799],
        [80.14514160156244, 13.545695304870662],
        [80.1448593139649, 13.545695304870662],
        [80.1448593139649, 13.545416831970272],
        [80.14430999755865, 13.545419692993164],
        [80.14430999755865, 13.545139312744197],
        [80.1437530517581, 13.545139312744197],
        [80.1437530517581, 13.544860839843807],
        [80.1418075561524, 13.544860839843807],
        [80.1418075561524, 13.545139312744197],
        [80.14125061035179, 13.545139312744197],
        [80.14125061035179, 13.545419692993164],
        [80.14097595214861, 13.545416831970272],
        [80.14097595214861, 13.54597091674799],
        [80.1406860351563, 13.54597091674799],
        [80.1406860351563, 13.54653167724615],
        [80.14041900634783, 13.546526908874455],
        [80.14041900634783, 13.54708290100092],
        [80.14013671875028, 13.54708290100092],
        [80.14013671875028, 13.548195838928166],
        [80.13986206054688, 13.548195838928166],
        [80.13986206054688, 13.54958438873291],
        [80.14013671875028, 13.549580574035758],
        [80.14013671875028, 13.550140380859375],
        [80.13986206054688, 13.550140380859375],
        [80.13986206054688, 13.550419807434082],
        [80.1395797729495, 13.550417900085506],
        [80.1395797729495, 13.550971984863338],
        [80.13931274414068, 13.550971984863338],
        [80.13931274414068, 13.551250457763729],
        [80.13903045654308, 13.551250457763729],
        [80.13903045654308, 13.55152797698986],
        [80.13874816894537, 13.55152797698986],
        [80.13874816894537, 13.552083969116325],
        [80.13847351074213, 13.552083969116325],
        [80.13847351074213, 13.552362442016715],
        [80.13817596435553, 13.552359580993652],
        [80.13817596435553, 13.55263996124279],
        [80.13791656494158, 13.55263996124279],
        [80.13791656494158, 13.553194046020508],
        [80.13764190673834, 13.553194046020508],
        [80.13764190673834, 13.553471565246582],
        [80.1373596191408, 13.553471565246582],
        [80.1373596191408, 13.55819416046154],
        [80.13708496093778, 13.55819416046154],
        [80.13708496093778, 13.560693740844783],
        [80.13819122314476, 13.560693740844783],
        [80.13819122314476, 13.560972213745117],
        [80.13903045654308, 13.560970306396541],
        [80.13903045654308, 13.561249732971248],
        [80.13931274414068, 13.561249732971248],
        [80.13931274414068, 13.561811447143668],
        [80.1395797729495, 13.561811447143668],
        [80.1395797729495, 13.562359809875602],
        [80.13986206054688, 13.562359809875602],
        [80.13986206054688, 13.562920570373535],
        [80.14013671875028, 13.562920570373535],
        [80.14013671875028, 13.564030647277889],
        [80.14041900634783, 13.564028739929313],
        [80.14041900634783, 13.565694808959961],
        [80.14013671875028, 13.565690040588493],
        [80.14013671875028, 13.566250801086426],
        [80.13986206054688, 13.566250801086426],
        [80.13986206054688, 13.567083358764705],
        [80.1395797729495, 13.567081451416072],
        [80.1395797729495, 13.567641258239746],
        [80.13930511474615, 13.56763935089117],
        [80.13930511474615, 13.568195343017635],
        [80.13903045654308, 13.56818962097168],
        [80.13903045654308, 13.5687513351441],
        [80.13874816894537, 13.5687513351441],
        [80.13874816894537, 13.569310188293457],
        [80.13847351074213, 13.569305419921989],
        [80.13847351074213, 13.569582939148063],
        [80.13817596435553, 13.569581031799316],
        [80.13817596435553, 13.570140838623104],
        [80.13791656494158, 13.570138931274528],
        [80.13791656494158, 13.570693016052246],
        [80.13764190673834, 13.57069110870367],
        [80.13764190673834, 13.571250915527287],
        [80.1373596191408, 13.571250915527287],
        [80.1373596191408, 13.571809768676815],
        [80.13708496093778, 13.571805000305176],
        [80.13708496093778, 13.572083473205566],
        [80.13680267333984, 13.572080612182674],
        [80.13680267333984, 13.57236099243164],
        [80.136528015137, 13.57236099243164],
        [80.136528015137, 13.572650909423942],
        [80.13625335693365, 13.572650909423942],
        [80.13625335693365, 13.572921752929801],
        [80.13597106933605, 13.572916984558105],
        [80.13597106933605, 13.573471069335994],
        [80.13569641113287, 13.573471069335994],
        [80.13569641113287, 13.573752403259277],
        [80.13540649414074, 13.573752403259277],
        [80.13540649414074, 13.574031829833984],
        [80.13513946533226, 13.574031829833984],
        [80.13513946533226, 13.574310302734318],
        [80.13486480712908, 13.57430458068859],
        [80.13486480712908, 13.57458305358898],
        [80.13346862792997, 13.57458305358898],
        [80.13346862792997, 13.574860572815055],
        [80.13318634033203, 13.574860572815055],
        [80.13318634033203, 13.575141906738338],
        [80.13263702392584, 13.575141906738338],
        [80.13263702392584, 13.575420379638672],
        [80.1323623657226, 13.575418472290096],
        [80.1323623657226, 13.575693130493107],
        [80.13208007812506, 13.575693130493107],
        [80.13208007812506, 13.575970649719238],
        [80.13181304931658, 13.575970649719238],
        [80.13181304931658, 13.576251983642692],
        [80.13124847412126, 13.576251983642692],
        [80.13124847412126, 13.576530456543026],
        [80.1309661865235, 13.576530456543026],
        [80.1309661865235, 13.576811790466309],
        [80.13041687011747, 13.576806068420353],
        [80.13041687011747, 13.577083587646484],
        [80.13014221191406, 13.577083587646484],
        [80.13014221191406, 13.577362060546818],
        [80.1296005249024, 13.577359199523983],
        [80.1296005249024, 13.57763957977295],
        [80.12902832031256, 13.57763957977295],
        [80.12902832031256, 13.577919960022086],
        [80.12875366210955, 13.57791805267334],
        [80.12875366210955, 13.578193664550838],
        [80.12819671630876, 13.578193664550838],
        [80.12819671630876, 13.578472137451172],
        [80.12790679931646, 13.578459739685172],
        [80.12790679931646, 13.57903003692627],
        [80.12763977050798, 13.579028129577694],
        [80.12763977050798, 13.579584121704158],
        [80.12735748291044, 13.579579353332463],
        [80.12735748291044, 13.580140113830623],
        [80.12708282470703, 13.580140113830623],
        [80.12708282470703, 13.580694198608512],
        [80.12680816650419, 13.580694198608512],
        [80.12680816650419, 13.582208633422852],
        [80.12680816650419, 13.58263969421381],
        [80.1265258789063, 13.582637786865234],
        [80.1265258789063, 13.584859848022518],
        [80.12680816650419, 13.584859848022518],
        [80.12680816650419, 13.585420608520451],
        [80.12708282470703, 13.585420608520451],
        [80.12708282470703, 13.586250305175838],
        [80.12735748291044, 13.586250305175838],
        [80.12735748291044, 13.586811065673828],
        [80.12847137451195, 13.586806297302303],
        [80.12847137451195, 13.58708190917963],
        [80.12958526611334, 13.587083816528434],
        [80.12958526611334, 13.587360382080021],
        [80.12985992431669, 13.587360382080021],
        [80.12985992431669, 13.587650299072322],
        [80.13041687011747, 13.587650299072322],
        [80.13041687011747, 13.587921142578182],
        [80.13069152832048, 13.587916374206486],
        [80.13069152832048, 13.588193893432617],
        [80.13124847412126, 13.588193893432617],
        [80.13124847412126, 13.588472366332951],
        [80.1323623657226, 13.588472366332951],
        [80.1323623657226, 13.588193893432617],
        [80.13263702392584, 13.588193893432617],
        [80.13263702392584, 13.587916374206486],
        [80.13346862792997, 13.587916374206486],
        [80.13346862792997, 13.588193893432617],
        [80.13402557373075, 13.588193893432617],
        [80.13402557373075, 13.588472366332951],
        [80.1345825195313, 13.588470458984375],
        [80.1345825195313, 13.588752746582145],
        [80.13513946533226, 13.588752746582145],
        [80.13513946533226, 13.589031219482536],
        [80.13569641113287, 13.589031219482536],
        [80.13569641113287, 13.589310646057186],
        [80.13590240478538, 13.58930873870861],
        [80.13625335693365, 13.589306831359806],
        [80.13625335693365, 13.589582443237305],
        [80.136528015137, 13.58957958221447],
        [80.136528015137, 13.589859962463436],
        [80.13707733154325, 13.589859962463436],
        [80.13707733154325, 13.590141296386662],
        [80.1373596191408, 13.590141296386662],
        [80.1373596191408, 13.59042072296154],
        [80.13791656494158, 13.59041690826416],
        [80.13791656494158, 13.59069538116455],
        [80.13817596435553, 13.590689659118596],
        [80.13817596435553, 13.591251373291016],
        [80.14013671875028, 13.591251373291016],
        [80.14013671875028, 13.59097003936779],
        [80.14125061035179, 13.590972900390682],
        [80.14125061035179, 13.59069538116455],
        [80.1418075561524, 13.59069538116455],
        [80.1418075561524, 13.590972900390682],
        [80.14263916015653, 13.59097003936779],
        [80.14263916015653, 13.591251373291016],
        [80.14653015136719, 13.591251373291016],
        [80.14653015136719, 13.59097003936779],
        [80.14819335937494, 13.590972900390682],
        [80.14819335937494, 13.59069538116455],
        [80.14986419677763, 13.59069538116455],
        [80.14986419677763, 13.59041690826416],
        [80.15116882324241, 13.590418815612793],
        [80.15152740478521, 13.59042072296154],
        [80.15152740478521, 13.590141296386662],
        [80.15319824218767, 13.590141296386662],
        [80.15319824218767, 13.589859962463436],
        [80.1551361083985, 13.589859962463436],
        [80.1551361083985, 13.58957958221447],
        [80.15569305419922, 13.589582443237305],
        [80.15569305419922, 13.589306831359806],
        [80.15651702880865, 13.589310646057186],
        [80.15651702880865, 13.589031219482536],
        [80.15735626220697, 13.589031219482536],
        [80.15735626220697, 13.589310646057186],
        [80.15791320800793, 13.589310646057186],
        [80.15791320800793, 13.590141296386662],
        [80.15819549560553, 13.590139389038086],
        [80.15819549560553, 13.590972900390682],
        [80.15791320800793, 13.59097003936779],
        [80.15791320800793, 13.591528892517147],
        [80.15763854980474, 13.591528892517147],
        [80.15763854980474, 13.591809272766113],
        [80.15680694580095, 13.591809272766113],
        [80.15680694580095, 13.591528892517147],
        [80.15651702880865, 13.591528892517147],
        [80.15651702880865, 13.591251373291016],
        [80.15625000000017, 13.591251373291016],
        [80.15625000000017, 13.59097003936779],
        [80.15541839599638, 13.59097003936779],
        [80.15541839599638, 13.591251373291016],
        [80.15348052978544, 13.591251373291016],
        [80.15348052978544, 13.591528892517147],
        [80.1518020629884, 13.591528892517147],
        [80.1518020629884, 13.591809272766113],
        [80.15051269531267, 13.591805458068904],
        [80.15013885498064, 13.591805458068904],
        [80.15013885498064, 13.592082977295036],
        [80.1490249633789, 13.592082977295036],
        [80.1490249633789, 13.59236145019537],
        [80.14763641357439, 13.59236145019537],
        [80.14763641357439, 13.592640876770076],
        [80.14708709716814, 13.592640876770076],
        [80.14708709716814, 13.592921257019043],
        [80.1468048095706, 13.592917442321891],
        [80.1468048095706, 13.593194961547965],
        [80.14624786376982, 13.593194961547965],
        [80.14624786376982, 13.593473434448356],
        [80.14541625976568, 13.593461990356445],
        [80.14541625976568, 13.594031333923397],
        [80.14514160156244, 13.594031333923397],
        [80.14514160156244, 13.59514141082775],
        [80.1448593139649, 13.595138549804688],
        [80.1448593139649, 13.595973014831543],
        [80.14457702636713, 13.595971107482967],
        [80.14457702636713, 13.596250534057617],
        [80.14430999755865, 13.596250534057617],
        [80.14430999755865, 13.59653186798107],
        [80.14347076416033, 13.59653186798107],
        [80.14347076416033, 13.596820831298885],
        [80.14236450195318, 13.596804618835506],
        [80.14236450195318, 13.597083091735897],
        [80.14152526855463, 13.597083091735897],
        [80.14152526855463, 13.597360610961971],
        [80.13874816894537, 13.597360610961971],
        [80.13874816894537, 13.597640991210938],
        [80.13764190673834, 13.597640991210938],
        [80.13764190673834, 13.597920417785645],
        [80.13680267333984, 13.597916603088436],
        [80.13680267333984, 13.59819316864025],
        [80.13625335693365, 13.59819316864025],
        [80.13625335693365, 13.598472595214957],
        [80.13569641113287, 13.598470687866154],
        [80.13569641113287, 13.598751068115291],
        [80.13540649414074, 13.598751068115291],
        [80.13540649414074, 13.599028587341422],
        [80.13485717773455, 13.599028587341422],
        [80.13485717773455, 13.599309921264648],
        [80.13403320312528, 13.599309921264648],
        [80.13403320312528, 13.599590301513672],
        [80.13319396972656, 13.599582672119084],
        [80.13319396972656, 13.599861145019474],
        [80.12763977050798, 13.599861145019474],
        [80.12763977050798, 13.600141525268668],
        [80.12735748291044, 13.600141525268668],
        [80.12735748291044, 13.600421905517635],
        [80.12680816650419, 13.60041713714594],
        [80.12680816650419, 13.60069465637207],
        [80.12625122070324, 13.60069465637207],
        [80.12625122070324, 13.600971221923828],
        [80.12596893310553, 13.600971221923828],
        [80.12596893310553, 13.601251602172795],
        [80.12541961669945, 13.601251602172795],
        [80.12541961669945, 13.601531982421989],
        [80.1248626708985, 13.601531982421989],
        [80.1248626708985, 13.600690841674862],
        [80.12513732910173, 13.60069465637207],
        [80.12513732910173, 13.599861145019474],
        [80.12541961669945, 13.599861145019474],
        [80.12541961669945, 13.599582672119084],
        [80.12596893310553, 13.599590301513672],
        [80.12596893310553, 13.599309921264648],
        [80.12623596191435, 13.599309921264648],
        [80.12623596191435, 13.599028587341422],
        [80.12847137451195, 13.599028587341422],
        [80.12847137451195, 13.598751068115291],
        [80.12874603271479, 13.598751068115291],
        [80.12874603271479, 13.598191261291504],
        [80.12902832031256, 13.59819316864025],
        [80.12902832031256, 13.597916603088436],
        [80.1293106079101, 13.597920417785645],
        [80.1293106079101, 13.597640991210938],
        [80.1296005249024, 13.597640991210938],
        [80.1296005249024, 13.597360610961971],
        [80.12902832031256, 13.597360610961971],
        [80.12902832031256, 13.595971107482967],
        [80.12930297851574, 13.595973014831543],
        [80.12930297851574, 13.595694541931152],
        [80.12958526611334, 13.595694541931152],
        [80.12958526611334, 13.595138549804688],
        [80.12985992431669, 13.59514141082775],
        [80.12985992431669, 13.594861030578613],
        [80.13014221191406, 13.594861030578613],
        [80.13014221191406, 13.594580650329647],
        [80.13041687011747, 13.594582557678223],
        [80.13041687011747, 13.593194961547965],
        [80.13014221191406, 13.593194961547965],
        [80.13014221191406, 13.592917442321891],
        [80.12985992431669, 13.592921257019043],
        [80.12985992431669, 13.592640876770076],
        [80.1296005249024, 13.592640876770076],
        [80.1296005249024, 13.591809272766113],
        [80.1293106079101, 13.591809272766113],
        [80.1293106079101, 13.591251373291016],
        [80.12958526611334, 13.591251373291016],
        [80.12958526611334, 13.590972900390682],
        [80.12985992431669, 13.590972900390682],
        [80.12985992431669, 13.59069538116455],
        [80.13014221191406, 13.59069538116455],
        [80.13014221191406, 13.590139389038086],
        [80.13041687011747, 13.590141296386662],
        [80.13041687011747, 13.5899019241333],
        [80.13041687011747, 13.589582443237305],
        [80.13069152832048, 13.589582443237305],
        [80.13069152832048, 13.589306831359806],
        [80.12985992431669, 13.589310646057186],
        [80.12985992431669, 13.589031219482536],
        [80.12902832031256, 13.589031219482536],
        [80.12902832031256, 13.588752746582145],
        [80.12819671630876, 13.588752746582145],
        [80.12819671630876, 13.588470458984375],
        [80.1265258789063, 13.588472366332951],
        [80.1265258789063, 13.588590621948242],
        [80.1265258789063, 13.589031219482536],
        [80.12623596191435, 13.589031219482536],
        [80.12623596191435, 13.589859962463436],
        [80.12596893310553, 13.589859962463436],
        [80.12596893310553, 13.59069538116455],
        [80.12568664550776, 13.590689659118596],
        [80.12568664550776, 13.591251373291016],
        [80.12541961669945, 13.591251373291016],
        [80.12541961669945, 13.591809272766113],
        [80.12513732910173, 13.591805458068904],
        [80.12513732910173, 13.59236145019537],
        [80.1248626708985, 13.59236145019537],
        [80.1248626708985, 13.592921257019043],
        [80.12458038330095, 13.592917442321891],
        [80.12458038330095, 13.593473434448356],
        [80.1248626708985, 13.593461990356445],
        [80.1248626708985, 13.594311714172363],
        [80.12458038330095, 13.594311714172363],
        [80.12458038330095, 13.59514141082775],
        [80.12430572509794, 13.595138549804688],
        [80.12430572509794, 13.595694541931152],
        [80.12374877929716, 13.595694541931152],
        [80.12374877929716, 13.595973014831543],
        [80.12346649169922, 13.595971107482967],
        [80.12346649169922, 13.59653186798107],
        [80.12319183349621, 13.596527099609432],
        [80.12319183349621, 13.597083091735897],
        [80.12291717529303, 13.59708118438732],
        [80.12291717529303, 13.597640991210938],
        [80.12264251708979, 13.597639083862362],
        [80.12264251708979, 13.598472595214957],
        [80.12236022949241, 13.598470687866154],
        [80.12236022949241, 13.599590301513672],
        [80.12208557128923, 13.599582672119084],
        [80.12208557128923, 13.60069465637207],
        [80.12180328369146, 13.60069465637207],
        [80.12180328369146, 13.601151466369686],
        [80.12180328369146, 13.601531982421989],
        [80.12152862548845, 13.601531982421989],
        [80.12152862548845, 13.60181045532238],
        [80.12124633789091, 13.60181045532238],
        [80.12124633789091, 13.602361679077148],
        [80.1209716796875, 13.602361679077148],
        [80.1209716796875, 13.602640151977539],
        [80.12069702148466, 13.602629661560115],
        [80.12069702148466, 13.6040296554566],
        [80.12041473388672, 13.604027748108024],
        [80.12041473388672, 13.605693817138672],
        [80.12069702148466, 13.60569095611578],
        [80.12069702148466, 13.606249809265137],
        [80.1209716796875, 13.606249809265137],
        [80.1209716796875, 13.606809616088924],
        [80.12124633789091, 13.606805801391602],
        [80.12124633789091, 13.607084274291992],
        [80.12291717529303, 13.607084274291992],
        [80.12291717529303, 13.606805801391602],
        [80.12403106689453, 13.606809616088924],
        [80.12403106689453, 13.606530189514274],
        [80.12374877929716, 13.606530189514274],
        [80.12374877929716, 13.606249809265137],
        [80.12346649169922, 13.606249809265137],
        [80.12346649169922, 13.60596942901617],
        [80.12319183349621, 13.605972290039006],
        [80.12319183349621, 13.60541629791254],
        [80.12374877929716, 13.60542011260992],
        [80.12374877929716, 13.605139732360954],
        [80.12431335449247, 13.605139732360954],
        [80.12431335449247, 13.605039596557674],
        [80.12430572509794, 13.60486221313488],
        [80.1248626708985, 13.60486221313488],
        [80.1248626708985, 13.604583740234489],
        [80.12625122070324, 13.604583740234489],
        [80.12625122070324, 13.60486221313488],
        [80.1265258789063, 13.604859352111816],
        [80.1265258789063, 13.605139732360954],
        [80.12708282470703, 13.605139732360954],
        [80.12708282470703, 13.60542011260992],
        [80.12735748291044, 13.60541629791254],
        [80.12735748291044, 13.605693817138672],
        [80.12763977050798, 13.605693817138672],
        [80.12763977050798, 13.605972290039006],
        [80.13124847412126, 13.60596942901617],
        [80.13124847412126, 13.606249809265137],
        [80.13680267333984, 13.606249809265137],
        [80.13680267333984, 13.606530189514274],
        [80.13764190673834, 13.606530189514274],
        [80.13764190673834, 13.606809616088924],
        [80.1378097534182, 13.606807708740348],
        [80.13819122314476, 13.606805801391602],
        [80.13819122314476, 13.607084274291992],
        [80.13903045654308, 13.607078552246207],
        [80.13903045654308, 13.607361793518066],
        [80.13931274414068, 13.607361793518066],
        [80.13931274414068, 13.607640266418457],
        [80.13986206054688, 13.607640266418457],
        [80.13986206054688, 13.607919692993107],
        [80.14013671875028, 13.607915878295955],
        [80.14013671875028, 13.608194351196346],
        [80.14069366455084, 13.608194351196346],
        [80.14069366455084, 13.60847187042242],
        [80.14097595214861, 13.60847187042242],
        [80.14097595214861, 13.608760833740234],
        [80.14154052734392, 13.608760833740234],
        [80.14154052734392, 13.609032630920353],
        [80.1418075561524, 13.609032630920353],
        [80.1418075561524, 13.609311103820914],
        [80.14208221435558, 13.609306335449276],
        [80.14208221435558, 13.609581947326774],
        [80.14263916015653, 13.609581947326774],
        [80.14263916015653, 13.60986232757574],
        [80.14290618896484, 13.609860420227164],
        [80.14290618896484, 13.610139846801815],
        [80.14347076416033, 13.610139846801815],
        [80.14347076416033, 13.610421180725098],
        [80.1437530517581, 13.61041641235363],
        [80.1437530517581, 13.610693931579704],
        [80.14402770996111, 13.610693931579704],
        [80.14402770996111, 13.610972404480094],
        [80.14457702636713, 13.610969543457031],
        [80.14457702636713, 13.611249923705998],
        [80.1448593139649, 13.611249923705998],
        [80.1448593139649, 13.611531257629451],
        [80.14541625976568, 13.611531257629451],
        [80.14541625976568, 13.611820220947266],
        [80.14569091796903, 13.611820220947266],
        [80.14569091796903, 13.612360000610352],
        [80.1459732055664, 13.612360000610352],
        [80.1459732055664, 13.612920761108512],
        [80.14624786376982, 13.612915992736816],
        [80.14624786376982, 13.613471984863281],
        [80.14653015136719, 13.613470077514705],
        [80.14653015136719, 13.614311218261832],
        [80.1468048095706, 13.614306449890137],
        [80.1468048095706, 13.614863395690918],
        [80.14708709716814, 13.614859580993766],
        [80.14708709716814, 13.615421295166016],
        [80.14734649658226, 13.615421295166016],
        [80.14734649658226, 13.616250991821346],
        [80.14763641357439, 13.616250991821346],
        [80.14763641357439, 13.61681079864502],
        [80.1479187011721, 13.61681079864502],
        [80.1479187011721, 13.617361068725529],
        [80.14819335937494, 13.617361068725529],
        [80.14819335937494, 13.617920875549373],
        [80.14847564697271, 13.617917060851994],
        [80.14847564697271, 13.618473052978516],
        [80.1490249633789, 13.618473052978516],
      ],
    ],
    [
      [
        [80.09707641601557, 13.6415309906007],
        [80.09707641601557, 13.641249656677246],
        [80.09735870361334, 13.641249656677246],
        [80.09735870361334, 13.640139579773063],
        [80.09707641601557, 13.640139579773063],
        [80.09707641601557, 13.638740539550895],
        [80.09680938720726, 13.638750076294002],
        [80.09680175781273, 13.638194084167537],
        [80.09652709960955, 13.638194084167537],
        [80.09652709960955, 13.637638092041072],
        [80.09626007080107, 13.637639999389648],
        [80.09626007080107, 13.637359619140682],
        [80.09597015380876, 13.637361526489258],
        [80.09597015380876, 13.637084007263184],
        [80.09541320800781, 13.637084007263184],
        [80.09541320800781, 13.636805534362793],
        [80.09430694580084, 13.636811256408748],
        [80.09430694580084, 13.636529922485295],
        [80.09291839599626, 13.636529922485295],
        [80.09291839599626, 13.636249542236328],
        [80.09152984619152, 13.636249542236328],
        [80.09152984619152, 13.636811256408748],
        [80.0912475585938, 13.636805534362793],
        [80.0912475585938, 13.636940956115723],
        [80.0912475585938, 13.637361526489258],
        [80.09152984619152, 13.637359619140682],
        [80.09152984619152, 13.637921333313102],
        [80.09180450439453, 13.637915611267147],
        [80.09180450439453, 13.638194084167537],
        [80.09208679199247, 13.638194084167537],
        [80.09208679199247, 13.638471603393612],
        [80.09236145019531, 13.638471603393612],
        [80.09236145019531, 13.638750076294002],
        [80.09263610839872, 13.638740539550895],
        [80.09263610839872, 13.639310836791992],
        [80.09291839599626, 13.639304161071891],
        [80.09291839599626, 13.639583587646598],
        [80.09319305419928, 13.639583587646598],
        [80.09319305419928, 13.639860153198185],
        [80.09349060058611, 13.639860153198185],
        [80.09349060058611, 13.640139579773063],
        [80.09401702880871, 13.640139579773063],
        [80.09401702880871, 13.640420913696346],
        [80.09430694580084, 13.64041614532465],
        [80.09430694580084, 13.640693664550781],
        [80.09458160400402, 13.640693664550781],
        [80.09458160400402, 13.640972137451115],
        [80.09485626220703, 13.640970230102539],
        [80.09485626220703, 13.641249656677246],
        [80.09533691406278, 13.641249656677246],
        [80.09597015380876, 13.641249656677246],
        [80.09597015380876, 13.6415309906007],
        [80.09707641601557, 13.6415309906007],
      ],
    ],
    [
      [
        [80.10652923584007, 13.652919769287166],
        [80.10652923584007, 13.652080535888672],
        [80.10624694824236, 13.652083396911735],
        [80.10624694824236, 13.65152740478527],
        [80.10652923584007, 13.651529312133846],
        [80.10652923584007, 13.651250839233455],
        [80.10708618164068, 13.651250839233455],
        [80.10708618164068, 13.650971412658805],
        [80.10736083984386, 13.650973320007381],
        [80.10736083984386, 13.650138854980526],
        [80.10764312744146, 13.650140762329102],
        [80.10764312744146, 13.649312019348201],
        [80.10791778564482, 13.649312019348201],
        [80.10791778564482, 13.648470878601131],
        [80.10819244384766, 13.648470878601131],
        [80.10819244384766, 13.646804809570312],
        [80.10791778564482, 13.646812438964844],
        [80.10791778564482, 13.646250724792594],
        [80.10764312744146, 13.646250724792594],
        [80.10764312744146, 13.645971298217717],
        [80.10680389404291, 13.64597320556652],
        [80.10680389404291, 13.64569473266613],
        [80.10652923584007, 13.64569473266613],
        [80.10652923584007, 13.645417213440055],
        [80.10624694824236, 13.645421028137207],
        [80.10624694824236, 13.64514064788824],
        [80.10597229003912, 13.64514064788824],
        [80.10597229003912, 13.644310951233024],
        [80.10569763183611, 13.644310951233024],
        [80.10569763183611, 13.643190383911246],
        [80.10541534423857, 13.643195152282715],
        [80.10541534423857, 13.6423597335816],
        [80.10514068603516, 13.6423597335816],
        [80.10514068603516, 13.6415309906007],
        [80.10485839843778, 13.6415309906007],
        [80.10485839843778, 13.640689849853572],
        [80.10458374023438, 13.640693664550781],
        [80.10458374023438, 13.639583587646598],
        [80.10430908203136, 13.639583587646598],
        [80.10430908203136, 13.638750076294002],
        [80.10402679443365, 13.638750076294002],
        [80.10402679443365, 13.637915611267147],
        [80.10375213623041, 13.637921333313102],
        [80.10375213623041, 13.637359619140682],
        [80.10346984863287, 13.637361526489258],
        [80.10346984863287, 13.637250900268555],
        [80.10346984863287, 13.637084007263184],
        [80.10291290283226, 13.637084007263184],
        [80.10291290283226, 13.636805534362793],
        [80.1012496948245, 13.636805534362793],
        [80.1012496948245, 13.637084007263184],
        [80.10097503662115, 13.637084007263184],
        [80.10097503662115, 13.637361526489258],
        [80.10069274902355, 13.637359619140682],
        [80.10069274902355, 13.637639999389648],
        [80.10041809082037, 13.637638092041072],
        [80.10041809082037, 13.638471603393612],
        [80.1001358032226, 13.638469696045036],
        [80.1001358032226, 13.639310836791992],
        [80.10041809082037, 13.639304161071891],
        [80.10041809082037, 13.639860153198185],
        [80.10069274902355, 13.639860153198185],
        [80.10069274902355, 13.640110969543514],
        [80.10069274902355, 13.640420913696346],
        [80.10097503662115, 13.64041614532465],
        [80.10097503662115, 13.640972137451115],
        [80.1012496948245, 13.640970230102539],
        [80.1012496948245, 13.6415309906007],
        [80.10152435302734, 13.641528129577637],
        [80.10152435302734, 13.64180660247797],
        [80.10180664062528, 13.641801834106502],
        [80.10180664062528, 13.6423597335816],
        [80.1020812988283, 13.6423597335816],
        [80.1020812988283, 13.64292144775402],
        [80.10236358642607, 13.642915725708065],
        [80.10236358642607, 13.643472671508846],
        [80.10263824462908, 13.64346790313715],
        [80.10263824462908, 13.644030570983887],
        [80.10291290283226, 13.64402866363531],
        [80.10291290283226, 13.644584655761776],
        [80.10320281982439, 13.6445827484132],
        [80.10320281982439, 13.644869804382381],
        [80.10346984863287, 13.644869804382381],
        [80.10346984863287, 13.645421028137207],
        [80.10375213623041, 13.645421028137207],
        [80.10375213623041, 13.646250724792594],
        [80.10402679443365, 13.646250724792594],
        [80.10402679443365, 13.646812438964844],
        [80.10430908203136, 13.646804809570312],
        [80.10430908203136, 13.647083282470703],
        [80.10458374023438, 13.647083282470703],
        [80.10458374023438, 13.647360801696777],
        [80.10485839843778, 13.647360801696777],
        [80.10485839843778, 13.648195266723633],
        [80.10514068603516, 13.648189544677848],
        [80.10514068603516, 13.649030685424748],
        [80.10543060302763, 13.649030685424748],
        [80.10543060302763, 13.650140762329102],
        [80.10569763183611, 13.650140762329102],
        [80.10569763183611, 13.651250839233455],
        [80.10597229003912, 13.651250839233455],
        [80.10597229003912, 13.652639389038029],
        [80.10624694824236, 13.652639389038029],
        [80.10624694824236, 13.652919769287166],
        [80.10652923584007, 13.652919769287166],
      ],
    ],
    [
      [
        [80.10263824462908, 13.677640914916935],
        [80.10263824462908, 13.677360534667969],
        [80.10402679443365, 13.677360534667969],
        [80.10402679443365, 13.677083015441895],
        [80.10541534423857, 13.677083015441895],
        [80.10541534423857, 13.676804542541504],
        [80.10624694824236, 13.67681026458746],
        [80.10624694824236, 13.675689697265682],
        [80.10597229003912, 13.675695419311637],
        [80.10597229003912, 13.675416946411246],
        [80.10569763183611, 13.675421714782715],
        [80.10569763183611, 13.67486953735363],
        [80.10543060302763, 13.67486953735363],
        [80.10543060302763, 13.673471450805721],
        [80.10569763183611, 13.673473358154354],
        [80.10569763183611, 13.671528816223145],
        [80.10543060302763, 13.671530723571891],
        [80.10543060302763, 13.669862747192496],
        [80.10514068603516, 13.669862747192496],
        [80.10514068603516, 13.66874980926525],
        [80.10485839843778, 13.66874980926525],
        [80.10485839843778, 13.668470382690373],
        [80.10430908203136, 13.668472290039176],
        [80.10430908203136, 13.668193817138786],
        [80.10402679443365, 13.668191909789982],
        [80.10402679443365, 13.669031143188533],
        [80.10375213623041, 13.669026374816838],
        [80.10375213623041, 13.670695304870549],
        [80.10402679443365, 13.67068958282482],
        [80.10402679443365, 13.671809196472282],
        [80.10375213623041, 13.671805381774902],
        [80.10375213623041, 13.672361373901367],
        [80.10346984863287, 13.672361373901367],
        [80.10346984863287, 13.672921180725211],
        [80.10320281982439, 13.672921180725211],
        [80.10320281982439, 13.674031257629395],
        [80.10291290283226, 13.674031257629395],
        [80.10291290283226, 13.675141334533748],
        [80.10263824462908, 13.675139427185172],
        [80.10263824462908, 13.675972938537711],
        [80.1023559570313, 13.675971031188965],
        [80.1023559570313, 13.67681026458746],
        [80.1020812988283, 13.67681026458746],
        [80.1020812988283, 13.677640914916935],
        [80.10263824462908, 13.677640914916935],
      ],
    ],
    [
      [
        [80.05986022949247, 15.199861526489258],
        [80.05986022949247, 15.199582099914608],
        [80.06041717529325, 15.199582099914608],
        [80.06041717529325, 15.199305534362793],
        [80.06069183349626, 15.199311256408748],
        [80.06069183349626, 15.198749542236328],
        [80.06124877929705, 15.198749542236328],
        [80.06124877929705, 15.198472023010254],
        [80.06153106689459, 15.198472023010254],
        [80.06153106689459, 15.198193550109863],
        [80.06180572509783, 15.198193550109863],
        [80.06180572509783, 15.197916030883789],
        [80.06208038330084, 15.197930335998535],
        [80.06208038330084, 15.197640419006461],
        [80.06236267089838, 15.197640419006461],
        [80.06236267089838, 15.197079658508358],
        [80.06124877929705, 15.197079658508358],
        [80.06124877929705, 15.197360038757324],
        [80.06096649169928, 15.197360038757324],
        [80.06096649169928, 15.197640419006461],
        [80.06069183349626, 15.197640419006461],
        [80.06069183349626, 15.197930335998535],
        [80.06041717529325, 15.197916030883789],
        [80.06041717529325, 15.198193550109863],
        [80.06014251708984, 15.198193550109863],
        [80.06014251708984, 15.198472023010254],
        [80.05986022949247, 15.198472023010254],
        [80.05986022949247, 15.198749542236328],
        [80.05959320068365, 15.198749542236328],
        [80.05959320068365, 15.199030876159782],
        [80.05930328369152, 15.199028015136719],
        [80.05930328369152, 15.199582099914608],
        [80.05958557128912, 15.199582099914608],
        [80.05958557128912, 15.199861526489258],
        [80.05986022949247, 15.199861526489258],
      ],
    ],
    [
      [
        [80.21069335937506, 15.501530647277889],
        [80.21069335937506, 15.501251220703239],
        [80.21098327636736, 15.501251220703239],
        [80.21097564697283, 15.500695228576774],
        [80.21125030517601, 15.500695228576774],
        [80.21125030517601, 15.500416755676383],
        [80.21153259277361, 15.500420570373535],
        [80.21153259277361, 15.499859809875602],
        [80.21180725097662, 15.499859809875602],
        [80.21180725097662, 15.499311447143668],
        [80.21153259277361, 15.499311447143668],
        [80.21153259277361, 15.499031066894531],
        [80.20958709716825, 15.499031066894531],
        [80.20958709716825, 15.499311447143668],
        [80.20929718017578, 15.499311447143668],
        [80.20929718017578, 15.499859809875602],
        [80.2090301513673, 15.499859809875602],
        [80.2090301513673, 15.500420570373535],
        [80.20929718017578, 15.500420570373535],
        [80.20929718017578, 15.500972747802848],
        [80.20958709716825, 15.500972747802848],
        [80.20958709716825, 15.501251220703239],
        [80.2098617553711, 15.501251220703239],
        [80.2098617553711, 15.501530647277889],
        [80.21069335937506, 15.501530647277889],
      ],
    ],
    [
      [
        [81.02791595458984, 15.765693664550781],
        [81.02791595458984, 15.765416145324707],
        [81.0287475585938, 15.765420913696346],
        [81.0287475585938, 15.765139579773063],
        [81.02958679199213, 15.765139579773063],
        [81.02958679199213, 15.764860153198242],
        [81.02986145019537, 15.764860153198242],
        [81.02986145019537, 15.764583587646598],
        [81.02958679199213, 15.764583587646598],
        [81.02958679199213, 15.76430416107172],
        [81.02929687500006, 15.764310836791992],
        [81.02929687500006, 15.763750076294059],
        [81.02902984619158, 15.763750076294059],
        [81.02902984619158, 15.763190269470215],
        [81.0287475585938, 15.763194084167594],
        [81.0287475585938, 15.762915611267204],
        [81.0284729003908, 15.762921333312931],
        [81.0284729003908, 15.762639999389705],
        [81.02819824218778, 15.762639999389705],
        [81.02819824218778, 15.762359619140739],
        [81.02791595458984, 15.762361526489315],
        [81.02791595458984, 15.76208400726324],
        [81.027641296387, 15.76208400726324],
        [81.027641296387, 15.76180553436285],
        [81.02735900878906, 15.761811256408805],
        [81.02735900878906, 15.761529922485352],
        [81.02707672119169, 15.761529922485352],
        [81.02707672119169, 15.761249542236385],
        [81.02680206298834, 15.761249542236385],
        [81.02680206298834, 15.760972023010254],
        [81.0265274047851, 15.760972023010254],
        [81.0265274047851, 15.760693550109863],
        [81.02625274658226, 15.760693550109863],
        [81.02625274658226, 15.760417938232365],
        [81.02597045898455, 15.760419845581168],
        [81.02597045898455, 15.76014041900629],
        [81.0256958007813, 15.76014041900629],
        [81.0256958007813, 15.7598619461059],
        [81.02540588378923, 15.7598619461059],
        [81.02540588378923, 15.759581565856934],
        [81.02513885498075, 15.759584426879996],
        [81.02513885498075, 15.759305953979435],
        [81.02485656738298, 15.759309768676815],
        [81.02485656738298, 15.759030342102108],
        [81.02458190917997, 15.759030342102108],
        [81.02458190917997, 15.758749961853141],
        [81.02430725097656, 15.758749961853141],
        [81.02430725097656, 15.758468627929688],
        [81.02402496337919, 15.758472442627067],
        [81.02402496337919, 15.758193969726676],
        [81.02375030517584, 15.758193969726676],
        [81.02375030517584, 15.757916450500602],
        [81.02291870117205, 15.757920265197754],
        [81.02291870117205, 15.757651329040527],
        [81.02179718017595, 15.757651329040527],
        [81.02179718017595, 15.757360458374137],
        [81.02097320556669, 15.757360458374137],
        [81.02097320556669, 15.757651329040527],
        [81.02041625976574, 15.757651329040527],
        [81.02041625976574, 15.757920265197754],
        [81.02014160156256, 15.757916450500602],
        [81.02014160156256, 15.758189201355037],
        [81.02041625976574, 15.758195877075252],
        [81.02041625976574, 15.758472442627067],
        [81.02097320556669, 15.758468627929688],
        [81.02097320556669, 15.758749961853141],
        [81.02124786376953, 15.758749961853141],
        [81.02124786376953, 15.759030342102108],
        [81.02179718017595, 15.759030342102108],
        [81.02179718017595, 15.759309768676815],
        [81.02236175537126, 15.759305953979435],
        [81.02236175537126, 15.759584426879996],
        [81.02291870117205, 15.759581565856934],
        [81.02291870117205, 15.7598619461059],
        [81.02320098876976, 15.7598619461059],
        [81.02320098876976, 15.76014041900629],
        [81.02375030517584, 15.76014041900629],
        [81.02375030517584, 15.760419845581168],
        [81.02430725097656, 15.760417938232365],
        [81.02430725097656, 15.760693550109863],
        [81.02486419677734, 15.760693550109863],
        [81.02486419677734, 15.760972023010254],
        [81.02513885498075, 15.760972023010254],
        [81.02513885498075, 15.761249542236385],
        [81.0256958007813, 15.761249542236385],
        [81.0256958007813, 15.761811256408805],
        [81.02597045898455, 15.76180553436285],
        [81.02597045898455, 15.762361526489315],
        [81.02626037597679, 15.762359619140739],
        [81.02626037597679, 15.762921333312931],
        [81.0265274047851, 15.762915611267204],
        [81.0265274047851, 15.763471603393668],
        [81.02680206298834, 15.763469696044865],
        [81.02680206298834, 15.764031410217285],
        [81.02708435058605, 15.764027595520133],
        [81.02708435058605, 15.764583587646598],
        [81.02735900878906, 15.764579772949219],
        [81.02735900878906, 15.765139579773063],
        [81.027641296387, 15.765137672424316],
        [81.027641296387, 15.765693664550781],
        [81.02791595458984, 15.765693664550781],
      ],
    ],
    [
      [
        [80.7829132080081, 15.837639808654785],
        [80.7829132080081, 15.837080955505428],
        [80.78319549560564, 15.83708381652832],
        [80.78319549560564, 15.836806297302246],
        [80.78347015380865, 15.836810111999569],
        [80.78347015380865, 15.836529731750431],
        [80.7840270996096, 15.836529731750431],
        [80.7840270996096, 15.836250305175781],
        [80.78430175781244, 15.836250305175781],
        [80.7843093872072, 15.835970878601074],
        [80.78458404541021, 15.835970878601074],
        [80.78458404541021, 15.835693359375],
        [80.7848587036134, 15.835693359375],
        [80.7848587036134, 15.835417747497672],
        [80.78542327880871, 15.835419654846248],
        [80.78542327880871, 15.835140228271598],
        [80.78597259521513, 15.835140228271598],
        [80.78597259521513, 15.834861755371207],
        [80.78624725341814, 15.834861755371207],
        [80.78624725341814, 15.83458137512207],
        [80.78680419921892, 15.834583282470817],
        [80.78680419921892, 15.834304809570426],
        [80.78708648681646, 15.834309577941895],
        [80.78708648681646, 15.834030151367244],
        [80.7873611450197, 15.834030151367244],
        [80.7873611450197, 15.833751678466854],
        [80.78763580322271, 15.833751678466854],
        [80.78763580322271, 15.833471298217887],
        [80.78847503662121, 15.833471298217887],
        [80.78847503662121, 15.833194732666072],
        [80.789306640625, 15.833194732666072],
        [80.789306640625, 15.832915306091422],
        [80.78958129882841, 15.832930564880485],
        [80.78958129882841, 15.8326416015625],
        [80.78985595703142, 15.8326416015625],
        [80.78985595703142, 15.832361221313533],
        [80.79013824462896, 15.832361221313533],
        [80.79013824462896, 15.832082748413143],
        [80.7904129028322, 15.832082748413143],
        [80.7904129028322, 15.831526756286678],
        [80.79070281982445, 15.831531524658317],
        [80.79070281982445, 15.831249237060604],
        [80.79096984863276, 15.831249237060604],
        [80.79096984863276, 15.830691337585506],
        [80.79125213623053, 15.830695152282715],
        [80.79125213623053, 15.83013916015625],
        [80.79151916503935, 15.830141067504996],
        [80.79151916503935, 15.829581260681152],
        [80.7918090820313, 15.829583168029728],
        [80.7918090820313, 15.827638626098746],
        [80.79207611084013, 15.827640533447322],
        [80.79207611084013, 15.827361106872615],
        [80.7923583984375, 15.827361106872615],
        [80.7923583984375, 15.827082633972225],
        [80.793197631836, 15.827082633972225],
        [80.793197631836, 15.82680511474615],
        [80.79347229003923, 15.826811790466422],
        [80.79347229003923, 15.826531410217228],
        [80.79374694824241, 15.826531410217228],
        [80.79374694824241, 15.826251029968262],
        [80.793197631836, 15.826251029968262],
        [80.793197631836, 15.825970649719295],
        [80.79264068603544, 15.825972557067871],
        [80.79264068603544, 15.825695037841797],
        [80.79208374023466, 15.825695037841797],
        [80.79208374023466, 15.825139045715332],
        [80.7918090820313, 15.825140953063908],
        [80.7918090820313, 15.824311256408748],
        [80.79151916503935, 15.824311256408748],
        [80.79151916503935, 15.824030876159725],
        [80.79096984863276, 15.824030876159725],
        [80.79096984863276, 15.82374191284174],
        [80.7904129028322, 15.82374191284174],
        [80.7904129028322, 15.824030876159725],
        [80.78985595703142, 15.824030876159725],
        [80.78985595703142, 15.824311256408748],
        [80.789306640625, 15.824304580688477],
        [80.789306640625, 15.824860572814941],
        [80.7890167236331, 15.824860572814941],
        [80.7890167236331, 15.825421333313102],
        [80.78874969482422, 15.825416564941406],
        [80.78874969482422, 15.825972557067871],
        [80.78848266601574, 15.825970649719295],
        [80.78848266601574, 15.826531410217228],
        [80.7881927490235, 15.82652664184576],
        [80.7881927490235, 15.827082633972225],
        [80.78791809082026, 15.827080726623649],
        [80.78791809082026, 15.827640533447322],
        [80.78763580322271, 15.827638626098746],
        [80.78763580322271, 15.828194618225211],
        [80.7873611450197, 15.828194618225211],
        [80.7873611450197, 15.828473091125602],
        [80.78708648681646, 15.828471183776799],
        [80.78708648681646, 15.829030990600643],
        [80.78679656982439, 15.829030990600643],
        [80.78679656982439, 15.829310417175293],
        [80.78652954101591, 15.829304695129395],
        [80.78652954101591, 15.829583168029728],
        [80.78624725341814, 15.829581260681152],
        [80.78624725341814, 15.829870223999137],
        [80.78597259521513, 15.829870223999137],
        [80.78597259521513, 15.830970764160213],
        [80.78569793701172, 15.830970764160213],
        [80.78569793701172, 15.831531524658317],
        [80.78541564941435, 15.831526756286678],
        [80.78541564941435, 15.832082748413143],
        [80.785140991211, 15.832082748413143],
        [80.785140991211, 15.832361221313533],
        [80.7848587036134, 15.832361221313533],
        [80.7848587036134, 15.832930564880485],
        [80.78458404541021, 15.832915306091422],
        [80.78458404541021, 15.833471298217887],
        [80.78430175781244, 15.833471298217887],
        [80.78430175781244, 15.833751678466854],
        [80.7840270996096, 15.833751678466854],
        [80.7840270996096, 15.834030151367244],
        [80.78375244140642, 15.834027290344352],
        [80.78375244140642, 15.834583282470817],
        [80.78347015380865, 15.83458137512207],
        [80.78347015380865, 15.834861755371207],
        [80.783203125, 15.834861755371207],
        [80.783203125, 15.835140228271598],
        [80.7829132080081, 15.835140228271598],
        [80.7829132080081, 15.835693359375],
        [80.78263854980469, 15.83568191528326],
        [80.78263854980469, 15.836250305175781],
        [80.78234863281278, 15.836250305175781],
        [80.78234863281278, 15.836810111999569],
        [80.78208160400396, 15.836810111999569],
        [80.78208160400396, 15.837639808654785],
        [80.7829132080081, 15.837639808654785],
      ],
    ],
    [
      [
        [80.78791809082026, 15.843194007873592],
        [80.78791809082026, 15.842915534973201],
        [80.78848266601574, 15.842921257019157],
        [80.78848266601574, 15.842639923095703],
        [80.7890167236331, 15.842639923095703],
        [80.7890167236331, 15.842359542846737],
        [80.789306640625, 15.842362403869572],
        [80.789306640625, 15.842083930969238],
        [80.78986358642595, 15.842083930969238],
        [80.78986358642595, 15.841806411743107],
        [80.7904129028322, 15.841811180114803],
        [80.7904129028322, 15.840691566467285],
        [80.79013824462896, 15.840694427490348],
        [80.79013824462896, 15.840415954589787],
        [80.78985595703142, 15.840419769287166],
        [80.78985595703142, 15.84014034271246],
        [80.78958129882841, 15.84014034271246],
        [80.78958129882841, 15.839861869812069],
        [80.789306640625, 15.839861869812069],
        [80.789306640625, 15.839582443237418],
        [80.78902435302763, 15.839584350585994],
        [80.78902435302763, 15.83819580078125],
        [80.78874969482422, 15.83819580078125],
        [80.78874969482422, 15.837639808654785],
        [80.78791809082026, 15.837639808654785],
        [80.78791809082026, 15.837920188903752],
        [80.78763580322271, 15.8379163742066],
        [80.78763580322271, 15.83819580078125],
        [80.7873611450197, 15.838191032409782],
        [80.7873611450197, 15.84014034271246],
        [80.78708648681646, 15.840138435363883],
        [80.78708648681646, 15.840971946716252],
        [80.78679656982439, 15.840971946716252],
        [80.78679656982439, 15.841250419616642],
        [80.78652954101591, 15.841250419616642],
        [80.78652954101591, 15.84152984619152],
        [80.78624725341814, 15.84152984619152],
        [80.78624725341814, 15.842639923095703],
        [80.78652954101591, 15.842639923095703],
        [80.78652954101591, 15.842921257019157],
        [80.78680419921892, 15.842915534973201],
        [80.78680419921892, 15.843194007873592],
        [80.78791809082026, 15.843194007873592],
      ],
    ],
    [
      [
        [80.78597259521513, 15.8565292358399],
        [80.78597259521513, 15.85625076293951],
        [80.78624725341814, 15.85625076293951],
        [80.78624725341814, 15.855971336364803],
        [80.78680419921892, 15.855973243713436],
        [80.78680419921892, 15.855694770813045],
        [80.78708648681646, 15.855694770813045],
        [80.78708648681646, 15.855417251586914],
        [80.7873611450197, 15.85542201995861],
        [80.7873611450197, 15.855140686035156],
        [80.78763580322271, 15.855140686035156],
        [80.78763580322271, 15.854580879211483],
        [80.78791809082026, 15.854582786560059],
        [80.78791809082026, 15.85180473327648],
        [80.78763580322271, 15.851811408996582],
        [80.78763580322271, 15.850970268249625],
        [80.78791809082026, 15.850973129272518],
        [80.78791809082026, 15.849304199218807],
        [80.7881927490235, 15.849310874939079],
        [80.7881927490235, 15.845970153808594],
        [80.78791809082026, 15.84597206115734],
        [80.78791809082026, 15.84569358825695],
        [80.78763580322271, 15.84569358825695],
        [80.78763580322271, 15.84486007690441],
        [80.78708648681646, 15.844850540161076],
        [80.78708648681646, 15.845140457153377],
        [80.78652954101591, 15.845140457153377],
        [80.78652954101591, 15.845420837402344],
        [80.78624725341814, 15.845416069030875],
        [80.78624725341814, 15.84569358825695],
        [80.78597259521513, 15.84568977355957],
        [80.78597259521513, 15.846249580383414],
        [80.78569793701172, 15.846249580383414],
        [80.78569793701172, 15.846530914306697],
        [80.78541564941435, 15.846528053283805],
        [80.78541564941435, 15.847359657287598],
        [80.785140991211, 15.847363471984977],
        [80.785140991211, 15.847915649414062],
        [80.7848587036134, 15.847915649414062],
        [80.7848587036134, 15.848472595214844],
        [80.78457641601568, 15.848469734191951],
        [80.78457641601568, 15.850420951843205],
        [80.78430175781244, 15.850420951843205],
        [80.78430175781244, 15.851531028747559],
        [80.7840270996096, 15.851529121398983],
        [80.7840270996096, 15.852083206176871],
        [80.78375244140642, 15.852083206176871],
        [80.78375244140642, 15.854582786560059],
        [80.7840270996096, 15.854580879211483],
        [80.7840270996096, 15.855140686035156],
        [80.78430175781244, 15.855140686035156],
        [80.78430175781244, 15.85542201995861],
        [80.7848587036134, 15.855417251586914],
        [80.7848587036134, 15.855694770813045],
        [80.785140991211, 15.855694770813045],
        [80.785140991211, 15.855973243713436],
        [80.78542327880871, 15.855971336364803],
        [80.78542327880871, 15.85625076293951],
        [80.78569793701172, 15.85625076293951],
        [80.78569793701172, 15.8565292358399],
        [80.78597259521513, 15.8565292358399],
      ],
    ],
    [
      [
        [80.77348327636719, 15.861809730529728],
        [80.77348327636719, 15.861251831054688],
        [80.77375030517607, 15.861251831054688],
        [80.77375030517607, 15.860971450805721],
        [80.77402496337908, 15.860971450805721],
        [80.77402496337908, 15.860138893127441],
        [80.77430725097662, 15.860141754150504],
        [80.77430725097662, 15.8595809936524],
        [80.77458190917986, 15.859582901000977],
        [80.77458190917986, 15.859026908874512],
        [80.77485656738287, 15.85903167724615],
        [80.77485656738287, 15.858751296997184],
        [80.77540588378912, 15.858751296997184],
        [80.77540588378912, 15.85819053649908],
        [80.77569580078136, 15.85819530487072],
        [80.77569580078136, 15.857916831970329],
        [80.77597045898438, 15.857919692993221],
        [80.77597045898438, 15.855690956115836],
        [80.77625274658232, 15.855694770813045],
        [80.77625274658232, 15.855417251586914],
        [80.77654266357422, 15.85542201995861],
        [80.77654266357422, 15.855140686035156],
        [80.77680206298857, 15.855140686035156],
        [80.77680206298857, 15.85404014587408],
        [80.77707672119158, 15.85404014587408],
        [80.77707672119158, 15.851531028747559],
        [80.77764129638689, 15.851531028747559],
        [80.77764129638689, 15.851250648498592],
        [80.77791595459007, 15.851250648498592],
        [80.77791595459007, 15.850970268249625],
        [80.77874755859386, 15.850973129272518],
        [80.77874755859386, 15.850417137146053],
        [80.77902984619146, 15.850420951843205],
        [80.77902984619146, 15.850140571594238],
        [80.77928924560575, 15.850140571594238],
        [80.77928924560575, 15.848751068115234],
        [80.7796020507813, 15.848751068115234],
        [80.7796020507813, 15.848469734191951],
        [80.7798614501956, 15.848472595214844],
        [80.7798614501956, 15.847915649414062],
        [80.78013610839861, 15.847915649414062],
        [80.78013610839861, 15.847082138061523],
        [80.78041839599615, 15.847082138061523],
        [80.78041839599615, 15.846249580383414],
        [80.78069305419939, 15.846249580383414],
        [80.78069305419939, 15.844850540161076],
        [80.78041839599615, 15.84486007690441],
        [80.78041839599615, 15.843672752380371],
        [80.78041839599615, 15.843471527099666],
        [80.7798614501956, 15.84346961975109],
        [80.7798614501956, 15.84375],
        [80.77958679199219, 15.84375],
        [80.77958679199219, 15.84429931640625],
        [80.77958679199219, 15.844584465026912],
        [80.77928924560575, 15.844579696655217],
        [80.77928924560575, 15.845140457153377],
        [80.77902984619146, 15.845137596130485],
        [80.77902984619146, 15.84597206115734],
        [80.77874755859386, 15.845970153808594],
        [80.77874755859386, 15.846249580383414],
        [80.77847290039068, 15.846249580383414],
        [80.77847290039068, 15.846811294555664],
        [80.77819824218744, 15.846805572509709],
        [80.77819061279291, 15.847082138061523],
        [80.77791595459007, 15.847080230712947],
        [80.77791595459007, 15.847359657287598],
        [80.77764129638689, 15.847363471984977],
        [80.77764129638689, 15.847915649414062],
        [80.77735900878912, 15.847915649414062],
        [80.77735900878912, 15.848472595214844],
        [80.77707672119158, 15.848469734191951],
        [80.77707672119158, 15.848751068115234],
        [80.77680206298857, 15.848751068115234],
        [80.77680206298857, 15.849031448364371],
        [80.77652740478516, 15.849028587341309],
        [80.77652740478516, 15.849584579467773],
        [80.77625274658232, 15.849584579467773],
        [80.77625274658232, 15.849861145019588],
        [80.77597045898438, 15.849861145019588],
        [80.77597045898438, 15.850140571594238],
        [80.77569580078136, 15.850140571594238],
        [80.77569580078136, 15.850420951843205],
        [80.77541351318365, 15.850417137146053],
        [80.77541351318365, 15.850973129272518],
        [80.77513885498041, 15.850970268249625],
        [80.77513885498041, 15.851531028747559],
        [80.77486419677757, 15.851529121398983],
        [80.77486419677757, 15.852083206176871],
        [80.77458190917986, 15.852080345153809],
        [80.77458190917986, 15.852641105651912],
        [80.77430725097662, 15.852641105651912],
        [80.77430725097662, 15.85292053222662],
        [80.77402496337908, 15.85291671752924],
        [80.77402496337908, 15.85319519042963],
        [80.77375030517607, 15.85319519042963],
        [80.77375030517607, 15.853470802307129],
        [80.77347564697266, 15.853470802307129],
        [80.77348327636719, 15.853751182556096],
        [80.77319335937528, 15.853751182556096],
        [80.77319335937528, 15.85404014587408],
        [80.77291870117188, 15.854026794433594],
        [80.77291870117188, 15.85486125946045],
        [80.7726364135745, 15.85486125946045],
        [80.7726364135745, 15.85542201995861],
        [80.77236175537115, 15.855417251586914],
        [80.77236175537115, 15.855694770813045],
        [80.77208709716791, 15.855694770813045],
        [80.77208709716791, 15.855973243713436],
        [80.77179718017584, 15.855971336364803],
        [80.77179718017584, 15.857360839843864],
        [80.77153015136736, 15.857360839843864],
        [80.77153015136736, 15.858751296997184],
        [80.77179718017584, 15.858751296997184],
        [80.77179718017584, 15.860141754150504],
        [80.77208709716791, 15.860141754150504],
        [80.77208709716791, 15.860420227050895],
        [80.77236175537115, 15.860417366027832],
        [80.77236175537115, 15.860971450805721],
        [80.7726364135745, 15.860971450805721],
        [80.7726364135745, 15.861530303955078],
        [80.77291870117188, 15.861530303955078],
        [80.77291870117188, 15.861809730529728],
        [80.77348327636719, 15.861809730529728],
      ],
    ],
    [
      [
        [81.54180908203148, 16.35542106628418],
        [81.54180908203148, 16.355140686035213],
        [81.54207611083996, 16.355140686035213],
        [81.54207611083996, 16.354860305786246],
        [81.54264068603527, 16.354860305786246],
        [81.54264068603527, 16.35457992553711],
        [81.54291534423828, 16.354583740234432],
        [81.54291534423828, 16.354305267334098],
        [81.54319763183622, 16.354310989379826],
        [81.54319763183622, 16.35403060913086],
        [81.54347229003906, 16.35403060913086],
        [81.54347229003906, 16.353750228881893],
        [81.54403686523455, 16.353750228881893],
        [81.54402923584001, 16.353471755981502],
        [81.54430389404303, 16.353471755981502],
        [81.54430389404303, 16.353195190429744],
        [81.5445861816408, 16.353200912475643],
        [81.5445861816408, 16.352920532226506],
        [81.54486083984398, 16.352920532226506],
        [81.54486083984398, 16.35264015197754],
        [81.54511260986357, 16.35264015197754],
        [81.54541778564459, 16.35264015197754],
        [81.54541778564459, 16.352359771728572],
        [81.54569244384777, 16.35236167907715],
        [81.54569244384777, 16.352083206176758],
        [81.54597473144537, 16.352083206176758],
        [81.54597473144537, 16.351804733276367],
        [81.54624938964872, 16.351804733276367],
        [81.54624938964872, 16.35152816772461],
        [81.5465164184572, 16.351530075073356],
        [81.5465164184572, 16.35124969482422],
        [81.5468063354495, 16.35124969482422],
        [81.5468063354495, 16.350971221923828],
        [81.54708099365251, 16.350973129272404],
        [81.54708099365251, 16.350694656372013],
        [81.54736328125006, 16.350694656372013],
        [81.54736328125006, 16.349584579467887],
        [81.54708099365251, 16.349584579467887],
        [81.54708099365251, 16.349306106567496],
        [81.5468063354495, 16.34930992126465],
        [81.5468063354495, 16.349029541015682],
        [81.54624938964872, 16.349029541015682],
        [81.54624938964872, 16.34875106811529],
        [81.54541778564459, 16.34875106811529],
        [81.54541778564459, 16.3484725952149],
        [81.54513549804682, 16.3484725952149],
        [81.54513549804682, 16.34819412231451],
        [81.54373168945318, 16.34819412231451],
        [81.54319763183622, 16.34819412231451],
        [81.54319763183622, 16.3484725952149],
        [81.5407028198245, 16.3484725952149],
        [81.5407028198245, 16.34875106811529],
        [81.5404129028322, 16.34875106811529],
        [81.5404129028322, 16.3484725952149],
        [81.54013824462919, 16.3484725952149],
        [81.54013824462919, 16.34875106811529],
        [81.53985595703125, 16.34875106811529],
        [81.53985595703125, 16.3484725952149],
        [81.53958129882841, 16.3484725952149],
        [81.53958129882841, 16.34875106811529],
        [81.53874969482428, 16.34875106811529],
        [81.53874969482428, 16.3484725952149],
        [81.5373611450197, 16.3484725952149],
        [81.5373611450197, 16.34875106811529],
        [81.53180694580095, 16.34875106811529],
        [81.53180694580095, 16.349029541015682],
        [81.53151702880882, 16.349029541015682],
        [81.53151702880882, 16.34875106811529],
        [81.53098297119169, 16.34875106811529],
        [81.53098297119169, 16.349029541015682],
        [81.52401733398438, 16.349029541015682],
        [81.52401733398438, 16.34930992126465],
        [81.51930236816429, 16.349306106567496],
        [81.51930236816429, 16.349584579467887],
        [81.51486206054688, 16.349582672119084],
        [81.51486206054688, 16.349861145019645],
        [81.51346588134777, 16.349861145019645],
        [81.51346588134777, 16.350139617920036],
        [81.51319122314459, 16.350139617920036],
        [81.51319122314459, 16.35124969482422],
        [81.51346588134777, 16.35124969482422],
        [81.51346588134777, 16.351530075073356],
        [81.51403045654308, 16.35152816772461],
        [81.51403045654308, 16.351804733276367],
        [81.5143051147461, 16.351804733276367],
        [81.5143051147461, 16.352083206176758],
        [81.5145797729495, 16.352083206176758],
        [81.5145797729495, 16.35236167907715],
        [81.51513671875028, 16.352359771728572],
        [81.51513671875028, 16.35264015197754],
        [81.51541900634783, 16.35264015197754],
        [81.51541900634783, 16.352920532226506],
        [81.51568603515648, 16.352920532226506],
        [81.51568603515648, 16.353200912475643],
        [81.51652526855463, 16.353195190429744],
        [81.51652526855463, 16.353471755981502],
        [81.51708221435558, 16.35346031188959],
        [81.51708221435558, 16.353750228881893],
        [81.51790618896501, 16.353750228881893],
        [81.51790618896501, 16.35403060913086],
        [81.51930236816429, 16.35403060913086],
        [81.51930236816429, 16.354310989379826],
        [81.52041625976568, 16.354305267334098],
        [81.52041625976568, 16.354583740234432],
        [81.52208709716814, 16.354583740234432],
        [81.52208709716814, 16.354305267334098],
        [81.52236175537132, 16.354310989379826],
        [81.52236175537132, 16.35346031188959],
        [81.52263641357416, 16.353471755981502],
        [81.52263641357416, 16.35236167907715],
        [81.52291870117193, 16.35236167907715],
        [81.52291870117193, 16.351804733276367],
        [81.52347564697271, 16.351804733276367],
        [81.52347564697271, 16.35152816772461],
        [81.52401733398438, 16.351530075073356],
        [81.52401733398438, 16.35124969482422],
        [81.52458190917986, 16.35124969482422],
        [81.52458190917986, 16.350971221923828],
        [81.52513885498064, 16.350973129272404],
        [81.52513885498064, 16.350694656372013],
        [81.52847290039068, 16.350694656372013],
        [81.52847290039068, 16.350973129272404],
        [81.52874755859392, 16.350971221923828],
        [81.52874755859392, 16.351530075073356],
        [81.52902984619163, 16.35152816772461],
        [81.52902984619163, 16.351804733276367],
        [81.53013610839844, 16.351804733276367],
        [81.53013610839844, 16.35152816772461],
        [81.5323638916015, 16.35152816772461],
        [81.5323638916015, 16.351804733276367],
        [81.53347015380888, 16.351804733276367],
        [81.53347015380888, 16.352083206176758],
        [81.53375244140625, 16.352083206176758],
        [81.53375244140625, 16.35236167907715],
        [81.53402709960966, 16.352359771728572],
        [81.53402709960966, 16.35264015197754],
        [81.53430175781267, 16.35264015197754],
        [81.53430175781267, 16.352920532226506],
        [81.53457641601568, 16.352920532226506],
        [81.53457641601568, 16.353200912475643],
        [81.53485870361345, 16.353195190429744],
        [81.53485870361345, 16.353471755981502],
        [81.53514099121116, 16.35346031188959],
        [81.53514099121116, 16.353750228881893],
        [81.53542327880876, 16.353750228881893],
        [81.53542327880876, 16.35403060913086],
        [81.53597259521496, 16.35403060913086],
        [81.53597259521496, 16.354310989379826],
        [81.53624725341803, 16.354305267334098],
        [81.53624725341803, 16.354583740234432],
        [81.53652954101591, 16.35457992553711],
        [81.53652954101591, 16.354860305786246],
        [81.53819274902366, 16.354860305786246],
        [81.53819274902366, 16.355140686035213],
        [81.53985595703125, 16.355140686035213],
        [81.53985595703125, 16.35542106628418],
        [81.54180908203148, 16.35542106628418],
      ],
    ],
    [
      [
        [81.53514099121116, 16.355972290039006],
        [81.53514099121116, 16.355693817138615],
        [81.535415649414, 16.355693817138615],
        [81.535415649414, 16.354583740234432],
        [81.53514099121116, 16.354583740234432],
        [81.53514099121116, 16.354305267334098],
        [81.53486633300798, 16.354310989379826],
        [81.53486633300798, 16.35403060913086],
        [81.53457641601568, 16.35403060913086],
        [81.53457641601568, 16.35346031188959],
        [81.5343093872072, 16.353471755981502],
        [81.53430175781267, 16.353195190429744],
        [81.53375244140625, 16.353200912475643],
        [81.53375244140625, 16.352920532226506],
        [81.53347015380888, 16.352920532226506],
        [81.53347015380888, 16.352359771728572],
        [81.53263854980474, 16.352359771728572],
        [81.53263854980474, 16.35264015197754],
        [81.53151702880882, 16.35264015197754],
        [81.53151702880882, 16.353750228881893],
        [81.53180694580095, 16.353750228881893],
        [81.53180694580095, 16.354583740234432],
        [81.53208160400413, 16.35457992553711],
        [81.53208160400413, 16.354860305786246],
        [81.53235626220697, 16.354860305786246],
        [81.53235626220697, 16.35542106628418],
        [81.53291320800793, 16.355417251587028],
        [81.53291320800793, 16.355693817138615],
        [81.53347015380888, 16.355693817138615],
        [81.53347015380888, 16.355972290039006],
        [81.53514099121116, 16.355972290039006],
      ],
    ],
    [
      [
        [82.31735229492193, 16.57653045654297],
        [82.31735229492193, 16.574579238891715],
        [82.31708526611345, 16.57458114624029],
        [82.31708526611345, 16.574029922485295],
        [82.31680297851591, 16.574031829834098],
        [82.31680297851591, 16.573749542236328],
        [82.3165283203125, 16.573749542236328],
        [82.3165283203125, 16.57346916198736],
        [82.31540679931646, 16.57346916198736],
        [82.31540679931646, 16.573749542236328],
        [82.31485748291038, 16.573749542236328],
        [82.31485748291038, 16.574859619140682],
        [82.31513977050776, 16.574859619140682],
        [82.31513977050776, 16.575420379638615],
        [82.315414428711, 16.575416564941463],
        [82.315414428711, 16.575973510742244],
        [82.31597137451178, 16.575969696045036],
        [82.31597137451178, 16.576259613037166],
        [82.31624603271513, 16.576259613037166],
        [82.31624603271513, 16.57653045654297],
        [82.31735229492193, 16.57653045654297],
      ],
    ],
    [
      [
        [82.26235961914062, 16.677917480468864],
        [82.26235961914062, 16.67764091491705],
        [82.26264190673857, 16.67764091491705],
        [82.26264190673857, 16.677360534667912],
        [82.26291656494158, 16.677360534667912],
        [82.26291656494158, 16.67542076110834],
        [82.26319885253935, 16.67542076110834],
        [82.26319885253935, 16.67486000061041],
        [82.26347351074236, 16.674861907958984],
        [82.26347351074236, 16.67458343505865],
        [82.26319122314459, 16.67458343505865],
        [82.26319122314459, 16.674306869506836],
        [82.26346588134783, 16.674310684204215],
        [82.26346588134783, 16.673469543457088],
        [82.26319122314459, 16.673471450805664],
        [82.26319122314459, 16.671806335449332],
        [82.26291656494158, 16.671810150146484],
        [82.26291656494158, 16.66986274719244],
        [82.26264190673857, 16.66986274719244],
        [82.26264190673857, 16.66958045959484],
        [82.26235961914062, 16.66958045959484],
        [82.26235961914062, 16.66986274719244],
        [82.26207733154325, 16.66986274719244],
        [82.26207733154325, 16.670141220092773],
        [82.2618026733399, 16.670139312744197],
        [82.2618026733399, 16.670694351196403],
        [82.26152801513683, 16.670690536499023],
        [82.26152801513683, 16.672649383545036],
        [82.26125335693365, 16.672637939453125],
        [82.26125335693365, 16.673471450805664],
        [82.26097106933588, 16.673471450805664],
        [82.26097106933588, 16.675971984863338],
        [82.26069641113304, 16.675970077514762],
        [82.26069641113304, 16.67625045776373],
        [82.26097106933588, 16.67625045776373],
        [82.26097106933588, 16.677360534667912],
        [82.26125335693365, 16.677360534667912],
        [82.26125335693365, 16.67764091491705],
        [82.26152801513683, 16.67764091491705],
        [82.26152801513683, 16.677917480468864],
        [82.26235961914062, 16.677917480468864],
      ],
    ],
    [
      [
        [82.29951477050798, 16.71433258056652],
        [82.29957580566412, 16.714031219482536],
        [82.29938507080084, 16.714029312133732],
        [82.29943847656278, 16.714128494262752],
        [82.29951477050798, 16.71433258056652],
      ],
    ],
    [
      [
        [82.30790710449236, 16.716529846191406],
        [82.30790710449236, 16.71624946594244],
        [82.30819702148443, 16.71624946594244],
        [82.30819702148443, 16.71597099304205],
        [82.30902862548822, 16.715972900390682],
        [82.30902862548822, 16.714305877685547],
        [82.30819702148443, 16.71430969238287],
        [82.30819702148443, 16.714031219482536],
        [82.30790710449236, 16.714031219482536],
        [82.30790710449236, 16.71375083923334],
        [82.30764007568388, 16.71375083923334],
        [82.30764007568388, 16.713470458984375],
        [82.30735778808611, 16.71347236633295],
        [82.30735778808611, 16.713195800781364],
        [82.3070831298831, 16.713195800781364],
        [82.3070831298831, 16.712917327880973],
        [82.30596923828136, 16.712921142578182],
        [82.30596923828136, 16.71264076232916],
        [82.30486297607439, 16.71264076232916],
        [82.30486297607439, 16.71236038208002],
        [82.30374908447266, 16.71236038208002],
        [82.30374908447266, 16.712080001831055],
        [82.30319213867193, 16.71208572387701],
        [82.30319213867193, 16.711805343628043],
        [82.30236816406267, 16.711820602417106],
        [82.30236816406267, 16.711530685424805],
        [82.30179595947283, 16.711530685424805],
        [82.30179595947283, 16.711250305175838],
        [82.30152893066412, 16.711250305175838],
        [82.30152893066412, 16.71096992492687],
        [82.30041503906278, 16.71097373962408],
        [82.30042266845732, 16.711250305175838],
        [82.30014038085943, 16.711250305175838],
        [82.30014038085943, 16.71236038208002],
        [82.30042266845732, 16.71236038208002],
        [82.30042266845732, 16.71264076232916],
        [82.30014038085943, 16.71264076232916],
        [82.30014038085943, 16.712921142578182],
        [82.30069732666016, 16.712917327880973],
        [82.30069732666016, 16.713195800781364],
        [82.30097198486357, 16.713195800781364],
        [82.30097198486357, 16.71347236633295],
        [82.30124664306658, 16.713470458984375],
        [82.30124664306658, 16.71375083923334],
        [82.30152893066412, 16.71375083923334],
        [82.30152893066412, 16.714031219482536],
        [82.30208587646507, 16.714031219482536],
        [82.30208587646507, 16.71430969238287],
        [82.30291748046886, 16.714305877685547],
        [82.30291748046886, 16.714582443237305],
        [82.30319213867193, 16.71457099914562],
        [82.30319213867193, 16.714860916137695],
        [82.30374908447266, 16.714860916137695],
        [82.30374908447266, 16.715139389038086],
        [82.30430603027361, 16.715139389038086],
        [82.30430603027361, 16.715419769287053],
        [82.30458068847662, 16.715417861938477],
        [82.30458068847662, 16.71569442749029],
        [82.30486297607439, 16.71569442749029],
        [82.30486297607439, 16.715972900390682],
        [82.30513763427757, 16.71597099304205],
        [82.30513763427757, 16.71624946594244],
        [82.3070831298831, 16.71624946594244],
        [82.3070831298831, 16.716529846191406],
        [82.30790710449236, 16.716529846191406],
      ],
    ],
    [
      [
        [82.31485748291038, 16.72597312927246],
        [82.31485748291038, 16.724861145019474],
        [82.3145828247072, 16.724861145019474],
        [82.3145828247072, 16.723470687866154],
        [82.31430816650396, 16.723474502563533],
        [82.31430816650396, 16.72263908386236],
        [82.31402587890642, 16.72264289855957],
        [82.31402587890642, 16.71957969665533],
        [82.31375122070341, 16.71958351135254],
        [82.31375122070341, 16.719860076904297],
        [82.31346893310547, 16.719860076904297],
        [82.31346893310547, 16.71958351135254],
        [82.3123626708985, 16.71958351135254],
        [82.3123626708985, 16.71930503845215],
        [82.31181335449241, 16.719310760498104],
        [82.31181335449241, 16.719028472900504],
        [82.31124877929693, 16.719028472900504],
        [82.31124877929693, 16.718748092651367],
        [82.30986022949219, 16.718748092651367],
        [82.30986022949219, 16.719028472900504],
        [82.30959320068371, 16.719028472900504],
        [82.30959320068371, 16.719310760498104],
        [82.30930328369146, 16.71930503845215],
        [82.30930328369146, 16.71958351135254],
        [82.30902862548822, 16.71958351135254],
        [82.30902862548822, 16.719860076904297],
        [82.30930328369146, 16.71985816955572],
        [82.30930328369146, 16.720422744751033],
        [82.30959320068371, 16.720422744751033],
        [82.30959320068371, 16.720701217651424],
        [82.30986022949219, 16.720701217651424],
        [82.30986022949219, 16.72125244140625],
        [82.31014251709013, 16.72125244140625],
        [82.31014251709013, 16.721532821655387],
        [82.31041717529297, 16.721532821655387],
        [82.31041717529297, 16.721809387206974],
        [82.31069183349638, 16.721805572509822],
        [82.31069183349638, 16.72208213806158],
        [82.31096649169939, 16.722080230713004],
        [82.31096649169939, 16.72264289855957],
        [82.31124877929693, 16.72264289855957],
        [82.31124877929693, 16.722919464111328],
        [82.3115310668947, 16.722917556762752],
        [82.3115310668947, 16.723192214965763],
        [82.31181335449241, 16.723190307617188],
        [82.31181335449241, 16.723760604858455],
        [82.31208038330072, 16.723760604858455],
        [82.31208038330072, 16.724029541015682],
        [82.3123626708985, 16.724029541015682],
        [82.3123626708985, 16.72430992126465],
        [82.31263732910168, 16.724306106567496],
        [82.31263732910168, 16.724584579467887],
        [82.31291961669928, 16.724580764770508],
        [82.31291961669928, 16.724861145019474],
        [82.3131866455081, 16.724861145019474],
        [82.3131866455081, 16.725139617919865],
        [82.31346893310547, 16.725139617919865],
        [82.31346893310547, 16.725419998169002],
        [82.31375122070341, 16.72541618347168],
        [82.31375122070341, 16.72569465637207],
        [82.31402587890642, 16.72569465637207],
        [82.31402587890642, 16.72597312927246],
        [82.31485748291038, 16.72597312927246],
      ],
    ],
    [
      [
        [82.33207702636747, 16.724861145019474],
        [82.33207702636747, 16.725139617919865],
        [82.33180236816412, 16.725139617919865],
        [82.33180236816412, 16.725419998169002],
        [82.33152770996105, 16.72541618347168],
        [82.33152770996105, 16.72597312927246],
        [82.33125305175787, 16.725971221923828],
        [82.33125305175787, 16.72624969482422],
        [82.33152770996105, 16.72624969482422],
        [82.33152770996105, 16.726530075073185],
        [82.33180236816412, 16.72652816772461],
        [82.33180236816412, 16.727083206176815],
        [82.33152770996105, 16.727079391479606],
        [82.33152770996105, 16.72764015197754],
        [82.33180236816412, 16.72764015197754],
        [82.33180236816412, 16.7282009124757],
        [82.33235168457031, 16.7282009124757],
        [82.33235168457031, 16.727920532226506],
        [82.33264160156278, 16.727920532226506],
        [82.33264160156278, 16.72764015197754],
        [82.3331909179688, 16.72764015197754],
        [82.3331909179688, 16.727079391479606],
        [82.33347320556658, 16.727083206176815],
        [82.33347320556658, 16.72652816772461],
        [82.3331909179688, 16.726530075073185],
        [82.3331909179688, 16.72624969482422],
        [82.33347320556658, 16.72624969482422],
        [82.33347320556658, 16.725971221923828],
        [82.33374786376976, 16.72597312927246],
        [82.33374786376976, 16.725139617919865],
        [82.3331909179688, 16.725139617919865],
        [82.3331909179688, 16.724861145019474],
        [82.33207702636747, 16.724861145019474],
      ],
    ],
    [
      [
        [82.33207702636747, 16.724861145019474],
        [82.33207702636747, 16.724580764770508],
        [82.33180236816412, 16.724584579467887],
        [82.33180236816412, 16.724306106567496],
        [82.33235168457031, 16.72430992126465],
        [82.33235168457031, 16.72264289855957],
        [82.33264160156278, 16.72264289855957],
        [82.33264160156278, 16.722080230713004],
        [82.3329162597658, 16.72208213806158],
        [82.3329162597658, 16.72125244140625],
        [82.3331909179688, 16.72125244140625],
        [82.3331909179688, 16.720701217651424],
        [82.33347320556658, 16.720701217651424],
        [82.33347320556658, 16.720422744751033],
        [82.33374786376976, 16.720422744751033],
        [82.33374786376976, 16.720138549804688],
        [82.33429718017584, 16.720138549804688],
        [82.33429718017584, 16.71985816955572],
        [82.33458709716791, 16.719860076904297],
        [82.33458709716791, 16.71958351135254],
        [82.33625030517607, 16.71958351135254],
        [82.33625030517607, 16.719860076904297],
        [82.33651733398455, 16.71985816955572],
        [82.33651733398455, 16.720138549804688],
        [82.33680725097662, 16.720138549804688],
        [82.33680725097662, 16.71985816955572],
        [82.33763885498041, 16.719860076904297],
        [82.33763885498041, 16.71958351135254],
        [82.33736419677757, 16.71958351135254],
        [82.33736419677757, 16.718748092651367],
        [82.33708190917986, 16.718748092651367],
        [82.33708190917986, 16.71791839599615],
        [82.33680725097662, 16.71791839599615],
        [82.33680725097662, 16.717081069946232],
        [82.33625030517607, 16.717082977295036],
        [82.33625030517607, 16.71680641174322],
        [82.33598327636719, 16.716810226440373],
        [82.33598327636719, 16.716529846191406],
        [82.33569335937528, 16.716529846191406],
        [82.33569335937528, 16.71624946594244],
        [82.33598327636719, 16.71624946594244],
        [82.33597564697266, 16.715972900390682],
        [82.33541870117188, 16.715972900390682],
        [82.33541870117188, 16.71569442749029],
        [82.33597564697266, 16.71569442749029],
        [82.33597564697266, 16.715417861938477],
        [82.33651733398455, 16.715419769287053],
        [82.33651733398455, 16.715139389038086],
        [82.33708190917986, 16.715139389038086],
        [82.33708190917986, 16.715419769287053],
        [82.33735656738287, 16.715419769287053],
        [82.33735656738287, 16.715139389038086],
        [82.33790588378912, 16.715139389038086],
        [82.33790588378912, 16.714860916137695],
        [82.33847808837896, 16.714860916137695],
        [82.33847045898438, 16.714582443237305],
        [82.33902740478516, 16.714582443237305],
        [82.33902740478516, 16.714027404785156],
        [82.33930206298857, 16.714031219482536],
        [82.33930206298857, 16.712921142578182],
        [82.33957672119158, 16.712921142578182],
        [82.33957672119158, 16.71236038208002],
        [82.33985900878912, 16.71236038208002],
        [82.33985900878912, 16.711820602417106],
        [82.34014129638689, 16.711820602417106],
        [82.34014129638689, 16.711530685424805],
        [82.34041595459007, 16.711530685424805],
        [82.34041595459007, 16.71096992492687],
        [82.34069824218744, 16.71097373962408],
        [82.34069824218744, 16.7104167938233],
        [82.34097290039068, 16.710401535034237],
        [82.34097290039068, 16.71013832092291],
        [82.34124755859386, 16.710140228271484],
        [82.34124755859386, 16.70957946777355],
        [82.34152984619146, 16.709581375122127],
        [82.34152984619146, 16.709028244018555],
        [82.34179687500028, 16.70903015136713],
        [82.34179687500028, 16.708469390869197],
        [82.34208679199219, 16.708469390869197],
        [82.34208679199219, 16.708194732666016],
        [82.34319305419939, 16.708194732666016],
        [82.34319305419939, 16.707916259765625],
        [82.34348297119146, 16.707920074462947],
        [82.34348297119146, 16.707641601562614],
        [82.34402465820318, 16.707641601562614],
        [82.34401702880865, 16.707359313964844],
        [82.3443069458009, 16.707359313964844],
        [82.3443069458009, 16.7059707641601],
        [82.34458160400396, 16.705972671508903],
        [82.34458160400396, 16.70513725280773],
        [82.34485626220732, 16.705139160156307],
        [82.34485626220732, 16.704307556152344],
        [82.34458160400396, 16.704307556152344],
        [82.34458160400396, 16.703750610351562],
        [82.34319305419939, 16.703750610351562],
        [82.34319305419939, 16.704029083251953],
        [82.34263610839861, 16.704029083251953],
        [82.34263610839861, 16.704307556152344],
        [82.34208679199219, 16.704305648803768],
        [82.34208679199219, 16.70458412170416],
        [82.34180450439482, 16.70458412170416],
        [82.34180450439482, 16.704305648803768],
        [82.34124755859386, 16.704307556152344],
        [82.34124755859386, 16.704029083251953],
        [82.34097290039068, 16.704029083251953],
        [82.34097290039068, 16.703750610351562],
        [82.34041595459007, 16.703750610351562],
        [82.34041595459007, 16.70318984985363],
        [82.34014129638689, 16.703193664550838],
        [82.34014129638689, 16.702917098999023],
        [82.33985900878912, 16.702920913696403],
        [82.33985900878912, 16.7026309967041],
        [82.33958435058611, 16.702638626098633],
        [82.33958435058611, 16.702362060546818],
        [82.33930206298857, 16.702360153198242],
        [82.33930206298857, 16.702079772949276],
        [82.33902740478516, 16.702083587646484],
        [82.33902740478516, 16.701805114746094],
        [82.33875274658232, 16.70181083679205],
        [82.33875274658232, 16.701530456543026],
        [82.33819580078136, 16.701530456543026],
        [82.33819580078136, 16.70125007629389],
        [82.33790588378912, 16.70125007629389],
        [82.33790588378912, 16.700969696044922],
        [82.33763885498041, 16.700971603393498],
        [82.33763885498041, 16.700696945190487],
        [82.33735656738287, 16.700700759887695],
        [82.33735656738287, 16.700420379638672],
        [82.33651733398455, 16.700420379638672],
        [82.33651733398455, 16.69985961914074],
        [82.33625030517607, 16.699861526489315],
        [82.33625030517607, 16.699306488037166],
        [82.33598327636719, 16.69930076599121],
        [82.33597564697266, 16.699028015136776],
        [82.33569335937528, 16.69902992248535],
        [82.33569335937528, 16.698749542236385],
        [82.3351364135745, 16.698749542236385],
        [82.3351364135745, 16.698190689087028],
        [82.33486175537115, 16.698194503784237],
        [82.33486175537115, 16.69763946533203],
        [82.33458709716791, 16.69763946533203],
        [82.33458709716791, 16.696529388427678],
        [82.33429718017584, 16.696529388427678],
        [82.33429718017584, 16.695421218872127],
        [82.33458709716791, 16.695421218872127],
        [82.33458709716791, 16.694860458373967],
        [82.33486175537115, 16.694860458373967],
        [82.33486175537115, 16.693752288818416],
        [82.3351364135745, 16.693752288818416],
        [82.3351364135745, 16.693199157714787],
        [82.33541870117188, 16.693199157714787],
        [82.33541870117188, 16.692638397216854],
        [82.33569335937528, 16.692638397216854],
        [82.33569335937528, 16.691801071167106],
        [82.33597564697266, 16.691804885864315],
        [82.33597564697266, 16.6915283203125],
        [82.33625030517607, 16.6915283203125],
        [82.33625030517607, 16.69124984741211],
        [82.33708190917986, 16.69124984741211],
        [82.33708190917986, 16.6915283203125],
        [82.33763885498041, 16.6915283203125],
        [82.33763885498041, 16.691804885864315],
        [82.33791351318365, 16.691804885864315],
        [82.33791351318365, 16.692083358764705],
        [82.33847045898438, 16.692083358764705],
        [82.33847045898438, 16.69236183166504],
        [82.33875274658232, 16.692359924316463],
        [82.33875274658232, 16.692638397216854],
        [82.33902740478516, 16.692638397216854],
        [82.33902740478516, 16.692920684814396],
        [82.33930206298857, 16.692920684814396],
        [82.33930206298857, 16.693199157714787],
        [82.33985900878912, 16.693195343017635],
        [82.33985900878912, 16.693471908569393],
        [82.34014129638689, 16.69346809387207],
        [82.34014129638689, 16.694032669067383],
        [82.34041595459007, 16.694032669067383],
        [82.34041595459007, 16.694311141967773],
        [82.34097290039068, 16.69430541992199],
        [82.34097290039068, 16.69458389282238],
        [82.34124755859386, 16.694580078125],
        [82.34124755859386, 16.694860458373967],
        [82.34152221679688, 16.694860458373967],
        [82.34152221679688, 16.695421218872127],
        [82.34208679199219, 16.69541740417492],
        [82.34208679199219, 16.69569206237793],
        [82.34263610839861, 16.695690155029354],
        [82.34263610839861, 16.69625282287609],
        [82.34291839599615, 16.69625282287609],
        [82.34291839599615, 16.697084426879883],
        [82.34319305419939, 16.697080612182674],
        [82.34319305419939, 16.69763946533203],
        [82.34348297119146, 16.69763946533203],
        [82.34348297119146, 16.697919845580998],
        [82.34375, 16.697916030883846],
        [82.34375, 16.698474884033203],
        [82.3443069458009, 16.698471069335994],
        [82.3443069458009, 16.698749542236385],
        [82.34513854980469, 16.698749542236385],
        [82.34513854980469, 16.69902992248535],
        [82.3454132080081, 16.69902992248535],
        [82.3454132080081, 16.699310302734318],
        [82.34597015380865, 16.699310302734318],
        [82.34597015380865, 16.699590682983512],
        [82.34625244140642, 16.69958305358898],
        [82.34625244140642, 16.699861526489315],
        [82.3465270996096, 16.69985961914074],
        [82.3465270996096, 16.700139999389705],
        [82.34680175781244, 16.700139999389705],
        [82.34680175781244, 16.700420379638672],
        [82.34707641601568, 16.700420379638672],
        [82.34707641601568, 16.700700759887695],
        [82.347640991211, 16.700696945190487],
        [82.347640991211, 16.700971603393498],
        [82.34819793701172, 16.700969696044922],
        [82.34819793701172, 16.70125007629389],
        [82.34847259521513, 16.70125007629389],
        [82.34847259521513, 16.70181083679205],
        [82.34874725341814, 16.70181083679205],
        [82.34874725341814, 16.702920913696403],
        [82.34902954101591, 16.702917098999023],
        [82.34902954101591, 16.703472137451172],
        [82.34929656982439, 16.703470230102596],
        [82.34929656982439, 16.704029083251953],
        [82.34958648681646, 16.704029083251953],
        [82.34958648681646, 16.704307556152344],
        [82.35041809082026, 16.704305648803768],
        [82.35041809082026, 16.70458412170416],
        [82.35097503662121, 16.70458412170416],
        [82.35097503662121, 16.704860687255916],
        [82.35124969482422, 16.704860687255916],
        [82.35124969482422, 16.705139160156307],
        [82.3515167236331, 16.705139160156307],
        [82.3515167236331, 16.705417633056697],
        [82.35208129882841, 16.705417633056697],
        [82.35208129882841, 16.705694198608512],
        [82.35236358642595, 16.705694198608512],
        [82.35236358642595, 16.705972671508903],
        [82.35263824462896, 16.7059707641601],
        [82.35263824462896, 16.706811904907227],
        [82.3529129028322, 16.706811904907227],
        [82.3529129028322, 16.707359313964844],
        [82.35263824462896, 16.707359313964844],
        [82.35263824462896, 16.708469390869197],
        [82.3529129028322, 16.708469390869197],
        [82.3529129028322, 16.709863662719727],
        [82.35235595703142, 16.709859848022518],
        [82.35235595703142, 16.710140228271484],
        [82.3506927490235, 16.710140228271484],
        [82.3506927490235, 16.709859848022518],
        [82.34792327880871, 16.709859848022518],
        [82.34792327880871, 16.71042060852045],
        [82.347640991211, 16.7104167938233],
        [82.347640991211, 16.71097373962408],
        [82.34791564941435, 16.71097373962408],
        [82.34792327880871, 16.71236038208002],
        [82.34707641601568, 16.71236038208002],
        [82.34707641601568, 16.712080001831055],
        [82.34625244140642, 16.71208572387701],
        [82.34625244140642, 16.711805343628043],
        [82.34597015380865, 16.711805343628043],
        [82.34597015380865, 16.71208572387701],
        [82.34458160400396, 16.71208572387701],
        [82.34458160400396, 16.711805343628043],
        [82.3443069458009, 16.711820602417106],
        [82.3443069458009, 16.711530685424805],
        [82.34401702880865, 16.711530685424805],
        [82.34401702880865, 16.711250305175838],
        [82.3434753417971, 16.711250305175838],
        [82.3434753417971, 16.71069526672369],
        [82.34319305419939, 16.710700988769645],
        [82.34319305419939, 16.71042060852045],
        [82.34291839599615, 16.71042060852045],
        [82.34291839599615, 16.710700988769645],
        [82.34263610839861, 16.71069526672369],
        [82.34263610839861, 16.71097373962408],
        [82.3423614501956, 16.71096992492687],
        [82.3423614501956, 16.711820602417106],
        [82.34208679199219, 16.711820602417106],
        [82.34208679199219, 16.71236038208002],
        [82.34180450439482, 16.71236038208002],
        [82.34180450439482, 16.713195800781364],
        [82.34159088134794, 16.71319007873541],
        [82.34152221679688, 16.714031219482536],
        [82.34124755859386, 16.714027404785156],
        [82.34124755859386, 16.714582443237305],
        [82.34097290039068, 16.71457099914562],
        [82.34097290039068, 16.715139389038086],
        [82.34069824218744, 16.715139389038086],
        [82.34069061279291, 16.71569442749029],
        [82.34014129638689, 16.71569442749029],
        [82.34014129638689, 16.715972900390682],
        [82.34041595459007, 16.71597099304205],
        [82.34041595459007, 16.71624946594244],
        [82.34014129638689, 16.71624946594244],
        [82.34014129638689, 16.717082977295036],
        [82.33958435058611, 16.717082977295036],
        [82.33958435058611, 16.71736145019537],
        [82.33985900878912, 16.717359542846793],
        [82.33985900878912, 16.71820068359375],
        [82.34041595459007, 16.718194961547965],
        [82.34041595459007, 16.718471527099553],
        [82.34069824218744, 16.718471527099553],
        [82.34069824218744, 16.718194961547965],
        [82.34123229980497, 16.718179702758903],
        [82.34124755859386, 16.717916488647575],
        [82.34179687500028, 16.71791839599615],
        [82.34179687500028, 16.717630386352653],
        [82.3434753417971, 16.71763992309576],
        [82.3434753417971, 16.71736145019537],
        [82.34402465820318, 16.71736145019537],
        [82.34402465820318, 16.717082977295036],
        [82.34597015380865, 16.717082977295036],
        [82.34597015380865, 16.71652793884283],
        [82.34680175781244, 16.716529846191406],
        [82.3468093872072, 16.71597099304205],
        [82.3473587036134, 16.715972900390682],
        [82.3473587036134, 16.71569442749029],
        [82.34847259521513, 16.71569442749029],
        [82.34847259521513, 16.715417861938477],
        [82.34902954101591, 16.715419769287053],
        [82.34902954101591, 16.715139389038086],
        [82.351806640625, 16.715139389038086],
        [82.351806640625, 16.715419769287053],
        [82.35208129882841, 16.715417861938477],
        [82.35208129882841, 16.71569442749029],
        [82.35236358642595, 16.71569442749029],
        [82.35236358642595, 16.715139389038086],
        [82.35263824462896, 16.715139389038086],
        [82.35263824462896, 16.71457099914562],
        [82.35346984863276, 16.714582443237305],
        [82.35346984863276, 16.714305877685547],
        [82.3543090820313, 16.71430969238287],
        [82.3543090820313, 16.714031219482536],
        [82.35457611084013, 16.714031219482536],
        [82.35457611084013, 16.71375083923334],
        [82.3548583984375, 16.71375083923334],
        [82.3548583984375, 16.712921142578182],
        [82.35514068603544, 16.712921142578182],
        [82.35514068603544, 16.71264076232916],
        [82.35542297363298, 16.71264076232916],
        [82.35542297363298, 16.71236038208002],
        [82.35819244384794, 16.71236038208002],
        [82.35819244384794, 16.712080001831055],
        [82.3587493896485, 16.712080001831055],
        [82.3587493896485, 16.71236038208002],
        [82.3590164184572, 16.71236038208002],
        [82.3590164184572, 16.712921142578182],
        [82.35930633544945, 16.712917327880973],
        [82.35930633544945, 16.713195800781364],
        [82.35986328125006, 16.713195800781364],
        [82.35986328125006, 16.71347236633295],
        [82.36042022705084, 16.713470458984375],
        [82.36042022705084, 16.71375083923334],
        [82.36068725585966, 16.71375083923334],
        [82.36068725585966, 16.71430969238287],
        [82.36096954345703, 16.714305877685547],
        [82.36096954345703, 16.714582443237305],
        [82.36125183105497, 16.71457099914562],
        [82.36125183105497, 16.714860916137695],
        [82.36152648925798, 16.714860916137695],
        [82.36152648925798, 16.715139389038086],
        [82.36207580566423, 16.715139389038086],
        [82.36207580566423, 16.715419769287053],
        [82.36264038085955, 16.715417861938477],
        [82.36264038085955, 16.71569442749029],
        [82.36291503906256, 16.71569442749029],
        [82.36291503906256, 16.71624946594244],
        [82.3631973266601, 16.71624946594244],
        [82.3631973266601, 16.716529846191406],
        [82.36347198486334, 16.71652793884283],
        [82.36347198486334, 16.717082977295036],
        [82.36374664306669, 16.717082977295036],
        [82.36374664306669, 16.71736145019537],
        [82.36430358886747, 16.71736145019537],
        [82.36430358886747, 16.71763992309576],
        [82.36458587646484, 16.717630386352653],
        [82.36458587646484, 16.71791839599615],
        [82.36486053466825, 16.71791839599615],
        [82.36486053466825, 16.719028472900504],
        [82.36458587646484, 16.719028472900504],
        [82.36458587646484, 16.720701217651424],
        [82.36486053466825, 16.720701217651424],
        [82.36486053466825, 16.721532821655387],
        [82.36458587646484, 16.721532821655387],
        [82.36458587646484, 16.722919464111328],
        [82.36430358886747, 16.722917556762752],
        [82.36430358886747, 16.723474502563533],
        [82.36402893066406, 16.723470687866154],
        [82.36402893066406, 16.723760604858455],
        [82.36374664306669, 16.723760604858455],
        [82.36374664306669, 16.724861145019474],
        [82.36347198486334, 16.724861145019474],
        [82.36347198486334, 16.725419998169002],
        [82.3631973266601, 16.72541618347168],
        [82.3631973266601, 16.72569465637207],
        [82.36208343505876, 16.72569465637207],
        [82.36208343505876, 16.72541618347168],
        [82.35986328125006, 16.72541618347168],
        [82.35986328125006, 16.72597312927246],
        [82.35958099365229, 16.725971221923828],
        [82.35958099365229, 16.72624969482422],
        [82.3590164184572, 16.72624969482422],
        [82.3590164184572, 16.726530075073185],
        [82.3587493896485, 16.72652816772461],
        [82.3587493896485, 16.726806640625],
        [82.35819244384794, 16.726806640625],
        [82.35819244384794, 16.727083206176815],
        [82.35791778564453, 16.727079391479606],
        [82.35791778564453, 16.72764015197754],
        [82.35846710205095, 16.72764015197754],
        [82.35846710205095, 16.727920532226506],
        [82.3590164184572, 16.727920532226506],
        [82.3590164184572, 16.7282009124757],
        [82.35930633544945, 16.728195190429744],
        [82.35930633544945, 16.728471755981502],
        [82.36042022705084, 16.728471755981502],
        [82.36042022705084, 16.728195190429744],
        [82.36068725585966, 16.7282009124757],
        [82.36068725585966, 16.72764015197754],
        [82.36125183105497, 16.72764015197754],
        [82.36125183105497, 16.727359771728572],
        [82.36152648925798, 16.72736167907715],
        [82.36152648925798, 16.727083206176815],
        [82.36458587646484, 16.727083206176815],
        [82.36458587646484, 16.72624969482422],
        [82.36486053466825, 16.72624969482422],
        [82.36486053466825, 16.725971221923828],
        [82.3654174804688, 16.72597312927246],
        [82.3654174804688, 16.72569465637207],
        [82.36597442626976, 16.72569465637207],
        [82.36597442626976, 16.725139617919865],
        [82.36653137207037, 16.725139617919865],
        [82.36653137207037, 16.724029541015682],
        [82.36708068847656, 16.724029541015682],
        [82.36708068847656, 16.72430992126465],
        [82.36763763427734, 16.72430992126465],
        [82.36763763427734, 16.724029541015682],
        [82.36818695068376, 16.724029541015682],
        [82.36818695068376, 16.722919464111328],
        [82.36791992187528, 16.722919464111328],
        [82.36791992187528, 16.722362518310604],
        [82.36763763427734, 16.722362518310604],
        [82.36763763427734, 16.721809387206974],
        [82.3673629760745, 16.721809387206974],
        [82.3673629760745, 16.72125244140625],
        [82.36708068847656, 16.72125244140625],
        [82.36708068847656, 16.720701217651424],
        [82.3673629760745, 16.720701217651424],
        [82.3673629760745, 16.720422744751033],
        [82.36763763427734, 16.720422744751033],
        [82.36763763427734, 16.71985816955572],
        [82.3673629760745, 16.719860076904297],
        [82.3673629760745, 16.71930503845215],
        [82.36708068847656, 16.719310760498104],
        [82.36708068847656, 16.718469619750977],
        [82.36680603027355, 16.718471527099553],
        [82.36680603027355, 16.718194961547965],
        [82.3662490844726, 16.71820068359375],
        [82.3662490844726, 16.717630386352653],
        [82.36597442626976, 16.71763992309576],
        [82.36597442626976, 16.717082977295036],
        [82.36569213867205, 16.717082977295036],
        [82.36569213867205, 16.71652793884283],
        [82.3654174804688, 16.716529846191406],
        [82.3654174804688, 16.71597099304205],
        [82.36513519287126, 16.715972900390682],
        [82.36513519287126, 16.715417861938477],
        [82.36486053466825, 16.715419769287053],
        [82.36486053466825, 16.714860916137695],
        [82.36458587646484, 16.714860916137695],
        [82.36458587646484, 16.71430969238287],
        [82.36429595947294, 16.71430969238287],
        [82.36429595947294, 16.714031219482536],
        [82.36402893066406, 16.714031219482536],
        [82.36402893066406, 16.71375083923334],
        [82.36374664306669, 16.71375083923334],
        [82.36374664306669, 16.71319007873541],
        [82.36347198486334, 16.713195800781364],
        [82.36347198486334, 16.712917327880973],
        [82.3631973266601, 16.712921142578182],
        [82.3631973266601, 16.71264076232916],
        [82.36292266845726, 16.71264076232916],
        [82.36292266845726, 16.712080001831055],
        [82.36264038085955, 16.71208572387701],
        [82.36264038085955, 16.711805343628043],
        [82.36235809326195, 16.711820602417106],
        [82.36235809326195, 16.711530685424805],
        [82.36207580566423, 16.711530685424805],
        [82.36207580566423, 16.71096992492687],
        [82.36180877685575, 16.71097373962408],
        [82.36180877685575, 16.71069526672369],
        [82.36152648925798, 16.710700988769645],
        [82.36152648925798, 16.71042060852045],
        [82.36125183105497, 16.71042060852045],
        [82.36125183105497, 16.710140228271484],
        [82.36096954345703, 16.710140228271484],
        [82.36096954345703, 16.709859848022518],
        [82.36069488525419, 16.709863662719727],
        [82.36069488525419, 16.709581375122127],
        [82.36042022705084, 16.709581375122127],
        [82.36042022705084, 16.70930480957037],
        [82.36013793945324, 16.70930480957037],
        [82.36013793945324, 16.709028244018555],
        [82.35986328125006, 16.70903015136713],
        [82.35986328125006, 16.708469390869197],
        [82.35958099365229, 16.708469390869197],
        [82.35958099365229, 16.708194732666016],
        [82.35930633544945, 16.708194732666016],
        [82.35930633544945, 16.707641601562614],
        [82.3590164184572, 16.707641601562614],
        [82.3590164184572, 16.707359313964844],
        [82.3587493896485, 16.707359313964844],
        [82.3587493896485, 16.706811904907227],
        [82.35846710205095, 16.706811904907227],
        [82.35846710205095, 16.70653152465826],
        [82.35819244384794, 16.70653152465826],
        [82.35819244384794, 16.7059707641601],
        [82.35791778564453, 16.705972671508903],
        [82.35791778564453, 16.705694198608512],
        [82.35763549804716, 16.705694198608512],
        [82.35763549804716, 16.70513725280773],
        [82.3567962646485, 16.705139160156307],
        [82.3567962646485, 16.704307556152344],
        [82.35652160644526, 16.704307556152344],
        [82.35652160644526, 16.704029083251953],
        [82.35624694824241, 16.704029083251953],
        [82.35624694824241, 16.703750610351562],
        [82.35597229003923, 16.703750610351562],
        [82.35597229003923, 16.703470230102596],
        [82.35514068603544, 16.703472137451172],
        [82.35514068603544, 16.703193664550838],
        [82.3543090820313, 16.703193664550838],
        [82.3543090820313, 16.702362060546818],
        [82.35402679443371, 16.702360153198242],
        [82.35402679443371, 16.701530456543026],
        [82.35375213623053, 16.701530456543026],
        [82.35375213623053, 16.70125007629389],
        [82.3534622192384, 16.70125007629389],
        [82.3534622192384, 16.700969696044922],
        [82.35319519042991, 16.700971603393498],
        [82.35319519042991, 16.700696945190487],
        [82.3529129028322, 16.700700759887695],
        [82.3529129028322, 16.700420379638672],
        [82.35263824462896, 16.700420379638672],
        [82.35263824462896, 16.69985961914074],
        [82.35236358642595, 16.699861526489315],
        [82.35236358642595, 16.69958305358898],
        [82.35208129882841, 16.699590682983512],
        [82.35208129882841, 16.699310302734318],
        [82.351806640625, 16.69930076599121],
        [82.351806640625, 16.698749542236385],
        [82.3515167236331, 16.698749542236385],
        [82.3515167236331, 16.698471069335994],
        [82.35124969482422, 16.698474884033203],
        [82.35124969482422, 16.698194503784237],
        [82.35097503662121, 16.698194503784237],
        [82.35097503662121, 16.697916030883846],
        [82.3506927490235, 16.697919845580998],
        [82.3506927490235, 16.69763946533203],
        [82.35041809082026, 16.69763946533203],
        [82.35041809082026, 16.697362899780273],
        [82.35013580322271, 16.697362899780273],
        [82.35013580322271, 16.697080612182674],
        [82.3498611450197, 16.697084426879883],
        [82.3498611450197, 16.696805953979492],
        [82.34958648681646, 16.696821212768555],
        [82.34958648681646, 16.696529388427678],
        [82.34929656982439, 16.696529388427678],
        [82.34929656982439, 16.69625282287609],
        [82.34902954101591, 16.69625282287609],
        [82.34902954101591, 16.69597053527832],
        [82.34874725341814, 16.69597053527832],
        [82.34874725341814, 16.69541740417492],
        [82.34847259521513, 16.695421218872127],
        [82.34847259521513, 16.695142745971737],
        [82.34819793701172, 16.695142745971737],
        [82.34819793701172, 16.694580078125],
        [82.34791564941435, 16.69458389282238],
        [82.34791564941435, 16.69430541992199],
        [82.347640991211, 16.694311141967773],
        [82.347640991211, 16.694032669067383],
        [82.3473587036134, 16.694032669067383],
        [82.3473587036134, 16.693752288818416],
        [82.34707641601568, 16.693752288818416],
        [82.34707641601568, 16.69346809387207],
        [82.3468093872072, 16.693471908569393],
        [82.34680175781244, 16.693195343017635],
        [82.34625244140642, 16.693199157714787],
        [82.34625244140642, 16.692920684814396],
        [82.34597015380865, 16.692920684814396],
        [82.34597015380865, 16.692638397216854],
        [82.345703125, 16.692638397216854],
        [82.345703125, 16.692359924316463],
        [82.3454132080081, 16.69236183166504],
        [82.3454132080081, 16.692083358764705],
        [82.34513854980469, 16.692083358764705],
        [82.34513854980469, 16.691804885864315],
        [82.34486389160185, 16.691804885864315],
        [82.34486389160185, 16.69124984741211],
        [82.34458160400396, 16.69124984741211],
        [82.34458160400396, 16.690690994262752],
        [82.3443069458009, 16.69069480895996],
        [82.3443069458009, 16.690139770507756],
        [82.34401702880865, 16.690139770507756],
        [82.34401702880865, 16.6895809173584],
        [82.34291839599615, 16.689582824706974],
        [82.34291839599615, 16.689306259155387],
        [82.34263610839861, 16.689308166503963],
        [82.34263610839861, 16.689029693603572],
        [82.3423614501956, 16.689029693603572],
        [82.3423614501956, 16.688470840454045],
        [82.34208679199219, 16.688472747802848],
        [82.34208679199219, 16.688194274902457],
        [82.34180450439482, 16.688194274902457],
        [82.34180450439482, 16.687917709350643],
        [82.34152221679688, 16.68791961669922],
        [82.34152221679688, 16.687650680541992],
        [82.34124755859386, 16.687650680541992],
        [82.34124755859386, 16.68736076354986],
        [82.34097290039068, 16.68736076354986],
        [82.34097290039068, 16.687080383300895],
        [82.34069824218744, 16.687084197998104],
        [82.34069824218744, 16.686527252197322],
        [82.34041595459007, 16.686531066894474],
        [82.34041595459007, 16.686250686645508],
        [82.34014129638689, 16.686250686645508],
        [82.34014129638689, 16.68597030639654],
        [82.33985900878912, 16.685972213745117],
        [82.33985900878912, 16.68541717529297],
        [82.33930206298857, 16.685420989990348],
        [82.33930206298857, 16.684860229492188],
        [82.33902740478516, 16.684860229492188],
        [82.33902740478516, 16.684589385986328],
        [82.33875274658232, 16.684589385986328],
        [82.33875274658232, 16.684310913085994],
        [82.33847808837896, 16.684310913085994],
        [82.33847808837896, 16.68403053283697],
        [82.33819580078136, 16.68403053283697],
        [82.33819580078136, 16.683469772338867],
        [82.33791351318365, 16.683473587036246],
        [82.33791351318365, 16.683195114135856],
        [82.33763885498041, 16.68320083618164],
        [82.33763885498041, 16.68264007568365],
        [82.33735656738287, 16.68264007568365],
        [82.33735656738287, 16.682359695434684],
        [82.33708190917986, 16.682363510131893],
        [82.33708190917986, 16.681804656982536],
        [82.33680725097662, 16.681804656982536],
        [82.33680725097662, 16.68152809143072],
        [82.33651733398455, 16.68153190612793],
        [82.33651733398455, 16.68124961853033],
        [82.33625030517607, 16.68124961853033],
        [82.33625030517607, 16.680969238281364],
        [82.33569335937528, 16.68097114562994],
        [82.33569335937528, 16.680694580078182],
        [82.33541870117188, 16.680694580078182],
        [82.33541870117188, 16.68041610717779],
        [82.3351364135745, 16.680421829223746],
        [82.3351364135745, 16.680141448974553],
        [82.33486175537115, 16.680141448974553],
        [82.33486175537115, 16.67985916137701],
        [82.33429718017584, 16.67985916137701],
        [82.33429718017584, 16.67958068847662],
        [82.33403015136736, 16.679582595825195],
        [82.33403015136736, 16.67874717712408],
        [82.33374786376976, 16.678749084472656],
        [82.33374786376976, 16.67846107482916],
        [82.33347320556658, 16.67847251892084],
        [82.33347320556658, 16.677917480468864],
        [82.3331909179688, 16.677917480468864],
        [82.3331909179688, 16.67764091491705],
        [82.3329162597658, 16.67764091491705],
        [82.3329162597658, 16.677360534667912],
        [82.33264160156278, 16.677360534667912],
        [82.33264160156278, 16.67681121826172],
        [82.33235168457031, 16.67681121826172],
        [82.33235168457031, 16.676530838012695],
        [82.33207702636747, 16.676530838012695],
        [82.33207702636747, 16.67625045776373],
        [82.33180236816412, 16.67625045776373],
        [82.33180236816412, 16.675689697265625],
        [82.33152770996105, 16.675693511963004],
        [82.33152770996105, 16.67541694641119],
        [82.33125305175787, 16.67542076110834],
        [82.33125305175787, 16.675140380859375],
        [82.3309707641601, 16.675140380859375],
        [82.3309707641601, 16.674579620361442],
        [82.33069610595726, 16.67458343505865],
        [82.33069610595726, 16.674306869506836],
        [82.33040618896501, 16.674310684204215],
        [82.33040618896501, 16.673749923706055],
        [82.3301391601563, 16.673749923706055],
        [82.3301391601563, 16.673191070556697],
        [82.3298645019533, 16.673196792602653],
        [82.3298645019533, 16.672637939453125],
        [82.32958221435575, 16.672649383545036],
        [82.32958221435575, 16.672080993652344],
        [82.32930755615234, 16.672084808349723],
        [82.32930755615234, 16.671806335449332],
        [82.3290328979495, 16.671810150146484],
        [82.3290328979495, 16.67124938964855],
        [82.32875061035162, 16.67124938964855],
        [82.32875061035162, 16.670690536499023],
        [82.32847595214855, 16.670694351196403],
        [82.32847595214855, 16.670139312744197],
        [82.3281860351563, 16.670141220092773],
        [82.3281860351563, 16.66958045959484],
        [82.3279190063476, 16.66958045959484],
        [82.3279190063476, 16.66930580139166],
        [82.32763671875023, 16.66930961608881],
        [82.32763671875023, 16.66903114318842],
        [82.32736206054705, 16.66903114318842],
        [82.32736206054705, 16.668752670288086],
        [82.32680511474626, 16.668752670288086],
        [82.32680511474626, 16.667917251586914],
        [82.32653808593778, 16.667921066284293],
        [82.32653808593778, 16.667642593383903],
        [82.32624816894531, 16.667642593383903],
        [82.32624816894531, 16.667079925537166],
        [82.32597351074247, 16.667083740234375],
        [82.32597351074247, 16.666530609130916],
        [82.32569122314453, 16.666530609130916],
        [82.32569122314453, 16.66597175598139],
        [82.32541656494152, 16.66597175598139],
        [82.32541656494152, 16.66402626037609],
        [82.32514190673834, 16.664028167724666],
        [82.32514190673834, 16.66345977783203],
        [82.32485961914057, 16.663473129272518],
        [82.32485961914057, 16.663194656372127],
        [82.32458496093773, 16.663194656372127],
        [82.32458496093773, 16.66236114501953],
        [82.32430267334001, 16.66236114501953],
        [82.32430267334001, 16.661809921264705],
        [82.32402801513678, 16.661809921264705],
        [82.32402801513678, 16.661251068115178],
        [82.32375335693376, 16.661251068115178],
        [82.32375335693376, 16.660419464111385],
        [82.32319641113281, 16.660419464111385],
        [82.32319641113281, 16.65958023071289],
        [82.32291412353544, 16.659585952758846],
        [82.32291412353544, 16.658195495605526],
        [82.32236480712902, 16.658195495605526],
        [82.32236480712902, 16.657638549804744],
        [82.3220825195313, 16.65764045715332],
        [82.3220825195313, 16.657360076904354],
        [82.32236480712902, 16.657363891601562],
        [82.32236480712902, 16.655973434448242],
        [82.3220825195313, 16.655973434448242],
        [82.3220825195313, 16.65569496154785],
        [82.32180786132807, 16.655700683593807],
        [82.32180786132807, 16.655420303344783],
        [82.32153320312523, 16.655420303344783],
        [82.32153320312523, 16.65514183044445],
        [82.32125091552751, 16.65514183044445],
        [82.32125091552751, 16.654579162597713],
        [82.32096862792991, 16.65458106994629],
        [82.32096862792991, 16.654027938842887],
        [82.3206863403322, 16.654031753540096],
        [82.3206863403322, 16.65346908569336],
        [82.32041931152372, 16.65347290039074],
        [82.32041931152372, 16.653194427490348],
        [82.32069396972673, 16.653194427490348],
        [82.32069396972673, 16.652639389038143],
        [82.31930541992216, 16.652639389038143],
        [82.31930541992216, 16.652360916137752],
        [82.31874847412121, 16.652360916137752],
        [82.31874847412121, 16.6512508392334],
        [82.3184661865235, 16.6512508392334],
        [82.3184661865235, 16.650970458984432],
        [82.3190307617188, 16.650972366333008],
        [82.3190307617188, 16.65041732788086],
        [82.31931304931652, 16.65042114257824],
        [82.31931304931652, 16.650140762329045],
        [82.319580078125, 16.650140762329045],
        [82.319580078125, 16.64958000183111],
        [82.31930541992216, 16.64958381652832],
        [82.31930541992216, 16.647916793823356],
        [82.3190307617188, 16.647920608520508],
        [82.3190307617188, 16.64764022827154],
        [82.31931304931652, 16.64764022827154],
        [82.31931304931652, 16.647470474243107],
        [82.31930541992216, 16.64652824401861],
        [82.319580078125, 16.646530151367188],
        [82.319580078125, 16.645969390869254],
        [82.31930541992216, 16.64597129821783],
        [82.31930541992216, 16.644861221313477],
        [82.319580078125, 16.644861221313477],
        [82.319580078125, 16.643472671508732],
        [82.31930541992216, 16.643474578857536],
        [82.31930541992216, 16.643192291259766],
        [82.3190307617188, 16.643192291259766],
        [82.3190307617188, 16.642915725708008],
        [82.31874847412121, 16.64291954040533],
        [82.31874847412121, 16.64264106750494],
        [82.31791687011741, 16.64264106750494],
        [82.31791687011741, 16.642080307006836],
        [82.31819152832026, 16.642084121704215],
        [82.31819152832026, 16.641805648803825],
        [82.3184661865235, 16.641809463500977],
        [82.3184661865235, 16.640970230102653],
        [82.31874847412121, 16.640970230102653],
        [82.31874847412121, 16.64041709899908],
        [82.3184661865235, 16.640422821045036],
        [82.3184661865235, 16.63874816894537],
        [82.31819152832026, 16.63874816894537],
        [82.31819152832026, 16.63792037963873],
        [82.31791687011741, 16.63792037963873],
        [82.31791687011741, 16.637638092041016],
        [82.31819152832026, 16.637638092041016],
        [82.31819152832026, 16.637359619140625],
        [82.31791687011741, 16.6373615264892],
        [82.31791687011741, 16.634304046630973],
        [82.31764221191423, 16.6343097686767],
        [82.31764221191423, 16.634029388427734],
        [82.31791687011741, 16.634029388427734],
        [82.31791687011741, 16.632921218872184],
        [82.31764221191423, 16.632921218872184],
        [82.31764221191423, 16.63181114196783],
        [82.31735229492193, 16.63181114196783],
        [82.31735229492193, 16.631429672241268],
        [82.31735992431646, 16.62986373901373],
        [82.31764221191423, 16.62986373901373],
        [82.31764221191423, 16.62958145141596],
        [82.31791687011741, 16.62958145141596],
        [82.31791687011741, 16.62930488586437],
        [82.31847381591803, 16.62930488586437],
        [82.31847381591803, 16.628751754760742],
        [82.31735229492193, 16.628751754760742],
        [82.31735229492193, 16.629030227661133],
        [82.31708526611345, 16.629028320312557],
        [82.31708526611345, 16.62958145141596],
        [82.31680297851591, 16.629579544067383],
        [82.31680297851591, 16.630140304565487],
        [82.3165283203125, 16.630140304565487],
        [82.3165283203125, 16.630701065063477],
        [82.31624603271513, 16.630701065063477],
        [82.31624603271513, 16.63125038146967],
        [82.31597137451178, 16.63125038146967],
        [82.31597137451178, 16.632085800171012],
        [82.31569671630871, 16.632080078125057],
        [82.31569671630871, 16.632921218872184],
        [82.315414428711, 16.632917404174805],
        [82.315414428711, 16.633291244506836],
        [82.315414428711, 16.635972976684684],
        [82.31513977050776, 16.63597106933588],
        [82.31513977050776, 16.636249542236442],
        [82.31485748291038, 16.636249542236442],
        [82.31485748291038, 16.636800765991268],
        [82.3145828247072, 16.636806488037223],
        [82.3145828247072, 16.63708305358881],
        [82.31417083740246, 16.63708305358881],
        [82.31402587890642, 16.63708305358881],
        [82.31402587890642, 16.6373615264892],
        [82.31346893310547, 16.637359619140625],
        [82.31346893310547, 16.637638092041016],
        [82.3131866455081, 16.637638092041016],
        [82.3131866455081, 16.638200759887752],
        [82.31263732910168, 16.638195037841797],
        [82.31263732910168, 16.638471603393555],
        [82.3123626708985, 16.63846969604498],
        [82.3123626708985, 16.641809463500977],
        [82.31208038330072, 16.641809463500977],
        [82.31208038330072, 16.64264106750494],
        [82.3123626708985, 16.642639160156364],
        [82.3123626708985, 16.643192291259766],
        [82.31263732910168, 16.64319038391119],
        [82.31263732910168, 16.643751144409123],
        [82.31291961669928, 16.643751144409123],
        [82.31291961669928, 16.64430999755865],
        [82.31319427490263, 16.644306182861328],
        [82.31319427490263, 16.64458465576172],
        [82.31346893310547, 16.64458084106451],
        [82.31346893310547, 16.644861221313477],
        [82.31375122070341, 16.644861221313477],
        [82.31375122070341, 16.645420074462834],
        [82.31402587890642, 16.645416259765682],
        [82.31402587890642, 16.64597129821783],
        [82.31430816650396, 16.645969390869254],
        [82.31430816650396, 16.64624977111822],
        [82.3145828247072, 16.64624977111822],
        [82.3145828247072, 16.64764022827154],
        [82.31430816650396, 16.64764022827154],
        [82.31430816650396, 16.647920608520508],
        [82.31402587890642, 16.647920608520508],
        [82.31402587890642, 16.64820098876953],
        [82.31375122070341, 16.64820098876953],
        [82.31375122070341, 16.648479461669865],
        [82.31346893310547, 16.648479461669865],
        [82.31346893310547, 16.648750305175724],
        [82.3131866455081, 16.648750305175724],
        [82.3131866455081, 16.64903068542486],
        [82.31291961669928, 16.64903068542486],
        [82.31291961669928, 16.649311065673885],
        [82.31263732910168, 16.64930534362793],
        [82.31263732910168, 16.64958381652832],
        [82.3123626708985, 16.64958000183111],
        [82.3123626708985, 16.649860382080078],
        [82.31208038330072, 16.649860382080078],
        [82.31208038330072, 16.650140762329045],
        [82.31180572509788, 16.65013885498047],
        [82.31180572509788, 16.650693893432674],
        [82.3115310668947, 16.650693893432674],
        [82.3115310668947, 16.6512508392334],
        [82.30958557128918, 16.6512508392334],
        [82.30958557128918, 16.64958381652832],
        [82.30986022949219, 16.64958381652832],
        [82.30986022949219, 16.64652824401861],
        [82.31014251709013, 16.646530151367188],
        [82.31014251709013, 16.645420074462834],
        [82.31041717529297, 16.645420074462834],
        [82.31041717529297, 16.64430999755865],
        [82.31069183349638, 16.64430999755865],
        [82.31069183349638, 16.644029617309513],
        [82.31096649169939, 16.644029617309513],
        [82.31096649169939, 16.643751144409123],
        [82.31124877929693, 16.643751144409123],
        [82.31124877929693, 16.64264106750494],
        [82.31096649169939, 16.64264106750494],
        [82.31096649169939, 16.64234924316412],
        [82.31069183349638, 16.642362594604606],
        [82.31069183349638, 16.642084121704215],
        [82.31041717529297, 16.642084121704215],
        [82.31041717529297, 16.640970230102653],
        [82.31014251709013, 16.640970230102653],
        [82.31014251709013, 16.6398601531983],
        [82.31041717529297, 16.6398601531983],
        [82.31041717529297, 16.63958358764654],
        [82.31069183349638, 16.63958358764654],
        [82.31069183349638, 16.63930511474615],
        [82.31096649169939, 16.639310836792106],
        [82.31096649169939, 16.63846969604498],
        [82.31124877929693, 16.638471603393555],
        [82.31124877929693, 16.637638092041016],
        [82.31153869628923, 16.637638092041016],
        [82.31153869628923, 16.637359619140625],
        [82.31180572509788, 16.6373615264892],
        [82.31180572509788, 16.636806488037223],
        [82.31208038330072, 16.636810302734375],
        [82.31208038330072, 16.63653755187994],
        [82.3123626708985, 16.63653755187994],
        [82.3123626708985, 16.636249542236442],
        [82.31263732910168, 16.636249542236442],
        [82.31263732910168, 16.63597106933588],
        [82.31291961669928, 16.635972976684684],
        [82.31291961669928, 16.635694503784293],
        [82.31319427490263, 16.635694503784293],
        [82.31319427490263, 16.63541793823248],
        [82.31346893310547, 16.635419845581055],
        [82.31346893310547, 16.635139465332088],
        [82.31375122070341, 16.635139465332088],
        [82.31375122070341, 16.63458061218273],
        [82.31402587890642, 16.634582519531307],
        [82.31402587890642, 16.63402748107916],
        [82.31430816650396, 16.634029388427734],
        [82.31430816650396, 16.63348007202154],
        [82.3145828247072, 16.63348007202154],
        [82.3145828247072, 16.632921218872184],
        [82.31485748291038, 16.632921218872184],
        [82.31485748291038, 16.632080078125057],
        [82.31513977050776, 16.632085800171012],
        [82.31513977050776, 16.630973815918082],
        [82.315414428711, 16.630973815918082],
        [82.315414428711, 16.628751754760742],
        [82.31569671630871, 16.628751754760742],
        [82.31569671630871, 16.628189086914062],
        [82.31625366210966, 16.628194808960018],
        [82.31625366210966, 16.627916336059627],
        [82.3165283203125, 16.62792015075678],
        [82.3165283203125, 16.62734985351574],
        [82.31680297851591, 16.627359390258846],
        [82.31680297851591, 16.62708282470703],
        [82.31708526611345, 16.62708282470703],
        [82.31708526611345, 16.626806259155273],
        [82.31735229492193, 16.626810073852596],
        [82.31735229492193, 16.626531600952262],
        [82.31764221191423, 16.626531600952262],
        [82.31764221191423, 16.6259708404541],
        [82.31791687011741, 16.625972747802678],
        [82.31791687011741, 16.625137329101562],
        [82.31819152832026, 16.62513923645031],
        [82.31819152832026, 16.624029159545955],
        [82.31791687011741, 16.624029159545955],
        [82.31791687011741, 16.623470306396598],
        [82.31764221191423, 16.623472213745174],
        [82.31764221191423, 16.622083663940487],
        [82.31791687011741, 16.622083663940487],
        [82.31791687011741, 16.621805191040096],
        [82.31874847412121, 16.62181091308605],
        [82.31874847412121, 16.62125015258789],
        [82.3190307617188, 16.62125015258789],
        [82.3190307617188, 16.620700836181697],
        [82.31931304931652, 16.620700836181697],
        [82.31931304931652, 16.61985969543457],
        [82.3190307617188, 16.619861602783146],
        [82.3190307617188, 16.61819458007824],
        [82.31874847412121, 16.61819458007824],
        [82.31874847412121, 16.616806030273494],
        [82.3184661865235, 16.616809844970646],
        [82.3184661865235, 16.615970611572322],
        [82.31819152832026, 16.615970611572322],
        [82.31819152832026, 16.61541748046875],
        [82.31791687011741, 16.61541748046875],
        [82.31791687011741, 16.614862442016545],
        [82.31764221191423, 16.614862442016545],
        [82.31764221191423, 16.614032745361385],
        [82.31735229492193, 16.614032745361385],
        [82.31735229492193, 16.6129207611084],
        [82.31709289550781, 16.6129207611084],
        [82.31709289550781, 16.61236953735363],
        [82.31680297851591, 16.61236953735363],
        [82.31680297851591, 16.611810684204045],
        [82.3165283203125, 16.611810684204045],
        [82.3165283203125, 16.61124992370611],
        [82.31624603271513, 16.61124992370611],
        [82.31624603271513, 16.610969543457145],
        [82.31569671630871, 16.61097145080572],
        [82.31569671630871, 16.610694885253963],
        [82.315414428711, 16.610694885253963],
        [82.315414428711, 16.610416412353572],
        [82.31569671630871, 16.61041831970215],
        [82.31569671630871, 16.6095809936524],
        [82.315414428711, 16.609582901000977],
        [82.315414428711, 16.609027862548828],
        [82.31513977050776, 16.609029769897404],
        [82.31513977050776, 16.60819053649908],
        [82.31485748291038, 16.60819435119629],
        [82.31485748291038, 16.607917785644645],
        [82.3145828247072, 16.60791969299322],
        [82.3145828247072, 16.607360839843693],
        [82.31430816650396, 16.607360839843693],
        [82.31430816650396, 16.607080459594727],
        [82.31402587890642, 16.607082366943303],
        [82.31402587890642, 16.606805801391715],
        [82.31375122070341, 16.606809616088867],
        [82.31375122070341, 16.606241226196403],
        [82.31346893310547, 16.60625076293951],
        [82.31346893310547, 16.60541725158697],
        [82.3131866455081, 16.605421066284123],
        [82.3131866455081, 16.604579925537223],
        [82.31291961669928, 16.604583740234432],
        [82.31291961669928, 16.604028701782227],
        [82.31263732910168, 16.604030609130803],
        [82.31263732910168, 16.60346984863287],
        [82.3123626708985, 16.603473663330078],
        [82.3123626708985, 16.602916717529297],
        [82.31208038330072, 16.60292053222662],
        [82.31208038330072, 16.602359771728516],
        [82.31069183349638, 16.602359771728516],
        [82.31069183349638, 16.602640151977482],
        [82.31014251709013, 16.602640151977482],
        [82.31014251709013, 16.60292053222662],
        [82.30930328369146, 16.60292053222662],
        [82.30930328369146, 16.603200912475643],
        [82.30874633789068, 16.603195190429688],
        [82.30874633789068, 16.603473663330078],
        [82.30847167968767, 16.60346984863287],
        [82.30847167968767, 16.603750228881836],
        [82.30790710449236, 16.603750228881836],
        [82.30790710449236, 16.604030609130803],
        [82.30764007568388, 16.604030609130803],
        [82.30764007568388, 16.604310989379996],
        [82.3070831298831, 16.60430526733404],
        [82.3070831298831, 16.604583740234432],
        [82.30652618408232, 16.604579925537223],
        [82.30652618408232, 16.60486030578619],
        [82.30625152587896, 16.60486030578619],
        [82.30625152587896, 16.605140686035156],
        [82.30568695068365, 16.605140686035156],
        [82.30568695068365, 16.605421066284123],
        [82.30541992187517, 16.60541725158697],
        [82.30541992187517, 16.60569572448736],
        [82.30486297607439, 16.60569572448736],
        [82.30486297607439, 16.60597229003912],
        [82.30458068847662, 16.60597229003912],
        [82.30458068847662, 16.60625076293951],
        [82.3040313720706, 16.606241226196403],
        [82.3040313720706, 16.606531143188477],
        [82.30374908447266, 16.606531143188477],
        [82.30374908447266, 16.606809616088867],
        [82.30339050292974, 16.606805801391715],
        [82.30319213867193, 16.606805801391715],
        [82.30319213867193, 16.607082366943303],
        [82.30264282226568, 16.607080459594727],
        [82.30264282226568, 16.60764122009283],
        [82.30236816406267, 16.60764122009283],
        [82.30236816406267, 16.60791969299322],
        [82.30208587646507, 16.607917785644645],
        [82.30208587646507, 16.60819435119629],
        [82.30152893066412, 16.60819435119629],
        [82.30152893066412, 16.608472824096623],
        [82.30124664306658, 16.608470916748047],
        [82.30124664306658, 16.608749389648438],
        [82.30097198486357, 16.608749389648438],
        [82.30097198486357, 16.60931015014654],
        [82.30069732666016, 16.60930633544922],
        [82.30069732666016, 16.609861373901367],
        [82.30042266845732, 16.60985946655279],
        [82.30042266845732, 16.610139846801758],
        [82.30014038085943, 16.610139846801758],
        [82.30014038085943, 16.61041831970215],
        [82.29985809326183, 16.610416412353572],
        [82.29985809326183, 16.610694885253963],
        [82.29958343505865, 16.610694885253963],
        [82.29958343505865, 16.61097145080572],
        [82.29930877685541, 16.610969543457145],
        [82.29930877685541, 16.61124992370611],
        [82.29902648925804, 16.61124992370611],
        [82.29902648925804, 16.611528396606502],
        [82.29875183105486, 16.611528396606502],
        [82.29875183105486, 16.611810684204045],
        [82.29846954345709, 16.611804962158317],
        [82.29846954345709, 16.612083435058707],
        [82.29818725585955, 16.612079620361328],
        [82.29818725585955, 16.612638473510856],
        [82.29792022705107, 16.612638473510856],
        [82.29792022705107, 16.6129207611084],
        [82.29736328125028, 16.612916946411246],
        [82.29736328125028, 16.61347007751465],
        [82.29708099365234, 16.613468170166072],
        [82.29708099365234, 16.614032745361385],
        [82.29680633544933, 16.61402702331543],
        [82.29680633544933, 16.614582061767578],
        [82.29651641845709, 16.614580154419002],
        [82.29651641845709, 16.61514282226574],
        [82.29624938964838, 16.615140914916935],
        [82.29624938964838, 16.61541748046875],
        [82.29596710205084, 16.615411758422965],
        [82.29596710205084, 16.616252899169922],
        [82.29569244384783, 16.616252899169922],
        [82.29569244384783, 16.61652946472168],
        [82.29541778564459, 16.61652946472168],
        [82.29541778564459, 16.617361068725643],
        [82.29513549804705, 16.617361068725643],
        [82.29513549804705, 16.61819458007824],
        [82.29458618164062, 16.618181228637752],
        [82.29458618164062, 16.618749618530387],
        [82.29430389404325, 16.618749618530387],
        [82.29430389404325, 16.619583129882756],
        [82.29402923583984, 16.61958122253418],
        [82.29402923583984, 16.620140075683537],
        [82.29374694824247, 16.620140075683537],
        [82.29374694824247, 16.620420455932674],
        [82.29347229003912, 16.620420455932674],
        [82.29347229003912, 16.62153816223139],
        [82.29319763183588, 16.62152862548828],
        [82.29319763183588, 16.622083663940487],
        [82.29292297363304, 16.622079849243278],
        [82.29292297363304, 16.62264060974121],
        [82.29264068603533, 16.622638702392635],
        [82.29264068603533, 16.623472213745174],
        [82.29235839843773, 16.623470306396598],
        [82.29235839843773, 16.623750686645565],
        [82.29207611084001, 16.623750686645565],
        [82.29207611084001, 16.624309539794922],
        [82.29180908203153, 16.624309539794922],
        [82.29180908203153, 16.624860763549748],
        [82.29152679443376, 16.624860763549748],
        [82.29152679443376, 16.62513923645031],
        [82.29125213623075, 16.62513923645031],
        [82.29125213623075, 16.626249313354492],
        [82.29096984863281, 16.626249313354492],
        [82.29096984863281, 16.62708282470703],
        [82.29070281982433, 16.627080917358455],
        [82.29070281982433, 16.62792015075678],
        [82.29013824462902, 16.62792015075678],
        [82.29013824462902, 16.629030227661133],
        [82.28986358642584, 16.629028320312557],
        [82.28986358642584, 16.62930488586437],
        [82.28958129882807, 16.62930488586437],
        [82.28958129882807, 16.6304168701173],
        [82.28930664062523, 16.6304168701173],
        [82.28930664062523, 16.630973815918082],
        [82.28901672363298, 16.630970001220703],
        [82.28901672363298, 16.63181114196783],
        [82.28874969482428, 16.63181114196783],
        [82.28874969482428, 16.632360458374023],
        [82.28848266601562, 16.632360458374023],
        [82.28848266601562, 16.632921218872184],
        [82.28791809082031, 16.632917404174805],
        [82.28791809082031, 16.633195877075195],
        [82.28763580322294, 16.63319015502941],
        [82.28763580322294, 16.633750915527344],
        [82.28736877441412, 16.633750915527344],
        [82.28736877441412, 16.634029388427734],
        [82.28708648681652, 16.634029388427734],
        [82.28708648681652, 16.6343097686767],
        [82.28652954101557, 16.6343097686767],
        [82.28652954101557, 16.634860992431697],
        [82.2862472534182, 16.634860992431697],
        [82.2862472534182, 16.636249542236442],
        [82.28597259521501, 16.636249542236442],
        [82.28597259521501, 16.636800765991268],
        [82.28569793701178, 16.636810302734375],
        [82.28569793701178, 16.638200759887752],
        [82.28542327880876, 16.638200759887752],
        [82.28542327880876, 16.639028549194336],
        [82.28485870361328, 16.639028549194336],
        [82.28485870361328, 16.639310836792106],
        [82.28457641601591, 16.639310836792106],
        [82.28457641601591, 16.64013862609869],
        [82.2843017578125, 16.64013862609869],
        [82.2843017578125, 16.640422821045036],
        [82.28402709960949, 16.640422821045036],
        [82.28402709960949, 16.64153289794922],
        [82.2843017578125, 16.641527175903434],
        [82.2843017578125, 16.642084121704215],
        [82.28402709960949, 16.642084121704215],
        [82.28402709960949, 16.642362594604606],
        [82.2837524414063, 16.64234924316412],
        [82.2837524414063, 16.64264106750494],
        [82.28320312500023, 16.64264106750494],
        [82.28320312500023, 16.64234924316412],
        [82.28291320800798, 16.64234924316412],
        [82.28291320800798, 16.64264106750494],
        [82.2823562622072, 16.64264106750494],
        [82.2823562622072, 16.64234924316412],
        [82.2748565673831, 16.64234924316412],
        [82.2748565673831, 16.64264106750494],
        [82.27375030517572, 16.64264106750494],
        [82.27375030517572, 16.64291954040533],
        [82.27319335937517, 16.642915725708008],
        [82.27319335937517, 16.643192291259766],
        [82.27291870117193, 16.643192291259766],
        [82.27291870117193, 16.643474578857536],
        [82.27236175537138, 16.643472671508732],
        [82.27236175537138, 16.643751144409123],
        [82.27014160156267, 16.643751144409123],
        [82.27014160156267, 16.644029617309513],
        [82.26930236816435, 16.644029617309513],
        [82.26930236816435, 16.643751144409123],
        [82.2687530517581, 16.643751144409123],
        [82.2687530517581, 16.64319038391119],
        [82.2676391601562, 16.643192291259766],
        [82.2676391601562, 16.643474578857536],
        [82.26708221435564, 16.643472671508732],
        [82.26708221435564, 16.643751144409123],
        [82.2668075561524, 16.643751144409123],
        [82.2668075561524, 16.644029617309513],
        [82.26625823974638, 16.644029617309513],
        [82.26625823974638, 16.64430999755865],
        [82.26569366455107, 16.644306182861328],
        [82.26569366455107, 16.64458465576172],
        [82.26513671875028, 16.64458084106451],
        [82.26513671875028, 16.644861221313477],
        [82.26486206054693, 16.644861221313477],
        [82.26486206054693, 16.645139694213867],
        [82.26431274414085, 16.645139694213867],
        [82.26431274414085, 16.645420074462834],
        [82.26374816894554, 16.645416259765682],
        [82.26374816894554, 16.645694732666072],
        [82.26291656494158, 16.645694732666072],
        [82.26291656494158, 16.64597129821783],
        [82.26264190673857, 16.64597129821783],
        [82.26264190673857, 16.645694732666072],
        [82.26235961914062, 16.645694732666072],
        [82.26235961914062, 16.64597129821783],
        [82.2618026733399, 16.645969390869254],
        [82.2618026733399, 16.64624977111822],
        [82.26125335693365, 16.64624977111822],
        [82.26125335693365, 16.646530151367188],
        [82.26069641113304, 16.64652824401861],
        [82.26069641113304, 16.646806716919002],
        [82.2604064941408, 16.646800994873047],
        [82.2604064941408, 16.64764022827154],
        [82.25985717773455, 16.64764022827154],
        [82.25985717773455, 16.647920608520508],
        [82.25958251953153, 16.647920608520508],
        [82.25958251953153, 16.648479461669865],
        [82.25930786132812, 16.648479461669865],
        [82.25930786132812, 16.648750305175724],
        [82.2587509155274, 16.648750305175724],
        [82.2587509155274, 16.649311065673885],
        [82.2584686279298, 16.64930534362793],
        [82.2584686279298, 16.64958381652832],
        [82.25818634033209, 16.64958000183111],
        [82.25818634033209, 16.650140762329045],
        [82.25791931152338, 16.650140762329045],
        [82.25791931152338, 16.65042114257824],
        [82.25763702392601, 16.65041732788086],
        [82.25763702392601, 16.650972366333008],
        [82.25736236572283, 16.650970458984432],
        [82.25736236572283, 16.6512508392334],
        [82.2570724487307, 16.6512508392334],
        [82.2570724487307, 16.651809692382756],
        [82.25680541992205, 16.651805877685604],
        [82.25681304931658, 16.652360916137752],
        [82.25653076171903, 16.652360916137752],
        [82.25653076171903, 16.652921676635742],
        [82.2562484741211, 16.652921676635742],
        [82.2562484741211, 16.653749465942496],
        [82.25596618652372, 16.653749465942496],
        [82.25596618652372, 16.655420303344783],
        [82.25569152832031, 16.655420303344783],
        [82.25569152832031, 16.656251907348576],
        [82.25596618652372, 16.656251907348576],
        [82.25596618652372, 16.65764045715332],
        [82.2562484741211, 16.657638549804744],
        [82.2562484741211, 16.658195495605526],
        [82.25653076171903, 16.65818977355957],
        [82.25653076171903, 16.658750534057674],
        [82.25681304931658, 16.658750534057674],
        [82.25681304931658, 16.65930938720703],
        [82.25708007812506, 16.65930557250988],
        [82.25708007812506, 16.659585952758846],
        [82.25763702392601, 16.65958023071289],
        [82.25763702392601, 16.659860610961857],
        [82.25791931152338, 16.659860610961857],
        [82.25791931152338, 16.660972595214787],
        [82.25818634033209, 16.66097068786621],
        [82.25818634033209, 16.661251068115178],
        [82.2584686279298, 16.661251068115178],
        [82.2584686279298, 16.661529541015568],
        [82.2587509155274, 16.661527633666992],
        [82.2587509155274, 16.66208267211914],
        [82.25902557373075, 16.66208267211914],
        [82.25902557373075, 16.66236114501953],
        [82.25958251953153, 16.66236114501953],
        [82.25958251953153, 16.662639617919922],
        [82.25985717773455, 16.662639617919922],
        [82.25985717773455, 16.662918090820312],
        [82.26013946533209, 16.662916183471737],
        [82.26013946533209, 16.663473129272518],
        [82.2604064941408, 16.66345977783203],
        [82.2604064941408, 16.663749694824276],
        [82.26069641113304, 16.663749694824276],
        [82.26069641113304, 16.66430473327648],
        [82.26097106933588, 16.66430473327648],
        [82.26097106933588, 16.66458320617687],
        [82.26125335693365, 16.66458320617687],
        [82.26125335693365, 16.664861679077205],
        [82.26152801513683, 16.66485977172863],
        [82.26152801513683, 16.66513824462902],
        [82.2618026733399, 16.66513824462902],
        [82.2618026733399, 16.665420532226562],
        [82.26207733154325, 16.665420532226562],
        [82.26207733154325, 16.665700912475586],
        [82.26235961914062, 16.6656951904298],
        [82.26235961914062, 16.66597175598139],
        [82.26291656494158, 16.665969848632812],
        [82.26291656494158, 16.666248321533203],
        [82.26319122314459, 16.666248321533203],
        [82.26319122314459, 16.666530609130916],
        [82.26346588134783, 16.666519165039062],
        [82.26346588134783, 16.667360305786133],
        [82.26374816894554, 16.667360305786133],
        [82.26374816894554, 16.667921066284293],
        [82.26403045654314, 16.667917251586914],
        [82.26403045654314, 16.66819381713873],
        [82.26457977294933, 16.668191909790096],
        [82.26457977294933, 16.668752670288086],
        [82.26486206054693, 16.668752670288086],
        [82.26486206054693, 16.66903114318842],
        [82.26513671875028, 16.669027328491268],
        [82.26513671875028, 16.67097473144537],
        [82.26541900634766, 16.670972824096793],
        [82.26541900634766, 16.67124938964855],
        [82.26568603515653, 16.67124938964855],
        [82.26568603515653, 16.671529769897518],
        [82.26597595214844, 16.671529769897518],
        [82.26597595214844, 16.674310684204215],
        [82.26569366455107, 16.674306869506836],
        [82.26569366455107, 16.67458343505865],
        [82.26541900634766, 16.674579620361442],
        [82.26541900634766, 16.675140380859375],
        [82.26513671875028, 16.675140380859375],
        [82.26513671875028, 16.676530838012695],
        [82.26486206054693, 16.67652893066412],
        [82.26486206054693, 16.677083969116325],
        [82.26457977294933, 16.677080154418945],
        [82.26457977294933, 16.677360534667912],
        [82.26431274414085, 16.677360534667912],
        [82.26431274414085, 16.677917480468864],
        [82.26403045654314, 16.677917480468864],
        [82.26403045654314, 16.678194046020508],
        [82.26430511474615, 16.678194046020508],
        [82.26430511474615, 16.67847251892084],
        [82.26403045654314, 16.67846107482916],
        [82.26403045654314, 16.678749084472656],
        [82.26374816894554, 16.67874717712408],
        [82.26374816894554, 16.679582595825195],
        [82.26346588134783, 16.67958068847662],
        [82.26347351074236, 16.680141448974553],
        [82.26319885253935, 16.680141448974553],
        [82.26319885253935, 16.680421829223746],
        [82.26291656494158, 16.680421829223746],
        [82.26291656494158, 16.68153190612793],
        [82.26264190673857, 16.68152809143072],
        [82.26264190673857, 16.682363510131893],
        [82.26235961914062, 16.682359695434684],
        [82.26235961914062, 16.682920455932617],
        [82.26264190673857, 16.682920455932617],
        [82.26264190673857, 16.68320083618164],
        [82.26235961914062, 16.68320083618164],
        [82.26235961914062, 16.684589385986328],
        [82.26208496093778, 16.68458366394043],
        [82.26208496093778, 16.68569564819336],
        [82.2618026733399, 16.685689926147575],
        [82.2618026733399, 16.686250686645508],
        [82.26152801513683, 16.686250686645508],
        [82.26152801513683, 16.686531066894474],
        [82.26125335693365, 16.686531066894474],
        [82.26125335693365, 16.686809539794865],
        [82.26097106933588, 16.686809539794865],
        [82.26097106933588, 16.68736076354986],
        [82.26069641113304, 16.68736076354986],
        [82.26069641113304, 16.68791961669922],
        [82.26041412353533, 16.687917709350643],
        [82.26041412353533, 16.688472747802848],
        [82.25985717773455, 16.688470840454045],
        [82.25985717773455, 16.688751220703182],
        [82.25958251953153, 16.688751220703182],
        [82.25958251953153, 16.689308166503963],
        [82.25930786132812, 16.689306259155387],
        [82.25930786132812, 16.689582824706974],
        [82.25902557373075, 16.689582824706974],
        [82.25902557373075, 16.689861297607365],
        [82.2587509155274, 16.689861297607365],
        [82.2587509155274, 16.690111160278377],
        [82.26467132568365, 16.690530776977653],
        [82.2763366699221, 16.6931095123291],
        [82.28515625000028, 16.69744110107422],
        [82.28539276123064, 16.69763946533203],
        [82.28763580322294, 16.69763946533203],
        [82.28763580322294, 16.697362899780273],
        [82.28958129882807, 16.697362899780273],
        [82.28958129882807, 16.697080612182674],
        [82.29013824462902, 16.697084426879883],
        [82.29013824462902, 16.696805953979492],
        [82.29096984863281, 16.696821212768555],
        [82.29096984863281, 16.696529388427678],
        [82.29207611084001, 16.696529388427678],
        [82.29207611084001, 16.696821212768555],
        [82.29235839843773, 16.696805953979492],
        [82.29235839843773, 16.697084426879883],
        [82.29264068603533, 16.697080612182674],
        [82.29264068603533, 16.69763946533203],
        [82.29292297363304, 16.69763946533203],
        [82.29292297363304, 16.697919845580998],
        [82.29319763183588, 16.697916030883846],
        [82.29319763183588, 16.698194503784237],
        [82.29458618164062, 16.698194503784237],
        [82.29458618164062, 16.698474884033203],
        [82.29596710205084, 16.698471069335994],
        [82.29596710205084, 16.698749542236385],
        [82.29708099365234, 16.698749542236385],
        [82.29708099365234, 16.69902992248535],
        [82.30374908447266, 16.69902992248535],
        [82.30374908447266, 16.699310302734318],
        [82.30430603027361, 16.699310302734318],
        [82.30430603027361, 16.699590682983512],
        [82.30596923828136, 16.69958305358898],
        [82.30596923828136, 16.699861526489315],
        [82.30652618408232, 16.69985961914074],
        [82.30652618408232, 16.700139999389705],
        [82.30680847167969, 16.700139999389705],
        [82.30680847167969, 16.700420379638672],
        [82.30735778808611, 16.700420379638672],
        [82.30735778808611, 16.700700759887695],
        [82.30791473388689, 16.700696945190487],
        [82.30791473388689, 16.700971603393498],
        [82.30847167968767, 16.700969696044922],
        [82.30847167968767, 16.70125007629389],
        [82.30902862548822, 16.70125007629389],
        [82.30902862548822, 16.701530456543026],
        [82.30959320068371, 16.701530456543026],
        [82.30959320068371, 16.70125007629389],
        [82.31041717529297, 16.70125007629389],
        [82.31041717529297, 16.70181083679205],
        [82.31069183349638, 16.701805114746094],
        [82.31069183349638, 16.702083587646484],
        [82.3115310668947, 16.702083587646484],
        [82.3115310668947, 16.702362060546818],
        [82.31208038330072, 16.702362060546818],
        [82.31208038330072, 16.702638626098633],
        [82.3123626708985, 16.7026309967041],
        [82.3123626708985, 16.702920913696403],
        [82.31319427490263, 16.702917098999023],
        [82.31319427490263, 16.703472137451172],
        [82.31430816650396, 16.703470230102596],
        [82.31430816650396, 16.703750610351562],
        [82.3145828247072, 16.703750610351562],
        [82.3145828247072, 16.704029083251953],
        [82.31540679931646, 16.704029083251953],
        [82.31540679931646, 16.704307556152344],
        [82.31569671630871, 16.704305648803768],
        [82.31569671630871, 16.70458412170416],
        [82.3165283203125, 16.70458030700695],
        [82.3165283203125, 16.704860687255916],
        [82.31680297851591, 16.704860687255916],
        [82.31680297851591, 16.705139160156307],
        [82.31714630126976, 16.705139160156307],
        [82.31735229492193, 16.705139160156307],
        [82.31735229492193, 16.705417633056697],
        [82.31764221191423, 16.705417633056697],
        [82.31764221191423, 16.705972671508903],
        [82.31819152832026, 16.7059707641601],
        [82.31819152832026, 16.70624923706066],
        [82.3184661865235, 16.70624923706066],
        [82.3184661865235, 16.70653152465826],
        [82.31931304931652, 16.70653152465826],
        [82.31931304931652, 16.706811904907227],
        [82.31986236572294, 16.706806182861442],
        [82.31986236572294, 16.70708274841303],
        [82.32013702392595, 16.707080841064453],
        [82.32013702392595, 16.707359313964844],
        [82.32041168212896, 16.707359313964844],
        [82.32041168212896, 16.707641601562614],
        [82.32069396972673, 16.707641601562614],
        [82.32069396972673, 16.708194732666016],
        [82.32125091552751, 16.708194732666016],
        [82.32125091552751, 16.708469390869197],
        [82.32153320312523, 16.708469390869197],
        [82.32153320312523, 16.70903015136713],
        [82.32180786132807, 16.709028244018555],
        [82.32180786132807, 16.709581375122127],
        [82.32235717773466, 16.70957946777355],
        [82.32235717773466, 16.710140228271484],
        [82.32263946533203, 16.710140228271484],
        [82.32263946533203, 16.710700988769645],
        [82.32290649414091, 16.710700988769645],
        [82.32290649414091, 16.711250305175838],
        [82.32319641113281, 16.711250305175838],
        [82.32319641113281, 16.711530685424805],
        [82.32347106933622, 16.711526870727653],
        [82.32347106933622, 16.71208572387701],
        [82.32375335693376, 16.712080001831055],
        [82.32375335693376, 16.71236038208002],
        [82.32402801513678, 16.71236038208002],
        [82.32402801513678, 16.71264076232916],
        [82.32430267334001, 16.71264076232916],
        [82.32430267334001, 16.712921142578182],
        [82.32458496093773, 16.712917327880973],
        [82.32458496093773, 16.71347236633295],
        [82.32514190673834, 16.713470458984375],
        [82.32514190673834, 16.714031219482536],
        [82.32569122314453, 16.714031219482536],
        [82.32569122314453, 16.71430969238287],
        [82.32662200927751, 16.714307785034293],
        [82.32763671875023, 16.71430969238287],
        [82.32763671875023, 16.714031219482536],
        [82.3281860351563, 16.714031219482536],
        [82.3281860351563, 16.71430969238287],
        [82.32902526855497, 16.714305877685547],
        [82.32902526855497, 16.714860916137695],
        [82.32930755615234, 16.714860916137695],
        [82.32930755615234, 16.715139389038086],
        [82.32902526855497, 16.715139389038086],
        [82.32902526855497, 16.71569442749029],
        [82.32875061035162, 16.71569442749029],
        [82.32875061035162, 16.715972900390682],
        [82.3281860351563, 16.71597099304205],
        [82.3281860351563, 16.71624946594244],
        [82.3279190063476, 16.71624946594244],
        [82.3279190063476, 16.716529846191406],
        [82.32736206054705, 16.716529846191406],
        [82.32736206054705, 16.716810226440373],
        [82.32653045654325, 16.71680641174322],
        [82.32653045654325, 16.71736145019537],
        [82.32624816894531, 16.717359542846793],
        [82.32624816894531, 16.71791839599615],
        [82.32653045654325, 16.717916488647575],
        [82.32653045654325, 16.718471527099553],
        [82.32624816894531, 16.718469619750977],
        [82.32624816894531, 16.718748092651367],
        [82.32653045654325, 16.718748092651367],
        [82.32653045654325, 16.719860076904297],
        [82.3268127441408, 16.71985816955572],
        [82.3268127441408, 16.721809387206974],
        [82.32707977294928, 16.721809387206974],
        [82.32707977294928, 16.72264289855957],
        [82.32736206054705, 16.72263908386236],
        [82.32736206054705, 16.723474502563533],
        [82.32763671875023, 16.723474502563533],
        [82.32763671875023, 16.724584579467887],
        [82.3279190063476, 16.724580764770508],
        [82.3279190063476, 16.724861145019474],
        [82.32875061035162, 16.724861145019474],
        [82.32875061035162, 16.725419998169002],
        [82.32930755615234, 16.725419998169002],
        [82.32930755615234, 16.725139617919865],
        [82.32958221435575, 16.725139617919865],
        [82.32958221435575, 16.724861145019474],
        [82.33207702636747, 16.724861145019474],
      ],
    ],
    [
      [
        [83.1720733642581, 17.569030761718864],
        [83.1720733642581, 17.568750381469727],
        [83.17236328125, 17.568750381469727],
        [83.17236328125, 17.56680107116705],
        [83.17208099365263, 17.5668048858642],
        [83.17208099365263, 17.566528320312614],
        [83.17180633544928, 17.566539764404297],
        [83.17180633544928, 17.566249847412223],
        [83.1712493896485, 17.566249847412223],
        [83.1712493896485, 17.565969467163086],
        [83.17069244384788, 17.565969467163086],
        [83.17069244384788, 17.566249847412223],
        [83.1704177856447, 17.566249847412223],
        [83.1704177856447, 17.5668048858642],
        [83.17069244384788, 17.5668048858642],
        [83.17069244384788, 17.567083358764762],
        [83.17097473144548, 17.567083358764762],
        [83.17097473144548, 17.567361831665153],
        [83.1712493896485, 17.567359924316406],
        [83.1712493896485, 17.568750381469727],
        [83.17151641845714, 17.568750381469727],
        [83.17151641845714, 17.569030761718864],
        [83.1720733642581, 17.569030761718864],
      ],
    ],
    [
      [
        [83.30569458007818, 17.684583663940373],
        [83.30569458007818, 17.683471679687614],
        [83.30541992187511, 17.683471679687614],
        [83.30541992187511, 17.6831951141358],
        [83.30458831787132, 17.683202743530387],
        [83.30458831787132, 17.682920455932617],
        [83.30430603027361, 17.682920455932617],
        [83.30430603027361, 17.68264007568365],
        [83.30403137207037, 17.68264007568365],
        [83.30403137207037, 17.68236160278326],
        [83.30319213867188, 17.68236160278326],
        [83.30319213867188, 17.68264007568365],
        [83.30291748046903, 17.68264007568365],
        [83.30291748046903, 17.683202743530387],
        [83.30319213867188, 17.6831951141358],
        [83.30319213867188, 17.683471679687614],
        [83.30347442626982, 17.683471679687614],
        [83.30347442626982, 17.683750152588004],
        [83.30374908447283, 17.68374061584467],
        [83.30374908447283, 17.68403053283697],
        [83.30430603027361, 17.68403053283697],
        [83.30430603027361, 17.684312820434513],
        [83.3051376342774, 17.684305191039982],
        [83.3051376342774, 17.684583663940373],
        [83.30569458007818, 17.684583663940373],
      ],
    ],
    [
      [
        [83.31208038330107, 17.684860229492188],
        [83.31208038330107, 17.68403053283697],
        [83.31124877929693, 17.68403053283697],
        [83.31124877929693, 17.68374061584467],
        [83.31014251709013, 17.683750152588004],
        [83.31014251709013, 17.683471679687614],
        [83.30986022949236, 17.683471679687614],
        [83.30986022949236, 17.6831951141358],
        [83.30902862548857, 17.683202743530387],
        [83.30902862548857, 17.682920455932617],
        [83.30847167968761, 17.682920455932617],
        [83.30847167968761, 17.68264007568365],
        [83.30764770507818, 17.68264007568365],
        [83.30764770507818, 17.68236160278326],
        [83.30680847167986, 17.68236160278326],
        [83.30680847167986, 17.68208312988287],
        [83.30652618408232, 17.68208312988287],
        [83.30652618408232, 17.68180465698248],
        [83.30569458007818, 17.68180465698248],
        [83.30569458007818, 17.68152809143072],
        [83.3051376342774, 17.68152809143072],
        [83.3051376342774, 17.68124961853033],
        [83.30430603027361, 17.68124961853033],
        [83.30430603027361, 17.68097114562994],
        [83.30403137207037, 17.680973052978516],
        [83.30403137207037, 17.680694580078125],
        [83.30291748046903, 17.680694580078125],
        [83.30291748046903, 17.680416107177734],
        [83.30236053466808, 17.680419921875114],
        [83.30236053466808, 17.680139541625977],
        [83.30179595947277, 17.680139541625977],
        [83.30179595947277, 17.679861068725586],
        [83.30069732666033, 17.679861068725586],
        [83.30069732666033, 17.67958068847662],
        [83.30014038085938, 17.679582595825195],
        [83.30014038085938, 17.679307937622184],
        [83.29930877685558, 17.67930984497076],
        [83.29930877685558, 17.679029464721793],
        [83.29818725585932, 17.679029464721793],
        [83.29818725585932, 17.67930984497076],
        [83.29792022705084, 17.679307937622184],
        [83.29792022705084, 17.679582595825195],
        [83.29902648925787, 17.67958068847662],
        [83.29902648925787, 17.679861068725586],
        [83.299858093262, 17.679861068725586],
        [83.299858093262, 17.680139541625977],
        [83.30042266845732, 17.680139541625977],
        [83.30042266845732, 17.680419921875114],
        [83.30152893066429, 17.680416107177734],
        [83.30152893066429, 17.680694580078125],
        [83.30180358886713, 17.680694580078125],
        [83.30180358886713, 17.680973052978516],
        [83.3026351928711, 17.680973052978516],
        [83.3026351928711, 17.68124961853033],
        [83.30346679687528, 17.68124961853033],
        [83.30346679687528, 17.68152809143072],
        [83.30403137207037, 17.68152809143072],
        [83.30403137207037, 17.68180465698248],
        [83.30486297607416, 17.68180465698248],
        [83.30486297607416, 17.68208312988287],
        [83.30541992187511, 17.68208312988287],
        [83.30541992187511, 17.68236160278326],
        [83.30596923828153, 17.68236160278326],
        [83.30596923828153, 17.68264007568365],
        [83.30652618408232, 17.68264007568365],
        [83.30652618408232, 17.682920455932617],
        [83.30735778808611, 17.682920455932617],
        [83.30735778808611, 17.683202743530387],
        [83.30791473388666, 17.6831951141358],
        [83.30791473388666, 17.683471679687614],
        [83.30874633789068, 17.683471679687614],
        [83.30874633789068, 17.683750152588004],
        [83.3093032836914, 17.68374061584467],
        [83.3093032836914, 17.68403053283697],
        [83.30986022949236, 17.68403053283697],
        [83.30986022949236, 17.684312820434513],
        [83.31069183349632, 17.684305191039982],
        [83.31069183349632, 17.684583663940373],
        [83.31124877929693, 17.684581756591797],
        [83.31124877929693, 17.684860229492188],
        [83.31208038330107, 17.684860229492188],
      ],
    ],
    [
      [
        [83.2698593139649, 17.708749771118164],
        [83.2698593139649, 17.708190917968807],
        [83.2687530517581, 17.70819473266596],
        [83.2687530517581, 17.708471298217773],
        [83.26847076416033, 17.708471298217773],
        [83.26847076416033, 17.709861755371094],
        [83.2687530517581, 17.709861755371094],
        [83.2687530517581, 17.710140228271484],
        [83.27014160156244, 17.710140228271484],
        [83.27014160156244, 17.708749771118164],
        [83.2698593139649, 17.708749771118164],
      ],
    ],
    [
      [
        [82.29951477050798, 16.71433258056652],
        [82.30282592773443, 16.722839355468807],
        [82.3010559082033, 16.733339309692383],
        [82.29773712158232, 16.740379333496094],
        [82.29370117187506, 16.743040084838924],
        [82.28909301757818, 16.74098968505865],
        [82.28507995605474, 16.734508514404297],
        [82.2807006835938, 16.722749710083065],
        [82.26370239257812, 16.719369888305607],
        [82.24494934082026, 16.718959808349666],
        [82.22704315185564, 16.717180252075252],
        [82.2204208374024, 16.718309402465877],
        [82.21478271484403, 16.725200653076172],
        [82.21096801757841, 16.727939605712947],
        [82.2100296020509, 16.731271743774414],
        [82.21978759765642, 16.74486923217779],
        [82.22181701660185, 16.750352859497127],
        [82.2176818847658, 16.751689910888672],
        [82.2111358642581, 16.7482204437257],
        [82.20722961425781, 16.743709564208984],
        [82.19677734375006, 16.73647117614746],
        [82.18875122070318, 16.72721290588379],
        [82.18032073974626, 16.728099822998104],
        [82.17463684082037, 16.72510910034191],
        [82.17907714843756, 16.71630096435547],
        [82.19358062744169, 16.708450317382756],
        [82.20252990722662, 16.70769119262701],
        [82.21543884277344, 16.709760665893555],
        [82.22740936279314, 16.704650878906193],
        [82.24653625488281, 16.69144058227539],
        [82.25344848632835, 16.689720153808707],
        [82.25763702392601, 16.690113067626953],
        [82.25791931152338, 16.690139770507756],
        [82.25791931152338, 16.68985939025879],
        [82.2584686279298, 16.689861297607365],
        [82.2584686279298, 16.688749313354606],
        [82.2587509155274, 16.688751220703182],
        [82.2587509155274, 16.687080383300895],
        [82.25902557373075, 16.687084197998104],
        [82.25902557373075, 16.685138702392578],
        [82.2587509155274, 16.685140609741154],
        [82.2587509155274, 16.68403053283697],
        [82.25903320312528, 16.68403053283697],
        [82.25903320312528, 16.68153190612793],
        [82.25930786132812, 16.68153190612793],
        [82.25930786132812, 16.680690765380973],
        [82.25958251953153, 16.680694580078182],
        [82.25958251953153, 16.68041610717779],
        [82.25930786132812, 16.680421829223746],
        [82.25930786132812, 16.680141448974553],
        [82.25958251953153, 16.680141448974553],
        [82.25958251953153, 16.67985916137701],
        [82.25985717773455, 16.67985916137701],
        [82.25985717773455, 16.67846107482916],
        [82.26013946533209, 16.67847251892084],
        [82.26013946533209, 16.678194046020508],
        [82.25986480712908, 16.678194046020508],
        [82.25986480712908, 16.676805496215934],
        [82.26013946533209, 16.67681121826172],
        [82.26013946533209, 16.67625045776373],
        [82.25985717773455, 16.67625045776373],
        [82.25985717773455, 16.675140380859375],
        [82.2604064941408, 16.675140380859375],
        [82.2604064941408, 16.67486000061041],
        [82.26013946533209, 16.674861907958984],
        [82.26013946533209, 16.67458343505865],
        [82.25986480712908, 16.67458343505865],
        [82.25986480712908, 16.674306869506836],
        [82.25958251953153, 16.674310684204215],
        [82.25958251953153, 16.673749923706055],
        [82.25985717773455, 16.673749923706055],
        [82.25985717773455, 16.672920227050838],
        [82.26013946533209, 16.672920227050838],
        [82.26013946533209, 16.672359466552734],
        [82.26041412353533, 16.67236137390131],
        [82.26041412353533, 16.672084808349723],
        [82.26069641113304, 16.672084808349723],
        [82.26069641113304, 16.671527862548942],
        [82.26097106933588, 16.671529769897518],
        [82.26097106933588, 16.66930961608881],
        [82.26069641113304, 16.66930961608881],
        [82.26069641113304, 16.66903114318842],
        [82.26097106933588, 16.66903114318842],
        [82.26097106933588, 16.668470382690487],
        [82.26152801513683, 16.668470382690487],
        [82.26152801513683, 16.66819381713873],
        [82.26125335693365, 16.66819381713873],
        [82.26125335693365, 16.666530609130916],
        [82.26097106933588, 16.666530609130916],
        [82.26097106933588, 16.666248321533203],
        [82.26069641113304, 16.666248321533203],
        [82.26069641113304, 16.665969848632812],
        [82.26041412353533, 16.66597175598139],
        [82.26041412353533, 16.6656951904298],
        [82.26013946533209, 16.665700912475586],
        [82.26013946533209, 16.66513824462902],
        [82.25985717773455, 16.66513824462902],
        [82.25985717773455, 16.66485977172863],
        [82.25958251953153, 16.664861679077205],
        [82.25958251953153, 16.66430473327648],
        [82.25902557373075, 16.66430473327648],
        [82.25902557373075, 16.66402626037609],
        [82.2587509155274, 16.664028167724666],
        [82.2587509155274, 16.663749694824276],
        [82.2584686279298, 16.663749694824276],
        [82.2584686279298, 16.66345977783203],
        [82.25819396972662, 16.663473129272518],
        [82.25819396972662, 16.663194656372127],
        [82.25791931152338, 16.663194656372127],
        [82.25791931152338, 16.662916183471737],
        [82.25736236572283, 16.662918090820312],
        [82.25736236572283, 16.66236114501953],
        [82.2570724487307, 16.66236114501953],
        [82.2570724487307, 16.662080764770565],
        [82.25680541992205, 16.66208267211914],
        [82.25680541992205, 16.661806106567383],
        [82.25653076171903, 16.661809921264705],
        [82.25653076171903, 16.661529541015568],
        [82.2562484741211, 16.661529541015568],
        [82.2562484741211, 16.66097068786621],
        [82.25569152832031, 16.660972595214787],
        [82.25569152832031, 16.660694122314453],
        [82.2554168701173, 16.660694122314453],
        [82.2554168701173, 16.66013908386242],
        [82.25514221191412, 16.660140991210994],
        [82.25514221191412, 16.65903091430664],
        [82.25485992431635, 16.65903091430664],
        [82.25485992431635, 16.658750534057674],
        [82.25569152832031, 16.658750534057674],
        [82.25569152832031, 16.658470153808707],
        [82.25485992431635, 16.658472061157283],
        [82.25485992431635, 16.657917022705135],
        [82.25459289550804, 16.657920837402287],
        [82.25459289550804, 16.657360076904354],
        [82.2543029785158, 16.657363891601562],
        [82.2543029785158, 16.656803131103572],
        [82.25402832031256, 16.65681076049816],
        [82.25402832031256, 16.655700683593807],
        [82.2543029785158, 16.655700683593807],
        [82.2543029785158, 16.65514183044445],
        [82.25402832031256, 16.65514183044445],
        [82.25402832031256, 16.65431022644043],
        [82.25374603271501, 16.65431022644043],
        [82.25374603271501, 16.652639389038143],
        [82.25402832031256, 16.652639389038143],
        [82.25402832031256, 16.652080535888786],
        [82.2543029785158, 16.65208244323736],
        [82.2543029785158, 16.6512508392334],
        [82.25459289550804, 16.6512508392334],
        [82.25458526611351, 16.650693893432674],
        [82.25485992431635, 16.650693893432674],
        [82.25485992431635, 16.65013885498047],
        [82.25514221191412, 16.650140762329045],
        [82.25514221191412, 16.64958000183111],
        [82.2554168701173, 16.64958381652832],
        [82.2554168701173, 16.64902687072754],
        [82.25569152832031, 16.64903068542486],
        [82.25569152832031, 16.648750305175724],
        [82.25596618652372, 16.648750305175724],
        [82.25596618652372, 16.648479461669865],
        [82.2562484741211, 16.648479461669865],
        [82.2562484741211, 16.647920608520508],
        [82.25653076171903, 16.647920608520508],
        [82.25653076171903, 16.64764022827154],
        [82.25681304931658, 16.64764022827154],
        [82.25681304931658, 16.647359848022575],
        [82.25708007812506, 16.64736175537115],
        [82.25708007812506, 16.646806716919002],
        [82.25736236572283, 16.646806716919002],
        [82.25736236572283, 16.64652824401861],
        [82.25763702392601, 16.646530151367188],
        [82.25763702392601, 16.64624977111822],
        [82.25791931152338, 16.64624977111822],
        [82.25791931152338, 16.645690917968864],
        [82.25819396972662, 16.645694732666072],
        [82.25819396972662, 16.645416259765682],
        [82.2587509155274, 16.645420074462834],
        [82.2587509155274, 16.643472671508732],
        [82.25818634033209, 16.643472671508732],
        [82.25818634033209, 16.64430999755865],
        [82.25791931152338, 16.64430999755865],
        [82.25791931152338, 16.644029617309513],
        [82.25681304931658, 16.644029617309513],
        [82.25681304931658, 16.64430999755865],
        [82.25708007812506, 16.644306182861328],
        [82.25708007812506, 16.64458465576172],
        [82.25653076171903, 16.64458465576172],
        [82.25653076171903, 16.642639160156364],
        [82.25681304931658, 16.64264106750494],
        [82.25681304931658, 16.642080307006836],
        [82.2584686279298, 16.642084121704215],
        [82.2584686279298, 16.641805648803825],
        [82.25930786132812, 16.641809463500977],
        [82.25930786132812, 16.64153289794922],
        [82.25985717773455, 16.64153289794922],
        [82.25985717773455, 16.641252517700252],
        [82.2618026733399, 16.641252517700252],
        [82.2618026733399, 16.64153289794922],
        [82.26207733154325, 16.64153289794922],
        [82.26207733154325, 16.641809463500977],
        [82.26264190673857, 16.641805648803825],
        [82.26264190673857, 16.642084121704215],
        [82.26291656494158, 16.642084121704215],
        [82.26291656494158, 16.642362594604606],
        [82.26569366455107, 16.642362594604606],
        [82.26569366455107, 16.642084121704215],
        [82.26652526855486, 16.642084121704215],
        [82.26652526855486, 16.641805648803825],
        [82.26735687255865, 16.641809463500977],
        [82.26735687255865, 16.64153289794922],
        [82.26819610595714, 16.64153289794922],
        [82.26819610595714, 16.641252517700252],
        [82.26902770996094, 16.641252517700252],
        [82.26902770996094, 16.640970230102653],
        [82.2698593139649, 16.640970230102653],
        [82.2698593139649, 16.640693664550895],
        [82.27041625976585, 16.640693664550895],
        [82.27041625976585, 16.64041709899908],
        [82.27124786376964, 16.640422821045036],
        [82.27124786376964, 16.64013862609869],
        [82.27179718017607, 16.64013862609869],
        [82.27179718017607, 16.639858245849723],
        [82.27208709716797, 16.639858245849723],
        [82.27208709716797, 16.639579772949332],
        [82.27263641357439, 16.63958358764654],
        [82.27263641357439, 16.63930511474615],
        [82.27319335937517, 16.639310836792106],
        [82.27319335937517, 16.639028549194336],
        [82.27348327636724, 16.639028549194336],
        [82.27348327636724, 16.63874816894537],
        [82.27402496337896, 16.63874816894537],
        [82.27401733398443, 16.63846969604498],
        [82.27430725097668, 16.638471603393555],
        [82.27430725097668, 16.638195037841797],
        [82.2748565673831, 16.638200759887752],
        [82.2748565673831, 16.63792037963873],
        [82.27540588378935, 16.63792037963873],
        [82.27540588378935, 16.637638092041016],
        [82.27569580078142, 16.637638092041016],
        [82.27569580078142, 16.637359619140625],
        [82.2762527465822, 16.6373615264892],
        [82.2762527465822, 16.63708305358881],
        [82.27680206298822, 16.63708305358881],
        [82.27680206298822, 16.636806488037223],
        [82.27707672119146, 16.636800765991268],
        [82.277084350586, 16.636526107788086],
        [82.27735900878918, 16.63653755187994],
        [82.27735900878918, 16.636249542236442],
        [82.2781982421875, 16.636249542236442],
        [82.2781982421875, 16.635972976684684],
        [82.27847290039091, 16.635972976684684],
        [82.27847290039091, 16.635694503784293],
        [82.27874755859392, 16.635694503784293],
        [82.27874755859392, 16.635139465332088],
        [82.27902984619169, 16.635139465332088],
        [82.27902984619169, 16.6343097686767],
        [82.27929687500017, 16.6343097686767],
        [82.27929687500017, 16.634029388427734],
        [82.27958679199224, 16.634029388427734],
        [82.27958679199224, 16.633750915527344],
        [82.27986145019548, 16.633750915527344],
        [82.27986145019548, 16.63348007202154],
        [82.28041839599604, 16.63348007202154],
        [82.28041839599604, 16.63319015502941],
        [82.28069305419928, 16.633195877075195],
        [82.28069305419928, 16.632917404174805],
        [82.28098297119152, 16.632921218872184],
        [82.28098297119152, 16.63264083862299],
        [82.28124237060547, 16.63264083862299],
        [82.28124237060547, 16.632080078125057],
        [82.28152465820341, 16.632085800171012],
        [82.28152465820341, 16.631526947021484],
        [82.28180694580078, 16.631530761718807],
        [82.28180694580078, 16.630701065063477],
        [82.28208160400419, 16.630701065063477],
        [82.28208160400419, 16.630140304565487],
        [82.2823562622072, 16.630140304565487],
        [82.2823562622072, 16.629579544067383],
        [82.28263854980474, 16.62958145141596],
        [82.28263854980474, 16.62930488586437],
        [82.28291320800798, 16.62930488586437],
        [82.28291320800798, 16.629028320312557],
        [82.28320312500023, 16.629030227661133],
        [82.28320312500023, 16.6284694671632],
        [82.28347015380854, 16.6284694671632],
        [82.28347015380854, 16.627916336059627],
        [82.2837524414063, 16.62792015075678],
        [82.2837524414063, 16.62734985351574],
        [82.28402709960949, 16.627359390258846],
        [82.28402709960949, 16.626806259155273],
        [82.2843017578125, 16.626810073852596],
        [82.2843017578125, 16.626249313354492],
        [82.28457641601591, 16.626249313354492],
        [82.28457641601591, 16.62513923645031],
        [82.28485870361328, 16.62513923645031],
        [82.28485870361328, 16.624309539794922],
        [82.28514099121122, 16.624309539794922],
        [82.28514099121122, 16.623470306396598],
        [82.28541564941423, 16.623472213745174],
        [82.28541564941423, 16.622917175293026],
        [82.28569793701178, 16.622920989990178],
        [82.28569793701178, 16.622079849243278],
        [82.28597259521501, 16.622083663940487],
        [82.28597259521501, 16.62152862548828],
        [82.2862472534182, 16.62153816223139],
        [82.2862472534182, 16.620700836181697],
        [82.28652954101557, 16.620700836181697],
        [82.28652954101557, 16.620140075683537],
        [82.28679656982428, 16.620140075683537],
        [82.28679656982428, 16.61958122253418],
        [82.28708648681652, 16.619583129882756],
        [82.28708648681652, 16.619028091430778],
        [82.28736877441412, 16.619029998779354],
        [82.28736877441412, 16.618471145629826],
        [82.28763580322294, 16.61847305297863],
        [82.28763580322294, 16.617916107177848],
        [82.28791809082031, 16.617919921875],
        [82.28791809082031, 16.617639541626033],
        [82.28819274902372, 16.617639541626033],
        [82.28819274902372, 16.617080688476676],
        [82.28847503662126, 16.617084503173885],
        [82.28847503662126, 16.616527557373104],
        [82.28874969482428, 16.61652946472168],
        [82.28874969482428, 16.616252899169922],
        [82.28901672363298, 16.616252899169922],
        [82.28901672363298, 16.615690231323356],
        [82.28930664062523, 16.615692138671932],
        [82.28930664062523, 16.614582061767578],
        [82.28958129882807, 16.614582061767578],
        [82.28958129882807, 16.612638473510856],
        [82.2898559570313, 16.612638473510856],
        [82.2898559570313, 16.610969543457145],
        [82.29013824462902, 16.61097145080572],
        [82.29013824462902, 16.610416412353572],
        [82.29041290283209, 16.61041831970215],
        [82.29041290283209, 16.610139846801758],
        [82.29070281982433, 16.610139846801758],
        [82.29070281982433, 16.60985946655279],
        [82.29096984863281, 16.609861373901367],
        [82.29096984863281, 16.609582901000977],
        [82.29180908203153, 16.609582901000977],
        [82.29180908203153, 16.60930633544922],
        [82.29207611084001, 16.60931015014654],
        [82.29207611084001, 16.608749389648438],
        [82.29235839843773, 16.608749389648438],
        [82.29235839843773, 16.60764122009283],
        [82.29207611084001, 16.60764122009283],
        [82.29207611084001, 16.606531143188477],
        [82.29180908203153, 16.606531143188477],
        [82.29180908203153, 16.605970382690543],
        [82.29152679443376, 16.60597229003912],
        [82.29152679443376, 16.60569572448736],
        [82.29125213623075, 16.60569572448736],
        [82.29125213623075, 16.60541725158697],
        [82.29096984863281, 16.605421066284123],
        [82.29096984863281, 16.605140686035156],
        [82.29041290283209, 16.605140686035156],
        [82.29041290283209, 16.60486030578619],
        [82.29013824462902, 16.60486030578619],
        [82.29013824462902, 16.604579925537223],
        [82.28986358642584, 16.604583740234432],
        [82.28986358642584, 16.60430526733404],
        [82.28958129882807, 16.604310989379996],
        [82.28958129882807, 16.604030609130803],
        [82.28901672363298, 16.604030609130803],
        [82.28901672363298, 16.603750228881836],
        [82.28874969482428, 16.603750228881836],
        [82.28874969482428, 16.60346984863287],
        [82.28819274902372, 16.603473663330078],
        [82.28819274902372, 16.603195190429688],
        [82.28791809082031, 16.603200912475643],
        [82.28791809082031, 16.60292053222662],
        [82.28736877441412, 16.60292053222662],
        [82.28736877441412, 16.602359771728516],
        [82.28708648681652, 16.602363586425895],
        [82.28708648681652, 16.601909637451286],
        [82.28708648681652, 16.601804733276367],
        [82.2868041992188, 16.601804733276367],
        [82.2868041992188, 16.60125160217291],
        [82.28485870361328, 16.60125160217291],
        [82.28485870361328, 16.6015300750733],
        [82.2837524414063, 16.6015300750733],
        [82.2837524414063, 16.60125160217291],
        [82.28347015380854, 16.60125160217291],
        [82.28347015380854, 16.600969314575195],
        [82.28263854980474, 16.60097122192377],
        [82.28263854980474, 16.600694656372184],
        [82.28152465820341, 16.600694656372184],
        [82.28152465820341, 16.600416183471793],
        [82.28125, 16.600416183471793],
        [82.28125, 16.600694656372184],
        [82.28069305419928, 16.600694656372184],
        [82.28069305419928, 16.60097122192377],
        [82.2801361083985, 16.60097122192377],
        [82.2801361083985, 16.600694656372184],
        [82.27986145019548, 16.600694656372184],
        [82.27986145019548, 16.600416183471793],
        [82.27903747558622, 16.600416183471793],
        [82.27874755859392, 16.60042953491211],
        [82.27874755859392, 16.600141525268555],
        [82.2781982421875, 16.600141525268555],
        [82.2781982421875, 16.59958076477062],
        [82.27680206298822, 16.599582672119197],
        [82.27680206298822, 16.59930610656744],
        [82.27652740478538, 16.599311828613395],
        [82.27652740478538, 16.5990314483642],
        [82.2762527465822, 16.5990314483642],
        [82.2762527465822, 16.59874916076666],
        [82.27569580078142, 16.59874916076666],
        [82.27569580078142, 16.598470687866268],
        [82.27430725097668, 16.598472595214844],
        [82.27430725097668, 16.59819412231451],
        [82.27097320556646, 16.59819412231451],
        [82.27097320556646, 16.597917556762695],
        [82.26819610595714, 16.597917556762695],
        [82.26819610595714, 16.597639083862305],
        [82.26597595214844, 16.597639083862305],
        [82.26597595214844, 16.597368240356445],
        [82.26568603515653, 16.597368240356445],
        [82.26568603515653, 16.596530914306697],
        [82.26541900634766, 16.596530914306697],
        [82.26541900634766, 16.595420837402344],
        [82.26513671875028, 16.595420837402344],
        [82.26513671875028, 16.594301223754883],
        [82.26486206054693, 16.594306945800838],
        [82.26486206054693, 16.59375],
        [82.26457977294933, 16.59375],
        [82.26457977294933, 16.593200683593864],
        [82.26431274414085, 16.593200683593864],
        [82.26431274414085, 16.592639923095703],
        [82.26403045654314, 16.592639923095703],
        [82.26403045654314, 16.592081069946346],
        [82.26374816894554, 16.592084884643555],
        [82.26374816894554, 16.591527938842773],
        [82.26346588134783, 16.59152984619135],
        [82.26346588134783, 16.590972900390568],
        [82.26319122314459, 16.59097480773937],
        [82.26319122314459, 16.590415954589844],
        [82.26291656494158, 16.590419769287166],
        [82.26291656494158, 16.589862823486442],
        [82.26264190673857, 16.589862823486442],
        [82.26264190673857, 16.589309692382812],
        [82.26235961914062, 16.589309692382812],
        [82.26235961914062, 16.589031219482422],
        [82.26207733154325, 16.589031219482422],
        [82.26207733154325, 16.588752746582088],
        [82.2618026733399, 16.588752746582088],
        [82.2618026733399, 16.58847045898449],
        [82.26152801513683, 16.58847045898449],
        [82.26152801513683, 16.58819389343273],
        [82.26125335693365, 16.58819961547846],
        [82.26125335693365, 16.587921142578068],
        [82.26097106933588, 16.587921142578068],
        [82.26097106933588, 16.587642669677734],
        [82.26069641113304, 16.587642669677734],
        [82.26069641113304, 16.587360382080135],
        [82.26013946533209, 16.587360382080135],
        [82.26013946533209, 16.587081909179744],
        [82.25986480712908, 16.587083816528377],
        [82.25986480712908, 16.586805343627987],
        [82.26125335693365, 16.586805343627987],
        [82.26125335693365, 16.587083816528377],
        [82.26152801513683, 16.587081909179744],
        [82.26152801513683, 16.587360382080135],
        [82.2618026733399, 16.587360382080135],
        [82.2618026733399, 16.587642669677734],
        [82.26235961914062, 16.587642669677734],
        [82.26235961914062, 16.587921142578068],
        [82.26264190673857, 16.587921142578068],
        [82.26264190673857, 16.58819961547846],
        [82.26291656494158, 16.58819389343273],
        [82.26291656494158, 16.58847045898449],
        [82.26319122314459, 16.58847045898449],
        [82.26319122314459, 16.589580535888672],
        [82.26374816894554, 16.589580535888672],
        [82.26374816894554, 16.589862823486442],
        [82.26403045654314, 16.589862823486442],
        [82.26403045654314, 16.590419769287166],
        [82.26430511474615, 16.590415954589844],
        [82.26430511474615, 16.59097480773937],
        [82.26457977294933, 16.590972900390568],
        [82.26457977294933, 16.59152984619135],
        [82.26486206054693, 16.591527938842773],
        [82.26486206054693, 16.592361450195312],
        [82.26513671875028, 16.592361450195312],
        [82.26513671875028, 16.593471527099666],
        [82.26541900634766, 16.59346961975109],
        [82.26541900634766, 16.594030380249023],
        [82.26569366455107, 16.594028472900447],
        [82.26569366455107, 16.594583511352653],
        [82.26597595214844, 16.594579696655273],
        [82.26597595214844, 16.595140457153377],
        [82.26625061035185, 16.5951385498048],
        [82.26625061035185, 16.59569358825678],
        [82.26652526855486, 16.59569358825678],
        [82.26652526855486, 16.59597206115717],
        [82.2676391601562, 16.595970153808594],
        [82.2676391601562, 16.59625053405756],
        [82.2687530517581, 16.59625053405756],
        [82.2687530517581, 16.596530914306697],
        [82.2706909179687, 16.596530914306697],
        [82.2706909179687, 16.596807479858512],
        [82.27291870117193, 16.596805572509766],
        [82.27291870117193, 16.597084045410156],
        [82.2748565673831, 16.597080230712947],
        [82.2748565673831, 16.597368240356445],
        [82.27540588378935, 16.597368240356445],
        [82.27540588378935, 16.597639083862305],
        [82.2762527465822, 16.597639083862305],
        [82.2762527465822, 16.597917556762695],
        [82.27735900878918, 16.597917556762695],
        [82.27735900878918, 16.59819412231451],
        [82.2781982421875, 16.59819412231451],
        [82.2781982421875, 16.598472595214844],
        [82.27874755859392, 16.598470687866268],
        [82.27874755859392, 16.59874916076666],
        [82.27986145019548, 16.59874916076666],
        [82.27986145019548, 16.5990314483642],
        [82.28041839599604, 16.5990314483642],
        [82.28041839599604, 16.599311828613395],
        [82.28069305419928, 16.59930610656744],
        [82.28069305419928, 16.599582672119197],
        [82.28124237060547, 16.59958076477062],
        [82.28124237060547, 16.599859237671012],
        [82.2823562622072, 16.599859237671012],
        [82.2823562622072, 16.600141525268555],
        [82.28347015380854, 16.600141525268555],
        [82.28347015380854, 16.60042953491211],
        [82.28569793701178, 16.60042953491211],
        [82.28569793701178, 16.600141525268555],
        [82.28736877441412, 16.600141525268555],
        [82.28736877441412, 16.60042953491211],
        [82.28763580322294, 16.600416183471793],
        [82.28763580322294, 16.600694656372184],
        [82.28778076171898, 16.600694656372184],
        [82.28874969482428, 16.600694656372184],
        [82.28874969482428, 16.60097122192377],
        [82.28930664062523, 16.600969314575195],
        [82.28930664062523, 16.60125160217291],
        [82.29013824462902, 16.60125160217291],
        [82.29013824462902, 16.6015300750733],
        [82.29069519042997, 16.601528167724723],
        [82.29069519042997, 16.601804733276367],
        [82.29152679443376, 16.601804733276367],
        [82.29152679443376, 16.602081298828125],
        [82.29208374023455, 16.602081298828125],
        [82.29208374023455, 16.602363586425895],
        [82.29264068603533, 16.602359771728516],
        [82.29264068603533, 16.602640151977482],
        [82.29402923583984, 16.602640151977482],
        [82.29402923583984, 16.602359771728516],
        [82.29458618164062, 16.602363586425895],
        [82.29458618164062, 16.601804733276367],
        [82.29486083984403, 16.601804733276367],
        [82.29486083984403, 16.60125160217291],
        [82.29514312744158, 16.60125160217291],
        [82.29513549804705, 16.600694656372184],
        [82.29541778564459, 16.600694656372184],
        [82.29541778564459, 16.59902763366705],
        [82.29569244384783, 16.5990314483642],
        [82.29569244384783, 16.59874916076666],
        [82.29596710205084, 16.59874916076666],
        [82.29596710205084, 16.598470687866268],
        [82.29652404785162, 16.598472595214844],
        [82.29652404785162, 16.59819412231451],
        [82.29680633544933, 16.59819412231451],
        [82.29680633544933, 16.597917556762695],
        [82.29708099365234, 16.597917556762695],
        [82.29708099365234, 16.597368240356445],
        [82.29736328125028, 16.597368240356445],
        [82.29736328125028, 16.596807479858512],
        [82.29763793945312, 16.596807479858512],
        [82.29763793945312, 16.596530914306697],
        [82.29792022705107, 16.596530914306697],
        [82.29792022705107, 16.595689773559627],
        [82.29819488525408, 16.59569358825678],
        [82.29819488525408, 16.594861984252987],
        [82.29846954345709, 16.594861984252987],
        [82.29846954345709, 16.594028472900447],
        [82.29875183105486, 16.594030380249023],
        [82.29875183105486, 16.59346961975109],
        [82.29902648925804, 16.593471527099666],
        [82.29902648925804, 16.593088150024528],
        [82.29902648925804, 16.592638015747127],
        [82.29930877685541, 16.592639923095703],
        [82.29930877685541, 16.591810226440487],
        [82.29957580566412, 16.591810226440487],
        [82.29957580566412, 16.590419769287166],
        [82.29930877685541, 16.590419769287166],
        [82.29930877685541, 16.589309692382812],
        [82.29902648925804, 16.589309692382812],
        [82.29902648925804, 16.587081909179744],
        [82.29875183105486, 16.587083816528377],
        [82.29875183105486, 16.586805343627987],
        [82.29846954345709, 16.586811065673942],
        [82.29846954345709, 16.586248397827205],
        [82.29818725585955, 16.586248397827205],
        [82.29818725585955, 16.585969924926815],
        [82.29763793945312, 16.58597183227539],
        [82.29763793945312, 16.585695266723633],
        [82.29736328125028, 16.585699081420955],
        [82.29736328125028, 16.58346939086914],
        [82.29708099365234, 16.583471298217717],
        [82.29708099365234, 16.58319473266613],
        [82.29680633544933, 16.58319473266613],
        [82.29680633544933, 16.58291625976574],
        [82.29651641845709, 16.582918167114315],
        [82.29651641845709, 16.582639694213924],
        [82.29624176025402, 16.582639694213924],
        [82.29624176025402, 16.582361221313533],
        [82.29569244384783, 16.582361221313533],
        [82.29569244384783, 16.582080841064567],
        [82.29541778564459, 16.582082748413143],
        [82.29541778564459, 16.581806182861385],
        [82.29514312744158, 16.58180809020996],
        [82.29514312744158, 16.58152961730957],
        [82.29486083984403, 16.58152961730957],
        [82.29486083984403, 16.580970764160213],
        [82.29458618164062, 16.58097267150879],
        [82.29458618164062, 16.580802917480582],
        [82.29458618164062, 16.580694198608455],
        [82.29430389404325, 16.580694198608455],
        [82.29430389404325, 16.57986068725586],
        [82.29402923583984, 16.57986068725586],
        [82.29402923583984, 16.579299926757926],
        [82.29374694824247, 16.57930564880371],
        [82.29374694824247, 16.57902717590332],
        [82.29402923583984, 16.579030990600643],
        [82.29402923583984, 16.578750610351506],
        [82.29374694824247, 16.578750610351506],
        [82.29374694824247, 16.577360153198356],
        [82.29347229003912, 16.577362060546932],
        [82.29347229003912, 16.576805114746207],
        [82.29319763183588, 16.576810836791935],
        [82.29319763183588, 16.57653045654297],
        [82.29292297363304, 16.57653045654297],
        [82.29292297363304, 16.576259613037166],
        [82.29264068603533, 16.576259613037166],
        [82.29264068603533, 16.57570075988781],
        [82.29235839843773, 16.57570075988781],
        [82.29235839843773, 16.575420379638615],
        [82.29207611084001, 16.575420379638615],
        [82.29207611084001, 16.574859619140682],
        [82.29125213623075, 16.574859619140682],
        [82.29125213623075, 16.57458114624029],
        [82.29096984863281, 16.57458114624029],
        [82.29096984863281, 16.574029922485295],
        [82.29070281982433, 16.574031829834098],
        [82.29070281982433, 16.57346916198736],
        [82.29041290283209, 16.573471069335938],
        [82.29041290283209, 16.57319450378418],
        [82.29013824462902, 16.573200225830078],
        [82.29013824462902, 16.57264137268072],
        [82.2898559570313, 16.57264137268072],
        [82.2898559570313, 16.572080612182617],
        [82.28958129882807, 16.572082519531193],
        [82.28958129882807, 16.5712509155274],
        [82.28930664062523, 16.5712509155274],
        [82.28930664062523, 16.570970535278434],
        [82.28902435302751, 16.57097244262701],
        [82.28902435302751, 16.570693969726676],
        [82.28847503662126, 16.570693969726676],
        [82.28847503662126, 16.57041740417486],
        [82.28819274902372, 16.570421218872013],
        [82.28819274902372, 16.570140838623047],
        [82.28736114501959, 16.57013893127447],
        [82.28736114501959, 16.570693969726676],
        [82.28708648681652, 16.570690155029297],
        [82.28708648681652, 16.5712509155274],
        [82.28679656982428, 16.5712509155274],
        [82.28679656982428, 16.571527481079215],
        [82.28652954101557, 16.571527481079215],
        [82.28652954101557, 16.572082519531193],
        [82.2862472534182, 16.572080612182617],
        [82.2862472534182, 16.57264137268072],
        [82.28457641601591, 16.57264137268072],
        [82.28457641601591, 16.572359085083008],
        [82.28291320800798, 16.572359085083008],
        [82.28291320800798, 16.572080612182617],
        [82.28152465820341, 16.572082519531193],
        [82.28152465820341, 16.571805953979606],
        [82.28069305419928, 16.571809768676758],
        [82.28069305419928, 16.571527481079215],
        [82.27874755859392, 16.571527481079215],
        [82.27874755859392, 16.5712509155274],
        [82.27791595459013, 16.5712509155274],
        [82.27791595459013, 16.570970535278434],
        [82.27764129638678, 16.57097244262701],
        [82.27764129638678, 16.570693969726676],
        [82.27735900878918, 16.570693969726676],
        [82.27735900878918, 16.57041740417486],
        [82.27680206298822, 16.570421218872013],
        [82.27680206298822, 16.570140838623047],
        [82.27597045898443, 16.570140838623047],
        [82.27597045898443, 16.56986045837408],
        [82.27540588378935, 16.56986045837408],
        [82.27540588378935, 16.569311141967887],
        [82.2748565673831, 16.569311141967887],
        [82.2748565673831, 16.569030761718693],
        [82.27430725097668, 16.569030761718693],
        [82.27430725097668, 16.568750381469727],
        [82.27401733398443, 16.568750381469727],
        [82.27401733398443, 16.568201065063533],
        [82.27375030517572, 16.568201065063533],
        [82.27375030517572, 16.56792068481451],
        [82.27348327636724, 16.56792068481451],
        [82.27348327636724, 16.567640304565373],
        [82.27319335937517, 16.567640304565373],
        [82.27319335937517, 16.567359924316406],
        [82.27263641357439, 16.567361831664982],
        [82.27263641357439, 16.56708335876459],
        [82.27208709716797, 16.56708335876459],
        [82.27208709716797, 16.566806793213004],
        [82.27153015136724, 16.566806793213004],
        [82.27153015136724, 16.566528320312614],
        [82.27097320556646, 16.56653022766119],
        [82.27097320556646, 16.566249847412223],
        [82.27041625976585, 16.566249847412223],
        [82.27041625976585, 16.565969467163086],
        [82.2698593139649, 16.565971374511662],
        [82.2698593139649, 16.565694808960075],
        [82.26958465576189, 16.565694808960075],
        [82.26958465576189, 16.565416336059684],
        [82.26930236816435, 16.565420150756836],
        [82.26930236816435, 16.56513977050787],
        [82.26847076416016, 16.56513977050787],
        [82.26847076416016, 16.564859390258903],
        [82.26819610595714, 16.56486129760748],
        [82.26819610595714, 16.56458473205572],
        [82.26791381835943, 16.56458473205572],
        [82.26791381835943, 16.56402778625494],
        [82.26847076416016, 16.564029693603516],
        [82.26847076416016, 16.563751220703125],
        [82.2687530517581, 16.563751220703125],
        [82.2687530517581, 16.563472747802734],
        [82.26958465576189, 16.563474655151367],
        [82.26958465576189, 16.5631942749024],
        [82.2706909179687, 16.5631942749024],
        [82.2706909179687, 16.56291580200201],
        [82.27097320556646, 16.562919616699162],
        [82.27097320556646, 16.56264114379877],
        [82.27124786376964, 16.56264114379877],
        [82.27124786376964, 16.56236267089838],
        [82.27153015136724, 16.56236267089838],
        [82.27153015136724, 16.562080383300838],
        [82.2718048095706, 16.562084197998047],
        [82.2718048095706, 16.561805725097656],
        [82.27208709716797, 16.56180953979498],
        [82.27208709716797, 16.561531066894588],
        [82.27236175537138, 16.561531066894588],
        [82.27236175537138, 16.560970306396484],
        [82.27263641357439, 16.560970306396484],
        [82.27263641357439, 16.560417175293082],
        [82.27291870117193, 16.560422897338867],
        [82.27291870117193, 16.560138702392692],
        [82.27348327636724, 16.560138702392692],
        [82.27348327636724, 16.559858322143555],
        [82.27458190917974, 16.559858322143555],
        [82.27458190917974, 16.560138702392692],
        [82.2748565673831, 16.560138702392692],
        [82.2748565673831, 16.560422897338867],
        [82.27597045898443, 16.560422897338867],
        [82.27597045898443, 16.560138702392692],
        [82.27902984619169, 16.560138702392692],
        [82.27902984619169, 16.560422897338867],
        [82.2793045043947, 16.560417175293082],
        [82.2793045043947, 16.56069374084467],
        [82.28041839599604, 16.56069374084467],
        [82.28041839599604, 16.560970306396484],
        [82.28069305419928, 16.560970306396484],
        [82.28069305419928, 16.561260223388786],
        [82.28098297119152, 16.561260223388786],
        [82.28098297119152, 16.56180953979498],
        [82.28125, 16.561805725097656],
        [82.28125, 16.562084197998047],
        [82.28180694580078, 16.562080383300838],
        [82.28180694580078, 16.56236267089838],
        [82.2823562622072, 16.56236267089838],
        [82.2823562622072, 16.56264114379877],
        [82.2831954956057, 16.562639236450195],
        [82.2831954956057, 16.5631942749024],
        [82.2837524414063, 16.5631942749024],
        [82.2837524414063, 16.56291580200201],
        [82.28457641601591, 16.562919616699162],
        [82.28457641601591, 16.56264114379877],
        [82.28652954101557, 16.56264114379877],
        [82.28652954101557, 16.562919616699162],
        [82.2868041992188, 16.56291580200201],
        [82.2868041992188, 16.5631942749024],
        [82.28736114501959, 16.5631942749024],
        [82.28736114501959, 16.563474655151367],
        [82.28763580322294, 16.563472747802734],
        [82.28763580322294, 16.563751220703125],
        [82.28819274902372, 16.563751220703125],
        [82.28819274902372, 16.564029693603516],
        [82.2885131835938, 16.564029693603516],
        [82.29041290283209, 16.564029693603516],
        [82.29041290283209, 16.563751220703125],
        [82.29235839843773, 16.563751220703125],
        [82.29235839843773, 16.564029693603516],
        [82.29264068603533, 16.564029693603516],
        [82.29264068603533, 16.564319610595817],
        [82.29402923583984, 16.56430625915533],
        [82.29402923583984, 16.56458473205572],
        [82.29430389404325, 16.56458473205572],
        [82.29430389404325, 16.56486129760748],
        [82.29486083984403, 16.564859390258903],
        [82.29486083984403, 16.56513977050787],
        [82.29514312744158, 16.56513977050787],
        [82.29514312744158, 16.565420150756836],
        [82.29569244384783, 16.565416336059684],
        [82.29569244384783, 16.565971374511662],
        [82.29541778564459, 16.565971374511662],
        [82.29541778564459, 16.566806793213004],
        [82.29736328125028, 16.566806793213004],
        [82.29736328125028, 16.56708335876459],
        [82.29792022705107, 16.56708335876459],
        [82.29792022705107, 16.566806793213004],
        [82.29819488525408, 16.566806793213004],
        [82.29819488525408, 16.566528320312614],
        [82.29875183105486, 16.566528320312614],
        [82.29875183105486, 16.566806793213004],
        [82.29902648925804, 16.56680107116705],
        [82.29902648925804, 16.567640304565373],
        [82.29957580566412, 16.567640304565373],
        [82.29957580566412, 16.56792068481451],
        [82.30042266845732, 16.56792068481451],
        [82.30042266845732, 16.568201065063533],
        [82.30097198486357, 16.568201065063533],
        [82.30097198486357, 16.56792068481451],
        [82.30179595947283, 16.56792068481451],
        [82.30179595947283, 16.568201065063533],
        [82.30208587646507, 16.568195343017578],
        [82.30208587646507, 16.568471908569336],
        [82.30263519287115, 16.568471908569336],
        [82.30263519287115, 16.568750381469727],
        [82.30291748046886, 16.568750381469727],
        [82.30291748046886, 16.569030761718693],
        [82.30319213867193, 16.569030761718693],
        [82.30319213867193, 16.568750381469727],
        [82.30596923828136, 16.568750381469727],
        [82.30596923828136, 16.569030761718693],
        [82.3070831298831, 16.56902694702154],
        [82.3070831298831, 16.56921005249029],
        [82.3070831298831, 16.570693969726676],
        [82.30680847167969, 16.570693969726676],
        [82.30680847167969, 16.57097244262701],
        [82.30652618408232, 16.570970535278434],
        [82.30652618408232, 16.571527481079215],
        [82.30680847167969, 16.571527481079215],
        [82.30680847167969, 16.572082519531193],
        [82.30652618408232, 16.572080612182617],
        [82.30652618408232, 16.57264137268072],
        [82.30625152587896, 16.57264137268072],
        [82.30625152587896, 16.573749542236328],
        [82.30596923828136, 16.573749542236328],
        [82.30596923828136, 16.57394027709961],
        [82.30596923828136, 16.57458114624029],
        [82.30652618408232, 16.57458114624029],
        [82.30652618408232, 16.57430648803711],
        [82.3070831298831, 16.574300765991325],
        [82.3070831298831, 16.574029922485295],
        [82.30764007568388, 16.574031829834098],
        [82.30764007568388, 16.573749542236328],
        [82.30847167968767, 16.573749542236328],
        [82.30847167968767, 16.57349014282238],
        [82.30863189697277, 16.573484420776424],
        [82.30902862548822, 16.573471069335938],
        [82.30902862548822, 16.573320388793945],
        [82.30902862548822, 16.57291603088379],
        [82.30959320068371, 16.572921752929744],
        [82.30959320068371, 16.572359085083008],
        [82.30986022949219, 16.572359085083008],
        [82.30986022949219, 16.572080612182617],
        [82.31014251709013, 16.572082519531193],
        [82.31014251709013, 16.571527481079215],
        [82.31041717529297, 16.571527481079215],
        [82.31041717529297, 16.570690155029297],
        [82.31069183349638, 16.570693969726676],
        [82.31069183349638, 16.57041740417486],
        [82.31096649169939, 16.570421218872013],
        [82.31096649169939, 16.569580078125114],
        [82.31069183349638, 16.569583892822322],
        [82.31069183349638, 16.568750381469727],
        [82.31041717529297, 16.568750381469727],
        [82.31041717529297, 16.568201065063533],
        [82.31014251709013, 16.568201065063533],
        [82.31014251709013, 16.56792068481451],
        [82.30986022949219, 16.56792068481451],
        [82.30986022949219, 16.567640304565373],
        [82.30847167968767, 16.567630767822266],
        [82.30847167968767, 16.567361831664982],
        [82.30791473388689, 16.567361831664982],
        [82.30791473388689, 16.56708335876459],
        [82.3070831298831, 16.56708335876459],
        [82.3070831298831, 16.566806793213004],
        [82.30596923828136, 16.566806793213004],
        [82.30596923828136, 16.566528320312614],
        [82.30541229248041, 16.56653022766119],
        [82.30541229248041, 16.566249847412223],
        [82.30486297607439, 16.566249847412223],
        [82.30486297607439, 16.565969467163086],
        [82.3040313720706, 16.565971374511662],
        [82.3040313720706, 16.565694808960075],
        [82.30319213867193, 16.565694808960075],
        [82.30319213867193, 16.565416336059684],
        [82.30208587646507, 16.565420150756836],
        [82.30208587646507, 16.56513977050787],
        [82.30179595947283, 16.56513977050787],
        [82.30179595947283, 16.564859390258903],
        [82.30152893066412, 16.56486129760748],
        [82.30152893066412, 16.56458473205572],
        [82.30069732666016, 16.56458473205572],
        [82.30069732666016, 16.56430625915533],
        [82.30014038085943, 16.564319610595817],
        [82.30014038085943, 16.564029693603516],
        [82.29957580566412, 16.564029693603516],
        [82.29957580566412, 16.563751220703125],
        [82.29846954345709, 16.563751220703125],
        [82.29846954345709, 16.563472747802734],
        [82.29736328125028, 16.563474655151367],
        [82.29736328125028, 16.5631942749024],
        [82.29680633544933, 16.5631942749024],
        [82.29680633544933, 16.56291580200201],
        [82.29624176025402, 16.562919616699162],
        [82.29624176025402, 16.56264114379877],
        [82.29596710205084, 16.56264114379877],
        [82.29596710205084, 16.56236267089838],
        [82.29569244384783, 16.56236267089838],
        [82.29569244384783, 16.562080383300838],
        [82.29541778564459, 16.562084197998047],
        [82.29541778564459, 16.561805725097656],
        [82.29486083984403, 16.56180953979498],
        [82.29486083984403, 16.561531066894588],
        [82.29458618164062, 16.561531066894588],
        [82.29458618164062, 16.561260223388786],
        [82.29319763183588, 16.561260223388786],
        [82.29319763183588, 16.560970306396484],
        [82.29264068603533, 16.560970306396484],
        [82.29264068603533, 16.56069374084467],
        [82.29235839843773, 16.56069374084467],
        [82.29235839843773, 16.560417175293082],
        [82.29207611084001, 16.560422897338867],
        [82.29207611084001, 16.560138702392692],
        [82.29152679443376, 16.560138702392692],
        [82.29152679443376, 16.559858322143555],
        [82.29096984863281, 16.559858322143555],
        [82.29096984863281, 16.559579849243164],
        [82.29069519042997, 16.559583663940543],
        [82.29069519042997, 16.559305191040153],
        [82.2898559570313, 16.55931091308588],
        [82.2898559570313, 16.559030532836914],
        [82.28930664062523, 16.559030532836914],
        [82.28930664062523, 16.55874824523937],
        [82.28874969482428, 16.55874824523937],
        [82.28874969482428, 16.55846977233898],
        [82.28791809082031, 16.558471679687557],
        [82.28791809082031, 16.5581951141358],
        [82.28736877441412, 16.558200836181754],
        [82.28736877441412, 16.55792045593256],
        [82.28695678710938, 16.55792045593256],
        [82.28679656982428, 16.55792045593256],
        [82.28678894042974, 16.557708740234432],
        [82.28652954101557, 16.557638168335018],
        [82.28652954101557, 16.5574893951416],
        [82.28652954101557, 16.557361602783203],
        [82.28617858886736, 16.557361602783203],
        [82.28569793701178, 16.557361602783203],
        [82.28569793701178, 16.557083129882812],
        [82.28544616699219, 16.557083129882812],
        [82.28402709960949, 16.557083129882812],
        [82.28402709960949, 16.55680465698248],
        [82.2831954956057, 16.55680465698248],
        [82.2831954956057, 16.556526184082088],
        [82.2823562622072, 16.556528091430664],
        [82.2823562622072, 16.556249618530273],
        [82.28041839599604, 16.556249618530273],
        [82.28041839599604, 16.555971145629883],
        [82.2781982421875, 16.55597305297846],
        [82.27819061279297, 16.555694580078125],
        [82.27791595459013, 16.555694580078125],
        [82.27791595459013, 16.555416107177734],
        [82.27764129638678, 16.555419921875057],
        [82.27764129638678, 16.555130004882812],
        [82.27735900878918, 16.55513954162609],
        [82.27735900878918, 16.55486106872553],
        [82.2748565673831, 16.55486106872553],
        [82.2748565673831, 16.554580688476562],
        [82.27402496337896, 16.55458259582514],
        [82.27402496337896, 16.554307937622127],
        [82.27375030517572, 16.554309844970703],
        [82.27375030517572, 16.554029464721737],
        [82.27319335937517, 16.554029464721737],
        [82.27319335937517, 16.553750991821346],
        [82.27153015136724, 16.553750991821346],
        [82.27153015136724, 16.55347061157238],
        [82.27124786376964, 16.553472518920955],
        [82.27124786376964, 16.553195953369197],
        [82.27041625976585, 16.553195953369197],
        [82.27041625976585, 16.552917480468807],
        [82.26902770996094, 16.552919387817383],
        [82.26902770996094, 16.552640914916992],
        [82.26819610595714, 16.552640914916992],
        [82.26819610595714, 16.552360534668026],
        [82.26652526855486, 16.552360534668026],
        [82.26652526855486, 16.552085876464844],
        [82.26541900634766, 16.552085876464844],
        [82.26541900634766, 16.551805496215877],
        [82.26457977294933, 16.551811218261832],
        [82.26457977294933, 16.55153083801264],
        [82.26403045654314, 16.55153083801264],
        [82.26403045654314, 16.551250457763672],
        [82.26319122314459, 16.551250457763672],
        [82.26319122314459, 16.550973892211914],
        [82.26208496093778, 16.550973892211914],
        [82.26208496093778, 16.550695419311523],
        [82.2604064941408, 16.55068969726574],
        [82.26041412353533, 16.550416946411133],
        [82.25985717773455, 16.550420761108455],
        [82.25985717773455, 16.550140380859318],
        [82.25903320312528, 16.550140380859318],
        [82.25903320312528, 16.549861907958928],
        [82.25819396972662, 16.54986381530773],
        [82.25819396972662, 16.549583435058537],
        [82.25680541992205, 16.549583435058537],
        [82.25680541992205, 16.54930305480957],
        [82.2562484741211, 16.549320220947266],
        [82.2562484741211, 16.549030303955135],
        [82.2554168701173, 16.549030303955135],
        [82.2554168701173, 16.548751831054744],
        [82.25458526611351, 16.548751831054744],
        [82.25458526611351, 16.54847335815441],
        [82.25421142578148, 16.54847335815441],
        [82.25375366210955, 16.54847335815441],
        [82.25375366210955, 16.54819488525402],
        [82.25291442871122, 16.54819488525402],
        [82.25291442871122, 16.54791641235363],
        [82.25208282470709, 16.54792022705078],
        [82.25208282470709, 16.54764175415039],
        [82.25125885009783, 16.54764175415039],
        [82.25125885009783, 16.547359466552848],
        [82.24986267089872, 16.547359466552848],
        [82.24986267089872, 16.546806335449276],
        [82.2493133544923, 16.546810150146428],
        [82.2493133544923, 16.546531677246037],
        [82.24874877929699, 16.546531677246037],
        [82.24874877929699, 16.54626274108898],
        [82.24736022949224, 16.54626274108898],
        [82.24736022949224, 16.545970916748104],
        [82.24652862548828, 16.54597282409668],
        [82.24652862548828, 16.545694351196346],
        [82.24597167968756, 16.545694351196346],
        [82.24597167968756, 16.545415878295955],
        [82.24540710449224, 16.54542160034191],
        [82.24540710449224, 16.54513931274414],
        [82.24430847167974, 16.54513931274414],
        [82.24430847167974, 16.54486083984375],
        [82.24375152587919, 16.54486083984375],
        [82.24375152587919, 16.544580459594783],
        [82.24263763427746, 16.544584274291992],
        [82.24263763427746, 16.5443058013916],
        [82.24180603027366, 16.544309616088924],
        [82.24180603027366, 16.544031143188533],
        [82.24124908447271, 16.544031143188533],
        [82.24124908447271, 16.543750762939396],
        [82.24069213867216, 16.543750762939396],
        [82.24069213867216, 16.54347038269043],
        [82.23986053466797, 16.543472290039006],
        [82.23986053466797, 16.543193817138615],
        [82.23958587646496, 16.543193817138615],
        [82.23958587646496, 16.542917251587028],
        [82.23874664306646, 16.54292106628418],
        [82.23874664306646, 16.542640686035213],
        [82.23819732666021, 16.542640686035213],
        [82.23819732666021, 16.542360305786246],
        [82.2379226684572, 16.542360305786246],
        [82.2379226684572, 16.542640686035213],
        [82.23764038085966, 16.542640686035213],
        [82.23764038085966, 16.542360305786246],
        [82.23735809326189, 16.542360305786246],
        [82.23735809326189, 16.54207992553711],
        [82.2365264892581, 16.54208374023449],
        [82.2365264892581, 16.541805267334098],
        [82.23568725585943, 16.541810989379826],
        [82.23568725585943, 16.54153060913086],
        [82.2345809936524, 16.54153060913086],
        [82.2345809936524, 16.541250228881893],
        [82.23401641845732, 16.541250228881893],
        [82.23401641845732, 16.540969848632926],
        [82.2334747314456, 16.540971755981502],
        [82.2334747314456, 16.540695190429744],
        [82.23264312744146, 16.5407009124757],
        [82.23264312744146, 16.540420532226506],
        [82.23208618164085, 16.540420532226506],
        [82.23208618164085, 16.540130615234432],
        [82.23124694824236, 16.540138244628963],
        [82.23124694824236, 16.53986167907715],
        [82.23097229003935, 16.53986167907715],
        [82.23097229003935, 16.539583206176758],
        [82.2293090820312, 16.539583206176758],
        [82.2293090820312, 16.539304733276424],
        [82.22875213623064, 16.539304733276424],
        [82.22875213623064, 16.53902816772461],
        [82.22820281982439, 16.539030075073185],
        [82.22820281982439, 16.53874969482422],
        [82.22791290283232, 16.53874969482422],
        [82.22791290283232, 16.538471221923828],
        [82.2276382446289, 16.538473129272404],
        [82.2276382446289, 16.53819465637207],
        [82.22736358642607, 16.53819465637207],
        [82.22736358642607, 16.538473129272404],
        [82.22680664062511, 16.538473129272404],
        [82.22680664062511, 16.53819465637207],
        [82.22624969482416, 16.53819465637207],
        [82.22624969482416, 16.53791618347168],
        [82.22541809082037, 16.53791618347168],
        [82.22541809082037, 16.53819465637207],
        [82.22513580322283, 16.53819465637207],
        [82.22513580322283, 16.53791618347168],
        [82.2242965698245, 16.537919998169002],
        [82.2242965698245, 16.537639617920036],
        [82.22402954101562, 16.537639617920036],
        [82.22402954101562, 16.537361145019474],
        [82.22374725341825, 16.537361145019474],
        [82.22374725341825, 16.537090301513615],
        [82.22319793701166, 16.537090301513615],
        [82.22319793701166, 16.53680992126465],
        [82.22235870361351, 16.53680992126465],
        [82.22235870361351, 16.536529541015682],
        [82.22152709960955, 16.536529541015682],
        [82.22152709960955, 16.53625106811529],
        [82.22125244140653, 16.53625106811529],
        [82.22125244140653, 16.535690307617188],
        [82.2209701538086, 16.535692214965763],
        [82.2209701538086, 16.535417556762752],
        [82.21958160400385, 16.535419464111328],
        [82.21958160400385, 16.53514289855957],
        [82.21901702880876, 16.53514289855957],
        [82.21901702880876, 16.534862518310547],
        [82.21875, 16.534862518310547],
        [82.21875, 16.534580230713004],
        [82.2181930541995, 16.534584045410213],
        [82.2181930541995, 16.534305572509822],
        [82.21736145019537, 16.534309387206974],
        [82.21736145019537, 16.534032821655387],
        [82.21624755859398, 16.534032821655387],
        [82.21624755859398, 16.533752441406193],
        [82.21569824218756, 16.533752441406193],
        [82.21569061279303, 16.533468246460075],
        [82.21541595459001, 16.53347015380865],
        [82.21541595459001, 16.533193588256836],
        [82.215141296387, 16.533193588256836],
        [82.215141296387, 16.532917022705078],
        [82.21457672119169, 16.5329208374024],
        [82.21457672119169, 16.532638549804688],
        [82.21430206298828, 16.532638549804688],
        [82.21430206298828, 16.5329208374024],
        [82.21347045898432, 16.5329208374024],
        [82.21347045898432, 16.532638549804688],
        [82.212905883789, 16.532638549804688],
        [82.212905883789, 16.53235816955572],
        [82.21263885498053, 16.532360076904297],
        [82.21263885498053, 16.532083511352482],
        [82.21236419677751, 16.532083511352482],
        [82.21236419677751, 16.53180503845215],
        [82.21180725097656, 16.531810760498047],
        [82.21180725097656, 16.531528472900504],
        [82.21069335937506, 16.531528472900504],
        [82.21069335937506, 16.531248092651367],
        [82.21041870117182, 16.531248092651367],
        [82.21041870117182, 16.53096008300787],
        [82.20986175537126, 16.530971527099553],
        [82.20986175537126, 16.530694961547965],
        [82.20929718017595, 16.530700683593693],
        [82.20929718017595, 16.530420303344727],
        [82.20902252197271, 16.530420303344727],
        [82.20902252197271, 16.53013992309576],
        [82.20819091796875, 16.53013992309576],
        [82.20819091796875, 16.529859542846793],
        [82.20764160156256, 16.52986145019537],
        [82.20764160156256, 16.52958297729498],
        [82.20708465576178, 16.52958297729498],
        [82.20708465576178, 16.52902793884283],
        [82.20540618896513, 16.529029846191406],
        [82.20540618896513, 16.52874946594244],
        [82.20513916015625, 16.52874946594244],
        [82.20513916015625, 16.52847099304205],
        [82.20486450195341, 16.528472900390625],
        [82.20486450195341, 16.52819442749029],
        [82.20430755615246, 16.52819442749029],
        [82.20430755615246, 16.527917861938477],
        [82.20347595214866, 16.527919769287053],
        [82.20347595214866, 16.527639389038086],
        [82.20318603515642, 16.527639389038086],
        [82.20318603515642, 16.527360916137695],
        [82.20263671875017, 16.527360916137695],
        [82.20263671875017, 16.52708053588873],
        [82.20236206054716, 16.527082443237305],
        [82.20236206054716, 16.526805877685547],
        [82.20069122314447, 16.52680969238287],
        [82.20069122314447, 16.52653121948248],
        [82.20014953613298, 16.52653121948248],
        [82.20014953613298, 16.52625083923334],
        [82.19957733154314, 16.52625083923334],
        [82.19957733154314, 16.525421142578125],
        [82.19875335693388, 16.525421142578125],
        [82.19875335693388, 16.525150299072322],
        [82.19819641113293, 16.525150299072322],
        [82.19819641113293, 16.524860382080192],
        [82.19735717773443, 16.524860382080192],
        [82.19735717773443, 16.524580001831055],
        [82.19680786132818, 16.52458572387701],
        [82.19680786132818, 16.524305343628043],
        [82.19596862792986, 16.52431106567377],
        [82.19596862792986, 16.524030685424805],
        [82.19541931152344, 16.524030685424805],
        [82.19541931152344, 16.523750305175838],
        [82.19486236572271, 16.523750305175838],
        [82.19486236572271, 16.52346992492687],
        [82.19403076171892, 16.52347373962408],
        [82.19403076171892, 16.52319526672369],
        [82.19374847412132, 16.52320289611822],
        [82.19374847412132, 16.52292060852045],
        [82.19291687011736, 16.52292060852045],
        [82.19291687011736, 16.522640228271484],
        [82.1923599243164, 16.522640228271484],
        [82.1923599243164, 16.522361755371094],
        [82.19180297851568, 16.522363662719727],
        [82.19180297851568, 16.522081375122127],
        [82.1912460327149, 16.522092819213867],
        [82.1912460327149, 16.521802902221737],
        [82.19041442871111, 16.52180480957037],
        [82.19041442871111, 16.521528244018555],
        [82.18985748291033, 16.52153015136713],
        [82.18985748291033, 16.52125167846674],
        [82.1893081665039, 16.52125167846674],
        [82.1893081665039, 16.520969390869197],
        [82.18875122070318, 16.520969390869197],
        [82.18875122070318, 16.520694732666016],
        [82.1881942749024, 16.520694732666016],
        [82.1881942749024, 16.520416259765625],
        [82.18763732910179, 16.520420074462947],
        [82.18763732910179, 16.520141601562557],
        [82.18708038330084, 16.520141601562557],
        [82.18708038330084, 16.519859313964844],
        [82.18653106689482, 16.519859313964844],
        [82.18653106689482, 16.519580841064453],
        [82.18624877929688, 16.51958274841303],
        [82.18624877929688, 16.519306182861442],
        [82.1859664916995, 16.519311904907227],
        [82.1859664916995, 16.519018173217717],
        [82.18541717529308, 16.51902770996105],
        [82.18541717529308, 16.51874923706066],
        [82.1851425170899, 16.51874923706066],
        [82.1851425170899, 16.51847076416027],
        [82.18402862548834, 16.518472671508846],
        [82.18402862548834, 16.518194198608512],
        [82.18319702148438, 16.518194198608512],
        [82.18319702148438, 16.517917633056697],
        [82.18290710449247, 16.517917633056697],
        [82.18290710449247, 16.517639160156307],
        [82.18208312988287, 16.517639160156307],
        [82.18208312988287, 16.517360687255916],
        [82.18152618408209, 16.517360687255916],
        [82.18152618408209, 16.51708030700695],
        [82.18096923828148, 16.51708412170416],
        [82.18096923828148, 16.516805648803768],
        [82.18068695068376, 16.51680946350092],
        [82.18068695068376, 16.516529083251953],
        [82.18013763427751, 16.516529083251953],
        [82.18013763427751, 16.516250610351562],
        [82.1798629760745, 16.516250610351562],
        [82.1798629760745, 16.51596069335949],
        [82.17930603027372, 16.515972137451172],
        [82.17930603027372, 16.51569366455078],
        [82.17874908447277, 16.51569366455078],
        [82.17874908447277, 16.515415191650447],
        [82.17846679687506, 16.515420913696346],
        [82.17846679687506, 16.51514053344721],
        [82.17791748046898, 16.51514053344721],
        [82.17791748046898, 16.514860153198242],
        [82.17736053466803, 16.514862060546818],
        [82.17736053466803, 16.514583587646428],
        [82.17708587646501, 16.514583587646428],
        [82.17708587646501, 16.514305114746094],
        [82.17679595947294, 16.514310836791992],
        [82.17679595947294, 16.514030456543026],
        [82.17652893066406, 16.514030456543026],
        [82.17652893066406, 16.51375007629406],
        [82.17597961425787, 16.51375007629406],
        [82.17597961425787, 16.514030456543026],
        [82.17514038085932, 16.514030456543026],
        [82.17514038085932, 16.51375007629406],
        [82.17457580566423, 16.51375007629406],
        [82.17457580566423, 16.513469696044922],
        [82.17430877685553, 16.513471603393498],
        [82.17430877685553, 16.51291656494152],
        [82.17402648925798, 16.512920379638672],
        [82.17402648925798, 16.512639999389705],
        [82.17318725585966, 16.512639999389705],
        [82.17318725585966, 16.51235961914074],
        [82.17263793945324, 16.51235961914074],
        [82.17263793945324, 16.512639999389705],
        [82.17208099365229, 16.512639999389705],
        [82.17208099365229, 16.51235961914074],
        [82.17152404785173, 16.512361526489315],
        [82.17152404785173, 16.511528015136776],
        [82.1712493896485, 16.51152992248535],
        [82.1712493896485, 16.511249542236385],
        [82.17096710205095, 16.511249542236385],
        [82.17096710205095, 16.510971069335994],
        [82.17069244384794, 16.510974884033203],
        [82.17069244384794, 16.510694503784237],
        [82.16986083984375, 16.510694503784237],
        [82.16986083984375, 16.510416030883846],
        [82.1692962646485, 16.510419845580998],
        [82.1692962646485, 16.510150909423942],
        [82.16847229003923, 16.510150909423942],
        [82.16847229003923, 16.509862899780217],
        [82.168197631836, 16.509862899780217],
        [82.168197631836, 16.509580612182674],
        [82.16764068603544, 16.509580612182674],
        [82.16764068603544, 16.509305953979492],
        [82.16707611084013, 16.509309768676815],
        [82.16707611084013, 16.509029388427848],
        [82.16652679443388, 16.509029388427848],
        [82.16652679443388, 16.50875282287609],
        [82.16596984863293, 16.50875282287609],
        [82.16596984863293, 16.50847053527832],
        [82.16569519042974, 16.50847053527832],
        [82.16569519042974, 16.50819206237793],
        [82.16541290283197, 16.50819206237793],
        [82.16541290283197, 16.50791740417492],
        [82.16458129882818, 16.50792121887207],
        [82.16458129882818, 16.507642745971737],
        [82.1640167236331, 16.507642745971737],
        [82.1640167236331, 16.507362365722713],
        [82.16347503662138, 16.507362365722713],
        [82.16347503662138, 16.50708389282238],
        [82.16263580322271, 16.50708389282238],
        [82.16263580322271, 16.50680541992199],
        [82.16236114501947, 16.506811141967717],
        [82.16236114501947, 16.50653076171875],
        [82.16208648681663, 16.50653076171875],
        [82.16208648681663, 16.506248474121207],
        [82.16124725341814, 16.506248474121207],
        [82.16124725341814, 16.50596809387207],
        [82.160140991211, 16.505971908569393],
        [82.160140991211, 16.505695343017635],
        [82.15957641601568, 16.505699157714787],
        [82.15957641601568, 16.505420684814396],
        [82.1590270996096, 16.505420684814396],
        [82.1590270996096, 16.505138397216854],
        [82.15838623046892, 16.505138397216854],
        [82.15820312500017, 16.505138397216854],
        [82.15820312500017, 16.504859924316463],
        [82.1579132080081, 16.50486183166504],
        [82.1579132080081, 16.50458335876465],
        [82.15763854980469, 16.50458335876465],
        [82.15763854980469, 16.504304885864315],
        [82.1568069458009, 16.504304885864315],
        [82.1568069458009, 16.5040283203125],
        [82.15625, 16.5040283203125],
        [82.15625, 16.50374984741211],
        [82.15569305419939, 16.50374984741211],
        [82.15569305419939, 16.503469467163143],
        [82.15430450439482, 16.50347137451172],
        [82.15430450439482, 16.50291633605957],
        [82.1540298461914, 16.502918243408317],
        [82.1540298461914, 16.502639770507926],
        [82.15319824218744, 16.502639770507926],
        [82.15319061279308, 16.50235939025879],
        [82.1529159545899, 16.502361297607365],
        [82.1529159545899, 16.502082824706974],
        [82.15264129638689, 16.502082824706974],
        [82.15264129638689, 16.501806259155387],
        [82.15207672119158, 16.50181007385254],
        [82.15207672119158, 16.501529693603572],
        [82.1518096923831, 16.501529693603572],
        [82.1518096923831, 16.501251220703182],
        [82.15125274658232, 16.501251220703182],
        [82.15125274658232, 16.50098037719738],
        [82.15040588378906, 16.50098037719738],
        [82.15040588378906, 16.500690460205078],
        [82.15013885498058, 16.500694274902457],
        [82.15013885498058, 16.500415802002067],
        [82.14930725097679, 16.50041961669922],
        [82.14930725097679, 16.500141143798828],
        [82.14848327636719, 16.500141143798828],
        [82.14848327636719, 16.49986076354986],
        [82.14791870117188, 16.49986076354986],
        [82.14791870117188, 16.499580383300895],
        [82.14653015136713, 16.499584197998104],
        [82.14653015136713, 16.499305725097713],
        [82.14597320556658, 16.499309539794865],
        [82.14597320556658, 16.499031066894474],
        [82.14514160156278, 16.499031066894474],
        [82.14514160156278, 16.498750686645508],
        [82.14457702636747, 16.498750686645508],
        [82.14457702636747, 16.49847030639654],
        [82.14430236816406, 16.498472213745117],
        [82.14430236816406, 16.49819564819336],
        [82.14375305175787, 16.49819564819336],
        [82.14375305175787, 16.497638702392578],
        [82.14235687255876, 16.497640609741154],
        [82.14235687255876, 16.49792098999029],
        [82.1415328979495, 16.49792098999029],
        [82.1415328979495, 16.497360229492188],
        [82.14125061035156, 16.497360229492188],
        [82.14125061035156, 16.49707984924322],
        [82.14097595214855, 16.497083663940373],
        [82.14097595214855, 16.49680519104004],
        [82.1406860351563, 16.496810913085938],
        [82.1406860351563, 16.49653053283697],
        [82.1404190063476, 16.49653053283697],
        [82.1404190063476, 16.496250152588004],
        [82.14013671875006, 16.496250152588004],
        [82.14013671875006, 16.495969772338867],
        [82.1393127441408, 16.495969772338867],
        [82.1393127441408, 16.49653053283697],
        [82.13903045654325, 16.49653053283697],
        [82.13903045654325, 16.495700836181584],
        [82.13846588134794, 16.495700836181584],
        [82.13846588134794, 16.495420455932617],
        [82.13819122314453, 16.495420455932617],
        [82.13819122314453, 16.49514007568365],
        [82.13681030273455, 16.49514007568365],
        [82.13681030273455, 16.494848251342773],
        [82.13652801513695, 16.494859695434684],
        [82.13652801513695, 16.494581222534293],
        [82.13625335693376, 16.494581222534293],
        [82.13625335693376, 16.494304656982536],
        [82.13569641113298, 16.494304656982536],
        [82.13569641113298, 16.49402809143072],
        [82.13513946533203, 16.494031906127873],
        [82.13513946533203, 16.493751525878906],
        [82.13402557373053, 16.493751525878906],
        [82.13402557373053, 16.49347114562994],
        [82.13346862792991, 16.49347114562994],
        [82.13346862792991, 16.49291610717779],
        [82.1329193115235, 16.492921829223746],
        [82.1329193115235, 16.492641448974553],
        [82.13236236572294, 16.492641448974553],
        [82.13236236572294, 16.49208068847662],
        [82.132080078125, 16.492082595825195],
        [82.132080078125, 16.491806030273438],
        [82.13168334960955, 16.491806030273438],
        [82.13014221191423, 16.491811752319393],
        [82.13014221191423, 16.491527557373047],
        [82.12985992431646, 16.491527557373047],
        [82.12985992431646, 16.491249084472656],
        [82.12930297851591, 16.491249084472656],
        [82.12930297851591, 16.490970611572266],
        [82.1290283203125, 16.49097251892084],
        [82.1290283203125, 16.49069404602045],
        [82.12819671630871, 16.49069404602045],
        [82.12819671630871, 16.490417480468864],
        [82.1270828247072, 16.490417480468864],
        [82.1270828247072, 16.49014091491705],
        [82.12680816650396, 16.49014091491705],
        [82.12680816650396, 16.489860534668082],
        [82.12652587890642, 16.489860534668082],
        [82.12652587890642, 16.489580154418945],
        [82.12569427490263, 16.489583969116325],
        [82.12569427490263, 16.489305496215934],
        [82.12487030029303, 16.489311218261662],
        [82.12487030029303, 16.48904037475586],
        [82.1245803833009, 16.48904037475586],
        [82.1245803833009, 16.48875045776373],
        [82.1240310668947, 16.48875045776373],
        [82.1240310668947, 16.488470077514762],
        [82.1237487792971, 16.488471984863338],
        [82.1237487792971, 16.48819541931158],
        [82.12264251709013, 16.488189697265625],
        [82.12264251709013, 16.48791694641119],
        [82.12209320068371, 16.48792076110834],
        [82.12209320068371, 16.487640380859375],
        [82.12124633789068, 16.487640380859375],
        [82.12124633789068, 16.48736000061041],
        [82.12097167968744, 16.487361907958984],
        [82.12097167968744, 16.487083435058594],
        [82.12041473388689, 16.487083435058594],
        [82.12041473388689, 16.486528396606445],
        [82.12014007568365, 16.48653030395502],
        [82.12014007568365, 16.485980987548828],
        [82.1195831298831, 16.485980987548828],
        [82.1195831298831, 16.485691070556697],
        [82.11875152587896, 16.485694885253906],
        [82.11875152587896, 16.485416412353516],
        [82.11791992187494, 16.485416412353516],
        [82.11791992187494, 16.485694885253906],
        [82.11763763427757, 16.485694885253906],
        [82.11763763427757, 16.485416412353516],
        [82.11680603027361, 16.485420227050838],
        [82.11680603027361, 16.48513984680187],
        [82.11541748046886, 16.48513984680187],
        [82.11541748046886, 16.486249923706055],
        [82.11514282226568, 16.486249923706055],
        [82.11514282226568, 16.48681068420416],
        [82.11486053466791, 16.486806869506836],
        [82.11486053466791, 16.487083435058594],
        [82.11458587646507, 16.487079620361442],
        [82.11458587646507, 16.487640380859375],
        [82.1142959594726, 16.487640380859375],
        [82.1142959594726, 16.48792076110834],
        [82.11402893066412, 16.48791694641119],
        [82.11402893066412, 16.488189697265625],
        [82.11374664306658, 16.48819541931158],
        [82.11374664306658, 16.488471984863338],
        [82.11264038085938, 16.488471984863338],
        [82.11264038085938, 16.48819541931158],
        [82.112358093262, 16.488189697265625],
        [82.112358093262, 16.4876384735108],
        [82.11207580566412, 16.487640380859375],
        [82.11207580566412, 16.48736000061041],
        [82.112358093262, 16.487361907958984],
        [82.112358093262, 16.485694885253906],
        [82.11264038085938, 16.485694885253906],
        [82.11264038085938, 16.485416412353516],
        [82.11293029785185, 16.485420227050838],
        [82.11293029785185, 16.484859466552734],
        [82.11319732666016, 16.48486137390131],
        [82.11319732666016, 16.484584808349723],
        [82.11347198486357, 16.484584808349723],
        [82.11347198486357, 16.484306335449332],
        [82.11402893066412, 16.484310150146484],
        [82.11402893066412, 16.48374938964855],
        [82.11374664306658, 16.48374938964855],
        [82.11374664306658, 16.483472824096737],
        [82.11347198486357, 16.48347473144537],
        [82.11347198486357, 16.483194351196403],
        [82.11291503906278, 16.483194351196403],
        [82.11291503906278, 16.482915878296012],
        [82.112358093262, 16.482915878296012],
        [82.112358093262, 16.482639312744197],
        [82.11207580566412, 16.482641220092773],
        [82.11207580566412, 16.482362747192383],
        [82.11152648925787, 16.482362747192383],
        [82.11152648925787, 16.48208045959484],
        [82.11096954345726, 16.48208427429205],
        [82.11096954345726, 16.48180580139166],
        [82.11042022705107, 16.48180961608881],
        [82.11042022705107, 16.48153114318842],
        [82.10986328125028, 16.48153114318842],
        [82.10986328125028, 16.48125267028803],
        [82.10957336425781, 16.48125267028803],
        [82.10957336425781, 16.480970382690487],
        [82.10902404785162, 16.480970382690487],
        [82.10902404785162, 16.480693817138672],
        [82.10847473144537, 16.480693817138672],
        [82.10847473144537, 16.480417251586914],
        [82.10791778564476, 16.480421066284237],
        [82.10791778564476, 16.480142593383903],
        [82.1073608398438, 16.480142593383903],
        [82.1073608398438, 16.479850769043026],
        [82.10680389404325, 16.479858398437557],
        [82.10680389404325, 16.479583740234318],
        [82.10624694824247, 16.479583740234318],
        [82.10624694824247, 16.479305267333984],
        [82.10542297363287, 16.479309082031307],
        [82.10542297363287, 16.479030609130916],
        [82.10485839843773, 16.479030609130916],
        [82.10485839843773, 16.478748321533203],
        [82.10402679443376, 16.478748321533203],
        [82.10402679443376, 16.478469848632812],
        [82.10346984863281, 16.47847175598139],
        [82.10346984863281, 16.4781951904298],
        [82.10263824462902, 16.47820091247553],
        [82.10263824462902, 16.477920532226562],
        [82.10208129882807, 16.477920532226562],
        [82.10208129882807, 16.47763824462902],
        [82.10124969482428, 16.47763824462902],
        [82.10124969482428, 16.47735977172863],
        [82.10069274902372, 16.477361679077205],
        [82.10069274902372, 16.477083206176815],
        [82.10041809082031, 16.477083206176815],
        [82.10041809082031, 16.47680473327648],
        [82.10013580322294, 16.47680473327648],
        [82.10013580322294, 16.47652626037609],
        [82.09958648681652, 16.476528167724666],
        [82.09958648681652, 16.476249694824276],
        [82.09902954101557, 16.476249694824276],
        [82.09902954101557, 16.475971221923885],
        [82.09874725341803, 16.47597312927246],
        [82.09874725341803, 16.475694656372127],
        [82.09847259521501, 16.475694656372127],
        [82.09847259521501, 16.475416183471737],
        [82.09792327880876, 16.475418090820312],
        [82.09792327880876, 16.475139617919922],
        [82.09707641601591, 16.475139617919922],
        [82.09707641601591, 16.47486114501953],
        [82.09652709960966, 16.47486114501953],
        [82.09652709960966, 16.474580764770565],
        [82.09597015380871, 16.47458267211914],
        [82.09597015380871, 16.474306106567383],
        [82.09541320800776, 16.474309921264705],
        [82.09541320800776, 16.47404098510748],
        [82.09456634521507, 16.47404098510748],
        [82.09456634521507, 16.473751068115178],
        [82.09430694580095, 16.473751068115178],
        [82.09430694580095, 16.47347068786621],
        [82.09375, 16.473472595214787],
        [82.09375, 16.473194122314396],
        [82.09319305419928, 16.473194122314396],
        [82.09319305419928, 16.47291755676281],
        [82.09236145019526, 16.472919464111385],
        [82.09236145019526, 16.472640991210994],
        [82.09152984619146, 16.472640991210994],
        [82.09152984619146, 16.472360610962028],
        [82.09124755859392, 16.472360610962028],
        [82.09124755859392, 16.47208023071289],
        [82.09097290039091, 16.472085952758846],
        [82.09097290039091, 16.47180557250988],
        [82.09041595459013, 16.47180938720703],
        [82.09041595459013, 16.47153091430664],
        [82.09014129638678, 16.47153091430664],
        [82.09014129638678, 16.470970153808707],
        [82.0887527465822, 16.470972061157283],
        [82.0887527465822, 16.470693588256893],
        [82.08847045898443, 16.470693588256893],
        [82.08847045898443, 16.470417022705135],
        [82.08790588378912, 16.470420837402287],
        [82.08790588378912, 16.47014045715332],
        [82.0873565673831, 16.47014045715332],
        [82.0873565673831, 16.469860076904354],
        [82.08680725097668, 16.469863891601562],
        [82.08680725097668, 16.46958351135254],
        [82.08652496337896, 16.46958351135254],
        [82.08652496337896, 16.469305038452205],
        [82.08598327636741, 16.469310760498104],
        [82.08598327636741, 16.469030380248967],
        [82.08541870117193, 16.469030380248967],
        [82.08541870117193, 16.468751907348576],
        [82.08429718017607, 16.468751907348576],
        [82.08429718017607, 16.468469619751033],
        [82.08374786376982, 16.468473434448185],
        [82.08374786376982, 16.46819496154785],
        [82.08319091796886, 16.46820068359375],
        [82.08319091796886, 16.467910766601676],
        [82.08264160156267, 16.46791648864746],
        [82.08264160156267, 16.46763801574707],
        [82.08180236816412, 16.467641830444393],
        [82.08180236816412, 16.46735954284668],
        [82.08152770996111, 16.46735954284668],
        [82.08152770996111, 16.46708106994629],
        [82.08097076416016, 16.46708106994629],
        [82.08097076416016, 16.466806411743278],
        [82.08013916015636, 16.46681022644043],
        [82.08013916015636, 16.46653175354004],
        [82.07958984375017, 16.46653175354004],
        [82.07958984375017, 16.466249465942496],
        [82.07875061035162, 16.466249465942496],
        [82.07875061035162, 16.46596908569336],
        [82.07847595214861, 16.465972900390682],
        [82.07847595214861, 16.465694427490348],
        [82.07791900634766, 16.465694427490348],
        [82.07791900634766, 16.465415954589957],
        [82.07736206054693, 16.465421676635685],
        [82.07736206054693, 16.465137481689567],
        [82.07680511474615, 16.465137481689567],
        [82.07680511474615, 16.464860916137752],
        [82.07653045654291, 16.464860916137752],
        [82.07653045654291, 16.46458244323736],
        [82.07624816894554, 16.46458244323736],
        [82.07624816894554, 16.464305877685604],
        [82.07596588134783, 16.464309692382756],
        [82.07596588134783, 16.464031219482365],
        [82.07541656494158, 16.464031219482365],
        [82.07541656494158, 16.4637508392334],
        [82.07514190673857, 16.4637508392334],
        [82.07514190673857, 16.463470458984432],
        [82.07347106933588, 16.463472366333008],
        [82.07347106933588, 16.463193893432617],
        [82.07291412353533, 16.463193893432617],
        [82.07291412353533, 16.46291732788086],
        [82.07263946533209, 16.462921142578182],
        [82.07263946533209, 16.462640762329045],
        [82.07235717773455, 16.462640762329045],
        [82.07235717773455, 16.462360382080078],
        [82.07180786132812, 16.462360382080078],
        [82.07180786132812, 16.46208000183111],
        [82.07125091552734, 16.462083816528263],
        [82.07125091552734, 16.46180534362793],
        [82.07068634033209, 16.461811065673828],
        [82.07068634033209, 16.461250305175895],
        [82.06986236572283, 16.461250305175895],
        [82.06986236572283, 16.460969924926758],
        [82.06958007812523, 16.460971832275334],
        [82.06958007812523, 16.460695266723746],
        [82.06903076171903, 16.460700988769474],
        [82.06903076171903, 16.460420608520508],
        [82.06846618652372, 16.460420608520508],
        [82.06846618652372, 16.46014022827154],
        [82.06791687011747, 16.46014022827154],
        [82.06791687011747, 16.459859848022575],
        [82.06735992431652, 16.45986175537115],
        [82.06735992431652, 16.45958328247076],
        [82.06680297851557, 16.45958328247076],
        [82.06680297851557, 16.459304809570426],
        [82.06625366210955, 16.459304809570426],
        [82.06625366210955, 16.45902824401861],
        [82.06597137451178, 16.459030151367188],
        [82.06597137451178, 16.45874977111822],
        [82.06569671630876, 16.45874977111822],
        [82.06569671630876, 16.458469390869254],
        [82.06541442871122, 16.45847129821783],
        [82.06541442871122, 16.458194732666072],
        [82.06458282470709, 16.458194732666072],
        [82.06458282470709, 16.457916259765682],
        [82.0634689331057, 16.457920074462834],
        [82.0634689331057, 16.457639694213867],
        [82.06318664550798, 16.457639694213867],
        [82.06318664550798, 16.457361221313477],
        [82.06291961669928, 16.457361221313477],
        [82.06291961669928, 16.457082748413086],
        [82.06236267089872, 16.45708465576172],
        [82.06236267089872, 16.456806182861328],
        [82.06208038330078, 16.45680999755865],
        [82.06208038330078, 16.456529617309684],
        [82.06151580810547, 16.456529617309684],
        [82.06151580810547, 16.456251144409123],
        [82.06096649169928, 16.456251144409123],
        [82.06096649169928, 16.455972671508732],
        [82.05986022949224, 16.455972671508732],
        [82.05986022949224, 16.45569419860834],
        [82.05958557128923, 16.45569419860834],
        [82.05958557128923, 16.455417633056754],
        [82.05930328369169, 16.45541954040533],
        [82.05930328369169, 16.45514106750494],
        [82.05902862548828, 16.45514106750494],
        [82.05902862548828, 16.45486259460455],
        [82.05845642089844, 16.45486259460455],
        [82.05845642089844, 16.454580307006836],
        [82.05791473388678, 16.454584121704215],
        [82.05791473388678, 16.454305648803825],
        [82.05764007568354, 16.454309463500977],
        [82.05764007568354, 16.45403289794922],
        [82.05708312988298, 16.45403289794922],
        [82.05708312988298, 16.453752517700195],
        [82.05680847167974, 16.453752517700195],
        [82.05680847167974, 16.453470230102653],
        [82.05569458007841, 16.453470230102653],
        [82.05569458007841, 16.453193664550838],
        [82.05486297607428, 16.453193664550838],
        [82.05486297607428, 16.45291709899908],
        [82.0543060302735, 16.452930450439567],
        [82.0543060302735, 16.45263862609869],
        [82.05403137207048, 16.45263862609869],
        [82.05403137207048, 16.452358245849723],
        [82.0534744262697, 16.4523601531983],
        [82.0534744262697, 16.452083587646484],
        [82.05291748046892, 16.452083587646484],
        [82.05291748046892, 16.45180511474615],
        [82.05265045166044, 16.45181083679205],
        [82.05265045166044, 16.451530456542912],
        [82.05208587646513, 16.451530456542912],
        [82.05208587646513, 16.45124816894537],
        [82.05179595947266, 16.45124816894537],
        [82.05179595947266, 16.45096969604498],
        [82.05124664306646, 16.450971603393555],
        [82.05124664306646, 16.450695037841797],
        [82.05069732666038, 16.450700759887695],
        [82.05069732666038, 16.45042037963873],
        [82.05014038085943, 16.45042037963873],
        [82.05014038085943, 16.450138092041016],
        [82.04875183105474, 16.450138092041016],
        [82.04875183105474, 16.449869155883732],
        [82.04818725585943, 16.449869155883732],
        [82.04818725585943, 16.449581146240234],
        [82.04792022705072, 16.44958305358881],
        [82.04792022705072, 16.449306488037223],
        [82.04763793945335, 16.449310302734375],
        [82.04763793945335, 16.44902992248541],
        [82.04736328125017, 16.44902992248541],
        [82.04736328125017, 16.448749542236442],
        [82.04680633544939, 16.448749542236442],
        [82.04680633544939, 16.44847106933605],
        [82.04652404785185, 16.448472976684627],
        [82.04652404785185, 16.448194503784293],
        [82.04624938964844, 16.448194503784293],
        [82.04624938964844, 16.44791793823248],
        [82.04596710205107, 16.447919845581055],
        [82.04596710205107, 16.447639465332088],
        [82.0448608398437, 16.447639465332088],
        [82.0448608398437, 16.447360992431697],
        [82.04429626464838, 16.447360992431697],
        [82.04429626464838, 16.44708061218273],
        [82.04374694824236, 16.447082519531307],
        [82.04374694824236, 16.446804046630916],
        [82.04345703125006, 16.4468097686767],
        [82.04345703125006, 16.446529388427734],
        [82.04125213623064, 16.446529388427734],
        [82.04125213623064, 16.446250915527344],
        [82.04096984863304, 16.446250915527344],
        [82.04096984863304, 16.445970535278377],
        [82.04013824462908, 16.445970535278377],
        [82.04013824462908, 16.446250915527344],
        [82.03874969482433, 16.446250915527344],
        [82.03874969482433, 16.445970535278377],
        [82.03819274902338, 16.445972442626953],
        [82.03819274902338, 16.445695877075195],
        [82.03736114501959, 16.445695877075195],
        [82.03736114501959, 16.445417404174805],
        [82.03708648681658, 16.445421218872127],
        [82.03708648681658, 16.44514083862299],
        [82.0367965698245, 16.44514083862299],
        [82.0367965698245, 16.444580078125057],
        [82.03652954101562, 16.444580078125057],
        [82.03652954101562, 16.44514083862299],
        [82.0359725952149, 16.44514083862299],
        [82.0359725952149, 16.445421218872127],
        [82.03514099121088, 16.445421218872127],
        [82.03514099121088, 16.44514083862299],
        [82.03291320800781, 16.44514083862299],
        [82.03291320800781, 16.444860458374023],
        [82.0326385498048, 16.444860458374023],
        [82.0326385498048, 16.444580078125057],
        [82.03236389160162, 16.444585800171012],
        [82.03236389160162, 16.444305419921875],
        [82.03180694580101, 16.444311141967773],
        [82.03180694580101, 16.444030761718807],
        [82.03151702880854, 16.444030761718807],
        [82.03151702880854, 16.44374275207531],
        [82.03125, 16.44375038146984],
        [82.03125, 16.443473815918082],
        [82.03097534179705, 16.443473815918082],
        [82.03097534179705, 16.443195343017692],
        [82.0304183959961, 16.44320106506342],
        [82.0304183959961, 16.442920684814453],
        [82.03013610839872, 16.442920684814453],
        [82.03013610839872, 16.442640304565487],
        [82.02986145019531, 16.442640304565487],
        [82.02986145019531, 16.442361831665096],
        [82.0295867919923, 16.44236373901373],
        [82.0295867919923, 16.442083358764705],
        [82.02930450439459, 16.442083358764705],
        [82.02930450439459, 16.441528320312557],
        [82.027641296387, 16.441530227661133],
        [82.027641296387, 16.441251754760742],
        [82.02735900878923, 16.441251754760742],
        [82.02735900878923, 16.4409694671632],
        [82.02708435058622, 16.4409694671632],
        [82.02708435058622, 16.439859390258846],
        [82.02735900878923, 16.439859390258846],
        [82.02735900878923, 16.43958282470703],
        [82.02819824218756, 16.43958282470703],
        [82.02819824218756, 16.439859390258846],
        [82.0287475585938, 16.439859390258846],
        [82.0287475585938, 16.44014167785639],
        [82.02902984619135, 16.44014167785639],
        [82.02902984619135, 16.44042015075678],
        [82.03013610839872, 16.44042015075678],
        [82.03013610839872, 16.44014167785639],
        [82.03098297119158, 16.44014167785639],
        [82.03097534179705, 16.439859390258846],
        [82.0315246582033, 16.439859390258846],
        [82.0315246582033, 16.43958282470703],
        [82.03097534179705, 16.43958282470703],
        [82.03097534179705, 16.439306259155273],
        [82.0304183959961, 16.439310073852596],
        [82.0304183959961, 16.439031600952205],
        [82.03013610839872, 16.439031600952205],
        [82.03013610839872, 16.438749313354492],
        [82.02986145019531, 16.438749313354492],
        [82.02986145019531, 16.4384708404541],
        [82.02930450439459, 16.438472747802678],
        [82.02930450439459, 16.438194274902287],
        [82.02902984619135, 16.438194274902287],
        [82.02902984619135, 16.437915802001953],
        [82.02848052978533, 16.43792915344244],
        [82.02848052978533, 16.43763923645031],
        [82.02791595459001, 16.43763923645031],
        [82.02791595459001, 16.43736076354992],
        [82.02735900878923, 16.43736076354992],
        [82.02735900878923, 16.43708038330078],
        [82.02708435058622, 16.43708419799816],
        [82.02708435058622, 16.43680572509777],
        [82.02680206298828, 16.436809539794922],
        [82.02680206298828, 16.43653106689453],
        [82.02597045898449, 16.43653106689453],
        [82.02597045898449, 16.436250686645565],
        [82.0251388549807, 16.436250686645565],
        [82.0251388549807, 16.435970306396598],
        [82.02486419677751, 16.435972213745174],
        [82.02486419677751, 16.435693740844783],
        [82.02458190917974, 16.435693740844783],
        [82.02458190917974, 16.43541526794445],
        [82.02430725097673, 16.435420989990178],
        [82.02430725097673, 16.43514060974121],
        [82.02401733398466, 16.43514060974121],
        [82.02401733398466, 16.43486976623535],
        [82.02319335937506, 16.43486976623535],
        [82.02319335937506, 16.434579849243278],
        [82.02291870117199, 16.43458366394043],
        [82.02291870117199, 16.434305191040096],
        [82.0212478637697, 16.434310913085994],
        [82.0212478637697, 16.434030532836857],
        [82.02097320556669, 16.434030532836857],
        [82.02097320556669, 16.43375015258789],
        [82.02069091796875, 16.43375015258789],
        [82.02069091796875, 16.433469772338924],
        [82.02041625976591, 16.4334716796875],
        [82.02041625976591, 16.433195114135742],
        [82.02014160156256, 16.43320083618164],
        [82.02014160156256, 16.432640075683707],
        [82.02041625976591, 16.432640075683707],
        [82.02041625976591, 16.431800842285213],
        [82.02097320556669, 16.431804656982365],
        [82.02097320556669, 16.431528091430778],
        [82.02014160156256, 16.431529998779354],
        [82.02014160156256, 16.431249618530387],
        [82.01985931396496, 16.431249618530387],
        [82.01985931396496, 16.431529998779354],
        [82.019302368164, 16.431528091430778],
        [82.019302368164, 16.432083129882756],
        [82.01902770996116, 16.432083129882756],
        [82.01902770996116, 16.432361602783146],
        [82.01847076416021, 16.432361602783146],
        [82.01847076416021, 16.432083129882756],
        [82.01763916015625, 16.432083129882756],
        [82.01763916015625, 16.431804656982365],
        [82.01652526855474, 16.431804656982365],
        [82.01652526855474, 16.431528091430778],
        [82.01597595214866, 16.431529998779354],
        [82.01597595214866, 16.431249618530387],
        [82.01541900634771, 16.431249618530387],
        [82.01541900634771, 16.430971145629996],
        [82.01513671875017, 16.430973052978572],
        [82.01513671875017, 16.43069458007824],
        [82.01457977294939, 16.43069458007824],
        [82.01457977294939, 16.430416107177848],
        [82.01431274414091, 16.430419921875],
        [82.01431274414091, 16.430139541626033],
        [82.01403045654297, 16.430139541626033],
        [82.01403045654297, 16.429861068725643],
        [82.01347351074224, 16.429861068725643],
        [82.01347351074224, 16.429580688476676],
        [82.01264190673845, 16.429580688476676],
        [82.01264190673845, 16.429306030273494],
        [82.01235961914085, 16.429309844970646],
        [82.01235961914085, 16.42902946472168],
        [82.01207733154314, 16.42902946472168],
        [82.01207733154314, 16.428739547729606],
        [82.01152801513689, 16.428749084472713],
        [82.01152801513689, 16.4284725189209],
        [82.01041412353521, 16.4284725189209],
        [82.01041412353521, 16.428192138671932],
        [82.00986480712896, 16.428192138671932],
        [82.00986480712896, 16.42791748046875],
        [82.0095825195312, 16.427919387817496],
        [82.0095825195312, 16.42764282226574],
        [82.00903320312517, 16.42764282226574],
        [82.00903320312517, 16.427362442016545],
        [82.00846862792986, 16.427362442016545],
        [82.00846862792986, 16.427080154419002],
        [82.00763702392607, 16.427083969116154],
        [82.00763702392607, 16.42680549621582],
        [82.0073471069336, 16.42681121826172],
        [82.0073471069336, 16.426530838012752],
        [82.00708007812511, 16.426530838012752],
        [82.00708007812511, 16.42624855041504],
        [82.00680541992193, 16.42624855041504],
        [82.00680541992193, 16.42597007751465],
        [82.0065307617187, 16.42597007751465],
        [82.0065307617187, 16.425693511962834],
        [82.00708007812511, 16.42569923400879],
        [82.00708007812511, 16.425138473510856],
        [82.00681304931646, 16.425138473510856],
        [82.00681304931646, 16.424310684204045],
        [82.0065307617187, 16.424310684204045],
        [82.0065307617187, 16.424028396606502],
        [82.00514221191435, 16.424028396606502],
        [82.00514221191435, 16.425138473510856],
        [82.00596618652361, 16.425138473510856],
        [82.00596618652361, 16.42569923400879],
        [82.00541687011736, 16.42569923400879],
        [82.00541687011736, 16.4254207611084],
        [82.00460052490246, 16.4254207611084],
        [82.00460052490246, 16.425138473510856],
        [82.00402832031261, 16.425138473510856],
        [82.00402832031261, 16.42485809326172],
        [82.00347137451166, 16.42486190795904],
        [82.00347137451166, 16.42458343505865],
        [82.00291442871111, 16.42458343505865],
        [82.00291442871111, 16.424304962158317],
        [82.00235748291033, 16.424310684204045],
        [82.00235748291033, 16.424028396606502],
        [82.0018081665039, 16.424028396606502],
        [82.0018081665039, 16.42374992370611],
        [82.00125122070312, 16.42374992370611],
        [82.00125122070312, 16.423469543457145],
        [82.00096893310575, 16.42347145080572],
        [82.00096893310575, 16.423194885253963],
        [82.0006942749024, 16.423194885253963],
        [82.0006942749024, 16.422916412353572],
        [82.00041961669916, 16.42292976379406],
        [82.00041961669916, 16.422639846801758],
        [81.99986267089861, 16.422639846801758],
        [81.99931335449236, 16.422639846801758],
        [81.99931335449236, 16.42235946655279],
        [81.99874877929705, 16.422361373901367],
        [81.99874877929705, 16.422082901000977],
        [81.9981918334961, 16.422082901000977],
        [81.9981918334961, 16.42180633544922],
        [81.9976425170899, 16.42181015014654],
        [81.9976425170899, 16.421529769897575],
        [81.99709320068382, 16.421529769897575],
        [81.99709320068382, 16.421249389648438],
        [81.99680328369135, 16.421249389648438],
        [81.99680328369135, 16.420970916748047],
        [81.99652862548851, 16.420972824096623],
        [81.99652862548851, 16.420694351196232],
        [81.9951400756836, 16.420694351196232],
        [81.9951400756836, 16.4204158782959],
        [81.99485778808622, 16.42041969299322],
        [81.99485778808622, 16.42014122009283],
        [81.99458312988287, 16.42014122009283],
        [81.99458312988287, 16.419860839843864],
        [81.9943084716798, 16.419860839843864],
        [81.9943084716798, 16.419572830200195],
        [81.99346923828148, 16.419584274292106],
        [81.99346923828148, 16.419305801391715],
        [81.99263763427751, 16.419309616088867],
        [81.99263763427751, 16.419031143188477],
        [81.99208068847656, 16.419031143188477],
        [81.99208068847656, 16.41875076293951],
        [81.99124908447277, 16.41875076293951],
        [81.99124908447277, 16.418470382690543],
        [81.99097442626959, 16.41847229003912],
        [81.99097442626959, 16.41819572448736],
        [81.99041748046898, 16.41819572448736],
        [81.99041748046898, 16.41791725158697],
        [81.9896011352539, 16.417921066284123],
        [81.9896011352539, 16.417640686035156],
        [81.989311218262, 16.417640686035156],
        [81.989311218262, 16.41736030578619],
        [81.98874664306669, 16.41736030578619],
        [81.98874664306669, 16.417079925537223],
        [81.98819732666027, 16.417083740234375],
        [81.98819732666027, 16.41680526733404],
        [81.98764038085932, 16.41680526733404],
        [81.98764038085932, 16.416528701782227],
        [81.987075805664, 16.416530609130803],
        [81.987075805664, 16.416250228881836],
        [81.98680877685553, 16.416250228881836],
        [81.98680877685553, 16.41596984863287],
        [81.98652648925798, 16.41597366333002],
        [81.98652648925798, 16.415695190429688],
        [81.98568725585966, 16.41570281982422],
        [81.98568725585966, 16.41542053222662],
        [81.98486328125006, 16.41542053222662],
        [81.98486328125006, 16.415140151977653],
        [81.98401641845697, 16.415140151977653],
        [81.98401641845697, 16.41486167907709],
        [81.98374938964866, 16.414863586425895],
        [81.98374938964866, 16.414581298828125],
        [81.98319244384771, 16.414581298828125],
        [81.98319244384771, 16.41430473327631],
        [81.98263549804716, 16.41430473327631],
        [81.98263549804716, 16.414028167724723],
        [81.98179626464844, 16.4140300750733],
        [81.98179626464844, 16.413761138916072],
        [81.98152923583996, 16.413761138916072],
        [81.98152923583996, 16.413469314575195],
        [81.980972290039, 16.413473129272518],
        [81.980972290039, 16.413194656372184],
        [81.98041534423845, 16.413194656372184],
        [81.98041534423845, 16.412916183471793],
        [81.98014068603521, 16.41292190551752],
        [81.98014068603521, 16.412641525268555],
        [81.97985839843767, 16.412641525268555],
        [81.97985839843767, 16.412359237671012],
        [81.97930908203125, 16.412359237671012],
        [81.97930908203125, 16.41208076477062],
        [81.97875213623053, 16.412082672119197],
        [81.97875213623053, 16.41180610656744],
        [81.97846984863293, 16.411811828613395],
        [81.97846984863293, 16.41152763366705],
        [81.97791290283197, 16.41152763366705],
        [81.97791290283197, 16.41124916076666],
        [81.97598266601591, 16.41124916076666],
        [81.97598266601591, 16.410970687866268],
        [81.97569274902344, 16.410972595214844],
        [81.97569274902344, 16.410694122314453],
        [81.97513580322271, 16.41069984436041],
        [81.97513580322271, 16.410417556762695],
        [81.97486114501947, 16.410417556762695],
        [81.97486114501947, 16.410139083862305],
        [81.97458648681663, 16.410139083862305],
        [81.97458648681663, 16.409860610961914],
        [81.97402954101568, 16.409860610961914],
        [81.97402954101568, 16.409580230712947],
        [81.97319793701172, 16.4095840454101],
        [81.97319793701172, 16.409305572509766],
        [81.97292327880888, 16.409309387207088],
        [81.97292327880888, 16.409030914306697],
        [81.97235870361357, 16.409030914306697],
        [81.97235870361357, 16.40875053405773],
        [81.97180175781261, 16.40875053405773],
        [81.97180175781261, 16.408470153808594],
        [81.97123718261713, 16.408470153808594],
        [81.97125244140642, 16.40875053405773],
        [81.97069549560564, 16.40875053405773],
        [81.97069549560564, 16.40847206115717],
        [81.97041320800787, 16.40847206115717],
        [81.97041320800787, 16.40819358825678],
        [81.97013854980486, 16.40819358825678],
        [81.97013854980486, 16.40791511535639],
        [81.96985626220732, 16.407920837402344],
        [81.96985626220732, 16.40763092041027],
        [81.96736145019537, 16.4076385498048],
        [81.96736145019537, 16.407361984252987],
        [81.96708679199236, 16.407361984252987],
        [81.96708679199236, 16.407083511352596],
        [81.96680450439482, 16.407083511352596],
        [81.96680450439482, 16.406806945800838],
        [81.9665298461914, 16.40681076049799],
        [81.9665298461914, 16.406530380249023],
        [81.96624755859403, 16.406530380249023],
        [81.96624755859403, 16.40625],
        [81.96569824218761, 16.40625],
        [81.96569061279308, 16.40596961975109],
        [81.96485900878929, 16.405971527099666],
        [81.96485900878929, 16.405693054199276],
        [81.96457672119158, 16.405700683593807],
        [81.96457672119158, 16.40542030334467],
        [81.96430206298834, 16.40542030334467],
        [81.96430206298834, 16.405139923095703],
        [81.96319580078153, 16.405139923095703],
        [81.96319580078153, 16.404859542846737],
        [81.96263885498058, 16.404861450195312],
        [81.96263885498058, 16.404584884643555],
        [81.96208190917963, 16.404584884643555],
        [81.96208190917963, 16.404306411743164],
        [81.96098327636736, 16.404310226440487],
        [81.96098327636736, 16.40402984619152],
        [81.96069335937528, 16.40402984619152],
        [81.96069335937528, 16.403749465942383],
        [81.96041870117188, 16.403749465942383],
        [81.96041870117188, 16.40402984619152],
        [81.95958709716808, 16.40402984619152],
        [81.95958709716808, 16.403472900390568],
        [81.95930480957037, 16.40347480773937],
        [81.95930480957037, 16.403194427490178],
        [81.95874786376959, 16.403194427490178],
        [81.95874786376959, 16.402915954589844],
        [81.95819091796898, 16.402919769287166],
        [81.95819091796898, 16.4026393890382],
        [81.95764160156278, 16.4026393890382],
        [81.95764160156278, 16.402362823486385],
        [81.95707702636747, 16.402362823486385],
        [81.95707702636747, 16.402080535888672],
        [81.95652770996122, 16.402080535888672],
        [81.95652770996122, 16.40180587768566],
        [81.95597076416027, 16.401821136474723],
        [81.95597076416027, 16.401531219482422],
        [81.95540618896479, 16.401531219482422],
        [81.95540618896479, 16.40125274658203],
        [81.95485687255876, 16.40125274658203],
        [81.95485687255876, 16.40097045898449],
        [81.95430755615251, 16.40097045898449],
        [81.95430755615251, 16.400693893432674],
        [81.95375061035156, 16.400705337524414],
        [81.95375061035156, 16.400421142578068],
        [81.95361328125023, 16.400421142578068],
        [81.95291900634777, 16.400421142578068],
        [81.95291900634777, 16.400142669677678],
        [81.95236206054682, 16.400142669677678],
        [81.95236206054682, 16.39985847473156],
        [81.95180511474626, 16.39985847473156],
        [81.95180511474626, 16.39958381652832],
        [81.95124816894548, 16.39958381652832],
        [81.95124816894548, 16.399305343627987],
        [81.95069122314453, 16.39930915832531],
        [81.95069122314453, 16.399030685424748],
        [81.95014190673834, 16.399030685424748],
        [81.95014190673834, 16.398761749267692],
        [81.94959259033209, 16.398761749267692],
        [81.94959259033209, 16.398469924926815],
        [81.94902801513695, 16.39847183227539],
        [81.94902801513695, 16.398195266723633],
        [81.948471069336, 16.398195266723633],
        [81.948471069336, 16.39847183227539],
        [81.94819641113298, 16.39847183227539],
        [81.94819641113298, 16.398195266723633],
        [81.94790649414068, 16.398199081420955],
        [81.94790649414068, 16.397920608520565],
        [81.94763946533203, 16.397920608520565],
        [81.94763946533203, 16.39763832092285],
        [81.94706726074219, 16.39763832092285],
        [81.94706726074219, 16.39735984802246],
        [81.94652557373053, 16.397361755371037],
        [81.94652557373053, 16.397083282470646],
        [81.94625091552729, 16.397083282470646],
        [81.94625091552729, 16.396804809570256],
        [81.94596862792974, 16.396804809570256],
        [81.94596862792974, 16.396526336669922],
        [81.94568634033197, 16.39652824401867],
        [81.94568634033197, 16.396249771118278],
        [81.9454193115235, 16.396249771118278],
        [81.9454193115235, 16.39596939086914],
        [81.94513702392595, 16.395971298217887],
        [81.94513702392595, 16.39569473266613],
        [81.94486236572294, 16.39569473266613],
        [81.94486236572294, 16.39541625976574],
        [81.94458007812517, 16.395418167114315],
        [81.94458007812517, 16.395139694213924],
        [81.94319152832043, 16.395139694213924],
        [81.94319152832043, 16.395418167114315],
        [81.94235992431663, 16.395418167114315],
        [81.94235992431663, 16.395139694213924],
        [81.9421157836914, 16.395139694213924],
        [81.94180297851568, 16.395139694213924],
        [81.94180297851568, 16.394861221313533],
        [81.94097137451172, 16.394861221313533],
        [81.94097137451172, 16.394580841064567],
        [81.93875122070318, 16.394580841064567],
        [81.93875122070318, 16.394861221313533],
        [81.93819427490263, 16.394861221313533],
        [81.93820190429716, 16.395139694213924],
        [81.9370803833009, 16.395139694213924],
        [81.9370803833009, 16.394861221313533],
        [81.93596649169939, 16.394861221313533],
        [81.93596649169939, 16.394580841064567],
        [81.93569183349615, 16.394582748413143],
        [81.93569183349615, 16.394306182861385],
        [81.93459320068388, 16.394309997558537],
        [81.93459320068388, 16.39402961730957],
        [81.9340286254884, 16.39402961730957],
        [81.9340286254884, 16.39375114440918],
        [81.93264007568365, 16.39375114440918],
        [81.93264007568365, 16.39402961730957],
        [81.92958068847679, 16.39402961730957],
        [81.92958068847679, 16.39375114440918],
        [81.92846679687528, 16.39375114440918],
        [81.92846679687528, 16.39402961730957],
        [81.92736053466808, 16.39402961730957],
        [81.92736053466808, 16.39375114440918],
        [81.92457580566406, 16.39375114440918],
        [81.92457580566406, 16.393470764160213],
        [81.92319488525408, 16.39347267150879],
        [81.92319488525408, 16.3931941986084],
        [81.92292022705084, 16.3931941986084],
        [81.92292022705084, 16.39291763305664],
        [81.9226379394533, 16.392919540405387],
        [81.9226379394533, 16.392629623413143],
        [81.9218063354495, 16.39263916015625],
        [81.9218063354495, 16.39236068725586],
        [81.92124938964855, 16.39236068725586],
        [81.92124938964855, 16.39263916015625],
        [81.9206924438476, 16.39263916015625],
        [81.9206924438476, 16.39236068725586],
        [81.9195861816408, 16.39236068725586],
        [81.9195861816408, 16.392080307006893],
        [81.91930389404325, 16.392084121704045],
        [81.91930389404325, 16.39180564880371],
        [81.91792297363287, 16.391809463501033],
        [81.91792297363287, 16.391530990600643],
        [81.91541290283203, 16.391530990600643],
        [81.91541290283203, 16.391250610351676],
        [81.91402435302729, 16.391250610351676],
        [81.91402435302729, 16.390972137451115],
        [81.91291809082048, 16.390972137451115],
        [81.91291809082048, 16.390417098999137],
        [81.91208648681669, 16.39042091369629],
        [81.91208648681669, 16.390140533447322],
        [81.9109573364259, 16.390140533447322],
        [81.9109573364259, 16.39042091369629],
        [81.91042327880876, 16.39042091369629],
        [81.91042327880876, 16.390140533447322],
        [81.9093017578125, 16.390140533447322],
        [81.9093017578125, 16.389860153198356],
        [81.90736389160173, 16.389862060546932],
        [81.90736389160173, 16.38958358764654],
        [81.90651702880865, 16.38959121704113],
        [81.90651702880865, 16.389310836791935],
        [81.90569305419922, 16.389310836791935],
        [81.90569305419922, 16.38903045654297],
        [81.90541839599621, 16.38903045654297],
        [81.90541839599621, 16.388750076294002],
        [81.90458679199241, 16.388750076294002],
        [81.90458679199241, 16.38903045654297],
        [81.90374755859392, 16.38903045654297],
        [81.90374755859392, 16.388750076294002],
        [81.90291595459013, 16.388750076294002],
        [81.90291595459013, 16.388200759887752],
        [81.9012527465822, 16.388200759887752],
        [81.9012527465822, 16.387920379638615],
        [81.90013885498064, 16.387920379638615],
        [81.90013885498064, 16.387641906738224],
        [81.89736175537115, 16.387641906738224],
        [81.89736175537115, 16.387359619140682],
        [81.8968048095706, 16.387359619140682],
        [81.8968048095706, 16.38708114624029],
        [81.89597320556646, 16.38708114624029],
        [81.89597320556646, 16.38680648803711],
        [81.89457702636736, 16.386819839477596],
        [81.89457702636736, 16.38653182983404],
        [81.89402770996111, 16.38653182983404],
        [81.89402770996111, 16.386251449584904],
        [81.89292144775396, 16.386251449584904],
        [81.89292144775396, 16.38653182983404],
        [81.89235687255865, 16.38653182983404],
        [81.89235687255865, 16.386251449584904],
        [81.89152526855486, 16.386251449584904],
        [81.89152526855486, 16.385971069335938],
        [81.89097595214861, 16.385971069335938],
        [81.89097595214861, 16.385694503784123],
        [81.89041900634766, 16.385694503784123],
        [81.89041900634766, 16.38541603088379],
        [81.88903045654291, 16.385421752929688],
        [81.88903045654291, 16.38514137268072],
        [81.88819122314476, 16.38514137268072],
        [81.88819122314476, 16.384859085083008],
        [81.8873596191408, 16.384859085083008],
        [81.8873596191408, 16.384580612182617],
        [81.88625335693365, 16.384582519531193],
        [81.88625335693365, 16.384305953979606],
        [81.88540649414057, 16.384309768676758],
        [81.88540649414057, 16.384027481079215],
        [81.88375091552734, 16.384027481079215],
        [81.88375091552734, 16.3837509155274],
        [81.88318634033203, 16.3837509155274],
        [81.88318634033203, 16.383470535278434],
        [81.8823623657226, 16.38347244262701],
        [81.8823623657226, 16.38319396972662],
        [81.88180541992205, 16.38319396972662],
        [81.88180541992205, 16.38291740417486],
        [81.88069152832031, 16.382921218872013],
        [81.88069152832031, 16.382640838623047],
        [81.87902832031273, 16.382640838623047],
        [81.87902832031273, 16.38236045837408],
        [81.87819671630876, 16.38236045837408],
        [81.87819671630876, 16.382083892822266],
        [81.87791442871122, 16.382083892822266],
        [81.87791442871122, 16.381805419921932],
        [81.87735748291044, 16.38181114196783],
        [81.87735748291044, 16.381530761718693],
        [81.8765258789063, 16.381530761718693],
        [81.8765258789063, 16.381250381469727],
        [81.87513732910173, 16.381250381469727],
        [81.87513732910173, 16.38097000122076],
        [81.87403106689453, 16.380971908569336],
        [81.87403106689453, 16.380695343017578],
        [81.87319183349621, 16.380695343017578],
        [81.87319183349621, 16.380416870117188],
        [81.8720779418947, 16.38042068481451],
        [81.8720779418947, 16.380140304565543],
        [81.8709716796875, 16.380140304565543],
        [81.8709716796875, 16.379859924316406],
        [81.87041473388678, 16.379861831664982],
        [81.87041473388678, 16.37958335876459],
        [81.86958312988276, 16.37958335876459],
        [81.86958312988276, 16.379306793213004],
        [81.86875152587896, 16.379306793213004],
        [81.86875152587896, 16.379028320312614],
        [81.86763763427763, 16.37903022766119],
        [81.86763763427763, 16.378749847412223],
        [81.8668060302735, 16.378749847412223],
        [81.8668060302735, 16.378469467163086],
        [81.86513519287138, 16.378471374511832],
        [81.86513519287138, 16.378194808960075],
        [81.86430358886719, 16.378194808960075],
        [81.86430358886719, 16.377916336059684],
        [81.86290740966791, 16.377920150756836],
        [81.86290740966791, 16.37765121459961],
        [81.86264038085943, 16.37765121459961],
        [81.86264038085943, 16.377359390258903],
        [81.86235809326189, 16.37736129760748],
        [81.86235809326189, 16.37708473205572],
        [81.86180877685547, 16.37708473205572],
        [81.86180877685547, 16.37680625915533],
        [81.86096954345732, 16.376810073852482],
        [81.86096954345732, 16.376529693603516],
        [81.86042022705072, 16.376529693603516],
        [81.86042022705072, 16.376251220703125],
        [81.85958099365257, 16.376251220703125],
        [81.85958099365257, 16.375972747802734],
        [81.85902404785162, 16.375972747802734],
        [81.85902404785162, 16.375694274902344],
        [81.85791778564482, 16.375694274902344],
        [81.85791778564482, 16.37541580200201],
        [81.85736083984386, 16.375419616699332],
        [81.85736083984386, 16.37514114379877],
        [81.85678863525402, 16.37514114379877],
        [81.85678863525402, 16.37486267089838],
        [81.85597229003912, 16.37486267089838],
        [81.85597229003912, 16.374589920043945],
        [81.85404205322288, 16.374589920043945],
        [81.85404205322288, 16.37430953979498],
        [81.85375213623041, 16.37430953979498],
        [81.85375213623041, 16.374031066894588],
        [81.85346984863304, 16.374031066894588],
        [81.85346984863304, 16.373470306396484],
        [81.85263824462908, 16.373470306396484],
        [81.85263824462908, 16.37319374084467],
        [81.85152435302734, 16.37319374084467],
        [81.85152435302734, 16.372917175293082],
        [81.85096740722662, 16.37291908264166],
        [81.85096740722662, 16.372640609741268],
        [81.85013580322283, 16.372640609741268],
        [81.85013580322283, 16.372358322143555],
        [81.84986114501959, 16.372358322143555],
        [81.84986114501959, 16.372079849243164],
        [81.84902954101562, 16.372083663940487],
        [81.84902954101562, 16.371805191040153],
        [81.84847259521484, 16.37181091308588],
        [81.84847259521484, 16.371530532836914],
        [81.84764099121088, 16.371530532836914],
        [81.84764099121088, 16.37124824523937],
        [81.84597015380876, 16.37124824523937],
        [81.84597015380876, 16.37096977233898],
        [81.84569549560575, 16.370971679687557],
        [81.84569549560575, 16.3706951141358],
        [81.84541320800781, 16.370700836181697],
        [81.84541320800781, 16.37042045593256],
        [81.84401702880854, 16.37042045593256],
        [81.84401702880854, 16.370138168335018],
        [81.84319305419928, 16.370138168335018],
        [81.84319305419928, 16.369859695434627],
        [81.84236145019531, 16.369861602783203],
        [81.84236145019531, 16.369583129882812],
        [81.84180450439459, 16.369583129882812],
        [81.84180450439459, 16.369304656982422],
        [81.8412475585938, 16.369304656982422],
        [81.8412475585938, 16.369026184082088],
        [81.84097290039057, 16.369028091430664],
        [81.84097290039057, 16.368749618530273],
        [81.84014129638678, 16.368749618530273],
        [81.84014129638678, 16.368459701538143],
        [81.83958435058622, 16.36847305297846],
        [81.83958435058622, 16.368194580078068],
        [81.83902740478544, 16.368194580078068],
        [81.83902740478544, 16.367916107177734],
        [81.8381958007813, 16.367919921875057],
        [81.8381958007813, 16.36763954162609],
        [81.83790588378918, 16.36763954162609],
        [81.83790588378918, 16.3673610687257],
        [81.83735656738298, 16.3673610687257],
        [81.83735656738298, 16.367080688476562],
        [81.83708190917974, 16.36708259582514],
        [81.83708190917974, 16.366804122924748],
        [81.83596801757841, 16.366809844970703],
        [81.83596801757841, 16.366529464721737],
        [81.83513641357428, 16.366529464721737],
        [81.83513641357428, 16.366250991821346],
        [81.8345870971682, 16.366250991821346],
        [81.8345870971682, 16.36597061157238],
        [81.8337478637697, 16.365972518920955],
        [81.8337478637697, 16.365695953369197],
        [81.83347320556669, 16.365695953369197],
        [81.83347320556669, 16.365417480468807],
        [81.83293151855497, 16.365419387817383],
        [81.83293151855497, 16.365140914916992],
        [81.83235931396513, 16.365140914916992],
        [81.83235931396513, 16.364860534668026],
        [81.83207702636724, 16.364860534668026],
        [81.83207702636724, 16.36458015441906],
        [81.83125305175798, 16.364585876464844],
        [81.83125305175798, 16.364305496215877],
        [81.8306961059572, 16.364311218261776],
        [81.8306961059572, 16.36403083801264],
        [81.8304061889649, 16.36403083801264],
        [81.8304061889649, 16.363750457763672],
        [81.83013916015642, 16.363750457763672],
        [81.83013916015642, 16.363470077514705],
        [81.82875061035168, 16.363473892211857],
        [81.82875061035168, 16.363195419311523],
        [81.8284759521485, 16.36318969726574],
        [81.8284759521485, 16.362916946411133],
        [81.8281860351562, 16.362920761108455],
        [81.8281860351562, 16.362649917602653],
        [81.82763671875017, 16.362649917602653],
        [81.82763671875017, 16.362361907958928],
        [81.82707977294939, 16.36236381530773],
        [81.82707977294939, 16.362083435058537],
        [81.82653045654297, 16.362083435058537],
        [81.82653045654297, 16.361804962158146],
        [81.82596588134766, 16.3618106842041],
        [81.82596588134766, 16.361530303955135],
        [81.82485961914085, 16.361530303955135],
        [81.82485961914085, 16.3618106842041],
        [81.8245925903322, 16.3618106842041],
        [81.8245925903322, 16.361530303955135],
        [81.82402801513689, 16.361530303955135],
        [81.82402801513689, 16.361251831054744],
        [81.82347106933594, 16.361251831054744],
        [81.82347106933594, 16.36096954345703],
        [81.82291412353516, 16.36096954345703],
        [81.82291412353516, 16.36069488525402],
        [81.82236480712896, 16.36069488525402],
        [81.82236480712896, 16.36041641235363],
        [81.82180786132835, 16.36042022705078],
        [81.82180786132835, 16.36014175415039],
        [81.82153320312517, 16.36014175415039],
        [81.82153320312517, 16.359859466552848],
        [81.8212509155274, 16.359859466552848],
        [81.8212509155274, 16.359582901001033],
        [81.82069396972685, 16.359582901001033],
        [81.82069396972685, 16.359306335449276],
        [81.82041931152344, 16.359310150146428],
        [81.82041931152344, 16.359031677246037],
        [81.81958007812528, 16.359031677246037],
        [81.81958007812528, 16.358749389648494],
        [81.8190307617187, 16.358749389648494],
        [81.8190307617187, 16.358470916748104],
        [81.81874847412115, 16.35847282409668],
        [81.81874847412115, 16.35819435119629],
        [81.81847381591814, 16.35819435119629],
        [81.81847381591814, 16.357915878295955],
        [81.81819152832054, 16.357919692993278],
        [81.81819152832054, 16.357641220092717],
        [81.81735992431658, 16.357641220092717],
        [81.81735992431658, 16.35736083984375],
        [81.81708526611357, 16.35736083984375],
        [81.81708526611357, 16.357084274291935],
        [81.81625366210943, 16.357084274291935],
        [81.81625366210943, 16.3568058013916],
        [81.81540679931635, 16.356809616088924],
        [81.81540679931635, 16.35651969909668],
        [81.81513977050804, 16.35652732849121],
        [81.81513977050804, 16.356250762939567],
        [81.81430816650408, 16.356250762939567],
        [81.81430816650408, 16.35597038269043],
        [81.81402587890653, 16.355972290039006],
        [81.81402587890653, 16.355693817138615],
        [81.81346893310575, 16.355693817138615],
        [81.81346893310575, 16.355417251587028],
        [81.81291961669933, 16.35542106628418],
        [81.81291961669933, 16.355140686035213],
        [81.81236267089838, 16.355140686035213],
        [81.81236267089838, 16.354860305786246],
        [81.81208038330101, 16.354860305786246],
        [81.81208038330101, 16.35457992553711],
        [81.81153106689459, 16.354583740234432],
        [81.81153106689459, 16.354305267334098],
        [81.8109664916995, 16.354310989379826],
        [81.8109664916995, 16.35403060913086],
        [81.81041717529325, 16.35403060913086],
        [81.81041717529325, 16.353750228881893],
        [81.80902862548851, 16.353750228881893],
        [81.80902862548851, 16.35346031188959],
        [81.80847167968756, 16.353471755981502],
        [81.80847167968756, 16.353195190429744],
        [81.80819702148455, 16.353200912475643],
        [81.80819702148455, 16.352920532226506],
        [81.8076400756836, 16.352920532226506],
        [81.8076400756836, 16.35264015197754],
        [81.80708312988281, 16.35264015197754],
        [81.80708312988281, 16.352359771728572],
        [81.8068084716798, 16.35236167907715],
        [81.8068084716798, 16.352083206176758],
        [81.80652618408209, 16.352083206176758],
        [81.80652618408209, 16.351804733276367],
        [81.80625152587885, 16.351804733276367],
        [81.80625152587885, 16.35152816772461],
        [81.80570220947283, 16.351530075073356],
        [81.80570220947283, 16.35124969482422],
        [81.80541992187506, 16.35124969482422],
        [81.80541992187506, 16.350971221923828],
        [81.80513763427751, 16.350973129272404],
        [81.80513763427751, 16.350694656372013],
        [81.80430603027372, 16.350694656372013],
        [81.80430603027372, 16.35041618347168],
        [81.80374908447294, 16.350419998169002],
        [81.80374908447294, 16.350139617920036],
        [81.80346679687506, 16.350139617920036],
        [81.80346679687506, 16.349861145019645],
        [81.8029174804688, 16.349861145019645],
        [81.8029174804688, 16.349584579467887],
        [81.80208587646501, 16.349584579467887],
        [81.80208587646501, 16.349306106567496],
        [81.80152893066423, 16.34930992126465],
        [81.80152893066423, 16.349029541015682],
        [81.80124664306669, 16.349029541015682],
        [81.80124664306669, 16.34875106811529],
        [81.80069732666044, 16.34875106811529],
        [81.80069732666044, 16.3484725952149],
        [81.80014038085949, 16.3484725952149],
        [81.80014038085949, 16.34819412231451],
        [81.79985809326178, 16.34819412231451],
        [81.79985809326178, 16.347917556762752],
        [81.7993087768557, 16.347919464111328],
        [81.7993087768557, 16.347642898559513],
        [81.79875183105474, 16.347642898559513],
        [81.79875183105474, 16.347362518310547],
        [81.79792022705078, 16.347362518310547],
        [81.79792022705078, 16.347080230713004],
        [81.79736328125006, 16.347084045410156],
        [81.79736328125006, 16.346805572509822],
        [81.79679870605474, 16.346809387207145],
        [81.79679870605474, 16.346532821655387],
        [81.79650878906261, 16.346532821655387],
        [81.79650878906261, 16.34624862670904],
        [81.79596710205095, 16.34624862670904],
        [81.79596710205095, 16.345968246460075],
        [81.79569244384771, 16.34597015380865],
        [81.79569244384771, 16.345693588256836],
        [81.79513549804716, 16.345693588256836],
        [81.79513549804716, 16.345418930053825],
        [81.79458618164091, 16.3454208374024],
        [81.79458618164091, 16.345138549804688],
        [81.79402923583996, 16.345138549804688],
        [81.79402923583996, 16.34485816955572],
        [81.793472290039, 16.344860076904297],
        [81.793472290039, 16.344583511352482],
        [81.79291534423845, 16.344583511352482],
        [81.79291534423845, 16.34430503845209],
        [81.79235839843767, 16.344310760498047],
        [81.79235839843767, 16.34403038024908],
        [81.7920761108399, 16.34403038024908],
        [81.7920761108399, 16.343748092651367],
        [81.79180908203125, 16.343748092651367],
        [81.79180908203125, 16.343469619750977],
        [81.79125213623047, 16.343471527099723],
        [81.79125213623047, 16.343194961547965],
        [81.79070281982428, 16.343200683593693],
        [81.79070281982428, 16.342920303344727],
        [81.79013824462896, 16.342920303344727],
        [81.79013824462896, 16.34263992309576],
        [81.7898559570312, 16.34263992309576],
        [81.7898559570312, 16.342359542846793],
        [81.78930664062517, 16.34236145019537],
        [81.78930664062517, 16.34208297729498],
        [81.78874969482439, 16.34208297729498],
        [81.78874969482439, 16.34180641174322],
        [81.78819274902344, 16.341810226440373],
        [81.78819274902344, 16.3415203094483],
        [81.78763580322271, 16.34152793884283],
        [81.78763580322271, 16.34124946594244],
        [81.78736114501964, 16.34124946594244],
        [81.78736114501964, 16.34097099304205],
        [81.7868041992187, 16.340972900390625],
        [81.7868041992187, 16.340694427490234],
        [81.7859725952149, 16.340694427490234],
        [81.7859725952149, 16.340414047241268],
        [81.78514099121094, 16.340419769287223],
        [81.78514099121094, 16.340139389038086],
        [81.7845687866211, 16.340139389038086],
        [81.7845687866211, 16.339860916137695],
        [81.78430175781261, 16.339860916137695],
        [81.78430175781261, 16.33958053588873],
        [81.78402709960943, 16.339582443237305],
        [81.78402709960943, 16.339305877685547],
        [81.7837524414062, 16.33930969238287],
        [81.7837524414062, 16.33903121948248],
        [81.78347015380882, 16.33903121948248],
        [81.78347015380882, 16.338750839233512],
        [81.78291320800787, 16.338750839233512],
        [81.78291320800787, 16.338479995727482],
        [81.7820816040039, 16.338479995727482],
        [81.7820816040039, 16.33819007873541],
        [81.78180694580107, 16.338195800781364],
        [81.78180694580107, 16.337917327880973],
        [81.78098297119146, 16.337921142578125],
        [81.78098297119146, 16.33764076232916],
        [81.78041839599632, 16.33764076232916],
        [81.78041839599632, 16.337360382080192],
        [81.77986145019537, 16.337360382080192],
        [81.77986145019537, 16.337080001831055],
        [81.77930450439482, 16.337083816528377],
        [81.77930450439482, 16.336805343628043],
        [81.77847290039062, 16.33681106567377],
        [81.77847290039062, 16.336530685424805],
        [81.7779159545899, 16.336530685424805],
        [81.7779159545899, 16.336250305175838],
        [81.77764129638666, 16.336250305175838],
        [81.77764129638666, 16.335971832275447],
        [81.77708435058611, 16.335973739624023],
        [81.77708435058611, 16.33569526672369],
        [81.77652740478533, 16.33570289611822],
        [81.77652740478533, 16.33542060852045],
        [81.77625274658232, 16.33542060852045],
        [81.77625274658232, 16.335140228271484],
        [81.775688171387, 16.335140228271484],
        [81.775688171387, 16.334861755371094],
        [81.77513885498075, 16.33486366271967],
        [81.77513885498075, 16.334583282470703],
        [81.7745819091798, 16.334583282470703],
        [81.7745819091798, 16.334304809570312],
        [81.77402496337885, 16.334304809570312],
        [81.77402496337885, 16.334028244018555],
        [81.77291870117205, 16.3340301513673],
        [81.77291870117205, 16.33375167846674],
        [81.7723617553711, 16.33375167846674],
        [81.7723617553711, 16.333469390869197],
        [81.77208709716825, 16.333469390869197],
        [81.77208709716825, 16.33319473266596],
        [81.77097320556635, 16.33319473266596],
        [81.77097320556635, 16.332916259765625],
        [81.77014160156256, 16.332920074462947],
        [81.77014160156256, 16.332641601562557],
        [81.769584655762, 16.332641601562557],
        [81.769584655762, 16.332359313964844],
        [81.76930236816406, 16.332359313964844],
        [81.76930236816406, 16.33208274841303],
        [81.76902770996122, 16.33208274841303],
        [81.76902770996122, 16.331806182861442],
        [81.76763916015648, 16.33181190490717],
        [81.76763916015648, 16.33152770996105],
        [81.76708221435553, 16.33152770996105],
        [81.76708221435553, 16.33124923706066],
        [81.76597595214872, 16.33124923706066],
        [81.76597595214872, 16.33097076416027],
        [81.76569366455078, 16.330972671508846],
        [81.76569366455078, 16.330694198608455],
        [81.76513671875006, 16.330694198608455],
        [81.76513671875006, 16.330419540405273],
        [81.76457977294928, 16.330419540405273],
        [81.76457977294928, 16.330139160156307],
        [81.76403045654303, 16.330139160156307],
        [81.76403045654303, 16.329860687255916],
        [81.76345825195318, 16.329860687255916],
        [81.76345825195318, 16.32958030700695],
        [81.76264190673828, 16.3295841217041],
        [81.76264190673828, 16.329305648803768],
        [81.76235961914091, 16.32930946350109],
        [81.76235961914091, 16.32903099060053],
        [81.76097106933616, 16.32903099060053],
        [81.76097106933616, 16.328750610351562],
        [81.76039886474632, 16.328750610351562],
        [81.76039886474632, 16.328470230102596],
        [81.75958251953125, 16.328472137451172],
        [81.75958251953125, 16.32819366455078],
        [81.75902557373053, 16.32819366455078],
        [81.75902557373053, 16.32791519165039],
        [81.75875091552746, 16.327920913696346],
        [81.75875091552746, 16.32764053344738],
        [81.75791931152366, 16.32764053344738],
        [81.75791931152366, 16.327360153198242],
        [81.75680541992216, 16.327362060546818],
        [81.75680541992216, 16.327083587646428],
        [81.75624847412138, 16.327083587646428],
        [81.75624847412138, 16.326805114746037],
        [81.75569152832043, 16.326810836791992],
        [81.75569152832043, 16.326538085937557],
        [81.755142211914, 16.326538085937557],
        [81.755142211914, 16.32625007629406],
        [81.75430297851568, 16.32625007629406],
        [81.75430297851568, 16.325969696044922],
        [81.75375366210966, 16.32597160339367],
        [81.75375366210966, 16.325693130493107],
        [81.75319671630888, 16.32570075988764],
        [81.75319671630888, 16.325420379638672],
        [81.75263977050793, 16.325420379638672],
        [81.75263977050793, 16.325139999389705],
        [81.75125122070318, 16.325139999389705],
        [81.75125122070318, 16.32485961914074],
        [81.75069427490263, 16.324861526489315],
        [81.75069427490263, 16.324583053588924],
        [81.75041961669922, 16.324583053588924],
        [81.75041961669922, 16.324306488037166],
        [81.75013732910185, 16.324310302734318],
        [81.75013732910185, 16.32402992248535],
        [81.74931335449224, 16.32402992248535],
        [81.74931335449224, 16.323749542236385],
        [81.74845886230463, 16.323749542236385],
        [81.74845886230463, 16.3234806060791],
        [81.74791717529314, 16.3234806060791],
        [81.74791717529314, 16.323190689087028],
        [81.74736022949236, 16.32319450378418],
        [81.74736022949236, 16.322916030883846],
        [81.74709320068388, 16.32291984558117],
        [81.74709320068388, 16.32263946533203],
        [81.74624633789068, 16.32263946533203],
        [81.74624633789068, 16.322362899780217],
        [81.74539947509777, 16.322362899780217],
        [81.74539947509777, 16.322080612182674],
        [81.74485778808611, 16.322080612182674],
        [81.74485778808611, 16.321805953979492],
        [81.7437515258789, 16.321809768676815],
        [81.7437515258789, 16.321529388427848],
        [81.74291992187511, 16.321529388427848],
        [81.74291992187511, 16.321252822876033],
        [81.74208068847679, 16.321252822876033],
        [81.74208068847679, 16.320972442626896],
        [81.74153137207037, 16.320972442626896],
        [81.74153137207037, 16.32069206237793],
        [81.74097442626982, 16.32069206237793],
        [81.74097442626982, 16.32041740417492],
        [81.74041748046903, 16.32041740417492],
        [81.74041748046903, 16.320140838623104],
        [81.7395858764649, 16.320140838623104],
        [81.7395858764649, 16.32041740417492],
        [81.73874664306658, 16.32041740417492],
        [81.73874664306658, 16.32069206237793],
        [81.73847198486334, 16.32069206237793],
        [81.73847198486334, 16.320140838623104],
        [81.73874664306658, 16.32014274597168],
        [81.73874664306658, 16.319578170776424],
        [81.73847198486334, 16.319583892822322],
        [81.73847198486334, 16.31930541992199],
        [81.73792266845732, 16.31930923461914],
        [81.73792266845732, 16.31903076171875],
        [81.73680877685558, 16.31903076171875],
        [81.73680877685558, 16.318748474121207],
        [81.73651885986334, 16.318748474121207],
        [81.73651885986334, 16.31846809387207],
        [81.73596954345709, 16.318471908569393],
        [81.73596954345709, 16.318195343017635],
        [81.73568725585932, 16.318199157714957],
        [81.73568725585932, 16.317920684814396],
        [81.73486328125028, 16.317920684814396],
        [81.73486328125028, 16.317638397216854],
        [81.7343063354495, 16.317638397216854],
        [81.7343063354495, 16.317350387573356],
        [81.73402404785156, 16.31736183166504],
        [81.73402404785156, 16.31708335876465],
        [81.73319244384777, 16.31708335876465],
        [81.73319244384777, 16.31624984741211],
        [81.73291778564459, 16.31624984741211],
        [81.73291778564459, 16.315690994262752],
        [81.73263549804682, 16.315694808959904],
        [81.73263549804682, 16.31541633605957],
        [81.7320861816408, 16.315420150756893],
        [81.7320861816408, 16.315139770507926],
        [81.73069763183622, 16.315139770507926],
        [81.73069763183622, 16.314861297607536],
        [81.72958374023432, 16.314861297607536],
        [81.72958374023432, 16.314582824706974],
        [81.72930908203148, 16.314582824706974],
        [81.72930908203148, 16.314306259155387],
        [81.72846984863298, 16.31431007385254],
        [81.72846984863298, 16.314029693603572],
        [81.72791290283203, 16.314029693603572],
        [81.72791290283203, 16.313751220703182],
        [81.72680664062506, 16.313751220703182],
        [81.72680664062506, 16.313470840454215],
        [81.7256927490235, 16.31347274780279],
        [81.7256927490235, 16.3131942749024],
        [81.72458648681669, 16.3131942749024],
        [81.72458648681669, 16.312915802002067],
        [81.72402954101574, 16.31291961669922],
        [81.72402954101574, 16.312641143798828],
        [81.72347259521479, 16.312641143798828],
        [81.72347259521479, 16.31236076354986],
        [81.72319793701195, 16.31236076354986],
        [81.72319793701195, 16.312080383300895],
        [81.722640991211, 16.312084197998047],
        [81.722640991211, 16.311805725097713],
        [81.72235870361345, 16.311809539795036],
        [81.72235870361345, 16.31154060363781],
        [81.72207641601568, 16.31154060363781],
        [81.72207641601568, 16.311250686645508],
        [81.72180938720703, 16.311250686645508],
        [81.72180175781267, 16.31154060363781],
        [81.72097015380888, 16.31154060363781],
        [81.72097015380888, 16.310689926147575],
        [81.72069549560553, 16.310693740844727],
        [81.72069549560553, 16.31041717529297],
        [81.72041320800793, 16.31042098999029],
        [81.72041320800793, 16.310140609741325],
        [81.71901702880865, 16.310140609741325],
        [81.71901702880865, 16.31042098999029],
        [81.71875000000017, 16.31042098999029],
        [81.71875000000017, 16.31154060363781],
        [81.71901702880865, 16.31154060363781],
        [81.71901702880865, 16.311809539795036],
        [81.71930694580095, 16.311809539795036],
        [81.71930694580095, 16.31236076354986],
        [81.71958160400413, 16.31236076354986],
        [81.71958160400413, 16.312641143798828],
        [81.72013854980474, 16.312641143798828],
        [81.72013854980474, 16.31291961669922],
        [81.71986389160173, 16.312915802002067],
        [81.71986389160173, 16.3131942749024],
        [81.71930694580095, 16.3131942749024],
        [81.71930694580095, 16.31347274780279],
        [81.71902465820318, 16.31347274780279],
        [81.71902465820318, 16.314582824706974],
        [81.71930694580095, 16.314582824706974],
        [81.71930694580095, 16.31597137451172],
        [81.71958160400413, 16.31597137451172],
        [81.71958160400413, 16.316850662231502],
        [81.71958160400413, 16.31708335876465],
        [81.71986389160173, 16.31708335876465],
        [81.71986389160173, 16.31736183166504],
        [81.72013854980474, 16.317350387573356],
        [81.72013854980474, 16.317638397216854],
        [81.72041320800793, 16.317638397216854],
        [81.72041320800793, 16.317920684814396],
        [81.72069549560553, 16.317916870117244],
        [81.72069549560553, 16.318471908569393],
        [81.72019958496122, 16.31846809387207],
        [81.72013854980474, 16.318748474121207],
        [81.71958160400413, 16.318748474121207],
        [81.71958160400413, 16.31903076171875],
        [81.71901702880865, 16.31903076171875],
        [81.71901702880865, 16.31930923461914],
        [81.71819305419922, 16.31930541992199],
        [81.71819305419922, 16.319583892822322],
        [81.7176361083985, 16.319578170776424],
        [81.7176361083985, 16.319862365722713],
        [81.71708679199224, 16.319862365722713],
        [81.71708679199224, 16.32014274597168],
        [81.71624755859392, 16.320140838623104],
        [81.71624755859392, 16.32041740417492],
        [81.71597290039068, 16.32041740417492],
        [81.71597290039068, 16.319862365722713],
        [81.71569824218767, 16.319862365722713],
        [81.71569061279314, 16.319578170776424],
        [81.7145767211914, 16.319578170776424],
        [81.7145767211914, 16.319862365722713],
        [81.7143020629884, 16.319862365722713],
        [81.7143020629884, 16.319578170776424],
        [81.71296691894543, 16.319580078125],
        [81.71097564697271, 16.319583892822322],
        [81.71097564697271, 16.319862365722713],
        [81.71055603027344, 16.319862365722713],
        [81.7104187011721, 16.319862365722713],
        [81.7104187011721, 16.32041740417492],
        [81.71013641357439, 16.32041740417492],
        [81.71013641357439, 16.32069206237793],
        [81.70958709716814, 16.32069206237793],
        [81.70958709716814, 16.320972442626896],
        [81.70903015136719, 16.320972442626896],
        [81.70903015136719, 16.321252822876033],
        [81.70707702636713, 16.321252822876033],
        [81.70707702636713, 16.321529388427848],
        [81.70680236816429, 16.321529388427848],
        [81.70680236816429, 16.321809768676815],
        [81.7062530517581, 16.321805953979492],
        [81.7062530517581, 16.322080612182674],
        [81.70569610595732, 16.322080612182674],
        [81.70569610595732, 16.322362899780217],
        [81.70513916015653, 16.322362899780217],
        [81.70513916015653, 16.32263946533203],
        [81.70458221435558, 16.32263946533203],
        [81.70458221435558, 16.32291984558117],
        [81.70402526855463, 16.322916030883846],
        [81.70402526855463, 16.32319450378418],
        [81.70347595214861, 16.323190689087028],
        [81.70347595214861, 16.3234806060791],
        [81.70291900634783, 16.3234806060791],
        [81.70291900634783, 16.323749542236385],
        [81.70263671875028, 16.323749542236385],
        [81.70263671875028, 16.32402992248535],
        [81.7020797729495, 16.324028015136776],
        [81.7020797729495, 16.324583053588924],
        [81.70180511474615, 16.324583053588924],
        [81.70180511474615, 16.324861526489315],
        [81.70124816894537, 16.32485961914074],
        [81.70124816894537, 16.325139999389705],
        [81.70069122314476, 16.325139999389705],
        [81.70069122314476, 16.325420379638672],
        [81.6998596191408, 16.325420379638672],
        [81.6998596191408, 16.32570075988764],
        [81.69847106933605, 16.325693130493107],
        [81.69847106933605, 16.32597160339367],
        [81.69819641113287, 16.325969696044922],
        [81.69819641113287, 16.326538085937557],
        [81.69790649414074, 16.326538085937557],
        [81.69790649414074, 16.326810836791992],
        [81.69763946533226, 16.326805114746037],
        [81.69763946533226, 16.327083587646428],
        [81.69652557373075, 16.327083587646428],
        [81.69652557373075, 16.327362060546818],
        [81.69541931152355, 16.327360153198242],
        [81.69541931152355, 16.32764053344738],
        [81.69513702392584, 16.32764053344738],
        [81.69513702392584, 16.327920913696346],
        [81.69458007812506, 16.32791519165039],
        [81.69458007812506, 16.32819366455078],
        [81.69430541992205, 16.32819366455078],
        [81.69430541992205, 16.328472137451172],
        [81.69374847412126, 16.328470230102596],
        [81.69374847412126, 16.328750610351562],
        [81.69209289550787, 16.328750610351562],
        [81.69209289550787, 16.32903099060053],
        [81.69180297851574, 16.32903099060053],
        [81.69180297851574, 16.32930946350109],
        [81.69152832031256, 16.329305648803768],
        [81.69152832031256, 16.3295841217041],
        [81.69124603271479, 16.32958030700695],
        [81.69124603271479, 16.329860687255916],
        [81.69040679931646, 16.329860687255916],
        [81.69040679931646, 16.330139160156307],
        [81.69013977050798, 16.330139160156307],
        [81.69013977050798, 16.330419540405273],
        [81.68958282470703, 16.330419540405273],
        [81.68958282470703, 16.330694198608455],
        [81.68875122070324, 16.330694198608455],
        [81.68875122070324, 16.330972671508846],
        [81.68846893310553, 16.33097076416027],
        [81.68846893310553, 16.33124923706066],
        [81.6881790161134, 16.33124923706066],
        [81.6881790161134, 16.33152770996105],
        [81.6873626708985, 16.33152770996105],
        [81.6873626708985, 16.33181190490717],
        [81.68653106689453, 16.331806182861442],
        [81.68653106689453, 16.33208274841303],
        [81.68486022949241, 16.33208274841303],
        [81.68486022949241, 16.332359313964844],
        [81.68458557128923, 16.332359313964844],
        [81.68459320068376, 16.332641601562557],
        [81.68402862548845, 16.332641601562557],
        [81.68402862548845, 16.332920074462947],
        [81.68374633789091, 16.332920074462947],
        [81.68374633789091, 16.332641601562557],
        [81.68096923828142, 16.332641601562557],
        [81.68096923828142, 16.33234977722168],
        [81.680419921875, 16.332359313964844],
        [81.680419921875, 16.33208274841303],
        [81.6793060302735, 16.33208274841303],
        [81.6793060302735, 16.331806182861442],
        [81.67903137207026, 16.331806182861442],
        [81.67903137207026, 16.33208274841303],
        [81.67874908447271, 16.33208274841303],
        [81.67874908447271, 16.331806182861442],
        [81.67791748046892, 16.331806182861442],
        [81.67791748046892, 16.33208274841303],
        [81.67736053466814, 16.33208274841303],
        [81.67736053466814, 16.332359313964844],
        [81.67652893066435, 16.33234977722168],
        [81.67652893066435, 16.332641601562557],
        [81.67569732666021, 16.332641601562557],
        [81.67569732666021, 16.332920074462947],
        [81.67485809326189, 16.332916259765625],
        [81.67485809326189, 16.33319473266596],
        [81.67430877685564, 16.33319473266596],
        [81.67430877685564, 16.333469390869197],
        [81.67375183105469, 16.333469390869197],
        [81.67375183105469, 16.33375167846674],
        [81.67346954345732, 16.33375167846674],
        [81.67346954345732, 16.333469390869197],
        [81.66874694824236, 16.333469390869197],
        [81.66874694824236, 16.33375167846674],
        [81.66847229003912, 16.33375167846674],
        [81.66847229003912, 16.333469390869197],
        [81.66791534423857, 16.333469390869197],
        [81.66791534423857, 16.33375167846674],
        [81.66735839843778, 16.33375167846674],
        [81.66735839843778, 16.333469390869197],
        [81.66569519042986, 16.333469390869197],
        [81.66570281982439, 16.33375167846674],
        [81.66179656982428, 16.33375167846674],
        [81.66179656982428, 16.333469390869197],
        [81.66041564941412, 16.333469390869197],
        [81.66041564941412, 16.33319473266596],
        [81.66014099121105, 16.33319473266596],
        [81.66014099121105, 16.333469390869197],
        [81.65957641601557, 16.333469390869197],
        [81.6595840454101, 16.33375167846674],
        [81.65820312500028, 16.33375167846674],
        [81.65820312500028, 16.3340301513673],
        [81.65513610839872, 16.334028244018555],
        [81.65513610839872, 16.334304809570312],
        [81.65402984619152, 16.334304809570312],
        [81.65402984619152, 16.334583282470703],
        [81.65264129638678, 16.334583282470703],
        [81.65264129638678, 16.334304809570312],
        [81.65208435058622, 16.334304809570312],
        [81.65208435058622, 16.334028244018555],
        [81.6506958007813, 16.334028244018555],
        [81.6506958007813, 16.334304809570312],
        [81.65041351318371, 16.334304809570312],
        [81.65041351318371, 16.334583282470703],
        [81.64902496337896, 16.334583282470703],
        [81.64902496337896, 16.334304809570312],
        [81.64791870117216, 16.334304809570312],
        [81.64791870117216, 16.334583282470703],
        [81.64763641357428, 16.334583282470703],
        [81.64763641357428, 16.33486366271967],
        [81.64653015136741, 16.33486366271967],
        [81.64653015136741, 16.334583282470703],
        [81.6451416015625, 16.334583282470703],
        [81.6451416015625, 16.33486366271967],
        [81.64457702636719, 16.334861755371094],
        [81.64457702636719, 16.335140228271484],
        [81.6409759521485, 16.335140228271484],
        [81.6409759521485, 16.33542060852045],
        [81.63903045654297, 16.33542060852045],
        [81.63903045654297, 16.33570289611822],
        [81.63791656494146, 16.33569526672369],
        [81.63791656494146, 16.335973739624023],
        [81.63652801513689, 16.335971832275447],
        [81.63652801513689, 16.336250305175838],
        [81.6356964111331, 16.336250305175838],
        [81.6356964111331, 16.335971832275447],
        [81.63485717773443, 16.335971832275447],
        [81.63486480712896, 16.336250305175838],
        [81.63403320312517, 16.336250305175838],
        [81.63403320312517, 16.336530685424805],
        [81.63263702392607, 16.336530685424805],
        [81.63263702392607, 16.336250305175838],
        [81.63181304931646, 16.336250305175838],
        [81.63181304931646, 16.336530685424805],
        [81.63125610351568, 16.336530685424805],
        [81.63125610351568, 16.33681106567377],
        [81.62791442871088, 16.336805343628043],
        [81.62791442871088, 16.337083816528377],
        [81.62763977050804, 16.337080001831055],
        [81.62763977050804, 16.337360382080192],
        [81.62625122070312, 16.337360382080192],
        [81.62625122070312, 16.337080001831055],
        [81.62458038330084, 16.337080001831055],
        [81.62458038330084, 16.337360382080192],
        [81.62374877929705, 16.337360382080192],
        [81.62374877929705, 16.33764076232916],
        [81.62319183349626, 16.33764076232916],
        [81.62319183349626, 16.337921142578125],
        [81.62291717529325, 16.337921142578125],
        [81.62291717529325, 16.33764076232916],
        [81.62014007568376, 16.33764076232916],
        [81.62014007568376, 16.337921142578125],
        [81.61985778808622, 16.337917327880973],
        [81.61985778808622, 16.338195800781364],
        [81.61902618408209, 16.338195800781364],
        [81.61902618408209, 16.337917327880973],
        [81.6184692382813, 16.337917327880973],
        [81.6184692382813, 16.338195800781364],
        [81.61708068847673, 16.33819007873541],
        [81.61708068847673, 16.338479995727482],
        [81.61624908447294, 16.338479995727482],
        [81.61624908447294, 16.338750839233512],
        [81.61569213867199, 16.338750839233512],
        [81.61569213867199, 16.33903121948248],
        [81.6154174804688, 16.33903121948248],
        [81.6154174804688, 16.33930969238287],
        [81.61458587646501, 16.33930969238287],
        [81.61458587646501, 16.33903121948248],
        [81.61318969726591, 16.33903121948248],
        [81.61318969726591, 16.33930969238287],
        [81.61069488525419, 16.339305877685547],
        [81.61069488525419, 16.339582443237305],
        [81.60958099365263, 16.33958053588873],
        [81.60958099365263, 16.339860916137695],
        [81.60846710205072, 16.339860916137695],
        [81.60847473144548, 16.340139389038086],
        [81.60763549804693, 16.340139389038086],
        [81.60763549804693, 16.339860916137695],
        [81.60652923584013, 16.339860916137695],
        [81.60652923584013, 16.340139389038086],
        [81.60485839843767, 16.340139389038086],
        [81.60485839843767, 16.340419769287223],
        [81.60291290283214, 16.340414047241268],
        [81.60291290283214, 16.340694427490234],
        [81.60013580322266, 16.340694427490234],
        [81.60013580322266, 16.340972900390625],
        [81.59764099121094, 16.34097099304205],
        [81.59764099121094, 16.34124946594244],
        [81.59708404541016, 16.34124946594244],
        [81.59708404541016, 16.34152793884283],
        [81.5940170288086, 16.3415203094483],
        [81.5940170288086, 16.341810226440373],
        [81.59347534179693, 16.34180641174322],
        [81.59347534179693, 16.34208297729498],
        [81.59014129638683, 16.34208297729498],
        [81.59014129638683, 16.34236145019537],
        [81.58819580078153, 16.342359542846793],
        [81.58819580078153, 16.34263992309576],
        [81.58651733398449, 16.34263992309576],
        [81.58651733398449, 16.342920303344727],
        [81.58541870117205, 16.342920303344727],
        [81.58541870117205, 16.343200683593693],
        [81.58430480957031, 16.343194961547965],
        [81.58430480957031, 16.343471527099723],
        [81.58347320556635, 16.343469619750977],
        [81.58347320556635, 16.343748092651367],
        [81.58264160156256, 16.343748092651367],
        [81.58264160156256, 16.343469619750977],
        [81.5801391601563, 16.343471527099723],
        [81.58014678955101, 16.343748092651367],
        [81.5795822143557, 16.343748092651367],
        [81.5795822143557, 16.34403038024908],
        [81.57818603515625, 16.34403038024908],
        [81.57818603515625, 16.343748092651367],
        [81.57707977294928, 16.343748092651367],
        [81.57707977294928, 16.34403038024908],
        [81.57457733154297, 16.34403038024908],
        [81.57457733154297, 16.344310760498047],
        [81.57375335693354, 16.34430503845209],
        [81.57375335693354, 16.344583511352482],
        [81.57291412353521, 16.344583511352482],
        [81.57291412353521, 16.34430503845209],
        [81.57208251953125, 16.34430503845209],
        [81.57208251953125, 16.344583511352482],
        [81.56874847412138, 16.344583511352482],
        [81.56874847412138, 16.344860076904297],
        [81.56680297851585, 16.34485816955572],
        [81.56680297851585, 16.345138549804688],
        [81.56652832031267, 16.345138549804688],
        [81.56652832031267, 16.3454208374024],
        [81.56597137451189, 16.3454208374024],
        [81.56597137451189, 16.345138549804688],
        [81.56569671630888, 16.345138549804688],
        [81.56569671630888, 16.3454208374024],
        [81.56430816650396, 16.3454208374024],
        [81.56430816650396, 16.345138549804688],
        [81.56318664550787, 16.345138549804688],
        [81.56318664550787, 16.3454208374024],
        [81.5609741210937, 16.345418930053825],
        [81.56096649169916, 16.345581054687557],
        [81.5609741210937, 16.345693588256836],
        [81.5601425170899, 16.345693588256836],
        [81.5601425170899, 16.34597015380865],
        [81.55958557128935, 16.34597015380865],
        [81.55958557128935, 16.34624862670904],
        [81.5590362548831, 16.34624862670904],
        [81.5590362548831, 16.346532821655387],
        [81.55874633789062, 16.346527099609432],
        [81.55874633789062, 16.347084045410156],
        [81.5590362548831, 16.347080230713004],
        [81.5590362548831, 16.347919464111328],
        [81.55959320068388, 16.347919464111328],
        [81.55959320068388, 16.34875106811529],
        [81.55986022949236, 16.34875106811529],
        [81.55986022949236, 16.349029541015682],
        [81.5601425170899, 16.349027633667106],
        [81.5601425170899, 16.349584579467887],
        [81.55986022949236, 16.349584579467887],
        [81.55986022949236, 16.350694656372013],
        [81.55959320068388, 16.35069084167486],
        [81.55959320068388, 16.351530075073356],
        [81.55486297607416, 16.351530075073356],
        [81.55486297607416, 16.35124969482422],
        [81.55264282226562, 16.35124969482422],
        [81.55264282226562, 16.351530075073356],
        [81.5518035888673, 16.35152816772461],
        [81.5518035888673, 16.351804733276367],
        [81.55124664306635, 16.351804733276367],
        [81.55124664306635, 16.352083206176758],
        [81.55097198486351, 16.352083206176758],
        [81.55097198486351, 16.35236167907715],
        [81.55069732666033, 16.352359771728572],
        [81.55069732666033, 16.35264015197754],
        [81.55042266845732, 16.35264015197754],
        [81.55042266845732, 16.353200912475643],
        [81.54846954345709, 16.353195190429744],
        [81.54846954345709, 16.353471755981502],
        [81.54792022705101, 16.35346031188959],
        [81.54792022705101, 16.35403060913086],
        [81.5476379394533, 16.35403060913086],
        [81.5476379394533, 16.354310989379826],
        [81.54708099365251, 16.354310989379826],
        [81.54708099365251, 16.354860305786246],
        [81.5465164184572, 16.354860305786246],
        [81.5465164184572, 16.355140686035213],
        [81.54596710205078, 16.355140686035213],
        [81.54596710205078, 16.35542106628418],
        [81.54569244384777, 16.355417251587028],
        [81.54569244384777, 16.355693817138615],
        [81.54541778564459, 16.355693817138615],
        [81.54541778564459, 16.355972290039006],
        [81.54513549804682, 16.355972290039006],
        [81.54514312744135, 16.356250762939567],
        [81.54486083984398, 16.356250762939567],
        [81.54486083984398, 16.35652732849121],
        [81.5445861816408, 16.35651969909668],
        [81.5445861816408, 16.356809616088924],
        [81.54430389404303, 16.3568058013916],
        [81.54430389404303, 16.357084274291935],
        [81.54402923584001, 16.357084274291935],
        [81.54403686523455, 16.35736083984375],
        [81.54347229003906, 16.35736083984375],
        [81.54347229003906, 16.357641220092717],
        [81.54319763183622, 16.357641220092717],
        [81.54319763183622, 16.357919692993278],
        [81.54291534423828, 16.357915878295955],
        [81.54291534423828, 16.35819435119629],
        [81.54180908203148, 16.35819435119629],
        [81.54180908203148, 16.35847282409668],
        [81.54125213623053, 16.358470916748104],
        [81.54125213623053, 16.358749389648494],
        [81.54096984863298, 16.358749389648494],
        [81.54096984863298, 16.359031677246037],
        [81.53791046142595, 16.359031677246037],
        [81.53791046142595, 16.358749389648494],
        [81.53708648681669, 16.358749389648494],
        [81.53708648681669, 16.359031677246037],
        [81.53624725341803, 16.359031677246037],
        [81.53624725341803, 16.358749389648494],
        [81.53457641601568, 16.358749389648494],
        [81.53457641601568, 16.358470916748104],
        [81.53402709960966, 16.35847282409668],
        [81.53402709960966, 16.35819435119629],
        [81.53319549560553, 16.35819435119629],
        [81.53319549560553, 16.357915878295955],
        [81.53291320800793, 16.357919692993278],
        [81.53291320800793, 16.357641220092717],
        [81.53263854980474, 16.357641220092717],
        [81.53263854980474, 16.35736083984375],
        [81.53151702880882, 16.35736083984375],
        [81.53151702880882, 16.357080459594783],
        [81.53125000000017, 16.357084274291935],
        [81.53125000000017, 16.3568058013916],
        [81.53098297119169, 16.356809616088924],
        [81.53098297119169, 16.356250762939567],
        [81.53069305419922, 16.356250762939567],
        [81.53069305419922, 16.355690002441463],
        [81.53041839599638, 16.355693817138615],
        [81.53041839599638, 16.355138778686637],
        [81.53013610839844, 16.355140686035213],
        [81.53013610839844, 16.354860305786246],
        [81.52986145019543, 16.354860305786246],
        [81.52986145019543, 16.35457992553711],
        [81.52902984619163, 16.354583740234432],
        [81.52902984619163, 16.354305267334098],
        [81.52791595459013, 16.354310989379826],
        [81.52791595459013, 16.35403060913086],
        [81.52652740478521, 16.354028701782283],
        [81.52652740478521, 16.354583740234432],
        [81.52625274658197, 16.35457992553711],
        [81.52625274658197, 16.354860305786246],
        [81.52458190917986, 16.354860305786246],
        [81.52458190917986, 16.355140686035213],
        [81.52430725097685, 16.355140686035213],
        [81.52430725097685, 16.35542106628418],
        [81.5240249633789, 16.355417251587028],
        [81.5240249633789, 16.355693817138615],
        [81.52375030517607, 16.355693817138615],
        [81.52375030517607, 16.35652732849121],
        [81.52403259277344, 16.35651969909668],
        [81.52403259277344, 16.356809616088924],
        [81.52430725097685, 16.3568058013916],
        [81.52430725097685, 16.35819435119629],
        [81.52291870117193, 16.35819435119629],
        [81.52291870117193, 16.357915878295955],
        [81.52236175537132, 16.357919692993278],
        [81.52236175537132, 16.357641220092717],
        [81.52124786376982, 16.357641220092717],
        [81.52124786376982, 16.357919692993278],
        [81.52041625976568, 16.357915878295955],
        [81.52041625976568, 16.35819435119629],
        [81.51958465576166, 16.35819435119629],
        [81.51958465576166, 16.35847282409668],
        [81.51875305175787, 16.35847282409668],
        [81.51875305175787, 16.35819435119629],
        [81.51819610595732, 16.35819435119629],
        [81.51819610595732, 16.357915878295955],
        [81.51763916015653, 16.357919692993278],
        [81.51763916015653, 16.357641220092717],
        [81.5173568725586, 16.357641220092717],
        [81.5173568725586, 16.35736083984375],
        [81.51708221435558, 16.35736083984375],
        [81.51708221435558, 16.357080459594783],
        [81.5168075561524, 16.357084274291935],
        [81.5168075561524, 16.3568058013916],
        [81.51653289794916, 16.356809616088924],
        [81.51653289794916, 16.35651969909668],
        [81.51597595214861, 16.35652732849121],
        [81.51597595214861, 16.355972290039006],
        [81.51569366455084, 16.355972290039006],
        [81.51569366455084, 16.355693817138615],
        [81.51541900634783, 16.355693817138615],
        [81.51541900634783, 16.355417251587028],
        [81.51513671875028, 16.35542106628418],
        [81.51513671875028, 16.355140686035213],
        [81.51486206054688, 16.355140686035213],
        [81.51486206054688, 16.354860305786246],
        [81.5145797729495, 16.354860305786246],
        [81.5145797729495, 16.35457992553711],
        [81.51374816894537, 16.354583740234432],
        [81.51374816894537, 16.354305267334098],
        [81.51291656494158, 16.354305267334098],
        [81.51291656494158, 16.354583740234432],
        [81.51069641113287, 16.354583740234432],
        [81.51069641113287, 16.354305267334098],
        [81.51013946533209, 16.354310989379826],
        [81.51013946533209, 16.35403060913086],
        [81.50985717773432, 16.35403060913086],
        [81.50985717773432, 16.353750228881893],
        [81.5093078613283, 16.353750228881893],
        [81.5093078613283, 16.353200912475643],
        [81.50903320312528, 16.353200912475643],
        [81.50903320312528, 16.35264015197754],
        [81.50875091552751, 16.35264015197754],
        [81.50875091552751, 16.352359771728572],
        [81.50763702392584, 16.352359771728572],
        [81.50763702392584, 16.35264015197754],
        [81.50708007812506, 16.35264015197754],
        [81.50708007812506, 16.352920532226506],
        [81.50653076171898, 16.352920532226506],
        [81.50653076171898, 16.353200912475643],
        [81.50569152832048, 16.353200912475643],
        [81.50569152832048, 16.352920532226506],
        [81.50541687011747, 16.352920532226506],
        [81.50541687011747, 16.35264015197754],
        [81.50486755371122, 16.35264015197754],
        [81.50486755371122, 16.352359771728572],
        [81.50180816650419, 16.35236167907715],
        [81.50180816650419, 16.352083206176758],
        [81.50152587890625, 16.352083206176758],
        [81.50152587890625, 16.351804733276367],
        [81.50125122070324, 16.351804733276367],
        [81.50125122070324, 16.35152816772461],
        [81.49874877929716, 16.35152816772461],
        [81.49874877929716, 16.351804733276367],
        [81.49597167968767, 16.351804733276367],
        [81.49597167968767, 16.35152816772461],
        [81.494857788086, 16.35152816772461],
        [81.494857788086, 16.351804733276367],
        [81.49208068847685, 16.351804733276367],
        [81.49208068847685, 16.352083206176758],
        [81.49153137207043, 16.352083206176758],
        [81.49153137207043, 16.35236167907715],
        [81.49124908447271, 16.352359771728572],
        [81.49124908447271, 16.35264015197754],
        [81.49096679687494, 16.35264015197754],
        [81.49096679687494, 16.352920532226506],
        [81.48486328124994, 16.352920532226506],
        [81.48486328124994, 16.35264015197754],
        [81.4845809936524, 16.35264015197754],
        [81.4845809936524, 16.352920532226506],
        [81.48375701904314, 16.352920532226506],
        [81.48375701904314, 16.353200912475643],
        [81.48124694824213, 16.353200912475643],
        [81.48124694824213, 16.352920532226506],
        [81.48069763183611, 16.352920532226506],
        [81.48069763183611, 16.35264015197754],
        [81.4804229736331, 16.35264015197754],
        [81.4804229736331, 16.352920532226506],
        [81.48014068603533, 16.352920532226506],
        [81.48014068603533, 16.35264015197754],
        [81.47957611083984, 16.35264015197754],
        [81.47957611083984, 16.352920532226506],
        [81.47902679443365, 16.352920532226506],
        [81.47902679443365, 16.353200912475643],
        [81.47819519042963, 16.353195190429744],
        [81.47819519042963, 16.353471755981502],
        [81.47652435302734, 16.353471755981502],
        [81.47652435302734, 16.353195190429744],
        [81.4740295410158, 16.353195190429744],
        [81.4740295410158, 16.353471755981502],
        [81.47264099121105, 16.353471755981502],
        [81.47264099121105, 16.353195190429744],
        [81.47013854980497, 16.353200912475643],
        [81.47013854980497, 16.352920532226506],
        [81.46901702880871, 16.352920532226506],
        [81.46901702880871, 16.353200912475643],
        [81.46875762939476, 16.353200912475643],
        [81.46875762939476, 16.352920532226506],
        [81.4676361083985, 16.352920532226506],
        [81.4676361083985, 16.35264015197754],
        [81.46736145019548, 16.35264015197754],
        [81.46736145019548, 16.352920532226506],
        [81.46514129638695, 16.352920532226506],
        [81.46514129638695, 16.35264015197754],
        [81.46235656738276, 16.35264015197754],
        [81.46235656738276, 16.352359771728572],
        [81.4587478637697, 16.35236167907715],
        [81.4587478637697, 16.352083206176758],
        [81.456527709961, 16.352083206176758],
        [81.456527709961, 16.351804733276367],
        [81.45430755615263, 16.351804733276367],
        [81.45430755615263, 16.35152816772461],
        [81.45263671874994, 16.351530075073356],
        [81.45263671874994, 16.35124969482422],
        [81.44930267334007, 16.35124969482422],
        [81.44930267334007, 16.350971221923828],
        [81.44680786132818, 16.350973129272404],
        [81.44680786132818, 16.350694656372013],
        [81.44458007812528, 16.350694656372013],
        [81.44458007812528, 16.35041618347168],
        [81.44235992431658, 16.350419998169002],
        [81.44235992431658, 16.350139617920036],
        [81.44069671630865, 16.350139617920036],
        [81.44069671630865, 16.349861145019645],
        [81.43846130371122, 16.349861145019645],
        [81.43846130371122, 16.349582672119084],
        [81.43708038330084, 16.349584579467887],
        [81.43708038330084, 16.349306106567496],
        [81.43596649169928, 16.34930992126465],
        [81.43596649169928, 16.349029541015682],
        [81.43402862548834, 16.349029541015682],
        [81.43402862548834, 16.34875106811529],
        [81.43180847167997, 16.34875106811529],
        [81.43180847167997, 16.3484725952149],
        [81.43069458007807, 16.3484725952149],
        [81.43069458007807, 16.34819412231451],
        [81.42874908447294, 16.34819412231451],
        [81.42874908447294, 16.347917556762752],
        [81.42708587646501, 16.347919464111328],
        [81.42708587646501, 16.347642898559513],
        [81.42597198486345, 16.347642898559513],
        [81.42597198486345, 16.347362518310547],
        [81.42485809326178, 16.347362518310547],
        [81.42485809326178, 16.347080230713004],
        [81.42375183105491, 16.347084045410156],
        [81.42375183105491, 16.346805572509822],
        [81.42151641845732, 16.346809387207145],
        [81.42151641845732, 16.346532821655387],
        [81.41986083984392, 16.346532821655387],
        [81.41986083984392, 16.34624862670904],
        [81.41764068603538, 16.34624862670904],
        [81.41764068603538, 16.345968246460075],
        [81.41708374023443, 16.34597015380865],
        [81.41708374023443, 16.345693588256836],
        [81.41625213623047, 16.345693588256836],
        [81.41625213623047, 16.345418930053825],
        [81.41485595703136, 16.3454208374024],
        [81.41485595703136, 16.345138549804688],
        [81.41401672363304, 16.345138549804688],
        [81.41401672363304, 16.34485816955572],
        [81.41152954101568, 16.344860076904297],
        [81.41152954101568, 16.344583511352482],
        [81.41041564941412, 16.344583511352482],
        [81.41041564941412, 16.34430503845209],
        [81.40902709960943, 16.344310760498047],
        [81.40902709960943, 16.34403038024908],
        [81.40763854980486, 16.34403038024908],
        [81.40763854980486, 16.343748092651367],
        [81.40541839599615, 16.343748092651367],
        [81.40541839599615, 16.34403038024908],
        [81.40374755859403, 16.34403038024908],
        [81.40374755859403, 16.344310760498047],
        [81.40347290039062, 16.344310760498047],
        [81.40347290039062, 16.3454208374024],
        [81.40374755859403, 16.345418930053825],
        [81.40374755859403, 16.345693588256836],
        [81.40513610839838, 16.345693588256836],
        [81.40513610839838, 16.34597015380865],
        [81.40625000000028, 16.345968246460075],
        [81.40625000000028, 16.34624862670904],
        [81.40680694580107, 16.34624862670904],
        [81.40680694580107, 16.346532821655387],
        [81.40708160400408, 16.346532821655387],
        [81.40708160400408, 16.347362518310547],
        [81.40680694580107, 16.347362518310547],
        [81.40680694580107, 16.347642898559513],
        [81.40651702880876, 16.347642898559513],
        [81.40651702880876, 16.347919464111328],
        [81.40625000000028, 16.347917556762752],
        [81.40625000000028, 16.3484725952149],
        [81.40541839599615, 16.3484725952149],
        [81.40541839599615, 16.34819412231451],
        [81.40458679199236, 16.34819412231451],
        [81.40458679199236, 16.347917556762752],
        [81.40402984619158, 16.347919464111328],
        [81.40402984619158, 16.347642898559513],
        [81.40347290039062, 16.347642898559513],
        [81.40347290039062, 16.347919464111328],
        [81.40319824218778, 16.347917556762752],
        [81.40319824218778, 16.34819412231451],
        [81.40291595458984, 16.34819412231451],
        [81.40291595458984, 16.3484725952149],
        [81.40264129638683, 16.3484725952149],
        [81.40264129638683, 16.349029541015682],
        [81.40235900878912, 16.349027633667106],
        [81.40235900878912, 16.351804733276367],
        [81.40208435058588, 16.351804733276367],
        [81.40208435058588, 16.352083206176758],
        [81.40125274658209, 16.352083206176758],
        [81.40125274658209, 16.351804733276367],
        [81.40097045898455, 16.351804733276367],
        [81.40097045898455, 16.35041618347168],
        [81.40125274658209, 16.350419998169002],
        [81.40125274658209, 16.350139617920036],
        [81.40152740478533, 16.350139617920036],
        [81.40152740478533, 16.348190307617188],
        [81.40180206298834, 16.34819412231451],
        [81.40180206298834, 16.347084045410156],
        [81.40152740478533, 16.347084045410156],
        [81.40152740478533, 16.345693588256836],
        [81.40125274658209, 16.345693588256836],
        [81.40125274658209, 16.345418930053825],
        [81.40069580078153, 16.3454208374024],
        [81.40069580078153, 16.345138549804688],
        [81.40013885498075, 16.345138549804688],
        [81.40013885498075, 16.34485816955572],
        [81.39986419677734, 16.344860076904297],
        [81.39986419677734, 16.344583511352482],
        [81.39958190917997, 16.344583511352482],
        [81.39958190917997, 16.34430503845209],
        [81.39930725097662, 16.344310760498047],
        [81.39930725097662, 16.34403038024908],
        [81.39736175537126, 16.34403038024908],
        [81.39736175537126, 16.344310760498047],
        [81.39708709716825, 16.34430503845209],
        [81.39708709716825, 16.344583511352482],
        [81.39624786376959, 16.34457969665533],
        [81.39624786376959, 16.345138549804688],
        [81.39597320556652, 16.345138549804688],
        [81.39597320556652, 16.34530067443859],
        [81.39597320556652, 16.34597015380865],
        [81.3956909179688, 16.345968246460075],
        [81.3956909179688, 16.34624862670904],
        [81.39541625976557, 16.34624862670904],
        [81.39541625976557, 16.346532821655387],
        [81.39457702636724, 16.346532821655387],
        [81.39457702636724, 16.346809387207145],
        [81.39430236816423, 16.346805572509822],
        [81.39430236816423, 16.347084045410156],
        [81.39375305175781, 16.347080230713004],
        [81.39375305175781, 16.347362518310547],
        [81.39299011230469, 16.347362518310547],
        [81.3926391601563, 16.347362518310547],
        [81.3926391601563, 16.347642898559513],
        [81.39235687255854, 16.347642898559513],
        [81.39235687255854, 16.347919464111328],
        [81.3920822143557, 16.347919464111328],
        [81.3920822143557, 16.349029541015682],
        [81.39236450195307, 16.349027633667106],
        [81.39236450195307, 16.349170684814567],
        [81.39235687255854, 16.349861145019645],
        [81.3920822143557, 16.349861145019645],
        [81.3920822143557, 16.350139617920036],
        [81.39180755615251, 16.350139617920036],
        [81.39180755615251, 16.350419998169002],
        [81.39152526855474, 16.35041618347168],
        [81.39152526855474, 16.350694656372013],
        [81.39069366455078, 16.350694656372013],
        [81.39069366455078, 16.350973129272404],
        [81.39013671875, 16.350973129272404],
        [81.39013671875, 16.352083206176758],
        [81.38986206054699, 16.352079391479606],
        [81.38986206054699, 16.35264015197754],
        [81.38957977294928, 16.35264015197754],
        [81.38957977294928, 16.353200912475643],
        [81.38930511474604, 16.353195190429744],
        [81.38930511474604, 16.353471755981502],
        [81.3890304565432, 16.35346031188959],
        [81.3890304565432, 16.353750228881893],
        [81.38874816894548, 16.353750228881893],
        [81.38874816894548, 16.35403060913086],
        [81.38846588134788, 16.35403060913086],
        [81.38846588134788, 16.354310989379826],
        [81.38791656494169, 16.354305267334098],
        [81.38791656494169, 16.354583740234432],
        [81.38735198974638, 16.35457992553711],
        [81.38735198974638, 16.354860305786246],
        [81.38652801513678, 16.354860305786246],
        [81.38652801513678, 16.355140686035213],
        [81.38569641113298, 16.355140686035213],
        [81.38569641113298, 16.35542106628418],
        [81.38486480712919, 16.355417251587028],
        [81.38486480712919, 16.355693817138615],
        [81.38430786132841, 16.355690002441463],
        [81.38430786132841, 16.356250762939567],
        [81.384033203125, 16.356250762939567],
        [81.384033203125, 16.356809616088924],
        [81.38375091552763, 16.3568058013916],
        [81.38375091552763, 16.3580703735351],
        [81.38375091552763, 16.358749389648494],
        [81.38346862792974, 16.358749389648494],
        [81.38346862792974, 16.359310150146428],
        [81.38318634033214, 16.359310150146428],
        [81.38318634033214, 16.361530303955135],
        [81.3829193115235, 16.361530303955135],
        [81.3829193115235, 16.3618106842041],
        [81.38263702392572, 16.3618106842041],
        [81.38263702392572, 16.361530303955135],
        [81.38236236572288, 16.361530303955135],
        [81.38236236572288, 16.361251831054744],
        [81.38208007812517, 16.361251831054744],
        [81.38208007812517, 16.36096954345703],
        [81.38180541992193, 16.36096954345703],
        [81.38181304931669, 16.361251831054744],
        [81.38124847412138, 16.361251831054744],
        [81.38124847412138, 16.361530303955135],
        [81.3806915283206, 16.361530303955135],
        [81.3806915283206, 16.3618106842041],
        [81.38041687011724, 16.361804962158146],
        [81.38041687011724, 16.362083435058537],
        [81.37930297851585, 16.362083435058537],
        [81.37930297851585, 16.361804962158146],
        [81.37847137451189, 16.3618106842041],
        [81.37847137451189, 16.361530303955135],
        [81.37735748291016, 16.361530303955135],
        [81.37735748291016, 16.361251831054744],
        [81.37680816650396, 16.361251831054744],
        [81.37680816650396, 16.36096954345703],
        [81.37625122070335, 16.36096954345703],
        [81.37625122070335, 16.36069488525402],
        [81.37513732910185, 16.36069488525402],
        [81.37513732910185, 16.36041641235363],
        [81.37458038330107, 16.36042022705078],
        [81.37458038330107, 16.36014175415039],
        [81.37319183349615, 16.36014175415039],
        [81.37319183349615, 16.359859466552848],
        [81.37291717529314, 16.359859466552848],
        [81.37291717529314, 16.36014175415039],
        [81.37180328369158, 16.36014175415039],
        [81.37180328369158, 16.36042022705078],
        [81.37152862548857, 16.36041641235363],
        [81.37152862548857, 16.362083435058537],
        [81.37124633789062, 16.362083435058537],
        [81.37124633789062, 16.361804962158146],
        [81.36985778808588, 16.361804962158146],
        [81.36985778808588, 16.362083435058537],
        [81.36930847167986, 16.362083435058537],
        [81.36930847167986, 16.361804962158146],
        [81.36902618408209, 16.3618106842041],
        [81.36902618408209, 16.361530303955135],
        [81.36875152587908, 16.361530303955135],
        [81.36875152587908, 16.361251831054744],
        [81.36846923828153, 16.361251831054744],
        [81.36846923828153, 16.36096954345703],
        [81.36819458007812, 16.36096954345703],
        [81.36819458007812, 16.36069488525402],
        [81.36736297607433, 16.36069488525402],
        [81.36736297607433, 16.36041641235363],
        [81.36653137207054, 16.36042022705078],
        [81.36653137207054, 16.36014175415039],
        [81.36596679687506, 16.36014175415039],
        [81.36596679687506, 16.359859466552848],
        [81.3651351928711, 16.359859466552848],
        [81.3651351928711, 16.359582901001033],
        [81.36486053466825, 16.359582901001033],
        [81.36486053466825, 16.35902976989746],
        [81.3645858764649, 16.359031677246037],
        [81.3645858764649, 16.358470916748104],
        [81.3643035888673, 16.35847282409668],
        [81.3643035888673, 16.357915878295955],
        [81.36374664306635, 16.357919692993278],
        [81.36374664306635, 16.357641220092717],
        [81.36347198486351, 16.357641220092717],
        [81.36347198486351, 16.35736083984375],
        [81.36319732666033, 16.35736083984375],
        [81.36319732666033, 16.357080459594783],
        [81.36264038085955, 16.357084274291935],
        [81.36264038085955, 16.3568058013916],
        [81.362358093262, 16.356809616088924],
        [81.362358093262, 16.35651969909668],
        [81.3620834350586, 16.35652732849121],
        [81.3620834350586, 16.355972290039006],
        [81.36180877685575, 16.355972290039006],
        [81.36180877685575, 16.355693817138615],
        [81.36152648925781, 16.355693817138615],
        [81.36152648925781, 16.355138778686637],
        [81.3612518310548, 16.355140686035213],
        [81.3612518310548, 16.354860305786246],
        [81.36096954345709, 16.354860305786246],
        [81.36096954345709, 16.35403060913086],
        [81.36068725585949, 16.35403060913086],
        [81.36068725585949, 16.353200912475643],
        [81.36042785644554, 16.353200912475643],
        [81.36042785644554, 16.352920532226506],
        [81.36068725585949, 16.352920532226506],
        [81.36068725585949, 16.352359771728572],
        [81.36096954345709, 16.35236167907715],
        [81.36096954345709, 16.35152816772461],
        [81.3612518310548, 16.351530075073356],
        [81.3612518310548, 16.35124969482422],
        [81.36042022705101, 16.35124969482422],
        [81.36042022705101, 16.350694656372013],
        [81.35986328125006, 16.350694656372013],
        [81.35986328125006, 16.35041618347168],
        [81.35874938964872, 16.350419998169002],
        [81.35874938964872, 16.350139617920036],
        [81.35819244384794, 16.350139617920036],
        [81.35819244384794, 16.349861145019645],
        [81.35791778564459, 16.349861145019645],
        [81.35791778564459, 16.34930992126465],
        [81.35958099365251, 16.34930992126465],
        [81.35958099365251, 16.349029541015682],
        [81.35986328125006, 16.349029541015682],
        [81.35986328125006, 16.34875106811529],
        [81.3601379394533, 16.34875106811529],
        [81.3601379394533, 16.348190307617188],
        [81.35986328125006, 16.34819412231451],
        [81.35986328125006, 16.347917556762752],
        [81.35958099365251, 16.347919464111328],
        [81.35958099365251, 16.347080230713004],
        [81.3593063354495, 16.347084045410156],
        [81.3593063354495, 16.34581947326666],
        [81.3593063354495, 16.345693588256836],
        [81.35902404785173, 16.345693588256836],
        [81.35902404785173, 16.345418930053825],
        [81.35874938964872, 16.3454208374024],
        [81.35874938964872, 16.345138549804688],
        [81.35846710205078, 16.345138549804688],
        [81.35846710205078, 16.34485816955572],
        [81.35819244384794, 16.344860076904297],
        [81.35819244384794, 16.34430503845209],
        [81.35791778564459, 16.344310760498047],
        [81.35791778564459, 16.34403038024908],
        [81.35764312744135, 16.34403038024908],
        [81.35764312744135, 16.343748092651367],
        [81.3573608398438, 16.343748092651367],
        [81.3573608398438, 16.343471527099723],
        [81.3570861816408, 16.343471527099723],
        [81.3570861816408, 16.343194961547965],
        [81.35679626464866, 16.343200683593693],
        [81.35679626464866, 16.34263992309576],
        [81.35652923584001, 16.34263992309576],
        [81.35652923584001, 16.342359542846793],
        [81.35624694824224, 16.34236145019537],
        [81.35624694824224, 16.34180641174322],
        [81.35597229003923, 16.341810226440373],
        [81.35597229003923, 16.340690612793082],
        [81.35541534423828, 16.340694427490234],
        [81.35541534423828, 16.340414047241268],
        [81.35514068603544, 16.340419769287223],
        [81.35514068603544, 16.339860916137695],
        [81.35485839843756, 16.339860916137695],
        [81.35485839843756, 16.33958053588873],
        [81.35458374023449, 16.339582443237305],
        [81.35458374023449, 16.339305877685547],
        [81.35430145263678, 16.33930969238287],
        [81.35430145263678, 16.33903121948248],
        [81.35402679443354, 16.33903121948248],
        [81.35402679443354, 16.338750839233512],
        [81.3537521362307, 16.338750839233512],
        [81.3537521362307, 16.33819007873541],
        [81.35346984863298, 16.338195800781364],
        [81.35346984863298, 16.337638854980582],
        [81.3532028198245, 16.33764076232916],
        [81.3532028198245, 16.337360382080192],
        [81.3529129028322, 16.337360382080192],
        [81.3529129028322, 16.337080001831055],
        [81.35263824462919, 16.337083816528377],
        [81.35263824462919, 16.336805343628043],
        [81.35235595703125, 16.33681106567377],
        [81.35235595703125, 16.336530685424805],
        [81.35180664062506, 16.336530685424805],
        [81.35180664062506, 16.336250305175838],
        [81.3515167236331, 16.336250305175838],
        [81.3515167236331, 16.335971832275447],
        [81.35124969482428, 16.335973739624023],
        [81.35124969482428, 16.3354167938233],
        [81.3509826660158, 16.33542060852045],
        [81.3509826660158, 16.335140228271484],
        [81.35069274902366, 16.335140228271484],
        [81.35069274902366, 16.334861755371094],
        [81.35041809082048, 16.33486366271967],
        [81.35041809082048, 16.334583282470703],
        [81.3498611450197, 16.334583282470703],
        [81.3498611450197, 16.334304809570312],
        [81.34958648681669, 16.334304809570312],
        [81.34958648681669, 16.334028244018555],
        [81.34929656982439, 16.3340301513673],
        [81.34929656982439, 16.33375167846674],
        [81.34874725341797, 16.33375167846674],
        [81.34874725341797, 16.333469390869197],
        [81.34847259521496, 16.333469390869197],
        [81.34847259521496, 16.33319473266596],
        [81.34819793701178, 16.33319473266596],
        [81.34819793701178, 16.332916259765625],
        [81.34792327880854, 16.332920074462947],
        [81.34792327880854, 16.332641601562557],
        [81.34764099121116, 16.332641601562557],
        [81.34764099121116, 16.33234977722168],
        [81.34708404541021, 16.332359313964844],
        [81.34708404541021, 16.33208274841303],
        [81.34680175781267, 16.33208274841303],
        [81.34680175781267, 16.331806182861442],
        [81.34652709960966, 16.33181190490717],
        [81.34652709960966, 16.33152770996105],
        [81.34597015380888, 16.33152770996105],
        [81.34597015380888, 16.33124923706066],
        [81.34570312500006, 16.33124923706066],
        [81.34570312500006, 16.330690383911133],
        [81.34680175781267, 16.330694198608455],
        [81.3468093872072, 16.330972671508846],
        [81.34764099121116, 16.33097076416027],
        [81.34764099121116, 16.33124923706066],
        [81.34819793701178, 16.33124923706066],
        [81.34819793701178, 16.33152770996105],
        [81.34874725341797, 16.33152770996105],
        [81.34874725341797, 16.33181190490717],
        [81.34930419921875, 16.331806182861442],
        [81.34930419921875, 16.33208274841303],
        [81.34958648681669, 16.33208274841303],
        [81.34958648681669, 16.332359313964844],
        [81.35069274902366, 16.33234977722168],
        [81.35069274902366, 16.332641601562557],
        [81.35208129882841, 16.332641601562557],
        [81.35208129882841, 16.332920074462947],
        [81.35263824462919, 16.332916259765625],
        [81.35263824462919, 16.33319473266596],
        [81.35346984863298, 16.33319473266596],
        [81.35346984863298, 16.333469390869197],
        [81.35402679443354, 16.333469390869197],
        [81.35402679443354, 16.33375167846674],
        [81.35569763183622, 16.33375167846674],
        [81.35569763183622, 16.3340301513673],
        [81.35597229003923, 16.334028244018555],
        [81.35597229003923, 16.334304809570312],
        [81.35652923584001, 16.334304809570312],
        [81.35652923584001, 16.334583282470703],
        [81.35679626464866, 16.33457946777355],
        [81.35679626464866, 16.335140228271484],
        [81.3570861816408, 16.335140228271484],
        [81.3570861816408, 16.33542060852045],
        [81.3573608398438, 16.3354167938233],
        [81.3573608398438, 16.335973739624023],
        [81.35763549804699, 16.335973739624023],
        [81.35763549804699, 16.336250305175838],
        [81.35791778564459, 16.336250305175838],
        [81.35791778564459, 16.33681106567377],
        [81.35819244384794, 16.33681106567377],
        [81.35819244384794, 16.337360382080192],
        [81.35846710205078, 16.337360382080192],
        [81.35846710205078, 16.33764076232916],
        [81.35874938964872, 16.337638854980582],
        [81.35874938964872, 16.338195800781364],
        [81.3590164184572, 16.33819007873541],
        [81.3590164184572, 16.338750839233512],
        [81.3593063354495, 16.338750839233512],
        [81.3593063354495, 16.339582443237305],
        [81.35958099365251, 16.33958053588873],
        [81.35958099365251, 16.339860916137695],
        [81.35986328125006, 16.339860916137695],
        [81.35986328125006, 16.340419769287223],
        [81.3601379394533, 16.340414047241268],
        [81.3601379394533, 16.340694427490234],
        [81.36069488525385, 16.340694427490234],
        [81.36069488525385, 16.340972900390625],
        [81.36096954345709, 16.34097099304205],
        [81.36096954345709, 16.34124946594244],
        [81.36152648925781, 16.34124946594244],
        [81.36152648925781, 16.34152793884283],
        [81.36207580566423, 16.3415203094483],
        [81.36207580566423, 16.341810226440373],
        [81.36264038085955, 16.34180641174322],
        [81.36264038085955, 16.34208297729498],
        [81.36319732666033, 16.34208297729498],
        [81.36319732666033, 16.34236145019537],
        [81.36347198486351, 16.342359542846793],
        [81.36347198486351, 16.34263992309576],
        [81.36374664306635, 16.34263992309576],
        [81.36374664306635, 16.343200683593693],
        [81.36402893066412, 16.343200683593693],
        [81.36402893066412, 16.343748092651367],
        [81.36429595947294, 16.343748092651367],
        [81.36429595947294, 16.344310760498047],
        [81.3645858764649, 16.34430503845209],
        [81.3645858764649, 16.344583511352482],
        [81.3651351928711, 16.344583511352482],
        [81.3651351928711, 16.344860076904297],
        [81.36569213867205, 16.34485816955572],
        [81.36569213867205, 16.345138549804688],
        [81.36596679687506, 16.345138549804688],
        [81.36596679687506, 16.3454208374024],
        [81.36653137207054, 16.345418930053825],
        [81.36653137207054, 16.345693588256836],
        [81.36680603027338, 16.345693588256836],
        [81.36680603027338, 16.34597015380865],
        [81.36708068847662, 16.34597015380865],
        [81.36708068847662, 16.345693588256836],
        [81.36958312988304, 16.345693588256836],
        [81.36958312988304, 16.34597015380865],
        [81.37097167968778, 16.345968246460075],
        [81.37097167968778, 16.346532821655387],
        [81.37069702148443, 16.346532821655387],
        [81.37069702148443, 16.346809387207145],
        [81.37041473388683, 16.346805572509822],
        [81.37041473388683, 16.347084045410156],
        [81.37014007568365, 16.347080230713004],
        [81.37014007568365, 16.347362518310547],
        [81.36985778808588, 16.347362518310547],
        [81.36985778808588, 16.347919464111328],
        [81.36902618408209, 16.347917556762752],
        [81.36902618408209, 16.34819412231451],
        [81.36846923828153, 16.34819412231451],
        [81.36846923828153, 16.3484725952149],
        [81.3676376342774, 16.3484725952149],
        [81.3676376342774, 16.34875106811529],
        [81.36736297607433, 16.34875106811529],
        [81.36736297607433, 16.349029541015682],
        [81.36708068847662, 16.349029541015682],
        [81.36708068847662, 16.34930992126465],
        [81.36680603027338, 16.349306106567496],
        [81.36680603027338, 16.349584579467887],
        [81.36653137207054, 16.349582672119084],
        [81.36653137207054, 16.349861145019645],
        [81.3662414550783, 16.349861145019645],
        [81.3662414550783, 16.350419998169002],
        [81.36541748046903, 16.350419998169002],
        [81.36541748046903, 16.35124969482422],
        [81.36569213867205, 16.35124969482422],
        [81.36569213867205, 16.352083206176758],
        [81.36597442626959, 16.352083206176758],
        [81.36597442626959, 16.35236167907715],
        [81.3662414550783, 16.352359771728572],
        [81.3662414550783, 16.35264015197754],
        [81.36653137207054, 16.35264015197754],
        [81.36653137207054, 16.352920532226506],
        [81.36680603027338, 16.352920532226506],
        [81.36680603027338, 16.353200912475643],
        [81.36708068847662, 16.353195190429744],
        [81.36708068847662, 16.353471755981502],
        [81.3676376342774, 16.35346031188959],
        [81.3676376342774, 16.35403060913086],
        [81.36791992187528, 16.35403060913086],
        [81.36791992187528, 16.354310989379826],
        [81.36846923828153, 16.354305267334098],
        [81.36846923828153, 16.354583740234432],
        [81.37124633789062, 16.35457992553711],
        [81.37124633789062, 16.354860305786246],
        [81.37209320068388, 16.354860305786246],
        [81.37209320068388, 16.355140686035213],
        [81.3726425170899, 16.355140686035213],
        [81.3726425170899, 16.35542106628418],
        [81.37291717529314, 16.355417251587028],
        [81.37291717529314, 16.355693817138615],
        [81.3734741210937, 16.355693817138615],
        [81.3734741210937, 16.355972290039006],
        [81.37430572509766, 16.355972290039006],
        [81.37430572509766, 16.356250762939567],
        [81.37486267089844, 16.356250762939567],
        [81.37486267089844, 16.35652732849121],
        [81.37513732910185, 16.35651969909668],
        [81.37513732910185, 16.356809616088924],
        [81.3756942749024, 16.3568058013916],
        [81.3756942749024, 16.35708236694336],
        [81.37625122070335, 16.357080459594783],
        [81.37625122070335, 16.35736083984375],
        [81.3765258789062, 16.35736083984375],
        [81.3765258789062, 16.357641220092717],
        [81.37680816650396, 16.357637405395565],
        [81.37680816650396, 16.35819435119629],
        [81.37708282470714, 16.358190536499137],
        [81.37708282470714, 16.358749389648494],
        [81.37735748291016, 16.358749389648494],
        [81.37735748291016, 16.359031677246037],
        [81.37790679931658, 16.359031677246037],
        [81.37790679931658, 16.359310150146428],
        [81.37875366210943, 16.359306335449276],
        [81.37875366210943, 16.359582901001033],
        [81.37985992431646, 16.359582901001033],
        [81.37985992431646, 16.359859466552848],
        [81.38097381591797, 16.359859466552848],
        [81.38097381591797, 16.359582901001033],
        [81.38153076171892, 16.359582901001033],
        [81.38153076171892, 16.359306335449276],
        [81.38181304931669, 16.359310150146428],
        [81.38181304931669, 16.359031677246037],
        [81.38236236572288, 16.359031677246037],
        [81.38236236572288, 16.358470916748104],
        [81.38263702392572, 16.35847282409668],
        [81.38263702392572, 16.35736083984375],
        [81.3829193115235, 16.357330322265625],
        [81.3829193115235, 16.3568058013916],
        [81.38318634033214, 16.356809616088924],
        [81.38318634033214, 16.356250762939567],
        [81.38346862792974, 16.356250762939567],
        [81.38346862792974, 16.355690002441463],
        [81.38375091552763, 16.355693817138615],
        [81.38375091552763, 16.355138778686637],
        [81.384033203125, 16.355140686035213],
        [81.384033203125, 16.354310989379826],
        [81.38430786132841, 16.354310989379826],
        [81.38430786132841, 16.35403060913086],
        [81.38458251953142, 16.35403060913086],
        [81.38458251953142, 16.353750228881893],
        [81.38652801513678, 16.353750228881893],
        [81.38652801513678, 16.35346031188959],
        [81.3870849609375, 16.353471755981502],
        [81.3870849609375, 16.353195190429744],
        [81.38764190673828, 16.353200912475643],
        [81.38764190673828, 16.352920532226506],
        [81.38791656494169, 16.352920532226506],
        [81.38791656494169, 16.35124969482422],
        [81.38764190673828, 16.35124969482422],
        [81.38764190673828, 16.350971221923828],
        [81.3870849609375, 16.350973129272404],
        [81.3870849609375, 16.350139617920036],
        [81.38791656494169, 16.350139617920036],
        [81.38791656494169, 16.349861145019645],
        [81.38846588134788, 16.349861145019645],
        [81.38846588134788, 16.349582672119084],
        [81.38874816894548, 16.349584579467887],
        [81.38874816894548, 16.349306106567496],
        [81.3890304565432, 16.34930992126465],
        [81.3890304565432, 16.3484725952149],
        [81.38930511474604, 16.3484725952149],
        [81.38930511474604, 16.34819412231451],
        [81.38957977294928, 16.34819412231451],
        [81.38957977294928, 16.34763908386236],
        [81.38986206054699, 16.347642898559513],
        [81.38986206054699, 16.347080230713004],
        [81.39013671875, 16.347084045410156],
        [81.39013671875, 16.346805572509822],
        [81.39041137695341, 16.346809387207145],
        [81.39041137695341, 16.346532821655387],
        [81.39068603515642, 16.346532821655387],
        [81.39068603515642, 16.34624862670904],
        [81.39097595214872, 16.34624862670904],
        [81.39097595214872, 16.345968246460075],
        [81.39125061035173, 16.34597015380865],
        [81.39125061035173, 16.345693588256836],
        [81.39152526855474, 16.345693588256836],
        [81.39152526855474, 16.345418930053825],
        [81.3920822143557, 16.3454208374024],
        [81.3920822143557, 16.345138549804688],
        [81.39235687255854, 16.345138549804688],
        [81.39235687255854, 16.34457969665533],
        [81.3926391601563, 16.344583511352482],
        [81.3926391601563, 16.34208297729498],
        [81.39236450195307, 16.34208297729498],
        [81.39236450195307, 16.34180641174322],
        [81.39180755615251, 16.341810226440373],
        [81.39180755615251, 16.3415203094483],
        [81.39152526855474, 16.34152793884283],
        [81.39152526855474, 16.34124946594244],
        [81.39097595214872, 16.34124946594244],
        [81.39097595214872, 16.34097099304205],
        [81.39041900634794, 16.340972900390625],
        [81.39041900634794, 16.340694427490234],
        [81.39013671875, 16.340694427490234],
        [81.39013671875, 16.340414047241268],
        [81.38957977294928, 16.340419769287223],
        [81.38957977294928, 16.340139389038086],
        [81.38791656494169, 16.340139389038086],
        [81.38791656494169, 16.339860916137695],
        [81.38735198974638, 16.339860916137695],
        [81.38735198974638, 16.33958053588873],
        [81.38652801513678, 16.339582443237305],
        [81.38652801513678, 16.339305877685547],
        [81.385971069336, 16.33930969238287],
        [81.385971069336, 16.33903121948248],
        [81.3851394653322, 16.33903121948248],
        [81.3851394653322, 16.338750839233512],
        [81.38375091552763, 16.338750839233512],
        [81.38375091552763, 16.338479995727482],
        [81.38263702392572, 16.338479995727482],
        [81.38263702392572, 16.33819007873541],
        [81.38180541992193, 16.338195800781364],
        [81.38180541992193, 16.337917327880973],
        [81.38096618652344, 16.337921142578125],
        [81.38096618652344, 16.33764076232916],
        [81.38041687011724, 16.33764076232916],
        [81.38041687011724, 16.337360382080192],
        [81.37985992431646, 16.337360382080192],
        [81.37985992431646, 16.337080001831055],
        [81.37930297851585, 16.337083816528377],
        [81.37930297851585, 16.336805343628043],
        [81.37847137451189, 16.33681106567377],
        [81.37847137451189, 16.336530685424805],
        [81.37735748291016, 16.336530685424805],
        [81.37735748291016, 16.336250305175838],
        [81.3765258789062, 16.336250305175838],
        [81.3765258789062, 16.335971832275447],
        [81.37458038330107, 16.335973739624023],
        [81.37458038330107, 16.33569526672369],
        [81.37403106689464, 16.33570289611822],
        [81.37403106689464, 16.33542060852045],
        [81.37346649169933, 16.33542060852045],
        [81.37346649169933, 16.335140228271484],
        [81.37236785888689, 16.335140228271484],
        [81.37236785888689, 16.334861755371094],
        [81.37152862548857, 16.33486366271967],
        [81.37152862548857, 16.334583282470703],
        [81.37097167968778, 16.334583282470703],
        [81.37097167968778, 16.334304809570312],
        [81.37014007568365, 16.334304809570312],
        [81.37014007568365, 16.334028244018555],
        [81.36930847167986, 16.3340301513673],
        [81.36930847167986, 16.33375167846674],
        [81.36846923828153, 16.33375167846674],
        [81.36846923828153, 16.333469390869197],
        [81.3676376342774, 16.333469390869197],
        [81.3676376342774, 16.33319473266596],
        [81.36653137207054, 16.33319473266596],
        [81.36653137207054, 16.332916259765625],
        [81.36596679687506, 16.332920074462947],
        [81.36596679687506, 16.332641601562557],
        [81.3651351928711, 16.332641601562557],
        [81.3651351928711, 16.332359313964844],
        [81.3643035888673, 16.332359313964844],
        [81.3643035888673, 16.33208274841303],
        [81.36347198486351, 16.33208274841303],
        [81.36347198486351, 16.331806182861442],
        [81.36292266845709, 16.33181190490717],
        [81.36292266845709, 16.33152770996105],
        [81.362358093262, 16.33152770996105],
        [81.362358093262, 16.33124923706066],
        [81.36207580566423, 16.33124923706066],
        [81.36207580566423, 16.33097076416027],
        [81.36152648925781, 16.330972671508846],
        [81.36152648925781, 16.330694198608455],
        [81.3601379394533, 16.330694198608455],
        [81.3601379394533, 16.330419540405273],
        [81.3593063354495, 16.330419540405273],
        [81.3593063354495, 16.330139160156307],
        [81.35846710205078, 16.330139160156307],
        [81.35846710205078, 16.329860687255916],
        [81.3570861816408, 16.329860687255916],
        [81.3570861816408, 16.32958030700695],
        [81.35652923584001, 16.3295841217041],
        [81.35652923584001, 16.329305648803768],
        [81.35624694824224, 16.32930946350109],
        [81.35624694824224, 16.32903099060053],
        [81.35542297363281, 16.32903099060053],
        [81.35542297363281, 16.328750610351562],
        [81.35485839843756, 16.328750610351562],
        [81.35485839843756, 16.328470230102596],
        [81.35402679443354, 16.328472137451172],
        [81.35402679443354, 16.32819366455078],
        [81.35319519042974, 16.32819366455078],
        [81.35319519042974, 16.32791519165039],
        [81.3529129028322, 16.327920913696346],
        [81.3529129028322, 16.32764053344738],
        [81.35235595703125, 16.32764053344738],
        [81.35235595703125, 16.327360153198242],
        [81.35180664062506, 16.327362060546818],
        [81.35180664062506, 16.327083587646428],
        [81.35124969482428, 16.327083587646428],
        [81.35124969482428, 16.326805114746037],
        [81.35041809082048, 16.326810836791992],
        [81.35041809082048, 16.326538085937557],
        [81.34902954101591, 16.326538085937557],
        [81.34902954101591, 16.32625007629406],
        [81.34847259521496, 16.32625007629406],
        [81.34847259521496, 16.325969696044922],
        [81.34819793701178, 16.32597160339367],
        [81.34819793701178, 16.325693130493107],
        [81.34735870361345, 16.32570075988764],
        [81.34735870361345, 16.325420379638672],
        [81.34652709960966, 16.325420379638672],
        [81.34652709960966, 16.325139999389705],
        [81.34597015380888, 16.325139999389705],
        [81.34597015380888, 16.32485961914074],
        [81.34569549560547, 16.324861526489315],
        [81.34569549560547, 16.324583053588924],
        [81.34458160400396, 16.324583053588924],
        [81.34458160400396, 16.324306488037166],
        [81.34375000000017, 16.324310302734318],
        [81.34375000000017, 16.32402992248535],
        [81.34319305419939, 16.32402992248535],
        [81.34319305419939, 16.323749542236385],
        [81.3423614501956, 16.323749542236385],
        [81.3423614501956, 16.3234806060791],
        [81.34179687500011, 16.3234806060791],
        [81.34179687500011, 16.323190689087028],
        [81.3412475585937, 16.32319450378418],
        [81.3412475585937, 16.322916030883846],
        [81.34069824218767, 16.32291984558117],
        [81.34069061279314, 16.32263946533203],
        [81.33985900878935, 16.32263946533203],
        [81.33985900878935, 16.322362899780217],
        [81.33875274658214, 16.322362899780217],
        [81.33875274658214, 16.322080612182674],
        [81.3381958007812, 16.322080612182674],
        [81.3381958007812, 16.321805953979492],
        [81.33735656738287, 16.321809768676815],
        [81.33735656738287, 16.321529388427848],
        [81.33708190917986, 16.321529388427848],
        [81.33708190917986, 16.321252822876033],
        [81.33625030517607, 16.321252822876033],
        [81.33625030517607, 16.320972442626896],
        [81.33597564697271, 16.320972442626896],
        [81.33597564697271, 16.32069206237793],
        [81.33541870117193, 16.32069206237793],
        [81.33541870117193, 16.32041740417492],
        [81.33458709716814, 16.32041740417492],
        [81.33458709716814, 16.320140838623104],
        [81.3334732055664, 16.32014274597168],
        [81.3334732055664, 16.319862365722713],
        [81.33264160156261, 16.319862365722713],
        [81.33264160156261, 16.319578170776424],
        [81.3323593139649, 16.319583892822322],
        [81.3323593139649, 16.31930541992199],
        [81.33180236816412, 16.31930923461914],
        [81.33180236816412, 16.31903076171875],
        [81.33152770996111, 16.31903076171875],
        [81.33152770996111, 16.318748474121207],
        [81.33097076416033, 16.318748474121207],
        [81.33097076416033, 16.31846809387207],
        [81.33013916015653, 16.318471908569393],
        [81.33013916015653, 16.318195343017635],
        [81.3293075561524, 16.318199157714957],
        [81.3293075561524, 16.317920684814396],
        [81.32847595214861, 16.317920684814396],
        [81.32847595214861, 16.317638397216854],
        [81.32791900634783, 16.317638397216854],
        [81.32791900634783, 16.317350387573356],
        [81.32763671875006, 16.31736183166504],
        [81.32763671875006, 16.31708335876465],
        [81.32736206054705, 16.31708335876465],
        [81.32736206054705, 16.316804885864258],
        [81.32653045654325, 16.316804885864258],
        [81.32653045654325, 16.316526412963924],
        [81.32596588134777, 16.3165283203125],
        [81.32596588134777, 16.31624984741211],
        [81.32541656494135, 16.31624984741211],
        [81.32541656494135, 16.315969467163143],
        [81.3248596191408, 16.31597137451172],
        [81.3248596191408, 16.315694808959904],
        [81.32430267334001, 16.315694808959904],
        [81.32430267334001, 16.31541633605957],
        [81.3237533569336, 16.315420150756893],
        [81.3237533569336, 16.315139770507926],
        [81.32347106933622, 16.315139770507926],
        [81.32347106933622, 16.31485939025879],
        [81.32291412353527, 16.314861297607536],
        [81.32291412353527, 16.314582824706974],
        [81.32236480712885, 16.314582824706974],
        [81.32236480712885, 16.314306259155387],
        [81.32153320312506, 16.31431007385254],
        [81.32153320312506, 16.314029693603572],
        [81.32096862792997, 16.314029693603572],
        [81.32096862792997, 16.313751220703182],
        [81.32041931152372, 16.313751220703182],
        [81.32041931152372, 16.313470840454215],
        [81.31958007812506, 16.31347274780279],
        [81.31958007812506, 16.3131942749024],
        [81.31874847412126, 16.3131942749024],
        [81.31874847412126, 16.312915802002067],
        [81.31846618652366, 16.31291961669922],
        [81.31846618652366, 16.312641143798828],
        [81.31819152832048, 16.312641143798828],
        [81.31819152832048, 16.31236076354986],
        [81.31791687011747, 16.31236076354986],
        [81.31791687011747, 16.312080383300895],
        [81.31764221191406, 16.312084197998047],
        [81.31764221191406, 16.311805725097713],
        [81.31709289550787, 16.311809539795036],
        [81.31709289550787, 16.31154060363781],
        [81.31652832031256, 16.31154060363781],
        [81.31652832031256, 16.311250686645508],
        [81.31597137451178, 16.311250686645508],
        [81.31597137451178, 16.31097030639654],
        [81.31541442871116, 16.310972213745117],
        [81.31541442871116, 16.310693740844727],
        [81.31513977050798, 16.310693740844727],
        [81.31513977050798, 16.31041717529297],
        [81.31485748291021, 16.31042098999029],
        [81.31485748291021, 16.310140609741325],
        [81.3145828247072, 16.310140609741325],
        [81.3145828247072, 16.309949874878043],
        [81.3145828247072, 16.309860229492188],
        [81.31405639648443, 16.309860229492188],
        [81.31375122070341, 16.309860229492188],
        [81.31375122070341, 16.309583663940373],
        [81.31291961669928, 16.309583663940373],
        [81.31291961669928, 16.309305191039982],
        [81.31207275390642, 16.309310913085938],
        [81.31207275390642, 16.30903053283697],
        [81.31181335449247, 16.30903053283697],
        [81.31181335449247, 16.308750152588004],
        [81.3115310668947, 16.308750152588004],
        [81.3115310668947, 16.308469772338867],
        [81.31124877929716, 16.30847358703619],
        [81.31124877929716, 16.308195114135856],
        [81.31069183349638, 16.308202743530387],
        [81.31069183349638, 16.307920455932617],
        [81.31014251708996, 16.307920455932617],
        [81.31014251708996, 16.30764007568365],
        [81.30959320068376, 16.30764007568365],
        [81.30959320068376, 16.30736160278326],
        [81.30902862548845, 16.30736160278326],
        [81.30902862548845, 16.307081222534293],
        [81.30847167968767, 16.307081222534293],
        [81.30847167968767, 16.30680465698248],
        [81.30819702148466, 16.30680465698248],
        [81.30819702148466, 16.30652809143072],
        [81.30790710449236, 16.306531906127873],
        [81.30790710449236, 16.306251525878906],
        [81.30735778808594, 16.306251525878906],
        [81.30735778808594, 16.305969238281364],
        [81.30708312988293, 16.305973052978516],
        [81.30708312988293, 16.305694580078125],
        [81.30625152587913, 16.305694580078125],
        [81.30625152587913, 16.30541610717779],
        [81.30596923828142, 16.30541610717779],
        [81.30596923828142, 16.305141448974553],
        [81.30568695068382, 16.305141448974553],
        [81.30568695068382, 16.30485916137701],
        [81.30541992187517, 16.30485916137701],
        [81.30541992187517, 16.30458068847662],
        [81.30458068847685, 16.304582595825195],
        [81.30458068847685, 16.304306030273438],
        [81.30374908447271, 16.30430984497076],
        [81.30374908447271, 16.304027557373047],
        [81.30346679687511, 16.304027557373047],
        [81.30346679687511, 16.303749084472656],
        [81.30291748046892, 16.303749084472656],
        [81.30291748046892, 16.303470611572266],
        [81.30263519287132, 16.30347251892084],
        [81.30263519287132, 16.30319404602045],
        [81.30208587646513, 16.30319404602045],
        [81.30208587646513, 16.302917480468864],
        [81.30152893066435, 16.302917480468864],
        [81.30152893066435, 16.30264091491705],
        [81.3012466430664, 16.30264091491705],
        [81.3012466430664, 16.302368164062614],
        [81.30069732666021, 16.302368164062614],
        [81.30069732666021, 16.302080154418945],
        [81.30041503906261, 16.302083969116268],
        [81.30041503906261, 16.301805496215934],
        [81.3001327514649, 16.301811218261662],
        [81.3001327514649, 16.301530838012695],
        [81.29985809326166, 16.301530838012695],
        [81.29985809326166, 16.30125045776373],
        [81.29930877685564, 16.30125045776373],
        [81.29930877685564, 16.300970077514762],
        [81.29902648925787, 16.300971984863338],
        [81.29902648925787, 16.30069541931158],
        [81.29846954345732, 16.300689697265625],
        [81.29846954345732, 16.30041694641119],
        [81.29736328125011, 16.30042076110834],
        [81.29736328125011, 16.29986000061041],
        [81.29680633544916, 16.299861907958984],
        [81.29680633544916, 16.299583435058594],
        [81.29652404785179, 16.299583435058594],
        [81.29652404785179, 16.299306869506836],
        [81.29624938964861, 16.29931068420416],
        [81.29624938964861, 16.299030303955192],
        [81.29596710205084, 16.299030303955192],
        [81.29596710205084, 16.298749923706055],
        [81.29541778564482, 16.298749923706055],
        [81.29541778564482, 16.298469543457088],
        [81.29458618164068, 16.298471450805664],
        [81.29458618164068, 16.29819488525385],
        [81.29430389404308, 16.29819488525385],
        [81.29430389404308, 16.297916412353516],
        [81.2940292358399, 16.297920227050838],
        [81.2940292358399, 16.29763984680187],
        [81.29374694824213, 16.29763984680187],
        [81.29374694824213, 16.297359466552734],
        [81.29347229003929, 16.29736137390148],
        [81.29347229003929, 16.297084808349723],
        [81.29319763183611, 16.297084808349723],
        [81.29319763183611, 16.296806335449332],
        [81.29235839843778, 16.296810150146484],
        [81.29235839843778, 16.296529769897518],
        [81.29207611084001, 16.296529769897518],
        [81.29207611084001, 16.29624176025402],
        [81.2915267944336, 16.29624938964855],
        [81.2915267944336, 16.295972824096737],
        [81.29125213623058, 16.295972824096737],
        [81.29125213623058, 16.295694351196346],
        [81.29069519042963, 16.295694351196346],
        [81.29069519042963, 16.295415878296012],
        [81.29041290283209, 16.295419692993164],
        [81.29041290283209, 16.295141220092773],
        [81.28985595703148, 16.295141220092773],
        [81.28985595703148, 16.294862747192383],
        [81.28901672363298, 16.294862747192383],
        [81.28901672363298, 16.29458045959484],
        [81.2884750366211, 16.294584274291992],
        [81.2884750366211, 16.29430580139166],
        [81.28819274902372, 16.29430961608898],
        [81.28819274902372, 16.29403114318842],
        [81.28791809082037, 16.29403114318842],
        [81.28791809082037, 16.29375267028803],
        [81.28708648681658, 16.29375267028803],
        [81.28708648681658, 16.293470382690487],
        [81.28680419921898, 16.293470382690487],
        [81.28680419921898, 16.293193817138672],
        [81.2865295410158, 16.293193817138672],
        [81.2865295410158, 16.292917251586914],
        [81.28624725341803, 16.29291915893566],
        [81.28624725341803, 16.29264068603527],
        [81.28597259521501, 16.29264068603527],
        [81.28597259521501, 16.292358398437557],
        [81.285697937012, 16.292358398437557],
        [81.285697937012, 16.292079925537166],
        [81.28541564941406, 16.292083740234318],
        [81.28541564941406, 16.291805267333928],
        [81.28485870361334, 16.291809082031307],
        [81.28485870361334, 16.291530609130916],
        [81.28457641601574, 16.291530609130916],
        [81.28457641601574, 16.291248321533203],
        [81.28430175781256, 16.291248321533203],
        [81.28430175781256, 16.290969848632812],
        [81.28319549560553, 16.29097175598156],
        [81.28319549560553, 16.2906951904298],
        [81.28263854980497, 16.29070091247553],
        [81.28263854980497, 16.29013824462902],
        [81.28235626220703, 16.29013824462902],
        [81.28235626220703, 16.28985977172863],
        [81.28208160400419, 16.289861679077205],
        [81.28208160400419, 16.289583206176815],
        [81.28180694580084, 16.289583206176815],
        [81.28180694580084, 16.289304733276424],
        [81.28152465820324, 16.289304733276424],
        [81.28152465820324, 16.28902626037609],
        [81.28125, 16.289028167724666],
        [81.28125, 16.288749694824276],
        [81.28069305419945, 16.288749694824276],
        [81.28069305419945, 16.28819084167492],
        [81.2801361083985, 16.28819465637207],
        [81.2801361083985, 16.287639617919922],
        [81.27986145019548, 16.287639617919922],
        [81.27986145019548, 16.28737068176264],
        [81.27958679199247, 16.28737068176264],
        [81.27958679199247, 16.287080764770565],
        [81.27930450439453, 16.28708267211914],
        [81.27930450439453, 16.286527633666992],
        [81.27847290039074, 16.28652954101574],
        [81.27847290039074, 16.286251068115348],
        [81.27819824218756, 16.286251068115348],
        [81.27819061279303, 16.28597068786621],
        [81.27791595458979, 16.285972595214787],
        [81.27791595458979, 16.285694122314396],
        [81.27764129638695, 16.285694122314396],
        [81.27764129638695, 16.28541755676281],
        [81.27680206298845, 16.285419464111385],
        [81.27680206298845, 16.285140991210994],
        [81.27652740478544, 16.285140991210994],
        [81.27652740478544, 16.284860610962028],
        [81.27625274658203, 16.284860610962028],
        [81.27625274658203, 16.28458023071289],
        [81.27569580078125, 16.284585952758846],
        [81.27569580078125, 16.28430557250988],
        [81.27541351318388, 16.28430557250988],
        [81.27541351318388, 16.28402709960949],
        [81.27485656738293, 16.28403091430664],
        [81.27485656738293, 16.283750534057674],
        [81.27458190917974, 16.283750534057674],
        [81.27458190917974, 16.283470153808707],
        [81.27430725097673, 16.283472061157283],
        [81.27430725097673, 16.283193588256893],
        [81.27402496337913, 16.283193588256893],
        [81.27402496337913, 16.282917022705135],
        [81.27348327636747, 16.282920837402287],
        [81.27348327636747, 16.28264045715332],
        [81.27319335937517, 16.28264045715332],
        [81.27319335937517, 16.282360076904354],
        [81.27291870117216, 16.282363891601506],
        [81.27291870117216, 16.28208351135254],
        [81.27263641357422, 16.28208351135254],
        [81.27263641357422, 16.28180503845215],
        [81.27208709716803, 16.281810760498104],
        [81.27208709716803, 16.28123855590826],
        [81.27180480957043, 16.281251907348576],
        [81.27180480957043, 16.280969619751033],
        [81.27124786376947, 16.280969619751033],
        [81.27124786376947, 16.280694961547795],
        [81.27069091796892, 16.28070068359375],
        [81.27069091796892, 16.280420303344783],
        [81.27041625976568, 16.280420303344783],
        [81.27041625976568, 16.280141830444393],
        [81.27014923095703, 16.280141830444393],
        [81.27014923095703, 16.279579162597713],
        [81.26985931396513, 16.27958106994629],
        [81.26985931396513, 16.279306411743278],
        [81.26930236816435, 16.27931022644043],
        [81.26930236816435, 16.27903175354004],
        [81.26875305175793, 16.27903175354004],
        [81.26875305175793, 16.278751373291072],
        [81.26847076416021, 16.278751373291072],
        [81.26847076416021, 16.27846908569336],
        [81.26819610595697, 16.27846908569336],
        [81.26819610595697, 16.278751373291072],
        [81.26699829101591, 16.278751373291072],
        [81.26680755615263, 16.278751373291072],
        [81.26680755615263, 16.27903175354004],
        [81.26653289794922, 16.27903175354004],
        [81.26653289794922, 16.27931022644043],
        [81.26625061035185, 16.27931022644043],
        [81.26625061035185, 16.280141830444393],
        [81.2659759521485, 16.280139923095817],
        [81.2659759521485, 16.280969619751033],
        [81.2656936645509, 16.280969619751033],
        [81.2656936645509, 16.281251907348576],
        [81.26541900634771, 16.281251907348576],
        [81.26541900634771, 16.280969619751033],
        [81.26513671874994, 16.280969619751033],
        [81.26513671874994, 16.280139923095817],
        [81.26541900634771, 16.280141830444393],
        [81.26541900634771, 16.279579162597713],
        [81.26513671874994, 16.27958106994629],
        [81.26513671874994, 16.279029846191463],
        [81.2648620605471, 16.27903175354004],
        [81.2648620605471, 16.278079986572266],
        [81.2648620605471, 16.277915954589957],
        [81.26457977294939, 16.27791976928711],
        [81.26457977294939, 16.276531219482365],
        [81.26431274414068, 16.276531219482365],
        [81.26431274414068, 16.2762508392334],
        [81.26402282714861, 16.2762508392334],
        [81.26402282714861, 16.275970458984432],
        [81.2637481689456, 16.275972366333008],
        [81.2637481689456, 16.27541732788086],
        [81.26346588134783, 16.275430679321346],
        [81.26346588134783, 16.275140762329215],
        [81.26319122314482, 16.275140762329215],
        [81.26319122314482, 16.27458000183111],
        [81.2629165649414, 16.274583816528263],
        [81.2629165649414, 16.273471832275504],
        [81.2626419067384, 16.273471832275504],
        [81.2626419067384, 16.27124977111822],
        [81.26235961914068, 16.27124977111822],
        [81.26235961914068, 16.270969390869254],
        [81.26208496093744, 16.27097129821783],
        [81.26208496093744, 16.270694732666016],
        [81.2618026733399, 16.270694732666016],
        [81.2618026733399, 16.270416259765682],
        [81.26152801513689, 16.270420074463004],
        [81.26152801513689, 16.270139694213867],
        [81.26125335693365, 16.270139694213867],
        [81.26123809814476, 16.269582748413086],
        [81.26097106933611, 16.269584655761662],
        [81.26097106933611, 16.269306182861328],
        [81.2606964111331, 16.269306182861328],
        [81.2606964111331, 16.269027709960938],
        [81.2604064941408, 16.269029617309684],
        [81.2604064941408, 16.268751144409293],
        [81.26013946533232, 16.268751144409293],
        [81.26013946533232, 16.268472671508732],
        [81.2598648071289, 16.268472671508732],
        [81.2598648071289, 16.26819419860834],
        [81.25958251953153, 16.26819419860834],
        [81.25958251953153, 16.267917633056754],
        [81.25930786132818, 16.26791954040533],
        [81.25930786132818, 16.26764106750494],
        [81.25903320312494, 16.26764106750494],
        [81.25903320312494, 16.26736259460455],
        [81.2587509155274, 16.26736259460455],
        [81.2587509155274, 16.266809463500977],
        [81.25846862792963, 16.266809463500977],
        [81.25846862792963, 16.266532897949162],
        [81.25791931152361, 16.266532897949162],
        [81.25791931152361, 16.266260147094727],
        [81.25763702392584, 16.266260147094727],
        [81.25763702392584, 16.265968322753906],
        [81.25736236572283, 16.265970230102653],
        [81.25736236572283, 16.265693664550838],
        [81.25708007812528, 16.265693664550838],
        [81.25708007812528, 16.26541709899908],
        [81.2568130493164, 16.265420913696232],
        [81.2568130493164, 16.265140533447266],
        [81.25653076171903, 16.265140533447266],
        [81.25653076171903, 16.264858245849723],
        [81.25624847412115, 16.2648601531983],
        [81.25624847412115, 16.264583587646484],
        [81.25597381591808, 16.264583587646484],
        [81.25597381591808, 16.264305114746094],
        [81.25569152832037, 16.26431083679205],
        [81.25569152832037, 16.264030456543082],
        [81.25541687011713, 16.264030456543082],
        [81.25541687011713, 16.26374816894537],
        [81.25514221191429, 16.26374816894537],
        [81.25514221191429, 16.26346969604498],
        [81.25485992431658, 16.263471603393555],
        [81.25485992431658, 16.26319503784174],
        [81.2545928955081, 16.263200759887695],
        [81.2545928955081, 16.26292037963873],
        [81.2543029785158, 16.26292037963873],
        [81.2543029785158, 16.262638092041016],
        [81.25402832031278, 16.262638092041016],
        [81.25402832031278, 16.262081146240234],
        [81.2543029785158, 16.26208305358881],
        [81.2543029785158, 16.261806488037223],
        [81.25319671630865, 16.261810302734375],
        [81.25319671630865, 16.26152992248541],
        [81.25290679931669, 16.26152992248541],
        [81.25290679931669, 16.261249542236442],
        [81.25263977050787, 16.261249542236442],
        [81.25263977050787, 16.26097106933605],
        [81.2523574829101, 16.260972976684627],
        [81.2523574829101, 16.260694503784237],
        [81.25208282470726, 16.260694503784237],
        [81.25208282470726, 16.2604141235351],
        [81.25180816650408, 16.260419845581055],
        [81.25180816650408, 16.260139465332088],
        [81.2515258789063, 16.260139465332088],
        [81.2515258789063, 16.259860992431697],
        [81.2512512207033, 16.259860992431697],
        [81.2512512207033, 16.25958061218273],
        [81.25096893310575, 16.259582519531307],
        [81.25096893310575, 16.259304046630916],
        [81.25068664550798, 16.25930976867687],
        [81.25068664550798, 16.259029388427734],
        [81.2504196166995, 16.259029388427734],
        [81.2504196166995, 16.258750915527344],
        [81.25013732910156, 16.258750915527344],
        [81.25013732910156, 16.25819015502941],
        [81.2504196166995, 16.258195877075195],
        [81.2504196166995, 16.257917404174805],
        [81.25013732910156, 16.257921218872127],
        [81.25013732910156, 16.25764083862316],
        [81.24931335449213, 16.25764083862316],
        [81.24931335449213, 16.257360458374023],
        [81.24903106689476, 16.257360458374023],
        [81.24903106689476, 16.25708389282221],
        [81.24874877929705, 16.25708389282221],
        [81.24874877929705, 16.256805419921818],
        [81.24846649169945, 16.256811141967773],
        [81.24846649169945, 16.25654029846197],
        [81.2484741210938, 16.25625038146984],
        [81.24819183349626, 16.25625038146984],
        [81.24819183349626, 16.25597190856945],
        [81.24791717529325, 16.255973815918026],
        [81.24791717529325, 16.255695343017692],
        [81.24764251708984, 16.25570106506342],
        [81.24764251708984, 16.255420684814453],
        [81.24736022949247, 16.255420684814453],
        [81.24736022949247, 16.255140304565487],
        [81.24709320068365, 16.255140304565487],
        [81.24709320068365, 16.254579544067383],
        [81.24680328369169, 16.254583358764705],
        [81.24680328369169, 16.254304885864315],
        [81.24652862548834, 16.254304885864315],
        [81.24652862548834, 16.254028320312557],
        [81.24624633789074, 16.254030227661133],
        [81.24624633789074, 16.253751754760742],
        [81.24597167968756, 16.253751754760742],
        [81.24597167968756, 16.2534694671632],
        [81.24569702148455, 16.2534694671632],
        [81.24569702148455, 16.252916336059627],
        [81.24540710449241, 16.25292015075695],
        [81.24540710449241, 16.25264167785639],
        [81.24514007568376, 16.25264167785639],
        [81.24514007568376, 16.252359390258846],
        [81.244857788086, 16.252359390258846],
        [81.244857788086, 16.25208282470703],
        [81.24458312988298, 16.25208282470703],
        [81.24458312988298, 16.251806259155273],
        [81.24430847167997, 16.251810073852596],
        [81.24430847167997, 16.251531600952205],
        [81.24402618408203, 16.251531600952205],
        [81.24402618408203, 16.2509708404541],
        [81.24375152587919, 16.250972747802678],
        [81.24375152587919, 16.250694274902287],
        [81.2434692382813, 16.250694274902287],
        [81.2434692382813, 16.250415802001896],
        [81.24318695068371, 16.250419616699276],
        [81.24318695068371, 16.250141143798885],
        [81.24291229248053, 16.250141143798885],
        [81.24291229248053, 16.24986076354992],
        [81.24263763427729, 16.24986076354992],
        [81.24263763427729, 16.249309539794922],
        [81.24236297607445, 16.249309539794922],
        [81.24236297607445, 16.248470306396598],
        [81.24208068847673, 16.248472213745174],
        [81.24208068847673, 16.248193740844783],
        [81.24153137207048, 16.24819946289074],
        [81.24153137207048, 16.247920989990178],
        [81.24124908447294, 16.247920989990178],
        [81.24124908447294, 16.24764060974121],
        [81.240966796875, 16.24764060974121],
        [81.240966796875, 16.247360229492244],
        [81.24069213867216, 16.247360229492244],
        [81.24069213867216, 16.247079849243278],
        [81.2404174804688, 16.24708366394043],
        [81.2404174804688, 16.24680519104004],
        [81.24014282226574, 16.246810913085994],
        [81.24014282226574, 16.24625015258789],
        [81.2398529052735, 16.24625015258789],
        [81.2398529052735, 16.245969772338924],
        [81.23958587646479, 16.2459716796875],
        [81.23958587646479, 16.24541664123535],
        [81.23929595947271, 16.245420455932674],
        [81.23929595947271, 16.24513053894043],
        [81.23902893066423, 16.24513816833496],
        [81.23902893066423, 16.244861602783317],
        [81.23874664306646, 16.244861602783317],
        [81.23874664306646, 16.244304656982365],
        [81.23847198486345, 16.244304656982365],
        [81.23847198486345, 16.244028091430778],
        [81.23819732666044, 16.244029998779354],
        [81.23819732666044, 16.243471145629996],
        [81.2379150390625, 16.243473052978572],
        [81.2379150390625, 16.243194580078182],
        [81.23764038085966, 16.243194580078182],
        [81.23764038085966, 16.242639541626033],
        [81.23735809326172, 16.242639541626033],
        [81.23735809326172, 16.242361068725643],
        [81.23707580566435, 16.242361068725643],
        [81.23707580566435, 16.241809844970817],
        [81.23680877685553, 16.241809844970817],
        [81.23680877685553, 16.241252899169865],
        [81.23652648925776, 16.241252899169865],
        [81.23652648925776, 16.2409725189209],
        [81.23680877685553, 16.2409725189209],
        [81.23680877685553, 16.240694046020508],
        [81.23652648925776, 16.240694046020508],
        [81.23652648925776, 16.24041748046875],
        [81.2359695434572, 16.240419387817496],
        [81.2359695434572, 16.240142822265682],
        [81.2356872558596, 16.240142822265682],
        [81.2356872558596, 16.239862442016545],
        [81.23542022705095, 16.239862442016545],
        [81.23542022705095, 16.239578247070426],
        [81.23513793945341, 16.239583969116154],
        [81.23513793945341, 16.23847007751465],
        [81.23542022705095, 16.23847007751465],
        [81.23542022705095, 16.238195419311637],
        [81.23458099365263, 16.238189697265682],
        [81.23458099365263, 16.237918853759822],
        [81.23430633544922, 16.2379207611084],
        [81.23430633544922, 16.23735809326172],
        [81.23402404785185, 16.23736190795904],
        [81.23402404785185, 16.23708343505865],
        [81.2337493896485, 16.23708343505865],
        [81.2337493896485, 16.23680496215826],
        [81.2334671020509, 16.236810684204045],
        [81.2334671020509, 16.236530303955078],
        [81.23264312744146, 16.236530303955078],
        [81.23264312744146, 16.236257553100643],
        [81.23236083984392, 16.236257553100643],
        [81.23236083984392, 16.235969543457145],
        [81.23208618164091, 16.23597145080572],
        [81.23208618164091, 16.235694885253906],
        [81.23180389404314, 16.235694885253906],
        [81.23180389404314, 16.235416412353572],
        [81.23124694824219, 16.235420227050895],
        [81.23124694824219, 16.235139846801758],
        [81.23097229003935, 16.235139846801758],
        [81.23097229003935, 16.2345809936524],
        [81.230697631836, 16.234582901000977],
        [81.230697631836, 16.23430633544922],
        [81.23097229003935, 16.23431015014654],
        [81.23097229003935, 16.231809616088867],
        [81.230697631836, 16.231809616088867],
        [81.230697631836, 16.231531143188477],
        [81.23014068603521, 16.231531143188477],
        [81.23014068603521, 16.23125076293951],
        [81.22985839843744, 16.23125076293951],
        [81.22985839843744, 16.230690002441406],
        [81.2295837402346, 16.23069381713873],
        [81.2295837402346, 16.23013877868658],
        [81.22930908203142, 16.23013877868658],
        [81.22930908203142, 16.229583740234375],
        [81.22902679443365, 16.229583740234375],
        [81.22902679443365, 16.229305267333984],
        [81.22875213623064, 16.229312896728572],
        [81.22875213623064, 16.228750228881836],
        [81.2284698486331, 16.228750228881836],
        [81.2284698486331, 16.22820281982422],
        [81.22820281982422, 16.22820281982422],
        [81.22820281982422, 16.227640151977653],
        [81.22791290283232, 16.227640151977653],
        [81.22791290283232, 16.227092742920036],
        [81.22763824462896, 16.227092742920036],
        [81.22763824462896, 16.22680091857916],
        [81.2273635864259, 16.22680473327631],
        [81.2273635864259, 16.22625160217291],
        [81.22708129882818, 16.22625160217291],
        [81.22708129882818, 16.225690841674805],
        [81.22680664062494, 16.225694656372127],
        [81.22680664062494, 16.225141525268555],
        [81.22651672363287, 16.225141525268555],
        [81.22651672363287, 16.224859237671012],
        [81.22624969482439, 16.224859237671012],
        [81.22624969482439, 16.224309921264762],
        [81.22598266601591, 16.224309921264762],
        [81.22598266601591, 16.22374916076666],
        [81.22569274902361, 16.22374916076666],
        [81.22569274902361, 16.2231903076173],
        [81.2254180908206, 16.223194122314453],
        [81.2254180908206, 16.22263717651373],
        [81.22513580322266, 16.22264099121105],
        [81.22513580322266, 16.222360610961914],
        [81.22486114501982, 16.222360610961914],
        [81.22486114501982, 16.22180557250971],
        [81.22458648681646, 16.221809387207088],
        [81.22458648681646, 16.22125053405773],
        [81.2242965698245, 16.22125053405773],
        [81.2242965698245, 16.220689773559627],
        [81.22402954101568, 16.22069358825678],
        [81.22402954101568, 16.22041511535639],
        [81.22374725341791, 16.220420837402344],
        [81.22374725341791, 16.21986007690441],
        [81.22347259521507, 16.219861984252987],
        [81.22347259521507, 16.219306945800838],
        [81.22319793701189, 16.21931076049799],
        [81.22319793701189, 16.21875],
        [81.22291564941412, 16.21875],
        [81.22291564941412, 16.218193054199276],
        [81.22264099121111, 16.218193054199276],
        [81.22264099121111, 16.217638015747127],
        [81.22235870361357, 16.217639923095703],
        [81.22235870361357, 16.217359542846737],
        [81.22208404541016, 16.217361450195312],
        [81.22208404541016, 16.216806411743164],
        [81.22180938720732, 16.216810226440487],
        [81.22180938720732, 16.216249465942383],
        [81.22152709960938, 16.216249465942383],
        [81.22152709960938, 16.215690612793026],
        [81.22125244140636, 16.215694427490178],
        [81.22125244140636, 16.2151393890382],
        [81.22097015380865, 16.21515083312994],
        [81.22097015380865, 16.214580535888672],
        [81.22069549560541, 16.214580535888672],
        [81.22069549560541, 16.21402740478527],
        [81.22041320800787, 16.214031219482422],
        [81.22041320800787, 16.21375274658203],
        [81.22013854980486, 16.21375274658203],
        [81.22013854980486, 16.21319007873535],
        [81.21986389160162, 16.213193893432674],
        [81.21986389160162, 16.2126407623291],
        [81.21958160400408, 16.2126407623291],
        [81.21958160400408, 16.212089538574276],
        [81.21930694580107, 16.212089538574276],
        [81.21930694580107, 16.21153068542492],
        [81.21901702880876, 16.21153068542492],
        [81.21901702880876, 16.210969924926815],
        [81.21875000000028, 16.21097183227539],
        [81.21875000000028, 16.210416793823242],
        [81.21848297119146, 16.210420608520565],
        [81.21848297119146, 16.20985984802246],
        [81.2181930541995, 16.209861755371207],
        [81.2181930541995, 16.209026336669865],
        [81.21791839599615, 16.209026336669865],
        [81.21791839599615, 16.208471298217887],
        [81.21763610839855, 16.208471298217887],
        [81.21763610839855, 16.207639694213924],
        [81.21736145019537, 16.207639694213924],
        [81.21736145019537, 16.207080841064567],
        [81.21708679199236, 16.207082748413143],
        [81.21708679199236, 16.206249237060604],
        [81.21679687500023, 16.20625114440918],
        [81.21679687500023, 16.205690383911246],
        [81.21652984619158, 16.2056941986084],
        [81.21652984619158, 16.20486068725586],
        [81.2162475585938, 16.20486068725586],
        [81.2162475585938, 16.204309463501033],
        [81.2159729003908, 16.204309463501033],
        [81.2159729003908, 16.20347023010254],
        [81.21569824218778, 16.203472137451286],
        [81.21569061279325, 16.202917098999137],
        [81.21541595458984, 16.20292091369629],
        [81.21541595458984, 16.20207977294922],
        [81.215141296387, 16.20208358764654],
        [81.215141296387, 16.201528549194393],
        [81.21485900878912, 16.20153045654297],
        [81.21485900878912, 16.200702667236385],
        [81.21457672119152, 16.200702667236385],
        [81.21457672119152, 16.199861526489258],
        [81.21430206298834, 16.199861526489258],
        [81.21430206298834, 16.199029922485465],
        [81.2140274047851, 16.19903182983404],
        [81.2140274047851, 16.19819068908697],
        [81.21375274658226, 16.198194503784123],
        [81.21375274658226, 16.197359085083008],
        [81.21347045898455, 16.197359085083008],
        [81.21347045898455, 16.1962509155274],
        [81.2131958007813, 16.1962509155274],
        [81.2131958007813, 16.195421218872013],
        [81.21290588378923, 16.195421218872013],
        [81.21290588378923, 16.194580078125114],
        [81.21263885498075, 16.194583892822266],
        [81.21263885498075, 16.193750381469727],
        [81.21235656738281, 16.193750381469727],
        [81.21235656738281, 16.19292068481451],
        [81.21208190917997, 16.19292068481451],
        [81.21208190917997, 16.19207954406744],
        [81.21180725097662, 16.19208335876459],
        [81.21180725097662, 16.191249847412223],
        [81.21152496337902, 16.191249847412223],
        [81.21152496337902, 16.19013977050787],
        [81.21125030517584, 16.19013977050787],
        [81.21125030517584, 16.189310073852653],
        [81.21098327636736, 16.189310073852653],
        [81.21098327636736, 16.188472747802734],
        [81.21069335937523, 16.188472747802734],
        [81.21069335937523, 16.18736267089838],
        [81.21041870117205, 16.18736267089838],
        [81.21041870117205, 16.186531066894588],
        [81.21013641357428, 16.186531066894588],
        [81.21013641357428, 16.185689926147518],
        [81.20986175537126, 16.18569374084467],
        [81.20986175537126, 16.185140609741268],
        [81.20958709716825, 16.185140609741268],
        [81.20958709716825, 16.184579849243164],
        [81.20930480957031, 16.184583663940487],
        [81.20930480957031, 16.184030532836914],
        [81.20903015136747, 16.184030532836914],
        [81.20903015136747, 16.183200836181697],
        [81.20874786376953, 16.183200836181697],
        [81.20874786376953, 16.182638168335018],
        [81.20847320556652, 16.182638168335018],
        [81.20847320556652, 16.182081222534237],
        [81.2081909179688, 16.182083129882812],
        [81.2081909179688, 16.181249618530273],
        [81.20791625976557, 16.181249618530273],
        [81.20791625976557, 16.180690765380916],
        [81.20764160156273, 16.180694580078068],
        [81.20764160156273, 16.18013954162609],
        [81.20735931396501, 16.18013954162609],
        [81.20735931396501, 16.179580688476562],
        [81.20708465576178, 16.17958259582531],
        [81.20708465576178, 16.17874908447277],
        [81.20680999755876, 16.178750991821346],
        [81.20680999755876, 16.177919387817383],
        [81.20652770996122, 16.177919387817383],
        [81.20652770996122, 16.17708015441906],
        [81.20625305175781, 16.177082061767635],
        [81.20625305175781, 16.17680549621582],
        [81.20569610595703, 16.176811218261776],
        [81.20569610595703, 16.17653083801281],
        [81.20540618896513, 16.17653083801281],
        [81.20540618896513, 16.175979614257812],
        [81.2051391601563, 16.175979614257812],
        [81.2051391601563, 16.174861907959098],
        [81.20541381835966, 16.174863815307674],
        [81.20541381835966, 16.173751831054744],
        [81.2051391601563, 16.173751831054744],
        [81.2051391601563, 16.17264175415039],
        [81.20485687255871, 16.17264175415039],
        [81.20485687255871, 16.171531677246037],
        [81.20458221435553, 16.171531677246037],
        [81.20458221435553, 16.170690536499137],
        [81.20430755615251, 16.17069435119629],
        [81.20430755615251, 16.16986083984375],
        [81.20402526855491, 16.16986083984375],
        [81.20402526855491, 16.169305801391545],
        [81.2037429809572, 16.169309616088924],
        [81.2037429809572, 16.16792106628418],
        [81.20403289794928, 16.16792106628418],
        [81.20403289794928, 16.164859771728572],
        [81.20375061035173, 16.16486167907715],
        [81.20375061035173, 16.16402816772461],
        [81.20347595214872, 16.164039611816463],
        [81.20347595214872, 16.163192749023438],
        [81.20319366455095, 16.163194656372013],
        [81.20319366455095, 16.161527633667106],
        [81.20291900634794, 16.161529541015682],
        [81.20291900634794, 16.156810760498047],
        [81.20318603515642, 16.156810760498047],
        [81.20318603515642, 16.155700683593693],
        [81.20347595214872, 16.155700683593693],
        [81.20347595214872, 16.154581069946403],
        [81.20375061035173, 16.15458297729498],
        [81.20375061035173, 16.15402793884283],
        [81.20403289794928, 16.154029846191406],
        [81.20403289794928, 16.15208053588873],
        [81.20430755615251, 16.152082443237305],
        [81.20430755615251, 16.149583816528377],
        [81.20402526855491, 16.149583816528377],
        [81.20402526855491, 16.149305343627987],
        [81.2037429809572, 16.14931106567377],
        [81.2037429809572, 16.148471832275447],
        [81.20347595214872, 16.148473739624023],
        [81.20347595214872, 16.148195266723633],
        [81.20318603515642, 16.14820289611822],
        [81.20318603515642, 16.14792060852062],
        [81.20291900634794, 16.14792060852062],
        [81.20291900634794, 16.14707946777355],
        [81.20263671875, 16.147083282470703],
        [81.20263671875, 16.146804809570312],
        [81.20236206054716, 16.146804809570312],
        [81.20236206054716, 16.145416259765568],
        [81.20207977294928, 16.145420074462947],
        [81.20207977294928, 16.145141601562557],
        [81.20180511474621, 16.145141601562557],
        [81.20180511474621, 16.144859313964844],
        [81.20124816894526, 16.144859313964844],
        [81.20124816894526, 16.144580841064453],
        [81.20014190673845, 16.144580841064453],
        [81.20014190673845, 16.145141601562557],
        [81.20041656494146, 16.145141601562557],
        [81.20041656494146, 16.1465301513673],
        [81.20014190673845, 16.146528244018555],
        [81.20014190673845, 16.147361755371094],
        [81.1995849609375, 16.147361755371094],
        [81.1995849609375, 16.147083282470703],
        [81.19902801513678, 16.147083282470703],
        [81.19902801513678, 16.146804809570312],
        [81.198471069336, 16.146804809570312],
        [81.198471069336, 16.145416259765568],
        [81.19819641113276, 16.145420074462947],
        [81.19819641113276, 16.143751144409237],
        [81.19790649414068, 16.143751144409237],
        [81.19790649414068, 16.14347076416027],
        [81.1976394653322, 16.143472671508846],
        [81.1976394653322, 16.143194198608455],
        [81.19736480712896, 16.143194198608455],
        [81.19736480712896, 16.142919540405273],
        [81.19680786132841, 16.14292907714838],
        [81.19680786132841, 16.142641067504883],
        [81.19568634033232, 16.142641067504883],
        [81.19568634033232, 16.14292907714838],
        [81.19041442871111, 16.142919540405273],
        [81.19041442871111, 16.143194198608455],
        [81.18902587890636, 16.143194198608455],
        [81.18902587890636, 16.142919540405273],
        [81.18875122070318, 16.142919540405273],
        [81.18875122070318, 16.143194198608455],
        [81.18819427490257, 16.143194198608455],
        [81.18819427490257, 16.143472671508846],
        [81.18763732910162, 16.14347076416027],
        [81.18763732910162, 16.143751144409237],
        [81.18708038330107, 16.143751144409237],
        [81.18708038330107, 16.144029617309627],
        [81.18569946289068, 16.144029617309627],
        [81.18569946289068, 16.143751144409237],
        [81.18514251709007, 16.143751144409237],
        [81.18514251709007, 16.14347076416027],
        [81.18264007568365, 16.143472671508846],
        [81.18264007568365, 16.142915725708065],
        [81.18319702148443, 16.14292907714838],
        [81.18319702148443, 16.142641067504883],
        [81.18402862548857, 16.142641067504883],
        [81.18402862548857, 16.142360687255916],
        [81.18514251709007, 16.142360687255916],
        [81.18514251709007, 16.142641067504883],
        [81.18624877929693, 16.142641067504883],
        [81.18624877929693, 16.14292907714838],
        [81.18736267089861, 16.14292907714838],
        [81.18736267089861, 16.142641067504883],
        [81.18763732910162, 16.142641067504883],
        [81.18763732910162, 16.14292907714838],
        [81.18846893310541, 16.14292907714838],
        [81.18846893310541, 16.142641067504883],
        [81.18863677978527, 16.142641067504883],
        [81.18958282470732, 16.142641067504883],
        [81.18958282470732, 16.142360687255916],
        [81.19040679931658, 16.142360687255916],
        [81.19040679931658, 16.14208030700695],
        [81.19097137451189, 16.1420841217041],
        [81.19097137451189, 16.14180564880371],
        [81.19235992431646, 16.14180946350109],
        [81.19235992431646, 16.1415309906007],
        [81.19374847412138, 16.1415309906007],
        [81.19374847412138, 16.141250610351562],
        [81.19486999511741, 16.141250610351562],
        [81.19486236572288, 16.140972137451172],
        [81.19708251953142, 16.140970230102596],
        [81.19708251953142, 16.141250610351562],
        [81.19735717773443, 16.141250610351562],
        [81.19735717773443, 16.140970230102596],
        [81.19819641113276, 16.140970230102596],
        [81.19819641113276, 16.141250610351562],
        [81.19875335693371, 16.141250610351562],
        [81.19875335693371, 16.1415309906007],
        [81.19930267334013, 16.1415309906007],
        [81.19930267334013, 16.14180946350109],
        [81.19985961914091, 16.14180946350109],
        [81.19985961914091, 16.142360687255916],
        [81.20014190673845, 16.142360687255916],
        [81.20014190673845, 16.143194198608455],
        [81.20153045654303, 16.143194198608455],
        [81.20153045654303, 16.142915725708065],
        [81.20181274414057, 16.14292907714838],
        [81.20181274414057, 16.142360687255916],
        [81.20153045654303, 16.142360687255916],
        [81.20153045654303, 16.140970230102596],
        [81.20124816894526, 16.140972137451172],
        [81.20124816894526, 16.13986206054699],
        [81.20096588134788, 16.13986968994135],
        [81.20096588134788, 16.13820075988764],
        [81.20067596435541, 16.13820075988764],
        [81.20067596435541, 16.135971069335994],
        [81.20041656494146, 16.135974884033146],
        [81.20041656494146, 16.13541603088379],
        [81.20014190673845, 16.13541984558117],
        [81.20014190673845, 16.134029388427848],
        [81.19985961914091, 16.134029388427848],
        [81.19985961914091, 16.133190155029354],
        [81.1995849609375, 16.133193969726506],
        [81.1995849609375, 16.132640838623104],
        [81.19930267334013, 16.132640838623104],
        [81.19930267334013, 16.132078170776367],
        [81.19902801513678, 16.132083892822322],
        [81.19902801513678, 16.131526947021598],
        [81.19875335693371, 16.13153076171875],
        [81.19875335693371, 16.130420684814567],
        [81.198471069336, 16.130420684814567],
        [81.198471069336, 16.129579544067496],
        [81.19819641113276, 16.12958335876465],
        [81.19819641113276, 16.12847137451172],
        [81.19791412353538, 16.12847137451172],
        [81.19791412353538, 16.126527786254996],
        [81.1976394653322, 16.126529693603572],
        [81.1976394653322, 16.125970840454215],
        [81.19736480712896, 16.12597274780279],
        [81.19736480712896, 16.125139236450252],
        [81.19708251953142, 16.125141143798828],
        [81.19708251953142, 16.124580383300895],
        [81.19680786132841, 16.124584197998047],
        [81.19680786132841, 16.123750686645508],
        [81.19652557373047, 16.123750686645508],
        [81.19652557373047, 16.123193740844727],
        [81.19625091552763, 16.123193740844727],
        [81.19625091552763, 16.122360229492188],
        [81.19596862792969, 16.122360229492188],
        [81.19596862792969, 16.12153053283697],
        [81.19568634033232, 16.12153053283697],
        [81.19568634033232, 16.120702743530387],
        [81.1954193115235, 16.120702743530387],
        [81.1954193115235, 16.12014007568365],
        [81.19513702392572, 16.12014007568365],
        [81.19513702392572, 16.119300842285156],
        [81.19486236572288, 16.11930465698248],
        [81.19486236572288, 16.118473052978516],
        [81.19458007812517, 16.118473052978516],
        [81.19458007812517, 16.1176376342774],
        [81.19430541992193, 16.1176376342774],
        [81.19430541992193, 16.116807937622184],
        [81.19403076171892, 16.11680984497076],
        [81.19403076171892, 16.115970611572266],
        [81.19374847412138, 16.115972518921012],
        [81.19374847412138, 16.11541938781744],
        [81.19346618652361, 16.11541938781744],
        [81.19346618652361, 16.114580154418945],
        [81.1931915283206, 16.114583969116268],
        [81.1931915283206, 16.11375045776373],
        [81.19291687011719, 16.11375045776373],
        [81.19291687011719, 16.112930297851676],
        [81.19264221191418, 16.112930297851676],
        [81.19264221191418, 16.11236000061041],
        [81.19235992431646, 16.112361907958984],
        [81.19235992431646, 16.111528396606445],
        [81.19209289550776, 16.111530303955192],
        [81.19209289550776, 16.110691070556697],
        [81.19180297851568, 16.11069488525385],
        [81.19180297851568, 16.11013984680187],
        [81.19152832031267, 16.11013984680187],
        [81.19152832031267, 16.109310150146484],
        [81.19124603271507, 16.109310150146484],
        [81.19124603271507, 16.10874938964855],
        [81.19097137451189, 16.10874938964855],
        [81.19097137451189, 16.107919692993164],
        [81.19069671630888, 16.107919692993164],
        [81.19069671630888, 16.10708045959484],
        [81.19041442871111, 16.107084274291992],
        [81.19041442871111, 16.106527328491268],
        [81.1901397705081, 16.10653114318859],
        [81.1901397705081, 16.105688095092717],
        [81.18985748291016, 16.105693817138672],
        [81.18985748291016, 16.10514068603527],
        [81.18958282470732, 16.10514068603527],
        [81.18958282470732, 16.104309082031307],
        [81.18930816650396, 16.104309082031307],
        [81.18930816650396, 16.103469848632812],
        [81.18902587890636, 16.10347175598156],
        [81.18902587890636, 16.102918624877987],
        [81.18873596191412, 16.102920532226562],
        [81.18873596191412, 16.10235977172863],
        [81.18846893310541, 16.102361679077205],
        [81.18846893310541, 16.101804733276424],
        [81.18819427490257, 16.101804733276424],
        [81.18819427490257, 16.101249694824276],
        [81.18791961669939, 16.101249694824276],
        [81.18791961669939, 16.100700378418026],
        [81.18763732910162, 16.100700378418026],
        [81.18763732910162, 16.100139617919922],
        [81.18736267089861, 16.100139617919922],
        [81.18736267089861, 16.099580764770565],
        [81.18708038330107, 16.09958267211914],
        [81.18708038330107, 16.099027633666992],
        [81.18681335449219, 16.09902954101574],
        [81.18681335449219, 16.09847068786621],
        [81.18653106689482, 16.098472595214957],
        [81.18653106689482, 16.098194122314396],
        [81.18624877929693, 16.098194122314396],
        [81.18624877929693, 16.09763908386242],
        [81.18597412109386, 16.09763908386242],
        [81.18597412109386, 16.097082138061637],
        [81.18569183349615, 16.097082138061637],
        [81.18569183349615, 16.09652709960949],
        [81.18541717529291, 16.09653091430664],
        [81.18541717529291, 16.095970153808707],
        [81.18514251709007, 16.095972061157283],
        [81.18514251709007, 16.095417022705135],
        [81.18486022949236, 16.095420837402457],
        [81.18486022949236, 16.094860076904354],
        [81.18458557128912, 16.094863891601506],
        [81.18458557128912, 16.09430503845215],
        [81.18430328369158, 16.094310760498104],
        [81.18430328369158, 16.093751907348746],
        [81.18402862548857, 16.093751907348746],
        [81.18402862548857, 16.093469619751033],
        [81.18374633789062, 16.093469619751033],
        [81.18374633789062, 16.092916488647404],
        [81.18347167968778, 16.092920303344783],
        [81.18347167968778, 16.092361450195426],
        [81.18319702148443, 16.092361450195426],
        [81.18319702148443, 16.091806411743278],
        [81.18290710449247, 16.091819763183537],
        [81.18290710449247, 16.091247558593693],
        [81.18264007568365, 16.091247558593693],
        [81.18264007568365, 16.09069061279297],
        [81.18235778808588, 16.09069442749029],
        [81.18235778808588, 16.090137481689567],
        [81.18208312988304, 16.090137481689567],
        [81.18208312988304, 16.089580535888786],
        [81.18180847167986, 16.08958244323736],
        [81.18180847167986, 16.08930778503418],
        [81.18152618408209, 16.089309692382926],
        [81.18152618408209, 16.088760375976506],
        [81.18125152587908, 16.088760375976506],
        [81.18125152587908, 16.088190078735465],
        [81.18096923828153, 16.088193893432617],
        [81.18096923828153, 16.08763885498047],
        [81.18068695068376, 16.087640762329215],
        [81.18068695068376, 16.087360382080078],
        [81.18041992187528, 16.087360382080078],
        [81.18041992187528, 16.086811065673828],
        [81.18013763427734, 16.086811065673828],
        [81.18013763427734, 16.086250305175895],
        [81.17986297607433, 16.086250305175895],
        [81.17986297607433, 16.08568954467779],
        [81.17958068847662, 16.08569526672369],
        [81.17958068847662, 16.085416793823356],
        [81.17930603027338, 16.085420608520508],
        [81.17930603027338, 16.084859848022575],
        [81.17903137207054, 16.08486175537115],
        [81.17903137207054, 16.08430480957037],
        [81.17874908447283, 16.08430480957037],
        [81.17874908447283, 16.08374977111822],
        [81.17846679687523, 16.08374977111822],
        [81.17846679687523, 16.083469390869254],
        [81.17819213867205, 16.08347129821783],
        [81.17819213867205, 16.082916259765625],
        [81.17791748046903, 16.082920074463004],
        [81.17791748046903, 16.082361221313477],
        [81.17763519287126, 16.082361221313477],
        [81.17763519287126, 16.08180618286127],
        [81.17736053466825, 16.08180999755865],
        [81.17736053466825, 16.081529617309684],
        [81.17708587646484, 16.081529617309684],
        [81.17708587646484, 16.080972671508903],
        [81.17680358886747, 16.080972671508903],
        [81.17680358886747, 16.080417633056754],
        [81.17664337158197, 16.08041572570795],
        [81.17652893066412, 16.08041954040533],
        [81.17652893066412, 16.07986259460455],
        [81.17624664306652, 16.07986259460455],
        [81.17624664306652, 16.07959175109869],
        [81.17597198486334, 16.07959175109869],
        [81.17597198486334, 16.07902908325201],
        [81.17569732666033, 16.07902908325201],
        [81.17569732666033, 16.07846832275385],
        [81.17541503906273, 16.078470230102653],
        [81.17541503906273, 16.07791709899908],
        [81.17514038085955, 16.077920913696403],
        [81.17514038085955, 16.077640533447266],
        [81.17485809326178, 16.077640533447266],
        [81.17485809326178, 16.077079772949332],
        [81.17458343505876, 16.077083587646484],
        [81.17458343505876, 16.076530456543082],
        [81.17430877685575, 16.076530456543082],
        [81.17430877685575, 16.07596969604498],
        [81.17402648925781, 16.075971603393555],
        [81.17402648925781, 16.075696945190543],
        [81.17375946044933, 16.075700759887695],
        [81.17375946044933, 16.075138092041016],
        [81.17346954345709, 16.075138092041016],
        [81.17346954345709, 16.074581146240234],
        [81.17319488525402, 16.07458305358898],
        [81.17319488525402, 16.07402610778803],
        [81.17292022705084, 16.07402992248541],
        [81.17292022705084, 16.073749542236442],
        [81.17263793945307, 16.073749542236442],
        [81.17263793945307, 16.073190689086914],
        [81.17236328125023, 16.073194503784237],
        [81.17236328125023, 16.072639465332088],
        [81.17208099365251, 16.072639465332088],
        [81.17208099365251, 16.07208061218273],
        [81.17180633544928, 16.072082519531307],
        [81.17180633544928, 16.07152748107916],
        [81.1715164184572, 16.071529388427734],
        [81.1715164184572, 16.070970535278377],
        [81.17124938964872, 16.070972442626953],
        [81.17124938964872, 16.07069587707514],
        [81.17097473144531, 16.07069587707514],
        [81.17097473144531, 16.070138931274414],
        [81.17070007324247, 16.07014083862316],
        [81.17070007324247, 16.069580078125057],
        [81.17041778564459, 16.06958389282221],
        [81.17041778564459, 16.069026947021428],
        [81.17014312744152, 16.069030761718807],
        [81.17014312744152, 16.06847190856945],
        [81.1698608398438, 16.068473815918026],
        [81.1698608398438, 16.0679168701173],
        [81.16958618164057, 16.067920684814453],
        [81.16958618164057, 16.067649841308594],
        [81.1692962646485, 16.067649841308594],
        [81.1692962646485, 16.067079544067383],
        [81.16902923584001, 16.067083358764705],
        [81.16902923584001, 16.066528320312557],
        [81.16874694824224, 16.066530227661133],
        [81.16874694824224, 16.0659694671632],
        [81.16847229003923, 16.0659694671632],
        [81.16847229003923, 16.06541633605957],
        [81.16819763183622, 16.06542015075695],
        [81.16819763183622, 16.064861297607422],
        [81.16791534423828, 16.064861297607422],
        [81.16791534423828, 16.064306259155217],
        [81.16763305664091, 16.06430816650402],
        [81.16763305664091, 16.06402969360363],
        [81.1673583984375, 16.06402969360363],
        [81.1673583984375, 16.0634708404541],
        [81.16708374023449, 16.063472747802848],
        [81.16708374023449, 16.062915802001896],
        [81.1668090820313, 16.062919616699276],
        [81.1668090820313, 16.06236076354992],
        [81.16652679443354, 16.06236076354992],
        [81.16652679443354, 16.061809539794922],
        [81.1662521362307, 16.061809539794922],
        [81.1662521362307, 16.061250686645565],
        [81.16596984863298, 16.061250686645565],
        [81.16596984863298, 16.060970306396598],
        [81.16569519042974, 16.060972213745174],
        [81.16569519042974, 16.060415267944393],
        [81.1654129028322, 16.060420989990348],
        [81.1654129028322, 16.059860229492244],
        [81.16513824462919, 16.059860229492244],
        [81.16513824462919, 16.059310913085994],
        [81.16485595703142, 16.059310913085994],
        [81.16485595703142, 16.05875015258789],
        [81.16456604003935, 16.05875015258789],
        [81.16456604003935, 16.05820083618164],
        [81.164306640625, 16.05820083618164],
        [81.164306640625, 16.057920455932674],
        [81.1640167236331, 16.057920455932674],
        [81.1640167236331, 16.05735969543457],
        [81.16374969482428, 16.057361602783317],
        [81.16374969482428, 16.056804656982365],
        [81.16347503662104, 16.056804656982365],
        [81.16347503662104, 16.056249618530387],
        [81.1631927490235, 16.056249618530387],
        [81.1631927490235, 16.055971145629996],
        [81.16291809082048, 16.055973052978572],
        [81.16291809082048, 16.05541610717779],
        [81.16263580322288, 16.055419921875],
        [81.16263580322288, 16.054861068725643],
        [81.1623611450197, 16.054861068725643],
        [81.1623611450197, 16.054550170898494],
        [81.1623611450197, 16.054306030273438],
        [81.16208648681669, 16.054306030273438],
        [81.16208648681669, 16.054027557373104],
        [81.16180419921892, 16.054027557373104],
        [81.16180419921892, 16.0534725189209],
        [81.16152954101591, 16.0534725189209],
        [81.16152954101591, 16.05291748046875],
        [81.16124725341797, 16.052919387817496],
        [81.16124725341797, 16.052358627319393],
        [81.16097259521513, 16.052358627319393],
        [81.16097259521513, 16.05207824707037],
        [81.16069793701178, 16.052083969116154],
        [81.16069793701178, 16.051528930664176],
        [81.16042327880854, 16.051530838012752],
        [81.16042327880854, 16.050968170166016],
        [81.160140991211, 16.05097007751465],
        [81.160140991211, 16.050418853759822],
        [81.15985870361322, 16.0504207611084],
        [81.15985870361322, 16.050140380859432],
        [81.15957641601585, 16.050140380859432],
        [81.15957641601585, 16.049579620361328],
        [81.15930175781267, 16.04958343505865],
        [81.15930175781267, 16.04902648925787],
        [81.15902709960943, 16.049030303955078],
        [81.15902709960943, 16.048469543457145],
        [81.15875244140642, 16.04847145080572],
        [81.15875244140642, 16.048194885253906],
        [81.15847015380888, 16.048194885253906],
        [81.15847015380888, 16.047639846801758],
        [81.158203125, 16.047639846801758],
        [81.158203125, 16.0470809936524],
        [81.1579132080081, 16.047082901000977],
        [81.1579132080081, 16.046527862548828],
        [81.15763854980474, 16.046527862548828],
        [81.15763854980474, 16.046249389648438],
        [81.15735626220714, 16.046249389648438],
        [81.15735626220714, 16.04569053649908],
        [81.15708160400396, 16.045694351196232],
        [81.15708160400396, 16.045139312744254],
        [81.15680694580072, 16.04514122009283],
        [81.15680694580072, 16.044580459594727],
        [81.15652465820335, 16.04458427429205],
        [81.15652465820335, 16.044027328491325],
        [81.15625000000017, 16.044031143188477],
        [81.15625000000017, 16.043462753296012],
        [81.15597534179693, 16.04347229003912],
        [81.15597534179693, 16.04291725158697],
        [81.15569305419939, 16.042921066284293],
        [81.15569305419939, 16.04236030578619],
        [81.15541839599638, 16.04236030578619],
        [81.15541839599638, 16.041812896728516],
        [81.15513610839844, 16.041812896728516],
        [81.15513610839844, 16.041250228881836],
        [81.1548614501956, 16.041250228881836],
        [81.1548614501956, 16.04071044921875],
        [81.15458679199224, 16.04071044921875],
        [81.15458679199224, 16.04042053222662],
        [81.15429687500028, 16.04042053222662],
        [81.15429687500028, 16.039861679077262],
        [81.15402984619146, 16.039861679077262],
        [81.15402984619146, 16.03930473327631],
        [81.15430450439464, 16.03930473327631],
        [81.15430450439464, 16.03875160217291],
        [81.15402984619146, 16.03875160217291],
        [81.15402984619146, 16.038469314575195],
        [81.15347290039085, 16.038473129272518],
        [81.15347290039085, 16.038194656372127],
        [81.15319824218767, 16.038194656372127],
        [81.15319061279314, 16.03763961791998],
        [81.1529159545899, 16.03763961791998],
        [81.1529159545899, 16.03708076477062],
        [81.15264129638689, 16.037082672119197],
        [81.15264129638689, 16.036529541015625],
        [81.15235900878935, 16.036529541015625],
        [81.15235900878935, 16.035970687866268],
        [81.15208435058594, 16.035972595214844],
        [81.15208435058594, 16.035419464111442],
        [81.15180206298857, 16.035419464111442],
        [81.15180206298857, 16.034860610961914],
        [81.15152740478516, 16.034860610961914],
        [81.15152740478516, 16.034580230712947],
        [81.15125274658214, 16.0345840454101],
        [81.15125274658214, 16.034025192260742],
        [81.15097045898443, 16.034030914306697],
        [81.15097045898443, 16.033470153808594],
        [81.1506958007812, 16.03347206115734],
        [81.1506958007812, 16.03319358825695],
        [81.15041351318365, 16.03319358825695],
        [81.15041351318365, 16.0326385498048],
        [81.15013885498064, 16.032640457153377],
        [81.15013885498064, 16.03236007690441],
        [81.1498641967774, 16.032361984252987],
        [81.1498641967774, 16.03180313110363],
        [81.14958953857439, 16.03181076049816],
        [81.14958953857439, 16.03125],
        [81.14930725097685, 16.03125],
        [81.14930725097685, 16.03096961975109],
        [81.14902496337908, 16.030971527099666],
        [81.14902496337908, 16.03041648864746],
        [81.14875030517607, 16.03042030334484],
        [81.14875030517607, 16.030139923095703],
        [81.14848327636724, 16.030139923095703],
        [81.14848327636724, 16.029581069946346],
        [81.14819335937528, 16.029584884643498],
        [81.14819335937528, 16.029306411743107],
        [81.14791870117193, 16.029310226440487],
        [81.14791870117193, 16.02902984619152],
        [81.14763641357433, 16.02902984619152],
        [81.14763641357433, 16.0284805297851],
        [81.14736175537115, 16.0284805297851],
        [81.14736175537115, 16.028190612793026],
        [81.14708709716814, 16.028194427490178],
        [81.14708709716814, 16.027915954589787],
        [81.14679718017601, 16.027919769287166],
        [81.14679718017601, 16.027362823486385],
        [81.14653015136736, 16.027362823486385],
        [81.14653015136736, 16.02708244323742],
        [81.14624786376959, 16.02708244323742],
        [81.14624786376959, 16.02652740478527],
        [81.14597320556658, 16.026531219482422],
        [81.14597320556658, 16.02625274658203],
        [81.14569091796903, 16.02625274658203],
        [81.14569091796903, 16.025968551635685],
        [81.14541625976562, 16.025972366333065],
        [81.14541625976562, 16.025417327880916],
        [81.14514160156278, 16.025419235229492],
        [81.14514160156278, 16.0251407623291],
        [81.1448593139649, 16.0251407623291],
        [81.1448593139649, 16.02485847473156],
        [81.1445770263673, 16.02485847473156],
        [81.1445770263673, 16.02430915832531],
        [81.14430236816412, 16.02430915832531],
        [81.14430236816412, 16.02403068542492],
        [81.14402770996088, 16.02403068542492],
        [81.14402770996088, 16.023748397827205],
        [81.14375305175804, 16.023748397827205],
        [81.14375305175804, 16.023199081420955],
        [81.1434631347658, 16.023199081420955],
        [81.1434631347658, 16.022640228271598],
        [81.14319610595709, 16.022640228271598],
        [81.14319610595709, 16.022079467773494],
        [81.14291381835955, 16.022083282470817],
        [81.14291381835955, 16.021526336669865],
        [81.14263916015653, 16.021530151367244],
        [81.14263916015653, 16.02096939086914],
        [81.14236450195312, 16.020971298217887],
        [81.14236450195312, 16.020416259765682],
        [81.14208221435575, 16.02042007446289],
        [81.14208221435575, 16.019861221313533],
        [81.1418075561524, 16.019861221313533],
        [81.1418075561524, 16.019309997558707],
        [81.1415405273437, 16.019309997558707],
        [81.1415252685548, 16.019199371338004],
        [81.1415252685548, 16.018749237060604],
        [81.14137268066423, 16.018749237060604],
        [81.14125061035162, 16.01875114440918],
        [81.14125061035162, 16.018449783325195],
        [81.14125061035162, 16.0181941986084],
        [81.14097595214838, 16.0181941986084],
        [81.14097595214838, 16.017692565918026],
        [81.14097595214838, 16.016805648803654],
        [81.1406860351563, 16.016809463501033],
        [81.1406860351563, 16.01542091369629],
        [81.14041900634783, 16.01542091369629],
        [81.14041900634783, 16.013750076294002],
        [81.14013671875006, 16.013750076294002],
        [81.14013671875006, 16.012079238891715],
        [81.13986206054705, 16.012083053588867],
        [81.13986206054705, 16.009859085083008],
        [81.1395797729495, 16.009859085083008],
        [81.1395797729495, 16.007640838623047],
        [81.13986206054705, 16.007640838623047],
        [81.13986206054705, 16.006530761718864],
        [81.1395797729495, 16.006530761718864],
        [81.1395797729495, 16.005140304565543],
        [81.13986206054705, 16.005140304565543],
        [81.13986206054705, 16.003192901611442],
        [81.1395797729495, 16.003194808960018],
        [81.1395797729495, 16.000139236450195],
        [81.1395797729495, 15.999861717224064],
        [81.1395797729495, 15.997639656066951],
        [81.13931274414062, 15.997639656066951],
        [81.13931274414062, 15.997359275817985],
        [81.13903045654325, 15.997362136840877],
        [81.13903045654325, 15.995973587036133],
        [81.1393051147461, 15.995973587036133],
        [81.1393051147461, 15.995693206787166],
        [81.1395797729495, 15.995693206787166],
        [81.1395797729495, 15.995139122009277],
        [81.13986206054705, 15.99514198303234],
        [81.13986206054705, 15.994310379028377],
        [81.14013671875006, 15.994310379028377],
        [81.14013671875006, 15.994031906127987],
        [81.14041900634783, 15.994031906127987],
        [81.14041900634783, 15.993471145629883],
        [81.14069366455101, 15.993471145629883],
        [81.14069366455101, 15.991805076599235],
        [81.14097595214838, 15.991811752319279],
        [81.14097595214838, 15.99097061157238],
        [81.14125061035162, 15.990973472595272],
        [81.14125061035162, 15.989860534668026],
        [81.14097595214838, 15.989860534668026],
        [81.14097595214838, 15.988751411437931],
        [81.14125061035162, 15.988751411437931],
        [81.14125061035162, 15.988471031188965],
        [81.1415252685548, 15.988472938537598],
        [81.1415252685548, 15.987360954284611],
        [81.1418075561524, 15.987360954284611],
        [81.1418075561524, 15.985969543457031],
        [81.14208221435575, 15.985973358154354],
        [81.14208221435575, 15.985138893127498],
        [81.1423568725586, 15.985140800476074],
        [81.1423568725586, 15.984861373901424],
        [81.14263916015653, 15.984861373901424],
        [81.14263916015653, 15.984582901001033],
        [81.14291381835955, 15.984582901001033],
        [81.14291381835955, 15.984304428100643],
        [81.1434631347658, 15.984311103820914],
        [81.1434631347658, 15.982362747192496],
        [81.14402770996088, 15.982362747192496],
        [81.14402770996088, 15.982641220092887],
        [81.14430236816412, 15.982641220092887],
        [81.14430236816412, 15.982362747192496],
        [81.1448593139649, 15.982362747192496],
        [81.1448593139649, 15.98181056976324],
        [81.14514160156278, 15.98181056976324],
        [81.14514160156278, 15.981531143188533],
        [81.14541625976562, 15.981531143188533],
        [81.14541625976562, 15.98097038269043],
        [81.14569091796903, 15.980972290039176],
        [81.14569091796903, 15.980693817138786],
        [81.14597320556658, 15.980693817138786],
        [81.14597320556658, 15.979306221008358],
        [81.14569091796903, 15.979310989379996],
        [81.14569091796903, 15.97903060913086],
        [81.14541625976562, 15.97903060913086],
        [81.14541625976562, 15.978469848632926],
        [81.14514160156278, 15.978471755981502],
        [81.14514160156278, 15.977640151977539],
        [81.14541625976562, 15.977640151977539],
        [81.14541625976562, 15.974860191345158],
        [81.14597320556658, 15.974862098693961],
        [81.14597320556658, 15.97430610656744],
        [81.14569091796903, 15.97430610656744],
        [81.14569091796903, 15.973750114440975],
        [81.14402770996088, 15.973752021789608],
        [81.14402770996088, 15.973471641540584],
        [81.14375305175804, 15.973471641540584],
        [81.14375305175804, 15.97319412231451],
        [81.14319610595709, 15.97319412231451],
        [81.14319610595709, 15.97291564941412],
        [81.14236450195312, 15.97291564941412],
        [81.14236450195312, 15.97319412231451],
        [81.1415252685548, 15.97319412231451],
        [81.1415252685548, 15.973471641540584],
        [81.14097595214838, 15.973471641540584],
        [81.14097595214838, 15.97319412231451],
        [81.13986206054705, 15.97319412231451],
        [81.13986206054705, 15.973471641540584],
        [81.13903045654325, 15.973471641540584],
        [81.13903045654325, 15.97319412231451],
        [81.13874816894531, 15.97319412231451],
        [81.13874816894531, 15.973471641540584],
        [81.13819122314459, 15.973471641540584],
        [81.13819122314459, 15.973752021789608],
        [81.13680267334001, 15.973752021789608],
        [81.13680267334001, 15.974029541015682],
        [81.13569641113281, 15.974027633667106],
        [81.13569641113281, 15.97430610656744],
        [81.13541412353544, 15.97430610656744],
        [81.13541412353544, 15.974027633667106],
        [81.13346862792974, 15.974029541015682],
        [81.13346862792974, 15.973752021789608],
        [81.13263702392578, 15.973752021789608],
        [81.13263702392578, 15.973471641540584],
        [81.13236236572294, 15.973471641540584],
        [81.13236236572294, 15.97319412231451],
        [81.13208007812506, 15.97319412231451],
        [81.13208007812506, 15.97291564941412],
        [81.13152313232428, 15.972920417785588],
        [81.13152313232428, 15.972641944885254],
        [81.13124847412104, 15.972641944885254],
        [81.13124847412104, 15.972359657287654],
        [81.1309738159182, 15.97236156463623],
        [81.1309738159182, 15.972084045410156],
        [81.13069152832048, 15.972084045410156],
        [81.13069152832048, 15.971805572509766],
        [81.13041687011724, 15.971810340881461],
        [81.13041687011724, 15.971240043640194],
        [81.13069152832048, 15.9712495803833],
        [81.13069152832048, 15.970417976379508],
        [81.13096618652366, 15.970421791076717],
        [81.13096618652366, 15.96875190734869],
        [81.13069152832048, 15.96875190734869],
        [81.13069152832048, 15.96736145019537],
        [81.13041687011724, 15.96736145019537],
        [81.13041687011724, 15.967082977294979],
        [81.13014221191423, 15.967082977294979],
        [81.13014221191423, 15.966805458068904],
        [81.12985992431669, 15.966810226440543],
        [81.12985992431669, 15.966531753540153],
        [81.12959289550781, 15.966531753540153],
        [81.12959289550781, 15.965970993042049],
        [81.12930297851591, 15.965970993042049],
        [81.12930297851591, 15.965790748596191],
        [81.12930297851591, 15.965139389038086],
        [81.12902832031256, 15.965141296386832],
        [81.12902832031256, 15.964580535888729],
        [81.12875366210949, 15.964583396911621],
        [81.12875366210949, 15.9640274047851],
        [81.12845611572271, 15.964029312133903],
        [81.12845611572271, 15.963471412658635],
        [81.12819671630854, 15.963473320007438],
        [81.12819671630854, 15.963194847107047],
        [81.12791442871116, 15.963194847107047],
        [81.12791442871116, 15.962917327880973],
        [81.12735748291021, 15.962919235229549],
        [81.12735748291021, 15.962640762329158],
        [81.1270828247072, 15.962640762329158],
        [81.1270828247072, 15.962361335754451],
        [81.12680816650419, 15.962361335754451],
        [81.12680816650419, 15.962082862854118],
        [81.12652587890625, 15.962082862854118],
        [81.12652587890625, 15.961805343627987],
        [81.12625122070341, 15.961812019348258],
        [81.12625122070341, 15.961530685424805],
        [81.12596893310547, 15.961530685424805],
        [81.12596893310547, 15.961251258850098],
        [81.1256866455081, 15.961251258850098],
        [81.1256866455081, 15.960970878601131],
        [81.1251373291015, 15.960972785949764],
        [81.1251373291015, 15.960695266723633],
        [81.12486267089866, 15.960695266723633],
        [81.12486267089866, 15.960416793823242],
        [81.12431335449224, 15.960420608520621],
        [81.12431335449224, 15.960141181945744],
        [81.1240310668947, 15.960141181945744],
        [81.1240310668947, 15.959860801696777],
        [81.12374877929716, 15.959860801696777],
        [81.12374877929716, 15.959583282470703],
        [81.12347412109375, 15.959583282470703],
        [81.12347412109375, 15.959029197692814],
        [81.12319183349638, 15.959031105041618],
        [81.12316131591803, 15.958750724792424],
        [81.12291717529297, 15.958750724792424],
        [81.12291717529297, 15.958470344543457],
        [81.12236022949224, 15.95847320556652],
        [81.12236022949224, 15.958194732665959],
        [81.122085571289, 15.958194732665959],
        [81.122085571289, 15.957917213440055],
        [81.12152862548845, 15.957921028137264],
        [81.12152862548845, 15.95764064788824],
        [81.12124633789085, 15.95764064788824],
        [81.12124633789085, 15.95736122131359],
        [81.12069702148466, 15.95736122131359],
        [81.12069702148466, 15.957084655761776],
        [81.12041473388689, 15.957084655761776],
        [81.12041473388689, 15.956804275512809],
        [81.12014007568388, 15.95681095123291],
        [81.12014007568388, 15.956530570983887],
        [81.11985778808594, 15.956530570983887],
        [81.11985778808594, 15.956259727478084],
        [81.1195831298831, 15.956259727478084],
        [81.1195831298831, 15.955972671508846],
        [81.11930847167974, 15.955972671508846],
        [81.11930847167974, 15.955694198608455],
        [81.11875152587896, 15.955694198608455],
        [81.11875152587896, 15.955415725708065],
        [81.1184692382812, 15.95542144775402],
        [81.1184692382812, 15.955141067504883],
        [81.11818695068382, 15.955141067504883],
        [81.11818695068382, 15.9548597335816],
        [81.11791992187517, 15.9548597335816],
        [81.11791992187517, 15.95458030700695],
        [81.11708068847685, 15.954582214355526],
        [81.11708068847685, 15.954305648803711],
        [81.11680603027344, 15.954311370849666],
        [81.11680603027344, 15.9540309906007],
        [81.11651611328153, 15.9540309906007],
        [81.11651611328153, 15.953749656677246],
        [81.11596679687511, 15.953749656677246],
        [81.11596679687511, 15.953470230102596],
        [81.11569213867193, 15.953472137451172],
        [81.11569213867193, 15.953193664550781],
        [81.1154174804687, 15.953201293945312],
        [81.1154174804687, 15.952920913696346],
        [81.11514282226585, 15.952920913696346],
        [81.11514282226585, 15.952639579772892],
        [81.11486053466814, 15.952639579772892],
        [81.11486053466814, 15.952360153198242],
        [81.1145858764649, 15.952360153198242],
        [81.1145858764649, 15.952083587646598],
        [81.11430358886736, 15.952083587646598],
        [81.11430358886736, 15.951804161071777],
        [81.1137466430664, 15.951810836791992],
        [81.1137466430664, 15.951250076294059],
        [81.1134567260745, 15.951250076294059],
        [81.1134567260745, 15.950969696044922],
        [81.11319732666021, 15.950971603393668],
        [81.11319732666021, 15.950694084167594],
        [81.11264038085943, 15.950694084167594],
        [81.11264038085943, 15.950415611267204],
        [81.11235809326166, 15.950421333312988],
        [81.11235809326166, 15.950139999389705],
        [81.11207580566412, 15.950139999389705],
        [81.11207580566412, 15.949859619140739],
        [81.11152648925787, 15.949861526489315],
        [81.11152648925787, 15.94958400726324],
        [81.11125183105486, 15.94958400726324],
        [81.11125183105486, 15.94930553436285],
        [81.11096954345732, 15.949311256408805],
        [81.11096954345732, 15.949029922485352],
        [81.11042022705107, 15.949029922485352],
        [81.11042022705107, 15.948749542236385],
        [81.11013793945312, 15.948749542236385],
        [81.11013793945312, 15.948472023010254],
        [81.10986328125011, 15.948472023010254],
        [81.10986328125011, 15.947917938232422],
        [81.1095809936524, 15.947919845581168],
        [81.1095809936524, 15.94764041900629],
        [81.10930633544916, 15.94764041900629],
        [81.10930633544916, 15.9473619461059],
        [81.10874938964861, 15.9473619461059],
        [81.10874938964861, 15.94707012176525],
        [81.10847473144537, 15.947084426879826],
        [81.10847473144537, 15.946805953979435],
        [81.10791778564482, 15.946809768676815],
        [81.10791778564482, 15.946530342102108],
        [81.1076431274414, 15.946530342102108],
        [81.1076431274414, 15.946251869201774],
        [81.10736083984403, 15.946251869201774],
        [81.10736083984403, 15.945972442627067],
        [81.10708618164062, 15.945972442627067],
        [81.10708618164062, 15.945695877075252],
        [81.10679626464872, 15.945689201355037],
        [81.10680389404325, 15.945416450500602],
        [81.1065292358399, 15.945420265197754],
        [81.1065292358399, 15.94514179229742],
        [81.1062469482423, 15.94514179229742],
        [81.1062469482423, 15.944858551025334],
        [81.10597229003912, 15.944862365722713],
        [81.10597229003912, 15.944583892822322],
        [81.10569763183611, 15.944583892822322],
        [81.10569763183611, 15.944306373596248],
        [81.10542297363287, 15.94432163238531],
        [81.10542297363287, 15.944029808044434],
        [81.10514068603533, 15.944029808044434],
        [81.10514068603533, 15.943750381469783],
        [81.10485839843756, 15.943750381469783],
        [81.10485839843756, 15.943470954895076],
        [81.10458374023455, 15.943470954895076],
        [81.10458374023455, 15.942917823791504],
        [81.10430908203153, 15.94291973114008],
        [81.10430908203153, 15.94264030456543],
        [81.1040267944336, 15.94264030456543],
        [81.1040267944336, 15.942361831665039],
        [81.10375213623075, 15.942361831665039],
        [81.10375213623075, 15.942081451416072],
        [81.10346984863287, 15.942083358764648],
        [81.10346984863287, 15.941804885864258],
        [81.10320281982416, 15.941809654235897],
        [81.10320281982416, 15.941530227661246],
        [81.10263824462885, 15.941530227661246],
        [81.10263824462885, 15.941261291503963],
        [81.10235595703148, 15.941261291503963],
        [81.10235595703148, 15.940971374511719],
        [81.1020812988283, 15.940971374511719],
        [81.1020812988283, 15.940694808959904],
        [81.10152435302751, 15.940694808959904],
        [81.10152435302751, 15.940417289734],
        [81.1012496948245, 15.940420150756893],
        [81.1012496948245, 15.939861297607536],
        [81.1009750366211, 15.939861297607536],
        [81.1009750366211, 15.939582824707145],
        [81.10069274902372, 15.939582824707145],
        [81.10069274902372, 15.93930530548107],
        [81.10041809082037, 15.939311981201115],
        [81.10041809082037, 15.939031600952148],
        [81.10013580322277, 15.939031600952148],
        [81.10013580322277, 15.938751220703182],
        [81.09986114501959, 15.938751220703182],
        [81.09986114501959, 15.938470840454215],
        [81.0990295410158, 15.938470840454215],
        [81.0990295410158, 15.938199996948356],
        [81.09874725341803, 15.938199996948356],
        [81.09874725341803, 15.937918663024902],
        [81.09848022460955, 15.937918663024902],
        [81.09848022460955, 15.937639236450252],
        [81.098197937012, 15.937639236450252],
        [81.098197937012, 15.937360763549862],
        [81.09764099121122, 15.937360763549862],
        [81.09764099121122, 15.937081336975154],
        [81.09735870361328, 15.937083244323787],
        [81.09735870361328, 15.936804771423397],
        [81.09707641601591, 15.936809539795036],
        [81.09707641601591, 15.936531066894645],
        [81.09680175781256, 15.936531066894645],
        [81.09680175781256, 15.936250686645508],
        [81.09652709960932, 15.936250686645508],
        [81.09652709960932, 15.9359712600708],
        [81.09597015380876, 15.935973167419434],
        [81.09597015380876, 15.935694694519043],
        [81.09569549560553, 15.935694694519043],
        [81.09569549560553, 15.935417175292912],
        [81.09542083740251, 15.935421943664608],
        [81.09542083740251, 15.935132026672477],
        [81.09513854980497, 15.935138702392578],
        [81.09513854980497, 15.934861183166447],
        [81.09486389160156, 15.934861183166447],
        [81.09486389160156, 15.934582710266056],
        [81.09458160400419, 15.934582710266056],
        [81.09458160400419, 15.934305191039982],
        [81.09401702880888, 15.934311866760254],
        [81.09401702880888, 15.934030532836971],
        [81.09348297119135, 15.934030532836971],
        [81.09348297119135, 15.933751106262264],
        [81.09319305419928, 15.933751106262264],
        [81.09319305419928, 15.933470726013127],
        [81.09291839599626, 15.93347263336193],
        [81.09291839599626, 15.933195114135799],
        [81.09263610839866, 15.933195114135799],
        [81.09263610839866, 15.932916641235408],
        [81.09234619140642, 15.932921409606934],
        [81.09234619140642, 15.93264102935791],
        [81.09179687500017, 15.93264102935791],
        [81.09179687500017, 15.932360649108944],
        [81.09124755859375, 15.932360649108944],
        [81.09124755859375, 15.9320707321167],
        [81.09097290039091, 15.93208312988287],
        [81.09097290039091, 15.931804656982479],
        [81.09069824218756, 15.93181133270275],
        [81.09069061279303, 15.931530952453556],
        [81.09014129638678, 15.931530952453556],
        [81.09014129638678, 15.93125057220459],
        [81.08957672119163, 15.93125057220459],
        [81.08957672119163, 15.930970191955623],
        [81.08930206298845, 15.930973052978516],
        [81.08930206298845, 15.930694580078125],
        [81.08902740478521, 15.930694580078125],
        [81.08902740478521, 15.93041706085205],
        [81.08847045898466, 15.93042087554943],
        [81.08847045898466, 15.930141448974723],
        [81.08819580078125, 15.930141448974723],
        [81.08819580078125, 15.92986011505127],
        [81.08790588378935, 15.92986011505127],
        [81.08790588378935, 15.929579734802303],
        [81.08763885498053, 15.929585456848258],
        [81.08763885498053, 15.929029464721793],
        [81.08735656738293, 15.92903137207037],
        [81.08735656738293, 15.928750991821403],
        [81.08708190917974, 15.928750991821403],
        [81.08708190917974, 15.928190231323299],
        [81.0868072509765, 15.928194046020621],
        [81.0868072509765, 15.92791557312006],
        [81.08651733398443, 15.927921295166016],
        [81.08651733398443, 15.927359580993766],
        [81.08625030517595, 15.927363395690975],
        [81.08625030517595, 15.926806449890194],
        [81.08598327636747, 15.926811218261832],
        [81.08598327636747, 15.926261901855412],
        [81.08569335937517, 15.926261901855412],
        [81.08569335937517, 15.925970077514762],
        [81.08541870117216, 15.925971984863338],
        [81.08541870117216, 15.925694465637264],
        [81.08513641357422, 15.925694465637264],
        [81.08513641357422, 15.925415992736873],
        [81.08486175537138, 15.925420761108512],
        [81.08486175537138, 15.925140380859375],
        [81.08458709716803, 15.925140380859375],
        [81.08458709716803, 15.924860000610408],
        [81.08429718017607, 15.924860000610408],
        [81.08429718017607, 15.924579620361442],
        [81.08403015136724, 15.92458438873291],
        [81.08403015136724, 15.92430591583252],
        [81.08374786376947, 15.924310684204158],
        [81.08374786376947, 15.923749923706055],
        [81.08348083496116, 15.923749923706055],
        [81.08348083496116, 15.923469543457088],
        [81.08319091796892, 15.923472404479924],
        [81.08319091796892, 15.92319393157959],
        [81.08264160156267, 15.92319393157959],
        [81.08264160156267, 15.922916412353459],
        [81.08235931396513, 15.922920227050838],
        [81.08235931396513, 15.922639846801871],
        [81.08207702636736, 15.922639846801871],
        [81.08207702636736, 15.922360420226994],
        [81.08152770996094, 15.922362327575797],
        [81.08152770996094, 15.922081947326603],
        [81.08097076416021, 15.922081947326603],
        [81.08097076416021, 15.921806335449276],
        [81.08069610595697, 15.9218111038208],
        [81.08069610595697, 15.921529769897518],
        [81.08013916015642, 15.921529769897518],
        [81.08013916015642, 15.92125034332281],
        [81.07958221435564, 15.92125034332281],
        [81.07958221435564, 15.92097187042242],
        [81.07930755615263, 15.92097187042242],
        [81.07930755615263, 15.920694351196346],
        [81.07902526855486, 15.920694351196346],
        [81.07902526855486, 15.920415878295955],
        [81.07875061035185, 15.920419692993164],
        [81.07875061035185, 15.92013072967535],
        [81.07847595214844, 15.920140266418457],
        [81.07847595214844, 15.919861793518066],
        [81.07818603515653, 15.919861793518066],
        [81.07818603515653, 15.919582366943416],
        [81.07791900634771, 15.919584274291992],
        [81.07791900634771, 15.919028282165527],
        [81.07763671875011, 15.919030189514103],
        [81.07763671875011, 15.918749809265137],
        [81.07734680175787, 15.918749809265137],
        [81.07734680175787, 15.91846942901617],
        [81.07707977294916, 15.918472290039062],
        [81.07707977294916, 15.918195724487248],
        [81.07680511474632, 15.918195724487248],
        [81.07680511474632, 15.917639732360783],
        [81.07653045654314, 15.917639732360783],
        [81.07653045654314, 15.917359352111816],
        [81.07624816894537, 15.91736221313488],
        [81.07624816894537, 15.917083740234489],
        [81.07596588134783, 15.91709041595459],
        [81.07596588134783, 15.916810035705566],
        [81.07569122314482, 15.916810035705566],
        [81.07569122314482, 15.916490554809627],
        [81.07569122314482, 15.916249275207633],
        [81.0754165649414, 15.916249275207633],
        [81.0754165649414, 15.915970802307072],
        [81.07514190673857, 15.915970802307072],
        [81.07514190673857, 15.915691375732422],
        [81.07485961914068, 15.915693283081168],
        [81.07485961914068, 15.91541767120367],
        [81.07457733154308, 15.915419578552246],
        [81.07457733154308, 15.914860725402889],
        [81.0743026733399, 15.914860725402889],
        [81.0743026733399, 15.914583206176815],
        [81.07402801513666, 15.914583206176815],
        [81.07402801513666, 15.914304733276424],
        [81.07375335693382, 15.91431045532238],
        [81.07375335693382, 15.914031982421989],
        [81.07347106933611, 15.914031982421989],
        [81.07347106933611, 15.913751602172852],
        [81.07319641113287, 15.913751602172852],
        [81.07319641113287, 15.913471221923885],
        [81.07291412353533, 15.913471221923885],
        [81.07291412353533, 15.91319465637207],
        [81.07263946533232, 15.91319465637207],
        [81.07263946533232, 15.912917137145996],
        [81.07235717773438, 15.912921905517635],
        [81.07235717773438, 15.912641525268668],
        [81.07180786132818, 15.912641525268668],
        [81.07180786132818, 15.912361145019531],
        [81.0712509155274, 15.912361145019531],
        [81.0712509155274, 15.91208267211914],
        [81.07069396972679, 15.91208267211914],
        [81.07069396972679, 15.911526679992676],
        [81.07041931152361, 15.911528587341422],
        [81.07041931152361, 15.911251068115348],
        [81.07013702392584, 15.911251068115348],
        [81.07013702392584, 15.910960197448787],
        [81.06986236572283, 15.910972595214957],
        [81.06986236572283, 15.910695075988883],
        [81.06930541992188, 15.910695075988883],
        [81.06930541992188, 15.910139083862418],
        [81.06903076171903, 15.910140991210994],
        [81.06903076171903, 15.909860610962028],
        [81.0687484741211, 15.909860610962028],
        [81.0687484741211, 15.909583091735954],
        [81.06847381591808, 15.909583091735954],
        [81.06847381591808, 15.909029006958065],
        [81.06817626953125, 15.90903091430664],
        [81.06817626953125, 15.908750534057674],
        [81.06791687011713, 15.908750534057674],
        [81.06791687011713, 15.908471107482967],
        [81.06764221191429, 15.9084730148316],
        [81.06764221191429, 15.907917022705078],
        [81.06735992431658, 15.907920837402457],
        [81.06735992431658, 15.90764141082758],
        [81.06709289550787, 15.90764141082758],
        [81.06709289550787, 15.907361030578613],
        [81.0668029785158, 15.907361030578613],
        [81.0668029785158, 15.90681171417242],
        [81.06652832031278, 15.90681171417242],
        [81.06652832031278, 15.906531333923454],
        [81.06624603271501, 15.906531333923454],
        [81.06624603271501, 15.905970573425293],
        [81.065971374512, 15.905973434448356],
        [81.065971374512, 15.905694961547795],
        [81.0656967163086, 15.905694961547795],
        [81.0656967163086, 15.9051389694215],
        [81.06540679931669, 15.90515041351324],
        [81.06540679931669, 15.904861450195426],
        [81.06513977050787, 15.904861450195426],
        [81.06513977050787, 15.904582977295036],
        [81.06485748291027, 15.904582977295036],
        [81.06485748291027, 15.904305458068961],
        [81.06458282470709, 15.904311180114746],
        [81.06458282470709, 15.904030799865723],
        [81.06430816650408, 15.904030799865723],
        [81.06430816650408, 15.90347003936779],
        [81.06402587890648, 15.903472900390682],
        [81.06402587890648, 15.903195381164608],
        [81.0637512207033, 15.903195381164608],
        [81.0637512207033, 15.902916908264217],
        [81.06318664550798, 15.902920722961369],
        [81.06318664550798, 15.902641296386719],
        [81.0629196166995, 15.902641296386719],
        [81.0629196166995, 15.902359962463436],
        [81.06263732910156, 15.902359962463436],
        [81.06263732910156, 15.90209102630621],
        [81.06237030029308, 15.90209102630621],
        [81.06237030029308, 15.901810646057243],
        [81.06208038330084, 15.901810646057243],
        [81.06208038330084, 15.901531219482536],
        [81.06181335449213, 15.901531219482536],
        [81.06181335449213, 15.901252746582145],
        [81.06153106689459, 15.901252746582145],
        [81.06153106689459, 15.900970458984432],
        [81.06124877929682, 15.900972366333008],
        [81.06124877929682, 15.900693893432617],
        [81.06097412109398, 15.900693893432617],
        [81.06097412109398, 15.900416374206543],
        [81.06069183349626, 15.900421142578182],
        [81.06069183349626, 15.899860382080078],
        [81.06041717529303, 15.899862289428825],
        [81.06041717529303, 15.899583816528434],
        [81.06014251709001, 15.899581909179688],
        [81.06014251709001, 15.89930629730236],
        [81.05960083007818, 15.899311065673828],
        [81.05960083007818, 15.899019241332951],
        [81.05930328369169, 15.89902782440197],
        [81.05930328369169, 15.89819431304943],
        [81.05902862548834, 15.89819431304943],
        [81.05902862548834, 15.89791584014904],
        [81.05874633789074, 15.897920608520508],
        [81.05874633789074, 15.897640228271541],
        [81.05847167968756, 15.897640228271541],
        [81.05847167968756, 15.897359848022575],
        [81.05819702148432, 15.897359848022575],
        [81.05819702148432, 15.897084236145076],
        [81.05791473388695, 15.897084236145076],
        [81.05791473388695, 15.896528244018612],
        [81.05764007568376, 15.896530151367188],
        [81.05764007568376, 15.895960807800407],
        [81.057357788086, 15.89597225189209],
        [81.057357788086, 15.895693778991756],
        [81.05708312988298, 15.895693778991756],
        [81.05708312988298, 15.895416259765625],
        [81.05680847167997, 15.895420074463004],
        [81.05680847167997, 15.895139694213867],
        [81.05652618408203, 15.895139694213867],
        [81.05652618408203, 15.89486026763916],
        [81.05625152587919, 15.894862174987736],
        [81.05625152587919, 15.894583702087402],
        [81.05596923828125, 15.894583702087402],
        [81.05596923828125, 15.894306182861271],
        [81.05568695068388, 15.894310951232967],
        [81.05568695068388, 15.893750190734806],
        [81.05541992187506, 15.893750190734806],
        [81.05541992187506, 15.893471717834416],
        [81.05513763427729, 15.893471717834416],
        [81.05513763427729, 15.893194198608512],
        [81.05486297607445, 15.893194198608512],
        [81.05486297607445, 15.892640113830623],
        [81.05458068847673, 15.892642021179256],
        [81.05458068847673, 15.89207935333252],
        [81.0543060302735, 15.892084121704158],
        [81.0543060302735, 15.891528129577694],
        [81.05403137207048, 15.89153003692627],
        [81.05403137207048, 15.891249656677303],
        [81.05374908447294, 15.891249656677303],
        [81.05374908447294, 15.890969276428336],
        [81.05347442626953, 15.890972137451229],
        [81.05347442626953, 15.890693664550838],
        [81.05319213867216, 15.890693664550838],
        [81.05319213867216, 15.89041805267334],
        [81.05291748046875, 15.890419960021916],
        [81.05291748046875, 15.89015102386486],
        [81.05264282226574, 15.89015102386486],
        [81.05264282226574, 15.889859199523983],
        [81.05236053466803, 15.889862060546875],
        [81.05236053466803, 15.889583587646484],
        [81.05208587646479, 15.889583587646484],
        [81.05208587646479, 15.88930606842041],
        [81.05179595947271, 15.889311790466365],
        [81.05179595947271, 15.888751983642692],
        [81.05152893066423, 15.888751983642692],
        [81.05152893066423, 15.888470649719238],
        [81.05097198486345, 15.888474464416618],
        [81.05097198486345, 15.888193130493164],
        [81.05069732666044, 15.888193130493164],
        [81.05069732666044, 15.887360572814885],
        [81.05041503906267, 15.887360572814885],
        [81.05041503906267, 15.88708305358898],
        [81.05014038085966, 15.88708305358898],
        [81.05014038085966, 15.886527061462516],
        [81.04985809326172, 15.886531829833984],
        [81.04985809326172, 15.885971069336051],
        [81.04958343505888, 15.885971069336051],
        [81.04958343505888, 15.885695457458553],
        [81.04930877685553, 15.885695457458553],
        [81.04930877685553, 15.885139465332088],
        [81.04902648925793, 15.88514232635498],
        [81.04902648925793, 15.88458061218273],
        [81.04875183105474, 15.884583473205623],
        [81.04875183105474, 15.884305000305233],
        [81.04846954345697, 15.884309768676871],
        [81.04846954345697, 15.88401985168457],
        [81.04819488525413, 15.884026527404842],
        [81.04819488525413, 15.883749008178768],
        [81.04763793945318, 15.883750915527344],
        [81.04763793945318, 15.883470535278377],
        [81.04736328125017, 15.88347339630127],
        [81.04736328125017, 15.883194923400879],
        [81.04708099365263, 15.88319110870367],
        [81.04708099365263, 15.882917404174748],
        [81.04680633544922, 15.882919311523551],
        [81.04680633544922, 15.88264083862316],
        [81.04651641845732, 15.88264083862316],
        [81.04651641845732, 15.882361412048283],
        [81.0462493896485, 15.882361412048283],
        [81.0462493896485, 15.882082939147892],
        [81.04597473144543, 15.882082939147892],
        [81.04597473144543, 15.881805419921818],
        [81.04541778564447, 15.881809234619197],
        [81.04541778564447, 15.881530761718807],
        [81.04486083984392, 15.881530761718807],
        [81.04486083984392, 15.8812513351441],
        [81.04458618164068, 15.8812513351441],
        [81.04458618164068, 15.88097953796398],
        [81.04429626464861, 15.88097953796398],
        [81.04429626464861, 15.88068962097168],
        [81.04402923584013, 15.880695343017635],
        [81.04402923584013, 15.880416870117244],
        [81.04374694824219, 15.880420684814453],
        [81.04374694824219, 15.880141258239746],
        [81.04347229003935, 15.880141258239746],
        [81.04347229003935, 15.87986087799078],
        [81.043197631836, 15.87986087799078],
        [81.043197631836, 15.879579544067326],
        [81.0429153442384, 15.879583358764705],
        [81.0429153442384, 15.87930679321289],
        [81.04264068603521, 15.879311561584586],
        [81.04264068603521, 15.879031181335392],
        [81.04235839843744, 15.879031181335392],
        [81.04235839843744, 15.878750801086426],
        [81.0420761108399, 15.878750801086426],
        [81.0420761108399, 15.878471374511776],
        [81.04152679443365, 15.878473281860352],
        [81.04152679443365, 15.877917289733887],
        [81.04123687744158, 15.877921104431266],
        [81.04123687744158, 15.877640724182072],
        [81.04070281982422, 15.877640724182072],
        [81.04070281982422, 15.877361297607422],
        [81.04041290283232, 15.877361297607422],
        [81.04041290283232, 15.877084732055607],
        [81.0398635864259, 15.877084732055607],
        [81.0398635864259, 15.876528739929313],
        [81.03958129882818, 15.876530647277889],
        [81.03958129882818, 15.876251220703239],
        [81.03930664062494, 15.876251220703239],
        [81.03930664062494, 15.875972747802848],
        [81.03874969482439, 15.875972747802848],
        [81.03874969482439, 15.875690460205135],
        [81.03847503662115, 15.875695228576774],
        [81.03847503662115, 15.875138282775993],
        [81.03820037841814, 15.875141143798885],
        [81.03820037841814, 15.874851226806584],
        [81.0379180908206, 15.874859809875602],
        [81.0379180908206, 15.87430667877203],
        [81.03763580322283, 15.874311447143668],
        [81.03763580322283, 15.874031066894531],
        [81.03736114501982, 15.874031066894531],
        [81.03736114501982, 15.873749732971248],
        [81.0370864868164, 15.873749732971248],
        [81.0370864868164, 15.873470306396598],
        [81.03680419921903, 15.873472213745174],
        [81.03680419921903, 15.873193740844783],
        [81.03624725341808, 15.873193740844783],
        [81.03624725341808, 15.87291622161871],
        [81.0359725952149, 15.872920989990348],
        [81.0359725952149, 15.872360229492244],
        [81.03569793701189, 15.872360229492244],
        [81.03569793701189, 15.87208366394043],
        [81.03541564941429, 15.87208366394043],
        [81.03541564941429, 15.871806144714355],
        [81.03485870361334, 15.871810913085994],
        [81.03485870361334, 15.871530532837028],
        [81.0345764160158, 15.871530532837028],
        [81.0345764160158, 15.87125015258789],
        [81.03430175781278, 15.87125015258789],
        [81.03430175781278, 15.8709716796875],
        [81.03375244140653, 15.8709716796875],
        [81.03375244140653, 15.870694160461426],
        [81.03319549560558, 15.870694160461426],
        [81.03319549560558, 15.870415687561035],
        [81.03291320800787, 15.87042140960699],
        [81.03291320800787, 15.870140075683707],
        [81.03263854980463, 15.870140075683707],
        [81.03263854980463, 15.86985969543457],
        [81.03236389160179, 15.86985969543457],
        [81.03235626220726, 15.869580268859863],
        [81.03208160400408, 15.869584083557243],
        [81.03208160400408, 15.869028091430778],
        [81.03180694580084, 15.869038581848201],
        [81.03180694580084, 15.868749618530387],
        [81.03151702880876, 15.868749618530387],
        [81.03151702880876, 15.86847019195568],
        [81.03125000000028, 15.868472099304256],
        [81.03125000000028, 15.868193626403922],
        [81.03097534179688, 15.868193626403922],
        [81.03097534179688, 15.867918014526424],
        [81.0306930541995, 15.867919921875],
        [81.0306930541995, 15.867639541626033],
        [81.03041839599615, 15.867639541626033],
        [81.03041839599615, 15.867081642150936],
        [81.03013610839855, 15.867083549499569],
        [81.03013610839855, 15.866806030273438],
        [81.02986145019537, 15.866809844970817],
        [81.02986145019537, 15.86653041839594],
        [81.02958679199213, 15.86653041839594],
        [81.02958679199213, 15.865981101989746],
        [81.02929687500006, 15.865981101989746],
        [81.02929687500006, 15.86568927764904],
        [81.02902984619158, 15.865694046020508],
        [81.02902984619158, 15.865415573120117],
        [81.0287475585938, 15.865420341491756],
        [81.0287475585938, 15.865139961242619],
        [81.0284729003908, 15.865139961242619],
        [81.0284729003908, 15.864859580993652],
        [81.02819824218778, 15.864862442016715],
        [81.02819061279325, 15.86430644989025],
        [81.02791595458984, 15.864310264587402],
        [81.02791595458984, 15.864031791687069],
        [81.027641296387, 15.864031791687069],
        [81.027641296387, 15.863750457763786],
        [81.02735900878906, 15.863750457763786],
        [81.02735900878906, 15.863471984863395],
        [81.02708435058605, 15.863471984863395],
        [81.02708435058605, 15.863192558288517],
        [81.0265274047851, 15.863192558288517],
        [81.0265274047851, 15.862917900085506],
        [81.02597045898455, 15.862917900085506],
        [81.02597045898455, 15.862636566162053],
        [81.0256958007813, 15.862640380859432],
        [81.0256958007813, 15.862361907959041],
        [81.02540588378923, 15.862361907959041],
        [81.02540588378923, 15.862080574035758],
        [81.02513885498075, 15.862084388732967],
        [81.02513885498075, 15.861248970031795],
        [81.02485656738298, 15.861251831054688],
        [81.02485656738298, 15.860691070556754],
        [81.02458190917997, 15.860695838928223],
        [81.02458190917997, 15.860417366027832],
        [81.02401733398466, 15.860420227050895],
        [81.02401733398466, 15.860141754150504],
        [81.02375030517584, 15.860141754150504],
        [81.02375030517584, 15.859849929809627],
        [81.02319335937506, 15.859861373901367],
        [81.02319335937506, 15.859582901000977],
        [81.02291870117205, 15.859582901000977],
        [81.02291870117205, 15.859305381774902],
        [81.02263641357445, 15.859310150146541],
        [81.02263641357445, 15.85903167724615],
        [81.02236175537126, 15.85903167724615],
        [81.02236175537126, 15.858751296997184],
        [81.02208709716825, 15.858751296997184],
        [81.02208709716825, 15.85819053649908],
        [81.02180480957048, 15.85819530487072],
        [81.02180480957048, 15.857916831970329],
        [81.02153015136747, 15.857919692993221],
        [81.02153015136747, 15.857639312744254],
        [81.02124786376953, 15.857639312744254],
        [81.02124786376953, 15.857360839843864],
        [81.02097320556669, 15.857360839843864],
        [81.02097320556669, 15.857081413268986],
        [81.0206909179688, 15.85708332061779],
        [81.0206909179688, 15.856804847717399],
        [81.02041625976574, 15.856809616088867],
        [81.02041625976574, 15.8565292358399],
        [81.02014160156256, 15.8565292358399],
        [81.02014160156256, 15.85625076293951],
        [81.01985931396479, 15.85625076293951],
        [81.01985931396479, 15.855971336364803],
        [81.01958465576195, 15.855973243713436],
        [81.01958465576195, 15.855694770813045],
        [81.01930236816423, 15.855694770813045],
        [81.01930236816423, 15.85486125946045],
        [81.019027709961, 15.85486125946045],
        [81.019027709961, 15.854582786560059],
        [81.01875305175798, 15.854582786560059],
        [81.01875305175798, 15.854305267333984],
        [81.01847076416044, 15.854311943054256],
        [81.01847076416044, 15.853751182556096],
        [81.01819610595703, 15.853751182556096],
        [81.01819610595703, 15.853470802307129],
        [81.01791381835966, 15.853470802307129],
        [81.01791381835966, 15.85319519042963],
        [81.0176391601563, 15.85319519042963],
        [81.0176391601563, 15.852360725402775],
        [81.01735687255871, 15.852360725402775],
        [81.01735687255871, 15.851531028747559],
        [81.01706695556646, 15.851531028747559],
        [81.01706695556646, 15.850970268249625],
        [81.01680755615229, 15.850973129272518],
        [81.01680755615229, 15.850694656372127],
        [81.01652526855491, 15.850694656372127],
        [81.01652526855491, 15.850138664245662],
        [81.01625061035173, 15.850140571594238],
        [81.01625061035173, 15.849579811096305],
        [81.0159759521485, 15.849584579467773],
        [81.0159759521485, 15.849028587341309],
        [81.01568603515642, 15.849031448364371],
        [81.01568603515642, 15.848751068115234],
        [81.01541900634794, 15.848751068115234],
        [81.01541900634794, 15.848469734191951],
        [81.01513671875, 15.848472595214844],
        [81.01513671875, 15.847638130187988],
        [81.01486206054716, 15.847640991211051],
        [81.01486206054716, 15.847359657287598],
        [81.01457977294922, 15.847363471984977],
        [81.01457977294922, 15.847082138061523],
        [81.01430511474621, 15.847082138061523],
        [81.01430511474621, 15.846805572509709],
        [81.01403045654303, 15.846811294555664],
        [81.01403045654303, 15.846249580383414],
        [81.01374816894526, 15.846249580383414],
        [81.01374816894526, 15.845970153808594],
        [81.01347351074241, 15.84597206115734],
        [81.01347351074241, 15.84569358825695],
        [81.0131912231447, 15.84569358825695],
        [81.0131912231447, 15.845137596130485],
        [81.01291656494146, 15.845140457153377],
        [81.01291656494146, 15.844850540161076],
        [81.01264190673845, 15.84486007690441],
        [81.01264190673845, 15.844028472900447],
        [81.01235961914091, 15.844030380249023],
        [81.01235961914091, 15.842639923095703],
        [81.01207733154314, 15.842639923095703],
        [81.01207733154314, 15.842082023620605],
        [81.01180267334013, 15.842083930969238],
        [81.01180267334013, 15.841527938842717],
        [81.01152801513672, 15.84152984619152],
        [81.01152801513672, 15.841250419616642],
        [81.01125335693371, 15.841250419616642],
        [81.01125335693371, 15.840971946716252],
        [81.010971069336, 15.840971946716252],
        [81.010971069336, 15.840415954589787],
        [81.01069641113276, 15.840419769287166],
        [81.01069641113276, 15.839861869812069],
        [81.01040649414068, 15.839861869812069],
        [81.01040649414068, 15.839309692382812],
        [81.0101394653322, 15.839309692382812],
        [81.0101394653322, 15.839030265808105],
        [81.0098571777346, 15.839030265808105],
        [81.0098571777346, 15.838191032409782],
        [81.00958251953142, 15.83819580078125],
        [81.00958251953142, 15.8379163742066],
        [81.00930786132841, 15.837920188903752],
        [81.00930786132841, 15.837359428405819],
        [81.00902557373064, 15.837362289428711],
        [81.00902557373064, 15.83708381652832],
        [81.00846862792969, 15.83708381652832],
        [81.00846862792969, 15.837362289428711],
        [81.00819396972685, 15.837362289428711],
        [81.00819396972685, 15.838472366333065],
        [81.00846862792969, 15.838468551635685],
        [81.00846862792969, 15.839030265808105],
        [81.00818634033232, 15.839030265808105],
        [81.00818634033232, 15.839309692382812],
        [81.0079193115235, 15.839305877685604],
        [81.0079193115235, 15.839584350585994],
        [81.0076370239259, 15.839584350585994],
        [81.0076370239259, 15.839305877685604],
        [81.00736236572271, 15.839309692382812],
        [81.00736236572271, 15.839030265808105],
        [81.00708007812494, 15.839030265808105],
        [81.00708007812494, 15.838751792907715],
        [81.00681304931646, 15.838751792907715],
        [81.00681304931646, 15.837639808654785],
        [81.00708007812494, 15.837639808654785],
        [81.00708007812494, 15.835419654846248],
        [81.00736236572271, 15.835419654846248],
        [81.00736236572271, 15.83458137512207],
        [81.0076370239259, 15.834583282470817],
        [81.0076370239259, 15.833748817443961],
        [81.00736236572271, 15.833751678466854],
        [81.00736236572271, 15.832361221313533],
        [81.00708007812494, 15.832361221313533],
        [81.00708007812494, 15.832082748413143],
        [81.00736236572271, 15.832082748413143],
        [81.00736236572271, 15.831805229187069],
        [81.00681304931646, 15.831811904907283],
        [81.00681304931646, 15.831531524658317],
        [81.00653076171892, 15.831526756286678],
        [81.00653076171892, 15.833471298217887],
        [81.00624847412115, 15.833471298217887],
        [81.00624847412115, 15.834030151367244],
        [81.00597381591814, 15.834027290344352],
        [81.00597381591814, 15.835693359375],
        [81.0056915283206, 15.83568191528326],
        [81.0056915283206, 15.836250305175781],
        [81.00541687011719, 15.836250305175781],
        [81.00541687011719, 15.837362289428711],
        [81.00512695312528, 15.837359428405819],
        [81.00512695312528, 15.839030265808105],
        [81.00541687011719, 15.839030265808105],
        [81.00541687011719, 15.839309692382812],
        [81.0056915283206, 15.839309692382812],
        [81.0056915283206, 15.840419769287166],
        [81.00541687011719, 15.840415954589787],
        [81.00541687011719, 15.840694427490348],
        [81.0045852661134, 15.840694427490348],
        [81.0045852661134, 15.840415954589787],
        [81.00430297851568, 15.840419769287166],
        [81.00430297851568, 15.839030265808105],
        [81.00459289550776, 15.839030265808105],
        [81.00459289550776, 15.838751792907715],
        [81.00430297851568, 15.838751792907715],
        [81.00430297851568, 15.837920188903752],
        [81.00402832031244, 15.837920188903752],
        [81.00402832031244, 15.837639808654785],
        [81.00374603271507, 15.837639808654785],
        [81.00374603271507, 15.836810111999569],
        [81.00402832031244, 15.836810111999569],
        [81.00402832031244, 15.835970878601074],
        [81.00430297851568, 15.835970878601074],
        [81.00430297851568, 15.834304809570426],
        [81.00235748291016, 15.834309577941895],
        [81.00235748291016, 15.833471298217887],
        [81.0026397705081, 15.833471298217887],
        [81.0026397705081, 15.831526756286678],
        [81.00235748291016, 15.831531524658317],
        [81.00235748291016, 15.831249237060604],
        [81.00180816650396, 15.831249237060604],
        [81.00180816650396, 15.831531524658317],
        [81.00152587890636, 15.831526756286678],
        [81.00152587890636, 15.832082748413143],
        [81.00125122070318, 15.832082748413143],
        [81.00125122070318, 15.832361221313533],
        [81.00013732910162, 15.832361221313533],
        [81.00013732910162, 15.832082748413143],
        [80.99986267089861, 15.832082748413143],
        [80.99986267089861, 15.831805229187069],
        [80.99958038330107, 15.831811904907283],
        [80.99958038330107, 15.831249237060604],
        [80.99930572509766, 15.831249237060604],
        [80.99930572509766, 15.830695152282715],
        [80.99903106689482, 15.830695152282715],
        [80.99903106689482, 15.830416679382324],
        [80.99874877929688, 15.83041858673107],
        [80.99874877929688, 15.830141067504996],
        [80.9984664916995, 15.830141067504996],
        [80.9984664916995, 15.829870223999137],
        [80.99819183349615, 15.829870223999137],
        [80.99819183349615, 15.829581260681152],
        [80.99791717529291, 15.829583168029728],
        [80.99791717529291, 15.827361106872615],
        [80.99764251709007, 15.827361106872615],
        [80.99764251709007, 15.82680511474615],
        [80.99736022949236, 15.826811790466422],
        [80.99736022949236, 15.826251029968262],
        [80.99709320068365, 15.826251029968262],
        [80.99709320068365, 15.824580192565975],
        [80.99680328369158, 15.824583053588867],
        [80.99680328369158, 15.824028968811149],
        [80.99652862548857, 15.824030876159725],
        [80.99652862548857, 15.823470115661621],
        [80.9962463378908, 15.823472976684684],
        [80.9962463378908, 15.82291698455822],
        [80.99597167968778, 15.822920799255371],
        [80.99597167968778, 15.821531295776367],
        [80.99569702148438, 15.821531295776367],
        [80.99569702148438, 15.820969581604118],
        [80.995414733887, 15.82097244262701],
        [80.995414733887, 15.82069396972662],
        [80.99485778808605, 15.82069396972662],
        [80.99485778808605, 15.82097244262701],
        [80.99430847167986, 15.820969581604118],
        [80.99430847167986, 15.8212509155274],
        [80.99318695068376, 15.8212509155274],
        [80.99318695068376, 15.820700645446891],
        [80.99291992187528, 15.820680618286133],
        [80.99291992187528, 15.818471908569336],
        [80.9931945800783, 15.818471908569336],
        [80.9931945800783, 15.817359924316406],
        [80.9934692382813, 15.817359924316406],
        [80.9934692382813, 15.817079544067383],
        [80.99375152587908, 15.817084312439079],
        [80.99375152587908, 15.816805839538688],
        [80.99402618408226, 15.816810607910156],
        [80.99402618408226, 15.815691947936955],
        [80.99430847167986, 15.815693855285758],
        [80.99430847167986, 15.815137863159237],
        [80.99458312988287, 15.815141677856445],
        [80.99458312988287, 15.813750267028809],
        [80.99430847167986, 15.813750267028809],
        [80.99430847167986, 15.813471794128418],
        [80.99402618408226, 15.813471794128418],
        [80.99402618408226, 15.812919616699332],
        [80.99375152587908, 15.812919616699332],
        [80.99375152587908, 15.810139656066951],
        [80.99402618408226, 15.810139656066951],
        [80.99402618408226, 15.809859275817985],
        [80.99430847167986, 15.809862136840877],
        [80.99430847167986, 15.809583663940487],
        [80.99458312988287, 15.809583663940487],
        [80.99458312988287, 15.809306144714412],
        [80.99485778808605, 15.809309959411564],
        [80.99485778808605, 15.80792045593273],
        [80.99458312988287, 15.80792045593273],
        [80.99458312988287, 15.806810379028377],
        [80.99485778808605, 15.806810379028377],
        [80.99485778808605, 15.80347061157238],
        [80.99514007568365, 15.803473472595272],
        [80.99514007568365, 15.802639007568416],
        [80.99540710449247, 15.802640914916992],
        [80.99540710449247, 15.802081108093319],
        [80.99569702148438, 15.802083015441895],
        [80.99569702148438, 15.80152702331543],
        [80.99597167968778, 15.801530838012809],
        [80.99597167968778, 15.800971031188965],
        [80.9962463378908, 15.80097293853754],
        [80.9962463378908, 15.800139427185172],
        [80.99652862548857, 15.800141334533748],
        [80.99652862548857, 15.799860954284611],
        [80.99680328369158, 15.799860954284611],
        [80.99680328369158, 15.799571037292537],
        [80.99708557128912, 15.799583435058707],
        [80.99708557128912, 15.799027442932243],
        [80.99736022949236, 15.799031257629395],
        [80.99736022949236, 15.798750877380428],
        [80.99764251709007, 15.798750877380428],
        [80.99764251709007, 15.798190116882324],
        [80.99791717529291, 15.798194885253963],
        [80.99791717529291, 15.797638893127498],
        [80.99819183349615, 15.797640800476074],
        [80.99819183349615, 15.797080039978141],
        [80.99847412109386, 15.797082901001033],
        [80.99847412109386, 15.796528816223145],
        [80.99874877929688, 15.79653072357172],
        [80.99874877929688, 15.79625129699707],
        [80.99903106689482, 15.79625129699707],
        [80.99903106689482, 15.79568958282482],
        [80.99930572509766, 15.795695304870605],
        [80.99930572509766, 15.795138359069824],
        [80.99958038330107, 15.795141220092887],
        [80.99958038330107, 15.794310569763184],
        [80.99986267089861, 15.794310569763184],
        [80.99986267089861, 15.794031143188533],
        [81.00013732910162, 15.794031143188533],
        [81.00013732910162, 15.793760299682674],
        [81.00041961669939, 15.793760299682674],
        [81.00041961669939, 15.792640686035213],
        [81.00068664550787, 15.792640686035213],
        [81.00068664550787, 15.79153060913086],
        [81.00096893310541, 15.79153060913086],
        [81.00096893310541, 15.790700912475643],
        [81.00125122070318, 15.790700912475643],
        [81.00125122070318, 15.788749694824219],
        [81.00152587890636, 15.788749694824219],
        [81.00152587890636, 15.787919998169002],
        [81.00180816650396, 15.787919998169002],
        [81.00180816650396, 15.787360191345329],
        [81.00208282470732, 15.787362098693904],
        [81.00208282470732, 15.78708362579357],
        [81.00235748291016, 15.78708362579357],
        [81.00235748291016, 15.78680610656744],
        [81.0026397705081, 15.786810874938908],
        [81.0026397705081, 15.78625202178955],
        [81.00290679931658, 15.78625202178955],
        [81.00290679931658, 15.785689353942871],
        [81.00319671630865, 15.78569412231451],
        [81.00319671630865, 15.785140037536621],
        [81.00347137451189, 15.785140037536621],
        [81.00347137451189, 15.784859657287654],
        [81.0037536621096, 15.784859657287654],
        [81.00374603271507, 15.784569740295353],
        [81.00402832031244, 15.784584045410156],
        [81.00402832031244, 15.784305572509766],
        [81.00430297851568, 15.784310340881404],
        [81.00430297851568, 15.784029960632267],
        [81.00459289550776, 15.784029960632267],
        [81.00459289550776, 15.7837495803833],
        [81.00485992431646, 15.7837495803833],
        [81.00485992431646, 15.783469200134334],
        [81.00514221191435, 15.783472061157227],
        [81.00514221191435, 15.783193588256836],
        [81.00541687011719, 15.783193588256836],
        [81.00541687011719, 15.782917976379508],
        [81.0056915283206, 15.782921791076717],
        [81.0056915283206, 15.782640457153434],
        [81.00541687011719, 15.782640457153434],
        [81.00541687011719, 15.782361984253043],
        [81.0056915283206, 15.782361984253043],
        [81.0056915283206, 15.78208065032959],
        [81.00597381591814, 15.78208446502697],
        [81.00597381591814, 15.780970573425407],
        [81.00624847412115, 15.780970573425407],
        [81.00624847412115, 15.78041839599615],
        [81.00653076171892, 15.780420303344727],
        [81.00653076171892, 15.780141830444336],
        [81.00681304931646, 15.780141830444336],
        [81.00681304931646, 15.77986145019537],
        [81.0076370239259, 15.77986145019537],
        [81.0076370239259, 15.780141830444336],
        [81.0079193115235, 15.780138969421444],
        [81.0079193115235, 15.78208446502697],
        [81.0076370239259, 15.78208065032959],
        [81.0076370239259, 15.782640457153434],
        [81.00736236572271, 15.782640457153434],
        [81.00736236572271, 15.782921791076717],
        [81.00708007812494, 15.782917976379508],
        [81.00708007812494, 15.783472061157227],
        [81.00681304931646, 15.783469200134334],
        [81.00681304931646, 15.784029960632267],
        [81.00653076171892, 15.784028053283691],
        [81.00653076171892, 15.784584045410156],
        [81.0079193115235, 15.784584045410156],
        [81.0079193115235, 15.784305572509766],
        [81.00818634033232, 15.784310340881404],
        [81.00818634033232, 15.783469200134334],
        [81.00846862792969, 15.783472061157227],
        [81.00846862792969, 15.782636642456055],
        [81.00875091552763, 15.782640457153434],
        [81.00875091552763, 15.78208065032959],
        [81.00902557373064, 15.78208446502697],
        [81.00902557373064, 15.781249046325797],
        [81.00930786132841, 15.78125190734869],
        [81.00930786132841, 15.780420303344727],
        [81.00958251953142, 15.780420303344727],
        [81.00958251953142, 15.780141830444336],
        [81.01040649414068, 15.780141830444336],
        [81.01040649414068, 15.779581069946403],
        [81.01069641113276, 15.779582977294979],
        [81.01069641113276, 15.778751373291016],
        [81.010971069336, 15.778760910034123],
        [81.010971069336, 15.777919769287223],
        [81.01126098632824, 15.777919769287223],
        [81.01126098632824, 15.776529312133903],
        [81.01152801513672, 15.776529312133903],
        [81.01152801513672, 15.776250839233512],
        [81.01207733154314, 15.776250839233512],
        [81.01207733154314, 15.775140762329158],
        [81.01180267334013, 15.775140762329158],
        [81.01180267334013, 15.774312019348258],
        [81.01152801513672, 15.774312019348258],
        [81.01152801513672, 15.773751258850098],
        [81.01125335693371, 15.773751258850098],
        [81.01125335693371, 15.773470878601131],
        [81.01152801513672, 15.773470878601131],
        [81.01152801513672, 15.772639274597168],
        [81.01180267334013, 15.772639274597168],
        [81.01180267334013, 15.772083282470703],
        [81.0120849609375, 15.772083282470703],
        [81.0120849609375, 15.771529197692985],
        [81.01235961914091, 15.77153110504156],
        [81.01235961914091, 15.770971298217773],
        [81.0120849609375, 15.77097320556652],
        [81.0120849609375, 15.770138740539664],
        [81.01180267334013, 15.77014064788824],
        [81.01180267334013, 15.769589424133244],
        [81.01152801513672, 15.769589424133244],
        [81.01152801513672, 15.768751144409237],
        [81.01125335693371, 15.768751144409237],
        [81.01125335693371, 15.768472671508846],
        [81.010971069336, 15.768472671508846],
        [81.010971069336, 15.768195152282772],
        [81.01069641113276, 15.768195152282772],
        [81.01069641113276, 15.767915725708065],
        [81.00930786132841, 15.76792144775402],
        [81.00930786132841, 15.767641067504883],
        [81.00846862792969, 15.767641067504883],
        [81.00846862792969, 15.7673597335816],
        [81.0076370239259, 15.7673597335816],
        [81.0076370239259, 15.76708030700695],
        [81.00597381591814, 15.767082214355526],
        [81.00597381591814, 15.766806602478027],
        [81.0056915283206, 15.766819953918514],
        [81.0056915283206, 15.7665309906007],
        [81.00596618652361, 15.7665309906007],
        [81.00596618652361, 15.766249656677246],
        [81.0056915283206, 15.766249656677246],
        [81.0056915283206, 15.761249542236385],
        [81.00541687011719, 15.761249542236385],
        [81.00541687011719, 15.760419845581168],
        [81.0056915283206, 15.760419845581168],
        [81.0056915283206, 15.759581565856934],
        [81.00597381591814, 15.759584426879996],
        [81.00597381591814, 15.758472442627067],
        [81.00624847412115, 15.758472442627067],
        [81.00624847412115, 15.757916450500602],
        [81.00596618652361, 15.757920265197754],
        [81.00596618652361, 15.757651329040527],
        [81.0056915283206, 15.757651329040527],
        [81.0056915283206, 15.757360458374137],
        [81.00541687011719, 15.757362365722713],
        [81.00541687011719, 15.757083892822322],
        [81.00514221191435, 15.757083892822322],
        [81.00514221191435, 15.756806373596248],
        [81.00485992431646, 15.7568101882934],
        [81.00485992431646, 15.756529808044434],
        [81.00459289550776, 15.756529808044434],
        [81.00459289550776, 15.756250381469783],
        [81.00430297851568, 15.756250381469783],
        [81.00430297851568, 15.755971908569393],
        [81.00402832031244, 15.755971908569393],
        [81.00402832031244, 15.755693435669002],
        [81.0037536621096, 15.755693435669002],
        [81.0037536621096, 15.75514030456543],
        [81.00319671630865, 15.75514030456543],
        [81.00319671630865, 15.754861831665039],
        [81.00235748291016, 15.754861831665039],
        [81.00235748291016, 15.754590034484863],
        [81.00206756591825, 15.754590034484863],
        [81.00206756591825, 15.753190994262752],
        [81.00180816650396, 15.753195762634391],
        [81.00180816650396, 15.752915382385254],
        [81.00152587890636, 15.752920150756893],
        [81.00152587890636, 15.752641677856502],
        [81.00125122070318, 15.752641677856502],
        [81.00125122070318, 15.752361297607536],
        [81.00096893310541, 15.752361297607536],
        [81.00096893310541, 15.75180530548107],
        [80.99986267089861, 15.751811981201286],
        [80.99986267089861, 15.751520156860408],
        [80.99958038330107, 15.75152683258068],
        [80.99958038330107, 15.751249313354606],
        [80.99930572509766, 15.751249313354606],
        [80.99930572509766, 15.750970840454215],
        [80.99903106689482, 15.750970840454215],
        [80.99903106689482, 15.750691413879508],
        [80.99874877929688, 15.750695228576717],
        [80.99874877929688, 15.750416755676326],
        [80.9984664916995, 15.750419616699219],
        [80.9984664916995, 15.750139236450252],
        [80.99791717529291, 15.750139236450252],
        [80.99791717529291, 15.749860763549862],
        [80.99764251709007, 15.749860763549862],
        [80.99764251709007, 15.749581336975154],
        [80.99708557128912, 15.74958324432373],
        [80.99708557128912, 15.749304771423397],
        [80.99652862548857, 15.749309539795036],
        [80.99652862548857, 15.749032020568961],
        [80.9962463378908, 15.749032020568961],
        [80.9962463378908, 15.748750686645508],
        [80.99569702148438, 15.748750686645508],
        [80.99569702148438, 15.748458862304801],
        [80.99514007568365, 15.748473167419377],
        [80.99514007568365, 15.748194694519043],
        [80.99485778808605, 15.748194694519043],
        [80.99485778808605, 15.747917175292912],
        [80.99430847167986, 15.747921943664608],
        [80.99430847167986, 15.747640609741325],
        [80.99402618408226, 15.747640609741325],
        [80.99402618408226, 15.747361183166447],
        [80.9934692382813, 15.747361183166447],
        [80.9934692382813, 15.747082710266056],
        [80.99291992187528, 15.747082710266056],
        [80.99291992187528, 15.746805191040153],
        [80.99263763427734, 15.746811866760254],
        [80.99263763427734, 15.746530532836971],
        [80.99208068847662, 15.746530532836971],
        [80.99208068847662, 15.746251106262264],
        [80.99180603027355, 15.746251106262264],
        [80.99180603027355, 15.745970726013297],
        [80.9912490844726, 15.745972633361873],
        [80.9912490844726, 15.745695114135799],
        [80.99069213867205, 15.745695114135799],
        [80.99069213867205, 15.745416641235408],
        [80.9904174804688, 15.745421409606877],
        [80.9904174804688, 15.74514102935791],
        [80.98986053466825, 15.74514102935791],
        [80.98986053466825, 15.744860649108944],
        [80.98930358886747, 15.744860649108944],
        [80.98929595947294, 15.744580268859977],
        [80.98902893066412, 15.74458312988287],
        [80.98902893066412, 15.744304656982479],
        [80.98847198486334, 15.74431133270275],
        [80.98847198486334, 15.744030952453556],
        [80.9881973266601, 15.744030952453556],
        [80.9881973266601, 15.74375057220459],
        [80.98764038085955, 15.74375057220459],
        [80.98764038085955, 15.743470191955623],
        [80.98735809326178, 15.743473052978516],
        [80.98735809326178, 15.743194580078125],
        [80.98680877685575, 15.743194580078125],
        [80.98680877685575, 15.74291706085205],
        [80.98652648925781, 15.742920875549373],
        [80.98652648925781, 15.74265003204357],
        [80.98596954345703, 15.74265003204357],
        [80.98596954345703, 15.742361068725586],
        [80.98568725585966, 15.742361068725586],
        [80.98568725585966, 15.742079734802303],
        [80.98513793945307, 15.742081642150879],
        [80.98513793945307, 15.741806983947868],
        [80.98486328125023, 15.74181079864502],
        [80.98486328125023, 15.74153137207037],
        [80.98430633544928, 15.74153137207037],
        [80.98430633544928, 15.741250991821403],
        [80.98403167724626, 15.741250991821403],
        [80.98403167724626, 15.74096965789795],
        [80.98347473144531, 15.740973472595329],
        [80.98347473144531, 15.740694046020621],
        [80.98319244384794, 15.740694046020621],
        [80.98319244384794, 15.74041557312023],
        [80.98264312744152, 15.740421295166016],
        [80.98264312744152, 15.740140914917049],
        [80.98208618164057, 15.740140914917049],
        [80.98208618164057, 15.739859580993766],
        [80.98152923584001, 15.739863395690975],
        [80.98152923584001, 15.739582061767692],
        [80.98097229003923, 15.739582061767692],
        [80.98097229003923, 15.739306449890194],
        [80.98042297363281, 15.739311218261832],
        [80.98042297363281, 15.739030838012695],
        [80.9798583984375, 15.739030838012695],
        [80.9798583984375, 15.738750457763729],
        [80.97957611084013, 15.738750457763729],
        [80.97957611084013, 15.738470077514762],
        [80.97902679443371, 15.738471984863338],
        [80.97902679443371, 15.738194465637264],
        [80.97846984863276, 15.738194465637264],
        [80.97846984863276, 15.737915992736873],
        [80.97789764404291, 15.737920761108512],
        [80.97789764404291, 15.737640380859375],
        [80.97735595703142, 15.737640380859375],
        [80.97735595703142, 15.737360000610408],
        [80.976806640625, 15.737360000610408],
        [80.976806640625, 15.737079620361385],
        [80.97624969482428, 15.73708438873291],
        [80.97624969482428, 15.73680591583252],
        [80.9756927490235, 15.736810684204158],
        [80.9756927490235, 15.736518859863281],
        [80.97513580322288, 15.736528396606388],
        [80.97513580322288, 15.736249923706055],
        [80.97458648681646, 15.736249923706055],
        [80.97458648681646, 15.735969543457031],
        [80.97402954101591, 15.735972404479924],
        [80.97402954101591, 15.735693931579533],
        [80.97347259521513, 15.735693931579533],
        [80.97347259521513, 15.735416412353459],
        [80.97292327880871, 15.735421180725098],
        [80.97292327880871, 15.735139846801871],
        [80.97235870361322, 15.735139846801871],
        [80.97235870361322, 15.734860420227164],
        [80.97152709960943, 15.73486232757574],
        [80.97152709960943, 15.734581947326603],
        [80.97125244140642, 15.734581947326603],
        [80.97125244140642, 15.734306335449276],
        [80.970703125, 15.734311103820744],
        [80.970703125, 15.73403263092041],
        [80.97013854980469, 15.73403263092041],
        [80.97013854980469, 15.73375034332281],
        [80.96985626220732, 15.73375034332281],
        [80.96985626220732, 15.733461380004826],
        [80.96930694580072, 15.73347187042242],
        [80.96930694580072, 15.733194351196346],
        [80.96875000000017, 15.733194351196346],
        [80.96875000000017, 15.732915878295955],
        [80.96819305419939, 15.732919692993164],
        [80.96819305419939, 15.732640266418457],
        [80.96763610839861, 15.732640266418457],
        [80.96763610839861, 15.732361793518066],
        [80.96708679199219, 15.732361793518066],
        [80.96708679199219, 15.732078552246037],
        [80.96680450439482, 15.732084274291992],
        [80.96680450439482, 15.731805801391602],
        [80.96624755859386, 15.73180961608898],
        [80.96624755859386, 15.731530189514103],
        [80.96569824218767, 15.731530189514103],
        [80.96569061279291, 15.731249809265137],
        [80.96514129638689, 15.731249809265137],
        [80.96514129638689, 15.73096942901617],
        [80.96458435058611, 15.730972290039062],
        [80.96458435058611, 15.730695724487248],
        [80.96402740478516, 15.730695724487248],
        [80.96402740478516, 15.730416297912598],
        [80.96347045898443, 15.73042011260992],
        [80.96347045898443, 15.730139732360954],
        [80.96292114257818, 15.730139732360954],
        [80.96292114257818, 15.729859352111816],
        [80.96208190917986, 15.72986221313488],
        [80.96208190917986, 15.729583740234489],
        [80.96152496337908, 15.729583740234489],
        [80.96152496337908, 15.729306221008414],
        [80.96098327636719, 15.729310035705566],
        [80.96098327636719, 15.7290296554566],
        [80.96041870117193, 15.7290296554566],
        [80.96041870117193, 15.72875022888195],
        [80.95986175537115, 15.72875022888195],
        [80.95986175537115, 15.728470802307243],
        [80.95874786376959, 15.728470802307243],
        [80.95874786376959, 15.728191375732422],
        [80.95735931396484, 15.728193283081168],
        [80.95735931396484, 15.72791767120367],
        [80.956787109375, 15.727919578552246],
        [80.956787109375, 15.727640151977596],
        [80.95652770996088, 15.727640151977596],
        [80.95652770996088, 15.727361679077205],
        [80.95569610595709, 15.727361679077205],
        [80.95569610595709, 15.727640151977596],
        [80.95401763916044, 15.727640151977596],
        [80.95401763916044, 15.727361679077205],
        [80.95375061035162, 15.727361679077205],
        [80.95375061035162, 15.727081298828239],
        [80.95319366455084, 15.727083206176815],
        [80.95319366455084, 15.726804733276424],
        [80.95263671875023, 15.72681045532238],
        [80.95263671875023, 15.726531982421989],
        [80.95207977294928, 15.726531982421989],
        [80.95207977294928, 15.726251602172852],
        [80.95153045654325, 15.726251602172852],
        [80.95153045654325, 15.725971221923885],
        [80.95097351074247, 15.725971221923885],
        [80.95097351074247, 15.72569465637207],
        [80.95056915283232, 15.72569465637207],
        [80.95041656494152, 15.72569465637207],
        [80.95041656494152, 15.725417137145996],
        [80.94985961914057, 15.725421905517635],
        [80.94985961914057, 15.725141525268668],
        [80.94930267334001, 15.725141525268668],
        [80.94930267334001, 15.724580764770565],
        [80.94902801513678, 15.72458267211914],
        [80.94902801513678, 15.724305152893066],
        [80.94875335693376, 15.724309921264705],
        [80.94875335693376, 15.724200248718262],
        [80.94875335693376, 15.723749160766545],
        [80.94847106933622, 15.723751068115348],
        [80.94847106933622, 15.723470687866211],
        [80.94819641113281, 15.723472595214957],
        [80.94819641113281, 15.72319316864008],
        [80.94791412353544, 15.72319316864008],
        [80.94791412353544, 15.722916603088493],
        [80.94763946533209, 15.722919464111385],
        [80.94763946533209, 15.722640991210994],
        [80.9470825195313, 15.722640991210994],
        [80.9470825195313, 15.722360610962028],
        [80.9465255737307, 15.722360610962028],
        [80.9465255737307, 15.722083091735897],
        [80.94625091552751, 15.722083091735897],
        [80.94625091552751, 15.721804618835563],
        [80.94596862792974, 15.72181034088129],
        [80.94596862792974, 15.721520423889217],
        [80.94569396972673, 15.721527099609432],
        [80.94569396972673, 15.721248626709041],
        [80.94484710693388, 15.721250534057674],
        [80.94484710693388, 15.720971107482967],
        [80.94430541992199, 15.720973014831543],
        [80.94430541992199, 15.72069454193121],
        [80.9440307617188, 15.72069454193121],
        [80.9440307617188, 15.720417022705078],
        [80.94374847412104, 15.720421791076774],
        [80.94374847412104, 15.72014141082758],
        [80.94319152832048, 15.72014141082758],
        [80.94319152832048, 15.719861030578613],
        [80.94291687011724, 15.719861030578613],
        [80.94291687011724, 15.719580650329647],
        [80.94208526611328, 15.719582557678223],
        [80.94208526611328, 15.719306945800724],
        [80.9415283203125, 15.71931171417242],
        [80.9415283203125, 15.719031333923397],
        [80.94069671630854, 15.719031333923397],
        [80.94069671630854, 15.71875095367426],
        [80.93985748291038, 15.71875095367426],
        [80.93985748291038, 15.718482017517204],
        [80.93904113769531, 15.718482017517204],
        [80.93904113769531, 15.718190193176326],
        [80.93791961669928, 15.718190193176326],
        [80.93791961669928, 15.718482017517204],
        [80.9373626708985, 15.718482017517204],
        [80.9373626708985, 15.71875095367426],
        [80.93681335449224, 15.71875095367426],
        [80.93681335449224, 15.719031333923397],
        [80.93569183349638, 15.719031333923397],
        [80.93569183349638, 15.71931171417242],
        [80.93514251709013, 15.719306945800724],
        [80.93514251709013, 15.719582557678223],
        [80.93486022949224, 15.719580650329647],
        [80.93486022949224, 15.719861030578613],
        [80.93459320068354, 15.719861030578613],
        [80.93459320068354, 15.72014141082758],
        [80.93430328369146, 15.72014141082758],
        [80.93430328369146, 15.720421791076774],
        [80.93402862548822, 15.720417022705078],
        [80.93402862548822, 15.72069454193121],
        [80.93374633789085, 15.72069454193121],
        [80.93374633789085, 15.720973014831543],
        [80.93347167968767, 15.720971107482967],
        [80.93347167968767, 15.72181034088129],
        [80.93319702148443, 15.72181034088129],
        [80.93319702148443, 15.722640991210994],
        [80.93290710449236, 15.722640991210994],
        [80.93290710449236, 15.723751068115348],
        [80.93264007568388, 15.723751068115348],
        [80.93264007568388, 15.724028587341422],
        [80.93180847167974, 15.724028587341422],
        [80.93180847167974, 15.723751068115348],
        [80.93041992187517, 15.723751068115348],
        [80.93041992187517, 15.723470687866211],
        [80.92930603027344, 15.723472595214957],
        [80.92930603027344, 15.72319316864008],
        [80.9279174804687, 15.72319316864008],
        [80.9279174804687, 15.722916603088493],
        [80.92652893066435, 15.722919464111385],
        [80.92652893066435, 15.722640991210994],
        [80.92542266845714, 15.722640991210994],
        [80.92542266845714, 15.722360610962028],
        [80.92514038085943, 15.722360610962028],
        [80.92514038085943, 15.722083091735897],
        [80.92430877685564, 15.722083091735897],
        [80.92430877685564, 15.721804618835563],
        [80.92263793945312, 15.72181034088129],
        [80.92263793945312, 15.721520423889217],
        [80.92041778564459, 15.721527099609432],
        [80.92041778564459, 15.721248626709041],
        [80.91929626464872, 15.721250534057674],
        [80.91929626464872, 15.720971107482967],
        [80.91847229003912, 15.720973014831543],
        [80.91847229003912, 15.72069454193121],
        [80.91764068603533, 15.72069454193121],
        [80.91764068603533, 15.720417022705078],
        [80.91680908203153, 15.720421791076774],
        [80.91680908203153, 15.72014141082758],
        [80.91513824462885, 15.72014141082758],
        [80.91513824462885, 15.719861030578613],
        [80.91348266601562, 15.719861030578613],
        [80.91348266601562, 15.72014141082758],
        [80.91236114501959, 15.72014141082758],
        [80.91236114501959, 15.719861030578613],
        [80.9112472534182, 15.719861030578613],
        [80.9112472534182, 15.719580650329647],
        [80.91097259521501, 15.719582557678223],
        [80.91097259521501, 15.719306945800724],
        [80.90902709960949, 15.71931171417242],
        [80.90902709960949, 15.719031333923397],
        [80.90791320800798, 15.719031333923397],
        [80.90791320800798, 15.71875095367426],
        [80.90680694580078, 15.71875095367426],
        [80.90680694580078, 15.718482017517204],
        [80.90625762939459, 15.718482017517204],
        [80.90567779541021, 15.718482017517204],
        [80.90567779541021, 15.718190193176326],
        [80.90097045898466, 15.718194961547965],
        [80.90097045898466, 15.717917442321891],
        [80.90069580078125, 15.717921257019043],
        [80.90069580078125, 15.717640876770076],
        [80.90013885498047, 15.717640876770076],
        [80.90013885498047, 15.717361450195426],
        [80.89986419677746, 15.717361450195426],
        [80.89986419677746, 15.717082977295036],
        [80.89902496337896, 15.717082977295036],
        [80.89902496337896, 15.716805458068961],
        [80.89624786376964, 15.716809272766113],
        [80.89624786376964, 15.716528892517147],
        [80.89541625976585, 15.716528892517147],
        [80.89541625976585, 15.716251373291072],
        [80.8929061889649, 15.716251373291072],
        [80.8929061889649, 15.71597003936779],
        [80.89236450195335, 15.715972900390682],
        [80.89236450195335, 15.715695381164608],
        [80.88986206054693, 15.715695381164608],
        [80.88986206054693, 15.715416908264217],
        [80.88957977294916, 15.715420722961369],
        [80.88957977294916, 15.715141296386719],
        [80.88874816894537, 15.715141296386719],
        [80.88874816894537, 15.714859962463436],
        [80.88735961914062, 15.714859962463436],
        [80.88735961914062, 15.71457958221447],
        [80.88402557373075, 15.714582443237362],
        [80.88402557373075, 15.714306831359863],
        [80.88208007812506, 15.714310646057186],
        [80.88208007812506, 15.714031219482536],
        [80.88153076171903, 15.714031219482536],
        [80.88153076171903, 15.713752746582145],
        [80.88096618652372, 15.713752746582145],
        [80.88096618652372, 15.713470458984432],
        [80.87902832031256, 15.713472366333008],
        [80.87902832031256, 15.713193893432617],
        [80.87596893310553, 15.713193893432617],
        [80.87596893310553, 15.712916374206543],
        [80.87374877929682, 15.712921142578182],
        [80.87374877929682, 15.712640762329215],
        [80.87124633789091, 15.712640762329215],
        [80.87124633789091, 15.712350845336914],
        [80.8690261840822, 15.712360382080078],
        [80.8690261840822, 15.712083816528434],
        [80.86653137207048, 15.712081909179688],
        [80.86653137207048, 15.71180629730236],
        [80.86207580566435, 15.711811065673828],
        [80.86207580566435, 15.711530685424862],
        [80.86152648925793, 15.711530685424862],
        [80.86152648925793, 15.711250305175895],
        [80.85930633544922, 15.711250305175895],
        [80.85930633544922, 15.710969924926701],
        [80.85763549804693, 15.710971832275504],
        [80.85763549804693, 15.71069431304943],
        [80.85569763183594, 15.71069431304943],
        [80.85569763183594, 15.71041584014904],
        [80.8526382446289, 15.710420608520508],
        [80.8526382446289, 15.710140228271541],
        [80.85041809082037, 15.710140228271541],
        [80.85041809082037, 15.710420608520508],
        [80.85013580322283, 15.71041584014904],
        [80.85013580322283, 15.71069431304943],
        [80.84986114501982, 15.71069431304943],
        [80.84986114501982, 15.710971832275504],
        [80.8492965698245, 15.710969924926701],
        [80.8492965698245, 15.711250305175895],
        [80.8484725952149, 15.711250305175895],
        [80.8484725952149, 15.710969924926701],
        [80.84819793701166, 15.710971832275504],
        [80.84819793701166, 15.710140228271541],
        [80.84792327880882, 15.710140228271541],
        [80.84792327880882, 15.709859848022575],
        [80.84764099121111, 15.709859848022575],
        [80.84764099121111, 15.709584236145076],
        [80.84124755859398, 15.709584236145076],
        [80.84124755859398, 15.709305763244686],
        [80.83930206298834, 15.709310531616325],
        [80.83930206298834, 15.709030151367188],
        [80.83790588378923, 15.709030151367188],
        [80.83790588378923, 15.709310531616325],
        [80.83680725097656, 15.709305763244686],
        [80.83680725097656, 15.709584236145076],
        [80.83652496337919, 15.709584236145076],
        [80.83652496337919, 15.709859848022575],
        [80.83598327636713, 15.709859848022575],
        [80.83598327636713, 15.710140228271541],
        [80.83458709716803, 15.710140228271541],
        [80.83458709716803, 15.710420608520508],
        [80.83319091796875, 15.71041584014904],
        [80.83319091796875, 15.71069431304943],
        [80.83180236816423, 15.71069431304943],
        [80.83180236816423, 15.710971832275504],
        [80.83068847656267, 15.710969924926701],
        [80.83068847656267, 15.711250305175895],
        [80.82930755615229, 15.711250305175895],
        [80.82930755615229, 15.711530685424862],
        [80.82791900634794, 15.711530685424862],
        [80.82791900634794, 15.711811065673828],
        [80.82514190673845, 15.71180629730236],
        [80.82514190673845, 15.712081909179688],
        [80.82402801513672, 15.712083816528434],
        [80.82402801513672, 15.712360382080078],
        [80.82319641113293, 15.712350845336914],
        [80.82319641113293, 15.712640762329215],
        [80.82263946533197, 15.712640762329215],
        [80.82263946533197, 15.712921142578182],
        [80.82208251953142, 15.712916374206543],
        [80.82208251953142, 15.713193893432617],
        [80.82180786132818, 15.713193893432617],
        [80.82180786132818, 15.713472366333008],
        [80.82151794433611, 15.713470458984432],
        [80.82151794433611, 15.714031219482536],
        [80.82096862792969, 15.714026451110897],
        [80.82096862792969, 15.714582443237362],
        [80.82068634033232, 15.71457958221447],
        [80.82068634033232, 15.714859962463436],
        [80.8204193115235, 15.714859962463436],
        [80.8204193115235, 15.715141296386719],
        [80.8201370239259, 15.715139389038143],
        [80.8201370239259, 15.715695381164608],
        [80.81986236572271, 15.715695381164608],
        [80.81986236572271, 15.715972900390682],
        [80.81958007812494, 15.71597003936779],
        [80.81958007812494, 15.716528892517147],
        [80.81931304931663, 15.716528892517147],
        [80.81931304931663, 15.716809272766113],
        [80.81903076171892, 15.716805458068961],
        [80.81903076171892, 15.717082977295036],
        [80.81874847412115, 15.717080116271973],
        [80.81874847412115, 15.717640876770076],
        [80.81845855712908, 15.717640876770076],
        [80.81845855712908, 15.717921257019043],
        [80.8181915283206, 15.717917442321891],
        [80.8181915283206, 15.718194961547965],
        [80.81764221191435, 15.718190193176326],
        [80.81764221191435, 15.718482017517204],
        [80.8173599243164, 15.718482017517204],
        [80.8173599243164, 15.71875095367426],
        [80.81709289550793, 15.71875095367426],
        [80.81709289550793, 15.719031333923397],
        [80.81652832031244, 15.719031333923397],
        [80.81652832031244, 15.71931171417242],
        [80.8162536621096, 15.719306945800724],
        [80.8162536621096, 15.719582557678223],
        [80.81597137451189, 15.719580650329647],
        [80.81597137451189, 15.72014141082758],
        [80.81569671630865, 15.720138549804688],
        [80.81569671630865, 15.72069454193121],
        [80.81539916992205, 15.720690727234],
        [80.81539916992205, 15.72181034088129],
        [80.8151397705081, 15.72181034088129],
        [80.8151397705081, 15.722919464111385],
        [80.81485748291033, 15.722919464111385],
        [80.81485748291033, 15.723751068115348],
        [80.81458282470732, 15.723751068115348],
        [80.81458282470732, 15.724309921264705],
        [80.8143081665039, 15.724305152893066],
        [80.8143081665039, 15.724861145019531],
        [80.81402587890653, 15.724861145019531],
        [80.81402587890653, 15.725421905517635],
        [80.81375122070318, 15.725417137145996],
        [80.81375122070318, 15.72569465637207],
        [80.81346893310558, 15.725690841674918],
        [80.81346893310558, 15.726251602172852],
        [80.81318664550787, 15.726251602172852],
        [80.81318664550787, 15.72681045532238],
        [80.81291961669939, 15.72681045532238],
        [80.81291961669939, 15.727361679077205],
        [80.81265258789068, 15.727361679077205],
        [80.81265258789068, 15.727919578552246],
        [80.81236267089861, 15.72791767120367],
        [80.81236267089861, 15.728193283081168],
        [80.81208038330084, 15.728191375732422],
        [80.81208038330084, 15.728470802307243],
        [80.81180572509783, 15.728473663330135],
        [80.81181335449219, 15.72875022888195],
        [80.81153106689482, 15.72875022888195],
        [80.81153106689482, 15.7290296554566],
        [80.81124877929688, 15.729027748108024],
        [80.81124877929688, 15.729583740234489],
        [80.81097412109403, 15.729583740234489],
        [80.81097412109403, 15.72986221313488],
        [80.81069183349615, 15.729859352111816],
        [80.81069183349615, 15.730139732360954],
        [80.81041717529308, 15.730139732360954],
        [80.81041717529308, 15.730695724487248],
        [80.8101425170899, 15.730695724487248],
        [80.8101425170899, 15.730972290039062],
        [80.80986022949213, 15.73096942901617],
        [80.80986022949213, 15.731249809265137],
        [80.80957794189476, 15.731249809265137],
        [80.80957794189476, 15.731530189514103],
        [80.80930328369158, 15.731530189514103],
        [80.80930328369158, 15.73180961608898],
        [80.80902862548834, 15.731805801391602],
        [80.80902862548834, 15.732084274291992],
        [80.8087463378908, 15.732078552246037],
        [80.8087463378908, 15.732361793518066],
        [80.80847167968778, 15.732361793518066],
        [80.80847167968778, 15.732919692993164],
        [80.80819702148438, 15.732915878295955],
        [80.80819702148438, 15.733194351196346],
        [80.807914733887, 15.733194351196346],
        [80.807914733887, 15.73347187042242],
        [80.80764007568365, 15.733461380004826],
        [80.80764007568365, 15.73375034332281],
        [80.80708312988287, 15.73375034332281],
        [80.80708312988287, 15.73403263092041],
        [80.80680847167963, 15.73403263092041],
        [80.80680847167963, 15.734311103820744],
        [80.80652618408226, 15.734306335449276],
        [80.80652618408226, 15.734581947326603],
        [80.8059692382813, 15.734581947326603],
        [80.8059692382813, 15.73486232757574],
        [80.80568695068376, 15.734860420227164],
        [80.80568695068376, 15.735139846801871],
        [80.80541992187528, 15.735139846801871],
        [80.80541992187528, 15.735421180725098],
        [80.8048629760745, 15.735416412353459],
        [80.8048629760745, 15.735693931579533],
        [80.80458068847656, 15.735693931579533],
        [80.80458068847656, 15.735972404479924],
        [80.80430603027355, 15.735969543457031],
        [80.80430603027355, 15.736249923706055],
        [80.80403137207037, 15.736249923706055],
        [80.80403137207037, 15.736528396606388],
        [80.8037490844726, 15.736518859863281],
        [80.8037490844726, 15.736810684204158],
        [80.80347442626976, 15.73680591583252],
        [80.80347442626976, 15.73708438873291],
        [80.80319213867205, 15.737079620361385],
        [80.80319213867205, 15.737360000610408],
        [80.8029174804688, 15.737360000610408],
        [80.8029174804688, 15.737640380859375],
        [80.80236053466825, 15.737640380859375],
        [80.80236053466825, 15.737920761108512],
        [80.80208587646484, 15.737915992736873],
        [80.80208587646484, 15.738194465637264],
        [80.80180358886747, 15.738194465637264],
        [80.80180358886747, 15.738471984863338],
        [80.80152893066406, 15.738470077514762],
        [80.80152893066406, 15.738750457763729],
        [80.80124664306669, 15.738750457763729],
        [80.80124664306669, 15.739030838012695],
        [80.80097198486334, 15.739030838012695],
        [80.80097198486334, 15.739311218261832],
        [80.80041503906256, 15.739306449890194],
        [80.80041503906256, 15.739863395690975],
        [80.80023956298851, 15.739863395690975],
        [80.80014038085955, 15.739863395690975],
        [80.80014038085955, 15.740140914917049],
        [80.79985809326195, 15.740140914917049],
        [80.79985809326195, 15.740421295166016],
        [80.79958343505876, 15.74041557312023],
        [80.79958343505876, 15.740973472595329],
        [80.79930877685575, 15.74096965789795],
        [80.79930877685575, 15.741250991821403],
        [80.79902648925798, 15.741250991821403],
        [80.79902648925798, 15.74181079864502],
        [80.79875183105497, 15.741806983947868],
        [80.79875183105497, 15.742081642150879],
        [80.79846954345703, 15.742079734802303],
        [80.79846954345703, 15.742920875549373],
        [80.79819488525419, 15.74291706085205],
        [80.79819488525419, 15.743194580078125],
        [80.79792022705084, 15.743194580078125],
        [80.79792022705084, 15.743473052978516],
        [80.79763793945324, 15.743470191955623],
        [80.79763793945324, 15.744030952453556],
        [80.79736328125006, 15.744030952453556],
        [80.79736328125006, 15.74431133270275],
        [80.79708099365229, 15.74431133270275],
        [80.79708099365229, 15.745421409606877],
        [80.79680633544945, 15.745416641235408],
        [80.79680633544945, 15.745695114135799],
        [80.79652404785173, 15.745695114135799],
        [80.79652404785173, 15.745972633361873],
        [80.7962493896485, 15.745972633361873],
        [80.7962493896485, 15.747361183166447],
        [80.79596710205095, 15.747361183166447],
        [80.79596710205095, 15.749032020568961],
        [80.79569244384794, 15.749032020568961],
        [80.79569244384794, 15.750139236450252],
        [80.79541778564453, 15.750139236450252],
        [80.79541778564453, 15.750970840454215],
        [80.79514312744169, 15.750970840454215],
        [80.79514312744169, 15.751811981201286],
        [80.7948608398438, 15.75180530548107],
        [80.7948608398438, 15.752361297607536],
        [80.79458618164074, 15.752361297607536],
        [80.79458618164074, 15.753195762634391],
        [80.79428863525396, 15.753190994262752],
        [80.79428863525396, 15.754030227661246],
        [80.79402923583979, 15.754030227661246],
        [80.79402923583979, 15.754861831665039],
        [80.79374694824241, 15.754861831665039],
        [80.79374694824241, 15.75514030456543],
        [80.79347229003923, 15.75514030456543],
        [80.79347229003923, 15.755693435669002],
        [80.793197631836, 15.75569057464611],
        [80.793197631836, 15.756250381469783],
        [80.79291534423845, 15.756250381469783],
        [80.79291534423845, 15.757362365722713],
        [80.79264068603544, 15.757360458374137],
        [80.79264068603544, 15.757651329040527],
        [80.7923583984375, 15.757651329040527],
        [80.7923583984375, 15.757920265197754],
        [80.79208374023466, 15.757916450500602],
        [80.79208374023466, 15.758472442627067],
        [80.7918090820313, 15.758468627929688],
        [80.7918090820313, 15.759030342102108],
        [80.79151916503935, 15.759030342102108],
        [80.79151916503935, 15.759309768676815],
        [80.79125213623053, 15.759305953979435],
        [80.79125213623053, 15.759584426879996],
        [80.79096984863276, 15.759581565856934],
        [80.79096984863276, 15.760419845581168],
        [80.79069519042991, 15.760417938232365],
        [80.79069519042991, 15.760972023010254],
        [80.7904129028322, 15.760972023010254],
        [80.7904129028322, 15.761249542236385],
        [80.79013824462896, 15.761249542236385],
        [80.79013824462896, 15.761811256408805],
        [80.78986358642595, 15.76180553436285],
        [80.78986358642595, 15.76208400726324],
        [80.78958129882841, 15.762080192566032],
        [80.78958129882841, 15.762639999389705],
        [80.789306640625, 15.762639999389705],
        [80.789306640625, 15.762921333312931],
        [80.78902435302763, 15.762915611267204],
        [80.78902435302763, 15.763194084167594],
        [80.78874969482422, 15.763190269470215],
        [80.78874969482422, 15.763750076294059],
        [80.78848266601574, 15.763750076294059],
        [80.78848266601574, 15.764310836791992],
        [80.7881927490235, 15.76430416107172],
        [80.7881927490235, 15.764860153198242],
        [80.78791809082026, 15.764860153198242],
        [80.78791809082026, 15.765139579773063],
        [80.78763580322271, 15.765139579773063],
        [80.78763580322271, 15.765420913696346],
        [80.7873611450197, 15.765416145324707],
        [80.7873611450197, 15.765693664550781],
        [80.78708648681646, 15.765689849853572],
        [80.78708648681646, 15.7665309906007],
        [80.78679656982439, 15.7665309906007],
        [80.78679656982439, 15.766819953918514],
        [80.78652954101591, 15.766819953918514],
        [80.78652954101591, 15.7673597335816],
        [80.78624725341814, 15.7673597335816],
        [80.78624725341814, 15.767641067504883],
        [80.78597259521513, 15.76763820648199],
        [80.78597259521513, 15.768472671508846],
        [80.78569793701172, 15.768469810485954],
        [80.78569793701172, 15.769310951232853],
        [80.78542327880871, 15.769310951232853],
        [80.78542327880871, 15.77014064788824],
        [80.785140991211, 15.77014064788824],
        [80.785140991211, 15.77153110504156],
        [80.7848587036134, 15.77153110504156],
        [80.7848587036134, 15.771812438964844],
        [80.78458404541021, 15.771804809570312],
        [80.78458404541021, 15.772360801696777],
        [80.7843093872072, 15.772360801696777],
        [80.78430175781244, 15.772639274597168],
        [80.7840270996096, 15.77262973785406],
        [80.7840270996096, 15.773751258850098],
        [80.78375244140642, 15.773751258850098],
        [80.78375244140642, 15.774030685424805],
        [80.78347015380865, 15.774030685424805],
        [80.78347015380865, 15.774312019348258],
        [80.78319549560564, 15.774305343627987],
        [80.78319549560564, 15.77458286285406],
        [80.7829132080081, 15.774580955505485],
        [80.7829132080081, 15.775140762329158],
        [80.78263854980469, 15.775140762329158],
        [80.78263854980469, 15.776529312133903],
        [80.78236389160185, 15.7765274047851],
        [80.78236389160185, 15.777083396911564],
        [80.78208160400396, 15.777080535888729],
        [80.78208160400396, 15.777639389038086],
        [80.7818069458009, 15.777639389038086],
        [80.7818069458009, 15.777919769287223],
        [80.78152465820318, 15.77791690826416],
        [80.78152465820318, 15.77819538116455],
        [80.78125, 15.77819538116455],
        [80.78125, 15.778470993042049],
        [80.78098297119146, 15.778470993042049],
        [80.78098297119146, 15.780420303344727],
        [80.78069305419939, 15.780420303344727],
        [80.78069305419939, 15.782640457153434],
        [80.78041839599615, 15.782640457153434],
        [80.78041839599615, 15.785420417785588],
        [80.78069305419939, 15.785420417785588],
        [80.78069305419939, 15.789311408996639],
        [80.7809753417971, 15.789305686950684],
        [80.7809753417971, 15.789584159851074],
        [80.78125, 15.789580345153865],
        [80.78125, 15.790700912475643],
        [80.78151702880865, 15.790700912475643],
        [80.78151702880865, 15.791250228881893],
        [80.7818069458009, 15.791250228881893],
        [80.7818069458009, 15.791810989379996],
        [80.78208160400396, 15.791806221008358],
        [80.78208160400396, 15.792083740234432],
        [80.78263854980469, 15.792083740234432],
        [80.78263854980469, 15.791806221008358],
        [80.7829132080081, 15.791810989379996],
        [80.7829132080081, 15.79153060913086],
        [80.783203125, 15.79153060913086],
        [80.783203125, 15.790420532226676],
        [80.7829132080081, 15.790420532226676],
        [80.7829132080081, 15.790140151977539],
        [80.78263854980469, 15.790140151977539],
        [80.78263854980469, 15.789861679077148],
        [80.78234863281278, 15.789861679077148],
        [80.78234863281278, 15.789311408996639],
        [80.78263854980469, 15.789311408996639],
        [80.78263854980469, 15.788749694824219],
        [80.78347015380865, 15.788749694824219],
        [80.78347015380865, 15.788470268249512],
        [80.78375244140642, 15.788472175598088],
        [80.78375244140642, 15.787918090820426],
        [80.7840270996096, 15.787919998169002],
        [80.7840270996096, 15.787360191345329],
        [80.78430175781244, 15.787362098693904],
        [80.78430175781244, 15.78680610656744],
        [80.78457641601568, 15.786810874938908],
        [80.78457641601568, 15.786529541015682],
        [80.7848587036134, 15.786529541015682],
        [80.7848587036134, 15.78625202178955],
        [80.78542327880871, 15.78625202178955],
        [80.78542327880871, 15.785689353942871],
        [80.78569793701172, 15.78569412231451],
        [80.78569793701172, 15.785140037536621],
        [80.78679656982439, 15.785140037536621],
        [80.78679656982439, 15.785420417785588],
        [80.78708648681646, 15.78541564941412],
        [80.78708648681646, 15.78569412231451],
        [80.78763580322271, 15.78569412231451],
        [80.78763580322271, 15.78541564941412],
        [80.7881927490235, 15.785420417785588],
        [80.7881927490235, 15.784310340881404],
        [80.78848266601574, 15.784310340881404],
        [80.78848266601574, 15.782361984253043],
        [80.7881927490235, 15.782361984253043],
        [80.7881927490235, 15.78208065032959],
        [80.78791809082026, 15.78208446502697],
        [80.78791809082026, 15.781805992126579],
        [80.78763580322271, 15.781819343566895],
        [80.78763580322271, 15.78153038024908],
        [80.7873611450197, 15.78153038024908],
        [80.7873611450197, 15.77986145019537],
        [80.78763580322271, 15.77986145019537],
        [80.78763580322271, 15.779582977294979],
        [80.78791809082026, 15.779582977294979],
        [80.78791809082026, 15.778751373291016],
        [80.7881927490235, 15.778760910034123],
        [80.7881927490235, 15.777360916137695],
        [80.78848266601574, 15.777360916137695],
        [80.78847503662121, 15.7765274047851],
        [80.78874969482422, 15.776529312133903],
        [80.78874969482422, 15.775140762329158],
        [80.7890167236331, 15.775140762329158],
        [80.7890167236331, 15.774030685424805],
        [80.78874969482422, 15.774030685424805],
        [80.78874969482422, 15.77262973785406],
        [80.78902435302763, 15.772639274597168],
        [80.78902435302763, 15.772360801696777],
        [80.789306640625, 15.772360801696777],
        [80.789306640625, 15.772083282470703],
        [80.78958129882841, 15.772083282470703],
        [80.78958129882841, 15.771804809570312],
        [80.78985595703142, 15.771812438964844],
        [80.78985595703142, 15.77153110504156],
        [80.79013824462896, 15.77153110504156],
        [80.79013824462896, 15.771250724792424],
        [80.79347229003923, 15.771250724792424],
        [80.79347229003923, 15.770971298217773],
        [80.79374694824241, 15.77097320556652],
        [80.79374694824241, 15.769304275512809],
        [80.79347229003923, 15.769310951232853],
        [80.79347229003923, 15.769030570983887],
        [80.79292297363298, 15.769030570983887],
        [80.79292297363298, 15.768469810485954],
        [80.79264068603544, 15.768472671508846],
        [80.79264068603544, 15.76763820648199],
        [80.7923583984375, 15.767641067504883],
        [80.7923583984375, 15.765689849853572],
        [80.79208374023466, 15.765693664550781],
        [80.79208374023466, 15.762915611267204],
        [80.7918090820313, 15.762921333312931],
        [80.7918090820313, 15.761249542236385],
        [80.79207611084013, 15.761249542236385],
        [80.79207611084013, 15.760691642761287],
        [80.7923583984375, 15.760693550109863],
        [80.7923583984375, 15.760137557983398],
        [80.79264068603544, 15.76014041900629],
        [80.79264068603544, 15.7598619461059],
        [80.793197631836, 15.7598619461059],
        [80.793197631836, 15.759581565856934],
        [80.79374694824241, 15.759584426879996],
        [80.79374694824241, 15.759305953979435],
        [80.79402923583979, 15.759309768676815],
        [80.79402923583979, 15.759030342102108],
        [80.79457855224621, 15.759030342102108],
        [80.79457855224621, 15.758749961853141],
        [80.7948608398438, 15.758749961853141],
        [80.7948608398438, 15.758189201355037],
        [80.79513549804716, 15.758193969726676],
        [80.79513549804716, 15.757916450500602],
        [80.79541778564453, 15.757920265197754],
        [80.79541778564453, 15.757360458374137],
        [80.79569244384794, 15.757362365722713],
        [80.79569244384794, 15.757083892822322],
        [80.79597473144548, 15.757083892822322],
        [80.79597473144548, 15.756806373596248],
        [80.7962493896485, 15.7568101882934],
        [80.7962493896485, 15.756250381469783],
        [80.7965164184572, 15.756250381469783],
        [80.7965164184572, 15.755971908569393],
        [80.79680633544945, 15.755971908569393],
        [80.79680633544945, 15.75514030456543],
        [80.79708099365229, 15.75514030456543],
        [80.79708099365229, 15.754030227661246],
        [80.79736328125006, 15.754030227661246],
        [80.79736328125006, 15.753190994262752],
        [80.79763793945324, 15.753195762634391],
        [80.79763793945324, 15.752915382385254],
        [80.79792022705084, 15.752920150756893],
        [80.79792022705084, 15.752080917358398],
        [80.79819488525419, 15.752082824707145],
        [80.79819488525419, 15.75152683258068],
        [80.79846954345703, 15.75152683258068],
        [80.79846954345703, 15.750416755676326],
        [80.79875183105497, 15.750419616699219],
        [80.79875183105497, 15.750139236450252],
        [80.79930877685575, 15.750139236450252],
        [80.79930877685575, 15.749860763549862],
        [80.80014038085955, 15.749860763549862],
        [80.80014038085955, 15.749581336975154],
        [80.80041503906256, 15.74958324432373],
        [80.80041503906256, 15.749304771423397],
        [80.80071258544939, 15.749309539795036],
        [80.80071258544939, 15.748458862304801],
        [80.80097198486334, 15.748473167419377],
        [80.80097198486334, 15.748194694519043],
        [80.80124664306669, 15.748194694519043],
        [80.80124664306669, 15.747638702392521],
        [80.80152893066406, 15.747640609741325],
        [80.80152893066406, 15.746811866760254],
        [80.80179595947294, 15.746811866760254],
        [80.80179595947294, 15.746251106262264],
        [80.80208587646484, 15.746251106262264],
        [80.80208587646484, 15.745970726013297],
        [80.80236053466825, 15.745972633361873],
        [80.80236053466825, 15.744860649108944],
        [80.8026428222658, 15.744860649108944],
        [80.80263519287126, 15.74458312988287],
        [80.8029174804688, 15.74458312988287],
        [80.8029174804688, 15.74402904510498],
        [80.80319213867205, 15.744030952453556],
        [80.80319213867205, 15.74375057220459],
        [80.8037490844726, 15.74375057220459],
        [80.8037490844726, 15.743470191955623],
        [80.80403137207037, 15.743473052978516],
        [80.80403137207037, 15.743194580078125],
        [80.80430603027355, 15.743194580078125],
        [80.80430603027355, 15.74291706085205],
        [80.80458068847656, 15.742920875549373],
        [80.80458068847656, 15.74265003204357],
        [80.80513763427734, 15.74265003204357],
        [80.80513763427734, 15.742361068725586],
        [80.80541992187528, 15.742361068725586],
        [80.80541992187528, 15.742079734802303],
        [80.8056945800783, 15.742081642150879],
        [80.8056945800783, 15.741806983947868],
        [80.8059692382813, 15.74181079864502],
        [80.8059692382813, 15.74153137207037],
        [80.80651855468756, 15.74153137207037],
        [80.80651855468756, 15.741250991821403],
        [80.80708312988287, 15.741250991821403],
        [80.80708312988287, 15.74096965789795],
        [80.80753326416044, 15.740973472595329],
        [80.807914733887, 15.740973472595329],
        [80.807914733887, 15.740694046020621],
        [80.80847167968778, 15.740694046020621],
        [80.80847167968778, 15.74041557312023],
        [80.81041717529308, 15.740421295166016],
        [80.81041717529308, 15.740140914917049],
        [80.8109664916995, 15.740140914917049],
        [80.8109664916995, 15.739859580993766],
        [80.81236267089861, 15.739863395690975],
        [80.81236267089861, 15.739582061767692],
        [80.81346893310558, 15.739582061767692],
        [80.81346893310558, 15.739863395690975],
        [80.81375122070318, 15.739859580993766],
        [80.81375122070318, 15.740140914917049],
        [80.81402587890653, 15.740140914917049],
        [80.81402587890653, 15.740421295166016],
        [80.8143081665039, 15.74041557312023],
        [80.8143081665039, 15.740973472595329],
        [80.81458282470732, 15.74096965789795],
        [80.81458282470732, 15.74153137207037],
        [80.81485748291033, 15.74153137207037],
        [80.81485748291033, 15.74181079864502],
        [80.8151397705081, 15.74181079864502],
        [80.8151397705081, 15.74265003204357],
        [80.81485748291033, 15.74263858795166],
        [80.81485748291033, 15.743194580078125],
        [80.81458282470732, 15.743194580078125],
        [80.81458282470732, 15.743473052978516],
        [80.8143081665039, 15.743470191955623],
        [80.8143081665039, 15.74375057220459],
        [80.81402587890653, 15.74375057220459],
        [80.81402587890653, 15.744860649108944],
        [80.81375122070318, 15.744860649108944],
        [80.81375122070318, 15.745695114135799],
        [80.81346893310558, 15.74569034576416],
        [80.81346893310558, 15.746811866760254],
        [80.81318664550787, 15.746811866760254],
        [80.81318664550787, 15.747640609741325],
        [80.81291961669939, 15.747640609741325],
        [80.81291961669939, 15.748750686645508],
        [80.81265258789068, 15.748750686645508],
        [80.81265258789068, 15.749860763549862],
        [80.81236267089861, 15.749860763549862],
        [80.81236267089861, 15.754861831665039],
        [80.81208038330084, 15.754861831665039],
        [80.81208038330084, 15.75514030456543],
        [80.81181335449219, 15.75514030456543],
        [80.81181335449219, 15.760419845581168],
        [80.81153106689482, 15.760417938232365],
        [80.81153106689482, 15.760693550109863],
        [80.81124877929688, 15.760691642761287],
        [80.81124877929688, 15.761811256408805],
        [80.8109664916995, 15.761811256408805],
        [80.8109664916995, 15.763750076294059],
        [80.81069183349615, 15.763750076294059],
        [80.81069183349615, 15.764583587646598],
        [80.81041717529308, 15.764579772949219],
        [80.81041717529308, 15.765139579773063],
        [80.8101425170899, 15.765137672424316],
        [80.8101425170899, 15.765693664550781],
        [80.80986022949213, 15.765693664550781],
        [80.80986022949213, 15.765972137451172],
        [80.80930328369158, 15.765970230102596],
        [80.80930328369158, 15.7665309906007],
        [80.80902862548834, 15.7665309906007],
        [80.80902862548834, 15.766819953918514],
        [80.8087463378908, 15.766819953918514],
        [80.8087463378908, 15.76792144775402],
        [80.80847167968778, 15.767915725708065],
        [80.80847167968778, 15.768195152282772],
        [80.80819702148438, 15.768190383911076],
        [80.80819702148438, 15.769310951232853],
        [80.807914733887, 15.769304275512809],
        [80.807914733887, 15.76986122131359],
        [80.80764007568365, 15.76986122131359],
        [80.80764007568365, 15.77014064788824],
        [80.80735778808605, 15.77014064788824],
        [80.80735778808605, 15.770421028137207],
        [80.80708312988287, 15.770417213440055],
        [80.80708312988287, 15.77097320556652],
        [80.80680847167963, 15.770971298217773],
        [80.80680847167963, 15.771250724792424],
        [80.80651855468756, 15.771250724792424],
        [80.80651855468756, 15.77153110504156],
        [80.80625152587908, 15.771529197692985],
        [80.80625152587908, 15.772083282470703],
        [80.8059692382813, 15.772083282470703],
        [80.8059692382813, 15.773470878601131],
        [80.80568695068376, 15.773470878601131],
        [80.80568695068376, 15.773751258850098],
        [80.80541992187528, 15.773751258850098],
        [80.80541992187528, 15.776529312133903],
        [80.80513763427734, 15.776529312133903],
        [80.80513763427734, 15.777639389038086],
        [80.8048629760745, 15.777639389038086],
        [80.8048629760745, 15.777919769287223],
        [80.80458068847656, 15.777919769287223],
        [80.80458068847656, 15.778760910034123],
        [80.80430603027355, 15.778760910034123],
        [80.80430603027355, 15.779310226440543],
        [80.80403137207037, 15.779310226440543],
        [80.80403137207037, 15.780141830444336],
        [80.8037490844726, 15.780138969421444],
        [80.8037490844726, 15.78486156463623],
        [80.80345916748053, 15.784859657287654],
        [80.80345916748053, 15.785420417785588],
        [80.80319213867205, 15.78541564941412],
        [80.80319213867205, 15.785971641540584],
        [80.80300140380876, 15.785971641540584],
        [80.8029174804688, 15.785971641540584],
        [80.8029174804688, 15.78625202178955],
        [80.8026428222658, 15.78625202178955],
        [80.8026428222658, 15.786810874938908],
        [80.80236053466825, 15.78680610656744],
        [80.80236053466825, 15.787637710571232],
        [80.80208587646484, 15.787631034851188],
        [80.80208587646484, 15.788749694824219],
        [80.80179595947294, 15.788749694824219],
        [80.80179595947294, 15.789030075073356],
        [80.80152893066406, 15.789028167724553],
        [80.80152893066406, 15.789584159851074],
        [80.80124664306669, 15.789580345153865],
        [80.80124664306669, 15.790420532226676],
        [80.80097198486334, 15.790415763855037],
        [80.80097198486334, 15.790971755981502],
        [80.8006973266601, 15.790971755981502],
        [80.8006973266601, 15.791250228881893],
        [80.80041503906256, 15.791250228881893],
        [80.80041503906256, 15.792083740234432],
        [80.80014038085955, 15.792079925537053],
        [80.80014038085955, 15.792640686035213],
        [80.79985809326195, 15.79263782501232],
        [80.79985809326195, 15.793472290039176],
        [80.79957580566423, 15.79347038269043],
        [80.79957580566423, 15.793760299682674],
        [80.79930877685575, 15.793760299682674],
        [80.79930877685575, 15.794862747192496],
        [80.79902648925798, 15.794862747192496],
        [80.79902648925798, 15.795141220092887],
        [80.79875183105497, 15.795138359069824],
        [80.79875183105497, 15.795695304870605],
        [80.79846954345703, 15.79568958282482],
        [80.79846954345703, 15.79625129699707],
        [80.79818725585966, 15.79625129699707],
        [80.79818725585966, 15.79653072357172],
        [80.79792022705084, 15.796528816223145],
        [80.79792022705084, 15.797361373901424],
        [80.79763793945324, 15.797361373901424],
        [80.79763793945324, 15.798473358154354],
        [80.79736328125006, 15.798473358154354],
        [80.79736328125006, 15.799583435058707],
        [80.79708099365229, 15.799571037292537],
        [80.79708099365229, 15.800141334533748],
        [80.79680633544945, 15.800139427185172],
        [80.79680633544945, 15.80097293853754],
        [80.79652404785173, 15.80097293853754],
        [80.79652404785173, 15.802083015441895],
        [80.7962493896485, 15.802083015441895],
        [80.7962493896485, 15.802360534668026],
        [80.79597473144548, 15.802360534668026],
        [80.79597473144548, 15.804582595825309],
        [80.79569244384794, 15.804582595825309],
        [80.79569244384794, 15.805971145629883],
        [80.79541778564453, 15.805971145629883],
        [80.79541778564453, 15.807083129882812],
        [80.79514312744169, 15.807081222534237],
        [80.79514312744169, 15.80764198303234],
        [80.7948608398438, 15.80764198303234],
        [80.7948608398438, 15.80792045593273],
        [80.79458618164074, 15.807917594909668],
        [80.79458618164074, 15.808193206787166],
        [80.79430389404303, 15.808193206787166],
        [80.79430389404303, 15.808473587036133],
        [80.79402923583979, 15.80847072601324],
        [80.79402923583979, 15.810420036315918],
        [80.79374694824241, 15.810416221618766],
        [80.79374694824241, 15.81208419799816],
        [80.79347229003923, 15.81208419799816],
        [80.79347229003923, 15.814583778381404],
        [80.793197631836, 15.814581871032772],
        [80.793197631836, 15.816249847412223],
        [80.79347229003923, 15.816249847412223],
        [80.79347229003923, 15.81653022766119],
        [80.79514312744169, 15.81653022766119],
        [80.79514312744169, 15.816810607910156],
        [80.79597473144548, 15.816805839538688],
        [80.79597473144548, 15.817084312439079],
        [80.79846954345703, 15.817084312439079],
        [80.79846954345703, 15.816805839538688],
        [80.80014038085955, 15.816810607910156],
        [80.80014038085955, 15.81653022766119],
        [80.80097198486334, 15.81653022766119],
        [80.80097198486334, 15.816249847412223],
        [80.80139923095709, 15.816249847412223],
        [80.8029174804688, 15.816249847412223],
        [80.8029174804688, 15.815970420837516],
        [80.8037490844726, 15.815970420837516],
        [80.8037490844726, 15.816249847412223],
        [80.80430603027355, 15.816249847412223],
        [80.80430603027355, 15.81653022766119],
        [80.80513763427734, 15.81653022766119],
        [80.80513763427734, 15.816249847412223],
        [80.80541992187528, 15.816249847412223],
        [80.80541992187528, 15.815970420837516],
        [80.8059692382813, 15.815970420837516],
        [80.8059692382813, 15.816249847412223],
        [80.80625152587908, 15.816249847412223],
        [80.80625152587908, 15.81653022766119],
        [80.80708312988287, 15.81653022766119],
        [80.80708312988287, 15.816249847412223],
        [80.80790710449247, 15.816249847412223],
        [80.80790710449247, 15.815970420837516],
        [80.80902862548834, 15.815972328186092],
        [80.80902862548834, 15.815693855285758],
        [80.81069183349615, 15.815693855285758],
        [80.81069183349615, 15.815972328186092],
        [80.81097412109403, 15.815972328186092],
        [80.81097412109403, 15.817084312439079],
        [80.81069183349615, 15.817079544067383],
        [80.81069183349615, 15.817640304565543],
        [80.81041717529308, 15.817640304565543],
        [80.81041717529308, 15.819863319396973],
        [80.8101425170899, 15.81986045837408],
        [80.8101425170899, 15.821810722351017],
        [80.81041717529308, 15.821810722351017],
        [80.81041717529308, 15.822360038757438],
        [80.81069183349615, 15.822360038757438],
        [80.81069183349615, 15.82319545745861],
        [80.8109664916995, 15.823189735412654],
        [80.8109664916995, 15.825140953063908],
        [80.81124877929688, 15.825140953063908],
        [80.81124877929688, 15.826251029968262],
        [80.81153106689482, 15.826251029968262],
        [80.81153106689482, 15.827082633972225],
        [80.81181335449219, 15.827080726623649],
        [80.81181335449219, 15.829310417175293],
        [80.81208038330084, 15.829310417175293],
        [80.81208038330084, 15.831531524658317],
        [80.81181335449219, 15.831531524658317],
        [80.81181335449219, 15.832930564880485],
        [80.81153106689482, 15.832915306091422],
        [80.81153106689482, 15.833194732666072],
        [80.81124877929688, 15.83319091796875],
        [80.81124877929688, 15.833751678466854],
        [80.8109664916995, 15.833751678466854],
        [80.8109664916995, 15.834030151367244],
        [80.81069183349615, 15.834027290344352],
        [80.81069183349615, 15.834583282470817],
        [80.81041717529308, 15.83458137512207],
        [80.81041717529308, 15.834861755371207],
        [80.8101425170899, 15.834861755371207],
        [80.8101425170899, 15.835419654846248],
        [80.80986022949213, 15.835417747497672],
        [80.80986022949213, 15.835970878601074],
        [80.80958557128929, 15.835970878601074],
        [80.80958557128929, 15.83708381652832],
        [80.80930328369158, 15.83708381652832],
        [80.80930328369158, 15.837362289428711],
        [80.80902862548834, 15.837359428405819],
        [80.80902862548834, 15.837639808654785],
        [80.8087463378908, 15.837639808654785],
        [80.8087463378908, 15.837920188903752],
        [80.80847167968778, 15.8379163742066],
        [80.80847167968778, 15.83819580078125],
        [80.80819702148438, 15.83819580078125],
        [80.80819702148438, 15.838472366333065],
        [80.80790710449247, 15.838468551635685],
        [80.80790710449247, 15.838751792907715],
        [80.80764007568365, 15.838751792907715],
        [80.80764007568365, 15.839309692382812],
        [80.80735778808605, 15.839305877685604],
        [80.80735778808605, 15.839584350585994],
        [80.80708312988287, 15.839582443237418],
        [80.80708312988287, 15.839861869812069],
        [80.80680847167963, 15.839861869812069],
        [80.80680847167963, 15.840419769287166],
        [80.80652618408226, 15.840415954589787],
        [80.80652618408226, 15.840694427490348],
        [80.80625152587908, 15.840691566467285],
        [80.80625152587908, 15.84152984619152],
        [80.8059692382813, 15.84152984619152],
        [80.8059692382813, 15.841811180114803],
        [80.8056945800783, 15.841806411743107],
        [80.8056945800783, 15.842083930969238],
        [80.80541992187528, 15.842082023620605],
        [80.80541992187528, 15.842639923095703],
        [80.80513763427734, 15.842639923095703],
        [80.80513763427734, 15.842921257019157],
        [80.8048629760745, 15.842915534973201],
        [80.8048629760745, 15.843194007873592],
        [80.80458068847656, 15.843194007873592],
        [80.80458068847656, 15.843471527099666],
        [80.80430603027355, 15.84346961975109],
        [80.80430603027355, 15.84375],
        [80.80403137207037, 15.84375],
        [80.80403137207037, 15.844030380249023],
        [80.8037490844726, 15.844028472900447],
        [80.8037490844726, 15.844584465026912],
        [80.80347442626976, 15.844584465026912],
        [80.80347442626976, 15.84486007690441],
        [80.80319213867205, 15.844850540161076],
        [80.80319213867205, 15.845140457153377],
        [80.8029174804688, 15.845140457153377],
        [80.8029174804688, 15.845420837402344],
        [80.80236053466825, 15.845416069030875],
        [80.80236053466825, 15.84569358825695],
        [80.80208587646484, 15.84569358825695],
        [80.80208587646484, 15.84597206115734],
        [80.80179595947294, 15.845970153808594],
        [80.80179595947294, 15.846249580383414],
        [80.80152893066406, 15.846249580383414],
        [80.80152893066406, 15.846530914306697],
        [80.80097198486334, 15.846530914306697],
        [80.80097198486334, 15.846811294555664],
        [80.8006973266601, 15.846805572509709],
        [80.8006973266601, 15.847082138061523],
        [80.80041503906256, 15.847082138061523],
        [80.80041503906256, 15.847363471984977],
        [80.80014038085955, 15.847359657287598],
        [80.80014038085955, 15.847640991211051],
        [80.79985809326195, 15.847638130187988],
        [80.79985809326195, 15.847915649414062],
        [80.79958343505876, 15.847915649414062],
        [80.79958343505876, 15.848194122314453],
        [80.79930877685575, 15.848194122314453],
        [80.79930877685575, 15.848472595214844],
        [80.79902648925798, 15.848469734191951],
        [80.79902648925798, 15.848751068115234],
        [80.79875183105497, 15.848751068115234],
        [80.79875183105497, 15.849031448364371],
        [80.79846954345703, 15.849031448364371],
        [80.79846954345703, 15.849310874939079],
        [80.79819488525419, 15.849304199218807],
        [80.79819488525419, 15.849584579467773],
        [80.79792022705084, 15.849584579467773],
        [80.79792022705084, 15.849861145019588],
        [80.79763793945324, 15.849861145019588],
        [80.79763793945324, 15.850140571594238],
        [80.79736328125006, 15.850140571594238],
        [80.79736328125006, 15.850420951843205],
        [80.79680633544945, 15.850417137146053],
        [80.79680633544945, 15.850694656372127],
        [80.7962493896485, 15.850694656372127],
        [80.7962493896485, 15.850973129272518],
        [80.79596710205095, 15.850970268249625],
        [80.79596710205095, 15.851531028747559],
        [80.79569244384794, 15.851531028747559],
        [80.79569244384794, 15.851811408996582],
        [80.79513549804716, 15.85180473327648],
        [80.79513549804716, 15.852083206176871],
        [80.7948608398438, 15.852080345153809],
        [80.7948608398438, 15.852360725402775],
        [80.79428863525396, 15.852360725402775],
        [80.79428863525396, 15.852641105651912],
        [80.79402923583979, 15.852641105651912],
        [80.79402923583979, 15.85292053222662],
        [80.79374694824241, 15.85291671752924],
        [80.79374694824241, 15.85319519042963],
        [80.793197631836, 15.85319519042963],
        [80.793197631836, 15.853470802307129],
        [80.79291534423845, 15.853472709655705],
        [80.79292297363298, 15.853751182556096],
        [80.79264068603544, 15.853751182556096],
        [80.79264068603544, 15.85404014587408],
        [80.7923583984375, 15.854026794433594],
        [80.7923583984375, 15.854582786560059],
        [80.79208374023466, 15.854582786560059],
        [80.79208374023466, 15.85486125946045],
        [80.7918090820313, 15.85486125946045],
        [80.7918090820313, 15.855140686035156],
        [80.79151916503935, 15.855140686035156],
        [80.79151916503935, 15.85542201995861],
        [80.79125213623053, 15.855417251586914],
        [80.79125213623053, 15.855694770813045],
        [80.79096984863276, 15.855690956115836],
        [80.79096984863276, 15.85625076293951],
        [80.79070281982445, 15.85625076293951],
        [80.79070281982445, 15.8565292358399],
        [80.7904129028322, 15.8565292358399],
        [80.7904129028322, 15.856809616088867],
        [80.78986358642595, 15.856804847717399],
        [80.78986358642595, 15.85708332061779],
        [80.78958129882841, 15.857081413268986],
        [80.78958129882841, 15.857360839843864],
        [80.789306640625, 15.857360839843864],
        [80.789306640625, 15.857639312744254],
        [80.7890167236331, 15.857639312744254],
        [80.7890167236331, 15.857919692993221],
        [80.78847503662121, 15.857916831970329],
        [80.78847503662121, 15.85819530487072],
        [80.7881927490235, 15.85819530487072],
        [80.7881927490235, 15.858470916748047],
        [80.78763580322271, 15.858470916748047],
        [80.78763580322271, 15.858751296997184],
        [80.7873611450197, 15.858751296997184],
        [80.7873611450197, 15.85903167724615],
        [80.78679656982439, 15.85903167724615],
        [80.78679656982439, 15.859310150146541],
        [80.78652954101591, 15.859305381774902],
        [80.78652954101591, 15.859582901000977],
        [80.78624725341814, 15.859582901000977],
        [80.78624725341814, 15.859861373901367],
        [80.78597259521513, 15.859849929809627],
        [80.78597259521513, 15.860141754150504],
        [80.785140991211, 15.860141754150504],
        [80.785140991211, 15.859849929809627],
        [80.78430175781244, 15.859861373901367],
        [80.78430175781244, 15.859582901000977],
        [80.7840270996096, 15.859582901000977],
        [80.7840270996096, 15.859026908874512],
        [80.78375244140642, 15.85903167724615],
        [80.78375244140642, 15.858751296997184],
        [80.78347015380865, 15.858751296997184],
        [80.78347015380865, 15.858470916748047],
        [80.78234863281278, 15.858470916748047],
        [80.78234863281278, 15.858751296997184],
        [80.7818069458009, 15.858751296997184],
        [80.7818069458009, 15.85903167724615],
        [80.78151702880865, 15.85903167724615],
        [80.78151702880865, 15.859310150146541],
        [80.7809753417971, 15.859305381774902],
        [80.7809753417971, 15.859861373901367],
        [80.78069305419939, 15.859849929809627],
        [80.78069305419939, 15.860141754150504],
        [80.78041839599615, 15.860141754150504],
        [80.78041839599615, 15.860420227050895],
        [80.7798614501956, 15.860417366027832],
        [80.7798614501956, 15.860695838928223],
        [80.77958679199219, 15.860695838928223],
        [80.77958679199219, 15.860971450805721],
        [80.77928924560575, 15.860971450805721],
        [80.77928924560575, 15.861251831054688],
        [80.77902984619146, 15.861251831054688],
        [80.77902984619146, 15.861530303955078],
        [80.77874755859386, 15.861527442932186],
        [80.77874755859386, 15.862084388732967],
        [80.77847290039068, 15.862080574035758],
        [80.77847290039068, 15.862361907959041],
        [80.77791595459007, 15.862361907959041],
        [80.77791595459007, 15.862917900085506],
        [80.77764129638689, 15.862917900085506],
        [80.77764129638689, 15.863192558288517],
        [80.77735900878912, 15.863192558288517],
        [80.77735900878912, 15.863471984863395],
        [80.77680206298857, 15.863471984863395],
        [80.77680206298857, 15.863750457763786],
        [80.77654266357422, 15.863750457763786],
        [80.77654266357422, 15.864031791687069],
        [80.77625274658232, 15.864031791687069],
        [80.77625274658232, 15.864310264587402],
        [80.77597045898438, 15.86430644989025],
        [80.77597045898438, 15.864583969116325],
        [80.77541351318365, 15.864583969116325],
        [80.77541351318365, 15.864862442016715],
        [80.77485656738287, 15.864859580993652],
        [80.77485656738287, 15.865139961242619],
        [80.77458190917986, 15.865139961242619],
        [80.77458190917986, 15.865420341491756],
        [80.77430725097662, 15.865420341491756],
        [80.77430725097662, 15.865981101989746],
        [80.77401733398455, 15.865981101989746],
        [80.77401733398455, 15.866251945495549],
        [80.77208709716791, 15.866251945495549],
        [80.77208709716791, 15.865420341491756],
        [80.77179718017584, 15.865420341491756],
        [80.77179718017584, 15.865139961242619],
        [80.77153015136736, 15.865139961242619],
        [80.77153015136736, 15.864579200744686],
        [80.77124786376976, 15.864583969116325],
        [80.77124786376976, 15.86402797698986],
        [80.7706909179688, 15.864031791687069],
        [80.7706909179688, 15.863750457763786],
        [80.77014160156278, 15.863750457763786],
        [80.77014160156278, 15.863471984863395],
        [80.76930236816412, 15.863471984863395],
        [80.76930236816412, 15.863192558288517],
        [80.76875305175787, 15.863192558288517],
        [80.76875305175787, 15.863471984863395],
        [80.7684707641601, 15.863471984863395],
        [80.7684707641601, 15.863750457763786],
        [80.76790618896501, 15.863750457763786],
        [80.76790618896501, 15.864031791687069],
        [80.7676391601563, 15.864031791687069],
        [80.7676391601563, 15.864310264587402],
        [80.76708221435575, 15.86430644989025],
        [80.76708221435575, 15.864583969116325],
        [80.76680755615234, 15.864583969116325],
        [80.76680755615234, 15.864862442016715],
        [80.7665328979495, 15.864859580993652],
        [80.7665328979495, 15.865139961242619],
        [80.76625061035162, 15.865139961242619],
        [80.76625061035162, 15.865420341491756],
        [80.76486206054705, 15.865415573120117],
        [80.76486206054705, 15.865694046020508],
        [80.76346588134794, 15.86568927764904],
        [80.76346588134794, 15.865981101989746],
        [80.76291656494152, 15.865981101989746],
        [80.76291656494152, 15.866251945495549],
        [80.76235961914057, 15.866251945495549],
        [80.76235961914057, 15.86653041839594],
        [80.76180267334001, 15.86653041839594],
        [80.76180267334001, 15.866809844970817],
        [80.76069641113281, 15.866806030273438],
        [80.76069641113281, 15.867083549499569],
        [80.75985717773466, 15.867081642150936],
        [80.75986480712902, 15.867362022399902],
        [80.75903320312523, 15.867362022399902],
        [80.75903320312523, 15.867639541626033],
        [80.75846862792991, 15.867639541626033],
        [80.75846862792991, 15.867919921875],
        [80.75791931152372, 15.867918014526424],
        [80.75791931152372, 15.868193626403922],
        [80.757080078125, 15.868193626403922],
        [80.757080078125, 15.868472099304256],
        [80.7565307617188, 15.86847019195568],
        [80.7565307617188, 15.868749618530387],
        [80.7559661865235, 15.868749618530387],
        [80.7559661865235, 15.869038581848201],
        [80.75569152832026, 15.869038581848201],
        [80.75569152832026, 15.869311332702637],
        [80.75458526611345, 15.869305610656852],
        [80.75458526611345, 15.869584083557243],
        [80.7540283203125, 15.869580268859863],
        [80.7540283203125, 15.86985969543457],
        [80.75347137451178, 15.86985969543457],
        [80.75347137451178, 15.870140075683707],
        [80.75290679931646, 15.870140075683707],
        [80.75290679931646, 15.87042140960699],
        [80.75180816650396, 15.870415687561035],
        [80.75180816650396, 15.870694160461426],
        [80.75096893310547, 15.870694160461426],
        [80.75096893310547, 15.8709716796875],
        [80.74958038330072, 15.8709716796875],
        [80.74958038330072, 15.87125015258789],
        [80.7490310668947, 15.87125015258789],
        [80.7490310668947, 15.871530532837028],
        [80.74846649169939, 15.871530532837028],
        [80.74846649169939, 15.871810913085994],
        [80.74764251709013, 15.871806144714355],
        [80.74764251709013, 15.87208366394043],
        [80.74708557128918, 15.87208366394043],
        [80.74708557128918, 15.872360229492244],
        [80.7445831298831, 15.872360229492244],
        [80.7445831298831, 15.872639656066895],
        [80.74346923828136, 15.872639656066895],
        [80.74346923828136, 15.872920989990348],
        [80.74291992187517, 15.87291622161871],
        [80.74291992187517, 15.873193740844783],
        [80.74097442626982, 15.873193740844783],
        [80.74097442626982, 15.873472213745174],
        [80.74014282226568, 15.873470306396598],
        [80.74014282226568, 15.873749732971248],
        [80.73929595947283, 15.873749732971248],
        [80.73929595947283, 15.874031066894531],
        [80.73874664306658, 15.874031066894531],
        [80.73874664306658, 15.874311447143668],
        [80.73791503906278, 15.87430667877203],
        [80.73791503906278, 15.874582290649528],
        [80.73680877685541, 15.874582290649528],
        [80.73680877685541, 15.874859809875602],
        [80.73513793945312, 15.874851226806584],
        [80.73513793945312, 15.875141143798885],
        [80.73486328125028, 15.875141143798885],
        [80.73486328125028, 15.875420570373535],
        [80.73374938964838, 15.875415802001896],
        [80.73374938964838, 15.875695228576774],
        [80.73152923583984, 15.875690460205135],
        [80.73152923583984, 15.875972747802848],
        [80.72930908203153, 15.875972747802848],
        [80.72930908203153, 15.876251220703239],
        [80.72875213623075, 15.876251220703239],
        [80.72875213623075, 15.876530647277889],
        [80.72624969482428, 15.876530647277889],
        [80.72624969482428, 15.876811027526912],
        [80.72458648681652, 15.87680435180664],
        [80.72458648681652, 15.877084732055607],
        [80.72402954101557, 15.877084732055607],
        [80.72402954101557, 15.877361297607422],
        [80.72292327880876, 15.877361297607422],
        [80.72292327880876, 15.877640724182072],
        [80.72152709960949, 15.877640724182072],
        [80.72152709960949, 15.877921104431266],
        [80.72041320800798, 15.877917289733887],
        [80.72041320800798, 15.878194808959961],
        [80.71958160400419, 15.878194808959961],
        [80.71958160400419, 15.878473281860352],
        [80.71708679199224, 15.878471374511776],
        [80.71708679199224, 15.878750801086426],
        [80.71457672119146, 15.878750801086426],
        [80.71457672119146, 15.879031181335392],
        [80.7137527465822, 15.879031181335392],
        [80.7137527465822, 15.879311561584586],
        [80.71291351318388, 15.87930679321289],
        [80.71291351318388, 15.879583358764705],
        [80.7123565673831, 15.879579544067326],
        [80.7123565673831, 15.87986087799078],
        [80.71180725097668, 15.87986087799078],
        [80.71180725097668, 15.880141258239746],
        [80.70764160156267, 15.880141258239746],
        [80.70764160156267, 15.880420684814453],
        [80.70652770996094, 15.880416870117244],
        [80.70652770996094, 15.880695343017635],
        [80.70347595214844, 15.88068962097168],
        [80.70347595214844, 15.88097953796398],
        [80.70041656494158, 15.88097953796398],
        [80.70041656494158, 15.8812513351441],
        [80.69902801513683, 15.8812513351441],
        [80.69902801513683, 15.881530761718807],
        [80.69235992431635, 15.881530761718807],
        [80.69235992431635, 15.8812513351441],
        [80.68985748291044, 15.8812513351441],
        [80.68985748291044, 15.881530761718807],
        [80.6884689331057, 15.881530761718807],
        [80.6884689331057, 15.881809234619197],
        [80.68819427490251, 15.881805419921818],
        [80.68819427490251, 15.882082939147892],
        [80.6879196166995, 15.882082939147892],
        [80.6879196166995, 15.882361412048283],
        [80.68763732910173, 15.882361412048283],
        [80.68763732910173, 15.88264083862316],
        [80.6868133544923, 15.88264083862316],
        [80.6868133544923, 15.882361412048283],
        [80.68653106689459, 15.882361412048283],
        [80.68653106689459, 15.882082939147892],
        [80.6859741210938, 15.882082939147892],
        [80.6859741210938, 15.881805419921818],
        [80.68569183349604, 15.881809234619197],
        [80.68569183349604, 15.881530761718807],
        [80.68374633789091, 15.881530761718807],
        [80.68374633789091, 15.8812513351441],
        [80.68125152587919, 15.8812513351441],
        [80.68125152587919, 15.88097953796398],
        [80.6795806884765, 15.88097953796398],
        [80.6795806884765, 15.88068962097168],
        [80.67819213867216, 15.880695343017635],
        [80.67819213867216, 15.880416870117244],
        [80.67569732666021, 15.880420684814453],
        [80.67569732666021, 15.880141258239746],
        [80.6690292358399, 15.880141258239746],
        [80.6690292358399, 15.87986087799078],
        [80.6681900024414, 15.87986087799078],
        [80.6681900024414, 15.879579544067326],
        [80.66236114501982, 15.879583358764705],
        [80.66236114501982, 15.87930679321289],
        [80.6584701538086, 15.879311561584586],
        [80.6584701538086, 15.879031181335392],
        [80.65651702880876, 15.879031181335392],
        [80.65651702880876, 15.878750801086426],
        [80.6545867919923, 15.878750801086426],
        [80.6545867919923, 15.878471374511776],
        [80.652641296387, 15.878473281860352],
        [80.652641296387, 15.878194808959961],
        [80.64930725097656, 15.878194808959961],
        [80.64930725097656, 15.877917289733887],
        [80.64707946777366, 15.877921104431266],
        [80.64707946777366, 15.877640724182072],
        [80.64402008056663, 15.877640724182072],
        [80.64402008056663, 15.877361297607422],
        [80.64347076416021, 15.877361297607422],
        [80.64347076416021, 15.877084732055607],
        [80.6431961059572, 15.877084732055607],
        [80.6431961059572, 15.87680435180664],
        [80.6412506103515, 15.876811027526912],
        [80.6412506103515, 15.876530647277889],
        [80.64013671875017, 15.876530647277889],
        [80.64013671875017, 15.876251220703239],
        [80.63903045654303, 15.876251220703239],
        [80.63903045654303, 15.875972747802848],
        [80.63874816894543, 15.875972747802848],
        [80.63874816894543, 15.875690460205135],
        [80.63847351074224, 15.875695228576774],
        [80.63847351074224, 15.875415802001896],
        [80.63625335693388, 15.875420570373535],
        [80.63625335693388, 15.875141143798885],
        [80.63540649414068, 15.875141143798885],
        [80.63540649414068, 15.874851226806584],
        [80.63319396972685, 15.874859809875602],
        [80.63319396972685, 15.874582290649528],
        [80.63208007812511, 15.874582290649528],
        [80.63208007812511, 15.87430667877203],
        [80.62847137451166, 15.874311447143668],
        [80.62847137451166, 15.874031066894531],
        [80.62652587890653, 15.874031066894531],
        [80.62652587890653, 15.873749732971248],
        [80.62486267089861, 15.873749732971248],
        [80.62486267089861, 15.873470306396598],
        [80.62347412109403, 15.873472213745174],
        [80.62347412109403, 15.873193740844783],
        [80.620414733887, 15.873193740844783],
        [80.620414733887, 15.87291622161871],
        [80.61846923828148, 15.872920989990348],
        [80.61846923828148, 15.872639656066895],
        [80.61680603027372, 15.872639656066895],
        [80.61680603027372, 15.872360229492244],
        [80.61597442626959, 15.872360229492244],
        [80.61597442626959, 15.87208366394043],
        [80.61347198486334, 15.87208366394043],
        [80.61347198486334, 15.871806144714355],
        [80.61264038085932, 15.871810913085994],
        [80.61264038085932, 15.871530532837028],
        [80.61013793945324, 15.871530532837028],
        [80.61013793945324, 15.87125015258789],
        [80.60930633544945, 15.87125015258789],
        [80.60930633544945, 15.8709716796875],
        [80.60847473144548, 15.8709716796875],
        [80.60847473144548, 15.870694160461426],
        [80.60708618164074, 15.870694160461426],
        [80.60708618164074, 15.870415687561035],
        [80.60624694824224, 15.87042140960699],
        [80.60624694824224, 15.870140075683707],
        [80.60542297363298, 15.870140075683707],
        [80.60542297363298, 15.86985969543457],
        [80.60319519042974, 15.86985969543457],
        [80.60319519042974, 15.869584083557243],
        [80.60124969482422, 15.869584083557243],
        [80.60124969482422, 15.869305610656852],
        [80.59958648681663, 15.869311332702637],
        [80.59958648681663, 15.869038581848201],
        [80.59874725341814, 15.869038581848201],
        [80.59874725341814, 15.868749618530387],
        [80.597640991211, 15.868749618530387],
        [80.597640991211, 15.86847019195568],
        [80.59597015380865, 15.868472099304256],
        [80.59597015380865, 15.868193626403922],
        [80.59486389160185, 15.868193626403922],
        [80.59486389160185, 15.867918014526424],
        [80.5945816040039, 15.867919921875],
        [80.5945816040039, 15.867639541626033],
        [80.5923614501956, 15.867639541626033],
        [80.5923614501956, 15.867362022399902],
        [80.59124755859403, 15.867362022399902],
        [80.59124755859403, 15.867081642150936],
        [80.5904159545899, 15.867083549499569],
        [80.5904159545899, 15.866806030273438],
        [80.58930206298834, 15.866809844970817],
        [80.58930206298834, 15.86653041839594],
        [80.58875274658232, 15.86653041839594],
        [80.58875274658232, 15.866251945495549],
        [80.58790588378906, 15.866251945495549],
        [80.58790588378906, 15.865981101989746],
        [80.58763885498058, 15.865981101989746],
        [80.58763885498058, 15.86568927764904],
        [80.58569335937528, 15.865694046020508],
        [80.58569335937528, 15.865415573120117],
        [80.58486175537115, 15.865415573120117],
        [80.58458709716808, 15.865420341491756],
        [80.58458709716808, 15.865139961242619],
        [80.58403015136713, 15.865139961242619],
        [80.58403015136713, 15.864859580993652],
        [80.58264160156278, 15.864862442016715],
        [80.58264160156278, 15.864583969116325],
        [80.58152770996105, 15.864583969116325],
        [80.58152770996105, 15.86430644989025],
        [80.5809707641601, 15.864310264587402],
        [80.5809707641601, 15.864031791687069],
        [80.58069610595726, 15.864031791687069],
        [80.58069610595726, 15.863750457763786],
        [80.57847595214855, 15.863750457763786],
        [80.57847595214855, 15.863471984863395],
        [80.57763671875006, 15.863471984863395],
        [80.57763671875006, 15.863192558288517],
        [80.57653045654325, 15.863192558288517],
        [80.57653045654325, 15.862917900085506],
        [80.57597351074247, 15.862917900085506],
        [80.57597351074247, 15.862636566162053],
        [80.57402801513695, 15.862640380859432],
        [80.57402801513695, 15.862361907959041],
        [80.57319641113298, 15.862361907959041],
        [80.57319641113298, 15.862080574035758],
        [80.57236480712919, 15.862084388732967],
        [80.57236480712919, 15.86180496215826],
        [80.57153320312506, 15.861809730529728],
        [80.57153320312506, 15.861530303955078],
        [80.56986236572294, 15.861530303955078],
        [80.56986236572294, 15.861251831054688],
        [80.569580078125, 15.861251831054688],
        [80.569580078125, 15.860971450805721],
        [80.5690307617188, 15.860971450805721],
        [80.5690307617188, 15.860695838928223],
        [80.56680297851591, 15.860695838928223],
        [80.56680297851591, 15.860417366027832],
        [80.56624603271513, 15.860420227050895],
        [80.56624603271513, 15.860141754150504],
        [80.56569671630871, 15.860141754150504],
        [80.56569671630871, 15.859849929809627],
        [80.56430816650396, 15.859861373901367],
        [80.56430816650396, 15.859582901000977],
        [80.56375122070341, 15.859582901000977],
        [80.56375122070341, 15.859305381774902],
        [80.5629272460938, 15.859310150146541],
        [80.5629272460938, 15.85903167724615],
        [80.56181335449241, 15.85903167724615],
        [80.56181335449241, 15.858751296997184],
        [80.56096649169939, 15.858751296997184],
        [80.56096649169939, 15.858470916748047],
        [80.56014251709013, 15.858470916748047],
        [80.56014251709013, 15.85819530487072],
        [80.55986022949219, 15.85819530487072],
        [80.55986022949219, 15.857916831970329],
        [80.55847167968744, 15.857919692993221],
        [80.55847167968744, 15.857639312744254],
        [80.55790710449236, 15.857639312744254],
        [80.55790710449236, 15.857360839843864],
        [80.55735778808611, 15.857360839843864],
        [80.55735778808611, 15.857081413268986],
        [80.55625152587896, 15.85708332061779],
        [80.55625152587896, 15.856804847717399],
        [80.55568695068365, 15.856809616088867],
        [80.55568695068365, 15.8565292358399],
        [80.55458068847662, 15.8565292358399],
        [80.55458068847662, 15.85625076293951],
        [80.55430603027361, 15.85625076293951],
        [80.55430603027361, 15.855971336364803],
        [80.5540313720706, 15.855973243713436],
        [80.5540313720706, 15.855694770813045],
        [80.55347442626982, 15.855694770813045],
        [80.55347442626982, 15.855417251586914],
        [80.55208587646507, 15.85542201995861],
        [80.55208587646507, 15.855140686035156],
        [80.55069732666016, 15.855140686035156],
        [80.55069732666016, 15.85486125946045],
        [80.54958343505865, 15.85486125946045],
        [80.54958343505865, 15.854582786560059],
        [80.54902648925787, 15.854582786560059],
        [80.54902648925787, 15.854305267333984],
        [80.54875183105486, 15.854311943054256],
        [80.54875183105486, 15.85404014587408],
        [80.54818725585955, 15.85404014587408],
        [80.54818725585955, 15.853751182556096],
        [80.54792022705107, 15.853751182556096],
        [80.54792022705107, 15.853470802307129],
        [80.5476379394533, 15.853470802307129],
        [80.5476379394533, 15.85319519042963],
        [80.54624938964855, 15.85319519042963],
        [80.54624938964855, 15.85291671752924],
        [80.5456924438476, 15.85292053222662],
        [80.5456924438476, 15.852641105651912],
        [80.54514312744158, 15.852641105651912],
        [80.54514312744158, 15.852360725402775],
        [80.54291534423834, 15.852360725402775],
        [80.54291534423834, 15.852083206176871],
        [80.54152679443376, 15.852080345153809],
        [80.54152679443376, 15.852360725402775],
        [80.54096984863281, 15.852360725402775],
        [80.54096984863281, 15.852528572082633],
        [80.54096984863281, 15.852641105651912],
        [80.5407028198245, 15.852641105651912],
        [80.5407028198245, 15.85292053222662],
        [80.54041290283203, 15.85291671752924],
        [80.54041290283203, 15.85319519042963],
        [80.53986358642584, 15.85319519042963],
        [80.53986358642584, 15.853470802307129],
        [80.53958129882807, 15.853470802307129],
        [80.53958129882807, 15.85404014587408],
        [80.53930664062523, 15.85404014587408],
        [80.53930664062523, 15.854311943054256],
        [80.53874969482428, 15.854305267333984],
        [80.53874969482428, 15.854582786560059],
        [80.53847503662126, 15.854582786560059],
        [80.53847503662126, 15.85486125946045],
        [80.53736114501953, 15.85486125946045],
        [80.53736114501953, 15.854582786560059],
        [80.53652954101557, 15.854582786560059],
        [80.53652954101557, 15.854305267333984],
        [80.53597259521501, 15.854311943054256],
        [80.53597259521501, 15.85404014587408],
        [80.53569030761741, 15.85404014587408],
        [80.53569030761741, 15.853751182556096],
        [80.53514099121122, 15.853751182556096],
        [80.53514099121122, 15.853470802307129],
        [80.53458404541044, 15.853470802307129],
        [80.53458404541044, 15.85319519042963],
        [80.5343017578125, 15.85319519042963],
        [80.5343017578125, 15.85291671752924],
        [80.53402709960966, 15.85292053222662],
        [80.53402709960966, 15.852641105651912],
        [80.5337524414063, 15.852641105651912],
        [80.5337524414063, 15.852360725402775],
        [80.53347015380871, 15.852360725402775],
        [80.53347015380871, 15.852080345153809],
        [80.53319549560553, 15.852083206176871],
        [80.53319549560553, 15.85180473327648],
        [80.53291320800776, 15.851811408996582],
        [80.53291320800776, 15.851531028747559],
        [80.53263092041038, 15.851531028747559],
        [80.53263092041038, 15.851250648498592],
        [80.53098297119152, 15.851250648498592],
        [80.53098297119152, 15.851531028747559],
        [80.53069305419928, 15.851531028747559],
        [80.53069305419928, 15.851811408996582],
        [80.53041839599621, 15.85180473327648],
        [80.53041839599621, 15.852083206176871],
        [80.52986145019526, 15.852083206176871],
        [80.52986145019526, 15.85180473327648],
        [80.52958679199241, 15.851811408996582],
        [80.52958679199241, 15.851250648498592],
        [80.52986145019526, 15.851250648498592],
        [80.52986145019526, 15.850970268249625],
        [80.5301361083985, 15.850973129272518],
        [80.5301361083985, 15.850694656372127],
        [80.53041839599621, 15.850694656372127],
        [80.53041839599621, 15.850417137146053],
        [80.53098297119152, 15.850420951843205],
        [80.53098297119152, 15.850140571594238],
        [80.53125, 15.850140571594238],
        [80.53125, 15.849861145019588],
        [80.53152465820341, 15.849861145019588],
        [80.53152465820341, 15.849584579467773],
        [80.53180694580095, 15.849584579467773],
        [80.53180694580095, 15.849304199218807],
        [80.5323562622072, 15.849310874939079],
        [80.5323562622072, 15.849031448364371],
        [80.53275299072283, 15.849028587341309],
        [80.53347015380871, 15.849031448364371],
        [80.53347015380871, 15.848751068115234],
        [80.53320312500023, 15.848751068115234],
        [80.53320312500023, 15.848469734191951],
        [80.53263854980491, 15.848472595214844],
        [80.53263854980491, 15.848194122314453],
        [80.53236389160173, 15.848194122314453],
        [80.53236389160173, 15.847915649414062],
        [80.53208160400396, 15.847915649414062],
        [80.53208160400396, 15.847638130187988],
        [80.53125, 15.847640991211051],
        [80.53125, 15.847359657287598],
        [80.53000640869169, 15.847363471984977],
        [80.5293045043947, 15.847363471984977],
        [80.5293045043947, 15.847082138061523],
        [80.52902984619146, 15.847082138061523],
        [80.52902984619146, 15.846805572509709],
        [80.52874755859392, 15.846811294555664],
        [80.52874755859392, 15.846530914306697],
        [80.5281982421875, 15.846530914306697],
        [80.52819061279297, 15.846249580383414],
        [80.52707672119146, 15.846249580383414],
        [80.52707672119146, 15.845970153808594],
        [80.52486419677763, 15.84597206115734],
        [80.52486419677763, 15.84569358825695],
        [80.52458190917969, 15.84569358825695],
        [80.52458190917969, 15.845416069030875],
        [80.52401733398443, 15.845420837402344],
        [80.52401733398443, 15.845140457153377],
        [80.52375030517572, 15.845140457153377],
        [80.52375030517572, 15.844850540161076],
        [80.52291870117193, 15.84486007690441],
        [80.52291870117193, 15.844584465026912],
        [80.5218048095706, 15.844584465026912],
        [80.5218048095706, 15.844305992126522],
        [80.52097320556646, 15.84431076049816],
        [80.52097320556646, 15.844030380249023],
        [80.52041625976568, 15.844030380249023],
        [80.52041625976568, 15.84375],
        [80.51985931396507, 15.84375],
        [80.51985931396507, 15.84346961975109],
        [80.51958465576189, 15.843471527099666],
        [80.51958465576189, 15.843194007873592],
        [80.5187530517581, 15.843194007873592],
        [80.5187530517581, 15.842915534973201],
        [80.51764678955072, 15.842921257019157],
        [80.51764678955072, 15.842639923095703],
        [80.51735687255865, 15.842639923095703],
        [80.51735687255865, 15.842359542846737],
        [80.51652526855486, 15.842362403869572],
        [80.51652526855486, 15.842083930969238],
        [80.51597595214861, 15.842083930969238],
        [80.51597595214861, 15.841806411743107],
        [80.51486206054693, 15.841811180114803],
        [80.51486206054693, 15.84152984619152],
        [80.51403045654291, 15.84152984619152],
        [80.51403045654291, 15.841250419616642],
        [80.51264190673857, 15.841250419616642],
        [80.51264190673857, 15.840971946716252],
        [80.51208496093778, 15.840971946716252],
        [80.51208496093778, 15.840694427490348],
        [80.51158905029314, 15.840694427490348],
        [80.51097106933588, 15.840694427490348],
        [80.51097106933588, 15.840415954589787],
        [80.51069641113304, 15.840419769287166],
        [80.51069641113304, 15.84014034271246],
        [80.50875091552734, 15.84014034271246],
        [80.50875091552734, 15.839861869812069],
        [80.50791931152338, 15.839861869812069],
        [80.50791931152338, 15.839582443237418],
        [80.50736236572283, 15.839584350585994],
        [80.50736236572283, 15.839305877685604],
        [80.50681304931658, 15.839309692382812],
        [80.50681304931658, 15.839030265808105],
        [80.50653076171903, 15.839030265808105],
        [80.50653076171903, 15.838751792907715],
        [80.50624847412126, 15.838751792907715],
        [80.50624847412126, 15.838468551635685],
        [80.50569152832031, 15.838472366333065],
        [80.50569152832031, 15.83819580078125],
        [80.50458526611334, 15.83819580078125],
        [80.50458526611334, 15.8379163742066],
        [80.50374603271501, 15.837920188903752],
        [80.50374603271501, 15.837639808654785],
        [80.50235748291044, 15.837639808654785],
        [80.50235748291044, 15.837359428405819],
        [80.50125122070307, 15.837362289428711],
        [80.50125122070307, 15.83708381652832],
        [80.50069427490251, 15.83708381652832],
        [80.50069427490251, 15.836806297302246],
        [80.50041961669928, 15.836810111999569],
        [80.50041961669928, 15.836529731750431],
        [80.50013732910173, 15.836529731750431],
        [80.50013732910173, 15.836250305175781],
        [80.49903106689459, 15.836250305175781],
        [80.49903106689459, 15.835970878601074],
        [80.4984741210938, 15.835970878601074],
        [80.4984741210938, 15.835693359375],
        [80.4979171752932, 15.835693359375],
        [80.4979171752932, 15.835417747497672],
        [80.49764251709001, 15.835419654846248],
        [80.49764251709001, 15.835140228271598],
        [80.49680328369169, 15.835140228271598],
        [80.49680328369169, 15.834861755371207],
        [80.4959716796875, 15.834861755371207],
        [80.4959716796875, 15.83458137512207],
        [80.49541473388678, 15.834583282470817],
        [80.49541473388678, 15.834304809570426],
        [80.49430847167974, 15.834309577941895],
        [80.49430847167974, 15.834030151367244],
        [80.49375152587919, 15.834030151367244],
        [80.49375152587919, 15.833751678466854],
        [80.49346160888689, 15.833751678466854],
        [80.49346160888689, 15.833471298217887],
        [80.492919921875, 15.833471298217887],
        [80.492919921875, 15.833194732666072],
        [80.49236297607428, 15.833194732666072],
        [80.49236297607428, 15.832915306091422],
        [80.49124908447288, 15.832930564880485],
        [80.49124908447288, 15.8326416015625],
        [80.49069213867193, 15.8326416015625],
        [80.49069213867193, 15.832361221313533],
        [80.48958587646513, 15.832361221313533],
        [80.48958587646513, 15.832082748413143],
        [80.48930358886724, 15.832082748413143],
        [80.48930358886724, 15.831805229187069],
        [80.48874664306646, 15.831811904907283],
        [80.48874664306646, 15.831531524658317],
        [80.48764038085943, 15.831531524658317],
        [80.48764038085943, 15.831249237060604],
        [80.48707580566435, 15.831249237060604],
        [80.48707580566435, 15.830970764160213],
        [80.48680877685547, 15.830972671508789],
        [80.48680877685547, 15.830695152282715],
        [80.48596954345714, 15.830695152282715],
        [80.48596954345714, 15.830416679382324],
        [80.48568725585943, 15.83041858673107],
        [80.48568725585943, 15.830141067504996],
        [80.48486328125017, 15.830141067504996],
        [80.48486328125017, 15.829870223999137],
        [80.48401641845709, 15.829870223999137],
        [80.48401641845709, 15.829581260681152],
        [80.4834747314456, 15.829583168029728],
        [80.4834747314456, 15.829304695129395],
        [80.48319244384766, 15.829310417175293],
        [80.48319244384766, 15.829030990600643],
        [80.4823608398437, 15.829030990600643],
        [80.4823608398437, 15.828750610351676],
        [80.48179626464838, 15.828750610351676],
        [80.48179626464838, 15.828471183776799],
        [80.48097229003935, 15.828473091125602],
        [80.48097229003935, 15.828194618225211],
        [80.48014068603516, 15.828194618225211],
        [80.48014068603516, 15.82791709899908],
        [80.4793090820312, 15.827921867370605],
        [80.4793090820312, 15.827640533447322],
        [80.47902679443365, 15.827640533447322],
        [80.47902679443365, 15.827361106872615],
        [80.47875213623064, 15.827361106872615],
        [80.47875213623064, 15.827082633972225],
        [80.47846984863304, 15.827082633972225],
        [80.47846984863304, 15.826251029968262],
        [80.47820281982439, 15.826251029968262],
        [80.47820281982439, 15.825970649719295],
        [80.47763824462908, 15.825970649719295],
        [80.47763824462908, 15.826251029968262],
        [80.47708129882812, 15.826251029968262],
        [80.47708129882812, 15.826531410217228],
        [80.47598266601585, 15.826531410217228],
        [80.47598266601585, 15.826251029968262],
        [80.47486114501959, 15.826251029968262],
        [80.47486114501959, 15.825970649719295],
        [80.47430419921903, 15.825972557067871],
        [80.47430419921903, 15.825695037841797],
        [80.47402954101562, 15.825695037841797],
        [80.47402954101562, 15.825416564941406],
        [80.47264099121088, 15.825421333313102],
        [80.47264099121088, 15.825140953063908],
        [80.47180175781256, 15.825140953063908],
        [80.47180175781256, 15.824860572814941],
        [80.47125244140653, 15.824860572814941],
        [80.47125244140653, 15.824583053588867],
        [80.4709701538086, 15.824583053588867],
        [80.4709701538086, 15.824304580688477],
        [80.47070312500028, 15.824311256408748],
        [80.47070312500028, 15.824030876159725],
        [80.47041320800781, 15.824030876159725],
        [80.47041320800781, 15.82374191284174],
        [80.4701385498048, 15.823747634887695],
        [80.4701385498048, 15.823472976684684],
        [80.46986389160162, 15.823472976684684],
        [80.46986389160162, 15.82319545745861],
        [80.46847534179705, 15.82319545745861],
        [80.46847534179705, 15.82291698455822],
        [80.4679183959961, 15.822920799255371],
        [80.4679183959961, 15.822641372680721],
        [80.46763610839872, 15.822641372680721],
        [80.46763610839872, 15.822360038757438],
        [80.4670867919923, 15.822360038757438],
        [80.4670867919923, 15.8220796585083],
        [80.46680450439459, 15.822081565856877],
        [80.46680450439459, 15.821806907653865],
        [80.4659729003908, 15.821810722351017],
        [80.4659729003908, 15.821531295776367],
        [80.465141296387, 15.821531295776367],
        [80.465141296387, 15.8212509155274],
        [80.46402740478544, 15.8212509155274],
        [80.46402740478544, 15.820969581604118],
        [80.46375274658209, 15.82097244262701],
        [80.46375274658209, 15.82069396972662],
        [80.46347808837885, 15.820680618286133],
        [80.46347045898449, 15.820416450500545],
        [80.4631958007813, 15.820421218872184],
        [80.4631958007813, 15.820142745971793],
        [80.4626388549807, 15.820142745971793],
        [80.4626388549807, 15.81986045837408],
        [80.46208190917974, 15.819863319396973],
        [80.46208190917974, 15.81958198547369],
        [80.46180725097673, 15.81958198547369],
        [80.46180725097673, 15.819306373596191],
        [80.46041107177746, 15.81931114196783],
        [80.46041107177746, 15.819030761718864],
        [80.46013641357428, 15.819030761718864],
        [80.46013641357428, 15.818750381469727],
        [80.45986175537104, 15.818750381469727],
        [80.45986175537104, 15.81847000122076],
        [80.45930480957048, 15.818471908569336],
        [80.45930480957048, 15.818194389343262],
        [80.45847320556669, 15.818194389343262],
        [80.45847320556669, 15.817915916442871],
        [80.45764160156256, 15.817930221557617],
        [80.45764160156256, 15.817640304565543],
        [80.456802368164, 15.817640304565543],
        [80.456802368164, 15.817359924316406],
        [80.45625305175798, 15.817359924316406],
        [80.45625305175798, 15.817079544067383],
        [80.4556961059572, 15.817084312439079],
        [80.4556961059572, 15.816805839538688],
        [80.4554061889649, 15.816810607910156],
        [80.4554061889649, 15.81653022766119],
        [80.45485687255888, 15.81653022766119],
        [80.45485687255888, 15.816249847412223],
        [80.4531860351562, 15.816249847412223],
        [80.4531860351562, 15.815970420837516],
        [80.45291900634771, 15.815972328186092],
        [80.45291900634771, 15.815693855285758],
        [80.45263671875017, 15.815693855285758],
        [80.45263671875017, 15.815416336059627],
        [80.45153808593756, 15.815420150756836],
        [80.45153808593756, 15.815141677856445],
        [80.45096588134771, 15.815141677856445],
        [80.45096588134771, 15.814870834350586],
        [80.45041656494146, 15.814870834350586],
        [80.45041656494146, 15.814581871032772],
        [80.45014190673845, 15.814583778381404],
        [80.45014190673845, 15.814306259155273],
        [80.44957733154314, 15.81431102752697],
        [80.44957733154314, 15.814029693603516],
        [80.4493026733399, 15.814029693603516],
        [80.4493026733399, 15.813750267028809],
        [80.44902801513689, 15.813750267028809],
        [80.44902801513689, 15.813471794128418],
        [80.44875335693388, 15.813471794128418],
        [80.44875335693388, 15.813192367553768],
        [80.44763946533214, 15.813194274902344],
        [80.44763946533214, 15.812915802001953],
        [80.44680786132835, 15.812919616699332],
        [80.44680786132835, 15.812640190124455],
        [80.4462509155274, 15.812640190124455],
        [80.4462509155274, 15.812361717224064],
        [80.44568634033232, 15.812361717224064],
        [80.44568634033232, 15.812079429626522],
        [80.44541931152344, 15.81208419799816],
        [80.44541931152344, 15.8118057250976],
        [80.44486236572271, 15.8118057250976],
        [80.44486236572271, 15.811528205871696],
        [80.44431304931646, 15.811530113220272],
        [80.44431304931646, 15.811249732971305],
        [80.44374847412132, 15.811249732971305],
        [80.44374847412132, 15.810969352722168],
        [80.44319152832037, 15.81097221374523],
        [80.44319152832037, 15.81069374084484],
        [80.44291687011736, 15.81069374084484],
        [80.44291687011736, 15.810416221618766],
        [80.4420928955081, 15.810420036315918],
        [80.4420928955081, 15.810139656066951],
        [80.4412460327149, 15.810139656066951],
        [80.4412460327149, 15.809859275817985],
        [80.44097137451166, 15.809862136840877],
        [80.44097137451166, 15.809583663940487],
        [80.44013977050787, 15.809583663940487],
        [80.44013977050787, 15.809306144714412],
        [80.43985748291033, 15.809309959411564],
        [80.43985748291033, 15.809029579162598],
        [80.4393081665039, 15.809029579162598],
        [80.4393081665039, 15.808739662170524],
        [80.43902587890653, 15.808749198913631],
        [80.43902587890653, 15.808473587036133],
        [80.43846893310575, 15.808473587036133],
        [80.43846893310575, 15.808193206787166],
        [80.43736267089861, 15.808193206787166],
        [80.43736267089861, 15.807917594909668],
        [80.43681335449236, 15.80792045593273],
        [80.43681335449236, 15.80764198303234],
        [80.43624114990251, 15.80764198303234],
        [80.43624114990251, 15.807360649108887],
        [80.43597412109403, 15.807360649108887],
        [80.4359664916995, 15.807081222534237],
        [80.43541717529325, 15.807083129882812],
        [80.43541717529325, 15.806804656982422],
        [80.4351425170899, 15.806810379028377],
        [80.4351425170899, 15.806531906127987],
        [80.43347167968756, 15.806531906127987],
        [80.43347167968756, 15.80625152587902],
        [80.43319702148455, 15.80625152587902],
        [80.43319702148455, 15.805971145629883],
        [80.4326400756836, 15.805971145629883],
        [80.4326400756836, 15.805694580078239],
        [80.43096923828148, 15.805700302124023],
        [80.43096923828148, 15.805421829223633],
        [80.43068695068376, 15.805421829223633],
        [80.43068695068376, 15.805142402648983],
        [80.43042755126982, 15.805142402648983],
        [80.43042755126982, 15.8048610687257],
        [80.43013763427751, 15.8048610687257],
        [80.43013763427751, 15.804580688476562],
        [80.4298629760745, 15.804582595825309],
        [80.4298629760745, 15.804305076599235],
        [80.42958068847656, 15.80431175231945],
        [80.42958068847656, 15.804029464721737],
        [80.42930603027372, 15.804029464721737],
        [80.42930603027372, 15.803750991821346],
        [80.42903137207037, 15.803750991821346],
        [80.42903137207037, 15.80347061157238],
        [80.42874908447277, 15.803473472595272],
        [80.42874908447277, 15.803193092346248],
        [80.42830657959007, 15.803193092346248],
        [80.42819213867182, 15.803193092346248],
        [80.42819213867182, 15.80291652679449],
        [80.42791748046898, 15.802930831909237],
        [80.42791748046898, 15.802640914916992],
        [80.42736816406256, 15.802640914916992],
        [80.42736816406256, 15.802360534668026],
        [80.42680358886747, 15.802360534668026],
        [80.42680358886747, 15.802083015441895],
        [80.42652893066406, 15.802083015441895],
        [80.42652893066406, 15.80180454254156],
        [80.42624664306669, 15.80181026458746],
        [80.42624664306669, 15.801530838012809],
        [80.42597198486328, 15.801530838012809],
        [80.42597198486328, 15.801251411437931],
        [80.42514038085932, 15.801251411437931],
        [80.42514038085932, 15.800971031188965],
        [80.42458343505876, 15.80097293853754],
        [80.42458343505876, 15.800695419311467],
        [80.42430877685553, 15.800695419311467],
        [80.42430877685553, 15.800416946411076],
        [80.42375183105497, 15.800421714782772],
        [80.42375183105497, 15.800141334533748],
        [80.4234695434572, 15.800141334533748],
        [80.4234695434572, 15.799860954284611],
        [80.42236328125006, 15.799860954284611],
        [80.42236328125006, 15.799571037292537],
        [80.42180633544928, 15.799583435058707],
        [80.42180633544928, 15.799306869506893],
        [80.42151641845697, 15.799311637878418],
        [80.42151641845697, 15.799031257629395],
        [80.42124176025413, 15.799031257629395],
        [80.42124176025413, 15.798750877380428],
        [80.42096710205095, 15.798750877380428],
        [80.42096710205095, 15.798471450805778],
        [80.41986083984375, 15.798473358154354],
        [80.41986083984375, 15.798194885253963],
        [80.41902923583996, 15.798194885253963],
        [80.41902923583996, 15.797917366027889],
        [80.41874694824224, 15.79792118072504],
        [80.41874694824224, 15.797640800476074],
        [80.41819763183616, 15.797640800476074],
        [80.41819763183616, 15.797361373901424],
        [80.41791534423845, 15.797361373901424],
        [80.41791534423845, 15.797082901001033],
        [80.41708374023466, 15.797082901001033],
        [80.41708374023466, 15.79680538177496],
        [80.41680908203125, 15.79680538177496],
        [80.41680908203125, 15.796528816223145],
        [80.41625213623053, 15.79653072357172],
        [80.41625213623053, 15.79625129699707],
        [80.41541290283197, 15.79625129699707],
        [80.41541290283197, 15.795969963073787],
        [80.41486358642595, 15.79597282409668],
        [80.41486358642595, 15.795695304870605],
        [80.41458129882818, 15.795695304870605],
        [80.41458129882818, 15.795416831970215],
        [80.41401672363287, 15.795420646667537],
        [80.41401672363287, 15.795141220092887],
        [80.41374969482422, 15.795141220092887],
        [80.41374969482422, 15.794862747192496],
        [80.41291809082043, 15.794862747192496],
        [80.41291809082043, 15.794580459594783],
        [80.41236114501947, 15.79458236694336],
        [80.41236114501947, 15.794306755066032],
        [80.41179656982416, 15.794310569763184],
        [80.41179656982416, 15.794031143188533],
        [80.41152954101568, 15.794031143188533],
        [80.41152954101568, 15.793760299682674],
        [80.41097259521513, 15.793760299682674],
        [80.41097259521513, 15.79347038269043],
        [80.41014099121094, 15.793472290039176],
        [80.41014099121094, 15.793193817138786],
        [80.40985870361357, 15.793193817138786],
        [80.40985870361357, 15.792916297912711],
        [80.40957641601568, 15.79292106628418],
        [80.40957641601568, 15.792640686035213],
        [80.40930175781261, 15.792640686035213],
        [80.40930175781261, 15.792360305786246],
        [80.40875244140642, 15.792360305786246],
        [80.40875244140642, 15.792083740234432],
        [80.40847015380882, 15.792083740234432],
        [80.40847015380882, 15.791806221008358],
        [80.40820312500017, 15.791810989379996],
        [80.40820312500017, 15.79153060913086],
        [80.40763854980486, 15.79153060913086],
        [80.40763854980486, 15.791250228881893],
        [80.40680694580107, 15.791250228881893],
        [80.40680694580107, 15.790969848632926],
        [80.40625000000011, 15.790971755981502],
        [80.40625000000011, 15.790694236755428],
        [80.40569305419916, 15.790700912475643],
        [80.40569305419916, 15.790420532226676],
        [80.40541839599632, 15.790420532226676],
        [80.40541839599632, 15.790140151977539],
        [80.40513610839861, 15.790140151977539],
        [80.40513610839861, 15.789861679077148],
        [80.40486145019537, 15.789861679077148],
        [80.40486145019537, 15.789580345153865],
        [80.4040298461914, 15.789584159851074],
        [80.4040298461914, 15.789305686950684],
        [80.40347290039068, 15.789311408996639],
        [80.40347290039068, 15.789030075073356],
        [80.40319824218761, 15.789030075073356],
        [80.40319824218761, 15.788749694824219],
        [80.4029159545899, 15.788749694824219],
        [80.4029159545899, 15.788470268249512],
        [80.40235900878929, 15.788472175598088],
        [80.40235900878929, 15.788193702697697],
        [80.40180206298834, 15.788193702697697],
        [80.40180206298834, 15.787918090820426],
        [80.40152740478533, 15.787919998169002],
        [80.40152740478533, 15.787631034851188],
        [80.40097045898438, 15.787637710571232],
        [80.40097045898438, 15.787362098693904],
        [80.4004135131836, 15.787362098693904],
        [80.4004135131836, 15.78708362579357],
        [80.3998641967774, 15.78708362579357],
        [80.3998641967774, 15.78680610656744],
        [80.39958190917963, 15.786810874938908],
        [80.39958190917963, 15.786529541015682],
        [80.39875030517584, 15.786529541015682],
        [80.39875030517584, 15.78625202178955],
        [80.39848327636736, 15.78625202178955],
        [80.39848327636736, 15.785971641540584],
        [80.39819335937528, 15.785971641540584],
        [80.39819335937528, 15.78569412231451],
        [80.3976364135745, 15.78569412231451],
        [80.3976364135745, 15.78541564941412],
        [80.3973617553711, 15.785420417785588],
        [80.3973617553711, 15.785140037536621],
        [80.39653015136713, 15.785140037536621],
        [80.39653015136713, 15.784859657287654],
        [80.39597320556658, 15.78486156463623],
        [80.39597320556658, 15.784584045410156],
        [80.3954162597658, 15.784584045410156],
        [80.3954162597658, 15.784305572509766],
        [80.39514160156278, 15.784310340881404],
        [80.39514160156278, 15.784029960632267],
        [80.39485931396501, 15.784029960632267],
        [80.39485931396501, 15.7837495803833],
        [80.39430236816406, 15.7837495803833],
        [80.39430236816406, 15.783469200134334],
        [80.39402770996122, 15.783472061157227],
        [80.39402770996122, 15.783193588256836],
        [80.39347076416027, 15.783193588256836],
        [80.39347076416027, 15.782917976379508],
        [80.39319610595709, 15.782921791076717],
        [80.39319610595709, 15.782640457153434],
        [80.39263916015648, 15.782640457153434],
        [80.39263916015648, 15.782361984253043],
        [80.39235687255876, 15.782361984253043],
        [80.39235687255876, 15.78208065032959],
        [80.39208221435553, 15.78208446502697],
        [80.39208221435553, 15.781805992126579],
        [80.3915328979495, 15.781819343566895],
        [80.3915328979495, 15.78153038024908],
        [80.3912582397461, 15.78153038024908],
        [80.3912582397461, 15.78125190734869],
        [80.39068603515625, 15.78125190734869],
        [80.39068603515625, 15.780970573425407],
        [80.38986206054682, 15.780970573425407],
        [80.38986206054682, 15.780693054199332],
        [80.38930511474626, 15.780693054199332],
        [80.38930511474626, 15.78041839599615],
        [80.38874816894548, 15.780420303344727],
        [80.38874816894548, 15.780141830444336],
        [80.38819122314453, 15.780141830444336],
        [80.38819122314453, 15.77986145019537],
        [80.38791656494169, 15.77986145019537],
        [80.38791656494169, 15.779582977294979],
        [80.38735961914074, 15.779582977294979],
        [80.38735961914074, 15.779305458068904],
        [80.38680267333979, 15.779310226440543],
        [80.38680267333979, 15.779031753540153],
        [80.38652801513695, 15.779031753540153],
        [80.38652801513695, 15.778760910034123],
        [80.38625335693376, 15.778760910034123],
        [80.38625335693376, 15.778470993042049],
        [80.38569641113298, 15.778470993042049],
        [80.38569641113298, 15.77819538116455],
        [80.38513946533203, 15.77819538116455],
        [80.38513946533203, 15.77791690826416],
        [80.38458251953125, 15.777919769287223],
        [80.38458251953125, 15.777639389038086],
        [80.38403320312506, 15.777639389038086],
        [80.38403320312506, 15.777360916137695],
        [80.38375091552729, 15.777360916137695],
        [80.38375091552729, 15.777080535888729],
        [80.38319396972673, 15.777083396911564],
        [80.38319396972673, 15.77680492401123],
        [80.3829193115235, 15.77680969238287],
        [80.3829193115235, 15.776529312133903],
        [80.38236236572294, 15.776529312133903],
        [80.38236236572294, 15.776250839233512],
        [80.38207244873064, 15.776250839233512],
        [80.38207244873064, 15.775971412658635],
        [80.38180541992216, 15.775973320007438],
        [80.38180541992216, 15.775694847107047],
        [80.38153076171875, 15.775694847107047],
        [80.38153076171875, 15.775417327880916],
        [80.38069152832043, 15.775419235229549],
        [80.38069152832043, 15.775140762329158],
        [80.38041687011724, 15.775140762329158],
        [80.38041687011724, 15.774861335754451],
        [80.37985992431663, 15.774861335754451],
        [80.37985992431663, 15.77458286285406],
        [80.37958526611345, 15.77458286285406],
        [80.37958526611345, 15.774305343627987],
        [80.37930297851568, 15.774312019348258],
        [80.37930297851568, 15.774030685424805],
        [80.37902832031267, 15.774030685424805],
        [80.37902832031267, 15.773751258850098],
        [80.37874603271513, 15.773751258850098],
        [80.37874603271513, 15.773470878601131],
        [80.37847137451172, 15.773470878601131],
        [80.37847137451172, 15.773195266723633],
        [80.37763977050793, 15.773195266723633],
        [80.37763977050793, 15.772916793823242],
        [80.37735748291021, 15.772920608520621],
        [80.37735748291021, 15.77262973785406],
        [80.37680816650413, 15.772639274597168],
        [80.37680816650413, 15.772360801696777],
        [80.37652587890642, 15.772360801696777],
        [80.37652587890642, 15.772083282470703],
        [80.37596893310564, 15.772083282470703],
        [80.37596893310564, 15.771804809570312],
        [80.37513732910185, 15.771812438964844],
        [80.37513732910185, 15.77153110504156],
        [80.3748626708985, 15.77153110504156],
        [80.3748626708985, 15.771250724792424],
        [80.3745803833009, 15.771250724792424],
        [80.3745803833009, 15.770971298217773],
        [80.37430572509771, 15.77097320556652],
        [80.37430572509771, 15.77069473266613],
        [80.3737487792971, 15.77069473266613],
        [80.3737487792971, 15.770417213440055],
        [80.37319183349615, 15.770421028137207],
        [80.37319183349615, 15.77014064788824],
        [80.37236022949219, 15.77014064788824],
        [80.37236022949219, 15.76986122131359],
        [80.37208557128935, 15.76986122131359],
        [80.37208557128935, 15.769584655761776],
        [80.3718032836914, 15.769589424133244],
        [80.3718032836914, 15.769310951232853],
        [80.37124633789068, 15.769310951232853],
        [80.37124633789068, 15.769030570983887],
        [80.37097167968744, 15.769030570983887],
        [80.37097167968744, 15.768751144409237],
        [80.3706970214846, 15.768751144409237],
        [80.3706970214846, 15.768469810485954],
        [80.37014007568365, 15.768472671508846],
        [80.37014007568365, 15.768195152282772],
        [80.36985778808611, 15.768195152282772],
        [80.36985778808611, 15.767915725708065],
        [80.36930847167969, 15.76792144775402],
        [80.36930847167969, 15.767641067504883],
        [80.3687515258789, 15.767641067504883],
        [80.3687515258789, 15.7673597335816],
        [80.36846923828153, 15.7673597335816],
        [80.36846923828153, 15.76708030700695],
        [80.36791992187494, 15.767082214355526],
        [80.36791992187494, 15.766806602478027],
        [80.3676376342774, 15.766819953918514],
        [80.3676376342774, 15.7665309906007],
        [80.36708831787115, 15.7665309906007],
        [80.36708831787115, 15.766249656677246],
        [80.3665313720706, 15.766249656677246],
        [80.3665313720706, 15.765970230102596],
        [80.36597442626982, 15.765972137451172],
        [80.36597442626982, 15.765693664550781],
        [80.36569213867188, 15.765693664550781],
        [80.36569213867188, 15.765416145324707],
        [80.36541748046903, 15.765420913696346],
        [80.36541748046903, 15.765139579773063],
        [80.36514282226568, 15.765139579773063],
        [80.36514282226568, 15.764860153198242],
        [80.36430358886713, 15.764860153198242],
        [80.36430358886713, 15.764583587646598],
        [80.36402893066429, 15.764583587646598],
        [80.36402893066429, 15.76430416107172],
        [80.36347198486334, 15.764310836791992],
        [80.36347198486334, 15.764031410217285],
        [80.36319732666033, 15.764031410217285],
        [80.36319732666033, 15.763750076294059],
        [80.36264038085938, 15.763750076294059],
        [80.36264038085938, 15.763469696044865],
        [80.36180877685558, 15.763471603393668],
        [80.36180877685558, 15.763194084167594],
        [80.36125183105463, 15.763194084167594],
        [80.36125183105463, 15.762915611267204],
        [80.36068725585955, 15.762921333312931],
        [80.36068725585955, 15.762359619140739],
        [80.36042022705084, 15.762361526489315],
        [80.36042022705084, 15.76208400726324],
        [80.35958099365234, 15.76208400726324],
        [80.35958099365234, 15.76180553436285],
        [80.35901641845703, 15.761811256408805],
        [80.35901641845703, 15.761529922485352],
        [80.35874938964855, 15.761529922485352],
        [80.35874938964855, 15.761249542236385],
        [80.35846710205084, 15.761249542236385],
        [80.35846710205084, 15.760972023010254],
        [80.3581924438476, 15.760972023010254],
        [80.3581924438476, 15.760693550109863],
        [80.35791778564476, 15.760693550109863],
        [80.35791778564476, 15.760417938232365],
        [80.35764312744158, 15.760419845581168],
        [80.35764312744158, 15.76014041900629],
        [80.3573608398438, 15.76014041900629],
        [80.3573608398438, 15.7598619461059],
        [80.3567962646485, 15.7598619461059],
        [80.3567962646485, 15.759581565856934],
        [80.35624694824247, 15.759584426879996],
        [80.35624694824247, 15.759305953979435],
        [80.35597229003906, 15.759309768676815],
        [80.35597229003906, 15.759030342102108],
        [80.35569763183605, 15.759030342102108],
        [80.35569763183605, 15.758468627929688],
        [80.35541534423834, 15.758472442627067],
        [80.35541534423834, 15.758195877075252],
        [80.35514831542963, 15.758189201355037],
        [80.3551406860351, 15.757639884948787],
        [80.35457611083979, 15.757651329040527],
        [80.35457611083979, 15.757360458374137],
        [80.35402679443376, 15.757362365722713],
        [80.35402679443376, 15.757083892822322],
        [80.35375213623075, 15.757083892822322],
        [80.35375213623075, 15.756806373596248],
        [80.35346984863298, 15.7568101882934],
        [80.35346984863298, 15.756250381469783],
        [80.35319519042997, 15.756250381469783],
        [80.35319519042997, 15.755971908569393],
        [80.35263824462919, 15.755971908569393],
        [80.35263824462919, 15.755693435669002],
        [80.35236358642584, 15.755693435669002],
        [80.35236358642584, 15.755417823791504],
        [80.35180664062506, 15.75541973114008],
        [80.35180664062506, 15.75514030456543],
        [80.35151672363276, 15.75514030456543],
        [80.35151672363276, 15.754861831665039],
        [80.35124969482445, 15.754861831665039],
        [80.35121917724615, 15.754643440246639],
        [80.35111236572294, 15.754588127136287],
        [80.3509826660158, 15.754590034484863],
        [80.3509826660158, 15.754309654235897],
        [80.3506927490235, 15.754309654235897],
        [80.3506927490235, 15.754030227661246],
        [80.350151062012, 15.754020690917912],
        [80.35013580322294, 15.753748893737793],
        [80.34986114501953, 15.753751754760856],
        [80.34986114501953, 15.753471374511719],
        [80.34958648681669, 15.753471374511719],
        [80.34958648681669, 15.753195762634391],
        [80.3493041992188, 15.753195762634391],
        [80.3493041992188, 15.752915382385254],
        [80.34902191162121, 15.752920150756893],
        [80.34902191162121, 15.752641677856502],
        [80.34874725341803, 15.752641677856502],
        [80.34874725341803, 15.752361297607536],
        [80.34826660156261, 15.752361297607536],
        [80.34791564941423, 15.752361297607536],
        [80.34791564941423, 15.752082824707145],
        [80.34735870361345, 15.752082824707145],
        [80.34735870361345, 15.75180530548107],
        [80.3462524414063, 15.751811981201286],
        [80.3462524414063, 15.751520156860408],
        [80.34597015380871, 15.75152683258068],
        [80.34597015380871, 15.751249313354606],
        [80.34513854980491, 15.751249313354606],
        [80.34513854980491, 15.750970840454215],
        [80.34458160400396, 15.750970840454215],
        [80.34458160400396, 15.750691413879508],
        [80.34430694580095, 15.750695228576717],
        [80.34430694580095, 15.750416755676326],
        [80.34401702880865, 15.750419616699219],
        [80.34401702880865, 15.750139236450252],
        [80.34375, 15.750139236450252],
        [80.34375, 15.749860763549862],
        [80.34319305419922, 15.749860763549862],
        [80.34319305419922, 15.749581336975154],
        [80.34291839599621, 15.74958324432373],
        [80.34291839599621, 15.749304771423397],
        [80.3426361083985, 15.749309539795036],
        [80.3426361083985, 15.749032020568961],
        [80.34236145019526, 15.749032020568961],
        [80.34236145019526, 15.748750686645508],
        [80.34179687499994, 15.748750686645508],
        [80.34179687499994, 15.748458862304801],
        [80.34124755859392, 15.748473167419377],
        [80.34124755859392, 15.748194694519043],
        [80.34097290039091, 15.748194694519043],
        [80.34097290039091, 15.747917175292912],
        [80.34041595459013, 15.747921943664608],
        [80.34041595459013, 15.747640609741325],
        [80.3401489257813, 15.747640609741325],
        [80.3401489257813, 15.747361183166447],
        [80.339584350586, 15.747361183166447],
        [80.339584350586, 15.747082710266056],
        [80.3393020629884, 15.747082710266056],
        [80.3393020629884, 15.746805191040153],
        [80.33902740478521, 15.746811866760254],
        [80.33902740478521, 15.746530532836971],
        [80.3387527465822, 15.746530532836971],
        [80.3387527465822, 15.746251106262264],
        [80.3384704589846, 15.746251106262264],
        [80.3384704589846, 15.745970726013297],
        [80.33819580078142, 15.745972633361873],
        [80.33819580078142, 15.745695114135799],
        [80.33791351318365, 15.745695114135799],
        [80.33791351318365, 15.745416641235408],
        [80.33763885498064, 15.745421409606877],
        [80.33763885498064, 15.74514102935791],
        [80.3373565673831, 15.74514102935791],
        [80.3373565673831, 15.744580268859977],
        [80.33708190917969, 15.74458312988287],
        [80.33708190917969, 15.743748664856014],
        [80.33680725097685, 15.74375057220459],
        [80.33680725097685, 15.743470191955623],
        [80.33652496337896, 15.743473052978516],
        [80.33652496337896, 15.743194580078125],
        [80.3362503051759, 15.743194580078125],
        [80.3362503051759, 15.74291706085205],
        [80.33598327636741, 15.742920875549373],
        [80.33598327636741, 15.742670059204102],
        [80.33569335937494, 15.74265003204357],
        [80.33569335937494, 15.742492675781307],
        [80.33569335937494, 15.742361068725586],
        [80.3354187011721, 15.742361068725586],
        [80.3354187011721, 15.742081642150879],
        [80.33525085449247, 15.742081642150879],
        [80.33513641357439, 15.742081642150879],
        [80.33513641357439, 15.74194145202648],
        [80.33513641357439, 15.741806983947868],
        [80.33486175537115, 15.74181079864502],
        [80.33484649658226, 15.7415513992309],
        [80.33458709716814, 15.74153137207037],
        [80.33458709716814, 15.741250991821403],
        [80.33429718017607, 15.741250991821403],
        [80.33429718017607, 15.74096965789795],
        [80.33403015136719, 15.740973472595329],
        [80.33403015136719, 15.740694046020621],
        [80.33374786376982, 15.740694046020621],
        [80.33374786376982, 15.74041557312023],
        [80.33347320556646, 15.740421295166016],
        [80.33347320556646, 15.740140914917049],
        [80.33319091796886, 15.740140914917049],
        [80.33319091796886, 15.739859580993766],
        [80.33291625976568, 15.739863395690975],
        [80.33291625976568, 15.739582061767692],
        [80.33264160156244, 15.739582061767692],
        [80.33264160156244, 15.739306449890194],
        [80.33235931396507, 15.739311218261832],
        [80.33235931396507, 15.739030838012695],
        [80.33207702636736, 15.739030838012695],
        [80.33207702636736, 15.738750457763729],
        [80.33180236816412, 15.738750457763729],
        [80.33180236816412, 15.738470077514762],
        [80.3312530517581, 15.738471984863338],
        [80.3312530517581, 15.738194465637264],
        [80.33097076416016, 15.738194465637264],
        [80.33097076416016, 15.737915992736873],
        [80.33069610595732, 15.737920761108512],
        [80.33069610595732, 15.737640380859375],
        [80.32985687255865, 15.737640380859375],
        [80.32985687255865, 15.737360000610408],
        [80.32958221435541, 15.737360000610408],
        [80.32958221435541, 15.737079620361385],
        [80.32930755615257, 15.73708438873291],
        [80.32930755615257, 15.73680591583252],
        [80.32903289794939, 15.736810684204158],
        [80.32903289794939, 15.736518859863281],
        [80.32847595214861, 15.736528396606388],
        [80.32847595214861, 15.736249923706055],
        [80.3281860351563, 15.736249923706055],
        [80.3281860351563, 15.735969543457031],
        [80.32791900634766, 15.735972404479924],
        [80.32791900634766, 15.735693931579533],
        [80.32763671875028, 15.735693931579533],
        [80.32763671875028, 15.735416412353459],
        [80.32736206054688, 15.735421180725098],
        [80.32736206054688, 15.735139846801871],
        [80.32681274414068, 15.735139846801871],
        [80.32681274414068, 15.734860420227164],
        [80.32653045654291, 15.73486232757574],
        [80.32653045654291, 15.734581947326603],
        [80.32624816894537, 15.734581947326603],
        [80.32624816894537, 15.734027862548885],
        [80.3259658813476, 15.73403263092041],
        [80.3259658813476, 15.73375034332281],
        [80.32569122314476, 15.73375034332281],
        [80.32569122314476, 15.733461380004826],
        [80.32541656494158, 15.73347187042242],
        [80.32541656494158, 15.733194351196346],
        [80.3248596191408, 15.733194351196346],
        [80.3248596191408, 15.732915878295955],
        [80.32459259033232, 15.732919692993164],
        [80.32459259033232, 15.732640266418457],
        [80.32430267333984, 15.732640266418457],
        [80.32430267333984, 15.732361793518066],
        [80.324028015137, 15.732361793518066],
        [80.324028015137, 15.732078552246037],
        [80.32375335693365, 15.732084274291992],
        [80.32375335693365, 15.731805801391602],
        [80.32347106933605, 15.73180961608898],
        [80.32347106933605, 15.731249809265137],
        [80.32319641113287, 15.731249809265137],
        [80.32319641113287, 15.73096942901617],
        [80.3229141235351, 15.730972290039062],
        [80.3229141235351, 15.730695724487248],
        [80.32263946533226, 15.730695724487248],
        [80.32263946533226, 15.730416297912598],
        [80.32235717773455, 15.73042011260992],
        [80.32235717773455, 15.730139732360954],
        [80.3220825195313, 15.730139732360954],
        [80.3220825195313, 15.729859352111816],
        [80.32152557373075, 15.72986221313488],
        [80.32152557373075, 15.729583740234489],
        [80.32125091552734, 15.729583740234489],
        [80.32125091552734, 15.729306221008414],
        [80.32096862792997, 15.729310035705566],
        [80.32096862792997, 15.7290296554566],
        [80.32068634033203, 15.7290296554566],
        [80.32068634033203, 15.72875022888195],
        [80.32041931152355, 15.72875022888195],
        [80.32041931152355, 15.728470802307243],
        [80.32013702392584, 15.728470802307243],
        [80.32013702392584, 15.728191375732422],
        [80.3198623657226, 15.728193283081168],
        [80.3198623657226, 15.72791767120367],
        [80.31958007812523, 15.727919578552246],
        [80.31958007812523, 15.727640151977596],
        [80.31931304931658, 15.727640151977596],
        [80.31931304931658, 15.727361679077205],
        [80.3190307617188, 15.727361679077205],
        [80.3190307617188, 15.727083206176815],
        [80.31874847412126, 15.727083206176815],
        [80.31874847412126, 15.726804733276424],
        [80.31846618652372, 15.72681045532238],
        [80.31846618652372, 15.726531982421989],
        [80.31819152832031, 15.726531982421989],
        [80.31819152832031, 15.726251602172852],
        [80.31791687011747, 15.726251602172852],
        [80.31791687011747, 15.725971221923885],
        [80.31764221191412, 15.725971221923885],
        [80.31764221191412, 15.72569465637207],
        [80.31708526611334, 15.72569465637207],
        [80.31708526611334, 15.725417137145996],
        [80.31680297851557, 15.725421905517635],
        [80.31680297851557, 15.725141525268668],
        [80.31652832031273, 15.725141525268668],
        [80.31652832031273, 15.724861145019531],
        [80.31625366210955, 15.724861145019531],
        [80.31625366210955, 15.72458267211914],
        [80.31569671630876, 15.72458267211914],
        [80.31569671630876, 15.724305152893066],
        [80.31540679931646, 15.724309921264705],
        [80.31540679931646, 15.724028587341422],
        [80.31513977050781, 15.724028587341422],
        [80.31513977050781, 15.723470687866211],
        [80.31485748291044, 15.723472595214957],
        [80.31485748291044, 15.72319316864008],
        [80.31458282470703, 15.72319316864008],
        [80.31458282470703, 15.722916603088493],
        [80.31430816650402, 15.722919464111385],
        [80.31430816650402, 15.722640991210994],
        [80.3140258789063, 15.722640991210994],
        [80.3140258789063, 15.722360610962028],
        [80.31375122070307, 15.722360610962028],
        [80.31375122070307, 15.722083091735897],
        [80.31346893310553, 15.722083091735897],
        [80.31346893310553, 15.721804618835563],
        [80.31263732910173, 15.72181034088129],
        [80.31263732910173, 15.721520423889217],
        [80.31236267089872, 15.721527099609432],
        [80.31236267089872, 15.721248626709041],
        [80.31208038330095, 15.721250534057674],
        [80.31208038330095, 15.720971107482967],
        [80.31180572509794, 15.720973014831543],
        [80.31180572509794, 15.720417022705078],
        [80.31153106689453, 15.720421791076774],
        [80.31153106689453, 15.72014141082758],
        [80.31124877929716, 15.72014141082758],
        [80.31124877929716, 15.719861030578613],
        [80.31096649169928, 15.719861030578613],
        [80.31096649169928, 15.719580650329647],
        [80.31069183349621, 15.719582557678223],
        [80.31069183349621, 15.719306945800724],
        [80.31041717529303, 15.71931171417242],
        [80.31041717529303, 15.719031333923397],
        [80.31014251709001, 15.719031333923397],
        [80.31014251709001, 15.71875095367426],
        [80.30985260009788, 15.71875095367426],
        [80.30985260009788, 15.718482017517204],
        [80.30959320068376, 15.718482017517204],
        [80.30959320068376, 15.717921257019043],
        [80.30930328369146, 15.717921257019043],
        [80.30930328369146, 15.717361450195426],
        [80.30902862548845, 15.717361450195426],
        [80.30902862548845, 15.717082977295036],
        [80.30874633789091, 15.717082977295036],
        [80.30874633789091, 15.716528892517147],
        [80.3084716796875, 15.716528892517147],
        [80.3084716796875, 15.716251373291072],
        [80.30819702148466, 15.716251373291072],
        [80.30819702148466, 15.71597003936779],
        [80.30791473388678, 15.715972900390682],
        [80.30791473388678, 15.715695381164608],
        [80.307357788086, 15.715695381164608],
        [80.307357788086, 15.715416908264217],
        [80.30708312988276, 15.715420722961369],
        [80.30708312988276, 15.715141296386719],
        [80.30680847167991, 15.715141296386719],
        [80.30680847167991, 15.71457958221447],
        [80.3065261840822, 15.714582443237362],
        [80.3065261840822, 15.714306831359863],
        [80.30625152587896, 15.714310646057186],
        [80.30625152587896, 15.714031219482536],
        [80.30596923828142, 15.714031219482536],
        [80.30596923828142, 15.713470458984432],
        [80.30569458007841, 15.713472366333008],
        [80.30569458007841, 15.713193893432617],
        [80.305419921875, 15.713193893432617],
        [80.305419921875, 15.712916374206543],
        [80.30513763427763, 15.712921142578182],
        [80.30513763427763, 15.712640762329215],
        [80.30486297607428, 15.712640762329215],
        [80.30486297607428, 15.712350845336914],
        [80.30458068847668, 15.712360382080078],
        [80.30458068847668, 15.712083816528434],
        [80.3043060302735, 15.712081909179688],
        [80.3043060302735, 15.71180629730236],
        [80.30403900146501, 15.711811065673828],
        [80.30403900146501, 15.711530685424862],
        [80.30374908447288, 15.711530685424862],
        [80.30374908447288, 15.711250305175895],
        [80.30346679687517, 15.711250305175895],
        [80.30346679687517, 15.710969924926701],
        [80.30319213867193, 15.710971832275504],
        [80.30319213867193, 15.71069431304943],
        [80.30291748046892, 15.71069431304943],
        [80.30291748046892, 15.71041584014904],
        [80.30236053466797, 15.710420608520508],
        [80.30236053466797, 15.710140228271541],
        [80.30208587646513, 15.710140228271541],
        [80.30208587646513, 15.709859848022575],
        [80.30180358886719, 15.709859848022575],
        [80.30180358886719, 15.709584236145076],
        [80.30152893066418, 15.709584236145076],
        [80.30152893066418, 15.709028244018555],
        [80.30124664306646, 15.709030151367188],
        [80.30124664306646, 15.708751678466797],
        [80.30097198486322, 15.708751678466797],
        [80.30097198486322, 15.708470344543514],
        [80.30069732666038, 15.70847225189209],
        [80.30069732666038, 15.7081937789917],
        [80.30041503906267, 15.7081937789917],
        [80.30041503906267, 15.707637786865234],
        [80.30014038085943, 15.707639694213867],
        [80.30014038085943, 15.70736026763916],
        [80.29985809326189, 15.707362174987736],
        [80.29985809326189, 15.707083702087346],
        [80.29958343505888, 15.707083702087346],
        [80.29958343505888, 15.70652770996088],
        [80.29930877685547, 15.706542015075684],
        [80.29930877685547, 15.706250190734977],
        [80.2990264892581, 15.706250190734977],
        [80.2990264892581, 15.705971717834416],
        [80.29875183105469, 15.705971717834416],
        [80.29875183105469, 15.705694198608512],
        [80.29846954345732, 15.705694198608512],
        [80.29846954345732, 15.705415725708121],
        [80.29818725585943, 15.70541954040533],
        [80.29818725585943, 15.705140113830623],
        [80.29791259765636, 15.705140113830623],
        [80.29791259765636, 15.704859733581657],
        [80.29763793945318, 15.704859733581657],
        [80.29763793945318, 15.704310417175236],
        [80.29736328125017, 15.704310417175236],
        [80.29736328125017, 15.70403003692627],
        [80.29680633544939, 15.70403003692627],
        [80.29680633544939, 15.703749656677303],
        [80.29651641845709, 15.703749656677303],
        [80.29651641845709, 15.703480720520076],
        [80.29624938964861, 15.703480720520076],
        [80.29624938964861, 15.7031888961792],
        [80.2959747314456, 15.703193664550838],
        [80.2959747314456, 15.70263957977295],
        [80.29569244384766, 15.70263957977295],
        [80.29569244384766, 15.702080726623592],
        [80.29541778564482, 15.702083587646484],
        [80.29541778564482, 15.70180606842041],
        [80.29514312744146, 15.701811790466365],
        [80.29514312744146, 15.701251983642692],
        [80.29486846923822, 15.701251983642692],
        [80.29486846923822, 15.700970649719238],
        [80.29458618164068, 15.700970649719238],
        [80.29458618164068, 15.700693130493164],
        [80.29430389404291, 15.700693130493164],
        [80.29430389404291, 15.700418472290153],
        [80.29402923584007, 15.700418472290153],
        [80.29402923584007, 15.700139999389762],
        [80.29374694824236, 15.700141906738338],
        [80.29374694824236, 15.699860572815055],
        [80.29347229003912, 15.699860572815055],
        [80.29347229003912, 15.69930458068859],
        [80.29319763183611, 15.699310302734375],
        [80.29319763183611, 15.698752403259277],
        [80.2929229736331, 15.698752403259277],
        [80.2929229736331, 15.698471069336051],
        [80.29264068603516, 15.698471069336051],
        [80.29264068603516, 15.698195457458553],
        [80.29235839843778, 15.698195457458553],
        [80.29235839843778, 15.697916984558162],
        [80.29207611083984, 15.697921752929801],
        [80.29207611083984, 15.697349548339957],
        [80.29180908203136, 15.697360992431697],
        [80.29180908203136, 15.697083473205566],
        [80.29152679443365, 15.697083473205566],
        [80.29152679443365, 15.696526527404842],
        [80.29125213623041, 15.696529388427734],
        [80.29123687744152, 15.696271896362362],
        [80.29096984863304, 15.696250915527344],
        [80.29096221923834, 15.695992469787654],
        [80.29070281982439, 15.695970535278377],
        [80.29069519042986, 15.695694923400879],
        [80.29041290283209, 15.69569110870367],
        [80.29041290283209, 15.695417404174748],
        [80.29013824462908, 15.695419311523551],
        [80.29013824462908, 15.695210456848088],
        [80.28958129882812, 15.69514083862316],
        [80.28958129882812, 15.694861412048283],
        [80.28930664062528, 15.694861412048283],
        [80.28930664062528, 15.694582939147892],
        [80.28902435302734, 15.694582939147892],
        [80.28902435302734, 15.694026947021428],
        [80.28893280029325, 15.694026947021428],
        [80.28874969482433, 15.694030761718807],
        [80.28874969482433, 15.693869590759391],
        [80.28874969482433, 15.6937513351441],
        [80.28864288330078, 15.6937513351441],
        [80.28847503662115, 15.6937513351441],
        [80.28847503662115, 15.693589210510197],
        [80.28847503662115, 15.693470954895133],
        [80.28835296630888, 15.693470954895133],
        [80.28819274902338, 15.693470954895133],
        [80.28819274902338, 15.693310737609863],
        [80.28819274902338, 15.693195343017635],
        [80.28807067871094, 15.693195343017635],
        [80.28791809082054, 15.693195343017635],
        [80.28791809082054, 15.693051338195914],
        [80.28791809082054, 15.69263935089117],
        [80.28762817382807, 15.692641258239746],
        [80.28763580322283, 15.69236087799078],
        [80.28746795654314, 15.69236087799078],
        [80.28736114501959, 15.69236087799078],
        [80.28736114501959, 15.692169189453239],
        [80.28736114501959, 15.692083358764705],
        [80.28708648681658, 15.692083358764705],
        [80.28708648681658, 15.691804885864315],
        [80.28679656982428, 15.691811561584586],
        [80.28679656982428, 15.691542625427303],
        [80.28652954101562, 15.691542625427303],
        [80.28652954101562, 15.691250801086426],
        [80.28624725341825, 15.691250801086426],
        [80.28624725341825, 15.690971374511776],
        [80.28597259521484, 15.690973281860352],
        [80.28597259521484, 15.690694808959961],
        [80.28569793701183, 15.690694808959961],
        [80.28569793701183, 15.690138816833496],
        [80.28542327880865, 15.690140724182072],
        [80.28542327880865, 15.689579963684139],
        [80.28514099121088, 15.689584732055778],
        [80.28514099121088, 15.689305305480957],
        [80.28485870361334, 15.689311027526855],
        [80.28485870361334, 15.689030647277889],
        [80.28457641601557, 15.689030647277889],
        [80.28457641601557, 15.688751220703239],
        [80.28430175781273, 15.688751220703239],
        [80.28430175781273, 15.688472747802848],
        [80.28402709960955, 15.688472747802848],
        [80.28402709960955, 15.688180923461971],
        [80.28375244140653, 15.688195228576774],
        [80.28375244140653, 15.687916755676383],
        [80.28347015380876, 15.687920570373535],
        [80.28347015380876, 15.687359809875602],
        [80.28319549560575, 15.687359809875602],
        [80.28319549560575, 15.687082290649528],
        [80.28291320800781, 15.687082290649528],
        [80.28291320800781, 15.686249732971248],
        [80.28263854980497, 15.686249732971248],
        [80.28263854980497, 15.685970306396598],
        [80.28236389160162, 15.685972213745174],
        [80.28236389160162, 15.685693740844783],
        [80.28208160400402, 15.685693740844783],
        [80.28208160400402, 15.68541622161871],
        [80.28180694580084, 15.68541622161871],
        [80.28180694580084, 15.685137748718319],
        [80.28151702880854, 15.685140609741211],
        [80.28151702880854, 15.684860229492244],
        [80.28125000000023, 15.684860229492244],
        [80.28125000000023, 15.68458366394043],
        [80.28097534179705, 15.68458366394043],
        [80.28097534179705, 15.684304237365723],
        [80.28069305419928, 15.684310913085994],
        [80.28069305419928, 15.684030532837028],
        [80.28041839599626, 15.684030532837028],
        [80.28041839599626, 15.68375015258789],
        [80.28013610839872, 15.68375015258789],
        [80.28013610839872, 15.683190345764217],
        [80.27986145019531, 15.683194160461369],
        [80.27986145019531, 15.682915687561035],
        [80.27958679199247, 15.682921409606934],
        [80.27958679199247, 15.682640075683707],
        [80.279296875, 15.682640075683707],
        [80.279296875, 15.682369232177848],
        [80.27902984619152, 15.682369232177848],
        [80.27902984619152, 15.682080268859863],
        [80.27847290039057, 15.682084083557243],
        [80.27847290039057, 15.681528091430721],
        [80.27819824218773, 15.681529998779354],
        [80.2781906127932, 15.68097019195568],
        [80.27791595459001, 15.680972099304256],
        [80.27791595459001, 15.680693626403865],
        [80.27764129638678, 15.680693626403865],
        [80.27764129638678, 15.680418014526367],
        [80.27735900878923, 15.680419921875],
        [80.27735900878923, 15.679862022399902],
        [80.27707672119169, 15.679862022399902],
        [80.27707672119169, 15.679581642150936],
        [80.27680206298828, 15.679583549499512],
        [80.27680206298828, 15.679306030273438],
        [80.27652740478544, 15.679310798645076],
        [80.27648925781256, 15.6788072586059],
        [80.27640533447283, 15.678750038146973],
        [80.27625274658209, 15.678751945495549],
        [80.27625274658209, 15.678630828857422],
        [80.27625274658209, 15.678471565246582],
        [80.27601623535173, 15.678471565246582],
        [80.2751388549807, 15.678471565246582],
        [80.2751388549807, 15.678194046020508],
        [80.27486419677751, 15.678194046020508],
        [80.27486419677751, 15.67763996124279],
        [80.27458190917974, 15.67763996124279],
        [80.27458190917974, 15.677459716796932],
        [80.27458190917974, 15.677083969116325],
        [80.27403259277372, 15.677083969116325],
        [80.27375030517578, 15.677083969116325],
        [80.27375030517578, 15.676880836486873],
        [80.27375030517578, 15.67652797698986],
        [80.27348327636747, 15.676531791687012],
        [80.27348327636747, 15.675419807434082],
        [80.273193359375, 15.675419807434082],
        [80.273193359375, 15.674861907959041],
        [80.27263641357428, 15.674861907959041],
        [80.27263641357428, 15.674580574035758],
        [80.2720870971682, 15.674584388732967],
        [80.2720870971682, 15.674305915832576],
        [80.27153015136724, 15.674309730529728],
        [80.27153015136724, 15.674030303955078],
        [80.2712478637697, 15.674030303955078],
        [80.2712478637697, 15.673751831054688],
        [80.27097320556669, 15.673751831054688],
        [80.27097320556669, 15.673471450805721],
        [80.27069091796892, 15.673471450805721],
        [80.27069091796892, 15.673195838928223],
        [80.27041625976591, 15.673195838928223],
        [80.27041625976591, 15.672638893127441],
        [80.2701416015625, 15.672641754150504],
        [80.2701416015625, 15.6720809936524],
        [80.26985931396513, 15.672082901000977],
        [80.26985931396513, 15.67124938964838],
        [80.26957702636724, 15.67124938964838],
        [80.26957702636724, 15.670431137084904],
        [80.26930236816418, 15.670431137084904],
        [80.26930236816418, 15.670139312744254],
        [80.269027709961, 15.670139312744254],
        [80.269027709961, 15.669860839843864],
        [80.26875305175798, 15.669860839843864],
        [80.26875305175798, 15.669309616088867],
        [80.26847076416038, 15.669309616088867],
        [80.26847076416038, 15.668190956115836],
        [80.2681961059572, 15.668194770813045],
        [80.2681961059572, 15.667638778686523],
        [80.2679061889649, 15.667640686035156],
        [80.2679061889649, 15.667371749877873],
        [80.26763916015642, 15.667371749877873],
        [80.26763916015642, 15.667080879211483],
        [80.26736450195341, 15.667082786560059],
        [80.26736450195341, 15.666805267333984],
        [80.26708221435547, 15.666811943054256],
        [80.26708221435547, 15.666530609130973],
        [80.26680755615263, 15.666530609130973],
        [80.26680755615263, 15.66542053222662],
        [80.26653289794928, 15.66542053222662],
        [80.26653289794928, 15.664580345153809],
        [80.26625061035168, 15.664583206176871],
        [80.26625061035168, 15.66430473327648],
        [80.2659759521485, 15.66430473327648],
        [80.2659759521485, 15.663473129272518],
        [80.26569366455072, 15.663473129272518],
        [80.26569366455072, 15.662082672119197],
        [80.26541900634788, 15.662082672119197],
        [80.26541900634788, 15.661528587341309],
        [80.26513671875017, 15.661531448364371],
        [80.26513671875017, 15.66124153137207],
        [80.26486206054693, 15.661251068115234],
        [80.26486206054693, 15.66069507598877],
        [80.26457977294939, 15.66069507598877],
        [80.26457977294939, 15.660415649414062],
        [80.26431274414091, 15.660421371460018],
        [80.26431274414091, 15.660140991211051],
        [80.26403045654297, 15.660140991211051],
        [80.26403045654297, 15.659311294555664],
        [80.2637481689456, 15.659311294555664],
        [80.2637481689456, 15.659030914306697],
        [80.26346588134766, 15.659030914306697],
        [80.26346588134766, 15.65820121765148],
        [80.26319122314464, 15.65820121765148],
        [80.26319122314464, 15.657920837402344],
        [80.26291656494146, 15.657920837402344],
        [80.26291656494146, 15.655969619751033],
        [80.26264190673822, 15.655971527099666],
        [80.26264190673822, 15.653471946716252],
        [80.26235961914085, 15.653471946716252],
        [80.26235961914085, 15.652915954589957],
        [80.2620925903322, 15.652922630310059],
        [80.2620925903322, 15.652361869812069],
        [80.2618026733399, 15.652361869812069],
        [80.2618026733399, 15.651809692382812],
        [80.26152801513689, 15.651809692382812],
        [80.26152801513689, 15.651251792907715],
        [80.26125335693388, 15.651251792907715],
        [80.26125335693388, 15.650691032409782],
        [80.26097106933594, 15.65069580078125],
        [80.26097106933594, 15.6504163742066],
        [80.2606964111331, 15.650420188903752],
        [80.2606964111331, 15.650139808654785],
        [80.26040649414062, 15.650139808654785],
        [80.26040649414062, 15.649580955505428],
        [80.26013946533214, 15.64958381652832],
        [80.26013946533214, 15.649027824401855],
        [80.25985717773443, 15.649029731750602],
        [80.25985717773443, 15.64847183227539],
        [80.2595825195312, 15.64847183227539],
        [80.2595825195312, 15.647917747497672],
        [80.25930786132835, 15.647919654846248],
        [80.25930786132835, 15.646809577941895],
        [80.25903320312517, 15.646809577941895],
        [80.25903320312517, 15.646261215209961],
        [80.25874328613304, 15.646261215209961],
        [80.25874328613304, 15.645971298217887],
        [80.25846862792986, 15.645971298217887],
        [80.25846862792986, 15.645695686340389],
        [80.25819396972685, 15.645695686340389],
        [80.25819396972685, 15.645415306091422],
        [80.25791931152344, 15.645421981811637],
        [80.25791931152344, 15.6451416015625],
        [80.25763702392607, 15.6451416015625],
        [80.25763702392607, 15.644580841064567],
        [80.25736236572266, 15.644582748413143],
        [80.25736236572266, 15.643749237060547],
        [80.25708007812528, 15.643749237060547],
        [80.25708007812528, 15.643199920654354],
        [80.25681304931646, 15.643199920654354],
        [80.25681304931646, 15.642642021179256],
        [80.2565307617187, 15.642642021179256],
        [80.2565307617187, 15.642081260681152],
        [80.25624847412115, 15.642083168029728],
        [80.25624847412115, 15.641527175903263],
        [80.25596618652338, 15.641531944274902],
        [80.25596618652338, 15.641250610351676],
        [80.2556991577149, 15.641250610351676],
        [80.2556991577149, 15.640690803527832],
        [80.25541687011736, 15.640694618225211],
        [80.25541687011736, 15.639861106872615],
        [80.25514221191435, 15.639861106872615],
        [80.25514221191435, 15.639582633972225],
        [80.25485992431658, 15.639582633972225],
        [80.25485992431658, 15.638751029968262],
        [80.2545928955081, 15.638751029968262],
        [80.2545928955081, 15.638470649719295],
        [80.25430297851562, 15.638472557067871],
        [80.25430297851562, 15.638195037841797],
        [80.25402832031278, 15.638195037841797],
        [80.25402832031278, 15.637360572814941],
        [80.25375366210943, 15.637360572814941],
        [80.25375366210943, 15.636804580688477],
        [80.25347137451183, 15.636809349060115],
        [80.25347137451183, 15.636530876159725],
        [80.25319671630865, 15.636530876159725],
        [80.25319671630865, 15.636251449585075],
        [80.25290679931635, 15.636251449585075],
        [80.25290679931635, 15.635970115661621],
        [80.25263977050804, 15.635972976684684],
        [80.25263977050804, 15.63569545745861],
        [80.25235748291033, 15.63569545745861],
        [80.25235748291033, 15.635139465332145],
        [80.25208282470709, 15.635141372680721],
        [80.25208282470709, 15.6345796585083],
        [80.25180816650408, 15.634581565857047],
        [80.25180816650408, 15.634306907653865],
        [80.25152587890653, 15.634320259094352],
        [80.25152587890653, 15.6337509155274],
        [80.25125122070312, 15.6337509155274],
        [80.25125122070312, 15.633200645446834],
        [80.25096893310575, 15.63319015502924],
        [80.25096893310575, 15.632637977600154],
        [80.25068664550781, 15.632640838623047],
        [80.25068664550781, 15.632080078125057],
        [80.25041961669933, 15.63208198547369],
        [80.25041961669933, 15.631250381469727],
        [80.25013732910162, 15.63126182556158],
        [80.25013732910162, 15.630689620971737],
        [80.24986267089838, 15.630694389343205],
        [80.24986267089838, 15.630140304565543],
        [80.2495727539063, 15.630140304565543],
        [80.2495727539063, 15.629579544067383],
        [80.24930572509783, 15.629584312439079],
        [80.24930572509783, 15.628749847412223],
        [80.24903106689459, 15.628749847412223],
        [80.24903106689459, 15.628200531005973],
        [80.24874877929705, 15.628200531005973],
        [80.24874877929705, 15.627642631530705],
        [80.2484664916995, 15.627642631530705],
        [80.2484664916995, 15.627360343933162],
        [80.2481918334961, 15.627362251281738],
        [80.2481918334961, 15.626806259155273],
        [80.24791717529325, 15.626811027526912],
        [80.24791717529325, 15.626529693603516],
        [80.2476425170899, 15.626529693603516],
        [80.2476425170899, 15.625971794128418],
        [80.2473602294923, 15.625971794128418],
        [80.2473602294923, 15.625130653381348],
        [80.24708557128912, 15.625140190124625],
        [80.24708557128912, 15.62458419799816],
        [80.24680328369135, 15.62458419799816],
        [80.24680328369135, 15.623749732971305],
        [80.24652862548851, 15.623749732971305],
        [80.24652862548851, 15.622920036315918],
        [80.2462463378908, 15.622920036315918],
        [80.2462463378908, 15.622639656066951],
        [80.24597167968756, 15.622639656066951],
        [80.24597167968756, 15.6220703125],
        [80.24569702148455, 15.622083663940487],
        [80.24569702148455, 15.621527671814022],
        [80.24540710449224, 15.621529579162598],
        [80.24540710449224, 15.62042045593273],
        [80.2451400756836, 15.62042045593273],
        [80.2451400756836, 15.619861602783203],
        [80.24485778808622, 15.619861602783203],
        [80.24485778808622, 15.6193208694458],
        [80.24458312988281, 15.6193208694458],
        [80.24458312988281, 15.61875152587902],
        [80.2443084716798, 15.61875152587902],
        [80.2443084716798, 15.61819076538086],
        [80.24402618408209, 15.618194580078239],
        [80.24402618408209, 15.617638587951774],
        [80.24375915527338, 15.617638587951774],
        [80.24375915527338, 15.61681175231945],
        [80.2434692382813, 15.61681175231945],
        [80.2434692382813, 15.616260528564453],
        [80.24318695068354, 15.616260528564453],
        [80.24318695068354, 15.615420341491642],
        [80.24291992187506, 15.615420341491642],
        [80.24291992187506, 15.614581108093319],
        [80.24263763427751, 15.614583015441895],
        [80.24263763427751, 15.61402702331543],
        [80.2423629760745, 15.614031791687069],
        [80.2423629760745, 15.613471031188965],
        [80.24208068847673, 15.613471031188965],
        [80.24208068847673, 15.612639427185172],
        [80.24180603027372, 15.612641334533748],
        [80.24180603027372, 15.611811637878361],
        [80.24153137207031, 15.611811637878361],
        [80.24153137207031, 15.611250877380428],
        [80.24124908447294, 15.611250877380428],
        [80.24124908447294, 15.61042118072504],
        [80.24096679687506, 15.61042118072504],
        [80.24096679687506, 15.609580039978141],
        [80.24069213867199, 15.609582901001033],
        [80.24069213867199, 15.609028816223145],
        [80.2404174804688, 15.60903072357172],
        [80.2404174804688, 15.60818958282482],
        [80.24013519287104, 15.608195304870605],
        [80.24013519287104, 15.607639312744084],
        [80.2398605346682, 15.607641220092887],
        [80.2398605346682, 15.606810569763184],
        [80.23958587646501, 15.606810569763184],
        [80.23958587646501, 15.605690002441406],
        [80.23930358886724, 15.605693817138786],
        [80.23930358886724, 15.60513782501232],
        [80.23902893066423, 15.605140686035213],
        [80.23902893066423, 15.604579925537053],
        [80.23874664306669, 15.604583740234432],
        [80.23874664306669, 15.602359771728516],
        [80.23847198486328, 15.602359771728516],
        [80.23847198486328, 15.602080345153865],
        [80.23819732666044, 15.602084159851017],
        [80.23819732666044, 15.601805686950684],
        [80.23792266845709, 15.601811408996582],
        [80.23792266845709, 15.601411819458065],
        [80.23791503906256, 15.601249694824162],
        [80.23763275146496, 15.601249694824162],
        [80.23763275146496, 15.600960731506404],
        [80.23735809326178, 15.600972175598088],
        [80.23735809326178, 15.599583625793514],
        [80.23708343505854, 15.599583625793514],
        [80.23708343505854, 15.59791564941412],
        [80.2368087768557, 15.597920417785588],
        [80.2368087768557, 15.596529960632438],
        [80.23652648925798, 15.596529960632438],
        [80.23652648925798, 15.594861984253043],
        [80.23625183105474, 15.594861984253043],
        [80.23625183105474, 15.59403038024908],
        [80.2359695434572, 15.59403038024908],
        [80.2359695434572, 15.592920303344727],
        [80.23568725585966, 15.592920303344727],
        [80.23568725585966, 15.592089653015194],
        [80.23542022705078, 15.592089653015194],
        [80.23542022705078, 15.590970993042049],
        [80.23513793945341, 15.590970993042049],
        [80.23513793945341, 15.590139389038029],
        [80.23486328125006, 15.590139389038029],
        [80.23486328125006, 15.589022636413631],
        [80.23458099365246, 15.5890274047851],
        [80.23458099365246, 15.588194847107047],
        [80.23430633544928, 15.588194847107047],
        [80.23430633544928, 15.58708286285406],
        [80.2340240478515, 15.58708286285406],
        [80.2340240478515, 15.586251258850098],
        [80.23374938964866, 15.586251258850098],
        [80.23374938964866, 15.585141181945744],
        [80.23346710205095, 15.585141181945744],
        [80.23346710205095, 15.584312438964844],
        [80.23319244384771, 15.584312438964844],
        [80.23319244384771, 15.58318996429449],
        [80.2329177856447, 15.58319473266613],
        [80.2329177856447, 15.5820827484132],
        [80.23263549804716, 15.5820827484132],
        [80.23263549804716, 15.580972671508846],
        [80.23236083984375, 15.580972671508846],
        [80.23236083984375, 15.58013820648199],
        [80.23208618164091, 15.580149650573674],
        [80.23208618164091, 15.578749656677246],
        [80.23179626464844, 15.578749656677246],
        [80.23179626464844, 15.577920913696346],
        [80.23152923583996, 15.577920913696346],
        [80.23152923583996, 15.576531410217285],
        [80.23124694824224, 15.576531410217285],
        [80.23124694824224, 15.575421333312931],
        [80.230972290039, 15.575421333312931],
        [80.230972290039, 15.574580192566032],
        [80.23069763183616, 15.574582099914608],
        [80.23069763183616, 15.573472023010254],
        [80.23041534423845, 15.573472023010254],
        [80.23041534423845, 15.5723619461059],
        [80.23014068603521, 15.5723619461059],
        [80.23014068603521, 15.571530342102108],
        [80.22985839843767, 15.571530342102108],
        [80.22985839843767, 15.570420265197754],
        [80.2295761108399, 15.570420265197754],
        [80.2295761108399, 15.5693101882934],
        [80.22930908203125, 15.5693101882934],
        [80.22930908203125, 15.566530227661246],
        [80.22902679443388, 15.566530227661246],
        [80.22902679443388, 15.565141677856502],
        [80.22875976562506, 15.565141677856502],
        [80.22875976562506, 15.564580917358342],
        [80.2284698486331, 15.564582824707145],
        [80.2284698486331, 15.562916755676326],
        [80.22820281982428, 15.562919616699219],
        [80.22820281982428, 15.562081336975154],
        [80.22791290283214, 15.56208324432373],
        [80.22791290283214, 15.560973167419377],
        [80.22763824462896, 15.560973167419377],
        [80.22763824462896, 15.560417175292912],
        [80.2273559570312, 15.56042194366455],
        [80.2273559570312, 15.560140609741325],
        [80.22708129882835, 15.560140609741325],
        [80.22708129882835, 15.559041023254395],
        [80.22680664062517, 15.559041023254395],
        [80.22680664062517, 15.557921409606877],
        [80.22651672363287, 15.557921409606877],
        [80.22651672363287, 15.555689811706657],
        [80.22624969482439, 15.555694580078125],
        [80.22624969482439, 15.554861068725586],
        [80.22597503662138, 15.554861068725586],
        [80.22597503662138, 15.553194999694938],
        [80.22624969482439, 15.553194999694938],
        [80.22624969482439, 15.552638053894157],
        [80.22651672363287, 15.552640914917049],
        [80.22651672363287, 15.552080154418888],
        [80.22680664062517, 15.552082061767692],
        [80.22680664062517, 15.551807403564453],
        [80.22708129882835, 15.551811218261832],
        [80.22708129882835, 15.550970077514705],
        [80.22736358642595, 15.550971984863338],
        [80.22736358642595, 15.550138473510742],
        [80.22763824462896, 15.550141334533635],
        [80.22763824462896, 15.548469543457031],
        [80.22736358642595, 15.548472404479924],
        [80.22736358642595, 15.54791641235363],
        [80.22708129882835, 15.547921180725098],
        [80.22708129882835, 15.547081947326774],
        [80.22736358642595, 15.547081947326774],
        [80.22736358642595, 15.545694351196346],
        [80.22763824462896, 15.545694351196346],
        [80.22763824462896, 15.542083740234489],
        [80.22736358642595, 15.542083740234489],
        [80.22736358642595, 15.541806221008414],
        [80.22708129882835, 15.541810035705566],
        [80.22708129882835, 15.54125022888195],
        [80.22680664062517, 15.54125022888195],
        [80.22680664062517, 15.540691375732365],
        [80.2265243530274, 15.540693283081168],
        [80.2265243530274, 15.54041767120367],
        [80.22624969482439, 15.540419578552246],
        [80.22624969482439, 15.539581298828239],
        [80.22597503662138, 15.539584159851074],
        [80.22597503662138, 15.53902721405035],
        [80.22569274902344, 15.539031982421989],
        [80.22569274902344, 15.538471221923885],
        [80.2254180908206, 15.538471221923885],
        [80.2254180908206, 15.537917137145996],
        [80.22513580322271, 15.537917137145996],
        [80.22513580322271, 15.537638664245605],
        [80.22486114501964, 15.537638664245605],
        [80.22486114501964, 15.533471107482967],
        [80.22513580322271, 15.533473014831543],
        [80.22513580322271, 15.532361030578613],
        [80.22486114501964, 15.532361030578613],
        [80.22486114501964, 15.532080650329647],
        [80.22458648681646, 15.532082557678223],
        [80.22458648681646, 15.531805038452148],
        [80.22429656982416, 15.531811714172363],
        [80.22429656982416, 15.531531333923397],
        [80.22402954101585, 15.531531333923397],
        [80.22402954101585, 15.530970573425293],
        [80.22374725341814, 15.530973434448356],
        [80.22374725341814, 15.530417442321891],
        [80.2234725952149, 15.530421257019043],
        [80.2234725952149, 15.530140876770076],
        [80.22319793701189, 15.530140876770076],
        [80.22319793701189, 15.529580116271973],
        [80.22291564941435, 15.529582977295036],
        [80.22291564941435, 15.529305458068961],
        [80.2226333618164, 15.529309272766113],
        [80.2226333618164, 15.528751373291072],
        [80.22235870361357, 15.528751373291072],
        [80.22235870361357, 15.528189659118652],
        [80.22208404541021, 15.528195381164608],
        [80.22208404541021, 15.527916908264217],
        [80.22180175781261, 15.527920722961369],
        [80.22180175781261, 15.527360916137752],
        [80.22152709960943, 15.527360916137752],
        [80.22152709960943, 15.526810646057186],
        [80.2212524414062, 15.526810646057186],
        [80.2212524414062, 15.525970458984375],
        [80.22097015380882, 15.525973320007267],
        [80.22097015380882, 15.525693893432617],
        [80.22069549560564, 15.525693893432617],
        [80.22069549560564, 15.524860382080021],
        [80.22041320800787, 15.524860382080021],
        [80.22041320800787, 15.52402782440197],
        [80.22013854980486, 15.524030685424862],
        [80.22013854980486, 15.523189544677734],
        [80.21986389160185, 15.523194313049373],
        [80.21986389160185, 15.52291584014904],
        [80.21958923339844, 15.522930145263786],
        [80.21958923339844, 15.522640228271541],
        [80.21930694580107, 15.522640228271541],
        [80.21930694580107, 15.522080421447868],
        [80.21902465820312, 15.52208423614502],
        [80.21902465820312, 15.521249771118164],
        [80.21875000000011, 15.521249771118164],
        [80.21875000000011, 15.520970344543514],
        [80.21847534179693, 15.52097225189209],
        [80.21847534179693, 15.5206937789917],
        [80.21819305419916, 15.5206937789917],
        [80.21819305419916, 15.520416259765625],
        [80.21791839599632, 15.520421028137264],
        [80.21791839599632, 15.519870758056754],
        [80.21763610839861, 15.519870758056754],
        [80.21763610839861, 15.519029617309684],
        [80.21736145019537, 15.519029617309684],
        [80.21736145019537, 15.51846981048584],
        [80.21708679199236, 15.518471717834586],
        [80.21708679199236, 15.517915725708121],
        [80.21679687500006, 15.51791954040533],
        [80.21679687500006, 15.517640113830623],
        [80.2165298461914, 15.517640113830623],
        [80.2165298461914, 15.51707935333252],
        [80.21624755859403, 15.517084121704158],
        [80.21624755859403, 15.516805648803768],
        [80.21597290039062, 15.516810417175236],
        [80.21597290039062, 15.51653003692627],
        [80.21569824218761, 15.51653003692627],
        [80.21569061279325, 15.515969276428336],
        [80.2154159545899, 15.515972137451229],
        [80.2154159545899, 15.51541805267334],
        [80.21569061279325, 15.515419960022086],
        [80.21569061279325, 15.513739585876465],
        [80.2154159545899, 15.513750076293888],
        [80.2154159545899, 15.513470649719238],
        [80.21514129638666, 15.513470649719238],
        [80.21514129638666, 15.513193130493164],
        [80.21485900878912, 15.513193130493164],
        [80.21485900878912, 15.512918472290096],
        [80.21457672119135, 15.512920379638729],
        [80.21457672119135, 15.512641906738338],
        [80.21430206298851, 15.512641906738338],
        [80.21430206298851, 15.512361526489371],
        [80.21402740478533, 15.512361526489371],
        [80.21402740478533, 15.51208305358898],
        [80.21375274658232, 15.51208305358898],
        [80.21375274658232, 15.51180458068859],
        [80.21346282959001, 15.511810302734375],
        [80.21346282959001, 15.511531829833984],
        [80.21319580078153, 15.511531829833984],
        [80.21319580078153, 15.510971069336051],
        [80.2129135131836, 15.510971069336051],
        [80.2129135131836, 15.509860992431697],
        [80.21319580078153, 15.509860992431697],
        [80.21319580078153, 15.509309768676871],
        [80.21402740478533, 15.509305000305176],
        [80.21402740478533, 15.509583473205566],
        [80.21485900878912, 15.509583473205566],
        [80.21485900878912, 15.508473396301213],
        [80.21458435058611, 15.508473396301213],
        [80.21458435058611, 15.507917404174748],
        [80.21430206298851, 15.507930755615234],
        [80.21430206298851, 15.506810188293457],
        [80.21402740478533, 15.506810188293457],
        [80.21402740478533, 15.505690574645996],
        [80.21375274658232, 15.505695343017635],
        [80.21375274658232, 15.50513935089117],
        [80.21346282959001, 15.505141258239746],
        [80.21346282959001, 15.50458145141613],
        [80.21319580078153, 15.504583358764705],
        [80.21319580078153, 15.503750801086426],
        [80.21290588378906, 15.503750801086426],
        [80.21290588378906, 15.502640724182243],
        [80.21263885498075, 15.502640724182243],
        [80.21263885498075, 15.502361297607422],
        [80.2120819091798, 15.502361297607422],
        [80.2120819091798, 15.502640724182243],
        [80.21125030517601, 15.502640724182243],
        [80.21125030517601, 15.502919197082633],
        [80.21097564697283, 15.502917289733887],
        [80.21097564697283, 15.503194808959961],
        [80.21069335937506, 15.503194808959961],
        [80.21069335937506, 15.503473281860352],
        [80.21041870117205, 15.503471374511776],
        [80.21041870117205, 15.503750801086426],
        [80.2101364135745, 15.503750801086426],
        [80.2101364135745, 15.50431156158453],
        [80.21041870117205, 15.504304885864315],
        [80.21041870117205, 15.504583358764705],
        [80.21069335937506, 15.50458145141613],
        [80.21069335937506, 15.504870414733887],
        [80.21041870117205, 15.504870414733887],
        [80.21041870117205, 15.505141258239746],
        [80.2098617553711, 15.505141258239746],
        [80.2098617553711, 15.505421638488713],
        [80.2090301513673, 15.505421638488713],
        [80.2090301513673, 15.505141258239746],
        [80.20874786376959, 15.505141258239746],
        [80.20874786376959, 15.504870414733887],
        [80.20847320556635, 15.504870414733887],
        [80.20847320556635, 15.50458145141613],
        [80.20819091796898, 15.504583358764705],
        [80.20819091796898, 15.504304885864315],
        [80.2079162597658, 15.50431156158453],
        [80.2079162597658, 15.503750801086426],
        [80.20764160156256, 15.503750801086426],
        [80.20764160156256, 15.503473281860352],
        [80.20735931396501, 15.503473281860352],
        [80.20735931396501, 15.503194808959961],
        [80.207084655762, 15.503194808959961],
        [80.207084655762, 15.502917289733887],
        [80.20680236816406, 15.502919197082633],
        [80.20680236816406, 15.502361297607422],
        [80.20652770996122, 15.502361297607422],
        [80.20652770996122, 15.5018053054809],
        [80.20625305175787, 15.5018053054809],
        [80.20625305175787, 15.500972747802848],
        [80.20597076416027, 15.500972747802848],
        [80.20597076416027, 15.500420570373535],
        [80.20513916015648, 15.500420570373535],
        [80.20513916015648, 15.500141143798885],
        [80.20430755615251, 15.500141143798885],
        [80.20430755615251, 15.499859809875602],
        [80.20347595214872, 15.499859809875602],
        [80.20347595214872, 15.499311447143668],
        [80.2040328979495, 15.499311447143668],
        [80.2040328979495, 15.499031066894531],
        [80.20430755615251, 15.499031066894531],
        [80.20430755615251, 15.498470306396541],
        [80.20402526855497, 15.498472213745174],
        [80.20402526855497, 15.498193740844783],
        [80.20347595214872, 15.498193740844783],
        [80.20347595214872, 15.49791622161871],
        [80.20291900634777, 15.49791622161871],
        [80.20291900634777, 15.498193740844783],
        [80.20263671875006, 15.498193740844783],
        [80.20263671875006, 15.498472213745174],
        [80.20236206054682, 15.498472213745174],
        [80.20236206054682, 15.498750686645565],
        [80.20124816894548, 15.498750686645565],
        [80.20124816894548, 15.498472213745174],
        [80.20097351074247, 15.498472213745174],
        [80.20097351074247, 15.498193740844783],
        [80.20041656494169, 15.498193740844783],
        [80.20041656494169, 15.497360229492188],
        [80.2006912231447, 15.497360229492188],
        [80.2006912231447, 15.49708461761469],
        [80.20097351074247, 15.49708461761469],
        [80.20097351074247, 15.496250152587834],
        [80.2006912231447, 15.496250152587834],
        [80.2006912231447, 15.49570083618164],
        [80.20041656494169, 15.49570083618164],
        [80.20041656494169, 15.495421409606934],
        [80.20014190673828, 15.495421409606934],
        [80.20014190673828, 15.495140075683707],
        [80.20041656494169, 15.495140075683707],
        [80.20041656494169, 15.494859695434513],
        [80.20124816894548, 15.494859695434513],
        [80.20124816894548, 15.495140075683707],
        [80.2018127441408, 15.495140075683707],
        [80.2018127441408, 15.495421409606934],
        [80.20847320556635, 15.495421409606934],
        [80.20847320556635, 15.495140075683707],
        [80.21041870117205, 15.495140075683707],
        [80.21041870117205, 15.495421409606934],
        [80.21098327636736, 15.495421409606934],
        [80.21098327636736, 15.49570083618164],
        [80.21125030517601, 15.495694160461369],
        [80.21125030517601, 15.4959716796875],
        [80.21152496337885, 15.4959716796875],
        [80.21152496337885, 15.495694160461369],
        [80.21180725097662, 15.49570083618164],
        [80.21180725097662, 15.495140075683707],
        [80.21153259277361, 15.495140075683707],
        [80.21153259277361, 15.49431133270258],
        [80.21125030517601, 15.49431133270258],
        [80.21125030517601, 15.494029998779354],
        [80.21098327636736, 15.494029998779354],
        [80.21098327636736, 15.492362022399902],
        [80.21069335937506, 15.492362022399902],
        [80.21069335937506, 15.492081642150936],
        [80.21041870117205, 15.492083549499512],
        [80.21041870117205, 15.490694046020508],
        [80.2101364135745, 15.490694046020508],
        [80.2101364135745, 15.489583969116325],
        [80.2098617553711, 15.489583969116325],
        [80.2098617553711, 15.48902797698986],
        [80.20958709716825, 15.489031791687012],
        [80.20958709716825, 15.487640380859432],
        [80.20929718017578, 15.487640380859432],
        [80.20929718017578, 15.487361907959041],
        [80.2090301513673, 15.487361907959041],
        [80.2090301513673, 15.485141754150504],
        [80.20874786376959, 15.485141754150504],
        [80.20874786376959, 15.484580993652344],
        [80.20847320556635, 15.484582901000977],
        [80.20847320556635, 15.48374938964838],
        [80.20819091796898, 15.483760833740291],
        [80.20819091796898, 15.482081413269157],
        [80.2079162597658, 15.482083320617733],
        [80.2079162597658, 15.480694770812988],
        [80.20764923095732, 15.480699539184684],
        [80.20764923095732, 15.479580879211483],
        [80.20735931396501, 15.479582786560059],
        [80.20735931396501, 15.479026794433594],
        [80.20707702636747, 15.479030609130973],
        [80.20707702636747, 15.478470802307129],
        [80.20680236816406, 15.478470802307129],
        [80.20680236816406, 15.478195190429801],
        [80.20652770996122, 15.478195190429801],
        [80.20652770996122, 15.477360725402946],
        [80.20625305175787, 15.477360725402946],
        [80.20625305175787, 15.476812362671012],
        [80.20597076416027, 15.476812362671012],
        [80.20597076416027, 15.476250648498592],
        [80.20569610595709, 15.476250648498592],
        [80.20569610595709, 15.474861145019588],
        [80.20541381835932, 15.474861145019588],
        [80.20541381835932, 15.474582672119197],
        [80.20513916015648, 15.474582672119197],
        [80.20513916015648, 15.474305152893066],
        [80.20485687255876, 15.474310874939022],
        [80.20485687255876, 15.474031448364371],
        [80.20458984375006, 15.474031448364371],
        [80.20458984375006, 15.473469734191951],
        [80.20430755615251, 15.473472595214844],
        [80.20430755615251, 15.472359657287598],
        [80.20402526855497, 15.472359657287598],
        [80.20402526855497, 15.471806526184196],
        [80.20375061035156, 15.471822738647575],
        [80.20375061035156, 15.471530914306697],
        [80.20347595214872, 15.471530914306697],
        [80.20347595214872, 15.47125053405773],
        [80.20318603515625, 15.47125053405773],
        [80.20318603515625, 15.47068977355957],
        [80.20291900634777, 15.47069358825695],
        [80.20291900634777, 15.470416069030875],
        [80.20263671875006, 15.470420837402344],
        [80.20263671875006, 15.469579696655217],
        [80.20236206054682, 15.469584465026855],
        [80.20236206054682, 15.469305992126522],
        [80.20207977294928, 15.46931076049816],
        [80.20207977294928, 15.468758583068848],
        [80.2018127441408, 15.468758583068848],
        [80.2018127441408, 15.468190193176383],
        [80.20153045654303, 15.468194007873535],
        [80.20153045654303, 15.467915534973145],
        [80.20124816894548, 15.4679212570191],
        [80.20124816894548, 15.46652984619152],
        [80.20096588134771, 15.46652984619152],
        [80.20096588134771, 15.465971946716422],
        [80.2006912231447, 15.465971946716422],
        [80.2006912231447, 15.465415954589957],
        [80.20041656494169, 15.465422630310002],
        [80.20041656494169, 15.46514034271246],
        [80.20014190673828, 15.46514034271246],
        [80.20014190673828, 15.464859962463493],
        [80.19985961914091, 15.464859962463493],
        [80.19985961914091, 15.464578628540039],
        [80.19958496093756, 15.464584350585994],
        [80.19958496093756, 15.46402835845953],
        [80.19930267333996, 15.464030265808105],
        [80.19930267333996, 15.463751792907715],
        [80.19902801513678, 15.463751792907715],
        [80.19902801513678, 15.463191032409782],
        [80.19875335693376, 15.463193893432674],
        [80.19875335693376, 15.4629163742066],
        [80.19845581054693, 15.462920188903922],
        [80.19845581054693, 15.462631225585938],
        [80.19819641113298, 15.462639808654785],
        [80.19819641113298, 15.462362289428711],
        [80.19791412353521, 15.462362289428711],
        [80.19791412353521, 15.461806297302246],
        [80.1976394653322, 15.461810111999569],
        [80.1976394653322, 15.461529731750602],
        [80.19735717773466, 15.461529731750602],
        [80.19735717773466, 15.46097183227539],
        [80.19708251953125, 15.46097183227539],
        [80.19708251953125, 15.46069431304926],
        [80.19680786132841, 15.46069431304926],
        [80.19680786132841, 15.460140228271598],
        [80.19653320312506, 15.460140228271598],
        [80.19653320312506, 15.459861755371207],
        [80.19625091552746, 15.459861755371207],
        [80.19625091552746, 15.459309577941895],
        [80.19596862792974, 15.459309577941895],
        [80.19596862792974, 15.459030151367244],
        [80.19568634033197, 15.459030151367244],
        [80.19568634033197, 15.458751678466854],
        [80.19541931152366, 15.458751678466854],
        [80.19541931152366, 15.458471298217887],
        [80.19513702392595, 15.458471298217887],
        [80.19513702392595, 15.457639694213867],
        [80.19486236572271, 15.4576416015625],
        [80.19486236572271, 15.4573593139649],
        [80.19458007812517, 15.457361221313533],
        [80.19458007812517, 15.456805229187012],
        [80.19431304931669, 15.45682144165039],
        [80.19431304931669, 15.456529617309513],
        [80.19403076171875, 15.456529617309513],
        [80.19403076171875, 15.456249237060547],
        [80.19374847412138, 15.456249237060547],
        [80.19374847412138, 15.455970764160156],
        [80.19347381591803, 15.455972671508789],
        [80.19347381591803, 15.455416679382324],
        [80.19319152832043, 15.455419540405387],
        [80.19319152832043, 15.455142021179256],
        [80.19291687011724, 15.455142021179256],
        [80.19291687011724, 15.454581260681152],
        [80.192642211914, 15.454583168029728],
        [80.192642211914, 15.454304695129338],
        [80.19235992431663, 15.454310417175293],
        [80.19235992431663, 15.454031944274902],
        [80.19209289550798, 15.454031944274902],
        [80.19209289550798, 15.453750610351676],
        [80.19180297851568, 15.453750610351676],
        [80.19180297851568, 15.45347118377697],
        [80.19152832031267, 15.453473091125545],
        [80.19152832031267, 15.45291709899908],
        [80.19124603271513, 15.452921867370549],
        [80.19124603271513, 15.452640533447322],
        [80.19097137451172, 15.452640533447322],
        [80.19097137451172, 15.452361106872615],
        [80.19069671630888, 15.452361106872615],
        [80.19069671630888, 15.452082633972225],
        [80.19041442871094, 15.452082633972225],
        [80.19041442871094, 15.45180511474615],
        [80.19013977050793, 15.451811790466365],
        [80.19013977050793, 15.450970649719295],
        [80.18985748291021, 15.450972557067871],
        [80.18985748291021, 15.450695037841797],
        [80.18930816650413, 15.450695037841797],
        [80.18930816650413, 15.450416564941406],
        [80.18875122070318, 15.450421333313045],
        [80.18875122070318, 15.450140953064079],
        [80.18846893310564, 15.450140953064079],
        [80.18846893310564, 15.449860572814941],
        [80.18819427490263, 15.449860572814941],
        [80.18819427490263, 15.449304580688477],
        [80.18791961669922, 15.449309349060115],
        [80.18791961669922, 15.449030876159725],
        [80.18763732910185, 15.449030876159725],
        [80.18763732910185, 15.448189735412654],
        [80.18736267089844, 15.44819545745861],
        [80.18736267089844, 15.44791698455822],
        [80.18708038330107, 15.447920799255371],
        [80.18708038330107, 15.447360992431754],
        [80.18651580810558, 15.447360992431754],
        [80.18651580810558, 15.4470796585083],
        [80.18624877929693, 15.447083473205623],
        [80.18624877929693, 15.446806907653865],
        [80.18596649169916, 15.446810722351017],
        [80.18596649169916, 15.446531295776367],
        [80.18569183349632, 15.446531295776367],
        [80.18569183349632, 15.4462509155274],
        [80.18541717529314, 15.4462509155274],
        [80.18541717529314, 15.445969581604118],
        [80.18514251709013, 15.44597339630127],
        [80.18514251709013, 15.445416450500545],
        [80.18486022949236, 15.445421218872184],
        [80.18486022949236, 15.445140838623047],
        [80.18459320068388, 15.445140838623047],
        [80.18459320068388, 15.44459056854248],
        [80.1843032836914, 15.44459056854248],
        [80.1843032836914, 15.44431114196783],
        [80.18402862548857, 15.44431114196783],
        [80.18402862548857, 15.444030761718864],
        [80.18374633789068, 15.444030761718864],
        [80.18374633789068, 15.443470001220703],
        [80.18347167968761, 15.443471908569336],
        [80.18347167968761, 15.443194389343205],
        [80.18319702148443, 15.443194389343205],
        [80.18319702148443, 15.442915916442814],
        [80.18290710449213, 15.44292068481451],
        [80.18290710449213, 15.44235992431635],
        [80.18264007568382, 15.44235992431635],
        [80.18264007568382, 15.442079544067383],
        [80.18235778808611, 15.442084312439022],
        [80.18235778808611, 15.441805839538688],
        [80.18208312988287, 15.441810607910156],
        [80.18208312988287, 15.441520690918026],
        [80.18180847167986, 15.441528320312557],
        [80.18180847167986, 15.441249847412166],
        [80.18152618408232, 15.441252708435059],
        [80.18152618408232, 15.440691947937125],
        [80.1812515258789, 15.440693855285701],
        [80.1812515258789, 15.440416336059627],
        [80.18096923828153, 15.440421104431096],
        [80.18096923828153, 15.440139770507812],
        [80.1806869506836, 15.440139770507812],
        [80.1806869506836, 15.439860343933162],
        [80.18041992187511, 15.439862251281738],
        [80.18041992187511, 15.439583778381348],
        [80.1801376342774, 15.439583778381348],
        [80.1801376342774, 15.439306259155273],
        [80.17986297607416, 15.439311027526912],
        [80.17986297607416, 15.438750267028809],
        [80.17958068847679, 15.438750267028809],
        [80.17958068847679, 15.438461303710994],
        [80.17930603027361, 15.438471794128418],
        [80.17930603027361, 15.438194274902344],
        [80.17903137207037, 15.438194274902344],
        [80.17903137207037, 15.437915802001953],
        [80.17874908447283, 15.437919616699332],
        [80.17874908447283, 15.437640190124625],
        [80.17846679687528, 15.437640190124625],
        [80.17846679687528, 15.437079429626522],
        [80.17819213867188, 15.43708419799816],
        [80.17819213867188, 15.43680572509777],
        [80.17791748046903, 15.436809539794979],
        [80.17791748046903, 15.436530113220272],
        [80.17764282226568, 15.436530113220272],
        [80.17764282226568, 15.436249732971305],
        [80.17736053466808, 15.436249732971305],
        [80.17736053466808, 15.435969352722168],
        [80.1770858764649, 15.43597221374523],
        [80.1770858764649, 15.43569374084484],
        [80.17680358886713, 15.43569374084484],
        [80.17680358886713, 15.435137748718375],
        [80.17652893066429, 15.435139656066951],
        [80.17652893066429, 15.434860229492244],
        [80.17624664306658, 15.434862136840877],
        [80.17624664306658, 15.434583663940487],
        [80.17597198486334, 15.434583663940487],
        [80.17597198486334, 15.434306144714412],
        [80.17569732666033, 15.434309959411735],
        [80.17569732666033, 15.434029579162598],
        [80.17542266845732, 15.434029579162598],
        [80.17542266845732, 15.43375015258789],
        [80.17514038085938, 15.43375015258789],
        [80.17514038085938, 15.433471679687557],
        [80.174858093262, 15.433471679687557],
        [80.174858093262, 15.432917594909668],
        [80.17457580566406, 15.43292045593273],
        [80.17457580566406, 15.432650566101188],
        [80.17430877685558, 15.432650566101188],
        [80.17430877685558, 15.432361602783203],
        [80.17402648925787, 15.432361602783203],
        [80.17402648925787, 15.43208122253418],
        [80.17319488525408, 15.432084083557072],
        [80.17319488525408, 15.431805610656681],
        [80.17292022705084, 15.431810379028377],
        [80.17292022705084, 15.43125152587902],
        [80.1726379394533, 15.43125152587902],
        [80.1726379394533, 15.43069076538086],
        [80.17208099365251, 15.430695533752555],
        [80.17208099365251, 15.430418014526424],
        [80.1718063354495, 15.430419921875057],
        [80.1718063354495, 15.430138587951774],
        [80.17151641845703, 15.430138587951774],
        [80.17151641845703, 15.429580688476506],
        [80.17097473144537, 15.429582595825309],
        [80.17097473144537, 15.429305076599178],
        [80.17069244384777, 15.42931175231945],
        [80.17069244384777, 15.429030418395996],
        [80.17041778564459, 15.429030418395996],
        [80.17041778564459, 15.428750991821346],
        [80.17014312744158, 15.428750991821346],
        [80.17014312744158, 15.428191184997672],
        [80.1695861816408, 15.428193092346248],
        [80.1695861816408, 15.42763900756836],
        [80.1692962646485, 15.427641868591252],
        [80.1692962646485, 15.427360534667969],
        [80.16902923584001, 15.427360534667969],
        [80.16902923584001, 15.427083015441895],
        [80.16874694824247, 15.427083015441895],
        [80.16874694824247, 15.42652702331543],
        [80.16847229003906, 15.42652702331543],
        [80.16847229003906, 15.426251411438102],
        [80.16819763183622, 15.426251411438102],
        [80.16819763183622, 15.425971031188965],
        [80.16791534423834, 15.425971031188965],
        [80.16791534423834, 15.425695419311637],
        [80.16735839843756, 15.425695419311637],
        [80.16735839843756, 15.425416946411246],
        [80.16707611083979, 15.425421714782715],
        [80.16707611083979, 15.425141334533748],
        [80.16652679443376, 15.425141334533748],
        [80.16652679443376, 15.424580574035645],
        [80.16625213623053, 15.424583435058707],
        [80.16625213623053, 15.424304962158317],
        [80.16596984863298, 15.424311637878361],
        [80.16596984863298, 15.423750877380428],
        [80.1657028198245, 15.423750877380428],
        [80.1657028198245, 15.423190116882324],
        [80.16541290283203, 15.423194885253963],
        [80.16541290283203, 15.422917366027889],
        [80.16513824462919, 15.422919273376465],
        [80.16513824462919, 15.422361373901424],
        [80.16486358642584, 15.422361373901424],
        [80.16486358642584, 15.422082901001033],
        [80.16458129882824, 15.422082901001033],
        [80.16458129882824, 15.421805381774902],
        [80.16430664062506, 15.421809196472282],
        [80.16430664062506, 15.42125129699707],
        [80.16374969482445, 15.42125129699707],
        [80.16374969482445, 15.420969963073787],
        [80.1631927490235, 15.42097282409668],
        [80.1631927490235, 15.420416831970215],
        [80.16291809082048, 15.420420646667537],
        [80.16291809082048, 15.420141220092887],
        [80.16263580322294, 15.420141220092887],
        [80.16263580322294, 15.419862747192496],
        [80.16234588623064, 15.419862747192496],
        [80.16234588623064, 15.419580459594727],
        [80.16208648681669, 15.41958236694336],
        [80.16208648681669, 15.419306755066032],
        [80.16179656982422, 15.419310569763184],
        [80.16179656982422, 15.419031143188533],
        [80.16124725341803, 15.419031143188533],
        [80.16124725341803, 15.418750762939567],
        [80.16097259521479, 15.418750762939567],
        [80.16097259521479, 15.418190002441406],
        [80.16069793701195, 15.418194770813045],
        [80.16069793701195, 15.417916297912711],
        [80.16042327880876, 15.41792106628418],
        [80.16042327880876, 15.417640686035213],
        [80.160140991211, 15.417640686035213],
        [80.160140991211, 15.41736030578619],
        [80.15985870361345, 15.41736030578619],
        [80.15985870361345, 15.417084693908691],
        [80.15958404541044, 15.417084693908691],
        [80.15958404541044, 15.416804313659725],
        [80.15928649902361, 15.416810989379996],
        [80.15930175781267, 15.416489601135254],
        [80.15930175781267, 15.416250228881836],
        [80.15909576416033, 15.416250228881836],
        [80.15875244140625, 15.416250228881836],
        [80.15875244140625, 15.41596984863287],
        [80.15847015380888, 15.415971755981502],
        [80.15847015380888, 15.415571212768668],
        [80.15847015380888, 15.41541576385498],
        [80.15831756591814, 15.415418624877873],
        [80.15820312500006, 15.415420532226676],
        [80.15819549560553, 15.415290832519531],
        [80.15819549560553, 15.415138244628906],
        [80.15805816650419, 15.415138244628906],
        [80.15791320800793, 15.415140151977482],
        [80.15791320800793, 15.414990425109977],
        [80.15791320800793, 15.414582252502441],
        [80.15763854980474, 15.414582252502441],
        [80.15763854980474, 15.414305686950627],
        [80.15735626220697, 15.414311408996582],
        [80.15735626220697, 15.413749694824162],
        [80.15708160400413, 15.413749694824162],
        [80.15708160400413, 15.413470268249512],
        [80.15680694580095, 15.413472175598258],
        [80.15680694580095, 15.413193702697697],
        [80.15652465820318, 15.413193702697697],
        [80.15652465820318, 15.412916183471793],
        [80.15625000000017, 15.412920951843262],
        [80.15625000000017, 15.412639617919979],
        [80.15598297119169, 15.412639617919979],
        [80.15598297119169, 15.412081718444938],
        [80.15569305419922, 15.412083625793514],
        [80.15569305419922, 15.41180610656744],
        [80.15541839599638, 15.411810874938908],
        [80.15541839599638, 15.411520957946834],
        [80.1551361083985, 15.411527633667049],
        [80.1551361083985, 15.411250114440975],
        [80.15486145019543, 15.411250114440975],
        [80.15486145019543, 15.410969734192008],
        [80.15458679199224, 15.410971641540584],
        [80.15458679199224, 15.41041564941412],
        [80.15402984619163, 15.410420417785758],
        [80.15402984619163, 15.409859657287654],
        [80.15374755859392, 15.40986156463623],
        [80.15374755859392, 15.409584045410156],
        [80.15347290039068, 15.409584045410156],
        [80.15347290039068, 15.409305572509766],
        [80.15319824218767, 15.409310340881404],
        [80.15319061279314, 15.409029960632438],
        [80.15291595459013, 15.409029960632438],
        [80.15291595459013, 15.408480644226017],
        [80.15264129638672, 15.408480644226017],
        [80.15264129638672, 15.408190727233944],
        [80.15235900878935, 15.408193588256836],
        [80.15235900878935, 15.407917976379508],
        [80.1520767211914, 15.40792179107666],
        [80.1520767211914, 15.407640457153434],
        [80.1518020629884, 15.407640457153434],
        [80.1518020629884, 15.407361984253043],
        [80.15152740478521, 15.407361984253043],
        [80.15152740478521, 15.40708065032959],
        [80.15125274658197, 15.407084465026912],
        [80.15125274658197, 15.406889915466309],
        [80.15125274658197, 15.406528472900447],
        [80.15106964111345, 15.40653038024908],
        [80.1509704589846, 15.406528472900447],
        [80.15092468261719, 15.406304359436149],
        [80.15085601806652, 15.40625],
        [80.15069580078142, 15.40625190734869],
        [80.15069580078142, 15.406091690063533],
        [80.15069580078142, 15.405970573425407],
        [80.15056610107428, 15.405970573425407],
        [80.15041351318365, 15.405970573425407],
        [80.15041351318365, 15.405830383300838],
        [80.15041351318365, 15.405693054199332],
        [80.15026092529325, 15.405693054199332],
        [80.15013885498064, 15.405693054199332],
        [80.15013885498064, 15.40557861328125],
        [80.15013885498064, 15.405414581298942],
        [80.14996337890625, 15.405418395996094],
        [80.1498565673831, 15.405420303344727],
        [80.1498565673831, 15.405141830444336],
        [80.14965057373053, 15.405141830444336],
        [80.14958190917969, 15.404581069946346],
        [80.14930725097685, 15.404583930969238],
        [80.14930725097685, 15.404305458068848],
        [80.1490325927735, 15.404310226440543],
        [80.1490325927735, 15.404031753540153],
        [80.1487503051759, 15.404031753540153],
        [80.1487503051759, 15.403749465942383],
        [80.14847564697271, 15.403749465942383],
        [80.14847564697271, 15.403470993041992],
        [80.14819335937494, 15.403470993041992],
        [80.14819335937494, 15.40319633483898],
        [80.1479187011721, 15.40319633483898],
        [80.1479187011721, 15.402639389038029],
        [80.14763641357439, 15.402639389038029],
        [80.14763641357439, 15.402350425720215],
        [80.14736175537115, 15.402360916137638],
        [80.14736175537115, 15.402083396911564],
        [80.14708709716814, 15.402083396911564],
        [80.14708709716814, 15.401804924011174],
        [80.14679718017584, 15.40180969238287],
        [80.14679718017584, 15.401250839233512],
        [80.14653015136719, 15.401250839233512],
        [80.14653015136719, 15.400971412658805],
        [80.14624786376982, 15.400973320007381],
        [80.14624786376982, 15.40069484710699],
        [80.1459732055664, 15.40069484710699],
        [80.1459732055664, 15.400138854980526],
        [80.14569091796903, 15.400140762329158],
        [80.14569091796903, 15.399861335754451],
        [80.14541625976568, 15.399861335754451],
        [80.14541625976568, 15.39958286285406],
        [80.14514160156244, 15.39958286285406],
        [80.14514160156244, 15.399026870727596],
        [80.1448593139649, 15.399031639099064],
        [80.1448593139649, 15.398751258850098],
        [80.14457702636713, 15.398751258850098],
        [80.14457702636713, 15.398470878601131],
        [80.14430236816429, 15.398470878601131],
        [80.14430236816429, 15.397916793823242],
        [80.14402770996111, 15.397921562194881],
        [80.14402770996111, 15.397641181945914],
        [80.1437530517581, 15.397641181945914],
        [80.1437530517581, 15.397360801696777],
        [80.14347076416033, 15.397360801696777],
        [80.14347076416033, 15.397083282470703],
        [80.14291381835938, 15.397083282470703],
        [80.14291381835938, 15.396529197692985],
        [80.14263916015653, 15.396539688110408],
        [80.14263916015653, 15.395971298217717],
        [80.14236450195318, 15.39597320556652],
        [80.14236450195318, 15.395417213440055],
        [80.14208221435558, 15.395421028137207],
        [80.14208221435558, 15.39514064788824],
        [80.1418075561524, 15.39514064788824],
        [80.1418075561524, 15.39486122131359],
        [80.14152526855463, 15.39486122131359],
        [80.14152526855463, 15.394305229187069],
        [80.14125061035179, 15.394310951232853],
        [80.14125061035179, 15.394030570983887],
        [80.14097595214861, 15.394030570983887],
        [80.1409378051759, 15.3934907913208],
        [80.1406860351563, 15.393480300903377],
        [80.14065551757818, 15.3932523727417],
        [80.14057159423822, 15.393195152282715],
        [80.14041900634783, 15.393195152282715],
        [80.14041900634783, 15.392916679382381],
        [80.14013671875028, 15.39292144775402],
        [80.14013671875028, 15.392470359802303],
        [80.14013671875028, 15.39236068725586],
        [80.1395797729495, 15.39236068725586],
        [80.1395797729495, 15.392080307006893],
        [80.13930511474615, 15.392082214355526],
        [80.13930511474615, 15.391529083251896],
        [80.13903045654308, 15.3915309906007],
        [80.13903045654308, 15.391250610351506],
        [80.13874816894537, 15.391250610351506],
        [80.13874816894537, 15.390689849853572],
        [80.13847351074213, 15.390693664550781],
        [80.13847351074213, 15.39041614532465],
        [80.13819122314476, 15.39041614532465],
        [80.13819122314476, 15.390137672424316],
        [80.13791656494158, 15.39014053344738],
        [80.13791656494158, 15.389860153198185],
        [80.13764190673834, 15.389860153198185],
        [80.13764190673834, 15.38930416107172],
        [80.1373596191408, 15.389310836791992],
        [80.1373596191408, 15.389031410217285],
        [80.13709259033232, 15.389031410217285],
        [80.13708496093778, 15.38884162902832],
        [80.13708496093778, 15.388750076294002],
        [80.13688659667997, 15.388750076294002],
        [80.13680267333984, 15.388750076294002],
        [80.13680267333984, 15.3884792327882],
        [80.136528015137, 15.388469696044865],
        [80.136528015137, 15.388194084167537],
        [80.13625335693365, 15.388194084167537],
        [80.13625335693365, 15.387638092041072],
        [80.13597106933605, 15.387639999389648],
        [80.13597106933605, 15.387351036071891],
        [80.13569641113287, 15.387361526489315],
        [80.13569641113287, 15.387082099914608],
        [80.1354141235351, 15.387082099914608],
        [80.1354141235351, 15.386528015136719],
        [80.13513946533226, 15.386529922485295],
        [80.13513946533226, 15.386249542236328],
        [80.13485717773455, 15.386249542236328],
        [80.13485717773455, 15.385972023010254],
        [80.1345825195313, 15.385972023010254],
        [80.1345825195313, 15.385416030883789],
        [80.1343078613283, 15.385420799255428],
        [80.1343078613283, 15.385140419006461],
        [80.13403320312528, 15.385140419006461],
        [80.13403320312528, 15.384860038757324],
        [80.13375091552734, 15.384860038757324],
        [80.13375091552734, 15.384310722351074],
        [80.13346862792997, 15.384310722351074],
        [80.13346862792997, 15.384030342102108],
        [80.13318634033203, 15.384030342102108],
        [80.13318634033203, 15.383749961853141],
        [80.13291931152355, 15.383749961853141],
        [80.13291931152355, 15.38320064544672],
        [80.13263702392584, 15.383189201355037],
        [80.13263702392584, 15.382916450500602],
        [80.13236999511713, 15.382920265197754],
        [80.13236999511713, 15.382641792297363],
        [80.13208007812506, 15.382641792297363],
        [80.13208007812506, 15.382190704345646],
        [80.13208007812506, 15.382083892822322],
        [80.13197326660168, 15.382083892822322],
        [80.13180541992205, 15.382083892822322],
        [80.13180541992205, 15.381931304931697],
        [80.13180541992205, 15.381806373596248],
        [80.13166809082031, 15.381806373596248],
        [80.1315307617188, 15.38181018829357],
        [80.1315307617188, 15.381691932678336],
        [80.1315307617188, 15.381250381469727],
        [80.13124847412126, 15.381250381469727],
        [80.13124847412126, 15.380971908569393],
        [80.13097381591825, 15.380971908569393],
        [80.13097381591825, 15.380694389343262],
        [80.13069152832048, 15.380694389343262],
        [80.13069152832048, 15.380140304565373],
        [80.13041687011747, 15.380140304565373],
        [80.13041687011747, 15.379861831665039],
        [80.13014221191406, 15.379861831665039],
        [80.13014221191406, 15.379581451416016],
        [80.12985992431669, 15.379584312438908],
        [80.12985992431669, 15.379028320312443],
        [80.1296005249024, 15.379030227661246],
        [80.1296005249024, 15.378751754760856],
        [80.1293106079101, 15.378751754760856],
        [80.1293106079101, 15.378471374511662],
        [80.12902832031256, 15.378471374511662],
        [80.12902832031256, 15.377915382385197],
        [80.12874603271479, 15.377920150756893],
        [80.12874603271479, 15.377641677856502],
        [80.12847137451195, 15.377641677856502],
        [80.12847137451195, 15.377359390258732],
        [80.12819671630876, 15.377362251281795],
        [80.12819671630876, 15.376805305481014],
        [80.12790679931646, 15.376810073852539],
        [80.12790679931646, 15.376529693603516],
        [80.12763977050798, 15.376529693603516],
        [80.12763977050798, 15.375970840454158],
        [80.12735748291044, 15.375970840454158],
        [80.12735748291044, 15.375691413879508],
        [80.12708282470703, 15.375693321228084],
        [80.12708282470703, 15.375139236450195],
        [80.12680816650419, 15.375139236450195],
        [80.12680816650419, 15.374581336975154],
        [80.1265258789063, 15.37458324432373],
        [80.1265258789063, 15.37430477142334],
        [80.12623596191435, 15.374309539795036],
        [80.12623596191435, 15.373750686645451],
        [80.12596893310553, 15.373750686645451],
        [80.12596893310553, 15.3734712600708],
        [80.12569427490229, 15.373473167419377],
        [80.12569427490229, 15.372917175293082],
        [80.12541961669945, 15.37292194366455],
        [80.12541961669945, 15.372640609741325],
        [80.12513732910173, 15.372640609741325],
        [80.12513732910173, 15.37208080291748],
        [80.1248626708985, 15.372082710266227],
        [80.1248626708985, 15.371526718139762],
        [80.12458038330095, 15.371530532836971],
        [80.12458038330095, 15.371251106262264],
        [80.12430572509794, 15.371251106262264],
        [80.12430572509794, 15.370972633361873],
        [80.12403106689453, 15.370972633361873],
        [80.12403106689453, 15.370139122009334],
        [80.12374877929716, 15.37014102935791],
        [80.12374877929716, 15.369860649108944],
        [80.1234741210938, 15.369860649108944],
        [80.12346649169922, 15.369581222534237],
        [80.12319183349621, 15.36958312988287],
        [80.12319183349621, 15.36902904510498],
        [80.12291717529303, 15.369030952453727],
        [80.12291717529303, 15.36875057220459],
        [80.12264251708979, 15.36875057220459],
        [80.12264251708979, 15.368471145629883],
        [80.12236022949241, 15.368473052978516],
        [80.12236022949241, 15.36791706085205],
        [80.12209320068376, 15.367920875549373],
        [80.12209320068376, 15.367641448974723],
        [80.12180328369146, 15.367641448974723],
        [80.12180328369146, 15.367079734802303],
        [80.12152862548845, 15.367082595825195],
        [80.12152862548845, 15.366525650024414],
        [80.12124633789091, 15.36653137207037],
        [80.12124633789091, 15.366242408752555],
        [80.1209716796875, 15.366250991821403],
        [80.1209716796875, 15.365694999694881],
        [80.12041473388672, 15.365694999694881],
        [80.12041473388672, 15.364582061767692],
        [80.12014007568371, 15.364582061767692],
        [80.12014007568371, 15.364307403564453],
        [80.119857788086, 15.364311218261832],
        [80.119857788086, 15.363751411437988],
        [80.11958312988276, 15.363751411437988],
        [80.11958312988276, 15.36318111419672],
        [80.11930847167991, 15.363194465637207],
        [80.11930847167991, 15.362915992736816],
        [80.1190261840822, 15.362920761108512],
        [80.1190261840822, 15.362360000610352],
        [80.11875152587896, 15.362360000610352],
        [80.11875152587896, 15.361810684204158],
        [80.11846923828142, 15.361810684204158],
        [80.11846923828142, 15.361531257629451],
        [80.11818695068365, 15.361531257629451],
        [80.11818695068365, 15.360969543457031],
        [80.117919921875, 15.360972404480094],
        [80.117919921875, 15.360693931579533],
        [80.11763763427763, 15.360693931579533],
        [80.11763763427763, 15.360137939453239],
        [80.1173706054688, 15.360142707824707],
        [80.1173706054688, 15.359581947326774],
        [80.11708068847685, 15.359581947326774],
        [80.11708068847685, 15.359306335449276],
        [80.1168060302735, 15.359311103820744],
        [80.1168060302735, 15.35875034332281],
        [80.11653137207026, 15.35875034332281],
        [80.11653137207026, 15.35818862915039],
        [80.11624908447271, 15.358194351196346],
        [80.11624908447271, 15.357640266418457],
        [80.11596679687494, 15.357640266418457],
        [80.11596679687494, 15.357078552246207],
        [80.1156921386721, 15.357084274291992],
        [80.1156921386721, 15.356528282165527],
        [80.11541748046892, 15.356530189514274],
        [80.11541748046892, 15.35597038269043],
        [80.11513519287115, 15.355972290039062],
        [80.11513519287115, 15.35541629791254],
        [80.11486053466814, 15.35542011260992],
        [80.11486053466814, 15.354860305786076],
        [80.11458587646513, 15.35486221313488],
        [80.11458587646513, 15.354306221008414],
        [80.11430358886719, 15.354306221008414],
        [80.11430358886719, 15.353750228881893],
        [80.11402893066435, 15.353750228881893],
        [80.11402893066435, 15.353191375732365],
        [80.11374664306646, 15.353194236755428],
        [80.11374664306646, 15.35291767120367],
        [80.1134719848634, 15.352919578552246],
        [80.1134719848634, 15.352361679077205],
        [80.11319732666021, 15.352361679077205],
        [80.11319732666021, 15.35181045532238],
        [80.1129226684572, 15.35181045532238],
        [80.1129226684572, 15.351240158081112],
        [80.1126403808596, 15.35124874114996],
        [80.1126403808596, 15.35069561004633],
        [80.11235809326189, 15.35069561004633],
        [80.11235809326189, 15.350139617919865],
        [80.11207580566412, 15.350139617919865],
        [80.11207580566412, 15.349580764770508],
        [80.11180877685564, 15.34958267211914],
        [80.11180877685564, 15.349026679992619],
        [80.1115264892581, 15.349029541015682],
        [80.1115264892581, 15.348752021789608],
        [80.1112365722658, 15.348752021789608],
        [80.1112365722658, 15.347920417785645],
        [80.11096954345732, 15.347920417785645],
        [80.11096954345732, 15.347360610961971],
        [80.11069488525396, 15.347360610961971],
        [80.11069488525396, 15.346804618835506],
        [80.1104202270509, 15.346810340881461],
        [80.1104202270509, 15.346250534057617],
        [80.11013793945318, 15.346250534057617],
        [80.11013793945318, 15.345431327819881],
        [80.10986328124994, 15.345431327819881],
        [80.10986328124994, 15.344861030578613],
        [80.10958099365257, 15.344861030578613],
        [80.10958099365257, 15.344031333923397],
        [80.10930633544939, 15.344031333923397],
        [80.10930633544939, 15.343470573425293],
        [80.10902404785162, 15.343473434448356],
        [80.10902404785162, 15.3426389694215],
        [80.10874938964861, 15.342640876770076],
        [80.10874938964861, 15.342080116271973],
        [80.1084747314456, 15.342082977295036],
        [80.1084747314456, 15.341251373291072],
        [80.10819244384766, 15.341251373291072],
        [80.10819244384766, 15.34069538116455],
        [80.10791778564482, 15.34069538116455],
        [80.10791778564482, 15.339860916137695],
        [80.10763549804688, 15.339860916137695],
        [80.10763549804688, 15.339306831359863],
        [80.10736083984386, 15.339306831359863],
        [80.10736083984386, 15.338473320007267],
        [80.10708618164068, 15.338473320007267],
        [80.10708618164068, 15.337360382080021],
        [80.10680389404291, 15.337360382080021],
        [80.10680389404291, 15.336528778076229],
        [80.10652923584007, 15.336530685424862],
        [80.10652923584007, 15.335689544677734],
        [80.10624694824236, 15.335694313049373],
        [80.10624694824236, 15.33458423614502],
        [80.10597229003912, 15.33458423614502],
        [80.10597229003912, 15.333749771118164],
        [80.10569763183611, 15.333749771118164],
        [80.10569763183611, 15.333470344543514],
        [80.10541534423857, 15.33347225189209],
        [80.10541534423857, 15.331806182861442],
        [80.10514068603516, 15.33181095123291],
        [80.10514068603516, 15.329310417175407],
        [80.10543060302763, 15.329310417175407],
        [80.10543060302763, 15.327919960022086],
        [80.10514068603516, 15.327919960022086],
        [80.10514068603516, 15.327360153198242],
        [80.10485839843778, 15.327362060546875],
        [80.10485839843778, 15.327083587646484],
        [80.10458374023438, 15.327083587646484],
        [80.10458374023438, 15.326806068420353],
        [80.10430908203136, 15.326812744140625],
        [80.10430908203136, 15.326530456543082],
        [80.10402679443365, 15.326530456543082],
        [80.10402679443365, 15.326251983642692],
        [80.10375213623041, 15.326251983642692],
        [80.10375213623041, 15.325971603393498],
        [80.10346984863287, 15.325971603393498],
        [80.10346984863287, 15.325694084167424],
        [80.10319519042986, 15.325694084167424],
        [80.10319519042986, 15.325414657592773],
        [80.10263824462908, 15.325420379638729],
        [80.10263824462908, 15.324861526489371],
        [80.10236358642607, 15.324861526489371],
        [80.10236358642607, 15.32458400726324],
        [80.10097503662115, 15.32458400726324],
        [80.10097503662115, 15.324861526489371],
        [80.1001358032226, 15.324861526489371],
        [80.1001358032226, 15.325141906738338],
        [80.09929656982428, 15.325141906738338],
        [80.09929656982428, 15.324861526489371],
        [80.0990295410158, 15.324861526489371],
        [80.0990295410158, 15.324027061462516],
        [80.09929656982428, 15.324031829833984],
        [80.09929656982428, 15.323748588562125],
        [80.09958648681658, 15.323748588562125],
        [80.09958648681658, 15.323471069335994],
        [80.09986114501976, 15.323471069335994],
        [80.09986114501976, 15.323196411132812],
        [80.10041809082037, 15.323196411132812],
        [80.10041809082037, 15.322639465332031],
        [80.10069274902355, 15.322639465332031],
        [80.10069274902355, 15.322080612182674],
        [80.10097503662115, 15.322083473205566],
        [80.10097503662115, 15.321249008178711],
        [80.1012496948245, 15.321261405944938],
        [80.1012496948245, 15.319581031799316],
        [80.10097503662115, 15.319582939148063],
        [80.10097503662115, 15.319305419921989],
        [80.1012496948245, 15.319310188293457],
        [80.1012496948245, 15.319031715393066],
        [80.10098266601568, 15.319031715393066],
        [80.10098266601568, 15.31820201873785],
        [80.10069274902355, 15.31820201873785],
        [80.10069274902355, 15.317921638488713],
        [80.10041809082037, 15.317921638488713],
        [80.10041809082037, 15.31736087799078],
        [80.1001358032226, 15.31736087799078],
        [80.1001358032226, 15.317081451416072],
        [80.09986114501976, 15.317083358764705],
        [80.09986114501976, 15.31652736663824],
        [80.09958648681658, 15.316529273986816],
        [80.09958648681658, 15.31624889373785],
        [80.09929656982428, 15.31624889373785],
        [80.09929656982428, 15.315690040588493],
        [80.0990295410158, 15.315694808959961],
        [80.0990295410158, 15.315417289733887],
        [80.09874725341825, 15.315419197082633],
        [80.09874725341825, 15.315130233764648],
        [80.09847259521484, 15.315138816833496],
        [80.09847259521484, 15.314861297607365],
        [80.098197937012, 15.314861297607365],
        [80.098197937012, 15.3143053054809],
        [80.09792327880865, 15.314311027526855],
        [80.09792327880865, 15.314030647277889],
        [80.09764099121105, 15.314030647277889],
        [80.09764099121105, 15.313751220703239],
        [80.09707641601557, 15.313751220703239],
        [80.09707641601557, 15.313472747802848],
        [80.0962524414063, 15.313472747802848],
        [80.0962524414063, 15.313195228576717],
        [80.09569549560575, 15.313195228576717],
        [80.09570312500028, 15.313472747802848],
        [80.09430694580084, 15.313472747802848],
        [80.09430694580084, 15.313751220703239],
        [80.09401702880871, 15.313751220703239],
        [80.09401702880871, 15.314030647277889],
        [80.09208679199247, 15.314030647277889],
        [80.09208679199247, 15.314311027526855],
        [80.08949279785173, 15.31430912017828],
        [80.08875274658203, 15.314311027526855],
        [80.08875274658203, 15.314030647277889],
        [80.08790588378918, 15.314030647277889],
        [80.08790588378918, 15.314311027526855],
        [80.08736419677751, 15.3143053054809],
        [80.08736419677751, 15.314582824707031],
        [80.08597564697294, 15.314582824707031],
        [80.08597564697294, 15.314861297607365],
        [80.08541870117216, 15.314861297607365],
        [80.08541870117216, 15.315138816833496],
        [80.0837478637697, 15.315130233764648],
        [80.0837478637697, 15.315419197082633],
        [80.08347320556646, 15.315417289733887],
        [80.08347320556646, 15.315694808959961],
        [80.0826416015625, 15.315694808959961],
        [80.0826416015625, 15.315973281860352],
        [80.08125305175776, 15.315973281860352],
        [80.08126068115251, 15.31624889373785],
        [80.08013916015642, 15.31624889373785],
        [80.08013916015642, 15.315971374511719],
        [80.07986450195341, 15.315973281860352],
        [80.07986450195341, 15.315694808959961],
        [80.08013916015642, 15.315694808959961],
        [80.08013916015642, 15.315417289733887],
        [80.08040618896507, 15.315419197082633],
        [80.08040618896507, 15.315130233764648],
        [80.08125305175776, 15.315138816833496],
        [80.08125305175776, 15.314582824707031],
        [80.081527709961, 15.314582824707031],
        [80.081527709961, 15.3143053054809],
        [80.08235931396513, 15.314311027526855],
        [80.08235931396513, 15.314030647277889],
        [80.08291625976591, 15.314030647277889],
        [80.08291625976591, 15.313751220703239],
        [80.08319091796892, 15.313751220703239],
        [80.08319091796892, 15.313472747802848],
        [80.0837478637697, 15.313472747802848],
        [80.0837478637697, 15.313190460205078],
        [80.08430480957026, 15.313195228576717],
        [80.08430480957026, 15.312916755676383],
        [80.08513641357428, 15.312920570373535],
        [80.08513641357428, 15.312641143798885],
        [80.08598327636747, 15.312641143798885],
        [80.08598327636747, 15.312360763549862],
        [80.08708190917991, 15.312360763549862],
        [80.08706665039068, 15.312070846557617],
        [80.0881958007813, 15.312083244323787],
        [80.0881958007813, 15.31180667877203],
        [80.08885192871122, 15.31180667877203],
        [80.08902740478544, 15.311811447143668],
        [80.08902740478544, 15.311531066894531],
        [80.08985900878923, 15.311531066894531],
        [80.08985900878923, 15.311250686645508],
        [80.09069061279303, 15.311250686645508],
        [80.09069824218773, 15.310970306396541],
        [80.09236145019531, 15.310973167419434],
        [80.09236145019531, 15.310694694519043],
        [80.09375000000023, 15.310694694519043],
        [80.09375000000023, 15.310416221618652],
        [80.09485626220703, 15.310420989990348],
        [80.09485626220703, 15.310140609741154],
        [80.09597015380876, 15.310140609741154],
        [80.09597015380876, 15.309860229492188],
        [80.09764099121105, 15.309860229492188],
        [80.09764099121105, 15.310140609741154],
        [80.0990295410158, 15.310140609741154],
        [80.0990295410158, 15.309860229492188],
        [80.0993041992188, 15.309860229492188],
        [80.0993041992188, 15.30958461761469],
        [80.0990295410158, 15.30958461761469],
        [80.0990295410158, 15.309304237365723],
        [80.09874725341825, 15.309320449829102],
        [80.09874725341825, 15.309030532837028],
        [80.09847259521484, 15.309030532837028],
        [80.09847259521484, 15.308469772338867],
        [80.098197937012, 15.308471679687443],
        [80.098197937012, 15.307915687560978],
        [80.09792327880865, 15.307921409606934],
        [80.09792327880865, 15.30764007568365],
        [80.09764099121105, 15.30764007568365],
        [80.09764099121105, 15.307359695434513],
        [80.09735870361334, 15.307359695434513],
        [80.09735870361334, 15.307080268859863],
        [80.0970840454101, 15.30708217620861],
        [80.0970840454101, 15.30624961853033],
        [80.09680175781273, 15.306262016296387],
        [80.09680175781273, 15.305689811706543],
        [80.09652709960955, 15.305693626403865],
        [80.09652709960955, 15.3051376342774],
        [80.09626007080107, 15.305139541625977],
        [80.09626007080107, 15.30403041839611],
        [80.09597015380876, 15.30403041839611],
        [80.09597015380876, 15.303750038146973],
        [80.09570312500028, 15.303750038146973],
        [80.09570312500028, 15.30318927764904],
        [80.09541320800781, 15.303194046020508],
        [80.09541320800781, 15.302362442016715],
        [80.09513854980497, 15.302362442016715],
        [80.09513854980497, 15.30152797698986],
        [80.09485626220703, 15.301531791687012],
        [80.09485626220703, 15.300971984863395],
        [80.09458160400402, 15.300971984863395],
        [80.09458160400402, 15.300694465637264],
        [80.09430694580084, 15.300694465637264],
        [80.09430694580084, 15.300138473510799],
        [80.09402465820307, 15.300138473510799],
        [80.09402465820307, 15.299861907959041],
        [80.09375000000023, 15.299861907959041],
        [80.09375000000023, 15.299309730529899],
        [80.09349060058611, 15.299309730529899],
        [80.09349060058611, 15.299030303955021],
        [80.0932006835938, 15.299030303955021],
        [80.0932006835938, 15.298191070556697],
        [80.09291839599626, 15.298195838928166],
        [80.09291839599626, 15.297362327575797],
        [80.09263610839872, 15.297362327575797],
        [80.09263610839872, 15.296805381774846],
        [80.09236145019531, 15.296810150146541],
        [80.09236145019531, 15.29624938964838],
        [80.09208679199247, 15.29624938964838],
        [80.09208679199247, 15.295419692993164],
        [80.091796875, 15.295419692993164],
        [80.091796875, 15.294860839843807],
        [80.09152984619152, 15.294860839843807],
        [80.09152984619152, 15.29430961608881],
        [80.0912475585938, 15.29430961608881],
        [80.0912475585938, 15.293750762939453],
        [80.09097290039057, 15.293750762939453],
        [80.09097290039057, 15.2926406860351],
        [80.09069824218773, 15.2926406860351],
        [80.09069061279303, 15.2918119430542],
        [80.09041595459001, 15.2918119430542],
        [80.09041595459001, 15.290691375732422],
        [80.09014129638678, 15.290695190429801],
        [80.09014129638678, 15.289860725402946],
        [80.08985900878923, 15.289860725402946],
        [80.08985900878923, 15.288750648498592],
        [80.08957672119146, 15.288750648498592],
        [80.08957672119146, 15.287920951843375],
        [80.08930206298845, 15.287920951843375],
        [80.08930206298845, 15.287079811096305],
        [80.08902740478544, 15.287082672119197],
        [80.08902740478544, 15.286251068115178],
        [80.08875274658203, 15.286251068115178],
        [80.08875274658203, 15.285690307617244],
        [80.08847045898466, 15.285695075988713],
        [80.08847045898466, 15.284860610961857],
        [80.0881958007813, 15.284860610961857],
        [80.0881958007813, 15.284029006958065],
        [80.08790588378918, 15.284030914306697],
        [80.08790588378918, 15.28318977355957],
        [80.08763885498053, 15.28319358825695],
        [80.08763885498053, 15.282360076904354],
        [80.08735656738276, 15.282360076904354],
        [80.08735656738276, 15.28181076049816],
        [80.08706665039068, 15.28181076049816],
        [80.08706665039068, 15.27985954284668],
        [80.08680725097673, 15.279862403869743],
        [80.08680725097673, 15.279306411743278],
        [80.08653259277372, 15.279311180114746],
        [80.08653259277372, 15.27764034271246],
        [80.08625030517595, 15.27764034271246],
        [80.08625030517595, 15.277079582214355],
        [80.08597564697294, 15.277084350585994],
        [80.08597564697294, 15.27652835845953],
        [80.085693359375, 15.276530265808105],
        [80.085693359375, 15.275959968566838],
        [80.08541870117216, 15.275972366333065],
        [80.08541870117216, 15.275416374206543],
        [80.08513641357428, 15.275420188903922],
        [80.08513641357428, 15.274860382080078],
        [80.08486175537121, 15.274862289428711],
        [80.08486175537121, 15.270415306091365],
        [80.08458709716803, 15.270420074462834],
        [80.08458709716803, 15.269309997558707],
        [80.08429718017572, 15.269309997558707],
        [80.08429718017572, 15.268470764160156],
        [80.08403015136741, 15.268473625183049],
        [80.08403015136741, 15.267639160156193],
        [80.0837478637697, 15.267642021179256],
        [80.0837478637697, 15.267081260681152],
        [80.08347320556646, 15.267083168029899],
        [80.08347320556646, 15.26402664184576],
        [80.08319091796892, 15.26402664184576],
        [80.08319091796892, 15.262360572814941],
        [80.08291625976591, 15.262360572814941],
        [80.08291625976591, 15.253749847412166],
        [80.08319091796892, 15.253752708435059],
        [80.08319091796892, 15.252639770507812],
        [80.08347320556646, 15.252639770507812],
        [80.08347320556646, 15.251529693603459],
        [80.0837478637697, 15.251529693603459],
        [80.0837478637697, 15.250140190124625],
        [80.08403015136741, 15.250140190124625],
        [80.08403015136741, 15.248749732971305],
        [80.08429718017572, 15.248749732971305],
        [80.08429718017572, 15.247639656066951],
        [80.08458709716803, 15.247639656066951],
        [80.08458709716803, 15.246529579162598],
        [80.08486175537121, 15.246529579162598],
        [80.08486175537121, 15.245691299438533],
        [80.08513641357428, 15.245694160461426],
        [80.08513641357428, 15.245140075683537],
        [80.08541870117216, 15.24514198303234],
        [80.08541870117216, 15.244310379028377],
        [80.085693359375, 15.244310379028377],
        [80.085693359375, 15.24346923828125],
        [80.08597564697294, 15.243471145629826],
        [80.08597564697294, 15.242918014526424],
        [80.08625030517595, 15.242918014526424],
        [80.08625030517595, 15.241805076599178],
        [80.08651733398443, 15.24181175231945],
        [80.08651733398443, 15.240420341491813],
        [80.08680725097673, 15.240420341491813],
        [80.08680725097673, 15.238751411438102],
        [80.08706665039068, 15.238751411438102],
        [80.08706665039068, 15.237360954284782],
        [80.08735656738276, 15.237360954284782],
        [80.08735656738276, 15.232362747192496],
        [80.08763885498053, 15.232362747192496],
        [80.08763885498053, 15.227359771728516],
        [80.08790588378918, 15.227359771728516],
        [80.08790588378918, 15.225139617919979],
        [80.0881958007813, 15.225139617919979],
        [80.0881958007813, 15.224029541015625],
        [80.08847045898466, 15.224029541015625],
        [80.08847045898466, 15.21930980682373],
        [80.0881958007813, 15.21930980682373],
        [80.0881958007813, 15.21792030334467],
        [80.08790588378918, 15.21792030334467],
        [80.08790588378918, 15.215682029724178],
        [80.08763885498053, 15.215693473815918],
        [80.08763885498053, 15.213473320007381],
        [80.08736419677751, 15.213473320007381],
        [80.08736419677751, 15.211805343627987],
        [80.08706665039068, 15.211812019348201],
        [80.08706665039068, 15.210691452026424],
        [80.08680725097673, 15.210695266723633],
        [80.08680725097673, 15.209860801696777],
        [80.08651733398443, 15.209870338439885],
        [80.08651733398443, 15.20818996429449],
        [80.08625030517595, 15.20819473266613],
        [80.08625030517595, 15.206526756286678],
        [80.08598327636747, 15.206530570983887],
        [80.08598327636747, 15.20486068725586],
        [80.085693359375, 15.20486068725586],
        [80.085693359375, 15.203189849853572],
        [80.08541870117216, 15.20319461822504],
        [80.08541870117216, 15.201527595520133],
        [80.08513641357428, 15.201531410217285],
        [80.08513641357428, 15.200421333313102],
        [80.08486175537121, 15.200421333313102],
        [80.08486175537121, 15.199311256408748],
        [80.08458709716803, 15.199311256408748],
        [80.08458709716803, 15.198472023010254],
        [80.08430480957026, 15.198472023010254],
        [80.08430480957026, 15.197360038757324],
        [80.08403015136741, 15.197360038757324],
        [80.08403015136741, 15.196530342102108],
        [80.0837478637697, 15.196530342102108],
        [80.0837478637697, 15.195420265197754],
        [80.08347320556646, 15.195420265197754],
        [80.08347320556646, 15.19458198547369],
        [80.08319091796892, 15.194583892822322],
        [80.08319091796892, 15.194306373596191],
        [80.08291625976591, 15.19431018829357],
        [80.08291625976591, 15.193750381469727],
        [80.0826416015625, 15.193750381469727],
        [80.0826416015625, 15.19319057464611],
        [80.08235931396513, 15.193194389343262],
        [80.08235931396513, 15.192917823791504],
        [80.08207702636719, 15.19291973114025],
        [80.08207702636719, 15.192640304565373],
        [80.08180236816418, 15.192640304565373],
        [80.08180236816418, 15.192361831664982],
        [80.081527709961, 15.192361831664982],
        [80.081527709961, 15.192081451416016],
        [80.08125305175776, 15.192084312438908],
        [80.08125305175776, 15.191805839538517],
        [80.08097076416038, 15.191805839538517],
        [80.08097076416038, 15.191249847412053],
        [80.0806961059572, 15.191251754760856],
        [80.0806961059572, 15.190972328186149],
        [80.08041381835943, 15.190972328186149],
        [80.08041381835943, 15.190695762634334],
        [80.08013916015642, 15.190695762634334],
        [80.08013916015642, 15.190415382385197],
        [80.07903289794928, 15.190420150756836],
        [80.07903289794928, 15.19013977050787],
        [80.07875061035168, 15.19013977050787],
        [80.07875061035168, 15.189859390258903],
        [80.0784759521485, 15.189862251281795],
        [80.0784759521485, 15.189583778381404],
        [80.07819366455072, 15.189583778381404],
        [80.07819366455072, 15.189026832580623],
        [80.07707977294939, 15.189029693603516],
        [80.07707977294939, 15.189310073852482],
        [80.07569122314482, 15.189305305481014],
        [80.07569122314482, 15.189583778381404],
        [80.07514190673822, 15.189583778381404],
        [80.07514190673822, 15.189862251281795],
        [80.07501220703125, 15.189862251281795],
        [80.07457733154291, 15.189859390258903],
        [80.07457733154291, 15.19013977050787],
        [80.07430267334007, 15.19013977050787],
        [80.07430267334007, 15.190420150756836],
        [80.07402801513689, 15.190415382385197],
        [80.07402801513689, 15.190695762634334],
        [80.07347106933611, 15.190695762634334],
        [80.07347106933611, 15.190972328186149],
        [80.07290649414062, 15.190972328186149],
        [80.07290649414062, 15.191251754760856],
        [80.07206726074247, 15.191251754760856],
        [80.07206726074247, 15.19153022766119],
        [80.07180786132818, 15.191528320312614],
        [80.07180786132818, 15.191805839538517],
        [80.07152557373041, 15.191805839538517],
        [80.07152557373041, 15.192084312438908],
        [80.07068634033209, 15.192081451416016],
        [80.07068634033209, 15.192361831664982],
        [80.07041931152361, 15.192361831664982],
        [80.07041931152361, 15.192640304565373],
        [80.06986236572266, 15.192640304565373],
        [80.06986236572266, 15.19291973114025],
        [80.06958007812528, 15.192917823791504],
        [80.06958007812528, 15.193471908569336],
        [80.06930541992193, 15.193471908569336],
        [80.069320678711, 15.193750381469727],
        [80.06903076171886, 15.193750381469727],
        [80.06903076171886, 15.194029808044434],
        [80.06874847412115, 15.194029808044434],
        [80.06874847412115, 15.19431018829357],
        [80.06846618652338, 15.19431018829357],
        [80.06846618652338, 15.194870948791504],
        [80.06819152832054, 15.194870948791504],
        [80.06819152832054, 15.195420265197754],
        [80.06791687011736, 15.195416450500545],
        [80.06791687011736, 15.19568920135498],
        [80.06764221191412, 15.195693969726676],
        [80.06764221191412, 15.195972442627067],
        [80.06735992431658, 15.195969581604004],
        [80.06735992431658, 15.196249961853141],
        [80.0670928955081, 15.196249961853141],
        [80.0670928955081, 15.196810722351074],
        [80.06680297851562, 15.196805953979606],
        [80.06680297851562, 15.197084426879996],
        [80.06652832031278, 15.197079658508358],
        [80.06652832031278, 15.197640419006461],
        [80.06597137451183, 15.197640419006461],
        [80.06597137451183, 15.197930335998535],
        [80.06569671630865, 15.197916030883789],
        [80.06569671630865, 15.198193550109863],
        [80.06513977050804, 15.198193550109863],
        [80.06513977050804, 15.198472023010254],
        [80.06485748291033, 15.198472023010254],
        [80.06485748291033, 15.198749542236328],
        [80.06430816650408, 15.198749542236328],
        [80.06430816650408, 15.199311256408748],
        [80.06375122070312, 15.199305534362793],
        [80.06375122070312, 15.199582099914608],
        [80.06346893310575, 15.199582099914608],
        [80.06346893310575, 15.199861526489258],
        [80.06318664550781, 15.199859619140682],
        [80.06318664550781, 15.200139999389648],
        [80.06291961669933, 15.200139999389648],
        [80.06291961669933, 15.200421333313102],
        [80.06236267089838, 15.200415611267147],
        [80.06236267089838, 15.200694084167537],
        [80.06208038330084, 15.200694084167537],
        [80.06208038330084, 15.200971603393612],
        [80.06153106689459, 15.200969696045036],
        [80.06153106689459, 15.201250076294002],
        [80.06124877929705, 15.201250076294002],
        [80.06124877929705, 15.201531410217285],
        [80.06096649169928, 15.201531410217285],
        [80.06096649169928, 15.201810836791992],
        [80.06069183349626, 15.201804161071891],
        [80.06069183349626, 15.202084541320858],
        [80.05986022949247, 15.202084541320858],
        [80.05986022949247, 15.202360153198185],
        [80.05930328369152, 15.202360153198185],
        [80.05930328369152, 15.202640533447322],
        [80.05874633789057, 15.202640533447322],
        [80.05874633789057, 15.202920913696346],
        [80.05819702148455, 15.20291614532465],
        [80.05819702148455, 15.20319461822504],
        [80.05791473388678, 15.20319461822504],
        [80.05791473388678, 15.203473091125602],
        [80.05764007568376, 15.203473091125602],
        [80.05764007568376, 15.203750610351506],
        [80.05735778808622, 15.203742027282715],
        [80.05735778808622, 15.2040309906007],
        [80.05709075927734, 15.2040309906007],
        [80.05709075927734, 15.204311370849666],
        [80.05680847167997, 15.204311370849666],
        [80.05680847167997, 15.20486068725586],
        [80.05652618408209, 15.20486068725586],
        [80.05652618408209, 15.205141067504826],
        [80.05625152587902, 15.205141067504826],
        [80.05625152587902, 15.20542144775402],
        [80.0559692382813, 15.205416679382324],
        [80.0559692382813, 15.205695152282715],
        [80.05541992187523, 15.205695152282715],
        [80.05541992187523, 15.205972671508846],
        [80.05513763427751, 15.205970764160213],
        [80.05513763427751, 15.20625114440918],
        [80.05486297607428, 15.20625114440918],
        [80.05486297607428, 15.206530570983887],
        [80.05458068847673, 15.206530570983887],
        [80.05458068847673, 15.20681190490734],
        [80.05403137207031, 15.206805229187069],
        [80.05403137207031, 15.2070827484132],
        [80.05347442626959, 15.2070827484132],
        [80.05347442626959, 15.207361221313533],
        [80.0529174804688, 15.207361221313533],
        [80.0529174804688, 15.20764064788824],
        [80.05208587646501, 15.20764064788824],
        [80.05208587646501, 15.207921028137207],
        [80.05152893066423, 15.207917213440055],
        [80.05152893066423, 15.20819473266613],
        [80.0504150390625, 15.20819473266613],
        [80.0504150390625, 15.20847320556652],
        [80.05014038085949, 15.208471298217717],
        [80.05014038085949, 15.208750724792594],
        [80.04957580566418, 15.208750724792594],
        [80.04957580566418, 15.209029197692985],
        [80.04875183105474, 15.209029197692985],
        [80.04875183105474, 15.209308624267635],
        [80.0484695434572, 15.209304809570312],
        [80.0484695434572, 15.209583282470703],
        [80.04736328125, 15.20958137512207],
        [80.04736328125, 15.209870338439885],
        [80.04651641845714, 15.209870338439885],
        [80.04651641845714, 15.20958137512207],
        [80.0462493896485, 15.209583282470703],
        [80.0462493896485, 15.209304809570312],
        [80.04596710205072, 15.209308624267635],
        [80.04596710205072, 15.208471298217717],
        [80.0462493896485, 15.20847320556652],
        [80.0462493896485, 15.20819473266613],
        [80.04680633544928, 15.20819473266613],
        [80.04680633544928, 15.207917213440055],
        [80.04736328125, 15.207921028137207],
        [80.04736328125, 15.20764064788824],
        [80.04818725585943, 15.20764064788824],
        [80.04818725585943, 15.207361221313533],
        [80.04875183105474, 15.207361221313533],
        [80.04875183105474, 15.2070827484132],
        [80.04902648925798, 15.2070827484132],
        [80.04902648925798, 15.206805229187069],
        [80.04957580566418, 15.20681190490734],
        [80.04957580566418, 15.206530570983887],
        [80.05014038085949, 15.206530570983887],
        [80.05014038085949, 15.205970764160213],
        [80.0504150390625, 15.205972671508846],
        [80.0504150390625, 15.205695152282715],
        [80.05097198486328, 15.205695152282715],
        [80.05097198486328, 15.20513916015625],
        [80.05124664306669, 15.205141067504826],
        [80.05124664306669, 15.20486068725586],
        [80.05152893066423, 15.20486068725586],
        [80.05152893066423, 15.204580307006893],
        [80.05180358886724, 15.204583168029785],
        [80.05180358886724, 15.20430660247797],
        [80.0523605346682, 15.204311370849666],
        [80.0523605346682, 15.2040309906007],
        [80.05264282226557, 15.2040309906007],
        [80.05264282226557, 15.203742027282715],
        [80.0529174804688, 15.203750610351506],
        [80.0529174804688, 15.20319461822504],
        [80.05319213867199, 15.20319461822504],
        [80.05319213867199, 15.20291614532465],
        [80.053466796875, 15.202920913696346],
        [80.053466796875, 15.202640533447322],
        [80.05374908447294, 15.202640533447322],
        [80.05374908447294, 15.202360153198185],
        [80.05403137207031, 15.202360153198185],
        [80.05403137207031, 15.202084541320858],
        [80.05430603027372, 15.202084541320858],
        [80.05430603027372, 15.201804161071891],
        [80.05486297607428, 15.201810836791992],
        [80.05486297607428, 15.201531410217285],
        [80.05513763427751, 15.201531410217285],
        [80.05513763427751, 15.201250076294002],
        [80.05541992187523, 15.201250076294002],
        [80.05541992187523, 15.20068073272705],
        [80.05569458007807, 15.200694084167537],
        [80.05569458007807, 15.199861526489258],
        [80.0559692382813, 15.199861526489258],
        [80.0559692382813, 15.199582099914608],
        [80.05625152587902, 15.199582099914608],
        [80.05625152587902, 15.199305534362793],
        [80.05652618408209, 15.199311256408748],
        [80.05652618408209, 15.199030876159782],
        [80.05680847167997, 15.199030876159782],
        [80.05680847167997, 15.198472023010254],
        [80.05708312988281, 15.198472023010254],
        [80.05708312988281, 15.198193550109863],
        [80.05735778808622, 15.198193550109863],
        [80.05735778808622, 15.197916030883789],
        [80.05764007568376, 15.197930335998535],
        [80.05764007568376, 15.197640419006461],
        [80.05790710449224, 15.197640419006461],
        [80.05790710449224, 15.197360038757324],
        [80.05847167968773, 15.197360038757324],
        [80.05847167968773, 15.197079658508358],
        [80.05902862548834, 15.197084426879996],
        [80.05902862548834, 15.196805953979606],
        [80.05930328369152, 15.196810722351074],
        [80.05930328369152, 15.196530342102108],
        [80.05959320068365, 15.196530342102108],
        [80.05959320068365, 15.196249961853141],
        [80.05986022949247, 15.196249961853141],
        [80.05986022949247, 15.19568920135498],
        [80.06014251708984, 15.195693969726676],
        [80.06014251708984, 15.195416450500545],
        [80.06041717529325, 15.195420265197754],
        [80.06041717529325, 15.195141792297363],
        [80.06069183349626, 15.195141792297363],
        [80.06069183349626, 15.194870948791504],
        [80.06096649169928, 15.194870948791504],
        [80.06096649169928, 15.19458198547369],
        [80.06124877929705, 15.194583892822322],
        [80.06124877929705, 15.194306373596191],
        [80.06153106689459, 15.19431018829357],
        [80.06153106689459, 15.194029808044434],
        [80.06181335449236, 15.194029808044434],
        [80.06181335449236, 15.193750381469727],
        [80.06208038330084, 15.193750381469727],
        [80.06208038330084, 15.19319057464611],
        [80.06236267089838, 15.193194389343262],
        [80.06236267089838, 15.192917823791504],
        [80.06263732910162, 15.19291973114025],
        [80.06263732910162, 15.192640304565373],
        [80.06291961669933, 15.192640304565373],
        [80.06291961669933, 15.192361831664982],
        [80.06346893310575, 15.192361831664982],
        [80.06346893310575, 15.192081451416016],
        [80.06375122070312, 15.192084312438908],
        [80.06375122070312, 15.191528320312614],
        [80.06346893310575, 15.19153022766119],
        [80.06346893310575, 15.191251754760856],
        [80.06236267089838, 15.191251754760856],
        [80.06236267089838, 15.19153022766119],
        [80.06208038330084, 15.191528320312614],
        [80.06208038330084, 15.191805839538517],
        [80.06124877929705, 15.191805839538517],
        [80.06124877929705, 15.191528320312614],
        [80.06096649169928, 15.19153022766119],
        [80.06096649169928, 15.191251754760856],
        [80.06069183349626, 15.191251754760856],
        [80.06069183349626, 15.190690994262695],
        [80.06097412109403, 15.190695762634334],
        [80.06097412109403, 15.190415382385197],
        [80.06181335449236, 15.190420150756836],
        [80.06181335449236, 15.19013977050787],
        [80.06291961669933, 15.19013977050787],
        [80.06291961669933, 15.189859390258903],
        [80.06319427490234, 15.189862251281795],
        [80.06319427490234, 15.189583778381404],
        [80.06430816650408, 15.189583778381404],
        [80.06430816650408, 15.189305305481014],
        [80.06513977050804, 15.189310073852482],
        [80.06513977050804, 15.188739776611442],
        [80.06569671630865, 15.188749313354549],
        [80.06569671630865, 15.188470840454158],
        [80.06597137451183, 15.188470840454158],
        [80.06597137451183, 15.188191413879508],
        [80.06652832031278, 15.188193321228084],
        [80.06652832031278, 15.187639236450195],
        [80.06680297851562, 15.187639236450195],
        [80.06680297851562, 15.187081336975154],
        [80.06735992431658, 15.18708324432373],
        [80.06735992431658, 15.18680477142334],
        [80.06791687011736, 15.186809539794979],
        [80.06791687011736, 15.186532020568904],
        [80.06846618652338, 15.186532020568904],
        [80.06846618652338, 15.186250686645451],
        [80.06874847412115, 15.186250686645451],
        [80.06874847412115, 15.1859712600708],
        [80.06930541992193, 15.1859712600708],
        [80.06930541992193, 15.185694694518986],
        [80.06986236572266, 15.185700416564941],
        [80.06986236572266, 15.18542194366455],
        [80.07013702392607, 15.18542194366455],
        [80.07013702392607, 15.185140609741268],
        [80.07068634033209, 15.185140609741268],
        [80.07068634033209, 15.184861183166618],
        [80.07125091552757, 15.184861183166618],
        [80.07125091552757, 15.184582710266227],
        [80.07152557373041, 15.184582710266227],
        [80.07152557373041, 15.184305191040153],
        [80.07312011718756, 15.184305191040153],
        [80.0731964111331, 15.184305191040153],
        [80.0731964111331, 15.184032440185547],
        [80.07347106933611, 15.184032440185547],
        [80.07347106933611, 15.183751106262264],
        [80.07402801513689, 15.183751106262264],
        [80.07402801513689, 15.18319129943842],
        [80.07485961914068, 15.183195114135799],
        [80.07485961914068, 15.182916641235408],
        [80.07541656494146, 15.182930946350211],
        [80.07541656494146, 15.18264102935791],
        [80.07596588134766, 15.18264102935791],
        [80.07596588134766, 15.182360649108944],
        [80.0762481689456, 15.182360649108944],
        [80.0762481689456, 15.182081222534237],
        [80.07736206054693, 15.18208312988287],
        [80.07736206054693, 15.181804656982479],
        [80.07818603515636, 15.181809425354118],
        [80.07818603515636, 15.182360649108944],
        [80.0784759521485, 15.182360649108944],
        [80.0784759521485, 15.182930946350211],
        [80.07875061035168, 15.182916641235408],
        [80.07875061035168, 15.183472633361873],
        [80.07903289794928, 15.183470726013297],
        [80.07903289794928, 15.184032440185547],
        [80.07930755615263, 15.184026718139762],
        [80.07930755615263, 15.184582710266227],
        [80.07958221435547, 15.18458080291748],
        [80.07958221435547, 15.185140609741268],
        [80.07985687255888, 15.185140609741268],
        [80.07985687255888, 15.185700416564941],
        [80.08013916015642, 15.185694694518986],
        [80.08013916015642, 15.1859712600708],
        [80.08040618896507, 15.1859712600708],
        [80.08040618896507, 15.186250686645451],
        [80.08097076416038, 15.186250686645451],
        [80.08097076416038, 15.1859712600708],
        [80.08125305175776, 15.1859712600708],
        [80.08125305175776, 15.185417175293082],
        [80.08097076416038, 15.18542194366455],
        [80.08097076416038, 15.18458080291748],
        [80.0806961059572, 15.184582710266227],
        [80.0806961059572, 15.183751106262264],
        [80.08040618896507, 15.183751106262264],
        [80.08040618896507, 15.18319129943842],
        [80.08013916015642, 15.183195114135799],
        [80.08013916015642, 15.182639122009334],
        [80.07985687255888, 15.18264102935791],
        [80.07985687255888, 15.182081222534237],
        [80.07958221435547, 15.18208312988287],
        [80.07958221435547, 15.18152904510498],
        [80.07930755615263, 15.181530952453727],
        [80.07930755615263, 15.180971145629883],
        [80.07902526855469, 15.180973052978516],
        [80.07902526855469, 15.180694580078125],
        [80.07819366455072, 15.180694580078125],
        [80.07819366455072, 15.179861068725529],
        [80.07791900634788, 15.179861068725529],
        [80.07791900634788, 15.179571151733455],
        [80.07763671875017, 15.179582595825138],
        [80.07763671875017, 15.179026603698674],
        [80.07736206054693, 15.17903137207037],
        [80.07736206054693, 15.17846965789795],
        [80.07707977294939, 15.178473472595272],
        [80.07707977294939, 15.178194999694881],
        [80.07680511474638, 15.178194999694881],
        [80.07680511474638, 15.177639007568416],
        [80.07653045654297, 15.177640914916992],
        [80.07653045654297, 15.177080154419059],
        [80.0762481689456, 15.177082061767635],
        [80.0762481689456, 15.176528930664062],
        [80.07596588134766, 15.176530838012638],
        [80.07596588134766, 15.175970077514705],
        [80.07569122314482, 15.175972938537598],
        [80.07569122314482, 15.175415992736816],
        [80.07541656494146, 15.175420761108512],
        [80.07541656494146, 15.174860000610352],
        [80.07512664794933, 15.174860000610352],
        [80.07512664794933, 15.174579620361385],
        [80.07485961914068, 15.17458534240734],
        [80.07485961914068, 15.174028396606559],
        [80.07457733154291, 15.174031257629451],
        [80.07457733154291, 15.173469543457031],
        [80.07430267334007, 15.173472404480094],
        [80.07430267334007, 15.17291641235363],
        [80.07402801513689, 15.172921180725098],
        [80.07402801513689, 15.172360420227164],
        [80.07375335693388, 15.17236232757574],
        [80.07375335693388, 15.171806335449276],
        [80.07347106933611, 15.171811103820914],
        [80.07347106933611, 15.17125034332281],
        [80.0731964111331, 15.17125034332281],
        [80.0731964111331, 15.17070102691656],
        [80.07290649414062, 15.17070102691656],
        [80.07290649414062, 15.170140266418457],
        [80.07263946533232, 15.170140266418457],
        [80.07263946533232, 15.169580459594783],
        [80.07236480712896, 15.169584274291992],
        [80.07236480712896, 15.169028282165527],
        [80.07206726074247, 15.169030189514274],
        [80.07206726074247, 15.168191909790039],
        [80.07180786132818, 15.168193817138672],
        [80.07180786132818, 15.16763782501215],
        [80.07152557373041, 15.16763782501215],
        [80.07152557373041, 15.167083740234489],
        [80.07125091552757, 15.167083740234489],
        [80.07125091552757, 15.166527748108024],
        [80.07096862792986, 15.1665296554566],
        [80.07096862792986, 15.16596889495844],
        [80.07069396972662, 15.165971755981502],
        [80.07069396972662, 15.16541767120367],
        [80.07041931152361, 15.165419578552246],
        [80.07041931152361, 15.164861679077148],
        [80.07013702392607, 15.164861679077148],
        [80.07013702392607, 15.16431045532238],
        [80.06986236572266, 15.16431045532238],
        [80.06986236572266, 15.163749694824219],
        [80.06958007812528, 15.163749694824219],
        [80.06958007812528, 15.163190841674862],
        [80.06930541992193, 15.16319561004633],
        [80.06930541992193, 15.162639617919865],
        [80.06903076171886, 15.162639617919865],
        [80.06903076171886, 15.162080764770508],
        [80.06874847412115, 15.162082672119084],
        [80.06874847412115, 15.161526679992619],
        [80.06846618652338, 15.161529541015682],
        [80.06846618652338, 15.160970687866154],
        [80.06819152832054, 15.160973548889217],
        [80.06819152832054, 15.160416603088436],
        [80.06791687011736, 15.160420417785645],
        [80.06791687011736, 15.159860610961971],
        [80.06764221191412, 15.159860610961971],
        [80.06764221191412, 15.159304618835506],
        [80.06735992431658, 15.159310340881461],
        [80.06735992431658, 15.158760070800781],
        [80.0670928955081, 15.158760070800781],
        [80.0670928955081, 15.158190727234],
        [80.06680297851562, 15.158194541931152],
        [80.06680297851562, 15.157638549804688],
        [80.06652832031278, 15.15764141082775],
        [80.06652832031278, 15.156811714172363],
        [80.06624603271484, 15.156811714172363],
        [80.06624603271484, 15.155970573425293],
        [80.06597137451183, 15.155973434448356],
        [80.06597137451183, 15.1551389694215],
        [80.06569671630865, 15.155140876770076],
        [80.06569671630865, 15.154581069946232],
        [80.06541442871088, 15.154582977295036],
        [80.06541442871088, 15.153751373291016],
        [80.06513977050804, 15.153751373291016],
        [80.06513977050804, 15.153189659118596],
        [80.06485748291033, 15.15319538116455],
        [80.06485748291033, 15.152083396911621],
        [80.06458282470709, 15.152083396911621],
        [80.06458282470709, 15.151250839233342],
        [80.06430816650408, 15.151250839233342],
        [80.06430816650408, 15.150140762329158],
        [80.06402587890653, 15.150140762329158],
        [80.06402587890653, 15.149311065673828],
        [80.06375122070312, 15.149311065673828],
        [80.06375122070312, 15.148469924926871],
        [80.06346893310575, 15.148471832275447],
        [80.06346893310575, 15.147638320922908],
        [80.06318664550781, 15.1476411819458],
        [80.06318664550781, 15.146531105041618],
        [80.06291961669933, 15.146531105041618],
        [80.06291961669933, 15.145691871643123],
        [80.06263732910162, 15.1456937789917],
        [80.06263732910162, 15.144862174987907],
        [80.06236267089838, 15.144862174987907],
        [80.06236267089838, 15.144027709961051],
        [80.06208038330084, 15.144029617309627],
        [80.06208038330084, 15.143189430236816],
        [80.06180572509783, 15.143194198608512],
        [80.06180572509783, 15.142084121704158],
        [80.06153106689459, 15.142084121704158],
        [80.06153106689459, 15.141249656677303],
        [80.06124877929705, 15.141249656677303],
        [80.06124877929705, 15.140419960022086],
        [80.06096649169928, 15.140419960022086],
        [80.06096649169928, 15.139581680297852],
        [80.06069183349626, 15.139583587646484],
        [80.06069183349626, 15.138471603393498],
        [80.06041717529325, 15.138471603393498],
        [80.06041717529325, 15.137361526489315],
        [80.06014251708984, 15.137361526489315],
        [80.06014251708984, 15.136248588562125],
        [80.05986022949247, 15.136248588562125],
        [80.05986022949247, 15.135421752929801],
        [80.05959320068365, 15.135421752929801],
        [80.05959320068365, 15.134309768676815],
        [80.05930328369152, 15.134309768676815],
        [80.05930328369152, 15.133199691772461],
        [80.05902862548834, 15.13319110870367],
        [80.05902862548834, 15.132638931274528],
        [80.05874633789057, 15.13264179229742],
        [80.05874633789057, 15.131810188293457],
        [80.05847167968773, 15.131810188293457],
        [80.05847167968773, 15.1312513351441],
        [80.05819702148455, 15.1312513351441],
        [80.05819702148455, 15.130690574645996],
        [80.05791473388678, 15.130695343017635],
        [80.05791473388678, 15.129304885864315],
        [80.05764007568376, 15.129308700561467],
        [80.05764007568376, 15.128461837768612],
        [80.05735778808622, 15.128473281860352],
        [80.05735778808622, 15.12791728973383],
        [80.05709075927734, 15.127919197082633],
        [80.05709075927734, 15.127080917358398],
        [80.05680847167997, 15.127082824706974],
        [80.05680847167997, 15.126251220703182],
        [80.05652618408209, 15.126251220703182],
        [80.05652618408209, 15.125420570373535],
        [80.05625152587902, 15.125420570373535],
        [80.05625152587902, 15.124580383300895],
        [80.0559692382813, 15.124583244323787],
        [80.0559692382813, 15.123750686645508],
        [80.05568695068354, 15.123750686645508],
        [80.05568695068354, 15.122920989990348],
        [80.05541992187523, 15.122920989990348],
        [80.05541992187523, 15.121810913085994],
        [80.05513763427751, 15.121810913085994],
        [80.05513763427751, 15.120969772338867],
        [80.05486297607428, 15.120971679687443],
        [80.05486297607428, 15.120138168335075],
        [80.05458068847673, 15.120141029357967],
        [80.05458068847673, 15.11931133270275],
        [80.05430603027372, 15.11931133270275],
        [80.05430603027372, 15.11847019195568],
        [80.05403137207031, 15.118472099304256],
        [80.05403137207031, 15.1176376342774],
        [80.05374908447294, 15.117639541625977],
        [80.05374908447294, 15.116520881652775],
        [80.05347442626959, 15.116527557373047],
        [80.05347442626959, 15.115415573120117],
        [80.05319213867199, 15.115420341491756],
        [80.05319213867199, 15.114579200744629],
        [80.0529174804688, 15.114583969116325],
        [80.0529174804688, 15.113471984863338],
        [80.05263519287104, 15.113471984863338],
        [80.05263519287104, 15.112361907958984],
        [80.0523605346682, 15.112361907958984],
        [80.0523605346682, 15.11125183105463],
        [80.05208587646501, 15.11125183105463],
        [80.05208587646501, 15.110141754150504],
        [80.05179595947288, 15.110141754150504],
        [80.05179595947288, 15.109029769897518],
        [80.05152893066423, 15.109029769897518],
        [80.05152893066423, 15.107919692993164],
        [80.05124664306669, 15.107919692993164],
        [80.05124664306669, 15.10680961608881],
        [80.05095672607439, 15.10680961608881],
        [80.05095672607439, 15.10486125946045],
        [80.05069732666044, 15.10486125946045],
        [80.05069732666044, 15.103195190429801],
        [80.0504150390625, 15.103195190429801],
        [80.0504150390625, 15.102639198303336],
        [80.05014038085949, 15.102639198303336],
        [80.05014038085949, 15.101250648498592],
        [80.04985809326178, 15.101250648498592],
        [80.04985809326178, 15.100140571594238],
        [80.04957580566418, 15.100140571594238],
        [80.04957580566418, 15.099031448364371],
        [80.0493087768557, 15.099031448364371],
        [80.0493087768557, 15.097360610961857],
        [80.04902648925798, 15.097360610961857],
        [80.04902648925798, 15.095137596130428],
        [80.04875183105474, 15.095141410827637],
        [80.04875183105474, 15.094579696655387],
        [80.0484695434572, 15.094585418701172],
        [80.0484695434572, 15.090694427490348],
        [80.04819488525419, 15.090694427490348],
        [80.04819488525419, 15.088749885559139],
        [80.04792022705078, 15.088751792907715],
        [80.04792022705078, 15.077349662780875],
        [80.04819488525419, 15.077361106872615],
        [80.04819488525419, 15.06902885437023],
        [80.0484695434572, 15.069030761718807],
        [80.0484695434572, 15.062640190124625],
        [80.04875183105474, 15.062640190124625],
        [80.04875183105474, 15.060420036315918],
        [80.04902648925798, 15.060420036315918],
        [80.04902648925798, 15.05181026458746],
        [80.0493087768557, 15.05181026458746],
        [80.0493087768557, 15.045689582824764],
        [80.04958343505854, 15.045695304870549],
        [80.04958343505854, 15.042917251586971],
        [80.04985809326178, 15.042921066284123],
        [80.04985809326178, 15.037920951843262],
        [80.05014038085949, 15.037920951843262],
        [80.05014038085949, 15.036250114440975],
        [80.05042266845709, 15.036250114440975],
        [80.05042266845709, 15.033470153808594],
        [80.05069732666044, 15.03347206115717],
        [80.05069732666044, 15.02791786193859],
        [80.05095672607439, 15.027919769287166],
        [80.05095672607439, 15.026530265808162],
        [80.05124664306669, 15.026530265808162],
        [80.05124664306669, 15.025691032409668],
        [80.05152893066423, 15.02569484710699],
        [80.05152893066423, 15.02458286285406],
        [80.05180358886724, 15.02458286285406],
        [80.05180358886724, 15.023470878601131],
        [80.05208587646501, 15.023470878601131],
        [80.05208587646501, 15.021804809570312],
        [80.0523605346682, 15.021809577941951],
        [80.0523605346682, 15.019861221313533],
        [80.05263519287104, 15.019861221313533],
        [80.05263519287104, 15.018472671508789],
        [80.0529174804688, 15.018472671508789],
        [80.0529174804688, 15.014860153198356],
        [80.05319213867199, 15.014860153198356],
        [80.05319213867199, 15.011249542236328],
        [80.053466796875, 15.011261940002498],
        [80.05347442626959, 15.009710311889762],
        [80.05347442626959, 15.00847244262701],
        [80.05374908447294, 15.00847244262701],
        [80.05374908447294, 15.006806373596191],
        [80.05403137207031, 15.006811141967887],
        [80.05403137207031, 15.00569057464611],
        [80.05430603027372, 15.005694389343262],
        [80.05430603027372, 15.003195762634334],
        [80.05458068847673, 15.003195762634334],
        [80.05458068847673, 14.999860763549805],
        [80.05458068847673, 14.999320983886719],
        [80.05486297607428, 14.999320983886719],
        [80.05486297607428, 14.998190879821834],
        [80.05513763427751, 14.998194694519157],
        [80.05513763427751, 14.996805191040153],
        [80.05541992187523, 14.996811866760368],
        [80.05541992187523, 14.99569129943859],
        [80.05569458007807, 14.995695114135799],
        [80.05569458007807, 14.994304656982479],
        [80.0559692382813, 14.994309425354118],
        [80.0559692382813, 14.993187904358024],
        [80.05625152587902, 14.993194580078125],
        [80.05625152587902, 14.992082595825138],
        [80.05652618408209, 14.992082595825138],
        [80.05652618408209, 14.990694999694881],
        [80.05680847167997, 14.990694999694881],
        [80.05680847167997, 14.989583015441951],
        [80.05708312988281, 14.989583015441951],
        [80.05708312988281, 14.988195419311523],
        [80.05735778808622, 14.988195419311523],
        [80.05735778808622, 14.987081527709961],
        [80.05764007568376, 14.987091064453125],
        [80.05764007568376, 14.985692024230957],
        [80.05791473388678, 14.985693931579704],
        [80.05791473388678, 14.984306335449276],
        [80.05819702148455, 14.984311103820914],
        [80.05819702148455, 14.982640266418457],
        [80.05847167968773, 14.982640266418457],
        [80.05847167968773, 14.981249809265137],
        [80.05874633789057, 14.981249809265137],
        [80.05874633789057, 14.979860305786246],
        [80.05902862548834, 14.979862213134822],
        [80.05902862548834, 14.978471755981502],
        [80.05930328369152, 14.978471755981502],
        [80.05930328369152, 14.976805686950684],
        [80.05959320068365, 14.976810455322322],
        [80.05959320068365, 14.975419998169002],
        [80.05986022949247, 14.975419998169002],
        [80.05986022949247, 14.974029541015682],
        [80.06012725830078, 14.974029541015682],
        [80.06012725830078, 14.972360610961971],
        [80.06041717529325, 14.972360610961971],
        [80.06041717529325, 14.970971107482967],
        [80.06069183349626, 14.970971107482967],
        [80.06069183349626, 14.969305038452148],
        [80.06096649169928, 14.969311714172363],
        [80.06096649169928, 14.967919349670467],
        [80.06124877929705, 14.967919349670467],
        [80.06124877929705, 14.966809272766113],
        [80.06153106689459, 14.966809272766113],
        [80.06153106689459, 14.966251373291016],
        [80.06181335449236, 14.966251373291016],
        [80.06180572509783, 14.964306831359806],
        [80.06208038330084, 14.964310646057186],
        [80.06208038330084, 14.963750839233342],
        [80.06181335449236, 14.963750839233342],
        [80.06181335449236, 14.963190078735408],
        [80.06208038330084, 14.963194847107047],
        [80.06208038330084, 14.96208190917963],
        [80.06291961669933, 14.96208190917963],
        [80.06291961669933, 14.961528778076229],
        [80.06236267089838, 14.961530685424805],
        [80.06236267089838, 14.960420608520451],
        [80.06263732910162, 14.960420608520451],
        [80.06263732910162, 14.95986270904541],
        [80.06291961669933, 14.95986270904541],
        [80.06291961669933, 14.958749771118164],
        [80.06318664550781, 14.958749771118164],
        [80.06318664550781, 14.955971717834586],
        [80.06346893310575, 14.955971717834586],
        [80.06346893310575, 14.95542144775385],
        [80.06375122070312, 14.95542144775385],
        [80.06375122070312, 14.954311370849723],
        [80.06346893310575, 14.954311370849723],
        [80.06346893310575, 14.953470230102596],
        [80.06375122070312, 14.953472137451172],
        [80.06375122070312, 14.952362060546818],
        [80.06402587890653, 14.952362060546818],
        [80.06402587890653, 14.950139999389705],
        [80.06430816650408, 14.950141906738281],
        [80.06430816650408, 14.948471069335994],
        [80.06458282470709, 14.948471069335994],
        [80.06458282470709, 14.947083473205566],
        [80.06485748291033, 14.947083473205566],
        [80.06485748291033, 14.9459743499757],
        [80.06513977050804, 14.9459743499757],
        [80.06513977050804, 14.945417404174918],
        [80.06540679931652, 14.94542026519781],
        [80.06540679931652, 14.944849967956543],
        [80.06569671630865, 14.944861412048454],
        [80.06569671630865, 14.9437513351441],
        [80.06597137451183, 14.9437513351441],
        [80.06597137451183, 14.943470954895133],
        [80.06652832031278, 14.943470954895133],
        [80.06652832031278, 14.94263935089117],
        [80.06624603271484, 14.94263935089117],
        [80.06624603271484, 14.942081451416072],
        [80.06597137451183, 14.942083358764648],
        [80.06597137451183, 14.941248893737793],
        [80.06624603271484, 14.941248893737793],
        [80.06624603271484, 14.940419197082633],
        [80.06652832031278, 14.940419197082633],
        [80.06652832031278, 14.939580917358398],
        [80.06680297851562, 14.939582824706974],
        [80.06680297851562, 14.937916755676326],
        [80.0670928955081, 14.937920570373535],
        [80.0670928955081, 14.937080383300895],
        [80.06735992431658, 14.937083244323787],
        [80.06735992431658, 14.936529159545898],
        [80.06764221191412, 14.936531066894474],
        [80.06764221191412, 14.935981750488281],
        [80.06791687011736, 14.935981750488281],
        [80.06791687011736, 14.93375110626232],
        [80.06764221191412, 14.93375110626232],
        [80.06764221191412, 14.93319034576416],
        [80.06791687011736, 14.93319416046154],
        [80.06791687011736, 14.932638168335075],
        [80.06819152832054, 14.932641029357967],
        [80.06819152832054, 14.931530952453613],
        [80.06846618652338, 14.931530952453613],
        [80.06846618652338, 14.92903041839611],
        [80.06874847412115, 14.92903041839611],
        [80.06874847412115, 14.927080154418945],
        [80.06903076171886, 14.927083969116325],
        [80.06903076171886, 14.926527976989803],
        [80.069320678711, 14.926532745361328],
        [80.069320678711, 14.925971984863338],
        [80.06958007812528, 14.925971984863338],
        [80.06958007812528, 14.92541790008545],
        [80.06986236572266, 14.925419807434082],
        [80.06986236572266, 14.924861907958984],
        [80.07013702392607, 14.924861907958984],
        [80.07013702392607, 14.924040794372615],
        [80.07041931152361, 14.924040794372615],
        [80.07041931152361, 14.923468589782772],
        [80.07069396972662, 14.92347240447998],
        [80.07069396972662, 14.923195838928166],
        [80.07096862792986, 14.923195838928166],
        [80.07096862792986, 14.922916412353516],
        [80.07125091552757, 14.922920227050838],
        [80.07125091552757, 14.922359466552734],
        [80.07152557373041, 14.922362327575797],
        [80.07152557373041, 14.922083854675407],
        [80.07180786132818, 14.922083854675407],
        [80.07180786132818, 14.921527862548942],
        [80.07206726074247, 14.921529769897518],
        [80.07206726074247, 14.921249389648551],
        [80.07235717773443, 14.921249389648551],
        [80.07235717773443, 14.920690536499023],
        [80.07263946533232, 14.920693397522086],
        [80.07263946533232, 14.920417785644588],
        [80.07290649414062, 14.920419692993164],
        [80.07290649414062, 14.920140266418514],
        [80.0731964111331, 14.920140266418514],
        [80.0731964111331, 14.919581413269157],
        [80.07347106933611, 14.919583320617733],
        [80.07347106933611, 14.919027328491268],
        [80.07375335693388, 14.91903018951416],
        [80.07375335693388, 14.918190956115836],
        [80.07402801513689, 14.918194770812988],
        [80.07402801513689, 14.917638778686523],
        [80.07430267334007, 14.917641639709586],
        [80.07430267334007, 14.917080879211483],
        [80.07458496093767, 14.917082786560059],
        [80.07458496093767, 14.916526794433594],
        [80.07485961914068, 14.916531562805233],
        [80.07485961914068, 14.915968894958496],
        [80.07514190673822, 14.915970802307129],
        [80.07514190673822, 14.91541671752941],
        [80.07541656494146, 14.915418624877987],
        [80.07541656494146, 14.914310455322209],
        [80.07569122314482, 14.914310455322209],
        [80.07569122314482, 14.913471221923885],
        [80.07597351074219, 14.913473129272461],
        [80.07597351074219, 14.912361145019531],
        [80.0762481689456, 14.912361145019531],
        [80.0762481689456, 14.911526679992676],
        [80.07653045654297, 14.911531448364315],
        [80.07653045654297, 14.910421371459961],
        [80.07681274414091, 14.910421371459961],
        [80.07681274414091, 14.90958023071289],
        [80.07707977294939, 14.909583091735954],
        [80.07707977294939, 14.9084730148316],
        [80.07736206054693, 14.9084730148316],
        [80.07736206054693, 14.907637596130428],
        [80.07763671875017, 14.907641410827637],
        [80.07763671875017, 14.906531333923454],
        [80.07791900634788, 14.906531333923454],
        [80.07791900634788, 14.90568065643322],
        [80.07819366455072, 14.905694007873535],
        [80.07819366455072, 14.904582023620605],
        [80.0784759521485, 14.904582023620605],
        [80.0784759521485, 14.903750419616813],
        [80.07875061035168, 14.903750419616813],
        [80.07875061035168, 14.90264034271246],
        [80.07903289794928, 14.90264034271246],
        [80.07903289794928, 14.901530265808105],
        [80.07930755615263, 14.901530265808105],
        [80.07930755615263, 14.900691986084041],
        [80.07958221435547, 14.900693893432617],
        [80.07958221435547, 14.899581909179688],
        [80.07986450195341, 14.899581909179688],
        [80.07986450195341, 14.898750305175895],
        [80.08013916015642, 14.898750305175895],
        [80.08013916015642, 14.897640228271541],
        [80.08040618896507, 14.897640228271541],
        [80.08040618896507, 14.896809577941895],
        [80.0806961059572, 14.896809577941895],
        [80.0806961059572, 14.895690917968864],
        [80.08097076416038, 14.895695686340332],
        [80.08097076416038, 14.894583702087402],
        [80.08125305175776, 14.894583702087402],
        [80.08125305175776, 14.893749237060547],
        [80.081527709961, 14.893749237060547],
        [80.081527709961, 14.892639160156364],
        [80.08180236816418, 14.892642021179256],
        [80.08180236816418, 14.891531944274902],
        [80.08207702636719, 14.891531944274902],
        [80.08207702636719, 14.890679359436092],
        [80.08235931396513, 14.890694618225154],
        [80.08235931396513, 14.889582633972225],
        [80.0826416015625, 14.889582633972225],
        [80.0826416015625, 14.888749122619629],
        [80.08291625976591, 14.888751029968262],
        [80.08291625976591, 14.887640953064079],
        [80.08319091796892, 14.887640953064079],
        [80.08319091796892, 14.886530876159725],
        [80.08347320556646, 14.886530876159725],
        [80.08347320556646, 14.885418891906795],
        [80.0837478637697, 14.885418891906795],
        [80.0837478637697, 14.883750915527344],
        [80.08403015136741, 14.883750915527344],
        [80.08403015136741, 14.882080078125057],
        [80.08430480957026, 14.88208293914795],
        [80.08430480957026, 14.880695343017692],
        [80.08458709716803, 14.880695343017692],
        [80.08458709716803, 14.879028320312557],
        [80.08486175537121, 14.87903118133545],
        [80.08486175537121, 14.876811027526912],
        [80.08513641357428, 14.876811027526912],
        [80.08513641357428, 14.874030113220272],
        [80.08541870117216, 14.874030113220272],
        [80.08541870117216, 14.87125015258789],
        [80.085693359375, 14.87125015258789],
        [80.085693359375, 14.866530418395996],
        [80.08598327636747, 14.866530418395996],
        [80.08598327636747, 14.859861373901367],
        [80.08625030517595, 14.859861373901367],
        [80.08625030517595, 14.852082252502441],
        [80.08652496337896, 14.852082252502441],
        [80.08652496337896, 14.849860191345329],
        [80.08680725097673, 14.849860191345329],
        [80.08680725097673, 14.849583625793514],
        [80.08708190917991, 14.849583625793514],
        [80.08708190917991, 14.849027633667049],
        [80.08735656738276, 14.849031448364201],
        [80.08735656738276, 14.848469734191951],
        [80.08708190917991, 14.848471641540584],
        [80.08708190917991, 14.84791564941412],
        [80.08680725097673, 14.847921371460018],
        [80.08680725097673, 14.847080230712947],
        [80.08652496337896, 14.8470840454101],
        [80.08652496337896, 14.844861984252987],
        [80.08680725097673, 14.844861984252987],
        [80.08680725097673, 14.840419769287166],
        [80.08706665039068, 14.840419769287166],
        [80.08706665039068, 14.836531639099235],
        [80.08735656738276, 14.836531639099235],
        [80.08735656738276, 14.83319091796875],
        [80.08708190917991, 14.83319473266613],
        [80.08708190917991, 14.830695152282715],
        [80.08736419677751, 14.830695152282715],
        [80.08736419677751, 14.826804161071891],
        [80.08763885498053, 14.826810836791935],
        [80.08763885498053, 14.824311256408748],
        [80.08790588378918, 14.824311256408748],
        [80.08790588378918, 14.822640419006461],
        [80.0881958007813, 14.822640419006461],
        [80.0881958007813, 14.821518898010368],
        [80.08763885498053, 14.821528434753475],
        [80.08763885498053, 14.822084426879996],
        [80.08735656738276, 14.8220796585083],
        [80.08735656738276, 14.822360038757267],
        [80.08706665039068, 14.822360038757267],
        [80.08706665039068, 14.822640419006461],
        [80.08651733398443, 14.822640419006461],
        [80.08651733398443, 14.822920799255428],
        [80.08598327636747, 14.822920799255428],
        [80.08598327636747, 14.822640419006461],
        [80.08429718017572, 14.822640419006461],
        [80.08429718017572, 14.821518898010368],
        [80.08513641357428, 14.821518898010368],
        [80.08513641357428, 14.821810722351074],
        [80.08680725097673, 14.821810722351074],
        [80.08680725097673, 14.821249961853141],
        [80.08706665039068, 14.821249961853141],
        [80.08706665039068, 14.820969581603947],
        [80.08736419677751, 14.82097244262701],
        [80.08736419677751, 14.820137977600154],
        [80.08706665039068, 14.820141792297363],
        [80.08706665039068, 14.81931114196783],
        [80.08735656738276, 14.81931114196783],
        [80.08735656738276, 14.819029808044434],
        [80.08763885498053, 14.819029808044434],
        [80.08763885498053, 14.818750381469727],
        [80.08790588378918, 14.818750381469727],
        [80.08790588378918, 14.81819152832037],
        [80.0881958007813, 14.818194389343262],
        [80.0881958007813, 14.817915916442871],
        [80.08847045898466, 14.81791973114025],
        [80.08847045898466, 14.817640304565543],
        [80.08875274658203, 14.817640304565543],
        [80.08875274658203, 14.817082405090332],
        [80.08847045898466, 14.817084312439079],
        [80.08847045898466, 14.815972328186149],
        [80.0881958007813, 14.815972328186149],
        [80.0881958007813, 14.81513977050787],
        [80.08847045898466, 14.81513977050787],
        [80.08847045898466, 14.814580917358512],
        [80.08875274658203, 14.814583778381404],
        [80.08875274658203, 14.81402778625494],
        [80.08902740478544, 14.814029693603516],
        [80.08902740478544, 14.812650680542106],
        [80.08875274658203, 14.812650680542106],
        [80.08875274658203, 14.811532020568904],
        [80.08790588378918, 14.811532020568904],
        [80.08790588378918, 14.811251640319938],
        [80.08706665039068, 14.811251640319938],
        [80.08706665039068, 14.8109712600708],
        [80.08625030517595, 14.8109712600708],
        [80.08625030517595, 14.810694694519157],
        [80.08513641357428, 14.810694694519157],
        [80.08513641357428, 14.810415267944279],
        [80.08429718017572, 14.81042194366455],
        [80.08429718017572, 14.810141563415584],
        [80.08347320556646, 14.810141563415584],
        [80.08347320556646, 14.809861183166618],
        [80.08180236816418, 14.809861183166618],
        [80.08180236816418, 14.810141563415584],
        [80.0806961059572, 14.810141563415584],
        [80.0806961059572, 14.81042194366455],
        [80.08041381835943, 14.810415267944279],
        [80.08041381835943, 14.810694694519157],
        [80.08013916015642, 14.810694694519157],
        [80.08013916015642, 14.8109712600708],
        [80.07985687255888, 14.8109712600708],
        [80.07985687255888, 14.811251640319938],
        [80.07930755615263, 14.811251640319938],
        [80.07930755615263, 14.811532020568904],
        [80.07903289794928, 14.811532020568904],
        [80.07903289794928, 14.811809539794979],
        [80.07875061035168, 14.81180477142334],
        [80.07875061035168, 14.81208324432373],
        [80.0784759521485, 14.812081336975154],
        [80.0784759521485, 14.812360763549805],
        [80.07818603515636, 14.812360763549805],
        [80.07818603515636, 14.812650680542106],
        [80.07763671875017, 14.812650680542106],
        [80.07763671875017, 14.812919616699332],
        [80.07736206054693, 14.812917709350586],
        [80.07736206054693, 14.813193321228084],
        [80.07707977294939, 14.813191413879508],
        [80.07707977294939, 14.813470840454158],
        [80.07680511474638, 14.813470840454158],
        [80.07681274414091, 14.813749313354549],
        [80.07653045654297, 14.813749313354549],
        [80.07653045654297, 14.814029693603516],
        [80.0762481689456, 14.814029693603516],
        [80.0762481689456, 14.814310073852482],
        [80.07569122314482, 14.81430625915533],
        [80.07569122314482, 14.814421653747672],
        [80.07569122314482, 14.814583778381404],
        [80.07541656494146, 14.814583778381404],
        [80.07541656494146, 14.814862251281795],
        [80.07485961914068, 14.814859390258903],
        [80.07485961914068, 14.81513977050787],
        [80.07457733154291, 14.81513977050787],
        [80.07457733154291, 14.815420150756836],
        [80.07430267334007, 14.815416336059684],
        [80.07430267334007, 14.815695762634334],
        [80.07375335693388, 14.815695762634334],
        [80.07375335693388, 14.815972328186149],
        [80.07347106933611, 14.815969467163086],
        [80.07347106933611, 14.816249847412223],
        [80.07290649414062, 14.816249847412223],
        [80.07290649414062, 14.81653022766119],
        [80.07206726074247, 14.81653022766119],
        [80.07206726074247, 14.816809654235897],
        [80.07152557373041, 14.816805839538688],
        [80.07152557373041, 14.817084312439079],
        [80.07068634033209, 14.817082405090332],
        [80.07068634033209, 14.817361831664982],
        [80.07013702392607, 14.817361831664982],
        [80.07013702392607, 14.817640304565543],
        [80.06986236572266, 14.817640304565543],
        [80.06986236572266, 14.81791973114025],
        [80.06958007812528, 14.817915916442871],
        [80.06958007812528, 14.818194389343262],
        [80.06930541992193, 14.818194389343262],
        [80.06930541992193, 14.818471908569336],
        [80.06903076171886, 14.818460464477653],
        [80.06903076171886, 14.818750381469727],
        [80.06874847412115, 14.818750381469727],
        [80.06874847412115, 14.81931114196783],
        [80.06819152832054, 14.819306373596191],
        [80.06819152832054, 14.81958198547369],
        [80.06735992431658, 14.81958198547369],
        [80.06735992431658, 14.819862365722656],
        [80.06680297851562, 14.81986045837408],
        [80.06680297851562, 14.820141792297363],
        [80.06624603271484, 14.820141792297363],
        [80.06624603271484, 14.820421218872013],
        [80.06485748291033, 14.820416450500545],
        [80.06485748291033, 14.82068920135498],
        [80.06346893310575, 14.82069396972662],
        [80.06346893310575, 14.82097244262701],
        [80.06291961669933, 14.820969581603947],
        [80.06291961669933, 14.821249961853141],
        [80.06124877929705, 14.821249961853141],
        [80.06124877929705, 14.820969581603947],
        [80.06069183349626, 14.82097244262701],
        [80.06069183349626, 14.82069396972662],
        [80.06012725830078, 14.82068920135498],
        [80.06014251708984, 14.820416450500545],
        [80.05959320068365, 14.820421218872013],
        [80.05959320068365, 14.820141792297363],
        [80.05930328369152, 14.820141792297363],
        [80.05930328369152, 14.81986045837408],
        [80.06069183349626, 14.81986045837408],
        [80.06069183349626, 14.820141792297363],
        [80.06263732910162, 14.820141792297363],
        [80.06263732910162, 14.81986045837408],
        [80.06346893310575, 14.819862365722656],
        [80.06346893310575, 14.81958198547369],
        [80.06402587890653, 14.81958198547369],
        [80.06402587890653, 14.819306373596191],
        [80.06485748291033, 14.81931114196783],
        [80.06485748291033, 14.819029808044434],
        [80.06624603271484, 14.819029808044434],
        [80.06624603271484, 14.818750381469727],
        [80.06680297851562, 14.818750381469727],
        [80.06680297851562, 14.818460464477653],
        [80.06708526611357, 14.818471908569336],
        [80.06708526611357, 14.818194389343262],
        [80.06735992431658, 14.818194389343262],
        [80.06735992431658, 14.817915916442871],
        [80.06764221191412, 14.81791973114025],
        [80.06764221191412, 14.817640304565543],
        [80.06791687011736, 14.817640304565543],
        [80.06791687011736, 14.817082405090332],
        [80.06819152832054, 14.817084312439079],
        [80.06819152832054, 14.816805839538688],
        [80.06874847412115, 14.816809654235897],
        [80.06874847412115, 14.81653022766119],
        [80.06903076171886, 14.81653022766119],
        [80.06903076171886, 14.816249847412223],
        [80.06958007812528, 14.816249847412223],
        [80.06958007812528, 14.815969467163086],
        [80.07041931152361, 14.815972328186149],
        [80.07041931152361, 14.815695762634334],
        [80.07096862792986, 14.815695762634334],
        [80.07096862792986, 14.815416336059684],
        [80.07125091552757, 14.815420150756836],
        [80.07125091552757, 14.81513977050787],
        [80.07153320312517, 14.81513977050787],
        [80.07153320312517, 14.814310073852482],
        [80.07180786132818, 14.814310073852482],
        [80.07180786132818, 14.814029693603516],
        [80.07235717773443, 14.814029693603516],
        [80.07235717773443, 14.813749313354549],
        [80.07263946533232, 14.813749313354549],
        [80.07263946533232, 14.813470840454158],
        [80.07290649414062, 14.813470840454158],
        [80.07290649414062, 14.813191413879508],
        [80.07485961914068, 14.813193321228084],
        [80.07485961914068, 14.812917709350586],
        [80.07511138916044, 14.812919616699332],
        [80.07512664794933, 14.812420845031795],
        [80.07514190673822, 14.81208324432373],
        [80.07541656494146, 14.81208324432373],
        [80.07541656494146, 14.81180477142334],
        [80.07518768310564, 14.81180477142334],
        [80.07458496093767, 14.81180477142334],
        [80.07458496093767, 14.81208324432373],
        [80.07430267334007, 14.81208324432373],
        [80.07430267334007, 14.81180477142334],
        [80.07402801513689, 14.811809539794979],
        [80.07402801513689, 14.811532020568904],
        [80.07430267334007, 14.811532020568904],
        [80.07430267334007, 14.810690879821777],
        [80.07458496093767, 14.810694694519157],
        [80.07458496093767, 14.810415267944279],
        [80.07557678222656, 14.810415267944279],
        [80.07597351074219, 14.810415267944279],
        [80.07597351074219, 14.810694694519157],
        [80.0762481689456, 14.810694694519157],
        [80.0762481689456, 14.810415267944279],
        [80.07707977294939, 14.81042194366455],
        [80.07707977294939, 14.810141563415584],
        [80.07763671875017, 14.810141563415584],
        [80.07763671875017, 14.809861183166618],
        [80.07819366455072, 14.809861183166618],
        [80.07819366455072, 14.809582710266227],
        [80.07902526855469, 14.809582710266227],
        [80.07902526855469, 14.809305191040153],
        [80.07958221435547, 14.809311866760368],
        [80.07958221435547, 14.809028625488338],
        [80.08013916015642, 14.809028625488338],
        [80.08013916015642, 14.808749198913631],
        [80.08040618896507, 14.808749198913631],
        [80.08040618896507, 14.808470726013297],
        [80.08097076416038, 14.808472633361873],
        [80.08097076416038, 14.808195114135799],
        [80.081527709961, 14.808195114135799],
        [80.081527709961, 14.807916641235408],
        [80.08180236816418, 14.807918548583984],
        [80.08180236816418, 14.80764102935791],
        [80.08291625976591, 14.80764102935791],
        [80.08291625976591, 14.807918548583984],
        [80.08319091796892, 14.807916641235408],
        [80.08319091796892, 14.808195114135799],
        [80.085693359375, 14.808195114135799],
        [80.085693359375, 14.808472633361873],
        [80.08625030517595, 14.808472633361873],
        [80.08625030517595, 14.808195114135799],
        [80.08652496337896, 14.808195114135799],
        [80.08652496337896, 14.808472633361873],
        [80.08706665039068, 14.808470726013297],
        [80.08706665039068, 14.808749198913631],
        [80.08735656738276, 14.808749198913631],
        [80.08735656738276, 14.809028625488338],
        [80.08790588378918, 14.809028625488338],
        [80.08790588378918, 14.809311866760368],
        [80.08930206298845, 14.809305191040153],
        [80.08930206298845, 14.809582710266227],
        [80.09041595459001, 14.809582710266227],
        [80.09041595459001, 14.809861183166618],
        [80.09097290039057, 14.809861183166618],
        [80.09097290039057, 14.810141563415584],
        [80.0912475585938, 14.810138702392692],
        [80.0912475585938, 14.8109712600708],
        [80.09208679199247, 14.8109712600708],
        [80.09208679199247, 14.810694694519157],
        [80.09236145019531, 14.810694694519157],
        [80.09236145019531, 14.810415267944279],
        [80.09291839599626, 14.81042194366455],
        [80.09291839599626, 14.810141563415584],
        [80.0932006835938, 14.810141563415584],
        [80.0932006835938, 14.809311866760368],
        [80.09349060058611, 14.809311866760368],
        [80.09349060058611, 14.809028625488338],
        [80.09263610839872, 14.809028625488338],
        [80.09263610839872, 14.808749198913631],
        [80.09236145019531, 14.808749198913631],
        [80.09236145019531, 14.808470726013297],
        [80.09263610839872, 14.808472633361873],
        [80.09263610839872, 14.808195114135799],
        [80.09291839599626, 14.808195114135799],
        [80.09291839599626, 14.806804656982422],
        [80.09263610839872, 14.806810379028377],
        [80.09263610839872, 14.80625057220459],
        [80.09291839599626, 14.80625057220459],
        [80.09291839599626, 14.805971145629883],
        [80.09319305419928, 14.805973052978459],
        [80.09319305419928, 14.805694580078068],
        [80.09291839599626, 14.805694580078068],
        [80.09291839599626, 14.805138587951603],
        [80.0932006835938, 14.805141448974666],
        [80.0932006835938, 14.8048610687257],
        [80.09291839599626, 14.8048610687257],
        [80.09291839599626, 14.804580688476562],
        [80.09319305419928, 14.804582595825138],
        [80.09319305419928, 14.803750991821346],
        [80.09291839599626, 14.803750991821346],
        [80.09291839599626, 14.803461074829102],
        [80.09319305419928, 14.803473472595272],
        [80.09319305419928, 14.803194999694881],
        [80.09347534179705, 14.803194999694881],
        [80.09347534179705, 14.802639007568416],
        [80.09375000000023, 14.802640914916992],
        [80.09375000000023, 14.802360534668026],
        [80.09402465820307, 14.802360534668026],
        [80.09402465820307, 14.802083015441951],
        [80.09375000000023, 14.802083015441951],
        [80.09375000000023, 14.801528930664062],
        [80.09401702880871, 14.801530838012638],
        [80.09401702880871, 14.799579620361385],
        [80.09458160400402, 14.799581527709961],
        [80.09458160400402, 14.79930686950695],
        [80.09430694580084, 14.799310684204102],
        [80.09430694580084, 14.798469543457031],
        [80.09458160400402, 14.798472404480094],
        [80.09458160400402, 14.796806335449276],
        [80.09430694580084, 14.796811103820914],
        [80.09430694580084, 14.79625034332281],
        [80.09458160400402, 14.79625034332281],
        [80.09458160400402, 14.79568958282465],
        [80.09486389160162, 14.795694351196289],
        [80.09486389160162, 14.795415878295955],
        [80.09513854980497, 14.795420646667594],
        [80.09513854980497, 14.795140266418457],
        [80.09541320800781, 14.795140266418457],
        [80.09541320800781, 14.79347038269043],
        [80.09569549560575, 14.793472290039006],
        [80.09569549560575, 14.792916297912711],
        [80.09597015380876, 14.79292011260992],
        [80.09597015380876, 14.790969848632926],
        [80.0962524414063, 14.790971755981502],
        [80.0962524414063, 14.790140151977539],
        [80.09652709960955, 14.790140151977539],
        [80.09652709960955, 14.789310455322322],
        [80.09680175781273, 14.789310455322322],
        [80.09680175781273, 14.789030075073356],
        [80.09652709960955, 14.789030075073356],
        [80.09652709960955, 14.788190841674862],
        [80.0962524414063, 14.788193702697754],
        [80.0962524414063, 14.787639617920036],
        [80.09652709960955, 14.787639617920036],
        [80.09652709960955, 14.787359237670898],
        [80.09680175781273, 14.787362098693961],
        [80.09680175781273, 14.786806106567496],
        [80.09707641601557, 14.786809921264648],
        [80.09707641601557, 14.785970687866325],
        [80.09735870361334, 14.785973548889217],
        [80.09735870361334, 14.784860610961971],
        [80.09791564941412, 14.784860610961971],
        [80.09791564941412, 14.784027099609432],
        [80.09764099121105, 14.78403186798107],
        [80.09764099121105, 14.783752441406193],
        [80.09792327880865, 14.783752441406193],
        [80.09792327880865, 14.78264236450201],
        [80.09847259521484, 14.78264236450201],
        [80.09847259521484, 14.78208065032959],
        [80.098197937012, 14.782082557678223],
        [80.098197937012, 14.781805038452092],
        [80.09764099121105, 14.781811714172363],
        [80.09764099121105, 14.78152942657482],
        [80.09792327880865, 14.78152942657482],
        [80.09792327880865, 14.780691146850586],
        [80.098197937012, 14.780693054199162],
        [80.098197937012, 14.779305458068904],
        [80.09847259521484, 14.779309272766113],
        [80.09847259521484, 14.77902889251709],
        [80.09874725341825, 14.77902889251709],
        [80.09874725341825, 14.778470993042049],
        [80.0990295410158, 14.778472900390625],
        [80.0990295410158, 14.777639389038086],
        [80.09929656982428, 14.777641296386662],
        [80.09929656982428, 14.777079582214412],
        [80.09986114501976, 14.777083396911621],
        [80.09986114501976, 14.776250839233512],
        [80.1001358032226, 14.776250839233512],
        [80.1001358032226, 14.775690078735408],
        [80.09986114501976, 14.775694847107047],
        [80.09986114501976, 14.775138854980582],
        [80.1001358032226, 14.775140762329158],
        [80.1001358032226, 14.774311065673771],
        [80.09986114501976, 14.774311065673771],
        [80.09986114501976, 14.773479461669979],
        [80.1001358032226, 14.773479461669979],
        [80.1001358032226, 14.772920608520451],
        [80.10041809082037, 14.772920608520451],
        [80.10041809082037, 14.7726411819458],
        [80.10069274902355, 14.7726411819458],
        [80.10069274902355, 14.771810531616268],
        [80.10098266601568, 14.771810531616268],
        [80.10098266601568, 14.770970344543457],
        [80.1012496948245, 14.77097225189209],
        [80.1012496948245, 14.770416259765625],
        [80.10097503662115, 14.770416259765625],
        [80.10097503662115, 14.769860267639103],
        [80.1012496948245, 14.769860267639103],
        [80.1012496948245, 14.769306182861442],
        [80.10151672363281, 14.76931095123291],
        [80.10151672363281, 14.768190383911133],
        [80.10180664062528, 14.768194198608455],
        [80.10180664062528, 14.7673597335816],
        [80.10236358642607, 14.7673597335816],
        [80.10236358642607, 14.767084121704102],
        [80.10291290283226, 14.767084121704102],
        [80.10291290283226, 14.766249656677246],
        [80.10346984863287, 14.766249656677246],
        [80.10346984863287, 14.765419960022086],
        [80.10375213623041, 14.765419960022086],
        [80.10375213623041, 14.764860153198242],
        [80.10402679443365, 14.764862060546818],
        [80.10402679443365, 14.764027595519963],
        [80.10430908203136, 14.764030456543026],
        [80.10430908203136, 14.763189315795955],
        [80.10402679443365, 14.763194084167594],
        [80.10402679443365, 14.762639999389705],
        [80.10375213623041, 14.762639999389705],
        [80.10375213623041, 14.761810302734318],
        [80.10402679443365, 14.761810302734318],
        [80.10402679443365, 14.761541366577262],
        [80.10430908203136, 14.761541366577262],
        [80.10430908203136, 14.760690689087028],
        [80.10458374023438, 14.760692596435604],
        [80.10458374023438, 14.760417938232422],
        [80.10485839843778, 14.760421752929744],
        [80.10485839843778, 14.760140419006348],
        [80.10514068603516, 14.760140419006348],
        [80.10514068603516, 14.759861946105957],
        [80.10543060302763, 14.759861946105957],
        [80.10541534423857, 14.758749008178711],
        [80.10597229003912, 14.758751869201774],
        [80.10597229003912, 14.75847053527832],
        [80.10569763183611, 14.75847053527832],
        [80.10569763183611, 14.757082939148063],
        [80.10597229003912, 14.757082939148063],
        [80.10597229003912, 14.756805419921989],
        [80.10624694824236, 14.756810188293457],
        [80.10624694824236, 14.755970954895133],
        [80.10652923584007, 14.755970954895133],
        [80.10652923584007, 14.755416870117244],
        [80.10680389404291, 14.755416870117244],
        [80.10680389404291, 14.755139350891113],
        [80.10736083984386, 14.755139350891113],
        [80.10736083984386, 14.754581451416072],
        [80.10708618164068, 14.754583358764648],
        [80.10708618164068, 14.754027366638184],
        [80.10736083984386, 14.75402927398676],
        [80.10736083984386, 14.753748893737793],
        [80.10764312744146, 14.753748893737793],
        [80.10763549804688, 14.753473281860295],
        [80.10791778564482, 14.753473281860295],
        [80.10791778564482, 14.753194808959904],
        [80.10819244384766, 14.753194808959904],
        [80.10819244384766, 14.75291728973383],
        [80.10846710205107, 14.752919197082633],
        [80.10846710205107, 14.751251220703182],
        [80.10874938964861, 14.751251220703182],
        [80.10874938964861, 14.750970840454215],
        [80.10901641845726, 14.750970840454215],
        [80.10901641845726, 14.750690460205078],
        [80.10930633544939, 14.750695228576717],
        [80.10930633544939, 14.750139236450252],
        [80.10958099365257, 14.750141143798828],
        [80.10958099365257, 14.749311447143612],
        [80.11013793945318, 14.749311447143612],
        [80.11013793945318, 14.749031066894474],
        [80.1104202270509, 14.749031066894474],
        [80.1104202270509, 14.748470306396541],
        [80.11069488525396, 14.748473167419434],
        [80.11069488525396, 14.746804237365723],
        [80.11096954345732, 14.746810913085938],
        [80.11096954345732, 14.746240615844727],
        [80.11125183105469, 14.74625110626232],
        [80.11125183105469, 14.745695114135856],
        [80.1115264892581, 14.745695114135856],
        [80.1115264892581, 14.745569229126033],
        [80.1115264892581, 14.744028091430721],
        [80.11180877685564, 14.744030952453613],
        [80.11180877685564, 14.74374961853033],
        [80.11235809326189, 14.74374961853033],
        [80.11235809326189, 14.744030952453613],
        [80.1134719848634, 14.744030952453613],
        [80.1134719848634, 14.74374961853033],
        [80.11374664306646, 14.74374961853033],
        [80.11374664306646, 14.743181228637638],
        [80.11402893066435, 14.743193626403865],
        [80.11402893066435, 14.742916107177791],
        [80.11429595947266, 14.74292087554943],
        [80.11429595947266, 14.742639541625977],
        [80.11458587646513, 14.742639541625977],
        [80.11458587646513, 14.742079734802303],
        [80.11486053466814, 14.742083549499512],
        [80.11486053466814, 14.741806030273438],
        [80.11514282226591, 14.741810798645076],
        [80.11514282226591, 14.74153137207037],
        [80.11429595947266, 14.74153137207037],
        [80.11429595947266, 14.74013996124279],
        [80.11458587646513, 14.74013996124279],
        [80.11458587646513, 14.739580154418945],
        [80.11486053466814, 14.739583969116268],
        [80.11486053466814, 14.737638473510799],
        [80.11514282226591, 14.737640380859375],
        [80.11514282226591, 14.734300613403377],
        [80.11486053466814, 14.734306335449332],
        [80.11486053466814, 14.732084274291992],
        [80.11458587646513, 14.732084274291992],
        [80.11458587646513, 14.731248855590877],
        [80.11486053466814, 14.731248855590877],
        [80.11486053466814, 14.730971336364803],
        [80.11513519287115, 14.730971336364803],
        [80.11513519287115, 14.730695724487305],
        [80.11541748046892, 14.730695724487305],
        [80.11541748046892, 14.730971336364803],
        [80.1156921386721, 14.730971336364803],
        [80.1156921386721, 14.73153018951416],
        [80.11596679687494, 14.73153018951416],
        [80.11596679687494, 14.732361793518123],
        [80.11624908447271, 14.732361793518123],
        [80.11624908447271, 14.732919692993164],
        [80.11653137207026, 14.732917785644588],
        [80.11653137207026, 14.73347091674816],
        [80.1168060302735, 14.73347091674816],
        [80.1168060302735, 14.734029769897518],
        [80.11653137207026, 14.734027862548942],
        [80.11653137207026, 14.735695838928336],
        [80.1168060302735, 14.735691070556697],
        [80.1168060302735, 14.736530303955192],
        [80.11708068847685, 14.736530303955192],
        [80.11708068847685, 14.736809730529899],
        [80.11736297607422, 14.73680591583252],
        [80.11736297607422, 14.73708438873291],
        [80.11708068847685, 14.737081527710018],
        [80.11708068847685, 14.737371444702148],
        [80.1168060302735, 14.737371444702148],
        [80.1168060302735, 14.737640380859375],
        [80.11653137207026, 14.737638473510799],
        [80.11653137207026, 14.738194465637264],
        [80.11624908447271, 14.738194465637264],
        [80.11624908447271, 14.738471984863338],
        [80.11596679687494, 14.738471984863338],
        [80.11596679687494, 14.738750457763729],
        [80.1156921386721, 14.738750457763729],
        [80.1156921386721, 14.739311218261662],
        [80.1159744262697, 14.739306449890194],
        [80.1159744262697, 14.739583969116268],
        [80.11653137207026, 14.739583969116268],
        [80.11653137207026, 14.739306449890194],
        [80.11708068847685, 14.739311218261662],
        [80.11708068847685, 14.739032745361271],
        [80.1173706054688, 14.739032745361271],
        [80.1173706054688, 14.738471984863338],
        [80.11763763427763, 14.738471984863338],
        [80.11763763427763, 14.737915992736873],
        [80.117919921875, 14.737919807434082],
        [80.117919921875, 14.737371444702148],
        [80.11846923828142, 14.737371444702148],
        [80.11846923828142, 14.736530303955192],
        [80.11875152587896, 14.736530303955192],
        [80.11875152587896, 14.735968589782772],
        [80.1190261840822, 14.73597240447998],
        [80.1190261840822, 14.735695838928336],
        [80.11958312988276, 14.735695838928336],
        [80.11958312988276, 14.735139846801871],
        [80.12014007568371, 14.735139846801871],
        [80.12014007568371, 14.734859466552734],
        [80.12041473388672, 14.734862327575797],
        [80.12041473388672, 14.734306335449332],
        [80.1209716796875, 14.734306335449332],
        [80.1209716796875, 14.734027862548942],
        [80.12124633789091, 14.734029769897518],
        [80.12124633789091, 14.733750343322868],
        [80.12152862548845, 14.733750343322868],
        [80.12152862548845, 14.73347091674816],
        [80.12180328369146, 14.73347091674816],
        [80.12180328369146, 14.733193397522086],
        [80.12208557128923, 14.733193397522086],
        [80.12208557128923, 14.732917785644588],
        [80.12236022949241, 14.732919692993164],
        [80.12236022949241, 14.732361793518123],
        [80.12264251708979, 14.732361793518123],
        [80.12264251708979, 14.732081413269157],
        [80.12291717529303, 14.732084274291992],
        [80.12291717529303, 14.731804847717342],
        [80.12317657470732, 14.73180961608898],
        [80.12317657470732, 14.73153018951416],
        [80.12346649169922, 14.73153018951416],
        [80.12346649169922, 14.73124027252203],
        [80.12374877929716, 14.731248855590877],
        [80.12374877929716, 14.730695724487305],
        [80.12403106689453, 14.730695724487305],
        [80.12403106689453, 14.730415344238281],
        [80.12458038330095, 14.730422019958553],
        [80.12458038330095, 14.730141639709586],
        [80.12541961669945, 14.730141639709586],
        [80.12541961669945, 14.728749275207463],
        [80.12568664550776, 14.728749275207463],
        [80.12568664550776, 14.728468894958496],
        [80.12596893310553, 14.728470802307072],
        [80.12596893310553, 14.728195190429801],
        [80.12623596191435, 14.72819995880127],
        [80.12623596191435, 14.727919578552303],
        [80.1265258789063, 14.727919578552303],
        [80.1265258789063, 14.726250648498592],
        [80.12680816650419, 14.726250648498592],
        [80.12680816650419, 14.725971221923885],
        [80.12708282470703, 14.725973129272461],
        [80.12708282470703, 14.72569465637207],
        [80.12735748291044, 14.72569465637207],
        [80.12735748291044, 14.725138664245605],
        [80.12763977050798, 14.725140571594238],
        [80.12763977050798, 14.724861145019531],
        [80.12819671630876, 14.724861145019531],
        [80.12819671630876, 14.72458267211914],
        [80.12847137451195, 14.72458267211914],
        [80.12847137451195, 14.724305152893066],
        [80.12902832031256, 14.724311828613281],
        [80.12902832031256, 14.723751068115348],
        [80.1293106079101, 14.723751068115348],
        [80.1293106079101, 14.723470687866211],
        [80.12985992431669, 14.723472595214787],
        [80.12985992431669, 14.722916603088493],
        [80.13014221191406, 14.722921371459961],
        [80.13014221191406, 14.722360610962028],
        [80.13041687011747, 14.722360610962028],
        [80.13041687011747, 14.72208023071289],
        [80.13069152832048, 14.722083091735954],
        [80.13069152832048, 14.721529006958065],
        [80.1309661865235, 14.72153091430664],
        [80.1309661865235, 14.720970153808707],
        [80.13124847412126, 14.7209730148316],
        [80.13124847412126, 14.72069454193121],
        [80.1315307617188, 14.72069454193121],
        [80.1315307617188, 14.720138549804744],
        [80.13208007812506, 14.720141410827637],
        [80.13208007812506, 14.719860076904354],
        [80.13236999511713, 14.719860076904354],
        [80.1323623657226, 14.719581604003963],
        [80.13263702392584, 14.719581604003963],
        [80.13263702392584, 14.71902942657465],
        [80.13291931152355, 14.719031333923454],
        [80.13291931152355, 14.717640876770133],
        [80.13318634033203, 14.717640876770133],
        [80.13318634033203, 14.71735954284668],
        [80.13346862792997, 14.717363357544002],
        [80.13346862792997, 14.716527938842887],
        [80.13375091552734, 14.71653079986578],
        [80.13375091552734, 14.715970039367619],
        [80.13402557373075, 14.715971946716422],
        [80.13402557373075, 14.715415954589957],
        [80.1343078613283, 14.715420722961426],
        [80.1343078613283, 14.714859962463436],
        [80.1345825195313, 14.714859962463436],
        [80.1345825195313, 14.714579582214299],
        [80.13486480712908, 14.714584350585938],
        [80.13486480712908, 14.714305877685604],
        [80.13513946533226, 14.714310646057243],
        [80.13513946533226, 14.714030265808105],
        [80.13540649414074, 14.714030265808105],
        [80.13540649414074, 14.713470458984432],
        [80.13513946533226, 14.713472366333008],
        [80.13513946533226, 14.712081909179688],
        [80.1354141235351, 14.712081909179688],
        [80.1354141235351, 14.712362289428654],
        [80.13597106933605, 14.712362289428654],
        [80.13597106933605, 14.711250305175895],
        [80.136528015137, 14.711250305175895],
        [80.136528015137, 14.710692405700684],
        [80.13680267333984, 14.71069431304943],
        [80.13680267333984, 14.71041584014904],
        [80.136528015137, 14.710419654846248],
        [80.136528015137, 14.709030151367188],
        [80.13680267333984, 14.709030151367188],
        [80.13680267333984, 14.708190917968864],
        [80.136528015137, 14.708193778991756],
        [80.136528015137, 14.707362174987793],
        [80.13680267333984, 14.707362174987793],
        [80.13680267333984, 14.706806182861328],
        [80.13707733154325, 14.70680999755865],
        [80.13707733154325, 14.706250190734806],
        [80.1373596191408, 14.706250190734806],
        [80.1373596191408, 14.705970764160156],
        [80.13791656494158, 14.705970764160156],
        [80.13791656494158, 14.705693244934082],
        [80.13819122314476, 14.705693244934082],
        [80.13819122314476, 14.70486164093029],
        [80.13791656494158, 14.70486164093029],
        [80.13791656494158, 14.704031944274902],
        [80.13764190673834, 14.704031944274902],
        [80.13764190673834, 14.70347118377697],
        [80.1373596191408, 14.70347118377697],
        [80.1373596191408, 14.70291709899908],
        [80.13707733154325, 14.70292186737072],
        [80.13707733154325, 14.702642440795842],
        [80.13680267333984, 14.702642440795842],
        [80.13680267333984, 14.702080726623592],
        [80.136528015137, 14.702082633972225],
        [80.136528015137, 14.701249122619629],
        [80.13625335693365, 14.701259613037053],
        [80.13625335693365, 14.700970649719238],
        [80.13569641113287, 14.700972557067871],
        [80.13569641113287, 14.700416564941406],
        [80.13540649414074, 14.70041942596447],
        [80.13540649414074, 14.700140953064079],
        [80.13513946533226, 14.700140953064079],
        [80.13513946533226, 14.699581146240234],
        [80.13486480712908, 14.69958305358881],
        [80.13486480712908, 14.69930458068842],
        [80.1345825195313, 14.699310302734375],
        [80.1345825195313, 14.698471069336051],
        [80.1343078613283, 14.698472976684627],
        [80.1343078613283, 14.698195457458553],
        [80.13403320312528, 14.698200225830021],
        [80.13403320312528, 14.697918891906738],
        [80.13375091552734, 14.697918891906738],
        [80.13375091552734, 14.697360992431697],
        [80.13346862792997, 14.697360992431697],
        [80.13346862792997, 14.696250915527344],
        [80.13318634033203, 14.696250915527344],
        [80.13318634033203, 14.695701599121094],
        [80.13291931152355, 14.69569015502941],
        [80.13291931152355, 14.695138931274414],
        [80.1323623657226, 14.695138931274414],
        [80.1323623657226, 14.69486141204834],
        [80.13208007812506, 14.69486141204834],
        [80.13208007812506, 14.69458293914795],
        [80.13180541992205, 14.69458293914795],
        [80.13180541992205, 14.69402885437023],
        [80.13124847412126, 14.694030761718807],
        [80.13124847412126, 14.693751335144157],
        [80.1309661865235, 14.693751335144157],
        [80.1309661865235, 14.693470001220703],
        [80.13069152832048, 14.693472862243766],
        [80.13069152832048, 14.693195343017692],
        [80.13041687011747, 14.693195343017692],
        [80.13041687011747, 14.692916870117301],
        [80.12985992431669, 14.692920684814453],
        [80.12985992431669, 14.692641258239803],
        [80.1296005249024, 14.692641258239803],
        [80.1296005249024, 14.692070960998535],
        [80.13014221191406, 14.692082405090446],
        [80.13014221191406, 14.691806793212947],
        [80.13041687011747, 14.691806793212947],
        [80.13041687011747, 14.692082405090446],
        [80.13124847412126, 14.692070960998535],
        [80.13124847412126, 14.69235992431652],
        [80.13181304931658, 14.69235992431652],
        [80.13181304931658, 14.692641258239803],
        [80.13236999511713, 14.692641258239803],
        [80.13236999511713, 14.69235992431652],
        [80.13263702392584, 14.69235992431652],
        [80.13263702392584, 14.692070960998535],
        [80.13318634033203, 14.692070960998535],
        [80.13318634033203, 14.69235992431652],
        [80.13403320312528, 14.69235992431652],
        [80.13403320312528, 14.692641258239803],
        [80.1343078613283, 14.692641258239803],
        [80.1343078613283, 14.692920684814453],
        [80.1345825195313, 14.692916870117301],
        [80.1345825195313, 14.69458293914795],
        [80.13485717773455, 14.694580078125057],
        [80.13485717773455, 14.695421218872127],
        [80.13513946533226, 14.695417404174805],
        [80.13513946533226, 14.69597339630127],
        [80.13540649414074, 14.695970535278377],
        [80.13540649414074, 14.696250915527344],
        [80.13569641113287, 14.696250915527344],
        [80.13569641113287, 14.69653129577631],
        [80.13597106933605, 14.69653129577631],
        [80.13597106933605, 14.696811676025447],
        [80.13569641113287, 14.696811676025447],
        [80.13569641113287, 14.697918891906738],
        [80.13597106933605, 14.697918891906738],
        [80.13597106933605, 14.698200225830021],
        [80.13625335693365, 14.698200225830021],
        [80.13625335693365, 14.698751449585018],
        [80.13680267333984, 14.698751449585018],
        [80.13680267333984, 14.699030876159725],
        [80.13707733154325, 14.699030876159725],
        [80.13707733154325, 14.699310302734375],
        [80.1373596191408, 14.69930458068842],
        [80.1373596191408, 14.699860572814885],
        [80.13764190673834, 14.699860572814885],
        [80.13764190673834, 14.70041942596447],
        [80.13791656494158, 14.700416564941406],
        [80.13791656494158, 14.700972557067871],
        [80.1384658813476, 14.700970649719238],
        [80.1384658813476, 14.701259613037053],
        [80.13874816894537, 14.701259613037053],
        [80.13874816894537, 14.701528549194336],
        [80.13903045654308, 14.70152664184576],
        [80.13903045654308, 14.702361106872615],
        [80.13931274414068, 14.702359199523983],
        [80.13931274414068, 14.702642440795842],
        [80.1395797729495, 14.702642440795842],
        [80.1395797729495, 14.70292186737072],
        [80.13986206054688, 14.70291709899908],
        [80.13986206054688, 14.703194618225154],
        [80.14013671875028, 14.703194618225154],
        [80.14013671875028, 14.70597362518322],
        [80.13986206054688, 14.705970764160156],
        [80.13986206054688, 14.706529617309684],
        [80.1395797729495, 14.706527709960938],
        [80.1395797729495, 14.708193778991756],
        [80.13931274414068, 14.708190917968864],
        [80.13931274414068, 14.709309577941895],
        [80.13903045654308, 14.709309577941895],
        [80.13903045654308, 14.710419654846248],
        [80.13874816894537, 14.71041584014904],
        [80.13874816894537, 14.710971832275504],
        [80.1384658813476, 14.710971832275504],
        [80.1384658813476, 14.711811065673828],
        [80.13819122314476, 14.71180629730236],
        [80.13819122314476, 14.712362289428654],
        [80.13791656494158, 14.712360382080078],
        [80.13791656494158, 14.712921142578182],
        [80.13764190673834, 14.712916374206543],
        [80.13764190673834, 14.713472366333008],
        [80.1373596191408, 14.713470458984432],
        [80.1373596191408, 14.714030265808105],
        [80.13708496093778, 14.714028358459473],
        [80.13708496093778, 14.714584350585938],
        [80.13680267333984, 14.714579582214299],
        [80.13680267333984, 14.714859962463436],
        [80.136528015137, 14.714859962463436],
        [80.136528015137, 14.715971946716422],
        [80.13625335693365, 14.715970039367619],
        [80.13625335693365, 14.71653079986578],
        [80.13597106933605, 14.716527938842887],
        [80.13597106933605, 14.717082023620605],
        [80.13569641113287, 14.717080116271973],
        [80.13569641113287, 14.717640876770133],
        [80.1354141235351, 14.71763801574707],
        [80.1354141235351, 14.718194007873535],
        [80.13513946533226, 14.718190193176326],
        [80.13513946533226, 14.718750953674316],
        [80.13569641113287, 14.718750953674316],
        [80.13569641113287, 14.718469619751033],
        [80.13597106933605, 14.718473434448185],
        [80.13597106933605, 14.717915534973145],
        [80.13625335693365, 14.7179212570191],
        [80.13625335693365, 14.717640876770133],
        [80.136528015137, 14.717640876770133],
        [80.136528015137, 14.71735954284668],
        [80.13680267333984, 14.717363357544002],
        [80.13680267333984, 14.716806411743278],
        [80.13707733154325, 14.716811180114746],
        [80.13707733154325, 14.71653079986578],
        [80.1373596191408, 14.71653079986578],
        [80.1373596191408, 14.716261863708496],
        [80.13764190673834, 14.716261863708496],
        [80.13764190673834, 14.715970039367619],
        [80.13791656494158, 14.715971946716422],
        [80.13791656494158, 14.715415954589957],
        [80.13817596435553, 14.715420722961426],
        [80.13817596435553, 14.71514034271246],
        [80.1384658813476, 14.71514034271246],
        [80.1384658813476, 14.714579582214299],
        [80.13874816894537, 14.714584350585938],
        [80.13874816894537, 14.713472366333008],
        [80.13903045654308, 14.713472366333008],
        [80.13903045654308, 14.712362289428654],
        [80.13930511474615, 14.712362289428654],
        [80.13930511474615, 14.711250305175895],
        [80.1395797729495, 14.711250305175895],
        [80.1395797729495, 14.710140228271541],
        [80.13986206054688, 14.710140228271541],
        [80.13986206054688, 14.709579467773438],
        [80.14013671875028, 14.709584236145076],
        [80.14013671875028, 14.709305763244686],
        [80.14125061035179, 14.709309577941895],
        [80.14125061035179, 14.708749771118221],
        [80.14154052734392, 14.708749771118221],
        [80.14154052734392, 14.708190917968864],
        [80.1418075561524, 14.708193778991756],
        [80.1418075561524, 14.706250190734806],
        [80.14208221435558, 14.706250190734806],
        [80.14208221435558, 14.70541954040533],
        [80.14235687255865, 14.70541954040533],
        [80.14235687255865, 14.704581260681096],
        [80.14263916015653, 14.704583168029899],
        [80.14263916015653, 14.703748703003043],
        [80.14290618896484, 14.703751564025936],
        [80.14290618896484, 14.70292186737072],
        [80.14319610595732, 14.70292186737072],
        [80.14319610595732, 14.702080726623592],
        [80.14347076416033, 14.702082633972225],
        [80.14347076416033, 14.70152664184576],
        [80.1437530517581, 14.701528549194336],
        [80.1437530517581, 14.700691223144588],
        [80.14402770996111, 14.700693130493164],
        [80.14402770996111, 14.700139045715275],
        [80.14430999755865, 14.700140953064079],
        [80.14430999755865, 14.699581146240234],
        [80.14458465576189, 14.69958305358881],
        [80.14458465576189, 14.699027061462516],
        [80.1448593139649, 14.699030876159725],
        [80.1448593139649, 14.698751449585018],
        [80.14457702636713, 14.698751449585018],
        [80.14457702636713, 14.698200225830021],
        [80.14430999755865, 14.698200225830021],
        [80.14430999755865, 14.69708061218273],
        [80.14458465576189, 14.697083473205623],
        [80.14458465576189, 14.696526527404842],
        [80.1448593139649, 14.69653129577631],
        [80.1448593139649, 14.69569015502941],
        [80.14514160156244, 14.695694923400879],
        [80.14514160156244, 14.695138931274414],
        [80.14541625976568, 14.695138931274414],
        [80.14541625976568, 14.69263935089117],
        [80.14514160156244, 14.692641258239803],
        [80.14514160156244, 14.692070960998535],
        [80.14569091796903, 14.692082405090446],
        [80.14569091796903, 14.69152641296398],
        [80.1459732055664, 14.69153118133545],
        [80.1459732055664, 14.690691947937125],
        [80.14624786376982, 14.690693855285701],
        [80.14624786376982, 14.689860343933105],
        [80.14653015136719, 14.689860343933105],
        [80.14653015136719, 14.689027786254883],
        [80.14679718017584, 14.689030647277946],
        [80.14679718017584, 14.688190460205135],
        [80.14708709716814, 14.688194274902287],
        [80.14708709716814, 14.687640190124625],
        [80.14734649658226, 14.687640190124625],
        [80.14734649658226, 14.687361717224235],
        [80.14708709716814, 14.687361717224235],
        [80.14708709716814, 14.686811447143498],
        [80.14679718017584, 14.686811447143498],
        [80.14679718017584, 14.686249732971248],
        [80.14708709716814, 14.686249732971248],
        [80.14708709716814, 14.685691833496207],
        [80.14736175537115, 14.685693740844783],
        [80.14736175537115, 14.685137748718319],
        [80.14763641357439, 14.685139656066895],
        [80.14763641357439, 14.684578895568961],
        [80.1479187011721, 14.68458366394043],
        [80.1479187011721, 14.6834716796875],
        [80.14819335937494, 14.6834716796875],
        [80.14819335937494, 14.682640075683707],
        [80.14848327636724, 14.682640075683707],
        [80.14848327636724, 14.681529998779354],
        [80.1487503051759, 14.681529998779354],
        [80.1487503051759, 14.680419921875],
        [80.14901733398438, 14.680419921875],
        [80.14901733398438, 14.679311752319393],
        [80.14930725097685, 14.679311752319393],
        [80.14930725097685, 14.678470611572322],
        [80.14986419677763, 14.678470611572322],
        [80.14986419677763, 14.67791843414301],
        [80.15013885498064, 14.677920341491813],
        [80.15013885498064, 14.677360534667969],
        [80.15041351318365, 14.677360534667969],
        [80.15041351318365, 14.676804542541504],
        [80.15013885498064, 14.67681026458746],
        [80.15013885498064, 14.675690650939941],
        [80.15041351318365, 14.675695419311637],
        [80.15041351318365, 14.674860954284782],
        [80.1509704589846, 14.674860954284782],
        [80.1509704589846, 14.674029350280762],
        [80.15069580078142, 14.674029350280762],
        [80.15069580078142, 14.673471450805721],
        [80.15041351318365, 14.673473358154297],
        [80.15041351318365, 14.672917366027832],
        [80.15069580078142, 14.672919273376408],
        [80.15069580078142, 14.672638893127441],
        [80.15125274658197, 14.672638893127441],
        [80.15125274658197, 14.672361373901367],
        [80.15152740478521, 14.672361373901367],
        [80.15152740478521, 14.671251296997184],
        [80.1518020629884, 14.671251296997184],
        [80.1518020629884, 14.670689582824764],
        [80.15152740478521, 14.67069530487072],
        [80.15152740478521, 14.670416831970329],
        [80.1518020629884, 14.670420646667537],
        [80.1518020629884, 14.669031143188477],
        [80.1520767211914, 14.669031143188477],
        [80.1520767211914, 14.668190002441406],
        [80.15235900878935, 14.668194770813045],
        [80.15235900878935, 14.667917251586971],
        [80.15264129638672, 14.667921066284123],
        [80.15264129638672, 14.667640686035156],
        [80.15235900878935, 14.667640686035156],
        [80.15235900878935, 14.667361259460506],
        [80.15264129638672, 14.667361259460506],
        [80.15264129638672, 14.666251182556152],
        [80.15291595459013, 14.666251182556152],
        [80.15291595459013, 14.665690422058162],
        [80.15319061279314, 14.66569519042963],
        [80.15319824218767, 14.665416717529297],
        [80.15348052978544, 14.66542053222662],
        [80.15348052978544, 14.664311408996582],
        [80.15374755859392, 14.664311408996582],
        [80.15374755859392, 14.662091255188045],
        [80.15402984619163, 14.662091255188045],
        [80.15402984619163, 14.661250114440918],
        [80.15429687499994, 14.661250114440918],
        [80.15429687499994, 14.659311294555664],
        [80.15486145019543, 14.659311294555664],
        [80.15486145019543, 14.658191680908203],
        [80.1551361083985, 14.658193588256779],
        [80.1551361083985, 14.657917976379451],
        [80.15541839599638, 14.65792274475092],
        [80.15541839599638, 14.657640457153377],
        [80.15569305419922, 14.657640457153377],
        [80.15569305419922, 14.657361984252987],
        [80.15598297119169, 14.657361984252987],
        [80.15597534179716, 14.65625],
        [80.15625000000017, 14.65625],
        [80.15625000000017, 14.655689239501953],
        [80.15597534179716, 14.655694007873592],
        [80.15597534179716, 14.655139923095703],
        [80.15625000000017, 14.655139923095703],
        [80.15625000000017, 14.654859542846737],
        [80.15652465820318, 14.654862403869629],
        [80.15652465820318, 14.653750419616642],
        [80.15625000000017, 14.653750419616642],
        [80.15625000000017, 14.652640342712516],
        [80.15651702880865, 14.652640342712516],
        [80.15651702880865, 14.652080535888672],
        [80.15708160400413, 14.652084350585994],
        [80.15708160400413, 14.65180587768566],
        [80.15625000000017, 14.651809692382812],
        [80.15625000000017, 14.651251792907772],
        [80.15651702880865, 14.651251792907772],
        [80.15651702880865, 14.650151252746696],
        [80.15680694580095, 14.650151252746696],
        [80.15680694580095, 14.649031639099235],
        [80.15708160400413, 14.649031639099235],
        [80.15708160400413, 14.648191452026424],
        [80.15736389160173, 14.648195266723576],
        [80.15736389160173, 14.647083282470646],
        [80.15763854980474, 14.647083282470646],
        [80.15763854980474, 14.64624881744379],
        [80.15791320800793, 14.646250724792594],
        [80.15791320800793, 14.64514064788824],
        [80.15820312500006, 14.64514064788824],
        [80.15820312500006, 14.644020080566463],
        [80.15847015380888, 14.644026756286678],
        [80.15847015380888, 14.643195152282715],
        [80.15875244140625, 14.643195152282715],
        [80.15875244140625, 14.64236068725586],
        [80.15902709960966, 14.64236068725586],
        [80.15902709960966, 14.641530990600643],
        [80.15928649902361, 14.641530990600643],
        [80.15928649902361, 14.640689849853572],
        [80.15958404541044, 14.640694618225211],
        [80.15958404541044, 14.640138626098746],
        [80.15985870361345, 14.640140533447322],
        [80.15985870361345, 14.639310836791935],
        [80.160140991211, 14.639310836791935],
        [80.160140991211, 14.638469696045036],
        [80.16041564941423, 14.638472557067928],
        [80.16041564941423, 14.637638092041072],
        [80.16069793701195, 14.637640953063965],
        [80.16069793701195, 14.636811256408748],
        [80.16097259521479, 14.636811256408748],
        [80.16097259521479, 14.635970115661621],
        [80.16124725341803, 14.635972023010254],
        [80.16124725341803, 14.635416030883732],
        [80.16152954101574, 14.635420799255428],
        [80.16152954101574, 14.6345796585083],
        [80.16180419921875, 14.63458442687994],
        [80.16180419921875, 14.633749961853084],
        [80.16208648681669, 14.633749961853084],
        [80.16208648681669, 14.632921218872013],
        [80.16234588623064, 14.632921218872013],
        [80.16234588623064, 14.63208198547369],
        [80.16263580322294, 14.63208198547369],
        [80.16263580322294, 14.631250381469727],
        [80.16291809082048, 14.631250381469727],
        [80.16291809082048, 14.63069152832037],
        [80.1631927490235, 14.630694389343262],
        [80.1631927490235, 14.629861831665153],
        [80.1634826660158, 14.629861831665153],
        [80.16347503662126, 14.629305839538688],
        [80.16374969482445, 14.629309654235897],
        [80.16374969482445, 14.628469467163086],
        [80.16402435302729, 14.628472328186149],
        [80.16402435302729, 14.627916336059684],
        [80.16430664062506, 14.627920150756836],
        [80.16430664062506, 14.627080917358512],
        [80.16458129882824, 14.627083778381404],
        [80.16458129882824, 14.626250267028809],
        [80.16485595703125, 14.626250267028809],
        [80.16485595703125, 14.625691413879451],
        [80.16513824462919, 14.625693321228084],
        [80.16513824462919, 14.624861717224121],
        [80.16542053222656, 14.624861717224121],
        [80.16541290283203, 14.624027252197266],
        [80.1657028198245, 14.624032020568904],
        [80.1657028198245, 14.623471260070744],
        [80.16596984863298, 14.623471260070744],
        [80.16596984863298, 14.622638702392692],
        [80.16625213623053, 14.622641563415584],
        [80.16625213623053, 14.621809959411621],
        [80.16652679443376, 14.621809959411621],
        [80.16652679443376, 14.621249198913631],
        [80.16680908203148, 14.621249198913631],
        [80.16680908203148, 14.62069129943859],
        [80.16708374023432, 14.620693206787166],
        [80.16708374023432, 14.620139122009277],
        [80.16735839843756, 14.62014102935791],
        [80.16735839843756, 14.619581222534237],
        [80.16764068603527, 14.619583129882812],
        [80.16764068603527, 14.619027137756348],
        [80.16792297363287, 14.619031906127987],
        [80.16792297363287, 14.618471145629883],
        [80.16819763183622, 14.618473052978459],
        [80.16819763183622, 14.617917060852164],
        [80.1684799194336, 14.617921829223633],
        [80.1684799194336, 14.6173610687257],
        [80.16874694824247, 14.6173610687257],
        [80.16874694824247, 14.616811752319279],
        [80.16902923584001, 14.616811752319279],
        [80.16902923584001, 14.616250991821346],
        [80.1692962646485, 14.616250991821346],
        [80.1692962646485, 14.61597061157238],
        [80.1695861816408, 14.615973472595272],
        [80.1695861816408, 14.61541652679449],
        [80.16986083984398, 14.615421295165959],
        [80.16986083984398, 14.614860534668026],
        [80.17013549804682, 14.614860534668026],
        [80.17013549804682, 14.61430454254156],
        [80.17041778564459, 14.6143093109132],
        [80.17041778564459, 14.613751411437988],
        [80.17069244384777, 14.613751411437988],
        [80.17069244384777, 14.613189697265739],
        [80.17097473144537, 14.613195419311467],
        [80.17097473144537, 14.612639427185002],
        [80.17124938964872, 14.612641334533805],
        [80.17124938964872, 14.612079620361385],
        [80.17152404785156, 14.612081527709961],
        [80.17152404785156, 14.61180686950695],
        [80.1718063354495, 14.611810684204102],
        [80.1718063354495, 14.611250877380485],
        [80.17208099365251, 14.611250877380485],
        [80.17208099365251, 14.60958003997797],
        [80.17236328125028, 14.609581947326774],
        [80.17236328125028, 14.609306335449276],
        [80.1726379394533, 14.609311103820914],
        [80.1726379394533, 14.609030723571777],
        [80.17318725585932, 14.609030723571777],
        [80.17318725585932, 14.608750343322754],
        [80.17346954345709, 14.608750343322754],
        [80.17346954345709, 14.608469963073787],
        [80.17375183105463, 14.60847187042242],
        [80.17375183105463, 14.608194351196289],
        [80.17402648925787, 14.608194351196289],
        [80.17402648925787, 14.607915878295898],
        [80.1745834350586, 14.607915878295898],
        [80.1745834350586, 14.6076402664184],
        [80.174858093262, 14.6076402664184],
        [80.174858093262, 14.607361793518066],
        [80.17514038085938, 14.607361793518066],
        [80.17514038085938, 14.607080459594783],
        [80.17541503906278, 14.607084274291935],
        [80.17541503906278, 14.606805801391545],
        [80.17597198486334, 14.60681056976324],
        [80.17597198486334, 14.606530189514274],
        [80.17624664306658, 14.606530189514274],
        [80.17624664306658, 14.606251716613883],
        [80.17679595947277, 14.606251716613883],
        [80.17679595947277, 14.60597038269043],
        [80.1770858764649, 14.605972290039176],
        [80.1770858764649, 14.605693817138615],
        [80.1776351928711, 14.605693817138615],
        [80.1776351928711, 14.605416297912711],
        [80.17791748046903, 14.60542106628418],
        [80.17791748046903, 14.605139732360897],
        [80.17846679687528, 14.605139732360897],
        [80.17846679687528, 14.60487079620367],
        [80.17903137207037, 14.60487079620367],
        [80.17903137207037, 14.604581832885856],
        [80.17930603027361, 14.604583740234432],
        [80.17930603027361, 14.604306221008358],
        [80.17986297607416, 14.604310989379826],
        [80.17986297607416, 14.604029655456543],
        [80.18041992187511, 14.604029655456543],
        [80.18041992187511, 14.603750228881893],
        [80.18096923828153, 14.603750228881893],
        [80.18096923828153, 14.603469848632926],
        [80.18152618408232, 14.603471755981502],
        [80.18152618408232, 14.603194236755428],
        [80.18208312988287, 14.603194236755428],
        [80.18208312988287, 14.602915763855037],
        [80.18235778808611, 14.60291957855219],
        [80.18235778808611, 14.602640151977539],
        [80.18290710449213, 14.602640151977539],
        [80.18290710449213, 14.602359771728572],
        [80.18345642089872, 14.602359771728572],
        [80.18345642089872, 14.602079391479606],
        [80.1843032836914, 14.602084159851074],
        [80.1843032836914, 14.601805686950684],
        [80.18541717529314, 14.601810455322322],
        [80.18541717529314, 14.601530075073356],
        [80.18596649169916, 14.601530075073356],
        [80.18596649169916, 14.601249694824219],
        [80.18681335449224, 14.601249694824219],
        [80.18681335449224, 14.600969314575252],
        [80.18736267089844, 14.600972175598145],
        [80.18736267089844, 14.600693702697754],
        [80.18763732910185, 14.600693702697754],
        [80.18763732910185, 14.600418090820426],
        [80.18791961669922, 14.600419998169002],
        [80.18791961669922, 14.600139617920036],
        [80.18818664550787, 14.600139617920036],
        [80.18818664550787, 14.600419998169002],
        [80.18930816650413, 14.600418090820426],
        [80.18930816650413, 14.600693702697754],
        [80.19041442871094, 14.600693702697754],
        [80.19041442871094, 14.600972175598145],
        [80.19180297851568, 14.600972175598145],
        [80.19180297851568, 14.600693702697754],
        [80.19235992431663, 14.600693702697754],
        [80.19235992431663, 14.600418090820426],
        [80.19264984130876, 14.600419998169002],
        [80.19264984130876, 14.600139617920036],
        [80.19291687011724, 14.600139617920036],
        [80.19291687011724, 14.599859237670898],
        [80.19319152832043, 14.599862098693961],
        [80.19319152832043, 14.599306106567496],
        [80.19346618652344, 14.599309921264648],
        [80.19346618652344, 14.598752021789608],
        [80.19374847412138, 14.598752021789608],
        [80.19374847412138, 14.598470687866325],
        [80.19403076171875, 14.598473548889217],
        [80.19403076171875, 14.59819316864025],
        [80.19430541992216, 14.59819316864025],
        [80.19430541992216, 14.597640037536621],
        [80.19458007812517, 14.597641944885254],
        [80.19458007812517, 14.596810340881461],
        [80.19486236572271, 14.596810340881461],
        [80.19486236572271, 14.595138549804688],
        [80.19513702392595, 14.595138549804688],
        [80.19513702392595, 14.594861030578556],
        [80.19625091552746, 14.594861030578556],
        [80.19625091552746, 14.59458065032959],
        [80.19652557373053, 14.594582557678223],
        [80.19652557373053, 14.594305038452092],
        [80.19680786132841, 14.594311714172363],
        [80.19680786132841, 14.594029426574764],
        [80.19708251953125, 14.594029426574764],
        [80.19708251953125, 14.593191146850586],
        [80.19736480712919, 14.593193054199332],
        [80.19736480712919, 14.590139389038086],
        [80.1976394653322, 14.590141296386832],
        [80.1976394653322, 14.589580535888729],
        [80.19791412353521, 14.589583396911621],
        [80.19791412353521, 14.588473320007438],
        [80.19819641113298, 14.588473320007438],
        [80.19819641113298, 14.587917327880973],
        [80.19845581054693, 14.587919235229549],
        [80.19845581054693, 14.587640762329158],
        [80.19875335693376, 14.587640762329158],
        [80.19875335693376, 14.587080001831055],
        [80.19902801513678, 14.587082862854118],
        [80.19902801513678, 14.584029197692814],
        [80.19875335693376, 14.584031105041618],
        [80.19875335693376, 14.58373928070074],
        [80.19819641113298, 14.583750724792424],
        [80.19819641113298, 14.58347225189209],
        [80.19791412353521, 14.58347225189209],
        [80.19791412353521, 14.582916259765568],
        [80.1976394653322, 14.582921028137264],
        [80.1976394653322, 14.582360267639103],
        [80.19736480712919, 14.582360267639103],
        [80.19736480712919, 14.582083702087516],
        [80.1976394653322, 14.582083702087516],
        [80.1976394653322, 14.581804275512638],
        [80.19790649414068, 14.58181095123291],
        [80.19790649414068, 14.580680847167969],
        [80.1976394653322, 14.580694198608455],
        [80.1976394653322, 14.579584121704102],
        [80.19736480712919, 14.579584121704102],
        [80.19736480712919, 14.579305648803711],
        [80.19708251953125, 14.579311370849666],
        [80.19706726074236, 14.578201293945312],
        [80.19680786132841, 14.578188896179313],
        [80.19680786132841, 14.578060150146484],
        [80.19680786132841, 14.576806068420524],
        [80.19653320312506, 14.576812744140568],
        [80.19653320312506, 14.576530456543026],
        [80.19625091552746, 14.576530456543026],
        [80.19625091552746, 14.576251983642635],
        [80.19596862792974, 14.576251983642635],
        [80.19596862792974, 14.575689315795955],
        [80.1956939697265, 14.575694084167594],
        [80.1956939697265, 14.573749542236385],
        [80.19541931152366, 14.573749542236385],
        [80.19541931152366, 14.572919845581168],
        [80.19513702392595, 14.572919845581168],
        [80.19513702392595, 14.571801185607967],
        [80.19486236572271, 14.571805953979435],
        [80.19486236572271, 14.57097053527832],
        [80.19458007812517, 14.57097053527832],
        [80.19458007812517, 14.570139884948787],
        [80.19431304931669, 14.57014179229742],
        [80.19431304931669, 14.569310188293457],
        [80.19403076171875, 14.569310188293457],
        [80.19403076171875, 14.56819057464594],
        [80.19374847412138, 14.568195343017578],
        [80.19374847412138, 14.567360877990723],
        [80.19346618652344, 14.567360877990723],
        [80.19346618652344, 14.56652927398676],
        [80.19319152832043, 14.56652927398676],
        [80.19319152832043, 14.565971374511719],
        [80.19291687011724, 14.565973281860295],
        [80.19291687011724, 14.565417289734],
        [80.19264984130876, 14.565419197082576],
        [80.19264984130876, 14.564861297607536],
        [80.19235992431663, 14.564861297607536],
        [80.19235992431663, 14.563751220703182],
        [80.19208526611345, 14.563751220703182],
        [80.19208526611345, 14.562916755676326],
        [80.19180297851568, 14.56293106079113],
        [80.19180297851568, 14.561812400817928],
        [80.19152832031267, 14.561812400817928],
        [80.19152832031267, 14.560689926147575],
        [80.19125366210966, 14.560694694519043],
        [80.19125366210966, 14.559305191039982],
        [80.19097137451172, 14.559310913085938],
        [80.19097137451172, 14.55819034576416],
        [80.19069671630888, 14.558195114135799],
        [80.19069671630888, 14.557362556457576],
        [80.19041442871094, 14.557359695434684],
        [80.1904067993164, 14.557080268859806],
        [80.19013977050793, 14.55708217620861],
        [80.19013977050793, 14.556806564331112],
        [80.18958282470697, 14.556806564331112],
        [80.18958282470697, 14.55652904510498],
        [80.18930816650413, 14.556530952453613],
        [80.18930816650413, 14.55624961853033],
        [80.18902587890642, 14.55624961853033],
        [80.18902587890642, 14.555691719055233],
        [80.18875122070318, 14.555693626403865],
        [80.18875122070318, 14.554583549499512],
        [80.18846893310564, 14.554583549499512],
        [80.18846893310564, 14.553194046020451],
        [80.18819427490263, 14.553194046020451],
        [80.18819427490263, 14.552082061767692],
        [80.18791961669922, 14.552082061767692],
        [80.18791961669922, 14.550694465637264],
        [80.18763732910185, 14.550701141357479],
        [80.18763732910185, 14.549578666687125],
        [80.18736267089844, 14.54958438873291],
        [80.18736267089844, 14.54847240447998],
        [80.18708038330107, 14.54847240447998],
        [80.18708038330107, 14.547639846801871],
        [80.18680572509771, 14.547639846801871],
        [80.18680572509771, 14.546527862548942],
        [80.18651580810558, 14.546529769897518],
        [80.18651580810558, 14.545690536499023],
        [80.18624877929693, 14.545694351196346],
        [80.18624877929693, 14.544861793518123],
        [80.18596649169916, 14.544861793518123],
        [80.18596649169916, 14.54375171661377],
        [80.18569183349632, 14.54375171661377],
        [80.18569183349632, 14.542922019958553],
        [80.18541717529314, 14.542922019958553],
        [80.18541717529314, 14.542080879211426],
        [80.18514251709013, 14.542082786560059],
        [80.18514251709013, 14.540972709655875],
        [80.18486022949236, 14.540970802307072],
        [80.18486022949236, 14.54013919830328],
        [80.18459320068388, 14.54013919830328],
        [80.18459320068388, 14.53931045532238],
        [80.1843032836914, 14.53931045532238],
        [80.1843032836914, 14.538192749023494],
        [80.18402862548857, 14.53819465637207],
        [80.18402862548857, 14.537361145019531],
        [80.18374633789068, 14.537361145019531],
        [80.18374633789068, 14.536805152893066],
        [80.18345642089872, 14.536811828613281],
        [80.18345642089872, 14.536531448364315],
        [80.18319702148443, 14.536531448364315],
        [80.18319702148443, 14.536251068115348],
        [80.18290710449213, 14.536251068115348],
        [80.18290710449213, 14.535970687866211],
        [80.18264007568382, 14.535972595214957],
        [80.18264007568382, 14.535695075988883],
        [80.18235778808611, 14.535695075988883],
        [80.18235778808611, 14.535416603088493],
        [80.18208312988287, 14.535421371459961],
        [80.18208312988287, 14.535140991210994],
        [80.17914581298828, 14.535140991210994],
        [80.17874908447283, 14.535140991210994],
        [80.17874908447283, 14.534860610962028],
        [80.17847442626982, 14.534860610962028],
        [80.17847442626982, 14.534583091735954],
        [80.17791748046903, 14.534583091735954],
        [80.17791748046903, 14.534304618835563],
        [80.17764282226568, 14.534309387207031],
        [80.17764282226568, 14.53403091430664],
        [80.1770858764649, 14.53403091430664],
        [80.1770858764649, 14.533750534057674],
        [80.17679595947277, 14.533750534057674],
        [80.17679595947277, 14.533471107482967],
        [80.17624664306658, 14.5334730148316],
        [80.17624664306658, 14.53319454193121],
        [80.17542266845732, 14.53318977355957],
        [80.17542266845732, 14.533750534057674],
        [80.17514038085938, 14.533750534057674],
        [80.17514038085938, 14.53403091430664],
        [80.174858093262, 14.534029006958065],
        [80.174858093262, 14.534583091735954],
        [80.17457580566406, 14.53458023071289],
        [80.17457580566406, 14.535140991210994],
        [80.17430877685558, 14.535140991210994],
        [80.17430877685558, 14.535421371459961],
        [80.17402648925787, 14.535416603088493],
        [80.17402648925787, 14.535695075988883],
        [80.1726379394533, 14.535695075988883],
        [80.1726379394533, 14.535139083862418],
        [80.17236328125028, 14.535140991210994],
        [80.17236328125028, 14.53458023071289],
        [80.1726379394533, 14.534583091735954],
        [80.1726379394533, 14.533748626709098],
        [80.17292022705084, 14.533750534057674],
        [80.17292022705084, 14.532631874084473],
        [80.17319488525408, 14.532638549804744],
        [80.17319488525408, 14.53208255767828],
        [80.17346954345709, 14.53208255767828],
        [80.17346954345709, 14.531806945800781],
        [80.17402648925787, 14.531810760498104],
        [80.17402648925787, 14.531531333923454],
        [80.17652893066429, 14.531531333923454],
        [80.17652893066429, 14.531810760498104],
        [80.17819213867188, 14.531806945800781],
        [80.17819213867188, 14.53208255767828],
        [80.17846679687528, 14.532079696655387],
        [80.17846679687528, 14.532361030578613],
        [80.17874908447283, 14.532361030578613],
        [80.17874908447283, 14.532638549804744],
        [80.17903137207037, 14.532638549804744],
        [80.17903137207037, 14.53319454193121],
        [80.17930603027361, 14.53319454193121],
        [80.17930603027361, 14.5334730148316],
        [80.17958068847679, 14.533471107482967],
        [80.17958068847679, 14.533750534057674],
        [80.1806869506836, 14.533750534057674],
        [80.1806869506836, 14.533471107482967],
        [80.18096923828153, 14.5334730148316],
        [80.18096923828153, 14.53319454193121],
        [80.1812515258789, 14.53319454193121],
        [80.1812515258789, 14.532917022705135],
        [80.18152618408232, 14.532920837402457],
        [80.18152618408232, 14.532361030578613],
        [80.18180847167986, 14.532361030578613],
        [80.18180847167986, 14.531810760498104],
        [80.18208312988287, 14.531810760498104],
        [80.18208312988287, 14.531531333923454],
        [80.18264007568382, 14.531531333923454],
        [80.18264007568382, 14.5304212570191],
        [80.18235778808611, 14.5304212570191],
        [80.18235778808611, 14.528750419616756],
        [80.18208312988287, 14.528750419616756],
        [80.18208312988287, 14.527359962463436],
        [80.18180847167986, 14.527359962463436],
        [80.18180847167986, 14.525142669677791],
        [80.18152618408232, 14.525142669677791],
        [80.18152618408232, 14.52346134185791],
        [80.1812515258789, 14.523471832275504],
        [80.1812515258789, 14.521528244018612],
        [80.18096923828153, 14.521530151367188],
        [80.18096923828153, 14.51958084106451],
        [80.18069458007818, 14.519583702087402],
        [80.18069458007818, 14.517917633056754],
        [80.18041992187511, 14.51791954040533],
        [80.18041992187511, 14.515971183776912],
        [80.1801376342774, 14.515971183776912],
        [80.1801376342774, 14.514026641845703],
        [80.17986297607416, 14.514028549194336],
        [80.17986297607416, 14.512360572814885],
        [80.17958068847679, 14.512360572814885],
        [80.17958068847679, 14.509583473205623],
        [80.17930603027361, 14.509583473205623],
        [80.17930603027361, 14.505695343017635],
        [80.17903137207037, 14.505695343017635],
        [80.17903137207037, 14.50180435180664],
        [80.17874908447283, 14.501811027526912],
        [80.17874908447283, 14.497920989990348],
        [80.17846679687528, 14.497920989990348],
        [80.17846679687528, 14.494029998779354],
        [80.17819213867188, 14.494029998779354],
        [80.17819213867188, 14.490691184997615],
        [80.17791748046903, 14.490693092346248],
        [80.17791748046903, 14.487360954284725],
        [80.1776351928711, 14.487360954284725],
        [80.1776351928711, 14.484305381774902],
        [80.17736053466808, 14.484310150146541],
        [80.17736053466808, 14.48125076293951],
        [80.1770858764649, 14.48125076293951],
        [80.1770858764649, 14.47792053222662],
        [80.17679595947277, 14.47792053222662],
        [80.17679595947277, 14.474860191345272],
        [80.17652893066429, 14.474860191345272],
        [80.17652893066429, 14.47097206115734],
        [80.17624664306658, 14.47097206115734],
        [80.17624664306658, 14.465971946716252],
        [80.17597198486334, 14.465971946716252],
        [80.17597198486334, 14.460970878601074],
        [80.17569732666033, 14.460970878601074],
        [80.17569732666033, 14.45625114440918],
        [80.17541503906278, 14.45625114440918],
        [80.17541503906278, 14.451251029968262],
        [80.17514038085938, 14.451251029968262],
        [80.17514038085938, 14.4470796585083],
        [80.174858093262, 14.44708442687994],
        [80.174858093262, 14.443750381469727],
        [80.17457580566406, 14.443750381469727],
        [80.17457580566406, 14.440420150756836],
        [80.17430877685558, 14.440420150756836],
        [80.17430877685558, 14.437081336975098],
        [80.17402648925787, 14.43708419799799],
        [80.17402648925787, 14.433749198913631],
        [80.17375183105463, 14.433749198913631],
        [80.17375183105463, 14.430431365966797],
        [80.17346954345709, 14.430431365966797],
        [80.17346954345709, 14.425689697265739],
        [80.17319488525408, 14.425695419311467],
        [80.17319488525408, 14.419584274291935],
        [80.17292022705084, 14.419584274291935],
        [80.17292022705084, 14.412918090820426],
        [80.1726379394533, 14.412919998169002],
        [80.1726379394533, 14.405691146850586],
        [80.17236328125028, 14.405693054199332],
        [80.17236328125028, 14.390137672424316],
        [80.17208099365251, 14.390139579772892],
        [80.17208099365251, 14.387359619140739],
        [80.17236328125028, 14.387361526489315],
        [80.17236328125028, 14.386249542236385],
        [80.1718063354495, 14.386249542236385],
        [80.1718063354495, 14.3848619461059],
        [80.17208099365251, 14.3848619461059],
        [80.17208099365251, 14.38264179229742],
        [80.1718063354495, 14.38264179229742],
        [80.1718063354495, 14.380970954895076],
        [80.17152404785156, 14.380970954895076],
        [80.17152404785156, 14.379304885864258],
        [80.17124938964872, 14.379321098327637],
        [80.17124938964872, 14.377640724182186],
        [80.17096710205084, 14.377640724182186],
        [80.17096710205084, 14.375970840454215],
        [80.17069244384777, 14.375970840454215],
        [80.17069244384777, 14.374581336975154],
        [80.17041778564459, 14.374583244323787],
        [80.17041778564459, 14.370695114135799],
        [80.17097473144537, 14.370695114135799],
        [80.17097473144537, 14.370139122009334],
        [80.17041778564459, 14.370139122009334],
        [80.17041778564459, 14.369582176208553],
        [80.17013549804682, 14.369582176208553],
        [80.17013549804682, 14.364582061767692],
        [80.16986083984398, 14.364582061767692],
        [80.16986083984398, 14.36069393157959],
        [80.1695861816408, 14.36069393157959],
        [80.1695861816408, 14.359583854675407],
        [80.16930389404303, 14.359583854675407],
        [80.16930389404303, 14.359027862548942],
        [80.1695861816408, 14.359029769897518],
        [80.1695861816408, 14.35875034332281],
        [80.1692962646485, 14.35875034332281],
        [80.1692962646485, 14.358190536499023],
        [80.16902923584001, 14.358194351196346],
        [80.16902923584001, 14.357084274291992],
        [80.16874694824247, 14.357084274291992],
        [80.16874694824247, 14.355695724487248],
        [80.16847229003906, 14.355695724487248],
        [80.16847229003906, 14.354305267333928],
        [80.16819763183622, 14.354310035705566],
        [80.16819763183622, 14.353749275207633],
        [80.1684799194336, 14.353749275207633],
        [80.16847229003906, 14.353193283081168],
        [80.16819763183622, 14.353193283081168],
        [80.16819763183622, 14.352360725402889],
        [80.16791534423834, 14.352360725402889],
        [80.16791534423834, 14.351804733276424],
        [80.16764068603527, 14.35181045532238],
        [80.16764068603527, 14.350140571594181],
        [80.16735839843756, 14.350140571594181],
        [80.16735839843756, 14.349577903747672],
        [80.16708374023432, 14.34958267211914],
        [80.16708374023432, 14.349026679992676],
        [80.16680908203148, 14.349026679992676],
        [80.16680908203148, 14.348195075988883],
        [80.16652679443376, 14.348195075988883],
        [80.16652679443376, 14.347639083862418],
        [80.16625213623053, 14.347640991210994],
        [80.16625213623053, 14.346809387207031],
        [80.16596984863298, 14.346809387207031],
        [80.16596984863298, 14.344861030578613],
        [80.16569519042997, 14.344861030578613],
        [80.16569519042997, 14.344582557678223],
        [80.16541290283203, 14.344582557678223],
        [80.16541290283203, 14.343473434448356],
        [80.16569519042997, 14.343473434448356],
        [80.16569519042997, 14.342917442321891],
        [80.16542053222656, 14.3429212570191],
        [80.16542053222656, 14.34235954284668],
        [80.16513824462919, 14.34235954284668],
        [80.16513824462919, 14.341807365417537],
        [80.16485595703125, 14.341811180114746],
        [80.16485595703125, 14.340420722961369],
        [80.16458129882824, 14.340420722961369],
        [80.16458129882824, 14.340151786804313],
        [80.16430664062506, 14.340151786804313],
        [80.16430664062506, 14.339859962463436],
        [80.16401672363293, 14.339859962463436],
        [80.16401672363293, 14.339031219482536],
        [80.16374969482445, 14.339031219482536],
        [80.16374969482445, 14.338470458984432],
        [80.16347503662126, 14.338472366333008],
        [80.16347503662126, 14.337916374206543],
        [80.1631927490235, 14.337921142578182],
        [80.1631927490235, 14.337360382080078],
        [80.16291809082048, 14.337362289428825],
        [80.16291809082048, 14.33652782440197],
        [80.16263580322294, 14.336529731750545],
        [80.16263580322294, 14.335971832275504],
        [80.16236114501953, 14.335971832275504],
        [80.16236114501953, 14.33541584014904],
        [80.16208648681669, 14.335419654846191],
        [80.16208648681669, 14.334859848022575],
        [80.16160583496094, 14.334859848022575],
        [80.16124725341803, 14.334859848022575],
        [80.16124725341803, 14.334020614624023],
        [80.16124725341803, 14.333749771118221],
        [80.1610717773438, 14.333749771118221],
        [80.16097259521479, 14.333749771118221],
        [80.16097259521479, 14.333601951599064],
        [80.16097259521479, 14.333193778991756],
        [80.16069793701195, 14.333193778991756],
        [80.16069793701195, 14.332362174987736],
        [80.16041564941423, 14.332362174987736],
        [80.16041564941423, 14.331806182861271],
        [80.160140991211, 14.33180999755865],
        [80.160140991211, 14.331250190734806],
        [80.15985870361345, 14.331250190734806],
        [80.15985870361345, 14.33041954040533],
        [80.15957641601568, 14.33041954040533],
        [80.15957641601568, 14.329861640930233],
        [80.15928649902361, 14.329861640930233],
        [80.15928649902361, 14.329310417175236],
        [80.15902709960966, 14.329310417175236],
        [80.15902709960966, 14.328469276428336],
        [80.15875244140625, 14.328471183776912],
        [80.15875244140625, 14.32791805267334],
        [80.15847015380888, 14.327919960021916],
        [80.15847015380888, 14.327359199523983],
        [80.15819549560553, 14.327361106872559],
        [80.15819549560553, 14.327082633972168],
        [80.15791320800793, 14.327082633972168],
        [80.15791320800793, 14.326805114746094],
        [80.15763854980474, 14.326811790466365],
        [80.15763854980474, 14.325970649719238],
        [80.15736389160173, 14.325972557067814],
        [80.15736389160173, 14.32541656494135],
        [80.15708160400413, 14.325420379638729],
        [80.15708160400413, 14.324851989746037],
        [80.15680694580095, 14.324860572814885],
        [80.15680694580095, 14.324027061462516],
        [80.15651702880865, 14.324031829833984],
        [80.15651702880865, 14.323471069336051],
        [80.15625000000017, 14.323471069336051],
        [80.15625000000017, 14.322916984558162],
        [80.15598297119169, 14.322921752929801],
        [80.15598297119169, 14.32208061218273],
        [80.15569305419922, 14.322083473205623],
        [80.15569305419922, 14.321526527404842],
        [80.15541839599638, 14.32153129577648],
        [80.15541839599638, 14.320970535278377],
        [80.1551361083985, 14.32097339630127],
        [80.1551361083985, 14.320138931274414],
        [80.15486145019543, 14.32014083862316],
        [80.15486145019543, 14.319580078125057],
        [80.15458679199224, 14.319582939147892],
        [80.15458679199224, 14.3187513351441],
        [80.15429687499994, 14.3187513351441],
        [80.15429687499994, 14.31818962097168],
        [80.15402984619163, 14.318195343017635],
        [80.15402984619163, 14.31736087799078],
        [80.15374755859392, 14.31736087799078],
        [80.15374755859392, 14.31681060791027],
        [80.15348052978544, 14.31681060791027],
        [80.15348052978544, 14.3159818649292],
        [80.15319824218767, 14.3159818649292],
        [80.15319061279314, 14.315421104431266],
        [80.1529083251956, 14.315421104431266],
        [80.15291595459013, 14.314579963684139],
        [80.15262603759783, 14.314591407775879],
        [80.15264129638672, 14.314030647277889],
        [80.1524429321289, 14.314027786254826],
        [80.15235900878935, 14.314027786254826],
        [80.15235900878935, 14.31379413604742],
        [80.15235900878935, 14.313194274902457],
        [80.152084350586, 14.313194274902457],
        [80.152084350586, 14.31298923492443],
        [80.152084350586, 14.312638282775993],
        [80.15196228027372, 14.312638282775993],
        [80.1518020629884, 14.312640190124569],
        [80.1518020629884, 14.312159538269043],
        [80.1518020629884, 14.311805725097713],
        [80.15167999267578, 14.311808586120605],
        [80.15152740478521, 14.311811447143668],
        [80.15152740478521, 14.31135272979742],
        [80.15152740478521, 14.311249732971248],
        [80.15125274658197, 14.311249732971248],
        [80.15125274658197, 14.31041622161871],
        [80.15116882324241, 14.310418128967285],
        [80.1509704589846, 14.310420989990348],
        [80.1509704589846, 14.31000995635992],
        [80.1509704589846, 14.30986213684082],
        [80.15069580078142, 14.30986213684082],
        [80.15069580078142, 14.30951976776123],
        [80.15069580078142, 14.309306144714355],
        [80.15058135986334, 14.309306144714355],
        [80.15040588378929, 14.309310913085994],
        [80.15041351318365, 14.30900955200201],
        [80.15041351318365, 14.3084716796875],
        [80.15013885498064, 14.3084716796875],
        [80.15013885498064, 14.307915687561035],
        [80.1498565673831, 14.307920455932674],
        [80.1498565673831, 14.307079315185547],
        [80.14958190917969, 14.307084083557243],
        [80.14958190917969, 14.306528091430778],
        [80.14930725097685, 14.306529998779354],
        [80.14930725097685, 14.305688858032227],
        [80.1490249633789, 14.305693626403922],
        [80.1490249633789, 14.3051376342774],
        [80.1487503051759, 14.305139541626033],
        [80.1487503051759, 14.304309844970817],
        [80.14848327636724, 14.304309844970817],
        [80.14848327636724, 14.303751945495549],
        [80.14819335937494, 14.303751945495549],
        [80.14819335937494, 14.302920341491756],
        [80.1479187011721, 14.302920341491756],
        [80.1479187011721, 14.302362442016715],
        [80.14763641357439, 14.302362442016715],
        [80.14763641357439, 14.301527023315373],
        [80.14734649658226, 14.301531791687069],
        [80.14734649658226, 14.300971031188908],
        [80.14708709716814, 14.300971031188908],
        [80.14708709716814, 14.300139427185115],
        [80.14679718017584, 14.300139427185115],
        [80.14679718017584, 14.299580574035758],
        [80.14653015136719, 14.29958343505865],
        [80.14653015136719, 14.298748970031795],
        [80.14624786376982, 14.298750877380371],
        [80.14624786376982, 14.2979097366333],
        [80.1459732055664, 14.297917366027832],
        [80.1459732055664, 14.296805381774902],
        [80.14569091796903, 14.296810150146541],
        [80.14569091796903, 14.295970916748047],
        [80.14541625976568, 14.295970916748047],
        [80.14541625976568, 14.295139312744254],
        [80.14514160156244, 14.29514122009283],
        [80.14514160156244, 14.294311523437614],
        [80.1448593139649, 14.294311523437614],
        [80.1448593139649, 14.293471336364803],
        [80.14458465576189, 14.293473243713436],
        [80.14458465576189, 14.29236125946045],
        [80.14430236816429, 14.29236125946045],
        [80.14430236816429, 14.291528701782227],
        [80.14402770996111, 14.291530609130973],
        [80.14402770996111, 14.290690422058162],
        [80.1437530517581, 14.29069519042963],
        [80.1437530517581, 14.289860725402775],
        [80.14347076416033, 14.289860725402775],
        [80.14347076416033, 14.289031028747559],
        [80.14319610595732, 14.289031028747559],
        [80.14319610595732, 14.287920951843205],
        [80.14290618896484, 14.287920951843205],
        [80.14290618896484, 14.287081718444881],
        [80.14263916015653, 14.287084579467773],
        [80.14263916015653, 14.286250114440918],
        [80.14235687255865, 14.286250114440918],
        [80.14235687255865, 14.285421371460018],
        [80.14208221435558, 14.285421371460018],
        [80.14208221435558, 14.284311294555664],
        [80.1418075561524, 14.284311294555664],
        [80.1418075561524, 14.283191680908203],
        [80.14152526855463, 14.28319358825695],
        [80.14152526855463, 14.282084465026912],
        [80.14125061035179, 14.282084465026912],
        [80.14125061035179, 14.280971527099666],
        [80.14097595214861, 14.280971527099666],
        [80.14097595214861, 14.279862403869572],
        [80.1406860351563, 14.27987098693842],
        [80.1406860351563, 14.278750419616642],
        [80.14041900634783, 14.278750419616642],
        [80.14041900634783, 14.277919769287166],
        [80.14013671875028, 14.277919769287166],
        [80.14013671875028, 14.276809692382812],
        [80.13986206054688, 14.276809692382812],
        [80.13986206054688, 14.275691032409782],
        [80.1395797729495, 14.27569580078125],
        [80.1395797729495, 14.274582862854004],
        [80.13930511474615, 14.274582862854004],
        [80.13930511474615, 14.273470878601074],
        [80.13903045654308, 14.273470878601074],
        [80.13903045654308, 14.27236080169672],
        [80.13874816894537, 14.27236080169672],
        [80.13874816894537, 14.270138740539608],
        [80.1384658813476, 14.270140647888184],
        [80.1384658813476, 14.266809463501033],
        [80.13817596435553, 14.266809463501033],
        [80.13817596435553, 14.263469696045036],
        [80.13791656494158, 14.263472557067871],
        [80.13791656494158, 14.25847244262701],
        [80.13764190673834, 14.25847244262701],
        [80.13764190673834, 14.253749847412223],
        [80.1373596191408, 14.253749847412223],
        [80.1373596191408, 14.25263977050787],
        [80.13709259033232, 14.25263977050787],
        [80.13709259033232, 14.252081871032772],
        [80.13680267333984, 14.252083778381404],
        [80.13680267333984, 14.251806259155273],
        [80.136528015137, 14.251810073852653],
        [80.136528015137, 14.251529693603516],
        [80.13569641113287, 14.251529693603516],
        [80.13569641113287, 14.250691413879451],
        [80.1354141235351, 14.250694274902344],
        [80.1354141235351, 14.2493057250976],
        [80.13513946533226, 14.249309539794979],
        [80.13513946533226, 14.248190879821777],
        [80.13486480712908, 14.248195648193416],
        [80.13486480712908, 14.246805191040096],
        [80.1345825195313, 14.246805191040096],
        [80.1345825195313, 14.245416641235352],
        [80.1343078613283, 14.24542045593273],
        [80.1343078613283, 14.244310379028377],
        [80.13403320312528, 14.244310379028377],
        [80.13403320312528, 14.24374008178711],
        [80.13402557373075, 14.242917060852164],
        [80.13375091552734, 14.242921829223633],
        [80.13375091552734, 14.2423610687257],
        [80.13434600830084, 14.2423610687257],
        [80.1345825195313, 14.2423610687257],
        [80.1345825195313, 14.24097061157238],
        [80.13486480712908, 14.240973472595272],
        [80.13486480712908, 14.239860534668026],
        [80.1345825195313, 14.239860534668026],
        [80.1345825195313, 14.23930454254156],
        [80.1343078613283, 14.2393093109132],
        [80.1343078613283, 14.238751411437931],
        [80.13402557373075, 14.238751411437931],
        [80.13402557373075, 14.238195419311467],
        [80.13375091552734, 14.238195419311467],
        [80.13375091552734, 14.237639427185172],
        [80.13346862792997, 14.237639427185172],
        [80.13346862792997, 14.237083435058707],
        [80.13319396972656, 14.237083435058707],
        [80.13319396972656, 14.236525535583496],
        [80.13291931152355, 14.236531257629395],
        [80.13291931152355, 14.235690116882324],
        [80.13263702392584, 14.235694885253963],
        [80.13263702392584, 14.234581947326717],
        [80.1323623657226, 14.234581947326717],
        [80.1323623657226, 14.233194351196289],
        [80.13208007812506, 14.233194351196289],
        [80.13208007812506, 14.231805801391545],
        [80.13181304931658, 14.231820106506348],
        [80.13181304931658, 14.230691909790039],
        [80.1315307617188, 14.230693817138786],
        [80.1315307617188, 14.229306221008358],
        [80.13124847412126, 14.229310989379996],
        [80.13124847412126, 14.227640151977539],
        [80.1309661865235, 14.227640151977539],
        [80.1309661865235, 14.225970268249512],
        [80.13069152832048, 14.225972175598088],
        [80.13069152832048, 14.22430610656744],
        [80.13041687011747, 14.224309921264648],
        [80.13041687011747, 14.222920417785588],
        [80.13014221191406, 14.222920417785588],
        [80.13014221191406, 14.221248626708984],
        [80.12985992431669, 14.221248626708984],
        [80.12985992431669, 14.219590187072697],
        [80.1296005249024, 14.219590187072697],
        [80.1296005249024, 14.217920303344727],
        [80.1293106079101, 14.217920303344727],
        [80.1293106079101, 14.216531753540153],
        [80.12902832031256, 14.216531753540153],
        [80.12902832031256, 14.214860916137695],
        [80.12874603271479, 14.214860916137695],
        [80.12874603271479, 14.213190078735352],
        [80.12847137451195, 14.213194847107047],
        [80.12847137451195, 14.211528778076229],
        [80.12819671630876, 14.211530685424805],
        [80.12819671630876, 14.210689544677678],
        [80.12902832031256, 14.210695266723633],
        [80.12902832031256, 14.210139274597168],
        [80.12874603271479, 14.210141181945744],
        [80.12874603271479, 14.209310531616268],
        [80.12847137451195, 14.209310531616268],
        [80.12847137451195, 14.207921028137207],
        [80.12819671630876, 14.207921028137207],
        [80.12819671630876, 14.206530570983887],
        [80.12790679931646, 14.206530570983887],
        [80.12790679931646, 14.205141067504883],
        [80.12819671630876, 14.205141067504883],
        [80.12819671630876, 14.204590797424373],
        [80.12847137451195, 14.204590797424373],
        [80.12847137451195, 14.204311370849666],
        [80.12819671630876, 14.204311370849666],
        [80.12819671630876, 14.203749656677246],
        [80.12790679931646, 14.203749656677246],
        [80.12790679931646, 14.201250076294059],
        [80.12763977050798, 14.201250076294059],
        [80.12763977050798, 14.199579238891545],
        [80.127914428711, 14.19958400726324],
        [80.127914428711, 14.199028015136719],
        [80.12763977050798, 14.199029922485352],
        [80.12763977050798, 14.198459625244254],
        [80.127914428711, 14.198472023010254],
        [80.127914428711, 14.194583892822322],
        [80.12763977050798, 14.194583892822322],
        [80.12763977050798, 14.194305419921932],
        [80.12735748291044, 14.1943101882934],
        [80.12735748291044, 14.194029808044434],
        [80.12708282470703, 14.194029808044434],
        [80.12708282470703, 14.19291973114008],
        [80.12735748291044, 14.19291973114008],
        [80.12735748291044, 14.192081451416072],
        [80.12763977050798, 14.192083358764648],
        [80.12763977050798, 14.191527366638184],
        [80.12735748291044, 14.191530227661246],
        [80.12735748291044, 14.191250801086369],
        [80.12708282470703, 14.191250801086369],
        [80.12708282470703, 14.190141677856502],
        [80.12680816650419, 14.190141677856502],
        [80.12680816650419, 14.189861297607536],
        [80.12708282470703, 14.189861297607536],
        [80.12708282470703, 14.189582824707145],
        [80.12680816650419, 14.189582824707145],
        [80.12680816650419, 14.18930530548107],
        [80.12708282470703, 14.189311981201286],
        [80.12708282470703, 14.186519622802734],
        [80.12735748291044, 14.186529159545898],
        [80.12735748291044, 14.185417175292912],
        [80.12708282470703, 14.185420989990291],
        [80.12708282470703, 14.184579849243164],
        [80.1265258789063, 14.184582710266056],
        [80.1265258789063, 14.184026718139592],
        [80.12680816650419, 14.184030532836971],
        [80.12680816650419, 14.183751106262264],
        [80.12708282470703, 14.183751106262264],
        [80.12708282470703, 14.18319034576416],
        [80.12735748291044, 14.183195114135799],
        [80.12735748291044, 14.182639122009334],
        [80.12708282470703, 14.18264102935791],
        [80.12708282470703, 14.180970191955623],
        [80.12680816650419, 14.180973052978516],
        [80.12680816650419, 14.180693626403809],
        [80.1265258789063, 14.180693626403809],
        [80.1265258789063, 14.178750038147086],
        [80.12680816650419, 14.178750038147086],
        [80.12680816650419, 14.178190231323242],
        [80.1265258789063, 14.178194046020621],
        [80.1265258789063, 14.177638053894157],
        [80.12623596191435, 14.177639961242733],
        [80.12623596191435, 14.176811218261832],
        [80.1265258789063, 14.176811218261832],
        [80.1265258789063, 14.176532745361442],
        [80.12680816650419, 14.176532745361442],
        [80.12680816650419, 14.175422668457088],
        [80.1265258789063, 14.175422668457088],
        [80.1265258789063, 14.175140380859375],
        [80.12680816650419, 14.175140380859375],
        [80.12680816650419, 14.174860000610408],
        [80.1265258789063, 14.174860000610408],
        [80.1265258789063, 14.174579620361385],
        [80.12680816650419, 14.17458438873291],
        [80.12680816650419, 14.173749923706055],
        [80.1265258789063, 14.173749923706055],
        [80.1265258789063, 14.173192024230957],
        [80.12625122070324, 14.173193931579533],
        [80.12625122070324, 14.17125034332281],
        [80.12680816650419, 14.17125034332281],
        [80.12680816650419, 14.1695814132691],
        [80.1265258789063, 14.169584274291992],
        [80.1265258789063, 14.169305801391602],
        [80.12623596191435, 14.16930961608898],
        [80.12623596191435, 14.169030189514103],
        [80.1265258789063, 14.169030189514103],
        [80.1265258789063, 14.168751716613713],
        [80.12680816650419, 14.168751716613713],
        [80.12680816650419, 14.16792011260992],
        [80.12708282470703, 14.16792011260992],
        [80.12708282470703, 14.167639732360954],
        [80.12680816650419, 14.167639732360954],
        [80.12680816650419, 14.166810035705566],
        [80.12708282470703, 14.166810035705566],
        [80.12708282470703, 14.1665296554566],
        [80.12680816650419, 14.1665296554566],
        [80.12680816650419, 14.166249275207633],
        [80.1265258789063, 14.166249275207633],
        [80.1265258789063, 14.164581298828239],
        [80.12680816650419, 14.164583206176815],
        [80.12680816650419, 14.16374874114996],
        [80.12708282470703, 14.163750648498535],
        [80.12708282470703, 14.162640571594181],
        [80.12680816650419, 14.162640571594181],
        [80.12680816650419, 14.162078857421932],
        [80.12708282470703, 14.16208267211914],
        [80.12708282470703, 14.160416603088493],
        [80.12735748291044, 14.160419464111385],
        [80.12735748291044, 14.160140991210994],
        [80.12708282470703, 14.160140991210994],
        [80.12708282470703, 14.159860610962028],
        [80.12680816650419, 14.159860610962028],
        [80.12680816650419, 14.159583091735897],
        [80.1265258789063, 14.159583091735897],
        [80.1265258789063, 14.159029006958065],
        [80.12623596191435, 14.15903091430664],
        [80.12623596191435, 14.158750534057674],
        [80.12596893310553, 14.158750534057674],
        [80.12596893310553, 14.158471107482967],
        [80.12625122070324, 14.158473014831543],
        [80.12625122070324, 14.157917022705078],
        [80.1265258789063, 14.157920837402457],
        [80.1265258789063, 14.157361030578613],
        [80.12680816650419, 14.157361030578613],
        [80.12680816650419, 14.155969619750977],
        [80.1265258789063, 14.155973434448356],
        [80.1265258789063, 14.154303550720158],
        [80.12680816650419, 14.15431118011469],
        [80.12680816650419, 14.152920722961369],
        [80.12708282470703, 14.152920722961369],
        [80.12708282470703, 14.152641296386719],
        [80.12735748291044, 14.152641296386719],
        [80.12735748291044, 14.15207958221447],
        [80.12763977050798, 14.152085304260254],
        [80.12763977050798, 14.150137901306152],
        [80.12735748291044, 14.150142669677791],
        [80.12735748291044, 14.149581909179688],
        [80.12708282470703, 14.149581909179688],
        [80.12708282470703, 14.14930629730236],
        [80.12735748291044, 14.149311065673828],
        [80.12735748291044, 14.148750305175895],
        [80.12763977050798, 14.148750305175895],
        [80.12763977050798, 14.147920608520508],
        [80.12735748291044, 14.147920608520508],
        [80.12735748291044, 14.147640228271541],
        [80.12708282470703, 14.147640228271541],
        [80.12708282470703, 14.146810531616325],
        [80.12735748291044, 14.146810531616325],
        [80.12735748291044, 14.146530151367188],
        [80.12790679931646, 14.146530151367188],
        [80.12790679931646, 14.145420074463004],
        [80.12819671630876, 14.145420074463004],
        [80.12819671630876, 14.143750190734977],
        [80.12790679931646, 14.143750190734977],
        [80.12790679931646, 14.14291954040533],
        [80.12763977050798, 14.14291954040533],
        [80.12763977050798, 14.142361640930233],
        [80.12790679931646, 14.142361640930233],
        [80.12790679931646, 14.142081260681266],
        [80.12819671630876, 14.142084121704158],
        [80.12819671630876, 14.140695571899414],
        [80.127914428711, 14.140695571899414],
        [80.127914428711, 14.14041805267334],
        [80.12763977050798, 14.140419960021916],
        [80.12763977050798, 14.14013957977295],
        [80.12819671630876, 14.14013957977295],
        [80.12819671630876, 14.139859199523983],
        [80.12847137451195, 14.139861106872559],
        [80.12847137451195, 14.139305114746094],
        [80.12819671630876, 14.139311790466365],
        [80.12819671630876, 14.139030456543082],
        [80.12847137451195, 14.139030456543082],
        [80.12847137451195, 14.137920379638729],
        [80.12874603271479, 14.137920379638729],
        [80.12874603271479, 14.137641906738338],
        [80.12847137451195, 14.137641906738338],
        [80.12847137451195, 14.137360572815055],
        [80.12875366210955, 14.137360572815055],
        [80.12875366210955, 14.135695457458553],
        [80.12902832031256, 14.135695457458553],
        [80.12902832031256, 14.134305000305233],
        [80.12874603271479, 14.134311676025447],
        [80.12874603271479, 14.133190155029354],
        [80.12902832031256, 14.133194923400879],
        [80.12902832031256, 14.13152885437023],
        [80.1293106079101, 14.131530761718807],
        [80.1293106079101, 14.129579544067326],
        [80.12958526611334, 14.129583358764705],
        [80.12958526611334, 14.129026412963924],
        [80.1293106079101, 14.129031181335392],
        [80.1293106079101, 14.12847042083746],
        [80.12958526611334, 14.128473281860352],
        [80.12958526611334, 14.128194808959961],
        [80.12930297851574, 14.128194808959961],
        [80.12930297851574, 14.12763786315918],
        [80.1296005249024, 14.127640724182072],
        [80.1296005249024, 14.127079963684139],
        [80.12930297851574, 14.127084732055778],
        [80.12930297851574, 14.125694274902457],
        [80.12958526611334, 14.125694274902457],
        [80.12958526611334, 14.123749732971248],
        [80.12874603271479, 14.123749732971248],
        [80.12874603271479, 14.124031066894531],
        [80.12847137451195, 14.124031066894531],
        [80.12847137451195, 14.123749732971248],
        [80.12819671630876, 14.123749732971248],
        [80.12819671630876, 14.123470306396598],
        [80.127914428711, 14.123472213745174],
        [80.127914428711, 14.123193740844783],
        [80.12763977050798, 14.123193740844783],
        [80.12763977050798, 14.12291622161871],
        [80.12735748291044, 14.122920989990348],
        [80.12735748291044, 14.122639656066895],
        [80.12623596191435, 14.122639656066895],
        [80.12623596191435, 14.124031066894531],
        [80.12596893310553, 14.124031066894531],
        [80.12596893310553, 14.125971794128361],
        [80.12625122070324, 14.125971794128361],
        [80.12625122070324, 14.127360343933105],
        [80.12680816650419, 14.127360343933105],
        [80.12680816650419, 14.127084732055778],
        [80.12735748291044, 14.127079963684139],
        [80.12735748291044, 14.127640724182072],
        [80.12763977050798, 14.127640724182072],
        [80.12763977050798, 14.12792110443121],
        [80.127914428711, 14.127917289733887],
        [80.127914428711, 14.129583358764705],
        [80.12763977050798, 14.129579544067326],
        [80.12763977050798, 14.12986087799078],
        [80.12735748291044, 14.12986087799078],
        [80.12735748291044, 14.130420684814453],
        [80.12708282470703, 14.130416870117244],
        [80.12708282470703, 14.132082939147892],
        [80.12735748291044, 14.132081031799316],
        [80.12735748291044, 14.13264083862316],
        [80.12708282470703, 14.13264083862316],
        [80.12708282470703, 14.133750915527344],
        [80.12680816650419, 14.133750915527344],
        [80.12680816650419, 14.134311676025447],
        [80.12596893310553, 14.134311676025447],
        [80.12596893310553, 14.13403129577648],
        [80.12568664550776, 14.13403129577648],
        [80.12568664550776, 14.132919311523551],
        [80.12541961669945, 14.132919311523551],
        [80.12541961669945, 14.131809234619197],
        [80.12568664550776, 14.131809234619197],
        [80.12568664550776, 14.130420684814453],
        [80.12541961669945, 14.130420684814453],
        [80.12541961669945, 14.130141258239746],
        [80.12568664550776, 14.130141258239746],
        [80.12568664550776, 14.12986087799078],
        [80.12541961669945, 14.12986087799078],
        [80.12541961669945, 14.12847042083746],
        [80.12513732910173, 14.128473281860352],
        [80.12513732910173, 14.124859809875602],
        [80.12541961669945, 14.124862670898494],
        [80.12541961669945, 14.120431900024357],
        [80.12513732910173, 14.120431900024357],
        [80.12513732910173, 14.120140075683707],
        [80.1248626708985, 14.120140075683707],
        [80.1248626708985, 14.119579315185547],
        [80.12458038330095, 14.119584083557243],
        [80.12458038330095, 14.119305610656852],
        [80.12431335449247, 14.11931133270258],
        [80.12431335449247, 14.11847019195568],
        [80.12374877929716, 14.118472099304256],
        [80.12374877929716, 14.117918014526367],
        [80.12346649169922, 14.117919921875],
        [80.12346649169922, 14.117639541626033],
        [80.12317657470732, 14.117639541626033],
        [80.12317657470732, 14.117081642150936],
        [80.12291717529303, 14.117083549499512],
        [80.12291717529303, 14.116806030273438],
        [80.12264251708979, 14.116809844970817],
        [80.12264251708979, 14.11653041839594],
        [80.12236022949241, 14.11653041839594],
        [80.12236022949241, 14.115971565246582],
        [80.12208557128923, 14.115971565246582],
        [80.12208557128923, 14.114583969116325],
        [80.12236022949241, 14.114583969116325],
        [80.12236022949241, 14.11430644989025],
        [80.12264251708979, 14.11430644989025],
        [80.12264251708979, 14.114583969116325],
        [80.12291717529303, 14.114579200744686],
        [80.12291717529303, 14.115420341491756],
        [80.12317657470732, 14.115420341491756],
        [80.12317657470732, 14.116251945495549],
        [80.12346649169922, 14.116251945495549],
        [80.12346649169922, 14.116809844970817],
        [80.12374877929716, 14.116809844970817],
        [80.12374877929716, 14.11737060546875],
        [80.12403106689453, 14.11737060546875],
        [80.12403106689453, 14.117639541626033],
        [80.12431335449247, 14.117639541626033],
        [80.12431335449247, 14.117919921875],
        [80.12458038330095, 14.117918014526367],
        [80.12458038330095, 14.118193626403865],
        [80.1248626708985, 14.118193626403865],
        [80.1248626708985, 14.118472099304256],
        [80.12513732910173, 14.11847019195568],
        [80.12513732910173, 14.118749618530387],
        [80.12568664550776, 14.118749618530387],
        [80.12568664550776, 14.118188858032227],
        [80.12596893310553, 14.118193626403865],
        [80.12596893310553, 14.117083549499512],
        [80.12625122070324, 14.117083549499512],
        [80.12625122070324, 14.115971565246582],
        [80.1265258789063, 14.115971565246582],
        [80.1265258789063, 14.1154146194458],
        [80.12623596191435, 14.115420341491756],
        [80.12623596191435, 14.115141868591365],
        [80.1265258789063, 14.115141868591365],
        [80.1265258789063, 14.111809730529728],
        [80.12680816650419, 14.111809730529728],
        [80.12680816650419, 14.111530303955078],
        [80.12763977050798, 14.111530303955078],
        [80.12763977050798, 14.111809730529728],
        [80.12819671630876, 14.11180496215826],
        [80.12819671630876, 14.11208343505865],
        [80.12874603271479, 14.112080574035758],
        [80.12874603271479, 14.112640380859432],
        [80.12902832031256, 14.112640380859432],
        [80.12902832031256, 14.112919807434082],
        [80.12930297851574, 14.112917900085506],
        [80.12930297851574, 14.113192558288517],
        [80.12958526611334, 14.113192558288517],
        [80.12958526611334, 14.113471031189079],
        [80.12985992431669, 14.113471031189079],
        [80.12985992431669, 14.114031791687012],
        [80.12902832031256, 14.114031791687012],
        [80.12902832031256, 14.113748550414982],
        [80.12874603271479, 14.113748550414982],
        [80.12874603271479, 14.113471031189079],
        [80.12763977050798, 14.113471031189079],
        [80.12763977050798, 14.119029998779354],
        [80.12708282470703, 14.119029998779354],
        [80.12708282470703, 14.120431900024357],
        [80.12735748291044, 14.120415687561035],
        [80.12735748291044, 14.1209716796875],
        [80.12763977050798, 14.1209716796875],
        [80.12763977050798, 14.12125015258789],
        [80.12790679931646, 14.12125015258789],
        [80.12790679931646, 14.121529579162711],
        [80.1296005249024, 14.121529579162711],
        [80.1296005249024, 14.12125015258789],
        [80.12985992431669, 14.12125015258789],
        [80.12985992431669, 14.119859695434513],
        [80.13014221191406, 14.119859695434513],
        [80.13014221191406, 14.119579315185547],
        [80.13097381591825, 14.119584083557243],
        [80.13097381591825, 14.118472099304256],
        [80.13124847412126, 14.118472099304256],
        [80.13124847412126, 14.116806030273438],
        [80.1309661865235, 14.116809844970817],
        [80.1309661865235, 14.115691184997615],
        [80.13124847412126, 14.115694046020508],
        [80.13124847412126, 14.113748550414982],
        [80.1315307617188, 14.113748550414982],
        [80.1315307617188, 14.112919807434082],
        [80.13181304931658, 14.112919807434082],
        [80.13181304931658, 14.112360954284725],
        [80.1315307617188, 14.112360954284725],
        [80.1315307617188, 14.111809730529728],
        [80.13181304931658, 14.111809730529728],
        [80.13181304931658, 14.107360839843864],
        [80.13208007812506, 14.107360839843864],
        [80.13208007812506, 14.10486125946045],
        [80.1323623657226, 14.10486125946045],
        [80.1323623657226, 14.103195190429801],
        [80.13263702392584, 14.103195190429801],
        [80.13263702392584, 14.102639198303336],
        [80.13291931152355, 14.102641105651912],
        [80.13291931152355, 14.101811408996525],
        [80.13263702392584, 14.101811408996525],
        [80.13263702392584, 14.100420951843205],
        [80.13291931152355, 14.100420951843205],
        [80.13291931152355, 14.099300384521428],
        [80.13319396972656, 14.099304199218807],
        [80.13319396972656, 14.097361564636174],
        [80.13346862792997, 14.097359657287598],
        [80.13346862792997, 14.096261978149414],
        [80.13375091552734, 14.096261978149414],
        [80.13375091552734, 14.09486007690441],
        [80.13403320312528, 14.09486007690441],
        [80.13402557373075, 14.093471527099666],
        [80.1343078613283, 14.093471527099666],
        [80.1343078613283, 14.092362403869572],
        [80.1345825195313, 14.092362403869572],
        [80.1345825195313, 14.091250419616813],
        [80.13485717773455, 14.091250419616813],
        [80.13485717773455, 14.090419769287166],
        [80.13513946533226, 14.090419769287166],
        [80.13513946533226, 14.089309692382812],
        [80.13540649414074, 14.089309692382812],
        [80.13540649414074, 14.087920188903752],
        [80.13569641113287, 14.087920188903752],
        [80.13569641113287, 14.086249351501465],
        [80.13597106933605, 14.086249351501465],
        [80.13597106933605, 14.084321022033805],
        [80.13625335693365, 14.084321022033805],
        [80.13625335693365, 14.0826416015625],
        [80.136528015137, 14.0826416015625],
        [80.136528015137, 14.080691337585506],
        [80.13680267333984, 14.080695152282715],
        [80.13680267333984, 14.079029083252067],
        [80.13707733154325, 14.079030990600643],
        [80.13707733154325, 14.077361106872615],
        [80.1373596191408, 14.077361106872615],
        [80.1373596191408, 14.075972557067871],
        [80.13764190673834, 14.075972557067871],
        [80.13764190673834, 14.075139045715332],
        [80.13791656494158, 14.075139045715332],
        [80.13791656494158, 14.074028968811149],
        [80.13817596435553, 14.074030876159725],
        [80.13817596435553, 14.073189735412654],
        [80.13847351074213, 14.07319545745861],
        [80.13847351074213, 14.072360038757438],
        [80.13817596435553, 14.072360038757438],
        [80.13817596435553, 14.072071075439453],
        [80.13847351074213, 14.072085380554256],
        [80.13847351074213, 14.071806907653865],
        [80.13874816894537, 14.071810722351017],
        [80.13874816894537, 14.071531295776367],
        [80.13903045654308, 14.071531295776367],
        [80.13903045654308, 14.071249961853084],
        [80.13931274414068, 14.071249961853084],
        [80.13931274414068, 14.070969581604118],
        [80.1395797729495, 14.07097244262701],
        [80.1395797729495, 14.07069396972662],
        [80.13986206054688, 14.07069396972662],
        [80.13986206054688, 14.070137977600154],
        [80.14013671875028, 14.070142745971793],
        [80.14013671875028, 14.06986045837408],
        [80.14097595214861, 14.069862365722656],
        [80.14097595214861, 14.0690279006958],
        [80.14125061035179, 14.06903266906744],
        [80.14125061035179, 14.068189620971737],
        [80.14152526855463, 14.068194389343205],
        [80.14152526855463, 14.067915916442871],
        [80.14125061035179, 14.06792068481451],
        [80.14125061035179, 14.06653022766119],
        [80.14154052734392, 14.06653022766119],
        [80.14154052734392, 14.06513977050787],
        [80.14125061035179, 14.06513977050787],
        [80.14125061035179, 14.063750267028809],
        [80.14097595214861, 14.063750267028809],
        [80.14097595214861, 14.062081336975098],
        [80.14125061035179, 14.06208419799816],
        [80.14125061035179, 14.061528205871696],
        [80.14097595214861, 14.061532020568848],
        [80.14097595214861, 14.060420036315918],
        [80.14125061035179, 14.060420036315918],
        [80.14125061035179, 14.060130119323844],
        [80.14152526855463, 14.060139656066951],
        [80.14152526855463, 14.059862136840877],
        [80.1418075561524, 14.059862136840877],
        [80.1418075561524, 14.059583663940487],
        [80.14208221435558, 14.059583663940487],
        [80.14208221435558, 14.059305191040096],
        [80.14235687255865, 14.059309959411564],
        [80.14235687255865, 14.05792045593273],
        [80.14263916015653, 14.05792045593273],
        [80.14263916015653, 14.056531906127987],
        [80.14235687255865, 14.056531906127987],
        [80.14235687255865, 14.055971145629883],
        [80.14291381835938, 14.055971145629883],
        [80.14291381835938, 14.055417060852164],
        [80.14263916015653, 14.055421829223633],
        [80.14263916015653, 14.054580688476506],
        [80.14291381835938, 14.054582595825309],
        [80.14291381835938, 14.054026603698844],
        [80.14319610595732, 14.054032325744572],
        [80.14319610595732, 14.0518093109132],
        [80.14347076416033, 14.0518093109132],
        [80.14347076416033, 14.050689697265682],
        [80.1437530517581, 14.050695419311637],
        [80.1437530517581, 14.050139427185172],
        [80.14402770996111, 14.050141334533748],
        [80.14402770996111, 14.049579620361328],
        [80.14430236816429, 14.049583435058707],
        [80.14430236816429, 14.049306869506893],
        [80.14457702636713, 14.049310684204102],
        [80.14457702636713, 14.049031257629395],
        [80.1448593139649, 14.049031257629395],
        [80.1448593139649, 14.048190116882324],
        [80.14458465576189, 14.048194885253963],
        [80.14458465576189, 14.047638893127498],
        [80.14430999755865, 14.047640800476074],
        [80.14430999755865, 14.047360420227108],
        [80.14458465576189, 14.047360420227108],
        [80.14458465576189, 14.046528816223145],
        [80.1448593139649, 14.04653072357172],
        [80.1448593139649, 14.04625129699707],
        [80.14514160156244, 14.04625129699707],
        [80.14514160156244, 14.045969963073787],
        [80.1448593139649, 14.04597282409668],
        [80.1448593139649, 14.045415878295898],
        [80.14514160156244, 14.045420646667537],
        [80.14514160156244, 14.044310569763184],
        [80.14541625976568, 14.044310569763184],
        [80.14541625976568, 14.043191909790039],
        [80.14569091796903, 14.043193817138786],
        [80.14569091796903, 14.040971755981502],
        [80.1459732055664, 14.040971755981502],
        [80.1459732055664, 14.040415763855037],
        [80.14624786376982, 14.040420532226676],
        [80.14624786376982, 14.039861679077148],
        [80.14653015136719, 14.039861679077148],
        [80.14653015136719, 14.039020538330021],
        [80.1468048095706, 14.039028167724553],
        [80.1468048095706, 14.038193702697697],
        [80.14708709716814, 14.038193702697697],
        [80.14708709716814, 14.037362098693904],
        [80.14736175537115, 14.037362098693904],
        [80.14736175537115, 14.03680610656744],
        [80.14763641357439, 14.036809921264648],
        [80.14763641357439, 14.035960197448674],
        [80.1479187011721, 14.035971641540584],
        [80.1479187011721, 14.035140037536621],
        [80.14819335937494, 14.035141944885197],
        [80.14819335937494, 14.032640457153434],
        [80.14848327636724, 14.032640457153434],
        [80.14848327636724, 14.030151367187443],
        [80.14819335937494, 14.030151367187443],
        [80.14819335937494, 14.028751373291016],
        [80.1479187011721, 14.028751373291016],
        [80.1479187011721, 14.027921676635799],
        [80.14819335937494, 14.027921676635799],
        [80.14819335937494, 14.027360916137695],
        [80.14848327636724, 14.027360916137695],
        [80.14847564697271, 14.026804924011174],
        [80.1487503051759, 14.02680969238287],
        [80.1487503051759, 14.026250839233512],
        [80.14901733398438, 14.026250839233512],
        [80.14901733398438, 14.025690078735352],
        [80.14930725097685, 14.025694847107047],
        [80.14930725097685, 14.025417327880916],
        [80.14958190917969, 14.025419235229549],
        [80.14958190917969, 14.024861335754451],
        [80.14986419677763, 14.024861335754451],
        [80.14986419677763, 14.024305343627987],
        [80.15013885498064, 14.024312019348258],
        [80.15013885498064, 14.023751258850098],
        [80.15040588378929, 14.023751258850098],
        [80.15040588378929, 14.02153110504156],
        [80.15013885498064, 14.02153110504156],
        [80.15013885498064, 14.020981788635368],
        [80.1498565673831, 14.020981788635368],
        [80.1498565673831, 14.020421028137207],
        [80.14958190917969, 14.020421028137207],
        [80.14958190917969, 14.018751144409237],
        [80.1498565673831, 14.018751144409237],
        [80.1498565673831, 14.019030570983887],
        [80.15013885498064, 14.019030570983887],
        [80.15013885498064, 14.019310951232853],
        [80.15041351318365, 14.019304275512809],
        [80.15041351318365, 14.019584655761776],
        [80.15069580078142, 14.019584655761776],
        [80.15069580078142, 14.019860267639274],
        [80.1509704589846, 14.019860267639274],
        [80.1509704589846, 14.02014064788824],
        [80.15125274658197, 14.02014064788824],
        [80.15125274658197, 14.020421028137207],
        [80.1518020629884, 14.020417213440055],
        [80.1518020629884, 14.02069473266613],
        [80.1520767211914, 14.02068996429449],
        [80.1520767211914, 14.020981788635368],
        [80.15235900878935, 14.020981788635368],
        [80.15235900878935, 14.021250724792594],
        [80.15264129638672, 14.021250724792594],
        [80.15264129638672, 14.02153110504156],
        [80.15291595459013, 14.02153110504156],
        [80.15291595459013, 14.021810531616268],
        [80.15319824218767, 14.021810531616268],
        [80.15319824218767, 14.022920608520621],
        [80.15291595459013, 14.022920608520621],
        [80.15291595459013, 14.023751258850098],
        [80.15264129638672, 14.023751258850098],
        [80.15264129638672, 14.024026870727596],
        [80.15235900878935, 14.024026870727596],
        [80.15235900878935, 14.024861335754451],
        [80.1520767211914, 14.024861335754451],
        [80.1520767211914, 14.025419235229549],
        [80.1518020629884, 14.025417327880916],
        [80.1518020629884, 14.025973320007381],
        [80.15152740478521, 14.025971412658805],
        [80.15152740478521, 14.02680969238287],
        [80.15125274658197, 14.02680969238287],
        [80.15125274658197, 14.027360916137695],
        [80.15069580078142, 14.027360916137695],
        [80.15069580078142, 14.027921676635799],
        [80.15040588378929, 14.027921676635799],
        [80.15040588378929, 14.029310226440543],
        [80.15069580078142, 14.029310226440543],
        [80.15069580078142, 14.030420303344727],
        [80.15152740478521, 14.030420303344727],
        [80.15152740478521, 14.030151367187443],
        [80.1518020629884, 14.030151367187443],
        [80.1518020629884, 14.029581069946403],
        [80.152084350586, 14.029582977294979],
        [80.152084350586, 14.028470993042049],
        [80.15235900878935, 14.028470993042049],
        [80.15235900878935, 14.027360916137695],
        [80.15264129638672, 14.027360916137695],
        [80.15264129638672, 14.026250839233512],
        [80.15291595459013, 14.026250839233512],
        [80.15291595459013, 14.025140762329158],
        [80.15319061279314, 14.025140762329158],
        [80.15319824218767, 14.024580955505485],
        [80.15347290039068, 14.02458286285406],
        [80.15347290039068, 14.024026870727596],
        [80.15374755859392, 14.024026870727596],
        [80.15374755859392, 14.023472785949707],
        [80.15402984619163, 14.023472785949707],
        [80.15402984619163, 14.022916793823242],
        [80.15429687499994, 14.022920608520621],
        [80.15429687499994, 14.022360801696777],
        [80.15458679199224, 14.022360801696777],
        [80.15458679199224, 14.021806716918888],
        [80.15486145019543, 14.021810531616268],
        [80.15486145019543, 14.021250724792594],
        [80.1551361083985, 14.021250724792594],
        [80.1551361083985, 14.02068996429449],
        [80.15541839599638, 14.02069473266613],
        [80.15541839599638, 14.019584655761776],
        [80.1551361083985, 14.019584655761776],
        [80.1551361083985, 14.019304275512809],
        [80.15458679199224, 14.019310951232853],
        [80.15458679199224, 14.018190383911076],
        [80.15486145019543, 14.018194198608455],
        [80.15486145019543, 14.016249656677246],
        [80.15458679199224, 14.016249656677246],
        [80.15458679199224, 14.015139579773063],
        [80.15429687499994, 14.015139579773063],
        [80.15429687499994, 14.012920379638672],
        [80.15458679199224, 14.012920379638672],
        [80.15458679199224, 14.011529922485352],
        [80.15486145019543, 14.011529922485352],
        [80.15486145019543, 14.010972023010254],
        [80.1551361083985, 14.010972023010254],
        [80.1551361083985, 14.010417938232365],
        [80.15541839599638, 14.010419845581168],
        [80.15541839599638, 14.010140419006461],
        [80.15569305419922, 14.010140419006461],
        [80.15569305419922, 14.0098619461059],
        [80.15598297119169, 14.0098619461059],
        [80.15597534179716, 14.009305953979606],
        [80.15625000000017, 14.009309768676815],
        [80.15625000000017, 14.009040832519531],
        [80.15651702880865, 14.009040832519531],
        [80.15651702880865, 14.008751869201717],
        [80.15680694580095, 14.008751869201717],
        [80.15680694580095, 14.008191108703613],
        [80.15708160400413, 14.008193969726676],
        [80.15708160400413, 14.007914543151799],
        [80.15735626220697, 14.007920265197754],
        [80.15735626220697, 14.007358551025334],
        [80.15763854980474, 14.007362365722713],
        [80.15763854980474, 14.006527900695858],
        [80.15791320800793, 14.006529808044434],
        [80.15791320800793, 14.004860877990723],
        [80.15763854980474, 14.004860877990723],
        [80.15763854980474, 14.000139236450195],
        [80.15791320800793, 14.000139236450195],
        [80.15791320800793, 13.999850273132438],
        [80.15819549560553, 13.999860763549862],
        [80.15819549560553, 13.99958324432373],
        [80.15847015380888, 13.99958324432373],
        [80.15847015380888, 13.999029159545842],
        [80.15866088867216, 13.999029159545842],
        [80.15985870361345, 13.999031066894645],
        [80.15985870361345, 13.9984712600708],
        [80.160140991211, 13.998473167419377],
        [80.160140991211, 13.997638702392521],
        [80.16042327880876, 13.997640609741325],
        [80.16042327880876, 13.996811866760197],
        [80.16069793701195, 13.996811866760197],
        [80.16069793701195, 13.995970726013297],
        [80.16097259521479, 13.995972633361873],
        [80.16097259521479, 13.995139122009334],
        [80.16124725341803, 13.99514102935791],
        [80.16124725341803, 13.994311332702694],
        [80.16152954101574, 13.994311332702694],
        [80.16152954101574, 13.99096965789795],
        [80.16069793701195, 13.99096965789795],
        [80.16069793701195, 13.991250038147086],
        [80.15985870361345, 13.991250038147086],
        [80.15985870361345, 13.990690231323242],
        [80.160140991211, 13.990694046020621],
        [80.160140991211, 13.990138053894157],
        [80.16042327880876, 13.990140914917049],
        [80.16042327880876, 13.989580154418888],
        [80.16069793701195, 13.989582061767692],
        [80.16069793701195, 13.989027976989803],
        [80.16097259521479, 13.989032745361442],
        [80.16097259521479, 13.988750457763729],
        [80.16124725341803, 13.988750457763729],
        [80.16124725341803, 13.988189697265568],
        [80.16152954101574, 13.988194465637207],
        [80.16152954101574, 13.987915992736873],
        [80.16236114501953, 13.987915992736873],
        [80.16236114501953, 13.988194465637207],
        [80.16291809082048, 13.988194465637207],
        [80.16291809082048, 13.987915992736873],
        [80.16374969482445, 13.987915992736873],
        [80.16374969482445, 13.987360000610352],
        [80.16401672363293, 13.987360000610352],
        [80.16401672363293, 13.985692024230957],
        [80.16374969482445, 13.985693931579533],
        [80.16374969482445, 13.985137939453068],
        [80.1631927490235, 13.985139846801871],
        [80.1631927490235, 13.985420227050838],
        [80.16291809082048, 13.98541641235363],
        [80.16291809082048, 13.985693931579533],
        [80.16236114501953, 13.985693931579533],
        [80.16236114501953, 13.984027862548885],
        [80.16263580322294, 13.984029769897518],
        [80.16263580322294, 13.98347187042242],
        [80.16291809082048, 13.98347187042242],
        [80.16291809082048, 13.982640266418457],
        [80.1631927490235, 13.982640266418457],
        [80.1631927490235, 13.9820814132691],
        [80.16347503662126, 13.982084274291992],
        [80.16347503662126, 13.980972290039062],
        [80.16374969482445, 13.980972290039062],
        [80.16374969482445, 13.979306221008414],
        [80.16401672363293, 13.979310035705566],
        [80.16401672363293, 13.978468894958496],
        [80.16430664062506, 13.978470802307243],
        [80.16430664062506, 13.978193283081168],
        [80.16486358642584, 13.978193283081168],
        [80.16486358642584, 13.97791767120367],
        [80.16513824462919, 13.977919578552246],
        [80.16513824462919, 13.97763919830328],
        [80.16542053222656, 13.97763919830328],
        [80.16542053222656, 13.977360725402889],
        [80.16596984863298, 13.977360725402889],
        [80.16596984863298, 13.977081298828239],
        [80.16625213623053, 13.977083206176815],
        [80.16625213623053, 13.97652721405035],
        [80.16652679443376, 13.976531982421989],
        [80.16652679443376, 13.976250648498535],
        [80.16680908203148, 13.976250648498535],
        [80.16680908203148, 13.975679397583008],
        [80.16708374023432, 13.97569465637207],
        [80.16708374023432, 13.975138664245605],
        [80.16735839843756, 13.975141525268668],
        [80.16735839843756, 13.974580764770508],
        [80.16764068603527, 13.97458267211914],
        [80.16764068603527, 13.974026679992676],
        [80.16792297363287, 13.974032402038574],
        [80.16792297363287, 13.973470687866154],
        [80.16819763183622, 13.973472595214957],
        [80.16819763183622, 13.973195075988883],
        [80.16847229003906, 13.973195075988883],
        [80.16847229003906, 13.972916603088493],
        [80.16902923584001, 13.972930908203068],
        [80.16902923584001, 13.97208118438732],
        [80.16930389404303, 13.972083091735897],
        [80.16930389404303, 13.970417022705078],
        [80.1695861816408, 13.970420837402457],
        [80.1695861816408, 13.967080116271973],
        [80.16986083984398, 13.967082977295036],
        [80.16986083984398, 13.964306831359863],
        [80.1695861816408, 13.964310646057186],
        [80.1695861816408, 13.963741302490234],
        [80.17041778564459, 13.963741302490234],
        [80.17041778564459, 13.964031219482536],
        [80.17069244384777, 13.964031219482536],
        [80.17069244384777, 13.964310646057186],
        [80.17097473144537, 13.964306831359863],
        [80.17097473144537, 13.964582443237362],
        [80.17124938964872, 13.964582443237362],
        [80.17124938964872, 13.964306831359863],
        [80.17151641845703, 13.964310646057186],
        [80.17151641845703, 13.963191986084041],
        [80.1718063354495, 13.963193893432617],
        [80.1718063354495, 13.96152782440197],
        [80.17208099365251, 13.961530685424862],
        [80.17208099365251, 13.960140228271541],
        [80.17292022705084, 13.960140228271541],
        [80.17292022705084, 13.960971832275504],
        [80.17318725585932, 13.960969924926701],
        [80.17318725585932, 13.961530685424862],
        [80.17375183105463, 13.96152782440197],
        [80.17375183105463, 13.963472366333008],
        [80.17346954345709, 13.963470458984375],
        [80.17346954345709, 13.964310646057186],
        [80.17318725585932, 13.964310646057186],
        [80.17318725585932, 13.965141296386719],
        [80.17292022705084, 13.965138435363826],
        [80.17292022705084, 13.965972900390682],
        [80.1726379394533, 13.96597003936779],
        [80.1726379394533, 13.96681118011469],
        [80.17236328125028, 13.96681118011469],
        [80.17236328125028, 13.967640876770076],
        [80.17208099365251, 13.9676389694215],
        [80.17208099365251, 13.968473434448356],
        [80.1718063354495, 13.968469619750977],
        [80.1718063354495, 13.969310760498104],
        [80.17151641845703, 13.969310760498104],
        [80.17151641845703, 13.97014141082758],
        [80.17124938964872, 13.970138549804688],
        [80.17124938964872, 13.970973014831543],
        [80.17096710205084, 13.970971107482967],
        [80.17096710205084, 13.97153091430664],
        [80.17069244384777, 13.97153091430664],
        [80.17069244384777, 13.971809387207031],
        [80.17041778564459, 13.971804618835506],
        [80.17041778564459, 13.972083091735897],
        [80.17014312744158, 13.97208118438732],
        [80.17014312744158, 13.972640991210994],
        [80.16986083984398, 13.972640991210994],
        [80.16986083984398, 13.972930908203068],
        [80.1695861816408, 13.972916603088493],
        [80.1695861816408, 13.973195075988883],
        [80.1692962646485, 13.973191261291504],
        [80.1692962646485, 13.973751068115348],
        [80.1695861816408, 13.973751068115348],
        [80.1695861816408, 13.974311828613281],
        [80.16986083984398, 13.97430515289301],
        [80.16986083984398, 13.974861145019531],
        [80.17014312744158, 13.974861145019531],
        [80.17014312744158, 13.975421905517635],
        [80.16902923584001, 13.975417137145996],
        [80.16902923584001, 13.975971221923885],
        [80.16874694824247, 13.975971221923885],
        [80.16874694824247, 13.976531982421989],
        [80.1684799194336, 13.976531982421989],
        [80.1684799194336, 13.97681045532238],
        [80.16819763183622, 13.97681045532238],
        [80.16819763183622, 13.977360725402889],
        [80.16792297363287, 13.977360725402889],
        [80.16792297363287, 13.977919578552246],
        [80.16764068603527, 13.97791767120367],
        [80.16764068603527, 13.978193283081168],
        [80.16791534423834, 13.978193283081168],
        [80.16791534423834, 13.978470802307243],
        [80.16819763183622, 13.978468894958496],
        [80.16819763183622, 13.978749275207633],
        [80.16874694824247, 13.978749275207633],
        [80.16874694824247, 13.979310035705566],
        [80.1684799194336, 13.979310035705566],
        [80.1684799194336, 13.980139732360954],
        [80.16819763183622, 13.980139732360954],
        [80.16819763183622, 13.980695724487418],
        [80.16792297363287, 13.98069095611578],
        [80.16792297363287, 13.981251716613713],
        [80.16764068603527, 13.981251716613713],
        [80.16764068603527, 13.982361793518066],
        [80.16735839843756, 13.982361793518066],
        [80.16735839843756, 13.982640266418457],
        [80.16707611083979, 13.982640266418457],
        [80.16707611083979, 13.982919692993164],
        [80.16652679443376, 13.982919692993164],
        [80.16652679443376, 13.982361793518066],
        [80.16625213623053, 13.982361793518066],
        [80.16625213623053, 13.981251716613713],
        [80.16596984863298, 13.981251716613713],
        [80.16596984863298, 13.98069095611578],
        [80.16569519042997, 13.980695724487418],
        [80.16569519042997, 13.980415344238281],
        [80.16513824462919, 13.980415344238281],
        [80.16513824462919, 13.980695724487418],
        [80.16485595703125, 13.98069095611578],
        [80.16485595703125, 13.981530189514274],
        [80.16458129882824, 13.981530189514274],
        [80.16458129882824, 13.982361793518066],
        [80.16486358642584, 13.982361793518066],
        [80.16486358642584, 13.98347187042242],
        [80.16513824462919, 13.98347187042242],
        [80.16513824462919, 13.984310150146484],
        [80.16542053222656, 13.984310150146484],
        [80.16542053222656, 13.985420227050838],
        [80.16569519042997, 13.98541641235363],
        [80.16569519042997, 13.985972404479924],
        [80.16596984863298, 13.985969543457031],
        [80.16596984863298, 13.986249923705998],
        [80.16625213623053, 13.986249923705998],
        [80.16625213623053, 13.985969543457031],
        [80.16680908203148, 13.985969543457031],
        [80.16680908203148, 13.986530303955192],
        [80.16652679443376, 13.986530303955192],
        [80.16652679443376, 13.987640380859375],
        [80.16625213623053, 13.987638473510742],
        [80.16625213623053, 13.988194465637207],
        [80.16596984863298, 13.988189697265568],
        [80.16596984863298, 13.988750457763729],
        [80.1657028198245, 13.988750457763729],
        [80.1657028198245, 13.989311218261832],
        [80.16542053222656, 13.989311218261832],
        [80.16542053222656, 13.990140914917049],
        [80.16513824462919, 13.990138053894157],
        [80.16513824462919, 13.990971565246696],
        [80.16485595703125, 13.99096965789795],
        [80.16485595703125, 13.99236011505127],
        [80.16458129882824, 13.99236011505127],
        [80.16458129882824, 13.993473052978516],
        [80.16430664062506, 13.993470191955623],
        [80.16430664062506, 13.994039535522575],
        [80.16402435302729, 13.99402904510498],
        [80.16402435302729, 13.99458312988287],
        [80.16374969482445, 13.994580268859977],
        [80.16374969482445, 13.99514102935791],
        [80.16347503662126, 13.995139122009334],
        [80.16347503662126, 13.995695114135799],
        [80.1631927490235, 13.995695114135799],
        [80.1631927490235, 13.995972633361873],
        [80.16291809082048, 13.995970726013297],
        [80.16291809082048, 13.996530532836971],
        [80.16263580322294, 13.996526718139762],
        [80.16263580322294, 13.997082710266056],
        [80.16234588623064, 13.997079849243164],
        [80.16234588623064, 13.997640609741325],
        [80.16263580322294, 13.997638702392521],
        [80.16263580322294, 13.998194694518986],
        [80.16234588623064, 13.998189926147518],
        [80.16234588623064, 13.998750686645508],
        [80.16208648681669, 13.998750686645508],
        [80.16208648681669, 14.000139236450195],
        [80.16180419921875, 14.000139236450195],
        [80.16180419921875, 14.000695228576717],
        [80.16152954101574, 14.000691413879508],
        [80.16152954101574, 14.000970840454215],
        [80.16124725341803, 14.000970840454215],
        [80.16124725341803, 14.001251220703182],
        [80.16097259521479, 14.001251220703182],
        [80.16097259521479, 14.002641677856502],
        [80.16069793701195, 14.002641677856502],
        [80.16069793701195, 14.003748893737793],
        [80.16042327880876, 14.003748893737793],
        [80.16042327880876, 14.00541973114025],
        [80.160140991211, 14.00541973114025],
        [80.160140991211, 14.0059814453125],
        [80.15985870361345, 14.0059814453125],
        [80.15985870361345, 14.006529808044434],
        [80.15958404541044, 14.006527900695858],
        [80.15958404541044, 14.007362365722713],
        [80.15930938720703, 14.007358551025334],
        [80.15928649902361, 14.007641792297363],
        [80.15902709960966, 14.007639884948787],
        [80.15902709960966, 14.008191108703613],
        [80.15875244140625, 14.008199691772461],
        [80.15875244140625, 14.008751869201717],
        [80.15847015380888, 14.008751869201717],
        [80.15847015380888, 14.009040832519531],
        [80.15820312500006, 14.009040832519531],
        [80.15820312500006, 14.009309768676815],
        [80.15791320800793, 14.009305953979606],
        [80.15791320800793, 14.009584426879996],
        [80.15763854980474, 14.009581565856934],
        [80.15763854980474, 14.0098619461059],
        [80.15736389160173, 14.0098619461059],
        [80.15736389160173, 14.010693550109863],
        [80.15708160400413, 14.010693550109863],
        [80.15708160400413, 14.012361526489315],
        [80.15680694580095, 14.012359619140682],
        [80.15680694580095, 14.013750076294059],
        [80.15652465820318, 14.013750076294059],
        [80.15652465820318, 14.014860153198185],
        [80.15625000000017, 14.014860153198185],
        [80.15625000000017, 14.015972137451172],
        [80.15597534179716, 14.015972137451172],
        [80.15597534179716, 14.017082214355526],
        [80.15569305419922, 14.017080307006893],
        [80.15569305419922, 14.017641067504883],
        [80.15625000000017, 14.017641067504883],
        [80.15625000000017, 14.0173597335816],
        [80.15651702880865, 14.0173597335816],
        [80.15651702880865, 14.0165309906007],
        [80.15680694580095, 14.0165309906007],
        [80.15680694580095, 14.016249656677246],
        [80.15735626220697, 14.016249656677246],
        [80.15735626220697, 14.015420913696346],
        [80.15763854980474, 14.015420913696346],
        [80.15763854980474, 14.014581680297852],
        [80.15791320800793, 14.014583587646598],
        [80.15791320800793, 14.014027595520133],
        [80.15820312500006, 14.014030456543026],
        [80.15820312500006, 14.013469696044865],
        [80.15847015380888, 14.013471603393668],
        [80.15847015380888, 14.012639999389705],
        [80.15875244140625, 14.012639999389705],
        [80.15875244140625, 14.011811256408748],
        [80.15902709960966, 14.011811256408748],
        [80.15902709960966, 14.011249542236328],
        [80.15928649902361, 14.011249542236328],
        [80.15928649902361, 14.009309768676815],
        [80.15957641601568, 14.009309768676815],
        [80.15957641601568, 14.009040832519531],
        [80.15985870361345, 14.009040832519531],
        [80.15985870361345, 14.008751869201717],
        [80.160140991211, 14.008751869201717],
        [80.160140991211, 14.008472442627067],
        [80.16041564941423, 14.008472442627067],
        [80.16041564941423, 14.007639884948787],
        [80.16069793701195, 14.007641792297363],
        [80.16069793701195, 14.006529808044434],
        [80.16097259521479, 14.006529808044434],
        [80.16097259521479, 14.0059814453125],
        [80.16124725341803, 14.0059814453125],
        [80.16124725341803, 14.00569057464611],
        [80.16152954101574, 14.005693435669002],
        [80.16152954101574, 14.004583358764648],
        [80.16180419921875, 14.004583358764648],
        [80.16180419921875, 14.004027366638184],
        [80.16208648681669, 14.004030227661246],
        [80.16208648681669, 14.003471374511719],
        [80.16236114501953, 14.003473281860465],
        [80.16236114501953, 14.002917289734],
        [80.16263580322294, 14.002917289734],
        [80.16263580322294, 14.002082824707145],
        [80.16291809082048, 14.002082824707145],
        [80.16291809082048, 14.00180530548107],
        [80.1631927490235, 14.001811981201286],
        [80.1631927490235, 14.001531600952148],
        [80.1634826660158, 14.001531600952148],
        [80.1634826660158, 14.000970840454215],
        [80.16374969482445, 14.000970840454215],
        [80.16374969482445, 13.999850273132438],
        [80.16374969482445, 13.99958324432373],
        [80.16402435302729, 13.99958324432373],
        [80.16402435302729, 13.99930477142334],
        [80.16430664062506, 13.99930858612072],
        [80.16430664062506, 13.998750686645508],
        [80.16458129882824, 13.998750686645508],
        [80.16458129882824, 13.998189926147518],
        [80.16486358642584, 13.998194694518986],
        [80.16486358642584, 13.997917175292912],
        [80.16513824462919, 13.997920989990291],
        [80.16513824462919, 13.997361183166618],
        [80.16541290283203, 13.997361183166618],
        [80.16541290283203, 13.996805191040153],
        [80.1657028198245, 13.996811866760197],
        [80.1657028198245, 13.996251106262264],
        [80.16596984863298, 13.996251106262264],
        [80.16596984863298, 13.994860649108944],
        [80.16625213623053, 13.994860649108944],
        [80.16625213623053, 13.994039535522575],
        [80.16652679443376, 13.994039535522575],
        [80.16652679443376, 13.993470191955623],
        [80.16680908203148, 13.993473052978516],
        [80.16680908203148, 13.993194580078125],
        [80.16708374023432, 13.993194580078125],
        [80.16708374023432, 13.99291706085205],
        [80.16735839843756, 13.992920875549373],
        [80.16735839843756, 13.992641448974723],
        [80.16764068603527, 13.992641448974723],
        [80.16764068603527, 13.991250038147086],
        [80.16792297363287, 13.991250038147086],
        [80.16792297363287, 13.990690231323242],
        [80.16819763183622, 13.990694046020621],
        [80.16819763183622, 13.99041557312023],
        [80.1684799194336, 13.990421295166016],
        [80.1684799194336, 13.990140914917049],
        [80.16874694824247, 13.990140914917049],
        [80.16874694824247, 13.989859580993766],
        [80.16902923584001, 13.989862442016658],
        [80.16902923584001, 13.989306449890194],
        [80.1692962646485, 13.989311218261832],
        [80.1692962646485, 13.988750457763729],
        [80.1695861816408, 13.988750457763729],
        [80.1695861816408, 13.987640380859375],
        [80.1692962646485, 13.987640380859375],
        [80.1692962646485, 13.987079620361385],
        [80.1695861816408, 13.987084388732853],
        [80.1695861816408, 13.98680591583252],
        [80.16986083984398, 13.986810684204158],
        [80.16986083984398, 13.986530303955192],
        [80.17014312744158, 13.986530303955192],
        [80.17014312744158, 13.985969543457031],
        [80.17041778564459, 13.985972404479924],
        [80.17041778564459, 13.985693931579533],
        [80.17069244384777, 13.985693931579533],
        [80.17069244384777, 13.985137939453068],
        [80.17041778564459, 13.985139846801871],
        [80.17041778564459, 13.984870910644588],
        [80.17096710205084, 13.984870910644588],
        [80.17096710205084, 13.984581947326774],
        [80.17124938964872, 13.98458385467535],
        [80.17124938964872, 13.984306335449276],
        [80.17151641845703, 13.984310150146484],
        [80.17151641845703, 13.984029769897518],
        [80.1718063354495, 13.984029769897518],
        [80.1718063354495, 13.982640266418457],
        [80.17208099365251, 13.982640266418457],
        [80.17208099365251, 13.98180961608898],
        [80.17236328125028, 13.98180961608898],
        [80.17236328125028, 13.98069095611578],
        [80.1726379394533, 13.980695724487418],
        [80.1726379394533, 13.980139732360954],
        [80.17292022705084, 13.980139732360954],
        [80.17292022705084, 13.979580879211426],
        [80.17319488525408, 13.979583740234489],
        [80.17319488525408, 13.979306221008414],
        [80.17346954345709, 13.979310035705566],
        [80.17346954345709, 13.9790296554566],
        [80.17375183105463, 13.9790296554566],
        [80.17375183105463, 13.978749275207633],
        [80.17402648925787, 13.978749275207633],
        [80.17402648925787, 13.977919578552246],
        [80.17430877685558, 13.977919578552246],
        [80.17430877685558, 13.97681045532238],
        [80.17457580566406, 13.97681045532238],
        [80.17457580566406, 13.976250648498535],
        [80.174858093262, 13.976250648498535],
        [80.174858093262, 13.975679397583008],
        [80.17514038085938, 13.97569465637207],
        [80.17514038085938, 13.975138664245605],
        [80.17542266845732, 13.975141525268668],
        [80.17542266845732, 13.974861145019531],
        [80.17597198486334, 13.974861145019531],
        [80.17597198486334, 13.97430515289301],
        [80.17624664306658, 13.974311828613281],
        [80.17624664306658, 13.973191261291504],
        [80.17652893066429, 13.973195075988883],
        [80.17652893066429, 13.972639083862362],
        [80.17679595947277, 13.972640991210994],
        [80.17679595947277, 13.97208118438732],
        [80.1770858764649, 13.972083091735897],
        [80.1770858764649, 13.970973014831543],
        [80.17736053466808, 13.970973014831543],
        [80.17736053466808, 13.970694541931152],
        [80.1776351928711, 13.970694541931152],
        [80.1776351928711, 13.970417022705078],
        [80.17791748046903, 13.970420837402457],
        [80.17791748046903, 13.969869613647461],
        [80.17819213867188, 13.969869613647461],
        [80.17819213867188, 13.969031333923397],
        [80.17846679687528, 13.969031333923397],
        [80.17846679687528, 13.96875095367443],
        [80.17874908447283, 13.96875095367443],
        [80.17874908447283, 13.967921257019043],
        [80.17903137207037, 13.967921257019043],
        [80.17903137207037, 13.967361450195426],
        [80.17930603027361, 13.967361450195426],
        [80.17930603027361, 13.967082977295036],
        [80.17958068847679, 13.967082977295036],
        [80.17958068847679, 13.966528892517147],
        [80.17986297607416, 13.966530799865723],
        [80.17986297607416, 13.965420722961369],
        [80.1801376342774, 13.965420722961369],
        [80.1801376342774, 13.964859962463436],
        [80.18041992187511, 13.964859962463436],
        [80.18041992187511, 13.964031219482536],
        [80.1806869506836, 13.964031219482536],
        [80.1806869506836, 13.963470458984375],
        [80.18096923828153, 13.963472366333008],
        [80.18096923828153, 13.962916374206543],
        [80.1812515258789, 13.962921142578182],
        [80.1812515258789, 13.961811065673828],
        [80.18096923828153, 13.961811065673828],
        [80.18096923828153, 13.960969924926701],
        [80.1812515258789, 13.960971832275504],
        [80.1812515258789, 13.960694313049373],
        [80.18152618408232, 13.960694313049373],
        [80.18152618408232, 13.96041584014904],
        [80.18180847167986, 13.960420608520508],
        [80.18180847167986, 13.959859848022518],
        [80.18208312988287, 13.959859848022518],
        [80.18208312988287, 13.95958423614502],
        [80.18235778808611, 13.95958423614502],
        [80.18235778808611, 13.959305763244686],
        [80.18264007568382, 13.959310531616325],
        [80.18264007568382, 13.959030151367188],
        [80.18290710449213, 13.959030151367188],
        [80.18290710449213, 13.958751678466797],
        [80.18319702148443, 13.958751678466797],
        [80.18319702148443, 13.958470344543514],
        [80.18347167968761, 13.95847225189209],
        [80.18347167968761, 13.957362174987736],
        [80.18374633789068, 13.957362174987736],
        [80.18374633789068, 13.956806182861442],
        [80.18402862548857, 13.95680999755865],
        [80.18402862548857, 13.955968856811523],
        [80.1843032836914, 13.955971717834586],
        [80.1843032836914, 13.955415725708121],
        [80.18459320068388, 13.95541954040533],
        [80.18459320068388, 13.954581260681266],
        [80.18486022949236, 13.954584121704158],
        [80.18486022949236, 13.954028129577694],
        [80.18514251709013, 13.95403003692627],
        [80.18514251709013, 13.953469276428336],
        [80.18541717529314, 13.953472137451229],
        [80.18541717529314, 13.95263957977295],
        [80.18569183349632, 13.95263957977295],
        [80.18569183349632, 13.952080726623592],
        [80.18597412109392, 13.952083587646484],
        [80.18597412109392, 13.951249122619629],
        [80.18624877929693, 13.951251983642692],
        [80.18624877929693, 13.950691223144531],
        [80.18653106689447, 13.950693130493164],
        [80.18653106689447, 13.950139045715446],
        [80.18681335449224, 13.950141906738338],
        [80.18681335449224, 13.947641372680664],
        [80.18708038330107, 13.947641372680664],
        [80.18708038330107, 13.947080612182674],
        [80.18736267089844, 13.947083473205566],
        [80.18736267089844, 13.946526527404842],
        [80.18763732910185, 13.946529388427678],
        [80.18763732910185, 13.946250915527344],
        [80.18791961669922, 13.946250915527344],
        [80.18791961669922, 13.945699691772518],
        [80.18818664550787, 13.945699691772518],
        [80.18818664550787, 13.944309234619197],
        [80.18846893310564, 13.944309234619197],
        [80.18846893310564, 13.9437513351441],
        [80.18875122070318, 13.9437513351441],
        [80.18875122070318, 13.943470954895133],
        [80.18902587890642, 13.94347286224371],
        [80.18902587890642, 13.943195343017635],
        [80.18930816650413, 13.943195343017635],
        [80.18930816650413, 13.94263935089117],
        [80.18958282470697, 13.942641258239746],
        [80.18958282470697, 13.94181060791027],
        [80.18985748291021, 13.94181060791027],
        [80.18985748291021, 13.94097042083746],
        [80.19013977050793, 13.940973281860352],
        [80.19013977050793, 13.939584732055778],
        [80.19041442871094, 13.939584732055778],
        [80.19041442871094, 13.939028739929313],
        [80.19069671630888, 13.939030647277889],
        [80.19069671630888, 13.938751220703239],
        [80.19097137451172, 13.938751220703239],
        [80.19097137451172, 13.938190460205078],
        [80.19125366210966, 13.938194274902457],
        [80.19125366210966, 13.937638282775993],
        [80.19152832031267, 13.937641143798885],
        [80.19152832031267, 13.937080383300724],
        [80.19180297851568, 13.937082290649528],
        [80.19180297851568, 13.935693740844783],
        [80.19208526611345, 13.935693740844783],
        [80.19208526611345, 13.935137748718319],
        [80.19235992431663, 13.935139656066895],
        [80.19235992431663, 13.934310913085994],
        [80.19264984130876, 13.934310913085994],
        [80.19264984130876, 13.932921409606934],
        [80.19291687011724, 13.932921409606934],
        [80.19291687011724, 13.932080268859863],
        [80.19319152832043, 13.932084083557186],
        [80.19319152832043, 13.931528091430721],
        [80.19346618652344, 13.931529998779354],
        [80.19346618652344, 13.930419921875],
        [80.19374847412138, 13.930419921875],
        [80.19374847412138, 13.929581642150936],
        [80.19403076171875, 13.929583549499512],
        [80.19403076171875, 13.928750038146973],
        [80.19431304931669, 13.928751945495549],
        [80.19431304931669, 13.928191184997615],
        [80.19458007812517, 13.928194046020508],
        [80.19458007812517, 13.92763996124279],
        [80.19486236572271, 13.92763996124279],
        [80.19486236572271, 13.927362442016715],
        [80.19513702392595, 13.927362442016715],
        [80.19513702392595, 13.92680644989025],
        [80.19541931152366, 13.926810264587402],
        [80.19541931152366, 13.92457199096691],
        [80.1956939697265, 13.92458343505865],
        [80.1956939697265, 13.923748970031795],
        [80.19596862792974, 13.923751831054688],
        [80.19596862792974, 13.923471450805721],
        [80.19625091552746, 13.923474311828556],
        [80.19625091552746, 13.922638893127441],
        [80.19653320312506, 13.922641754150504],
        [80.19653320312506, 13.921820640563965],
        [80.19680786132841, 13.921820640563965],
        [80.19680786132841, 13.921251296997184],
        [80.19708251953125, 13.921251296997184],
        [80.19708251953125, 13.920690536499023],
        [80.19736480712919, 13.92069530487072],
        [80.19736480712919, 13.920139312744197],
        [80.1976394653322, 13.920139312744197],
        [80.1976394653322, 13.919581413269157],
        [80.19791412353521, 13.919583320617733],
        [80.19791412353521, 13.918748855590877],
        [80.19819641113298, 13.918760299682617],
        [80.19819641113298, 13.918471336364803],
        [80.19791412353521, 13.918473243713379],
        [80.19791412353521, 13.918194770812988],
        [80.19819641113298, 13.918194770812988],
        [80.19819641113298, 13.917917251586914],
        [80.19845581054693, 13.917922019958553],
        [80.19845581054693, 13.91736125946045],
        [80.19875335693376, 13.91736125946045],
        [80.19875335693376, 13.917082786560059],
        [80.19902801513678, 13.917082786560059],
        [80.19902801513678, 13.916526794433594],
        [80.19930267333996, 13.916530609130973],
        [80.19930267333996, 13.915970802307129],
        [80.19958496093756, 13.915972709655705],
        [80.19958496093756, 13.915695190429801],
        [80.19985961914091, 13.915695190429801],
        [80.19985961914091, 13.915139198303336],
        [80.20014190673828, 13.915141105651912],
        [80.20014190673828, 13.914580345153809],
        [80.20041656494169, 13.914583206176871],
        [80.20041656494169, 13.914029121398983],
        [80.2006912231447, 13.914031028747559],
        [80.2006912231447, 13.912920951843205],
        [80.20096588134771, 13.912920951843205],
        [80.20096588134771, 13.91263198852539],
        [80.20124816894548, 13.912638664245662],
        [80.20124816894548, 13.912084579467773],
        [80.20153045654303, 13.912084579467773],
        [80.20153045654303, 13.910971641540527],
        [80.20207977294928, 13.910971641540527],
        [80.20207977294928, 13.910694122314453],
        [80.20236206054682, 13.910694122314453],
        [80.20236206054682, 13.910415649414062],
        [80.20263671875006, 13.910421371460018],
        [80.20263671875006, 13.909590721130314],
        [80.20291900634777, 13.909590721130314],
        [80.20291900634777, 13.909030914306697],
        [80.20318603515625, 13.909030914306697],
        [80.20318603515625, 13.908749580383414],
        [80.20375061035156, 13.908749580383414],
        [80.20375061035156, 13.908191680908146],
        [80.20402526855497, 13.90819358825695],
        [80.20402526855497, 13.907637596130485],
        [80.20430755615251, 13.907640457153377],
        [80.20430755615251, 13.907079696655217],
        [80.20458221435553, 13.907084465026855],
        [80.20458221435553, 13.90652847290039],
        [80.20485687255876, 13.906530380249023],
        [80.20485687255876, 13.905689239502067],
        [80.20513916015648, 13.905694007873535],
        [80.20513916015648, 13.905415534973145],
        [80.20540618896496, 13.90542030334484],
        [80.20540618896496, 13.905139923095646],
        [80.20569610595709, 13.905139923095646],
        [80.20569610595709, 13.904582023620605],
        [80.20597076416027, 13.904583930969181],
        [80.20597076416027, 13.904027938842717],
        [80.20625305175787, 13.90402984619152],
        [80.20625305175787, 13.903459548950252],
        [80.20652770996122, 13.903471946716422],
        [80.20652770996122, 13.902917861938533],
        [80.20680236816406, 13.902919769287166],
        [80.20680236816406, 13.90264034271246],
        [80.20707702636747, 13.90264034271246],
        [80.20707702636747, 13.902361869812069],
        [80.20735931396501, 13.902361869812069],
        [80.20735931396501, 13.901809692382812],
        [80.20764923095732, 13.901809692382812],
        [80.20764923095732, 13.901251792907715],
        [80.2079162597658, 13.901251792907715],
        [80.2079162597658, 13.900968551635856],
        [80.20819091796898, 13.900972366333065],
        [80.20819091796898, 13.90069580078125],
        [80.20847320556635, 13.90069580078125],
        [80.20847320556635, 13.899862289428711],
        [80.20874786376959, 13.899862289428711],
        [80.20874786376959, 13.899306297302246],
        [80.2090301513673, 13.899310111999569],
        [80.2090301513673, 13.898191452026367],
        [80.20874786376959, 13.898193359375],
        [80.20874786376959, 13.897360801696891],
        [80.2090301513673, 13.897360801696891],
        [80.2090301513673, 13.897083282470817],
        [80.2101364135745, 13.897083282470817],
        [80.2101364135745, 13.896804809570426],
        [80.21041870117205, 13.896809577941895],
        [80.21041870117205, 13.896530151367244],
        [80.21069335937506, 13.896530151367244],
        [80.21069335937506, 13.895690917968693],
        [80.21097564697283, 13.895694732666072],
        [80.21097564697283, 13.895138740539608],
        [80.21125030517601, 13.8951416015625],
        [80.21125030517601, 13.89375114440918],
        [80.21151733398432, 13.89375114440918],
        [80.21151733398432, 13.893191337585506],
        [80.2120819091798, 13.893195152282658],
        [80.2120819091798, 13.892916679382324],
        [80.21235656738287, 13.89291858673107],
        [80.21235656738287, 13.892360687255803],
        [80.21263885498075, 13.892360687255803],
        [80.21263885498075, 13.891519546508903],
        [80.2129135131836, 13.89152908325201],
        [80.2129135131836, 13.890694618225154],
        [80.21319580078153, 13.890694618225154],
        [80.21319580078153, 13.89013862609869],
        [80.21346282959001, 13.890140533447322],
        [80.21346282959001, 13.889311790466365],
        [80.21375274658232, 13.889311790466365],
        [80.21375274658232, 13.888190269470329],
        [80.21402740478533, 13.888195037841797],
        [80.21402740478533, 13.887360572814941],
        [80.21430206298851, 13.887360572814941],
        [80.21430206298851, 13.886804580688477],
        [80.21457672119135, 13.886811256408691],
        [80.21457672119135, 13.886530876159725],
        [80.21485900878912, 13.886530876159725],
        [80.21485900878912, 13.886251449585075],
        [80.21514129638666, 13.886251449585075],
        [80.21514129638666, 13.885970115661621],
        [80.2154159545899, 13.885972976684684],
        [80.2154159545899, 13.88569545745861],
        [80.21569061279325, 13.88569545745861],
        [80.21569824218761, 13.88541698455822],
        [80.21597290039062, 13.885420799255371],
        [80.21597290039062, 13.885141372680721],
        [80.21624755859403, 13.885141372680721],
        [80.21624755859403, 13.8845796585083],
        [80.2165298461914, 13.884581565857047],
        [80.2165298461914, 13.884029388427734],
        [80.21679687500006, 13.884031295776367],
        [80.21679687500006, 13.883469581604118],
        [80.21708679199236, 13.88347244262701],
        [80.21708679199236, 13.882637977600154],
        [80.21736145019537, 13.882650375366325],
        [80.21736145019537, 13.88208198547369],
        [80.21763610839861, 13.88208198547369],
        [80.21763610839861, 13.880971908569336],
        [80.21791839599632, 13.880971908569336],
        [80.21791839599632, 13.880415916442814],
        [80.21819305419916, 13.88042068481451],
        [80.21819305419916, 13.880140304565543],
        [80.21848297119146, 13.880140304565543],
        [80.21848297119146, 13.87985992431635],
        [80.21875000000011, 13.87985992431635],
        [80.21875000000011, 13.87903022766119],
        [80.2190170288086, 13.87903022766119],
        [80.2190170288086, 13.878470420837516],
        [80.21930694580107, 13.878472328186092],
        [80.21930694580107, 13.877916336059627],
        [80.21958923339844, 13.877920150756836],
        [80.21958923339844, 13.877360343933162],
        [80.21986389160185, 13.877362251281738],
        [80.21986389160185, 13.876806259155273],
        [80.22013854980486, 13.876810073852653],
        [80.22013854980486, 13.875971794128418],
        [80.22041320800787, 13.875971794128418],
        [80.22041320800787, 13.875140190124625],
        [80.22070312500017, 13.875140190124625],
        [80.22070312500017, 13.874309539794979],
        [80.22097015380882, 13.874309539794979],
        [80.22097015380882, 13.873190879821777],
        [80.2212524414062, 13.873195648193416],
        [80.2212524414062, 13.872362136840877],
        [80.22152709960943, 13.872362136840877],
        [80.22152709960943, 13.871526718139705],
        [80.22180175781261, 13.871529579162598],
        [80.22180175781261, 13.870691299438533],
        [80.22208404541021, 13.870693206787166],
        [80.22208404541021, 13.869860649108887],
        [80.22235870361357, 13.869860649108887],
        [80.22235870361357, 13.868750572204704],
        [80.2226333618164, 13.868750572204704],
        [80.2226333618164, 13.867921829223633],
        [80.22292327880888, 13.867921829223633],
        [80.22292327880888, 13.86681175231945],
        [80.22319793701189, 13.86681175231945],
        [80.22319793701189, 13.865970611572322],
        [80.2234725952149, 13.865973472595215],
        [80.2234725952149, 13.864860534667969],
        [80.22374725341814, 13.864860534667969],
        [80.22374725341814, 13.863751411438102],
        [80.22402954101585, 13.863751411438102],
        [80.22402954101585, 13.862920761108455],
        [80.22429656982416, 13.862920761108455],
        [80.22429656982416, 13.861811637878361],
        [80.22458648681646, 13.861811637878361],
        [80.22458648681646, 13.860971450805778],
        [80.22486114501964, 13.860973358154354],
        [80.22486114501964, 13.859861373901424],
        [80.22513580322271, 13.859861373901424],
        [80.22513580322271, 13.85847282409668],
        [80.2254180908206, 13.858478546142635],
        [80.2254180908206, 13.856531143188533],
        [80.22569274902344, 13.856531143188533],
        [80.22569274902344, 13.855691909789982],
        [80.22597503662138, 13.855693817138786],
        [80.22597503662138, 13.85486030578619],
        [80.22624969482439, 13.85486030578619],
        [80.22624969482439, 13.854306221008358],
        [80.22651672363287, 13.854310989379996],
        [80.22651672363287, 13.853750228881836],
        [80.22680664062517, 13.853750228881836],
        [80.22680664062517, 13.852351188659725],
        [80.22708129882835, 13.852359771728516],
        [80.22708129882835, 13.851805686950627],
        [80.2273559570312, 13.851811408996582],
        [80.2273559570312, 13.851249694824162],
        [80.22763824462896, 13.851249694824162],
        [80.22763824462896, 13.850691795349121],
        [80.22791290283214, 13.850693702697697],
        [80.22791290283214, 13.850137710571403],
        [80.22820281982428, 13.850139617919979],
        [80.22820281982428, 13.849860191345329],
        [80.2284698486331, 13.849862098693904],
        [80.2284698486331, 13.848750114440975],
        [80.22875976562506, 13.84875202178955],
        [80.22875976562506, 13.847359657287654],
        [80.22902679443388, 13.847359657287654],
        [80.22902679443388, 13.846810340881404],
        [80.22930908203125, 13.846810340881404],
        [80.22930908203125, 13.8462495803833],
        [80.2295761108399, 13.8462495803833],
        [80.2295761108399, 13.845969200134334],
        [80.22985839843767, 13.845972061157227],
        [80.22985839843767, 13.845140457153434],
        [80.23014068603521, 13.845140457153434],
        [80.23014068603521, 13.844861984253043],
        [80.23042297363298, 13.844861984253043],
        [80.23041534423845, 13.844305038452262],
        [80.23069763183616, 13.84430980682373],
        [80.23069763183616, 13.84375190734869],
        [80.230972290039, 13.84375190734869],
        [80.230972290039, 13.84236145019537],
        [80.23124694824224, 13.84236145019537],
        [80.23124694824224, 13.842082977294979],
        [80.23152923583996, 13.842082977294979],
        [80.23152923583996, 13.841526985168514],
        [80.23179626464844, 13.841531753540153],
        [80.23179626464844, 13.841251373291016],
        [80.23208618164091, 13.841251373291016],
        [80.23208618164091, 13.840410232544059],
        [80.23236083984375, 13.84041690826416],
        [80.23236083984375, 13.839860916137638],
        [80.23264312744169, 13.839860916137638],
        [80.23263549804716, 13.838748931884709],
        [80.2329177856447, 13.838750839233512],
        [80.2329177856447, 13.838191032409668],
        [80.23319244384771, 13.83819484710699],
        [80.23319244384771, 13.837638854980526],
        [80.23346710205095, 13.837640762329158],
        [80.23346710205095, 13.837351799011344],
        [80.23374938964866, 13.837361335754451],
        [80.23374938964866, 13.836526870727596],
        [80.23401641845714, 13.836530685424805],
        [80.23401641845714, 13.835420608520621],
        [80.23430633544928, 13.835420608520621],
        [80.23430633544928, 13.83458042144781],
        [80.23458099365246, 13.834583282470703],
        [80.23458099365246, 13.834029197692985],
        [80.23486328125006, 13.83403110504156],
        [80.23486328125006, 13.83318996429449],
        [80.23513793945341, 13.83319473266613],
        [80.23513793945341, 13.83236122131359],
        [80.23542022705078, 13.83236122131359],
        [80.23542022705078, 13.831804275512809],
        [80.23568725585966, 13.831810951232853],
        [80.23568725585966, 13.831540107726994],
        [80.2359695434572, 13.831540107726994],
        [80.2359695434572, 13.831251144409237],
        [80.23625183105474, 13.831251144409237],
        [80.23625183105474, 13.83096790313715],
        [80.2368087768557, 13.830972671508846],
        [80.2368087768557, 13.830694198608455],
        [80.23708343505854, 13.830694198608455],
        [80.23708343505854, 13.83013820648199],
        [80.23735809326178, 13.830141067504883],
        [80.23735809326178, 13.829311370849666],
        [80.23763275146496, 13.829311370849666],
        [80.23763275146496, 13.828470230102539],
        [80.23791503906256, 13.828472137451172],
        [80.23791503906256, 13.827637672424316],
        [80.23819732666044, 13.827639579773063],
        [80.23819732666044, 13.826810836791992],
        [80.23847198486328, 13.826810836791992],
        [80.23847198486328, 13.826250076294002],
        [80.23874664306669, 13.826250076294002],
        [80.23874664306669, 13.825969696044865],
        [80.23902893066423, 13.825971603393668],
        [80.23902893066423, 13.825694084167537],
        [80.23930358886724, 13.825694084167537],
        [80.23930358886724, 13.825138092041072],
        [80.23958587646501, 13.825139999389648],
        [80.23958587646501, 13.824859619140682],
        [80.2398605346682, 13.824861526489315],
        [80.2398605346682, 13.824584007263184],
        [80.24013519287104, 13.824584007263184],
        [80.24013519287104, 13.824305534362793],
        [80.2404174804688, 13.824311256408748],
        [80.2404174804688, 13.824029922485295],
        [80.24069976806635, 13.824029922485295],
        [80.24069976806635, 13.823749542236328],
        [80.24096679687506, 13.823749542236328],
        [80.24096679687506, 13.823472023010254],
        [80.24124908447294, 13.823472023010254],
        [80.24124908447294, 13.823193550109863],
        [80.24153137207031, 13.823193550109863],
        [80.24153137207031, 13.822917938232365],
        [80.24208068847673, 13.822919845581168],
        [80.24208068847673, 13.822640419006461],
        [80.2423629760745, 13.822640419006461],
        [80.2423629760745, 13.822370529174918],
        [80.24263763427751, 13.822370529174918],
        [80.24263763427751, 13.822081565856934],
        [80.24291992187506, 13.822084426879996],
        [80.24291992187506, 13.821805953979606],
        [80.24318695068354, 13.821809768676815],
        [80.24318695068354, 13.821530342102108],
        [80.2434692382813, 13.821530342102108],
        [80.2434692382813, 13.820972442627067],
        [80.24375152587885, 13.820972442627067],
        [80.24375152587885, 13.820693969726676],
        [80.24402618408209, 13.820693969726676],
        [80.24402618408209, 13.820416450500602],
        [80.2443084716798, 13.820420265197754],
        [80.2443084716798, 13.819858551025504],
        [80.24458312988281, 13.819862365722713],
        [80.24458312988281, 13.819583892822322],
        [80.24485778808622, 13.819583892822322],
        [80.24485778808622, 13.817083358764648],
        [80.2451400756836, 13.817083358764648],
        [80.2451400756836, 13.81402683258068],
        [80.24540710449224, 13.814031600952148],
        [80.24540710449224, 13.812360763549805],
        [80.24569702148455, 13.812360763549805],
        [80.24569702148455, 13.810689926147518],
        [80.24597167968756, 13.810694694518986],
        [80.24597167968756, 13.809305191040153],
        [80.2462463378908, 13.809311866760197],
        [80.2462463378908, 13.808470726013297],
        [80.24652862548851, 13.808472633361873],
        [80.24652862548851, 13.807639122009334],
        [80.24680328369135, 13.80764102935791],
        [80.24680328369135, 13.806811332702694],
        [80.24709320068365, 13.806811332702694],
        [80.24709320068365, 13.805970191955623],
        [80.2473602294923, 13.805973052978516],
        [80.2473602294923, 13.80513858795166],
        [80.2476425170899, 13.805141448974723],
        [80.2476425170899, 13.80403137207037],
        [80.24791717529325, 13.80403137207037],
        [80.24791717529325, 13.802921295166016],
        [80.2481918334961, 13.802921295166016],
        [80.2481918334961, 13.801532745361442],
        [80.2484664916995, 13.801532745361442],
        [80.2484664916995, 13.800420761108512],
        [80.24874877929705, 13.800420761108512],
        [80.24874877929705, 13.799579620361385],
        [80.24791717529325, 13.799584388732853],
        [80.24791717529325, 13.799305915832463],
        [80.2476425170899, 13.799310684204158],
        [80.2476425170899, 13.798469543457031],
        [80.24791717529325, 13.798472404480094],
        [80.24791717529325, 13.798193931579533],
        [80.2481918334961, 13.798200607299805],
        [80.2481918334961, 13.797920227050838],
        [80.2484664916995, 13.797920227050838],
        [80.2484664916995, 13.797639846801815],
        [80.24874877929705, 13.797639846801815],
        [80.24874877929705, 13.797360420227164],
        [80.24903106689459, 13.79736232757574],
        [80.24903106689459, 13.797081947326774],
        [80.24930572509783, 13.797081947326774],
        [80.24930572509783, 13.79625034332281],
        [80.24958038330101, 13.79625034332281],
        [80.2495727539063, 13.79569244384777],
        [80.24986267089838, 13.795694351196346],
        [80.24986267089838, 13.795140266418457],
        [80.25013732910162, 13.795140266418457],
        [80.25013732910162, 13.793749809265137],
        [80.25041961669933, 13.793749809265137],
        [80.25041961669933, 13.790691375732365],
        [80.25013732910162, 13.790693283081168],
        [80.25013732910162, 13.789304733276424],
        [80.25041961669933, 13.789304733276424],
        [80.25041961669933, 13.78819465637207],
        [80.2506942749024, 13.78819465637207],
        [80.2506942749024, 13.78708267211914],
        [80.25096893310575, 13.78708267211914],
        [80.25096893310575, 13.786249160766545],
        [80.25152587890653, 13.786259651184139],
        [80.25152587890653, 13.785419464111328],
        [80.25180816650408, 13.785419464111328],
        [80.25180816650408, 13.784309387206974],
        [80.25208282470709, 13.784309387206974],
        [80.25208282470709, 13.782921791076717],
        [80.25235748291033, 13.782921791076717],
        [80.25235748291033, 13.780970573425293],
        [80.25208282470709, 13.780973434448356],
        [80.25208282470709, 13.780417442321891],
        [80.25235748291033, 13.780421257019043],
        [80.25235748291033, 13.780140876770076],
        [80.25208282470709, 13.780140876770076],
        [80.25208282470709, 13.778751373291072],
        [80.25180816650408, 13.778751373291072],
        [80.25180816650408, 13.77792072296154],
        [80.25152587890653, 13.77792072296154],
        [80.25152587890653, 13.775970458984375],
        [80.25125122070312, 13.775972366333008],
        [80.25125122070312, 13.775137901306152],
        [80.25096893310575, 13.775142669677791],
        [80.25096893310575, 13.774860382080021],
        [80.25125122070312, 13.774860382080021],
        [80.25125122070312, 13.774583816528434],
        [80.25096893310575, 13.77458190917963],
        [80.25096893310575, 13.773750305175838],
        [80.25068664550781, 13.773750305175838],
        [80.25068664550781, 13.773189544677734],
        [80.25041961669933, 13.773194313049373],
        [80.25041961669933, 13.771528244018555],
        [80.25013732910162, 13.77153015136713],
        [80.25013732910162, 13.770970344543514],
        [80.24986267089838, 13.77097225189209],
        [80.24986267089838, 13.768471717834586],
        [80.24958038330101, 13.768471717834586],
        [80.24958038330101, 13.767915725708121],
        [80.24931335449236, 13.767919540405273],
        [80.24931335449236, 13.76707935333252],
        [80.24903106689459, 13.767084121704158],
        [80.24903106689459, 13.765972137451229],
        [80.24930572509783, 13.765972137451229],
        [80.24930572509783, 13.764583587646484],
        [80.24903106689459, 13.764583587646484],
        [80.24903106689459, 13.762639045715446],
        [80.24874877929705, 13.762641906738338],
        [80.24874877929705, 13.761810302734375],
        [80.2484664916995, 13.761810302734375],
        [80.2484664916995, 13.761531829833984],
        [80.2481918334961, 13.761531829833984],
        [80.2481918334961, 13.75986099243164],
        [80.24791717529325, 13.75986099243164],
        [80.24791717529325, 13.759321212768555],
        [80.2476425170899, 13.759321212768555],
        [80.2476425170899, 13.757919311523494],
        [80.2473602294923, 13.757919311523494],
        [80.2473602294923, 13.757361412048454],
        [80.24708557128912, 13.757361412048454],
        [80.24708557128912, 13.756805419921989],
        [80.24680328369135, 13.75680923461914],
        [80.24680328369135, 13.7562513351441],
        [80.24652862548851, 13.7562513351441],
        [80.24652862548851, 13.75568962097168],
        [80.2462463378908, 13.755695343017635],
        [80.2462463378908, 13.754583358764705],
        [80.24597167968756, 13.754583358764705],
        [80.24597167968756, 13.75402736663824],
        [80.24569702148455, 13.754031181335563],
        [80.24569702148455, 13.753190040588493],
        [80.245414733887, 13.753194808959961],
        [80.245414733887, 13.752084732055778],
        [80.2451400756836, 13.752084732055778],
        [80.2451400756836, 13.750972747802848],
        [80.24485778808622, 13.750972747802848],
        [80.24485778808622, 13.750420570373535],
        [80.24458312988281, 13.750420570373535],
        [80.24458312988281, 13.749580383300724],
        [80.24485778808622, 13.749582290649528],
        [80.24485778808622, 13.748749732971248],
        [80.24458312988281, 13.748749732971248],
        [80.24458312988281, 13.748189926147575],
        [80.2443084716798, 13.748193740844783],
        [80.2443084716798, 13.747916221618652],
        [80.24402618408209, 13.747920989990348],
        [80.24402618408209, 13.747360229492188],
        [80.24375152587885, 13.747360229492188],
        [80.24375152587885, 13.74708366394043],
        [80.2434692382813, 13.747091293334961],
        [80.2434692382813, 13.746530532837028],
        [80.24318695068354, 13.746530532837028],
        [80.24318695068354, 13.74431133270258],
        [80.24291992187506, 13.74431133270258],
        [80.24291992187506, 13.74374961853033],
        [80.24263763427751, 13.74374961853033],
        [80.24263763427751, 13.74347019195568],
        [80.2423629760745, 13.743472099304256],
        [80.2423629760745, 13.743193626403865],
        [80.24208068847673, 13.743193626403865],
        [80.24208068847673, 13.741806030273438],
        [80.24180603027372, 13.741809844970817],
        [80.24180603027372, 13.740420341491756],
        [80.24153137207031, 13.740420341491756],
        [80.24153137207031, 13.739031791687012],
        [80.24124908447294, 13.739031791687012],
        [80.24124908447294, 13.737640380859375],
        [80.24096679687506, 13.737640380859375],
        [80.24096679687506, 13.737361907959041],
        [80.24069213867199, 13.737361907959041],
        [80.24069213867199, 13.735971450805664],
        [80.2404174804688, 13.735971450805664],
        [80.2404174804688, 13.734026908874455],
        [80.2401428222658, 13.73403167724615],
        [80.2401428222658, 13.73347091674799],
        [80.2398605346682, 13.73347091674799],
        [80.2398605346682, 13.731529235839844],
        [80.23958587646501, 13.731529235839844],
        [80.23958587646501, 13.731250762939453],
        [80.23929595947271, 13.731250762939453],
        [80.23929595947271, 13.729580879211483],
        [80.23902893066423, 13.729582786560059],
        [80.23902893066423, 13.72791671752941],
        [80.23874664306669, 13.72792053222662],
        [80.23874664306669, 13.726531028747559],
        [80.23847198486328, 13.726531028747559],
        [80.23847198486328, 13.724861145019531],
        [80.23819732666044, 13.724861145019531],
        [80.23819732666044, 13.723194122314453],
        [80.23791503906256, 13.723194122314453],
        [80.23791503906256, 13.722638130187988],
        [80.23763275146496, 13.722640991211051],
        [80.23763275146496, 13.721811294555664],
        [80.23792266845709, 13.721811294555664],
        [80.23792266845709, 13.720420837402344],
        [80.23763275146496, 13.720420837402344],
        [80.23763275146496, 13.719030380248967],
        [80.23735809326178, 13.719030380248967],
        [80.23735809326178, 13.716811180114746],
        [80.237075805664, 13.716811180114746],
        [80.237075805664, 13.715971946716422],
        [80.2368087768557, 13.715971946716422],
        [80.2368087768557, 13.713749885559139],
        [80.23652648925798, 13.713751792907715],
        [80.23652648925798, 13.713191032409782],
        [80.23625183105474, 13.71319580078125],
        [80.23625183105474, 13.709304809570426],
        [80.2359695434572, 13.709309577941895],
        [80.2359695434572, 13.708751678466854],
        [80.23568725585966, 13.708751678466854],
        [80.23568725585966, 13.706811904907283],
        [80.23542022705078, 13.706811904907283],
        [80.23542022705078, 13.70347118377697],
        [80.23513793945341, 13.703473091125545],
        [80.23513793945341, 13.701251029968262],
        [80.23486328125006, 13.701251029968262],
        [80.23486328125006, 13.700421333313045],
        [80.2345733642581, 13.700421333313045],
        [80.2345733642581, 13.698189735412654],
        [80.23486328125006, 13.698195457458553],
        [80.23486328125006, 13.697639465332088],
        [80.23430633544928, 13.697641372680721],
        [80.23430633544928, 13.697360038757438],
        [80.23401641845714, 13.697360038757438],
        [80.23401641845714, 13.6970796585083],
        [80.23430633544928, 13.697081565857047],
        [80.23430633544928, 13.695137977600154],
        [80.23401641845714, 13.695142745971793],
        [80.23401641845714, 13.694030761718864],
        [80.23430633544928, 13.694030761718864],
        [80.23430633544928, 13.693189620971737],
        [80.2340240478515, 13.693194389343205],
        [80.2340240478515, 13.691805839538631],
        [80.23430633544928, 13.691810607910156],
        [80.23430633544928, 13.690691947937125],
        [80.2340240478515, 13.690693855285701],
        [80.2340240478515, 13.690416336059627],
        [80.23374938964866, 13.690421104431266],
        [80.23374938964866, 13.689311027526912],
        [80.23346710205095, 13.689311027526912],
        [80.23346710205095, 13.689029693603459],
        [80.23374938964866, 13.689029693603459],
        [80.23374938964866, 13.686249732971305],
        [80.23346710205095, 13.686249732971305],
        [80.23346710205095, 13.683749198913631],
        [80.23374938964866, 13.683749198913631],
        [80.23374938964866, 13.68292045593273],
        [80.23401641845714, 13.68292045593273],
        [80.23401641845714, 13.68264198303234],
        [80.23374938964866, 13.68264198303234],
        [80.23374938964866, 13.681810379028377],
        [80.23346710205095, 13.681810379028377],
        [80.23346710205095, 13.681531906127987],
        [80.23319244384771, 13.681531906127987],
        [80.23319244384771, 13.680971145629826],
        [80.23347473144548, 13.680971145629826],
        [80.23347473144548, 13.680417060852164],
        [80.23374938964866, 13.680421829223633],
        [80.23374938964866, 13.677640914916935],
        [80.23346710205095, 13.677640914916935],
        [80.23346710205095, 13.677360534667969],
        [80.23374938964866, 13.677360534667969],
        [80.23374938964866, 13.675695419311637],
        [80.23347473144548, 13.675695419311637],
        [80.23347473144548, 13.673194885253963],
        [80.23374938964866, 13.673194885253963],
        [80.23374938964866, 13.672917366027889],
        [80.23346710205095, 13.672921180725211],
        [80.23346710205095, 13.672080039978141],
        [80.23319244384771, 13.672082901001033],
        [80.23319244384771, 13.670695304870549],
        [80.2329177856447, 13.670695304870549],
        [80.2329177856447, 13.670416831970158],
        [80.23264312744169, 13.670420646667537],
        [80.23264312744169, 13.669580459594727],
        [80.2329177856447, 13.669582366943303],
        [80.2329177856447, 13.669306755066032],
        [80.23374938964866, 13.669310569763184],
        [80.23374938964866, 13.669031143188533],
        [80.23346710205095, 13.669031143188533],
        [80.23346710205095, 13.66874980926525],
        [80.23319244384771, 13.66874980926525],
        [80.23319244384771, 13.667640686035156],
        [80.23346710205095, 13.667640686035156],
        [80.23346710205095, 13.66736030578619],
        [80.2340240478515, 13.66736030578619],
        [80.2340240478515, 13.666527748107967],
        [80.23374938964866, 13.666530609130803],
        [80.23374938964866, 13.66596984863287],
        [80.23347473144548, 13.665971755981445],
        [80.23347473144548, 13.66541576385498],
        [80.23319244384771, 13.665420532226676],
        [80.23319244384771, 13.664580345153865],
        [80.2329177856447, 13.664584159851017],
        [80.2329177856447, 13.663749694824162],
        [80.23346710205095, 13.663749694824162],
        [80.23346710205095, 13.663470268249512],
        [80.2340240478515, 13.663472175598258],
        [80.2340240478515, 13.663193702697868],
        [80.23374938964866, 13.663193702697868],
        [80.23374938964866, 13.661527633667049],
        [80.23401641845714, 13.661529541015625],
        [80.23401641845714, 13.659871101379338],
        [80.23430633544928, 13.659871101379338],
        [80.23430633544928, 13.65792179107666],
        [80.23401641845714, 13.65792179107666],
        [80.23401641845714, 13.655970573425407],
        [80.23430633544928, 13.655970573425407],
        [80.23430633544928, 13.655138969421444],
        [80.23401641845714, 13.655141830444336],
        [80.23401641845714, 13.654861450195312],
        [80.23430633544928, 13.654861450195312],
        [80.23430633544928, 13.654026985168457],
        [80.2345733642581, 13.654031753540153],
        [80.2345733642581, 13.652639389038029],
        [80.23486328125006, 13.652639389038029],
        [80.23486328125006, 13.651529312133846],
        [80.2345733642581, 13.651529312133846],
        [80.2345733642581, 13.649861335754451],
        [80.23486328125006, 13.649861335754451],
        [80.23486328125006, 13.648751258850098],
        [80.23513793945341, 13.648751258850098],
        [80.23513793945341, 13.648189544677848],
        [80.23542022705078, 13.648195266723633],
        [80.23542022705078, 13.647083282470703],
        [80.23569488525419, 13.647083282470703],
        [80.23569488525419, 13.645138740539664],
        [80.2359695434572, 13.64514064788824],
        [80.2359695434572, 13.6415309906007],
        [80.23625183105474, 13.6415309906007],
        [80.23625183105474, 13.639860153198185],
        [80.23652648925798, 13.639860153198185],
        [80.23652648925798, 13.638471603393612],
        [80.2368087768557, 13.638471603393612],
        [80.2368087768557, 13.637638092041072],
        [80.237075805664, 13.637639999389648],
        [80.237075805664, 13.636529922485295],
        [80.23735809326178, 13.636529922485295],
        [80.23735809326178, 13.634030342102108],
        [80.23763275146496, 13.634030342102108],
        [80.23763275146496, 13.63318920135498],
        [80.23791503906256, 13.633193969726676],
        [80.23791503906256, 13.632639884948787],
        [80.23819732666044, 13.632641792297363],
        [80.23819732666044, 13.63181018829357],
        [80.23847198486328, 13.63181018829357],
        [80.23847198486328, 13.63041973114025],
        [80.23874664306669, 13.63041973114025],
        [80.23874664306669, 13.629309654235897],
        [80.23847198486328, 13.629309654235897],
        [80.23847198486328, 13.628748893737793],
        [80.23874664306669, 13.628748893737793],
        [80.23874664306669, 13.627920150756836],
        [80.23902893066423, 13.627920150756836],
        [80.23902893066423, 13.627080917358342],
        [80.23930358886724, 13.627082824707145],
        [80.23930358886724, 13.626249313354549],
        [80.23958587646501, 13.626249313354549],
        [80.23958587646501, 13.625139236450195],
        [80.2398605346682, 13.625139236450195],
        [80.2398605346682, 13.624581336975154],
        [80.24013519287104, 13.62458324432373],
        [80.24013519287104, 13.623194694518986],
        [80.2404174804688, 13.623194694518986],
        [80.2404174804688, 13.622361183166618],
        [80.24069213867199, 13.622361183166618],
        [80.24069213867199, 13.621805191040153],
        [80.24096679687506, 13.621811866760197],
        [80.24096679687506, 13.621251106262264],
        [80.24124908447294, 13.621251106262264],
        [80.24124908447294, 13.619580268859977],
        [80.24153137207031, 13.61958312988287],
        [80.24153137207031, 13.61902904510498],
        [80.24180603027372, 13.619030952453727],
        [80.24180603027372, 13.617361068725529],
        [80.24208068847673, 13.617361068725529],
        [80.24208068847673, 13.616250991821346],
        [80.2423629760745, 13.616250991821346],
        [80.2423629760745, 13.615690231323242],
        [80.24263763427751, 13.615694046020621],
        [80.24263763427751, 13.61541557312023],
        [80.24291992187506, 13.615421295166016],
        [80.24291992187506, 13.614859580993766],
        [80.2431945800783, 13.614863395690918],
        [80.2431945800783, 13.613750457763672],
        [80.2434692382813, 13.613750457763672],
        [80.2434692382813, 13.613470077514705],
        [80.24375152587885, 13.613471984863281],
        [80.24375152587885, 13.612915992736816],
        [80.24402618408209, 13.612920761108512],
        [80.24402618408209, 13.612360000610352],
        [80.2443084716798, 13.612360000610352],
        [80.2443084716798, 13.611531257629451],
        [80.24458312988281, 13.611531257629451],
        [80.24458312988281, 13.610969543457031],
        [80.24485778808622, 13.610972404480094],
        [80.24485778808622, 13.61041641235363],
        [80.2451400756836, 13.610421180725098],
        [80.2451400756836, 13.609860420227164],
        [80.245414733887, 13.60986232757574],
        [80.245414733887, 13.609306335449276],
        [80.24569702148455, 13.609311103820914],
        [80.24569702148455, 13.608760833740234],
        [80.24597167968756, 13.608760833740234],
        [80.24597167968756, 13.608192443847713],
        [80.2462463378908, 13.608194351196346],
        [80.2462463378908, 13.607640266418457],
        [80.24652862548851, 13.607640266418457],
        [80.24652862548851, 13.607078552246207],
        [80.24680328369135, 13.607084274291992],
        [80.24680328369135, 13.606528282165527],
        [80.24709320068365, 13.606530189514274],
        [80.24709320068365, 13.606249809265137],
        [80.2473602294923, 13.606249809265137],
        [80.2473602294923, 13.60569095611578],
        [80.2476425170899, 13.605693817138672],
        [80.2476425170899, 13.605139732360954],
        [80.24791717529325, 13.605139732360954],
        [80.24791717529325, 13.604580879211426],
        [80.2481918334961, 13.604583740234489],
        [80.2481918334961, 13.604027748108024],
        [80.2484664916995, 13.6040296554566],
        [80.2484664916995, 13.60346889495844],
        [80.24874877929705, 13.603470802307243],
        [80.24874877929705, 13.60291767120367],
        [80.24903106689459, 13.602919578552246],
        [80.24903106689459, 13.602361679077148],
        [80.24931335449236, 13.602361679077148],
        [80.24930572509783, 13.601804733276424],
        [80.2495727539063, 13.60181045532238],
        [80.2495727539063, 13.601251602172795],
        [80.24986267089838, 13.601251602172795],
        [80.24986267089838, 13.600690841674862],
        [80.25013732910162, 13.60069465637207],
        [80.25013732910162, 13.60041713714594],
        [80.25041961669933, 13.600421905517635],
        [80.25041961669933, 13.599861145019474],
        [80.2506942749024, 13.599861145019474],
        [80.2506942749024, 13.59930515289301],
        [80.25096893310575, 13.599309921264648],
        [80.25096893310575, 13.598751068115291],
        [80.25125122070312, 13.598751068115291],
        [80.25125122070312, 13.598470687866154],
        [80.25152587890653, 13.598472595214957],
        [80.25152587890653, 13.597916603088436],
        [80.25180816650408, 13.597920417785645],
        [80.25180816650408, 13.597360610961971],
        [80.25208282470709, 13.597360610961971],
        [80.25208282470709, 13.59708118438732],
        [80.25235748291033, 13.597083091735897],
        [80.25235748291033, 13.596527099609432],
        [80.25263977050804, 13.59653186798107],
        [80.25263977050804, 13.596250534057617],
        [80.25290679931635, 13.596250534057617],
        [80.25290679931635, 13.595971107482967],
        [80.25319671630865, 13.595973014831543],
        [80.25319671630865, 13.595417022705078],
        [80.25347137451183, 13.595421791076717],
        [80.25347137451183, 13.59514141082775],
        [80.2537460327149, 13.59514141082775],
        [80.2537460327149, 13.594861030578613],
        [80.25347137451183, 13.594861030578613],
        [80.25347137451183, 13.594311714172363],
        [80.2537460327149, 13.594311714172363],
        [80.2537460327149, 13.59375095367443],
        [80.25402832031278, 13.59375095367443],
        [80.25402832031278, 13.593461990356445],
        [80.25375366210943, 13.593473434448356],
        [80.25375366210943, 13.593194961547965],
        [80.25402832031278, 13.593194961547965],
        [80.25402832031278, 13.592917442321891],
        [80.2545928955081, 13.592921257019043],
        [80.2545928955081, 13.59236145019537],
        [80.25485992431658, 13.59236145019537],
        [80.25485992431658, 13.591251373291016],
        [80.25514221191435, 13.591251373291016],
        [80.25514221191435, 13.590689659118596],
        [80.25541687011736, 13.59069538116455],
        [80.25541687011736, 13.589859962463436],
        [80.2556991577149, 13.589859962463436],
        [80.25569152832054, 13.589582443237305],
        [80.25597381591814, 13.589582443237305],
        [80.25597381591814, 13.589306831359806],
        [80.25624847412115, 13.589310646057186],
        [80.25624847412115, 13.588470458984375],
        [80.2565307617187, 13.588472366332951],
        [80.2565307617187, 13.587916374206486],
        [80.25681304931646, 13.587921142578182],
        [80.25681304931646, 13.587360382080021],
        [80.25708007812528, 13.587360382080021],
        [80.25708007812528, 13.586806297302303],
        [80.25736236572266, 13.586811065673828],
        [80.25736236572266, 13.586250305175838],
        [80.25763702392607, 13.586250305175838],
        [80.25763702392607, 13.585969924926871],
        [80.25791931152344, 13.585971832275447],
        [80.25791931152344, 13.585415840148983],
        [80.25818634033209, 13.585420608520451],
        [80.25818634033209, 13.585140228271484],
        [80.25846862792986, 13.585140228271484],
        [80.25846862792986, 13.584590911865291],
        [80.25874328613304, 13.584590911865291],
        [80.25874328613304, 13.58403015136713],
        [80.25903320312517, 13.58403015136713],
        [80.25903320312517, 13.581250190734977],
        [80.25930786132835, 13.581250190734977],
        [80.25930786132835, 13.58096981048584],
        [80.2595825195312, 13.580971717834586],
        [80.2595825195312, 13.580415725708121],
        [80.25985717773443, 13.580419540405273],
        [80.25985717773443, 13.579859733581657],
        [80.26013946533214, 13.579859733581657],
        [80.26013946533214, 13.579310417175407],
        [80.26040649414062, 13.579310417175407],
        [80.26040649414062, 13.57903003692627],
        [80.2606964111331, 13.57903003692627],
        [80.2606964111331, 13.578459739685172],
        [80.26097106933594, 13.578472137451172],
        [80.26097106933594, 13.578193664550838],
        [80.26125335693388, 13.578193664550838],
        [80.26125335693388, 13.57791805267334],
        [80.26152801513689, 13.577919960022086],
        [80.26152801513689, 13.577359199523983],
        [80.2618026733399, 13.577362060546818],
        [80.2618026733399, 13.576527595519963],
        [80.26207733154314, 13.576530456543026],
        [80.26207733154314, 13.576251983642692],
        [80.26235961914085, 13.576251983642692],
        [80.26235961914085, 13.575691223144531],
        [80.26264190673822, 13.575693130493107],
        [80.26264190673822, 13.575418472290096],
        [80.26291656494146, 13.575420379638672],
        [80.26291656494146, 13.574860572815055],
        [80.26319122314464, 13.574860572815055],
        [80.26319122314464, 13.57402706146246],
        [80.26346588134766, 13.574031829833984],
        [80.26346588134766, 13.573752403259277],
        [80.2637481689456, 13.573752403259277],
        [80.2637481689456, 13.572921752929801],
        [80.26403045654297, 13.572921752929801],
        [80.26403045654297, 13.572080612182674],
        [80.26430511474638, 13.572083473205566],
        [80.26430511474638, 13.571249008178711],
        [80.26457977294939, 13.571250915527287],
        [80.26457977294939, 13.57069110870367],
        [80.26486206054693, 13.570693016052246],
        [80.26486206054693, 13.570138931274528],
        [80.26513671875017, 13.570140838623104],
        [80.26513671875017, 13.569861412048454],
        [80.26541900634788, 13.569861412048454],
        [80.26541900634788, 13.569305419921989],
        [80.2656860351562, 13.569310188293457],
        [80.2656860351562, 13.5687513351441],
        [80.2659759521485, 13.5687513351441],
        [80.2659759521485, 13.56818962097168],
        [80.26625061035168, 13.568195343017635],
        [80.26625061035168, 13.56763935089117],
        [80.26653289794928, 13.567641258239746],
        [80.26653289794928, 13.56681156158453],
        [80.26680755615263, 13.56681156158453],
        [80.26680755615263, 13.566250801086426],
        [80.26708221435547, 13.566250801086426],
        [80.26708221435547, 13.565690040588493],
        [80.26736450195341, 13.565694808959961],
        [80.26736450195341, 13.565138816833496],
        [80.26763916015642, 13.565140724182243],
        [80.26763916015642, 13.564579963684139],
        [80.26791381835943, 13.564584732055778],
        [80.26791381835943, 13.564028739929313],
        [80.2681961059572, 13.564030647277889],
        [80.2681961059572, 13.563190460205078],
        [80.26847076416038, 13.563195228576717],
        [80.26847076416038, 13.562638282775993],
        [80.26875305175798, 13.562641143798828],
        [80.26875305175798, 13.562080383300895],
        [80.269027709961, 13.562082290649471],
        [80.269027709961, 13.561529159545898],
        [80.26930236816418, 13.561531066894474],
        [80.26930236816418, 13.560970306396541],
        [80.26958465576178, 13.560972213745117],
        [80.26958465576178, 13.560137748718262],
        [80.26985931396513, 13.560140609741154],
        [80.26985931396513, 13.559579849243221],
        [80.2701416015625, 13.55958366394043],
        [80.2701416015625, 13.559027671813908],
        [80.27041625976591, 13.559030532836971],
        [80.27041625976591, 13.558469772338867],
        [80.27069091796892, 13.558471679687443],
        [80.27069091796892, 13.557915687560978],
        [80.27097320556669, 13.557921409606934],
        [80.27097320556669, 13.557359695434684],
        [80.2712478637697, 13.557359695434684],
        [80.2712478637697, 13.556529998779297],
        [80.27153015136724, 13.556529998779297],
        [80.27153015136724, 13.55597019195568],
        [80.27180480957048, 13.555972099304256],
        [80.27180480957048, 13.5551376342774],
        [80.2720870971682, 13.555139541625977],
        [80.2720870971682, 13.554581642150936],
        [80.27236175537104, 13.554583549499512],
        [80.27236175537104, 13.553750038146973],
        [80.27263641357428, 13.553750038146973],
        [80.27263641357428, 13.552920341491756],
        [80.27291870117199, 13.552920341491756],
        [80.27291870117199, 13.552359580993652],
        [80.273193359375, 13.552362442016715],
        [80.273193359375, 13.55152797698986],
        [80.27347564697294, 13.55152797698986],
        [80.27347564697294, 13.550971984863338],
        [80.27375030517578, 13.550971984863338],
        [80.27375030517578, 13.550136566162223],
        [80.27401733398443, 13.550140380859375],
        [80.27401733398443, 13.549580574035758],
        [80.27430725097673, 13.54958438873291],
        [80.27430725097673, 13.548748970031795],
        [80.27458190917974, 13.54875183105463],
        [80.27458190917974, 13.547920227050838],
        [80.27485656738298, 13.547920227050838],
        [80.27485656738298, 13.54736137390131],
        [80.2751388549807, 13.54736137390131],
        [80.2751388549807, 13.546526908874455],
        [80.27540588378918, 13.54653167724615],
        [80.27540588378918, 13.54597091674799],
        [80.2756958007813, 13.54597091674799],
        [80.2756958007813, 13.545139312744197],
        [80.27597045898449, 13.545139312744197],
        [80.27597045898449, 13.544581413269157],
        [80.27625274658209, 13.544583320617733],
        [80.27625274658209, 13.543748855590877],
        [80.27652740478544, 13.543750762939453],
        [80.27652740478544, 13.542922019958553],
        [80.27680969238281, 13.542922019958553],
        [80.27680969238281, 13.54234981536871],
        [80.27708435058622, 13.54236125946045],
        [80.27708435058622, 13.541526794433594],
        [80.27735900878923, 13.541530609130916],
        [80.27735900878923, 13.540970802307129],
        [80.27764129638678, 13.540970802307129],
        [80.27764129638678, 13.540139198303336],
        [80.27791595459001, 13.540141105651912],
        [80.27791595459001, 13.539580345153809],
        [80.2781906127932, 13.539583206176871],
        [80.27819824218773, 13.538748741150016],
        [80.27847290039057, 13.538750648498592],
        [80.27847290039057, 13.537920951843375],
        [80.2787475585938, 13.537920951843375],
        [80.2787475585938, 13.537361145019531],
        [80.27902984619152, 13.537361145019531],
        [80.27902984619152, 13.536528587341309],
        [80.279296875, 13.536540031433162],
        [80.279296875, 13.535969734191951],
        [80.27958679199247, 13.535972595214787],
        [80.27958679199247, 13.535138130187931],
        [80.27986907958984, 13.535140991210994],
        [80.27986907958984, 13.53458023071289],
        [80.28013610839872, 13.534582138061467],
        [80.28013610839872, 13.533749580383414],
        [80.28041839599626, 13.533749580383414],
        [80.28041839599626, 13.532920837402287],
        [80.28069305419928, 13.532920837402287],
        [80.28069305419928, 13.532360076904354],
        [80.28098297119158, 13.532360076904354],
        [80.28097534179705, 13.53152847290039],
        [80.28125000000023, 13.531531333923454],
        [80.28125000000023, 13.530969619751033],
        [80.28152465820307, 13.53097152709961],
        [80.28152465820307, 13.530415534973145],
        [80.28180694580084, 13.530415534973145],
        [80.28180694580084, 13.529582023620605],
        [80.28208160400402, 13.529582023620605],
        [80.28208160400402, 13.529027938842887],
        [80.28235626220709, 13.529029846191463],
        [80.28235626220709, 13.528471946716422],
        [80.28263854980497, 13.528471946716422],
        [80.28263854980497, 13.527638435363883],
        [80.28291320800781, 13.52764034271246],
        [80.28291320800781, 13.527078628540039],
        [80.28319549560575, 13.527084350585994],
        [80.28319549560575, 13.52652835845953],
        [80.28347015380876, 13.526530265808105],
        [80.28347015380876, 13.525691032409782],
        [80.28375244140653, 13.52569580078125],
        [80.28375244140653, 13.524862289428654],
        [80.28402709960955, 13.524862289428654],
        [80.28402709960955, 13.524027824401799],
        [80.28430175781273, 13.524029731750602],
        [80.28430175781273, 13.523471832275334],
        [80.28458404541033, 13.523471832275334],
        [80.28458404541033, 13.522640228271541],
        [80.28485870361334, 13.522640228271541],
        [80.28485870361334, 13.521809577941895],
        [80.28514099121088, 13.521809577941895],
        [80.28514099121088, 13.52097129821783],
        [80.28541564941412, 13.52097129821783],
        [80.28541564941412, 13.520138740539608],
        [80.28569793701183, 13.520141601562443],
        [80.28569793701183, 13.51958084106451],
        [80.28597259521484, 13.519582748413086],
        [80.28597259521484, 13.518749237060547],
        [80.28624725341825, 13.518749237060547],
        [80.28624725341825, 13.51791954040533],
        [80.28652954101562, 13.51791954040533],
        [80.28652954101562, 13.517081260681152],
        [80.28680419921903, 13.517083168029899],
        [80.28680419921903, 13.516248703003043],
        [80.28708648681658, 13.51625061035162],
        [80.28708648681658, 13.515410423278809],
        [80.28736114501959, 13.51541709899908],
        [80.28736114501959, 13.514582633972225],
        [80.28763580322283, 13.514582633972225],
        [80.28763580322283, 13.513472557067871],
        [80.28791809082054, 13.513472557067871],
        [80.28791809082054, 13.512639045715332],
        [80.28819274902338, 13.512640953064079],
        [80.28819274902338, 13.511809349060115],
        [80.28848266601568, 13.511809349060115],
        [80.28848266601568, 13.510970115661621],
        [80.28874969482433, 13.510972976684684],
        [80.28874969482433, 13.510139465332088],
        [80.28901672363281, 13.510141372680664],
        [80.28901672363281, 13.509310722351188],
        [80.28930664062528, 13.509310722351188],
        [80.28930664062528, 13.508469581604118],
        [80.28958129882812, 13.50847339630127],
        [80.28958129882812, 13.507637977600098],
        [80.28985595703153, 13.50764083862299],
        [80.28985595703153, 13.50681114196783],
        [80.29013824462908, 13.50681114196783],
        [80.29013824462908, 13.505689620971737],
        [80.29041290283209, 13.505694389343375],
        [80.29041290283209, 13.50485992431652],
        [80.29070281982439, 13.50485992431652],
        [80.29069519042986, 13.504028320312557],
        [80.29096984863304, 13.50403118133545],
        [80.29096984863304, 13.503179550170898],
        [80.29125213623041, 13.503193855285701],
        [80.29125213623041, 13.502362251281738],
        [80.29152679443365, 13.502362251281738],
        [80.29152679443365, 13.501250267028809],
        [80.29180908203136, 13.501250267028809],
        [80.29180908203136, 13.500140190124625],
        [80.29207611083984, 13.500140190124625],
        [80.29207611083984, 13.499030113220272],
        [80.29235839843778, 13.499030113220272],
        [80.29235839843778, 13.497920036315918],
        [80.29264068603516, 13.497920036315918],
        [80.29264068603516, 13.497639656066951],
        [80.2929229736331, 13.497639656066951],
        [80.2929229736331, 13.49707889556879],
        [80.29319763183611, 13.497083663940487],
        [80.29319763183611, 13.496527671813965],
        [80.29347229003912, 13.496529579162598],
        [80.29347229003912, 13.495691299438533],
        [80.29374694824236, 13.49569320678711],
        [80.29374694824236, 13.494861602783146],
        [80.29402923584007, 13.494861602783146],
        [80.29402923584007, 13.494031906127987],
        [80.29429626464838, 13.494031906127987],
        [80.29429626464838, 13.492921829223633],
        [80.29458618164068, 13.492921829223633],
        [80.29458618164068, 13.49181175231945],
        [80.29486846923822, 13.49181175231945],
        [80.29486846923822, 13.490691184997672],
        [80.29513549804693, 13.490693092346248],
        [80.29513549804693, 13.489583015441895],
        [80.29541778564482, 13.489583015441895],
        [80.29541778564482, 13.488471031188965],
        [80.29569244384766, 13.488471031188965],
        [80.29569244384766, 13.487639427185172],
        [80.29596710205107, 13.487641334533748],
        [80.29596710205107, 13.486531257629395],
        [80.29624938964861, 13.486531257629395],
        [80.29624938964861, 13.485690116882267],
        [80.29652404785162, 13.485694885253963],
        [80.29652404785162, 13.485138893127498],
        [80.29680633544939, 13.485138893127498],
        [80.29680633544939, 13.484580039978141],
        [80.29708099365257, 13.484582901000977],
        [80.29708099365257, 13.483751296997013],
        [80.29736328125017, 13.483751296997013],
        [80.29736328125017, 13.483189582824764],
        [80.29763793945318, 13.483195304870549],
        [80.29763793945318, 13.482359886169434],
        [80.29791259765636, 13.482362747192496],
        [80.29791259765636, 13.481810569763184],
        [80.29818725585943, 13.481810569763184],
        [80.29818725585943, 13.48125076293951],
        [80.29846954345732, 13.48125076293951],
        [80.29846954345732, 13.480421066284123],
        [80.29875183105469, 13.480421066284123],
        [80.29875183105469, 13.47986030578619],
        [80.2990264892581, 13.47986030578619],
        [80.2990264892581, 13.479304313659725],
        [80.29930877685547, 13.479304313659725],
        [80.29930877685547, 13.478750228881836],
        [80.29957580566412, 13.478750228881836],
        [80.29957580566412, 13.47819042205822],
        [80.29985809326189, 13.478194236755371],
        [80.29985809326189, 13.47791576385498],
        [80.30014038085943, 13.47792053222662],
        [80.30014038085943, 13.477359771728516],
        [80.3004226684572, 13.477359771728516],
        [80.30041503906267, 13.476805686950627],
        [80.30069732666038, 13.476811408996582],
        [80.30069732666038, 13.476530075073299],
        [80.30097198486322, 13.476530075073299],
        [80.30097198486322, 13.475970268249512],
        [80.30124664306646, 13.475972175598258],
        [80.30124664306646, 13.475693702697868],
        [80.30152893066418, 13.475693702697868],
        [80.30152893066418, 13.475137710571403],
        [80.30179595947266, 13.475139617919979],
        [80.30179595947266, 13.474579811096135],
        [80.30152893066418, 13.474583625793514],
        [80.30152893066418, 13.473750114440975],
        [80.30124664306646, 13.47375202178955],
        [80.30124664306646, 13.473200798034725],
        [80.30097198486322, 13.473200798034725],
        [80.30097198486322, 13.472640037536621],
        [80.30124664306646, 13.472640037536621],
        [80.30124664306646, 13.472359657287654],
        [80.30152893066418, 13.472359657287654],
        [80.30152893066418, 13.472079277038631],
        [80.30180358886719, 13.472084045410156],
        [80.30180358886719, 13.471805572509766],
        [80.30208587646513, 13.471810340881404],
        [80.30208587646513, 13.471529960632438],
        [80.30236053466797, 13.471529960632438],
        [80.30236053466797, 13.4712495803833],
        [80.30264282226591, 13.4712495803833],
        [80.30264282226591, 13.470969200134277],
        [80.30319213867193, 13.47097206115717],
        [80.30319213867193, 13.470693588256779],
        [80.30403137207026, 13.470693588256779],
        [80.30403137207026, 13.470417976379508],
        [80.3043060302735, 13.47042179107666],
        [80.3043060302735, 13.470140457153377],
        [80.30458068847668, 13.470140457153377],
        [80.30458068847668, 13.469861984252987],
        [80.30486297607428, 13.469861984252987],
        [80.30486297607428, 13.46958065032959],
        [80.30513763427763, 13.469584465026912],
        [80.30513763427763, 13.469305992126522],
        [80.305419921875, 13.46930980682373],
        [80.305419921875, 13.469030380249023],
        [80.30568695068388, 13.469030380249023],
        [80.30568695068388, 13.468751907348633],
        [80.30596923828142, 13.468751907348633],
        [80.30596923828142, 13.468470573425407],
        [80.30625152587896, 13.468470573425407],
        [80.30625152587896, 13.468193054199276],
        [80.3065261840822, 13.468193054199276],
        [80.3065261840822, 13.467918395996094],
        [80.30680847167991, 13.46792030334467],
        [80.30680847167991, 13.467641830444279],
        [80.30708312988276, 13.467641830444279],
        [80.30708312988276, 13.467361450195312],
        [80.307357788086, 13.467361450195312],
        [80.307357788086, 13.467082977294922],
        [80.30764007568371, 13.467082977294922],
        [80.30764007568371, 13.466526985168457],
        [80.30790710449219, 13.466531753540153],
        [80.30790710449219, 13.465970993041992],
        [80.30819702148466, 13.465970993041992],
        [80.30819702148466, 13.465416908264103],
        [80.3084716796875, 13.465419769287166],
        [80.3084716796875, 13.464860916137638],
        [80.30874633789091, 13.464860916137638],
        [80.30874633789091, 13.464029312133846],
        [80.30902862548845, 13.464029312133846],
        [80.30902862548845, 13.463191032409668],
        [80.30930328369146, 13.46319484710699],
        [80.30930328369146, 13.462638854980526],
        [80.30959320068376, 13.462640762329102],
        [80.30959320068376, 13.461812019348201],
        [80.30985260009788, 13.461812019348201],
        [80.30985260009788, 13.461262702942008],
        [80.31014251709001, 13.461262702942008],
        [80.31014251709001, 13.460421562194881],
        [80.31041717529303, 13.460421562194881],
        [80.31041717529303, 13.45958137512207],
        [80.31069183349621, 13.459583282470646],
        [80.31069183349621, 13.459029197692985],
        [80.31096649169928, 13.45903110504156],
        [80.31096649169928, 13.457921028137207],
        [80.31124877929716, 13.457921028137207],
        [80.31124877929716, 13.456530570983887],
        [80.31153106689453, 13.456530570983887],
        [80.31153106689453, 13.455131530761719],
        [80.31180572509794, 13.455138206481934],
        [80.31180572509794, 13.454029083252067],
        [80.31208038330095, 13.454030990600643],
        [80.31208038330095, 13.452920913696289],
        [80.31181335449247, 13.452920913696289],
        [80.31181335449247, 13.452071189880371],
        [80.31153106689453, 13.452083587646598],
        [80.31153106689453, 13.451527595520076],
        [80.31124877929716, 13.451531410217285],
        [80.31124877929716, 13.450969696045036],
        [80.31236267089872, 13.450971603393612],
        [80.31236267089872, 13.450694084167537],
        [80.31291961669928, 13.450694084167537],
        [80.31291961669928, 13.450415611267147],
        [80.31346893310553, 13.450421333313102],
        [80.31346893310553, 13.449859619140682],
        [80.31375122070307, 13.449861526489258],
        [80.31375122070307, 13.446805953979606],
        [80.3140258789063, 13.44681262969965],
        [80.3140258789063, 13.446249961853141],
        [80.31430816650402, 13.446249961853141],
        [80.31430816650402, 13.44568920135498],
        [80.31458282470703, 13.445693969726676],
        [80.31458282470703, 13.445139884948787],
        [80.31485748291044, 13.445141792297363],
        [80.31485748291044, 13.44458198547369],
        [80.31513977050781, 13.444583892822322],
        [80.31513977050781, 13.443750381469727],
        [80.31485748291044, 13.443750381469727],
        [80.31485748291044, 13.443471908569336],
        [80.31513977050781, 13.443471908569336],
        [80.31513977050781, 13.442640304565373],
        [80.31540679931646, 13.442640304565373],
        [80.31540679931646, 13.441809654235897],
        [80.31513977050781, 13.441809654235897],
        [80.31513977050781, 13.440420150756836],
        [80.31540679931646, 13.440420150756836],
        [80.31540679931646, 13.437360763549805],
        [80.31569671630876, 13.437360763549805],
        [80.31569671630876, 13.437089920043945],
        [80.3159790039063, 13.437089920043945],
        [80.3159790039063, 13.4359712600708],
        [80.31541442871122, 13.435973167419547],
        [80.31541442871122, 13.435694694519157],
        [80.31513977050781, 13.435694694519157],
        [80.31513977050781, 13.435417175293082],
        [80.31540679931646, 13.43542194366455],
        [80.31540679931646, 13.43458080291748],
        [80.31513977050781, 13.434582710266227],
        [80.31513977050781, 13.433751106262264],
        [80.31485748291044, 13.433751106262264],
        [80.31485748291044, 13.433470726013297],
        [80.31291961669928, 13.433472633361873],
        [80.31291961669928, 13.433195114135799],
        [80.31208038330095, 13.433195114135799],
        [80.31208038330095, 13.432916641235408],
        [80.31153106689453, 13.432921409607047],
        [80.31153106689453, 13.43264102935791],
        [80.31069183349621, 13.43264102935791],
        [80.31069183349621, 13.432921409607047],
        [80.31041717529303, 13.432921409607047],
        [80.31041717529303, 13.43264102935791],
        [80.30985260009788, 13.43264102935791],
        [80.30985260009788, 13.432921409607047],
        [80.30819702148466, 13.432916641235408],
        [80.30819702148466, 13.433195114135799],
        [80.30764007568371, 13.433195114135799],
        [80.30764007568371, 13.433472633361873],
        [80.307357788086, 13.433470726013297],
        [80.307357788086, 13.433751106262264],
        [80.30680847167991, 13.433751106262264],
        [80.30680847167991, 13.434030532836914],
        [80.3065261840822, 13.434030532836914],
        [80.3065261840822, 13.434311866760368],
        [80.30596923828142, 13.434305191040153],
        [80.30596923828142, 13.434582710266227],
        [80.30569458007841, 13.434582710266227],
        [80.30569458007841, 13.434861183166618],
        [80.305419921875, 13.434861183166618],
        [80.305419921875, 13.43542194366455],
        [80.30513763427763, 13.435417175293082],
        [80.30513763427763, 13.435694694519157],
        [80.30486297607428, 13.435694694519157],
        [80.30486297607428, 13.435973167419547],
        [80.30458068847668, 13.4359712600708],
        [80.30457305908232, 13.436532020568904],
        [80.3043060302735, 13.436532020568904],
        [80.3043060302735, 13.436809539794979],
        [80.30403900146501, 13.436809539794979],
        [80.30403900146501, 13.437089920043945],
        [80.30374908447288, 13.437089920043945],
        [80.30374908447288, 13.437639236450195],
        [80.30346679687517, 13.437639236450195],
        [80.30346679687517, 13.437919616699162],
        [80.30319213867193, 13.43791675567627],
        [80.30319213867193, 13.43819522857666],
        [80.30291748046892, 13.438191413879508],
        [80.30291748046892, 13.438749313354549],
        [80.30264282226591, 13.438749313354549],
        [80.30264282226591, 13.439029693603516],
        [80.30236053466797, 13.439029693603516],
        [80.30236053466797, 13.439310073852482],
        [80.30208587646513, 13.439305305481014],
        [80.30208587646513, 13.439861297607479],
        [80.30180358886719, 13.439861297607479],
        [80.30180358886719, 13.44013977050787],
        [80.30152893066418, 13.440130233764762],
        [80.30152893066418, 13.440420150756836],
        [80.30097198486322, 13.440415382385368],
        [80.30097198486322, 13.440695762634334],
        [80.30069732666038, 13.440695762634334],
        [80.30069732666038, 13.440971374511662],
        [80.30014038085943, 13.440971374511662],
        [80.30014038085943, 13.441248893737736],
        [80.29985809326189, 13.441248893737736],
        [80.29985809326189, 13.44153022766119],
        [80.29957580566412, 13.44153022766119],
        [80.29957580566412, 13.441809654235897],
        [80.2990264892581, 13.441805839538517],
        [80.2990264892581, 13.442084312439079],
        [80.29875183105469, 13.442081451416016],
        [80.29875183105469, 13.442361831664982],
        [80.29846954345732, 13.442361831664982],
        [80.29846954345732, 13.442640304565373],
        [80.29791259765636, 13.442640304565373],
        [80.29791259765636, 13.44291973114025],
        [80.29763793945318, 13.442917823791447],
        [80.29763793945318, 13.443194389343262],
        [80.29680633544939, 13.443194389343262],
        [80.29680633544939, 13.443471908569336],
        [80.29651641845709, 13.443471908569336],
        [80.29651641845709, 13.443750381469727],
        [80.29624938964861, 13.443750381469727],
        [80.29624938964861, 13.444029808044434],
        [80.29596710205107, 13.444029808044434],
        [80.29596710205107, 13.44431018829357],
        [80.29569244384766, 13.444306373596191],
        [80.29569244384766, 13.444583892822322],
        [80.29541778564482, 13.444583892822322],
        [80.29541778564482, 13.444862365722656],
        [80.29514312744146, 13.44486045837408],
        [80.29514312744146, 13.445141792297363],
        [80.29486846923822, 13.445141792297363],
        [80.29486846923822, 13.445420265197754],
        [80.29458618164068, 13.445416450500545],
        [80.29458618164068, 13.44568920135498],
        [80.29430389404291, 13.445695877075252],
        [80.29430389404291, 13.44597244262701],
        [80.29402923584007, 13.445969581603947],
        [80.29402923584007, 13.446249961853141],
        [80.29374694824236, 13.446249961853141],
        [80.29374694824236, 13.446530342102108],
        [80.29347229003912, 13.446530342102108],
        [80.29347229003912, 13.44681262969965],
        [80.29319763183611, 13.446805953979606],
        [80.29319763183611, 13.447084426879996],
        [80.2929229736331, 13.447081565856934],
        [80.2929229736331, 13.447640419006461],
        [80.29264068603516, 13.447637557983398],
        [80.29264068603516, 13.448472023010254],
        [80.29235839843778, 13.448472023010254],
        [80.29235839843778, 13.449321746826172],
        [80.29207611083984, 13.449321746826172],
        [80.29207611083984, 13.450421333313102],
        [80.29180908203136, 13.450421333313102],
        [80.29180908203136, 13.451250076294002],
        [80.29152679443365, 13.451250076294002],
        [80.29152679443365, 13.452083587646598],
        [80.29125213623041, 13.452071189880371],
        [80.29125213623041, 13.452640533447322],
        [80.29096984863304, 13.45263767242426],
        [80.29096984863304, 13.453193664550724],
        [80.29070281982439, 13.453189849853572],
        [80.29070281982439, 13.453749656677246],
        [80.29041290283209, 13.453749656677246],
        [80.29041290283209, 13.454582214355469],
        [80.29013824462908, 13.454582214355469],
        [80.29013824462908, 13.455138206481934],
        [80.28986358642607, 13.455138206481934],
        [80.28986358642607, 13.455695152282715],
        [80.28958129882812, 13.455690383911246],
        [80.28958129882812, 13.45625114440918],
        [80.28930664062528, 13.45625114440918],
        [80.28930664062528, 13.456810951233024],
        [80.28902435302734, 13.456805229187069],
        [80.28902435302734, 13.457361221313533],
        [80.28874969482433, 13.457361221313533],
        [80.28874969482433, 13.45764064788824],
        [80.28848266601568, 13.45764064788824],
        [80.28848266601568, 13.458198547363281],
        [80.28819274902338, 13.458198547363281],
        [80.28819274902338, 13.458750724792594],
        [80.28791809082054, 13.458750724792594],
        [80.28791809082054, 13.459312438964844],
        [80.28763580322283, 13.459312438964844],
        [80.28763580322283, 13.460141181945914],
        [80.28736114501959, 13.460139274597168],
        [80.28736114501959, 13.460970878601131],
        [80.28708648681658, 13.460970878601131],
        [80.28708648681658, 13.46208286285406],
        [80.28679656982428, 13.462080955505485],
        [80.28679656982428, 13.462919235229492],
        [80.28652954101562, 13.462917327880916],
        [80.28652954101562, 13.463473320007381],
        [80.28624725341825, 13.463471412658805],
        [80.28624725341825, 13.463750839233455],
        [80.28597259521484, 13.463750839233455],
        [80.28597259521484, 13.464860916137638],
        [80.28569793701183, 13.464860916137638],
        [80.28569793701183, 13.466252326965446],
        [80.28541564941412, 13.466252326965446],
        [80.28541564941412, 13.467082977294922],
        [80.28624725341825, 13.467082977294922],
        [80.28624725341825, 13.466526985168457],
        [80.28652954101562, 13.466531753540153],
        [80.28652954101562, 13.466252326965446],
        [80.28679656982428, 13.466252326965446],
        [80.28679656982428, 13.4651393890382],
        [80.28708648681658, 13.4651393890382],
        [80.28708648681658, 13.465419769287166],
        [80.28736114501959, 13.465416908264103],
        [80.28736114501959, 13.465970993041992],
        [80.28763580322283, 13.465970993041992],
        [80.28763580322283, 13.467361450195312],
        [80.28736114501959, 13.467361450195312],
        [80.28736114501959, 13.469030380249023],
        [80.28763580322283, 13.469030380249023],
        [80.28763580322283, 13.46930980682373],
        [80.28791809082054, 13.469305992126522],
        [80.28791809082054, 13.470693588256779],
        [80.28819274902338, 13.470690727233944],
        [80.28819274902338, 13.4712495803833],
        [80.28791809082054, 13.4712495803833],
        [80.28791809082054, 13.471529960632438],
        [80.28819274902338, 13.471529960632438],
        [80.28819274902338, 13.473200798034725],
        [80.28791809082054, 13.47319412231451],
        [80.28791809082054, 13.473471641540584],
        [80.28819274902338, 13.473469734192008],
        [80.28819274902338, 13.474310874939079],
        [80.28791809082054, 13.47430610656744],
        [80.28791809082054, 13.474583625793514],
        [80.28763580322283, 13.474583625793514],
        [80.28763580322283, 13.474862098693904],
        [80.28736114501959, 13.474860191345329],
        [80.28736114501959, 13.475139617919979],
        [80.28708648681658, 13.475137710571403],
        [80.28708648681658, 13.475693702697868],
        [80.28736114501959, 13.475691795349121],
        [80.28736114501959, 13.476259231567496],
        [80.28708648681658, 13.476259231567496],
        [80.28708648681658, 13.476811408996582],
        [80.28680419921903, 13.476805686950627],
        [80.28680419921903, 13.477084159851188],
        [80.28652954101562, 13.477084159851188],
        [80.28652954101562, 13.476805686950627],
        [80.28597259521484, 13.476811408996582],
        [80.28597259521484, 13.476530075073299],
        [80.28569793701183, 13.476530075073299],
        [80.28569793701183, 13.476259231567496],
        [80.28485870361334, 13.476259231567496],
        [80.28485870361334, 13.476811408996582],
        [80.28457641601557, 13.476811408996582],
        [80.28457641601557, 13.477359771728516],
        [80.28485870361334, 13.477359771728516],
        [80.28485870361334, 13.478471755981445],
        [80.28514099121088, 13.478471755981445],
        [80.28514099121088, 13.479584693908691],
        [80.28485870361334, 13.479584693908691],
        [80.28485870361334, 13.47986030578619],
        [80.28458404541033, 13.47986030578619],
        [80.28458404541033, 13.480693817138786],
        [80.28430938720709, 13.480693817138786],
        [80.28430175781273, 13.48097229003912],
        [80.28402709960955, 13.480970382690543],
        [80.28402709960955, 13.481810569763184],
        [80.28375244140653, 13.481810569763184],
        [80.28375244140653, 13.482362747192496],
        [80.28347015380876, 13.482362747192496],
        [80.28347015380876, 13.482920646667537],
        [80.28375244140653, 13.482916831970158],
        [80.28375244140653, 13.483195304870549],
        [80.28402709960955, 13.483189582824764],
        [80.28402709960955, 13.483751296997013],
        [80.28430175781273, 13.483751296997013],
        [80.28430175781273, 13.484030723571891],
        [80.28402709960955, 13.484030723571891],
        [80.28402709960955, 13.484309196472282],
        [80.28347015380876, 13.484305381774902],
        [80.28347015380876, 13.484582901000977],
        [80.28319549560575, 13.484582901000977],
        [80.28319549560575, 13.484861373901367],
        [80.28291320800781, 13.484861373901367],
        [80.28291320800781, 13.485138893127498],
        [80.28263854980497, 13.485138893127498],
        [80.28263854980497, 13.485429763794059],
        [80.28236389160162, 13.485417366027832],
        [80.28236389160162, 13.485973358154354],
        [80.28208160400402, 13.485971450805721],
        [80.28208160400402, 13.486531257629395],
        [80.28180694580084, 13.486527442932186],
        [80.28180694580084, 13.487083435058707],
        [80.28151702880854, 13.487080574035645],
        [80.28151702880854, 13.487641334533748],
        [80.28180694580084, 13.487639427185172],
        [80.28180694580084, 13.488471031188965],
        [80.28151702880854, 13.488471031188965],
        [80.28151702880854, 13.489031791687069],
        [80.28125000000023, 13.48902702331543],
        [80.28125000000023, 13.491249084472713],
        [80.28151702880854, 13.491239547729606],
        [80.28151702880854, 13.49181175231945],
        [80.28180694580084, 13.491805076599178],
        [80.28180694580084, 13.499859809875488],
        [80.28152465820307, 13.499859809875488],
        [80.28152465820307, 13.500694274902344],
        [80.28125000000023, 13.500692367553711],
        [80.28125000000023, 13.501250267028809],
        [80.28097534179705, 13.501250267028809],
        [80.28097534179705, 13.502083778381348],
        [80.28069305419928, 13.502083778381348],
        [80.28069305419928, 13.502362251281738],
        [80.28041839599626, 13.502360343933162],
        [80.28041839599626, 13.502921104431266],
        [80.28013610839872, 13.502916336059627],
        [80.28013610839872, 13.503193855285701],
        [80.27986145019531, 13.503193855285701],
        [80.27986907958984, 13.503749847412166],
        [80.27958679199247, 13.503749847412166],
        [80.27958679199247, 13.5043106079101],
        [80.279296875, 13.5043106079101],
        [80.279296875, 13.50485992431652],
        [80.27902984619152, 13.50485992431652],
        [80.27902984619152, 13.50625038146967],
        [80.2787475585938, 13.50625038146967],
        [80.2787475585938, 13.507081985473633],
        [80.27847290039057, 13.507081985473633],
        [80.27847290039057, 13.50819015502941],
        [80.27819824218773, 13.508200645446834],
        [80.27819824218773, 13.509310722351188],
        [80.27791595459001, 13.509310722351188],
        [80.27791595459001, 13.512372016906795],
        [80.27764129638678, 13.512372016906795],
        [80.27764129638678, 13.512640953064079],
        [80.27735900878923, 13.512640953064079],
        [80.27735900878923, 13.512921333313045],
        [80.27764129638678, 13.512921333313045],
        [80.27764129638678, 13.517642021179256],
        [80.27791595459001, 13.517639160156364],
        [80.27791595459001, 13.520695686340332],
        [80.27764129638678, 13.520690917968864],
        [80.27764129638678, 13.521251678466797],
        [80.27735900878923, 13.521248817443961],
        [80.27735900878923, 13.522084236145076],
        [80.27707672119169, 13.522081375122013],
        [80.27707672119169, 13.522919654846248],
        [80.27680206298828, 13.522917747497672],
        [80.27680206298828, 13.523471832275334],
        [80.27652740478544, 13.523471832275334],
        [80.27652740478544, 13.524310111999569],
        [80.27625274658209, 13.52430629730219],
        [80.27625274658209, 13.524862289428654],
        [80.27597045898449, 13.524860382080078],
        [80.27597045898449, 13.525139808654785],
        [80.27540588378918, 13.525139808654785],
        [80.27540588378918, 13.524860382080078],
        [80.2751388549807, 13.524862289428654],
        [80.2751388549807, 13.524027824401799],
        [80.27540588378918, 13.524029731750602],
        [80.27540588378918, 13.522919654846248],
        [80.2756958007813, 13.522919654846248],
        [80.2756958007813, 13.521251678466797],
        [80.27597045898449, 13.521251678466797],
        [80.27597045898449, 13.519029617309513],
        [80.27625274658209, 13.519029617309513],
        [80.27625274658209, 13.517081260681152],
        [80.27597045898449, 13.517083168029899],
        [80.27597045898449, 13.515694618225154],
        [80.2756958007813, 13.515694618225154],
        [80.2756958007813, 13.51513862609869],
        [80.27540588378918, 13.515140533447266],
        [80.27540588378918, 13.514580726623649],
        [80.27486419677751, 13.514582633972225],
        [80.27486419677751, 13.51430511474615],
        [80.27430725097673, 13.51430511474615],
        [80.27430725097673, 13.514582633972225],
        [80.27374267578142, 13.514580726623649],
        [80.27374267578142, 13.516531944274902],
        [80.27263641357428, 13.516531944274902],
        [80.27263641357428, 13.518749237060547],
        [80.27236175537104, 13.518749237060547],
        [80.27236175537104, 13.520421981811637],
        [80.2720870971682, 13.520415306091365],
        [80.2720870971682, 13.52236175537115],
        [80.27180480957048, 13.52236175537115],
        [80.27180480957048, 13.523471832275334],
        [80.2712478637697, 13.523471832275334],
        [80.2712478637697, 13.523750305175724],
        [80.27068328857439, 13.523750305175724],
        [80.27068328857439, 13.524029731750602],
        [80.2701416015625, 13.524029731750602],
        [80.2701416015625, 13.524310111999569],
        [80.26985931396513, 13.524310111999569],
        [80.26985931396513, 13.524029731750602],
        [80.26957702636724, 13.524029731750602],
        [80.26957702636724, 13.523750305175724],
        [80.269027709961, 13.523750305175724],
        [80.269027709961, 13.523471832275334],
        [80.26875305175798, 13.523471832275334],
        [80.26875305175798, 13.523193359374943],
        [80.26847076416038, 13.523193359374943],
        [80.26847076416038, 13.522917747497672],
        [80.2681961059572, 13.522919654846248],
        [80.2681961059572, 13.522640228271541],
        [80.2679061889649, 13.522640228271541],
        [80.2679061889649, 13.522358894348088],
        [80.26763916015642, 13.52236175537115],
        [80.26763916015642, 13.522084236145076],
        [80.26736450195341, 13.522084236145076],
        [80.26736450195341, 13.521804809570426],
        [80.2659759521485, 13.521804809570426],
        [80.2659759521485, 13.522084236145076],
        [80.26569366455072, 13.522084236145076],
        [80.26569366455072, 13.52236175537115],
        [80.26541900634788, 13.522358894348088],
        [80.26541900634788, 13.522640228271541],
        [80.26513671875017, 13.522640228271541],
        [80.26513671875017, 13.522919654846248],
        [80.26486206054693, 13.522917747497672],
        [80.26486206054693, 13.523193359374943],
        [80.26457977294939, 13.523193359374943],
        [80.26457977294939, 13.523471832275334],
        [80.26431274414091, 13.523471832275334],
        [80.26431274414091, 13.524310111999569],
        [80.26457977294939, 13.52430629730219],
        [80.26457977294939, 13.52458381652832],
        [80.26513671875017, 13.52458381652832],
        [80.26513671875017, 13.524862289428654],
        [80.2656860351562, 13.524860382080078],
        [80.2656860351562, 13.525139808654785],
        [80.26625061035168, 13.525139808654785],
        [80.26625061035168, 13.525420188903922],
        [80.26680755615263, 13.525416374206543],
        [80.26680755615263, 13.52569580078125],
        [80.26736450195341, 13.52569580078125],
        [80.26736450195341, 13.525972366333008],
        [80.26763916015642, 13.525968551635856],
        [80.26763916015642, 13.526251792907715],
        [80.2681961059572, 13.526251792907715],
        [80.2681961059572, 13.526530265808105],
        [80.26847076416038, 13.526530265808105],
        [80.26848602294928, 13.526809692382756],
        [80.269027709961, 13.526805877685604],
        [80.269027709961, 13.527084350585994],
        [80.26930236816418, 13.527084350585994],
        [80.26930236816418, 13.528194427490348],
        [80.2701416015625, 13.528194427490348],
        [80.2701416015625, 13.528471946716422],
        [80.27041625976591, 13.528471946716422],
        [80.27041625976591, 13.529029846191463],
        [80.2701416015625, 13.529027938842887],
        [80.2701416015625, 13.529862403869743],
        [80.26985931396513, 13.529862403869743],
        [80.26985931396513, 13.530415534973145],
        [80.26957702636724, 13.53040885925293],
        [80.26957702636724, 13.53125],
        [80.26930236816418, 13.53125],
        [80.26930236816418, 13.53181076049816],
        [80.269027709961, 13.53181076049816],
        [80.269027709961, 13.532360076904354],
        [80.26875305175798, 13.532360076904354],
        [80.26875305175798, 13.532920837402287],
        [80.26847076416038, 13.532920837402287],
        [80.26847076416038, 13.533481597900447],
        [80.2681961059572, 13.533481597900447],
        [80.2681961059572, 13.53403091430664],
        [80.26791381835943, 13.534028053283805],
        [80.26791381835943, 13.534582138061467],
        [80.26763916015642, 13.53458023071289],
        [80.26763916015642, 13.535421371460018],
        [80.26735687255888, 13.535421371460018],
        [80.26735687255888, 13.536540031433162],
        [80.26708221435547, 13.536540031433162],
        [80.26708221435547, 13.537640571594238],
        [80.26736450195341, 13.537638664245662],
        [80.26736450195341, 13.538194656372127],
        [80.26763916015642, 13.538191795349235],
        [80.26763916015642, 13.538750648498592],
        [80.2679061889649, 13.538750648498592],
        [80.2679061889649, 13.539860725402946],
        [80.26763916015642, 13.539860725402946],
        [80.26763916015642, 13.541251182556266],
        [80.26735687255888, 13.541251182556266],
        [80.26735687255888, 13.5426406860351],
        [80.26708221435547, 13.5426406860351],
        [80.26708221435547, 13.544029235839844],
        [80.26680755615263, 13.544029235839844],
        [80.26680755615263, 13.544860839843807],
        [80.26653289794928, 13.544860839843807],
        [80.26653289794928, 13.545419692993164],
        [80.26625061035168, 13.545416831970272],
        [80.26625061035168, 13.54597091674799],
        [80.2659759521485, 13.54597091674799],
        [80.2659759521485, 13.54653167724615],
        [80.26569366455072, 13.546526908874455],
        [80.26569366455072, 13.54708290100092],
        [80.26541900634788, 13.547080993652344],
        [80.26541900634788, 13.547641754150504],
        [80.26513671875017, 13.547638893127441],
        [80.26513671875017, 13.548195838928166],
        [80.26486968994169, 13.548191070556697],
        [80.26486968994169, 13.54875183105463],
        [80.26457977294939, 13.548748970031795],
        [80.26457977294939, 13.55152797698986],
        [80.26486968994169, 13.551521301269588],
        [80.26486968994169, 13.55263996124279],
        [80.26513671875017, 13.55263996124279],
        [80.26513671875017, 13.555419921874943],
        [80.26486206054693, 13.555418014526367],
        [80.26486206054693, 13.558471679687443],
        [80.26457977294939, 13.558469772338867],
        [80.26457977294939, 13.560140609741154],
        [80.26431274414091, 13.560140609741154],
        [80.26431274414091, 13.561249732971248],
        [80.26403045654297, 13.561249732971248],
        [80.26403045654297, 13.561531066894474],
        [80.2637481689456, 13.561529159545898],
        [80.2637481689456, 13.562082290649471],
        [80.26346588134766, 13.562080383300895],
        [80.26346588134766, 13.562359809875602],
        [80.26319122314464, 13.562359809875602],
        [80.26319122314464, 13.562641143798828],
        [80.26291656494146, 13.562641143798828],
        [80.26291656494146, 13.562920570373535],
        [80.26264190673822, 13.562916755676326],
        [80.26264190673822, 13.563472747802848],
        [80.26235961914085, 13.563461303710938],
        [80.26235961914085, 13.563751220703182],
        [80.26207733154314, 13.563751220703182],
        [80.26207733154314, 13.564030647277889],
        [80.2618026733399, 13.564030647277889],
        [80.2618026733399, 13.564311027526855],
        [80.26152801513689, 13.5643053054809],
        [80.26152801513689, 13.564584732055778],
        [80.26125335693388, 13.564584732055778],
        [80.26125335693388, 13.564861297607365],
        [80.26097106933594, 13.564861297607365],
        [80.26097106933594, 13.565140724182243],
        [80.2606964111331, 13.565140724182243],
        [80.2606964111331, 13.565419197082633],
        [80.26013946533214, 13.56541728973383],
        [80.26013946533214, 13.565694808959961],
        [80.25986480712896, 13.565694808959961],
        [80.25986480712896, 13.565973281860352],
        [80.2595825195312, 13.565971374511719],
        [80.2595825195312, 13.566250801086426],
        [80.25930786132835, 13.566250801086426],
        [80.25930786132835, 13.566527366638184],
        [80.25903320312517, 13.566519737243652],
        [80.25903320312517, 13.56681156158453],
        [80.2587509155274, 13.566804885864315],
        [80.2587509155274, 13.567083358764705],
        [80.25846862792986, 13.567081451416072],
        [80.25846862792986, 13.56736087799078],
        [80.25818634033209, 13.56736087799078],
        [80.25818634033209, 13.567641258239746],
        [80.25791931152344, 13.567641258239746],
        [80.25791931152344, 13.567921638488883],
        [80.25736236572266, 13.567916870117244],
        [80.25736236572266, 13.568195343017635],
        [80.25708007812528, 13.568195343017635],
        [80.25708007812528, 13.568470954895133],
        [80.25680541992193, 13.568470954895133],
        [80.25681304931646, 13.5687513351441],
        [80.25624847412115, 13.5687513351441],
        [80.25624847412115, 13.56903076171875],
        [80.25596618652338, 13.56903076171875],
        [80.25596618652338, 13.569310188293457],
        [80.25569152832054, 13.569305419921989],
        [80.25569152832054, 13.569582939148063],
        [80.25541687011736, 13.569582939148063],
        [80.25541687011736, 13.569861412048454],
        [80.25514221191435, 13.569861412048454],
        [80.25514221191435, 13.570140838623104],
        [80.2545928955081, 13.570140838623104],
        [80.2545928955081, 13.570419311523494],
        [80.25430297851562, 13.570417404174918],
        [80.25430297851562, 13.57069110870367],
        [80.25402832031278, 13.570694923400822],
        [80.25402832031278, 13.570973396301383],
        [80.25290679931635, 13.57097053527832],
        [80.25290679931635, 13.571250915527287],
        [80.25208282470709, 13.571250915527287],
        [80.25208282470709, 13.571529388427678],
        [80.25096893310575, 13.571529388427678],
        [80.25096893310575, 13.571809768676815],
        [80.25041961669933, 13.571805000305176],
        [80.25041961669933, 13.572083473205566],
        [80.24986267089838, 13.572083473205566],
        [80.24986267089838, 13.573471069335994],
        [80.25013732910162, 13.573471069335994],
        [80.25013732910162, 13.574860572815055],
        [80.25041961669933, 13.574860572815055],
        [80.25041961669933, 13.575141906738338],
        [80.2506942749024, 13.575139999389705],
        [80.2506942749024, 13.575693130493107],
        [80.25096893310575, 13.575693130493107],
        [80.25096893310575, 13.575970649719238],
        [80.25125122070312, 13.575970649719238],
        [80.25125122070312, 13.57763957977295],
        [80.25096893310575, 13.57763957977295],
        [80.25096893310575, 13.578749656677303],
        [80.25068664550781, 13.578749656677303],
        [80.25068664550781, 13.579310417175407],
        [80.25041961669933, 13.579305648803768],
        [80.25041961669933, 13.579584121704158],
        [80.25013732910162, 13.579579353332463],
        [80.25013732910162, 13.579859733581657],
        [80.24986267089838, 13.579859733581657],
        [80.24986267089838, 13.580419540405273],
        [80.24958038330101, 13.580415725708121],
        [80.24958038330101, 13.580694198608512],
        [80.24930572509783, 13.580694198608512],
        [80.24930572509783, 13.580971717834586],
        [80.24903106689459, 13.58096981048584],
        [80.24903106689459, 13.581250190734977],
        [80.24847412109403, 13.581250190734977],
        [80.24847412109403, 13.581527709961051],
        [80.2481918334961, 13.581521034240836],
        [80.2481918334961, 13.58181095123291],
        [80.2476425170899, 13.581806182861442],
        [80.2476425170899, 13.582083702087516],
        [80.2473602294923, 13.582083702087516],
        [80.2473602294923, 13.582362174987907],
        [80.24709320068365, 13.58236026763916],
        [80.24709320068365, 13.58263969421381],
        [80.24652862548851, 13.58263969421381],
        [80.24652862548851, 13.582921028137264],
        [80.2462463378908, 13.582921028137264],
        [80.2462463378908, 13.58403015136713],
        [80.24597167968756, 13.58403015136713],
        [80.24597167968756, 13.584310531616325],
        [80.24540710449224, 13.584310531616325],
        [80.24540710449224, 13.584590911865291],
        [80.2451400756836, 13.58458423614502],
        [80.2451400756836, 13.584859848022518],
        [80.24485778808622, 13.584859848022518],
        [80.24485778808622, 13.585140228271484],
        [80.24458312988281, 13.585140228271484],
        [80.24458312988281, 13.585420608520451],
        [80.2443084716798, 13.585420608520451],
        [80.2443084716798, 13.586250305175838],
        [80.24402618408209, 13.586250305175838],
        [80.24402618408209, 13.58708190917963],
        [80.24375915527338, 13.587080001831055],
        [80.24375915527338, 13.587650299072322],
        [80.2434692382813, 13.587650299072322],
        [80.2434692382813, 13.587921142578182],
        [80.24318695068354, 13.587921142578182],
        [80.24318695068354, 13.589310646057186],
        [80.24291992187506, 13.589310646057186],
        [80.24291992187506, 13.590141296386662],
        [80.24263763427751, 13.590139389038086],
        [80.24263763427751, 13.590972900390682],
        [80.2423629760745, 13.59097003936779],
        [80.2423629760745, 13.591809272766113],
        [80.24263763427751, 13.591805458068904],
        [80.24263763427751, 13.59236145019537],
        [80.2423629760745, 13.59236145019537],
        [80.2423629760745, 13.593473434448356],
        [80.24208068847673, 13.593473434448356],
        [80.24208068847673, 13.594582557678223],
        [80.24180603027372, 13.594580650329647],
        [80.24180603027372, 13.595421791076717],
        [80.24153137207031, 13.595417022705078],
        [80.24153137207031, 13.595694541931152],
        [80.24124908447294, 13.595694541931152],
        [80.24124908447294, 13.595973014831543],
        [80.24096679687506, 13.595971107482967],
        [80.24096679687506, 13.596820831298885],
        [80.24069213867199, 13.596804618835506],
        [80.24069213867199, 13.597083091735897],
        [80.2404174804688, 13.597083091735897],
        [80.2404174804688, 13.597360610961971],
        [80.2401428222658, 13.597360610961971],
        [80.2401428222658, 13.597640991210938],
        [80.2398605346682, 13.597640991210938],
        [80.2398605346682, 13.597920417785645],
        [80.23958587646501, 13.597916603088436],
        [80.23958587646501, 13.598472595214957],
        [80.23929595947271, 13.598470687866154],
        [80.23929595947271, 13.598751068115291],
        [80.23902893066423, 13.598751068115291],
        [80.23902893066423, 13.599028587341422],
        [80.23847198486328, 13.599028587341422],
        [80.23847198486328, 13.599309921264648],
        [80.23819732666044, 13.599309921264648],
        [80.23819732666044, 13.599590301513672],
        [80.23791503906256, 13.599582672119084],
        [80.23791503906256, 13.599861145019474],
        [80.23735809326178, 13.599861145019474],
        [80.23735809326178, 13.600421905517635],
        [80.23708343505854, 13.60041713714594],
        [80.23708343505854, 13.60069465637207],
        [80.2368087768557, 13.60069465637207],
        [80.2368087768557, 13.600971221923828],
        [80.23652648925798, 13.600971221923828],
        [80.23652648925798, 13.601251602172795],
        [80.23625183105474, 13.601251602172795],
        [80.23625183105474, 13.601531982421989],
        [80.2359695434572, 13.601531982421989],
        [80.2359695434572, 13.60181045532238],
        [80.23542022705078, 13.601804733276424],
        [80.23542022705078, 13.602083206176815],
        [80.23513793945341, 13.602081298828182],
        [80.23513793945341, 13.602361679077148],
        [80.23458099365246, 13.602361679077148],
        [80.23458099365246, 13.602640151977539],
        [80.23430633544928, 13.602629661560115],
        [80.23430633544928, 13.602919578552246],
        [80.2340240478515, 13.60291767120367],
        [80.2340240478515, 13.603193283081168],
        [80.23347473144548, 13.603193283081168],
        [80.23347473144548, 13.603470802307243],
        [80.23319244384771, 13.60346889495844],
        [80.23319244384771, 13.603750228881893],
        [80.23264312744169, 13.603750228881893],
        [80.23264312744169, 13.6040296554566],
        [80.23250579833979, 13.604027748108024],
        [80.23236083984375, 13.6040296554566],
        [80.23236083984375, 13.604310035705566],
        [80.23180389404297, 13.604306221008358],
        [80.23180389404297, 13.604583740234489],
        [80.230972290039, 13.604583740234489],
        [80.230972290039, 13.60486221313488],
        [80.23069763183616, 13.604859352111816],
        [80.23069763183616, 13.605139732360954],
        [80.23042297363298, 13.605139732360954],
        [80.23042297363298, 13.60542011260992],
        [80.22985839843767, 13.60541629791254],
        [80.22985839843767, 13.605693817138672],
        [80.22958374023466, 13.605693817138672],
        [80.22958374023466, 13.605972290039006],
        [80.22930908203125, 13.60596942901617],
        [80.22930908203125, 13.606249809265137],
        [80.22902679443388, 13.606249809265137],
        [80.22902679443388, 13.606530189514274],
        [80.22791290283214, 13.606530189514274],
        [80.22791290283214, 13.606809616088924],
        [80.22736358642595, 13.606805801391602],
        [80.22736358642595, 13.607084274291992],
        [80.22708129882835, 13.607078552246207],
        [80.22708129882835, 13.607361793518066],
        [80.22680664062517, 13.607361793518066],
        [80.22680664062517, 13.607640266418457],
        [80.22651672363287, 13.607640266418457],
        [80.22651672363287, 13.607919692993107],
        [80.22680664062517, 13.607915878295955],
        [80.22680664062517, 13.608194351196346],
        [80.22708129882835, 13.608192443847713],
        [80.22708129882835, 13.608760833740234],
        [80.22651672363287, 13.608760833740234],
        [80.22651672363287, 13.609032630920353],
        [80.2254180908206, 13.609027862548885],
        [80.2254180908206, 13.60986232757574],
        [80.22486114501964, 13.609860420227164],
        [80.22486114501964, 13.611820220947266],
        [80.22458648681646, 13.611820220947266],
        [80.22458648681646, 13.612640380859318],
        [80.22429656982416, 13.612640380859318],
        [80.22429656982416, 13.612920761108512],
        [80.22402954101585, 13.612915992736816],
        [80.22402954101585, 13.613471984863281],
        [80.22374725341814, 13.613470077514705],
        [80.22374725341814, 13.613750457763672],
        [80.2234725952149, 13.613750457763672],
        [80.2234725952149, 13.614030838012638],
        [80.22319793701189, 13.614030838012638],
        [80.22319793701189, 13.614311218261832],
        [80.22291564941435, 13.614306449890137],
        [80.22291564941435, 13.614863395690918],
        [80.2226333618164, 13.614859580993766],
        [80.2226333618164, 13.615140914916992],
        [80.22235870361357, 13.615138053894157],
        [80.22235870361357, 13.615973472595272],
        [80.22180938720714, 13.61596965789795],
        [80.22180175781261, 13.616250991821346],
        [80.22152709960943, 13.616250991821346],
        [80.22152709960943, 13.617081642150879],
        [80.2212524414062, 13.617079734802303],
        [80.2212524414062, 13.617920875549373],
        [80.22097015380882, 13.617917060851994],
        [80.22097015380882, 13.618473052978516],
        [80.22070312500017, 13.618470191955623],
        [80.22070312500017, 13.619311332702694],
        [80.22041320800787, 13.619304656982479],
        [80.22041320800787, 13.61958312988287],
        [80.22013854980486, 13.619580268859977],
        [80.22013854980486, 13.62014102935791],
        [80.21985626220732, 13.62014102935791],
        [80.21985626220732, 13.620701789856014],
        [80.2195816040039, 13.620695114135799],
        [80.2195816040039, 13.620972633361873],
        [80.21930694580107, 13.620970726013297],
        [80.21930694580107, 13.621251106262264],
        [80.2190170288086, 13.621251106262264],
        [80.2190170288086, 13.621530532836914],
        [80.21875000000011, 13.621530532836914],
        [80.21875000000011, 13.621811866760197],
        [80.21848297119146, 13.621811866760197],
        [80.21848297119146, 13.622640609741268],
        [80.21819305419916, 13.622638702392692],
        [80.21819305419916, 13.623473167419547],
        [80.21763610839861, 13.6234712600708],
        [80.21763610839861, 13.623760223388786],
        [80.21736145019537, 13.623760223388786],
        [80.21736145019537, 13.624032020568904],
        [80.21708679199236, 13.624032020568904],
        [80.21708679199236, 13.624309539794979],
        [80.21680450439482, 13.62430477142334],
        [80.21680450439482, 13.62458324432373],
        [80.2165298461914, 13.624581336975154],
        [80.2165298461914, 13.624860763549805],
        [80.21624755859403, 13.624860763549805],
        [80.21624755859403, 13.625139236450195],
        [80.21597290039062, 13.625139236450195],
        [80.21597290039062, 13.625419616699162],
        [80.21569824218761, 13.62541675567627],
        [80.21569824218761, 13.62569522857666],
        [80.2154159545899, 13.625691413879508],
        [80.2154159545899, 13.625970840454158],
        [80.21514129638666, 13.625970840454158],
        [80.21514129638666, 13.626249313354549],
        [80.21485900878912, 13.626249313354549],
        [80.21485900878912, 13.626531600952148],
        [80.21458435058611, 13.626526832580623],
        [80.21458435058611, 13.626805305481014],
        [80.21430206298851, 13.626805305481014],
        [80.21430206298851, 13.627082824707145],
        [80.21402740478533, 13.627082824707145],
        [80.21402740478533, 13.627361297607479],
        [80.21346282959001, 13.627361297607479],
        [80.21346282959001, 13.627641677856502],
        [80.21290588378906, 13.627641677856502],
        [80.21290588378906, 13.627920150756836],
        [80.2123641967774, 13.627915382385197],
        [80.2123641967774, 13.628195762634334],
        [80.21180725097662, 13.628195762634334],
        [80.21180725097662, 13.628471374511662],
        [80.21125030517601, 13.628471374511662],
        [80.21125030517601, 13.628748893737793],
        [80.21069335937506, 13.628748893737793],
        [80.21069335937506, 13.62903022766119],
        [80.21041870117205, 13.62903022766119],
        [80.21041870117205, 13.629309654235897],
        [80.2098617553711, 13.629304885864258],
        [80.2098617553711, 13.629584312438908],
        [80.2090301513673, 13.629570007324332],
        [80.2090301513673, 13.629861831664982],
        [80.2079162597658, 13.629861831664982],
        [80.2079162597658, 13.630140304565373],
        [80.20707702636747, 13.630140304565373],
        [80.20707702636747, 13.63041973114025],
        [80.20680236816406, 13.630417823791504],
        [80.20680236816406, 13.630693435669002],
        [80.20652770996122, 13.630693435669002],
        [80.20652770996122, 13.630971908569336],
        [80.20597076416027, 13.630971908569336],
        [80.20597076416027, 13.631250381469727],
        [80.20540618896496, 13.631250381469727],
        [80.20540618896496, 13.631529808044434],
        [80.20458984375006, 13.631529808044434],
        [80.20458984375006, 13.63181018829357],
        [80.20402526855497, 13.631806373596191],
        [80.20402526855497, 13.632083892822322],
        [80.20347595214872, 13.632083892822322],
        [80.20347595214872, 13.631806373596191],
        [80.20319366455078, 13.631806373596191],
        [80.20319366455078, 13.632083892822322],
        [80.20236206054682, 13.632083892822322],
        [80.20236206054682, 13.632362365722713],
        [80.20207977294928, 13.63236045837408],
        [80.20207977294928, 13.632641792297363],
        [80.20180511474626, 13.632639884948787],
        [80.2018127441408, 13.63318920135498],
        [80.20124816894548, 13.633195877075252],
        [80.20124816894548, 13.633472442627067],
        [80.20096588134771, 13.633468627929688],
        [80.20096588134771, 13.633749961853141],
        [80.20041656494169, 13.633749961853141],
        [80.20041656494169, 13.633468627929688],
        [80.19985961914091, 13.633468627929688],
        [80.19985961914091, 13.633749961853141],
        [80.19930267333996, 13.633749961853141],
        [80.19930267333996, 13.634030342102108],
        [80.19902801513678, 13.634030342102108],
        [80.19902801513678, 13.635140419006461],
        [80.19875335693376, 13.635140419006461],
        [80.19875335693376, 13.635419845581112],
        [80.19845581054693, 13.635419845581112],
        [80.19845581054693, 13.635701179504395],
        [80.19791412353521, 13.635693550109863],
        [80.19791412353521, 13.635972023010254],
        [80.19735717773466, 13.635972023010254],
        [80.19735717773466, 13.636249542236328],
        [80.19708251953125, 13.636249542236328],
        [80.19708251953125, 13.636529922485295],
        [80.19653320312506, 13.636529922485295],
        [80.19653320312506, 13.636811256408748],
        [80.19625091552746, 13.636805534362793],
        [80.19625091552746, 13.637084007263184],
        [80.1956939697265, 13.637084007263184],
        [80.1956939697265, 13.637361526489258],
        [80.19513702392595, 13.637359619140682],
        [80.19513702392595, 13.637639999389648],
        [80.19486236572271, 13.637639999389648],
        [80.19486236572271, 13.637921333313102],
        [80.19430541992216, 13.637915611267147],
        [80.19430541992216, 13.638194084167537],
        [80.19403076171875, 13.638194084167537],
        [80.19403076171875, 13.638750076294002],
        [80.19374847412138, 13.638740539550895],
        [80.19374847412138, 13.639310836791992],
        [80.19347381591803, 13.639304161071891],
        [80.19347381591803, 13.639583587646598],
        [80.19319152832043, 13.639583587646598],
        [80.19319152832043, 13.639860153198185],
        [80.19264984130876, 13.639860153198185],
        [80.19264984130876, 13.640139579773063],
        [80.19235992431663, 13.640139579773063],
        [80.19235992431663, 13.640420913696346],
        [80.19180297851568, 13.64041614532465],
        [80.19180297851568, 13.640972137451115],
        [80.19152832031267, 13.640970230102539],
        [80.19152832031267, 13.641249656677246],
        [80.19097137451172, 13.641249656677246],
        [80.19097137451172, 13.64180660247797],
        [80.19069671630888, 13.64180660247797],
        [80.19069671630888, 13.642082214355469],
        [80.1904067993164, 13.642080307006893],
        [80.1904067993164, 13.6423597335816],
        [80.19013977050793, 13.6423597335816],
        [80.19013977050793, 13.642641067504826],
        [80.18985748291021, 13.64263820648199],
        [80.18985748291021, 13.643195152282715],
        [80.18958282470697, 13.643190383911246],
        [80.18958282470697, 13.64375114440918],
        [80.18930816650413, 13.64375114440918],
        [80.18930816650413, 13.644030570983887],
        [80.18902587890642, 13.644030570983887],
        [80.18902587890642, 13.644310951233024],
        [80.18875122070318, 13.644310951233024],
        [80.18875122070318, 13.644869804382381],
        [80.18846893310564, 13.644869804382381],
        [80.18846893310564, 13.646812438964844],
        [80.18818664550787, 13.646812438964844],
        [80.18818664550787, 13.647931098938045],
        [80.18791961669922, 13.647916793823242],
        [80.18791961669922, 13.648195266723633],
        [80.18763732910185, 13.648189544677848],
        [80.18763732910185, 13.649312019348201],
        [80.18791961669922, 13.649312019348201],
        [80.18791961669922, 13.651529312133846],
        [80.18819427490263, 13.65152740478527],
        [80.18819427490263, 13.653751373290959],
        [80.18846893310564, 13.653751373290959],
        [80.18846893310564, 13.654582977294979],
        [80.18875122070318, 13.654582977294979],
        [80.18875122070318, 13.655693054199332],
        [80.18902587890642, 13.655691146850529],
        [80.18902587890642, 13.656530380249023],
        [80.18930816650413, 13.656530380249023],
        [80.18930816650413, 13.65792179107666],
        [80.18958282470697, 13.657917976379508],
        [80.18958282470697, 13.658193588256836],
        [80.18930816650413, 13.658193588256836],
        [80.18930816650413, 13.65847206115717],
        [80.18958282470697, 13.65847206115717],
        [80.18958282470697, 13.659584045410156],
        [80.18985748291021, 13.659579277038688],
        [80.18985748291021, 13.660140037536621],
        [80.19013977050793, 13.660140037536621],
        [80.19013977050793, 13.662362098693904],
        [80.18985748291021, 13.662360191345329],
        [80.18985748291021, 13.662639617919979],
        [80.19013977050793, 13.662637710571403],
        [80.19013977050793, 13.663193702697868],
        [80.18985748291021, 13.663191795349121],
        [80.18985748291021, 13.664311408996582],
        [80.18958282470697, 13.664311408996582],
        [80.18958282470697, 13.664861679077092],
        [80.18930816650413, 13.664859771728516],
        [80.18930816650413, 13.665971755981445],
        [80.18902587890642, 13.66596984863287],
        [80.18902587890642, 13.666810989379996],
        [80.18875122070318, 13.6668062210083],
        [80.18875122070318, 13.66736030578619],
        [80.18846893310564, 13.66736030578619],
        [80.18846893310564, 13.667640686035156],
        [80.18818664550787, 13.667640686035156],
        [80.18818664550787, 13.66792106628418],
        [80.18791961669922, 13.66792106628418],
        [80.18791961669922, 13.66874980926525],
        [80.18763732910185, 13.66874980926525],
        [80.18763732910185, 13.669031143188533],
        [80.18736267089844, 13.6690292358399],
        [80.18736267089844, 13.669582366943303],
        [80.18708038330107, 13.669580459594727],
        [80.18708038330107, 13.670141220092887],
        [80.18651580810558, 13.670141220092887],
        [80.18651580810558, 13.670420646667537],
        [80.18624877929693, 13.670416831970158],
        [80.18624877929693, 13.67097282409668],
        [80.18596649169916, 13.670969963073787],
        [80.18596649169916, 13.671251296997013],
        [80.18541717529314, 13.671251296997013],
        [80.18541717529314, 13.671530723571891],
        [80.18486022949236, 13.671528816223145],
        [80.18486022949236, 13.672082901001033],
        [80.1843032836914, 13.672080039978141],
        [80.1843032836914, 13.672640800476074],
        [80.18402862548857, 13.672640800476074],
        [80.18402862548857, 13.672921180725211],
        [80.18374633789068, 13.672917366027889],
        [80.18374633789068, 13.673473358154354],
        [80.18345642089872, 13.673471450805721],
        [80.18345642089872, 13.673750877380428],
        [80.18319702148443, 13.673750877380428],
        [80.18319702148443, 13.674311637878361],
        [80.18264007568382, 13.674306869506893],
        [80.18264007568382, 13.674583435058707],
        [80.18208312988287, 13.674580574035645],
        [80.18208312988287, 13.67486953735363],
        [80.18180847167986, 13.67486953735363],
        [80.18180847167986, 13.675141334533748],
        [80.18152618408232, 13.675141334533748],
        [80.18152618408232, 13.675421714782715],
        [80.18096923828153, 13.675416946411246],
        [80.18096923828153, 13.675695419311637],
        [80.18041992187511, 13.675689697265682],
        [80.18041992187511, 13.676251411438102],
        [80.17986297607416, 13.676251411438102],
        [80.17986297607416, 13.676530838012809],
        [80.17958068847679, 13.676530838012809],
        [80.17958068847679, 13.67681026458746],
        [80.17930603027361, 13.676804542541504],
        [80.17930603027361, 13.677083015441895],
        [80.17903137207037, 13.677083015441895],
        [80.17903137207037, 13.677916526794434],
        [80.17847442626982, 13.677916526794434],
        [80.17847442626982, 13.678473472595215],
        [80.17819213867188, 13.678470611572322],
        [80.17819213867188, 13.678750991821289],
        [80.17764282226568, 13.678750991821289],
        [80.17764282226568, 13.67902946472168],
        [80.17791748046903, 13.67902946472168],
        [80.17791748046903, 13.67931175231945],
        [80.1776351928711, 13.679305076599178],
        [80.1776351928711, 13.679582595825309],
        [80.17736053466808, 13.679580688476506],
        [80.17736053466808, 13.680142402648926],
        [80.1770858764649, 13.680142402648926],
        [80.1770858764649, 13.680421829223633],
        [80.17680358886713, 13.680417060852164],
        [80.17680358886713, 13.680694580078239],
        [80.17652893066429, 13.680694580078239],
        [80.17652893066429, 13.680971145629826],
        [80.17624664306658, 13.680971145629826],
        [80.17624664306658, 13.68125152587902],
        [80.17597198486334, 13.68125152587902],
        [80.17597198486334, 13.681531906127987],
        [80.17514038085938, 13.681531906127987],
        [80.17514038085938, 13.681810379028377],
        [80.1745834350586, 13.681804656982422],
        [80.1745834350586, 13.682083129882812],
        [80.17402648925787, 13.68208122253418],
        [80.17402648925787, 13.682360649108887],
        [80.17375183105463, 13.682360649108887],
        [80.17375183105463, 13.683193206787166],
        [80.17346954345709, 13.683193206787166],
        [80.17346954345709, 13.683473587036133],
        [80.17292022705084, 13.68347072601324],
        [80.17292022705084, 13.683749198913631],
        [80.1726379394533, 13.683749198913631],
        [80.1726379394533, 13.684862136840877],
        [80.17236328125028, 13.684859275817985],
        [80.17236328125028, 13.685420036315918],
        [80.17208099365251, 13.68541622161871],
        [80.17208099365251, 13.68597221374523],
        [80.1718063354495, 13.685969352722168],
        [80.1718063354495, 13.686249732971305],
        [80.17151641845703, 13.686249732971305],
        [80.17151641845703, 13.687359809875488],
        [80.1718063354495, 13.687359809875488],
        [80.1718063354495, 13.687919616699276],
        [80.17151641845703, 13.687919616699276],
        [80.17151641845703, 13.688471794128418],
        [80.17124938964872, 13.688471794128418],
        [80.17124938964872, 13.688750267028809],
        [80.17097473144537, 13.688750267028809],
        [80.17097473144537, 13.689583778381348],
        [80.17041778564459, 13.689583778381348],
        [80.17041778564459, 13.689862251281738],
        [80.16986083984398, 13.689862251281738],
        [80.16986083984398, 13.689583778381348],
        [80.1695861816408, 13.689583778381348],
        [80.1695861816408, 13.689306259155273],
        [80.1692962646485, 13.689311027526912],
        [80.1692962646485, 13.688192367553711],
        [80.16902923584001, 13.688194274902344],
        [80.16902923584001, 13.687359809875488],
        [80.16874694824247, 13.687359809875488],
        [80.16874694824247, 13.686809539794922],
        [80.16652679443376, 13.68680572509777],
        [80.16652679443376, 13.68708419799816],
        [80.16625213623053, 13.687079429626522],
        [80.16625213623053, 13.687640190124625],
        [80.16596984863298, 13.687640190124625],
        [80.16596984863298, 13.688194274902344],
        [80.16569519042997, 13.688194274902344],
        [80.1657028198245, 13.688750267028809],
        [80.16485595703125, 13.688750267028809],
        [80.16485595703125, 13.689029693603459],
        [80.16430664062506, 13.689029693603459],
        [80.16430664062506, 13.689311027526912],
        [80.16402435302729, 13.689306259155273],
        [80.16402435302729, 13.689583778381348],
        [80.16374969482445, 13.689583778381348],
        [80.16374969482445, 13.689862251281738],
        [80.1634826660158, 13.689850807189998],
        [80.1634826660158, 13.690141677856445],
        [80.1631927490235, 13.690141677856445],
        [80.1631927490235, 13.690421104431266],
        [80.16291809082048, 13.690416336059627],
        [80.16291809082048, 13.690693855285701],
        [80.16263580322294, 13.690693855285701],
        [80.16263580322294, 13.690972328186092],
        [80.16234588623064, 13.690970420837516],
        [80.16234588623064, 13.691530227661133],
        [80.16208648681669, 13.691530227661133],
        [80.16208648681669, 13.691810607910156],
        [80.16179656982422, 13.691810607910156],
        [80.16179656982422, 13.69431114196783],
        [80.16152954101574, 13.694306373596135],
        [80.16152954101574, 13.694581985473633],
        [80.16124725341803, 13.694581985473633],
        [80.16124725341803, 13.694863319396916],
        [80.16097259521479, 13.694860458374023],
        [80.16097259521479, 13.695142745971793],
        [80.16069793701195, 13.695142745971793],
        [80.16069793701195, 13.695421218872184],
        [80.16041564941423, 13.695416450500488],
        [80.16042327880876, 13.69569015502941],
        [80.160140991211, 13.695700645446834],
        [80.160140991211, 13.696250915527344],
        [80.15985870361345, 13.696250915527344],
        [80.15985870361345, 13.696810722351188],
        [80.15957641601568, 13.696810722351188],
        [80.15957641601568, 13.697360038757438],
        [80.15930938720703, 13.697360038757438],
        [80.15928649902361, 13.697641372680721],
        [80.15902709960966, 13.697641372680721],
        [80.15902709960966, 13.697920799255371],
        [80.15847015380888, 13.69791698455822],
        [80.15847015380888, 13.698195457458553],
        [80.15819549560553, 13.698195457458553],
        [80.15819549560553, 13.698472976684684],
        [80.15791320800793, 13.698470115661621],
        [80.15791320800793, 13.699040412902832],
        [80.15763854980474, 13.699040412902832],
        [80.15763854980474, 13.699311256408691],
        [80.15736389160173, 13.699304580688477],
        [80.15736389160173, 13.699860572814941],
        [80.15708160400413, 13.699860572814941],
        [80.15708160400413, 13.699950218200797],
        [80.15708160400413, 13.700140953064079],
        [80.15680694580095, 13.700140953064079],
        [80.15680694580095, 13.700421333313045],
        [80.15652465820318, 13.700416564941406],
        [80.15652465820318, 13.700695037841797],
        [80.15625000000017, 13.700695037841797],
        [80.15625000000017, 13.700972557067871],
        [80.15598297119169, 13.700970649719295],
        [80.15598297119169, 13.701251029968262],
        [80.15569305419922, 13.701251029968262],
        [80.15569305419922, 13.701531410217399],
        [80.15541839599638, 13.701531410217399],
        [80.15541839599638, 13.701811790466365],
        [80.1551361083985, 13.70180511474615],
        [80.1551361083985, 13.702082633972225],
        [80.15486145019543, 13.702080726623649],
        [80.15486145019543, 13.702640533447266],
        [80.15458679199224, 13.702640533447266],
        [80.15458679199224, 13.702921867370549],
        [80.15402984619163, 13.70291709899908],
        [80.15402984619163, 13.703194618225154],
        [80.15347290039068, 13.703194618225154],
        [80.15347290039068, 13.70291709899908],
        [80.152084350586, 13.70291709899908],
        [80.152084350586, 13.703194618225154],
        [80.15152740478521, 13.703194618225154],
        [80.15152740478521, 13.70263862609869],
        [80.14930725097685, 13.702640533447266],
        [80.14930725097685, 13.702921867370549],
        [80.1487503051759, 13.70291709899908],
        [80.1487503051759, 13.703194618225154],
        [80.14848327636724, 13.703190803527832],
        [80.14848327636724, 13.70375061035162],
        [80.14819335937494, 13.70375061035162],
        [80.14819335937494, 13.704031944274902],
        [80.1479187011721, 13.704031944274902],
        [80.1479187011721, 13.704310417175293],
        [80.14763641357439, 13.704310417175293],
        [80.14763641357439, 13.704031944274902],
        [80.14734649658226, 13.704031944274902],
        [80.14734649658226, 13.70375061035162],
        [80.14708709716814, 13.70375061035162],
        [80.14708709716814, 13.702640533447266],
        [80.14679718017584, 13.702640533447266],
        [80.14679718017584, 13.702080726623649],
        [80.14653015136719, 13.702082633972225],
        [80.14653015136719, 13.70152664184576],
        [80.1459732055664, 13.701531410217399],
        [80.1459732055664, 13.701251029968262],
        [80.14541625976568, 13.701251029968262],
        [80.14541625976568, 13.700970649719295],
        [80.14457702636713, 13.700970649719295],
        [80.14457702636713, 13.701251029968262],
        [80.14430999755865, 13.701251029968262],
        [80.14430999755865, 13.701531410217399],
        [80.14402770996111, 13.70152664184576],
        [80.14402770996111, 13.702082633972225],
        [80.1437530517581, 13.702080726623649],
        [80.1437530517581, 13.702640533447266],
        [80.14347076416033, 13.70263862609869],
        [80.14347076416033, 13.703194618225154],
        [80.14319610595732, 13.703190803527832],
        [80.14319610595732, 13.70375061035162],
        [80.14263916015653, 13.70375061035162],
        [80.14263916015653, 13.704031944274902],
        [80.14235687255865, 13.704031944274902],
        [80.14235687255865, 13.704310417175293],
        [80.14208221435558, 13.704304695129338],
        [80.14208221435558, 13.704860687255803],
        [80.14235687255865, 13.704850196838379],
        [80.14235687255865, 13.70541858673107],
        [80.14208221435558, 13.70541858673107],
        [80.14208221435558, 13.706811904907283],
        [80.1418075561524, 13.706805229187012],
        [80.1418075561524, 13.707361221313477],
        [80.14125061035179, 13.707361221313477],
        [80.14125061035179, 13.707921981811637],
        [80.14097595214861, 13.707915306091365],
        [80.14097595214861, 13.708194732666072],
        [80.14069366455084, 13.708194732666072],
        [80.14069366455084, 13.70847129821783],
        [80.14041900634783, 13.70847129821783],
        [80.14041900634783, 13.708751678466854],
        [80.14013671875028, 13.708748817443961],
        [80.14013671875028, 13.709861755371207],
        [80.13986206054688, 13.709858894348145],
        [80.13986206054688, 13.710419654846248],
        [80.14013671875028, 13.710417747497672],
        [80.14013671875028, 13.710693359375],
        [80.14097595214861, 13.710693359375],
        [80.14097595214861, 13.710417747497672],
        [80.14125061035179, 13.710419654846248],
        [80.14125061035179, 13.709858894348145],
        [80.14235687255865, 13.709858894348145],
        [80.14235687255865, 13.710419654846248],
        [80.14208221435558, 13.710419654846248],
        [80.14208221435558, 13.711250305175724],
        [80.1418075561524, 13.711250305175724],
        [80.1418075561524, 13.711529731750602],
        [80.14154052734392, 13.711529731750602],
        [80.14154052734392, 13.712639808654785],
        [80.14125061035179, 13.712639808654785],
        [80.14125061035179, 13.712920188903922],
        [80.14097595214861, 13.712916374206543],
        [80.14097595214861, 13.71319580078125],
        [80.14041900634783, 13.71319580078125],
        [80.14041900634783, 13.713472366333065],
        [80.1395797729495, 13.713472366333065],
        [80.1395797729495, 13.712916374206543],
        [80.13903045654308, 13.712916374206543],
        [80.13903045654308, 13.71319580078125],
        [80.13817596435553, 13.713191032409782],
        [80.13817596435553, 13.713751792907715],
        [80.13791656494158, 13.713749885559139],
        [80.13791656494158, 13.71402835845953],
        [80.13764190673834, 13.71402835845953],
        [80.13764190673834, 13.714584350585994],
        [80.13708496093778, 13.714584350585994],
        [80.13709259033232, 13.714861869812069],
        [80.13680267333984, 13.714861869812069],
        [80.13680267333984, 13.715971946716422],
        [80.136528015137, 13.715971946716422],
        [80.136528015137, 13.716811180114746],
        [80.13625335693365, 13.716806411743278],
        [80.13625335693365, 13.717083930969181],
        [80.13597106933605, 13.717083930969181],
        [80.13597106933605, 13.717600822448844],
        [80.13597106933605, 13.71875],
        [80.136528015137, 13.71875],
        [80.136528015137, 13.718469619751033],
        [80.13680267333984, 13.71847152709961],
        [80.13680267333984, 13.718194007873535],
        [80.13708496093778, 13.718194007873535],
        [80.13708496093778, 13.717915534973145],
        [80.1373596191408, 13.7179212570191],
        [80.1373596191408, 13.717639923095646],
        [80.13764190673834, 13.717639923095646],
        [80.13764190673834, 13.71735954284668],
        [80.13791656494158, 13.717362403869743],
        [80.13791656494158, 13.717083930969181],
        [80.13819122314476, 13.717083930969181],
        [80.13819122314476, 13.716806411743278],
        [80.1384658813476, 13.716811180114746],
        [80.1384658813476, 13.716529846191463],
        [80.13874816894537, 13.716529846191463],
        [80.13874816894537, 13.716250419616813],
        [80.13903045654308, 13.716250419616813],
        [80.13903045654308, 13.715691566467285],
        [80.13930511474615, 13.715694427490348],
        [80.13930511474615, 13.715138435363883],
        [80.1395797729495, 13.71514034271246],
        [80.1395797729495, 13.714582443237418],
        [80.13986206054688, 13.714584350585994],
        [80.13986206054688, 13.714305877685604],
        [80.14041900634783, 13.714309692382756],
        [80.14041900634783, 13.715422630310002],
        [80.14013671875028, 13.715415954589957],
        [80.14013671875028, 13.717083930969181],
        [80.14041900634783, 13.717083930969181],
        [80.14041900634783, 13.718194007873535],
        [80.1406860351563, 13.718189239502067],
        [80.1406860351563, 13.71875],
        [80.14235687255865, 13.71875],
        [80.14235687255865, 13.718469619751033],
        [80.14402770996111, 13.718469619751033],
        [80.14402770996111, 13.71875],
        [80.14430999755865, 13.71875],
        [80.14430999755865, 13.719030380248967],
        [80.14457702636713, 13.719030380248967],
        [80.14457702636713, 13.71931076049816],
        [80.14514160156244, 13.719305992126465],
        [80.14514160156244, 13.719584465026855],
        [80.14541625976568, 13.719579696655387],
        [80.14541625976568, 13.719860076904354],
        [80.14569091796903, 13.719860076904354],
        [80.14569091796903, 13.72015190124523],
        [80.1459732055664, 13.72015190124523],
        [80.1459732055664, 13.720420837402344],
        [80.14653015136719, 13.720416069030819],
        [80.14653015136719, 13.72069358825695],
        [80.14708709716814, 13.72069358825695],
        [80.14708709716814, 13.72097206115734],
        [80.14679718017584, 13.720970153808537],
        [80.14679718017584, 13.721530914306697],
        [80.14653015136719, 13.721528053283805],
        [80.14653015136719, 13.722082138061467],
        [80.14624786376982, 13.72208023071289],
        [80.14624786376982, 13.722359657287598],
        [80.14653015136719, 13.722359657287598],
        [80.14653015136719, 13.722921371460018],
        [80.1468048095706, 13.722915649414062],
        [80.1468048095706, 13.723472595214844],
        [80.14708709716814, 13.723469734191951],
        [80.14708709716814, 13.724310874939022],
        [80.14541625976568, 13.724310874939022],
        [80.14541625976568, 13.724031448364371],
        [80.14402770996111, 13.724031448364371],
        [80.14402770996111, 13.723751068115178],
        [80.14229583740251, 13.723751068115178],
        [80.14208221435558, 13.723751068115178],
        [80.14208221435558, 13.723469734191951],
        [80.1418075561524, 13.723472595214844],
        [80.1418075561524, 13.723194122314453],
        [80.14125061035179, 13.723194122314453],
        [80.14125061035179, 13.722915649414062],
        [80.14069366455084, 13.722915649414062],
        [80.14069366455084, 13.723472595214844],
        [80.14041900634783, 13.723469734191951],
        [80.14041900634783, 13.724031448364371],
        [80.14013671875028, 13.724031448364371],
        [80.14013671875028, 13.724310874939022],
        [80.14097595214861, 13.724304199218807],
        [80.14097595214861, 13.724584579467773],
        [80.14154052734392, 13.724577903747502],
        [80.14154052734392, 13.725140571594238],
        [80.14097595214861, 13.725140571594238],
        [80.14097595214861, 13.725420951843375],
        [80.1395797729495, 13.725417137146053],
        [80.1395797729495, 13.725973129272518],
        [80.13931274414068, 13.72596073150629],
        [80.13931274414068, 13.726250648498592],
        [80.13874816894537, 13.726250648498592],
        [80.13874816894537, 13.72596073150629],
        [80.13819122314476, 13.725973129272518],
        [80.13819122314476, 13.725694656372127],
        [80.1373596191408, 13.725694656372127],
        [80.1373596191408, 13.725973129272518],
        [80.136528015137, 13.72596073150629],
        [80.136528015137, 13.726250648498592],
        [80.1345825195313, 13.726250648498592],
        [80.1345825195313, 13.72596073150629],
        [80.13291931152355, 13.725973129272518],
        [80.13291931152355, 13.725694656372127],
        [80.13240051269548, 13.725694656372127],
        [80.1315307617188, 13.725694656372127],
        [80.1315307617188, 13.724584579467773],
        [80.13124847412126, 13.724584579467773],
        [80.13124847412126, 13.723751068115178],
        [80.12985992431669, 13.723751068115178],
        [80.12985992431669, 13.722359657287598],
        [80.13014221191406, 13.722359657287598],
        [80.13014221191406, 13.72208023071289],
        [80.13041687011747, 13.722082138061467],
        [80.13041687011747, 13.72180557250988],
        [80.13053894042974, 13.72180557250988],
        [80.13069152832048, 13.721811294555664],
        [80.13069152832048, 13.717061042785758],
        [80.13069152832048, 13.713472366333065],
        [80.13097381591825, 13.713472366333065],
        [80.13097381591825, 13.712916374206543],
        [80.13124847412126, 13.712920188903922],
        [80.13124847412126, 13.712639808654785],
        [80.1315307617188, 13.712639808654785],
        [80.1315307617188, 13.712080955505428],
        [80.13263702392584, 13.71208381652832],
        [80.13263702392584, 13.71180629730219],
        [80.13318634033203, 13.711810111999569],
        [80.13318634033203, 13.711529731750602],
        [80.13569641113287, 13.711527824401855],
        [80.13569641113287, 13.71319580078125],
        [80.13597106933605, 13.71319580078125],
        [80.13597106933605, 13.713472366333065],
        [80.13625335693365, 13.713468551635856],
        [80.13625335693365, 13.713751792907715],
        [80.136528015137, 13.713751792907715],
        [80.136528015137, 13.713191032409782],
        [80.13680267333984, 13.71319580078125],
        [80.13680267333984, 13.712916374206543],
        [80.13709259033232, 13.712920188903922],
        [80.13709259033232, 13.712639808654785],
        [80.1373596191408, 13.712639808654785],
        [80.1373596191408, 13.712080955505428],
        [80.13764190673834, 13.71208381652832],
        [80.13764190673834, 13.711527824401855],
        [80.13791656494158, 13.711529731750602],
        [80.13791656494158, 13.710419654846248],
        [80.13817596435553, 13.710419654846248],
        [80.13817596435553, 13.708751678466854],
        [80.1384658813476, 13.708751678466854],
        [80.1384658813476, 13.707921981811637],
        [80.13817596435553, 13.707921981811637],
        [80.13817596435553, 13.706811904907283],
        [80.1384658813476, 13.706811904907283],
        [80.1384658813476, 13.706249237060547],
        [80.13874816894537, 13.706249237060547],
        [80.13874816894537, 13.705970764160156],
        [80.13930511474615, 13.705970764160156],
        [80.13930511474615, 13.705695152282658],
        [80.1395797729495, 13.705695152282658],
        [80.1395797729495, 13.704583168029899],
        [80.13986206054688, 13.704583168029899],
        [80.13986206054688, 13.702361106872615],
        [80.14013671875028, 13.702361106872615],
        [80.14013671875028, 13.700695037841797],
        [80.14041900634783, 13.700695037841797],
        [80.14041900634783, 13.700416564941406],
        [80.14125061035179, 13.700416564941406],
        [80.14125061035179, 13.700972557067871],
        [80.14154052734392, 13.700970649719295],
        [80.14154052734392, 13.701531410217399],
        [80.1418075561524, 13.70152664184576],
        [80.1418075561524, 13.702082633972225],
        [80.14291381835938, 13.702082633972225],
        [80.14291381835938, 13.699028968811149],
        [80.14263916015653, 13.699040412902832],
        [80.14263916015653, 13.698751449585075],
        [80.14235687255865, 13.698751449585075],
        [80.14235687255865, 13.698470115661621],
        [80.14208221435558, 13.698472976684684],
        [80.14208221435558, 13.69791698455822],
        [80.1418075561524, 13.697920799255371],
        [80.1418075561524, 13.695700645446834],
        [80.14154052734392, 13.69569015502941],
        [80.14152526855463, 13.6940279006958],
        [80.14125061035179, 13.694030761718864],
        [80.14125061035179, 13.693189620971737],
        [80.14097595214861, 13.693194389343205],
        [80.14097595214861, 13.692640304565487],
        [80.1406860351563, 13.692640304565487],
        [80.1406860351563, 13.692079544067383],
        [80.14041900634783, 13.692084312439022],
        [80.14041900634783, 13.691805839538631],
        [80.14013671875028, 13.691810607910156],
        [80.14013671875028, 13.691249847412166],
        [80.13986206054688, 13.691249847412166],
        [80.13986206054688, 13.690691947937125],
        [80.14013671875028, 13.690693855285701],
        [80.14013671875028, 13.690416336059627],
        [80.14041900634783, 13.690421104431266],
        [80.14041900634783, 13.689850807189998],
        [80.14069366455084, 13.689862251281738],
        [80.14069366455084, 13.689027786254883],
        [80.14125061035179, 13.689029693603459],
        [80.14125061035179, 13.688192367553711],
        [80.14097595214861, 13.688194274902344],
        [80.14097595214861, 13.687640190124625],
        [80.14125061035179, 13.687640190124625],
        [80.14125061035179, 13.686249732971305],
        [80.14154052734392, 13.686249732971305],
        [80.14154052734392, 13.684309959411735],
        [80.1418075561524, 13.684309959411735],
        [80.1418075561524, 13.68292045593273],
        [80.14208221435558, 13.68292045593273],
        [80.14208221435558, 13.682588577270565],
        [80.14208221435558, 13.681804656982422],
        [80.14235687255865, 13.681810379028377],
        [80.14235687255865, 13.680421829223633],
        [80.14263916015653, 13.680421829223633],
        [80.14263916015653, 13.671251296997013],
        [80.14290618896484, 13.671251296997013],
        [80.14290618896484, 13.669310569763184],
        [80.14347076416033, 13.669310569763184],
        [80.14347076416033, 13.668191909789982],
        [80.1437530517581, 13.668193817138786],
        [80.1437530517581, 13.666250228881836],
        [80.14402770996111, 13.666250228881836],
        [80.14402770996111, 13.665140151977482],
        [80.14430999755865, 13.665140151977482],
        [80.14430999755865, 13.664861679077092],
        [80.14457702636713, 13.664861679077092],
        [80.14457702636713, 13.664580345153865],
        [80.1448593139649, 13.664584159851017],
        [80.1448593139649, 13.664305686950627],
        [80.14514160156244, 13.664311408996582],
        [80.14514160156244, 13.664030075073299],
        [80.14541625976568, 13.664030075073299],
        [80.14541625976568, 13.663749694824162],
        [80.14569091796903, 13.663749694824162],
        [80.14569091796903, 13.663191795349121],
        [80.1459732055664, 13.663193702697868],
        [80.1459732055664, 13.66291809082037],
        [80.14624786376982, 13.66292953491211],
        [80.14624786376982, 13.662639617919979],
        [80.14653015136719, 13.662639617919979],
        [80.14653015136719, 13.662360191345329],
        [80.1468048095706, 13.662362098693904],
        [80.1468048095706, 13.66180610656744],
        [80.14708709716814, 13.661810874939079],
        [80.14708709716814, 13.660689353942814],
        [80.14736175537115, 13.66069412231451],
        [80.14736175537115, 13.659584045410156],
        [80.14763641357439, 13.659584045410156],
        [80.14763641357439, 13.658193588256836],
        [80.14736175537115, 13.658193588256836],
        [80.14736175537115, 13.655970573425407],
        [80.14708709716814, 13.655970573425407],
        [80.14708709716814, 13.654582977294979],
        [80.1468048095706, 13.654582977294979],
        [80.1468048095706, 13.653751373290959],
        [80.14653015136719, 13.653751373290959],
        [80.14653015136719, 13.652916908264103],
        [80.14624786376982, 13.652919769287166],
        [80.14624786376982, 13.652360916137638],
        [80.1459732055664, 13.652360916137638],
        [80.1459732055664, 13.651529312133846],
        [80.14569091796903, 13.651529312133846],
        [80.14569091796903, 13.650680541992244],
        [80.1459732055664, 13.65069484710699],
        [80.1459732055664, 13.649861335754451],
        [80.14624786376982, 13.649861335754451],
        [80.14624786376982, 13.649026870727596],
        [80.14653015136719, 13.649030685424748],
        [80.14653015136719, 13.648470878601131],
        [80.1468048095706, 13.648470878601131],
        [80.1468048095706, 13.647360801696777],
        [80.14708709716814, 13.647360801696777],
        [80.14708709716814, 13.646250724792594],
        [80.14679718017584, 13.646250724792594],
        [80.14679718017584, 13.645971298217717],
        [80.14653015136719, 13.64597320556652],
        [80.14653015136719, 13.64569473266613],
        [80.1459732055664, 13.64569473266613],
        [80.1459732055664, 13.645417213440055],
        [80.14569091796903, 13.645421028137207],
        [80.14569091796903, 13.64514064788824],
        [80.14541625976568, 13.64514064788824],
        [80.14541625976568, 13.644869804382381],
        [80.14514160156244, 13.644869804382381],
        [80.14514160156244, 13.6445827484132],
        [80.14402770996111, 13.644584655761776],
        [80.14402770996111, 13.640693664550781],
        [80.1437530517581, 13.640693664550781],
        [80.1437530517581, 13.639860153198185],
        [80.14402770996111, 13.639860153198185],
        [80.14402770996111, 13.639583587646598],
        [80.1437530517581, 13.639583587646598],
        [80.1437530517581, 13.637638092041072],
        [80.14319610595732, 13.637639999389648],
        [80.14319610595732, 13.635140419006461],
        [80.1437530517581, 13.635140419006461],
        [80.1437530517581, 13.63486194610607],
        [80.14430999755865, 13.63486194610607],
        [80.14430236816429, 13.633472442627067],
        [80.14402770996111, 13.633472442627067],
        [80.14402770996111, 13.633193969726676],
        [80.14430236816429, 13.633193969726676],
        [80.14430236816429, 13.632916450500545],
        [80.14457702636713, 13.632931709289664],
        [80.14457702636713, 13.63208103179943],
        [80.1448593139649, 13.632083892822322],
        [80.1448593139649, 13.629861831664982],
        [80.14457702636713, 13.629861831664982],
        [80.14457702636713, 13.62903022766119],
        [80.14430999755865, 13.62903022766119],
        [80.14430999755865, 13.627641677856502],
        [80.14402770996111, 13.627641677856502],
        [80.14402770996111, 13.627080917358342],
        [80.1437530517581, 13.627082824707145],
        [80.1437530517581, 13.626526832580623],
        [80.14347076416033, 13.626531600952148],
        [80.14347076416033, 13.625139236450195],
        [80.14319610595732, 13.625139236450195],
        [80.14319610595732, 13.624032020568904],
        [80.14290618896484, 13.624032020568904],
        [80.14290618896484, 13.623190879821834],
        [80.14263916015653, 13.623194694518986],
        [80.14263916015653, 13.622917175293082],
        [80.14235687255865, 13.62292194366455],
        [80.14235687255865, 13.622361183166618],
        [80.14208221435558, 13.622361183166618],
        [80.14208221435558, 13.622082710266227],
        [80.1418075561524, 13.622082710266227],
        [80.1418075561524, 13.621805191040153],
        [80.14154052734392, 13.621811866760197],
        [80.14154052734392, 13.621251106262264],
        [80.14125061035179, 13.621251106262264],
        [80.14125061035179, 13.620970726013297],
        [80.14097595214861, 13.620972633361873],
        [80.14097595214861, 13.61902904510498],
        [80.1406860351563, 13.619030952453727],
        [80.1406860351563, 13.61875057220459],
        [80.14041900634783, 13.61875057220459],
        [80.14041900634783, 13.618187904357853],
        [80.14013671875028, 13.618194580078125],
        [80.14013671875028, 13.61763858795166],
        [80.13986206054688, 13.61763858795166],
        [80.13986206054688, 13.616529464721737],
        [80.1395797729495, 13.61653137207037],
        [80.1395797729495, 13.615690231323242],
        [80.13930511474615, 13.615694046020621],
        [80.13930511474615, 13.614863395690918],
        [80.13903045654308, 13.614863395690918],
        [80.13903045654308, 13.614306449890137],
        [80.13874816894537, 13.614311218261832],
        [80.13874816894537, 13.613470077514705],
        [80.13847351074213, 13.613471984863281],
        [80.13847351074213, 13.612915992736816],
        [80.13817596435553, 13.612920761108512],
        [80.13817596435553, 13.612640380859318],
        [80.13791656494158, 13.612640380859318],
        [80.13791656494158, 13.612360000610352],
        [80.13764190673834, 13.612360000610352],
        [80.13764190673834, 13.612079620361385],
        [80.13708496093778, 13.612084388732853],
        [80.13708496093778, 13.611805915832463],
        [80.13680267333984, 13.611820220947266],
        [80.13680267333984, 13.611531257629451],
        [80.136528015137, 13.611531257629451],
        [80.136528015137, 13.611249923705998],
        [80.13625335693365, 13.611249923705998],
        [80.13625335693365, 13.610980987548942],
        [80.13513946533226, 13.610969543457031],
        [80.13513946533226, 13.610693931579704],
        [80.13402557373075, 13.610693931579704],
        [80.13402557373075, 13.61041641235363],
        [80.13291931152355, 13.610421180725098],
        [80.13291931152355, 13.610139846801815],
        [80.13069152832048, 13.610139846801815],
        [80.13069152832048, 13.610421180725098],
        [80.13014221191406, 13.61041641235363],
        [80.13014221191406, 13.610693931579704],
        [80.12985992431669, 13.610693931579704],
        [80.12985992431669, 13.610972404480094],
        [80.12930297851574, 13.610972404480094],
        [80.12930297851574, 13.610693931579704],
        [80.12763977050798, 13.610693931579704],
        [80.12763977050798, 13.610972404480094],
        [80.12708282470703, 13.610969543457031],
        [80.12708282470703, 13.611249923705998],
        [80.12680816650419, 13.611249923705998],
        [80.12680816650419, 13.611531257629451],
        [80.12648010253923, 13.611528396606559],
        [80.12623596191435, 13.611531257629451],
        [80.12623596191435, 13.611820220947266],
        [80.12596893310553, 13.611820220947266],
        [80.12596893310553, 13.612360000610352],
        [80.12568664550776, 13.612360000610352],
        [80.12568664550776, 13.612920761108512],
        [80.12541961669945, 13.612915992736816],
        [80.12541961669945, 13.613471984863281],
        [80.12513732910173, 13.613470077514705],
        [80.12513732910173, 13.613750457763672],
        [80.12431335449247, 13.613750457763672],
        [80.12431335449247, 13.614030838012638],
        [80.12403106689453, 13.614030838012638],
        [80.12403106689453, 13.614311218261832],
        [80.12374877929716, 13.614306449890137],
        [80.12374877929716, 13.614582061767635],
        [80.1234741210938, 13.614582061767635],
        [80.1234741210938, 13.614863395690918],
        [80.12317657470732, 13.614859580993766],
        [80.12317657470732, 13.615421295166016],
        [80.12291717529303, 13.61541557312023],
        [80.12291717529303, 13.615973472595272],
        [80.12264251708979, 13.61596965789795],
        [80.12264251708979, 13.61653137207037],
        [80.12236022949241, 13.61653137207037],
        [80.12236022949241, 13.61681079864502],
        [80.12208557128923, 13.616806983947868],
        [80.12208557128923, 13.617081642150879],
        [80.12180328369146, 13.617081642150879],
        [80.12180328369146, 13.61763858795166],
        [80.12152862548845, 13.617631912231559],
        [80.12152862548845, 13.617920875549373],
        [80.12124633789091, 13.617917060851994],
        [80.12124633789091, 13.618194580078125],
        [80.1209716796875, 13.618187904357853],
        [80.1209716796875, 13.61875057220459],
        [80.12069702148466, 13.61875057220459],
        [80.12069702148466, 13.619030952453727],
        [80.12040710449219, 13.619030952453727],
        [80.12040710449219, 13.619311332702694],
        [80.12014007568371, 13.619311332702694],
        [80.12014007568371, 13.619860649108944],
        [80.119857788086, 13.619860649108944],
        [80.119857788086, 13.62014102935791],
        [80.11958312988276, 13.62014102935791],
        [80.11958312988276, 13.620421409607047],
        [80.1190261840822, 13.620421409607047],
        [80.1190261840822, 13.620701789856014],
        [80.11875152587896, 13.620695114135799],
        [80.11875152587896, 13.620972633361873],
        [80.11846923828142, 13.620972633361873],
        [80.11846923828142, 13.622082710266227],
        [80.11875152587896, 13.62208080291748],
        [80.11875152587896, 13.622640609741268],
        [80.1190261840822, 13.622640609741268],
        [80.1190261840822, 13.62292194366455],
        [80.11930847167991, 13.62292194366455],
        [80.11930847167991, 13.624032020568904],
        [80.11958312988276, 13.624032020568904],
        [80.11958312988276, 13.625419616699162],
        [80.11930847167991, 13.625419616699162],
        [80.11930847167991, 13.626531600952148],
        [80.1190261840822, 13.626526832580623],
        [80.1190261840822, 13.627082824707145],
        [80.11875152587896, 13.627080917358342],
        [80.11875152587896, 13.627920150756836],
        [80.11846923828142, 13.627915382385197],
        [80.11846923828142, 13.628195762634334],
        [80.11875152587896, 13.628195762634334],
        [80.11875152587896, 13.628471374511662],
        [80.11846923828142, 13.628471374511662],
        [80.11846923828142, 13.628748893737793],
        [80.11818695068365, 13.628748893737793],
        [80.11818695068365, 13.629309654235897],
        [80.117919921875, 13.629304885864258],
        [80.117919921875, 13.629584312438908],
        [80.11763763427763, 13.629570007324332],
        [80.11763763427763, 13.630140304565373],
        [80.1173706054688, 13.630140304565373],
        [80.1173706054688, 13.63041973114025],
        [80.11708068847685, 13.630417823791504],
        [80.11708068847685, 13.630971908569336],
        [80.1168060302735, 13.630971908569336],
        [80.1168060302735, 13.631250381469727],
        [80.11653137207026, 13.631250381469727],
        [80.11653137207026, 13.631529808044434],
        [80.11596679687494, 13.631529808044434],
        [80.11596679687494, 13.63181018829357],
        [80.1156921386721, 13.631806373596191],
        [80.1156921386721, 13.632083892822322],
        [80.11541748046892, 13.632083892822322],
        [80.11541748046892, 13.632362365722713],
        [80.11486053466814, 13.63236045837408],
        [80.11486053466814, 13.632641792297363],
        [80.11458587646513, 13.632641792297363],
        [80.11458587646513, 13.632931709289664],
        [80.11430358886719, 13.632916450500545],
        [80.11429595947266, 13.63318920135498],
        [80.11374664306646, 13.633195877075252],
        [80.11374664306646, 13.633472442627067],
        [80.1134719848634, 13.633472442627067],
        [80.1134567260745, 13.633758544921989],
        [80.11344909667997, 13.634030342102108],
        [80.11319732666021, 13.634030342102108],
        [80.11319732666021, 13.63431262969965],
        [80.11291503906244, 13.634305953979606],
        [80.11291503906244, 13.634584426879996],
        [80.1126403808596, 13.634581565856934],
        [80.1126403808596, 13.635140419006461],
        [80.11235809326189, 13.635140419006461],
        [80.11235809326189, 13.635419845581112],
        [80.11207580566412, 13.635419845581112],
        [80.11207580566412, 13.635701179504395],
        [80.11180877685564, 13.635701179504395],
        [80.11180877685564, 13.636529922485295],
        [80.1115264892581, 13.636528015136719],
        [80.1115264892581, 13.637084007263184],
        [80.1112365722658, 13.637080192566032],
        [80.1112365722658, 13.637639999389648],
        [80.11096954345732, 13.637638092041072],
        [80.11096954345732, 13.638750076294002],
        [80.11068725585938, 13.638740539550895],
        [80.11068725585938, 13.639310836791992],
        [80.1104202270509, 13.639304161071891],
        [80.1104202270509, 13.639583587646598],
        [80.11013793945318, 13.639583587646598],
        [80.11013793945318, 13.639860153198185],
        [80.10986328124994, 13.639860153198185],
        [80.10986328124994, 13.640420913696346],
        [80.10958099365257, 13.64041614532465],
        [80.10958099365257, 13.640972137451115],
        [80.10930633544939, 13.640972137451115],
        [80.10930633544939, 13.64180660247797],
        [80.10902404785162, 13.64180660247797],
        [80.10902404785162, 13.642082214355469],
        [80.10874938964861, 13.642080307006893],
        [80.10874938964861, 13.645421028137207],
        [80.10902404785162, 13.645417213440055],
        [80.10902404785162, 13.64597320556652],
        [80.10930633544939, 13.645971298217717],
        [80.10930633544939, 13.646812438964844],
        [80.10902404785162, 13.646804809570312],
        [80.10902404785162, 13.648195266723633],
        [80.10930633544939, 13.648189544677848],
        [80.10930633544939, 13.648751258850098],
        [80.10902404785162, 13.648751258850098],
        [80.10902404785162, 13.649861335754451],
        [80.10874938964861, 13.649861335754451],
        [80.10874938964861, 13.650973320007381],
        [80.1084747314456, 13.650973320007381],
        [80.1084747314456, 13.652083396911735],
        [80.10819244384766, 13.652083396911735],
        [80.10819244384766, 13.653195381164494],
        [80.10791778564482, 13.653190612793026],
        [80.10791778564482, 13.654310226440543],
        [80.10820007324219, 13.654310226440543],
        [80.10820007324219, 13.65542030334467],
        [80.10846710205107, 13.65542030334467],
        [80.10846710205107, 13.656530380249023],
        [80.10874938964861, 13.656526565551871],
        [80.10874938964861, 13.656830787658748],
        [80.10874938964861, 13.657361984253043],
        [80.10901641845726, 13.657361984253043],
        [80.10901641845726, 13.657640457153377],
        [80.10958099365257, 13.657640457153377],
        [80.10958099365257, 13.65792179107666],
        [80.10986328124994, 13.657917976379508],
        [80.10986328124994, 13.658193588256836],
        [80.11235809326189, 13.658193588256836],
        [80.11235809326189, 13.657917976379508],
        [80.11402893066435, 13.65792179107666],
        [80.11402893066435, 13.657640457153377],
        [80.11624908447271, 13.657640457153377],
        [80.11624908447271, 13.6587495803833],
        [80.11818695068365, 13.6587495803833],
        [80.11818695068365, 13.655691146850529],
        [80.117919921875, 13.655693054199332],
        [80.117919921875, 13.655418395996094],
        [80.11763763427763, 13.65542030334467],
        [80.11763763427763, 13.655141830444336],
        [80.1173706054688, 13.655141830444336],
        [80.1173706054688, 13.654861450195312],
        [80.11708068847685, 13.654861450195312],
        [80.11708068847685, 13.65625190734869],
        [80.11541748046892, 13.65625190734869],
        [80.11541748046892, 13.655970573425407],
        [80.11513519287115, 13.655970573425407],
        [80.11513519287115, 13.655851364135856],
        [80.11513519287115, 13.655418395996094],
        [80.11486053466814, 13.65542030334467],
        [80.11486053466814, 13.655141830444336],
        [80.11514282226591, 13.655141830444336],
        [80.11514282226591, 13.654581069946346],
        [80.11541748046892, 13.654582977294979],
        [80.11541748046892, 13.654305458068848],
        [80.1156921386721, 13.654310226440543],
        [80.1156921386721, 13.654031753540153],
        [80.11596679687494, 13.654031753540153],
        [80.11596679687494, 13.653470993041992],
        [80.11624908447271, 13.653470993041992],
        [80.11624908447271, 13.653195381164494],
        [80.11736297607422, 13.653195381164494],
        [80.11736297607422, 13.653751373290959],
        [80.117919921875, 13.653751373290959],
        [80.117919921875, 13.653470993041992],
        [80.11819458007841, 13.653470993041992],
        [80.11819458007841, 13.652916908264103],
        [80.11839294433622, 13.652919769287166],
        [80.11846923828142, 13.652916908264103],
        [80.11846923828142, 13.652639389038029],
        [80.11875152587896, 13.652639389038029],
        [80.11875152587896, 13.652080535888672],
        [80.1190261840822, 13.652083396911735],
        [80.1190261840822, 13.65152740478527],
        [80.11930847167991, 13.651529312133846],
        [80.11930847167991, 13.651250839233455],
        [80.11958312988276, 13.651250839233455],
        [80.11958312988276, 13.650680541992244],
        [80.119857788086, 13.65069484710699],
        [80.119857788086, 13.650138854980526],
        [80.11958312988276, 13.650140762329102],
        [80.11958312988276, 13.649312019348201],
        [80.11930847167991, 13.649312019348201],
        [80.11930847167991, 13.648751258850098],
        [80.1190261840822, 13.648751258850098],
        [80.1190261840822, 13.648189544677848],
        [80.11875152587896, 13.648195266723633],
        [80.11875152587896, 13.647916793823242],
        [80.11818695068365, 13.647931098938045],
        [80.11818695068365, 13.647641181945914],
        [80.117919921875, 13.647641181945914],
        [80.117919921875, 13.647360801696777],
        [80.11763763427763, 13.647360801696777],
        [80.11763763427763, 13.647083282470703],
        [80.11736297607422, 13.647083282470703],
        [80.11736297607422, 13.646804809570312],
        [80.1168060302735, 13.646812438964844],
        [80.1168060302735, 13.64653110504156],
        [80.11653137207026, 13.64653110504156],
        [80.11653137207026, 13.646250724792594],
        [80.11624908447271, 13.646250724792594],
        [80.11624908447271, 13.645971298217717],
        [80.1158218383789, 13.64597320556652],
        [80.1156921386721, 13.64597320556652],
        [80.1156921386721, 13.64569473266613],
        [80.11541748046892, 13.64569473266613],
        [80.11541748046892, 13.645417213440055],
        [80.11514282226591, 13.645421028137207],
        [80.11514282226591, 13.64514064788824],
        [80.11486053466814, 13.64514064788824],
        [80.11486053466814, 13.644869804382381],
        [80.11429595947266, 13.644869804382381],
        [80.11429595947266, 13.6445827484132],
        [80.11402893066435, 13.644584655761776],
        [80.11402893066435, 13.644304275512809],
        [80.11374664306646, 13.644310951233024],
        [80.11374664306646, 13.644030570983887],
        [80.1134719848634, 13.644030570983887],
        [80.1134719848634, 13.64375114440918],
        [80.11319732666021, 13.64375114440918],
        [80.11319732666021, 13.64346790313715],
        [80.11291503906244, 13.643472671508846],
        [80.11291503906244, 13.643195152282715],
        [80.1126403808596, 13.643195152282715],
        [80.1126403808596, 13.642915725708065],
        [80.11180877685564, 13.64292144775402],
        [80.11180877685564, 13.642080307006893],
        [80.11235809326189, 13.642082214355469],
        [80.11235809326189, 13.64180660247797],
        [80.11374664306646, 13.64180660247797],
        [80.11374664306646, 13.642082214355469],
        [80.11402893066435, 13.642080307006893],
        [80.11402893066435, 13.642641067504826],
        [80.11429595947266, 13.642641067504826],
        [80.11429595947266, 13.64292144775402],
        [80.11486053466814, 13.642915725708065],
        [80.11486053466814, 13.643195152282715],
        [80.11522674560564, 13.643195152282715],
        [80.11541748046892, 13.643195152282715],
        [80.11541748046892, 13.643472671508846],
        [80.11596679687494, 13.64346790313715],
        [80.11596679687494, 13.64375114440918],
        [80.11653137207026, 13.64375114440918],
        [80.11653137207026, 13.644030570983887],
        [80.11708068847685, 13.644030570983887],
        [80.11708068847685, 13.644310951233024],
        [80.117919921875, 13.644304275512809],
        [80.117919921875, 13.644584655761776],
        [80.11846923828142, 13.6445827484132],
        [80.11846923828142, 13.644869804382381],
        [80.1190261840822, 13.644869804382381],
        [80.1190261840822, 13.64514064788824],
        [80.11958312988276, 13.64514064788824],
        [80.11958312988276, 13.645421028137207],
        [80.12014007568371, 13.645417213440055],
        [80.12014007568371, 13.64569473266613],
        [80.12069702148466, 13.64569473266613],
        [80.12069702148466, 13.64597320556652],
        [80.12040710449219, 13.645971298217717],
        [80.12040710449219, 13.646250724792594],
        [80.12014007568371, 13.646250724792594],
        [80.12014007568371, 13.648470878601131],
        [80.12040710449219, 13.648470878601131],
        [80.12040710449219, 13.649030685424748],
        [80.12069702148466, 13.649026870727596],
        [80.12069702148466, 13.649861335754451],
        [80.1209716796875, 13.649861335754451],
        [80.1209716796875, 13.65069484710699],
        [80.12124633789091, 13.650680541992244],
        [80.12124633789091, 13.651250839233455],
        [80.12152862548845, 13.651250839233455],
        [80.12152862548845, 13.651809692382812],
        [80.12180328369146, 13.651809692382812],
        [80.12180328369146, 13.652919769287166],
        [80.12152862548845, 13.652916908264103],
        [80.12152862548845, 13.654582977294979],
        [80.12124633789091, 13.654582977294979],
        [80.12124633789091, 13.654751777648926],
        [80.12124633789091, 13.65542030334467],
        [80.12014007568371, 13.655418395996094],
        [80.12014007568371, 13.655970573425407],
        [80.119857788086, 13.655970573425407],
        [80.119857788086, 13.656530380249023],
        [80.12014007568371, 13.656530380249023],
        [80.12014007568371, 13.657361984253043],
        [80.12040710449219, 13.657361984253043],
        [80.12040710449219, 13.660420417785758],
        [80.12014007568371, 13.66041564941412],
        [80.12014007568371, 13.660971641540584],
        [80.119857788086, 13.660971641540584],
        [80.119857788086, 13.661810874939079],
        [80.11958312988276, 13.66180610656744],
        [80.11958312988276, 13.663193702697868],
        [80.119857788086, 13.663191795349121],
        [80.119857788086, 13.663749694824162],
        [80.12014007568371, 13.663749694824162],
        [80.12014007568371, 13.664030075073299],
        [80.12152862548845, 13.664030075073299],
        [80.12152862548845, 13.664311408996582],
        [80.12209320068376, 13.664311408996582],
        [80.12209320068376, 13.664861679077092],
        [80.12236022949241, 13.664861679077092],
        [80.12236022949241, 13.665420532226676],
        [80.12264251708979, 13.66541576385498],
        [80.12264251708979, 13.665971755981445],
        [80.12291717529303, 13.66596984863287],
        [80.12291717529303, 13.666530609130803],
        [80.12317657470732, 13.666530609130803],
        [80.12317657470732, 13.666810989379996],
        [80.1234741210938, 13.6668062210083],
        [80.1234741210938, 13.66736030578619],
        [80.12374877929716, 13.66736030578619],
        [80.12374877929716, 13.66792106628418],
        [80.12403106689453, 13.667916297912654],
        [80.12403106689453, 13.668472290039176],
        [80.12431335449247, 13.668470382690373],
        [80.12431335449247, 13.669031143188533],
        [80.12458038330095, 13.669026374816838],
        [80.12458038330095, 13.669582366943303],
        [80.1248626708985, 13.669580459594727],
        [80.1248626708985, 13.669862747192496],
        [80.12541961669945, 13.669862747192496],
        [80.12541961669945, 13.669580459594727],
        [80.12596893310553, 13.669580459594727],
        [80.12596893310553, 13.670420646667537],
        [80.12568664550776, 13.670420646667537],
        [80.12568664550776, 13.671251296997013],
        [80.12541961669945, 13.671251296997013],
        [80.12541961669945, 13.672361373901367],
        [80.12513732910173, 13.672361373901367],
        [80.12513732910173, 13.673194885253963],
        [80.1248626708985, 13.673194885253963],
        [80.1248626708985, 13.675720214843864],
        [80.1248626708985, 13.67681026458746],
        [80.12458038330095, 13.676804542541504],
        [80.12458038330095, 13.677083015441895],
        [80.12431335449247, 13.677081108093319],
        [80.12431335449247, 13.677640914916935],
        [80.12403106689453, 13.67763900756836],
        [80.12403106689453, 13.677916526794434],
        [80.12236022949241, 13.677916526794434],
        [80.12236022949241, 13.678193092346248],
        [80.12041473388672, 13.678193092346248],
        [80.12041473388672, 13.677083015441895],
        [80.12069702148466, 13.677083015441895],
        [80.12069702148466, 13.67652702331543],
        [80.1209716796875, 13.676530838012809],
        [80.1209716796875, 13.675971031188965],
        [80.12124633789091, 13.675972938537711],
        [80.12124633789091, 13.675416946411246],
        [80.12152862548845, 13.675421714782715],
        [80.12152862548845, 13.67486953735363],
        [80.12180328369146, 13.67486953735363],
        [80.12180328369146, 13.674031257629395],
        [80.12152862548845, 13.674031257629395],
        [80.12152862548845, 13.672640800476074],
        [80.12124633789091, 13.672640800476074],
        [80.12124633789091, 13.671530723571891],
        [80.1209716796875, 13.671530723571891],
        [80.1209716796875, 13.67068958282482],
        [80.11958312988276, 13.670695304870549],
        [80.11958312988276, 13.67097282409668],
        [80.11875152587896, 13.670969963073787],
        [80.11875152587896, 13.671251296997013],
        [80.117919921875, 13.671251296997013],
        [80.117919921875, 13.671530723571891],
        [80.11763763427763, 13.671530723571891],
        [80.11763763427763, 13.671809196472282],
        [80.11736297607422, 13.671805381774902],
        [80.11736297607422, 13.672082901001033],
        [80.1168060302735, 13.672082901001033],
        [80.1168060302735, 13.672361373901367],
        [80.11653137207026, 13.672361373901367],
        [80.11653137207026, 13.672640800476074],
        [80.11624908447271, 13.672640800476074],
        [80.11624908447271, 13.672921180725211],
        [80.1159744262697, 13.672917366027889],
        [80.1159744262697, 13.673194885253963],
        [80.1156921386721, 13.673190116882324],
        [80.1156921386721, 13.673750877380428],
        [80.11541748046892, 13.673750877380428],
        [80.11541748046892, 13.674311637878361],
        [80.11514282226591, 13.674311637878361],
        [80.11514282226591, 13.67486953735363],
        [80.11486053466814, 13.67486953735363],
        [80.11486053466814, 13.675421714782715],
        [80.11458587646513, 13.675416946411246],
        [80.11458587646513, 13.675972938537711],
        [80.1134719848634, 13.675972938537711],
        [80.1134719848634, 13.675416946411246],
        [80.11319732666021, 13.675421714782715],
        [80.11319732666021, 13.674580574035645],
        [80.11291503906244, 13.674583435058707],
        [80.11291503906244, 13.674027442932243],
        [80.1126403808596, 13.674031257629395],
        [80.1126403808596, 13.673471450805721],
        [80.11235809326189, 13.673473358154354],
        [80.11235809326189, 13.673194885253963],
        [80.11208343505865, 13.673194885253963],
        [80.11208343505865, 13.672638893127498],
        [80.11180877685564, 13.672640800476074],
        [80.11180877685564, 13.672361373901367],
        [80.1115264892581, 13.672361373901367],
        [80.1115264892581, 13.671805381774902],
        [80.1112365722658, 13.671809196472282],
        [80.1112365722658, 13.671530723571891],
        [80.10901641845726, 13.671530723571891],
        [80.10901641845726, 13.671251296997013],
        [80.10846710205107, 13.671251296997013],
        [80.10846710205107, 13.672640800476074],
        [80.10819244384766, 13.672638893127498],
        [80.10819244384766, 13.673473358154354],
        [80.10764312744146, 13.673471450805721],
        [80.10764312744146, 13.673750877380428],
        [80.10736083984386, 13.673750877380428],
        [80.10736083984386, 13.674031257629395],
        [80.10679626464855, 13.674031257629395],
        [80.10679626464855, 13.674311637878361],
        [80.10736083984386, 13.674306869506893],
        [80.10736083984386, 13.674583435058707],
        [80.10763549804688, 13.674583435058707],
        [80.10763549804688, 13.677360534667969],
        [80.10736083984386, 13.677360534667969],
        [80.10736083984386, 13.678473472595215],
        [80.10764312744146, 13.678470611572322],
        [80.10764312744146, 13.678750991821289],
        [80.10791778564482, 13.678750991821289],
        [80.10791778564482, 13.67931175231945],
        [80.10819244384766, 13.679305076599178],
        [80.10819244384766, 13.679861068725643],
        [80.1084747314456, 13.679861068725643],
        [80.1084747314456, 13.680694580078239],
        [80.10820007324219, 13.68069076538086],
        [80.10820007324219, 13.68125152587902],
        [80.10791778564482, 13.68125152587902],
        [80.10791778564482, 13.681531906127987],
        [80.10763549804688, 13.68152713775629],
        [80.10763549804688, 13.682083129882812],
        [80.10736083984386, 13.68208122253418],
        [80.10736083984386, 13.68264198303234],
        [80.10708618164068, 13.682640075683537],
        [80.10708618164068, 13.683193206787166],
        [80.10680389404291, 13.683193206787166],
        [80.10680389404291, 13.683473587036133],
        [80.10652923584007, 13.68347072601324],
        [80.10652923584007, 13.683749198913631],
        [80.10624694824236, 13.683749198913631],
        [80.10624694824236, 13.684041023254508],
        [80.10652923584007, 13.684041023254508],
        [80.10652923584007, 13.684309959411735],
        [80.1084747314456, 13.684306144714355],
        [80.1084747314456, 13.684583663940487],
        [80.10874938964861, 13.68457889556879],
        [80.10874938964861, 13.685139656066951],
        [80.10902404785162, 13.685139656066951],
        [80.10902404785162, 13.68569374084484],
        [80.10874938964861, 13.685690879821777],
        [80.10874938964861, 13.686249732971305],
        [80.10846710205107, 13.686249732971305],
        [80.10846710205107, 13.686809539794922],
        [80.10819244384766, 13.68680572509777],
        [80.10819244384766, 13.688471794128418],
        [80.1084747314456, 13.688471794128418],
        [80.1084747314456, 13.689862251281738],
        [80.10874938964861, 13.689850807189998],
        [80.10874938964861, 13.690141677856445],
        [80.10901641845726, 13.690141677856445],
        [80.10901641845726, 13.690421104431266],
        [80.10930633544939, 13.690416336059627],
        [80.10930633544939, 13.690693855285701],
        [80.10958099365257, 13.690693855285701],
        [80.10958099365257, 13.690972328186092],
        [80.10986328124994, 13.690970420837516],
        [80.10986328124994, 13.691249847412166],
        [80.11013793945318, 13.691249847412166],
        [80.11013793945318, 13.691530227661133],
        [80.11068725585938, 13.691530227661133],
        [80.11068725585938, 13.691810607910156],
        [80.11096954345732, 13.691805839538631],
        [80.11096954345732, 13.692084312439022],
        [80.1112365722658, 13.692079544067383],
        [80.1112365722658, 13.692640304565487],
        [80.1115264892581, 13.692640304565487],
        [80.1115264892581, 13.69375038146967],
        [80.11125183105469, 13.69375038146967],
        [80.11125183105469, 13.694581985473633],
        [80.11096954345732, 13.694581985473633],
        [80.11096954345732, 13.694863319396916],
        [80.11068725585938, 13.694860458374023],
        [80.11068725585938, 13.695421218872184],
        [80.1104202270509, 13.695416450500488],
        [80.1104202270509, 13.69569015502941],
        [80.11013793945318, 13.695700645446834],
        [80.11013793945318, 13.695981979370117],
        [80.10986328124994, 13.695981979370117],
        [80.10986328124994, 13.696250915527344],
        [80.10958099365257, 13.696250915527344],
        [80.10958099365257, 13.696810722351188],
        [80.10930633544939, 13.696806907653865],
        [80.10930633544939, 13.697081565857047],
        [80.10901641845726, 13.6970796585083],
        [80.10901641845726, 13.697360038757438],
        [80.10874938964861, 13.697360038757438],
        [80.10874938964861, 13.697920799255371],
        [80.1084747314456, 13.69791698455822],
        [80.1084747314456, 13.698472976684684],
        [80.10820007324219, 13.698470115661621],
        [80.10820007324219, 13.699040412902832],
        [80.10791778564482, 13.699040412902832],
        [80.10791778564482, 13.699311256408691],
        [80.10763549804688, 13.699304580688477],
        [80.10763549804688, 13.699583053588867],
        [80.10736083984386, 13.699583053588867],
        [80.10736083984386, 13.699860572814941],
        [80.10708618164068, 13.699860572814941],
        [80.10708618164068, 13.700140953064079],
        [80.10680389404291, 13.700139045715332],
        [80.10680389404291, 13.700695037841797],
        [80.10708618164068, 13.700690269470329],
        [80.10708618164068, 13.701811790466365],
        [80.10736083984386, 13.701811790466365],
        [80.10736083984386, 13.702921867370549],
        [80.10764312744146, 13.702921867370549],
        [80.10764312744146, 13.70375061035162],
        [80.10791778564482, 13.703748703003043],
        [80.10791778564482, 13.70388126373291],
        [80.10791778564482, 13.706249237060547],
        [80.10820007324219, 13.706249237060547],
        [80.10820007324219, 13.706811904907283],
        [80.1084747314456, 13.706805229187012],
        [80.1084747314456, 13.707082748413143],
        [80.10902404785162, 13.707082748413143],
        [80.10902404785162, 13.707361221313477],
        [80.10930633544939, 13.707361221313477],
        [80.10930633544939, 13.707921981811637],
        [80.10902404785162, 13.707915306091365],
        [80.10902404785162, 13.70847129821783],
        [80.10874938964861, 13.70847129821783],
        [80.10874938964861, 13.708751678466854],
        [80.10846710205107, 13.708751678466854],
        [80.10846710205107, 13.709030151367188],
        [80.10820007324219, 13.709030151367188],
        [80.10820007324219, 13.709309577941895],
        [80.10679626464855, 13.709309577941895],
        [80.10679626464855, 13.709030151367188],
        [80.10652923584007, 13.709030151367188],
        [80.10652923584007, 13.70847129821783],
        [80.10624694824236, 13.70847129821783],
        [80.10624694824236, 13.707915306091365],
        [80.10597229003912, 13.707921981811637],
        [80.10597229003912, 13.7076416015625],
        [80.10514068603516, 13.7076416015625],
        [80.10514068603516, 13.707361221313477],
        [80.10430908203136, 13.707361221313477],
        [80.10430908203136, 13.707082748413143],
        [80.10458374023438, 13.707082748413143],
        [80.10458374023438, 13.706526756286621],
        [80.10485839843778, 13.706528663635254],
        [80.10485839843778, 13.70347118377697],
        [80.10458374023438, 13.703473091125545],
        [80.10458374023438, 13.703194618225154],
        [80.10430908203136, 13.703194618225154],
        [80.10430908203136, 13.70291709899908],
        [80.10402679443365, 13.702921867370549],
        [80.10402679443365, 13.702361106872615],
        [80.10375213623041, 13.702361106872615],
        [80.10375213623041, 13.702082633972225],
        [80.10346984863287, 13.702082633972225],
        [80.10346984863287, 13.70180511474615],
        [80.10291290283226, 13.70180511474615],
        [80.10291290283226, 13.702082633972225],
        [80.10180664062528, 13.702082633972225],
        [80.10180664062528, 13.702361106872615],
        [80.10069274902355, 13.702361106872615],
        [80.10069274902355, 13.702082633972225],
        [80.10041809082037, 13.702082633972225],
        [80.10041809082037, 13.699028968811149],
        [80.10069274902355, 13.699040412902832],
        [80.10069274902355, 13.698470115661621],
        [80.10097503662115, 13.698472976684684],
        [80.10097503662115, 13.698195457458553],
        [80.1012496948245, 13.698195457458553],
        [80.1012496948245, 13.697639465332088],
        [80.10151672363281, 13.697641372680721],
        [80.10151672363281, 13.6970796585083],
        [80.10180664062528, 13.697081565857047],
        [80.10180664062528, 13.696806907653865],
        [80.1020812988283, 13.696810722351188],
        [80.1020812988283, 13.696250915527344],
        [80.1023559570313, 13.696250915527344],
        [80.1023559570313, 13.695981979370117],
        [80.10260772705072, 13.695981979370117],
        [80.10263824462908, 13.695421218872184],
        [80.10291290283226, 13.695421218872184],
        [80.10291290283226, 13.695142745971793],
        [80.10597229003912, 13.695142745971793],
        [80.10597229003912, 13.694860458374023],
        [80.10652923584007, 13.694863319396916],
        [80.10652923584007, 13.694581985473633],
        [80.10680389404291, 13.694581985473633],
        [80.10680389404291, 13.694306373596135],
        [80.10736083984386, 13.69431114196783],
        [80.10736083984386, 13.693041801452637],
        [80.10736083984386, 13.692915916442814],
        [80.10763549804688, 13.692915916442814],
        [80.10763549804688, 13.691528320312557],
        [80.10736083984386, 13.691530227661133],
        [80.10736083984386, 13.691249847412166],
        [80.10679626464855, 13.691249847412166],
        [80.10679626464855, 13.690970420837516],
        [80.10652923584007, 13.690972328186092],
        [80.10652923584007, 13.690693855285701],
        [80.10624694824236, 13.690693855285701],
        [80.10624694824236, 13.690416336059627],
        [80.10569763183611, 13.690421104431266],
        [80.10569763183611, 13.690141677856445],
        [80.10543060302763, 13.690141677856445],
        [80.10543060302763, 13.689581871032772],
        [80.10514068603516, 13.689583778381348],
        [80.10514068603516, 13.689027786254883],
        [80.10485839843778, 13.689029693603459],
        [80.10485839843778, 13.688471794128418],
        [80.10457611083984, 13.688471794128418],
        [80.10457611083984, 13.687919616699276],
        [80.10430908203136, 13.687919616699276],
        [80.10430908203136, 13.687640190124625],
        [80.10375213623041, 13.687640190124625],
        [80.10375213623041, 13.687359809875488],
        [80.10319519042986, 13.687359809875488],
        [80.10319519042986, 13.68708419799816],
        [80.10263824462908, 13.68708419799816],
        [80.10263824462908, 13.68680572509777],
        [80.10180664062528, 13.68680572509777],
        [80.10180664062528, 13.68708419799816],
        [80.10097503662115, 13.68708419799816],
        [80.10097503662115, 13.679026603698787],
        [80.1012496948245, 13.67902946472168],
        [80.1012496948245, 13.678750991821289],
        [80.10098266601568, 13.678750991821289],
        [80.10098266601568, 13.678470611572322],
        [80.10069274902355, 13.678473472595215],
        [80.10069274902355, 13.677916526794434],
        [80.10097503662115, 13.677916526794434],
        [80.10097503662115, 13.676804542541504],
        [80.1012496948245, 13.67681026458746],
        [80.1012496948245, 13.675971031188965],
        [80.10152435302734, 13.675972938537711],
        [80.10152435302734, 13.674860954284782],
        [80.10180664062528, 13.67486953735363],
        [80.10180664062528, 13.673750877380428],
        [80.1020812988283, 13.673750877380428],
        [80.1020812988283, 13.672640800476074],
        [80.1023559570313, 13.672640800476074],
        [80.1023559570313, 13.66736030578619],
        [80.10263824462908, 13.66736030578619],
        [80.10263824462908, 13.667083740234432],
        [80.10346984863287, 13.667083740234432],
        [80.10346984863287, 13.66541576385498],
        [80.10375213623041, 13.665420532226676],
        [80.10375213623041, 13.662639617919979],
        [80.10402679443365, 13.662639617919979],
        [80.10402679443365, 13.661529541015625],
        [80.10430908203136, 13.661529541015625],
        [80.10430908203136, 13.660420417785758],
        [80.10457611083984, 13.660420417785758],
        [80.10457611083984, 13.659029960632438],
        [80.10430908203136, 13.659029960632438],
        [80.10430908203136, 13.657640457153377],
        [80.10402679443365, 13.657640457153377],
        [80.10402679443365, 13.656530380249023],
        [80.10375213623041, 13.656530380249023],
        [80.10375213623041, 13.656189918518066],
        [80.10375213623041, 13.655138969421444],
        [80.10346984863287, 13.655141830444336],
        [80.10346984863287, 13.654310226440543],
        [80.10320281982439, 13.654310226440543],
        [80.10320281982439, 13.654031753540153],
        [80.10291290283226, 13.654031753540153],
        [80.10291290283226, 13.653739929199276],
        [80.10263824462908, 13.653751373290959],
        [80.10263824462908, 13.653470993041992],
        [80.1020812988283, 13.653470993041992],
        [80.1020812988283, 13.653195381164494],
        [80.10180664062528, 13.653195381164494],
        [80.10180664062528, 13.652916908264103],
        [80.10151672363281, 13.652919769287166],
        [80.10151672363281, 13.652639389038029],
        [80.09764099121105, 13.652639389038029],
        [80.09764099121105, 13.653195381164494],
        [80.09735870361334, 13.653195381164494],
        [80.09735870361334, 13.653751373290959],
        [80.09707641601557, 13.653739929199276],
        [80.09707641601557, 13.654310226440543],
        [80.09735870361334, 13.654305458068848],
        [80.09735870361334, 13.654920578003043],
        [80.09735870361334, 13.65542030334467],
        [80.09764099121105, 13.65542030334467],
        [80.09764099121105, 13.656530380249023],
        [80.09792327880865, 13.656530380249023],
        [80.09792327880865, 13.657640457153377],
        [80.098197937012, 13.657636642456055],
        [80.098197937012, 13.659584045410156],
        [80.09847259521484, 13.659579277038688],
        [80.09847259521484, 13.66125202178955],
        [80.098197937012, 13.66125202178955],
        [80.098197937012, 13.661529541015625],
        [80.09735870361334, 13.661529541015625],
        [80.09735870361334, 13.66125202178955],
        [80.09707641601557, 13.66125202178955],
        [80.09707641601557, 13.660971641540584],
        [80.09680175781273, 13.660971641540584],
        [80.09680175781273, 13.66041564941412],
        [80.09652709960955, 13.660420417785758],
        [80.09652709960955, 13.659871101379338],
        [80.09626007080107, 13.659871101379338],
        [80.09626007080107, 13.659579277038688],
        [80.09597015380876, 13.659584045410156],
        [80.09597015380876, 13.659028053283691],
        [80.09570312500028, 13.659029960632438],
        [80.09570312500028, 13.6587495803833],
        [80.09541320800781, 13.6587495803833],
        [80.09541320800781, 13.658190727233944],
        [80.09513854980497, 13.658193588256836],
        [80.09513854980497, 13.657636642456055],
        [80.09485626220703, 13.657640457153377],
        [80.09485626220703, 13.657361984253043],
        [80.09458160400402, 13.657361984253043],
        [80.09458160400402, 13.65680980682373],
        [80.09430694580084, 13.65680980682373],
        [80.09430694580084, 13.656530380249023],
        [80.09401702880871, 13.656530380249023],
        [80.09401702880871, 13.655970573425407],
        [80.09375000000023, 13.655970573425407],
        [80.09375000000023, 13.655418395996094],
        [80.09349060058611, 13.65542030334467],
        [80.09349060058611, 13.655141830444336],
        [80.0932006835938, 13.655141830444336],
        [80.0932006835938, 13.654581069946346],
        [80.09291839599626, 13.654582977294979],
        [80.09291839599626, 13.654305458068848],
        [80.09236145019531, 13.654310226440543],
        [80.09236145019531, 13.654141426086369],
        [80.09236145019531, 13.654026985168457],
        [80.091796875, 13.654031753540153],
        [80.091796875, 13.653739929199276],
        [80.08902740478544, 13.653751373290959],
        [80.08902740478544, 13.65388107299816],
        [80.08902740478544, 13.654031753540153],
        [80.08875274658203, 13.654031753540153],
        [80.08875274658203, 13.65542030334467],
        [80.08930206298845, 13.655418395996094],
        [80.08930206298845, 13.655693054199332],
        [80.08958435058622, 13.655693054199332],
        [80.08958435058622, 13.655970573425407],
        [80.09014129638678, 13.655970573425407],
        [80.09014129638678, 13.65625190734869],
        [80.09041595459001, 13.65625190734869],
        [80.09041595459001, 13.656530380249023],
        [80.09097290039057, 13.656530380249023],
        [80.09097290039057, 13.65680980682373],
        [80.0912475585938, 13.65680980682373],
        [80.0912475585938, 13.657361984253043],
        [80.09152984619152, 13.657361984253043],
        [80.09152984619152, 13.657640457153377],
        [80.09180450439453, 13.657636642456055],
        [80.09180450439453, 13.658193588256836],
        [80.09208679199247, 13.658190727233944],
        [80.09208679199247, 13.6587495803833],
        [80.09236145019531, 13.6587495803833],
        [80.09236145019531, 13.659029960632438],
        [80.09263610839872, 13.659028053283691],
        [80.09263610839872, 13.659584045410156],
        [80.09291839599626, 13.659579277038688],
        [80.09291839599626, 13.659871101379338],
        [80.0932006835938, 13.659871101379338],
        [80.0932006835938, 13.660420417785758],
        [80.09347534179705, 13.66041564941412],
        [80.09347534179705, 13.660971641540584],
        [80.09375000000023, 13.660971641540584],
        [80.09375000000023, 13.66125202178955],
        [80.09401702880871, 13.66125202178955],
        [80.09401702880871, 13.661810874939079],
        [80.09430694580084, 13.66180610656744],
        [80.09430694580084, 13.662083625793514],
        [80.09541320800781, 13.662078857421875],
        [80.09541320800781, 13.662639617919979],
        [80.09569549560575, 13.662637710571403],
        [80.09569549560575, 13.663193702697868],
        [80.09597015380876, 13.663191795349121],
        [80.09597015380876, 13.663749694824162],
        [80.09626007080107, 13.663749694824162],
        [80.09626007080107, 13.664311408996582],
        [80.09652709960955, 13.664311408996582],
        [80.09652709960955, 13.664861679077092],
        [80.09680175781273, 13.664859771728516],
        [80.09680175781273, 13.665694236755371],
        [80.09652709960955, 13.665679931640625],
        [80.09652709960955, 13.666810989379996],
        [80.09626007080107, 13.666810989379996],
        [80.09626007080107, 13.66792106628418],
        [80.09597015380876, 13.667916297912654],
        [80.09597015380876, 13.668472290039176],
        [80.09570312500028, 13.668470382690373],
        [80.09570312500028, 13.66874980926525],
        [80.09458160400402, 13.66874980926525],
        [80.09458160400402, 13.668191909789982],
        [80.09430694580084, 13.668193817138786],
        [80.09430694580084, 13.66763782501232],
        [80.09401702880871, 13.667640686035156],
        [80.09401702880871, 13.66736030578619],
        [80.09347534179705, 13.66736030578619],
        [80.09347534179705, 13.667083740234432],
        [80.09319305419928, 13.667083740234432],
        [80.09319305419928, 13.6668062210083],
        [80.09263610839872, 13.666810989379996],
        [80.09263610839872, 13.666530609130803],
        [80.09236145019531, 13.666530609130803],
        [80.09236145019531, 13.666250228881836],
        [80.09208679199247, 13.666250228881836],
        [80.09208679199247, 13.66596984863287],
        [80.09152984619152, 13.665971755981445],
        [80.09152984619152, 13.665694236755371],
        [80.0912475585938, 13.665694236755371],
        [80.0912475585938, 13.66541576385498],
        [80.09097290039057, 13.665420532226676],
        [80.09097290039057, 13.665140151977482],
        [80.09041595459001, 13.665140151977482],
        [80.09041595459001, 13.664861679077092],
        [80.09014129638678, 13.664861679077092],
        [80.09014129638678, 13.664580345153865],
        [80.08930206298845, 13.664580345153865],
        [80.08930206298845, 13.665140151977482],
        [80.08902740478544, 13.665140151977482],
        [80.08902740478544, 13.665694236755371],
        [80.08791351318371, 13.665694236755371],
        [80.08791351318371, 13.66541576385498],
        [80.08763885498053, 13.665420532226676],
        [80.08763885498053, 13.664861679077092],
        [80.08735656738276, 13.664861679077092],
        [80.08735656738276, 13.664311408996582],
        [80.08706665039068, 13.664311408996582],
        [80.08706665039068, 13.663749694824162],
        [80.08680725097673, 13.663749694824162],
        [80.08680725097673, 13.663470268249512],
        [80.08652496337896, 13.663472175598258],
        [80.08652496337896, 13.66291809082037],
        [80.08625030517595, 13.66292953491211],
        [80.08625030517595, 13.662360191345329],
        [80.08597564697294, 13.662362098693904],
        [80.08597564697294, 13.662083625793514],
        [80.085693359375, 13.662083625793514],
        [80.085693359375, 13.661527633667049],
        [80.08541870117216, 13.661529541015625],
        [80.08541870117216, 13.66125202178955],
        [80.08513641357428, 13.66125202178955],
        [80.08513641357428, 13.660689353942814],
        [80.08430480957026, 13.66069412231451],
        [80.08430480957026, 13.659584045410156],
        [80.08403015136741, 13.659584045410156],
        [80.08403015136741, 13.65847206115717],
        [80.0837478637697, 13.65847206115717],
        [80.0837478637697, 13.658193588256836],
        [80.08347320556646, 13.658193588256836],
        [80.08347320556646, 13.657917976379508],
        [80.08291625976591, 13.65792179107666],
        [80.08291625976591, 13.657640457153377],
        [80.0826416015625, 13.657640457153377],
        [80.0826416015625, 13.657361984253043],
        [80.08235931396513, 13.657361984253043],
        [80.08235931396513, 13.65708065032959],
        [80.08208465576178, 13.657084465026912],
        [80.08208465576178, 13.656805992126522],
        [80.08180236816418, 13.65680980682373],
        [80.08180236816418, 13.656530380249023],
        [80.08126068115251, 13.656530380249023],
        [80.08126068115251, 13.65625190734869],
        [80.08097076416038, 13.65625190734869],
        [80.08097076416038, 13.655970573425407],
        [80.07930755615263, 13.655970573425407],
        [80.07930755615263, 13.655138969421444],
        [80.07958221435547, 13.655141830444336],
        [80.07958221435547, 13.654581069946346],
        [80.08013916015642, 13.654582977294979],
        [80.08013916015642, 13.652639389038029],
        [80.07985687255888, 13.652639389038029],
        [80.07985687255888, 13.651809692382812],
        [80.08013916015642, 13.651809692382812],
        [80.08013916015642, 13.651529312133846],
        [80.08040618896507, 13.651529312133846],
        [80.08040618896507, 13.651250839233455],
        [80.0806961059572, 13.651250839233455],
        [80.0806961059572, 13.650971412658805],
        [80.08097076416038, 13.650973320007381],
        [80.08097076416038, 13.65069484710699],
        [80.08125305175776, 13.65069484710699],
        [80.08125305175776, 13.650417327880916],
        [80.08235931396513, 13.650417327880916],
        [80.08235931396513, 13.650973320007381],
        [80.08319091796892, 13.650973320007381],
        [80.08319091796892, 13.65069484710699],
        [80.0837478637697, 13.65069484710699],
        [80.0837478637697, 13.650417327880916],
        [80.08403015136741, 13.650419235229492],
        [80.08403015136741, 13.650140762329102],
        [80.08735656738276, 13.650140762329102],
        [80.08735656738276, 13.650419235229492],
        [80.09014129638678, 13.650417327880916],
        [80.09014129638678, 13.650973320007381],
        [80.09069824218773, 13.650971412658805],
        [80.09069824218773, 13.651250839233455],
        [80.0912475585938, 13.651250839233455],
        [80.0912475585938, 13.650971412658805],
        [80.09180450439453, 13.650973320007381],
        [80.09180450439453, 13.64958286285406],
        [80.09152984619152, 13.64958286285406],
        [80.09152984619152, 13.649305343627987],
        [80.09097290039057, 13.649312019348201],
        [80.09097290039057, 13.648751258850098],
        [80.0932006835938, 13.648751258850098],
        [80.09319305419928, 13.648470878601131],
        [80.09375000000023, 13.648470878601131],
        [80.09375000000023, 13.648195266723633],
        [80.09430694580084, 13.648195266723633],
        [80.09430694580084, 13.647916793823242],
        [80.09485626220703, 13.647931098938045],
        [80.09485626220703, 13.647360801696777],
        [80.09430694580084, 13.647360801696777],
        [80.09430694580084, 13.646804809570312],
        [80.09401702880871, 13.646812438964844],
        [80.09401702880871, 13.64653110504156],
        [80.09375000000023, 13.64653110504156],
        [80.09375000000023, 13.646250724792594],
        [80.09349060058611, 13.646250724792594],
        [80.09349060058611, 13.645971298217717],
        [80.09236145019531, 13.64597320556652],
        [80.09236145019531, 13.64569473266613],
        [80.09152984619152, 13.64569473266613],
        [80.09152984619152, 13.645417213440055],
        [80.09108734130882, 13.645417213440055],
        [80.08875274658203, 13.645417213440055],
        [80.08875274658203, 13.64569473266613],
        [80.08458709716803, 13.64569473266613],
        [80.08458709716803, 13.645417213440055],
        [80.08403015136741, 13.645421028137207],
        [80.08403015136741, 13.64514064788824],
        [80.08347320556646, 13.64514064788824],
        [80.08347320556646, 13.644869804382381],
        [80.08291625976591, 13.644869804382381],
        [80.08291625976591, 13.6445827484132],
        [80.0826416015625, 13.6445827484132],
        [80.0826416015625, 13.644869804382381],
        [80.08235931396513, 13.644869804382381],
        [80.08235931396513, 13.6445827484132],
        [80.081527709961, 13.644584655761776],
        [80.081527709961, 13.644304275512809],
        [80.08097076416038, 13.644310951233024],
        [80.08097076416038, 13.644030570983887],
        [80.0806961059572, 13.644030570983887],
        [80.0806961059572, 13.64346790313715],
        [80.08013916015642, 13.643472671508846],
        [80.08013916015642, 13.643195152282715],
        [80.07930755615263, 13.643195152282715],
        [80.07930755615263, 13.642915725708065],
        [80.07903289794928, 13.64292144775402],
        [80.07903289794928, 13.642641067504826],
        [80.07875061035168, 13.642641067504826],
        [80.07875061035168, 13.6423597335816],
        [80.07818603515636, 13.6423597335816],
        [80.07818603515636, 13.642080307006893],
        [80.07763671875017, 13.642082214355469],
        [80.07763671875017, 13.64180660247797],
        [80.07736206054693, 13.64180660247797],
        [80.07736206054693, 13.641528129577637],
        [80.07681274414091, 13.6415309906007],
        [80.07681274414091, 13.641249656677246],
        [80.07653045654297, 13.641249656677246],
        [80.07653045654297, 13.640970230102539],
        [80.0762481689456, 13.640972137451115],
        [80.0762481689456, 13.640693664550781],
        [80.07569122314482, 13.640693664550781],
        [80.07569122314482, 13.64041614532465],
        [80.07541656494146, 13.640420913696346],
        [80.07541656494146, 13.640139579773063],
        [80.07512664794933, 13.640139579773063],
        [80.07512664794933, 13.639579772949219],
        [80.07458496093767, 13.639583587646598],
        [80.07458496093767, 13.639027595520133],
        [80.07430267334007, 13.639031410217285],
        [80.07430267334007, 13.638740539550895],
        [80.07402801513689, 13.638750076294002],
        [80.07402801513689, 13.638194084167537],
        [80.07375335693388, 13.638194084167537],
        [80.07375335693388, 13.637915611267147],
        [80.07347106933611, 13.637921333313102],
        [80.07347106933611, 13.637359619140682],
        [80.0731964111331, 13.637361526489258],
        [80.0731964111331, 13.637084007263184],
        [80.07263946533232, 13.637084007263184],
        [80.07263946533232, 13.636528015136719],
        [80.07235717773443, 13.636529922485295],
        [80.07235717773443, 13.635972023010254],
        [80.07208251953136, 13.635972023010254],
        [80.07208251953136, 13.635417938232536],
        [80.07180786132818, 13.635419845581112],
        [80.07180786132818, 13.63486194610607],
        [80.07152557373041, 13.63486194610607],
        [80.07152557373041, 13.633749961853141],
        [80.07125091552757, 13.633749961853141],
        [80.07125091552757, 13.632641792297363],
        [80.07096862792986, 13.632641792297363],
        [80.07096862792986, 13.63181018829357],
        [80.07068634033209, 13.63181018829357],
        [80.07068634033209, 13.63069057464611],
        [80.07041931152361, 13.630693435669002],
        [80.07041931152361, 13.628195762634334],
        [80.07069396972662, 13.628195762634334],
        [80.07069396972662, 13.62763881683361],
        [80.07096862792986, 13.627641677856502],
        [80.07096862792986, 13.627361297607479],
        [80.07125091552757, 13.627361297607479],
        [80.07125091552757, 13.626805305481014],
        [80.07152557373041, 13.626805305481014],
        [80.07152557373041, 13.62569522857666],
        [80.07180786132818, 13.62569522857666],
        [80.07180786132818, 13.624650955200309],
        [80.07180786132818, 13.622361183166618],
        [80.07152557373041, 13.622361183166618],
        [80.07152557373041, 13.620972633361873],
        [80.07125091552757, 13.620972633361873],
        [80.07125091552757, 13.619860649108944],
        [80.07096862792986, 13.619860649108944],
        [80.07096862792986, 13.617361068725529],
        [80.07125091552757, 13.617361068725529],
        [80.07125091552757, 13.614570617675781],
        [80.07180786132818, 13.614582061767635],
        [80.07180786132818, 13.614306449890137],
        [80.07235717773443, 13.614311218261832],
        [80.07235717773443, 13.614030838012638],
        [80.07430267334007, 13.614030838012638],
        [80.07430267334007, 13.613750457763672],
        [80.07485961914068, 13.613750457763672],
        [80.07485961914068, 13.613470077514705],
        [80.07514190673822, 13.613471984863281],
        [80.07514190673822, 13.613194465637207],
        [80.07541656494146, 13.613194465637207],
        [80.07541656494146, 13.612360000610352],
        [80.07569122314482, 13.612360000610352],
        [80.07569122314482, 13.611820220947266],
        [80.07596588134766, 13.611820220947266],
        [80.07596588134766, 13.611531257629451],
        [80.0762481689456, 13.611531257629451],
        [80.0762481689456, 13.610692024230957],
        [80.07597351074219, 13.610693931579704],
        [80.07597351074219, 13.610137939453239],
        [80.07569122314482, 13.610139846801815],
        [80.07569122314482, 13.609860420227164],
        [80.07541656494146, 13.60986232757574],
        [80.07541656494146, 13.609581947326774],
        [80.07514190673822, 13.609581947326774],
        [80.07514190673822, 13.60875034332281],
        [80.07485961914068, 13.608760833740234],
        [80.07485961914068, 13.60847187042242],
        [80.07430267334007, 13.60847187042242],
        [80.07430267334007, 13.608194351196346],
        [80.07208251953136, 13.608194351196346],
        [80.07208251953136, 13.607915878295955],
        [80.07180786132818, 13.607919692993107],
        [80.07180786132818, 13.607640266418457],
        [80.07153320312517, 13.607640266418457],
        [80.07153320312517, 13.607361793518066],
        [80.07125091552757, 13.607361793518066],
        [80.07125091552757, 13.607078552246207],
        [80.07096862792986, 13.607084274291992],
        [80.07096862792986, 13.606805801391602],
        [80.07068634033209, 13.606809616088924],
        [80.07068634033209, 13.606530189514274],
        [80.07096862792986, 13.606530189514274],
        [80.07096862792986, 13.60596942901617],
        [80.07125091552757, 13.605972290039006],
        [80.07125091552757, 13.60541629791254],
        [80.06986236572266, 13.60542011260992],
        [80.06986236572266, 13.604580879211426],
        [80.07013702392607, 13.604583740234489],
        [80.07013702392607, 13.60291767120367],
        [80.06986236572266, 13.602919578552246],
        [80.06986236572266, 13.602361679077148],
        [80.06930541992193, 13.602361679077148],
        [80.06930541992193, 13.602083206176815],
        [80.06819152832054, 13.602083206176815],
        [80.06819152832054, 13.603193283081168],
        [80.06764221191412, 13.603193283081168],
        [80.06764221191412, 13.60291767120367],
        [80.06735992431658, 13.602919578552246],
        [80.06735992431658, 13.602629661560115],
        [80.06625366210943, 13.602640151977539],
        [80.06625366210943, 13.602361679077148],
        [80.06569671630865, 13.602361679077148],
        [80.06569671630865, 13.602081298828182],
        [80.06541442871088, 13.602083206176815],
        [80.06541442871088, 13.60124874114996],
        [80.06513977050804, 13.601251602172795],
        [80.06513977050804, 13.600971221923828],
        [80.06485748291033, 13.600971221923828],
        [80.06485748291033, 13.60069465637207],
        [80.06430816650408, 13.60069465637207],
        [80.06430816650408, 13.60041713714594],
        [80.06402587890653, 13.600421905517635],
        [80.06402587890653, 13.600141525268668],
        [80.06375122070312, 13.600141525268668],
        [80.06375122070312, 13.599861145019474],
        [80.06319427490234, 13.599861145019474],
        [80.06319427490234, 13.599582672119084],
        [80.06291961669933, 13.599590301513672],
        [80.06291961669933, 13.599028587341422],
        [80.06263732910162, 13.599028587341422],
        [80.06263732910162, 13.597360610961971],
        [80.06236267089838, 13.597360610961971],
        [80.06236267089838, 13.597083091735897],
        [80.06208038330084, 13.597083091735897],
        [80.06208038330084, 13.596527099609432],
        [80.06153106689459, 13.59653186798107],
        [80.06153106689459, 13.596250534057617],
        [80.06124877929705, 13.596250534057617],
        [80.06124877929705, 13.595690727234],
        [80.06097412109403, 13.595694541931152],
        [80.06097412109403, 13.595138549804688],
        [80.06041717529325, 13.59514141082775],
        [80.06041717529325, 13.594861030578613],
        [80.05986022949247, 13.594861030578613],
        [80.05986022949247, 13.594582557678223],
        [80.05930328369152, 13.594582557678223],
        [80.05930328369152, 13.594306945800895],
        [80.05874633789057, 13.594306945800895],
        [80.05874633789057, 13.594582557678223],
        [80.05819702148455, 13.594580650329647],
        [80.05819702148455, 13.594861030578613],
        [80.05764007568376, 13.594861030578613],
        [80.05764007568376, 13.59514141082775],
        [80.05735778808622, 13.59514141082775],
        [80.05735778808622, 13.595421791076717],
        [80.05709075927734, 13.595421791076717],
        [80.05709075927734, 13.59514141082775],
        [80.05680847167997, 13.59514141082775],
        [80.05680847167997, 13.595421791076717],
        [80.05652618408209, 13.595421791076717],
        [80.05652618408209, 13.596250534057617],
        [80.0559692382813, 13.596250534057617],
        [80.0559692382813, 13.595971107482967],
        [80.05569458007807, 13.595973014831543],
        [80.05569458007807, 13.595694541931152],
        [80.05541992187523, 13.595694541931152],
        [80.05541992187523, 13.595417022705078],
        [80.05513763427751, 13.595421791076717],
        [80.05513763427751, 13.59514141082775],
        [80.05486297607428, 13.59514141082775],
        [80.05486297607428, 13.594861030578613],
        [80.05430603027372, 13.594861030578613],
        [80.05430603027372, 13.594580650329647],
        [80.05403137207031, 13.594582557678223],
        [80.05403137207031, 13.594026565551758],
        [80.05430603027372, 13.594031333923397],
        [80.05430603027372, 13.593461990356445],
        [80.05458068847673, 13.593473434448356],
        [80.05458068847673, 13.592917442321891],
        [80.05486297607428, 13.592921257019043],
        [80.05486297607428, 13.591809272766113],
        [80.05513763427751, 13.591809272766113],
        [80.05513763427751, 13.59097003936779],
        [80.05541992187523, 13.590972900390682],
        [80.05541992187523, 13.59069538116455],
        [80.05486297607428, 13.59069538116455],
        [80.05486297607428, 13.59041690826416],
        [80.0523605346682, 13.59042072296154],
        [80.0523605346682, 13.590141296386662],
        [80.05208587646501, 13.590141296386662],
        [80.05208587646501, 13.589859962463436],
        [80.05152893066423, 13.589859962463436],
        [80.05152893066423, 13.58957958221447],
        [80.05124664306669, 13.589582443237305],
        [80.05124664306669, 13.58902645111084],
        [80.05095672607439, 13.589031219482536],
        [80.05095672607439, 13.588191986083984],
        [80.05124664306669, 13.588193893432617],
        [80.05124664306669, 13.587637901306096],
        [80.05152893066423, 13.587650299072322],
        [80.05152893066423, 13.587360382080021],
        [80.05180358886724, 13.587360382080021],
        [80.05180358886724, 13.58708190917963],
        [80.05208587646501, 13.58708190917963],
        [80.05208587646501, 13.586806297302303],
        [80.0523605346682, 13.586811065673828],
        [80.0523605346682, 13.585420608520451],
        [80.05264282226557, 13.585399627685604],
        [80.05263519287104, 13.584859848022518],
        [80.0529174804688, 13.584859848022518],
        [80.0529174804688, 13.584305763244629],
        [80.05319213867199, 13.584310531616325],
        [80.05319213867199, 13.58403015136713],
        [80.053466796875, 13.58403015136713],
        [80.053466796875, 13.58375167846674],
        [80.05319213867199, 13.58375167846674],
        [80.05319213867199, 13.583470344543514],
        [80.05347442626959, 13.58347225189209],
        [80.05347442626959, 13.582916259765625],
        [80.05374908447294, 13.582921028137264],
        [80.05374908447294, 13.58236026763916],
        [80.05403137207031, 13.582362174987907],
        [80.05403137207031, 13.581806182861442],
        [80.05430603027372, 13.58181095123291],
        [80.05430603027372, 13.581521034240836],
        [80.05458068847673, 13.581527709961051],
        [80.05458068847673, 13.581250190734977],
        [80.05486297607428, 13.581250190734977],
        [80.05486297607428, 13.580692291259709],
        [80.05513763427751, 13.580694198608512],
        [80.05513763427751, 13.580415725708121],
        [80.05541992187523, 13.580419540405273],
        [80.05541992187523, 13.580140113830623],
        [80.05568695068354, 13.580140113830623],
        [80.05568695068354, 13.579859733581657],
        [80.0559692382813, 13.579859733581657],
        [80.0559692382813, 13.579579353332463],
        [80.05625152587902, 13.579584121704158],
        [80.05625152587902, 13.579305648803768],
        [80.05652618408209, 13.579310417175407],
        [80.05652618408209, 13.57903003692627],
        [80.05680847167997, 13.57903003692627],
        [80.05680847167997, 13.578749656677303],
        [80.05709075927734, 13.578749656677303],
        [80.05709075927734, 13.578459739685172],
        [80.05735778808622, 13.578472137451172],
        [80.05735778808622, 13.57791805267334],
        [80.05764007568376, 13.577919960022086],
        [80.05764007568376, 13.57763957977295],
        [80.05790710449224, 13.57763957977295],
        [80.05790710449224, 13.577359199523983],
        [80.05819702148455, 13.577362060546818],
        [80.05819702148455, 13.577083587646484],
        [80.05847167968773, 13.577083587646484],
        [80.05847167968773, 13.576806068420353],
        [80.05874633789057, 13.576811790466309],
        [80.05874633789057, 13.576530456543026],
        [80.05902862548834, 13.576530456543026],
        [80.05902862548834, 13.576251983642692],
        [80.05930328369152, 13.576251983642692],
        [80.05930328369152, 13.575970649719238],
        [80.05986022949247, 13.575970649719238],
        [80.05986022949247, 13.575693130493107],
        [80.06014251708984, 13.575693130493107],
        [80.06014251708984, 13.575418472290096],
        [80.06069183349626, 13.575420379638672],
        [80.06069183349626, 13.575141906738338],
        [80.06096649169928, 13.575141906738338],
        [80.06096649169928, 13.574860572815055],
        [80.06153106689459, 13.574860572815055],
        [80.06153106689459, 13.57458305358898],
        [80.06180572509783, 13.57458305358898],
        [80.06180572509783, 13.57430458068859],
        [80.06236267089838, 13.574310302734318],
        [80.06236267089838, 13.574031829833984],
        [80.06291961669933, 13.574031829833984],
        [80.06291961669933, 13.573752403259277],
        [80.06375122070312, 13.573752403259277],
        [80.06375122070312, 13.573471069335994],
        [80.06430816650408, 13.573471069335994],
        [80.06430816650408, 13.573195457458496],
        [80.06513977050804, 13.573195457458496],
        [80.06513977050804, 13.572916984558105],
        [80.06569671630865, 13.572921752929801],
        [80.06569671630865, 13.572650909423942],
        [80.06652832031278, 13.572650909423942],
        [80.06652832031278, 13.572080612182674],
        [80.06680297851562, 13.572083473205566],
        [80.06680297851562, 13.571249008178711],
        [80.0670928955081, 13.571250915527287],
        [80.0670928955081, 13.57069110870367],
        [80.06735992431658, 13.570693016052246],
        [80.06735992431658, 13.569861412048454],
        [80.06764221191412, 13.569861412048454],
        [80.06764221191412, 13.569026947021598],
        [80.06791687011736, 13.56903076171875],
        [80.06791687011736, 13.56818962097168],
        [80.06819152832054, 13.568195343017635],
        [80.06819152832054, 13.56736087799078],
        [80.06846618652338, 13.56736087799078],
        [80.06846618652338, 13.56681156158453],
        [80.06874847412115, 13.56681156158453],
        [80.06874847412115, 13.565971374511719],
        [80.06903076171886, 13.565973281860352],
        [80.06903076171886, 13.56541728973383],
        [80.069320678711, 13.565419197082633],
        [80.069320678711, 13.564861297607365],
        [80.06958007812528, 13.564861297607365],
        [80.06958007812528, 13.564584732055778],
        [80.06986236572266, 13.564584732055778],
        [80.06986236572266, 13.5643053054809],
        [80.07013702392607, 13.564311027526855],
        [80.07013702392607, 13.563751220703182],
        [80.07041931152361, 13.563751220703182],
        [80.07041931152361, 13.563461303710938],
        [80.07069396972662, 13.563472747802848],
        [80.07069396972662, 13.563195228576717],
        [80.07096862792986, 13.563195228576717],
        [80.07096862792986, 13.562638282775993],
        [80.07125091552757, 13.562641143798828],
        [80.07125091552757, 13.562080383300895],
        [80.07152557373041, 13.562082290649471],
        [80.07152557373041, 13.561529159545898],
        [80.07180786132818, 13.561531066894474],
        [80.07180786132818, 13.560689926147575],
        [80.07208251953136, 13.560693740844783],
        [80.07208251953136, 13.560137748718262],
        [80.07235717773443, 13.560140609741154],
        [80.07235717773443, 13.559310913085994],
        [80.07263946533232, 13.559310913085994],
        [80.07263946533232, 13.558750152587834],
        [80.07290649414062, 13.558750152587834],
        [80.07290649414062, 13.558190345764217],
        [80.07347106933611, 13.55819416046154],
        [80.07347106933611, 13.557915687560978],
        [80.07402801513689, 13.557921409606934],
        [80.07402801513689, 13.55764007568365],
        [80.0745925903322, 13.55764007568365],
        [80.0745925903322, 13.557359695434684],
        [80.07485961914068, 13.557359695434684],
        [80.07485961914068, 13.557080268859863],
        [80.07541656494146, 13.557084083557186],
        [80.07541656494146, 13.556805610656795],
        [80.07596588134766, 13.55681133270275],
        [80.07596588134766, 13.556529998779297],
        [80.07653045654297, 13.556529998779297],
        [80.07653045654297, 13.55597019195568],
        [80.07680511474638, 13.555972099304256],
        [80.07680511474638, 13.5551376342774],
        [80.07707977294939, 13.555139541625977],
        [80.07707977294939, 13.554581642150936],
        [80.07736206054693, 13.554583549499512],
        [80.07736206054693, 13.554027557373047],
        [80.0784759521485, 13.55403041839611],
        [80.0784759521485, 13.553750038146973],
        [80.07985687255888, 13.553750038146973],
        [80.07985687255888, 13.553469657898006],
        [80.08097076416038, 13.553471565246582],
        [80.08097076416038, 13.553194046020508],
        [80.08208465576178, 13.553194046020508],
        [80.08208465576178, 13.552915573120117],
        [80.08319091796892, 13.552920341491756],
        [80.08319091796892, 13.55263996124279],
        [80.08458709716803, 13.55263996124279],
        [80.08458709716803, 13.552359580993652],
        [80.085693359375, 13.552362442016715],
        [80.085693359375, 13.552083969116325],
        [80.08625030517595, 13.552083969116325],
        [80.08625030517595, 13.551806449890194],
        [80.08653259277372, 13.551810264587402],
        [80.08653259277372, 13.551521301269588],
        [80.08708190917991, 13.55152797698986],
        [80.08708190917991, 13.551250457763729],
        [80.08735656738276, 13.551250457763729],
        [80.08735656738276, 13.550971984863338],
        [80.08791351318371, 13.550971984863338],
        [80.08791351318371, 13.550136566162223],
        [80.0881958007813, 13.550140380859375],
        [80.0881958007813, 13.54875183105463],
        [80.08847045898466, 13.54875183105463],
        [80.08847045898466, 13.547641754150504],
        [80.0881958007813, 13.547641754150504],
        [80.0881958007813, 13.547080993652344],
        [80.08847045898466, 13.54708290100092],
        [80.08847045898466, 13.546526908874455],
        [80.08875274658203, 13.54653167724615],
        [80.08875274658203, 13.54597091674799],
        [80.08902740478544, 13.54597091674799],
        [80.08902740478544, 13.545695304870662],
        [80.08930206298845, 13.545695304870662],
        [80.08930206298845, 13.545139312744197],
        [80.08957672119146, 13.545139312744197],
        [80.08957672119146, 13.544581413269157],
        [80.08985900878923, 13.544583320617733],
        [80.08985900878923, 13.544304847717342],
        [80.09014129638678, 13.54430961608881],
        [80.09014129638678, 13.543750762939453],
        [80.09041595459001, 13.543750762939453],
        [80.09041595459001, 13.543190956115836],
        [80.09069061279303, 13.543194770812988],
        [80.09069824218773, 13.542917251586914],
        [80.09152984619152, 13.542922019958553],
        [80.09152984619152, 13.5426406860351],
        [80.09208679199247, 13.5426406860351],
        [80.09208679199247, 13.54234981536871],
        [80.09291839599626, 13.54236125946045],
        [80.09291839599626, 13.542082786560059],
        [80.09347534179705, 13.542082786560059],
        [80.09347534179705, 13.541805267333984],
        [80.09430694580084, 13.5418119430542],
        [80.09430694580084, 13.541530609130916],
        [80.09513854980497, 13.541530609130916],
        [80.09513854980497, 13.541251182556266],
        [80.09570312500028, 13.541251182556266],
        [80.09569549560575, 13.540970802307129],
        [80.09652709960955, 13.540970802307129],
        [80.09652709960955, 13.540695190429801],
        [80.09680175781273, 13.540695190429801],
        [80.09680175781273, 13.540139198303336],
        [80.09707641601557, 13.540141105651912],
        [80.09707641601557, 13.539580345153809],
        [80.09735870361334, 13.539583206176871],
        [80.09735870361334, 13.537917137145996],
        [80.09764099121105, 13.537920951843375],
        [80.09764099121105, 13.536540031433162],
        [80.098197937012, 13.536540031433162],
        [80.098197937012, 13.536251068115178],
        [80.0990295410158, 13.536251068115178],
        [80.0990295410158, 13.535969734191951],
        [80.09958648681658, 13.535972595214787],
        [80.09958648681658, 13.535138130187931],
        [80.09929656982428, 13.535140991210994],
        [80.09929656982428, 13.53403091430664],
        [80.0990295410158, 13.53403091430664],
        [80.0990295410158, 13.532920837402287],
        [80.09874725341825, 13.532920837402287],
        [80.09874725341825, 13.53181076049816],
        [80.09847259521484, 13.53181076049816],
        [80.09847259521484, 13.53125],
        [80.09874725341825, 13.53125],
        [80.09874725341825, 13.530690193176383],
        [80.0990295410158, 13.530694007873535],
        [80.0990295410158, 13.530415534973145],
        [80.0993041992188, 13.530415534973145],
        [80.0993041992188, 13.529862403869743],
        [80.09958648681658, 13.529862403869743],
        [80.09958648681658, 13.529582023620605],
        [80.09986114501976, 13.529582023620605],
        [80.09986114501976, 13.529027938842887],
        [80.1001358032226, 13.529029846191463],
        [80.1001358032226, 13.528750419616813],
        [80.10069274902355, 13.528750419616813],
        [80.10069274902355, 13.528471946716422],
        [80.1012496948245, 13.528471946716422],
        [80.1012496948245, 13.528194427490348],
        [80.10180664062528, 13.528194427490348],
        [80.10180664062528, 13.527915954589957],
        [80.10263824462908, 13.527922630310002],
        [80.10263824462908, 13.52764034271246],
        [80.10320281982439, 13.52764034271246],
        [80.10320281982439, 13.527351379394645],
        [80.10375213623041, 13.527361869812069],
        [80.10375213623041, 13.52652835845953],
        [80.10402679443365, 13.526530265808105],
        [80.10402679443365, 13.525420188903922],
        [80.10430908203136, 13.525420188903922],
        [80.10430908203136, 13.524860382080078],
        [80.10485839843778, 13.524862289428654],
        [80.10485839843778, 13.52458381652832],
        [80.10541534423857, 13.52458381652832],
        [80.10541534423857, 13.52430629730219],
        [80.10597229003912, 13.524310111999569],
        [80.10597229003912, 13.524029731750602],
        [80.10652923584007, 13.524029731750602],
        [80.10652923584007, 13.523750305175724],
        [80.10679626464855, 13.523750305175724],
        [80.10679626464855, 13.523191452026367],
        [80.10708618164068, 13.523193359374943],
        [80.10708618164068, 13.522640228271541],
        [80.10736083984386, 13.522640228271541],
        [80.10736083984386, 13.522358894348088],
        [80.10763549804688, 13.52236175537115],
        [80.10763549804688, 13.521804809570426],
        [80.10791778564482, 13.521809577941895],
        [80.10791778564482, 13.521251678466797],
        [80.10820007324219, 13.521251678466797],
        [80.10820007324219, 13.52097129821783],
        [80.1084747314456, 13.52097129821783],
        [80.1084747314456, 13.520415306091365],
        [80.10874938964861, 13.520421981811637],
        [80.10874938964861, 13.519861221313477],
        [80.10902404785162, 13.519861221313477],
        [80.10902404785162, 13.519305229187012],
        [80.11013793945318, 13.51930999755865],
        [80.11013793945318, 13.519029617309513],
        [80.11068725585938, 13.519029617309513],
        [80.11068725585938, 13.518749237060547],
        [80.1115264892581, 13.518749237060547],
        [80.1115264892581, 13.518470764160156],
        [80.11208343505865, 13.518470764160156],
        [80.11207580566412, 13.517642021179256],
        [80.11180877685564, 13.517642021179256],
        [80.11180877685564, 13.516531944274902],
        [80.11207580566412, 13.516531944274902],
        [80.11207580566412, 13.516810417175293],
        [80.11235809326189, 13.516804695129508],
        [80.11235809326189, 13.517083168029899],
        [80.1126403808596, 13.517081260681152],
        [80.1126403808596, 13.517360687255803],
        [80.1129226684572, 13.517360687255803],
        [80.1129226684572, 13.517642021179256],
        [80.11486053466814, 13.517642021179256],
        [80.11486053466814, 13.517360687255803],
        [80.11596679687494, 13.517360687255803],
        [80.11596679687494, 13.517081260681152],
        [80.11736297607422, 13.517083168029899],
        [80.11736297607422, 13.516527175903434],
        [80.11763763427763, 13.516531944274902],
        [80.11763763427763, 13.51597118377697],
        [80.117919921875, 13.515973091125545],
        [80.117919921875, 13.51541709899908],
        [80.11819458007841, 13.51541709899908],
        [80.11819458007841, 13.51513862609869],
        [80.1190261840822, 13.515140533447266],
        [80.1190261840822, 13.514580726623649],
        [80.11930847167991, 13.514582633972225],
        [80.11930847167991, 13.513751029968262],
        [80.11958312988276, 13.513751029968262],
        [80.11958312988276, 13.512080192565975],
        [80.11930847167991, 13.51208305358881],
        [80.11930847167991, 13.511804580688477],
        [80.11763763427763, 13.511804580688477],
        [80.11763763427763, 13.51208305358881],
        [80.11708068847685, 13.512080192565975],
        [80.11708068847685, 13.512372016906795],
        [80.11624908447271, 13.512372016906795],
        [80.11624908447271, 13.511530876159725],
        [80.11596679687494, 13.511530876159725],
        [80.11596679687494, 13.510418891906795],
        [80.1156921386721, 13.510418891906795],
        [80.1156921386721, 13.509310722351188],
        [80.11486053466814, 13.509310722351188],
        [80.11486053466814, 13.50903129577631],
        [80.11429595947266, 13.50903129577631],
        [80.11429595947266, 13.508750915527344],
        [80.11458587646513, 13.508750915527344],
        [80.11458587646513, 13.508469581604118],
        [80.11486053466814, 13.50847339630127],
        [80.11486053466814, 13.50819396972662],
        [80.11513519287115, 13.50819396972662],
        [80.11513519287115, 13.507916450500488],
        [80.11541748046892, 13.507921218872184],
        [80.11541748046892, 13.50764083862299],
        [80.11596679687494, 13.50764083862299],
        [80.11596679687494, 13.507360458374023],
        [80.11653137207026, 13.507360458374023],
        [80.11653137207026, 13.507081985473633],
        [80.11708068847685, 13.507081985473633],
        [80.11708068847685, 13.506806373596135],
        [80.11763763427763, 13.506806373596135],
        [80.11763763427763, 13.507081985473633],
        [80.1173706054688, 13.507080078125057],
        [80.1173706054688, 13.50764083862299],
        [80.11708068847685, 13.507637977600098],
        [80.11708068847685, 13.50819015502941],
        [80.1168060302735, 13.50819396972662],
        [80.1168060302735, 13.510695457458553],
        [80.117919921875, 13.510695457458553],
        [80.117919921875, 13.50847339630127],
        [80.11819458007841, 13.50847339630127],
        [80.11819458007841, 13.506527900695744],
        [80.11846923828142, 13.506530761718807],
        [80.11846923828142, 13.506240844726562],
        [80.1190261840822, 13.50625038146967],
        [80.1190261840822, 13.505971908569279],
        [80.12014007568371, 13.505971908569279],
        [80.12014007568371, 13.505694389343375],
        [80.12124633789091, 13.505694389343375],
        [80.12124633789091, 13.505415916442814],
        [80.12236022949241, 13.505420684814453],
        [80.12236022949241, 13.505140304565487],
        [80.12317657470732, 13.505140304565487],
        [80.12317657470732, 13.505420684814453],
        [80.12430572509794, 13.505415916442814],
        [80.12430572509794, 13.505694389343375],
        [80.12541961669945, 13.505694389343375],
        [80.12541961669945, 13.50625038146967],
        [80.12569427490229, 13.50625038146967],
        [80.12569427490229, 13.507081985473633],
        [80.12596893310553, 13.507080078125057],
        [80.12596893310553, 13.50764083862299],
        [80.12625122070324, 13.507637977600098],
        [80.12625122070324, 13.50847339630127],
        [80.1265258789063, 13.508469581604118],
        [80.1265258789063, 13.509310722351188],
        [80.12680816650419, 13.509310722351188],
        [80.12680816650419, 13.511251449585018],
        [80.1265258789063, 13.511251449585018],
        [80.1265258789063, 13.513195037841797],
        [80.12625122070324, 13.513195037841797],
        [80.12625122070324, 13.513472557067871],
        [80.12596893310553, 13.513470649719295],
        [80.12596893310553, 13.513751029968262],
        [80.12541961669945, 13.513751029968262],
        [80.12541961669945, 13.514031410217399],
        [80.12513732910173, 13.514031410217399],
        [80.12513732910173, 13.514311790466365],
        [80.1248626708985, 13.51430511474615],
        [80.1248626708985, 13.515694618225154],
        [80.12513732910173, 13.515694618225154],
        [80.12513732910173, 13.515973091125545],
        [80.12541961669945, 13.51597118377697],
        [80.12541961669945, 13.516531944274902],
        [80.12568664550776, 13.516531944274902],
        [80.12568664550776, 13.516810417175293],
        [80.12680816650419, 13.516804695129508],
        [80.12680816650419, 13.517083168029899],
        [80.12847137451195, 13.517083168029899],
        [80.12847137451195, 13.516804695129508],
        [80.12902832031256, 13.516810417175293],
        [80.12902832031256, 13.516531944274902],
        [80.12985992431669, 13.516531944274902],
        [80.12985992431669, 13.51625061035162],
        [80.13041687011747, 13.51625061035162],
        [80.13041687011747, 13.51597118377697],
        [80.13097381591825, 13.515973091125545],
        [80.13097381591825, 13.515694618225154],
        [80.1315307617188, 13.515694618225154],
        [80.1315307617188, 13.51541709899908],
        [80.13180541992205, 13.51541709899908],
        [80.13180541992205, 13.51513862609869],
        [80.13236999511713, 13.515140533447266],
        [80.13236999511713, 13.514861106872615],
        [80.13291931152355, 13.514861106872615],
        [80.13291931152355, 13.514582633972225],
        [80.13319396972656, 13.514582633972225],
        [80.13319396972656, 13.51430511474615],
        [80.13375091552734, 13.514311790466365],
        [80.13375091552734, 13.514031410217399],
        [80.13403320312528, 13.514031410217399],
        [80.13403320312528, 13.513751029968262],
        [80.1343078613283, 13.513751029968262],
        [80.1343078613283, 13.513470649719295],
        [80.13486480712908, 13.513472557067871],
        [80.13486480712908, 13.513195037841797],
        [80.13513946533226, 13.513195037841797],
        [80.13513946533226, 13.512916564941406],
        [80.13569641113287, 13.512921333313045],
        [80.13569641113287, 13.512640953064079],
        [80.13597106933605, 13.512640953064079],
        [80.13597106933605, 13.512372016906795],
        [80.13817596435553, 13.512372016906795],
        [80.13817596435553, 13.512080192565975],
        [80.13847351074213, 13.51208305358881],
        [80.13847351074213, 13.511804580688477],
        [80.13874816894537, 13.511809349060115],
        [80.13874816894537, 13.511530876159725],
        [80.13903045654308, 13.511530876159725],
        [80.13903045654308, 13.511251449585018],
        [80.13931274414068, 13.511251449585018],
        [80.13931274414068, 13.510689735412598],
        [80.1395797729495, 13.510695457458553],
        [80.1395797729495, 13.510416984558162],
        [80.13986206054688, 13.510418891906795],
        [80.13986206054688, 13.510141372680664],
        [80.14013671875028, 13.510141372680664],
        [80.14013671875028, 13.509860992431697],
        [80.14041900634783, 13.509860992431697],
        [80.14041900634783, 13.509579658508244],
        [80.14069366455084, 13.509581565857047],
        [80.14069366455084, 13.509306907653809],
        [80.14097595214861, 13.509310722351188],
        [80.14097595214861, 13.50903129577631],
        [80.14125061035179, 13.50903129577631],
        [80.14125061035179, 13.508750915527344],
        [80.14154052734392, 13.508750915527344],
        [80.14154052734392, 13.508469581604118],
        [80.1418075561524, 13.50847339630127],
        [80.1418075561524, 13.50819396972662],
        [80.14208221435558, 13.50819396972662],
        [80.14208221435558, 13.507916450500488],
        [80.14235687255865, 13.507921218872184],
        [80.14235687255865, 13.50764083862299],
        [80.14263916015653, 13.50764083862299],
        [80.14263916015653, 13.507360458374023],
        [80.14291381835938, 13.507360458374023],
        [80.14291381835938, 13.507081985473633],
        [80.14319610595732, 13.507081985473633],
        [80.14319610595732, 13.506527900695744],
        [80.14347076416033, 13.506530761718807],
        [80.14347076416033, 13.505970001220703],
        [80.1437530517581, 13.505971908569279],
        [80.1437530517581, 13.505415916442814],
        [80.14402770996111, 13.505420684814453],
        [80.14402770996111, 13.504579544067383],
        [80.14430236816429, 13.504584312439022],
        [80.14430236816429, 13.504028320312557],
        [80.14457702636713, 13.50403118133545],
        [80.14457702636713, 13.503470420837516],
        [80.1448593139649, 13.503472328186092],
        [80.1448593139649, 13.502916336059627],
        [80.14514160156244, 13.502921104431266],
        [80.14514160156244, 13.502081871032772],
        [80.14541625976568, 13.502083778381348],
        [80.14541625976568, 13.501527786254883],
        [80.14569091796903, 13.501529693603459],
        [80.14569091796903, 13.500971794128418],
        [80.1459732055664, 13.500971794128418],
        [80.1459732055664, 13.500432014465332],
        [80.14624786376982, 13.500432014465332],
        [80.14624786376982, 13.499859809875488],
        [80.14653015136719, 13.499859809875488],
        [80.14653015136719, 13.49930572509777],
        [80.14679718017584, 13.499309539794922],
        [80.14679718017584, 13.498749732971305],
        [80.14708709716814, 13.498749732971305],
        [80.14708709716814, 13.498190879821777],
        [80.14736175537115, 13.49819374084484],
        [80.14736175537115, 13.49791622161871],
        [80.14763641357439, 13.497920036315918],
        [80.14763641357439, 13.497370719909668],
        [80.1479187011721, 13.497370719909668],
        [80.1479187011721, 13.49707889556879],
        [80.14819335937494, 13.497083663940487],
        [80.14819335937494, 13.496806144714355],
        [80.1487503051759, 13.496809959411735],
        [80.1487503051759, 13.496529579162598],
        [80.1490325927735, 13.496529579162598],
        [80.1490325927735, 13.49625015258789],
        [80.14958190917969, 13.49625015258789],
        [80.14958190917969, 13.49597072601324],
        [80.14986419677763, 13.495973587036133],
        [80.14986419677763, 13.49569320678711],
        [80.15013885498064, 13.49569320678711],
        [80.15013885498064, 13.495417594909611],
        [80.15069580078142, 13.495420455932674],
        [80.15069580078142, 13.49514198303234],
        [80.1509704589846, 13.49514198303234],
        [80.1509704589846, 13.494861602783146],
        [80.15152740478521, 13.494861602783146],
        [80.15152740478521, 13.49458122253418],
        [80.1518020629884, 13.494583129882756],
        [80.1518020629884, 13.494304656982422],
        [80.15235900878935, 13.494304656982422],
        [80.15235900878935, 13.49402713775629],
        [80.15319061279314, 13.494031906127987],
        [80.15319824218767, 13.493751525878963],
        [80.15429687499994, 13.493751525878963],
        [80.15429687499994, 13.493471145629826],
        [80.15486145019543, 13.493471145629826],
        [80.15486145019543, 13.493195533752498],
        [80.15569305419922, 13.493195533752498],
        [80.15569305419922, 13.492917060852108],
        [80.15625000000017, 13.492921829223633],
        [80.15625000000017, 13.492638587951774],
        [80.15708160400413, 13.492638587951774],
        [80.15708160400413, 13.492361068725643],
        [80.15735626220697, 13.492361068725643],
        [80.15735626220697, 13.492080688476676],
        [80.15763854980474, 13.492082595825252],
        [80.15763854980474, 13.491805076599178],
        [80.15791320800793, 13.49181175231945],
        [80.15791320800793, 13.49152946472168],
        [80.15847015380888, 13.49152946472168],
        [80.15847015380888, 13.491239547729606],
        [80.15875244140625, 13.491249084472713],
        [80.15875244140625, 13.490973472595215],
        [80.15902709960966, 13.490973472595215],
        [80.15902709960966, 13.490693092346248],
        [80.15958404541044, 13.490693092346248],
        [80.15958404541044, 13.490416526794434],
        [80.160140991211, 13.490420341491813],
        [80.160140991211, 13.490140914916935],
        [80.16097259521479, 13.490140914916935],
        [80.16097259521479, 13.489860534667969],
        [80.16152954101574, 13.489860534667969],
        [80.16152954101574, 13.489583015441895],
        [80.16263580322294, 13.489583015441895],
        [80.16263580322294, 13.489860534667969],
        [80.16374969482445, 13.489860534667969],
        [80.16374969482445, 13.490140914916935],
        [80.16458129882824, 13.490140914916935],
        [80.16458129882824, 13.490420341491813],
        [80.16569519042997, 13.490416526794434],
        [80.16569519042997, 13.490693092346248],
        [80.16708374023432, 13.490693092346248],
        [80.16708374023432, 13.490416526794434],
        [80.16735839843756, 13.490420341491813],
        [80.16735839843756, 13.490140914916935],
        [80.16792297363287, 13.490140914916935],
        [80.16792297363287, 13.489860534667969],
        [80.16819763183622, 13.489860534667969],
        [80.16819763183622, 13.490140914916935],
        [80.1684799194336, 13.490140914916935],
        [80.1684799194336, 13.490420341491813],
        [80.16902923584001, 13.490416526794434],
        [80.16902923584001, 13.490693092346248],
        [80.16930389404303, 13.490693092346248],
        [80.16930389404303, 13.490973472595215],
        [80.1695861816408, 13.490973472595215],
        [80.1695861816408, 13.491249084472713],
        [80.16986083984398, 13.491239547729606],
        [80.16986083984398, 13.49152946472168],
        [80.17041778564459, 13.49152946472168],
        [80.17041778564459, 13.49181175231945],
        [80.17069244384777, 13.491805076599178],
        [80.17069244384777, 13.492082595825252],
        [80.17096710205084, 13.492080688476676],
        [80.17096710205084, 13.492361068725643],
        [80.17151641845703, 13.492361068725643],
        [80.17151641845703, 13.492638587951774],
        [80.17208099365251, 13.492638587951774],
        [80.17208099365251, 13.492921829223633],
        [80.1726379394533, 13.492917060852108],
        [80.1726379394533, 13.493195533752498],
        [80.17319488525408, 13.493195533752498],
        [80.17319488525408, 13.493471145629826],
        [80.17375183105463, 13.493471145629826],
        [80.17375183105463, 13.493751525878963],
        [80.17430877685558, 13.493751525878963],
        [80.17430877685558, 13.494031906127987],
        [80.17514038085938, 13.49402713775629],
        [80.17514038085938, 13.494304656982422],
        [80.17624664306658, 13.494304656982422],
        [80.17624664306658, 13.494583129882756],
        [80.1770858764649, 13.49458122253418],
        [80.1770858764649, 13.494861602783146],
        [80.17958068847679, 13.494861602783146],
        [80.17958068847679, 13.49458122253418],
        [80.1812515258789, 13.494583129882756],
        [80.1812515258789, 13.494304656982422],
        [80.18152618408232, 13.494304656982422],
        [80.18152618408232, 13.4937486648559],
        [80.18180847167986, 13.493751525878963],
        [80.18180847167986, 13.493471145629826],
        [80.18208312988287, 13.493471145629826],
        [80.18208312988287, 13.493195533752498],
        [80.18264007568382, 13.493195533752498],
        [80.18264007568382, 13.492917060852108],
        [80.18319702148443, 13.492921829223633],
        [80.18319702148443, 13.492638587951774],
        [80.18345642089872, 13.492638587951774],
        [80.18345642089872, 13.492361068725643],
        [80.18402862548857, 13.492361068725643],
        [80.18402862548857, 13.492080688476676],
        [80.18458557128935, 13.492082595825252],
        [80.18458557128935, 13.491805076599178],
        [80.18569183349632, 13.49181175231945],
        [80.18569183349632, 13.49152946472168],
        [80.18763732910185, 13.49152946472168],
        [80.18763732910185, 13.491239547729606],
        [80.18930816650413, 13.491249084472713],
        [80.18930816650413, 13.490973472595215],
        [80.19097137451172, 13.490973472595215],
        [80.19097137451172, 13.490693092346248],
        [80.192642211914, 13.490693092346248],
        [80.192642211914, 13.490416526794434],
        [80.19403076171875, 13.490420341491813],
        [80.19403076171875, 13.490140914916935],
        [80.19568634033197, 13.490140914916935],
        [80.19568634033197, 13.489860534667969],
        [80.19736480712919, 13.489860534667969],
        [80.19736480712919, 13.489583015441895],
        [80.19902801513678, 13.489583015441895],
        [80.19902801513678, 13.489304542541504],
        [80.2006912231447, 13.48931026458746],
        [80.2006912231447, 13.489031791687069],
        [80.20236206054682, 13.489031791687069],
        [80.20236206054682, 13.488751411438102],
        [80.2040328979495, 13.488751411438102],
        [80.2040328979495, 13.488471031188965],
        [80.2048645019533, 13.488471031188965],
        [80.2048645019533, 13.488195419311637],
        [80.20569610595709, 13.488200187683105],
        [80.20569610595709, 13.487921714782715],
        [80.20652770996122, 13.487921714782715],
        [80.20652770996122, 13.487641334533748],
        [80.20764923095732, 13.487641334533748],
        [80.20764923095732, 13.487360954284782],
        [80.20847320556635, 13.487360954284782],
        [80.20847320556635, 13.487080574035645],
        [80.20930480957037, 13.487083435058707],
        [80.20930480957037, 13.486804962158317],
        [80.21153259277361, 13.486811637878532],
        [80.21153259277361, 13.486531257629395],
        [80.21402740478533, 13.486531257629395],
        [80.21402740478533, 13.486250877380428],
        [80.21624755859403, 13.486250877380428],
        [80.21624755859403, 13.485971450805721],
        [80.22569274902344, 13.485973358154354],
        [80.22569274902344, 13.485694885253963],
        [80.22624969482439, 13.485694885253963],
        [80.22624969482439, 13.485417366027832],
        [80.22708129882835, 13.485429763794059],
        [80.22708129882835, 13.485138893127498],
        [80.22791290283214, 13.485138893127498],
        [80.22791290283214, 13.484861373901367],
        [80.2284698486331, 13.484861373901367],
        [80.2284698486331, 13.485138893127498],
        [80.22985839843767, 13.485138893127498],
        [80.22985839843767, 13.485429763794059],
        [80.23014068603521, 13.485429763794059],
        [80.23014068603521, 13.485138893127498],
        [80.23042297363298, 13.485138893127498],
        [80.23042297363298, 13.484861373901367],
        [80.230972290039, 13.484861373901367],
        [80.230972290039, 13.484582901000977],
        [80.23124694824224, 13.484582901000977],
        [80.23124694824224, 13.484305381774902],
        [80.23430633544928, 13.484309196472282],
        [80.23430633544928, 13.484030723571891],
        [80.237075805664, 13.484030723571891],
        [80.237075805664, 13.484309196472282],
        [80.23735809326178, 13.484305381774902],
        [80.23735809326178, 13.484582901000977],
        [80.23708343505854, 13.484582901000977],
        [80.23708343505854, 13.484861373901367],
        [80.2368087768557, 13.484861373901367],
        [80.2368087768557, 13.485138893127498],
        [80.23652648925798, 13.485138893127498],
        [80.23652648925798, 13.485429763794059],
        [80.23625183105474, 13.485417366027832],
        [80.23625183105474, 13.485694885253963],
        [80.2359695434572, 13.485694885253963],
        [80.2359695434572, 13.485973358154354],
        [80.23542022705078, 13.485971450805721],
        [80.23542022705078, 13.486250877380428],
        [80.23486328125006, 13.486250877380428],
        [80.23486328125006, 13.486811637878532],
        [80.2345733642581, 13.486811637878532],
        [80.2345733642581, 13.487360954284782],
        [80.23430633544928, 13.487360954284782],
        [80.23430633544928, 13.487921714782715],
        [80.23652648925798, 13.487921714782715],
        [80.23652648925798, 13.487641334533748],
        [80.2368087768557, 13.487641334533748],
        [80.2368087768557, 13.487360954284782],
        [80.23735809326178, 13.487360954284782],
        [80.23735809326178, 13.487080574035645],
        [80.23764038085949, 13.487083435058707],
        [80.23764038085949, 13.486804962158317],
        [80.23819732666044, 13.486811637878532],
        [80.23819732666044, 13.486531257629395],
        [80.23847198486328, 13.486531257629395],
        [80.23847198486328, 13.486250877380428],
        [80.23874664306669, 13.486250877380428],
        [80.23874664306669, 13.486049652099723],
        [80.23874664306669, 13.485973358154354],
        [80.2390213012697, 13.485973358154354],
        [80.23902893066423, 13.485417366027832],
        [80.23929595947271, 13.485429763794059],
        [80.23929595947271, 13.485138893127498],
        [80.23958587646501, 13.485138893127498],
        [80.23958587646501, 13.484861373901367],
        [80.2398605346682, 13.484861373901367],
        [80.2398605346682, 13.484305381774902],
        [80.2401428222658, 13.484309196472282],
        [80.2401428222658, 13.483751296997013],
        [80.2404174804688, 13.483751296997013],
        [80.2404174804688, 13.483189582824764],
        [80.24069213867199, 13.483195304870549],
        [80.24069213867199, 13.482639312744254],
        [80.24096679687506, 13.48264122009283],
        [80.24096679687506, 13.482080459594727],
        [80.24153137207031, 13.482082366943303],
        [80.24153137207031, 13.481806755065975],
        [80.24208068847673, 13.481810569763184],
        [80.24208068847673, 13.481531143188477],
        [80.24263763427751, 13.481531143188477],
        [80.24263763427751, 13.48125076293951],
        [80.24291992187506, 13.48125076293951],
        [80.24291992187506, 13.480970382690543],
        [80.2434692382813, 13.48097229003912],
        [80.2434692382813, 13.480693817138786],
        [80.24402618408209, 13.480693817138786],
        [80.24402618408209, 13.480416297912654],
        [80.24458312988281, 13.480421066284123],
        [80.24458312988281, 13.480140686035156],
        [80.24485778808622, 13.480140686035156],
        [80.24485778808622, 13.47986030578619],
        [80.2451400756836, 13.47986030578619],
        [80.2451400756836, 13.479584693908691],
        [80.245414733887, 13.479584693908691],
        [80.245414733887, 13.479304313659725],
        [80.24569702148455, 13.479304313659725],
        [80.24569702148455, 13.47902774810791],
        [80.24597167968756, 13.479030609130803],
        [80.24597167968756, 13.47846984863287],
        [80.2462463378908, 13.478471755981445],
        [80.2462463378908, 13.478194236755371],
        [80.24652862548851, 13.478194236755371],
        [80.24652862548851, 13.47791576385498],
        [80.24680328369135, 13.47792053222662],
        [80.24680328369135, 13.477640151977482],
        [80.24709320068365, 13.477640151977482],
        [80.24709320068365, 13.477359771728516],
        [80.2473602294923, 13.477359771728516],
        [80.2473602294923, 13.475691795349121],
        [80.2476425170899, 13.475693702697868],
        [80.2476425170899, 13.47541809082037],
        [80.2473602294923, 13.475420951843262],
        [80.2473602294923, 13.474310874939079],
        [80.24709320068365, 13.474310874939079],
        [80.24709320068365, 13.473469734192008],
        [80.24680328369135, 13.473471641540584],
        [80.24680328369135, 13.47319412231451],
        [80.24709320068365, 13.473200798034725],
        [80.24709320068365, 13.472920417785758],
        [80.2473602294923, 13.472920417785758],
        [80.2473602294923, 13.472640037536621],
        [80.24986267089838, 13.472640037536621],
        [80.24986267089838, 13.472359657287654],
        [80.25041961669933, 13.472359657287654],
        [80.25041961669933, 13.472079277038631],
        [80.25152587890653, 13.472079277038631],
        [80.25152587890653, 13.472359657287654],
        [80.25319671630865, 13.472359657287654],
        [80.25319671630865, 13.472640037536621],
        [80.25347137451183, 13.472640037536621],
        [80.25347137451183, 13.472920417785758],
        [80.25402832031278, 13.472920417785758],
        [80.25402832031278, 13.473200798034725],
        [80.25430297851562, 13.47319412231451],
        [80.25430297851562, 13.473471641540584],
        [80.2545928955081, 13.473469734192008],
        [80.2545928955081, 13.47375202178955],
        [80.25485992431658, 13.47375202178955],
        [80.25485992431658, 13.474029541015625],
        [80.25514221191435, 13.474029541015625],
        [80.25514221191435, 13.474310874939079],
        [80.25930786132835, 13.474310874939079],
        [80.25930786132835, 13.474029541015625],
        [80.25985717773443, 13.474029541015625],
        [80.25985717773443, 13.47375202178955],
        [80.26040649414062, 13.47375202178955],
        [80.26040649414062, 13.473469734192008],
        [80.26097106933594, 13.473471641540584],
        [80.26097106933594, 13.47319412231451],
        [80.26152801513689, 13.473200798034725],
        [80.26152801513689, 13.472920417785758],
        [80.2620925903322, 13.472920417785758],
        [80.2620925903322, 13.472640037536621],
        [80.26235961914085, 13.472640037536621],
        [80.26235961914085, 13.472079277038631],
        [80.26264190673822, 13.472084045410156],
        [80.26264190673822, 13.471805572509766],
        [80.26291656494146, 13.471810340881404],
        [80.26291656494146, 13.471529960632438],
        [80.26319122314464, 13.471529960632438],
        [80.26319122314464, 13.470969200134277],
        [80.26347351074224, 13.47097206115717],
        [80.26347351074224, 13.470693588256779],
        [80.2637481689456, 13.470693588256779],
        [80.2637481689456, 13.470417976379508],
        [80.26403045654297, 13.47042179107666],
        [80.26403045654297, 13.469861984252987],
        [80.26431274414091, 13.469861984252987],
        [80.26430511474638, 13.469584465026912],
        [80.26457977294939, 13.469584465026912],
        [80.26457977294939, 13.469028472900447],
        [80.26486968994169, 13.469030380249023],
        [80.26486968994169, 13.468470573425407],
        [80.26513671875017, 13.468470573425407],
        [80.26513671875017, 13.467918395996094],
        [80.26541900634788, 13.46792030334467],
        [80.26541900634788, 13.467641830444279],
        [80.2656860351562, 13.467641830444279],
        [80.2656860351562, 13.467361450195312],
        [80.26625061035168, 13.467361450195312],
        [80.26625061035168, 13.467082977294922],
        [80.26652526855474, 13.467082977294922],
        [80.26652526855474, 13.466805458068848],
        [80.26708221435547, 13.466810226440487],
        [80.26708221435547, 13.462919235229492],
        [80.26735687255888, 13.462919235229492],
        [80.26735687255888, 13.459312438964844],
        [80.26680755615263, 13.459312438964844],
        [80.26680755615263, 13.458750724792594],
        [80.26708221435547, 13.458750724792594],
        [80.26708221435547, 13.458198547363281],
        [80.26735687255888, 13.458198547363281],
        [80.26735687255888, 13.457921028137207],
        [80.26763916015642, 13.457921028137207],
        [80.26763916015642, 13.45764064788824],
        [80.2681961059572, 13.45764064788824],
        [80.2681961059572, 13.457361221313533],
        [80.26847076416038, 13.457361221313533],
        [80.26847076416038, 13.457082748413143],
        [80.269027709961, 13.457082748413143],
        [80.269027709961, 13.4548597335816],
        [80.26930236816418, 13.4548597335816],
        [80.26930236816418, 13.454311370849666],
        [80.26957702636724, 13.454311370849666],
        [80.26957702636724, 13.453749656677246],
        [80.26985931396513, 13.453749656677246],
        [80.26985931396513, 13.453189849853572],
        [80.2701416015625, 13.453193664550724],
        [80.2701416015625, 13.45263767242426],
        [80.27041625976591, 13.452640533447322],
        [80.27041625976591, 13.452071189880371],
        [80.27069091796892, 13.452083587646598],
        [80.27069091796892, 13.451527595520076],
        [80.27097320556669, 13.451531410217285],
        [80.27097320556669, 13.450969696045036],
        [80.2712478637697, 13.450971603393612],
        [80.2712478637697, 13.450415611267147],
        [80.27153015136724, 13.450421333313102],
        [80.27153015136724, 13.449859619140682],
        [80.27180480957048, 13.449861526489258],
        [80.27180480957048, 13.449028015136719],
        [80.2720870971682, 13.449029922485295],
        [80.2720870971682, 13.448472023010254],
        [80.27236175537104, 13.448472023010254],
        [80.27236175537104, 13.447637557983398],
        [80.27263641357428, 13.447640419006461],
        [80.27263641357428, 13.447081565856934],
        [80.27291870117199, 13.447084426879996],
        [80.27291870117199, 13.446528434753532],
        [80.273193359375, 13.446530342102108],
        [80.273193359375, 13.44568920135498],
        [80.27347564697294, 13.445693969726676],
        [80.27347564697294, 13.445139884948787],
        [80.27374267578142, 13.445141792297363],
        [80.27374267578142, 13.44458198547369],
        [80.27402496337919, 13.444583892822322],
        [80.27402496337919, 13.444306373596191],
        [80.27430725097673, 13.44431018829357],
        [80.27430725097673, 13.444029808044434],
        [80.27458190917974, 13.444029808044434],
        [80.27458190917974, 13.443750381469727],
        [80.27485656738298, 13.443750381469727],
        [80.27485656738298, 13.443471908569336],
        [80.2751388549807, 13.443471908569336],
        [80.2751388549807, 13.443194389343262],
        [80.27541351318354, 13.443194389343262],
        [80.27541351318354, 13.442917823791447],
        [80.2756958007813, 13.44291973114025],
        [80.2756958007813, 13.442361831664982],
        [80.27597045898449, 13.442361831664982],
        [80.27597045898449, 13.442081451416016],
        [80.27625274658209, 13.442084312439079],
        [80.27625274658209, 13.441805839538517],
        [80.27652740478544, 13.441809654235897],
        [80.27652740478544, 13.44153022766119],
        [80.27680969238281, 13.44153022766119],
        [80.27680969238281, 13.441248893737736],
        [80.27708435058622, 13.441248893737736],
        [80.27707672119169, 13.440971374511662],
        [80.27735900878923, 13.440971374511662],
        [80.27735900878923, 13.440695762634334],
        [80.27764129638678, 13.440695762634334],
        [80.27764129638678, 13.440415382385368],
        [80.27707672119169, 13.440420150756836],
        [80.27707672119169, 13.440130233764762],
        [80.27625274658209, 13.44013977050787],
        [80.27625274658209, 13.439861297607479],
        [80.27708435058622, 13.439861297607479],
        [80.27708435058622, 13.439582824707088],
        [80.27791595459001, 13.439582824707088],
        [80.27791595459001, 13.439305305481014],
        [80.2787475585938, 13.439310073852482],
        [80.2787475585938, 13.439029693603516],
        [80.27986907958984, 13.439029693603516],
        [80.27986907958984, 13.438749313354549],
        [80.28069305419928, 13.438749313354549],
        [80.28069305419928, 13.438191413879508],
        [80.28097534179705, 13.43819522857666],
        [80.28097534179705, 13.437639236450195],
        [80.2787475585938, 13.437639236450195],
        [80.2787475585938, 13.437919616699162],
        [80.27540588378918, 13.437919616699162],
        [80.27540588378918, 13.437360763549805],
        [80.2751388549807, 13.437360763549805],
        [80.2751388549807, 13.435140609741268],
        [80.27540588378918, 13.435140609741268],
        [80.27540588378918, 13.431530952453727],
        [80.2751388549807, 13.431530952453727],
        [80.2751388549807, 13.43096065521246],
        [80.27486419677751, 13.430973052978459],
        [80.27486419677751, 13.430694580078125],
        [80.27458190917974, 13.430694580078125],
        [80.27458190917974, 13.430417060851994],
        [80.27430725097673, 13.430420875549373],
        [80.27430725097673, 13.429861068725529],
        [80.27402496337919, 13.429861068725529],
        [80.27402496337919, 13.429582595825138],
        [80.27375030517578, 13.429582595825138],
        [80.27375030517578, 13.429025650024414],
        [80.27348327636747, 13.429031372070312],
        [80.27348327636747, 13.428750991821346],
        [80.273193359375, 13.428750991821346],
        [80.273193359375, 13.428469657897892],
        [80.27291870117199, 13.428473472595272],
        [80.27291870117199, 13.42791557312023],
        [80.27263641357428, 13.427921295165959],
        [80.27263641357428, 13.427640914916992],
        [80.27236175537104, 13.427640914916992],
        [80.27236175537104, 13.427359580993766],
        [80.2720870971682, 13.427359580993766],
        [80.2720870971682, 13.426807403564453],
        [80.27179718017572, 13.426811218261832],
        [80.27179718017572, 13.426250457763672],
        [80.27153015136724, 13.426250457763672],
        [80.27153015136724, 13.425689697265739],
        [80.2712478637697, 13.425694465637207],
        [80.2712478637697, 13.425138473510742],
        [80.27097320556669, 13.425151824951229],
        [80.27097320556669, 13.424579620361385],
        [80.27069091796892, 13.424584388733024],
        [80.27069091796892, 13.424028396606559],
        [80.27041625976591, 13.424031257629451],
        [80.27041625976591, 13.423469543457031],
        [80.2701416015625, 13.423472404480094],
        [80.2701416015625, 13.42291641235363],
        [80.26985931396513, 13.422921180725098],
        [80.26985931396513, 13.422090530395621],
        [80.26957702636724, 13.422090530395621],
        [80.26957702636724, 13.42097187042242],
        [80.26930236816418, 13.42097187042242],
        [80.26930236816418, 13.420140266418457],
        [80.269027709961, 13.420140266418457],
        [80.269027709961, 13.419309616088924],
        [80.26875305175798, 13.419309616088924],
        [80.26875305175798, 13.41819095611578],
        [80.26847076416038, 13.418193817138615],
        [80.26847076416038, 13.417362213134822],
        [80.26875305175798, 13.417360305786246],
        [80.26875305175798, 13.417639732360954],
        [80.269027709961, 13.417639732360954],
        [80.269027709961, 13.418193817138615],
        [80.26930236816418, 13.41819095611578],
        [80.26930236816418, 13.418749809265137],
        [80.26957702636724, 13.418749809265137],
        [80.26957702636724, 13.419309616088924],
        [80.26985931396513, 13.419309616088924],
        [80.26985931396513, 13.419861793518066],
        [80.2701416015625, 13.41985988616949],
        [80.2701416015625, 13.420694351196346],
        [80.27041625976591, 13.420692443847713],
        [80.27041625976591, 13.42125034332281],
        [80.27068328857439, 13.42125034332281],
        [80.27068328857439, 13.422090530395621],
        [80.27097320556669, 13.422090530395621],
        [80.27097320556669, 13.422639846801815],
        [80.2712478637697, 13.422637939453239],
        [80.2712478637697, 13.423193931579704],
        [80.27153015136724, 13.423192024230957],
        [80.27153015136724, 13.424310684204102],
        [80.27179718017572, 13.424310684204102],
        [80.27179718017572, 13.425420761108455],
        [80.2720870971682, 13.425415992736816],
        [80.2720870971682, 13.425971984863281],
        [80.27236175537104, 13.425970077514705],
        [80.27236175537104, 13.426250457763672],
        [80.27263641357428, 13.426250457763672],
        [80.27263641357428, 13.426530838012638],
        [80.27291870117199, 13.426528930664062],
        [80.27291870117199, 13.427082061767635],
        [80.273193359375, 13.427082061767635],
        [80.273193359375, 13.427359580993766],
        [80.27348327636747, 13.427359580993766],
        [80.27348327636747, 13.427921295165959],
        [80.27375030517578, 13.42791557312023],
        [80.27375030517578, 13.428194999694881],
        [80.27402496337919, 13.428194999694881],
        [80.27402496337919, 13.428473472595272],
        [80.27430725097673, 13.428469657897892],
        [80.27430725097673, 13.429031372070312],
        [80.27458190917974, 13.429031372070312],
        [80.27458190917974, 13.429308891296444],
        [80.2751388549807, 13.42930698394781],
        [80.2751388549807, 13.429582595825138],
        [80.27540588378918, 13.429579734802246],
        [80.27540588378918, 13.429861068725529],
        [80.2756958007813, 13.429861068725529],
        [80.2756958007813, 13.430420875549373],
        [80.27597045898449, 13.430417060851994],
        [80.27597045898449, 13.430694580078125],
        [80.27625274658209, 13.430687904358024],
        [80.27625274658209, 13.43125057220459],
        [80.27652740478544, 13.43125057220459],
        [80.27652740478544, 13.431809425354118],
        [80.27680206298828, 13.431804656982479],
        [80.27680206298828, 13.433751106262264],
        [80.27708435058622, 13.433751106262264],
        [80.27708435058622, 13.434861183166618],
        [80.27735900878923, 13.434861183166618],
        [80.27735900878923, 13.43542194366455],
        [80.27764129638678, 13.435417175293082],
        [80.27764129638678, 13.435694694519157],
        [80.27902984619152, 13.435694694519157],
        [80.27902984619152, 13.435417175293082],
        [80.28125000000023, 13.435417175293082],
        [80.28125000000023, 13.435694694519157],
        [80.28263854980497, 13.435694694519157],
        [80.28263854980497, 13.435417175293082],
        [80.28291320800781, 13.43542194366455],
        [80.28291320800781, 13.435140609741268],
        [80.28320312500028, 13.435140609741268],
        [80.28320312500028, 13.43458080291748],
        [80.28347015380876, 13.434582710266227],
        [80.28347015380876, 13.434305191040153],
        [80.28375244140653, 13.434311866760368],
        [80.28375244140653, 13.434030532836914],
        [80.28402709960955, 13.434030532836914],
        [80.28402709960955, 13.433751106262264],
        [80.28430175781273, 13.433751106262264],
        [80.28430175781273, 13.433470726013297],
        [80.28458404541033, 13.433472633361873],
        [80.28458404541033, 13.433195114135799],
        [80.28485870361334, 13.433195114135799],
        [80.28485870361334, 13.432916641235408],
        [80.28514099121088, 13.432921409607047],
        [80.28514099121088, 13.43264102935791],
        [80.28597259521484, 13.43264102935791],
        [80.28597259521484, 13.432921409607047],
        [80.28624725341825, 13.432916641235408],
        [80.28624725341825, 13.433195114135799],
        [80.28680419921903, 13.433195114135799],
        [80.28680419921903, 13.433472633361873],
        [80.28708648681658, 13.433470726013297],
        [80.28708648681658, 13.433751106262264],
        [80.28736114501959, 13.433751106262264],
        [80.28736114501959, 13.434030532836914],
        [80.28791809082054, 13.434030532836914],
        [80.28791809082054, 13.434311866760368],
        [80.28819274902338, 13.434305191040153],
        [80.28819274902338, 13.434582710266227],
        [80.28874969482433, 13.434582710266227],
        [80.28874969482433, 13.434861183166618],
        [80.29013824462908, 13.434861183166618],
        [80.29013824462908, 13.434582710266227],
        [80.29041290283209, 13.434582710266227],
        [80.29041290283209, 13.434305191040153],
        [80.29070281982439, 13.434311866760368],
        [80.29070281982439, 13.433751106262264],
        [80.29096984863304, 13.433751106262264],
        [80.29096984863304, 13.433470726013297],
        [80.29125213623041, 13.433472633361873],
        [80.29125213623041, 13.433195114135799],
        [80.29152679443365, 13.433195114135799],
        [80.29152679443365, 13.432916641235408],
        [80.29180908203136, 13.432921409607047],
        [80.29180908203136, 13.43264102935791],
        [80.29207611083984, 13.43264102935791],
        [80.29207611083984, 13.432360649108944],
        [80.29235839843778, 13.432360649108944],
        [80.29235839843778, 13.431809425354118],
        [80.29264068603516, 13.431809425354118],
        [80.29264068603516, 13.431530952453727],
        [80.2929229736331, 13.431530952453727],
        [80.2929229736331, 13.43125057220459],
        [80.29374694824236, 13.43125057220459],
        [80.29374694824236, 13.43096065521246],
        [80.29402923584007, 13.430973052978459],
        [80.29402923584007, 13.430694580078125],
        [80.29430389404291, 13.430694580078125],
        [80.29430389404291, 13.42930698394781],
        [80.29458618164068, 13.429308891296444],
        [80.29458618164068, 13.428750991821346],
        [80.29486846923822, 13.428750991821346],
        [80.29486846923822, 13.427359580993766],
        [80.29541778564482, 13.427359580993766],
        [80.29541778564482, 13.427082061767635],
        [80.29736328125017, 13.427082061767635],
        [80.29736328125017, 13.426807403564453],
        [80.29763793945318, 13.426811218261832],
        [80.29763793945318, 13.426530838012638],
        [80.29791259765636, 13.426530838012638],
        [80.29791259765636, 13.426811218261832],
        [80.29921722412138, 13.426807403564453],
        [80.3004226684572, 13.426811218261832],
        [80.3004226684572, 13.426530838012638],
        [80.30069732666038, 13.426530838012638],
        [80.30069732666038, 13.425151824951229],
        [80.30152893066418, 13.425151824951229],
        [80.30152893066418, 13.424579620361385],
        [80.30124664306646, 13.424584388733024],
        [80.30124664306646, 13.424305915832463],
        [80.30097198486322, 13.424310684204102],
        [80.30097198486322, 13.423749923706168],
        [80.30124664306646, 13.423749923706168],
        [80.30124664306646, 13.423469543457031],
        [80.30097198486322, 13.423472404480094],
        [80.30097198486322, 13.42291641235363],
        [80.30069732666038, 13.422921180725098],
        [80.30069732666038, 13.422360420227164],
        [80.30041503906267, 13.42236232757574],
        [80.30041503906267, 13.421527862548885],
        [80.30014038085943, 13.421532630920524],
        [80.30014038085943, 13.42097187042242],
        [80.29985809326189, 13.42097187042242],
        [80.29985809326189, 13.41985988616949],
        [80.29957580566412, 13.419861793518066],
        [80.29957580566412, 13.41902065277094],
        [80.29985809326189, 13.41902828216547],
        [80.29985809326189, 13.418472290039006],
        [80.30014038085943, 13.418472290039006],
        [80.30014038085943, 13.41791629791254],
        [80.3004226684572, 13.41792011260992],
        [80.3004226684572, 13.417639732360954],
        [80.30069732666038, 13.417639732360954],
        [80.30069732666038, 13.417360305786246],
        [80.30124664306646, 13.417362213134822],
        [80.30124664306646, 13.417083740234489],
        [80.30152893066418, 13.417083740234489],
        [80.30152893066418, 13.416527748107967],
        [80.30179595947266, 13.4165296554566],
        [80.30179595947266, 13.416250228881893],
        [80.30236053466797, 13.416250228881893],
        [80.30236053466797, 13.415960311889648],
        [80.30263519287138, 13.415971755981502],
        [80.30263519287138, 13.417362213134822],
        [80.30236053466797, 13.417360305786246],
        [80.30236053466797, 13.418749809265137],
        [80.30208587646513, 13.418749809265137],
        [80.30208587646513, 13.419309616088924],
        [80.30180358886719, 13.419305801391602],
        [80.30180358886719, 13.419584274291992],
        [80.30152893066418, 13.419578552246207],
        [80.30152893066418, 13.421811103820914],
        [80.30179595947266, 13.421811103820914],
        [80.30179595947266, 13.422090530395621],
        [80.30208587646513, 13.422090530395621],
        [80.30208587646513, 13.422921180725098],
        [80.30236053466797, 13.42291641235363],
        [80.30236053466797, 13.423193931579704],
        [80.30263519287138, 13.423193931579704],
        [80.30263519287138, 13.423472404480094],
        [80.30291748046892, 13.423469543457031],
        [80.30291748046892, 13.424031257629451],
        [80.30319213867193, 13.424031257629451],
        [80.30319213867193, 13.424310684204102],
        [80.30346679687517, 13.424310684204102],
        [80.30346679687517, 13.424860000610352],
        [80.30374908447288, 13.424860000610352],
        [80.30374908447288, 13.425151824951229],
        [80.30403900146501, 13.425151824951229],
        [80.30403900146501, 13.425420761108455],
        [80.30458068847668, 13.425415992736816],
        [80.30458068847668, 13.425694465637207],
        [80.30486297607428, 13.425694465637207],
        [80.30486297607428, 13.425971984863281],
        [80.305419921875, 13.425970077514705],
        [80.305419921875, 13.426250457763672],
        [80.30568695068388, 13.426250457763672],
        [80.30568695068388, 13.426530838012638],
        [80.30625152587896, 13.426530838012638],
        [80.30625152587896, 13.426811218261832],
        [80.3065261840822, 13.426807403564453],
        [80.3065261840822, 13.427082061767635],
        [80.30680847167991, 13.427082061767635],
        [80.30680847167991, 13.427359580993766],
        [80.307357788086, 13.427359580993766],
        [80.307357788086, 13.427640914916992],
        [80.30819702148466, 13.427640914916992],
        [80.30819702148466, 13.427921295165959],
        [80.30930328369146, 13.42791557312023],
        [80.30930328369146, 13.428194999694881],
        [80.31041717529303, 13.428194999694881],
        [80.31041717529303, 13.42791557312023],
        [80.31069183349621, 13.42791557312023],
        [80.31069183349621, 13.428194999694881],
        [80.31263732910173, 13.428194999694881],
        [80.31263732910173, 13.42791557312023],
        [80.31299591064447, 13.42791557312023],
        [80.31273651123053, 13.42627143859869],
        [80.3127899169923, 13.426150321960563],
        [80.31204986572283, 13.422459602355957],
        [80.31150817871111, 13.421760559082031],
        [80.31163787841808, 13.41808032989502],
        [80.31278228759777, 13.405929565429688],
        [80.31450653076183, 13.399210929870662],
        [80.31478881835943, 13.391762733459586],
        [80.31526184082043, 13.379731178283805],
        [80.31649780273443, 13.376372337341365],
        [80.31548309326189, 13.375520706176815],
        [80.31497955322271, 13.374591827392635],
        [80.3153686523438, 13.37145137786871],
        [80.31212615966803, 13.369380950927734],
        [80.30760192871122, 13.373511314392147],
        [80.30670928955095, 13.374580383300895],
        [80.30645751953153, 13.37476921081543],
        [80.30179595947266, 13.377919197082633],
        [80.29692077636747, 13.3812513351441],
        [80.2931365966798, 13.383420944213924],
        [80.28214263916016, 13.385531425476188],
        [80.2792205810548, 13.387361526489315],
        [80.27733612060564, 13.388261795043945],
        [80.27597045898449, 13.389142036438045],
        [80.27391815185564, 13.397690773010368],
        [80.2741470336914, 13.398371696472168],
        [80.27493286132818, 13.398871421813965],
        [80.27629089355491, 13.399399757385368],
        [80.27722167968756, 13.399210929870662],
        [80.27894592285162, 13.39906024932867],
        [80.27984619140642, 13.399580001831055],
        [80.28015899658232, 13.400260925293026],
        [80.27999877929682, 13.402050971984863],
        [80.2778930664062, 13.407531738281307],
        [80.27160644531244, 13.413180351257438],
        [80.26799774169939, 13.41434192657482],
        [80.26608276367205, 13.417979240417424],
        [80.26534271240251, 13.421111106872502],
        [80.26494598388689, 13.428290367126522],
        [80.26457977294939, 13.435610771179256],
        [80.26342010498064, 13.439351081848258],
        [80.25988769531273, 13.44736194610607],
        [80.25834655761724, 13.44929027557373],
        [80.25578308105486, 13.454030990600643],
        [80.25360870361334, 13.455739974975643],
        [80.2520370483399, 13.458850860595703],
        [80.2506408691408, 13.459671974182243],
        [80.25058746337919, 13.460580825805721],
        [80.2472000122072, 13.46360111236578],
        [80.24639892578153, 13.465380668640194],
        [80.24585723876947, 13.465749740600586],
        [80.2441711425783, 13.467030525207463],
        [80.243637084961, 13.467799186706543],
        [80.24224090576189, 13.468710899353084],
        [80.24090576171892, 13.4701509475708],
        [80.23500823974626, 13.47387981414795],
        [80.2303314208985, 13.476849555969295],
        [80.22204589843767, 13.480320930481014],
        [80.21624755859403, 13.481831550598201],
        [80.21177673339844, 13.48231029510498],
        [80.20944213867205, 13.481801986694336],
        [80.20369720458979, 13.478041648864803],
        [80.19406127929693, 13.476229667663631],
        [80.18176269531244, 13.477769851684627],
        [80.17942810058622, 13.478281021118164],
        [80.17569732666033, 13.47895145416254],
        [80.16581726074224, 13.477769851684627],
        [80.1651000976563, 13.477971076965332],
        [80.16459655761713, 13.477861404418945],
        [80.1631469726563, 13.477552413940373],
        [80.15837860107428, 13.47832202911377],
        [80.15650177001976, 13.478889465332145],
        [80.15416717529297, 13.478941917419434],
        [80.15209960937506, 13.47887992858881],
        [80.15065765380871, 13.479981422424316],
        [80.14742279052746, 13.482821464538517],
        [80.14465332031256, 13.484689712524528],
        [80.14457702636713, 13.485610961914006],
        [80.13761138916021, 13.49115085601818],
        [80.13616180419933, 13.491971969604549],
        [80.13545989990251, 13.492180824279785],
        [80.13540649414074, 13.49309062957775],
        [80.13271331787138, 13.495919227600211],
        [80.13069915771484, 13.49707889556879],
        [80.12950897216791, 13.49732971191412],
        [80.12889099121122, 13.497639656066951],
        [80.12861633300781, 13.498551368713493],
        [80.12789154052757, 13.498950004577694],
        [80.1208114624024, 13.502360343933162],
        [80.10691070556663, 13.503941535949764],
        [80.10504913330107, 13.503631591796932],
        [80.10035705566423, 13.505200386047363],
        [80.09942626953125, 13.506310462951717],
        [80.09670257568354, 13.508210182189941],
        [80.09565734863304, 13.50984001159668],
        [80.09377288818365, 13.51356029510498],
        [80.0886688232423, 13.525529861450138],
        [80.08793640136736, 13.525959968567008],
        [80.08766937255865, 13.526610374450684],
        [80.086723327637, 13.527240753173942],
        [80.07943725585943, 13.529360771179142],
        [80.07627868652355, 13.526120185852164],
        [80.07582092285179, 13.52676010131836],
        [80.07276153564459, 13.533921241760254],
        [80.06944274902361, 13.537590980529842],
        [80.06717681884783, 13.535920143127498],
        [80.0656127929687, 13.534760475158748],
        [80.0595092773437, 13.532548904419059],
        [80.05098724365234, 13.530511856079102],
        [80.0454177856447, 13.521981239318904],
        [80.03409576416044, 13.521800994873047],
        [80.02951049804716, 13.523590087890625],
        [80.01754760742182, 13.525131225585938],
        [80.01449584960932, 13.528490066528377],
        [80.00771331787115, 13.532372474670353],
        [79.99413299560547, 13.52960014343273],
        [79.98967742919933, 13.52676010131836],
        [79.98928070068388, 13.519000053405819],
        [79.98973846435564, 13.513001441955623],
        [79.9901733398438, 13.510090827942008],
        [80.0003967285158, 13.512372016906795],
        [80.00623321533232, 13.51506233215332],
        [80.00842285156267, 13.50830173492443],
        [80.01361846923834, 13.503911972045898],
        [80.01908874511736, 13.50166034698492],
        [80.02564239501959, 13.50397109985363],
        [80.03275299072294, 13.50183963775629],
        [80.03697967529308, 13.499350547790527],
        [80.03719329833979, 13.492821693420467],
        [80.03604125976557, 13.484479904174805],
        [80.02481842041033, 13.486700057983342],
        [80.018852233887, 13.486371994018555],
        [80.00589752197277, 13.487771034240723],
        [80.0044021606447, 13.478260040283146],
        [79.99537658691435, 13.459450721740836],
        [79.99076080322288, 13.459219932556266],
        [79.99056243896507, 13.462059974670467],
        [79.98747253417969, 13.461910247802734],
        [79.96826934814459, 13.456431388855037],
        [79.96186065673845, 13.451279640197868],
        [79.96218872070341, 13.44413852691656],
        [79.96306610107428, 13.440572738647461],
        [79.9610214233399, 13.42766094207775],
        [79.96322631835955, 13.417910575866756],
        [79.95848083496094, 13.412851333618164],
        [79.95085144042969, 13.40812015533453],
        [79.9442672729495, 13.41078186035162],
        [79.93595123291033, 13.409571647643986],
        [79.93247985839838, 13.40437126159668],
        [79.93557739257812, 13.39059066772461],
        [79.93562316894537, 13.384849548339957],
        [79.95028686523443, 13.381971359253043],
        [79.95358276367193, 13.374111175537223],
        [79.94803619384788, 13.367220878601188],
        [79.93974304199236, 13.369100570678768],
        [79.94064331054716, 13.360980987548828],
        [79.93998718261724, 13.358111381530762],
        [79.92872619628906, 13.34353065490734],
        [79.92550659179693, 13.335940361023063],
        [79.91713714599632, 13.341039657592773],
        [79.91068267822294, 13.343700408935604],
        [79.90628814697294, 13.339051246643123],
        [79.90386199951166, 13.333271026611442],
        [79.89736175537121, 13.329489707946834],
        [79.89179229736357, 13.324619293212947],
        [79.88607788085966, 13.32129192352295],
        [79.88015747070341, 13.320439338684139],
        [79.8749618530274, 13.317400932312125],
        [79.87055206298828, 13.311759948730526],
        [79.86519622802734, 13.31072998046875],
        [79.85885620117182, 13.313740730285645],
        [79.85568237304693, 13.30814075469982],
        [79.85116577148466, 13.303429603576774],
        [79.83905792236345, 13.303340911865348],
        [79.83676910400408, 13.302998542785588],
        [79.83059692382841, 13.302060127258244],
        [79.82404327392584, 13.30231952667242],
        [79.80848693847668, 13.305080413818303],
        [79.80029296874994, 13.304321289062557],
        [79.79500579833984, 13.29857063293457],
        [79.79226684570324, 13.291640281677246],
        [79.78727722167997, 13.284620285034237],
        [79.77980041503929, 13.28207015991211],
        [79.77422332763672, 13.285131454467773],
        [79.76835632324241, 13.289629936218262],
        [79.75405883789085, 13.302789688110352],
        [79.75055694580095, 13.298810005187931],
        [79.74975585937494, 13.290881156921444],
        [79.74614715576189, 13.280000686645621],
        [79.74149322509794, 13.276661872863883],
        [79.72963714599632, 13.275079727172795],
        [79.72129058837919, 13.272239685058594],
        [79.7217788696289, 13.265730857849121],
        [79.73938751220709, 13.254430770874023],
        [79.74558258056658, 13.248210906982536],
        [79.75277709960966, 13.243760108947868],
        [79.77539062500006, 13.234119415283146],
        [79.78325653076178, 13.22816181182867],
        [79.78489685058594, 13.223480224609432],
        [79.78325653076178, 13.217919349670467],
        [79.77733612060553, 13.217191696166935],
        [79.77356719970732, 13.217519760131893],
        [79.76716613769531, 13.213339805603084],
        [79.7633361816408, 13.20885086059576],
        [79.75772094726591, 13.205571174621582],
        [79.75183105468761, 13.204779624939079],
        [79.74437713623075, 13.19491100311285],
        [79.73826599121122, 13.198259353637695],
        [79.7375030517581, 13.20552062988287],
        [79.73368072509794, 13.210231781005973],
        [79.73252868652372, 13.214961051940975],
        [79.72651672363298, 13.21738052368164],
        [79.7228317260745, 13.22185134887701],
        [79.7185668945313, 13.222591400146541],
        [79.71570587158209, 13.214400291442871],
        [79.71142578125017, 13.210479736328182],
        [79.70568847656244, 13.209231376648006],
        [79.69963836669922, 13.202680587768668],
        [79.69243621826178, 13.207530975341854],
        [79.6887512207033, 13.207409858703727],
        [79.68943786621122, 13.211250305175838],
        [79.69574737548857, 13.223760604858455],
        [79.69603729248053, 13.23389053344738],
        [79.69193267822294, 13.238529205322266],
        [79.68456268310564, 13.233849525451774],
        [79.68068695068371, 13.228370666503906],
        [79.6733169555664, 13.229490280151424],
        [79.67111206054716, 13.235841751098633],
        [79.6737670898438, 13.247020721435604],
        [79.68430328369169, 13.25667953491211],
        [79.68161010742216, 13.265550613403263],
        [79.67932128906278, 13.269331932067871],
        [79.66853332519548, 13.281930923462028],
        [79.66590118408209, 13.273279190063533],
        [79.66256713867199, 13.26814174652094],
        [79.6574020385745, 13.26848030090332],
        [79.65338134765653, 13.274568557739258],
        [79.64862823486357, 13.273359298706112],
        [79.64376831054688, 13.273359298706112],
        [79.63848114013678, 13.276189804077148],
        [79.63250732421875, 13.273542404174862],
        [79.63072204589855, 13.268159866333065],
        [79.62956237792997, 13.260860443115234],
        [79.62431335449219, 13.262020111083984],
        [79.62075805664091, 13.25895023345953],
        [79.61519622802757, 13.260450363159293],
        [79.61075592041016, 13.26459121704113],
        [79.60510253906256, 13.26736068725586],
        [79.60114288330107, 13.267729759216422],
        [79.59485626220714, 13.263042449951229],
        [79.58867645263678, 13.260411262512264],
        [79.58644866943388, 13.253480911254996],
        [79.58541870117193, 13.247100830078182],
        [79.5802612304688, 13.245370864868164],
        [79.57096099853521, 13.247379302978572],
        [79.56537628173828, 13.249401092529297],
        [79.55478668212919, 13.2591810226441],
        [79.54872894287126, 13.267121315002441],
        [79.54634094238304, 13.27404975891119],
        [79.54144287109403, 13.278931617736816],
        [79.53922271728533, 13.289759635925407],
        [79.53887176513678, 13.300921440124512],
        [79.53578186035173, 13.311070442199707],
        [79.52271270751959, 13.315931320190487],
        [79.50953674316429, 13.314700126648006],
        [79.4978103637697, 13.314821243286133],
        [79.48722839355474, 13.31655120849615],
        [79.47927856445324, 13.32145977020258],
        [79.454643249512, 13.324320793151799],
        [79.42887878417997, 13.323561668396053],
        [79.41732025146501, 13.321958541870117],
        [79.40139770507818, 13.315751075744629],
        [79.37460327148455, 13.303039550781364],
        [79.38562774658214, 13.294700622558594],
        [79.39897918701183, 13.289151191711426],
        [79.40542602539091, 13.285410881042424],
        [79.42177581787115, 13.286660194397086],
        [79.42502593994146, 13.281340599060059],
        [79.41837310791021, 13.274589538574276],
        [79.41799163818365, 13.269061088562012],
        [79.41902160644526, 13.266339302063102],
        [79.41230010986357, 13.257510185241813],
        [79.40866851806669, 13.246741294860954],
        [79.40734100341825, 13.237661361694336],
        [79.40968322753923, 13.22815036773676],
        [79.4086532592774, 13.219590187072754],
        [79.41084289550798, 13.21216201782238],
        [79.41699981689459, 13.212201118469352],
        [79.41816711425804, 13.218461990356445],
        [79.4269027709961, 13.222740173339957],
        [79.43609619140648, 13.229831695556697],
        [79.44245910644543, 13.229180335998592],
        [79.44544219970726, 13.221720695495605],
        [79.44550323486357, 13.21677112579357],
        [79.42163085937528, 13.208531379699764],
        [79.42315673828153, 13.203800201415959],
        [79.42315673828153, 13.1989107131958],
        [79.4215698242188, 13.193181037902832],
        [79.42378997802729, 13.18746089935297],
        [79.42059326171875, 13.18446159362793],
        [79.41329193115229, 13.185849189758414],
        [79.39791870117193, 13.186422348022518],
        [79.39256286621094, 13.18446159362793],
        [79.38437652587896, 13.184720993042106],
        [79.37835693359403, 13.182431221008414],
        [79.36900329589872, 13.176260948181152],
        [79.37078094482439, 13.168589591980037],
        [79.36717224121111, 13.165580749511719],
        [79.36493682861334, 13.160370826721248],
        [79.36120605468761, 13.156041145324764],
        [79.35472106933605, 13.161339759826774],
        [79.34777069091803, 13.163019180297908],
        [79.3470916748048, 13.153950691223201],
        [79.34783935546903, 13.143819808959961],
        [79.34686279296903, 13.14015102386486],
        [79.34674835205095, 13.134860992431697],
        [79.35457611084001, 13.129771232604924],
        [79.35009765625006, 13.125450134277457],
        [79.3443527221682, 13.1235609054566],
        [79.33779144287126, 13.12317085266119],
        [79.33531951904314, 13.127510070800838],
        [79.33068847656261, 13.12123107910162],
        [79.3167266845706, 13.119368553161564],
        [79.3123931884765, 13.113769531250057],
        [79.3097305297851, 13.10690021514904],
        [79.3100280761721, 13.09994029998785],
        [79.30793762207037, 13.096911430359],
        [79.29747009277372, 13.097371101379508],
        [79.29876708984398, 13.11483192443859],
        [79.28810119628912, 13.118280410766715],
        [79.28495025634783, 13.122850418090934],
        [79.27751922607439, 13.119720458984432],
        [79.2738494873048, 13.120901107788086],
        [79.27266693115263, 13.12666034698492],
        [79.27128601074224, 13.138060569763127],
        [79.26741027832048, 13.141201019287223],
        [79.26120758056646, 13.140749931335506],
        [79.25646972656278, 13.136540412902832],
        [79.25267028808622, 13.141299247741756],
        [79.2513732910158, 13.146199226379508],
        [79.24722290039062, 13.148081779479924],
        [79.24423980712896, 13.1411514282226],
        [79.24333953857433, 13.13335037231451],
        [79.2367019653322, 13.126751899719352],
        [79.2330474853515, 13.127699851989803],
        [79.22635650634777, 13.134610176086426],
        [79.21832275390653, 13.135551452636776],
        [79.21466064453148, 13.127670288085938],
        [79.21826934814459, 13.120430946350098],
        [79.22244262695318, 13.116009712219295],
        [79.21827697753929, 13.110309600830192],
        [79.21827697753929, 13.103941917419434],
        [79.21428680419928, 13.09683990478527],
        [79.20744323730497, 13.091441154479924],
        [79.20220947265625, 13.090290069580192],
        [79.19332122802763, 13.089690208435059],
        [79.18891906738287, 13.084618568420467],
        [79.18699645996116, 13.077050209045524],
        [79.1835021972658, 13.07294082641613],
        [79.18065643310547, 13.06704044342041],
        [79.17910003662126, 13.060111999511719],
        [79.16942596435553, 13.053009986877555],
        [79.17473602294922, 13.049679756164664],
        [79.17227172851562, 13.043720245361442],
        [79.17653656005888, 13.03827095031744],
        [79.17697143554682, 13.033379554748649],
        [79.17662048339861, 13.029769897460938],
        [79.17439270019537, 13.025250434875602],
        [79.17310333251982, 13.019281387329215],
        [79.17059326171898, 13.015680313110295],
        [79.16120147705078, 13.011798858642578],
        [79.15270233154325, 13.007292747497559],
        [79.14163208007841, 13.009819984436035],
        [79.13114929199247, 13.016762733459586],
        [79.11927032470709, 13.02256965637207],
        [79.10437774658209, 13.02422046661377],
        [79.08278656005876, 13.012471199035758],
        [79.0690689086914, 13.014150619506893],
        [79.06057739257841, 13.020839691162223],
        [79.05397796630876, 13.028161048889217],
        [79.05326843261724, 13.037990570068416],
        [79.04389190673828, 13.043720245361442],
        [79.03353881835932, 13.045660018920955],
        [79.02588653564482, 13.049579620361385],
        [79.02281951904325, 13.056401252746582],
        [79.01142883300798, 13.057829856872559],
        [79.00036621093767, 13.057881355285758],
        [78.99523925781267, 13.06304073333746],
        [78.9882507324221, 13.072651863098145],
        [78.98174285888678, 13.07619953155529],
        [78.97959136962908, 13.076510429382438],
        [78.97167968750006, 13.075119972229118],
        [78.964653015137, 13.072799682617244],
        [78.96293640136747, 13.071559906005916],
        [78.95918273925798, 13.065440177917594],
        [78.95509338378935, 13.064929962158317],
        [78.95001220703142, 13.066089630126896],
        [78.94584655761736, 13.063509941101131],
        [78.93663787841808, 13.069971084594783],
        [78.93007659912132, 13.066021919250545],
        [78.91438293457031, 13.06776142120367],
        [78.91204071044916, 13.063282012939453],
        [78.9108428955081, 13.056100845337028],
        [78.90867614746094, 13.050459861755428],
        [78.90091705322271, 13.052101135253906],
        [78.89346313476585, 13.0350923538208],
        [78.8983993530274, 13.031690597534237],
        [78.90439605712902, 13.031050682067985],
        [78.91739654541033, 13.032549858093262],
        [78.92043304443354, 13.028620719909725],
        [78.92745971679716, 13.023461341858024],
        [78.92864990234392, 13.02145099639904],
        [78.92463684082026, 13.018641471862793],
        [78.92299652099615, 13.021760940551871],
        [78.91593170166021, 13.023061752319336],
        [78.89010620117182, 13.019829750061149],
        [78.88191986084001, 13.01976203918457],
        [78.87937164306669, 13.025501251220703],
        [78.88088989257818, 13.044281005859375],
        [78.88040924072283, 13.06960201263422],
        [78.88352203369146, 13.081989288330078],
        [78.87603759765642, 13.08615970611578],
        [78.87032318115234, 13.086920738220329],
        [78.86473846435541, 13.08468055725109],
        [78.86068725585966, 13.079500198364315],
        [78.85835266113287, 13.071069717407227],
        [78.84393310546886, 13.071479797363395],
        [78.83226776123075, 13.069590568542537],
        [78.8134231567384, 13.074647903442383],
        [78.80809020996088, 13.077930450439453],
        [78.80087280273455, 13.075530052185059],
        [78.79418182373064, 13.069759368896484],
        [78.78659057617188, 13.062151908874569],
        [78.78306579589872, 13.056371688842887],
        [78.77752685546903, 13.05315971374506],
        [78.75446319580107, 13.046981811523438],
        [78.74601745605491, 13.045409202575684],
        [78.73951721191412, 13.045491218567008],
        [78.73217773437494, 13.0504026412965],
        [78.7255020141601, 13.052780151367301],
        [78.70244598388689, 13.05615139007574],
        [78.70125579834013, 13.044260025024528],
        [78.69597625732439, 13.012520790100154],
        [78.69375610351585, 13.004391670227108],
        [78.67935943603533, 13.008970260620117],
        [78.66861724853544, 13.011059761047363],
        [78.65110778808605, 13.017931938171444],
        [78.64895629882818, 13.016351699829158],
        [78.6450500488283, 13.00731086730957],
        [78.64171600341825, 13.00161075592041],
        [78.63733673095732, 12.99684143066412],
        [78.62837982177763, 12.989721298217773],
        [78.62049865722662, 12.982521057128906],
        [78.61366271972662, 12.978890419006348],
        [78.6202468872072, 12.973910331726188],
        [78.63791656494169, 12.954409599304256],
        [78.62899780273432, 12.953110694885197],
        [78.63566589355463, 12.93834018707281],
        [78.64671325683605, 12.919019699096737],
        [78.63746643066412, 12.915881156921444],
        [78.63073730468767, 12.915979385375977],
        [78.62770080566423, 12.917921066284293],
        [78.62570953369169, 12.919181823730469],
        [78.62477111816435, 12.914180755615291],
        [78.61965179443354, 12.902071952819824],
        [78.61554718017584, 12.893219947814885],
        [78.61222839355486, 12.888561248779297],
        [78.60597229003906, 12.873699188232422],
        [78.6046218872072, 12.866000175476074],
        [78.60443115234392, 12.857441902160588],
        [78.59900665283232, 12.849470138549748],
        [78.59156799316435, 12.838549613952637],
        [78.59229278564476, 12.836770057678223],
        [78.59379577636724, 12.833001136779785],
        [78.59562683105486, 12.815489768982047],
        [78.59767150878935, 12.811079978942928],
        [78.58819580078142, 12.804780006408691],
        [78.59060668945341, 12.79790019989008],
        [78.58976745605469, 12.792449951171989],
        [78.58251953125, 12.785321235656852],
        [78.58290863037115, 12.776220321655217],
        [78.58193969726562, 12.770771026611385],
        [78.58007049560553, 12.767240524292106],
        [78.57765960693388, 12.765850067138786],
        [78.56572723388672, 12.75901126861578],
        [78.56684875488298, 12.752149581909293],
        [78.56157684326195, 12.752570152282829],
        [78.55811309814459, 12.75164890289318],
        [78.55406188964861, 12.746069908142204],
        [78.55091857910162, 12.746809959411678],
        [78.54663085937517, 12.74560928344738],
        [78.54554748535162, 12.743910789489803],
        [78.55030822753912, 12.73876094818121],
        [78.54564666748041, 12.728611946106014],
        [78.5508880615235, 12.726229667663574],
        [78.55393218994146, 12.726060867309627],
        [78.55224609375006, 12.720391273498649],
        [78.54824829101568, 12.71498012542736],
        [78.5455093383789, 12.707381248474064],
        [78.54931640625, 12.700700759887752],
        [78.55224609375006, 12.697091102600211],
        [78.55195617675798, 12.694339752197209],
        [78.54801177978521, 12.695369720458984],
        [78.54509735107439, 12.69260120391857],
        [78.54773712158197, 12.68911170959484],
        [78.54767608642584, 12.685590744018668],
        [78.54467773437511, 12.68443965911871],
        [78.54371643066418, 12.684600830078182],
        [78.54135894775413, 12.685011863708553],
        [78.53978729248047, 12.684221267700195],
        [78.53127288818365, 12.679931640625114],
        [78.52774810791033, 12.679001808166618],
        [78.52436828613287, 12.67969036102295],
        [78.52015686035162, 12.687007904052791],
        [78.51853942871105, 12.692379951477108],
        [78.52085876464861, 12.697181701660156],
        [78.52561187744135, 12.69474983215332],
        [78.52745819091803, 12.697300910949707],
        [78.52641296386736, 12.701230049133244],
        [78.5159759521485, 12.703520774841422],
        [78.51616668701178, 12.70623970031744],
        [78.51618957519554, 12.706400871276855],
        [78.51474761962885, 12.706989288330078],
        [78.51230621337908, 12.708009719848746],
        [78.51094055175798, 12.721721649169979],
        [78.50936889648466, 12.719791412353572],
        [78.50740814209007, 12.713090896606502],
        [78.50356292724615, 12.713371276855469],
        [78.50315856933594, 12.718099594116211],
        [78.50180053710955, 12.72150993347168],
        [78.49710083007818, 12.72411918640148],
        [78.49431610107433, 12.730371475219727],
        [78.48886108398438, 12.73196029663086],
        [78.48435211181646, 12.734781265258789],
        [78.47966003417986, 12.73001956939703],
        [78.4844131469726, 12.723050117492676],
        [78.49146270751964, 12.717069625854606],
        [78.4936294555664, 12.711169242858887],
        [78.50903320312517, 12.704901695251408],
        [78.51051330566435, 12.70176982879633],
        [78.50981140136736, 12.698780059814453],
        [78.5045928955081, 12.69800090789795],
        [78.50389099121111, 12.693301200866756],
        [78.49978637695335, 12.696851730346793],
        [78.4978866577149, 12.698511123657227],
        [78.49492645263689, 12.703729629516658],
        [78.49340057373064, 12.703470230102539],
        [78.49413299560558, 12.69794082641613],
        [78.49301147460955, 12.693699836731014],
        [78.4909057617187, 12.693291664123649],
        [78.48754882812506, 12.692628860473633],
        [78.48631286621105, 12.690510749817008],
        [78.4895629882812, 12.686609268188533],
        [78.49357604980463, 12.685750007629508],
        [78.49340057373064, 12.681030273437557],
        [78.49740600585955, 12.679249763488883],
        [78.49711608886741, 12.676489830017033],
        [78.49405670166038, 12.674700736999512],
        [78.49344635009766, 12.669590950012207],
        [78.48855590820341, 12.663342475891113],
        [78.47406005859403, 12.6591796875],
        [78.45765686035185, 12.662249565124625],
        [78.45935821533209, 12.638760566711483],
        [78.46292877197266, 12.635370254516545],
        [78.46189880371105, 12.632279396057072],
        [78.45907592773455, 12.631520271301326],
        [78.46008300781256, 12.62045955657959],
        [78.45961761474604, 12.617409706115666],
        [78.45478057861334, 12.612019538879395],
        [78.45140075683605, 12.615751266479549],
        [78.44744873046892, 12.616500854492188],
        [78.43943786621111, 12.616920471191463],
        [78.42489624023449, 12.614900588989371],
        [78.41834259033226, 12.620641708374023],
        [78.41233062744158, 12.618310928344783],
        [78.40711212158232, 12.617520332336426],
        [78.39980316162115, 12.613319396972713],
        [78.39955902099626, 12.613350868225211],
        [78.39752960205084, 12.613590240478572],
        [78.38613891601591, 12.613870620727539],
        [78.37420654296892, 12.613091468811035],
        [78.36833953857428, 12.611840248108024],
        [78.36054992675804, 12.615111351013184],
        [78.34835815429693, 12.623641014099235],
        [78.33883666992216, 12.631900787353572],
        [78.32939910888672, 12.638430595397892],
        [78.32235717773438, 12.637200355529899],
        [78.30876922607439, 12.649999618530273],
        [78.29496765136747, 12.654240608215389],
        [78.29045867919939, 12.652920722961483],
        [78.28885650634794, 12.656560897827205],
        [78.2870025634765, 12.658149719238395],
        [78.2841873168947, 12.667619705200195],
        [78.26722717285156, 12.687430381774902],
        [78.26442718505865, 12.691840171814022],
        [78.23906707763678, 12.692392349243107],
        [78.23265838623064, 12.69376087188732],
        [78.22744750976591, 12.689281463623104],
        [78.22664642334007, 12.680141448974666],
        [78.22808074951189, 12.673851013183537],
        [78.21398925781267, 12.674751281738395],
        [78.20011138916021, 12.675642013549862],
        [78.19683837890625, 12.67859077453619],
        [78.1953125, 12.679969787597656],
        [78.18984985351568, 12.681309700012264],
        [78.18946075439476, 12.683550834655819],
        [78.19877624511724, 12.688711166381836],
        [78.19934082031256, 12.689979553222713],
        [78.1947326660158, 12.695269584655705],
        [78.19608306884766, 12.69668960571289],
        [78.19999694824247, 12.696221351623535],
        [78.20324707031278, 12.699009895324707],
        [78.20980834960938, 12.699260711670036],
        [78.21382141113281, 12.702360153198356],
        [78.22032928466803, 12.703289985656852],
        [78.22722625732445, 12.7151718139649],
        [78.22886657714861, 12.720202445983944],
        [78.21927642822277, 12.747029304504395],
        [78.21991729736328, 12.7496213912965],
        [78.22064971923845, 12.75259017944336],
        [78.22512054443382, 12.75493144989025],
        [78.22949981689459, 12.762009620666504],
        [78.23542785644554, 12.787579536438045],
        [78.24021911621122, 12.803141593933162],
        [78.24186706542969, 12.812331199645996],
        [78.24109649658203, 12.847420692443961],
        [78.24465942382807, 12.851100921630973],
        [78.24874877929693, 12.849849700927734],
        [78.2538223266601, 12.849590301513786],
        [78.25814819335966, 12.855629920959416],
        [78.26262664794939, 12.856590270996094],
        [78.27056884765653, 12.850549697875977],
        [78.27568817138678, 12.849160194396916],
        [78.30310058593756, 12.847200393676815],
        [78.30622100830107, 12.856471061706543],
        [78.30409240722679, 12.865760803222656],
        [78.31105041503923, 12.865370750427246],
        [78.32158660888672, 12.862850189209041],
        [78.32271575927751, 12.867851257324219],
        [78.3274917602539, 12.874040603637695],
        [78.32271575927751, 12.876190185546989],
        [78.32141876220709, 12.871659278869572],
        [78.31617736816418, 12.872201919555778],
        [78.31093597412115, 12.8738307952882],
        [78.30996704101568, 12.876482009887695],
        [78.313285827637, 12.87977123260498],
        [78.3124084472658, 12.88330078125],
        [78.31347656250028, 12.888621330261287],
        [78.31645965576178, 12.892610549926871],
        [78.3221664428711, 12.889600753784237],
        [78.32718658447271, 12.889230728149357],
        [78.33833312988298, 12.906499862670842],
        [78.33773040771496, 12.91312122344982],
        [78.33827209472685, 12.91575145721447],
        [78.34398651123075, 12.918091773986873],
        [78.34041595458979, 12.921989440917912],
        [78.34076690673857, 12.924920082092285],
        [78.34542846679688, 12.928060531616211],
        [78.36097717285185, 12.91161918640148],
        [78.3686828613283, 12.901780128479118],
        [78.37834167480497, 12.895191192626896],
        [78.38337707519531, 12.895409584045467],
        [78.38508605957048, 12.896250724792537],
        [78.3883972167971, 12.90552043914795],
        [78.39953613281244, 12.896201133728141],
        [78.40291595459013, 12.89132118225092],
        [78.41152954101591, 12.890899658203068],
        [78.41734313964844, 12.889031410217285],
        [78.42465972900419, 12.893410682678166],
        [78.42489624023449, 12.898170471191406],
        [78.42640686035185, 12.9020614624024],
        [78.43582916259766, 12.900670051574764],
        [78.43570709228521, 12.888859748840389],
        [78.43830108642595, 12.88624095916748],
        [78.43849182128923, 12.879740715026855],
        [78.44644165039074, 12.87473201751709],
        [78.45266723632835, 12.865491867065373],
        [78.45211791992193, 12.855721473693904],
        [78.45423889160185, 12.85316181182867],
        [78.4569473266601, 12.866059303283748],
        [78.45833587646484, 12.879720687866268],
        [78.4561309814456, 12.892752647400016],
        [78.44905090332054, 12.896658897399902],
        [78.44290161132812, 12.904111862182674],
        [78.44123840332037, 12.905632019042969],
        [78.43447113037126, 12.908270835876465],
        [78.42594909667974, 12.917130470275936],
        [78.42135620117193, 12.923529624938965],
        [78.42035675048845, 12.928958892822209],
        [78.42328643798834, 12.933799743652457],
        [78.42459106445312, 12.93820953369135],
        [78.41977691650408, 12.946681976318303],
        [78.42057800292969, 12.947649955749512],
        [78.42735290527372, 12.950531005859432],
        [78.42384338378912, 12.954401016235408],
        [78.42282104492205, 12.957731246948299],
        [78.42191314697266, 12.962748527526855],
        [78.42265319824236, 12.968600273132267],
        [78.42565155029308, 12.969771385192928],
        [78.43358612060553, 12.968779563903865],
        [78.4579010009765, 12.963241577148438],
        [78.45759582519537, 12.970660209655875],
        [78.45939636230497, 12.975751876831055],
        [78.46142578125006, 12.979120254516602],
        [78.45542144775385, 12.990090370178336],
        [78.45231628417997, 13.003760337829704],
        [78.45263671875006, 13.008581161499023],
        [78.45036315917974, 13.014870643615836],
        [78.44991302490251, 13.025811195373535],
        [78.45060729980497, 13.028240203857479],
        [78.45157623291044, 13.031532287597656],
        [78.4550094604495, 13.032590866088867],
        [78.46105194091803, 13.032010078430176],
        [78.46472167968778, 13.034899711608944],
        [78.46704864501964, 13.04137134552002],
        [78.4710769653322, 13.043858528137207],
        [78.47738647460955, 13.043221473693904],
        [78.48108673095726, 13.044389724731502],
        [78.48750305175798, 13.04939079284668],
        [78.48792266845709, 13.049720764160213],
        [78.50457763671903, 13.052762031555119],
        [78.50479888916044, 13.059379577636719],
        [78.49857330322283, 13.069790840148926],
        [78.49958038330084, 13.075821876525936],
        [78.50192260742216, 13.081661224365291],
        [78.50681304931646, 13.08419132232666],
        [78.51380920410173, 13.08468055725109],
        [78.51480865478516, 13.091110229492244],
        [78.52796173095703, 13.097359657287598],
        [78.5223770141601, 13.09966182708746],
        [78.5161972045899, 13.100819587707463],
        [78.5111389160158, 13.106329917907772],
        [78.50869750976562, 13.113269805908203],
        [78.50875091552757, 13.119589805603027],
        [78.51291656494146, 13.12023067474371],
        [78.52143096923845, 13.12905120849615],
        [78.53544616699241, 13.13074016571045],
        [78.53669738769548, 13.136930465698242],
        [78.53269958496111, 13.142091751098746],
        [78.53369903564459, 13.146109580993652],
        [78.53878021240229, 13.151571273803825],
        [78.546859741211, 13.153430938720817],
        [78.55805969238298, 13.15234184265131],
        [78.56125640869169, 13.15570068359375],
        [78.5653686523438, 13.156810760498104],
        [78.56907653808622, 13.156482696533317],
        [78.5656814575197, 13.17175102233898],
        [78.5647277832033, 13.174040794372615],
        [78.56333923339872, 13.177400588989371],
        [78.55940246582048, 13.179800987243766],
        [78.5564727783206, 13.179841995239315],
        [78.5534973144533, 13.179870605468693],
        [78.54688262939459, 13.178620338439941],
        [78.54247283935553, 13.180170059204102],
        [78.54094696044928, 13.187251091003418],
        [78.55326080322277, 13.189241409301871],
        [78.55837249755888, 13.192180633544922],
        [78.5601272583009, 13.198200225830135],
        [78.56588745117205, 13.200889587402344],
        [78.5686798095706, 13.204262733459586],
        [78.56433105468744, 13.208050727844238],
        [78.55719757080101, 13.212020874023494],
        [78.55887603759771, 13.227130889892578],
        [78.55941009521501, 13.230381965637207],
        [78.5598907470706, 13.233371734619084],
        [78.56321716308611, 13.238410949706974],
        [78.56854248046892, 13.243530273437614],
        [78.57215118408197, 13.248600959777946],
        [78.5774688720706, 13.26204967498785],
        [78.57611083984403, 13.267129898071289],
        [78.56716918945341, 13.271771430969238],
        [78.56443786621116, 13.283410072326774],
        [78.56156158447271, 13.288039207458496],
        [78.56009674072294, 13.293349266052246],
        [78.55565643310553, 13.299429893493596],
        [78.55413818359398, 13.298301696777287],
        [78.55323028564482, 13.284620285034237],
        [78.55060577392572, 13.276029586792049],
        [78.54717254638666, 13.27267074584961],
        [78.54361724853521, 13.27323246002203],
        [78.53557586669939, 13.276080131530762],
        [78.53327178955095, 13.276000976562443],
        [78.52876281738287, 13.275861740112362],
        [78.52561950683588, 13.269290924072266],
        [78.52377319335938, 13.260621070861816],
        [78.52229309082054, 13.258111953735295],
        [78.52079772949247, 13.25559139251709],
        [78.51550292968744, 13.257149696350098],
        [78.51197052001959, 13.25995063781744],
        [78.50833129882818, 13.269471168518123],
        [78.5039291381836, 13.272450447082633],
        [78.50115966796903, 13.277211189270133],
        [78.49521636962896, 13.279211997985783],
        [78.48352813720703, 13.288080215454102],
        [78.47255706787138, 13.291131973266602],
        [78.47017669677734, 13.293901443481502],
        [78.47174835205101, 13.307920455932674],
        [78.4664001464846, 13.31012153625494],
        [78.46053314209013, 13.307920455932674],
        [78.45417785644537, 13.304149627685547],
        [78.44790649414085, 13.302819252014217],
        [78.44507598876982, 13.301399230957031],
        [78.44130706787104, 13.299501419067326],
        [78.43785858154308, 13.298760414123535],
        [78.43598175048845, 13.298350334167594],
        [78.43031311035156, 13.307828903198242],
        [78.42906951904325, 13.31711196899414],
        [78.42682647705095, 13.318079948425293],
        [78.42446899414091, 13.319101333618278],
        [78.4207763671875, 13.319091796874943],
        [78.41526031494146, 13.317130088806266],
        [78.4070663452149, 13.310091972351131],
        [78.40206146240251, 13.307379722595272],
        [78.39125061035179, 13.30370998382574],
        [78.38291168212919, 13.314070701599235],
        [78.37914276123064, 13.316879272460938],
        [78.37512207031244, 13.31859111785883],
        [78.36699676513678, 13.320039749145565],
        [78.36791229248064, 13.328489303588924],
        [78.37129211425793, 13.332408905029297],
        [78.37754058837896, 13.331319808959961],
        [78.37538909912115, 13.326401710510254],
        [78.37899017333984, 13.324521064758414],
        [78.38456726074224, 13.323540687561035],
        [78.38838958740257, 13.318451881408748],
        [78.39125061035179, 13.32371044158947],
        [78.39523315429693, 13.322569847106934],
        [78.39737701416021, 13.332331657409611],
        [78.39253234863281, 13.335540771484375],
        [78.3849105834961, 13.338200569152889],
        [78.38366699218778, 13.342350959777832],
        [78.37477111816423, 13.346409797668514],
        [78.36818695068365, 13.348311424255371],
        [78.36402130126982, 13.348301887512264],
        [78.36051177978521, 13.346961975097656],
        [78.3583984375, 13.346840858459473],
        [78.35640716552763, 13.346719741821346],
        [78.35515594482422, 13.355071067810172],
        [78.3598175048831, 13.364321708679313],
        [78.36444854736322, 13.370491027832088],
        [78.3687133789063, 13.379811286926326],
        [78.37127685546903, 13.391000747680778],
        [78.37127685546903, 13.39717960357666],
        [78.36946105957031, 13.408949851989746],
        [78.3673171997072, 13.415000915527457],
        [78.36531066894554, 13.42439079284668],
        [78.36702728271507, 13.431270599365291],
        [78.36463165283197, 13.436850547790584],
        [78.36416625976585, 13.437911033630314],
        [78.36419677734403, 13.445611953735295],
        [78.3625564575197, 13.452071189880371],
        [78.36357879638678, 13.459240913391113],
        [78.36728668212902, 13.466851234436092],
        [78.36744689941435, 13.481970787048283],
        [78.36828613281267, 13.489769935607967],
        [78.36728668212902, 13.495171546935978],
        [78.36958312988293, 13.499930381774902],
        [78.37419128417986, 13.505510330200195],
        [78.37309265136719, 13.512250900268668],
        [78.38546752929705, 13.531669616699276],
        [78.38600921630876, 13.543951988220158],
        [78.39775848388678, 13.566191673278865],
        [78.39389801025396, 13.57065200805664],
        [78.3907623291015, 13.578100204467773],
        [78.38031768798845, 13.58130931854248],
        [78.36709594726574, 13.580300331115723],
        [78.36139678955072, 13.578872680664006],
        [78.34366607666033, 13.577780723571777],
        [78.3228302001956, 13.582990646362418],
        [78.31247711181663, 13.583501815795955],
        [78.29183959960938, 13.578820228576717],
        [78.28058624267578, 13.57767200469965],
        [78.26539611816435, 13.57822132110607],
        [78.25827026367188, 13.575309753417969],
        [78.24967956542969, 13.574300765991211],
        [78.236557006836, 13.584490776061955],
        [78.22769165039057, 13.58722114562994],
        [78.22261047363287, 13.5770902633667],
        [78.2184066772461, 13.57800197601324],
        [78.21479034423834, 13.58010101318365],
        [78.20849609375006, 13.581880569458065],
        [78.20240020751959, 13.567969322204704],
        [78.1958389282226, 13.560681343078556],
        [78.18900299072283, 13.556380271911621],
        [78.18327331542986, 13.556380271911621],
        [78.18161010742193, 13.559150695800838],
        [78.18012237548834, 13.564171791076774],
        [78.18105316162115, 13.573700904846305],
        [78.17946624755876, 13.578941345214844],
        [78.18856811523449, 13.588401794433707],
        [78.1937408447265, 13.594902038574162],
        [78.19536590576195, 13.601550102233944],
        [78.19393920898455, 13.616159439086914],
        [78.18583679199241, 13.621179580688533],
        [78.16555786132812, 13.620970726013297],
        [78.16053771972668, 13.623430252075195],
        [78.15899658203153, 13.645171165466365],
        [78.15576171875011, 13.648710250854492],
        [78.14617156982428, 13.649660110473746],
        [78.12908935546892, 13.648269653320426],
        [78.12322998046875, 13.648900985717773],
        [78.11589050292997, 13.64563083648693],
        [78.10736083984392, 13.647091865539494],
        [78.09390258789085, 13.64026069641119],
        [78.09060668945312, 13.633000373840332],
        [78.08634948730463, 13.633501052856445],
        [78.08072662353521, 13.63534069061285],
        [78.0796585083009, 13.644541740417594],
        [78.07998657226568, 13.650389671325627],
        [78.07280731201166, 13.655171394348145],
        [78.06585693359398, 13.66449069976818],
        [78.05834197998064, 13.666970252990836],
        [78.06092071533209, 13.671770095825309],
        [78.0640869140625, 13.674559593200797],
        [78.06537628173822, 13.680060386657772],
        [78.0626068115235, 13.685600280761776],
        [78.06405639648455, 13.688179969787711],
        [78.08515167236357, 13.680631637573356],
        [78.09986877441435, 13.682561874389762],
        [78.10537719726591, 13.684400558471737],
        [78.10721588134771, 13.69835186004633],
        [78.10988616943365, 13.703951835632324],
        [78.10938262939482, 13.710230827331543],
        [78.10550689697271, 13.714632034301815],
        [78.10044097900419, 13.718011856079102],
        [78.09738922119169, 13.723151206970272],
        [78.09327697753906, 13.727621078491211],
        [78.0849227905274, 13.733491897583008],
        [78.08927154541016, 13.743049621582145],
        [78.09532165527338, 13.750280380249137],
        [78.10119628906278, 13.752989768981877],
        [78.10649871826178, 13.756791114807186],
        [78.11100769042969, 13.761751174926815],
        [78.11823272705095, 13.776900291442871],
        [78.11517333984392, 13.787679672241154],
        [78.10900115966825, 13.798362731933707],
        [78.10765838623075, 13.803600311279354],
        [78.11725616455072, 13.821771621704102],
        [78.11353302001959, 13.840890884399471],
        [78.10604095459001, 13.8535413742066],
        [78.0973663330081, 13.852761268615836],
        [78.08837890625006, 13.85491943359375],
        [78.07491302490229, 13.85491943359375],
        [78.07092285156267, 13.871959686279297],
        [78.0679626464846, 13.874520301818961],
        [78.06034851074224, 13.876781463623047],
        [78.05471038818376, 13.879351615905705],
        [78.04831695556663, 13.884530067443848],
        [78.0411529541015, 13.886729240417537],
        [78.04270172119158, 13.873379707336483],
        [78.04292297363281, 13.863780975341797],
        [78.04133605957026, 13.858051300048828],
        [78.03782653808616, 13.852849960327205],
        [78.01558685302729, 13.85438156127941],
        [78.0103378295899, 13.856490135192928],
        [78.00477600097656, 13.861710548400993],
        [77.99149322509771, 13.862410545349178],
        [77.98516082763678, 13.861669540405387],
        [77.98226928710938, 13.856801986694336],
        [77.98123931884777, 13.848799705505371],
        [77.97038269042963, 13.826642036437988],
        [77.96578216552763, 13.820159912109318],
        [77.94458007812528, 13.81997108459484],
        [77.94814300537115, 13.824801445007267],
        [77.94805145263683, 13.829360961914062],
        [77.94571685791021, 13.839801788330135],
        [77.94184112548828, 13.871050834655819],
        [77.9424667358399, 13.880230903625488],
        [77.94951629638695, 13.884470939636344],
        [77.9716186523438, 13.88858890533453],
        [77.97802734375017, 13.89334964752203],
        [77.97466278076178, 13.905331611633414],
        [77.97460174560564, 13.912251472473145],
        [77.97336578369146, 13.919881820678711],
        [77.97351074218756, 13.92422962188715],
        [77.97795104980474, 13.937620162963867],
        [77.97857666015636, 13.947650909423942],
        [77.9664306640625, 13.95055198669445],
        [77.96127319335955, 13.949971199035588],
        [77.95532226562494, 13.941900253295955],
        [77.95426940917974, 13.93704986572277],
        [77.95075988769548, 13.927920341491756],
        [77.94905090332048, 13.926739692687988],
        [77.9460220336914, 13.928430557251033],
        [77.93885803222685, 13.92461967468273],
        [77.93564605712919, 13.917909622192383],
        [77.92973327636747, 13.908809661865234],
        [77.91980743408232, 13.898701667785645],
        [77.91160583496122, 13.897240638732853],
        [77.90593719482439, 13.899451255798454],
        [77.90045166015625, 13.90234088897705],
        [77.89570617675787, 13.907719612121639],
        [77.88968658447277, 13.911351203918514],
        [77.88732147216803, 13.917740821838436],
        [77.88786315917969, 13.927721977233944],
        [77.88698577880888, 13.931181907653809],
        [77.86242675781244, 13.926800727844295],
        [77.85407257080095, 13.927079200744686],
        [77.846000671387, 13.92832088470459],
        [77.83011627197288, 13.927151679992676],
        [77.821586608887, 13.930541038513297],
        [77.81829071044945, 13.934881210327262],
        [77.8142318725586, 13.931001663208121],
        [77.80848693847673, 13.922589302063045],
        [77.80635070800776, 13.917550086975154],
        [77.80635833740229, 13.908640861511287],
        [77.807373046875, 13.903229713439998],
        [77.81108093261747, 13.894701957702694],
        [77.81468963623075, 13.89111042022705],
        [77.82099151611357, 13.889231681823844],
        [77.82504272460932, 13.879900932312125],
        [77.82724761962913, 13.871600151062012],
        [77.81920623779303, 13.866021156311035],
        [77.82772064208979, 13.860619544982853],
        [77.81080627441406, 13.852111816406307],
        [77.80784606933622, 13.846671104431152],
        [77.80278015136719, 13.840669631958008],
        [77.79453277587885, 13.840959548950309],
        [77.786376953125, 13.83992004394537],
        [77.77603912353533, 13.83591079711914],
        [77.77410125732433, 13.831730842590275],
        [77.77742767333984, 13.824060440063477],
        [77.78026580810564, 13.820020675659237],
        [77.78044128417986, 13.816691398620662],
        [77.7784881591798, 13.8128919601441],
        [77.76921081542969, 13.80854129791254],
        [77.7553329467774, 13.807540893554801],
        [77.74817657470697, 13.806209564208984],
        [77.7410278320313, 13.803791999816951],
        [77.73359680175804, 13.80344104766857],
        [77.72841644287115, 13.801739692688102],
        [77.71849822998053, 13.792721748352108],
        [77.71286010742216, 13.790519714355526],
        [77.71512603759794, 13.783271789550838],
        [77.71275329589844, 13.774709701538029],
        [77.71420288085966, 13.760790824890137],
        [77.71314239501953, 13.750740051269474],
        [77.71346282958979, 13.746150016784725],
        [77.71100616455095, 13.73770999908453],
        [77.70874786376953, 13.732739448547306],
        [77.70736694335932, 13.734180450439567],
        [77.70218658447294, 13.730991363525334],
        [77.69552612304699, 13.761431694030875],
        [77.68467712402361, 13.758621215820312],
        [77.67881774902344, 13.755681037902832],
        [77.66850280761719, 13.752281188964844],
        [77.66371154785173, 13.752260208129996],
        [77.66979217529291, 13.762559890747184],
        [77.67176818847662, 13.77013111114502],
        [77.67022705078125, 13.777499198913517],
        [77.6640167236331, 13.775870323181266],
        [77.65659332275419, 13.77666091918951],
        [77.64908599853521, 13.775839805603141],
        [77.61434936523438, 13.760310173034782],
        [77.61046600341791, 13.755439758300838],
        [77.61264801025396, 13.746870040893498],
        [77.63375091552751, 13.741291046142521],
        [77.63674926757841, 13.735241889953613],
        [77.63356018066423, 13.732839584350643],
        [77.62851715087896, 13.729941368103027],
        [77.62124633789068, 13.727401733398494],
        [77.61564636230486, 13.728361129760685],
        [77.60427093505865, 13.742550849914608],
        [77.5955963134765, 13.743578910827637],
        [77.586898803711, 13.736789703369197],
        [77.58154296875, 13.730571746826286],
        [77.57363128662138, 13.72665023803711],
        [77.56661987304705, 13.729389190673885],
        [77.55607604980497, 13.716460227966365],
        [77.54814147949236, 13.720409393310604],
        [77.54514312744146, 13.728180885314998],
        [77.541557312012, 13.734931945800781],
        [77.53951263427729, 13.743720054626465],
        [77.5295028686524, 13.745850563049316],
        [77.51966857910168, 13.74384975433344],
        [77.51512145996111, 13.737039566039982],
        [77.52268981933622, 13.731071472168082],
        [77.52659606933594, 13.725250244140682],
        [77.52754211425798, 13.719030380248967],
        [77.52783966064482, 13.707031250000114],
        [77.5255661010745, 13.697210311889705],
        [77.52152252197271, 13.68787002563488],
        [77.51232910156256, 13.685680389404354],
        [77.50797271728521, 13.688799858093375],
        [77.50691986084001, 13.692661285400334],
        [77.50679016113287, 13.700470924377441],
        [77.50276947021484, 13.704409599304313],
        [77.48734283447271, 13.706791877746525],
        [77.479721069336, 13.703210830688533],
        [77.48239898681663, 13.698830604553336],
        [77.48352050781256, 13.691700935363883],
        [77.48513793945324, 13.686690330505371],
        [77.48294067382818, 13.67970180511486],
        [77.47648620605491, 13.677599906921387],
        [77.44975280761724, 13.675790786743278],
        [77.44481658935553, 13.695760726928654],
        [77.44600677490263, 13.700639724731445],
        [77.45340728759788, 13.70409965515148],
        [77.45684814453148, 13.712639808654785],
        [77.4580688476562, 13.723038673400936],
        [77.45809936523455, 13.730330467224121],
        [77.46006011962896, 13.73388957977295],
        [77.46261596679716, 13.743578910827637],
        [77.46212005615251, 13.749051094055233],
        [77.45873260498053, 13.749810218810978],
        [77.45395660400396, 13.778041839599666],
        [77.45240020751953, 13.780401229858512],
        [77.45079040527372, 13.785531044006348],
        [77.45273590087885, 13.791319847107047],
        [77.4574508666995, 13.797050476074332],
        [77.45559692382807, 13.799810409545955],
        [77.44676971435575, 13.802029609680176],
        [77.44056701660173, 13.802291870117188],
        [77.43546295166021, 13.79923057556158],
        [77.42865753173857, 13.787989616393986],
        [77.42005920410185, 13.788961410522518],
        [77.41689300537126, 13.79094028472906],
        [77.41954040527338, 13.79580116271967],
        [77.41925811767584, 13.799710273742676],
        [77.41470336914068, 13.800190925598201],
        [77.41158294677734, 13.798891067504883],
        [77.40866088867199, 13.799251556396598],
        [77.40820312500023, 13.80247020721447],
        [77.41368103027344, 13.807380676269474],
        [77.41275787353521, 13.811750411987305],
        [77.41506195068365, 13.815360069274846],
        [77.42472076416033, 13.826689720153809],
        [77.42624664306658, 13.829730033874625],
        [77.42453002929705, 13.835129737854004],
        [77.41873931884794, 13.833530426025447],
        [77.40393066406267, 13.83142185211193],
        [77.39920043945312, 13.832420349121094],
        [77.38983917236328, 13.832929611206055],
        [77.38111114501959, 13.831251144409237],
        [77.37285614013689, 13.831289291381893],
        [77.3731307983399, 13.838191032409668],
        [77.36753845214844, 13.843209266662711],
        [77.35946655273432, 13.845670700073185],
        [77.35220336914074, 13.844381332397461],
        [77.34697723388695, 13.839580535888672],
        [77.33988189697266, 13.834849357605037],
        [77.33468627929705, 13.829420089721793],
        [77.32724761962908, 13.82479190826416],
        [77.32000732421892, 13.825751304626522],
        [77.31616210937494, 13.839731216430664],
        [77.31088256835938, 13.851131439209098],
        [77.30693054199241, 13.857239723205566],
        [77.29602813720709, 13.853561401367188],
        [77.28970336914062, 13.85002231597906],
        [77.2855224609375, 13.8460311889649],
        [77.28292846679716, 13.84097957611084],
        [77.27514648437511, 13.842801094055176],
        [77.25595092773438, 13.839450836181697],
        [77.25044250488287, 13.839470863342285],
        [77.25303649902338, 13.856121063232365],
        [77.24373626708984, 13.860290527343807],
        [77.24073791503929, 13.865881919860954],
        [77.23925018310575, 13.873590469360465],
        [77.23882293701178, 13.881509780883846],
        [77.24156188964872, 13.888851165771541],
        [77.24009704589861, 13.894080162048397],
        [77.23384094238287, 13.894931793212947],
        [77.22547912597679, 13.895469665527457],
        [77.22189331054716, 13.88883113861084],
        [77.22093200683622, 13.881760597229118],
        [77.21700286865251, 13.86926174163824],
        [77.21150970458984, 13.861418724060115],
        [77.2081222534182, 13.86040115356451],
        [77.20884704589844, 13.878431320190543],
        [77.20494079589872, 13.881160736083984],
        [77.20256805419928, 13.874840736389217],
        [77.20095825195307, 13.866519927978572],
        [77.19689178466803, 13.859530448913517],
        [77.19271087646513, 13.856221199035701],
        [77.17880249023466, 13.85984039306635],
        [77.17513275146507, 13.86140060424816],
        [77.17822265625028, 13.871391296386776],
        [77.17959594726557, 13.878331184387207],
        [77.17920684814464, 13.883280754089412],
        [77.18134307861345, 13.89118957519537],
        [77.18221282959013, 13.901300430297852],
        [77.18161773681663, 13.910770416259822],
        [77.17703247070335, 13.917400360107422],
        [77.17006683349604, 13.916341781616268],
        [77.15956115722668, 13.90846061706543],
        [77.15593719482433, 13.899811744689998],
        [77.15702056884771, 13.891301155090332],
        [77.16171264648455, 13.882431030273494],
        [77.16237640380888, 13.876291275024357],
        [77.16491699218744, 13.866218566894588],
        [77.16249084472656, 13.856980323791618],
        [77.14821624755865, 13.859801292419377],
        [77.13938903808611, 13.859040260315055],
        [77.13015747070341, 13.84993934631359],
        [77.12388610839855, 13.84939002990717],
        [77.12526702880876, 13.843010902404842],
        [77.1404571533206, 13.837889671325684],
        [77.14576721191412, 13.834939956665039],
        [77.14789581298857, 13.828850746154842],
        [77.15791320800781, 13.825470924377555],
        [77.1622467041015, 13.82074069976818],
        [77.16194152832037, 13.815461158752441],
        [77.15619659423828, 13.811340332031364],
        [77.15537261962885, 13.807390213012809],
        [77.15632629394526, 13.803759574890194],
        [77.15409851074219, 13.80056190490717],
        [77.15573883056652, 13.79814243316656],
        [77.15599822998047, 13.79331016540533],
        [77.15849304199219, 13.787100791931266],
        [77.16407012939476, 13.780350685119629],
        [77.16918945312506, 13.775560379028434],
        [77.1713409423831, 13.772049903869686],
        [77.16964721679716, 13.761369705200252],
        [77.1665802001956, 13.754669189453182],
        [77.15815734863304, 13.754931449890194],
        [77.14673614501982, 13.754751205444336],
        [77.13751983642584, 13.755652427673454],
        [77.13410949707037, 13.753030776977653],
        [77.12351989746088, 13.750890731811523],
        [77.11094665527361, 13.754620552063102],
        [77.095268249512, 13.760880470275879],
        [77.08577728271501, 13.758111000061092],
        [77.0852127075197, 13.754550933837947],
        [77.08036041259794, 13.753422737121639],
        [77.07927703857416, 13.74602031707775],
        [77.08058929443371, 13.73739147186285],
        [77.07148742675798, 13.738750457763729],
        [77.06004333496111, 13.738861083984489],
        [77.0547485351563, 13.744500160217285],
        [77.047348022461, 13.75607967376709],
        [77.03518676757841, 13.769281387329045],
        [77.02636718750006, 13.771441459655762],
        [77.0198669433596, 13.76986026763916],
        [77.0184402465822, 13.761699676513615],
        [77.01384735107416, 13.747471809387207],
        [77.01142120361328, 13.732721328735352],
        [77.00193786621116, 13.736661911010799],
        [76.99392700195335, 13.741510391235408],
        [76.99259185791038, 13.742550849914608],
        [76.99167633056669, 13.745341300964355],
        [76.99212646484392, 13.749508857726994],
        [76.99012756347662, 13.753089904785156],
        [76.98898315429693, 13.760180473327637],
        [76.98634338378935, 13.766521453857422],
        [76.984359741211, 13.776570320129508],
        [76.98123168945307, 13.785799980163631],
        [76.98484802246105, 13.788701057434139],
        [76.98577880859403, 13.793000221252498],
        [76.98371887207026, 13.804189682006893],
        [76.98002624511724, 13.807191848754996],
        [76.96882629394543, 13.810241699218693],
        [76.96839141845709, 13.81439018249506],
        [76.9715576171875, 13.818861007690487],
        [76.97653961181646, 13.821360588073844],
        [76.98398590087896, 13.827740669250488],
        [76.98625946044922, 13.839229583740291],
        [76.98992919921898, 13.839509963989258],
        [76.99413299560553, 13.838970184326172],
        [77.00598907470732, 13.850090026855582],
        [77.00711822509794, 13.854171752929688],
        [77.00802612304693, 13.862900733947697],
        [77.01230621337902, 13.864090919494629],
        [77.01259613037115, 13.867239952087346],
        [77.01169586181669, 13.871440887451172],
        [77.01661682128912, 13.887729644775504],
        [77.01728057861322, 13.893370628356877],
        [77.02780914306658, 13.89717006683361],
        [77.04128265380888, 13.897399902343693],
        [77.03584289550781, 13.925591468811092],
        [77.0186004638673, 13.93479156494135],
        [77.0139770507813, 13.939481735229606],
        [77.00810241699247, 13.947369575500488],
        [76.99942016601562, 13.95044040679943],
        [76.9950561523438, 13.952721595764274],
        [76.99433135986334, 13.95337963104248],
        [76.98899841308622, 13.95669174194336],
        [76.99019622802729, 13.970279693603572],
        [76.99133300781261, 13.975811958313045],
        [76.99437713623058, 13.981491088867301],
        [76.98501586914091, 13.987520217895508],
        [76.97734832763678, 13.993870735168457],
        [76.97764587402361, 13.993860244751033],
        [76.97518157958984, 13.996589660644474],
        [76.96420288085966, 14.002240180969181],
        [76.94857025146513, 14.006779670715446],
        [76.94474029541021, 14.010680198669377],
        [76.94232940673822, 14.01548004150385],
        [76.94078063964855, 14.020601272583065],
        [76.93109893798845, 14.022731781005916],
        [76.93371582031278, 14.040671348571777],
        [76.93797302246111, 14.047599792480526],
        [76.94328308105463, 14.047219276428223],
        [76.94972991943388, 14.04784107208252],
        [76.97152709960955, 14.05239105224615],
        [76.97421264648455, 14.057709693908691],
        [76.97500610351562, 14.065270423889103],
        [76.97225189208996, 14.068401336669865],
        [76.97184753417997, 14.075711250305176],
        [76.96808624267595, 14.079739570617676],
        [76.96012115478516, 14.082969665527457],
        [76.95143127441435, 14.085371971130428],
        [76.94629669189482, 14.085260391235408],
        [76.9485473632813, 14.091411590576229],
        [76.94822692871105, 14.09658145904541],
        [76.94658660888672, 14.102971076965332],
        [76.94291687011736, 14.111901283264217],
        [76.93739318847673, 14.11515140533453],
        [76.92697906494163, 14.127209663391113],
        [76.92241668701178, 14.12903976440441],
        [76.91712951660168, 14.124480247497615],
        [76.91250610351568, 14.122109413146916],
        [76.8984680175783, 14.12687969207775],
        [76.89090728759771, 14.127840995788688],
        [76.88368225097679, 14.13103199005127],
        [76.88691711425787, 14.136240005493278],
        [76.88919067382818, 14.150878906250114],
        [76.89142608642595, 14.1589994430542],
        [76.8967590332033, 14.161901473998967],
        [76.91718292236345, 14.157670974731445],
        [76.92296600341803, 14.163399696350154],
        [76.93698120117182, 14.16589164733898],
        [76.93924713134794, 14.167710304260197],
        [76.94153594970732, 14.178030014038143],
        [76.93923950195341, 14.18680858612072],
        [76.94164276123053, 14.189311981201286],
        [76.94404602050776, 14.188320159912223],
        [76.9490814208985, 14.183810234069824],
        [76.95378112792986, 14.181030273437443],
        [76.95849609375028, 14.175509452819881],
        [76.9656829833985, 14.174320220947266],
        [76.97276306152366, 14.17428970336914],
        [76.9781875610351, 14.172920227050838],
        [76.9862670898438, 14.172531127929744],
        [76.99439239501947, 14.173749923706055],
        [77.00605010986345, 14.17485141754156],
        [77.01403808593744, 14.173041343688965],
        [77.01982879638672, 14.172701835632267],
        [77.0213394165039, 14.168398857116756],
        [77.02105712890653, 14.16432952880865],
        [77.01600646972656, 14.152690887451286],
        [77.01493835449247, 14.132181167602596],
        [77.01380157470732, 14.127401351928711],
        [77.01920318603533, 14.12529087066656],
        [77.02027130126959, 14.122321128845215],
        [77.01635742187517, 14.117521286010742],
        [77.01703643798857, 14.111950874328613],
        [77.01658630371094, 14.10609245300293],
        [77.008316040039, 14.09813117980957],
        [77.01043701171892, 14.092049598693848],
        [77.0147094726563, 14.088699340820426],
        [77.02398681640642, 14.08337116241455],
        [77.02453613281244, 14.077169418335075],
        [77.02024078369146, 14.061441421508903],
        [77.02369689941412, 14.053258895874023],
        [77.02848052978544, 14.051980018615723],
        [77.03485870361345, 14.051440238952637],
        [77.04322052001947, 14.05167007446289],
        [77.0512008666995, 14.049592018127555],
        [77.06903076171875, 14.047189712524357],
        [77.0695266723634, 14.039441108703556],
        [77.07649230957037, 14.038049697875977],
        [77.09075927734392, 14.03826045989996],
        [77.09665679931658, 14.040700912475643],
        [77.1039657592774, 14.040571212768668],
        [77.1101531982423, 14.04204082489025],
        [77.12110137939453, 14.042180061340389],
        [77.12468719482439, 14.03474140167242],
        [77.12783050537132, 14.01865005493164],
        [77.13403320312517, 14.00967025756836],
        [77.13288116455095, 14.004210472106934],
        [77.13434600830107, 13.998130798339844],
        [77.13949584960966, 13.997190475463924],
        [77.14437103271484, 13.99351978302002],
        [77.14459991455084, 13.99351978302002],
        [77.14733886718778, 13.991621971130371],
        [77.15444183349626, 13.990610122680721],
        [77.15836334228521, 13.99351978302002],
        [77.15815734863304, 13.99351978302002],
        [77.16278076171903, 13.997759819030875],
        [77.16168975830095, 14.006249427795467],
        [77.17138671875011, 14.008599281311149],
        [77.19455718994146, 14.002860069274846],
        [77.20767974853533, 14.004871368408317],
        [77.21884918212919, 14.007910728454647],
        [77.22727203369152, 14.007951736450195],
        [77.24256896972673, 14.00631141662609],
        [77.25575256347656, 14.020958900451717],
        [77.26451873779314, 14.026529312133903],
        [77.27150726318388, 14.028101921081657],
        [77.2742080688476, 14.016181945800895],
        [77.27260589599615, 14.008020401001033],
        [77.27851104736351, 14.006900787353572],
        [77.28518676757835, 14.011529922485352],
        [77.29228210449247, 14.014499664306697],
        [77.29802703857433, 14.023031234741325],
        [77.30355834960949, 14.026729583740291],
        [77.313491821289, 14.024820327758903],
        [77.31648254394537, 14.02145957946783],
        [77.31700897216803, 14.015322685241813],
        [77.32022094726562, 14.009250640869254],
        [77.32409667968773, 14.005310058593864],
        [77.33011627197283, 13.994880676269474],
        [77.3296661376956, 13.993351936340389],
        [77.32977294921875, 13.993351936340389],
        [77.33032989501953, 13.990359306335392],
        [77.33347320556669, 13.988051414489746],
        [77.34567260742216, 13.985580444335938],
        [77.35378265380876, 13.982320785522518],
        [77.35338592529314, 13.976289749145508],
        [77.34693908691406, 13.960589408874625],
        [77.34720611572294, 13.955351829528922],
        [77.3437423706057, 13.950892448425236],
        [77.36200714111328, 13.945468902587947],
        [77.3626098632813, 13.942300796508789],
        [77.35263824462908, 13.928450584411735],
        [77.3426132202149, 13.912241935730037],
        [77.343505859375, 13.905981063842773],
        [77.34590148925793, 13.903330802917537],
        [77.3483428955081, 13.896540641784668],
        [77.35884857177763, 13.895561218261719],
        [77.39041900634766, 13.897689819335994],
        [77.39186096191435, 13.879890441894531],
        [77.39626312255871, 13.880440711975211],
        [77.39705657959001, 13.88054084777832],
        [77.40850830078142, 13.888810157775993],
        [77.41896820068354, 13.894908905029354],
        [77.42562866210949, 13.902051925659237],
        [77.42740631103533, 13.909219741821346],
        [77.42250061035162, 13.91195011138916],
        [77.41015625000028, 13.914601325988826],
        [77.40843963623075, 13.921610832214412],
        [77.40770721435558, 13.936100006103516],
        [77.42257690429699, 13.939001083374023],
        [77.42870330810541, 13.943341255187988],
        [77.42904663085966, 13.956381797790527],
        [77.4309005737307, 13.961991310119686],
        [77.43064117431658, 13.968779563903809],
        [77.42661285400408, 13.973281860351676],
        [77.42050170898432, 13.9768705368042],
        [77.41018676757824, 13.976922035217399],
        [77.38651275634794, 13.97400188446045],
        [77.3756561279298, 13.969111442565975],
        [77.37269592285162, 13.975510597229004],
        [77.37320709228533, 13.981710433959961],
        [77.37988281250017, 13.993281364440918],
        [77.38336181640625, 14.007358551025334],
        [77.3750762939456, 14.012100219726562],
        [77.36232757568354, 14.015990257263297],
        [77.35009765625011, 14.016450881958121],
        [77.34406280517578, 14.019060134887752],
        [77.32618713378929, 14.022939682006893],
        [77.33126068115251, 14.03984165191656],
        [77.33454895019537, 14.047790527343807],
        [77.33796691894531, 14.053831100463867],
        [77.35012054443354, 14.053381919860954],
        [77.35855865478516, 14.058929443359489],
        [77.3613662719726, 14.065951347351074],
        [77.37238311767607, 14.071851730346793],
        [77.37966156005871, 14.09039115905756],
        [77.3856811523438, 14.093461036682243],
        [77.3954467773438, 14.103441238403263],
        [77.39643096923857, 14.1105699539184],
        [77.39444732666021, 14.114641189575252],
        [77.38973999023466, 14.118570327758789],
        [77.38035583496116, 14.11662960052496],
        [77.37325286865234, 14.112580299377385],
        [77.35893249511736, 14.108721733093319],
        [77.35246276855469, 14.105351448059139],
        [77.34851074218773, 14.099261283874625],
        [77.344535827637, 14.096011161804313],
        [77.34651184082048, 14.110440254211426],
        [77.34651184082048, 14.117850303650016],
        [77.34773254394537, 14.124341011047363],
        [77.35485839843778, 14.123749732971248],
        [77.36501312255876, 14.127109527588004],
        [77.3766860961914, 14.129600524902344],
        [77.37873077392572, 14.138279914855957],
        [77.38262176513678, 14.147720336914062],
        [77.38955688476591, 14.153540611267033],
        [77.38971710205084, 14.165260314941406],
        [77.39745330810564, 14.166500091552734],
        [77.40972137451178, 14.169719696044922],
        [77.41631317138689, 14.169301033020133],
        [77.42347717285162, 14.165080070495549],
        [77.45392608642578, 14.162981033325195],
        [77.46333312988304, 14.164230346679801],
        [77.48854064941435, 14.153079986572209],
        [77.50044250488298, 14.150960922241268],
        [77.50267028808622, 14.157120704650936],
        [77.50679016113287, 14.164240837097225],
        [77.51035308837908, 14.172019958496207],
        [77.50942230224626, 14.176950454711914],
        [77.5041427612307, 14.180660247802791],
        [77.5010833740235, 14.193221092224064],
        [77.49971008300781, 14.20446968078619],
        [77.49308776855491, 14.213970184326172],
        [77.49285125732422, 14.220060348510856],
        [77.49008178710966, 14.227630615234375],
        [77.49627685546875, 14.240409851074219],
        [77.49878692626959, 14.249540328979549],
        [77.49936676025396, 14.260510444641113],
        [77.49626159667986, 14.27175140380865],
        [77.49214172363287, 14.27128124237072],
        [77.484390258789, 14.275259971618652],
        [77.47934722900413, 14.284770965576229],
        [77.47912597656256, 14.283740997314396],
        [77.46842956542969, 14.279439926147461],
        [77.45469665527344, 14.272190093994197],
        [77.4436264038086, 14.277550697326774],
        [77.44243621826166, 14.290910720825309],
        [77.44233703613287, 14.3097505569458],
        [77.42752838134794, 14.304849624633903],
        [77.42587280273455, 14.320811271667537],
        [77.42072296142595, 14.321991920471305],
        [77.40928649902361, 14.32710170745844],
        [77.39488983154325, 14.329159736633244],
        [77.38565826416016, 14.32487010955822],
        [77.37882232666021, 14.317079544067326],
        [77.3738632202149, 14.305109977722168],
        [77.37500762939459, 14.29518890380865],
        [77.38120269775385, 14.282991409301758],
        [77.38504791259783, 14.272450447082576],
        [77.3843383789063, 14.265610694885254],
        [77.38986968994146, 14.261389732360897],
        [77.40101623535185, 14.260410308837947],
        [77.40479278564459, 14.248600959777889],
        [77.40618133544933, 14.236710548400993],
        [77.40905761718744, 14.223581314087028],
        [77.41470336914068, 14.217060089111385],
        [77.4157028198245, 14.211961746215934],
        [77.41487884521507, 14.20322132110607],
        [77.3912200927735, 14.190881729126033],
        [77.38378906250023, 14.191821098327637],
        [77.38011169433594, 14.202550888061523],
        [77.3816223144533, 14.210180282592773],
        [77.37661743164074, 14.21671199798584],
        [77.36431121826178, 14.223310470581168],
        [77.35540771484403, 14.230039596557617],
        [77.35923767089872, 14.243600845337028],
        [77.35826873779325, 14.260320663452092],
        [77.35926055908214, 14.269330024719238],
        [77.35340881347656, 14.27067947387701],
        [77.3433685302735, 14.27052021026617],
        [77.3205871582033, 14.273450851440543],
        [77.30748748779303, 14.273839950561637],
        [77.29545593261724, 14.276471138000602],
        [77.28549957275408, 14.274749755859375],
        [77.27958679199236, 14.27608966827404],
        [77.28189086914062, 14.290459632873592],
        [77.2826766967774, 14.30228042602539],
        [77.28114318847662, 14.331110954284725],
        [77.27330780029297, 14.33175086975092],
        [77.26732635498058, 14.327010154724178],
        [77.25785064697266, 14.325701713562012],
        [77.25129699707043, 14.320602416992301],
        [77.24508666992188, 14.314530372619743],
        [77.23195648193365, 14.311070442199707],
        [77.22292327880876, 14.314981460571232],
        [77.20979309082031, 14.313010215759277],
        [77.19635772705107, 14.314299583435172],
        [77.17951202392601, 14.326370239257756],
        [77.17674255371088, 14.326290130615234],
        [77.17188262939476, 14.330230712890625],
        [77.16422271728521, 14.330460548400879],
        [77.15975952148432, 14.336238861083984],
        [77.15215301513689, 14.337740898132324],
        [77.14644622802751, 14.332191467285213],
        [77.14054107666033, 14.336111068725586],
        [77.1355361938476, 14.334012031555233],
        [77.13191986083979, 14.33059120178234],
        [77.13262939453153, 14.32404994964594],
        [77.13092803955107, 14.313810348510799],
        [77.12577056884794, 14.301531791687069],
        [77.11730957031256, 14.291410446166935],
        [77.11192321777361, 14.287530899047852],
        [77.1105422973634, 14.274930000305233],
        [77.10692596435541, 14.261760711670036],
        [77.1155166625976, 14.256819725036678],
        [77.11157989501959, 14.248461723327637],
        [77.11299896240263, 14.239919662475529],
        [77.1104660034182, 14.231980323791447],
        [77.10994720459013, 14.221480369567871],
        [77.1050796508789, 14.21328163146984],
        [77.09522247314459, 14.209480285644531],
        [77.08486938476562, 14.208450317382926],
        [77.07534027099615, 14.20934963226324],
        [77.06761932373058, 14.217860221862907],
        [77.06022644042986, 14.224160194396916],
        [77.05532073974615, 14.232300758361873],
        [77.04962921142601, 14.239542007446346],
        [77.04064941406256, 14.24075889587408],
        [77.0330429077149, 14.240489959716797],
        [77.02458953857422, 14.236680984497127],
        [77.0125122070312, 14.235091209411678],
        [77.00411987304682, 14.231569290161246],
        [76.9945602416995, 14.23556041717535],
        [76.9927902221682, 14.232989311218205],
        [76.98937988281278, 14.23640060424816],
        [76.98248291015648, 14.238151550293026],
        [76.97560882568365, 14.237420082092342],
        [76.96971893310553, 14.235211372375545],
        [76.96157073974621, 14.235901832580623],
        [76.95139312744163, 14.234151840210075],
        [76.94346618652338, 14.236310005187988],
        [76.93784332275396, 14.240120887756404],
        [76.9359893798831, 14.248771667480469],
        [76.93363189697271, 14.253500938415527],
        [76.92582702636724, 14.264399528503532],
        [76.92485809326178, 14.272180557251033],
        [76.93548583984392, 14.276641845703125],
        [76.94375610351568, 14.281681060791016],
        [76.94873809814459, 14.286761283874455],
        [76.95011138916044, 14.2940292358399],
        [76.9467010498048, 14.300449371337947],
        [76.94293975830095, 14.305378913879395],
        [76.93656158447294, 14.308409690856877],
        [76.92314910888678, 14.309650421142692],
        [76.91680908203142, 14.312259674072322],
        [76.90615844726568, 14.315340995788688],
        [76.89897155761747, 14.31924057006836],
        [76.88536071777344, 14.33959102630621],
        [76.87909698486334, 14.346389770507756],
        [76.87861633300776, 14.351392745971737],
        [76.88314056396513, 14.390291213989371],
        [76.88677978515653, 14.392451286315918],
        [76.89232635498075, 14.392870903015137],
        [76.89663696289068, 14.394479751587028],
        [76.90116119384783, 14.398320198059139],
        [76.91310119628935, 14.413552284240666],
        [76.91896820068382, 14.41145038604742],
        [76.93678283691418, 14.401309967041072],
        [76.9415664672851, 14.399541854858512],
        [76.94359588623053, 14.403261184692496],
        [76.9446792602539, 14.408920288086051],
        [76.944320678711, 14.42523193359375],
        [76.94271087646479, 14.43984127044672],
        [76.9437866210938, 14.445101737976074],
        [76.95858764648438, 14.445390701294059],
        [76.96437072753929, 14.451741218566951],
        [76.97419738769548, 14.470360755920524],
        [76.97339630126964, 14.478281021118164],
        [76.9690933227539, 14.479089736938533],
        [76.95580291748053, 14.47958946228033],
        [76.94541168212908, 14.48215103149414],
        [76.9065170288086, 14.484161376953125],
        [76.89427185058611, 14.480751991271973],
        [76.88549041748041, 14.47594070434576],
        [76.8803024291995, 14.470529556274471],
        [76.87020111084013, 14.468440055847225],
        [76.86380767822266, 14.474671363830623],
        [76.86044311523466, 14.487131118774471],
        [76.85421752929705, 14.498880386352539],
        [76.84393310546892, 14.509149551391545],
        [76.83207702636713, 14.515070915222111],
        [76.828422546387, 14.522240638733024],
        [76.82758331298834, 14.5230588912965],
        [76.81973266601562, 14.520339965820256],
        [76.81089782714855, 14.523799896240291],
        [76.80509185791021, 14.524870872497672],
        [76.79972839355474, 14.527731895446891],
        [76.794692993164, 14.540730476379395],
        [76.78617095947266, 14.553059577942008],
        [76.78405761718767, 14.561980247497615],
        [76.77815246582031, 14.579331398010254],
        [76.77628326416021, 14.590330123901367],
        [76.77435302734375, 14.590570449829215],
        [76.7727508544923, 14.587201118469352],
        [76.76091003417997, 14.596281051635742],
        [76.76017761230463, 14.602010726928768],
        [76.76297760009794, 14.611921310424805],
        [76.76950836181652, 14.656311035156364],
        [76.77127838134783, 14.673839569091797],
        [76.78108215332037, 14.690470695495662],
        [76.7855377197265, 14.707091331481934],
        [76.79807281494169, 14.734590530395451],
        [76.79502868652372, 14.741131782531852],
        [76.78813934326178, 14.751840591430721],
        [76.78642272949224, 14.761911392211914],
        [76.78128814697294, 14.774330139160213],
        [76.7803497314456, 14.779261589050293],
        [76.81096649169922, 14.781431198120117],
        [76.8167114257813, 14.782490730285758],
        [76.81735229492216, 14.785440444946346],
        [76.82073211669928, 14.78660964965826],
        [76.826416015625, 14.786980628967399],
        [76.83136749267578, 14.784111976623649],
        [76.83377075195341, 14.784909248352108],
        [76.8349685668947, 14.80013179779047],
        [76.83721160888683, 14.804141044616756],
        [76.84691619873075, 14.80231094360363],
        [76.84873962402344, 14.804031372070312],
        [76.84960174560575, 14.811809539794979],
        [76.84854125976562, 14.814250946044979],
        [76.84445190429716, 14.815590858459586],
        [76.84298706054705, 14.824301719665641],
        [76.84026336669933, 14.828071594238281],
        [76.84008789062494, 14.830040931701717],
        [76.84436035156256, 14.833251953125057],
        [76.84938812255888, 14.838699340820312],
        [76.8541183471682, 14.861371994018555],
        [76.85167694091825, 14.862521171569881],
        [76.85087585449241, 14.872850418090934],
        [76.84905242919916, 14.87993144989025],
        [76.84326171875006, 14.881471633911133],
        [76.8437728881836, 14.886420249939022],
        [76.84613800048857, 14.890331268310547],
        [76.86030578613276, 14.891189575195312],
        [76.86271667480474, 14.901769638061467],
        [76.86389923095732, 14.911741256713867],
        [76.8634872436524, 14.915642738342285],
        [76.86547851562517, 14.93760013580328],
        [76.86367034912115, 14.955210685730037],
        [76.8644561767581, 14.964019775390625],
        [76.8411560058596, 14.961171150207576],
        [76.83587646484386, 14.959080696106014],
        [76.83255004882818, 14.95348072052002],
        [76.82789611816423, 14.951009750366211],
        [76.82364654541044, 14.95361137390148],
        [76.82028198242205, 14.9591703414917],
        [76.81545257568388, 14.963809967041072],
        [76.79688262939459, 14.962921142578125],
        [76.77208709716803, 14.968640327453727],
        [76.76377105712919, 14.969869613647404],
        [76.76185607910185, 14.97528076171875],
        [76.75863647460949, 14.979460716247559],
        [76.75698852539062, 14.982971191406307],
        [76.75923919677751, 14.98507022857666],
        [76.76235961914068, 14.989461898803825],
        [76.76518249511719, 14.98906135559082],
        [76.76622009277372, 14.990811347961369],
        [76.76805877685547, 14.995639801025447],
        [76.76953125000017, 15.000581741333065],
        [76.7797470092774, 15.005889892578182],
        [76.78457641601574, 15.016670227050781],
        [76.78179168701172, 15.023329734802246],
        [76.7807922363283, 15.03423976898199],
        [76.77501678466825, 15.043959617614746],
        [76.77112579345709, 15.054091453552303],
        [76.77082061767607, 15.05828857421875],
        [76.77256011962902, 15.065119743347282],
        [76.7757415771485, 15.069161415100098],
        [76.77422332763678, 15.071380615234318],
        [76.77636718750011, 15.073292732238826],
        [76.77860260009771, 15.07157039642334],
        [76.78391265869146, 15.07567024230957],
        [76.78224945068388, 15.078820228576774],
        [76.78423309326189, 15.080780029296875],
        [76.78756713867193, 15.080439567565918],
        [76.79693603515653, 15.090760231018123],
        [76.81188201904314, 15.080230712890682],
        [76.8212661743164, 15.074851989746094],
        [76.82943725585955, 15.07429122924816],
        [76.83740997314464, 15.072469711303654],
        [76.84320831298828, 15.066159248351994],
        [76.86057281494146, 15.05671024322504],
        [76.864067077637, 15.050000190734863],
        [76.86975097656256, 15.044019699096793],
        [76.87670135498058, 15.028678894042969],
        [76.88868713378935, 15.03175067901617],
        [76.89620971679705, 15.031881332397461],
        [76.90544128417974, 15.029290199279785],
        [76.91352081298822, 15.030950546264648],
        [76.91935729980491, 15.028521537780875],
        [76.9316711425783, 15.026310920715332],
        [76.94274902343767, 15.027030944824276],
        [76.95066833496122, 15.024431228637752],
        [76.966796875, 15.01316928863531],
        [76.98178100585966, 15.010360717773438],
        [76.98351287841808, 15.020421981811523],
        [76.99565124511741, 15.023680686950627],
        [76.99862670898455, 15.0270614624024],
        [77.0006790161134, 15.029589653015194],
        [77.00929260253923, 15.029581069946346],
        [77.02295684814482, 15.02765083312994],
        [77.03745269775419, 15.027570724487362],
        [77.0468826293947, 15.029211997985783],
        [77.04878997802751, 15.023860931396484],
        [77.04638671875023, 15.01601791381836],
        [77.04100799560553, 15.005481719970646],
        [77.05092620849615, 15.004289627075138],
        [77.06147003173834, 15.001549720764274],
        [77.06858825683588, 15.001110076904297],
        [77.0788192749024, 15.000009536743221],
        [77.08049774169928, 15.00791072845459],
        [77.08206939697271, 15.022290229797306],
        [77.08825683593767, 15.02303791046154],
        [77.09078216552734, 15.02772235870367],
        [77.10167694091803, 15.027340888977108],
        [77.1095733642581, 15.028569221496696],
        [77.11161041259788, 15.038129806518668],
        [77.11606597900402, 15.040102005004996],
        [77.11839294433611, 15.050630569457951],
        [77.12091064453125, 15.055070877075195],
        [77.12552642822271, 15.068209648132267],
        [77.12760925292974, 15.093641281127873],
        [77.13938903808611, 15.09988117218029],
        [77.14771270751982, 15.107330322265682],
        [77.15403747558588, 15.120520591735954],
        [77.15276336669922, 15.131621360778752],
        [77.13536071777361, 15.136010169983024],
        [77.13208007812528, 15.142681121826172],
        [77.13697052001959, 15.149251937866268],
        [77.14549255371111, 15.150700569152832],
        [77.15305328369169, 15.148820877075309],
        [77.15770721435564, 15.148950576782283],
        [77.1592864990235, 15.152120590210018],
        [77.15879058837902, 15.157869338989258],
        [77.16320037841791, 15.16133117675787],
        [77.1644821166995, 15.16847038269043],
        [77.16153717041016, 15.182210922241211],
        [77.15815734863304, 15.193819999694881],
        [77.15577697753923, 15.194339752197266],
        [77.1499633789063, 15.207901000976676],
        [77.1470794677735, 15.208809852600154],
        [77.14138031005865, 15.218132019043026],
        [77.12090301513672, 15.221240997314453],
        [77.12345886230474, 15.233320236206055],
        [77.13265991210949, 15.234609603881779],
        [77.14025115966825, 15.251550674438477],
        [77.14668273925787, 15.250290870666618],
        [77.15793609619146, 15.251350402832088],
        [77.16525268554716, 15.250971794128418],
        [77.16414642333979, 15.25907039642334],
        [77.15766906738276, 15.260379791259822],
        [77.14985656738298, 15.26693058013916],
        [77.14517211914068, 15.266051292419547],
        [77.14491271972673, 15.269280433654842],
        [77.1480331420899, 15.270951271057129],
        [77.14872741699236, 15.277850151062012],
        [77.14791107177763, 15.285670280456543],
        [77.13883972167969, 15.285479545593262],
        [77.12374877929705, 15.287569999694824],
        [77.1154174804688, 15.289850234985352],
        [77.11342620849626, 15.319120407104492],
        [77.11023712158203, 15.320730209350643],
        [77.10968780517584, 15.327281951904297],
        [77.09929656982416, 15.326621055603027],
        [77.06909179687506, 15.319471359252873],
        [77.06157684326189, 15.321860313415527],
        [77.06314849853521, 15.328080177307243],
        [77.0628967285158, 15.337909698486442],
        [77.057846069336, 15.343190193176326],
        [77.04260253906278, 15.352240562439022],
        [77.03803253173857, 15.358781814575252],
        [77.04228973388689, 15.374799728393498],
        [77.04382324218767, 15.383741378784293],
        [77.04100799560553, 15.391510009765682],
        [77.033256530762, 15.396260261535701],
        [77.02854156494135, 15.401302337646484],
        [77.0268630981447, 15.407752037048397],
        [77.03100585937511, 15.418339729309139],
        [77.03048706054705, 15.426330566406193],
        [77.02739715576166, 15.4351806640625],
        [77.02384185791021, 15.438561439514103],
        [77.01528167724615, 15.441069602966309],
        [77.00609588623047, 15.448230743408203],
        [77.00203704834001, 15.455381393432674],
        [77.00074005126953, 15.461701393127441],
        [76.99600219726568, 15.467459678650016],
        [76.98898315429693, 15.473151206970272],
        [76.97608184814447, 15.482012748718319],
        [76.97039031982439, 15.490921974182186],
        [76.97013854980497, 15.494951248169002],
        [76.97189331054682, 15.506331443786678],
        [76.98165130615251, 15.504770278930607],
        [76.99085235595709, 15.501070976257381],
        [76.99603271484398, 15.499731063842717],
        [77.00543212890653, 15.502771377563533],
        [77.02659606933622, 15.499990463256893],
        [77.02874755859392, 15.508560180664062],
        [77.028861999512, 15.525121688842773],
        [77.02371215820341, 15.57638168334961],
        [77.02443695068365, 15.58436203002924],
        [77.02889251709013, 15.598259925842285],
        [77.02839660644531, 15.606849670410213],
        [77.02710723876959, 15.629349708557129],
        [77.02928161621105, 15.63383865356451],
        [77.03266143798834, 15.632008552551326],
        [77.03601074218767, 15.632371902465763],
        [77.06887054443365, 15.640909194946403],
        [77.08143615722656, 15.652840614318904],
        [77.08889770507818, 15.647521018982047],
        [77.09593200683594, 15.64414978027338],
        [77.10056304931646, 15.639161109924373],
        [77.10389709472673, 15.633931159973258],
        [77.11620330810553, 15.6251096725465],
        [77.12092590332037, 15.637201309204102],
        [77.12497711181646, 15.659050941467228],
        [77.11905670166038, 15.662089347839412],
        [77.1104660034182, 15.671262741088867],
        [77.1008377075197, 15.678879737854118],
        [77.07592773437506, 15.690878868103027],
        [77.0779266357423, 15.693361282348746],
        [77.0816497802735, 15.695330619812125],
        [77.08436584472685, 15.708959579467717],
        [77.07625579834001, 15.710371017456112],
        [77.0762710571289, 15.721410751342773],
        [77.06299591064459, 15.723671913147086],
        [77.04708862304688, 15.725011825561523],
        [77.0472488403322, 15.733171463012752],
        [77.043228149414, 15.734481811523494],
        [77.04669189453142, 15.75133132934576],
        [77.05171966552757, 15.750129699707088],
        [77.05316925048845, 15.758879661560115],
        [77.04850769042997, 15.760760307311955],
        [77.04972076416033, 15.76410102844244],
        [77.05372619628923, 15.763649940490723],
        [77.05660247802751, 15.7722806930542],
        [77.0597686767581, 15.778840065002385],
        [77.06247711181652, 15.778390884399471],
        [77.06723785400402, 15.796050071716365],
        [77.06151580810558, 15.79792118072504],
        [77.06388854980469, 15.823169708251896],
        [77.05226135253912, 15.82433891296398],
        [77.04991912841814, 15.820142745971793],
        [77.04154205322283, 15.821660995483512],
        [77.03836822509788, 15.825510025024471],
        [77.03388214111322, 15.824399948120117],
        [77.02815246582026, 15.825590133667049],
        [77.02442169189459, 15.829489707946891],
        [77.02227020263689, 15.831780433654842],
        [77.02902984619163, 15.84490108489996],
        [77.033187866211, 15.853080749511719],
        [77.04104614257835, 15.863959312439022],
        [77.0524520874024, 15.87628269195568],
        [77.05821228027366, 15.887161254882756],
        [77.06595611572283, 15.899311065673828],
        [77.0767974853515, 15.90974044799816],
        [77.0900573730471, 15.917869567871037],
        [77.10460662841825, 15.924531936645621],
        [77.12065887451166, 15.928820610046387],
        [77.12993621826178, 15.930391311645565],
        [77.13677215576178, 15.936219215393066],
        [77.14343261718756, 15.94060039520275],
        [77.15712738037126, 15.94448089599615],
        [77.17070007324219, 15.945549964904728],
        [77.1806259155274, 15.95006275177002],
        [77.18782806396501, 15.950710296630973],
        [77.20295715332037, 15.94987964630127],
        [77.21866607666044, 15.951642036438045],
        [77.22711944580095, 15.951609611511287],
        [77.2360916137697, 15.952711105346623],
        [77.24405670166027, 15.956761360168457],
        [77.24891662597662, 15.957740783691406],
        [77.2581329345706, 15.95397090911871],
        [77.26317596435564, 15.95138931274414],
        [77.28244781494158, 15.9473619461059],
        [77.29178619384783, 15.949969291686955],
        [77.30021667480474, 15.950499534606934],
        [77.31478881835966, 15.9451904296875],
        [77.3351669311524, 15.943270683288688],
        [77.35360717773438, 15.943701744079647],
        [77.3598709106447, 15.941550254821777],
        [77.36814117431646, 15.936040878295955],
        [77.37622070312528, 15.93325042724615],
        [77.38559722900385, 15.934451103210506],
        [77.3927001953125, 15.936761856079215],
        [77.40003204345732, 15.935460090637264],
        [77.40763092041038, 15.935651779174862],
        [77.41644287109403, 15.937960624694767],
        [77.42193603515648, 15.941299438476676],
        [77.4264221191408, 15.942278861999569],
        [77.4364776611331, 15.939031600952148],
        [77.45250701904325, 15.931520462036133],
        [77.46697998046903, 15.927580833435059],
        [77.48332214355474, 15.928059577942008],
        [77.49732208251982, 15.926300048828125],
        [77.50945281982428, 15.920860290527457],
        [77.53173828125017, 15.916119575500488],
        [77.54884338378918, 15.913668632507381],
        [77.57163238525396, 15.912320137023926],
        [77.58010101318388, 15.910209655761832],
        [77.58841705322271, 15.906991958618278],
        [77.59868621826189, 15.908741950988826],
        [77.60637664794945, 15.911729812622127],
        [77.6138458251956, 15.907830238342285],
        [77.61882019042997, 15.903751373291072],
        [77.62570953369152, 15.899130821228141],
        [77.63204956054705, 15.891800880432129],
        [77.6396026611331, 15.88313102722168],
        [77.64821624755871, 15.878939628601131],
        [77.6589431762697, 15.876520156860295],
        [77.68353271484392, 15.87389183044445],
        [77.69249725341814, 15.875321388244743],
        [77.699447631836, 15.880439758300895],
        [77.70730590820341, 15.884099960327148],
        [77.71611785888683, 15.886570930480957],
        [77.72486114501982, 15.884791374206543],
        [77.73195648193371, 15.88221073150629],
        [77.7388305664062, 15.878520965576172],
        [77.74964141845709, 15.875879287719783],
        [77.76004791259783, 15.876791000366325],
        [77.7702026367188, 15.873271942138786],
        [77.77889251708979, 15.869301795959473],
        [77.78978729248064, 15.866588592529354],
        [77.79918670654303, 15.865320205688477],
        [77.8092193603515, 15.867029190063477],
        [77.81772613525396, 15.869490623474178],
        [77.82534790039085, 15.873790740966854],
        [77.83384704589861, 15.875510215759277],
        [77.83976745605486, 15.87394046783453],
        [77.85011291503912, 15.873821258544979],
        [77.8556518554688, 15.875761032104549],
        [77.86694335937506, 15.892389297485352],
        [77.87638092041033, 15.896001815795955],
        [77.887680053711, 15.895921707153434],
        [77.90756225585943, 15.884890556335563],
        [77.92829895019531, 15.876509666442871],
        [77.9440231323245, 15.874910354614315],
        [77.96308135986357, 15.87590026855463],
        [77.97287750244158, 15.8741197586059],
        [77.9936065673831, 15.860980033874569],
        [78.00186920166033, 15.859230041503963],
        [78.00660705566418, 15.871970176696834],
        [78.0147323608399, 15.888811111450252],
        [78.01696777343767, 15.895149230957145],
        [78.02423858642578, 15.898582458496207],
        [78.02879333496111, 15.902690887451115],
        [78.03629302978538, 15.90209102630621],
        [78.03981781005854, 15.898100852966365],
        [78.04071807861357, 15.872200965881404],
        [78.04886627197271, 15.870751380920524],
        [78.05847167968778, 15.850410461425781],
        [78.06366729736345, 15.844561576843262],
        [78.10114288330101, 15.827921867370605],
        [78.10958099365263, 15.827180862426758],
        [78.11978912353516, 15.830268859863395],
        [78.12687683105474, 15.834920883178768],
        [78.12583160400385, 15.848931312561092],
        [78.1379165649414, 15.850240707397575],
        [78.14840698242188, 15.849969863891715],
        [78.15093231201195, 15.84904003143322],
        [78.16467285156256, 15.848990440368596],
        [78.1686706542971, 15.853170394897461],
        [78.17066192626947, 15.866438865661678],
        [78.17384338378935, 15.872520446777344],
        [78.17907714843767, 15.879778861999455],
        [78.18148803710966, 15.887651443481445],
        [78.17919158935575, 15.894940376281738],
        [78.18003082275408, 15.90044975280756],
        [78.19667053222662, 15.912240028381404],
        [78.20539855957048, 15.917060852050895],
        [78.22786712646496, 15.923480987548771],
        [78.23796844482433, 15.9290513992309],
        [78.24286651611351, 15.937048912048454],
        [78.2440109252932, 15.946961402893123],
        [78.24735260009783, 15.960049629211483],
        [78.24835968017584, 15.96830940246582],
        [78.25110626220732, 15.970838546752873],
        [78.25293731689459, 15.97844028472906],
        [78.25974273681658, 15.994730949401912],
        [78.25868225097662, 16.005081176757812],
        [78.25138092041033, 16.025680541992188],
        [78.25666809082043, 16.031299591064453],
        [78.26815795898455, 16.03475952148449],
        [78.281059265137, 16.032091140747127],
        [78.29218292236345, 16.028490066528434],
        [78.31620788574236, 16.029151916503963],
        [78.32356262207037, 16.037242889404354],
        [78.33114624023443, 16.047090530395508],
        [78.34041595458979, 16.05277061462408],
        [78.35311126709007, 16.054510116577205],
        [78.37003326416016, 16.059841156005973],
        [78.38424682617216, 16.07183074951172],
        [78.40566253662126, 16.08094978332531],
        [78.42095184326172, 16.08284950256359],
        [78.45101165771513, 16.081031799316463],
        [78.49559783935553, 16.07308959960949],
        [78.52083587646496, 16.06506919860834],
        [78.53285980224604, 16.062322616577205],
        [78.54689788818382, 16.056200027465763],
        [78.55912780761747, 16.05637168884283],
        [78.56711578369163, 16.06187057495123],
        [78.57643127441412, 16.07034111022955],
        [78.5925674438476, 16.087230682373104],
        [78.60350036621094, 16.09643936157238],
        [78.61701202392595, 16.0989093780517],
        [78.63648986816406, 16.091449737548885],
        [78.64669799804716, 16.0827312469483],
        [78.65776062011747, 16.07143974304205],
        [78.66837310791021, 16.055799484252987],
        [78.67498016357439, 16.049030303955078],
        [78.68656158447294, 16.04038047790533],
        [78.7136688232423, 16.026660919189567],
        [78.72596740722662, 16.023040771484432],
        [78.73898315429693, 16.02114105224615],
        [78.7539367675783, 16.021009445190373],
        [78.77504730224638, 16.023609161377067],
        [78.79001617431658, 16.032699584960938],
        [78.80799865722662, 16.046190261840877],
        [78.81376647949247, 16.052190780639705],
        [78.82155609130882, 16.06220054626459],
        [78.83650207519543, 16.087377548217717],
        [78.83937835693354, 16.09424018859869],
        [78.8402862548831, 16.102830886840877],
        [78.83808898925781, 16.11086082458496],
        [78.83394622802757, 16.12014007568365],
        [78.83127593994163, 16.132978439331055],
        [78.83049774169928, 16.142150878906364],
        [78.83213043212885, 16.15176963806158],
        [78.8378372192384, 16.156738281250057],
        [78.84628295898455, 16.159540176391715],
        [78.85645294189476, 16.158599853515625],
        [78.86293792724632, 16.153089523315487],
        [78.8663330078125, 16.143859863281364],
        [78.86802673339861, 16.135261535644645],
        [78.86942291259788, 16.12162971496582],
        [78.8726730346682, 16.110170364379826],
        [78.88906860351585, 16.098560333252067],
        [78.90117645263666, 16.097621917724553],
        [78.9104766845706, 16.105222702026424],
        [78.91335296630888, 16.108840942382812],
        [78.91726684570318, 16.11351966857916],
        [78.92186737060553, 16.121871948242244],
        [78.92086029052751, 16.13367080688471],
        [78.90558624267595, 16.16492080688488],
        [78.9054565429688, 16.181869506835938],
        [78.90808105468767, 16.1910400390625],
        [78.92549896240257, 16.202339172363338],
        [78.94108581542969, 16.209810256958065],
        [78.95900726318376, 16.217220306396428],
        [78.9780578613283, 16.223070144653434],
        [78.99243164062506, 16.228300094604606],
        [79.01017761230474, 16.24044990539545],
        [79.02014160156278, 16.24427032470703],
        [79.03093719482422, 16.245742797851506],
        [79.04252624511736, 16.244951248169002],
        [79.05407714843756, 16.242559432983455],
        [79.06859588623075, 16.23814010620123],
        [79.12300872802751, 16.23791122436529],
        [79.13317871093756, 16.236379623413086],
        [79.1424865722658, 16.229259490966797],
        [79.151176452637, 16.22462081909191],
        [79.16272735595703, 16.220228195190487],
        [79.1765823364259, 16.217130661010742],
        [79.19198608398466, 16.222719192504883],
        [79.20751190185564, 16.230670928955135],
        [79.21868896484403, 16.23964118957531],
        [79.22505950927751, 16.25007057189947],
        [79.22982025146501, 16.300220489501953],
        [79.22785186767607, 16.33681106567377],
        [79.2232360839846, 16.35036087036127],
        [79.21147155761747, 16.362810134887752],
        [79.21330261230474, 16.374389648437557],
        [79.2126007080081, 16.393760681152344],
        [79.21501922607428, 16.415761947631893],
        [79.21906280517584, 16.438169479370117],
        [79.22635650634777, 16.45253944396967],
        [79.23618316650396, 16.458539962768498],
        [79.23513031005871, 16.47068977355957],
        [79.2309112548831, 16.48167037963873],
        [79.22410583496111, 16.49213981628418],
        [79.22527313232439, 16.50822067260748],
        [79.2304458618164, 16.51929092407238],
        [79.26077270507812, 16.546421051025334],
        [79.26611328125, 16.56147003173828],
        [79.28237915039068, 16.565441131591854],
        [79.29512023925787, 16.5673503875733],
        [79.3197784423831, 16.575870513916016],
        [79.33333587646513, 16.57852172851574],
        [79.34552764892607, 16.57956123352045],
        [79.40270996093756, 16.580440521240234],
        [79.4265899658206, 16.58252143859869],
        [79.43450164794939, 16.5856609344483],
        [79.44315338134788, 16.59370040893566],
        [79.45059204101585, 16.609149932861442],
        [79.47009277343778, 16.618749618530387],
        [79.49717712402372, 16.630510330200195],
        [79.51212310791033, 16.63365173339855],
        [79.53671264648455, 16.63156127929699],
        [79.55399322509771, 16.634590148925895],
        [79.56359863281244, 16.638069152831974],
        [79.57402038574224, 16.643590927124023],
        [79.58558654785156, 16.65135002136242],
        [79.59990692138678, 16.663270950317496],
        [79.61680603027338, 16.6646404266358],
        [79.63329315185553, 16.660869598388615],
        [79.64803314209013, 16.662000656127987],
        [79.65550231933622, 16.674089431762752],
        [79.66864013671903, 16.684009552001953],
        [79.68303680419939, 16.69038009643566],
        [79.7227401733399, 16.692609786987305],
        [79.73380279541021, 16.698020935058594],
        [79.75048065185575, 16.720119476318416],
        [79.76309204101574, 16.72713279724121],
        [79.77490234375028, 16.72952079772955],
        [79.78337860107422, 16.724840164184627],
        [79.81025695800798, 16.690490722656364],
        [79.82592010498047, 16.69146919250494],
        [79.84456634521501, 16.69646072387701],
        [79.86183929443365, 16.69822120666504],
        [79.87330627441435, 16.68869972229004],
        [79.88065338134766, 16.676170349121207],
        [79.89092254638689, 16.64956092834467],
        [79.90207672119146, 16.637849807739315],
        [79.9138259887697, 16.63313102722168],
        [79.92366790771513, 16.630739212036133],
        [79.94256591796886, 16.632629394531307],
        [79.95815277099615, 16.64204978942871],
        [79.97302246093773, 16.65952110290533],
        [79.99151611328148, 16.692550659179744],
        [79.99368286132824, 16.69444084167492],
        [80.00238800048851, 16.70294952392578],
        [80.01667785644537, 16.711830139160213],
        [80.02330780029314, 16.718479156494084],
        [80.03276062011747, 16.731781005859375],
        [80.04335021972656, 16.739700317382926],
        [80.04789733886736, 16.745151519775504],
        [80.05158996582037, 16.771289825439567],
        [80.05767059326178, 16.781879425048885],
        [80.064926147461, 16.80251121520996],
        [80.06967163085938, 16.813608169555607],
        [80.04183959960943, 16.826061248779297],
        [80.03666687011719, 16.832389831543082],
        [80.03439331054688, 16.839122772216854],
        [80.03436279296892, 16.84776115417486],
        [80.03309631347679, 16.852449417114315],
        [80.01383209228538, 16.852939605712834],
        [79.99413299560547, 16.862180709838867],
        [79.99167633056646, 16.863250732421932],
        [79.99505615234392, 16.87731170654297],
        [80.0027770996096, 16.891468048095646],
        [80.0095520019533, 16.907260894775504],
        [80.01860046386741, 16.918809890747127],
        [80.02849578857428, 16.92741012573248],
        [80.03705596923828, 16.936470031738338],
        [80.04588317871116, 16.965299606323356],
        [80.08423614501959, 16.96319007873535],
        [80.08394622802751, 16.977678298950195],
        [80.08769226074247, 16.987440109253043],
        [80.09947204589866, 16.982801437377987],
        [80.10578918457037, 16.981319427490234],
        [80.11279296875006, 16.98232078552246],
        [80.11467742919922, 16.985870361328182],
        [80.12014770507824, 16.9868106842041],
        [80.12689971923828, 16.98533058166504],
        [80.13385772705107, 16.985359191894645],
        [80.14057159423822, 16.99328994750988],
        [80.1424713134765, 17.001480102539062],
        [80.14911651611357, 17.00805091857916],
        [80.16718292236351, 17.02362060546875],
        [80.17053222656267, 17.03041267395031],
        [80.18212890625011, 17.044940948486385],
        [80.19426727294945, 17.042512893676758],
        [80.19610595703142, 17.02568817138672],
        [80.19554901123047, 17.018041610717773],
        [80.20125579834001, 17.013139724731445],
        [80.21321868896496, 17.019180297851562],
        [80.21784210205095, 17.0196208953858],
        [80.2322387695313, 17.00493049621582],
        [80.23574829101557, 17.002330780029297],
        [80.24114990234375, 17.002809524536076],
        [80.25026702880876, 17.007379531860295],
        [80.2621765136721, 17.010629653930607],
        [80.27617645263666, 16.99324989318859],
        [80.28898620605486, 16.97680282592779],
        [80.30137634277361, 16.95586013793951],
        [80.30396270751959, 16.93947029113781],
        [80.30784606933588, 16.923812866210938],
        [80.31537628173834, 16.91238975524908],
        [80.31328582763695, 16.912002563476562],
        [80.31613159179693, 16.894662857055607],
        [80.31600189209013, 16.8840007781983],
        [80.31844329834007, 16.87743759155279],
        [80.31922149658209, 16.87094116210949],
        [80.32731628417986, 16.871019363403263],
        [80.33731079101568, 16.86708068847662],
        [80.33495330810564, 16.855739593505916],
        [80.3509063720706, 16.85330009460455],
        [80.35862731933594, 16.854909896850586],
        [80.36120605468778, 16.850381851196403],
        [80.36472320556658, 16.838901519775447],
        [80.36711120605497, 16.826761245727482],
        [80.37293243408203, 16.811100006103572],
        [80.37587738037138, 16.8106307983399],
        [80.3778457641601, 16.812480926513786],
        [80.37938690185564, 16.81953048706066],
        [80.39422607421886, 16.825162887573242],
        [80.39914703369169, 16.831581115722713],
        [80.4001770019533, 16.837049484252987],
        [80.39971160888678, 16.849739074707145],
        [80.41867065429705, 16.84231948852539],
        [80.42140960693365, 16.8371200561524],
        [80.42283630371105, 16.829309463500977],
        [80.43210601806646, 16.827180862426758],
        [80.43183135986345, 16.823850631713867],
        [80.44476318359403, 16.81939125061035],
        [80.45260620117182, 16.815290451049805],
        [80.45507812500011, 16.806480407714957],
        [80.45639801025419, 16.79002952575695],
        [80.47101593017584, 16.78832054138178],
        [80.47905731201178, 16.7849502563476],
        [80.48464965820307, 16.781311035156193],
        [80.48953247070335, 16.775859832763786],
        [80.51098632812517, 16.76993751525879],
        [80.51806640625017, 16.768768310546875],
        [80.5300598144533, 16.770059585571403],
        [80.5465087890625, 16.767658233642692],
        [80.56281280517607, 16.76283073425293],
        [80.56933593749994, 16.77075004577648],
        [80.59751892089872, 16.78508949279791],
        [80.6041030883789, 16.787759780883846],
        [80.59777832031267, 16.792030334472656],
        [80.59364318847662, 16.79791831970215],
        [80.58584594726585, 16.806659698486328],
        [80.57176971435575, 16.812099456787223],
        [80.56700897216825, 16.8171005249024],
        [80.5612487792971, 16.81712913513178],
        [80.55602264404314, 16.8184108734132],
        [80.55458068847662, 16.82478904724121],
        [80.5624313354495, 16.836320877075195],
        [80.5640029907226, 16.84256172180187],
        [80.56482696533203, 16.849910736083984],
        [80.5649719238283, 16.86903953552246],
        [80.5765075683596, 16.87310028076172],
        [80.58674621582031, 16.873939514160213],
        [80.58577728271501, 16.887161254882926],
        [80.5833587646485, 16.89060974121088],
        [80.5812377929688, 16.896778106689567],
        [80.58212280273432, 16.903102874755803],
        [80.58587646484403, 16.904321670532283],
        [80.58936309814447, 16.90713119506836],
        [80.59030914306652, 16.911630630493107],
        [80.58914184570341, 16.91666984558117],
        [80.58419036865263, 16.926519393920955],
        [80.56948852539091, 16.926889419555607],
        [80.53173828125028, 16.95017814636236],
        [80.52069091796886, 16.936399459838867],
        [80.50975799560553, 16.94243812561041],
        [80.49130249023466, 16.948181152343864],
        [80.48693847656244, 16.936859130859432],
        [80.48558807373058, 16.927038192749023],
        [80.48781585693382, 16.917440414428825],
        [80.47688293457031, 16.92117118835455],
        [80.46985626220709, 16.926511764526424],
        [80.46324920654325, 16.93021011352539],
        [80.4530868530274, 16.93832969665533],
        [80.44254302978533, 16.94494056701666],
        [80.41234588623058, 16.95417022705078],
        [80.39762115478544, 16.957010269165153],
        [80.38080596923851, 16.962511062622184],
        [80.36042022705084, 16.970500946044922],
        [80.35824584960938, 16.970630645751896],
        [80.36106872558611, 16.97446060180664],
        [80.36972045898443, 16.982950210571403],
        [80.37161254882812, 16.987119674682617],
        [80.37120056152361, 16.99315071105957],
        [80.37119293212908, 16.99584007263178],
        [80.38197326660162, 17.000040054321346],
        [80.38649749755865, 17.002941131591854],
        [80.38790893554716, 17.007299423217717],
        [80.38871765136713, 17.014060974121094],
        [80.38376617431658, 17.019758224487305],
        [80.37789154052734, 17.023340225219783],
        [80.37722015380888, 17.027870178222713],
        [80.37838745117216, 17.034120559692383],
        [80.37162017822271, 17.047529220580998],
        [80.36775207519548, 17.052698135376033],
        [80.36699676513689, 17.05656814575201],
        [80.37416076660185, 17.063110351562557],
        [80.37905883789068, 17.066150665283317],
        [80.390121459961, 17.076200485229492],
        [80.39319610595709, 17.0778102874757],
        [80.39508819580084, 17.07756805419922],
        [80.39734649658203, 17.07389068603527],
        [80.39977264404291, 17.065250396728516],
        [80.39890289306646, 17.059789657592773],
        [80.40087127685575, 17.05418968200695],
        [80.41429138183611, 17.04178810119629],
        [80.41777801513678, 17.028610229492244],
        [80.42053985595732, 17.024860382080078],
        [80.43266296386724, 17.021781921386832],
        [80.44624328613287, 17.017021179199332],
        [80.45288848876959, 17.03704261779791],
        [80.46422576904308, 17.03718185424799],
        [80.47174835205078, 17.038101196289062],
        [80.47506713867216, 17.04281044006359],
        [80.48220062255876, 17.050270080566406],
        [80.4846572875976, 17.056310653686467],
        [80.48462677001982, 17.067590713501033],
        [80.48678588867205, 17.082899093627987],
        [80.4887313842774, 17.087331771850643],
        [80.49240112304693, 17.092441558837947],
        [80.49653625488281, 17.10129165649414],
        [80.49611663818376, 17.107860565185604],
        [80.51172637939464, 17.106210708618164],
        [80.51941680908203, 17.108150482177678],
        [80.52916717529303, 17.113649368286133],
        [80.53542327880876, 17.119789123535213],
        [80.54165649414091, 17.120410919189453],
        [80.54907226562528, 17.12362289428711],
        [80.56017303466808, 17.1380290985108],
        [80.57348632812528, 17.135290145874023],
        [80.58026123046875, 17.130950927734375],
        [80.59510803222673, 17.11956024169922],
        [80.60388183593767, 17.11551094055187],
        [80.61292266845709, 17.116640090942496],
        [80.61654663085943, 17.115699768066406],
        [80.62391662597673, 17.111881256103572],
        [80.62975311279325, 17.110019683838004],
        [80.62523651123064, 17.10173988342291],
        [80.63640594482428, 17.09699058532715],
        [80.6462631225586, 17.09445953369152],
        [80.65664672851591, 17.08776283264166],
        [80.65541076660156, 17.083971023559627],
        [80.64438629150396, 17.077100753784123],
        [80.63775634765653, 17.071939468383846],
        [80.63771820068365, 17.06561088562023],
        [80.63392639160162, 17.058879852295036],
        [80.63960266113287, 17.055120468139762],
        [80.64919281005865, 17.05458068847662],
        [80.66059875488287, 17.056720733642635],
        [80.66777038574236, 17.059921264648494],
        [80.6756362915039, 17.061279296875114],
        [80.68139648437506, 17.064300537109432],
        [80.68399810791033, 17.068740844726676],
        [80.69516754150419, 17.068349838256836],
        [80.71261596679705, 17.06546020507824],
        [80.72573089599621, 17.064609527588004],
        [80.74659729003906, 17.05491828918457],
        [80.76609802246116, 17.05030059814453],
        [80.78019714355474, 17.048799514770508],
        [80.7852859497072, 17.047622680664062],
        [80.79284667968778, 17.043039321899528],
        [80.80322265625017, 17.04253959655773],
        [80.82322692871111, 17.037780761718807],
        [80.82930755615229, 17.032339096069336],
        [80.83246612548851, 17.025711059570312],
        [80.84487915039068, 17.032840728759766],
        [80.85053253173828, 17.040151596069336],
        [80.85489654541033, 17.044040679931754],
        [80.85884857177763, 17.050851821899528],
        [80.85646057128906, 17.06851959228527],
        [80.862548828125, 17.081529617309684],
        [80.86239624023443, 17.08563041687023],
        [80.86421203613276, 17.095817565918082],
        [80.8550491333009, 17.111410140991325],
        [80.86347198486345, 17.11799812316906],
        [80.86679840087913, 17.124620437622127],
        [80.8703079223634, 17.146301269531307],
        [80.876762390137, 17.14520072937006],
        [80.88886260986345, 17.141050338745117],
        [80.89615631103521, 17.140779495239258],
        [80.90397644042974, 17.141149520874137],
        [80.90939331054716, 17.1424503326416],
        [80.91330718994146, 17.145950317382926],
        [80.91219329834007, 17.153509140014705],
        [80.9140014648437, 17.16077041625988],
        [80.91487121582054, 17.169839859008903],
        [80.9130172729495, 17.173780441284293],
        [80.9084777832033, 17.178039550781193],
        [80.90699005126953, 17.18384170532238],
        [80.90763854980474, 17.192859649658203],
        [80.90534973144537, 17.201290130615234],
        [80.9138488769533, 17.20525932312023],
        [80.92064666748075, 17.205829620361328],
        [80.93064880371094, 17.205030441284293],
        [80.93904876708984, 17.200380325317496],
        [80.94773101806669, 17.19333076477062],
        [80.95598602294939, 17.188209533691406],
        [80.96633148193365, 17.18454170227062],
        [80.98516082763689, 17.179389953613224],
        [80.99125671386736, 17.17970275878912],
        [80.99771881103527, 17.182010650634766],
        [81.0054016113283, 17.184448242187557],
        [81.02617645263689, 17.186668395996094],
        [81.03145599365263, 17.192350387573242],
        [81.0348510742188, 17.19725990295416],
        [81.03996276855474, 17.19994163513178],
        [81.04386138916044, 17.204540252685604],
        [81.04654693603527, 17.21381759643549],
        [81.0538406372072, 17.210140228271598],
        [81.06221008300798, 17.20409965515148],
        [81.08450317382824, 17.213640213012695],
        [81.11817932128912, 17.22459030151373],
        [81.1455459594726, 17.231662750244197],
        [81.16278839111334, 17.232580184936523],
        [81.16799926757841, 17.242321014404354],
        [81.18055725097679, 17.254600524902344],
        [81.17463684082037, 17.2603702545166],
        [81.17114257812517, 17.26666069030773],
        [81.1706161499024, 17.29646110534668],
        [81.1719589233399, 17.305450439453068],
        [81.17822265625, 17.314510345458928],
        [81.18154144287138, 17.322229385376033],
        [81.19030761718756, 17.32736968994152],
        [81.21875000000028, 17.321739196777344],
        [81.23429107666016, 17.32282066345215],
        [81.25752258300798, 17.32266044616705],
        [81.26222229003935, 17.321941375732536],
        [81.26716613769537, 17.31999969482422],
        [81.28179931640625, 17.32975959777832],
        [81.28916931152338, 17.33754920959467],
        [81.29881286621116, 17.352140426635685],
        [81.30162048339844, 17.359729766845646],
        [81.31162261962919, 17.374780654907227],
        [81.32247161865257, 17.38915061950678],
        [81.32469940185547, 17.390649795532283],
        [81.33035278320324, 17.392099380493107],
        [81.33734893798851, 17.389999389648438],
        [81.34292602539091, 17.385410308837947],
        [81.34329986572294, 17.375720977783317],
        [81.34983825683611, 17.368522644043026],
        [81.36395263671892, 17.360260009765625],
        [81.37180328369158, 17.357168197631836],
        [81.37864685058611, 17.356121063232422],
        [81.3852233886721, 17.35513114929205],
        [81.4064483642581, 17.361410140991268],
        [81.41564941406267, 17.361629486083928],
        [81.41831207275408, 17.36638069152832],
        [81.42011260986334, 17.372182846069336],
        [81.43364715576178, 17.37494277954113],
        [81.44151306152372, 17.381710052490234],
        [81.44608306884771, 17.39069175720215],
        [81.45155334472656, 17.398330688476676],
        [81.45726013183594, 17.405399322509822],
        [81.47223663330107, 17.419530868530273],
        [81.48198699951183, 17.43220138549799],
        [81.49341583251982, 17.448820114135856],
        [81.49536132812494, 17.453809738159123],
        [81.49585723876959, 17.46037101745611],
        [81.4937362670899, 17.465999603271484],
        [81.4936065673831, 17.477020263671932],
        [81.49816131591825, 17.489999771118164],
        [81.49951171875028, 17.501541137695256],
        [81.49803161621088, 17.51007270812994],
        [81.49946594238304, 17.531539916992188],
        [81.50142669677763, 17.53936004638672],
        [81.5090789794923, 17.54932022094721],
        [81.51374053955084, 17.557260513305778],
        [81.52030944824247, 17.56460952758789],
        [81.52371978759794, 17.569709777832088],
        [81.52390289306646, 17.572610855102596],
        [81.52089691162121, 17.57574081420904],
        [81.51267242431669, 17.58296012878418],
        [81.50292968750028, 17.58987998962408],
        [81.50789642334013, 17.59532165527355],
        [81.50926971435558, 17.598329544067383],
        [81.50927734374994, 17.603559494018555],
        [81.51187896728521, 17.60988044738781],
        [81.51895904541044, 17.618789672851676],
        [81.53414916992193, 17.63042259216303],
        [81.54432678222685, 17.645299911498967],
        [81.54608917236322, 17.650699615478572],
        [81.5536499023438, 17.66638946533203],
        [81.56018066406278, 17.67665290832531],
        [81.5681915283206, 17.682830810546932],
        [81.57116699218756, 17.68786811828619],
        [81.5710830688476, 17.702899932861328],
        [81.57198333740263, 17.711830139160213],
        [81.57295989990263, 17.718669891357536],
        [81.57662200927751, 17.726091384887695],
        [81.5819473266601, 17.73065948486328],
        [81.60919952392607, 17.743640899658317],
        [81.61281585693388, 17.74693107604992],
        [81.61859130859392, 17.758581161499137],
        [81.62306213378929, 17.76279067993164],
        [81.64189910888672, 17.76564025878912],
        [81.65299987792974, 17.76561164855957],
        [81.6746902465822, 17.767110824585018],
        [81.68477630615251, 17.770561218261776],
        [81.69138336181669, 17.775701522827205],
        [81.69824218750028, 17.784490585327262],
        [81.70398712158232, 17.799560546875057],
        [81.70951843261747, 17.805110931396484],
        [81.72342681884794, 17.815221786499137],
        [81.72959136962908, 17.81929969787609],
        [81.73889160156278, 17.821609497070312],
        [81.75293731689459, 17.818309783935547],
        [81.76393127441423, 17.82992935180664],
        [81.78235626220732, 17.831951141357536],
        [81.78784942626959, 17.839241027832088],
        [81.79440307617216, 17.844150543213004],
        [81.79277038574219, 17.85252189636236],
        [81.78753662109403, 17.8625106811524],
        [81.7814407348634, 17.870719909668026],
        [81.76885986328136, 17.87869834899908],
        [81.76342010498053, 17.885400772094727],
        [81.7582931518557, 17.893360137939567],
        [81.76222229003923, 17.89658927917486],
        [81.77574920654314, 17.904769897460938],
        [81.78241729736322, 17.909740447998104],
        [81.78601074218778, 17.913610458374137],
        [81.78932189941423, 17.92136955261236],
        [81.79405212402338, 17.92966079711914],
        [81.80104064941412, 17.936080932617188],
        [81.81980133056635, 17.945461273193303],
        [81.83333587646479, 17.951030731201172],
        [81.8396987915039, 17.950069427490234],
        [81.84681701660162, 17.9507999420166],
        [81.87413787841808, 17.95984077453619],
        [81.88507843017578, 17.965839385986442],
        [81.89927673339872, 17.96854972839367],
        [81.90454864501953, 17.973470687866268],
        [81.9136962890625, 17.97989082336437],
        [81.9247131347658, 17.980880737304688],
        [81.93609619140653, 17.98409080505371],
        [81.95176696777355, 17.992099761962947],
        [81.95713043212885, 17.997070312499943],
        [81.96404266357422, 17.99986839294428],
        [81.97305297851568, 18.005670547485465],
        [81.98046112060547, 18.011280059814396],
        [81.98587799072294, 18.014270782470817],
        [81.98886108398443, 18.02034187316906],
        [81.99147796630876, 18.02182006835949],
        [82.00168609619146, 18.02366065979004],
        [82.00211334228544, 18.028240203857365],
        [82.0047302246096, 18.03430938720703],
        [82.00157928466814, 18.04481124877941],
        [82.00663757324241, 18.04919815063488],
        [82.0167770385745, 18.055128097534237],
        [82.02465820312517, 18.057880401611328],
        [82.02986145019531, 18.056531906127873],
        [82.0326385498048, 18.051710128784293],
        [82.04516601562523, 18.045589447021598],
        [82.05085754394537, 18.044460296630803],
        [82.05972290039074, 18.04511833190918],
        [82.06564331054699, 18.04891967773449],
        [82.0654678344726, 18.051300048828125],
        [82.06098937988304, 18.053981781005973],
        [82.05898284912104, 18.058450698852596],
        [82.06440734863287, 18.058370590210018],
        [82.06989288330095, 18.060430526733455],
        [82.07221221923834, 18.065637588500977],
        [82.08006286621116, 18.060449600219727],
        [82.08915710449236, 18.057411193847713],
        [82.09236145019526, 18.052761077880916],
        [82.1079788208009, 18.054019927978516],
        [82.1105880737307, 18.057661056518498],
        [82.113265991211, 18.057821273803825],
        [82.11931610107422, 18.051200866699332],
        [82.1270828247072, 18.051000595092717],
        [82.12924194335943, 18.048669815063477],
        [82.13041687011741, 18.040901184082145],
        [82.13236999511747, 18.03561973571783],
        [82.14229583740263, 18.043569564819336],
        [82.14730834960955, 18.040420532226562],
        [82.16034698486334, 18.043598175048885],
        [82.16374969482422, 18.0416202545166],
        [82.17314910888695, 18.025592803955078],
        [82.1769409179688, 18.020891189575252],
        [82.17424774169922, 18.015502929687557],
        [82.17740631103527, 18.010570526123047],
        [82.1850891113283, 18.007352828979492],
        [82.1910171508789, 18.00895118713379],
        [82.19692230224626, 18.008689880371037],
        [82.20775604248041, 18.000709533691406],
        [82.21817779541021, 17.99419021606451],
        [82.2229766845706, 17.99287986755371],
        [82.23079681396513, 17.993709564209098],
        [82.23699188232422, 17.992010116577262],
        [82.23831939697266, 17.98390007019043],
        [82.24170684814447, 17.97976112365717],
        [82.25318908691423, 17.980190277099553],
        [82.2610778808596, 17.982400894165153],
        [82.26725006103527, 17.986440658569393],
        [82.26885223388672, 17.991989135742244],
        [82.27052307128923, 17.997741699218807],
        [82.2708129882813, 18.010669708252067],
        [82.26893615722685, 18.01993942260748],
        [82.26624298095709, 18.025690078735465],
        [82.2581558227539, 18.034870147705192],
        [82.26220703125006, 18.0363006591798],
        [82.26460266113298, 18.03901100158697],
        [82.26510620117216, 18.043060302734375],
        [82.2669525146485, 18.048271179199276],
        [82.27894592285156, 18.054929733276424],
        [82.28302764892584, 18.05577087402355],
        [82.2850570678711, 18.059410095214957],
        [82.28777313232445, 18.06056022644043],
        [82.29789733886724, 18.061960220336857],
        [82.29640197753935, 18.057558059692496],
        [82.29280853271496, 18.05433273315441],
        [82.29648590087908, 18.046140670776424],
        [82.3010559082033, 18.044040679931754],
        [82.3063430786134, 18.04499053955078],
        [82.30441284179716, 18.03961944580078],
        [82.3055267333985, 18.037809371948356],
        [82.30961608886736, 18.03842926025402],
        [82.31320953369152, 18.034757614135856],
        [82.31696319580101, 18.036020278930607],
        [82.31299591064482, 18.045190811157227],
        [82.31572723388689, 18.04668045043951],
        [82.31730651855474, 18.048530578613395],
        [82.31964874267584, 18.048570632934684],
        [82.32182312011736, 18.050180435180664],
        [82.32617950439464, 18.046510696411076],
        [82.32491302490234, 18.042690277099723],
        [82.32977294921903, 18.040410995483455],
        [82.33634948730486, 18.047260284423885],
        [82.33692932128906, 18.049100875854492],
        [82.3431777954101, 18.067819595337028],
        [82.34194183349632, 18.07130050659191],
        [82.34362792968756, 18.07499122619629],
        [82.34316253662121, 18.08247947692871],
        [82.3405532836914, 18.087089538574162],
        [82.33466339111334, 18.08988761901867],
        [82.33216094970703, 18.0941219329834],
        [82.33248901367182, 18.10704994201666],
        [82.33030700683616, 18.118999481201286],
        [82.33535766601557, 18.127258300781307],
        [82.3333663940432, 18.1423797607423],
        [82.33825683593767, 18.137960433959904],
        [82.34487152099621, 18.134620666503906],
        [82.35756683349615, 18.125101089477482],
        [82.36176300048834, 18.129198074340877],
        [82.36429595947294, 18.134859085083008],
        [82.36515808105469, 18.139669418335075],
        [82.35897827148466, 18.149139404296875],
        [82.34873199462908, 18.161832809448242],
        [82.341606140137, 18.163431167602482],
        [82.33302307128935, 18.1634197235108],
        [82.3247909545899, 18.16641998291027],
        [82.31931304931652, 18.17143058776861],
        [82.31030273437506, 18.174602508544922],
        [82.30821990966825, 18.1812801361084],
        [82.30943298339861, 18.189380645752067],
        [82.30609893798834, 18.195659637451286],
        [82.3197326660158, 18.201040267944393],
        [82.332817077637, 18.21578979492199],
        [82.3267364501956, 18.216230392456055],
        [82.32131958007818, 18.222459793090877],
        [82.32253265380888, 18.22812843322754],
        [82.33022308349626, 18.23134040832514],
        [82.33100891113298, 18.233360290527457],
        [82.33203887939459, 18.240129470825195],
        [82.33180236816412, 18.246791839599723],
        [82.33390045166021, 18.25229072570812],
        [82.33689880371111, 18.266241073608455],
        [82.34365081787115, 18.27165031433117],
        [82.36705780029314, 18.27094078063965],
        [82.37187957763678, 18.274101257324276],
        [82.38224029541044, 18.28429031372076],
        [82.38540649414068, 18.289091110229492],
        [82.38735198974615, 18.300622940063477],
        [82.39301300048828, 18.306440353393555],
        [82.388427734375, 18.311201095581055],
        [82.38436889648432, 18.31870079040533],
        [82.38049316406256, 18.319311141967887],
        [82.3751068115235, 18.318000793457088],
        [82.36918640136724, 18.31775283813488],
        [82.36524963378935, 18.31642913818365],
        [82.35797119140648, 18.308240890503043],
        [82.35456085205107, 18.309020996093807],
        [82.34874725341814, 18.30615043640148],
        [82.34290313720726, 18.307340621948185],
        [82.34101104736334, 18.310659408569393],
        [82.33444213867205, 18.317132949829215],
        [82.3364181518557, 18.32011985778803],
        [82.34542083740263, 18.321819305419922],
        [82.35021972656261, 18.328910827636832],
        [82.35656738281267, 18.333570480346737],
        [82.36013793945324, 18.34370040893566],
        [82.36947631835949, 18.350362777710018],
        [82.37635040283214, 18.35326957702648],
        [82.37970733642601, 18.35742950439453],
        [82.37879943847685, 18.361658096313477],
        [82.38109588623075, 18.366689682006836],
        [82.38442993164062, 18.369110107421932],
        [82.38443756103516, 18.372409820556697],
        [82.37841033935575, 18.378131866455135],
        [82.37523651123064, 18.383060455322266],
        [82.37490844726568, 18.392810821533203],
        [82.3725128173831, 18.39809036254877],
        [82.36599731445318, 18.40335273742687],
        [82.36128997802729, 18.40991020202648],
        [82.36599731445318, 18.413011550903377],
        [82.36702728271501, 18.41716957092285],
        [82.37174224853544, 18.42016983032238],
        [82.37760162353521, 18.421989440918082],
        [82.38065338134771, 18.426429748535156],
        [82.38227081298822, 18.43169975280756],
        [82.39737701416033, 18.451601028442496],
        [82.41220092773449, 18.464670181274414],
        [82.42032623291021, 18.469881057739315],
        [82.42687225341825, 18.476991653442326],
        [82.42913818359403, 18.485141754150447],
        [82.4371261596682, 18.497301101684627],
        [82.44993591308622, 18.511421203613395],
        [82.45342254638666, 18.51702117919922],
        [82.47400665283214, 18.536832809448242],
        [82.48161315917997, 18.538459777832088],
        [82.48532104492188, 18.533700942993164],
        [82.481918334961, 18.532711029052848],
        [82.47783660888666, 18.53153038024908],
        [82.47573852539057, 18.52940750122076],
        [82.48145294189447, 18.5150604248048],
        [82.49820709228544, 18.50604057312023],
        [82.49880981445307, 18.503271102905273],
        [82.49915313720732, 18.50175094604498],
        [82.50202178955107, 18.501731872558707],
        [82.5041809082033, 18.50549125671398],
        [82.50740814209001, 18.508079528808594],
        [82.51426696777338, 18.50363922119152],
        [82.51751708984392, 18.50563812255865],
        [82.5231323242188, 18.50392913818365],
        [82.52390289306663, 18.499290466308594],
        [82.522026062012, 18.49699020385748],
        [82.52321624755871, 18.493511199951172],
        [82.5244979858399, 18.489780426025504],
        [82.52005767822271, 18.48726081848156],
        [82.519790649414, 18.48488998413086],
        [82.51951599121116, 18.4825115203858],
        [82.52448272705101, 18.482849121093864],
        [82.52752685546898, 18.477720260620117],
        [82.52101135253912, 18.472370147705135],
        [82.52572631835955, 18.470541000366268],
        [82.52751159667974, 18.468219757080192],
        [82.52176666259766, 18.4617595672608],
        [82.51774597167969, 18.459289550781307],
        [82.51676177978533, 18.457210540771428],
        [82.51613616943388, 18.455881118774414],
        [82.52319335937528, 18.452779769897518],
        [82.52043914794928, 18.450532913208008],
        [82.5212020874024, 18.447111129760856],
        [82.52316284179693, 18.445770263671932],
        [82.52670288085955, 18.44730949401867],
        [82.52938079833984, 18.442939758300838],
        [82.52879333496111, 18.44034004211437],
        [82.5301513671875, 18.434310913085994],
        [82.53308105468756, 18.434061050415153],
        [82.53560638427746, 18.43665122985834],
        [82.53900909423857, 18.438089370727482],
        [82.53836059570312, 18.443180084228572],
        [82.54421234130854, 18.441299438476562],
        [82.54772949218756, 18.441789627075252],
        [82.55011749267572, 18.43889045715332],
        [82.55377197265642, 18.437650680541992],
        [82.55107116699213, 18.433900833129826],
        [82.55021667480474, 18.42650032043457],
        [82.54605865478544, 18.424142837524528],
        [82.5451202392581, 18.41724967956543],
        [82.53968048095703, 18.416868209838867],
        [82.53763580322288, 18.41222953796398],
        [82.53591156005865, 18.408319473266715],
        [82.53697967529314, 18.404199600219727],
        [82.53968811035156, 18.403621673583928],
        [82.5405731201173, 18.403430938720646],
        [82.54032135009771, 18.401870727539062],
        [82.5326004028322, 18.39682006835949],
        [82.53230285644554, 18.39344978332514],
        [82.53515625000023, 18.38956069946289],
        [82.54234313964861, 18.389049530029354],
        [82.54944610595709, 18.383510589599666],
        [82.55172729492193, 18.387081146240234],
        [82.55603027343744, 18.388509750366268],
        [82.5652160644533, 18.383989334106445],
        [82.57060241699224, 18.38418960571289],
        [82.57556152343756, 18.379869461059684],
        [82.5773315429688, 18.37732124328619],
        [82.58080291748075, 18.378850936889762],
        [82.58917236328153, 18.37713813781744],
        [82.59364318847673, 18.370491027832145],
        [82.59902191162115, 18.371618270873967],
        [82.60050964355486, 18.368709564208928],
        [82.60087585449236, 18.3660507202149],
        [82.59768676757818, 18.36259269714361],
        [82.5993118286134, 18.359970092773494],
        [82.59761810302729, 18.35621833801281],
        [82.59857940673845, 18.354011535644588],
        [82.60566711425798, 18.352809906005916],
        [82.60865783691418, 18.35123062133789],
        [82.61128234863287, 18.346460342407283],
        [82.60787963867216, 18.341670989990234],
        [82.5991516113283, 18.342420578003043],
        [82.59783172607439, 18.34164047241211],
        [82.59651184082048, 18.340869903564396],
        [82.59757995605497, 18.333618164062557],
        [82.60031890869158, 18.33076095581049],
        [82.60382843017601, 18.320371627807617],
        [82.60214233398466, 18.317260742187614],
        [82.59568786621105, 18.31590080261242],
        [82.5922927856447, 18.317920684814567],
        [82.58966827392578, 18.319482803344727],
        [82.58863830566418, 18.318479537963924],
        [82.58596038818371, 18.31590080261242],
        [82.58325195312528, 18.311410903930778],
        [82.57805633544928, 18.30935096740717],
        [82.57643890380854, 18.306810379028434],
        [82.57978057861334, 18.301040649414176],
        [82.5810775756836, 18.30093955993658],
        [82.58270263671903, 18.30080032348627],
        [82.58783721923857, 18.3020401000976],
        [82.5909500122072, 18.291959762573242],
        [82.59031677246122, 18.28778076171875],
        [82.58757781982428, 18.28478050231945],
        [82.58558654785168, 18.28096008300787],
        [82.58722686767601, 18.279439926147575],
        [82.58840179443388, 18.278339385986328],
        [82.58846282958979, 18.275049209594727],
        [82.58428192138689, 18.27322006225586],
        [82.58074188232445, 18.27009963989252],
        [82.58318328857422, 18.26655006408697],
        [82.5854721069336, 18.266120910644588],
        [82.58724975585943, 18.265779495239315],
        [82.58866882324236, 18.26211929321289],
        [82.58920288085949, 18.25672912597662],
        [82.59826660156256, 18.254472732543945],
        [82.60076904296898, 18.25201988220209],
        [82.60948181152361, 18.24925041198736],
        [82.61181640625006, 18.246511459350586],
        [82.61103057861328, 18.24384117126465],
        [82.61341857910185, 18.239429473876953],
        [82.61640930175804, 18.238121032714957],
        [82.62039184570318, 18.236370086669922],
        [82.62687683105474, 18.228740692138672],
        [82.62886810302763, 18.228660583496094],
        [82.6316528320313, 18.22853088378912],
        [82.6371688842774, 18.23493003845215],
        [82.64530944824236, 18.24044990539562],
        [82.64758300781244, 18.244731903076115],
        [82.64485931396513, 18.257078170776424],
        [82.6491088867187, 18.263839721679744],
        [82.65222930908203, 18.272140502929688],
        [82.65648651123053, 18.27880096435547],
        [82.65883636474604, 18.28660964965826],
        [82.66493988037104, 18.29062080383312],
        [82.67257690429682, 18.292200088500977],
        [82.67539978027372, 18.29236984252941],
        [82.68177795410156, 18.29277992248535],
        [82.69032287597673, 18.297069549560604],
        [82.6937408447265, 18.2973308563233],
        [82.69809722900419, 18.29627037048334],
        [82.7005004882813, 18.299789428710938],
        [82.70169067382818, 18.304840087890682],
        [82.70255279541033, 18.30846977233898],
        [82.70670318603527, 18.31063270568859],
        [82.71090698242182, 18.31282043457037],
        [82.7172622680664, 18.314821243286246],
        [82.72531127929693, 18.311571121215877],
        [82.7343292236331, 18.313251495361328],
        [82.73280334472685, 18.317899703979492],
        [82.73781585693376, 18.31990051269537],
        [82.74642944335955, 18.319850921630973],
        [82.75064849853516, 18.323299407959098],
        [82.7510375976563, 18.328332901001033],
        [82.75446319580078, 18.32982063293457],
        [82.75849914550787, 18.327180862426815],
        [82.7618484497072, 18.327810287475586],
        [82.76705169677734, 18.33107948303217],
        [82.77143859863281, 18.339679718017692],
        [82.78421783447266, 18.35415077209484],
        [82.77790832519531, 18.370920181274528],
        [82.78096771240234, 18.3725204467774],
        [82.78761291503923, 18.378160476684684],
        [82.78675842285162, 18.38458061218256],
        [82.78748321533209, 18.39020919799816],
        [82.79329681396496, 18.394969940185604],
        [82.7873916625976, 18.39982032775879],
        [82.78118133544928, 18.399450302124137],
        [82.78160858154303, 18.404251098632926],
        [82.77845764160173, 18.409479141235295],
        [82.77620697021484, 18.415519714355582],
        [82.77622222900396, 18.419691085815373],
        [82.78710937500006, 18.426740646362248],
        [82.79197692871088, 18.433139801025504],
        [82.79917144775419, 18.439401626587028],
        [82.818359375, 18.438529968261776],
        [82.81874847412115, 18.433370590210075],
        [82.8241729736331, 18.429920196533317],
        [82.83014678955095, 18.426120758056584],
        [82.83576965332037, 18.424230575561637],
        [82.83634185791021, 18.41954040527338],
        [82.83698272705072, 18.414270401000977],
        [82.8397827148438, 18.41177177429205],
        [82.84490966796898, 18.409772872924748],
        [82.84863281250017, 18.415008544921875],
        [82.85597991943382, 18.410800933837947],
        [82.86296844482439, 18.407920837402344],
        [82.87001037597673, 18.406200408935604],
        [82.87706756591814, 18.41183090209961],
        [82.87935638427751, 18.4146404266358],
        [82.88461303710966, 18.41275978088379],
        [82.88835906982422, 18.406259536743164],
        [82.89077758789057, 18.398599624633732],
        [82.89095306396496, 18.392219543457145],
        [82.88490295410173, 18.37862014770502],
        [82.89186096191435, 18.366880416870117],
        [82.8985214233399, 18.359300613403434],
        [82.90274810791044, 18.35591125488287],
        [82.90838623046875, 18.35943031311041],
        [82.91166687011741, 18.357379913330135],
        [82.91513061523466, 18.356399536132756],
        [82.92005920410179, 18.35973930358898],
        [82.92761230468767, 18.360988616943416],
        [82.93268585205107, 18.3513698577882],
        [82.9455032348634, 18.3504695892334],
        [82.9481658935548, 18.352060317993107],
        [82.9520492553711, 18.354349136352482],
        [82.95597076416044, 18.355810165405273],
        [82.9597930908206, 18.357240676879883],
        [82.96878051757807, 18.35758018493658],
        [82.9764480590822, 18.35491180419922],
        [82.97843170166038, 18.360988616943416],
        [82.9812088012697, 18.36581993103033],
        [82.98610687255888, 18.365901947021484],
        [82.98761749267607, 18.36950111389166],
        [82.99196624755882, 18.37499809265148],
        [82.99286651611328, 18.37482070922846],
        [82.99711608886741, 18.374860763549748],
        [83.0034866333009, 18.376920700073356],
        [83.01161193847679, 18.37821006774908],
        [83.01718139648466, 18.384500503539982],
        [83.02005004882841, 18.38348007202154],
        [83.02375793457048, 18.376449584960938],
        [83.03188323974638, 18.373260498046875],
        [83.0369796752932, 18.369842529296932],
        [83.03952026367216, 18.370080947876033],
        [83.05339050292991, 18.375299453735465],
        [83.05619812011719, 18.377260208129883],
        [83.06582641601591, 18.38940048217779],
        [83.06649780273438, 18.392259597778434],
        [83.06582641601591, 18.393159866333065],
        [83.06373596191435, 18.395709991455135],
        [83.05903625488298, 18.399009704589844],
        [83.05581665039062, 18.40436935424799],
        [83.05531311035162, 18.408111572265625],
        [83.06035614013689, 18.413379669189453],
        [83.06046295166021, 18.41348838806158],
        [83.06062316894537, 18.419242858886776],
        [83.06208801269548, 18.420881271362305],
        [83.06120300292991, 18.423940658569336],
        [83.05982971191406, 18.42863273620617],
        [83.0582580566408, 18.429059982299805],
        [83.0485610961914, 18.42486000061041],
        [83.0426101684572, 18.418291091918945],
        [83.03743743896496, 18.418949127197322],
        [83.0355529785158, 18.42007064819336],
        [83.0333023071289, 18.423339843750114],
        [83.03819274902355, 18.426710128784293],
        [83.03900909423845, 18.432041168212834],
        [83.03732299804688, 18.434829711914062],
        [83.03234100341814, 18.4361896514892],
        [83.02484130859386, 18.436660766601676],
        [83.01803588867205, 18.440401077270508],
        [83.01541137695318, 18.444429397583008],
        [83.01644897460949, 18.44767951965332],
        [83.02455902099615, 18.45369911193859],
        [83.02672576904325, 18.456920623779354],
        [83.03414916992216, 18.460769653320312],
        [83.03701782226591, 18.463130950927678],
        [83.04384613037132, 18.472110748291016],
        [83.05179595947283, 18.477489471435547],
        [83.05517578125011, 18.488122940063477],
        [83.06035614013689, 18.494421005249137],
        [83.06511688232439, 18.497560501098746],
        [83.06881713867193, 18.50202941894537],
        [83.07315826416033, 18.514739990234375],
        [83.0823059082033, 18.527170181274357],
        [83.08850860595732, 18.532760620117244],
        [83.08933258056635, 18.537910461425838],
        [83.08705139160173, 18.541059494018555],
        [83.07147979736334, 18.542491912841797],
        [83.057357788086, 18.534690856933707],
        [83.05414581298851, 18.534799575805607],
        [83.05117797851557, 18.534910202026367],
        [83.04509735107439, 18.539329528808537],
        [83.04023742675787, 18.544891357421875],
        [83.03247833251964, 18.54788970947277],
        [83.03370666503912, 18.558290481567383],
        [83.02545928955095, 18.564300537109318],
        [83.02686309814459, 18.5679607391358],
        [83.03114318847668, 18.571790695190487],
        [83.03376007080084, 18.575719833374023],
        [83.0365066528322, 18.577571868896428],
        [83.04171752929693, 18.577320098877067],
        [83.04203796386736, 18.57730293273937],
        [83.04474639892578, 18.578170776367244],
        [83.05113220214872, 18.583070755004883],
        [83.05394744873053, 18.58646965026861],
        [83.05297851562506, 18.589939117431754],
        [83.05208587646513, 18.593059539794922],
        [83.04946899414057, 18.594869613647518],
        [83.04891967773455, 18.595262527465763],
        [83.04110717773455, 18.596731185913086],
        [83.04065704345732, 18.595262527465763],
        [83.03970336914091, 18.592079162597713],
        [83.03781890869152, 18.588712692260742],
        [83.03114318847668, 18.58358955383312],
        [83.02770233154308, 18.58227157592779],
        [83.01586914062528, 18.584779739379883],
        [83.00804138183622, 18.588420867920036],
        [83.0094833374024, 18.591440200805607],
        [83.02030181884783, 18.60541152954096],
        [83.02126312255876, 18.61045074462902],
        [83.01737976074247, 18.623399734497127],
        [83.01461029052734, 18.626260757446346],
        [83.01132202148432, 18.627710342407227],
        [83.00934600830084, 18.631481170654354],
        [83.0094833374024, 18.63354110717779],
        [83.00968170166021, 18.63652992248541],
        [83.01154327392595, 18.639030456543082],
        [83.02260589599626, 18.644359588623047],
        [83.03616333007812, 18.647689819335938],
        [83.0509719848634, 18.654159545898438],
        [83.05146026611334, 18.66323089599615],
        [83.0558471679688, 18.667140960693416],
        [83.07080841064464, 18.689300537109375],
        [83.06987762451166, 18.691881179809627],
        [83.06909179687511, 18.694051742553768],
        [83.07073211669928, 18.697191238403377],
        [83.07788085937494, 18.705339431762695],
        [83.08574676513689, 18.709980010986328],
        [83.08806610107428, 18.712400436401424],
        [83.08960723876959, 18.716270446777457],
        [83.09079742431669, 18.719259262085018],
        [83.09259033203142, 18.721761703491325],
        [83.0954666137697, 18.723091125488338],
        [83.09921264648466, 18.7268905639649],
        [83.10600280761724, 18.738491058349723],
        [83.11650085449224, 18.7479705810548],
        [83.11752319335966, 18.75048065185547],
        [83.11219024658232, 18.758279800415153],
        [83.11580657959013, 18.76208114624029],
        [83.13295745849638, 18.772449493408317],
        [83.13884735107428, 18.771139144897518],
        [83.14491271972662, 18.766901016235465],
        [83.14686584472662, 18.763179779052734],
        [83.15083312988287, 18.758689880371094],
        [83.15622711181669, 18.757310867309627],
        [83.16854858398438, 18.759069442749023],
        [83.17394256591803, 18.756769180297965],
        [83.17701721191435, 18.75440979003912],
        [83.18080139160179, 18.748250961303768],
        [83.18537139892601, 18.74414253234869],
        [83.19181823730469, 18.741781234741325],
        [83.20170593261736, 18.742229461669922],
        [83.20342254638689, 18.74035263061529],
        [83.20188903808611, 18.736131668090877],
        [83.20242309570324, 18.7325496673584],
        [83.20626068115251, 18.728742599487305],
        [83.20633697509771, 18.72803115844738],
        [83.20641326904325, 18.727420806884822],
        [83.20366668701178, 18.72350120544445],
        [83.20332336425787, 18.719579696655273],
        [83.20506286621122, 18.71586036682129],
        [83.20677947998075, 18.717491149902344],
        [83.20918273925787, 18.72454071044922],
        [83.21254730224604, 18.73447990417486],
        [83.21363830566435, 18.736021041870174],
        [83.21446228027361, 18.737199783325195],
        [83.22033691406278, 18.742048263549805],
        [83.2223510742188, 18.74505043029785],
        [83.22300720214872, 18.747980117797965],
        [83.2226867675783, 18.748619079589844],
        [83.22189331054682, 18.75202178955078],
        [83.21733093261736, 18.75895118713379],
        [83.21707916259794, 18.763561248779297],
        [83.21939086914074, 18.76581954956049],
        [83.2210311889649, 18.76587104797369],
        [83.22271728515625, 18.76593017578125],
        [83.22651672363281, 18.764900207519588],
        [83.2293167114259, 18.764141082763672],
        [83.2395629882813, 18.758781433105526],
        [83.24508666992193, 18.75808906555187],
        [83.25541687011747, 18.75881958007824],
        [83.25936889648443, 18.757600784301758],
        [83.26609802246122, 18.757009506225643],
        [83.26991271972685, 18.759809494018555],
        [83.27171325683611, 18.763179779052734],
        [83.27124786376982, 18.76732063293457],
        [83.26914978027372, 18.770759582519588],
        [83.26992034912138, 18.775287628173885],
        [83.27525329589872, 18.785369873046818],
        [83.27950286865251, 18.78960037231451],
        [83.29210662841814, 18.792530059814396],
        [83.30036926269537, 18.79791069030756],
        [83.30422210693365, 18.798870086669922],
        [83.31404113769548, 18.795801162719783],
        [83.3182601928711, 18.795841217041072],
        [83.32302093505876, 18.79828071594244],
        [83.3261413574221, 18.794820785522575],
        [83.3297882080081, 18.793729782104492],
        [83.33333587646484, 18.79267120361328],
        [83.3358764648438, 18.793212890625],
        [83.33687591552763, 18.794652938842887],
        [83.33761596679716, 18.795700073242188],
        [83.33812713623053, 18.800350189208928],
        [83.33927917480474, 18.803091049194336],
        [83.34073638916033, 18.80352020263672],
        [83.344291687012, 18.80293083190918],
        [83.3478088378908, 18.801258087158317],
        [83.35247039794928, 18.800189971923828],
        [83.35629272460943, 18.800119400024414],
        [83.35848236084001, 18.80179977416998],
        [83.3602294921875, 18.804470062255916],
        [83.3599929809572, 18.807350158691406],
        [83.35011291503906, 18.82163810729986],
        [83.35349273681635, 18.827489852905273],
        [83.35887908935564, 18.832000732421932],
        [83.36032104492216, 18.83204078674322],
        [83.36226654052729, 18.83210945129406],
        [83.37283325195318, 18.821210861206055],
        [83.37635040283232, 18.82195091247553],
        [83.38313293457037, 18.826070785522518],
        [83.386489868164, 18.825769424438533],
        [83.3963012695313, 18.830709457397404],
        [83.3987121582033, 18.833528518676758],
        [83.39694213867216, 18.849210739135685],
        [83.39787292480474, 18.852590560913143],
        [83.39985656738298, 18.85365104675293],
        [83.40680694580084, 18.85367965698248],
        [83.40875244140653, 18.854450225830135],
        [83.41073608398455, 18.85676193237316],
        [83.41133880615234, 18.85934066772461],
        [83.40585327148443, 18.865329742431754],
        [83.40432739257818, 18.865980148315543],
        [83.40177917480486, 18.867071151733455],
        [83.39425659179716, 18.86820030212408],
        [83.38780975341808, 18.871030807495117],
        [83.38539123535173, 18.873262405395565],
        [83.38568115234403, 18.875490188598633],
        [83.37890625000017, 18.880279541015682],
        [83.37390136718744, 18.885911941528434],
        [83.37248992919945, 18.889839172363395],
        [83.3744430541995, 18.89194107055664],
        [83.38326263427751, 18.89723968505865],
        [83.38391113281256, 18.899690628051758],
        [83.38099670410173, 18.901821136474553],
        [83.37684631347662, 18.902469635009822],
        [83.36715698242193, 18.90036010742199],
        [83.36631011962908, 18.900461196899414],
        [83.3605270385745, 18.901170730590934],
        [83.3577728271485, 18.903680801391545],
        [83.356498718262, 18.90480995178217],
        [83.35016632080101, 18.915929794311637],
        [83.34768676757835, 18.920988082885742],
        [83.34661102294933, 18.926870346069336],
        [83.34792327880888, 18.93214035034174],
        [83.34902954101585, 18.933839797973633],
        [83.35523986816423, 18.933031082153434],
        [83.3627624511721, 18.930070877075252],
        [83.36406707763678, 18.92278861999523],
        [83.37100219726591, 18.917089462280387],
        [83.3738403320313, 18.916791915893555],
        [83.37634277343778, 18.917770385742188],
        [83.37966156005876, 18.921520233154297],
        [83.38022613525408, 18.923282623291072],
        [83.36741638183588, 18.933490753173828],
        [83.36389923095726, 18.937931060791072],
        [83.36084747314476, 18.948110580444393],
        [83.36106872558622, 18.95340156555187],
        [83.3602294921875, 18.953899383544922],
        [83.35890197753906, 18.954711914062614],
        [83.35440826416044, 18.95445060729992],
        [83.34903717041021, 18.956090927124023],
        [83.34575653076178, 18.957099914550838],
        [83.34501647949241, 18.956300735473746],
        [83.34480285644537, 18.945249557495174],
        [83.34326171875, 18.935161590576286],
        [83.33983612060553, 18.93321990966797],
        [83.33972930908232, 18.932752609252873],
        [83.33952331542997, 18.931911468505973],
        [83.33953857421903, 18.929479598999137],
        [83.33957672119135, 18.926200866699276],
        [83.3413696289063, 18.91839981079096],
        [83.33905792236328, 18.916200637817496],
        [83.33454132080084, 18.91582107543951],
        [83.32901000976568, 18.916908264160213],
        [83.32202911376947, 18.923250198364315],
        [83.31991577148449, 18.92581748962408],
        [83.31887817382818, 18.92843818664562],
        [83.31849670410162, 18.933620452880973],
        [83.32119750976568, 18.93602943420416],
        [83.3311309814456, 18.937639236450195],
        [83.33302307128912, 18.93931961059576],
        [83.33245849609403, 18.94337081909191],
        [83.33104705810547, 18.945810317993278],
        [83.32888793945307, 18.94682121276867],
        [83.32704162597656, 18.94790077209484],
        [83.32601165771496, 18.950162887573242],
        [83.32565307617182, 18.952922821044865],
        [83.32691955566412, 18.957340240478516],
        [83.32556152343773, 18.958971023559684],
        [83.320556640625, 18.96103096008312],
        [83.319808959961, 18.96133041381836],
        [83.31334686279303, 18.966630935668945],
        [83.3096160888673, 18.976821899414176],
        [83.3091430664063, 18.98035049438488],
        [83.3077468872072, 18.983249664306584],
        [83.3043289184572, 18.986989974975586],
        [83.30526733398455, 18.989681243896598],
        [83.30783843994146, 18.990949630737305],
        [83.31643676757841, 18.990400314331055],
        [83.32537841796898, 18.988809585571346],
        [83.32917785644537, 18.988180160522518],
        [83.3312072753908, 18.990070343017692],
        [83.33075714111357, 18.992570877075195],
        [83.3301010131836, 18.99695968627941],
        [83.33067321777344, 18.998428344726562],
        [83.33271026611322, 18.9998397827149],
        [83.33497619628918, 19.006719589233512],
        [83.34091186523432, 19.00916290283203],
        [83.34633636474615, 19.009889602661246],
        [83.35498046875028, 19.00938987731945],
        [83.35800933837896, 19.006139755249137],
        [83.3623275756836, 18.994729995727596],
        [83.36747741699219, 18.993619918823242],
        [83.377197265625, 18.994720458984432],
        [83.3847198486331, 18.997272491455135],
        [83.38875579834013, 19.000019073486442],
        [83.39295959472668, 18.999128341674748],
        [83.39605712890642, 18.995830535888786],
        [83.40142059326172, 18.99638175964361],
        [83.40231323242199, 18.995161056518555],
        [83.40464019775408, 18.9932098388673],
        [83.4068984985351, 18.995080947875977],
        [83.40885162353533, 18.99619102478033],
        [83.41057586669922, 18.992610931396484],
        [83.41142272949247, 18.988719940185604],
        [83.40878295898466, 18.979890823364315],
        [83.41484069824224, 18.9778728485108],
        [83.4329223632813, 18.961399078369197],
        [83.43647766113298, 18.957878112793026],
        [83.44001007080101, 18.950220108032227],
        [83.44267272949241, 18.94774055480957],
        [83.44684600830084, 18.945648193359375],
        [83.45120239257812, 18.944459915161246],
        [83.45548248291044, 18.945579528808537],
        [83.46227264404297, 18.959220886230526],
        [83.46224212646501, 18.9635906219483],
        [83.46147155761736, 18.96792030334484],
        [83.45894622802763, 18.970449447631893],
        [83.45831298828148, 18.972471237182617],
        [83.46029663085949, 18.980779647827262],
        [83.45771789550787, 18.982500076293945],
        [83.4502258300783, 18.982421875],
        [83.44908905029314, 18.992610931396484],
        [83.448226928711, 18.995941162109318],
        [83.4485168457033, 18.999631881713924],
        [83.45017242431669, 19.001888275146598],
        [83.4532775878908, 19.002460479736442],
        [83.458480834961, 19.002098083496094],
        [83.46750640869169, 18.998918533325252],
        [83.47183990478521, 18.99897956848156],
        [83.47460174560558, 19.000312805175895],
        [83.47663879394537, 19.004070281982422],
        [83.47511291503912, 19.01152992248541],
        [83.47796630859398, 19.02119064331066],
        [83.4773559570313, 19.0255184173584],
        [83.46543884277355, 19.03619003295904],
        [83.4627990722658, 19.04395103454584],
        [83.46327209472679, 19.05260086059576],
        [83.45670318603533, 19.05611038208002],
        [83.4573669433596, 19.059801101684627],
        [83.45913696289091, 19.063489913940487],
        [83.45826721191406, 19.067132949829045],
        [83.45939636230486, 19.069671630859432],
        [83.4638290405274, 19.07140159606945],
        [83.46746826171898, 19.06987953186041],
        [83.47027587890625, 19.070320129394474],
        [83.47202301025396, 19.072740554809627],
        [83.47323608398466, 19.077762603759822],
        [83.4769897460938, 19.075330734252987],
        [83.47817993164091, 19.070220947265682],
        [83.4804382324221, 19.06648063659668],
        [83.49249267578153, 19.054950714111442],
        [83.49362945556669, 19.053089141845703],
        [83.49633026123075, 19.0555095672608],
        [83.50036621093744, 19.062080383300895],
        [83.50287628173845, 19.064661026000977],
        [83.50538635253906, 19.06477165222168],
        [83.50617980957037, 19.05430984497076],
        [83.50847625732445, 19.04991149902338],
        [83.51074981689459, 19.048000335693416],
        [83.50979614257818, 19.040081024170036],
        [83.51008605957026, 19.036172866821403],
        [83.51322937011724, 19.030321121215763],
        [83.51367187500028, 19.0247802734375],
        [83.50817871093744, 19.02197074890148],
        [83.50392913818388, 19.026201248169002],
        [83.49931335449224, 19.027080535888786],
        [83.4935531616211, 19.02680015563965],
        [83.49263000488287, 19.023981094360465],
        [83.49292755126947, 19.018030166626033],
        [83.4953994750976, 19.01109123229992],
        [83.50460052490251, 19.0100994110108],
        [83.51529693603516, 19.004909515380916],
        [83.53138732910162, 19.005420684814453],
        [83.53311157226591, 19.01246070861822],
        [83.53309631347662, 19.019382476806697],
        [83.53482818603544, 19.02861976623535],
        [83.53578948974638, 19.03034973144537],
        [83.54277801513678, 19.036840438842887],
        [83.5477294921875, 19.044969558715934],
        [83.55229949951172, 19.04718971252447],
        [83.55148315429682, 19.050380706787223],
        [83.54486846923828, 19.062080383300895],
        [83.54485321044922, 19.069402694702205],
        [83.54643249511741, 19.073829650878963],
        [83.55149078369158, 19.077850341796932],
        [83.55497741699219, 19.0781307220459],
        [83.5606765747072, 19.06622123718273],
        [83.56038665771513, 19.063961029052734],
        [83.55710601806646, 19.059989929199332],
        [83.55642700195324, 19.05583953857422],
        [83.55766296386724, 19.051380157470703],
        [83.56105041503923, 19.050388336181584],
        [83.5673599243164, 19.052259445190487],
        [83.5787658691408, 19.049789428710938],
        [83.58078002929693, 19.055149078369254],
        [83.58178710937511, 19.06277275085455],
        [83.58219909667986, 19.075639724731502],
        [83.58444976806669, 19.079730987548942],
        [83.58763122558616, 19.08307075500494],
        [83.59320831298857, 19.084890365600643],
        [83.59986114501959, 19.084802627563533],
        [83.60441589355491, 19.088180541992244],
        [83.60440063476568, 19.09287071228033],
        [83.59677886962896, 19.105199813842773],
        [83.5942993164063, 19.110580444335938],
        [83.59365081787126, 19.115489959716797],
        [83.5942993164063, 19.120330810546875],
        [83.60051727294939, 19.127700805664176],
        [83.60521697998075, 19.131032943725643],
        [83.61060333251947, 19.130937576294002],
        [83.61408996582031, 19.13381958007824],
        [83.61913299560575, 19.14188194274908],
        [83.62271881103521, 19.144687652587947],
        [83.62023925781256, 19.148628234863338],
        [83.62116241455078, 19.151920318603516],
        [83.62328338623053, 19.15363121032709],
        [83.62850952148466, 19.149740219116268],
        [83.6301879882813, 19.14479064941412],
        [83.62844085693365, 19.13150024414074],
        [83.6298370361331, 19.126991271972656],
        [83.63331604003923, 19.124429702758846],
        [83.64556121826172, 19.123100280761832],
        [83.64943695068382, 19.12187957763672],
        [83.65072631835938, 19.11840057373047],
        [83.64974975585955, 19.114820480346737],
        [83.64627075195307, 19.114498138427734],
        [83.64472961425793, 19.113290786743107],
        [83.64588165283214, 19.108730316162223],
        [83.64778900146496, 19.10594177246105],
        [83.64791870117193, 19.102251052856445],
        [83.65026855468761, 19.100099563598746],
        [83.65748596191435, 19.08777236938471],
        [83.6580963134765, 19.082109451294002],
        [83.65685272216803, 19.074939727783317],
        [83.65969848632818, 19.06974029541027],
        [83.66261291503912, 19.066490173339957],
        [83.66673278808594, 19.06446075439453],
        [83.67637634277372, 19.052450180053768],
        [83.68438720703153, 19.043930053710994],
        [83.6941528320312, 19.021141052246094],
        [83.69702148437517, 19.016479492187614],
        [83.69983673095732, 19.01503181457531],
        [83.71682739257824, 19.014448165893555],
        [83.72128295898438, 19.01341819763195],
        [83.72325134277366, 19.01189994812023],
        [83.71984863281278, 19.004289627075252],
        [83.71478271484392, 19.00213050842285],
        [83.70941925048845, 19.00195121765148],
        [83.7050018310548, 18.99946975708002],
        [83.70436096191429, 18.99530982971197],
        [83.70469665527344, 18.99248123168951],
        [83.70484924316423, 18.99200820922863],
        [83.7057418823245, 18.985240936279354],
        [83.70880126953153, 18.983802795410213],
        [83.71140289306646, 18.984951019287223],
        [83.7168960571289, 18.98253822326666],
        [83.72630310058616, 18.97556114196783],
        [83.72746276855474, 18.96607971191412],
        [83.73002624511736, 18.96015930175787],
        [83.73649597167963, 18.938970565795955],
        [83.73992156982416, 18.93406105041504],
        [83.74140167236357, 18.930290222168082],
        [83.7421493530274, 18.92313957214361],
        [83.74582672119152, 18.918172836303768],
        [83.75614929199236, 18.91669082641596],
        [83.7628479003908, 18.906539916992188],
        [83.76750183105491, 18.902362823486442],
        [83.77050018310547, 18.90159034729004],
        [83.77127075195312, 18.90130043029791],
        [83.77739715576178, 18.90439987182623],
        [83.78006744384771, 18.907720565796012],
        [83.77715301513689, 18.919071197509822],
        [83.76986694335932, 18.932970046997127],
        [83.76525115966825, 18.936420440673885],
        [83.76148223876947, 18.94460105895996],
        [83.75520324707048, 18.942029953002987],
        [83.75036621093778, 18.94437026977539],
        [83.74836730957031, 18.951370239257812],
        [83.74900054931663, 18.958440780639705],
        [83.74597167968778, 18.963220596313477],
        [83.74172210693365, 18.967058181762695],
        [83.73956298828142, 18.978441238403263],
        [83.74108123779314, 18.983890533447266],
        [83.74923706054716, 18.990671157836914],
        [83.7506179809572, 18.99241828918457],
        [83.75128173828148, 18.993280410766715],
        [83.75242614746116, 18.993331909179688],
        [83.75354766845709, 18.993169784546012],
        [83.75469207763678, 18.9933700561524],
        [83.75582885742193, 18.993610382080078],
        [83.7569580078125, 18.993909835815487],
        [83.75778961181646, 18.99455833435053],
        [83.7584533691408, 18.99547004699707],
        [83.75946807861334, 18.99605941772461],
        [83.76056671142595, 18.99646949768072],
        [83.76171112060564, 18.996778488159237],
        [83.76288604736334, 18.997051239013672],
        [83.76405334472662, 18.997310638427848],
        [83.76523590087919, 18.997600555419922],
        [83.76640319824247, 18.99795913696289],
        [83.76747894287115, 18.998428344726562],
        [83.76850128173857, 18.999080657958928],
        [83.76941680908209, 18.999771118164062],
        [83.77021026611357, 19.000591278076286],
        [83.7706604003908, 19.001609802246207],
        [83.77111816406273, 19.002639770507812],
        [83.77156829833996, 19.003669738769588],
        [83.77204895019537, 19.004671096801815],
        [83.77259826660156, 19.00566101074213],
        [83.77320098876959, 19.006589889526367],
        [83.77392578125, 19.007459640502987],
        [83.77474212646507, 19.008249282836914],
        [83.77565002441406, 19.008960723877067],
        [83.77664947509788, 19.009519577026424],
        [83.77774047851562, 19.00988006591797],
        [83.77887725830095, 19.010009765625114],
        [83.78037261962908, 19.009941101074276],
        [83.78148651123058, 19.009790420532283],
        [83.7826385498048, 19.009571075439396],
        [83.78375244140653, 19.00933074951172],
        [83.78486633300787, 19.00909042358404],
        [83.7859725952149, 19.008789062500057],
        [83.78704071044916, 19.00836944580078],
        [83.78830718994146, 19.007810592651424],
        [83.78894805908197, 18.99833106994629],
        [83.79116821289068, 18.992359161377067],
        [83.80253601074219, 18.981809616088867],
        [83.808090209961, 18.968402862548828],
        [83.81063842773466, 18.964830398559627],
        [83.81476593017584, 18.953178405761832],
        [83.81606292724626, 18.948659896850643],
        [83.81340789794922, 18.933580398559684],
        [83.81488800048822, 18.930038452148494],
        [83.81890106201189, 18.92609977722168],
        [83.82116699218761, 18.92196083068859],
        [83.81693267822294, 18.90940284729004],
        [83.81845092773466, 18.907119750976562],
        [83.823051452637, 18.905460357666016],
        [83.83487701416044, 18.90929985046381],
        [83.8377227783206, 18.908609390258903],
        [83.84027099609392, 18.90406036376953],
        [83.84163665771484, 18.89763069152832],
        [83.84232330322294, 18.888240814209098],
        [83.8366928100586, 18.872720718383903],
        [83.83600616455107, 18.869108200073242],
        [83.8370971679688, 18.86470985412609],
        [83.84366607666027, 18.851400375366268],
        [83.84797668457037, 18.847618103027344],
        [83.8590698242188, 18.843011856079045],
        [83.86434173583984, 18.839040756225643],
        [83.86686706542991, 18.823450088501033],
        [83.87017822265636, 18.818429946899414],
        [83.87391662597662, 18.817768096923828],
        [83.8832397460938, 18.81969070434576],
        [83.88463592529325, 18.817819595337028],
        [83.88535308837896, 18.81004142761236],
        [83.88687896728521, 18.80540847778326],
        [83.89064025878923, 18.80686950683605],
        [83.8954772949221, 18.81487846374506],
        [83.89855194091803, 18.816440582275447],
        [83.90197753906256, 18.816921234130973],
        [83.90775299072277, 18.81638908386242],
        [83.92059326171892, 18.81157112121582],
        [83.92265319824213, 18.808532714843807],
        [83.92639160156256, 18.80656051635748],
        [83.93358612060547, 18.80685806274414],
        [83.93572235107445, 18.803129196166992],
        [83.93657684326166, 18.799200057983512],
        [83.93978881835966, 18.79660034179699],
        [83.94368743896484, 18.796287536621094],
        [83.94902038574219, 18.79704093933111],
        [83.95121002197294, 18.800041198730582],
        [83.95561218261747, 18.803060531616154],
        [83.96402740478527, 18.8041095733642],
        [83.96938323974604, 18.803829193115234],
        [83.98232269287138, 18.800010681152457],
        [83.99153900146501, 18.80466270446783],
        [83.99997711181646, 18.804790496826172],
        [84.00739288330078, 18.804210662841797],
        [84.01249694824236, 18.80063056945812],
        [84.01586914062528, 18.801410675048885],
        [84.01966094970709, 18.797622680664062],
        [84.02231597900413, 18.798629760742244],
        [84.0237274169923, 18.801151275634766],
        [84.0270614624024, 18.802200317382812],
        [84.03071594238304, 18.800279617309684],
        [84.03077697753918, 18.79544067382824],
        [84.03428649902344, 18.79012107849121],
        [84.03871917724632, 18.787429809570426],
        [84.04794311523449, 18.78347969055187],
        [84.04760742187517, 18.779872894287166],
        [84.04972076416033, 18.77730941772461],
        [84.04804992675787, 18.774402618408146],
        [84.04920196533209, 18.77065086364746],
        [84.05355834960938, 18.768369674682617],
        [84.05860900878935, 18.768100738525334],
        [84.06046295166038, 18.762670516967773],
        [84.06577301025396, 18.75169944763195],
        [84.06989288330072, 18.74975967407238],
        [84.07788085937528, 18.748880386352596],
        [84.079330444336, 18.74611091613781],
        [84.08177947998047, 18.74464035034174],
        [84.0855789184572, 18.745180130004826],
        [84.0887832641601, 18.74711036682129],
        [84.09163665771479, 18.745239257812557],
        [84.10072326660162, 18.7526798248291],
        [84.10758972167974, 18.754800796508846],
        [84.11406707763695, 18.755140304565543],
        [84.1189270019533, 18.75303077697754],
        [84.12294006347673, 18.748268127441463],
        [84.12948608398443, 18.75286293029785],
        [84.12793731689453, 18.761268615722713],
        [84.13136291503906, 18.76480293273937],
        [84.13497924804705, 18.766788482666016],
        [84.14042663574247, 18.767690658569393],
        [84.14811706542986, 18.771760940551815],
        [84.15136718750017, 18.775760650634766],
        [84.15493011474638, 18.77601051330572],
        [84.15934753417963, 18.775039672851676],
        [84.16027069091825, 18.772319793701172],
        [84.15818786621105, 18.76918983459484],
        [84.1626281738283, 18.768569946289176],
        [84.16703033447266, 18.770139694213924],
        [84.17981719970703, 18.76897048950201],
        [84.18151092529314, 18.77752113342291],
        [84.18651580810553, 18.77960014343256],
        [84.19033813476568, 18.77673912048334],
        [84.20382690429705, 18.782640457153377],
        [84.21671295166044, 18.78728103637701],
        [84.22145843505865, 18.786479949951172],
        [84.22429656982428, 18.7842693328858],
        [84.2269897460938, 18.787160873413143],
        [84.23069000244135, 18.785640716552848],
        [84.2311630249024, 18.780120849609432],
        [84.2460327148438, 18.773410797119254],
        [84.25218963623075, 18.772481918335018],
        [84.25888824462919, 18.774829864501953],
        [84.26422119140653, 18.780519485473633],
        [84.26818847656256, 18.780309677124137],
        [84.27321624755854, 18.782489776611385],
        [84.27533721923845, 18.78718185424799],
        [84.27854919433605, 18.789680480957088],
        [84.28392028808611, 18.790821075439453],
        [84.30036926269531, 18.781740188598746],
        [84.30432128906267, 18.780719757080078],
        [84.3071670532226, 18.78166007995617],
        [84.30885314941435, 18.778419494628963],
        [84.31249237060558, 18.77919006347662],
        [84.31292724609392, 18.782180786132812],
        [84.31571960449247, 18.786170959472656],
        [84.326957702637, 18.789672851562557],
        [84.33222198486328, 18.79202270507824],
        [84.33358764648443, 18.795051574707145],
        [84.32952880859392, 18.804531097412053],
        [84.32932281494158, 18.80899810791027],
        [84.3441009521485, 18.811941146850643],
        [84.34075927734392, 18.818250656127987],
        [84.34243011474638, 18.821508407592887],
        [84.34426879882818, 18.822700500488395],
        [84.34516906738298, 18.826780319213867],
        [84.34435272216808, 18.83271980285639],
        [84.33806610107416, 18.836219787597713],
        [84.33598327636736, 18.841421127319336],
        [84.33413696289068, 18.851560592651367],
        [84.33413696289068, 18.857990264892578],
        [84.33442687988293, 18.86384963989252],
        [84.33602142333984, 18.86671066284174],
        [84.33979034423822, 18.865921020507812],
        [84.34438323974626, 18.86276245117199],
        [84.34696960449224, 18.859979629516715],
        [84.35146331787126, 18.850900650024414],
        [84.35411834716814, 18.85149955749506],
        [84.35521697998058, 18.852640151977596],
        [84.35833740234375, 18.851341247558707],
        [84.3624114990235, 18.85106086730957],
        [84.36933898925793, 18.858970642089957],
        [84.3714675903322, 18.863382339477653],
        [84.371566772461, 18.86721992492687],
        [84.36624908447294, 18.871709823608512],
        [84.36132049560558, 18.872680664062614],
        [84.35962677001947, 18.871900558471793],
        [84.35382843017584, 18.875062942504826],
        [84.3518371582033, 18.87779045104992],
        [84.3514480590822, 18.88319969177246],
        [84.35473632812506, 18.882930755615178],
        [84.37135314941412, 18.875440597534237],
        [84.37406921386747, 18.87649917602539],
        [84.37808227539091, 18.874099731445312],
        [84.3832321166995, 18.869279861450252],
        [84.38622283935553, 18.871709823608512],
        [84.3876266479495, 18.877668380737305],
        [84.38609313964872, 18.883029937744197],
        [84.37903594970732, 18.885469436645565],
        [84.37422943115257, 18.888860702514762],
        [84.36767578125017, 18.889329910278434],
        [84.36490631103544, 18.893220901489258],
        [84.36531829834013, 18.896259307861442],
        [84.36976623535173, 18.895811080932617],
        [84.37406921386747, 18.894029617309627],
        [84.37845611572294, 18.893241882324276],
        [84.38687133789091, 18.88986968994152],
        [84.39396667480486, 18.8858699798584],
        [84.3978271484375, 18.88611984252941],
        [84.40981292724626, 18.8906307220459],
        [84.41208648681658, 18.893939971923942],
        [84.41072082519543, 18.898258209228516],
        [84.40769958496111, 18.901121139526367],
        [84.40615081787121, 18.90612030029297],
        [84.40956878662138, 18.90810012817377],
        [84.41796875000011, 18.900659561157227],
        [84.42032623291016, 18.904870986938477],
        [84.42938995361357, 18.913682937622127],
        [84.4199829101563, 18.929710388183707],
        [84.41713714599632, 18.933301925659293],
        [84.41559600830084, 18.9380397796632],
        [84.42030334472673, 18.949359893798828],
        [84.43454742431669, 18.96315956115734],
        [84.437728881836, 18.96570968627924],
        [84.44064331054716, 18.966930389404354],
        [84.43472290039091, 18.976829528808707],
        [84.42796325683594, 18.98264122009283],
        [84.42640686035173, 18.983970642089844],
        [84.42555236816412, 18.98915100097662],
        [84.42610931396507, 18.991769790649528],
        [84.42526245117205, 18.995340347290096],
        [84.42003631591825, 19.000841140747127],
        [84.41803741455078, 19.004989624023494],
        [84.41541290283209, 19.008132934570426],
        [84.41464996337896, 19.012359619140625],
        [84.41513061523432, 19.01386070251465],
        [84.42002105712896, 19.012519836425895],
        [84.42218780517572, 19.008531570434684],
        [84.42980194091808, 19.001180648803825],
        [84.4329757690432, 19.001859664916992],
        [84.43739318847685, 18.997459411621037],
        [84.44406127929716, 18.99455833435053],
        [84.4481506347658, 18.991739273071403],
        [84.45218658447266, 18.98833084106451],
        [84.46282958984392, 18.983289718628043],
        [84.47165679931646, 18.981220245361328],
        [84.47306060791044, 18.98264122009283],
        [84.47569274902366, 18.991739273071403],
        [84.47551727294928, 18.996032714843807],
        [84.47782897949247, 19.007890701294002],
        [84.48206329345714, 19.009908676147518],
        [84.48938751220703, 19.020950317382812],
        [84.4977798461914, 19.02487945556652],
        [84.50308990478533, 19.02926254272461],
        [84.50711059570312, 19.035448074340934],
        [84.50991821289057, 19.03746032714855],
        [84.52715301513672, 19.038719177246094],
        [84.53147125244135, 19.044149398803654],
        [84.53481292724615, 19.045360565185604],
        [84.54772186279314, 19.046091079712028],
        [84.55359649658197, 19.0474090576173],
        [84.5597229003908, 19.05956077575695],
        [84.56613922119169, 19.06503868103033],
        [84.5679702758789, 19.063470840454215],
        [84.56852722167969, 19.06064033508295],
        [84.57060241699236, 19.060411453247184],
        [84.57644653320341, 19.064561843872127],
        [84.5781173706057, 19.062128067016715],
        [84.57509613037138, 19.037538528442496],
        [84.57364654541033, 19.03511047363287],
        [84.57531738281278, 19.02719116210949],
        [84.57813262939459, 19.018901824951286],
        [84.58769226074247, 19.012990951538143],
        [84.59011840820318, 19.013380050659293],
        [84.59697723388678, 19.01974105834961],
        [84.60048675537138, 19.03293037414562],
        [84.60756683349621, 19.045408248901424],
        [84.60946655273449, 19.04673957824707],
        [84.61418914794928, 19.0467205047608],
        [84.61235809326189, 19.04973983764654],
        [84.6115264892581, 19.053730010986385],
        [84.6127166748048, 19.054830551147404],
        [84.62329101562506, 19.05566024780279],
        [84.62541198730474, 19.05278015136713],
        [84.62608337402338, 19.050291061401367],
        [84.6300582885745, 19.049591064453125],
        [84.63437652587896, 19.05554008483898],
        [84.63683319091803, 19.05714988708496],
        [84.64772796630865, 19.056089401245174],
        [84.65453338623064, 19.052310943603516],
        [84.65901184082037, 19.06364822387701],
        [84.66320037841803, 19.064451217651424],
        [84.66458892822277, 19.066291809082145],
        [84.6596221923831, 19.07093048095703],
        [84.65416717529297, 19.081012725830135],
        [84.64871215820318, 19.084199905395565],
        [84.64460754394548, 19.084451675415153],
        [84.63474273681646, 19.078689575195426],
        [84.6336059570313, 19.074638366699276],
        [84.63144683837908, 19.07262039184576],
        [84.62444305419928, 19.07442092895502],
        [84.61982727050798, 19.076509475708008],
        [84.61656951904314, 19.08520126342779],
        [84.61678314208984, 19.090000152587947],
        [84.61441040039091, 19.092317581176758],
        [84.60962677001982, 19.090892791748047],
        [84.60556030273443, 19.092630386352653],
        [84.60293579101568, 19.096570968628043],
        [84.59577178955095, 19.11520957946783],
        [84.59168243408209, 19.121120452880973],
        [84.59184265136724, 19.123840332031307],
        [84.59464263916033, 19.12354850769043],
        [84.60939788818382, 19.117450714111385],
        [84.62554931640642, 19.118270874023494],
        [84.62931823730463, 19.120700836181697],
        [84.64037322998058, 19.122238159179688],
        [84.64170837402355, 19.124788284301758],
        [84.64729309082048, 19.120370864868164],
        [84.6534271240235, 19.12290954589855],
        [84.65731811523466, 19.12138938903803],
        [84.6611709594726, 19.121860504150504],
        [84.6645126342774, 19.128150939941406],
        [84.66590118408232, 19.135271072387695],
        [84.6654129028322, 19.14470291137701],
        [84.6632690429687, 19.14748001098627],
        [84.66501617431658, 19.156730651855412],
        [84.66929626464866, 19.155681610107365],
        [84.67333221435575, 19.15689086914074],
        [84.6780700683596, 19.153469085693416],
        [84.68141174316423, 19.149749755859432],
        [84.692138671875, 19.151700973510856],
        [84.697563171387, 19.150291442871094],
        [84.7020874023438, 19.15114974975586],
        [84.70522308349621, 19.1490097045899],
        [84.70648193359398, 19.1435604095459],
        [84.69731903076178, 19.12512969970703],
        [84.69391632080084, 19.121551513671932],
        [84.69381713867205, 19.11952018737793],
        [84.69776916503935, 19.115631103515682],
        [84.69832611084013, 19.110309600830078],
        [84.69754791259794, 19.108818054199332],
        [84.71185302734403, 19.10003089904791],
        [84.71903228759766, 19.095844268798828],
        [84.71902465820312, 19.095138549804688],
        [84.72022247314476, 19.095138549804688],
        [84.72041320800804, 19.095138549804688],
        [84.72041320800804, 19.09505844116211],
        [84.72041320800804, 19.094861984253043],
        [84.72069549560564, 19.094861984253043],
        [84.72069549560564, 19.094306945800895],
        [84.72097015380865, 19.094310760498047],
        [84.72097015380865, 19.093750000000114],
        [84.72069549560564, 19.093750000000114],
        [84.72069549560564, 19.092916488647575],
        [84.72097015380865, 19.092920303344727],
        [84.72097015380865, 19.092081069946403],
        [84.7212524414062, 19.092084884643555],
        [84.7212524414062, 19.09180641174322],
        [84.72152709960943, 19.091810226440543],
        [84.72152709960943, 19.091529846191406],
        [84.72180175781278, 19.091529846191406],
        [84.72180175781278, 19.09124946594244],
        [84.72235870361357, 19.09124946594244],
        [84.72235870361357, 19.09097099304205],
        [84.72264099121094, 19.0909748077392],
        [84.72264099121094, 19.090414047241268],
        [84.72235870361357, 19.090419769287223],
        [84.72235870361357, 19.089862823486442],
        [84.72264099121094, 19.089862823486442],
        [84.72264099121094, 19.08958053588873],
        [84.72319793701189, 19.08958053588873],
        [84.72319793701189, 19.089862823486442],
        [84.7234725952149, 19.089862823486442],
        [84.7234725952149, 19.090139389038086],
        [84.72374725341814, 19.090139389038086],
        [84.72374725341814, 19.090419769287223],
        [84.72402954101585, 19.090414047241268],
        [84.72402954101585, 19.09069252014166],
        [84.7243041992187, 19.09069252014166],
        [84.7243041992187, 19.090414047241268],
        [84.72486114501964, 19.090419769287223],
        [84.72486114501964, 19.090139389038086],
        [84.7254180908206, 19.090139389038086],
        [84.7254180908206, 19.089862823486442],
        [84.72569274902344, 19.089862823486442],
        [84.72569274902344, 19.090139389038086],
        [84.72624969482439, 19.090139389038086],
        [84.72624969482439, 19.089862823486442],
        [84.72820281982428, 19.089862823486442],
        [84.72819519042974, 19.089582443237305],
        [84.72973632812506, 19.089582443237305],
        [84.72985839843767, 19.089582443237305],
        [84.72985839843767, 19.08930587768549],
        [84.73021697998041, 19.08930587768549],
        [84.76101684570318, 19.071311950683594],
        [84.76097106933611, 19.07097053527832],
        [84.7606964111331, 19.070972442627067],
        [84.7606964111331, 19.070693969726676],
        [84.76041412353516, 19.070693969726676],
        [84.76041412353516, 19.07041740417492],
        [84.76013946533232, 19.07042121887207],
        [84.76013946533232, 19.069860458374137],
        [84.75985717773443, 19.069860458374137],
        [84.75985717773443, 19.069580078125],
        [84.75958251953136, 19.069583892822322],
        [84.75958251953136, 19.069305419921932],
        [84.75930786132818, 19.069311141967887],
        [84.75930786132818, 19.068750381469783],
        [84.75902557373041, 19.068750381469783],
        [84.75902557373041, 19.068471908569393],
        [84.75875091552757, 19.068471908569393],
        [84.75875091552757, 19.068195343017578],
        [84.75846862792986, 19.068201065063533],
        [84.75846862792986, 19.06764030456543],
        [84.75820159912138, 19.06764030456543],
        [84.75820159912138, 19.066801071167106],
        [84.75791931152361, 19.06680679321289],
        [84.75791931152361, 19.06597137451172],
        [84.75763702392607, 19.06597137451172],
        [84.75763702392607, 19.065696716308707],
        [84.75736236572266, 19.065696716308707],
        [84.75736236572266, 19.065416336059513],
        [84.75708007812528, 19.0654296875],
        [84.75708007812528, 19.065139770507926],
        [84.75680541992193, 19.065139770507926],
        [84.75680541992193, 19.064861297607536],
        [84.75624847412115, 19.064861297607536],
        [84.75624847412115, 19.06458473205572],
        [84.75597381591791, 19.06458473205572],
        [84.75597381591791, 19.064027786254996],
        [84.75569152832054, 19.064029693603572],
        [84.75569152832054, 19.063751220703182],
        [84.75543212890642, 19.063751220703182],
        [84.75543212890642, 19.063470840454215],
        [84.75514221191412, 19.063474655151367],
        [84.75514221191412, 19.062639236450252],
        [84.75485992431658, 19.062641143798828],
        [84.75485992431658, 19.061531066894645],
        [84.7545928955081, 19.061531066894645],
        [84.7545928955081, 19.061252593994254],
        [84.75430297851562, 19.061252593994254],
        [84.75430297851562, 19.06097030639654],
        [84.75402832031278, 19.06097030639654],
        [84.75402832031278, 19.060693740844727],
        [84.75375366210943, 19.060693740844727],
        [84.75375366210943, 19.059860229492188],
        [84.75347137451183, 19.059860229492188],
        [84.75347137451183, 19.059581756591797],
        [84.75319671630865, 19.059583663940543],
        [84.75319671630865, 19.059305191039982],
        [84.75291442871088, 19.059305191039982],
        [84.75291442871088, 19.059028625488395],
        [84.75263977050804, 19.059032440185547],
        [84.75263977050804, 19.058748245239258],
        [84.75235748291033, 19.058748245239258],
        [84.75235748291033, 19.058469772338867],
        [84.75208282470709, 19.058471679687614],
        [84.75208282470709, 19.057916641235465],
        [84.75180816650408, 19.05791854858404],
        [84.75180816650408, 19.057638168335075],
        [84.75125122070312, 19.057638168335075],
        [84.75125122070312, 19.057081222534293],
        [84.75096893310575, 19.05708312988287],
        [84.75096893310575, 19.05680465698248],
        [84.75069427490234, 19.05680465698248],
        [84.75069427490234, 19.05624961853033],
        [84.75041961669933, 19.056259155273438],
        [84.75041961669933, 19.055419921875114],
        [84.75013732910162, 19.055419921875114],
        [84.75013732910162, 19.055139541625977],
        [84.74986267089838, 19.055139541625977],
        [84.74986267089838, 19.054861068725586],
        [84.74958038330084, 19.054861068725586],
        [84.74958038330084, 19.05430984497076],
        [84.74929809570307, 19.05430984497076],
        [84.74929809570307, 19.054029464721793],
        [84.74903106689459, 19.054029464721793],
        [84.74903106689459, 19.053750991821403],
        [84.74874877929705, 19.053750991821403],
        [84.74874877929705, 19.053199768066406],
        [84.74846649169928, 19.053199768066406],
        [84.74846649169928, 19.05291938781744],
        [84.74791717529325, 19.05291938781744],
        [84.74791717529325, 19.05264091491705],
        [84.74764251708984, 19.05264091491705],
        [84.74764251708984, 19.052360534668082],
        [84.74736022949247, 19.052360534668082],
        [84.74736022949247, 19.051811218261832],
        [84.74709320068365, 19.051811218261832],
        [84.74709320068365, 19.05125045776373],
        [84.74680328369152, 19.05125045776373],
        [84.74680328369152, 19.05070114135748],
        [84.74652862548834, 19.050689697265625],
        [84.74652862548834, 19.049581527710018],
        [84.74624633789057, 19.049581527710018],
        [84.74624633789057, 19.048471450805664],
        [84.74597167968773, 19.048471450805664],
        [84.74597167968773, 19.04819488525385],
        [84.74541473388678, 19.04819488525385],
        [84.74541473388678, 19.04791641235346],
        [84.74514007568376, 19.047920227050838],
        [84.74514007568376, 19.047641754150447],
        [84.74485778808622, 19.047641754150447],
        [84.74485778808622, 19.047359466552734],
        [84.74430847167997, 19.04736137390148],
        [84.74430847167997, 19.04708290100109],
        [84.74402618408209, 19.04708290100109],
        [84.74402618408209, 19.046806335449332],
        [84.7434692382813, 19.046810150146484],
        [84.7434692382813, 19.04624938964855],
        [84.74319458007807, 19.04624938964855],
        [84.74319458007807, 19.045415878295955],
        [84.74236297607428, 19.04542160034191],
        [84.74236297607428, 19.04458045959484],
        [84.74208068847673, 19.044584274291992],
        [84.74208068847673, 19.0443058013916],
        [84.74180603027372, 19.04432106018072],
        [84.74180603027372, 19.043750762939453],
        [84.74124908447294, 19.043750762939453],
        [84.74124908447294, 19.043470382690487],
        [84.74097442626959, 19.043472290039062],
        [84.74097442626959, 19.043193817138672],
        [84.74069213867199, 19.043193817138672],
        [84.74069213867199, 19.042917251586914],
        [84.7404174804688, 19.04291915893566],
        [84.7404174804688, 19.042079925537166],
        [84.74013519287104, 19.04208374023449],
        [84.74013519287104, 19.04125022888195],
        [84.7398605346682, 19.041259765625057],
        [84.7398605346682, 19.040420532226562],
        [84.73958587646501, 19.040420532226562],
        [84.73958587646501, 19.03985977172863],
        [84.73930358886724, 19.039861679077205],
        [84.73930358886724, 19.039306640625057],
        [84.73902893066423, 19.039306640625057],
        [84.73902893066423, 19.039028167724666],
        [84.73847198486328, 19.039030075073242],
        [84.73847198486328, 19.038749694824276],
        [84.73821258544933, 19.038749694824276],
        [84.7379150390625, 19.038749694824276],
        [84.7379150390625, 19.03847312927246],
        [84.73764038085949, 19.03847312927246],
        [84.73764038085949, 19.037914276123104],
        [84.73706817626964, 19.03791999816906],
        [84.73706817626964, 19.037639617919922],
        [84.73652648925798, 19.037639617919922],
        [84.73652648925798, 19.03736114501953],
        [84.73625183105474, 19.03736114501953],
        [84.73625183105474, 19.036809921264705],
        [84.7359695434572, 19.036809921264705],
        [84.7359695434572, 19.03652954101574],
        [84.73568725585943, 19.03652954101574],
        [84.73568725585943, 19.036251068115348],
        [84.73542022705078, 19.036251068115348],
        [84.73542022705078, 19.035690307617244],
        [84.73513793945341, 19.03569221496582],
        [84.73513793945341, 19.03513908386242],
        [84.73486328125, 19.03513908386242],
        [84.73486328125, 19.034860610962028],
        [84.73430633544928, 19.034860610962028],
        [84.73430633544928, 19.03402709960949],
        [84.73400878906278, 19.034032821655217],
        [84.73400878906278, 19.033470153808707],
        [84.73347473144548, 19.033470153808707],
        [84.73347473144548, 19.033193588256893],
        [84.73319244384788, 19.033193588256893],
        [84.73319244384788, 19.032917022705135],
        [84.7329177856447, 19.032922744751033],
        [84.7329177856447, 19.032642364502067],
        [84.73264312744169, 19.032642364502067],
        [84.73264312744169, 19.032358169555778],
        [84.73236083984392, 19.032360076904354],
        [84.73236083984392, 19.031248092651424],
        [84.73208618164091, 19.031248092651424],
        [84.73208618164091, 19.030969619751033],
        [84.73180389404297, 19.03097152709961],
        [84.73180389404297, 19.030694961547795],
        [84.73152923584013, 19.03070068359375],
        [84.73152923584013, 19.030418395996207],
        [84.7312622070313, 19.030418395996207],
        [84.7312622070313, 19.030139923095817],
        [84.730697631836, 19.030139923095817],
        [84.730697631836, 19.02985954284668],
        [84.73041534423822, 19.029861450195426],
        [84.73041534423822, 19.029306411743278],
        [84.73014068603538, 19.02932167053234],
        [84.73014068603538, 19.029029846191463],
        [84.72985839843767, 19.029029846191463],
        [84.72985839843767, 19.028749465942496],
        [84.7295761108399, 19.028749465942496],
        [84.7295761108399, 19.028470993042106],
        [84.72930908203142, 19.028472900390682],
        [84.72930908203142, 19.027917861938533],
        [84.72875213623047, 19.02791976928711],
        [84.72875213623047, 19.026809692382926],
        [84.72820281982428, 19.026809692382926],
        [84.72820281982428, 19.026260375976506],
        [84.72791290283214, 19.026260375976506],
        [84.72791290283214, 19.025970458984432],
        [84.72763824462896, 19.025972366333008],
        [84.72763824462896, 19.025695800781193],
        [84.72736358642572, 19.025695800781193],
        [84.72736358642572, 19.02513885498047],
        [84.72680664062517, 19.025140762329215],
        [84.72680664062517, 19.024860382080078],
        [84.72651672363304, 19.024860382080078],
        [84.72651672363304, 19.02458000183111],
        [84.72624969482439, 19.024585723877067],
        [84.72624969482439, 19.024303436279297],
        [84.72598266601591, 19.024311065673828],
        [84.72598266601591, 19.023750305175895],
        [84.72569274902344, 19.023750305175895],
        [84.72569274902344, 19.023460388183594],
        [84.72569274902344, 19.022916793823356],
        [84.72558593750028, 19.022918701171932],
        [84.7254180908206, 19.022920608520508],
        [84.7254180908206, 19.02264022827154],
        [84.72512817382812, 19.02264022827154],
        [84.72512817382812, 19.022359848022575],
        [84.72486114501964, 19.022363662719727],
        [84.72486114501964, 19.02180480957037],
        [84.7243041992187, 19.02180480957037],
        [84.7243041992187, 19.02152824401861],
        [84.72402954101585, 19.021530151367188],
        [84.72402954101585, 19.02069282531744],
        [84.72374725341814, 19.020694732666016],
        [84.72374725341814, 19.020416259765625],
        [84.7234725952149, 19.020420074463004],
        [84.7234725952149, 19.020259857177678],
        [84.7234725952149, 19.020139694213867],
        [84.72303009033203, 19.020139694213867],
        [84.72291564941435, 19.020139694213867],
        [84.72291564941435, 19.0198593139649],
        [84.72264099121094, 19.0198593139649],
        [84.72264099121094, 19.019311904907227],
        [84.72235870361357, 19.019311904907227],
        [84.72235870361357, 19.018749237060547],
        [84.7220687866211, 19.018749237060547],
        [84.7220687866211, 19.018470764160156],
        [84.72180175781278, 19.018472671508903],
        [84.72180175781278, 19.018194198608512],
        [84.72152709960943, 19.018194198608512],
        [84.72152709960943, 19.017639160156364],
        [84.7212524414062, 19.017639160156364],
        [84.7212524414062, 19.017360687255973],
        [84.72097015380865, 19.017360687255973],
        [84.72097015380865, 19.0168075561524],
        [84.72070312500017, 19.0168075561524],
        [84.72070312500017, 19.01652908325201],
        [84.72041320800804, 19.01652908325201],
        [84.72041320800804, 19.01625061035162],
        [84.71985626220709, 19.01625061035162],
        [84.71985626220709, 19.015970230102653],
        [84.71958160400408, 19.01597213745123],
        [84.71958160400408, 19.014583587646484],
        [84.71930694580107, 19.014583587646484],
        [84.71930694580107, 19.014305114746094],
        [84.71875000000028, 19.014320373535213],
        [84.71875000000028, 19.014030456543082],
        [84.71819305419933, 19.014030456543082],
        [84.71819305419933, 19.013750076293945],
        [84.71791839599615, 19.013750076293945],
        [84.71791839599615, 19.013200759887695],
        [84.71763610839838, 19.013200759887695],
        [84.71763610839838, 19.012639999389762],
        [84.71736145019554, 19.012639999389762],
        [84.71736145019554, 19.012359619140625],
        [84.71708679199236, 19.01236152648937],
        [84.71708679199236, 19.01208305358898],
        [84.71680450439459, 19.01208305358898],
        [84.71680450439459, 19.011806488037223],
        [84.71597290039062, 19.011810302734375],
        [84.71597290039062, 19.011249542236442],
        [84.71569824218778, 19.011249542236442],
        [84.71569061279325, 19.010690689086914],
        [84.7154159545899, 19.01069259643566],
        [84.7154159545899, 19.01041412353527],
        [84.71485900878912, 19.010419845581055],
        [84.71485900878912, 19.010139465332088],
        [84.71457672119135, 19.010139465332088],
        [84.71457672119135, 19.00958061218273],
        [84.71430206298851, 19.009584426879883],
        [84.71430206298851, 19.009304046630916],
        [84.71402740478533, 19.00930976867687],
        [84.71402740478533, 19.008470535278377],
        [84.71375274658209, 19.008470535278377],
        [84.71375274658209, 19.008192062377987],
        [84.71347045898455, 19.008192062377987],
        [84.71347045898455, 19.007917404174805],
        [84.713188171387, 19.007921218872127],
        [84.713188171387, 19.007642745971737],
        [84.7128982543947, 19.007642745971737],
        [84.7128982543947, 19.007360458374023],
        [84.71263885498075, 19.007360458374023],
        [84.71263885498075, 19.006811141967773],
        [84.71235656738281, 19.006811141967773],
        [84.71235656738281, 19.006532669067383],
        [84.7120819091798, 19.006532669067383],
        [84.7120819091798, 19.00597190856945],
        [84.71180725097662, 19.00597190856945],
        [84.71180725097662, 19.005695343017635],
        [84.71151733398449, 19.00570106506359],
        [84.71151733398449, 19.005420684814453],
        [84.71098327636736, 19.005420684814453],
        [84.71098327636736, 19.005151748657227],
        [84.71069335937506, 19.005151748657227],
        [84.71069335937506, 19.00485992431652],
        [84.7101364135745, 19.004861831665096],
        [84.7101364135745, 19.004028320312557],
        [84.7098617553711, 19.004028320312557],
        [84.7098617553711, 19.0034694671632],
        [84.70930480957031, 19.003471374511776],
        [84.70930480957031, 19.00319480895996],
        [84.7090301513673, 19.00319480895996],
        [84.7090301513673, 19.002639770507812],
        [84.70874786376959, 19.002639770507812],
        [84.70874786376959, 19.002090454101562],
        [84.70847320556635, 19.002090454101562],
        [84.70847320556635, 19.00152969360363],
        [84.7081909179688, 19.00152969360363],
        [84.7081909179688, 19.000419616699276],
        [84.7079162597658, 19.000419616699276],
        [84.7079162597658, 19.000141143798885],
        [84.7079162597658, 18.99986076354992],
        [84.7079162597658, 18.99958038330078],
        [84.70764160156256, 18.999584197998104],
        [84.70764160156256, 18.999305725097713],
        [84.70735931396501, 18.999309539794922],
        [84.70735931396501, 18.998750686645565],
        [84.707084655762, 18.998750686645565],
        [84.707084655762, 18.998472213745174],
        [84.70680236816423, 18.998472213745174],
        [84.70680236816423, 18.99819564819336],
        [84.70652770996122, 18.99819564819336],
        [84.70652770996122, 18.997638702392635],
        [84.70625305175781, 18.99764060974121],
        [84.70625305175781, 18.997360229492244],
        [84.70597076416044, 18.997360229492244],
        [84.70597076416044, 18.997079849243278],
        [84.70569610595709, 18.997085571289006],
        [84.70569610595709, 18.99652862548828],
        [84.70540618896496, 18.996530532837028],
        [84.70540618896496, 18.99625015258789],
        [84.7051391601563, 18.99625015258789],
        [84.7051391601563, 18.99596023559576],
        [84.7048645019533, 18.995973587036246],
        [84.7048645019533, 18.995416641235295],
        [84.7045822143557, 18.995420455932674],
        [84.7045822143557, 18.995140075683707],
        [84.70430755615251, 18.995140075683707],
        [84.70430755615251, 18.99485969543457],
        [84.70402526855474, 18.994863510131893],
        [84.70402526855474, 18.994304656982536],
        [84.70375061035173, 18.994304656982536],
        [84.70375061035173, 18.994028091430778],
        [84.70347595214872, 18.99403190612793],
        [84.70347595214872, 18.99346923828125],
        [84.70319366455078, 18.993471145629996],
        [84.70319366455078, 18.993194580078182],
        [84.70291900634794, 18.993194580078182],
        [84.70291900634794, 18.992639541626033],
        [84.70263671875006, 18.992639541626033],
        [84.70263671875006, 18.992359161377067],
        [84.70236206054699, 18.992359161377067],
        [84.70236206054699, 18.992080688476676],
        [84.70207977294928, 18.992082595825252],
        [84.70207977294928, 18.991527557373104],
        [84.7018127441408, 18.991531372070426],
        [84.7018127441408, 18.991249084472713],
        [84.7015304565432, 18.991249084472713],
        [84.7015304565432, 18.990970611572322],
        [84.70124816894548, 18.9909725189209],
        [84.70124816894548, 18.99041748046875],
        [84.70095825195335, 18.99041748046875],
        [84.70095825195335, 18.990148544311637],
        [84.7006912231447, 18.990148544311637],
        [84.7006912231447, 18.98986053466797],
        [84.70041656494169, 18.98986053466797],
        [84.70041656494169, 18.989580154419002],
        [84.70014190673828, 18.989583969116325],
        [84.70014190673828, 18.989028930664176],
        [84.69985961914091, 18.989030838012752],
        [84.69985961914091, 18.988750457763786],
        [84.69957733154297, 18.988750457763786],
        [84.69957733154297, 18.98847007751465],
        [84.69930267333996, 18.988471984863395],
        [84.69930267333996, 18.98819541931158],
        [84.69902801513678, 18.988189697265682],
        [84.69902801513678, 18.987918853759822],
        [84.69875335693354, 18.9879207611084],
        [84.69875335693354, 18.987360000610465],
        [84.69847106933616, 18.98736190795904],
        [84.69847106933616, 18.98708343505865],
        [84.69819641113298, 18.98708343505865],
        [84.69819641113298, 18.986806869506893],
        [84.69789886474632, 18.986810684204215],
        [84.69789886474632, 18.986530303955078],
        [84.6976394653322, 18.986530303955078],
        [84.6976394653322, 18.985969543457145],
        [84.69736480712919, 18.98597145080572],
        [84.69736480712919, 18.98569679260254],
        [84.69708251953125, 18.98569679260254],
        [84.69708251953125, 18.985416412353516],
        [84.69680786132841, 18.985420227050895],
        [84.69680786132841, 18.985139846801758],
        [84.69653320312506, 18.985139846801758],
        [84.69653320312506, 18.9845809936524],
        [84.69625091552746, 18.984584808349553],
        [84.69625091552746, 18.984306335449162],
        [84.69596862792974, 18.98431015014654],
        [84.69596862792974, 18.98402023315424],
        [84.6956939697265, 18.984027862548828],
        [84.6956939697265, 18.983749389648438],
        [84.69541931152366, 18.983749389648438],
        [84.69541931152366, 18.98319053649908],
        [84.69513702392595, 18.983192443847656],
        [84.69513702392595, 18.98291587829584],
        [84.69486236572271, 18.98291969299322],
        [84.69486236572271, 18.98264122009283],
        [84.69458007812517, 18.98264122009283],
        [84.69458007812517, 18.982080459594727],
        [84.69430541992216, 18.982082366943473],
        [84.69430541992216, 18.98180580139166],
        [84.69403076171875, 18.981809616088867],
        [84.69403076171875, 18.981531143188477],
        [84.69374847412138, 18.981531143188477],
        [84.69374847412138, 18.981252670288086],
        [84.69346618652344, 18.981252670288086],
        [84.69346618652344, 18.980691909790153],
        [84.6931915283206, 18.98069381713873],
        [84.6931915283206, 18.98041725158697],
        [84.69291687011724, 18.980421066284293],
        [84.69291687011724, 18.980142593383903],
        [84.692642211914, 18.980142593383903],
        [84.692642211914, 18.97986030578619],
        [84.69235992431646, 18.97986030578619],
        [84.69235992431646, 18.979312896728516],
        [84.69209289550798, 18.979312896728516],
        [84.69209289550798, 18.97903251647955],
        [84.69180297851585, 18.97903251647955],
        [84.69180297851585, 18.97874832153326],
        [84.69152832031267, 18.97874832153326],
        [84.69152832031267, 18.97821044921875],
        [84.6912460327149, 18.97821044921875],
        [84.6912460327149, 18.97792053222662],
        [84.69097137451189, 18.97792053222662],
        [84.69097137451189, 18.977638244628906],
        [84.69069671630888, 18.977638244628906],
        [84.69069671630888, 18.97707939147955],
        [84.69041442871094, 18.97708320617687],
        [84.69041442871094, 18.97680473327648],
        [84.6901397705081, 18.97680473327648],
        [84.6901397705081, 18.976249694824332],
        [84.68985748291021, 18.976249694824332],
        [84.68985748291021, 18.975971221923942],
        [84.68958282470714, 18.975973129272518],
        [84.68958282470714, 18.975416183471737],
        [84.68930816650396, 18.97541809082037],
        [84.68930816650396, 18.97513961791998],
        [84.68901824951166, 18.97513961791998],
        [84.68901824951166, 18.974849700927848],
        [84.68875122070335, 18.974861145019588],
        [84.68875122070335, 18.974306106567383],
        [84.68846893310564, 18.974309921264762],
        [84.68846893310564, 18.973751068115234],
        [84.68818664550787, 18.973751068115234],
        [84.68818664550787, 18.9731903076173],
        [84.68791961669939, 18.973194122314453],
        [84.68791961669939, 18.972917556762695],
        [84.68763732910185, 18.972919464111442],
        [84.68763732910185, 18.972360610961914],
        [84.68736267089844, 18.972360610961914],
        [84.68736267089844, 18.97264099121105],
        [84.68708038330107, 18.97264099121105],
        [84.68708038330107, 18.972360610961914],
        [84.68680572509771, 18.972360610961914],
        [84.68680572509771, 18.97180557250971],
        [84.68653106689464, 18.97180557250971],
        [84.68653106689464, 18.972085952758903],
        [84.6859741210937, 18.972085952758903],
        [84.6859741210937, 18.97180557250971],
        [84.68569183349632, 18.971809387207088],
        [84.68569183349632, 18.971530914306697],
        [84.68541717529314, 18.971530914306697],
        [84.68541717529314, 18.970970153808594],
        [84.68486022949236, 18.97097206115734],
        [84.68486022949236, 18.970695495605526],
        [84.68458557128935, 18.970695495605526],
        [84.68458557128935, 18.970417022705192],
        [84.6843032836914, 18.970420837402344],
        [84.6843032836914, 18.970140457153377],
        [84.68402862548857, 18.970140457153377],
        [84.68402862548857, 18.96986007690441],
        [84.68374633789062, 18.969863891601562],
        [84.68374633789062, 18.96958160400402],
        [84.68347167968761, 18.96958160400402],
        [84.68347167968761, 18.96930313110363],
        [84.68319702148443, 18.96930313110363],
        [84.68319702148443, 18.96958160400402],
        [84.674858093262, 18.96958160400402],
        [84.674858093262, 18.969863891601562],
        [84.67401885986328, 18.96986007690441],
        [84.67401885986328, 18.970140457153377],
        [84.67318725585932, 18.970140457153377],
        [84.67318725585932, 18.970420837402344],
        [84.6726379394533, 18.970417022705192],
        [84.6726379394533, 18.970695495605526],
        [84.67236328125006, 18.970695495605526],
        [84.67236328125006, 18.970417022705192],
        [84.67041778564459, 18.970420837402344],
        [84.67041778564459, 18.970140457153377],
        [84.66986083984398, 18.970140457153377],
        [84.66986083984398, 18.96986007690441],
        [84.6695861816408, 18.969863891601562],
        [84.6695861816408, 18.96958160400402],
        [84.66874694824247, 18.96958160400402],
        [84.66874694824247, 18.96930313110363],
        [84.66847229003906, 18.96931076049816],
        [84.66847229003906, 18.96903991699213],
        [84.66792297363287, 18.96903991699213],
        [84.66792297363287, 18.968751907348633],
        [84.66764068603527, 18.968751907348633],
        [84.66764068603527, 18.968200683593807],
        [84.66735839843756, 18.968200683593807],
        [84.66735839843756, 18.96792030334484],
        [84.66707611083996, 18.96792030334484],
        [84.66707611083996, 18.96764183044445],
        [84.66680908203148, 18.96764183044445],
        [84.66680908203148, 18.967359542846737],
        [84.66652679443376, 18.96736335754406],
        [84.66652679443376, 18.967081069946346],
        [84.66625213623053, 18.967081069946346],
        [84.66625213623053, 18.966806411743107],
        [84.66596984863298, 18.966810226440487],
        [84.66596984863298, 18.966531753540096],
        [84.6654129028322, 18.966531753540096],
        [84.6654129028322, 18.966249465942383],
        [84.66513824462919, 18.966249465942383],
        [84.66513824462919, 18.96598052978527],
        [84.66484832763672, 18.96598052978527],
        [84.66484832763672, 18.965690612793026],
        [84.66458129882841, 18.965694427490348],
        [84.66458129882841, 18.964305877685604],
        [84.66430664062506, 18.96431159973156],
        [84.66430664062506, 18.963750839233455],
        [84.66401672363293, 18.963750839233455],
        [84.66401672363293, 18.961811065673885],
        [84.66374969482428, 18.961811065673885],
        [84.66374969482428, 18.96153068542492],
        [84.66401672363293, 18.96153068542492],
        [84.66401672363293, 18.96125030517578],
        [84.66458129882841, 18.96125030517578],
        [84.66458129882841, 18.96153068542492],
        [84.66484832763672, 18.96153068542492],
        [84.66484832763672, 18.961811065673885],
        [84.6657028198245, 18.961811065673885],
        [84.6657028198245, 18.96125030517578],
        [84.66596984863298, 18.96125030517578],
        [84.66596984863298, 18.95792007446289],
        [84.66625213623053, 18.95792007446289],
        [84.66625213623053, 18.957639694213924],
        [84.66652679443376, 18.957639694213924],
        [84.66652679443376, 18.957361221313533],
        [84.66735839843756, 18.957361221313533],
        [84.66735839843756, 18.957080841064567],
        [84.66791534423828, 18.95708465576172],
        [84.66791534423828, 18.957361221313533],
        [84.66874694824247, 18.957361221313533],
        [84.66874694824247, 18.957639694213924],
        [84.66929626464866, 18.957639694213924],
        [84.66929626464866, 18.95792007446289],
        [84.6695861816408, 18.957916259765682],
        [84.6695861816408, 18.958196640014705],
        [84.66986083984398, 18.958196640014705],
        [84.66986083984398, 18.958471298217887],
        [84.67013549804682, 18.958471298217887],
        [84.67013549804682, 18.958749771118278],
        [84.67041778564459, 18.958749771118278],
        [84.67041778564459, 18.959030151367244],
        [84.67069244384777, 18.95902824401867],
        [84.67069244384777, 18.95930671691906],
        [84.67097473144537, 18.95930671691906],
        [84.67097473144537, 18.959583282470817],
        [84.67124938964872, 18.959583282470817],
        [84.67124938964872, 18.959861755371207],
        [84.6715164184572, 18.959850311279297],
        [84.6715164184572, 18.960140228271598],
        [84.6718063354495, 18.960140228271598],
        [84.6718063354495, 18.960420608520565],
        [84.67208099365251, 18.960420608520565],
        [84.67208099365251, 18.96070098876953],
        [84.67236328125006, 18.960695266723576],
        [84.67236328125006, 18.96097183227539],
        [84.6726379394533, 18.960969924926815],
        [84.6726379394533, 18.96125030517578],
        [84.67292022705101, 18.96125030517578],
        [84.67292022705101, 18.961811065673885],
        [84.67346954345709, 18.96180534362793],
        [84.67346954345709, 18.96208381652832],
        [84.6737518310548, 18.96208381652832],
        [84.6737518310548, 18.963193893432674],
        [84.67402648925787, 18.963193893432674],
        [84.67402648925787, 18.963472366333065],
        [84.67430877685575, 18.96347045898449],
        [84.67430877685575, 18.963750839233455],
        [84.67457580566406, 18.963750839233455],
        [84.67457580566406, 18.964029312133846],
        [84.67542266845732, 18.964029312133846],
        [84.67542266845732, 18.96431159973156],
        [84.67569732666033, 18.964305877685604],
        [84.67569732666033, 18.96458244323742],
        [84.67597198486351, 18.964580535888672],
        [84.67597198486351, 18.965421676635742],
        [84.67624664306635, 18.965415954589787],
        [84.67624664306635, 18.965694427490348],
        [84.67652893066412, 18.965690612793026],
        [84.67652893066412, 18.96598052978527],
        [84.67678833007841, 18.96598052978527],
        [84.67678833007841, 18.966249465942383],
        [84.67707824707037, 18.966249465942383],
        [84.67707824707037, 18.966531753540096],
        [84.67764282226562, 18.966531753540096],
        [84.67764282226562, 18.966810226440487],
        [84.67791748046903, 18.966806411743107],
        [84.67791748046903, 18.967081069946346],
        [84.67819213867205, 18.967081069946346],
        [84.67819213867205, 18.96736335754406],
        [84.67930603027361, 18.967359542846737],
        [84.67930603027361, 18.96764183044445],
        [84.67986297607416, 18.96764183044445],
        [84.67986297607416, 18.96792030334484],
        [84.68015289306646, 18.96792030334484],
        [84.68015289306646, 18.968200683593807],
        [84.67986297607416, 18.968193054199276],
        [84.67986297607416, 18.968471527099666],
        [84.6801376342774, 18.968471527099666],
        [84.6801376342774, 18.968751907348633],
        [84.68180847167986, 18.968751907348633],
        [84.68180847167986, 18.96846961975109],
        [84.68208312988287, 18.968471527099666],
        [84.68208312988287, 18.968193054199276],
        [84.68264007568382, 18.968200683593807],
        [84.68264007568382, 18.96792030334484],
        [84.6829223632812, 18.96792030334484],
        [84.6829223632812, 18.96764183044445],
        [84.68321228027366, 18.96764183044445],
        [84.68321228027366, 18.966810226440487],
        [84.6829223632812, 18.966810226440487],
        [84.6829223632812, 18.966531753540096],
        [84.68264007568382, 18.966531753540096],
        [84.68264007568382, 18.966249465942383],
        [84.68235778808611, 18.966249465942383],
        [84.68235778808611, 18.96598052978527],
        [84.68208312988287, 18.96598052978527],
        [84.68208312988287, 18.965690612793026],
        [84.68180847167986, 18.965694427490348],
        [84.68180847167986, 18.965415954589787],
        [84.68152618408232, 18.965421676635742],
        [84.68152618408232, 18.9651393890382],
        [84.6812515258789, 18.9651393890382],
        [84.6812515258789, 18.96486091613781],
        [84.68096923828153, 18.96486091613781],
        [84.68096923828153, 18.964580535888672],
        [84.68069458007812, 18.96458244323742],
        [84.68069458007812, 18.964305877685604],
        [84.68041992187511, 18.96431159973156],
        [84.68041992187511, 18.964029312133846],
        [84.68015289306646, 18.964029312133846],
        [84.68015289306646, 18.963750839233455],
        [84.67986297607416, 18.963750839233455],
        [84.67986297607416, 18.96347045898449],
        [84.67958068847662, 18.963472366333065],
        [84.67958068847662, 18.963193893432674],
        [84.67930603027361, 18.963193893432674],
        [84.67930603027361, 18.962917327880916],
        [84.67903137207037, 18.962917327880916],
        [84.67903137207037, 18.962638854980526],
        [84.67874908447283, 18.9626407623291],
        [84.67874908447283, 18.96208000183117],
        [84.67847442626982, 18.96208381652832],
        [84.67847442626982, 18.96180534362793],
        [84.67819213867205, 18.961811065673885],
        [84.67819213867205, 18.96153068542492],
        [84.67791748046903, 18.96153068542492],
        [84.67791748046903, 18.96125030517578],
        [84.6776351928711, 18.96125030517578],
        [84.6776351928711, 18.96097183227539],
        [84.67736053466825, 18.96097183227539],
        [84.67736053466825, 18.960695266723576],
        [84.67707824707037, 18.96070098876953],
        [84.67707824707037, 18.960420608520565],
        [84.67678833007841, 18.960420608520565],
        [84.67678833007841, 18.960140228271598],
        [84.67652893066412, 18.960140228271598],
        [84.67652893066412, 18.959850311279297],
        [84.67624664306635, 18.959861755371207],
        [84.67624664306635, 18.959583282470817],
        [84.67597198486351, 18.959583282470817],
        [84.67597198486351, 18.95930671691906],
        [84.67569732666033, 18.95930671691906],
        [84.67569732666033, 18.95902824401867],
        [84.67514038085955, 18.959030151367244],
        [84.67514038085955, 18.958749771118278],
        [84.67457580566406, 18.958749771118278],
        [84.67457580566406, 18.95846939086914],
        [84.67430877685575, 18.958471298217887],
        [84.67430877685575, 18.958196640014705],
        [84.67402648925787, 18.958196640014705],
        [84.67402648925787, 18.957639694213924],
        [84.6737518310548, 18.957639694213924],
        [84.6737518310548, 18.957080841064567],
        [84.67346954345709, 18.95708465576172],
        [84.67346954345709, 18.956806182861328],
        [84.67318725585932, 18.956809997558707],
        [84.67318725585932, 18.95625114440918],
        [84.67208099365251, 18.95625114440918],
        [84.67208099365251, 18.955970764160213],
        [84.67152404785156, 18.955974578857365],
        [84.67152404785156, 18.955692291259822],
        [84.67124938964872, 18.955692291259822],
        [84.67124938964872, 18.95541763305664],
        [84.6709823608399, 18.955419540405387],
        [84.6709823608399, 18.95486068725586],
        [84.67069244384777, 18.95486068725586],
        [84.67069244384777, 18.954309463501033],
        [84.67041778564459, 18.954309463501033],
        [84.67041778564459, 18.953752517700252],
        [84.67013549804682, 18.953752517700252],
        [84.67013549804682, 18.953193664550895],
        [84.66986083984398, 18.953193664550895],
        [84.66986083984398, 18.952638626098746],
        [84.6695861816408, 18.9526424407959],
        [84.6695861816408, 18.95207977294922],
        [84.66930389404303, 18.95208358764654],
        [84.66930389404303, 18.951528549194393],
        [84.66902923584001, 18.951528549194393],
        [84.66902923584001, 18.951248168945426],
        [84.66874694824247, 18.951248168945426],
        [84.66874694824247, 18.950969696045036],
        [84.66847229003906, 18.95097160339361],
        [84.66847229003906, 18.950695037841797],
        [84.66792297363287, 18.950700759887752],
        [84.66792297363287, 18.95041847229004],
        [84.66764068603527, 18.95041847229004],
        [84.66764068603527, 18.950138092041072],
        [84.66735839843756, 18.950138092041072],
        [84.66735839843756, 18.949859619140682],
        [84.66708374023432, 18.949861526489258],
        [84.66708374023432, 18.949583053588867],
        [84.66680908203148, 18.949583053588867],
        [84.66680908203148, 18.949306488037053],
        [84.66625213623053, 18.949310302734432],
        [84.66625213623053, 18.949029922485465],
        [84.66596984863298, 18.949029922485465],
        [84.66596984863298, 18.948749542236328],
        [84.66569519042997, 18.948749542236328],
        [84.66569519042997, 18.948472976684684],
        [84.6654129028322, 18.948472976684684],
        [84.6654129028322, 18.948194503784293],
        [84.66513824462919, 18.948194503784293],
        [84.66513824462919, 18.947917938232536],
        [84.66458129882841, 18.947917938232536],
        [84.66458129882841, 18.947639465332145],
        [84.66430664062506, 18.947639465332145],
        [84.66430664062506, 18.947360992431754],
        [84.66401672363293, 18.947360992431754],
        [84.66401672363293, 18.947080612182617],
        [84.66374969482428, 18.947082519531364],
        [84.66374969482428, 18.946807861328182],
        [84.6634826660158, 18.946809768676758],
        [84.6634826660158, 18.94652938842779],
        [84.66291809082048, 18.94652938842779],
        [84.66291809082048, 18.9462509155274],
        [84.66263580322271, 18.9462509155274],
        [84.66263580322271, 18.945970535278434],
        [84.6623611450197, 18.94597244262701],
        [84.6623611450197, 18.945695877075195],
        [84.66208648681669, 18.945695877075195],
        [84.66208648681669, 18.94541740417486],
        [84.66178894042986, 18.945421218872184],
        [84.66178894042986, 18.945140838623047],
        [84.66124725341803, 18.945140838623047],
        [84.66124725341803, 18.94485282897955],
        [84.66097259521496, 18.94486045837408],
        [84.66097259521496, 18.94458580017084],
        [84.66069793701178, 18.94458580017084],
        [84.66069793701178, 18.944305419921875],
        [84.66042327880876, 18.94431114196783],
        [84.66042327880876, 18.944030761718864],
        [84.66014099121116, 18.944030761718864],
        [84.66014099121116, 18.943750381469727],
        [84.65985870361345, 18.943750381469727],
        [84.65985870361345, 18.94347000122076],
        [84.65958404541021, 18.943473815918082],
        [84.65958404541021, 18.94319534301752],
        [84.65930175781267, 18.943201065063477],
        [84.65930175781267, 18.94292068481451],
        [84.65875244140625, 18.94292068481451],
        [84.65875244140625, 18.942640304565543],
        [84.65847015380888, 18.942640304565543],
        [84.65847015380888, 18.942359924316406],
        [84.65819549560553, 18.94236373901373],
        [84.65819549560553, 18.942081451416016],
        [84.65791320800793, 18.942081451416016],
        [84.65791320800793, 18.94180488586437],
        [84.65763854980474, 18.941810607910156],
        [84.65763854980474, 18.94153022766119],
        [84.65735626220697, 18.94153022766119],
        [84.65735626220697, 18.941249847412223],
        [84.65708160400413, 18.941249847412223],
        [84.65708160400413, 18.940969467163086],
        [84.65680694580095, 18.940971374511832],
        [84.65680694580095, 18.940694808960018],
        [84.65652465820318, 18.940694808960018],
        [84.65652465820318, 18.940416336059627],
        [84.65598297119169, 18.940420150756836],
        [84.65598297119169, 18.940141677856445],
        [84.65569305419922, 18.940141677856445],
        [84.65569305419922, 18.939859390258903],
        [84.65541839599638, 18.939859390258903],
        [84.65541839599638, 18.939582824707088],
        [84.65513610839844, 18.939582824707088],
        [84.65513610839844, 18.939306259155273],
        [84.65486145019543, 18.939310073852653],
        [84.65486145019543, 18.939031600952262],
        [84.65458679199224, 18.939031600952262],
        [84.65458679199224, 18.93874931335455],
        [84.65429687500011, 18.93874931335455],
        [84.65429687500011, 18.93847084045416],
        [84.65402984619163, 18.938472747802734],
        [84.65402984619163, 18.938194274902344],
        [84.65374755859392, 18.938194274902344],
        [84.65374755859392, 18.937915802001953],
        [84.65347290039068, 18.93792152404791],
        [84.65347290039068, 18.937639236450195],
        [84.65290832519537, 18.937639236450195],
        [84.65290832519537, 18.937360763549805],
        [84.65264129638672, 18.937360763549805],
        [84.65264129638672, 18.937080383300838],
        [84.65235900878935, 18.93708419799816],
        [84.65235900878935, 18.9368057250976],
        [84.6520767211914, 18.936807632446403],
        [84.6520767211914, 18.936529159546012],
        [84.65180206298857, 18.936529159546012],
        [84.65180206298857, 18.93625068664562],
        [84.65152740478521, 18.93625068664562],
        [84.65152740478521, 18.935970306396484],
        [84.65125274658197, 18.93597221374523],
        [84.65125274658197, 18.93569374084484],
        [84.65097045898443, 18.93569374084484],
        [84.65097045898443, 18.935417175293082],
        [84.65069580078142, 18.93541908264166],
        [84.65069580078142, 18.935140609741268],
        [84.65040588378929, 18.935140609741268],
        [84.65040588378929, 18.9348602294923],
        [84.65013885498064, 18.9348602294923],
        [84.65013885498064, 18.934579849243164],
        [84.64958190917986, 18.934583663940487],
        [84.64958190917986, 18.934305191040096],
        [84.64930725097685, 18.93431091308605],
        [84.64930725097685, 18.934030532836914],
        [84.64901733398438, 18.934030532836914],
        [84.64901733398438, 18.933750152587947],
        [84.64875030517607, 18.933750152587947],
        [84.64875030517607, 18.93346977233898],
        [84.64847564697271, 18.933471679687557],
        [84.64847564697271, 18.933195114135742],
        [84.64819335937511, 18.933200836181697],
        [84.64819335937511, 18.932910919189453],
        [84.64791870117193, 18.93291664123535],
        [84.64791870117193, 18.932638168335018],
        [84.64763641357416, 18.932640075683594],
        [84.64763641357416, 18.932359695434627],
        [84.64736175537132, 18.932361602783203],
        [84.64736175537132, 18.932083129882812],
        [84.64708709716814, 18.932083129882812],
        [84.64708709716814, 18.931806564330998],
        [84.64680480957037, 18.931806564330998],
        [84.64680480957037, 18.931528091430664],
        [84.64653015136736, 18.93152999877941],
        [84.64653015136736, 18.931249618530273],
        [84.6459732055664, 18.931249618530273],
        [84.6459732055664, 18.930971145629883],
        [84.64569091796903, 18.93097305297863],
        [84.64569091796903, 18.93069458007824],
        [84.64541625976568, 18.93069458007824],
        [84.64541625976568, 18.9304141998291],
        [84.64514160156261, 18.930419921875057],
        [84.64514160156261, 18.93013954162609],
        [84.6448593139649, 18.93013954162609],
        [84.6448593139649, 18.929870605468807],
        [84.64457702636713, 18.929870605468807],
        [84.64457702636713, 18.929580688476562],
        [84.64430236816429, 18.929584503173885],
        [84.64430236816429, 18.929306030273494],
        [84.64404296875017, 18.929309844970703],
        [84.64404296875017, 18.929029464721737],
        [84.64375305175787, 18.929029464721737],
        [84.64375305175787, 18.928752899169922],
        [84.64347076416033, 18.928752899169922],
        [84.64347076416033, 18.92847061157238],
        [84.64319610595732, 18.92847061157238],
        [84.64319610595732, 18.92819213867199],
        [84.64291381835938, 18.92819213867199],
        [84.64291381835938, 18.927917480468807],
        [84.64263916015653, 18.927919387817383],
        [84.64263916015653, 18.92764282226574],
        [84.64208221435558, 18.92764282226574],
        [84.64208221435558, 18.927360534668026],
        [84.6418075561524, 18.927360534668026],
        [84.6418075561524, 18.92708015441906],
        [84.64152526855463, 18.927082061767635],
        [84.64152526855463, 18.92680549621582],
        [84.64125061035179, 18.926811218261776],
        [84.64125061035179, 18.926532745361385],
        [84.64096832275408, 18.926532745361385],
        [84.64096832275408, 18.92625236511242],
        [84.64068603515648, 18.92625236511242],
        [84.64068603515648, 18.925970077514705],
        [84.64041900634783, 18.925970077514705],
        [84.64041900634783, 18.925695419311467],
        [84.64013671875028, 18.92568969726574],
        [84.64013671875028, 18.925416946411076],
        [84.63986206054688, 18.92542266845703],
        [84.63986206054688, 18.925251007080192],
        [84.63986206054688, 18.925138473510742],
        [84.6395797729495, 18.925138473510742],
        [84.6395797729495, 18.92485809326172],
        [84.63874816894537, 18.924861907959098],
        [84.63874816894537, 18.924583435058707],
        [84.63847351074213, 18.924583435058707],
        [84.63847351074213, 18.924304962158317],
        [84.63819122314459, 18.9243106842041],
        [84.63819122314459, 18.92402839660656],
        [84.63790893554682, 18.92402839660656],
        [84.63790893554682, 18.92346954345703],
        [84.63764190673834, 18.923471450805778],
        [84.63764190673834, 18.923194885253963],
        [84.6373596191408, 18.923194885253963],
        [84.6373596191408, 18.922916412353572],
        [84.63707733154303, 18.922918319702205],
        [84.63707733154303, 18.922639846801815],
        [84.63680267334001, 18.922639846801815],
        [84.63680267334001, 18.922359466552848],
        [84.636528015137, 18.922361373901424],
        [84.636528015137, 18.922082901001033],
        [84.6362533569336, 18.922082901001033],
        [84.6362533569336, 18.92180633544922],
        [84.63597106933622, 18.92180824279785],
        [84.63597106933622, 18.92152976989746],
        [84.63569641113287, 18.92152976989746],
        [84.63569641113287, 18.921249389648494],
        [84.63540649414074, 18.921249389648494],
        [84.63540649414074, 18.920970916748104],
        [84.63513946533209, 18.92097282409668],
        [84.63513946533209, 18.92069435119629],
        [84.63458251953148, 18.92069435119629],
        [84.63458251953148, 18.92041778564453],
        [84.6343078613283, 18.920419692993278],
        [84.6343078613283, 18.920141220092887],
        [84.63403320312528, 18.920141220092887],
        [84.63403320312528, 18.91986083984375],
        [84.63375091552751, 18.91986083984375],
        [84.63375091552751, 18.919580459594783],
        [84.63346862792997, 18.919580459594783],
        [84.63346862792997, 18.91986083984375],
        [84.63318634033203, 18.91986083984375],
        [84.63318634033203, 18.919580459594783],
        [84.63291931152372, 18.919584274292106],
        [84.63291931152372, 18.918750762939567],
        [84.63263702392584, 18.918750762939567],
        [84.63263702392584, 18.918190002441463],
        [84.63236236572277, 18.91819572448736],
        [84.63236236572277, 18.917917251587028],
        [84.63208007812506, 18.917930603027287],
        [84.63208007812506, 18.917640686035213],
        [84.63181304931658, 18.917640686035213],
        [84.63181304931658, 18.91707992553711],
        [84.63153076171898, 18.917085647583008],
        [84.63153076171898, 18.916803359985465],
        [84.63124847412126, 18.916810989379996],
        [84.63124847412126, 18.91653060913086],
        [84.6309661865235, 18.91653060913086],
        [84.6309661865235, 18.916250228881893],
        [84.63014221191406, 18.916250228881893],
        [84.63014221191406, 18.915969848632926],
        [84.62958526611334, 18.915973663330078],
        [84.62958526611334, 18.915695190429688],
        [84.62902832031256, 18.915700912475643],
        [84.62902832031256, 18.915420532226676],
        [84.62874603271479, 18.915420532226676],
        [84.62874603271479, 18.91514015197754],
        [84.62819671630876, 18.91514015197754],
        [84.62819671630876, 18.914871215820256],
        [84.62790679931663, 18.914871215820256],
        [84.62790679931663, 18.914579391479606],
        [84.62763977050798, 18.914581298828182],
        [84.62763977050798, 18.914304733276367],
        [84.62735748291044, 18.914304733276367],
        [84.62735748291044, 18.91402816772461],
        [84.62708282470703, 18.914030075073356],
        [84.62708282470703, 18.91374969482422],
        [84.62680816650419, 18.91374969482422],
        [84.62680816650419, 18.913469314575252],
        [84.62652587890625, 18.913471221923828],
        [84.62652587890625, 18.912916183471623],
        [84.62625122070324, 18.912921905517578],
        [84.62625122070324, 18.91264152526861],
        [84.62596893310553, 18.91264152526861],
        [84.62596893310553, 18.912080764770508],
        [84.62569427490229, 18.912082672119254],
        [84.62569427490229, 18.91180610656744],
        [84.62541961669945, 18.91180610656744],
        [84.62541961669945, 18.911527633667106],
        [84.62513732910173, 18.911531448364258],
        [84.62513732910173, 18.911249160766715],
        [84.6248626708985, 18.911249160766715],
        [84.6248626708985, 18.910970687866325],
        [84.62458038330095, 18.9109725952149],
        [84.62458038330095, 18.91069412231451],
        [84.62374877929716, 18.91069412231451],
        [84.62374877929716, 18.910417556762752],
        [84.62346649169922, 18.910417556762752],
        [84.62346649169922, 18.91013908386236],
        [84.62319183349638, 18.91013908386236],
        [84.62319183349638, 18.909580230713004],
        [84.62291717529303, 18.909584045410156],
        [84.62291717529303, 18.909305572509766],
        [84.62264251708979, 18.9093074798584],
        [84.62264251708979, 18.909030914306754],
        [84.62236022949224, 18.909030914306754],
        [84.62236022949224, 18.908740997314453],
        [84.62180328369163, 18.908750534057617],
        [84.62180328369163, 18.908472061157227],
        [84.62152862548845, 18.908472061157227],
        [84.62152862548845, 18.908193588256836],
        [84.62124633789068, 18.908193588256836],
        [84.62124633789068, 18.907918930053825],
        [84.62097167968767, 18.9079208374024],
        [84.62097167968767, 18.907360076904297],
        [84.62069702148466, 18.907361984253043],
        [84.62069702148466, 18.907083511352653],
        [84.62041473388672, 18.907083511352653],
        [84.62041473388672, 18.906806945800838],
        [84.62014007568388, 18.906810760498047],
        [84.62014007568388, 18.90653038024908],
        [84.61987304687506, 18.90653038024908],
        [84.61987304687506, 18.906250000000114],
        [84.61958312988293, 18.906250000000114],
        [84.61958312988293, 18.90596961975092],
        [84.61930847167974, 18.905971527099723],
        [84.61930847167974, 18.905696868896484],
        [84.61902618408197, 18.905700683593864],
        [84.61902618408197, 18.905420303344727],
        [84.61875152587913, 18.905420303344727],
        [84.61875152587913, 18.90513992309576],
        [84.61846923828142, 18.90513992309576],
        [84.61846923828142, 18.904859542846793],
        [84.61819458007818, 18.90486145019537],
        [84.61819458007818, 18.904584884643555],
        [84.61791992187517, 18.904584884643555],
        [84.61791992187517, 18.904306411743164],
        [84.61763763427763, 18.904310226440543],
        [84.61763763427763, 18.904029846191406],
        [84.61708068847685, 18.904029846191406],
        [84.61708068847685, 18.90374946594244],
        [84.61653137207043, 18.90374946594244],
        [84.61653137207043, 18.903472900390625],
        [84.61624908447271, 18.9034748077392],
        [84.61624908447271, 18.90319252014166],
        [84.6156921386721, 18.90319252014166],
        [84.6156921386721, 18.902914047241268],
        [84.61541748046892, 18.902931213378906],
        [84.61541748046892, 18.902639389038086],
        [84.61513519287115, 18.902639389038086],
        [84.61513519287115, 18.902362823486442],
        [84.61458587646513, 18.902362823486442],
        [84.61458587646513, 18.90208053588873],
        [84.61430358886719, 18.90208435058605],
        [84.61430358886719, 18.90180587768549],
        [84.61402893066435, 18.90180969238287],
        [84.61402893066435, 18.90153121948248],
        [84.61373901367188, 18.90153121948248],
        [84.61373901367188, 18.901252746582088],
        [84.6134719848634, 18.901252746582088],
        [84.6134719848634, 18.900970458984375],
        [84.61319732666021, 18.900970458984375],
        [84.61319732666021, 18.90069389343273],
        [84.61291503906244, 18.90069389343273],
        [84.61291503906244, 18.900417327880916],
        [84.6126403808596, 18.900421142578125],
        [84.6126403808596, 18.900142669677734],
        [84.61235809326189, 18.900142669677734],
        [84.61235809326189, 18.899860382080192],
        [84.61180877685564, 18.899860382080192],
        [84.61180877685564, 18.89957046508789],
        [84.6115264892581, 18.899583816528377],
        [84.6115264892581, 18.899305343627987],
        [84.61180877685564, 18.899311065673942],
        [84.61180877685564, 18.898748397827205],
        [84.6115264892581, 18.898748397827205],
        [84.6115264892581, 18.89846992492687],
        [84.61125183105469, 18.898471832275447],
        [84.61125183105469, 18.898195266723633],
        [84.61067962646484, 18.898199081421012],
        [84.61067962646484, 18.89792060852062],
        [84.6104202270509, 18.89792060852062],
        [84.6104202270509, 18.89763832092285],
        [84.61013793945318, 18.89763832092285],
        [84.61013793945318, 18.897079467773494],
        [84.60986328124994, 18.897083282470703],
        [84.60986328124994, 18.896804809570312],
        [84.60930633544939, 18.896804809570312],
        [84.60930633544939, 18.896528244018498],
        [84.60901641845726, 18.896528244018498],
        [84.60901641845726, 18.896249771118164],
        [84.60874938964861, 18.896249771118164],
        [84.60874938964861, 18.895690917968807],
        [84.6084747314456, 18.89569473266613],
        [84.6084747314456, 18.895416259765568],
        [84.60819244384783, 18.89541816711437],
        [84.60819244384783, 18.89513969421398],
        [84.60791778564482, 18.89513969421398],
        [84.60791778564482, 18.89486122131359],
        [84.60763549804688, 18.89486122131359],
        [84.60763549804688, 18.8945827484132],
        [84.60736083984403, 18.8945827484132],
        [84.60736083984403, 18.894027709960994],
        [84.60708618164068, 18.894029617309627],
        [84.60708618164068, 18.894308090210018],
        [84.60679626464855, 18.894308090210018],
        [84.60679626464855, 18.894029617309627],
        [84.6065292358399, 18.894029617309627],
        [84.6065292358399, 18.892919540405273],
        [84.60624694824213, 18.892919540405273],
        [84.60624694824213, 18.89208030700695],
        [84.60597229003929, 18.8920841217041],
        [84.60597229003929, 18.89180564880371],
        [84.6054229736331, 18.89180946350109],
        [84.6054229736331, 18.8915309906007],
        [84.60514068603533, 18.8915309906007],
        [84.60514068603533, 18.891250610351562],
        [84.60485839843778, 18.891250610351562],
        [84.60485839843778, 18.890695571899357],
        [84.60457611083984, 18.890699386596737],
        [84.60457611083984, 18.89014053344738],
        [84.60430908203153, 18.89014053344738],
        [84.60430908203153, 18.889860153198242],
        [84.60402679443365, 18.88986206054699],
        [84.60402679443365, 18.88958168029785],
        [84.60375213623058, 18.88958168029785],
        [84.60375213623058, 18.88930320739746],
        [84.60346984863287, 18.889310836791992],
        [84.60346984863287, 18.889030456543026],
        [84.60291290283226, 18.889030456543026],
        [84.60291290283226, 18.88875007629406],
        [84.60263824462908, 18.88875007629406],
        [84.60263824462908, 18.888469696044865],
        [84.60236358642584, 18.888473510742244],
        [84.60236358642584, 18.888193130493278],
        [84.6020812988283, 18.88820075988781],
        [84.6020812988283, 18.887920379638672],
        [84.60179901123075, 18.887920379638672],
        [84.60179901123075, 18.88735961914074],
        [84.6020812988283, 18.88736343383789],
        [84.6020812988283, 18.88680648803711],
        [84.60180664062528, 18.88680648803711],
        [84.60180664062528, 18.887081146240348],
        [84.60152435302734, 18.887081146240348],
        [84.60152435302734, 18.88680648803711],
        [84.6012496948245, 18.88681030273449],
        [84.6012496948245, 18.886531829834098],
        [84.60098266601568, 18.886531829834098],
        [84.60098266601568, 18.88596916198736],
        [84.60069274902355, 18.885971069335994],
        [84.60069274902355, 18.88569450378418],
        [84.60041809082037, 18.88569450378418],
        [84.60041809082037, 18.88513946533203],
        [84.6001358032226, 18.88513946533203],
        [84.6001358032226, 18.884859085083008],
        [84.59986114501976, 18.884859085083008],
        [84.59986114501976, 18.88457107543951],
        [84.59958648681658, 18.88458251953125],
        [84.59958648681658, 18.884305953979435],
        [84.59929656982445, 18.88431167602539],
        [84.59929656982445, 18.884031295776424],
        [84.5990295410158, 18.884031295776424],
        [84.5990295410158, 18.883190155029297],
        [84.59874725341825, 18.883193969726676],
        [84.59874725341825, 18.882638931274528],
        [84.59847259521484, 18.882640838623104],
        [84.59847259521484, 18.882080078124943],
        [84.598197937012, 18.882083892822322],
        [84.598197937012, 18.881805419921932],
        [84.59764099121105, 18.881820678710994],
        [84.59764099121105, 18.88153076171875],
        [84.59735870361334, 18.88153076171875],
        [84.59735870361334, 18.881250381469783],
        [84.59707641601574, 18.881250381469783],
        [84.59707641601574, 18.880970001220817],
        [84.59652709960955, 18.880971908569393],
        [84.59652709960955, 18.880695343017578],
        [84.5962524414063, 18.880701065063533],
        [84.5962524414063, 18.880420684814567],
        [84.59597015380876, 18.880420684814567],
        [84.59597015380876, 18.879859924316463],
        [84.59569549560575, 18.87986183166504],
        [84.59569549560575, 18.87958335876465],
        [84.59541320800798, 18.87958335876465],
        [84.59541320800798, 18.879306793212834],
        [84.59513854980497, 18.879306793212834],
        [84.59513854980497, 18.879028320312443],
        [84.59485626220703, 18.879030227661246],
        [84.59485626220703, 18.878759384155387],
        [84.59458160400419, 18.878759384155387],
        [84.59458160400419, 18.878469467163086],
        [84.59430694580084, 18.87847137451172],
        [84.59430694580084, 18.87819671630865],
        [84.59402465820324, 18.87819671630865],
        [84.59402465820324, 18.877639770507926],
        [84.59375, 18.877639770507926],
        [84.59375, 18.877359390258732],
        [84.59347534179705, 18.877361297607536],
        [84.59347534179705, 18.87708473205572],
        [84.59319305419945, 18.87708473205572],
        [84.59319305419945, 18.87652778625494],
        [84.5929260253908, 18.876529693603572],
        [84.5929260253908, 18.876251220703182],
        [84.5926361083985, 18.876251220703182],
        [84.5926361083985, 18.875970840454215],
        [84.59236145019548, 18.875974655151367],
        [84.59236145019548, 18.875692367553825],
        [84.59208679199247, 18.875692367553825],
        [84.59208679199247, 18.87541580200201],
        [84.591796875, 18.87541961669922],
        [84.591796875, 18.875141143798828],
        [84.59152984619169, 18.875141143798828],
        [84.59152984619169, 18.874862670898438],
        [84.5912475585938, 18.874862670898438],
        [84.5912475585938, 18.874580383300895],
        [84.59069824218756, 18.87458229064947],
        [84.59069061279303, 18.874305725097656],
        [84.59041595458979, 18.874309539795036],
        [84.59041595458979, 18.873752593994254],
        [84.59014129638695, 18.873752593994254],
        [84.59014129638695, 18.87347030639654],
        [84.58985900878923, 18.87347030639654],
        [84.58985900878923, 18.873193740844727],
        [84.589584350586, 18.873193740844727],
        [84.589584350586, 18.872917175292912],
        [84.58902740478544, 18.872922897338867],
        [84.58902740478544, 18.872629165649414],
        [84.58847045898466, 18.872629165649414],
        [84.58847045898466, 18.872922897338867],
        [84.58680725097673, 18.872917175292912],
        [84.58680725097673, 18.873193740844727],
        [84.58625030517595, 18.873193740844727],
        [84.58625030517595, 18.87347030639654],
        [84.585693359375, 18.87347030639654],
        [84.585693359375, 18.873752593994254],
        [84.58541870117216, 18.873752593994254],
        [84.58541870117216, 18.874031066894645],
        [84.58513641357422, 18.874031066894645],
        [84.58513641357422, 18.874309539795036],
        [84.58458709716803, 18.874309539795036],
        [84.58458709716803, 18.874862670898438],
        [84.5842971801759, 18.874862670898438],
        [84.5842971801759, 18.875141143798828],
        [84.58403015136741, 18.875141143798828],
        [84.58403015136741, 18.87541961669922],
        [84.5837478637697, 18.87541580200201],
        [84.5837478637697, 18.875692367553825],
        [84.58347320556646, 18.87569046020502],
        [84.58347320556646, 18.876251220703182],
        [84.58319091796892, 18.876251220703182],
        [84.58319091796892, 18.876529693603572],
        [84.58291625976591, 18.876529693603572],
        [84.58291625976591, 18.876251220703182],
        [84.5826416015625, 18.876251220703182],
        [84.5826416015625, 18.87569046020502],
        [84.58291625976591, 18.875692367553825],
        [84.58291625976591, 18.875139236450252],
        [84.58319091796892, 18.875141143798828],
        [84.58319091796892, 18.874862670898438],
        [84.58347320556646, 18.874862670898438],
        [84.58347320556646, 18.874580383300895],
        [84.5837478637697, 18.87458229064947],
        [84.5837478637697, 18.874305725097656],
        [84.58403015136741, 18.874309539795036],
        [84.58403015136741, 18.874031066894645],
        [84.5842971801759, 18.874031066894645],
        [84.5842971801759, 18.873752593994254],
        [84.58458709716803, 18.873752593994254],
        [84.58458709716803, 18.87319183349615],
        [84.58486175537121, 18.873193740844727],
        [84.58486175537121, 18.872917175292912],
        [84.58541870117216, 18.872922897338867],
        [84.58541870117216, 18.872629165649414],
        [84.58597564697294, 18.87263870239252],
        [84.58597564697294, 18.872360229492188],
        [84.58625030517595, 18.872360229492188],
        [84.58625030517595, 18.872079849243164],
        [84.58652496337896, 18.872083663940543],
        [84.58652496337896, 18.87041664123541],
        [84.58625030517595, 18.870420455932617],
        [84.58625030517595, 18.869859695434684],
        [84.58597564697294, 18.86986160278326],
        [84.58597564697294, 18.868194580078125],
        [84.585693359375, 18.868194580078125],
        [84.585693359375, 18.867916107177734],
        [84.58541870117216, 18.867919921875114],
        [84.58541870117216, 18.867361068725586],
        [84.58513641357422, 18.867361068725586],
        [84.58513641357422, 18.86681747436529],
        [84.58486175537121, 18.86681747436529],
        [84.58486175537121, 18.865690231323242],
        [84.58458709716803, 18.865695953369197],
        [84.58458709716803, 18.865139007568473],
        [84.5842971801759, 18.86514091491705],
        [84.5842971801759, 18.86458015441889],
        [84.58403015136741, 18.864585876464844],
        [84.58403015136741, 18.864305496215877],
        [84.58376312255876, 18.864311218261832],
        [84.58376312255876, 18.864030838012695],
        [84.5826416015625, 18.864030838012695],
        [84.5826416015625, 18.86375045776373],
        [84.58207702636719, 18.86375045776373],
        [84.58207702636719, 18.863470077514762],
        [84.58180236816435, 18.863473892211914],
        [84.58180236816435, 18.86319160461437],
        [84.58208465576172, 18.86319160461437],
        [84.58208465576172, 18.862916946411133],
        [84.58180236816435, 18.862920761108512],
        [84.58180236816435, 18.862640380859375],
        [84.581527709961, 18.862640380859375],
        [84.581527709961, 18.862489700317383],
        [84.581527709961, 18.86236381530773],
        [84.58125305175776, 18.86236381530773],
        [84.58125305175776, 18.859859466552678],
        [84.58097076416021, 18.859859466552678],
        [84.58097076416021, 18.858194351196346],
        [84.5806961059572, 18.858194351196346],
        [84.5806961059572, 18.857639312744197],
        [84.58040618896507, 18.857652664184684],
        [84.58040618896507, 18.857360839843807],
        [84.58013916015642, 18.857360839843807],
        [84.58013916015642, 18.85708045959484],
        [84.57986450195341, 18.857084274291992],
        [84.57986450195341, 18.85652732849121],
        [84.57958221435564, 18.856529235839844],
        [84.57958221435564, 18.856250762939453],
        [84.57930755615263, 18.856250762939453],
        [84.57930755615263, 18.85514068603527],
        [84.57903289794922, 18.85514068603527],
        [84.57903289794922, 18.854310989379883],
        [84.57875061035185, 18.854310989379883],
        [84.57875061035185, 18.853469848632756],
        [84.5784759521485, 18.85347175598156],
        [84.5784759521485, 18.852638244628963],
        [84.57818603515636, 18.852640151977596],
        [84.57818603515636, 18.852079391479435],
        [84.57791900634771, 18.852083206176815],
        [84.57791900634771, 18.851806640625],
        [84.57763671874994, 18.851806640625],
        [84.57763671874994, 18.851249694824276],
        [84.5773620605471, 18.851249694824276],
        [84.5773620605471, 18.850971221923885],
        [84.57707977294939, 18.85097312927246],
        [84.57707977294939, 18.85041618347168],
        [84.57681274414091, 18.85041999816906],
        [84.57681274414091, 18.84986114501953],
        [84.57653045654314, 18.84986114501953],
        [84.57653045654314, 18.849309921264705],
        [84.5762481689456, 18.849309921264705],
        [84.5762481689456, 18.848751068115348],
        [84.57596588134766, 18.848751068115348],
        [84.57596588134766, 18.847362518310604],
        [84.57569122314482, 18.847362518310604],
        [84.57569122314482, 18.842918395996207],
        [84.57596588134766, 18.842918395996207],
        [84.57596588134766, 18.842359542846623],
        [84.57569122314482, 18.842361450195426],
        [84.57569122314482, 18.840139389038143],
        [84.57596588134766, 18.840139389038143],
        [84.57596588134766, 18.839309692382926],
        [84.5762481689456, 18.839309692382926],
        [84.5762481689456, 18.839031219482536],
        [84.57653045654314, 18.839031219482536],
        [84.57653045654314, 18.8387508392334],
        [84.57681274414091, 18.8387508392334],
        [84.57680511474615, 18.838195800781193],
        [84.57653045654314, 18.838195800781193],
        [84.57653045654314, 18.83708572387701],
        [84.5762481689456, 18.83708572387701],
        [84.5762481689456, 18.836805343628043],
        [84.57569122314482, 18.836811065673828],
        [84.57569122314482, 18.836521148681697],
        [84.5751419067384, 18.836526870727482],
        [84.5751419067384, 18.836250305175895],
        [84.57485961914068, 18.836250305175895],
        [84.57485961914068, 18.8359699249267],
        [84.57458496093744, 18.83597373962408],
        [84.57458496093744, 18.83569526672369],
        [84.57402801513689, 18.835700988769645],
        [84.57402801513689, 18.835420608520508],
        [84.57375335693365, 18.835420608520508],
        [84.57375335693365, 18.83514022827154],
        [84.57347106933611, 18.83514022827154],
        [84.57347106933611, 18.834859848022575],
        [84.57277679443382, 18.834863662719727],
        [84.57263946533232, 18.834863662719727],
        [84.57263946533232, 18.834581375122184],
        [84.57208251953136, 18.834581375122184],
        [84.57208251953136, 18.83430480957037],
        [84.57180786132818, 18.83430480957037],
        [84.57180786132818, 18.834028244018555],
        [84.57153320312494, 18.834030151367188],
        [84.57153320312494, 18.833751678466797],
        [84.57125091552757, 18.833751678466797],
        [84.57125091552757, 18.833190917968864],
        [84.57096862792986, 18.833194732666016],
        [84.57096862792986, 18.832916259765625],
        [84.57041931152361, 18.832920074463004],
        [84.57041931152361, 18.832641601562614],
        [84.57013702392607, 18.832641601562614],
        [84.57013702392607, 18.832359313964844],
        [84.56986236572266, 18.832359313964844],
        [84.56986236572266, 18.83208084106451],
        [84.56958007812528, 18.832082748413086],
        [84.56958007812528, 18.83180618286127],
        [84.56931304931646, 18.831811904907227],
        [84.56931304931646, 18.83153152465826],
        [84.56903076171886, 18.83153152465826],
        [84.56903076171886, 18.83124923706049],
        [84.56875610351568, 18.83124923706049],
        [84.56875610351568, 18.830970764160156],
        [84.56847381591791, 18.830972671508903],
        [84.56847381591791, 18.830694198608512],
        [84.56836700439459, 18.830694198608512],
        [84.56819152832037, 18.830694198608512],
        [84.56819152832037, 18.83055114746105],
        [84.56819152832037, 18.830417633056697],
        [84.56799316406256, 18.83041572570812],
        [84.56791687011736, 18.83041572570812],
        [84.56791687011736, 18.830139160156364],
        [84.56759643554693, 18.830139160156364],
        [84.56735992431658, 18.830139160156364],
        [84.56735992431658, 18.829980850219783],
        [84.56735992431658, 18.829860687255973],
        [84.56720733642578, 18.829860687255973],
        [84.5668029785158, 18.829860687255973],
        [84.5668029785158, 18.82958030700678],
        [84.56652832031278, 18.82958412170416],
        [84.56652832031278, 18.829370498657283],
        [84.56652832031278, 18.829027175903377],
        [84.56624603271484, 18.82902908325201],
        [84.56624603271484, 18.82875061035162],
        [84.56568908691412, 18.82875061035162],
        [84.56568908691412, 18.828470230102653],
        [84.56541442871105, 18.82847213745123],
        [84.56541442871105, 18.828193664550838],
        [84.56511688232428, 18.828193664550838],
        [84.5648574829101, 18.828193664550838],
        [84.5648574829101, 18.827917098999023],
        [84.56458282470726, 18.827920913696403],
        [84.56456756591803, 18.827661514282283],
        [84.56430816650408, 18.827640533447266],
        [84.56430816650408, 18.82740020751953],
        [84.56430816650408, 18.827083587646484],
        [84.5640258789063, 18.827083587646484],
        [84.5640258789063, 18.82652854919428],
        [84.56387329101591, 18.826530456543082],
        [84.5637512207033, 18.826530456543082],
        [84.5637512207033, 18.826250076293945],
        [84.56346893310575, 18.826250076293945],
        [84.56346893310575, 18.825969696044922],
        [84.56319427490234, 18.825971603393555],
        [84.56319427490234, 18.825696945190487],
        [84.5629196166995, 18.825700759887695],
        [84.5629196166995, 18.825139999389762],
        [84.56318664550781, 18.825139999389762],
        [84.56318664550781, 18.824859619140568],
        [84.5629196166995, 18.82486152648937],
        [84.5629196166995, 18.82458305358898],
        [84.56263732910162, 18.82458305358898],
        [84.56263732910162, 18.824306488037166],
        [84.56236267089855, 18.824310302734375],
        [84.56236267089855, 18.82402992248541],
        [84.56208038330084, 18.82402992248541],
        [84.56208038330084, 18.823749542236442],
        [84.5618057250976, 18.823749542236442],
        [84.5618057250976, 18.823194503784237],
        [84.56153106689476, 18.823194503784237],
        [84.56153106689476, 18.82291412353527],
        [84.56124877929705, 18.822919845581055],
        [84.56124877929705, 18.822639465332088],
        [84.56096649169928, 18.822639465332088],
        [84.56096649169928, 18.822362899780273],
        [84.56069183349626, 18.822362899780273],
        [84.56069183349626, 18.82208061218273],
        [84.56041717529325, 18.822084426879883],
        [84.56041717529325, 18.821805953979492],
        [84.56014251708984, 18.82180976867687],
        [84.56014251708984, 18.82125282287609],
        [84.55986022949247, 18.82125282287609],
        [84.55986022949247, 18.820970535278377],
        [84.55958557128912, 18.820970535278377],
        [84.55958557128912, 18.820692062377987],
        [84.55930328369152, 18.820692062377987],
        [84.55930328369152, 18.820417404174748],
        [84.55902862548834, 18.820421218872127],
        [84.55902862548834, 18.819860458374023],
        [84.55874633789057, 18.819860458374023],
        [84.55874633789057, 18.819311141967773],
        [84.55847167968773, 18.819311141967773],
        [84.55847167968773, 18.818752288818416],
        [84.55819702148455, 18.818752288818416],
        [84.55819702148455, 18.81847953796398],
        [84.55790710449241, 18.81847953796398],
        [84.55790710449241, 18.817920684814453],
        [84.55764007568376, 18.817920684814453],
        [84.55764007568376, 18.817638397216854],
        [84.55735778808622, 18.817638397216854],
        [84.55735778808622, 18.81735992431652],
        [84.55708312988281, 18.817361831665096],
        [84.55708312988281, 18.815971374511776],
        [84.55680847167997, 18.815971374511776],
        [84.55680847167997, 18.815139770507812],
        [84.55652618408203, 18.815139770507812],
        [84.55652618408203, 18.812641143798885],
        [84.55625152587902, 18.812641143798885],
        [84.55625152587902, 18.810689926147404],
        [84.5559692382813, 18.81069564819336],
        [84.5559692382813, 18.80958366394043],
        [84.55569458007807, 18.80958366394043],
        [84.55569458007807, 18.809303283691463],
        [84.55541992187523, 18.809310913085994],
        [84.55541992187523, 18.808469772338867],
        [84.55569458007807, 18.808473587036246],
        [84.55569458007807, 18.807363510131893],
        [84.55541992187523, 18.807363510131893],
        [84.55541992187523, 18.806804656982536],
        [84.55513763427751, 18.806804656982536],
        [84.55513763427751, 18.80541610717779],
        [84.55486297607428, 18.805421829223746],
        [84.55486297607428, 18.80514144897461],
        [84.55458068847673, 18.80514144897461],
        [84.55458068847673, 18.804580688476676],
        [84.55430603027372, 18.804582595825252],
        [84.55430603027372, 18.804027557373047],
        [84.55403137207031, 18.804031372070426],
        [84.55403137207031, 18.802917480468693],
        [84.55374908447294, 18.802917480468693],
        [84.55374908447294, 18.80236053466797],
        [84.55403137207031, 18.80236053466797],
        [84.55403137207031, 18.800100326538143],
        [84.55403137207031, 18.79847145080572],
        [84.55430603027372, 18.79847145080572],
        [84.55430603027372, 18.797916412353516],
        [84.55458068847673, 18.797920227050895],
        [84.55458068847673, 18.795972824096793],
        [84.55403137207031, 18.79597473144537],
        [84.55403137207031, 18.795692443847656],
        [84.55374908447294, 18.795692443847656],
        [84.55374908447294, 18.795415878296012],
        [84.553466796875, 18.79541969299322],
        [84.553466796875, 18.79514122009283],
        [84.55319213867216, 18.79514122009283],
        [84.55319213867216, 18.79486274719244],
        [84.5529174804688, 18.79486274719244],
        [84.5529174804688, 18.79458045959467],
        [84.55319213867216, 18.79458045959467],
        [84.55319213867216, 18.79430580139166],
        [84.553466796875, 18.794309616088867],
        [84.553466796875, 18.794031143188477],
        [84.55374908447294, 18.794031143188477],
        [84.55374908447294, 18.792921066284293],
        [84.55403137207031, 18.792921066284293],
        [84.55403137207031, 18.792642593383903],
        [84.55374908447294, 18.792642593383903],
        [84.55374908447294, 18.79236030578619],
        [84.553466796875, 18.79236030578619],
        [84.553466796875, 18.791809082031364],
        [84.55319213867216, 18.791809082031364],
        [84.55319213867216, 18.79154014587408],
        [84.5529174804688, 18.79154014587408],
        [84.5529174804688, 18.79236030578619],
        [84.55264282226557, 18.79236030578619],
        [84.55264282226557, 18.792642593383903],
        [84.55236053466803, 18.792642593383903],
        [84.55236053466803, 18.792921066284293],
        [84.55152893066423, 18.792921066284293],
        [84.55152893066423, 18.792642593383903],
        [84.55124664306646, 18.792642593383903],
        [84.55124664306646, 18.791248321533203],
        [84.55068969726591, 18.791248321533203],
        [84.55068969726591, 18.790969848632812],
        [84.55014038085966, 18.790971755981445],
        [84.55014038085966, 18.7906951904298],
        [84.54930877685553, 18.790700912475586],
        [84.54930877685553, 18.791248321533203],
        [84.54917907714872, 18.791248321533203],
        [84.54902648925776, 18.791248321533203],
        [84.54902648925776, 18.790969848632812],
        [84.5484695434572, 18.790971755981445],
        [84.5484695434572, 18.7906951904298],
        [84.54818725585943, 18.790700912475586],
        [84.54818725585943, 18.79013824462885],
        [84.54905700683611, 18.79013824462885],
        [84.54930877685553, 18.79013824462885],
        [84.54930877685553, 18.78985977172846],
        [84.54902648925776, 18.789861679077262],
        [84.54902648925776, 18.78972816467291],
        [84.54902648925776, 18.78930473327648],
        [84.54875183105491, 18.78930473327648],
        [84.54875183105491, 18.788749694824332],
        [84.5484695434572, 18.788749694824332],
        [84.5484695434572, 18.788471221923942],
        [84.54819488525396, 18.788473129272518],
        [84.54819488525396, 18.788194656372127],
        [84.54792022705095, 18.788194656372127],
        [84.54792022705095, 18.787916183471737],
        [84.54765319824247, 18.787918090820312],
        [84.54765319824247, 18.78763961791998],
        [84.54651641845732, 18.78763961791998],
        [84.54651641845732, 18.786809921264762],
        [84.5462493896485, 18.786809921264762],
        [84.5462493896485, 18.786251068115234],
        [84.54596710205072, 18.786251068115234],
        [84.54596710205072, 18.785690307617244],
        [84.54569244384788, 18.785694122314453],
        [84.54569244384788, 18.78541755676264],
        [84.54513549804693, 18.78541755676264],
        [84.54513549804693, 18.785139083862248],
        [84.54458618164091, 18.78514099121105],
        [84.54458618164091, 18.784860610961914],
        [84.54429626464861, 18.784860610961914],
        [84.54429626464861, 18.78458023071289],
        [84.54402923584013, 18.784585952758846],
        [84.54402923584013, 18.78430557250988],
        [84.54374694824219, 18.784309387207088],
        [84.54374694824219, 18.784030914306697],
        [84.543197631836, 18.784030914306697],
        [84.543197631836, 18.78375053405773],
        [84.54291534423822, 18.78375053405773],
        [84.54291534423822, 18.78347206115734],
        [84.54264068603538, 18.78347206115734],
        [84.54264068603538, 18.783195495605526],
        [84.54235839843767, 18.783195495605526],
        [84.54235839843767, 18.782638549804744],
        [84.54207611084007, 18.782640457153377],
        [84.54207611084007, 18.782369613647518],
        [84.54151916503912, 18.782369613647518],
        [84.54151916503912, 18.78181076049816],
        [84.54125213623047, 18.78181076049816],
        [84.54125213623047, 18.781530380249023],
        [84.5409698486331, 18.781530380249023],
        [84.5409698486331, 18.781251907348633],
        [84.54013824462896, 18.781251907348633],
        [84.54013824462896, 18.780969619751033],
        [84.53986358642572, 18.780973434448242],
        [84.53986358642572, 18.78041648864746],
        [84.53958129882818, 18.78042030334484],
        [84.53958129882818, 18.78014183044445],
        [84.53901672363304, 18.78014183044445],
        [84.53901672363304, 18.779579162597713],
        [84.53874969482439, 18.779581069946346],
        [84.53874969482439, 18.779306411743107],
        [84.53848266601591, 18.779310226440487],
        [84.53848266601591, 18.779031753540096],
        [84.5379180908206, 18.779031753540096],
        [84.5379180908206, 18.778749465942326],
        [84.53708648681646, 18.778749465942326],
        [84.53708648681646, 18.777921676635742],
        [84.53679656982433, 18.777921676635742],
        [84.53679656982433, 18.77736091613781],
        [84.53652954101568, 18.77736091613781],
        [84.53652954101568, 18.776531219482422],
        [84.53597259521507, 18.776531219482422],
        [84.53597259521507, 18.776239395141715],
        [84.53569793701189, 18.776250839233455],
        [84.53569793701189, 18.775972366333065],
        [84.53541564941412, 18.775972366333065],
        [84.53541564941412, 18.775693893432674],
        [84.53514099121111, 18.775693893432674],
        [84.53514099121111, 18.77541732788086],
        [84.53457641601562, 18.77542114257824],
        [84.53457641601562, 18.7751407623291],
        [84.53430175781278, 18.7751407623291],
        [84.53430175781278, 18.774860382080135],
        [84.53402709960943, 18.774860382080135],
        [84.53402709960943, 18.77458000183111],
        [84.53375244140636, 18.77458381652832],
        [84.53375244140636, 18.77430534362793],
        [84.53320312500017, 18.774311065673885],
        [84.53320312500017, 18.77375030517578],
        [84.53263854980486, 18.77375030517578],
        [84.53263854980486, 18.77347183227539],
        [84.53236389160162, 18.77347183227539],
        [84.53236389160162, 18.773195266723746],
        [84.53208160400408, 18.77320098876953],
        [84.53208160400408, 18.772920608520565],
        [84.53180694580107, 18.772920608520565],
        [84.53180694580107, 18.772359848022404],
        [84.53152465820312, 18.772361755371207],
        [84.53152465820312, 18.772083282470817],
        [84.53069305419933, 18.772083282470817],
        [84.53069305419933, 18.771806716919002],
        [84.53013610839838, 18.771806716919002],
        [84.53013610839838, 18.77152824401861],
        [84.52957916259783, 18.771530151367244],
        [84.52957916259783, 18.770690917968693],
        [84.52930450439459, 18.770694732666072],
        [84.52930450439459, 18.770416259765682],
        [84.52902984619158, 18.77042961120617],
        [84.52902984619158, 18.770139694213924],
        [84.52819824218778, 18.770139694213924],
        [84.52819061279325, 18.769861221313533],
        [84.52764129638683, 18.769861221313533],
        [84.52764129638683, 18.769580841064567],
        [84.52735900878912, 18.76958465576172],
        [84.52735900878912, 18.769306182861328],
        [84.52651977539057, 18.769309997558707],
        [84.52651977539057, 18.76902961730957],
        [84.52625274658209, 18.76902961730957],
        [84.52625274658209, 18.767370223999137],
        [84.52401733398449, 18.767370223999137],
        [84.52401733398449, 18.767080307006836],
        [84.52375030517584, 18.767084121704215],
        [84.52375030517584, 18.766805648803825],
        [84.52348327636736, 18.766809463501033],
        [84.52348327636736, 18.76653289794922],
        [84.52319335937523, 18.76653289794922],
        [84.52319335937523, 18.766252517700252],
        [84.52263641357428, 18.766252517700252],
        [84.52263641357428, 18.765970230102482],
        [84.52208709716825, 18.765970230102482],
        [84.52208709716825, 18.765693664550895],
        [84.52153015136747, 18.765693664550895],
        [84.52153015136747, 18.76541709899908],
        [84.52124786376959, 18.765422821045036],
        [84.52124786376959, 18.764858245849553],
        [84.52097320556652, 18.764860153198356],
        [84.52097320556652, 18.76458358764654],
        [84.52041625976557, 18.76458358764654],
        [84.52041625976557, 18.76430511474615],
        [84.51985931396501, 18.76430511474615],
        [84.51985931396501, 18.764028549194336],
        [84.51957702636724, 18.764028549194336],
        [84.51957702636724, 18.76346969604498],
        [84.51875305175781, 18.76347160339361],
        [84.51875305175781, 18.763195037841797],
        [84.51847076416044, 18.763200759887752],
        [84.51847076416044, 18.762638092041016],
        [84.51819610595709, 18.762638092041016],
        [84.51819610595709, 18.762359619140625],
        [84.5176391601563, 18.762361526489258],
        [84.5176391601563, 18.762083053588867],
        [84.5170822143557, 18.762083053588867],
        [84.5170822143557, 18.761806488037053],
        [84.51653289794928, 18.761810302734432],
        [84.51653289794928, 18.761529922485465],
        [84.51625061035173, 18.761529922485465],
        [84.51625061035173, 18.761240005493164],
        [84.51597595214872, 18.76124954223627],
        [84.51597595214872, 18.760972976684684],
        [84.51569366455078, 18.760972976684684],
        [84.51569366455078, 18.760694503784293],
        [84.51541900634794, 18.760694503784293],
        [84.51541900634794, 18.76041793823248],
        [84.51486206054699, 18.76041984558111],
        [84.51486206054699, 18.760139465332145],
        [84.51457977294928, 18.760139465332145],
        [84.51457977294928, 18.759860992431754],
        [84.51374816894548, 18.759860992431754],
        [84.51374816894548, 18.75958061218256],
        [84.51347351074224, 18.759582519531364],
        [84.51347351074224, 18.759304046630973],
        [84.51291656494169, 18.759309768676758],
        [84.51291656494169, 18.7587509155274],
        [84.51235961914091, 18.7587509155274],
        [84.51235961914091, 18.758470535278434],
        [84.51180267334013, 18.75847244262701],
        [84.51180267334013, 18.758195877075195],
        [84.51125335693354, 18.758195877075195],
        [84.51125335693354, 18.757917404174805],
        [84.510971069336, 18.757921218872184],
        [84.510971069336, 18.757640838623047],
        [84.51040649414085, 18.757640838623047],
        [84.51040649414085, 18.75736045837408],
        [84.5101394653322, 18.75736045837408],
        [84.5101394653322, 18.757080078125057],
        [84.50986480712919, 18.75708580017084],
        [84.50986480712919, 18.756805419921875],
        [84.50958251953142, 18.75681114196783],
        [84.50958251953142, 18.756530761718864],
        [84.50930786132841, 18.756530761718864],
        [84.50930786132841, 18.756250381469727],
        [84.50875091552763, 18.756250381469727],
        [84.50875091552763, 18.755701065063477],
        [84.50847625732428, 18.755701065063477],
        [84.50847625732428, 18.755430221557617],
        [84.50818634033214, 18.755430221557617],
        [84.50818634033214, 18.755140304565543],
        [84.5079193115235, 18.755140304565543],
        [84.5079193115235, 18.754861831665153],
        [84.50736236572288, 18.75486373901373],
        [84.50736236572288, 18.754581451416016],
        [84.50708007812517, 18.754581451416016],
        [84.50708007812517, 18.75430488586437],
        [84.50680541992193, 18.75430488586437],
        [84.50680541992193, 18.754028320312557],
        [84.50653076171892, 18.75403022766119],
        [84.50653076171892, 18.7537517547608],
        [84.50596618652344, 18.7537517547608],
        [84.50596618652344, 18.75346946716303],
        [84.50541687011724, 18.75347328186041],
        [84.50541687011724, 18.753194808960018],
        [84.50514221191418, 18.753194808960018],
        [84.50514221191418, 18.752916336059627],
        [84.50485992431646, 18.752920150756836],
        [84.50485992431646, 18.752641677856445],
        [84.50458526611322, 18.752641677856445],
        [84.50458526611322, 18.752359390258846],
        [84.50430297851585, 18.752359390258846],
        [84.50430297851585, 18.752082824707088],
        [84.50402832031267, 18.752082824707088],
        [84.50402832031267, 18.751527786254883],
        [84.50347137451189, 18.751531600952262],
        [84.50347137451189, 18.751249313354492],
        [84.50319671630888, 18.751249313354492],
        [84.50319671630888, 18.75097084045416],
        [84.50208282470714, 18.750972747802734],
        [84.50208282470714, 18.750415802001953],
        [84.50180816650396, 18.75042152404791],
        [84.50180816650396, 18.75013923645014],
        [84.5015258789062, 18.75013923645014],
        [84.5015258789062, 18.749860763549805],
        [84.50125122070335, 18.749860763549805],
        [84.50125122070335, 18.74958038330078],
        [84.50096893310564, 18.74958419799816],
        [84.50096893310564, 18.74930572509777],
        [84.5006942749024, 18.74930572509777],
        [84.5006942749024, 18.74902725219721],
        [84.50041961669939, 18.749029159546012],
        [84.50041961669939, 18.74818992614746],
        [84.50013732910185, 18.74819374084484],
        [84.50013732910185, 18.747917175293026],
        [84.49931335449224, 18.747920989990234],
        [84.49931335449224, 18.7473602294923],
        [84.49874877929693, 18.7473602294923],
        [84.49874877929693, 18.747079849243107],
        [84.4984741210937, 18.747083663940487],
        [84.4984741210937, 18.746805191040096],
        [84.49819183349615, 18.74681091308605],
        [84.49819183349615, 18.746530532836914],
        [84.49791717529314, 18.746530532836914],
        [84.49791717529314, 18.746259689331055],
        [84.4976425170899, 18.746259689331055],
        [84.4976425170899, 18.745969772338924],
        [84.49736022949236, 18.745971679687557],
        [84.49736022949236, 18.745695114135742],
        [84.4965362548831, 18.745700836181697],
        [84.4965362548831, 18.74542045593273],
        [84.49597167968778, 18.74542045593273],
        [84.49597167968778, 18.74485969543457],
        [84.49569702148443, 18.744861602783203],
        [84.49569702148443, 18.744304656982422],
        [84.49541473388683, 18.744304656982422],
        [84.49541473388683, 18.744028091430607],
        [84.49514007568365, 18.74402999877941],
        [84.49514007568365, 18.743749618530217],
        [84.49485778808588, 18.743749618530217],
        [84.49485778808588, 18.743471145629883],
        [84.49458312988304, 18.74347305297863],
        [84.49458312988304, 18.742916107177848],
        [84.49430847167986, 18.742919921875057],
        [84.49430847167986, 18.74263954162609],
        [84.49402618408209, 18.74263954162609],
        [84.49402618408209, 18.7423610687257],
        [84.49346923828153, 18.7423610687257],
        [84.49346923828153, 18.742084503173885],
        [84.49319458007812, 18.742084503173885],
        [84.49319458007812, 18.741806030273494],
        [84.4926376342774, 18.741809844970703],
        [84.4926376342774, 18.741529464721737],
        [84.49236297607433, 18.741529464721737],
        [84.49236297607433, 18.741252899169922],
        [84.49208068847662, 18.741252899169922],
        [84.49208068847662, 18.74097061157238],
        [84.49180603027338, 18.74097061157238],
        [84.49180603027338, 18.74069213867199],
        [84.49153137207054, 18.74069213867199],
        [84.49153137207054, 18.74041748046875],
        [84.49124908447283, 18.740419387817383],
        [84.49124908447283, 18.74014282226574],
        [84.49096679687506, 18.74014282226574],
        [84.49096679687506, 18.7398624420166],
        [84.49069213867205, 18.7398624420166],
        [84.49069213867205, 18.739580154419002],
        [84.49041748046903, 18.739582061767635],
        [84.49041748046903, 18.73930549621582],
        [84.49014282226562, 18.739311218261776],
        [84.49014282226562, 18.73875236511242],
        [84.48986053466825, 18.73875236511242],
        [84.48986053466825, 18.738468170166072],
        [84.4895858764649, 18.73847007751465],
        [84.4895858764649, 18.737916946411076],
        [84.48929595947294, 18.737920761108455],
        [84.48929595947294, 18.73735809326172],
        [84.48902893066412, 18.737361907959098],
        [84.48902893066412, 18.737083435058707],
        [84.48874664306635, 18.737083435058707],
        [84.48874664306635, 18.736804962158317],
        [84.48847198486351, 18.7368106842041],
        [84.48847198486351, 18.736528396606502],
        [84.48819732666033, 18.736528396606502],
        [84.48819732666033, 18.73624992370617],
        [84.48791503906256, 18.73624992370617],
        [84.48791503906256, 18.735971450805778],
        [84.48764038085955, 18.735971450805778],
        [84.48764038085955, 18.735694885253963],
        [84.487358093262, 18.735694885253963],
        [84.487358093262, 18.73430633544922],
        [84.48707580566423, 18.734319686889705],
        [84.48707580566423, 18.733749389648438],
        [84.48680877685575, 18.733749389648438],
        [84.48680877685575, 18.73319053649908],
        [84.48652648925781, 18.73319435119629],
        [84.48652648925781, 18.732639312744084],
        [84.4862518310548, 18.732641220092887],
        [84.4862518310548, 18.73236083984375],
        [84.48568725585949, 18.73236083984375],
        [84.48568725585949, 18.732080459594727],
        [84.48542022705101, 18.732084274292106],
        [84.48542022705101, 18.731805801391715],
        [84.4851379394533, 18.731809616088924],
        [84.4851379394533, 18.730970382690373],
        [84.48486328125006, 18.730972290039176],
        [84.48486328125006, 18.73041725158697],
        [84.48458099365251, 18.73042106628418],
        [84.48458099365251, 18.729860305786246],
        [84.4840164184572, 18.729860305786246],
        [84.4840164184572, 18.729579925537053],
        [84.48374938964872, 18.729583740234432],
        [84.48374938964872, 18.72930526733404],
        [84.48346710205078, 18.729310989379996],
        [84.48346710205078, 18.72903060913086],
        [84.48319244384794, 18.72903060913086],
        [84.48319244384794, 18.728750228881893],
        [84.48291778564459, 18.728750228881893],
        [84.48291778564459, 18.728200912475643],
        [84.48264312744135, 18.728200912475643],
        [84.48264312744135, 18.727920532226676],
        [84.4823608398438, 18.727920532226676],
        [84.4823608398438, 18.72764015197754],
        [84.4820861816408, 18.72764015197754],
        [84.4820861816408, 18.727359771728516],
        [84.4818038940432, 18.727363586425895],
        [84.4818038940432, 18.727081298828182],
        [84.48124694824224, 18.727081298828182],
        [84.48124694824224, 18.726804733276367],
        [84.48097229003923, 18.726804733276367],
        [84.48097229003923, 18.726528167724553],
        [84.48042297363281, 18.726530075073356],
        [84.48042297363281, 18.726251602172965],
        [84.48014068603544, 18.726251602172965],
        [84.48014068603544, 18.725969314575195],
        [84.47985839843756, 18.725971221923828],
        [84.47985839843756, 18.725694656372184],
        [84.47958374023449, 18.725694656372184],
        [84.47958374023449, 18.725416183471793],
        [84.47902679443354, 18.725421905517578],
        [84.47902679443354, 18.72485923767084],
        [84.4787521362307, 18.72485923767084],
        [84.4787521362307, 18.72458076477045],
        [84.47819519042974, 18.724582672119254],
        [84.47819519042974, 18.72430610656744],
        [84.47763824462919, 18.724311828613395],
        [84.47763824462919, 18.724031448364258],
        [84.47735595703125, 18.724031448364258],
        [84.47735595703125, 18.72374916076666],
        [84.47680664062506, 18.72374916076666],
        [84.47680664062506, 18.722917556762695],
        [84.4765167236331, 18.722917556762695],
        [84.4765167236331, 18.722639083862305],
        [84.47569274902366, 18.722639083862305],
        [84.47569274902366, 18.72236061096197],
        [84.47541046142595, 18.72236061096197],
        [84.47541046142595, 18.72207069396984],
        [84.47513580322271, 18.722084045410156],
        [84.47513580322271, 18.721527099609375],
        [84.4748611450197, 18.721530914306754],
        [84.4748611450197, 18.721250534057617],
        [84.47458648681669, 18.721250534057617],
        [84.47458648681669, 18.720970153808594],
        [84.47430419921875, 18.720972061157227],
        [84.47430419921875, 18.720693588256836],
        [84.47374725341797, 18.720693588256836],
        [84.47374725341797, 18.720415115356445],
        [84.47347259521496, 18.7204208374024],
        [84.47347259521496, 18.720140457153434],
        [84.47319793701178, 18.720140457153434],
        [84.47319793701178, 18.71986007690424],
        [84.472915649414, 18.719861984253043],
        [84.472915649414, 18.719583511352653],
        [84.47264099121116, 18.719583511352653],
        [84.47264099121116, 18.719306945800838],
        [84.47236633300798, 18.719320297241154],
        [84.47236633300798, 18.71903038024908],
        [84.47207641601585, 18.71903038024908],
        [84.47207641601585, 18.718750000000114],
        [84.47180175781267, 18.718750000000114],
        [84.47180175781267, 18.71846961975092],
        [84.47152709960966, 18.718471527099723],
        [84.47152709960966, 18.717916488647518],
        [84.47125244140625, 18.717920303344727],
        [84.47125244140625, 18.71763992309576],
        [84.47097015380888, 18.71763992309576],
        [84.47097015380888, 18.717081069946403],
        [84.47069549560547, 18.717084884643555],
        [84.47069549560547, 18.716527938842773],
        [84.4704132080081, 18.716529846191406],
        [84.4704132080081, 18.716260910034293],
        [84.47013854980474, 18.716260910034293],
        [84.47013854980474, 18.715972900390625],
        [84.4698638916015, 18.71597480773937],
        [84.4698638916015, 18.715694427490234],
        [84.46930694580095, 18.715694427490234],
        [84.46930694580095, 18.71513938903803],
        [84.46901702880882, 18.71513938903803],
        [84.46901702880882, 18.714862823486442],
        [84.46875000000017, 18.714862823486442],
        [84.46875000000017, 18.71430969238287],
        [84.46848297119169, 18.71430969238287],
        [84.46848297119169, 18.71403121948248],
        [84.46819305419939, 18.71403121948248],
        [84.46819305419939, 18.713752746582088],
        [84.46791839599638, 18.713752746582088],
        [84.46791839599638, 18.713470458984318],
        [84.46763610839844, 18.713470458984318],
        [84.46763610839844, 18.71319389343273],
        [84.46597290039085, 18.71319389343273],
        [84.46597290039085, 18.712917327880916],
        [84.46569824218767, 18.712921142578125],
        [84.46569061279314, 18.711530685424805],
        [84.4654159545899, 18.711530685424805],
        [84.4654159545899, 18.710969924926815],
        [84.46514129638689, 18.710971832275447],
        [84.46514129638689, 18.710695266723633],
        [84.46485900878935, 18.710699081421012],
        [84.46485900878935, 18.71013069152832],
        [84.46458435058594, 18.71013832092285],
        [84.46458435058594, 18.709861755371094],
        [84.46430206298857, 18.709861755371094],
        [84.46430206298857, 18.709583282470703],
        [84.46402740478521, 18.709583282470703],
        [84.46402740478521, 18.709304809570312],
        [84.46375274658214, 18.709304809570312],
        [84.46375274658214, 18.708749771118107],
        [84.46347045898443, 18.708749771118107],
        [84.46347045898443, 18.70846939086914],
        [84.46291351318382, 18.708471298217773],
        [84.46291351318382, 18.70819473266613],
        [84.46263885498064, 18.70819473266613],
        [84.46263885498064, 18.70791625976574],
        [84.46235656738287, 18.707918167114315],
        [84.46235656738287, 18.70736122131359],
        [84.46208190917986, 18.70736122131359],
        [84.46208190917986, 18.706529617309627],
        [84.46180725097685, 18.706529617309627],
        [84.46180725097685, 18.70597076416027],
        [84.4615249633789, 18.705972671508846],
        [84.4615249633789, 18.70513916015625],
        [84.46125030517607, 18.705141067504883],
        [84.46125030517607, 18.704860687255916],
        [84.46097564697271, 18.704860687255916],
        [84.46097564697271, 18.7045841217041],
        [84.46069335937511, 18.7045841217041],
        [84.46069335937511, 18.703472137451172],
        [84.46041870117193, 18.703472137451172],
        [84.46041870117193, 18.703195571899528],
        [84.46013641357416, 18.703195571899528],
        [84.46013641357416, 18.702638626098576],
        [84.45986175537132, 18.70264053344738],
        [84.45986175537132, 18.70207977294922],
        [84.45958709716814, 18.702083587646598],
        [84.45958709716814, 18.701805114746207],
        [84.4584732055664, 18.701810836791992],
        [84.4584732055664, 18.70125007629406],
        [84.45819091796903, 18.70125007629406],
        [84.45819091796903, 18.700960159301758],
        [84.45791625976562, 18.700973510742244],
        [84.45791625976562, 18.700695037841854],
        [84.45764160156261, 18.70070075988781],
        [84.45764160156261, 18.70014190673828],
        [84.45736694335943, 18.70014190673828],
        [84.45736694335943, 18.699579238891545],
        [84.45708465576166, 18.699581146240348],
        [84.45708465576166, 18.69902992248535],
        [84.45680236816412, 18.699031829834098],
        [84.45680236816412, 18.69819068908697],
        [84.45652770996111, 18.69819450378418],
        [84.45652770996111, 18.69791603088379],
        [84.45625305175787, 18.697921752929744],
        [84.45625305175787, 18.697641372680778],
        [84.45597076416033, 18.697641372680778],
        [84.45597076416033, 18.697359085083008],
        [84.45569610595732, 18.697359085083008],
        [84.45569610595732, 18.696809768676815],
        [84.4548568725586, 18.696809768676815],
        [84.4548568725586, 18.696527481079045],
        [84.45458221435575, 18.696527481079045],
        [84.45458221435575, 18.696250915527457],
        [84.45429992675787, 18.696250915527457],
        [84.45429992675787, 18.695970535278263],
        [84.4540252685548, 18.695972442627067],
        [84.4540252685548, 18.69541740417486],
        [84.45375061035162, 18.69542121887207],
        [84.45375061035162, 18.695148468017635],
        [84.45347595214861, 18.695148468017635],
        [84.45347595214861, 18.694860458374137],
        [84.45318603515648, 18.694860458374137],
        [84.45318603515648, 18.694311141967887],
        [84.45291900634783, 18.694311141967887],
        [84.45291900634783, 18.69403076171875],
        [84.45263671875006, 18.69403076171875],
        [84.45263671875006, 18.693750381469783],
        [84.45236206054705, 18.693750381469783],
        [84.45236206054705, 18.69347000122076],
        [84.4520797729495, 18.693471908569393],
        [84.4520797729495, 18.692916870117188],
        [84.45181274414062, 18.692920684814567],
        [84.45181274414062, 18.692359924316406],
        [84.45124816894537, 18.69236183166504],
        [84.45124816894537, 18.69208335876465],
        [84.45096588134777, 18.69209098815918],
        [84.45096588134777, 18.691530227661246],
        [84.45069122314459, 18.691530227661246],
        [84.45069122314459, 18.691249847412053],
        [84.45041656494135, 18.691249847412053],
        [84.45041656494135, 18.690969467163086],
        [84.45014190673851, 18.69097137451172],
        [84.45014190673851, 18.690694808960075],
        [84.4498596191408, 18.690694808960075],
        [84.4498596191408, 18.690416336059684],
        [84.44957733154303, 18.690420150756893],
        [84.44957733154303, 18.690139770507926],
        [84.44930267334001, 18.690139770507926],
        [84.44930267334001, 18.689859390258732],
        [84.449028015137, 18.689861297607536],
        [84.449028015137, 18.68958473205572],
        [84.4487533569336, 18.68958473205572],
        [84.4487533569336, 18.68902778625494],
        [84.44847106933622, 18.68902778625494],
        [84.44847106933622, 18.68874931335455],
        [84.44819641113287, 18.688751220703182],
        [84.44819641113287, 18.68847274780279],
        [84.44791412353527, 18.688474655151367],
        [84.44791412353527, 18.6881942749024],
        [84.44763946533209, 18.6881942749024],
        [84.44763946533209, 18.68791580200201],
        [84.44735717773432, 18.68791961669922],
        [84.44735717773432, 18.687362670898438],
        [84.44708251953148, 18.687362670898438],
        [84.44708251953148, 18.687080383300838],
        [84.4468078613283, 18.687084197998047],
        [84.4468078613283, 18.686805725097656],
        [84.44653320312506, 18.686809539795036],
        [84.44653320312506, 18.686252593994254],
        [84.44625091552751, 18.686252593994254],
        [84.44625091552751, 18.685960769653377],
        [84.44596862792997, 18.685970306396484],
        [84.44596862792997, 18.685693740844727],
        [84.44569396972656, 18.685693740844727],
        [84.44569396972656, 18.68513870239252],
        [84.44542694091825, 18.6851425170899],
        [84.44542694091825, 18.684579849243164],
        [84.44513702392578, 18.684583663940543],
        [84.44513702392578, 18.684305191040153],
        [84.44486236572277, 18.684310913085938],
        [84.44486236572277, 18.68403053283697],
        [84.44458007812506, 18.68403053283697],
        [84.44458007812506, 18.6837482452392],
        [84.44430541992182, 18.6837482452392],
        [84.44430541992182, 18.683471679687614],
        [84.44403076171898, 18.683471679687614],
        [84.44403076171898, 18.6831951141358],
        [84.44374847412126, 18.68321037292486],
        [84.44374847412126, 18.682920455932617],
        [84.44346618652366, 18.682920455932617],
        [84.44346618652366, 18.682638168335018],
        [84.44319152832048, 18.682638168335018],
        [84.44319152832048, 18.682359695434627],
        [84.44291687011747, 18.68236160278326],
        [84.44291687011747, 18.68208312988287],
        [84.44235992431669, 18.68208312988287],
        [84.44235992431669, 18.68180465698248],
        [84.44208526611328, 18.68180465698248],
        [84.44208526611328, 18.681249618530273],
        [84.44180297851591, 18.681249618530273],
        [84.44180297851591, 18.680419921875114],
        [84.44124603271496, 18.680419921875114],
        [84.44124603271496, 18.680149078369254],
        [84.44097137451178, 18.680149078369254],
        [84.44097137451178, 18.67930984497076],
        [84.44069671630876, 18.67930984497076],
        [84.44069671630876, 18.67847061157221],
        [84.44097137451178, 18.678472518921012],
        [84.44097137451178, 18.678195953369197],
        [84.44125366210932, 18.678195953369197],
        [84.44125366210932, 18.677917480468807],
        [84.44152832031256, 18.67791938781744],
        [84.44152832031256, 18.675970077514705],
        [84.44125366210932, 18.675973892211914],
        [84.44125366210932, 18.67486381530773],
        [84.44097137451178, 18.67486381530773],
        [84.44097137451178, 18.6737518310548],
        [84.44069671630876, 18.6737518310548],
        [84.44069671630876, 18.672359466552678],
        [84.44041442871116, 18.672359466552678],
        [84.44041442871116, 18.671806335449276],
        [84.44013977050798, 18.671810150146484],
        [84.44013977050798, 18.66847038269043],
        [84.44041442871116, 18.668472290039062],
        [84.44041442871116, 18.667638778686467],
        [84.44069671630876, 18.66764068603527],
        [84.44069671630876, 18.667280197143555],
        [84.44069671630876, 18.666528701782283],
        [84.44040679931663, 18.666530609130916],
        [84.44040679931663, 18.6657009124757],
        [84.44069671630876, 18.6657009124757],
        [84.44069671630876, 18.664030075073242],
        [84.43985748291021, 18.66402816772461],
        [84.43985748291021, 18.664304733276424],
        [84.43902587890625, 18.664304733276424],
        [84.43902587890625, 18.66402816772461],
        [84.43875122070341, 18.664030075073242],
        [84.43875122070341, 18.66319084167486],
        [84.43846893310553, 18.66319465637207],
        [84.43846893310553, 18.66291618347168],
        [84.43818664550793, 18.66291999816906],
        [84.43818664550793, 18.66236114501953],
        [84.43791961669928, 18.66236114501953],
        [84.43791961669928, 18.661809921264705],
        [84.43736267089866, 18.661809921264705],
        [84.43736267089866, 18.66152954101574],
        [84.43708038330095, 18.66152954101574],
        [84.43708038330095, 18.661251068115348],
        [84.43681335449247, 18.661251068115348],
        [84.43681335449247, 18.66152954101574],
        [84.4365310668947, 18.66152954101574],
        [84.4365310668947, 18.661251068115348],
        [84.43625640869169, 18.661251068115348],
        [84.43625640869169, 18.660972595214957],
        [84.43597412109375, 18.660972595214957],
        [84.43597412109375, 18.66069221496582],
        [84.43569183349638, 18.66069221496582],
        [84.43569183349638, 18.66013908386236],
        [84.43541717529303, 18.66014289855957],
        [84.43541717529303, 18.659580230712834],
        [84.43514251708996, 18.659584045410213],
        [84.43514251708996, 18.65847015380865],
        [84.43486022949224, 18.65847015380865],
        [84.43486022949224, 18.657638549804688],
        [84.43459320068376, 18.657638549804688],
        [84.43459320068376, 18.65735816955572],
        [84.43430328369163, 18.657360076904297],
        [84.43430328369163, 18.65708351135254],
        [84.43402862548845, 18.65708351135254],
        [84.43402862548845, 18.65680503845215],
        [84.43374633789068, 18.656810760498104],
        [84.43374633789068, 18.656528472900334],
        [84.43347167968767, 18.656528472900334],
        [84.43347167968767, 18.656248092651367],
        [84.43319702148466, 18.656248092651367],
        [84.43319702148466, 18.65597915649414],
        [84.43290710449236, 18.65597915649414],
        [84.43290710449236, 18.655420303344783],
        [84.43264007568388, 18.655420303344783],
        [84.43264007568388, 18.655139923095817],
        [84.43235778808594, 18.655139923095817],
        [84.43235778808594, 18.654859542846623],
        [84.43208312988293, 18.654861450195426],
        [84.43208312988293, 18.65430641174322],
        [84.43152618408197, 18.65431022644043],
        [84.43152618408197, 18.654029846191463],
        [84.43131256103544, 18.654029846191463],
        [84.43096923828142, 18.654029846191463],
        [84.43096923828142, 18.65374946594244],
        [84.43068695068382, 18.65374946594244],
        [84.43068695068382, 18.653470993042106],
        [84.43041992187517, 18.653472900390682],
        [84.43041992187517, 18.652917861938477],
        [84.42986297607422, 18.652917861938477],
        [84.42986297607422, 18.652639389038086],
        [84.42903137207043, 18.652639389038086],
        [84.42903137207043, 18.652360916137752],
        [84.42874908447271, 18.652360916137752],
        [84.42874908447271, 18.65208053588873],
        [84.42763519287132, 18.65208244323736],
        [84.42763519287132, 18.651805877685547],
        [84.42791748046892, 18.651809692382926],
        [84.42791748046892, 18.651531219482536],
        [84.42764282226568, 18.651531219482536],
        [84.42764282226568, 18.6512508392334],
        [84.42708587646513, 18.6512508392334],
        [84.42708587646513, 18.65069007873541],
        [84.42680358886736, 18.650695800781364],
        [84.42680358886736, 18.650417327880803],
        [84.42652893066435, 18.650421142578182],
        [84.42652893066435, 18.650140762329215],
        [84.4262466430664, 18.650140762329215],
        [84.4262466430664, 18.64985275268549],
        [84.42597198486357, 18.64986038208002],
        [84.42597198486357, 18.64958572387701],
        [84.42569732666021, 18.64958572387701],
        [84.42569732666021, 18.649305343628043],
        [84.42542266845697, 18.649311065673828],
        [84.42542266845697, 18.64903068542486],
        [84.42514038085943, 18.64903068542486],
        [84.42514038085943, 18.648750305175895],
        [84.42485809326166, 18.648750305175895],
        [84.42485809326166, 18.64820289611822],
        [84.42457580566429, 18.64820289611822],
        [84.42457580566429, 18.647920608520508],
        [84.42431640625017, 18.647920608520508],
        [84.42431640625017, 18.64764022827154],
        [84.42402648925787, 18.64764022827154],
        [84.42402648925787, 18.64736175537115],
        [84.42375183105486, 18.647363662719727],
        [84.42375183105486, 18.647081375122184],
        [84.42346954345732, 18.647081375122184],
        [84.42346954345732, 18.64680480957037],
        [84.4231948852539, 18.64680480957037],
        [84.4231948852539, 18.646528244018555],
        [84.42292022705107, 18.646530151367188],
        [84.42292022705107, 18.645969390869197],
        [84.42263793945318, 18.645969390869197],
        [84.42263793945318, 18.645694732666016],
        [84.42236328125011, 18.645694732666016],
        [84.42236328125011, 18.645416259765625],
        [84.42180633544916, 18.645420074463004],
        [84.42180633544916, 18.645141601562614],
        [84.42124938964861, 18.645141601562614],
        [84.42124938964861, 18.644306182861442],
        [84.42096710205084, 18.644311904907227],
        [84.42096710205084, 18.644041061401367],
        [84.42069244384783, 18.644041061401367],
        [84.42069244384783, 18.64374923706049],
        [84.41958618164068, 18.64374923706049],
        [84.41958618164068, 18.6434707641601],
        [84.4190292358399, 18.643472671508903],
        [84.4190292358399, 18.643194198608512],
        [84.41874694824213, 18.643194198608512],
        [84.41874694824213, 18.642917633056697],
        [84.41847229003929, 18.642917633056697],
        [84.41847229003929, 18.642360687255973],
        [84.41819763183611, 18.642360687255973],
        [84.41819763183611, 18.64208030700678],
        [84.41791534423834, 18.64208412170416],
        [84.41791534423834, 18.641805648803768],
        [84.41764068603533, 18.641809463500977],
        [84.41764068603533, 18.641529083251953],
        [84.41735839843778, 18.641529083251953],
        [84.41735839843778, 18.64125061035162],
        [84.41707611084001, 18.64125061035162],
        [84.41707611084001, 18.64068984985346],
        [84.41680908203153, 18.640693664550838],
        [84.41680908203153, 18.640415191650447],
        [84.4165267944336, 18.640420913696403],
        [84.4165267944336, 18.640140533447266],
        [84.41541290283209, 18.640140533447266],
        [84.41541290283209, 18.639860153198242],
        [84.4145812988283, 18.639862060546875],
        [84.4145812988283, 18.639305114746094],
        [84.41430664062528, 18.63931083679205],
        [84.41430664062528, 18.639030456543082],
        [84.41401672363298, 18.639030456543082],
        [84.41401672363298, 18.63875007629389],
        [84.4137496948245, 18.63875007629389],
        [84.4137496948245, 18.638469696044922],
        [84.4134750366211, 18.638471603393555],
        [84.4134750366211, 18.638196945190487],
        [84.41319274902372, 18.638200759887695],
        [84.41319274902372, 18.637910842895565],
        [84.41291809082037, 18.63791656494152],
        [84.41291809082037, 18.63763809204096],
        [84.41263580322277, 18.637639999389762],
        [84.41263580322277, 18.637359619140568],
        [84.41236114501959, 18.63736152648937],
        [84.41236114501959, 18.63708305358898],
        [84.41208648681658, 18.63708305358898],
        [84.41208648681658, 18.636806488037166],
        [84.41179656982445, 18.636810302734375],
        [84.41179656982445, 18.63652992248541],
        [84.4115295410158, 18.63652992248541],
        [84.4115295410158, 18.636249542236385],
        [84.41124725341803, 18.636249542236385],
        [84.41124725341803, 18.63597106933605],
        [84.41097259521501, 18.635974884033203],
        [84.41097259521501, 18.635694503784237],
        [84.41041564941406, 18.635694503784237],
        [84.41041564941406, 18.635416030883846],
        [84.41014099121122, 18.635419845581055],
        [84.41014099121122, 18.63513946533203],
        [84.40985870361334, 18.63513946533203],
        [84.40985870361334, 18.634849548339957],
        [84.40958404541027, 18.634862899780273],
        [84.40958404541027, 18.634580612182674],
        [84.40930175781256, 18.634580612182674],
        [84.40930175781256, 18.634305953979492],
        [84.40902709960932, 18.63430976867687],
        [84.40902709960932, 18.634029388427678],
        [84.40875244140648, 18.634029388427678],
        [84.40875244140648, 18.63375282287609],
        [84.40819549560553, 18.63375282287609],
        [84.40819549560553, 18.63347053527832],
        [84.40791320800798, 18.63347053527832],
        [84.40791320800798, 18.633192062377987],
        [84.40763854980497, 18.633192062377987],
        [84.40763854980497, 18.632917404174748],
        [84.40735626220703, 18.632921218872127],
        [84.40735626220703, 18.632642745971737],
        [84.40708160400419, 18.632642745971737],
        [84.40708160400419, 18.63236236572277],
        [84.40680694580084, 18.63236236572277],
        [84.40680694580084, 18.632080078125],
        [84.40625, 18.63208389282238],
        [84.40625, 18.63180541992199],
        [84.40598297119158, 18.631811141967773],
        [84.40598297119158, 18.631530761718807],
        [84.40569305419945, 18.631530761718807],
        [84.40569305419945, 18.631248474121037],
        [84.40541839599626, 18.631248474121037],
        [84.40541839599626, 18.63096809387207],
        [84.4051361083985, 18.63097190856945],
        [84.4051361083985, 18.630695343017635],
        [84.40486145019548, 18.630699157714844],
        [84.40486145019548, 18.630420684814453],
        [84.40458679199247, 18.630420684814453],
        [84.40458679199247, 18.630138397216854],
        [84.40429687500017, 18.630138397216854],
        [84.40429687500017, 18.629859924316463],
        [84.40374755859375, 18.629861831665096],
        [84.40374755859375, 18.629583358764705],
        [84.40347290039074, 18.629583358764705],
        [84.40347290039074, 18.629304885864315],
        [84.40319824218756, 18.629304885864315],
        [84.40319061279303, 18.6290283203125],
        [84.40264129638695, 18.6290283203125],
        [84.40264129638695, 18.62874984741211],
        [84.40235900878923, 18.62874984741211],
        [84.40235900878923, 18.628469467163143],
        [84.402084350586, 18.628471374511776],
        [84.402084350586, 18.62819480895996],
        [84.40180206298845, 18.62819480895996],
        [84.40180206298845, 18.62791633605957],
        [84.40125274658203, 18.627918243408146],
        [84.40125274658203, 18.627639770507756],
        [84.40097045898466, 18.627639770507756],
        [84.40097045898466, 18.62735939025879],
        [84.40069580078125, 18.627361297607422],
        [84.40069580078125, 18.626806259155387],
        [84.40040588378935, 18.626810073852596],
        [84.40040588378935, 18.62625122070324],
        [84.400131225586, 18.62625122070324],
        [84.400131225586, 18.625970840454045],
        [84.39986419677729, 18.625972747802848],
        [84.39986419677729, 18.625694274902457],
        [84.39958190917974, 18.625694274902457],
        [84.39958190917974, 18.625415802002067],
        [84.39930725097673, 18.625419616699276],
        [84.39930725097673, 18.625141143798885],
        [84.39875030517595, 18.625141143798885],
        [84.39875030517595, 18.62486076354992],
        [84.39847564697294, 18.62486076354992],
        [84.39847564697294, 18.624584197998104],
        [84.39791870117216, 18.624584197998104],
        [84.39791870117216, 18.624305725097713],
        [84.39763641357422, 18.624309539794922],
        [84.39763641357422, 18.62403106689453],
        [84.39736175537138, 18.62403106689453],
        [84.39736175537138, 18.623750686645565],
        [84.39708709716803, 18.623750686645565],
        [84.39708709716803, 18.62347030639654],
        [84.39680480957043, 18.623472213745174],
        [84.39680480957043, 18.62291717529297],
        [84.39653015136724, 18.62291717529297],
        [84.39653015136724, 18.622638702392578],
        [84.39624786376947, 18.62264060974121],
        [84.39624786376947, 18.622360229492188],
        [84.39597320556663, 18.622360229492188],
        [84.39597320556663, 18.62207984924322],
        [84.39569091796892, 18.62208366394043],
        [84.39569091796892, 18.62180519104004],
        [84.39541625976568, 18.621810913085994],
        [84.39541625976568, 18.621530532837028],
        [84.39514160156267, 18.621530532837028],
        [84.39514160156267, 18.621250152587834],
        [84.39485931396513, 18.621250152587834],
        [84.39485931396513, 18.620969772338867],
        [84.39430236816435, 18.620973587036246],
        [84.39430236816435, 18.620695114135856],
        [84.394027709961, 18.62070083618164],
        [84.394027709961, 18.620420455932674],
        [84.39375305175793, 18.620420455932674],
        [84.39375305175793, 18.619871139526424],
        [84.39319610595697, 18.619871139526424],
        [84.39319610595697, 18.619579315185547],
        [84.3929138183596, 18.619581222534123],
        [84.3929138183596, 18.619304656982536],
        [84.39263916015642, 18.619304656982536],
        [84.39263916015642, 18.61902809143072],
        [84.39235687255865, 18.61903190612793],
        [84.39235687255865, 18.618751525878963],
        [84.39180755615263, 18.618751525878963],
        [84.39180755615263, 18.618469238281193],
        [84.39125061035185, 18.618471145629996],
        [84.39125061035185, 18.618194580078182],
        [84.3909759521485, 18.618194580078182],
        [84.3909759521485, 18.61791610717779],
        [84.39068603515653, 18.617921829223746],
        [84.39068603515653, 18.61735916137701],
        [84.39041900634771, 18.61735916137701],
        [84.39041900634771, 18.61708068847662],
        [84.39013671874994, 18.617082595825252],
        [84.39013671874994, 18.616806030273438],
        [84.3898620605471, 18.616811752319393],
        [84.3898620605471, 18.616527557373047],
        [84.38957977294939, 18.616527557373047],
        [84.38957977294939, 18.616249084472656],
        [84.38931274414068, 18.616249084472656],
        [84.38931274414068, 18.615970611572266],
        [84.38847351074219, 18.6159725189209],
        [84.38847351074219, 18.615694046020508],
        [84.3876419067384, 18.615694046020508],
        [84.3876419067384, 18.614583969116325],
        [84.38735961914068, 18.614583969116325],
        [84.38735961914068, 18.614305496215934],
        [84.38707733154308, 18.61431121826172],
        [84.38707733154308, 18.614030838012752],
        [84.3868026733399, 18.614030838012752],
        [84.3868026733399, 18.61374092102062],
        [84.38652801513689, 18.613750457763786],
        [84.38652801513689, 18.613471984863395],
        [84.38625335693365, 18.613471984863395],
        [84.38625335693365, 18.61291694641119],
        [84.38597106933611, 18.6129207611084],
        [84.38597106933611, 18.612640380859432],
        [84.3854064941408, 18.612640380859432],
        [84.3854064941408, 18.61236000061041],
        [84.38513946533232, 18.61236190795904],
        [84.38513946533232, 18.61208343505865],
        [84.3848648071289, 18.61208343505865],
        [84.3848648071289, 18.611806869506836],
        [84.38403320312494, 18.611810684204215],
        [84.38403320312494, 18.611530303955078],
        [84.38346862792963, 18.611530303955078],
        [84.38346862792963, 18.611249923706055],
        [84.38318634033226, 18.611249923706055],
        [84.38318634033226, 18.610679626464844],
        [84.38291931152361, 18.610694885253906],
        [84.38291931152361, 18.610416412353516],
        [84.38263702392584, 18.610420227050895],
        [84.38263702392584, 18.6101398468017],
        [84.38236236572283, 18.6101398468017],
        [84.38236236572283, 18.609582901000977],
        [84.38208007812528, 18.609584808349723],
        [84.38208007812528, 18.609306335449332],
        [84.38153076171903, 18.60931015014654],
        [84.38153076171903, 18.609029769897575],
        [84.38124847412115, 18.609029769897575],
        [84.38124847412115, 18.60874938964838],
        [84.38096618652355, 18.60874938964838],
        [84.38096618652355, 18.608190536499023],
        [84.38041687011713, 18.608194351196403],
        [84.38041687011713, 18.607915878296012],
        [84.37985992431658, 18.607931137084904],
        [84.37985992431658, 18.60736274719244],
        [84.37958526611334, 18.60736274719244],
        [84.37958526611334, 18.60708427429205],
        [84.37875366210938, 18.60708427429205],
        [84.37875366210938, 18.60680580139166],
        [84.378471374512, 18.606809616088867],
        [84.378471374512, 18.606531143188477],
        [84.37819671630865, 18.606531143188477],
        [84.37819671630865, 18.606252670288086],
        [84.37790679931669, 18.606252670288086],
        [84.37790679931669, 18.605970382690487],
        [84.37763977050787, 18.605970382690487],
        [84.37763977050787, 18.605417251586914],
        [84.3773574829101, 18.605421066284293],
        [84.3773574829101, 18.605142593383903],
        [84.37708282470726, 18.605142593383903],
        [84.37708282470726, 18.604871749878043],
        [84.37680816650408, 18.604871749878043],
        [84.37680816650408, 18.604579925537166],
        [84.3765258789063, 18.604583740234375],
        [84.3765258789063, 18.604305267333984],
        [84.3762512207033, 18.604309082031364],
        [84.3762512207033, 18.603469848632812],
        [84.37596893310575, 18.603471755981445],
        [84.37596893310575, 18.6031951904298],
        [84.37568664550798, 18.603200912475586],
        [84.37568664550798, 18.60263824462885],
        [84.3754196166995, 18.60263824462885],
        [84.3754196166995, 18.60235977172846],
        [84.37513732910156, 18.602361679077262],
        [84.37513732910156, 18.60208320617687],
        [84.37486267089855, 18.60208320617687],
        [84.37486267089855, 18.60180473327648],
        [84.3743057250976, 18.60180473327648],
        [84.3743057250976, 18.60152626037609],
        [84.37403106689476, 18.601528167724666],
        [84.37403106689476, 18.601249694824276],
        [84.37346649169945, 18.601249694824276],
        [84.37346649169945, 18.600971221923942],
        [84.37319183349626, 18.600973129272518],
        [84.37319183349626, 18.600694656372127],
        [84.37291717529325, 18.600694656372127],
        [84.37291717529325, 18.600139617919922],
        [84.37264251708984, 18.600139617919922],
        [84.37264251708984, 18.599861145019588],
        [84.37236022949247, 18.599861145019588],
        [84.37236022949247, 18.599580764770565],
        [84.37208557128906, 18.599582672119197],
        [84.37208557128906, 18.599306106567383],
        [84.37180328369169, 18.599309921264762],
        [84.37180328369169, 18.599029541015568],
        [84.37152862548834, 18.599029541015568],
        [84.37152862548834, 18.59847068786621],
        [84.37124633789074, 18.598472595214844],
        [84.37124633789074, 18.598194122314453],
        [84.37097167968756, 18.598194122314453],
        [84.37097167968756, 18.59791755676264],
        [84.37069702148455, 18.597919464111442],
        [84.37069702148455, 18.59764099121105],
        [84.37040710449241, 18.59764099121105],
        [84.37040710449241, 18.59708023071289],
        [84.37014007568376, 18.597085952758846],
        [84.37014007568376, 18.59680557250988],
        [84.369857788086, 18.596809387207088],
        [84.369857788086, 18.596530914306697],
        [84.36958312988298, 18.596530914306697],
        [84.36958312988298, 18.59625053405773],
        [84.36930847167997, 18.59625053405773],
        [84.36930847167997, 18.59570121765148],
        [84.36902618408203, 18.59570121765148],
        [84.36902618408203, 18.595420837402344],
        [84.36875152587919, 18.595420837402344],
        [84.36875152587919, 18.595140457153377],
        [84.3684692382813, 18.595140457153377],
        [84.3684692382813, 18.594860076904354],
        [84.36819458007824, 18.594863891601562],
        [84.36819458007824, 18.594583511352596],
        [84.36791992187506, 18.594583511352596],
        [84.36791992187506, 18.59402847290039],
        [84.36763763427729, 18.594030380249023],
        [84.36763763427729, 18.593751907348633],
        [84.36736297607445, 18.593751907348633],
        [84.36736297607445, 18.593469619751033],
        [84.36708068847673, 18.593473434448242],
        [84.36708068847673, 18.59319496154785],
        [84.3668060302735, 18.593200683593807],
        [84.3668060302735, 18.59264183044445],
        [84.36653137207048, 18.59264183044445],
        [84.36653137207048, 18.59235954284668],
        [84.36624908447294, 18.59235954284668],
        [84.36624908447294, 18.59208106994629],
        [84.36597442626953, 18.59208106994629],
        [84.36597442626953, 18.591806411743278],
        [84.36569213867216, 18.591810226440487],
        [84.36569213867216, 18.591249465942326],
        [84.3654174804688, 18.591249465942326],
        [84.3654174804688, 18.59096908569336],
        [84.36513519287121, 18.59097290039074],
        [84.36513519287121, 18.590694427490348],
        [84.36486053466803, 18.590694427490348],
        [84.36486053466803, 18.590415954589957],
        [84.36458587646479, 18.590421676635742],
        [84.36458587646479, 18.590137481689567],
        [84.36429595947271, 18.590137481689567],
        [84.36429595947271, 18.589570999145508],
        [84.36402893066423, 18.58958244323742],
        [84.36402893066423, 18.589305877685604],
        [84.36374664306646, 18.589309692382812],
        [84.36374664306646, 18.589031219482422],
        [84.36347198486345, 18.589031219482422],
        [84.36347198486345, 18.588750839233455],
        [84.36319732666044, 18.588750839233455],
        [84.36319732666044, 18.588470458984432],
        [84.3629150390625, 18.588472366333065],
        [84.3629150390625, 18.58763885498047],
        [84.36264038085966, 18.5876407623291],
        [84.36264038085966, 18.587360382080078],
        [84.36235809326172, 18.587360382080078],
        [84.36235809326172, 18.58708000183111],
        [84.36208343505871, 18.58708381652832],
        [84.36208343505871, 18.58680534362793],
        [84.36180877685553, 18.58680534362793],
        [84.36180877685553, 18.586250305175724],
        [84.36152648925776, 18.586250305175724],
        [84.36152648925776, 18.584859848022404],
        [84.36125183105491, 18.584861755371207],
        [84.36125183105491, 18.584306716919002],
        [84.3609695434572, 18.584306716919002],
        [84.3609695434572, 18.58402824401861],
        [84.3606872558596, 18.584030151367244],
        [84.3606872558596, 18.583469390869084],
        [84.36042022705095, 18.583471298217887],
        [84.36042022705095, 18.58208465576172],
        [84.36013793945341, 18.58208465576172],
        [84.36013793945341, 18.581806182861328],
        [84.35986328125, 18.581809997558707],
        [84.35986328125, 18.581529617309513],
        [84.35958099365263, 18.581529617309513],
        [84.35958099365263, 18.58125114440918],
        [84.35930633544922, 18.58125114440918],
        [84.35930633544922, 18.58097267150879],
        [84.35902404785185, 18.58097267150879],
        [84.35902404785185, 18.5806941986084],
        [84.3587493896485, 18.580699920654354],
        [84.3587493896485, 18.580141067504996],
        [84.3584671020509, 18.580141067504996],
        [84.3584671020509, 18.579862594604606],
        [84.35819244384771, 18.579862594604606],
        [84.35819244384771, 18.579580307006836],
        [84.3579177856447, 18.579584121704215],
        [84.3579177856447, 18.578752517700252],
        [84.3576354980471, 18.578752517700252],
        [84.3576354980471, 18.57708358764654],
        [84.35736083984392, 18.57708358764654],
        [84.35736083984392, 18.57680511474615],
        [84.35708618164091, 18.576810836792106],
        [84.35708618164091, 18.57653045654297],
        [84.35679626464861, 18.57653045654297],
        [84.35679626464861, 18.57624816894537],
        [84.35652923584013, 18.57624816894537],
        [84.35652923584013, 18.57596969604498],
        [84.35624694824219, 18.57597160339361],
        [84.35624694824219, 18.575695037841797],
        [84.35597229003935, 18.575700759887752],
        [84.35597229003935, 18.574859619140625],
        [84.355697631836, 18.574861526489258],
        [84.355697631836, 18.569585800171012],
        [84.35597229003935, 18.569585800171012],
        [84.35597229003935, 18.56875038146967],
        [84.355697631836, 18.568759918213004],
        [84.355697631836, 18.568471908569336],
        [84.3554153442384, 18.568473815918082],
        [84.3554153442384, 18.56736373901373],
        [84.35514068603521, 18.56736373901373],
        [84.35514068603521, 18.566930770873967],
        [84.35514068603521, 18.565694808960018],
        [84.35485839843744, 18.565694808960018],
        [84.35485839843744, 18.564306259155273],
        [84.35457611084007, 18.564310073852653],
        [84.35457611084007, 18.564031600952262],
        [84.35430908203142, 18.564031600952262],
        [84.35430908203142, 18.563749313354492],
        [84.35402679443365, 18.563749313354492],
        [84.35402679443365, 18.563190460205135],
        [84.35363769531256, 18.563192367553768],
        [84.35319519042969, 18.563194274902344],
        [84.35319519042969, 18.562915802001953],
        [84.35180664062494, 18.562919616699332],
        [84.35180664062494, 18.56262969970703],
        [84.35069274902361, 18.562637329101562],
        [84.35069274902361, 18.562360763549748],
        [84.35013580322266, 18.562360763549748],
        [84.35013580322266, 18.562637329101562],
        [84.34930419921886, 18.562637329101562],
        [84.34930419921886, 18.562360763549748],
        [84.34819793701189, 18.562360763549748],
        [84.34819793701189, 18.56208038330078],
        [84.34652709960938, 18.56208038330078],
        [84.34652709960938, 18.562360763549748],
        [84.34625244140636, 18.562360763549748],
        [84.34625244140636, 18.56208038330078],
        [84.34597015380865, 18.56208419799816],
        [84.34597015380865, 18.56180572509777],
        [84.34513854980486, 18.56180953979498],
        [84.34513854980486, 18.561531066894588],
        [84.34430694580107, 18.561531066894588],
        [84.34430694580107, 18.56125068664562],
        [84.34401702880876, 18.56125068664562],
        [84.34401702880876, 18.560970306396428],
        [84.34375000000028, 18.56097221374523],
        [84.34375000000028, 18.56069374084484],
        [84.34347534179688, 18.56069374084484],
        [84.34347534179688, 18.560138702392635],
        [84.3431930541995, 18.560140609741268],
        [84.3431930541995, 18.559860229492244],
        [84.34291076660162, 18.559860229492244],
        [84.34291076660162, 18.559589385986442],
        [84.34263610839855, 18.559589385986442],
        [84.34263610839855, 18.55931091308605],
        [84.34236145019537, 18.55931091308605],
        [84.34236145019537, 18.559030532836914],
        [84.34208679199236, 18.559030532836914],
        [84.34208679199236, 18.55875015258789],
        [84.34179687500023, 18.55875015258789],
        [84.34179687500023, 18.558200836181697],
        [84.34152984619158, 18.558200836181697],
        [84.34152984619158, 18.55792045593273],
        [84.3412475585938, 18.55792045593273],
        [84.3412475585938, 18.557640075683537],
        [84.3409729003908, 18.557640075683537],
        [84.3409729003908, 18.55708122253418],
        [84.34081268310553, 18.557083129882812],
        [84.34069824218778, 18.557083129882812],
        [84.34069061279325, 18.556249618530217],
        [84.34041595458984, 18.556249618530217],
        [84.34041595458984, 18.55569076538086],
        [84.340141296387, 18.55569458007824],
        [84.340141296387, 18.555139541626033],
        [84.33985137939453, 18.555139541626033],
        [84.33985137939453, 18.554580688476506],
        [84.340141296387, 18.554580688476506],
        [84.340141296387, 18.553749084472713],
        [84.34041595458984, 18.553752899169922],
        [84.34041595458984, 18.553190231323185],
        [84.34069061279325, 18.55319213867199],
        [84.34069061279325, 18.552640914916992],
        [84.34041595458984, 18.55264282226574],
        [84.34041595458984, 18.5523624420166],
        [84.340141296387, 18.5523624420166],
        [84.340141296387, 18.552080154419002],
        [84.33985900878912, 18.55208396911621],
        [84.33985900878912, 18.55180549621582],
        [84.33957672119152, 18.551811218261776],
        [84.33957672119152, 18.551532745361385],
        [84.33930206298834, 18.551532745361385],
        [84.33930206298834, 18.550968170166072],
        [84.3390274047851, 18.55097007751465],
        [84.3390274047851, 18.55069351196289],
        [84.33985900878912, 18.55069351196289],
        [84.33985900878912, 18.55097007751465],
        [84.34041595458984, 18.550968170166072],
        [84.34041595458984, 18.55124855041504],
        [84.3412475585938, 18.55124855041504],
        [84.3412475585938, 18.551532745361385],
        [84.34152984619158, 18.551532745361385],
        [84.34152984619158, 18.551811218261776],
        [84.34179687500023, 18.551811218261776],
        [84.34179687500023, 18.55264282226574],
        [84.34152984619158, 18.552640914916992],
        [84.34152984619158, 18.554740905761832],
        [84.34152984619158, 18.55597305297863],
        [84.34179687500023, 18.555971145629826],
        [84.34179687500023, 18.556249618530217],
        [84.34208679199236, 18.556249618530217],
        [84.34208679199236, 18.55652999877941],
        [84.34263610839855, 18.556528091430607],
        [84.34263610839855, 18.556804656982422],
        [84.34291839599615, 18.556804656982422],
        [84.34291839599615, 18.557083129882812],
        [84.3431930541995, 18.55708122253418],
        [84.3431930541995, 18.557640075683537],
        [84.34348297119146, 18.557640075683537],
        [84.34348297119146, 18.55792045593273],
        [84.34401702880876, 18.55792045593273],
        [84.34401702880876, 18.558200836181697],
        [84.34430694580107, 18.558195114135742],
        [84.34430694580107, 18.558471679687557],
        [84.34458160400408, 18.558469772338924],
        [84.34458160400408, 18.55875015258789],
        [84.34485626220726, 18.55875015258789],
        [84.34485626220726, 18.559030532836914],
        [84.34541320800787, 18.559030532836914],
        [84.34541320800787, 18.55931091308605],
        [84.34570312499994, 18.55931091308605],
        [84.34570312499994, 18.559589385986442],
        [84.34735870361357, 18.559589385986442],
        [84.34735870361357, 18.559860229492244],
        [84.34847259521507, 18.559860229492244],
        [84.34847259521507, 18.560140609741268],
        [84.34958648681646, 18.560140609741268],
        [84.34958648681646, 18.560420989990234],
        [84.3504180908206, 18.56041526794445],
        [84.3504180908206, 18.56069374084484],
        [84.35124969482439, 18.56069374084484],
        [84.35124969482439, 18.56097221374523],
        [84.35263824462896, 18.560970306396428],
        [84.35263824462896, 18.56125068664562],
        [84.35291290283232, 18.56125068664562],
        [84.35291290283232, 18.561531066894588],
        [84.35305786132835, 18.561531066894588],
        [84.35320281982422, 18.561531066894588],
        [84.35320281982422, 18.56180953979498],
        [84.3534698486331, 18.56180572509777],
        [84.3534698486331, 18.56208419799816],
        [84.35430908203142, 18.56208038330078],
        [84.35430908203142, 18.562360763549748],
        [84.3545837402346, 18.562360763549748],
        [84.3545837402346, 18.562637329101562],
        [84.35514068603521, 18.56262969970703],
        [84.35514068603521, 18.562919616699332],
        [84.355697631836, 18.562915802001953],
        [84.355697631836, 18.563194274902344],
        [84.35652923584013, 18.563194274902344],
        [84.35652923584013, 18.56125068664562],
        [84.35624694824219, 18.56125068664562],
        [84.35624694824219, 18.56068992614746],
        [84.35597229003935, 18.56069374084484],
        [84.35597229003935, 18.559860229492244],
        [84.355697631836, 18.559860229492244],
        [84.355697631836, 18.559589385986442],
        [84.35542297363276, 18.559589385986442],
        [84.35542297363276, 18.558469772338924],
        [84.355697631836, 18.558471679687557],
        [84.355697631836, 18.558195114135742],
        [84.35597229003935, 18.558200836181697],
        [84.35597229003935, 18.55735969543457],
        [84.35624694824219, 18.557361602783203],
        [84.35624694824219, 18.556804656982422],
        [84.35597229003935, 18.556804656982422],
        [84.35597229003935, 18.556528091430607],
        [84.355697631836, 18.55652999877941],
        [84.355697631836, 18.556249618530217],
        [84.35514068603521, 18.556249618530217],
        [84.35514068603521, 18.555971145629826],
        [84.35430908203142, 18.55597305297863],
        [84.35430908203142, 18.55569458007824],
        [84.35375213623064, 18.55569458007824],
        [84.35375213623064, 18.555416107177848],
        [84.3534698486331, 18.555419921875057],
        [84.3534698486331, 18.555139541626033],
        [84.35291290283232, 18.555139541626033],
        [84.35291290283232, 18.5548610687257],
        [84.35263824462896, 18.5548610687257],
        [84.35263824462896, 18.554580688476506],
        [84.3523635864259, 18.554580688476506],
        [84.3523635864259, 18.554306030273494],
        [84.35208129882818, 18.554309844970703],
        [84.35208129882818, 18.55402946472168],
        [84.35180664062494, 18.55402946472168],
        [84.35180664062494, 18.553752899169922],
        [84.35124969482439, 18.553752899169922],
        [84.35124969482439, 18.553472518920955],
        [84.35097503662115, 18.553472518920955],
        [84.35097503662115, 18.55319213867199],
        [84.35069274902361, 18.55319213867199],
        [84.35069274902361, 18.55291748046875],
        [84.3504180908206, 18.552919387817326],
        [84.3504180908206, 18.55264282226574],
        [84.34986114501982, 18.55264282226574],
        [84.34986114501982, 18.5523624420166],
        [84.34958648681646, 18.5523624420166],
        [84.34958648681646, 18.552080154419002],
        [84.34930419921886, 18.55208396911621],
        [84.34930419921886, 18.55180549621582],
        [84.34903717041021, 18.551811218261776],
        [84.34903717041021, 18.551532745361385],
        [84.34874725341791, 18.551532745361385],
        [84.34874725341791, 18.55124855041504],
        [84.34819793701189, 18.55124855041504],
        [84.34819793701189, 18.550968170166072],
        [84.34791564941412, 18.55097007751465],
        [84.34791564941412, 18.550695419311637],
        [84.34764099121111, 18.55069351196289],
        [84.34764099121111, 18.550416946411246],
        [84.34735870361357, 18.550420761108455],
        [84.34735870361357, 18.550138473510685],
        [84.34680175781278, 18.550138473510685],
        [84.34680175781278, 18.54985809326172],
        [84.34652709960938, 18.549861907959098],
        [84.34652709960938, 18.549583435058707],
        [84.34625244140636, 18.549583435058707],
        [84.34625244140636, 18.549304962158317],
        [84.34597778320318, 18.5493106842041],
        [84.34597778320318, 18.549028396606502],
        [84.34570312499994, 18.549028396606502],
        [84.34570312499994, 18.54874992370611],
        [84.34513854980486, 18.54874992370611],
        [84.34513854980486, 18.548469543456974],
        [84.34486389160162, 18.548471450805778],
        [84.34486389160162, 18.548194885253963],
        [84.34458160400408, 18.548194885253963],
        [84.34458160400408, 18.547916412353572],
        [84.34430694580107, 18.54792022705078],
        [84.34430694580107, 18.547647476196346],
        [84.34375000000028, 18.547647476196346],
        [84.34375000000028, 18.54735946655279],
        [84.34347534179688, 18.547361373901424],
        [84.34347534179688, 18.547082901001033],
        [84.3431930541995, 18.547082901001033],
        [84.3431930541995, 18.54680633544922],
        [84.34291076660162, 18.546810150146598],
        [84.34291076660162, 18.546529769897404],
        [84.34236145019537, 18.546529769897404],
        [84.34236145019537, 18.546249389648438],
        [84.34208679199236, 18.546249389648438],
        [84.34208679199236, 18.545970916748047],
        [84.34180450439476, 18.54597282409668],
        [84.34180450439476, 18.54569435119629],
        [84.34152984619158, 18.54569435119629],
        [84.34152984619158, 18.5454158782959],
        [84.3412475585938, 18.545419692993278],
        [84.3412475585938, 18.545141220092887],
        [84.34069824218778, 18.545141220092887],
        [84.34069061279325, 18.544860839843693],
        [84.34041595458984, 18.544860839843693],
        [84.34041595458984, 18.54458999633789],
        [84.340141296387, 18.54458999633789],
        [84.340141296387, 18.544309616088924],
        [84.33985137939453, 18.544309616088924],
        [84.33985137939453, 18.544031143188533],
        [84.33930206298834, 18.544031143188533],
        [84.33930206298834, 18.543750762939567],
        [84.3390274047851, 18.543750762939567],
        [84.3390274047851, 18.543470382690373],
        [84.33875274658226, 18.543472290039176],
        [84.33875274658226, 18.54319572448736],
        [84.33847045898455, 18.54319572448736],
        [84.33847045898455, 18.54291725158697],
        [84.3381958007813, 18.54292106628418],
        [84.3381958007813, 18.542640686035213],
        [84.33766174316435, 18.542640686035213],
        [84.33763885498075, 18.54236030578619],
        [84.33736419677734, 18.54236030578619],
        [84.33736419677734, 18.542083740234432],
        [84.33719635009771, 18.542083740234432],
        [84.33708190917997, 18.542083740234432],
        [84.33708190917997, 18.541950225830078],
        [84.33708190917997, 18.54180526733404],
        [84.33693695068376, 18.54180526733404],
        [84.33680725097662, 18.541810989379996],
        [84.33680725097662, 18.541641235351562],
        [84.33680725097662, 18.541528701782227],
        [84.33663940429693, 18.541528701782227],
        [84.33625030517584, 18.54153060913086],
        [84.33625030517584, 18.541259765625],
        [84.33598327636736, 18.541250228881836],
        [84.3359756469726, 18.540973663330078],
        [84.33583068847656, 18.540973663330078],
        [84.33569335937523, 18.540973663330078],
        [84.33569335937523, 18.540695190429688],
        [84.33542633056658, 18.54070281982422],
        [84.33541870117205, 18.540420532226676],
        [84.33513641357428, 18.540420532226676],
        [84.33513641357428, 18.540140151977482],
        [84.33460235595732, 18.540140151977482],
        [84.33458709716825, 18.53986167907715],
        [84.33430480957031, 18.539863586425895],
        [84.33430480957031, 18.539581298828125],
        [84.33403015136747, 18.539581298828125],
        [84.33403015136747, 18.539304733276367],
        [84.33374786376953, 18.539304733276367],
        [84.33374786376953, 18.539028167724553],
        [84.3331909179688, 18.539030075073356],
        [84.3331909179688, 18.538751602172965],
        [84.33291625976557, 18.538751602172965],
        [84.33291625976557, 18.538459777832088],
        [84.33264160156273, 18.538473129272575],
        [84.33264160156273, 18.538194656372184],
        [84.33235931396501, 18.538194656372184],
        [84.33235931396501, 18.537916183471793],
        [84.33207702636741, 18.537921905517578],
        [84.33207702636741, 18.53764152526861],
        [84.33152770996122, 18.53764152526861],
        [84.33152770996122, 18.53735923767084],
        [84.33125305175781, 18.53735923767084],
        [84.33125305175781, 18.53708076477045],
        [84.33097076416044, 18.537082672119254],
        [84.33097076416044, 18.53680610656744],
        [84.33069610595703, 18.536811828613395],
        [84.33069610595703, 18.53652763366705],
        [84.3301391601563, 18.53652763366705],
        [84.3301391601563, 18.53624916076666],
        [84.32985687255871, 18.53624916076666],
        [84.32985687255871, 18.535970687866268],
        [84.32958221435553, 18.5359725952149],
        [84.32958221435553, 18.53569412231451],
        [84.32930755615251, 18.53569412231451],
        [84.32930755615251, 18.535417556762695],
        [84.32903289794928, 18.535417556762695],
        [84.32903289794928, 18.535139083862305],
        [84.32847595214872, 18.535139083862305],
        [84.32847595214872, 18.534860610961914],
        [84.32818603515642, 18.534860610961914],
        [84.32818603515642, 18.534580230712947],
        [84.32791900634794, 18.534584045410156],
        [84.32791900634794, 18.534305572509766],
        [84.32763671875, 18.534309387207145],
        [84.32763671875, 18.534030914306754],
        [84.32707977294928, 18.534030914306754],
        [84.32707977294928, 18.53375053405756],
        [84.32680511474621, 18.53375053405756],
        [84.32680511474621, 18.533472061157227],
        [84.32653045654303, 18.533472061157227],
        [84.32653045654303, 18.533193588256836],
        [84.32624816894526, 18.533193588256836],
        [84.32624816894526, 18.532915115356445],
        [84.32596588134788, 18.5329208374024],
        [84.32596588134788, 18.532648086547965],
        [84.32541656494146, 18.532648086547965],
        [84.32541656494146, 18.53236007690424],
        [84.32514190673845, 18.532361984253043],
        [84.32514190673845, 18.532083511352653],
        [84.32485961914091, 18.532083511352653],
        [84.32485961914091, 18.531806945800838],
        [84.32457733154297, 18.531810760498047],
        [84.32457733154297, 18.53153038024908],
        [84.32402801513678, 18.53153038024908],
        [84.32402801513678, 18.53125],
        [84.32375335693371, 18.53125],
        [84.32375335693371, 18.53096961975092],
        [84.323471069336, 18.530971527099723],
        [84.323471069336, 18.530693054199332],
        [84.32319641113276, 18.530700683593864],
        [84.32319641113276, 18.530420303344727],
        [84.3226394653322, 18.530420303344727],
        [84.3226394653322, 18.530139923095703],
        [84.32235717773443, 18.530139923095703],
        [84.32235717773443, 18.529859542846737],
        [84.32208251953142, 18.52986145019537],
        [84.32208251953142, 18.529584884643555],
        [84.32180786132841, 18.52959060668951],
        [84.32180786132841, 18.529310226440543],
        [84.321533203125, 18.529310226440543],
        [84.321533203125, 18.52902984619135],
        [84.32096862792969, 18.52902984619135],
        [84.32096862792969, 18.528749465942383],
        [84.32068634033232, 18.528749465942383],
        [84.32068634033232, 18.528472900390625],
        [84.3204193115235, 18.52847480773937],
        [84.3204193115235, 18.528194427490234],
        [84.32013702392572, 18.528194427490234],
        [84.32013702392572, 18.527915954589844],
        [84.31958007812517, 18.527919769287223],
        [84.31958007812517, 18.52763938903803],
        [84.31930541992193, 18.52763938903803],
        [84.31930541992193, 18.527362823486442],
        [84.31903076171892, 18.527362823486442],
        [84.31903076171892, 18.527080535888672],
        [84.31874847412138, 18.527080535888672],
        [84.31874847412138, 18.52680587768566],
        [84.31846618652361, 18.52680969238287],
        [84.31846618652361, 18.526521682739315],
        [84.31791687011719, 18.52652740478527],
        [84.31791687011719, 18.526252746582088],
        [84.31764221191418, 18.526252746582088],
        [84.31764221191418, 18.525970458984318],
        [84.31735992431646, 18.525970458984318],
        [84.31735992431646, 18.52569389343273],
        [84.31708526611322, 18.52569389343273],
        [84.31708526611322, 18.525417327880916],
        [84.31652832031267, 18.525421142578125],
        [84.31652832031267, 18.525142669677734],
        [84.31624603271507, 18.525142669677734],
        [84.31624603271507, 18.52485847473156],
        [84.31597137451189, 18.52485847473156],
        [84.31597137451189, 18.524578094482422],
        [84.31569671630888, 18.524583816528377],
        [84.31569671630888, 18.524305343627987],
        [84.31540679931658, 18.52430915832514],
        [84.31540679931658, 18.524030685424805],
        [84.31485748291016, 18.524030685424805],
        [84.31485748291016, 18.523748397827205],
        [84.31458282470732, 18.523748397827205],
        [84.31458282470732, 18.523460388183707],
        [84.31430816650396, 18.523471832275447],
        [84.31430816650396, 18.523195266723633],
        [84.31402587890636, 18.523199081421012],
        [84.31402587890636, 18.52292060852062],
        [84.31346893310541, 18.52292060852062],
        [84.31346893310541, 18.52263832092285],
        [84.31318664550804, 18.52263832092285],
        [84.31318664550804, 18.52235984802246],
        [84.31291961669939, 18.522361755371094],
        [84.31291961669939, 18.522083282470703],
        [84.31236267089861, 18.522083282470703],
        [84.31236267089861, 18.521804809570312],
        [84.31208038330107, 18.521804809570312],
        [84.31208038330107, 18.521526336669922],
        [84.31181335449219, 18.521528244018498],
        [84.31181335449219, 18.521249771118107],
        [84.31124877929693, 18.521249771118107],
        [84.31124877929693, 18.52096939086914],
        [84.31097412109386, 18.520971298217717],
        [84.31097412109386, 18.52069473266613],
        [84.31069183349615, 18.52069473266613],
        [84.31069183349615, 18.52041625976574],
        [84.31041717529291, 18.520418167114315],
        [84.31041717529291, 18.520139694213924],
        [84.30986022949236, 18.520139694213924],
        [84.30986022949236, 18.51986122131359],
        [84.30958557128912, 18.51986122131359],
        [84.30958557128912, 18.5195827484132],
        [84.30930328369158, 18.5195827484132],
        [84.30930328369158, 18.519306182861385],
        [84.30874633789062, 18.519309997558594],
        [84.30874633789062, 18.51902961730957],
        [84.30847167968778, 18.51902961730957],
        [84.30847167968778, 18.518751144409237],
        [84.30819702148443, 18.518751144409237],
        [84.30819702148443, 18.518470764160213],
        [84.30764007568365, 18.518472671508846],
        [84.30764007568365, 18.518194198608455],
        [84.30735778808588, 18.518194198608455],
        [84.30735778808588, 18.51791763305664],
        [84.30708312988304, 18.517919540405217],
        [84.30708312988304, 18.517641067504883],
        [84.30652618408209, 18.517641067504883],
        [84.30652618408209, 18.51736068725586],
        [84.30625152587908, 18.51736068725586],
        [84.30625152587908, 18.517080307006893],
        [84.30596923828153, 18.5170841217041],
        [84.30596923828153, 18.51680564880371],
        [84.30568695068376, 18.51680946350109],
        [84.30568695068376, 18.5165309906007],
        [84.30513763427734, 18.5165309906007],
        [84.30513763427734, 18.516250610351506],
        [84.30486297607433, 18.516250610351506],
        [84.30486297607433, 18.51597023010254],
        [84.30458068847662, 18.515972137451172],
        [84.30458068847662, 18.51569366455078],
        [84.30403137207054, 18.51569366455078],
        [84.30403137207054, 18.515417098999137],
        [84.30374145507807, 18.515420913696346],
        [84.30374145507807, 18.51514053344738],
        [84.30346679687523, 18.51514053344738],
        [84.30346679687523, 18.514860153198185],
        [84.30291748046903, 18.51486206054699],
        [84.30291748046903, 18.514583587646598],
        [84.30263519287126, 18.514583587646598],
        [84.30263519287126, 18.514305114746207],
        [84.30236053466825, 18.514310836791992],
        [84.30236053466825, 18.514030456543026],
        [84.30208587646484, 18.514030456543026],
        [84.30208587646484, 18.513750076294002],
        [84.30152893066412, 18.513750076294002],
        [84.30152893066412, 18.513469696044865],
        [84.30124664306652, 18.513473510742244],
        [84.30124664306652, 18.513195037841854],
        [84.30097198486334, 18.51320075988781],
        [84.30097198486334, 18.512920379638672],
        [84.30042266845709, 18.512920379638672],
        [84.30042266845709, 18.51264190673828],
        [84.30014038085955, 18.51264190673828],
        [84.30014038085955, 18.512359619140682],
        [84.29985809326178, 18.512359619140682],
        [84.29985809326178, 18.51208114624029],
        [84.29930877685575, 18.51208114624029],
        [84.29930877685575, 18.51180648803711],
        [84.29902648925781, 18.51181030273449],
        [84.29902648925781, 18.51151847839361],
        [84.29875183105497, 18.511529922485295],
        [84.29875183105497, 18.51125144958496],
        [84.29846954345709, 18.51125144958496],
        [84.29846954345709, 18.51096916198736],
        [84.29792022705084, 18.510971069335938],
        [84.29792022705084, 18.51069450378418],
        [84.29763793945307, 18.51069450378418],
        [84.29763793945307, 18.51041603088379],
        [84.29736328125023, 18.510421752929744],
        [84.29736328125023, 18.510141372680778],
        [84.29708099365251, 18.510141372680778],
        [84.29708099365251, 18.509859085083008],
        [84.2965164184572, 18.509859085083008],
        [84.2965164184572, 18.509580612182617],
        [84.29624938964872, 18.50958251953125],
        [84.29624938964872, 18.509305953979606],
        [84.29596710205078, 18.509309768676815],
        [84.29596710205078, 18.509027481079215],
        [84.29541778564459, 18.509027481079215],
        [84.29541778564459, 18.508750915527457],
        [84.29513549804699, 18.508750915527457],
        [84.29513549804699, 18.508472442627067],
        [84.29486846923834, 18.508478164672795],
        [84.29486846923834, 18.508190155029297],
        [84.29458618164057, 18.508193969726676],
        [84.29458618164057, 18.50791740417486],
        [84.29402923584001, 18.50792121887207],
        [84.29402923584001, 18.507640838623104],
        [84.29374694824224, 18.507640838623104],
        [84.29374694824224, 18.50736045837408],
        [84.29347229003923, 18.50736045837408],
        [84.29347229003923, 18.507080078124943],
        [84.29319763183622, 18.507083892822322],
        [84.29319763183622, 18.506805419921932],
        [84.29264068603544, 18.506811141967887],
        [84.29264068603544, 18.50653076171875],
        [84.2923583984375, 18.50653076171875],
        [84.2923583984375, 18.506250381469727],
        [84.29207611084013, 18.506250381469727],
        [84.29207611084013, 18.50597000122076],
        [84.2918090820313, 18.505971908569393],
        [84.2918090820313, 18.505695343017578],
        [84.2912521362307, 18.505701065063533],
        [84.2912521362307, 18.505420684814567],
        [84.29096984863298, 18.505420684814567],
        [84.29096984863298, 18.505140304565373],
        [84.29070281982428, 18.505140304565373],
        [84.29070281982428, 18.504859924316406],
        [84.2904129028322, 18.50486183166504],
        [84.2904129028322, 18.50458335876465],
        [84.28986358642578, 18.50458335876465],
        [84.28986358642578, 18.504306793213004],
        [84.28958129882841, 18.504306793213004],
        [84.28958129882841, 18.504028320312443],
        [84.289306640625, 18.504030227661246],
        [84.289306640625, 18.503749847412053],
        [84.28874206542974, 18.503749847412053],
        [84.28874206542974, 18.503469467163086],
        [84.28847503662104, 18.503471374511662],
        [84.28847503662104, 18.503194808960075],
        [84.2881927490235, 18.503194808960075],
        [84.2881927490235, 18.502916336059684],
        [84.28791809082048, 18.502920150756893],
        [84.28791809082048, 18.50263977050787],
        [84.2873611450197, 18.50263977050787],
        [84.2873611450197, 18.50235176086437],
        [84.28708648681669, 18.502361297607536],
        [84.28708648681669, 18.50208473205572],
        [84.28680419921892, 18.50208473205572],
        [84.28680419921892, 18.50180625915533],
        [84.28652954101591, 18.50181007385254],
        [84.28652954101591, 18.501529693603516],
        [84.28597259521513, 18.501529693603516],
        [84.28597259521513, 18.501251220703182],
        [84.28569793701178, 18.501251220703182],
        [84.28569793701178, 18.50097274780279],
        [84.28541564941418, 18.50097274780279],
        [84.28541564941418, 18.5006942749024],
        [84.285140991211, 18.5006942749024],
        [84.285140991211, 18.50041580200201],
        [84.28485870361322, 18.500419616699162],
        [84.28485870361322, 18.500141143798828],
        [84.28430175781267, 18.500141143798828],
        [84.28430175781267, 18.499862670898438],
        [84.28402709960943, 18.499862670898438],
        [84.28402709960943, 18.499580383300838],
        [84.28375244140642, 18.499584197998047],
        [84.28375244140642, 18.499305725097656],
        [84.28347015380888, 18.499309539795036],
        [84.28347015380888, 18.499031066894645],
        [84.283203125, 18.499031066894645],
        [84.283203125, 18.498752593994254],
        [84.2829132080081, 18.498752593994254],
        [84.2829132080081, 18.498470306396484],
        [84.28263854980474, 18.498470306396484],
        [84.28263854980474, 18.498193740844727],
        [84.28208160400396, 18.498193740844727],
        [84.28208160400396, 18.497917175293082],
        [84.28180694580072, 18.49791908264166],
        [84.28180694580072, 18.497640609741325],
        [84.28151702880865, 18.497640609741325],
        [84.28151702880865, 18.497358322143555],
        [84.28125000000017, 18.497358322143555],
        [84.28125000000017, 18.497079849243164],
        [84.28097534179693, 18.497083663940543],
        [84.28097534179693, 18.496805191040153],
        [84.28069305419939, 18.496810913085938],
        [84.28069305419939, 18.496540069580078],
        [84.28013610839844, 18.496540069580078],
        [84.28013610839844, 18.49624824523937],
        [84.2798614501956, 18.49624824523937],
        [84.2798614501956, 18.49596977233881],
        [84.27958679199224, 18.495971679687614],
        [84.27958679199224, 18.4956951141358],
        [84.27929687500028, 18.495700836181754],
        [84.27929687500028, 18.495420455932617],
        [84.27902984619146, 18.495420455932617],
        [84.27902984619146, 18.495138168335018],
        [84.2787475585937, 18.495138168335018],
        [84.2787475585937, 18.494859695434627],
        [84.27819824218767, 18.49486160278326],
        [84.27819061279314, 18.49458312988287],
        [84.2779159545899, 18.49458312988287],
        [84.2779159545899, 18.49430465698248],
        [84.27764129638689, 18.49430465698248],
        [84.27764129638689, 18.494026184082088],
        [84.27735900878935, 18.494028091430664],
        [84.27735900878935, 18.493749618530273],
        [84.27707672119158, 18.493749618530273],
        [84.27707672119158, 18.493480682373047],
        [84.27680206298857, 18.493480682373047],
        [84.27680206298857, 18.493190765380916],
        [84.27652740478516, 18.493194580078125],
        [84.27652740478516, 18.492916107177734],
        [84.27597045898443, 18.492919921875114],
        [84.27597045898443, 18.49274253845209],
        [84.27597045898443, 18.49263954162592],
        [84.2756958007812, 18.49263954162592],
        [84.2756958007812, 18.49236106872553],
        [84.27540588378912, 18.49236106872553],
        [84.27540588378912, 18.492080688476562],
        [84.27513885498064, 18.492082595825195],
        [84.27513885498064, 18.491804122924805],
        [84.27485656738304, 18.49180984497076],
        [84.27485656738304, 18.491529464721737],
        [84.27458190917986, 18.491529464721737],
        [84.27458190917986, 18.491250991821403],
        [84.27403259277344, 18.491250991821403],
        [84.27403259277344, 18.49097061157221],
        [84.27375030517607, 18.490972518921012],
        [84.27375030517607, 18.490695953369197],
        [84.27347564697266, 18.490695953369197],
        [84.27347564697266, 18.490417480468807],
        [84.27319335937528, 18.490417480468807],
        [84.27319335937528, 18.490139007568416],
        [84.27291870117193, 18.49014091491705],
        [84.27291870117193, 18.489860534668026],
        [84.27263641357433, 18.489860534668026],
        [84.27263641357433, 18.48958015441889],
        [84.27208709716814, 18.489585876464844],
        [84.27208709716814, 18.489305496215877],
        [84.27179718017601, 18.489311218261832],
        [84.27179718017601, 18.489030838012695],
        [84.27153015136736, 18.489030838012695],
        [84.27153015136736, 18.488750457763672],
        [84.27124786376959, 18.488750457763672],
        [84.27124786376959, 18.488470077514705],
        [84.27097320556658, 18.488473892211914],
        [84.27097320556658, 18.488195419311523],
        [84.27069854736357, 18.48818969726574],
        [84.27069091796903, 18.487916946411133],
        [84.27041625976562, 18.487920761108512],
        [84.27041625976562, 18.487640380859318],
        [84.2698593139649, 18.487640380859318],
        [84.2698593139649, 18.48735237121582],
        [84.26958465576183, 18.48736381530773],
        [84.26958465576183, 18.487083435058594],
        [84.26930236816412, 18.487083435058594],
        [84.26930236816412, 18.486804962158203],
        [84.26902770996088, 18.48681068420416],
        [84.26902770996088, 18.486530303955192],
        [84.26875305175804, 18.486530303955192],
        [84.26875305175804, 18.4862518310548],
        [84.26847076416033, 18.4862518310548],
        [84.26847076416033, 18.48596954345703],
        [84.26791381835955, 18.48596954345703],
        [84.26791381835955, 18.48569488525402],
        [84.26763916015653, 18.48569488525402],
        [84.26763916015653, 18.48541641235363],
        [84.2673568725586, 18.485420227050838],
        [84.2673568725586, 18.485141754150447],
        [84.26708221435575, 18.485141754150447],
        [84.26708221435575, 18.484859466552678],
        [84.2668075561524, 18.484859466552678],
        [84.2668075561524, 18.48458290100109],
        [84.2665252685548, 18.48458290100109],
        [84.2665252685548, 18.484306335449276],
        [84.26625061035162, 18.484310150146484],
        [84.26625061035162, 18.484031677246094],
        [84.2656860351563, 18.484031677246094],
        [84.2656860351563, 18.483749389648494],
        [84.26541900634783, 18.483749389648494],
        [84.26541900634783, 18.483470916748104],
        [84.26513671875006, 18.483472824096737],
        [84.26513671875006, 18.483194351196346],
        [84.26486206054705, 18.483194351196346],
        [84.26486206054705, 18.482915878295955],
        [84.26457214355474, 18.482919692993107],
        [84.26457214355474, 18.482641220092773],
        [84.26431274414062, 18.482641220092773],
        [84.26431274414062, 18.48236083984375],
        [84.26374816894531, 18.48236083984375],
        [84.26374816894531, 18.482080459594783],
        [84.2634735107423, 18.482084274291992],
        [84.2634735107423, 18.4818058013916],
        [84.26319122314459, 18.48180961608898],
        [84.26319122314459, 18.481540679931697],
        [84.26291656494135, 18.481540679931697],
        [84.26291656494135, 18.481250762939396],
        [84.26264190673851, 18.481250762939396],
        [84.26264190673851, 18.48097038269043],
        [84.2623596191408, 18.480972290039062],
        [84.2623596191408, 18.480693817138672],
        [84.26180267334001, 18.480693817138672],
        [84.26180267334001, 18.480417251587028],
        [84.261528015137, 18.480421066284237],
        [84.261528015137, 18.48014068603527],
        [84.2612533569336, 18.48014068603527],
        [84.2612533569336, 18.479860305786076],
        [84.26097106933622, 18.479860305786076],
        [84.26097106933622, 18.47957992553711],
        [84.26069641113281, 18.47958374023449],
        [84.26069641113281, 18.479305267334098],
        [84.26040649414091, 18.479310989379883],
        [84.26040649414091, 18.479030609130916],
        [84.26013946533209, 18.479030609130916],
        [84.26013946533209, 18.478750228881893],
        [84.2595825195313, 18.478750228881893],
        [84.2595825195313, 18.478469848632756],
        [84.2593078613283, 18.47847175598156],
        [84.2593078613283, 18.478195190429744],
        [84.25903320312506, 18.4782009124757],
        [84.25903320312506, 18.477920532226562],
        [84.25875854492205, 18.477920532226562],
        [84.25875854492205, 18.47764015197754],
        [84.25846862792974, 18.47764015197754],
        [84.25846862792974, 18.477359771728572],
        [84.25819396972673, 18.477361679077205],
        [84.25819396972673, 18.477083206176815],
        [84.25763702392578, 18.477083206176815],
        [84.25763702392578, 18.476804733276424],
        [84.25736236572294, 18.476804733276424],
        [84.25736236572294, 18.47652816772461],
        [84.25708007812506, 18.476530075073185],
        [84.25708007812506, 18.47624969482422],
        [84.25680541992199, 18.47624969482422],
        [84.25680541992199, 18.47597312927246],
        [84.2565307617188, 18.47597312927246],
        [84.2565307617188, 18.47569465637207],
        [84.25624847412104, 18.47569465637207],
        [84.25624847412104, 18.47541618347168],
        [84.25569152832048, 18.47541618347168],
        [84.25569152832048, 18.475139617919865],
        [84.25541687011724, 18.475139617919865],
        [84.25541687011724, 18.474861145019474],
        [84.25514221191423, 18.474861145019474],
        [84.25514221191423, 18.47458267211914],
        [84.25485992431669, 18.474584579467887],
        [84.25485992431669, 18.474306106567496],
        [84.25459289550781, 18.474309921264705],
        [84.25459289550781, 18.474029541015682],
        [84.25430297851591, 18.474029541015682],
        [84.25430297851591, 18.473751068115348],
        [84.25402832031256, 18.473751068115348],
        [84.25402832031256, 18.473472595214957],
        [84.25347137451178, 18.473472595214957],
        [84.25347137451178, 18.473194122314567],
        [84.25319671630854, 18.473194122314567],
        [84.25319671630854, 18.472917556762752],
        [84.25290679931646, 18.472919464111328],
        [84.25290679931646, 18.47264289855957],
        [84.25263214111345, 18.47264289855957],
        [84.25263214111345, 18.472370147705135],
        [84.25235748291021, 18.472370147705135],
        [84.25235748291021, 18.472080230712834],
        [84.2520828247072, 18.472084045410213],
        [84.2520828247072, 18.471805572509822],
        [84.25180816650419, 18.471809387206974],
        [84.25180816650419, 18.471532821655387],
        [84.25152587890625, 18.471532821655387],
        [84.25152587890625, 18.47124862670904],
        [84.25125122070341, 18.47124862670904],
        [84.25125122070341, 18.470968246460075],
        [84.25069427490246, 18.47097015380865],
        [84.25069427490246, 18.470693588256893],
        [84.25041961669928, 18.470693588256893],
        [84.25041961669928, 18.470418930053654],
        [84.2501373291015, 18.470420837402457],
        [84.2501373291015, 18.470138549804688],
        [84.24986267089866, 18.470138549804688],
        [84.24986267089866, 18.46985816955572],
        [84.24958038330095, 18.469860076904297],
        [84.24958038330095, 18.46958351135254],
        [84.24930572509771, 18.46958351135254],
        [84.24930572509771, 18.46930503845215],
        [84.2490310668947, 18.469310760498104],
        [84.2490310668947, 18.469030380249137],
        [84.24874877929716, 18.469030380249137],
        [84.24874877929716, 18.468748092651367],
        [84.24846649169939, 18.468748092651367],
        [84.24846649169939, 18.468469619750977],
        [84.24819183349638, 18.468471527099553],
        [84.24819183349638, 18.468194961547965],
        [84.24791717529297, 18.46820068359375],
        [84.24791717529297, 18.467920303344783],
        [84.24736022949224, 18.467920303344783],
        [84.24736022949224, 18.46763992309576],
        [84.24709320068354, 18.46763992309576],
        [84.24709320068354, 18.467359542846623],
        [84.24680328369146, 18.467361450195426],
        [84.24680328369146, 18.467082977295036],
        [84.24652862548845, 18.467082977295036],
        [84.24652862548845, 18.46680641174322],
        [84.24624633789085, 18.46681022644043],
        [84.24624633789085, 18.466529846191406],
        [84.24597167968767, 18.466529846191406],
        [84.24597167968767, 18.466239929199332],
        [84.24569702148466, 18.46624946594244],
        [84.24569702148466, 18.465972900390682],
        [84.24541473388689, 18.465972900390682],
        [84.24541473388689, 18.46569442749029],
        [84.24514007568388, 18.46569442749029],
        [84.24514007568388, 18.465414047241325],
        [84.24485778808594, 18.465419769287053],
        [84.24485778808594, 18.465139389038086],
        [84.2445831298831, 18.465139389038086],
        [84.2445831298831, 18.464860916137695],
        [84.24402618408214, 18.464860916137695],
        [84.24402618408214, 18.46458053588873],
        [84.24375152587896, 18.46458244323736],
        [84.24375152587896, 18.464305877685547],
        [84.2434692382812, 18.464309692382926],
        [84.2434692382812, 18.464031219482536],
        [84.24318695068382, 18.464031219482536],
        [84.24318695068382, 18.46375083923334],
        [84.24291992187517, 18.46375083923334],
        [84.24291992187517, 18.463470458984375],
        [84.2426376342774, 18.463472366333008],
        [84.2426376342774, 18.463195800781364],
        [84.24236297607439, 18.463195800781364],
        [84.24236297607439, 18.462917327880973],
        [84.24208068847685, 18.462921142578182],
        [84.24208068847685, 18.462640762329215],
        [84.24180603027344, 18.462640762329215],
        [84.24180603027344, 18.46236038208002],
        [84.2415313720706, 18.46236038208002],
        [84.2415313720706, 18.462080001831055],
        [84.24124908447271, 18.462083816528434],
        [84.24124908447271, 18.461805343628043],
        [84.24069213867193, 18.461811065673828],
        [84.24069213867193, 18.46153068542486],
        [84.2404174804687, 18.46153068542486],
        [84.2404174804687, 18.461250305175838],
        [84.24013519287132, 18.461250305175838],
        [84.24013519287132, 18.46097373962408],
        [84.23986053466814, 18.46097373962408],
        [84.23986053466814, 18.46069526672369],
        [84.2395858764649, 18.46070289611822],
        [84.2395858764649, 18.460430145263786],
        [84.23929595947283, 18.460430145263786],
        [84.23929595947283, 18.460140228271484],
        [84.23902893066435, 18.460140228271484],
        [84.23902893066435, 18.45986175537115],
        [84.2387466430664, 18.459863662719727],
        [84.2387466430664, 18.45958328247076],
        [84.23847198486357, 18.45958328247076],
        [84.23847198486357, 18.45930480957037],
        [84.23819732666021, 18.45930480957037],
        [84.23819732666021, 18.459028244018555],
        [84.23792266845714, 18.45903015136713],
        [84.23792266845714, 18.458751678466797],
        [84.23735809326166, 18.458751678466797],
        [84.23735809326166, 18.458469390869197],
        [84.23708343505882, 18.458469390869197],
        [84.23708343505882, 18.458194732666016],
        [84.23680877685564, 18.458194732666016],
        [84.23680877685564, 18.457916259765625],
        [84.23652648925787, 18.457920074463004],
        [84.23652648925787, 18.457641601562614],
        [84.23625183105486, 18.457641601562614],
        [84.23625183105486, 18.45737266540533],
        [84.23596954345732, 18.45737266540533],
        [84.23596954345732, 18.457080841064453],
        [84.2356948852539, 18.457082748413086],
        [84.2356948852539, 18.456806182861442],
        [84.23542022705107, 18.456811904907227],
        [84.23542022705107, 18.45652770996105],
        [84.23513793945312, 18.45652770996105],
        [84.23513793945312, 18.45624923706049],
        [84.23486328125011, 18.45624923706049],
        [84.23486328125011, 18.4559707641601],
        [84.2345809936524, 18.455972671508903],
        [84.2345809936524, 18.455694198608512],
        [84.23402404785162, 18.455694198608512],
        [84.23402404785162, 18.455419540405273],
        [84.23374938964861, 18.455419540405273],
        [84.23374938964861, 18.455139160156307],
        [84.23346710205101, 18.455139160156307],
        [84.23346710205101, 18.454860687255916],
        [84.23319244384783, 18.454860687255916],
        [84.23319244384783, 18.45458030700678],
        [84.23291778564482, 18.45458412170416],
        [84.23291778564482, 18.454305648803768],
        [84.23263549804705, 18.454305648803768],
        [84.23263549804705, 18.454027175903377],
        [84.23236083984403, 18.454030990600586],
        [84.23236083984403, 18.453750610351562],
        [84.23208618164062, 18.453750610351562],
        [84.23208618164062, 18.453470230102596],
        [84.23180389404325, 18.45347213745123],
        [84.23180389404325, 18.453193664550838],
        [84.2315292358399, 18.453193664550838],
        [84.2315292358399, 18.452915191650447],
        [84.2312469482423, 18.452920913696403],
        [84.2312469482423, 18.45264053344721],
        [84.23069763183611, 18.45264053344721],
        [84.23069763183611, 18.452360153198242],
        [84.23041534423851, 18.452362060546875],
        [84.23041534423851, 18.452083587646484],
        [84.23014068603533, 18.452083587646484],
        [84.23014068603533, 18.451805114746094],
        [84.22985839843756, 18.45181083679205],
        [84.22985839843756, 18.451530456543082],
        [84.22957611084001, 18.451530456543082],
        [84.22957611084001, 18.45124053955078],
        [84.22930908203153, 18.45125007629389],
        [84.22930908203153, 18.450971603393498],
        [84.2290267944336, 18.450971603393498],
        [84.2290267944336, 18.450696945190487],
        [84.22875213623075, 18.450700759887695],
        [84.22875213623075, 18.45042037963873],
        [84.22846221923828, 18.45042037963873],
        [84.22846221923828, 18.450139999389705],
        [84.22820281982416, 18.450139999389705],
        [84.22820281982416, 18.449859619140568],
        [84.22791290283209, 18.44986152648937],
        [84.22791290283209, 18.44958305358898],
        [84.22763824462885, 18.44958305358898],
        [84.22763824462885, 18.449306488037166],
        [84.22735595703148, 18.449310302734375],
        [84.22735595703148, 18.44902992248535],
        [84.22680664062506, 18.44902992248535],
        [84.22680664062506, 18.448749542236385],
        [84.22651672363298, 18.448749542236385],
        [84.22651672363298, 18.448471069335994],
        [84.2262496948245, 18.448474884033203],
        [84.2262496948245, 18.448194503784237],
        [84.2259750366211, 18.448194503784237],
        [84.2259750366211, 18.447916030883846],
        [84.22569274902372, 18.447919845580998],
        [84.22569274902372, 18.44763946533203],
        [84.22541809082037, 18.44763946533203],
        [84.22541809082037, 18.447362899780273],
        [84.22513580322277, 18.447362899780273],
        [84.22513580322277, 18.447080612182674],
        [84.22486114501959, 18.447080612182674],
        [84.22486114501959, 18.446805953979492],
        [84.22458648681635, 18.44680976867687],
        [84.22458648681635, 18.446529388427678],
        [84.22429656982428, 18.446529388427678],
        [84.22429656982428, 18.44625282287609],
        [84.2240295410158, 18.44625282287609],
        [84.2240295410158, 18.445972442626953],
        [84.22374725341803, 18.445972442626953],
        [84.22374725341803, 18.44569206237793],
        [84.22347259521501, 18.44569206237793],
        [84.22347259521501, 18.44541740417492],
        [84.2229232788086, 18.445430755615234],
        [84.2229232788086, 18.445142745971737],
        [84.22264862060575, 18.445142745971737],
        [84.22264862060575, 18.44486236572277],
        [84.22235870361328, 18.44486236572277],
        [84.22235870361328, 18.444578170776424],
        [84.22208404541027, 18.44458389282238],
        [84.22208404541027, 18.44430541992199],
        [84.22180175781256, 18.44430923461914],
        [84.22180175781256, 18.444030761718807],
        [84.22152709960932, 18.444030761718807],
        [84.22152709960932, 18.443748474121207],
        [84.22125244140648, 18.443748474121207],
        [84.22125244140648, 18.44346809387207],
        [84.22097015380876, 18.44347190856945],
        [84.22097015380876, 18.443195343017635],
        [84.22070312500006, 18.443199157714787],
        [84.22070312500006, 18.442920684814453],
        [84.22041320800798, 18.442920684814453],
        [84.22041320800798, 18.442638397216854],
        [84.22013854980497, 18.442638397216854],
        [84.22013854980497, 18.442359924316463],
        [84.21986389160156, 18.442361831665096],
        [84.21986389160156, 18.442083358764705],
        [84.21958160400419, 18.442083358764705],
        [84.21958160400419, 18.441804885864315],
        [84.21902465820341, 18.441804885864315],
        [84.21902465820341, 18.441526412963924],
        [84.21875, 18.4415283203125],
        [84.21875, 18.44124984741211],
        [84.21847534179682, 18.44124984741211],
        [84.21847534179682, 18.44097137451172],
        [84.21819305419928, 18.44097137451172],
        [84.21819305419928, 18.44069480895996],
        [84.21791839599626, 18.44069480895996],
        [84.21791839599626, 18.44041633605957],
        [84.21763610839866, 18.44042015075695],
        [84.21763610839866, 18.440139770507756],
        [84.21736145019548, 18.440139770507756],
        [84.21736145019548, 18.43985939025879],
        [84.21708679199247, 18.439861297607365],
        [84.21708679199247, 18.43958282470703],
        [84.2168045043947, 18.43958282470703],
        [84.2168045043947, 18.439306259155387],
        [84.21652984619169, 18.439306259155387],
        [84.21652984619169, 18.439027786254996],
        [84.21624755859375, 18.439029693603572],
        [84.21624755859375, 18.43875122070324],
        [84.21597290039091, 18.43875122070324],
        [84.21597290039091, 18.438470840454045],
        [84.21569824218756, 18.438472747802848],
        [84.21569061279303, 18.438194274902457],
        [84.21541595458996, 18.438194274902457],
        [84.21541595458996, 18.437915802002067],
        [84.21514129638678, 18.43791961669922],
        [84.21514129638678, 18.437641143798885],
        [84.214859008789, 18.437641143798885],
        [84.214859008789, 18.43736076354986],
        [84.21457672119163, 18.43736076354986],
        [84.21457672119163, 18.437080383300724],
        [84.21430206298845, 18.437084197998104],
        [84.21430206298845, 18.436805725097713],
        [84.21402740478521, 18.436809539794865],
        [84.21402740478521, 18.43653106689453],
        [84.21346282959013, 18.43653106689453],
        [84.21346282959013, 18.436260223388672],
        [84.21319580078125, 18.436260223388672],
        [84.21319580078125, 18.43597030639654],
        [84.21291351318388, 18.435972213745174],
        [84.21291351318388, 18.435693740844783],
        [84.21263885498053, 18.435693740844783],
        [84.21263885498053, 18.43541717529297],
        [84.21235656738293, 18.435420989990348],
        [84.21235656738293, 18.435140609741154],
        [84.21208190917974, 18.435140609741154],
        [84.21208190917974, 18.434860229492188],
        [84.2118072509765, 18.434860229492188],
        [84.2118072509765, 18.43457984924322],
        [84.21152496337913, 18.43458366394043],
        [84.21152496337913, 18.43430519104004],
        [84.21125030517595, 18.434310913085994],
        [84.21125030517595, 18.434030532837028],
        [84.21098327636747, 18.434030532837028],
        [84.21098327636747, 18.433750152587834],
        [84.21069335937517, 18.433750152587834],
        [84.21069335937517, 18.433469772338867],
        [84.21041870117216, 18.433473587036246],
        [84.21041870117216, 18.433195114135856],
        [84.21013641357422, 18.433202743530387],
        [84.21013641357422, 18.432920455932674],
        [84.20986175537138, 18.432920455932674],
        [84.20986175537138, 18.432361602783317],
        [84.20958709716803, 18.432361602783317],
        [84.20958709716803, 18.432081222534123],
        [84.20930480957043, 18.432081222534123],
        [84.20930480957043, 18.431804656982536],
        [84.20903015136724, 18.431804656982536],
        [84.20903015136724, 18.43152809143072],
        [84.20874786376947, 18.43153190612793],
        [84.20874786376947, 18.431251525878963],
        [84.20847320556663, 18.431251525878963],
        [84.20847320556663, 18.430969238281364],
        [84.20819091796892, 18.430973052978572],
        [84.20819091796892, 18.430694580078182],
        [84.20791625976568, 18.430694580078182],
        [84.20791625976568, 18.43041610717779],
        [84.20764160156267, 18.430421829223746],
        [84.20764160156267, 18.43014144897461],
        [84.20707702636736, 18.43014144897461],
        [84.20707702636736, 18.42985916137701],
        [84.20680236816435, 18.42985916137701],
        [84.20680236816435, 18.42958068847662],
        [84.20652770996094, 18.429582595825252],
        [84.20652770996094, 18.429306030273438],
        [84.20625305175793, 18.429309844970817],
        [84.20625305175793, 18.429027557373047],
        [84.20597076416021, 18.429027557373047],
        [84.20597076416021, 18.428749084472656],
        [84.20569610595697, 18.428749084472656],
        [84.20569610595697, 18.428470611572266],
        [84.20541381835943, 18.4284725189209],
        [84.20541381835943, 18.428194046020508],
        [84.20513916015642, 18.428194046020508],
        [84.20513916015642, 18.427917480468864],
        [84.20485687255882, 18.427917480468864],
        [84.20485687255882, 18.427640914917106],
        [84.20403289794922, 18.427640914917106],
        [84.20403289794922, 18.427360534667912],
        [84.20375061035185, 18.427360534667912],
        [84.20375061035185, 18.427070617675838],
        [84.20319366455107, 18.427083969116325],
        [84.20319366455107, 18.426805496215934],
        [84.20291900634771, 18.42681121826172],
        [84.20291900634771, 18.426530838012752],
        [84.20263671875011, 18.426530838012752],
        [84.20263671875011, 18.42625045776373],
        [84.20207977294916, 18.42625045776373],
        [84.20207977294916, 18.425701141357536],
        [84.20181274414068, 18.425689697265625],
        [84.20180511474632, 18.42541694641119],
        [84.20207977294916, 18.4254207611084],
        [84.20207977294916, 18.425140380859375],
        [84.20181274414068, 18.425140380859375],
        [84.20181274414068, 18.42486000061041],
        [84.20153045654314, 18.42486190795904],
        [84.20153045654314, 18.42458343505865],
        [84.20097351074236, 18.42458343505865],
        [84.20097351074236, 18.424306869506836],
        [84.20069122314482, 18.424320220947322],
        [84.20069122314482, 18.42403030395502],
        [84.2004165649414, 18.42403030395502],
        [84.2004165649414, 18.423749923706055],
        [84.20014190673857, 18.423749923706055],
        [84.20014190673857, 18.423469543457088],
        [84.19985961914068, 18.423471450805664],
        [84.19985961914068, 18.423194885253906],
        [84.19958496093761, 18.423194885253906],
        [84.19958496093761, 18.422916412353516],
        [84.19902801513666, 18.422920227050895],
        [84.19902801513666, 18.4226398468017],
        [84.19875335693382, 18.4226398468017],
        [84.19875335693382, 18.422359466552734],
        [84.19847106933611, 18.42236137390131],
        [84.19847106933611, 18.422084808349723],
        [84.19819641113287, 18.422084808349723],
        [84.19819641113287, 18.421806335449332],
        [84.1979064941408, 18.42181015014654],
        [84.1979064941408, 18.421529769897518],
        [84.19763946533232, 18.421529769897518],
        [84.19763946533232, 18.42126083374029],
        [84.19708251953153, 18.42126083374029],
        [84.19708251953153, 18.420972824096793],
        [84.19680786132818, 18.420972824096793],
        [84.19680786132818, 18.420694351196403],
        [84.19652557373058, 18.420694351196403],
        [84.19652557373058, 18.420415878296012],
        [84.19596862792963, 18.420419692993164],
        [84.19596862792963, 18.42014122009283],
        [84.19568634033209, 18.42014122009283],
        [84.19568634033209, 18.41958045959467],
        [84.19541931152361, 18.41958427429205],
        [84.19541931152361, 18.419027328491268],
        [84.19513702392584, 18.419031143188477],
        [84.19513702392584, 18.418752670288086],
        [84.19486236572283, 18.418752670288086],
        [84.19486236572283, 18.417921066284293],
        [84.1943130493164, 18.417921066284293],
        [84.1943130493164, 18.4176406860351],
        [84.19403076171903, 18.4176406860351],
        [84.19403076171903, 18.417358398437557],
        [84.1937484741211, 18.417358398437557],
        [84.1937484741211, 18.417079925537166],
        [84.19347381591808, 18.417083740234375],
        [84.19347381591808, 18.416805267333984],
        [84.19319152832037, 18.416809082031307],
        [84.19319152832037, 18.416530609130973],
        [84.19291687011713, 18.416530609130973],
        [84.19291687011713, 18.416248321533203],
        [84.19264221191429, 18.416248321533203],
        [84.19264221191429, 18.415969848632812],
        [84.19208526611334, 18.41597175598139],
        [84.19208526611334, 18.4156951904298],
        [84.1918029785158, 18.415700912475586],
        [84.1918029785158, 18.41542053222662],
        [84.190971374512, 18.41542053222662],
        [84.190971374512, 18.41513061523449],
        [84.19041442871122, 18.41513824462902],
        [84.19041442871122, 18.414861679077262],
        [84.19013977050787, 18.414861679077262],
        [84.19013977050787, 18.41458320617687],
        [84.18985748291027, 18.41458320617687],
        [84.18985748291027, 18.41402626037609],
        [84.18930816650408, 18.414028167724666],
        [84.18930816650408, 18.413749694824276],
        [84.18902587890648, 18.413749694824276],
        [84.18902587890648, 18.413471221923885],
        [84.1887512207033, 18.413473129272518],
        [84.1887512207033, 18.413194656372127],
        [84.18846893310553, 18.413194656372127],
        [84.18846893310553, 18.41236114501953],
        [84.18818664550798, 18.41236114501953],
        [84.18818664550798, 18.412071228027457],
        [84.1879196166995, 18.412082672119197],
        [84.1879196166995, 18.411804199218807],
        [84.18763732910156, 18.411809921264762],
        [84.18763732910156, 18.411529541015568],
        [84.18736267089872, 18.411529541015568],
        [84.18736267089872, 18.411251068115178],
        [84.18708038330084, 18.411251068115178],
        [84.18708038330084, 18.41097068786621],
        [84.18680572509777, 18.410972595214844],
        [84.18680572509777, 18.410694122314453],
        [84.18653106689459, 18.410694122314453],
        [84.18653106689459, 18.41041755676281],
        [84.18624877929682, 18.410419464111385],
        [84.18624877929682, 18.41014099121105],
        [84.18596649169945, 18.41014099121105],
        [84.18596649169945, 18.40958023071289],
        [84.18569183349626, 18.409585952758846],
        [84.18569183349626, 18.40930557250988],
        [84.18541717529303, 18.409320831298942],
        [84.18541717529303, 18.409030914306697],
        [84.18514251709001, 18.409030914306697],
        [84.18514251709001, 18.408750534057674],
        [84.18486022949247, 18.408750534057674],
        [84.18486022949247, 18.408470153808537],
        [84.18458557128906, 18.40847206115734],
        [84.18458557128906, 18.40819358825695],
        [84.18430328369169, 18.40819358825695],
        [84.18430328369169, 18.407917022705135],
        [84.18402862548834, 18.407920837402344],
        [84.18402862548834, 18.40764045715332],
        [84.18374633789074, 18.40764045715332],
        [84.18374633789074, 18.407360076904354],
        [84.18347167968756, 18.407363891601562],
        [84.18347167968756, 18.407083511352596],
        [84.18319702148432, 18.407083511352596],
        [84.18319702148432, 18.406805038452205],
        [84.18290710449224, 18.40681076049816],
        [84.18290710449224, 18.406530380248967],
        [84.18264007568376, 18.406530380248967],
        [84.18264007568376, 18.406259536743164],
        [84.182357788086, 18.406259536743164],
        [84.182357788086, 18.405969619751033],
        [84.18208312988298, 18.405969619751033],
        [84.18208312988298, 18.40569496154785],
        [84.18180847167997, 18.405700683593807],
        [84.18180847167997, 18.40542030334484],
        [84.18152618408203, 18.40542030334484],
        [84.18152618408203, 18.40514183044445],
        [84.18125152587919, 18.40514183044445],
        [84.18125152587919, 18.40485954284668],
        [84.18096923828125, 18.40485954284668],
        [84.18096923828125, 18.40458106994629],
        [84.18069458007824, 18.40458106994629],
        [84.18069458007824, 18.404306411743278],
        [84.18041229248053, 18.404310226440487],
        [84.18041229248053, 18.404031753540096],
        [84.17986297607445, 18.404031753540096],
        [84.17986297607445, 18.40375137329113],
        [84.17958068847673, 18.40375137329113],
        [84.17958068847673, 18.40346908569336],
        [84.1793060302735, 18.40347290039074],
        [84.1793060302735, 18.403194427490348],
        [84.17903137207048, 18.403194427490348],
        [84.17903137207048, 18.402915954589957],
        [84.17874908447294, 18.40291976928711],
        [84.17874908447294, 18.402637481689567],
        [84.17846679687517, 18.402637481689567],
        [84.17846679687517, 18.402360916137752],
        [84.17819213867216, 18.402360916137752],
        [84.17819213867216, 18.402080535888615],
        [84.17791748046875, 18.40208244323742],
        [84.17791748046875, 18.401805877685604],
        [84.17764282226574, 18.401809692382756],
        [84.17764282226574, 18.401531219482422],
        [84.1773529052735, 18.401531219482422],
        [84.1773529052735, 18.4012508392334],
        [84.17708587646479, 18.4012508392334],
        [84.17708587646479, 18.400970458984432],
        [84.17680358886724, 18.400972366333065],
        [84.17680358886724, 18.400693893432674],
        [84.17652893066423, 18.400693893432674],
        [84.17652893066423, 18.40041732788086],
        [84.17624664306663, 18.40042114257824],
        [84.17624664306663, 18.400131225585938],
        [84.17597198486345, 18.40013885498047],
        [84.17597198486345, 18.399860382080078],
        [84.17569732666044, 18.399860382080078],
        [84.17569732666044, 18.39958000183111],
        [84.17541503906267, 18.39958381652832],
        [84.17541503906267, 18.39930534362793],
        [84.17514038085966, 18.399311065673885],
        [84.17514038085966, 18.398750305175724],
        [84.17485809326172, 18.398750305175724],
        [84.17485809326172, 18.398469924926758],
        [84.17458343505888, 18.398471832275334],
        [84.17458343505888, 18.398195266723746],
        [84.17430877685553, 18.39820098876953],
        [84.17430877685553, 18.397920608520565],
        [84.17402648925793, 18.397920608520565],
        [84.17402648925793, 18.39764022827154],
        [84.17375183105474, 18.39764022827154],
        [84.17375183105474, 18.397359848022404],
        [84.17346954345697, 18.397361755371207],
        [84.17346954345697, 18.397083282470817],
        [84.1731872558596, 18.397090911865348],
        [84.1731872558596, 18.396800994873047],
        [84.17292022705095, 18.396804809570426],
        [84.17292022705095, 18.39652824401861],
        [84.17263793945318, 18.396530151367188],
        [84.17263793945318, 18.39624977111822],
        [84.17236328125017, 18.39624977111822],
        [84.17236328125017, 18.395969390869254],
        [84.17208099365263, 18.39597129821783],
        [84.17208099365263, 18.395694732666072],
        [84.17180633544922, 18.395694732666072],
        [84.17180633544922, 18.395416259765682],
        [84.17151641845732, 18.395420074462834],
        [84.17151641845732, 18.394861221313477],
        [84.1712493896485, 18.394861221313477],
        [84.1712493896485, 18.394582748413143],
        [84.17097473144543, 18.39458465576172],
        [84.17097473144543, 18.394306182861328],
        [84.17069244384771, 18.394319534301815],
        [84.17069244384771, 18.394029617309513],
        [84.17041778564447, 18.394029617309513],
        [84.17041778564447, 18.393751144409123],
        [84.17014312744163, 18.393751144409123],
        [84.17014312744163, 18.39347267150879],
        [84.16986083984392, 18.39347267150879],
        [84.16986083984392, 18.3931941986084],
        [84.16958618164068, 18.3931941986084],
        [84.16958618164068, 18.392917633056754],
        [84.16929626464861, 18.39291954040533],
        [84.16929626464861, 18.392641067504996],
        [84.16902923584013, 18.392641067504996],
        [84.16902923584013, 18.392362594604606],
        [84.16874694824219, 18.392362594604606],
        [84.16874694824219, 18.392080307006836],
        [84.16847229003935, 18.392084121704215],
        [84.16847229003935, 18.391805648803825],
        [84.168197631836, 18.391809463500977],
        [84.168197631836, 18.39153289794922],
        [84.16792297363293, 18.39153289794922],
        [84.16792297363293, 18.391248703003043],
        [84.16764068603521, 18.391248703003043],
        [84.16764068603521, 18.390960693359375],
        [84.16735839843744, 18.390970230102482],
        [84.16735839843744, 18.390693664550895],
        [84.1670837402346, 18.390693664550895],
        [84.1670837402346, 18.39041709899908],
        [84.16680908203142, 18.39042091369629],
        [84.16680908203142, 18.390140533447266],
        [84.16652679443365, 18.390140533447266],
        [84.16652679443365, 18.389858245849723],
        [84.16625213623064, 18.3898601531983],
        [84.16625213623064, 18.38958358764654],
        [84.1659698486331, 18.38958358764654],
        [84.1659698486331, 18.38930511474615],
        [84.16570281982422, 18.389310836792106],
        [84.16570281982422, 18.38874816894537],
        [84.16541290283232, 18.38874816894537],
        [84.16541290283232, 18.38846969604498],
        [84.1651382446289, 18.388471603393555],
        [84.1651382446289, 18.388195037841797],
        [84.1648635864259, 18.388195037841797],
        [84.1648635864259, 18.387916564941406],
        [84.16458129882818, 18.387920379638786],
        [84.16458129882818, 18.387638092041016],
        [84.16430664062494, 18.387638092041016],
        [84.16430664062494, 18.387359619140625],
        [84.1640243530274, 18.3873615264892],
        [84.1640243530274, 18.387083053588867],
        [84.16374969482439, 18.387083053588867],
        [84.16374969482439, 18.386806488037223],
        [84.16348266601568, 18.386810302734432],
        [84.16348266601568, 18.38652992248541],
        [84.16319274902361, 18.38652992248541],
        [84.16319274902361, 18.38624954223627],
        [84.1629180908206, 18.38624954223627],
        [84.1629180908206, 18.38597106933588],
        [84.16263580322283, 18.385972976684684],
        [84.16263580322283, 18.385694503784293],
        [84.16236114501982, 18.385694503784293],
        [84.16236114501982, 18.385414123535156],
        [84.1620864868164, 18.385419845581055],
        [84.1620864868164, 18.385150909423828],
        [84.1617965698245, 18.385150909423828],
        [84.1617965698245, 18.384860992431697],
        [84.16152954101568, 18.384860992431697],
        [84.16152954101568, 18.38458061218256],
        [84.16124725341808, 18.384582519531364],
        [84.16124725341808, 18.384304046630973],
        [84.1609725952149, 18.3843097686767],
        [84.1609725952149, 18.384029388427734],
        [84.16069793701189, 18.384029388427734],
        [84.16069793701189, 18.383750915527344],
        [84.16041564941429, 18.383750915527344],
        [84.16041564941429, 18.38347244262701],
        [84.16014099121111, 18.38347244262701],
        [84.16014099121111, 18.383195877075195],
        [84.15985870361334, 18.383195877075195],
        [84.15985870361334, 18.382917404174805],
        [84.1595764160158, 18.382921218872184],
        [84.1595764160158, 18.38264083862299],
        [84.15930938720732, 18.38264083862299],
        [84.15930938720732, 18.382360458374023],
        [84.15902709960938, 18.382360458374023],
        [84.15902709960938, 18.38208961486822],
        [84.15875244140653, 18.38208961486822],
        [84.15875244140653, 18.38181114196783],
        [84.15847015380865, 18.38181114196783],
        [84.15847015380865, 18.381530761718864],
        [84.15820312499994, 18.381530761718864],
        [84.15820312499994, 18.38125038146967],
        [84.15791320800787, 18.38125038146967],
        [84.15791320800787, 18.38097190856928],
        [84.15763854980463, 18.380973815918082],
        [84.15763854980463, 18.380695343017692],
        [84.15735626220726, 18.380701065063477],
        [84.15735626220726, 18.38042068481451],
        [84.15708160400408, 18.38042068481451],
        [84.15708160400408, 18.380140304565487],
        [84.15680694580084, 18.380140304565487],
        [84.15680694580084, 18.379861831665153],
        [84.1565246582033, 18.37986373901373],
        [84.1565246582033, 18.379583358764762],
        [84.15625000000028, 18.379583358764762],
        [84.15625000000028, 18.37930488586437],
        [84.15597534179688, 18.37930488586437],
        [84.15597534179688, 18.379028320312557],
        [84.1556930541995, 18.379028320312557],
        [84.1556930541995, 18.3787517547608],
        [84.15541839599615, 18.3787517547608],
        [84.15541839599615, 18.3784694671632],
        [84.15513610839855, 18.3784694671632],
        [84.15513610839855, 18.378194808960018],
        [84.15486145019537, 18.378194808960018],
        [84.15486145019537, 18.377916336059627],
        [84.15458679199213, 18.37792015075678],
        [84.15458679199213, 18.377641677856445],
        [84.15429687500006, 18.377641677856445],
        [84.15429687500006, 18.377359390258846],
        [84.15402984619158, 18.377359390258846],
        [84.15402984619158, 18.377082824707088],
        [84.1537475585938, 18.377082824707088],
        [84.1537475585938, 18.376806259155273],
        [84.1534729003908, 18.376810073852653],
        [84.1534729003908, 18.376531600952262],
        [84.15319824218778, 18.376531600952262],
        [84.1531829833985, 18.37595939636242],
        [84.15291595458984, 18.375972747802734],
        [84.15291595458984, 18.375694274902344],
        [84.152641296387, 18.375694274902344],
        [84.152641296387, 18.375415802001953],
        [84.15235900878906, 18.375419616699276],
        [84.15235900878906, 18.375141143798942],
        [84.15207672119169, 18.375141143798942],
        [84.15207672119169, 18.374860763549748],
        [84.15180206298834, 18.374860763549748],
        [84.15180206298834, 18.37458038330078],
        [84.1515274047851, 18.37458419799816],
        [84.1515274047851, 18.37430572509777],
        [84.15125274658226, 18.374309539794922],
        [84.15125274658226, 18.374031066894588],
        [84.15097045898455, 18.374031066894588],
        [84.15097045898455, 18.373750686645565],
        [84.1506958007813, 18.373750686645565],
        [84.1506958007813, 18.373470306396428],
        [84.15041351318376, 18.37347221374523],
        [84.15041351318376, 18.37291526794445],
        [84.15013885498075, 18.372920989990234],
        [84.15013885498075, 18.372360229492244],
        [84.14958190917997, 18.372360229492244],
        [84.14958190917997, 18.372079849243278],
        [84.14930725097656, 18.372083663940487],
        [84.14930725097656, 18.371805191040096],
        [84.14903259277355, 18.37181091308605],
        [84.14903259277355, 18.37125015258789],
        [84.14875030517584, 18.37125015258789],
        [84.14875030517584, 18.37042045593273],
        [84.14848327636713, 18.37042045593273],
        [84.14848327636713, 18.36985969543457],
        [84.14819335937506, 18.369861602783146],
        [84.14819335937506, 18.369304656982422],
        [84.14791870117205, 18.369304656982422],
        [84.14791870117205, 18.368749618530217],
        [84.14763641357445, 18.368749618530217],
        [84.14763641357445, 18.368471145629826],
        [84.14736175537126, 18.36847305297863],
        [84.14736175537126, 18.36819458007824],
        [84.14708709716825, 18.36819458007824],
        [84.14708709716825, 18.367361068725643],
        [84.14679718017595, 18.367361068725643],
        [84.14679718017595, 18.36652946472168],
        [84.14653015136747, 18.36652946472168],
        [84.14653015136747, 18.365972518920955],
        [84.14624786376953, 18.365972518920955],
        [84.14624786376953, 18.365694046020565],
        [84.14597320556669, 18.365694046020565],
        [84.14597320556669, 18.36402702331543],
        [84.1456909179688, 18.36402702331543],
        [84.1456909179688, 18.36374855041504],
        [84.14541625976574, 18.36374855041504],
        [84.14541625976574, 18.36319923400879],
        [84.14514160156256, 18.363189697265682],
        [84.14514160156256, 18.362638473510856],
        [84.14485931396479, 18.362638473510856],
        [84.14485931396479, 18.36235809326172],
        [84.14430236816423, 18.362361907959098],
        [84.14430236816423, 18.362083435058707],
        [84.144027709961, 18.362083435058707],
        [84.144027709961, 18.36124992370611],
        [84.14375305175798, 18.36124992370611],
        [84.14375305175798, 18.360862731933594],
        [84.14375305175798, 18.360694885253963],
        [84.14347076416044, 18.360694885253963],
        [84.14347076416044, 18.360139846801758],
        [84.14319610595703, 18.360139846801758],
        [84.14319610595703, 18.35985946655279],
        [84.14291381835966, 18.359861373901367],
        [84.14291381835966, 18.35930633544922],
        [84.1426391601563, 18.359310150146598],
        [84.1426391601563, 18.358749389648438],
        [84.14235687255871, 18.358749389648438],
        [84.14235687255871, 18.358470916748047],
        [84.14208221435553, 18.35847282409668],
        [84.14208221435553, 18.35819435119629],
        [84.14180755615229, 18.35819435119629],
        [84.14180755615229, 18.3579158782959],
        [84.14153289794945, 18.35791969299322],
        [84.14153289794945, 18.357641220092887],
        [84.1412429809572, 18.357641220092887],
        [84.1412429809572, 18.356809616088867],
        [84.1409759521485, 18.356809616088867],
        [84.1409759521485, 18.35625076293951],
        [84.14068603515642, 18.35625076293951],
        [84.14068603515642, 18.355970382690373],
        [84.14041900634794, 18.355972290039176],
        [84.14041900634794, 18.355693817138786],
        [84.14013671875, 18.355693817138786],
        [84.14013671875, 18.35541725158697],
        [84.13986206054716, 18.35542106628418],
        [84.13986206054716, 18.35485267639166],
        [84.13957977294922, 18.35486030578619],
        [84.13957977294922, 18.354583740234432],
        [84.13930511474621, 18.354583740234432],
        [84.13930511474621, 18.354028701782227],
        [84.13903045654303, 18.354030609130803],
        [84.13903045654303, 18.353750228881836],
        [84.13874816894526, 18.353750228881836],
        [84.13874816894526, 18.35320281982422],
        [84.13846588134771, 18.35320281982422],
        [84.13846588134771, 18.352640151977482],
        [84.13819885253923, 18.352640151977482],
        [84.13819885253923, 18.351789474487248],
        [84.13791656494146, 18.351804733276367],
        [84.13791656494146, 18.351251602172965],
        [84.13764190673845, 18.351251602172965],
        [84.13764190673845, 18.350690841674805],
        [84.13735961914091, 18.350694656372184],
        [84.13735961914091, 18.349859237671012],
        [84.1370849609375, 18.349859237671012],
        [84.1370849609375, 18.34930610656744],
        [84.13652801513672, 18.34930992126459],
        [84.13652801513672, 18.34903907775879],
        [84.135971069336, 18.34903907775879],
        [84.135971069336, 18.34874916076666],
        [84.13569641113276, 18.34874916076666],
        [84.13569641113276, 18.348470687866268],
        [84.13541412353521, 18.3484725952149],
        [84.13541412353521, 18.34819412231451],
        [84.1351394653322, 18.34819412231451],
        [84.1351394653322, 18.34791946411127],
        [84.1348571777346, 18.34791946411127],
        [84.1348571777346, 18.347639083862305],
        [84.13458251953142, 18.347639083862305],
        [84.13458251953142, 18.347360610961914],
        [84.13430786132841, 18.347360610961914],
        [84.13430786132841, 18.347084045410156],
        [84.13319396972685, 18.347084045410156],
        [84.13319396972685, 18.346805572509766],
        [84.13208007812494, 18.346809387207088],
        [84.13208007812494, 18.347360610961914],
        [84.1318054199221, 18.347360610961914],
        [84.1318054199221, 18.3484725952149],
        [84.13124847412115, 18.348470687866268],
        [84.13124847412115, 18.34930992126459],
        [84.13041687011719, 18.34930610656744],
        [84.13041687011719, 18.349582672119254],
        [84.12985992431646, 18.34958076477045],
        [84.12985992431646, 18.349859237671012],
        [84.12930297851568, 18.349859237671012],
        [84.12930297851568, 18.35014152526861],
        [84.12902832031244, 18.35014152526861],
        [84.12902832031244, 18.350421905517578],
        [84.12847137451189, 18.350416183471793],
        [84.12847137451189, 18.350694656372184],
        [84.12819671630865, 18.350694656372184],
        [84.12819671630865, 18.350973129272575],
        [84.12790679931658, 18.350969314575195],
        [84.12790679931658, 18.351251602172965],
        [84.12708282470732, 18.351251602172965],
        [84.12708282470732, 18.3515300750733],
        [84.12652587890636, 18.351528167724723],
        [84.12652587890636, 18.351804733276367],
        [84.12596893310541, 18.351804733276367],
        [84.12596893310541, 18.351528167724723],
        [84.12568664550787, 18.3515300750733],
        [84.12568664550787, 18.351251602172965],
        [84.12596893310541, 18.351251602172965],
        [84.12596893310541, 18.350969314575195],
        [84.12625122070318, 18.350973129272575],
        [84.12625122070318, 18.350694656372184],
        [84.12680816650396, 18.350694656372184],
        [84.12680816650396, 18.35014152526861],
        [84.12708282470732, 18.35014152526861],
        [84.12708282470732, 18.34958076477045],
        [84.12735748291016, 18.349582672119254],
        [84.12735748291016, 18.34930610656744],
        [84.1276397705081, 18.34930992126459],
        [84.1276397705081, 18.34903907775879],
        [84.12735748291016, 18.34903907775879],
        [84.12735748291016, 18.3481903076173],
        [84.12708282470732, 18.34819412231451],
        [84.12708282470732, 18.346805572509766],
        [84.12735748291016, 18.346809387207088],
        [84.12735748291016, 18.346530914306754],
        [84.12790679931658, 18.346530914306754],
        [84.12790679931658, 18.346809387207088],
        [84.12819671630865, 18.346805572509766],
        [84.12819671630865, 18.347084045410156],
        [84.12790679931658, 18.347080230712947],
        [84.12790679931658, 18.34791946411127],
        [84.12819671630865, 18.34791946411127],
        [84.12819671630865, 18.347639083862305],
        [84.12847137451189, 18.347639083862305],
        [84.12847137451189, 18.347360610961914],
        [84.12874603271507, 18.347360610961914],
        [84.12874603271507, 18.347080230712947],
        [84.12902832031244, 18.347084045410156],
        [84.12902832031244, 18.34597206115717],
        [84.12847137451189, 18.345979690551758],
        [84.12847137451189, 18.345689773559627],
        [84.12819671630865, 18.345693588256836],
        [84.12819671630865, 18.345415115356445],
        [84.12790679931658, 18.3454208374024],
        [84.12790679931658, 18.345140457153377],
        [84.12819671630865, 18.345140457153377],
        [84.12819671630865, 18.34486007690424],
        [84.1287536621096, 18.344861984253043],
        [84.1287536621096, 18.344583511352653],
        [84.12902832031244, 18.344583511352653],
        [84.12902832031244, 18.344306945800838],
        [84.12930297851568, 18.344310760498047],
        [84.12930297851568, 18.343200683593864],
        [84.12847137451189, 18.343200683593864],
        [84.12847137451189, 18.342910766601562],
        [84.12625885009771, 18.342910766601562],
        [84.12625885009771, 18.343200683593864],
        [84.12569427490257, 18.343193054199332],
        [84.12569427490257, 18.343471527099666],
        [84.12486267089861, 18.343471527099666],
        [84.12486267089861, 18.343193054199332],
        [84.12431335449219, 18.343200683593864],
        [84.12431335449219, 18.342910766601562],
        [84.12486267089861, 18.342916488647518],
        [84.12486267089861, 18.342638015747127],
        [84.12513732910162, 18.342639923095703],
        [84.12513732910162, 18.342359542846737],
        [84.12569427490257, 18.342361450195312],
        [84.12569427490257, 18.342084884643555],
        [84.12596893310541, 18.342084884643555],
        [84.12596893310541, 18.341527938842773],
        [84.12625885009771, 18.34152984619135],
        [84.12625885009771, 18.341249465942383],
        [84.12680816650396, 18.341249465942383],
        [84.12680816650396, 18.340972900390625],
        [84.12735748291016, 18.34097480773937],
        [84.12735748291016, 18.340415954589844],
        [84.12708282470732, 18.340419769287166],
        [84.12708282470732, 18.339921951293945],
        [84.12680816650396, 18.339870452880973],
        [84.12680816650396, 18.33903121948248],
        [84.12652587890636, 18.33903121948248],
        [84.12652587890636, 18.33847236633312],
        [84.12680816650396, 18.33847236633312],
        [84.12680816650396, 18.337083816528377],
        [84.12708282470732, 18.337083816528377],
        [84.12708282470732, 18.336805343627987],
        [84.12735748291016, 18.33680915832514],
        [84.12735748291016, 18.336248397827205],
        [84.12708282470732, 18.336248397827205],
        [84.12708282470732, 18.33513832092285],
        [84.12680816650396, 18.33513832092285],
        [84.12680816650396, 18.33403968811041],
        [84.12652587890636, 18.33403968811041],
        [84.12652587890636, 18.33292007446289],
        [84.12625885009771, 18.33292007446289],
        [84.12625885009771, 18.331809997558537],
        [84.12596893310541, 18.331809997558537],
        [84.12596893310541, 18.330141067504826],
        [84.12568664550787, 18.330141067504826],
        [84.12568664550787, 18.328750610351506],
        [84.12541961669939, 18.328750610351506],
        [84.12541961669939, 18.328189849853572],
        [84.12486267089861, 18.32819366455078],
        [84.12486267089861, 18.328472137451115],
        [84.12458038330107, 18.32847023010254],
        [84.12458038330107, 18.328750610351506],
        [84.1234664916995, 18.328750610351506],
        [84.1234664916995, 18.32707977294922],
        [84.12374877929688, 18.327083587646598],
        [84.12374877929688, 18.326528549194393],
        [84.12403106689482, 18.32653045654297],
        [84.12403106689482, 18.325702667236385],
        [84.12431335449219, 18.325702667236385],
        [84.12431335449219, 18.325420379638615],
        [84.12458038330107, 18.325420379638615],
        [84.12458038330107, 18.32514190673828],
        [84.12486267089861, 18.32514190673828],
        [84.12486267089861, 18.32484817504877],
        [84.12513732910162, 18.324861526489258],
        [84.12513732910162, 18.32458114624029],
        [84.12596893310541, 18.324579238891715],
        [84.12596893310541, 18.325702667236385],
        [84.12625122070318, 18.325695037841854],
        [84.12625122070318, 18.326250076294002],
        [84.12680816650396, 18.326250076294002],
        [84.12680816650396, 18.32653045654297],
        [84.12708282470732, 18.32653045654297],
        [84.12708282470732, 18.326250076294002],
        [84.12735748291016, 18.326250076294002],
        [84.12735748291016, 18.32484817504877],
        [84.1276397705081, 18.324861526489258],
        [84.1276397705081, 18.32291603088379],
        [84.12790679931658, 18.32291984558111],
        [84.12790679931658, 18.320690155029297],
        [84.12819671630865, 18.320693969726676],
        [84.12819671630865, 18.319583892822322],
        [84.12847137451189, 18.319583892822322],
        [84.12847137451189, 18.31902694702154],
        [84.12874603271507, 18.319030761718693],
        [84.12874603271507, 18.31847000122076],
        [84.12902832031244, 18.318471908569336],
        [84.12902832031244, 18.318195343017578],
        [84.12930297851568, 18.318201065063533],
        [84.12930297851568, 18.317640304565373],
        [84.12959289550776, 18.317640304565373],
        [84.12959289550776, 18.31680107116705],
        [84.12930297851568, 18.316804885864258],
        [84.12930297851568, 18.315971374511662],
        [84.1295852661134, 18.315971374511662],
        [84.1295852661134, 18.315694808960075],
        [84.12985992431646, 18.315694808960075],
        [84.12985992431646, 18.31513977050787],
        [84.1295852661134, 18.31513977050787],
        [84.1295852661134, 18.31430625915533],
        [84.12902832031244, 18.314310073852482],
        [84.12902832031244, 18.314029693603516],
        [84.12847137451189, 18.314029693603516],
        [84.12847137451189, 18.313751220703125],
        [84.12819671630865, 18.313751220703125],
        [84.12819671630865, 18.31347274780279],
        [84.1276397705081, 18.31347274780279],
        [84.1276397705081, 18.3131942749024],
        [84.12735748291016, 18.3131942749024],
        [84.12735748291016, 18.31291580200201],
        [84.12708282470732, 18.31291580200201],
        [84.12708282470732, 18.312084197998047],
        [84.12680816650396, 18.312084197998047],
        [84.12680816650396, 18.311527252197266],
        [84.12652587890636, 18.311531066894645],
        [84.12652587890636, 18.311252593994254],
        [84.12596893310541, 18.311252593994254],
        [84.12596893310541, 18.31096839904791],
        [84.12569427490257, 18.310970306396484],
        [84.12569427490257, 18.310417175293082],
        [84.12541961669939, 18.31041908264166],
        [84.12541961669939, 18.310140609741268],
        [84.12513732910162, 18.310140609741268],
        [84.12513732910162, 18.30987167358404],
        [84.12486267089861, 18.30987167358404],
        [84.12486267089861, 18.309579849243164],
        [84.12458038330107, 18.309583663940543],
        [84.12458038330107, 18.309305191040153],
        [84.12403106689482, 18.309310913085938],
        [84.12403106689482, 18.309030532836914],
        [84.1234664916995, 18.309030532836914],
        [84.1234664916995, 18.30874824523937],
        [84.12291717529291, 18.30874824523937],
        [84.12291717529291, 18.30846977233898],
        [84.12264251709007, 18.308471679687557],
        [84.12264251709007, 18.3081951141358],
        [84.12209320068365, 18.308200836181754],
        [84.12209320068365, 18.30792045593256],
        [84.12097167968778, 18.30792045593256],
        [84.12097167968778, 18.308200836181754],
        [84.12014007568365, 18.308200836181754],
        [84.12014007568365, 18.30792045593256],
        [84.11985778808605, 18.30792045593256],
        [84.11985778808605, 18.307638168335018],
        [84.11958312988287, 18.307638168335018],
        [84.11958312988287, 18.307359695434627],
        [84.11902618408226, 18.307361602783203],
        [84.11902618408226, 18.30708312988287],
        [84.11875152587908, 18.30708312988287],
        [84.11875152587908, 18.30680465698248],
        [84.11859893798828, 18.30680847167963],
        [84.1184692382813, 18.306810379028434],
        [84.1184692382813, 18.306528091430664],
        [84.11818695068376, 18.306528091430664],
        [84.11818695068376, 18.306249618530273],
        [84.11763763427734, 18.306249618530273],
        [84.11763763427734, 18.305971145629883],
        [84.11708068847662, 18.305973052978516],
        [84.11708068847662, 18.305694580078125],
        [84.11680603027355, 18.305694580078125],
        [84.11680603027355, 18.305416107177734],
        [84.1162490844726, 18.305419921875057],
        [84.1162490844726, 18.30513954162592],
        [84.11569213867205, 18.30513954162592],
        [84.11569213867205, 18.30486106872553],
        [84.1154174804688, 18.30486106872553],
        [84.1154174804688, 18.304580688476562],
        [84.11486053466825, 18.30458259582514],
        [84.11486053466825, 18.304304122924805],
        [84.11429595947294, 18.304309844970703],
        [84.11429595947294, 18.304029464721737],
        [84.11402893066412, 18.304029464721737],
        [84.11402893066412, 18.303739547729492],
        [84.11347198486334, 18.30374908447277],
        [84.11347198486334, 18.303472518921012],
        [84.1131973266601, 18.303472518921012],
        [84.1131973266601, 18.303195953369197],
        [84.11264038085955, 18.303195953369197],
        [84.11264038085955, 18.302917480468807],
        [84.11207580566423, 18.302919387817383],
        [84.11207580566423, 18.302640914916992],
        [84.11180877685575, 18.302640914916992],
        [84.11180877685575, 18.302360534668026],
        [84.11125183105497, 18.302360534668026],
        [84.11125183105497, 18.302082061767635],
        [84.11069488525402, 18.302082061767635],
        [84.11069488525402, 18.301805496215877],
        [84.11042022705084, 18.301811218261832],
        [84.11042022705084, 18.30153083801264],
        [84.10986328125023, 18.30153083801264],
        [84.10986328125023, 18.301250457763672],
        [84.10930633544928, 18.301250457763672],
        [84.10930633544928, 18.300970077514705],
        [84.10902404785173, 18.300973892211914],
        [84.10902404785173, 18.300695419311523],
        [84.10846710205095, 18.30068016052246],
        [84.10847473144531, 18.300416946411133],
        [84.10791778564453, 18.300420761108512],
        [84.10791778564453, 18.300140380859318],
        [84.10764312744152, 18.300140380859318],
        [84.10764312744152, 18.299861907958928],
        [84.10708618164057, 18.29986381530773],
        [84.10708618164057, 18.299583435058594],
        [84.10652923584001, 18.299583435058594],
        [84.10652923584001, 18.299304962158203],
        [84.10624694824241, 18.29931068420416],
        [84.10624694824241, 18.299030303955135],
        [84.10569763183622, 18.299030303955135],
        [84.10569763183622, 18.2987518310548],
        [84.10513305664091, 18.2987518310548],
        [84.10513305664091, 18.29846954345703],
        [84.1048583984375, 18.29846954345703],
        [84.1048583984375, 18.29819488525402],
        [84.1043090820313, 18.29819488525402],
        [84.1043090820313, 18.29791641235363],
        [84.10375213623053, 18.297929763793945],
        [84.10375213623053, 18.297641754150447],
        [84.10346984863276, 18.297641754150447],
        [84.10346984863276, 18.297359466552848],
        [84.1029129028322, 18.297359466552848],
        [84.1029129028322, 18.29708290100109],
        [84.10263824462896, 18.29708290100109],
        [84.10263824462896, 18.296806335449276],
        [84.10206604003912, 18.296810150146428],
        [84.10206604003912, 18.296531677246094],
        [84.1015167236331, 18.296531677246094],
        [84.1015167236331, 18.29624748229992],
        [84.10124969482428, 18.29624748229992],
        [84.10124969482428, 18.295970916748104],
        [84.1006927490235, 18.295972824096737],
        [84.1006927490235, 18.295694351196346],
        [84.10013580322288, 18.295694351196346],
        [84.10013580322288, 18.295415878295955],
        [84.0998611450197, 18.295419692993107],
        [84.0998611450197, 18.295141220092717],
        [84.09929656982439, 18.295141220092717],
        [84.09929656982439, 18.29486846923828],
        [84.09874725341797, 18.29486846923828],
        [84.09874725341797, 18.294580459594783],
        [84.09847259521513, 18.294584274291992],
        [84.09847259521513, 18.2943058013916],
        [84.09792327880871, 18.294309616088924],
        [84.09792327880871, 18.29403114318859],
        [84.09735870361322, 18.29403114318859],
        [84.09735870361322, 18.293750762939396],
        [84.09680175781267, 18.293750762939396],
        [84.09680175781267, 18.29347038269043],
        [84.09652709960943, 18.293472290039006],
        [84.09652709960943, 18.293193817138672],
        [84.09597015380888, 18.293193817138672],
        [84.09597015380888, 18.29291534423828],
        [84.0954132080081, 18.292921066284237],
        [84.0954132080081, 18.292640686035213],
        [84.09485626220732, 18.292640686035213],
        [84.09485626220732, 18.292360305786076],
        [84.09458160400396, 18.292360305786076],
        [84.09458160400396, 18.29207992553711],
        [84.09402465820318, 18.29208374023449],
        [84.09402465820318, 18.291805267334098],
        [84.09347534179693, 18.291805267334098],
        [84.09347534179693, 18.291528701782283],
        [84.09291839599638, 18.29153060913086],
        [84.09291839599638, 18.291250228881893],
        [84.09263610839861, 18.291250228881893],
        [84.09263610839861, 18.290969848632926],
        [84.09208679199219, 18.290971755981502],
        [84.09208679199219, 18.290695190429744],
        [84.09152984619146, 18.2907009124757],
        [84.09152984619146, 18.290420532226506],
        [84.09124755859386, 18.290420532226506],
        [84.09124755859386, 18.29014015197754],
        [84.09069824218767, 18.29014015197754],
        [84.09069061279291, 18.289859771728572],
        [84.09014129638689, 18.28986167907715],
        [84.09014129638689, 18.289583206176815],
        [84.08958435058611, 18.289583206176815],
        [84.08958435058611, 18.289304733276424],
        [84.08930206298857, 18.289304733276424],
        [84.08930206298857, 18.28902816772461],
        [84.08875274658232, 18.289030075073185],
        [84.08875274658232, 18.28874015808111],
        [84.08847045898443, 18.28874015808111],
        [84.08847045898443, 18.289030075073185],
        [84.08790588378912, 18.289030075073185],
        [84.08790588378912, 18.28874015808111],
        [84.08763885498041, 18.28874969482422],
        [84.08763885498041, 18.28819465637207],
        [84.08736419677757, 18.28819465637207],
        [84.08736419677757, 18.28791618347168],
        [84.08598327636719, 18.287919998169002],
        [84.08598327636719, 18.287639617919865],
        [84.08569335937528, 18.287639617919865],
        [84.08569335937528, 18.285419464111328],
        [84.08541870117193, 18.285419464111328],
        [84.08541870117193, 18.28514289855957],
        [84.08513641357433, 18.28514289855957],
        [84.08513641357433, 18.284862518310604],
        [84.08486175537115, 18.284862518310604],
        [84.08486175537115, 18.284578323364258],
        [84.08430480957054, 18.284584045410213],
        [84.08430480957054, 18.284305572509822],
        [84.08403015136736, 18.284309387206974],
        [84.08403015136736, 18.284030914306584],
        [84.08374786376959, 18.284030914306584],
        [84.08374786376959, 18.28374862670904],
        [84.08403015136736, 18.28374862670904],
        [84.08403015136736, 18.283468246460075],
        [84.08291625976562, 18.28347015380865],
        [84.08291625976562, 18.283193588256893],
        [84.08208465576183, 18.283193588256893],
        [84.08208465576183, 18.282918930053654],
        [84.08180236816412, 18.282930374145565],
        [84.08180236816412, 18.282640457153263],
        [84.08152770996088, 18.282640457153263],
        [84.08152770996088, 18.28235816955572],
        [84.08097076416033, 18.282360076904297],
        [84.08097076416033, 18.28208351135254],
        [84.08069610595709, 18.28208351135254],
        [84.08069610595709, 18.28153038024908],
        [84.07985687255876, 18.28153038024908],
        [84.07985687255876, 18.281248092651367],
        [84.07958221435575, 18.281248092651367],
        [84.07958221435575, 18.280969619750977],
        [84.07930755615234, 18.280971527099553],
        [84.07930755615234, 18.280694961547965],
        [84.07847595214838, 18.28070068359375],
        [84.07847595214838, 18.280420303344727],
        [84.0781860351563, 18.280420303344727],
        [84.0781860351563, 18.28013992309576],
        [84.07763671875023, 18.28013992309576],
        [84.07763671875023, 18.279859542846793],
        [84.07736206054705, 18.27986145019537],
        [84.07736206054705, 18.279582977295036],
        [84.07707977294928, 18.279582977295036],
        [84.07707977294928, 18.27902793884283],
        [84.07681274414062, 18.279029846191406],
        [84.07681274414062, 18.27874946594244],
        [84.07653045654325, 18.27874946594244],
        [84.07653045654325, 18.278190612793082],
        [84.07624816894531, 18.27819442749029],
        [84.07624816894531, 18.277914047241325],
        [84.07596588134794, 18.277919769287053],
        [84.07596588134794, 18.277639389038086],
        [84.07541656494152, 18.277639389038086],
        [84.07541656494152, 18.277360916137695],
        [84.0745773315432, 18.277360916137695],
        [84.0745773315432, 18.27708053588873],
        [84.07402801513678, 18.277082443237305],
        [84.07402801513678, 18.276805877685547],
        [84.07375335693376, 18.276805877685547],
        [84.07375335693376, 18.276527404785156],
        [84.07209014892584, 18.276531219482536],
        [84.07209014892584, 18.27625083923334],
        [84.07041931152372, 18.27625083923334],
        [84.07041931152372, 18.275970458984375],
        [84.069580078125, 18.27597236633295],
        [84.069580078125, 18.275695800781364],
        [84.0690307617188, 18.275695800781364],
        [84.0690307617188, 18.275417327880973],
        [84.0684661865235, 18.275421142578182],
        [84.0684661865235, 18.27514076232916],
        [84.06791687011724, 18.27514076232916],
        [84.06791687011724, 18.27486038208002],
        [84.06735992431669, 18.27486038208002],
        [84.06735992431669, 18.27458190917963],
        [84.06680297851591, 18.274583816528434],
        [84.06680297851591, 18.274305343628043],
        [84.06624603271513, 18.274311065673828],
        [84.06624603271513, 18.274030685424805],
        [84.06569671630854, 18.274030685424805],
        [84.06569671630854, 18.273759841919002],
        [84.06485748291038, 18.273759841919002],
        [84.06485748291038, 18.273471832275447],
        [84.06430816650419, 18.27347373962408],
        [84.06430816650419, 18.27319526672369],
        [84.06375122070341, 18.27320289611822],
        [84.06375122070341, 18.27292060852045],
        [84.0631866455081, 18.27292060852045],
        [84.0631866455081, 18.272640228271484],
        [84.06263732910168, 18.272640228271484],
        [84.06263732910168, 18.272361755371094],
        [84.06208038330072, 18.272361755371094],
        [84.06208038330072, 18.27208328247076],
        [84.0615310668947, 18.27208328247076],
        [84.0615310668947, 18.27180480957037],
        [84.06097412109392, 18.27180480957037],
        [84.06097412109392, 18.271528244018555],
        [84.06015014648449, 18.27153015136713],
        [84.06015014648449, 18.27125167846674],
        [84.05958557128918, 18.27125167846674],
        [84.05958557128918, 18.270969390869197],
        [84.05902862548822, 18.270969390869197],
        [84.05902862548822, 18.270694732666016],
        [84.05847167968767, 18.27070045471197],
        [84.05847167968767, 18.270420074462947],
        [84.05790710449236, 18.270420074462947],
        [84.05790710449236, 18.270141601562614],
        [84.05735778808594, 18.270141601562614],
        [84.05735778808594, 18.269859313964844],
        [84.05680847167974, 18.269859313964844],
        [84.05680847167974, 18.269580841064453],
        [84.0559692382812, 18.26958274841303],
        [84.0559692382812, 18.269306182861442],
        [84.05541992187517, 18.269308090210018],
        [84.05541992187517, 18.269029617309627],
        [84.05486297607439, 18.269029617309627],
        [84.05486297607439, 18.26874923706066],
        [84.05430603027344, 18.26874923706066],
        [84.05430603027344, 18.2684707641601],
        [84.05374908447266, 18.268472671508903],
        [84.05374908447266, 18.268194198608512],
        [84.05319213867193, 18.268194198608512],
        [84.05319213867193, 18.267919540405273],
        [84.05264282226585, 18.267919540405273],
        [84.05264282226585, 18.2676296234132],
        [84.0520858764649, 18.26763725280773],
        [84.0520858764649, 18.267360687255916],
        [84.05124664306658, 18.267360687255916],
        [84.05124664306658, 18.26708030700695],
        [84.05069732666016, 18.26708412170416],
        [84.05069732666016, 18.266805648803768],
        [84.05014038085943, 18.26680946350092],
        [84.05014038085943, 18.26653099060053],
        [84.04957580566412, 18.26653099060053],
        [84.04957580566412, 18.266250610351562],
        [84.04902648925804, 18.266250610351562],
        [84.04902648925804, 18.265970230102596],
        [84.04846954345709, 18.265972137451172],
        [84.04846954345709, 18.265693664550838],
        [84.04792022705107, 18.265693664550838],
        [84.04792022705107, 18.265415191650447],
        [84.04736328125028, 18.265420913696403],
        [84.04736328125028, 18.26514053344721],
        [84.04651641845709, 18.26514053344721],
        [84.04651641845709, 18.264860153198242],
        [84.04597473144554, 18.264862060546818],
        [84.04597473144554, 18.264583587646484],
        [84.04541778564459, 18.264583587646484],
        [84.04541778564459, 18.264305114746094],
        [84.0448532104495, 18.26431083679205],
        [84.0448532104495, 18.264030456543026],
        [84.04429626464872, 18.264030456543026],
        [84.04429626464872, 18.26375007629389],
        [84.0437469482423, 18.26375007629389],
        [84.0437469482423, 18.263469696044922],
        [84.04291534423851, 18.263471603393498],
        [84.04291534423851, 18.263193130493107],
        [84.04235839843756, 18.263200759887695],
        [84.04235839843756, 18.262920379638672],
        [84.04180908203153, 18.262920379638672],
        [84.04180908203153, 18.262639999389705],
        [84.04125213623075, 18.262639999389705],
        [84.04125213623075, 18.26235961914074],
        [84.04013824462885, 18.262361526489315],
        [84.04013824462885, 18.26208305358898],
        [84.03930664062506, 18.26208305358898],
        [84.03930664062506, 18.261806488037166],
        [84.03901672363298, 18.261819839477653],
        [84.03901672363298, 18.26152992248535],
        [84.0387496948245, 18.26152992248535],
        [84.0387496948245, 18.261249542236385],
        [84.03819274902372, 18.261249542236385],
        [84.03819274902372, 18.260971069335994],
        [84.03763580322294, 18.260974884033203],
        [84.03763580322294, 18.260694503784237],
        [84.03708648681635, 18.260694503784237],
        [84.03708648681635, 18.260416030883846],
        [84.0365295410158, 18.260419845580998],
        [84.0365295410158, 18.26013946533203],
        [84.035697937012, 18.26013946533203],
        [84.035697937012, 18.259862899780273],
        [84.03514099121122, 18.259862899780273],
        [84.03514099121122, 18.25958251953125],
        [84.03458404541044, 18.25958251953125],
        [84.03458404541044, 18.259305953979492],
        [84.03402709960949, 18.259309768676815],
        [84.03402709960949, 18.259029388427678],
        [84.03347015380854, 18.259029388427678],
        [84.03347015380854, 18.25876045227062],
        [84.03291320800798, 18.25876045227062],
        [84.03291320800798, 18.258472442626896],
        [84.03208160400419, 18.258472442626896],
        [84.03208160400419, 18.258193969726562],
        [84.03152465820341, 18.258193969726562],
        [84.03152465820341, 18.25791740417492],
        [84.03098297119135, 18.257921218872127],
        [84.03098297119135, 18.257640838623104],
        [84.03041839599604, 18.257640838623104],
        [84.03041839599604, 18.25735855102539],
        [84.0298461914062, 18.25735855102539],
        [84.0298461914062, 18.257078170776424],
        [84.0293045043947, 18.25708389282238],
        [84.0293045043947, 18.25680541992199],
        [84.02847290039091, 18.25680923461914],
        [84.02847290039091, 18.25653076171875],
        [84.02791595458996, 18.25653076171875],
        [84.02791595458996, 18.256248474121207],
        [84.027359008789, 18.256248474121207],
        [84.027359008789, 18.25596809387207],
        [84.02680206298845, 18.255971908569393],
        [84.02680206298845, 18.255695343017635],
        [84.0262527465822, 18.255695343017635],
        [84.0262527465822, 18.25541877746582],
        [84.02569580078125, 18.255420684814396],
        [84.02569580078125, 18.255138397216854],
        [84.0248565673831, 18.255138397216854],
        [84.0248565673831, 18.254859924316463],
        [84.0243072509765, 18.25486183166504],
        [84.0243072509765, 18.254583358764705],
        [84.02375030517595, 18.254583358764705],
        [84.02375030517595, 18.254304885864315],
        [84.02319335937517, 18.254304885864315],
        [84.02319335937517, 18.254026412963924],
        [84.02263641357439, 18.254030227661076],
        [84.02263641357439, 18.25374984741211],
        [84.02208709716797, 18.25374984741211],
        [84.02208709716797, 18.253469467163143],
        [84.02153015136724, 18.25347137451172],
        [84.02153015136724, 18.25319480895996],
        [84.02097320556646, 18.25319480895996],
        [84.02097320556646, 18.25291633605957],
        [84.02041625976585, 18.252920150756893],
        [84.02041625976585, 18.25263023376465],
        [84.0198593139649, 18.252639770507756],
        [84.0198593139649, 18.252361297607365],
        [84.01930236816435, 18.252361297607365],
        [84.01930236816435, 18.252082824706974],
        [84.0187530517581, 18.252082824706974],
        [84.0187530517581, 18.25180435180664],
        [84.01819610595714, 18.25181007385254],
        [84.01819610595714, 18.251529693603572],
        [84.0176391601562, 18.251529693603572],
        [84.0176391601562, 18.251251220703182],
        [84.01708221435564, 18.251251220703182],
        [84.01708221435564, 18.250970840454045],
        [84.01652526855486, 18.250972747802848],
        [84.01652526855486, 18.250694274902457],
        [84.01597595214844, 18.250694274902457],
        [84.01597595214844, 18.250972747802848],
        [84.01513671875011, 18.250972747802848],
        [84.01513671875011, 18.250694274902457],
        [84.01403045654314, 18.250694274902457],
        [84.01403045654314, 18.250415802002067],
        [84.01264190673857, 18.25041961669922],
        [84.01264190673857, 18.250141143798828],
        [84.01181030273443, 18.250141143798828],
        [84.01181030273443, 18.24986076354986],
        [84.01125335693382, 18.24986076354986],
        [84.01125335693382, 18.24958992004406],
        [84.01097106933611, 18.24958992004406],
        [84.01097106933611, 18.249309539794865],
        [84.01069641113287, 18.249309539794865],
        [84.01069641113287, 18.249031066894474],
        [84.0104064941408, 18.249031066894474],
        [84.0104064941408, 18.248189926147575],
        [84.01013946533232, 18.248193740844783],
        [84.01013946533232, 18.24791717529297],
        [84.00985717773455, 18.247920989990348],
        [84.00985717773455, 18.247640609741154],
        [84.00958251953153, 18.247640609741154],
        [84.00958251953153, 18.247920989990348],
        [84.00903320312511, 18.247920989990348],
        [84.00903320312511, 18.247640609741154],
        [84.0087509155274, 18.247640609741154],
        [84.0087509155274, 18.247360229492188],
        [84.0084686279298, 18.247360229492188],
        [84.0084686279298, 18.24707984924322],
        [84.00791931152361, 18.24708366394043],
        [84.00791931152361, 18.24680519104004],
        [84.00763702392601, 18.2468204498291],
        [84.00763702392601, 18.24653053283697],
        [84.00736236572283, 18.24653053283697],
        [84.00736236572283, 18.246250152587834],
        [84.00680541992205, 18.246250152587834],
        [84.00680541992205, 18.245973587036246],
        [84.00597381591825, 18.245973587036246],
        [84.00597381591825, 18.245695114135856],
        [84.0054168701173, 18.245702743530387],
        [84.0054168701173, 18.245420455932617],
        [84.00485992431635, 18.245416641235465],
        [84.00485992431635, 18.245973587036246],
        [84.00458526611351, 18.245973587036246],
        [84.00459289550787, 18.246250152587834],
        [84.00402832031256, 18.246250152587834],
        [84.00402832031256, 18.245971679687443],
        [84.00375366210955, 18.245973587036246],
        [84.00375366210955, 18.245695114135856],
        [84.003471374512, 18.245702743530387],
        [84.003471374512, 18.245420455932617],
        [84.0031967163086, 18.245420455932617],
        [84.0031967163086, 18.24486160278326],
        [84.00291442871122, 18.24486160278326],
        [84.00291442871122, 18.24402809143072],
        [84.00235748291027, 18.24403190612793],
        [84.00235748291027, 18.243751525878906],
        [84.00180816650385, 18.243751525878906],
        [84.00180816650385, 18.243469238281364],
        [84.0012512207033, 18.243473052978572],
        [84.0012512207033, 18.243194580078182],
        [84.00069427490251, 18.243194580078182],
        [84.00069427490251, 18.24291610717779],
        [83.99987030029325, 18.242921829223746],
        [83.99903106689459, 18.242921829223746],
        [83.99903106689459, 18.2426376342774],
        [83.99846649169928, 18.2426376342774],
        [83.99846649169928, 18.24235916137701],
        [83.99819183349626, 18.24235916137701],
        [83.99819183349626, 18.24208068847662],
        [83.99764251709001, 18.242082595825195],
        [83.99764251709001, 18.241807937622184],
        [83.99736022949247, 18.24180984497076],
        [83.99736022949247, 18.241527557373047],
        [83.99652862548828, 18.241527557373047],
        [83.99652862548828, 18.241249084472656],
        [83.99624633789091, 18.241249084472656],
        [83.99624633789091, 18.240970611572266],
        [83.99569702148432, 18.24097251892084],
        [83.99569702148432, 18.240694046020508],
        [83.99541473388678, 18.240694046020508],
        [83.99541473388678, 18.24041938781744],
        [83.99485778808616, 18.24041938781744],
        [83.99485778808616, 18.24014091491705],
        [83.99430847167997, 18.24014091491705],
        [83.99430847167997, 18.239860534667912],
        [83.99373626709013, 18.239860534667912],
        [83.99373626709013, 18.239580154418945],
        [83.99319458007841, 18.239583969116325],
        [83.99319458007841, 18.239305496215934],
        [83.99263763427746, 18.23931121826172],
        [83.99263763427746, 18.239030838012695],
        [83.9920806884765, 18.239030838012695],
        [83.9920806884765, 18.23875045776373],
        [83.99124908447271, 18.23875045776373],
        [83.99124908447271, 18.238470077514762],
        [83.99069213867216, 18.238471984863338],
        [83.99069213867216, 18.23819541931158],
        [83.99014282226591, 18.238189697265625],
        [83.99013519287138, 18.23791694641119],
        [83.98958587646496, 18.23792076110834],
        [83.98958587646496, 18.23764991760254],
        [83.989028930664, 18.23764991760254],
        [83.989028930664, 18.23736000061041],
        [83.98847198486345, 18.237361907958984],
        [83.98847198486345, 18.23708343505865],
        [83.98791503906267, 18.23708343505865],
        [83.98791503906267, 18.236803054809627],
        [83.98735809326172, 18.236810684204215],
        [83.98735809326172, 18.23653030395502],
        [83.98680877685553, 18.23653030395502],
        [83.98680877685553, 18.236249923706055],
        [83.98625183105474, 18.236249923706055],
        [83.98625183105474, 18.235969543457088],
        [83.98569488525413, 18.235971450805664],
        [83.98569488525413, 18.235694885253906],
        [83.98513793945318, 18.235694885253906],
        [83.98513793945318, 18.235416412353516],
        [83.98430633544922, 18.235420227050838],
        [83.98430633544922, 18.2351398468017],
        [83.98401641845732, 18.2351398468017],
        [83.98401641845732, 18.234859466552734],
        [83.98347473144543, 18.23486137390131],
        [83.98347473144543, 18.234584808349723],
        [83.98264312744163, 18.234590530395508],
        [83.98264312744163, 18.234310150146484],
        [83.98208618164068, 18.234310150146484],
        [83.98208618164068, 18.234029769897518],
        [83.98152923584013, 18.234029769897518],
        [83.98152923584013, 18.23374938964855],
        [83.98097229003935, 18.23374938964855],
        [83.98097229003935, 18.233472824096793],
        [83.98041534423857, 18.233472824096793],
        [83.98041534423857, 18.233194351196403],
        [83.98014068603521, 18.233194351196403],
        [83.98014068603521, 18.232915878296012],
        [83.9795761108399, 18.232919692993164],
        [83.9795761108399, 18.232641220092773],
        [83.97846984863287, 18.232641220092773],
        [83.97846984863287, 18.23236274719244],
        [83.97819519042986, 18.23236274719244],
        [83.97819519042986, 18.23208427429205],
        [83.9776382446289, 18.23208427429205],
        [83.9776382446289, 18.23180580139166],
        [83.97708129882818, 18.23180961608881],
        [83.97708129882818, 18.23153114318842],
        [83.97651672363287, 18.23153114318842],
        [83.97651672363287, 18.231252670288086],
        [83.97597503662132, 18.231252670288086],
        [83.97597503662132, 18.230972290039062],
        [83.97569274902361, 18.230972290039062],
        [83.97569274902361, 18.23069381713873],
        [83.97541809082037, 18.23069381713873],
        [83.97541809082037, 18.230417251586914],
        [83.9745864868164, 18.23041915893549],
        [83.9745864868164, 18.2301406860351],
        [83.97402954101568, 18.2301406860351],
        [83.97402954101568, 18.229858398437557],
        [83.9734725952149, 18.229858398437557],
        [83.9734725952149, 18.229579925537166],
        [83.97264099121111, 18.229583740234375],
        [83.97264099121111, 18.229305267333984],
        [83.97235870361334, 18.229309082031307],
        [83.97235870361334, 18.229030609130916],
        [83.9720764160158, 18.229030609130916],
        [83.9720764160158, 18.228748321533203],
        [83.97041320800787, 18.228748321533203],
        [83.97041320800787, 18.228200912475586],
        [83.97013854980463, 18.228200912475586],
        [83.97013854980463, 18.227920532226562],
        [83.96985626220709, 18.227920532226562],
        [83.96985626220709, 18.22763824462902],
        [83.96956634521479, 18.22763824462902],
        [83.96956634521479, 18.22735977172863],
        [83.96930694580084, 18.227361679077205],
        [83.96930694580084, 18.22708320617687],
        [83.96875000000028, 18.22708320617687],
        [83.96875000000028, 18.22680473327648],
        [83.9681930541995, 18.22680473327648],
        [83.9681930541995, 18.22652626037609],
        [83.96763610839872, 18.226530075073242],
        [83.96763610839872, 18.226249694824276],
        [83.96708679199213, 18.226249694824276],
        [83.96708679199213, 18.225971221923885],
        [83.96680450439459, 18.225973129272518],
        [83.96680450439459, 18.225694656372127],
        [83.96624755859398, 18.225694656372127],
        [83.96624755859398, 18.225416183471737],
        [83.96541595459001, 18.22541999816889],
        [83.96541595459001, 18.225139617919922],
        [83.96457672119169, 18.225139617919922],
        [83.96457672119169, 18.22486114501953],
        [83.96430206298834, 18.22486114501953],
        [83.96430206298834, 18.224580764770565],
        [83.96402740478527, 18.22458267211914],
        [83.96402740478527, 18.224304199218807],
        [83.96376037597679, 18.224309921264705],
        [83.96376037597679, 18.224029541015568],
        [83.96235656738298, 18.224029541015568],
        [83.96235656738298, 18.223751068115178],
        [83.96208190917997, 18.223751068115178],
        [83.96208190917997, 18.22347068786621],
        [83.96152496337919, 18.223472595214787],
        [83.96152496337919, 18.223194122314453],
        [83.96125030517584, 18.223194122314453],
        [83.96125030517584, 18.22291755676281],
        [83.96099090576166, 18.222919464111385],
        [83.96099090576166, 18.22265052795416],
        [83.96041870117182, 18.22265052795416],
        [83.96041870117182, 18.222360610961857],
        [83.96013641357445, 18.222360610961857],
        [83.96013641357445, 18.22208023071289],
        [83.95903015136747, 18.222082138061467],
        [83.95903015136747, 18.22180557250988],
        [83.95874786376953, 18.22180938720703],
        [83.95874786376953, 18.22153091430664],
        [83.95847320556669, 18.22153091430664],
        [83.95847320556669, 18.221250534057674],
        [83.95819091796875, 18.221250534057674],
        [83.95819091796875, 18.220970153808707],
        [83.95680236816423, 18.220972061157283],
        [83.95680236816423, 18.22069358825695],
        [83.956527709961, 18.22069358825695],
        [83.956527709961, 18.220417022705135],
        [83.95597076416044, 18.220420837402287],
        [83.95597076416044, 18.22014045715332],
        [83.95540618896513, 18.22014045715332],
        [83.95540618896513, 18.219860076904354],
        [83.95486450195324, 18.219863891601562],
        [83.95486450195324, 18.219583511352596],
        [83.9545669555664, 18.219591140747127],
        [83.9545669555664, 18.21931076049816],
        [83.95430755615229, 18.21931076049816],
        [83.95430755615229, 18.219030380248967],
        [83.9534759521485, 18.219030380248967],
        [83.9534759521485, 18.218751907348576],
        [83.95291900634794, 18.218751907348576],
        [83.95291900634794, 18.218469619751033],
        [83.95236206054716, 18.218469619751033],
        [83.95236206054716, 18.21819496154785],
        [83.95182037353527, 18.218202590942383],
        [83.95182037353527, 18.217920303344783],
        [83.95153045654303, 18.217920303344783],
        [83.95153045654303, 18.21764183044445],
        [83.95124816894543, 18.21764183044445],
        [83.95124816894543, 18.217361450195256],
        [83.95097351074224, 18.217361450195256],
        [83.95097351074224, 18.21708106994629],
        [83.95069122314447, 18.21708106994629],
        [83.95069122314447, 18.216806411743278],
        [83.94958496093767, 18.216806411743278],
        [83.94958496093767, 18.21708106994629],
        [83.94902801513672, 18.21708106994629],
        [83.94902801513672, 18.216806411743278],
        [83.94876098632824, 18.21681022644043],
        [83.94876098632824, 18.21624755859375],
        [83.94819641113293, 18.21624755859375],
        [83.94819641113293, 18.21596908569336],
        [83.94708251953142, 18.21597290039074],
        [83.94708251953142, 18.215694427490348],
        [83.94625091552763, 18.215694427490348],
        [83.94625091552763, 18.215415954589957],
        [83.94486236572271, 18.21541976928711],
        [83.94486236572271, 18.215137481689567],
        [83.94458007812494, 18.215137481689567],
        [83.94458007812494, 18.214860916137752],
        [83.94403076171892, 18.214860916137752],
        [83.94403076171892, 18.214580535888786],
        [83.9431915283206, 18.21458244323736],
        [83.9431915283206, 18.21430778503418],
        [83.9423599243164, 18.214309692382756],
        [83.9423599243164, 18.214031219482365],
        [83.93875122070318, 18.214031219482365],
        [83.93875122070318, 18.214309692382756],
        [83.93653106689482, 18.214309692382756],
        [83.93653106689482, 18.214031219482365],
        [83.93569183349615, 18.214031219482365],
        [83.93569183349615, 18.214309692382756],
        [83.93430328369158, 18.214309692382756],
        [83.93430328369158, 18.2137508392334],
        [83.93459320068365, 18.2137508392334],
        [83.93458557128929, 18.212915420532283],
        [83.93486022949213, 18.21292114257824],
        [83.93486022949213, 18.212640762329045],
        [83.9351425170899, 18.212640762329045],
        [83.9351425170899, 18.212360382080078],
        [83.93541717529308, 18.212360382080078],
        [83.93541717529308, 18.21208000183111],
        [83.93624877929688, 18.21208381652832],
        [83.93624877929688, 18.21180534362793],
        [83.93653106689482, 18.211811065673885],
        [83.93653106689482, 18.21153068542486],
        [83.93682098388672, 18.21153068542486],
        [83.93682098388672, 18.211250305175724],
        [83.93708038330084, 18.211250305175724],
        [83.93708038330084, 18.21071052551281],
        [83.9359664916995, 18.21071052551281],
        [83.9359664916995, 18.210420608520508],
        [83.93430328369158, 18.210420608520508],
        [83.93430328369158, 18.21014022827154],
        [83.93402862548834, 18.21014022827154],
        [83.93402862548834, 18.209859848022575],
        [83.932914733887, 18.20986175537115],
        [83.932914733887, 18.209583282470817],
        [83.93235778808605, 18.209583282470817],
        [83.93235778808605, 18.20902824401861],
        [83.93208312988287, 18.209030151367188],
        [83.93208312988287, 18.20874977111822],
        [83.93102264404308, 18.20874977111822],
        [83.92903137207037, 18.20874977111822],
        [83.92903137207037, 18.208469390869254],
        [83.92763519287126, 18.20847129821783],
        [83.92763519287126, 18.208194732666072],
        [83.92708587646484, 18.208194732666072],
        [83.92708587646484, 18.207916259765682],
        [83.92624664306669, 18.207920074462834],
        [83.92624664306669, 18.207639694213867],
        [83.92346954345703, 18.207639694213867],
        [83.92346954345703, 18.207361221313477],
        [83.92236328125006, 18.207361221313477],
        [83.92236328125006, 18.207082748413086],
        [83.92152404785173, 18.207082748413086],
        [83.92152404785173, 18.206806182861328],
        [83.92069244384794, 18.20680999755865],
        [83.92069244384794, 18.206529617309513],
        [83.92041778564453, 18.206529617309513],
        [83.92041778564453, 18.206251144409123],
        [83.9192962646485, 18.206251144409123],
        [83.9192962646485, 18.206529617309513],
        [83.91897583007841, 18.206527709960938],
        [83.918197631836, 18.206529617309513],
        [83.918197631836, 18.206251144409123],
        [83.91764068603544, 18.206251144409123],
        [83.91764068603544, 18.205972671508732],
        [83.91708374023466, 18.205972671508732],
        [83.91708374023466, 18.2056941986084],
        [83.91625213623053, 18.2056941986084],
        [83.91625213623053, 18.205417633056754],
        [83.91539764404291, 18.20541954040533],
        [83.91539764404291, 18.20514106750494],
        [83.91458129882841, 18.20514106750494],
        [83.91458129882841, 18.204862594604606],
        [83.91374969482422, 18.204862594604606],
        [83.91374969482422, 18.204580307006836],
        [83.91291809082026, 18.204584121704215],
        [83.91291809082026, 18.204305648803825],
        [83.91179656982439, 18.204309463500977],
        [83.91179656982439, 18.20402908325201],
        [83.91124725341814, 18.20402908325201],
        [83.91124725341814, 18.20375061035162],
        [83.90957641601568, 18.20375061035162],
        [83.90957641601568, 18.203468322753906],
        [83.90819549560564, 18.203470230102653],
        [83.90819549560564, 18.203193664550895],
        [83.90763854980469, 18.203193664550895],
        [83.90763854980469, 18.20291709899908],
        [83.90708160400396, 18.202920913696232],
        [83.90708160400396, 18.202640533447266],
        [83.9068069458009, 18.202640533447266],
        [83.9068069458009, 18.202358245849723],
        [83.90652465820318, 18.202362060546875],
        [83.90652465820318, 18.20208358764654],
        [83.90541839599615, 18.20208358764654],
        [83.90541839599615, 18.20180511474615],
        [83.90429687500028, 18.201810836792106],
        [83.90429687500028, 18.201520919799805],
        [83.90347290039068, 18.201530456542912],
        [83.90347290039068, 18.20124816894537],
        [83.90319824218744, 18.20124816894537],
        [83.90319824218744, 18.201530456542912],
        [83.90235900878912, 18.201530456542912],
        [83.90235900878912, 18.20124816894537],
        [83.90125274658232, 18.20124816894537],
        [83.90125274658232, 18.20096969604498],
        [83.90097045898438, 18.200971603393555],
        [83.90097045898438, 18.200696945190543],
        [83.90042114257818, 18.200700759887752],
        [83.90042114257818, 18.20042037963873],
        [83.89901733398455, 18.20042037963873],
        [83.89901733398455, 18.200138092041016],
        [83.89848327636719, 18.200138092041016],
        [83.89848327636719, 18.199859619140625],
        [83.89819335937528, 18.1998615264892],
        [83.89819335937528, 18.19958305358881],
        [83.8976364135745, 18.19958305358881],
        [83.8976364135745, 18.1993083953858],
        [83.89653015136736, 18.199310302734375],
        [83.89653015136736, 18.19902992248541],
        [83.89597320556658, 18.19902992248541],
        [83.89597320556658, 18.198749542236442],
        [83.8954162597658, 18.198749542236442],
        [83.8954162597658, 18.198480606079215],
        [83.89457702636747, 18.198480606079215],
        [83.89457702636747, 18.198190689086914],
        [83.8926391601563, 18.198194503784293],
        [83.8926391601563, 18.1979141235351],
        [83.89208221435575, 18.197919845581055],
        [83.89208221435575, 18.197639465332088],
        [83.89180755615234, 18.197639465332088],
        [83.89180755615234, 18.197360992431697],
        [83.89125061035162, 18.197360992431697],
        [83.89125061035162, 18.19708061218273],
        [83.89069366455084, 18.197082519531307],
        [83.89069366455084, 18.196804046630973],
        [83.8893127441408, 18.1968097686767],
        [83.8893127441408, 18.196250915527344],
        [83.88874816894531, 18.196250915527344],
        [83.88874816894531, 18.195970535278377],
        [83.88847351074247, 18.195972442626953],
        [83.88847351074247, 18.195695877075195],
        [83.88819122314453, 18.195695877075195],
        [83.88819122314453, 18.195417404174805],
        [83.88791656494152, 18.195421218872184],
        [83.88791656494152, 18.19514083862299],
        [83.88707733154303, 18.19514083862299],
        [83.88707733154303, 18.195421218872184],
        [83.88625335693376, 18.195421218872184],
        [83.88625335693376, 18.19514083862299],
        [83.88597106933622, 18.19514083862299],
        [83.88597106933622, 18.194860458374023],
        [83.88569641113281, 18.194860458374023],
        [83.88569641113281, 18.194580078125057],
        [83.88486480712902, 18.194583892822266],
        [83.88486480712902, 18.194026947021484],
        [83.8845825195313, 18.194030761718807],
        [83.8845825195313, 18.19375038146967],
        [83.88430786132807, 18.19375038146967],
        [83.88430786132807, 18.19347190856928],
        [83.88375091552751, 18.193473815918082],
        [83.88375091552751, 18.193195343017692],
        [83.88263702392595, 18.193201065063477],
        [83.88263702392595, 18.192920684814453],
        [83.88234710693388, 18.192920684814453],
        [83.88234710693388, 18.192640304565487],
        [83.88181304931652, 18.192640304565487],
        [83.88181304931652, 18.19234848022461],
        [83.88124847412121, 18.192361831665096],
        [83.88124847412121, 18.192083358764762],
        [83.88014221191423, 18.192083358764762],
        [83.88014221191423, 18.191528320312557],
        [83.87985992431646, 18.191530227661133],
        [83.87985992431646, 18.191251754760742],
        [83.87957763671892, 18.191251754760742],
        [83.87957763671892, 18.1909694671632],
        [83.87875366210966, 18.1909694671632],
        [83.87875366210966, 18.190694808960018],
        [83.87847137451178, 18.190694808960018],
        [83.87847137451178, 18.190416336059627],
        [83.87790679931646, 18.19042015075678],
        [83.87790679931646, 18.19014167785639],
        [83.87735748291038, 18.19014167785639],
        [83.87735748291038, 18.189859390258846],
        [83.8770828247072, 18.189859390258846],
        [83.8770828247072, 18.18958282470703],
        [83.87680816650396, 18.18958282470703],
        [83.87680816650396, 18.189306259155273],
        [83.87654113769531, 18.18930816650402],
        [83.87654113769531, 18.18902969360346],
        [83.87625122070341, 18.18902969360346],
        [83.87625122070341, 18.188190460205135],
        [83.87569427490263, 18.188194274902344],
        [83.87569427490263, 18.187915802001953],
        [83.8748626708985, 18.187919616699276],
        [83.8748626708985, 18.187641143798885],
        [83.87458038330072, 18.187641143798885],
        [83.87458038330072, 18.186250686645565],
        [83.87430572509788, 18.186250686645565],
        [83.87430572509788, 18.185972213745174],
        [83.8740310668947, 18.185972213745174],
        [83.8740310668947, 18.184860229492244],
        [83.87431335449241, 18.184860229492244],
        [83.87430572509788, 18.184305191040096],
        [83.8740310668947, 18.18431091308605],
        [83.8740310668947, 18.184030532836857],
        [83.87374877929693, 18.184030532836857],
        [83.87374877929693, 18.18375015258789],
        [83.87347412109392, 18.18375015258789],
        [83.87347412109392, 18.1834716796875],
        [83.87319183349638, 18.183481216430664],
        [83.87319183349638, 18.183200836181697],
        [83.87291717529297, 18.183200836181697],
        [83.87291717529297, 18.182920455932674],
        [83.87264251709013, 18.182920455932674],
        [83.87264251709013, 18.182640075683537],
        [83.87236022949219, 18.182640075683537],
        [83.87236022949219, 18.18235969543457],
        [83.87208557128918, 18.182361602783146],
        [83.87208557128918, 18.182083129882756],
        [83.87152862548822, 18.182083129882756],
        [83.87152862548822, 18.181804656982422],
        [83.87124633789068, 18.181804656982422],
        [83.87124633789068, 18.181528091430778],
        [83.87097167968767, 18.181529998779354],
        [83.87097167968767, 18.181249618530387],
        [83.87069702148443, 18.181249618530387],
        [83.87069702148443, 18.180692672729435],
        [83.87041473388689, 18.18069458007824],
        [83.87041473388689, 18.180416107177848],
        [83.87014007568388, 18.180416107177848],
        [83.87014007568388, 18.180139541626033],
        [83.86985778808611, 18.180139541626033],
        [83.86985778808611, 18.179861068725643],
        [83.8695831298831, 18.179861068725643],
        [83.8695831298831, 18.179582595825252],
        [83.86819458007818, 18.179582595825252],
        [83.86819458007818, 18.179306030273494],
        [83.86734771728533, 18.179309844970646],
        [83.86734771728533, 18.17902946472168],
        [83.86708068847662, 18.17902946472168],
        [83.86708068847662, 18.178752899169922],
        [83.86624908447266, 18.178752899169922],
        [83.86624908447266, 18.1784725189209],
        [83.86569213867193, 18.1784725189209],
        [83.86569213867193, 18.178194046020565],
        [83.86541748046886, 18.178194046020565],
        [83.86541748046886, 18.17791748046875],
        [83.86514282226568, 18.177919387817326],
        [83.86514282226568, 18.17764282226574],
        [83.86460113525396, 18.17764282226574],
        [83.86460113525396, 18.177349090576286],
        [83.86430358886736, 18.177358627319393],
        [83.86430358886736, 18.17708396911621],
        [83.86347198486357, 18.17708396911621],
        [83.86347198486357, 18.17680549621582],
        [83.86319732666016, 18.176809310913143],
        [83.86319732666016, 18.17624855041504],
        [83.86291503906278, 18.17624855041504],
        [83.86291503906278, 18.17597007751465],
        [83.86264038085943, 18.17597007751465],
        [83.86264038085943, 18.175695419311637],
        [83.86125183105486, 18.175689697265682],
        [83.86125183105486, 18.175418853759822],
        [83.86068725585955, 18.1754207611084],
        [83.86068725585955, 18.175140380859432],
        [83.86042022705107, 18.175140380859432],
        [83.86042022705107, 18.17485809326172],
        [83.85986328125028, 18.17486190795904],
        [83.85986328125028, 18.174583435058707],
        [83.85874938964838, 18.174583435058707],
        [83.85874938964838, 18.174304962158317],
        [83.85817718505854, 18.174310684204045],
        [83.85817718505854, 18.174030303955078],
        [83.85791778564459, 18.174030303955078],
        [83.85791778564459, 18.17374992370611],
        [83.85763549804705, 18.17374992370611],
        [83.85763549804705, 18.17347145080572],
        [83.85708618164062, 18.17347145080572],
        [83.85708618164062, 18.173194885253963],
        [83.85652923583984, 18.173194885253963],
        [83.85652923583984, 18.172916412353572],
        [83.85597229003912, 18.172920227050724],
        [83.85597229003912, 18.172639846801758],
        [83.85430908203153, 18.172639846801758],
        [83.85430908203153, 18.17235946655279],
        [83.85346984863281, 18.17235946655279],
        [83.85346984863281, 18.172639846801758],
        [83.85320281982433, 18.172639846801758],
        [83.85320281982433, 18.17235946655279],
        [83.85097503662126, 18.172361373901367],
        [83.85097503662126, 18.17180633544922],
        [83.85124969482428, 18.17181015014654],
        [83.85124969482428, 18.171541213989315],
        [83.85041809082031, 18.171541213989315],
        [83.85041809082031, 18.171249389648438],
        [83.84958648681652, 18.171249389648438],
        [83.84958648681652, 18.170972824096623],
        [83.8487472534182, 18.170972824096623],
        [83.8487472534182, 18.17069435119629],
        [83.84819793701178, 18.17069435119629],
        [83.84819793701178, 18.1704158782959],
        [83.84735870361328, 18.17041969299322],
        [83.84735870361328, 18.17014122009283],
        [83.84652709960949, 18.17014122009283],
        [83.84652709960949, 18.169860839843693],
        [83.8456954956057, 18.169860839843693],
        [83.8456954956057, 18.169584274292106],
        [83.84486389160173, 18.169584274292106],
        [83.84486389160173, 18.169305801391715],
        [83.84401702880888, 18.169309616088867],
        [83.84401702880888, 18.169031143188477],
        [83.84317779541016, 18.169031143188477],
        [83.84317779541016, 18.16875076293951],
        [83.84236145019548, 18.16875076293951],
        [83.84236145019548, 18.168470382690543],
        [83.8418045043947, 18.16847229003912],
        [83.8418045043947, 18.168193817138786],
        [83.84097290039091, 18.168193817138786],
        [83.84097290039091, 18.16791725158697],
        [83.84014129638678, 18.167921066284123],
        [83.84014129638678, 18.167640686035156],
        [83.83930206298822, 18.167640686035156],
        [83.83930206298822, 18.16736030578619],
        [83.83790588378935, 18.16736030578619],
        [83.83790588378935, 18.167640686035156],
        [83.83763885498047, 18.167640686035156],
        [83.83763885498047, 18.16736030578619],
        [83.83736419677763, 18.16736030578619],
        [83.83736419677763, 18.167083740234432],
        [83.83680725097668, 18.167083740234432],
        [83.83680725097668, 18.16680526733404],
        [83.83625030517572, 18.166812896728572],
        [83.83625030517572, 18.166530609130803],
        [83.83569335937517, 18.166530609130803],
        [83.83569335937517, 18.166250228881836],
        [83.83541870117193, 18.166250228881836],
        [83.83541870117193, 18.165971755981445],
        [83.83486175537138, 18.165973663330078],
        [83.83486175537138, 18.165695190429688],
        [83.83403015136724, 18.16570281982422],
        [83.83403015136724, 18.165409088134766],
        [83.8331909179687, 18.165416717529297],
        [83.8331909179687, 18.165138244628906],
        [83.83180236816435, 18.165140151977482],
        [83.83180236816435, 18.16486167907709],
        [83.82958221435564, 18.16486167907709],
        [83.82958221435564, 18.1645832061767],
        [83.8293075561524, 18.1645832061767],
        [83.8293075561524, 18.164028167724723],
        [83.8284912109375, 18.1640300750733],
        [83.8284912109375, 18.16375160217291],
        [83.82791900634766, 18.16375160217291],
        [83.82791900634766, 18.163469314575195],
        [83.82707977294933, 18.163473129272575],
        [83.82707977294933, 18.163194656372184],
        [83.82653045654314, 18.163194656372184],
        [83.82653045654314, 18.162916183471793],
        [83.82569122314459, 18.162921905517578],
        [83.82569122314459, 18.162637710571403],
        [83.82514190673857, 18.162637710571403],
        [83.82514190673857, 18.162370681762752],
        [83.82457733154325, 18.162370681762752],
        [83.82457733154325, 18.16208076477062],
        [83.82375335693365, 18.162082672119197],
        [83.82375335693365, 18.16180610656744],
        [83.82319641113304, 18.16180992126459],
        [83.82319641113304, 18.161529541015625],
        [83.82235717773455, 18.161529541015625],
        [83.82235717773455, 18.161251068115234],
        [83.82180786132812, 18.161251068115234],
        [83.82180786132812, 18.160970687866268],
        [83.8212509155274, 18.160972595214844],
        [83.8212509155274, 18.16069412231451],
        [83.82041931152338, 18.16069412231451],
        [83.82041931152338, 18.16041946411127],
        [83.81986236572283, 18.16041946411127],
        [83.81986236572283, 18.16014099121088],
        [83.81903076171903, 18.16014099121088],
        [83.81903076171903, 18.159860610961914],
        [83.81846618652372, 18.159860610961914],
        [83.81846618652372, 18.159580230712947],
        [83.81764221191412, 18.159584045410156],
        [83.81764221191412, 18.159305572509766],
        [83.81709289550804, 18.159309387207088],
        [83.81709289550804, 18.159030914306697],
        [83.81626129150408, 18.159030914306697],
        [83.81626129150408, 18.15875053405756],
        [83.8156967163086, 18.15875053405756],
        [83.8156967163086, 18.158470153808594],
        [83.81485748291044, 18.15847206115717],
        [83.81485748291044, 18.15819358825678],
        [83.81430816650385, 18.15819358825678],
        [83.81430816650385, 18.157915115356445],
        [83.8134689331057, 18.157920837402344],
        [83.8134689331057, 18.157640457153377],
        [83.81263732910173, 18.157640457153377],
        [83.81263732910173, 18.15736007690441],
        [83.81180572509794, 18.157361984252987],
        [83.81180572509794, 18.157083511352653],
        [83.81124877929699, 18.157083511352653],
        [83.81124877929699, 18.15680313110346],
        [83.8104171752932, 18.15681076049799],
        [83.8104171752932, 18.156530380249023],
        [83.80819702148449, 18.156530380249023],
        [83.80819702148449, 18.15624046325695],
        [83.80791473388678, 18.15625],
        [83.80791473388678, 18.155971527099666],
        [83.80764007568354, 18.155971527099666],
        [83.80764007568354, 18.155693054199276],
        [83.80735778808616, 18.155700683593864],
        [83.80735778808616, 18.15542030334467],
        [83.80680847167974, 18.15542030334467],
        [83.80680847167974, 18.155139923095703],
        [83.8065261840822, 18.155139923095703],
        [83.8065261840822, 18.154859542846737],
        [83.80625152587919, 18.154861450195312],
        [83.80625152587919, 18.154584884643555],
        [83.80541992187506, 18.154584884643555],
        [83.80541992187506, 18.154306411743164],
        [83.80486297607428, 18.154310226440487],
        [83.80486297607428, 18.15402984619135],
        [83.80403137207048, 18.15402984619135],
        [83.80403137207048, 18.153749465942383],
        [83.80319213867216, 18.153749465942383],
        [83.80319213867216, 18.153472900390568],
        [83.80263519287138, 18.153472900390568],
        [83.80263519287138, 18.153194427490234],
        [83.80180358886724, 18.153194427490234],
        [83.80180358886724, 18.152915954589844],
        [83.8012619018557, 18.152919769287166],
        [83.8012619018557, 18.1526393890382],
        [83.80041503906267, 18.1526393890382],
        [83.80041503906267, 18.152362823486442],
        [83.79957580566435, 18.152362823486442],
        [83.79957580566435, 18.152082443237248],
        [83.7990264892581, 18.152082443237248],
        [83.7990264892581, 18.15180587768566],
        [83.79820251464866, 18.151809692382812],
        [83.79820251464866, 18.151531219482422],
        [83.79736328125017, 18.151531219482422],
        [83.79736328125017, 18.151252746582088],
        [83.79680633544939, 18.151252746582088],
        [83.79680633544939, 18.150968551635742],
        [83.7959747314456, 18.150972366333065],
        [83.7959747314456, 18.15069389343273],
        [83.7940292358399, 18.15069389343273],
        [83.7940292358399, 18.150972366333065],
        [83.79347229003935, 18.150972366333065],
        [83.79347229003935, 18.150417327880916],
        [83.7929229736331, 18.150430679321403],
        [83.7929229736331, 18.14985847473156],
        [83.79264068603521, 18.14985847473156],
        [83.79264068603521, 18.149578094482422],
        [83.79235839843761, 18.149583816528377],
        [83.79235839843761, 18.149305343627987],
        [83.7918090820312, 18.14930915832514],
        [83.7918090820312, 18.149030685424748],
        [83.79152679443382, 18.149030685424748],
        [83.79152679443382, 18.148748397827205],
        [83.79096984863287, 18.148748397827205],
        [83.79096984863287, 18.148469924926815],
        [83.79069519042986, 18.14847183227539],
        [83.79069519042986, 18.148195266723633],
        [83.78985595703153, 18.148199081420955],
        [83.78985595703153, 18.147920608520565],
        [83.78819274902361, 18.147920608520565],
        [83.78819274902361, 18.147640228271428],
        [83.78736114501982, 18.147640228271428],
        [83.78736114501982, 18.147367477416992],
        [83.7845764160158, 18.147367477416992],
        [83.7845764160158, 18.147079467773494],
        [83.78430175781256, 18.147083282470646],
        [83.78430175781256, 18.146804809570312],
        [83.78375244140653, 18.146804809570312],
        [83.78375244140653, 18.146526336669922],
        [83.78318786621122, 18.146530151367244],
        [83.78318786621122, 18.146249771118278],
        [83.7826385498048, 18.146249771118278],
        [83.7826385498048, 18.14596939086914],
        [83.78208160400385, 18.145971298217717],
        [83.78208160400385, 18.14569473266613],
        [83.78180694580101, 18.14569473266613],
        [83.78180694580101, 18.14541625976574],
        [83.78125, 18.14542007446289],
        [83.78125, 18.145139694213924],
        [83.78097534179705, 18.145139694213924],
        [83.78097534179705, 18.144861221313533],
        [83.7804183959961, 18.144861221313533],
        [83.7804183959961, 18.144580841064567],
        [83.77986145019537, 18.144582748413143],
        [83.77986145019537, 18.14430427551281],
        [83.7795867919923, 18.14430427551281],
        [83.7795867919923, 18.144027709960994],
        [83.77902984619135, 18.14402961730957],
        [83.77902984619135, 18.14375114440918],
        [83.7784729003908, 18.14375114440918],
        [83.7784729003908, 18.143470764160213],
        [83.77791595459001, 18.14347267150879],
        [83.77791595459001, 18.143194198608455],
        [83.777641296387, 18.143194198608455],
        [83.777641296387, 18.14291763305664],
        [83.77709197998075, 18.142919540405217],
        [83.77709197998075, 18.142641067504826],
        [83.77652740478527, 18.142641067504826],
        [83.77652740478527, 18.142080307006893],
        [83.77513885498053, 18.1420841217041],
        [83.77513885498053, 18.14180564880371],
        [83.77430725097656, 18.141809463501033],
        [83.77430725097656, 18.141530990600643],
        [83.77403259277372, 18.141530990600643],
        [83.77403259277372, 18.1412410736084],
        [83.77375030517578, 18.141250610351506],
        [83.77375030517578, 18.140972137451115],
        [83.77347564697277, 18.140972137451115],
        [83.77347564697277, 18.140693664550724],
        [83.77319335937506, 18.140693664550724],
        [83.77319335937506, 18.140417098999137],
        [83.77291870117182, 18.14042091369629],
        [83.77291870117182, 18.140140533447322],
        [83.77236175537126, 18.140140533447322],
        [83.77236175537126, 18.139860153198356],
        [83.77153015136747, 18.139862060546932],
        [83.77153015136747, 18.139583587646598],
        [83.77041625976591, 18.139583587646598],
        [83.77041625976591, 18.139305114746207],
        [83.76957702636724, 18.13931274414074],
        [83.76957702636724, 18.13903045654297],
        [83.76875305175798, 18.13903045654297],
        [83.76875305175798, 18.138750076294002],
        [83.76763916015625, 18.138750076294002],
        [83.76763916015625, 18.13847160339361],
        [83.76680755615246, 18.13847160339361],
        [83.76680755615246, 18.137916564941463],
        [83.76653289794928, 18.137920379638615],
        [83.76653289794928, 18.137641906738224],
        [83.7662506103515, 18.137641906738224],
        [83.7662506103515, 18.137361526489258],
        [83.76569366455095, 18.137361526489258],
        [83.76569366455095, 18.137083053588867],
        [83.76513671875017, 18.137083053588867],
        [83.76513671875017, 18.13680648803711],
        [83.76457977294922, 18.136810302734432],
        [83.76457977294922, 18.13596916198736],
        [83.76430511474638, 18.135972976684513],
        [83.76430511474638, 18.13569450378418],
        [83.76264190673845, 18.13569450378418],
        [83.76264190673845, 18.135417938232536],
        [83.76180267334013, 18.135429382324276],
        [83.76180267334013, 18.1351375579834],
        [83.76069641113293, 18.1351375579834],
        [83.76069641113293, 18.134859085083008],
        [83.76013946533197, 18.134859085083008],
        [83.76013946533197, 18.134580612182617],
        [83.75958251953142, 18.134582519531193],
        [83.75958251953142, 18.134307861328182],
        [83.75901794433611, 18.134309768676758],
        [83.75901794433611, 18.13402938842779],
        [83.75875091552763, 18.13402938842779],
        [83.75875091552763, 18.1337509155274],
        [83.75846862792986, 18.1337509155274],
        [83.75846862792986, 18.133470535278434],
        [83.75791931152344, 18.13347244262701],
        [83.75791931152344, 18.133193969726676],
        [83.75708007812511, 18.133193969726676],
        [83.75708007812511, 18.132915496826286],
        [83.75653076171892, 18.132921218872013],
        [83.75653076171892, 18.132640838623047],
        [83.75595855712908, 18.132640838623047],
        [83.75595855712908, 18.13236045837408],
        [83.75541687011736, 18.13236045837408],
        [83.75541687011736, 18.132080078125114],
        [83.7548599243164, 18.132083892822322],
        [83.7548599243164, 18.131805419921932],
        [83.75430297851568, 18.131811141967887],
        [83.75430297851568, 18.131250381469727],
        [83.75347137451166, 18.131250381469727],
        [83.75347137451166, 18.13097000122076],
        [83.75263977050787, 18.130971908569336],
        [83.75263977050787, 18.130695343017578],
        [83.75208282470732, 18.130701065063533],
        [83.75208282470732, 18.13042068481451],
        [83.75152587890653, 18.13042068481451],
        [83.75152587890653, 18.130140304565373],
        [83.75125122070318, 18.130140304565373],
        [83.75125122070318, 18.129859924316406],
        [83.75041961669916, 18.129861831664982],
        [83.75041961669916, 18.12958335876459],
        [83.74986267089861, 18.12958335876459],
        [83.74986267089861, 18.129304885864258],
        [83.74958038330084, 18.129304885864258],
        [83.74958038330084, 18.129028320312614],
        [83.74903106689482, 18.12903022766119],
        [83.74903106689482, 18.128749847412223],
        [83.7484664916995, 18.128749847412223],
        [83.7484664916995, 18.128469467163086],
        [83.7481918334961, 18.128471374511662],
        [83.7481918334961, 18.128194808960075],
        [83.7476425170899, 18.128194808960075],
        [83.7476425170899, 18.127916336059684],
        [83.74707794189459, 18.127920150756836],
        [83.74707794189459, 18.12763977050787],
        [83.74678802490229, 18.12763977050787],
        [83.74678802490229, 18.127359390258903],
        [83.7462463378908, 18.12736129760748],
        [83.7462463378908, 18.127082824707088],
        [83.74569702148438, 18.127082824707088],
        [83.74569702148438, 18.12680625915533],
        [83.74540710449247, 18.126810073852482],
        [83.74540710449247, 18.126529693603516],
        [83.74485778808622, 18.126529693603516],
        [83.74485778808622, 18.12626075744629],
        [83.74430847167963, 18.12626075744629],
        [83.74430847167963, 18.125972747802734],
        [83.74402618408209, 18.125972747802734],
        [83.74402618408209, 18.1256942749024],
        [83.74346923828148, 18.1256942749024],
        [83.74346923828148, 18.12541580200201],
        [83.74263763427751, 18.125419616699162],
        [83.74263763427751, 18.12514114379877],
        [83.7423629760745, 18.12514114379877],
        [83.7423629760745, 18.12486267089838],
        [83.74153137207037, 18.12486267089838],
        [83.74153137207037, 18.124580383300838],
        [83.74069213867182, 18.124584197998047],
        [83.74069213867182, 18.124305725097656],
        [83.7401428222658, 18.12430953979498],
        [83.7401428222658, 18.124029159546012],
        [83.73958587646501, 18.124029159546012],
        [83.73958587646501, 18.12375068664545],
        [83.73902893066406, 18.12375068664545],
        [83.73902893066406, 18.12346839904791],
        [83.73847198486334, 18.12347221374506],
        [83.73847198486334, 18.12319374084467],
        [83.73792266845709, 18.123199462890625],
        [83.73792266845709, 18.12291908264166],
        [83.73735809326195, 18.12291908264166],
        [83.73735809326195, 18.122640609741268],
        [83.73680877685553, 18.122640609741268],
        [83.73680877685553, 18.122358322143555],
        [83.73625183105497, 18.122358322143555],
        [83.73625183105497, 18.122079849243164],
        [83.73569488525419, 18.122083663940543],
        [83.73569488525419, 18.121805191040153],
        [83.73513793945324, 18.12181091308588],
        [83.73513793945324, 18.121530532836914],
        [83.73458099365229, 18.121530532836914],
        [83.73458099365229, 18.12124824523937],
        [83.73401641845697, 18.12124824523937],
        [83.73401641845697, 18.12096977233898],
        [83.73347473144548, 18.120971679687557],
        [83.73347473144548, 18.1206951141358],
        [83.73291778564453, 18.120700836181754],
        [83.73291778564453, 18.12042045593256],
        [83.73236083984375, 18.12042045593256],
        [83.73236083984375, 18.120138168335018],
        [83.73207855224638, 18.120138168335018],
        [83.73207855224638, 18.119859695434627],
        [83.73152923583979, 18.119861602783203],
        [83.73152923583979, 18.119583129882812],
        [83.73097229003923, 18.119583129882812],
        [83.73097229003923, 18.11930465698248],
        [83.73041534423845, 18.11930465698248],
        [83.73041534423845, 18.119026184082088],
        [83.72985839843767, 18.11902999877924],
        [83.72985839843767, 18.118749618530273],
        [83.72930908203125, 18.118749618530273],
        [83.72930908203125, 18.118471145629883],
        [83.72875213623053, 18.11847305297846],
        [83.72875213623053, 18.118194580078125],
        [83.72819519042974, 18.118194580078125],
        [83.72819519042974, 18.1179141998291],
        [83.72846984863293, 18.117919921875057],
        [83.72846984863293, 18.11763954162609],
        [83.72819519042974, 18.11763954162609],
        [83.72819519042974, 18.11736106872553],
        [83.72763824462913, 18.11736106872553],
        [83.72763824462913, 18.117071151733455],
        [83.72624969482422, 18.11708259582514],
        [83.72624969482422, 18.116804122924748],
        [83.72598266601574, 18.116809844970703],
        [83.72598266601574, 18.116529464721737],
        [83.72541809082043, 18.116529464721737],
        [83.72541809082043, 18.116250991821346],
        [83.72513580322271, 18.116250991821346],
        [83.72513580322271, 18.11597061157238],
        [83.72458648681663, 18.115972518920955],
        [83.72458648681663, 18.115695953369197],
        [83.72402954101568, 18.115695953369197],
        [83.72402954101568, 18.115417480468807],
        [83.72374725341814, 18.115419387817383],
        [83.72374725341814, 18.115140914916992],
        [83.72319793701172, 18.115140914916992],
        [83.72319793701172, 18.114860534668026],
        [83.7223587036134, 18.114860534668026],
        [83.7223587036134, 18.11458015441906],
        [83.72125244140642, 18.114582061767635],
        [83.72125244140642, 18.114305496215877],
        [83.72097015380865, 18.11432075500494],
        [83.72097015380865, 18.113750457763672],
        [83.7193069458009, 18.113750457763672],
        [83.7193069458009, 18.113470077514705],
        [83.71875, 18.113473892211914],
        [83.71875, 18.112916946411133],
        [83.71848297119163, 18.112920761108455],
        [83.71848297119163, 18.112640380859318],
        [83.71791839599615, 18.112640380859318],
        [83.71791839599615, 18.112361907958928],
        [83.71763610839861, 18.11236381530773],
        [83.71763610839861, 18.112083435058537],
        [83.71708679199219, 18.112083435058537],
        [83.71708679199219, 18.111804962158203],
        [83.71678924560575, 18.1118106842041],
        [83.71678924560575, 18.111530303955135],
        [83.71624755859403, 18.111530303955135],
        [83.71624755859403, 18.111259460449332],
        [83.7154159545899, 18.111259460449332],
        [83.7154159545899, 18.11096954345703],
        [83.71430206298834, 18.11096954345703],
        [83.71430206298834, 18.11069488525402],
        [83.71347045898438, 18.11069488525402],
        [83.71347045898438, 18.11041641235363],
        [83.71319580078153, 18.11042022705078],
        [83.71319580078153, 18.11014175415039],
        [83.71263885498058, 18.11014175415039],
        [83.71263885498058, 18.109861373901424],
        [83.71208190917963, 18.109861373901424],
        [83.71208190917963, 18.109582901001033],
        [83.71152496337908, 18.109582901001033],
        [83.71152496337908, 18.109306335449276],
        [83.7109680175783, 18.109310150146428],
        [83.7109680175783, 18.10902976989746],
        [83.71069335937528, 18.10902976989746],
        [83.71069335937528, 18.108470916748104],
        [83.71041870117188, 18.10847282409668],
        [83.71041870117188, 18.108194351196346],
        [83.7101364135745, 18.10819816589367],
        [83.7101364135745, 18.107919692993107],
        [83.70958709716808, 18.107919692993107],
        [83.70958709716808, 18.107641220092717],
        [83.70903015136713, 18.107641220092717],
        [83.70903015136713, 18.10736083984375],
        [83.70874786376976, 18.10736083984375],
        [83.70874786376976, 18.107080459594783],
        [83.70847320556658, 18.107084274291992],
        [83.70847320556658, 18.106807708740348],
        [83.70764160156278, 18.106809616088924],
        [83.70764160156278, 18.106531143188533],
        [83.70735931396484, 18.106531143188533],
        [83.70735931396484, 18.106250762939396],
        [83.70707702636747, 18.106250762939396],
        [83.70707702636747, 18.10597038269043],
        [83.70680236816406, 18.105972290039006],
        [83.70680236816406, 18.105693817138615],
        [83.70541381835955, 18.105693817138615],
        [83.70541381835955, 18.10541534423828],
        [83.7051391601563, 18.10542106628418],
        [83.7051391601563, 18.105131149292106],
        [83.7048645019533, 18.105138778686637],
        [83.7048645019533, 18.104860305786246],
        [83.70430755615234, 18.104860305786246],
        [83.70430755615234, 18.10457992553711],
        [83.70402526855497, 18.10458374023449],
        [83.70402526855497, 18.104305267334098],
        [83.70375061035156, 18.104310989379826],
        [83.70375061035156, 18.10403060913086],
        [83.7031860351563, 18.10403060913086],
        [83.7031860351563, 18.103750228881893],
        [83.70207977294945, 18.103750228881893],
        [83.70207977294945, 18.103469848632926],
        [83.70153045654325, 18.103471755981502],
        [83.70153045654325, 18.10319328308111],
        [83.70124816894548, 18.1032009124757],
        [83.70124816894548, 18.102920532226506],
        [83.70069122314453, 18.102920532226506],
        [83.70069122314453, 18.10264015197754],
        [83.70014190673834, 18.10264015197754],
        [83.70014190673834, 18.102359771728572],
        [83.69958496093756, 18.10236167907715],
        [83.69958496093756, 18.102083206176758],
        [83.69902801513695, 18.102083206176758],
        [83.69902801513695, 18.101804733276424],
        [83.698471069336, 18.101804733276424],
        [83.698471069336, 18.10152816772461],
        [83.69819641113298, 18.101530075073185],
        [83.69819641113298, 18.10124969482422],
        [83.69790649414091, 18.10124969482422],
        [83.69790649414091, 18.100692749023438],
        [83.69736480712919, 18.10069465637207],
        [83.69736480712919, 18.10041618347168],
        [83.69653320312506, 18.100419998169002],
        [83.69653320312506, 18.100139617920036],
        [83.69567871093744, 18.100139617920036],
        [83.69567871093744, 18.099861145019474],
        [83.69513702392595, 18.099861145019474],
        [83.69513702392595, 18.099582672119084],
        [83.69486236572294, 18.099582672119084],
        [83.69486236572294, 18.099306106567496],
        [83.69431304931669, 18.099319458007812],
        [83.69431304931669, 18.099029541015682],
        [83.6940307617188, 18.099029541015682],
        [83.6940307617188, 18.09875106811529],
        [83.69374847412121, 18.09875106811529],
        [83.69374847412121, 18.0984725952149],
        [83.69347381591803, 18.0984725952149],
        [83.69347381591803, 18.098194122314567],
        [83.69291687011741, 18.098194122314567],
        [83.69291687011741, 18.097917556762752],
        [83.69235992431646, 18.097919464111328],
        [83.69235992431646, 18.09764289855957],
        [83.69180297851591, 18.09764289855957],
        [83.69180297851591, 18.097358703613395],
        [83.69124603271513, 18.097358703613395],
        [83.69124603271513, 18.097078323364258],
        [83.69097137451172, 18.097084045410213],
        [83.69097137451172, 18.096805572509822],
        [83.69040679931646, 18.096809387206974],
        [83.69040679931646, 18.096530914306584],
        [83.68987274169945, 18.096530914306584],
        [83.68987274169945, 18.09626007080078],
        [83.68930816650396, 18.09626007080078],
        [83.68930816650396, 18.095968246460075],
        [83.68902587890642, 18.095972061157227],
        [83.68902587890642, 18.095693588256836],
        [83.68875122070341, 18.095693588256836],
        [83.68875122070341, 18.095418930053825],
        [83.6881866455081, 18.0954208374024],
        [83.6881866455081, 18.095140457153263],
        [83.68791961669922, 18.095140457153263],
        [83.68791961669922, 18.09485816955572],
        [83.68763732910185, 18.094861984252873],
        [83.68763732910185, 18.094583511352482],
        [83.6873626708985, 18.094583511352482],
        [83.6873626708985, 18.09430503845215],
        [83.68596649169939, 18.094310760498047],
        [83.68596649169939, 18.09403038024908],
        [83.68541717529314, 18.09403038024908],
        [83.68541717529314, 18.093748092651367],
        [83.68514251709013, 18.093748092651367],
        [83.68514251709013, 18.093469619750977],
        [83.68486022949219, 18.093471527099553],
        [83.68486022949219, 18.09319686889654],
        [83.68430328369146, 18.09319686889654],
        [83.68430328369146, 18.092916488647575],
        [83.6840286254884, 18.092920303344727],
        [83.6840286254884, 18.09263992309576],
        [83.68347167968744, 18.09263992309576],
        [83.68347167968744, 18.092359542846793],
        [83.6831970214846, 18.09236145019537],
        [83.6831970214846, 18.09208297729498],
        [83.68264007568365, 18.09208297729498],
        [83.68264007568365, 18.091808319091797],
        [83.6820831298831, 18.091810226440373],
        [83.6820831298831, 18.091529846191406],
        [83.68180847167969, 18.091529846191406],
        [83.68180847167969, 18.09124946594244],
        [83.68125152587896, 18.09124946594244],
        [83.68125152587896, 18.09097099304205],
        [83.68069458007818, 18.090972900390625],
        [83.68069458007818, 18.09069442749029],
        [83.68041992187494, 18.09069442749029],
        [83.68041992187494, 18.090414047241268],
        [83.67986297607439, 18.090419769287053],
        [83.67986297607439, 18.09012985229498],
        [83.67958068847662, 18.090139389038086],
        [83.67958068847662, 18.089860916137695],
        [83.67846679687528, 18.089860916137695],
        [83.67846679687528, 18.08930969238287],
        [83.67819213867188, 18.08930969238287],
        [83.67819213867188, 18.08903121948248],
        [83.67764282226568, 18.08903121948248],
        [83.67764282226568, 18.08875083923334],
        [83.67708587646507, 18.08875083923334],
        [83.67708587646507, 18.088470458984375],
        [83.67680358886736, 18.08847236633295],
        [83.67680358886736, 18.08819389343256],
        [83.67624664306658, 18.08819389343256],
        [83.67624664306658, 18.087917327880973],
        [83.67597198486357, 18.087917327880973],
        [83.67597198486357, 18.08819389343256],
        [83.67541503906278, 18.08819389343256],
        [83.67541503906278, 18.087917327880973],
        [83.67514038085938, 18.087921142578125],
        [83.67514038085938, 18.087360382080192],
        [83.674858093262, 18.087360382080192],
        [83.674858093262, 18.087083816528434],
        [83.67457580566412, 18.087089538574162],
        [83.67457580566412, 18.08681106567377],
        [83.67318725585955, 18.08681106567377],
        [83.67318725585955, 18.086530685424805],
        [83.67292022705107, 18.086530685424805],
        [83.67292022705107, 18.086250305175838],
        [83.6726379394533, 18.086250305175838],
        [83.6726379394533, 18.085971832275447],
        [83.67208099365234, 18.08597373962408],
        [83.67208099365234, 18.08569526672369],
        [83.67179870605497, 18.08570289611822],
        [83.67179870605497, 18.08542060852045],
        [83.67124938964855, 18.08542060852045],
        [83.67124938964855, 18.085140228271484],
        [83.67096710205084, 18.085140228271484],
        [83.67096710205084, 18.08457946777355],
        [83.6706924438476, 18.084583282470703],
        [83.6706924438476, 18.08430480957037],
        [83.67013549804705, 18.08430480957037],
        [83.67013549804705, 18.084028244018555],
        [83.6695861816408, 18.08403015136713],
        [83.6695861816408, 18.08375167846674],
        [83.66929626464872, 18.08375167846674],
        [83.66929626464872, 18.083469390869197],
        [83.66902923583984, 18.083469390869197],
        [83.66902923583984, 18.083194732666016],
        [83.66874694824247, 18.083194732666016],
        [83.66874694824247, 18.082916259765625],
        [83.66847229003912, 18.082920074462947],
        [83.66847229003912, 18.08263969421398],
        [83.66819763183605, 18.08263969421398],
        [83.66819763183605, 18.08236122131342],
        [83.66791534423834, 18.08236122131342],
        [83.66791534423834, 18.08208274841303],
        [83.6676406860351, 18.08208274841303],
        [83.6676406860351, 18.081806182861442],
        [83.66735839843773, 18.081808090210018],
        [83.66735839843773, 18.081529617309627],
        [83.6668090820313, 18.081529617309627],
        [83.6668090820313, 18.081251144409237],
        [83.66652679443376, 18.081251144409237],
        [83.66652679443376, 18.080961227416992],
        [83.66625213623075, 18.080972671508846],
        [83.66625213623075, 18.080694198608512],
        [83.66596984863281, 18.080694198608512],
        [83.66596984863281, 18.08013725280773],
        [83.6657028198245, 18.080141067504883],
        [83.6657028198245, 18.07958030700695],
        [83.66541290283203, 18.07958412170416],
        [83.66541290283203, 18.079305648803768],
        [83.6648559570313, 18.07930946350092],
        [83.6648559570313, 18.07903099060053],
        [83.66458129882807, 18.07903099060053],
        [83.66458129882807, 18.078750610351562],
        [83.66401672363276, 18.078750610351562],
        [83.66401672363276, 18.078470230102596],
        [83.66374969482428, 18.078472137451172],
        [83.66374969482428, 18.07819366455078],
        [83.66319274902372, 18.07819366455078],
        [83.66319274902372, 18.077915191650447],
        [83.66293334960938, 18.077920913696346],
        [83.66293334960938, 18.07764053344721],
        [83.66236114501953, 18.07764053344721],
        [83.66236114501953, 18.077360153198242],
        [83.66208648681652, 18.077362060546818],
        [83.66208648681652, 18.077083587646428],
        [83.6618041992188, 18.077083587646428],
        [83.6618041992188, 18.076805114746094],
        [83.66152954101557, 18.076810836791992],
        [83.66152954101557, 18.076530456543026],
        [83.66097259521501, 18.076530456543026],
        [83.66097259521501, 18.07625007629406],
        [83.66069793701178, 18.07625007629406],
        [83.66069793701178, 18.075969696044922],
        [83.66014099121122, 18.075971603393498],
        [83.66014099121122, 18.075693130493107],
        [83.65956878662138, 18.07570075988764],
        [83.65958404541044, 18.07550048828125],
        [83.65958404541044, 18.07541656494152],
        [83.6587524414063, 18.075420379638672],
        [83.6587524414063, 18.07514953613287],
        [83.65820312500023, 18.07514953613287],
        [83.65820312500023, 18.07485961914074],
        [83.65791320800776, 18.074861526489315],
        [83.65791320800776, 18.074583053588924],
        [83.65763854980491, 18.074583053588924],
        [83.65763854980491, 18.074306488037166],
        [83.6573562622072, 18.074310302734318],
        [83.6573562622072, 18.07402992248535],
        [83.65650939941412, 18.07402992248535],
        [83.65650939941412, 18.072919845580998],
        [83.65679931640642, 18.072919845580998],
        [83.65679931640642, 18.072362899780217],
        [83.65650939941412, 18.072362899780217],
        [83.65650939941412, 18.071529388427848],
        [83.65569305419928, 18.071529388427848],
        [83.65569305419928, 18.07125282287609],
        [83.6551361083985, 18.07125282287609],
        [83.6551361083985, 18.070972442626896],
        [83.65458679199241, 18.070972442626896],
        [83.65458679199241, 18.070693969726506],
        [83.6543045043947, 18.070693969726506],
        [83.6543045043947, 18.070140838623104],
        [83.65402984619146, 18.070140838623104],
        [83.65402984619146, 18.06985855102539],
        [83.65347290039091, 18.06985855102539],
        [83.65347290039091, 18.069578170776424],
        [83.65264129638678, 18.06958389282238],
        [83.65264129638678, 18.06930541992199],
        [83.65235900878918, 18.06930923461914],
        [83.65235900878918, 18.069021224975643],
        [83.65180206298822, 18.069028854370174],
        [83.65180206298822, 18.068750381469783],
        [83.65152740478538, 18.068750381469783],
        [83.65152740478538, 18.06846809387207],
        [83.65097045898443, 18.068471908569393],
        [83.65097045898443, 18.068195343017635],
        [83.6498565673831, 18.068199157714787],
        [83.6498565673831, 18.067920684814396],
        [83.64958190917969, 18.067920684814396],
        [83.64958190917969, 18.06764030456543],
        [83.64819335937517, 18.06764030456543],
        [83.64819335937517, 18.067359924316463],
        [83.64791870117193, 18.06736183166504],
        [83.64791870117193, 18.066804885864315],
        [83.64736175537138, 18.066804885864315],
        [83.64736175537138, 18.066526412963924],
        [83.64708709716797, 18.066530227661076],
        [83.64708709716797, 18.06624984741211],
        [83.64679718017607, 18.06624984741211],
        [83.64679718017607, 18.065959930420036],
        [83.64653015136719, 18.06597137451172],
        [83.64653015136719, 18.06569480895996],
        [83.64624786376982, 18.06569480895996],
        [83.64624786376982, 18.065139770507926],
        [83.64597320556646, 18.065139770507926],
        [83.64597320556646, 18.06485939025879],
        [83.64569091796886, 18.064861297607365],
        [83.64569091796886, 18.064582824706974],
        [83.64514160156267, 18.064582824706974],
        [83.64514160156267, 18.064304351806584],
        [83.64485931396507, 18.06431007385254],
        [83.64485931396507, 18.064029693603572],
        [83.64456939697283, 18.064029693603572],
        [83.64456939697283, 18.063190460205078],
        [83.64430236816412, 18.063194274902457],
        [83.64430236816412, 18.062915802002067],
        [83.64402770996111, 18.06291961669922],
        [83.64402770996111, 18.062080383300895],
        [83.6437530517581, 18.062084197998104],
        [83.6437530517581, 18.061805725097713],
        [83.64235687255865, 18.061809539794865],
        [83.64235687255865, 18.061531066894474],
        [83.64153289794939, 18.061531066894474],
        [83.64153289794939, 18.061250686645508],
        [83.64097595214861, 18.061250686645508],
        [83.64097595214861, 18.06097030639654],
        [83.64041900634766, 18.060972213745117],
        [83.64041900634766, 18.060693740844727],
        [83.63986206054693, 18.060693740844727],
        [83.63986206054693, 18.06041717529297],
        [83.63903045654291, 18.060422897338924],
        [83.63903045654291, 18.06015014648449],
        [83.63846588134783, 18.06015014648449],
        [83.63846588134783, 18.059860229492188],
        [83.63791656494158, 18.059860229492188],
        [83.63791656494158, 18.059581756591797],
        [83.63764190673857, 18.059583663940373],
        [83.63764190673857, 18.05930519104004],
        [83.63735961914062, 18.05931282043457],
        [83.63735961914062, 18.05903053283697],
        [83.63680267333984, 18.05903053283697],
        [83.63680267333984, 18.058750152588004],
        [83.63652801513683, 18.058750152588004],
        [83.63652801513683, 18.058471679687443],
        [83.63597106933588, 18.058471679687443],
        [83.63597106933588, 18.058195114135856],
        [83.63513946533209, 18.058202743530387],
        [83.63513946533209, 18.057920455932617],
        [83.63485717773455, 18.057920455932617],
        [83.63485717773455, 18.05764007568365],
        [83.63458251953153, 18.05764007568365],
        [83.63458251953153, 18.057079315185547],
        [83.63430786132812, 18.05708312988287],
        [83.63430786132812, 18.056804656982536],
        [83.63402557373075, 18.056804656982536],
        [83.63402557373075, 18.056251525878906],
        [83.63346862792997, 18.056251525878906],
        [83.63346862792997, 18.055969238281364],
        [83.63291931152338, 18.055973052978516],
        [83.63291931152338, 18.055694580078182],
        [83.63263702392584, 18.055694580078182],
        [83.63263702392584, 18.05541610717779],
        [83.63236236572283, 18.055419921874943],
        [83.63236236572283, 18.05485916137701],
        [83.63208007812523, 18.05485916137701],
        [83.63208007812523, 18.05458068847662],
        [83.63180541992205, 18.054582595825195],
        [83.63180541992205, 18.054307937622184],
        [83.63124847412126, 18.05430984497076],
        [83.63124847412126, 18.054019927978516],
        [83.63069152832031, 18.054029464721793],
        [83.63069152832031, 18.05374717712408],
        [83.63041687011747, 18.053750991821232],
        [83.63041687011747, 18.053470611572266],
        [83.62985992431652, 18.05347251892084],
        [83.62985992431652, 18.05319404602045],
        [83.62958526611334, 18.05319404602045],
        [83.62958526611334, 18.052915573120117],
        [83.62874603271501, 18.05291938781744],
        [83.62874603271501, 18.05264091491705],
        [83.62847137451178, 18.05264091491705],
        [83.62847137451178, 18.052360534668082],
        [83.62625122070307, 18.052360534668082],
        [83.62625122070307, 18.052080154418945],
        [83.6259689331057, 18.052083969116325],
        [83.6259689331057, 18.05125045776373],
        [83.62568664550798, 18.05125045776373],
        [83.62568664550798, 18.05097961425787],
        [83.62513732910173, 18.05097961425787],
        [83.62513732910173, 18.050701141357536],
        [83.62458038330078, 18.050689697265625],
        [83.62458038330078, 18.05041694641119],
        [83.62431335449247, 18.05042076110834],
        [83.62431335449247, 18.04986000061041],
        [83.6234741210938, 18.049861907958984],
        [83.6234741210938, 18.049583435058594],
        [83.6229171752932, 18.049583435058594],
        [83.6229171752932, 18.049303054809627],
        [83.62264251709001, 18.04931068420416],
        [83.62264251709001, 18.04903030395502],
        [83.62209320068376, 18.04903030395502],
        [83.62209320068376, 18.048749923706055],
        [83.62180328369169, 18.048749923706055],
        [83.62180328369169, 18.048469543457088],
        [83.62124633789091, 18.048471450805664],
        [83.62124633789091, 18.048194885253906],
        [83.6209716796875, 18.048194885253906],
        [83.6209716796875, 18.047916412353516],
        [83.62039947509766, 18.047920227050838],
        [83.62039947509766, 18.04763984680187],
        [83.62014007568354, 18.04763984680187],
        [83.62014007568354, 18.047359466552734],
        [83.61958312988298, 18.04736137390131],
        [83.61958312988298, 18.04708290100092],
        [83.61930847167974, 18.04708290100092],
        [83.61930847167974, 18.046806335449332],
        [83.61846923828142, 18.046810150146484],
        [83.61846923828142, 18.046529769897518],
        [83.61818695068388, 18.046529769897518],
        [83.61818695068388, 18.04624938964855],
        [83.617919921875, 18.04624938964855],
        [83.617919921875, 18.045972824096737],
        [83.61736297607428, 18.045972824096737],
        [83.61736297607428, 18.045694351196403],
        [83.61708068847668, 18.045694351196403],
        [83.61708068847668, 18.045415878296012],
        [83.61653137207048, 18.045419692993164],
        [83.61653137207048, 18.045141220092773],
        [83.61596679687517, 18.045141220092773],
        [83.61596679687517, 18.044849395751896],
        [83.61569213867193, 18.044862747192383],
        [83.61569213867193, 18.04458427429205],
        [83.61486053466797, 18.04458427429205],
        [83.61486053466797, 18.04430580139166],
        [83.61429595947266, 18.04430961608881],
        [83.61429595947266, 18.04403114318842],
        [83.61402893066418, 18.04403114318842],
        [83.61402893066418, 18.043750762939453],
        [83.61347198486322, 18.043750762939453],
        [83.61347198486322, 18.04346847534191],
        [83.61264038085943, 18.043472290039062],
        [83.61264038085943, 18.043193817138672],
        [83.61208343505888, 18.043193817138672],
        [83.61208343505888, 18.042917251586914],
        [83.61180877685547, 18.04291915893566],
        [83.61180877685547, 18.0426406860351],
        [83.61068725585943, 18.0426406860351],
        [83.61068725585943, 18.042358398437557],
        [83.61042022705072, 18.042358398437557],
        [83.61042022705072, 18.042079925537166],
        [83.61013793945335, 18.042083740234318],
        [83.61013793945335, 18.041805267333984],
        [83.60986328125017, 18.041809082031307],
        [83.60986328125017, 18.041530609130916],
        [83.6095809936524, 18.041530609130916],
        [83.6095809936524, 18.041248321533203],
        [83.60901641845709, 18.041248321533203],
        [83.60901641845709, 18.040969848632812],
        [83.6084747314456, 18.04097175598139],
        [83.6084747314456, 18.0406951904298],
        [83.60819244384766, 18.04070091247553],
        [83.60819244384766, 18.040420532226562],
        [83.60791778564464, 18.040420532226562],
        [83.60791778564464, 18.039579391479492],
        [83.60763549804693, 18.039583206176815],
        [83.60763549804693, 18.03902626037609],
        [83.60708618164085, 18.039039611816406],
        [83.60708618164085, 18.038749694824276],
        [83.60597229003935, 18.038749694824276],
        [83.60597229003935, 18.038471221923885],
        [83.60541534423857, 18.03847312927246],
        [83.60541534423857, 18.038194656372127],
        [83.60514068603516, 18.038194656372127],
        [83.60514068603516, 18.037914276123104],
        [83.60485839843778, 18.03791999816889],
        [83.60485839843778, 18.037639617919922],
        [83.6043090820312, 18.037639617919922],
        [83.6043090820312, 18.03736114501953],
        [83.60402679443365, 18.03736114501953],
        [83.60402679443365, 18.037080764770565],
        [83.60346984863304, 18.03708267211914],
        [83.60346984863304, 18.03680419921875],
        [83.60320281982439, 18.036809921264705],
        [83.60320281982439, 18.036251068115178],
        [83.60291290283209, 18.036251068115178],
        [83.60291290283209, 18.035980224609318],
        [83.60264587402344, 18.035980224609318],
        [83.60264587402344, 18.035690307617244],
        [83.60236358642607, 18.035694122314396],
        [83.60236358642607, 18.03513908386242],
        [83.60208129882812, 18.035140991210994],
        [83.60208129882812, 18.034860610962028],
        [83.60151672363281, 18.034860610962028],
        [83.60151672363281, 18.03458023071289],
        [83.60097503662115, 18.034582138061467],
        [83.60097503662115, 18.03430557250988],
        [83.60069274902338, 18.03430938720703],
        [83.60069274902338, 18.03403091430664],
        [83.60013580322283, 18.03403091430664],
        [83.60013580322283, 18.033750534057674],
        [83.59986114501959, 18.033750534057674],
        [83.59986114501959, 18.033470153808707],
        [83.59958648681658, 18.033472061157283],
        [83.59958648681658, 18.033193588256893],
        [83.59930419921903, 18.033193588256893],
        [83.59930419921903, 18.032917022705135],
        [83.59902954101562, 18.032917022705135],
        [83.59902954101562, 18.032638549804744],
        [83.59874725341825, 18.03264045715332],
        [83.59874725341825, 18.032360076904354],
        [83.59819793701183, 18.032363891601562],
        [83.59819793701183, 18.03208351135254],
        [83.59764099121088, 18.03208351135254],
        [83.59764099121088, 18.031805038452205],
        [83.59735870361351, 18.031812667846737],
        [83.59735870361351, 18.031530380248967],
        [83.5970764160158, 18.031530380248967],
        [83.5970764160158, 18.031251907348576],
        [83.59680175781256, 18.031251907348576],
        [83.59680175781256, 18.03097152709961],
        [83.59652709960955, 18.03097152709961],
        [83.59652709960955, 18.03069496154785],
        [83.59625244140653, 18.030702590942383],
        [83.59625244140653, 18.030420303344783],
        [83.5959701538086, 18.030420303344783],
        [83.5959701538086, 18.030141830444393],
        [83.59570312500028, 18.030141830444393],
        [83.59570312500028, 18.02984809875494],
        [83.59541320800781, 18.029861450195256],
        [83.59541320800781, 18.029582977294865],
        [83.5951385498048, 18.029582977294865],
        [83.5951385498048, 18.029306411743278],
        [83.59458160400385, 18.02931022644043],
        [83.59458160400385, 18.029029846191463],
        [83.59430694580101, 18.029029846191463],
        [83.59430694580101, 18.02874755859375],
        [83.59375, 18.02874755859375],
        [83.59375, 18.02846908569336],
        [83.59347534179705, 18.028472900390682],
        [83.59347534179705, 18.028194427490348],
        [83.5931930541995, 18.028194427490348],
        [83.5931930541995, 18.027917861938533],
        [83.5929183959961, 18.02791976928711],
        [83.5929183959961, 18.027637481689567],
        [83.59236145019531, 18.027637481689567],
        [83.59236145019531, 18.027360916137752],
        [83.5920867919923, 18.027360916137752],
        [83.5920867919923, 18.027080535888786],
        [83.59180450439459, 18.02708244323736],
        [83.59180450439459, 18.02680778503418],
        [83.59152984619135, 18.026809692382756],
        [83.59152984619135, 18.026531219482365],
        [83.5912475585938, 18.026531219482365],
        [83.5912475585938, 18.0262508392334],
        [83.59069824218756, 18.0262508392334],
        [83.5906906127932, 18.025972366333008],
        [83.59041595459001, 18.025972366333008],
        [83.59041595459001, 18.025693893432617],
        [83.590141296387, 18.025693893432617],
        [83.590141296387, 18.025415420532283],
        [83.58985900878923, 18.025421142578182],
        [83.58985900878923, 18.025140762329045],
        [83.58957672119169, 18.025140762329045],
        [83.58957672119169, 18.024860382080078],
        [83.58847045898449, 18.024860382080078],
        [83.58847045898449, 18.02458000183111],
        [83.5881958007813, 18.024583816528263],
        [83.5881958007813, 18.02430534362793],
        [83.587905883789, 18.024311065673828],
        [83.587905883789, 18.024040222168026],
        [83.5876388549807, 18.024040222168026],
        [83.5876388549807, 18.023750305175895],
        [83.58708190917974, 18.023750305175895],
        [83.58708190917974, 18.023469924926758],
        [83.58680725097673, 18.023471832275334],
        [83.58680725097673, 18.023193359374943],
        [83.58651733398466, 18.023200988769474],
        [83.58651733398466, 18.022920608520508],
        [83.5859832763673, 18.022920608520508],
        [83.5859832763673, 18.02264022827154],
        [83.58569335937506, 18.02264022827154],
        [83.58569335937506, 18.022079467773438],
        [83.58541870117199, 18.02208328247076],
        [83.58541870117199, 18.021804809570426],
        [83.58513641357428, 18.021804809570426],
        [83.58513641357428, 18.02152824401861],
        [83.58486175537104, 18.021530151367188],
        [83.58486175537104, 18.02124977111822],
        [83.58403015136724, 18.02124977111822],
        [83.58403015136724, 18.021530151367188],
        [83.58319091796875, 18.021530151367188],
        [83.58319091796875, 18.02124977111822],
        [83.58291625976591, 18.02124977111822],
        [83.58291625976591, 18.020969390869254],
        [83.58264160156256, 18.02097129821783],
        [83.58264160156256, 18.020694732666072],
        [83.58235931396496, 18.020694732666072],
        [83.58235931396496, 18.020139694213867],
        [83.58207702636724, 18.020139694213867],
        [83.58207702636724, 18.019861221313477],
        [83.581802368164, 18.019861221313477],
        [83.581802368164, 18.019582748413086],
        [83.58152770996116, 18.019582748413086],
        [83.58152770996116, 18.019306182861328],
        [83.58125305175798, 18.019306182861328],
        [83.58125305175798, 18.019582748413086],
        [83.58041381835966, 18.019582748413086],
        [83.58041381835966, 18.019306182861328],
        [83.58013916015625, 18.01930999755865],
        [83.58013916015625, 18.019029617309684],
        [83.57985687255888, 18.019029617309684],
        [83.57985687255888, 18.018472671508732],
        [83.57958221435547, 18.018472671508732],
        [83.57958221435547, 18.01819419860834],
        [83.5787506103515, 18.01819419860834],
        [83.5787506103515, 18.017917633056754],
        [83.57791900634771, 18.017915725708008],
        [83.57791900634771, 18.017639160156364],
        [83.57763671875017, 18.01764106750494],
        [83.57763671875017, 18.017360687255973],
        [83.57736206054716, 18.017360687255973],
        [83.57736206054716, 18.01707839965826],
        [83.57680511474638, 18.017084121704215],
        [83.57680511474638, 18.016805648803825],
        [83.57569122314464, 18.016809463500977],
        [83.57569122314464, 18.01652908325201],
        [83.57514190673845, 18.01652908325201],
        [83.57514190673845, 18.01625061035162],
        [83.5743026733399, 18.01625061035162],
        [83.5743026733399, 18.017084121704215],
        [83.57402801513689, 18.017084121704215],
        [83.57402801513689, 18.017915725708008],
        [83.57347106933594, 18.017917633056754],
        [83.57347106933594, 18.01819419860834],
        [83.57263946533214, 18.01819419860834],
        [83.57263946533214, 18.017639160156364],
        [83.5723495483399, 18.01764106750494],
        [83.5723495483399, 18.01707839965826],
        [83.5720825195312, 18.017084121704215],
        [83.5720825195312, 18.01625061035162],
        [83.57290649414062, 18.01625061035162],
        [83.57290649414062, 18.015689849853516],
        [83.5731964111331, 18.015693664550838],
        [83.5731964111331, 18.01541709899908],
        [83.57290649414062, 18.015420913696232],
        [83.57290649414062, 18.014850616455192],
        [83.57263946533214, 18.014862060546875],
        [83.57263946533214, 18.014583587646484],
        [83.57152557373064, 18.014583587646484],
        [83.57152557373064, 18.01430511474615],
        [83.57041931152344, 18.01431083679205],
        [83.57041931152344, 18.01374816894537],
        [83.57013702392607, 18.01374816894537],
        [83.57013702392607, 18.013200759887695],
        [83.56986236572271, 18.013200759887695],
        [83.56986236572271, 18.01292037963873],
        [83.56931304931646, 18.01292037963873],
        [83.56931304931646, 18.012638092041016],
        [83.56846618652361, 18.012638092041016],
        [83.56846618652361, 18.012359619140625],
        [83.56819152832037, 18.0123615264892],
        [83.56819152832037, 18.0118083953858],
        [83.5673599243164, 18.011810302734375],
        [83.5673599243164, 18.01152992248541],
        [83.5670928955081, 18.01152992248541],
        [83.5670928955081, 18.011249542236442],
        [83.56485748291033, 18.011249542236442],
        [83.56485748291033, 18.01097106933605],
        [83.56458282470732, 18.010972976684627],
        [83.56458282470732, 18.010139465332088],
        [83.56485748291033, 18.010139465332088],
        [83.56485748291033, 18.009860992431697],
        [83.56569671630882, 18.009860992431697],
        [83.56569671630882, 18.008750915527344],
        [83.56597137451166, 18.008750915527344],
        [83.56597137451166, 18.008470535278377],
        [83.56625366210943, 18.008472442626953],
        [83.56625366210943, 18.007638931274414],
        [83.56597137451166, 18.00764083862299],
        [83.56597137451166, 18.007360458374023],
        [83.56569671630882, 18.007360458374023],
        [83.56569671630882, 18.006811141967773],
        [83.56540679931635, 18.006811141967773],
        [83.56540679931635, 18.006530761718807],
        [83.56513977050787, 18.006530761718807],
        [83.56513977050787, 18.00625038146984],
        [83.56485748291033, 18.00625038146984],
        [83.56485748291033, 18.00570106506342],
        [83.56458282470732, 18.00570106506342],
        [83.56458282470732, 18.005420684814453],
        [83.5643081665039, 18.005420684814453],
        [83.5643081665039, 18.005140304565487],
        [83.56458282470732, 18.005140304565487],
        [83.56458282470732, 18.004861831665096],
        [83.5643081665039, 18.004861831665096],
        [83.5643081665039, 18.004510879516715],
        [83.5643081665039, 18.00430488586437],
        [83.56402587890653, 18.00430488586437],
        [83.56402587890653, 18.004028320312557],
        [83.56375122070312, 18.004030227661133],
        [83.56375122070312, 18.003189086914062],
        [83.56346893310575, 18.003194808960018],
        [83.56346893310575, 18.002916336059627],
        [83.56291961669916, 18.002916336059627],
        [83.56291961669916, 18.00264167785639],
        [83.56124877929705, 18.00264167785639],
        [83.56124877929705, 18.00235748291027],
        [83.56097412109403, 18.002361297607422],
        [83.56097412109403, 18.001806259155273],
        [83.56040954589872, 18.00180816650402],
        [83.56040954589872, 18.000419616699276],
        [83.5601425170899, 18.000419616699276],
        [83.5601425170899, 18.000141143798885],
        [83.5598602294923, 18.000141143798885],
        [83.5598602294923, 17.99986839294428],
        [83.5598602294923, 17.99958038330078],
        [83.55958557128912, 17.99958419799816],
        [83.55958557128912, 17.999025344848576],
        [83.55930328369135, 17.99903106689453],
        [83.55930328369135, 17.997079849243278],
        [83.55902862548851, 17.99708366394043],
        [83.55902862548851, 17.996805191040096],
        [83.5587463378908, 17.996810913085994],
        [83.5587463378908, 17.992919921875],
        [83.55902862548851, 17.992919921875],
        [83.55902862548851, 17.99152946472168],
        [83.55930328369135, 17.99152946472168],
        [83.55930328369135, 17.990419387817496],
        [83.55959320068382, 17.990419387817496],
        [83.55959320068382, 17.98986053466797],
        [83.5598602294923, 17.98986053466797],
        [83.5598602294923, 17.989578247070426],
        [83.5601425170899, 17.989583969116154],
        [83.5601425170899, 17.98930549621582],
        [83.56040954589872, 17.989309310913143],
        [83.56040954589872, 17.987930297851562],
        [83.5601425170899, 17.987930297851562],
        [83.5601425170899, 17.98735809326172],
        [83.5598602294923, 17.98736190795904],
        [83.5598602294923, 17.986804962158317],
        [83.55959320068382, 17.986810684204045],
        [83.55959320068382, 17.98624992370611],
        [83.55930328369135, 17.98624992370611],
        [83.55930328369135, 17.985691070556754],
        [83.55902862548851, 17.98569679260254],
        [83.55902862548851, 17.983472824096623],
        [83.5587463378908, 17.983472824096623],
        [83.5587463378908, 17.983194351196232],
        [83.55847167968756, 17.983194351196232],
        [83.55847167968756, 17.9829158782959],
        [83.55819702148455, 17.98291969299322],
        [83.55819702148455, 17.98264122009283],
        [83.55790710449247, 17.98264122009283],
        [83.55790710449247, 17.982360839843864],
        [83.5576400756836, 17.982360839843864],
        [83.5576400756836, 17.982080459594727],
        [83.55735778808622, 17.982084274292106],
        [83.55735778808622, 17.981527328491325],
        [83.55708312988287, 17.981531143188477],
        [83.55708312988287, 17.98125076293951],
        [83.5568084716798, 17.98125076293951],
        [83.5568084716798, 17.980970382690543],
        [83.55652618408209, 17.98097229003912],
        [83.55652618408209, 17.98041725158697],
        [83.55625152587885, 17.980421066284123],
        [83.55625152587885, 17.980140686035156],
        [83.55596923828148, 17.980140686035156],
        [83.55596923828148, 17.97986030578619],
        [83.55568695068376, 17.97986030578619],
        [83.55568695068376, 17.9795818328858],
        [83.55513763427751, 17.979583740234375],
        [83.55513763427751, 17.97930526733404],
        [83.5548629760745, 17.979312896728572],
        [83.5548629760745, 17.979030609130803],
        [83.55458068847656, 17.979030609130803],
        [83.55458068847656, 17.97874069213873],
        [83.55430603027372, 17.978750228881836],
        [83.55430603027372, 17.978471755981445],
        [83.55403137207037, 17.978471755981445],
        [83.55403137207037, 17.978195190429688],
        [83.55346679687506, 17.97820281982422],
        [83.55346679687506, 17.97792053222662],
        [83.55319213867182, 17.97792053222662],
        [83.55319213867182, 17.977640151977653],
        [83.55291748046898, 17.977640151977653],
        [83.55291748046898, 17.97736167907709],
        [83.55263519287126, 17.97736167907709],
        [83.55263519287126, 17.9770832061767],
        [83.55236053466803, 17.9770832061767],
        [83.55236053466803, 17.97680473327631],
        [83.55208587646501, 17.97680473327631],
        [83.55208587646501, 17.976528167724723],
        [83.5515365600586, 17.9765300750733],
        [83.5515365600586, 17.97625160217291],
        [83.55124664306669, 17.97625160217291],
        [83.55124664306669, 17.975969314575195],
        [83.55097198486328, 17.975973129272518],
        [83.55097198486328, 17.975694656372184],
        [83.55069732666027, 17.975702285766715],
        [83.55069732666027, 17.97541809082037],
        [83.55042266845709, 17.97541809082037],
        [83.55042266845709, 17.974861145019588],
        [83.55014038085932, 17.974861145019588],
        [83.55014038085932, 17.97458076477062],
        [83.54985809326178, 17.974582672119197],
        [83.54985809326178, 17.97430610656744],
        [83.549575805664, 17.97430992126459],
        [83.549575805664, 17.973751068115234],
        [83.54930877685553, 17.973751068115234],
        [83.54930877685553, 17.973470687866268],
        [83.54902648925798, 17.973472595214844],
        [83.54902648925798, 17.973194122314453],
        [83.54875183105497, 17.973194122314453],
        [83.54875183105497, 17.97263717651373],
        [83.5484695434572, 17.97264099121088],
        [83.5484695434572, 17.972360610961914],
        [83.54817962646513, 17.972360610961914],
        [83.54817962646513, 17.972080230712947],
        [83.54792022705078, 17.972085952758903],
        [83.54792022705078, 17.971805572509766],
        [83.54763793945341, 17.971809387207088],
        [83.54763793945341, 17.971530914306697],
        [83.54736328125006, 17.971530914306697],
        [83.54736328125006, 17.97125053405773],
        [83.54708099365246, 17.97125053405773],
        [83.54708099365246, 17.970970153808594],
        [83.54680633544928, 17.97097206115717],
        [83.54680633544928, 17.9701385498048],
        [83.54651641845697, 17.970140457153377],
        [83.54651641845697, 17.969030380249023],
        [83.54624938964866, 17.969030380249023],
        [83.54624938964866, 17.96792030334467],
        [83.54596710205095, 17.96792030334467],
        [83.54596710205095, 17.967081069946346],
        [83.54569244384771, 17.967084884643555],
        [83.54569244384771, 17.966527938842773],
        [83.54541015625017, 17.96652984619152],
        [83.54541015625017, 17.965972900390568],
        [83.54513549804716, 17.965972900390568],
        [83.54513549804716, 17.965694427490178],
        [83.54486083984375, 17.965694427490178],
        [83.54486083984375, 17.9651393890382],
        [83.54458618164091, 17.9651393890382],
        [83.54458618164091, 17.964582443237248],
        [83.54430389404303, 17.964582443237248],
        [83.54430389404303, 17.96402740478527],
        [83.54402923583996, 17.964031219482422],
        [83.54402923583996, 17.963468551635742],
        [83.54374694824224, 17.963472366333065],
        [83.54374694824224, 17.963193893432674],
        [83.543472290039, 17.963193893432674],
        [83.543472290039, 17.962917327880916],
        [83.54319763183616, 17.962919235229492],
        [83.54319763183616, 17.9626407623291],
        [83.54292297363298, 17.9626407623291],
        [83.54292297363298, 17.96235847473156],
        [83.54264068603521, 17.96235847473156],
        [83.54264068603521, 17.962078094482422],
        [83.54208374023466, 17.96208381652832],
        [83.54208374023466, 17.961805343627987],
        [83.54180908203125, 17.96180915832531],
        [83.54180908203125, 17.961530685424748],
        [83.54152679443388, 17.961530685424748],
        [83.54152679443388, 17.96125030517578],
        [83.54125213623053, 17.96125030517578],
        [83.54125213623053, 17.960969924926815],
        [83.54096984863293, 17.96097183227539],
        [83.54096984863293, 17.960695266723633],
        [83.54070281982428, 17.960699081420955],
        [83.54070281982428, 17.960420608520565],
        [83.54041290283197, 17.960420608520565],
        [83.54041290283197, 17.960140228271598],
        [83.53985595703142, 17.960140228271598],
        [83.53985595703142, 17.95985984802246],
        [83.53958129882818, 17.959861755371037],
        [83.53958129882818, 17.959583282470646],
        [83.53930664062517, 17.959583282470646],
        [83.53930664062517, 17.959304809570256],
        [83.53902435302763, 17.959304809570256],
        [83.53902435302763, 17.959026336669922],
        [83.53848266601591, 17.959030151367244],
        [83.53848266601591, 17.958749771118278],
        [83.53819274902344, 17.958749771118278],
        [83.53819274902344, 17.95846939086914],
        [83.53791809082043, 17.958471298217887],
        [83.53791809082043, 17.95819473266613],
        [83.53763580322271, 17.95819473266613],
        [83.53763580322271, 17.957914352416935],
        [83.53708648681663, 17.95792007446289],
        [83.53708648681663, 17.957630157470817],
        [83.53680419921892, 17.957639694213924],
        [83.53680419921892, 17.957361221313533],
        [83.53652954101568, 17.957361221313533],
        [83.53652954101568, 17.957080841064567],
        [83.53624725341814, 17.957082748413143],
        [83.53624725341814, 17.956804275512752],
        [83.53597259521513, 17.956809997558537],
        [83.53597259521513, 17.95652961730957],
        [83.53569793701172, 17.95652961730957],
        [83.53569793701172, 17.95625114440918],
        [83.53514099121094, 17.95625114440918],
        [83.53514099121094, 17.955970764160213],
        [83.53485870361357, 17.95597267150879],
        [83.53485870361357, 17.9556941986084],
        [83.53458404541021, 17.9556941986084],
        [83.53458404541021, 17.95541763305664],
        [83.53430175781261, 17.955419540405387],
        [83.53430175781261, 17.955141067504826],
        [83.53402709960943, 17.955141067504826],
        [83.53402709960943, 17.95486068725586],
        [83.53375244140642, 17.95486068725586],
        [83.53375244140642, 17.954309463501033],
        [83.53347015380882, 17.954309463501033],
        [83.53347015380882, 17.954030990600643],
        [83.53320312500017, 17.954030990600643],
        [83.53320312500017, 17.95347023010254],
        [83.53291320800787, 17.953472137451115],
        [83.53291320800787, 17.953193664550724],
        [83.53263854980486, 17.953193664550724],
        [83.53263854980486, 17.952917098999137],
        [83.53235626220732, 17.952922821044922],
        [83.53235626220732, 17.952360153198356],
        [83.5320816040039, 17.952362060546932],
        [83.5320816040039, 17.95208358764654],
        [83.53180694580107, 17.95208358764654],
        [83.53180694580107, 17.951805114746207],
        [83.5315170288086, 17.95182037353527],
        [83.5315170288086, 17.95153045654297],
        [83.53125000000011, 17.95153045654297],
        [83.53125000000011, 17.951250076294002],
        [83.53097534179693, 17.951250076294002],
        [83.53097534179693, 17.950695037841854],
        [83.53069305419916, 17.950702667236385],
        [83.53069305419916, 17.950420379638615],
        [83.53042602539068, 17.950420379638615],
        [83.53042602539068, 17.950141906738224],
        [83.53013610839861, 17.950141906738224],
        [83.53013610839861, 17.949861526489258],
        [83.52986145019537, 17.949861526489258],
        [83.52986145019537, 17.949583053588867],
        [83.52958679199236, 17.949583053588867],
        [83.52958679199236, 17.94930648803711],
        [83.52874755859403, 17.949310302734432],
        [83.52874755859403, 17.949029922485465],
        [83.52819824218761, 17.949029922485465],
        [83.52819061279308, 17.948759078979435],
        [83.52736663818382, 17.948759078979435],
        [83.52736663818382, 17.94846916198736],
        [83.52680206298834, 17.948472976684513],
        [83.52680206298834, 17.948194503784123],
        [83.52597045898438, 17.948194503784123],
        [83.52597045898438, 17.947917938232536],
        [83.52540588378906, 17.94791984558111],
        [83.52540588378906, 17.947639465332145],
        [83.52485656738287, 17.947639465332145],
        [83.52485656738287, 17.947359085083008],
        [83.52429962158209, 17.947359085083008],
        [83.52429962158209, 17.947080612182617],
        [83.52375030517584, 17.947082519531193],
        [83.52375030517584, 17.946807861328182],
        [83.52319335937528, 17.946809768676758],
        [83.52319335937528, 17.94652938842779],
        [83.5226364135745, 17.94652938842779],
        [83.5226364135745, 17.9462509155274],
        [83.52208709716808, 17.9462509155274],
        [83.52208709716808, 17.945970535278434],
        [83.52153015136713, 17.94597244262701],
        [83.52153015136713, 17.94569396972662],
        [83.52097320556658, 17.94569396972662],
        [83.52097320556658, 17.945415496826286],
        [83.5204162597658, 17.945421218872013],
        [83.5204162597658, 17.945140838623047],
        [83.52014160156278, 17.945140838623047],
        [83.52014160156278, 17.94486045837408],
        [83.51957702636747, 17.94486045837408],
        [83.51957702636747, 17.944580078125114],
        [83.51930236816406, 17.944583892822266],
        [83.51930236816406, 17.944305419921932],
        [83.51875305175787, 17.94431114196783],
        [83.51875305175787, 17.944030761718693],
        [83.51847076416027, 17.944030761718693],
        [83.51847076416027, 17.943750381469727],
        [83.51790618896479, 17.943750381469727],
        [83.51790618896479, 17.94347000122076],
        [83.51763916015648, 17.943471908569336],
        [83.51763916015648, 17.943195343017578],
        [83.51708221435553, 17.943201065063477],
        [83.51708221435553, 17.94292068481451],
        [83.51680755615251, 17.94292068481451],
        [83.51680755615251, 17.942630767822266],
        [83.51625061035156, 17.942638397216797],
        [83.51625061035156, 17.942361831664982],
        [83.51597595214872, 17.942361831664982],
        [83.51597595214872, 17.94208335876459],
        [83.51569366455084, 17.94208335876459],
        [83.51569366455084, 17.9418048858642],
        [83.51513671875006, 17.9418048858642],
        [83.51513671875006, 17.941528320312614],
        [83.51486206054682, 17.94153022766119],
        [83.51486206054682, 17.941249847412223],
        [83.51430511474626, 17.941249847412223],
        [83.51430511474626, 17.940971374511832],
        [83.51403045654303, 17.940971374511832],
        [83.51403045654303, 17.940694808960075],
        [83.51374816894548, 17.940694808960075],
        [83.51374816894548, 17.94013977050787],
        [83.51346588134794, 17.94013977050787],
        [83.51346588134794, 17.939859390258903],
        [83.51319122314453, 17.93986129760748],
        [83.51319122314453, 17.939582824707088],
        [83.51291656494169, 17.93959045410162],
        [83.51291656494169, 17.939310073852482],
        [83.51264190673834, 17.939310073852482],
        [83.51264190673834, 17.938751220703125],
        [83.51235961914074, 17.938751220703125],
        [83.51235961914074, 17.938472747802734],
        [83.51208496093756, 17.938472747802734],
        [83.51208496093756, 17.938194274902344],
        [83.51180267333979, 17.938194274902344],
        [83.51180267333979, 17.93791580200201],
        [83.51125335693376, 17.937919616699332],
        [83.51125335693376, 17.93764114379877],
        [83.510971069336, 17.93764114379877],
        [83.510971069336, 17.93736267089838],
        [83.51040649414068, 17.93736267089838],
        [83.51040649414068, 17.937078475952262],
        [83.50986480712919, 17.93708419799799],
        [83.50986480712919, 17.936805725097656],
        [83.50958251953125, 17.936822891235465],
        [83.50958251953125, 17.936529159546012],
        [83.50903320312506, 17.936529159546012],
        [83.50903320312506, 17.93625068664562],
        [83.50846862792974, 17.93625068664562],
        [83.50846862792974, 17.93596839904791],
        [83.5079193115235, 17.93597221374506],
        [83.5079193115235, 17.93569374084467],
        [83.50763702392595, 17.93569374084467],
        [83.50763702392595, 17.935417175293082],
        [83.50708007812517, 17.93541908264166],
        [83.50708007812517, 17.935140609741268],
        [83.50653076171875, 17.935140609741268],
        [83.50653076171875, 17.934858322143555],
        [83.5059661865235, 17.934858322143555],
        [83.5059661865235, 17.934579849243164],
        [83.50541687011724, 17.934583663940487],
        [83.50541687011724, 17.934305191040153],
        [83.50485992431663, 17.93431091308588],
        [83.50485992431663, 17.934030532836914],
        [83.50430297851568, 17.934030532836914],
        [83.50430297851568, 17.93374824523937],
        [83.50374603271513, 17.93374824523937],
        [83.50374603271513, 17.93346977233898],
        [83.50319671630888, 17.933471679687557],
        [83.50319671630888, 17.9331951141358],
        [83.50263977050793, 17.933200836181697],
        [83.50263977050793, 17.93292045593256],
        [83.50208282470697, 17.93292045593256],
        [83.50208282470697, 17.932640075683594],
        [83.50152587890642, 17.932640075683594],
        [83.50152587890642, 17.932359695434627],
        [83.50096893310564, 17.932361602783203],
        [83.50096893310564, 17.932083129882812],
        [83.50041961669922, 17.932083129882812],
        [83.50041961669922, 17.931804656982422],
        [83.4998626708985, 17.931804656982422],
        [83.4998626708985, 17.931526184082088],
        [83.49903106689447, 17.93152999877941],
        [83.49903106689447, 17.931249618530273],
        [83.49846649169939, 17.931249618530273],
        [83.49846649169939, 17.930971145629883],
        [83.49791717529314, 17.93097305297846],
        [83.49791717529314, 17.930694580078068],
        [83.49736022949219, 17.930694580078068],
        [83.49736022949219, 17.9304141998291],
        [83.4968032836914, 17.930419921875057],
        [83.4968032836914, 17.93013954162609],
        [83.4965286254884, 17.93013954162609],
        [83.4965286254884, 17.9298610687257],
        [83.49597167968744, 17.9298610687257],
        [83.49597167968744, 17.929580688476562],
        [83.49541473388689, 17.92958259582514],
        [83.49541473388689, 17.929304122924748],
        [83.49485778808611, 17.929309844970703],
        [83.49485778808611, 17.929029464721737],
        [83.49431610107422, 17.929029464721737],
        [83.49431610107422, 17.928750991821346],
        [83.49402618408232, 17.928750991821346],
        [83.49402618408232, 17.92847061157238],
        [83.49346923828153, 17.928472518920955],
        [83.49346923828153, 17.92819213867199],
        [83.49319458007818, 17.92819213867199],
        [83.49319458007818, 17.927917480468807],
        [83.49291992187494, 17.927919387817383],
        [83.49291992187494, 17.927650451660156],
        [83.49236297607439, 17.927650451660156],
        [83.49236297607439, 17.927360534668026],
        [83.49208068847679, 17.927360534668026],
        [83.49208068847679, 17.92708015441906],
        [83.49180603027361, 17.927082061767635],
        [83.49180603027361, 17.926805496215877],
        [83.49125671386719, 17.926811218261776],
        [83.49125671386719, 17.92653083801264],
        [83.49041748046903, 17.92653083801264],
        [83.49041748046903, 17.926250457763672],
        [83.48986053466808, 17.926250457763672],
        [83.48986053466808, 17.925970077514705],
        [83.48902893066429, 17.925973892211857],
        [83.48902893066429, 17.925695419311523],
        [83.48792266845732, 17.92568969726574],
        [83.48791503906278, 17.925416946411133],
        [83.48764038085938, 17.925422668457088],
        [83.48764038085938, 17.92514038085949],
        [83.487358093262, 17.92514038085949],
        [83.487358093262, 17.924861907958928],
        [83.48708343505865, 17.924861907958928],
        [83.48708343505865, 17.924304962158146],
        [83.48680877685558, 17.924312591552734],
        [83.48680877685558, 17.924030303955135],
        [83.48652648925787, 17.924030303955135],
        [83.48652648925787, 17.923751831054744],
        [83.48625183105463, 17.923751831054744],
        [83.48625183105463, 17.923471450805778],
        [83.48569488525408, 17.923471450805778],
        [83.48569488525408, 17.92319488525402],
        [83.48542022705084, 17.92319488525402],
        [83.48542022705084, 17.92291641235363],
        [83.48486328125028, 17.92292022705078],
        [83.48486328125028, 17.92264175415039],
        [83.4843063354495, 17.92264175415039],
        [83.4843063354495, 17.9223575592041],
        [83.48402404785156, 17.922361373901424],
        [83.48402404785156, 17.922082901001033],
        [83.48347473144537, 17.922082901001033],
        [83.48347473144537, 17.921806335449276],
        [83.48291778564476, 17.92180824279785],
        [83.48291778564476, 17.92152976989746],
        [83.4823608398438, 17.92152976989746],
        [83.4823608398438, 17.92124748229992],
        [83.4820861816408, 17.92124748229992],
        [83.4820861816408, 17.920970916748104],
        [83.48152923583984, 17.92097282409668],
        [83.48152923583984, 17.92069435119629],
        [83.48097229003906, 17.92069435119629],
        [83.48097229003906, 17.92041778564453],
        [83.48069763183605, 17.920419692993278],
        [83.48069763183605, 17.920141220092717],
        [83.4801406860351, 17.920141220092717],
        [83.4801406860351, 17.91986083984375],
        [83.47957611083979, 17.91986083984375],
        [83.47957611083979, 17.919580459594783],
        [83.4793090820313, 17.919584274291935],
        [83.4793090820313, 17.919307708740348],
        [83.47875213623075, 17.919309616088924],
        [83.47875213623075, 17.919031143188533],
        [83.4782028198245, 17.919031143188533],
        [83.4782028198245, 17.918750762939567],
        [83.47763824462919, 17.918750762939567],
        [83.47763824462919, 17.918460845947322],
        [83.47744750976591, 17.918460845947322],
        [83.47736358642584, 17.918193817138615],
        [83.47680664062506, 17.918193817138615],
        [83.47680664062506, 17.917915344238224],
        [83.47651672363276, 17.91792106628418],
        [83.47651672363276, 17.917640686035213],
        [83.4759826660158, 17.917640686035213],
        [83.4759826660158, 17.917360305786246],
        [83.4756927490235, 17.917360305786246],
        [83.4756927490235, 17.91707992553711],
        [83.47513580322294, 17.917083740234432],
        [83.47513580322294, 17.916805267334098],
        [83.47486114501953, 17.916810989379826],
        [83.47486114501953, 17.91653060913086],
        [83.47429656982422, 17.91653060913086],
        [83.47429656982422, 17.916250228881893],
        [83.47402954101574, 17.916250228881893],
        [83.47402954101574, 17.915969848632926],
        [83.47347259521479, 17.915971755981502],
        [83.47347259521479, 17.91569328308111],
        [83.47319030761741, 17.915710449218807],
        [83.47319030761741, 17.915420532226506],
        [83.47235870361345, 17.915420532226506],
        [83.47235870361345, 17.91514015197754],
        [83.4718017578125, 17.91514015197754],
        [83.4718017578125, 17.914859771728572],
        [83.47069549560553, 17.91486167907715],
        [83.47069549560553, 17.914583206176758],
        [83.47013854980491, 17.914583206176758],
        [83.47013854980491, 17.914304733276367],
        [83.46986389160173, 17.914304733276367],
        [83.46986389160173, 17.91402816772461],
        [83.46958160400396, 17.914030075073356],
        [83.46958160400396, 17.91374969482422],
        [83.46930694580095, 17.91374969482422],
        [83.46930694580095, 17.913471221923828],
        [83.46902465820341, 17.913471221923828],
        [83.46902465820341, 17.913194656372013],
        [83.46875, 17.913194656372013],
        [83.46875, 17.91291618347168],
        [83.46848297119169, 17.912919998169002],
        [83.46848297119169, 17.912651062011776],
        [83.46819305419922, 17.912651062011776],
        [83.46819305419922, 17.912361145019645],
        [83.46791839599621, 17.912361145019645],
        [83.46791839599621, 17.912082672119084],
        [83.4676361083985, 17.912082672119084],
        [83.4676361083985, 17.911806106567496],
        [83.46736145019526, 17.911811828613224],
        [83.46736145019526, 17.911529541015682],
        [83.46708679199241, 17.911529541015682],
        [83.46708679199241, 17.91125106811529],
        [83.46679687499994, 17.91125106811529],
        [83.46679687499994, 17.910690307617188],
        [83.46652984619146, 17.91069412231451],
        [83.46652984619146, 17.910417556762752],
        [83.46624755859392, 17.910419464111328],
        [83.46624755859392, 17.91013908386236],
        [83.46597290039091, 17.91013908386236],
        [83.46597290039091, 17.90986061096197],
        [83.4656982421875, 17.90986061096197],
        [83.46569061279314, 17.909578323364258],
        [83.46541595459013, 17.909584045410156],
        [83.46541595459013, 17.909305572509822],
        [83.46514129638672, 17.909309387207145],
        [83.46514129638672, 17.909030914306584],
        [83.46485900878935, 17.909030914306584],
        [83.46485900878935, 17.908750534057617],
        [83.46457672119146, 17.908750534057617],
        [83.46457672119146, 17.908468246460075],
        [83.4643020629884, 17.908472061157227],
        [83.4643020629884, 17.908193588256836],
        [83.46402740478521, 17.908193588256836],
        [83.46402740478521, 17.907918930053825],
        [83.4637527465822, 17.9079208374024],
        [83.4637527465822, 17.907640457153434],
        [83.46319580078142, 17.907640457153434],
        [83.46319580078142, 17.90735816955572],
        [83.46291351318365, 17.907361984252873],
        [83.46291351318365, 17.907083511352482],
        [83.46263885498064, 17.907083511352482],
        [83.46263885498064, 17.906806945800895],
        [83.4623565673831, 17.906810760498047],
        [83.4623565673831, 17.906520843505973],
        [83.46208190917969, 17.906526565551758],
        [83.46208190917969, 17.906248092651367],
        [83.46180725097685, 17.906248092651367],
        [83.46180725097685, 17.905969619750977],
        [83.46152496337896, 17.905971527099723],
        [83.46152496337896, 17.90569686889654],
        [83.4612503051759, 17.905700683593693],
        [83.4612503051759, 17.905420303344727],
        [83.46098327636741, 17.905420303344727],
        [83.46098327636741, 17.90513992309576],
        [83.46069335937494, 17.90513992309576],
        [83.46069335937494, 17.904859542846793],
        [83.4604187011721, 17.90486145019537],
        [83.4604187011721, 17.90458297729498],
        [83.46013641357439, 17.904581069946403],
        [83.46013641357439, 17.905420303344727],
        [83.4604187011721, 17.90541839599615],
        [83.4604187011721, 17.905971527099723],
        [83.46069335937494, 17.905971527099723],
        [83.46069335937494, 17.907083511352482],
        [83.4604187011721, 17.90707969665533],
        [83.4604187011721, 17.907640457153434],
        [83.46013641357439, 17.907640457153434],
        [83.46013641357439, 17.908750534057617],
        [83.45986175537115, 17.908750534057617],
        [83.45986175537115, 17.912361145019645],
        [83.45958709716814, 17.912361145019645],
        [83.45958709716814, 17.914583206176758],
        [83.4593048095706, 17.914583206176758],
        [83.4593048095706, 17.918472290039006],
        [83.45958709716814, 17.918460845947322],
        [83.45958709716814, 17.920141220092717],
        [83.45903015136719, 17.920141220092717],
        [83.45903015136719, 17.920419692993278],
        [83.45874786376982, 17.92041778564453],
        [83.45874786376982, 17.92097282409668],
        [83.45847320556646, 17.920970916748104],
        [83.45847320556646, 17.92124748229992],
        [83.45791625976568, 17.92124748229992],
        [83.45791625976568, 17.920970916748104],
        [83.45764160156244, 17.92097282409668],
        [83.45764160156244, 17.92069435119629],
        [83.45735931396507, 17.92069435119629],
        [83.45735931396507, 17.919025421142578],
        [83.45764160156244, 17.919031143188533],
        [83.45764160156244, 17.918750762939567],
        [83.45791625976568, 17.918750762939567],
        [83.45791625976568, 17.918460845947322],
        [83.45819091796886, 17.918472290039006],
        [83.45819091796886, 17.912082672119084],
        [83.45847320556646, 17.912082672119084],
        [83.45847320556646, 17.9109725952149],
        [83.45874786376982, 17.9109725952149],
        [83.45874786376982, 17.908472061157227],
        [83.45903015136719, 17.908472061157227],
        [83.45903015136719, 17.90569686889654],
        [83.45874786376982, 17.905700683593693],
        [83.45874786376982, 17.905420303344727],
        [83.45847320556646, 17.905420303344727],
        [83.45847320556646, 17.90513992309576],
        [83.45819091796886, 17.90513992309576],
        [83.45819091796886, 17.904859542846793],
        [83.45764160156244, 17.90486145019537],
        [83.45764160156244, 17.90458297729498],
        [83.45708465576189, 17.90458297729498],
        [83.45708465576189, 17.904304504394588],
        [83.45680236816412, 17.904310226440373],
        [83.45680236816412, 17.904029846191406],
        [83.4562530517581, 17.904029846191406],
        [83.4562530517581, 17.90374946594244],
        [83.45597076416016, 17.90374946594244],
        [83.45597076416016, 17.90345954895014],
        [83.45569610595732, 17.903472900390625],
        [83.45569610595732, 17.903194427490234],
        [83.45402526855486, 17.903194427490234],
        [83.45402526855486, 17.902914047241268],
        [83.45347595214861, 17.902919769287223],
        [83.45347595214861, 17.902639389038086],
        [83.4531860351563, 17.902639389038086],
        [83.4531860351563, 17.90180969238287],
        [83.45263671875028, 17.90180969238287],
        [83.45263671875028, 17.901250839233512],
        [83.45236206054688, 17.901250839233512],
        [83.45236206054688, 17.900970458984375],
        [83.4520797729495, 17.90097236633295],
        [83.4520797729495, 17.90069389343256],
        [83.45180511474615, 17.90069389343256],
        [83.45180511474615, 17.900138854980582],
        [83.45153045654291, 17.90014076232916],
        [83.45153045654291, 17.8965301513673],
        [83.45181274414068, 17.8965301513673],
        [83.45181274414068, 17.895690917968807],
        [83.4520797729495, 17.89569473266596],
        [83.4520797729495, 17.89513969421398],
        [83.45236206054688, 17.89513969421398],
        [83.45236206054688, 17.894580841064453],
        [83.45263671875028, 17.89458274841303],
        [83.45263671875028, 17.894306182861442],
        [83.45291900634766, 17.894308090210018],
        [83.45291900634766, 17.894029617309627],
        [83.45347595214861, 17.894029617309627],
        [83.45347595214861, 17.893751144409237],
        [83.45403289794939, 17.893751144409237],
        [83.45403289794939, 17.89347076416027],
        [83.45430755615257, 17.893472671508846],
        [83.45430755615257, 17.893194198608455],
        [83.45458221435541, 17.893194198608455],
        [83.45458221435541, 17.89263725280773],
        [83.45485687255865, 17.892641067504883],
        [83.45485687255865, 17.892360687255916],
        [83.4551467895509, 17.892360687255916],
        [83.45513916015636, 17.8920841217041],
        [83.45541381835938, 17.8920841217041],
        [83.45541381835938, 17.89041519165039],
        [83.4551467895509, 17.890420913696346],
        [83.4551467895509, 17.889030456543026],
        [83.45485687255865, 17.889030456543026],
        [83.45485687255865, 17.88820075988764],
        [83.45458221435541, 17.88820075988764],
        [83.45458221435541, 17.887639999389705],
        [83.45430755615257, 17.887639999389705],
        [83.45430755615257, 17.88735961914074],
        [83.45402526855486, 17.887361526489315],
        [83.45402526855486, 17.886806488037166],
        [83.45375061035162, 17.886810302734318],
        [83.45375061035162, 17.88652992248535],
        [83.45347595214861, 17.88652992248535],
        [83.45347595214861, 17.886249542236385],
        [83.45291900634766, 17.886249542236385],
        [83.45291900634766, 17.885971069335994],
        [83.45263671875028, 17.885971069335994],
        [83.45263671875028, 17.88569450378418],
        [83.45236206054688, 17.88569450378418],
        [83.45236206054688, 17.885416030883846],
        [83.45181274414068, 17.88541984558117],
        [83.45181274414068, 17.88513946533203],
        [83.45124816894537, 17.88513946533203],
        [83.45124816894537, 17.884862899780217],
        [83.4509658813476, 17.884862899780217],
        [83.4509658813476, 17.88458251953125],
        [83.45069122314476, 17.88458251953125],
        [83.45069122314476, 17.884305953979492],
        [83.45041656494158, 17.884309768676815],
        [83.45041656494158, 17.884029388427848],
        [83.45014190673857, 17.884029388427848],
        [83.45014190673857, 17.883750915527457],
        [83.44957733154325, 17.883750915527457],
        [83.44957733154325, 17.883468627929744],
        [83.44930267333984, 17.883472442626896],
        [83.44930267333984, 17.883193969726506],
        [83.449028015137, 17.883193969726506],
        [83.449028015137, 17.88291740417492],
        [83.44875335693365, 17.882919311523494],
        [83.44875335693365, 17.882640838623104],
        [83.44847106933605, 17.882640838623104],
        [83.44847106933605, 17.88235092163086],
        [83.44819641113287, 17.882360458374137],
        [83.44819641113287, 17.882083892822322],
        [83.4479141235351, 17.882083892822322],
        [83.4479141235351, 17.88180541992199],
        [83.44763946533226, 17.88180923461914],
        [83.44763946533226, 17.88153076171875],
        [83.44735717773455, 17.88153076171875],
        [83.44735717773455, 17.881250381469783],
        [83.4470825195313, 17.881250381469783],
        [83.4470825195313, 17.88096809387207],
        [83.4468078613283, 17.880971908569393],
        [83.4468078613283, 17.880695343017635],
        [83.44653320312528, 17.880699157714957],
        [83.44653320312528, 17.88014030456543],
        [83.44638824462885, 17.88014030456543],
        [83.44625091552734, 17.88014030456543],
        [83.44625091552734, 17.879579544067496],
        [83.44596862792997, 17.87958335876465],
        [83.44596862792997, 17.879026412963924],
        [83.44568634033203, 17.879030227661246],
        [83.44568634033203, 17.878469467163143],
        [83.44541931152355, 17.87847137451172],
        [83.44541931152355, 17.877914428710938],
        [83.44513702392584, 17.877920150756893],
        [83.44513702392584, 17.87735939025879],
        [83.4448623657226, 17.877361297607536],
        [83.4448623657226, 17.876804351806584],
        [83.44458007812523, 17.87681007385254],
        [83.44458007812523, 17.875141143798828],
        [83.44431304931658, 17.875141143798828],
        [83.44431304931658, 17.87319183349615],
        [83.4440307617188, 17.873193740844727],
        [83.4440307617188, 17.872638702392578],
        [83.44374847412126, 17.872640609741325],
        [83.44374847412126, 17.872360229492188],
        [83.44291687011747, 17.872360229492188],
        [83.44291687011747, 17.872081756591797],
        [83.44235992431652, 17.872083663940373],
        [83.44235992431652, 17.871805191039982],
        [83.44152832031273, 17.87181282043457],
        [83.44152832031273, 17.87153053283697],
        [83.44069671630876, 17.87153053283697],
        [83.44069671630876, 17.871250152588004],
        [83.43958282470703, 17.871250152588004],
        [83.43958282470703, 17.870971679687614],
        [83.4390258789063, 17.870971679687614],
        [83.4390258789063, 17.870695114135856],
        [83.43875122070307, 17.870702743530387],
        [83.43875122070307, 17.87041091918951],
        [83.43819427490251, 17.870416641235465],
        [83.43819427490251, 17.870138168335075],
        [83.43791961669928, 17.87014007568365],
        [83.43791961669928, 17.86986160278326],
        [83.43736267089872, 17.86986160278326],
        [83.43736267089872, 17.86958312988287],
        [83.43708038330095, 17.86958312988287],
        [83.43708038330095, 17.86930465698248],
        [83.43653106689453, 17.86930465698248],
        [83.43653106689453, 17.86902809143072],
        [83.43624877929716, 17.869031906127873],
        [83.43624877929716, 17.86874961853033],
        [83.43569183349621, 17.86874961853033],
        [83.43569183349621, 17.868469238281364],
        [83.43541717529303, 17.868473052978516],
        [83.43541717529303, 17.868194580078125],
        [83.43486022949241, 17.868194580078125],
        [83.43486022949241, 17.86791610717779],
        [83.43430328369146, 17.867919921875114],
        [83.43430328369146, 17.867639541625977],
        [83.43374633789091, 17.867639541625977],
        [83.43374633789091, 17.867349624633846],
        [83.4334716796875, 17.867361068725586],
        [83.4334716796875, 17.867082595825195],
        [83.43319702148466, 17.867082595825195],
        [83.43319702148466, 17.866807937622184],
        [83.43264007568371, 17.86680984497076],
        [83.43264007568371, 17.866529464721793],
        [83.43208312988276, 17.866529464721793],
        [83.43208312988276, 17.866250991821403],
        [83.43180847167991, 17.866250991821403],
        [83.43180847167991, 17.865970611572266],
        [83.43125152587896, 17.86597251892084],
        [83.43125152587896, 17.86569404602045],
        [83.43096923828142, 17.86569404602045],
        [83.43096923828142, 17.86541557312006],
        [83.430419921875, 17.86541938781744],
        [83.430419921875, 17.86514091491705],
        [83.42986297607428, 17.86514091491705],
        [83.42986297607428, 17.864860534668082],
        [83.42958068847668, 17.864860534668082],
        [83.42958068847668, 17.864580154418945],
        [83.42903137207026, 17.8645858764649],
        [83.42903137207026, 17.864305496215934],
        [83.42874908447288, 17.864311218261662],
        [83.42874908447288, 17.864030838012695],
        [83.42819213867193, 17.864030838012695],
        [83.42819213867193, 17.86375045776373],
        [83.42791748046892, 17.86375045776373],
        [83.42791748046892, 17.863470077514762],
        [83.42736053466797, 17.863471984863338],
        [83.42736053466797, 17.863197326660156],
        [83.42708587646513, 17.863189697265625],
        [83.42708587646513, 17.86291694641119],
        [83.42679595947266, 17.86292076110834],
        [83.42679595947266, 17.862640380859375],
        [83.42624664306646, 17.862640380859375],
        [83.42624664306646, 17.86236000061041],
        [83.42597198486322, 17.862361907958984],
        [83.42597198486322, 17.862083435058594],
        [83.42569732666038, 17.862083435058594],
        [83.42569732666038, 17.861803054809627],
        [83.42514038085943, 17.86181068420416],
        [83.42514038085943, 17.861539840698356],
        [83.42485046386736, 17.861539840698356],
        [83.42485046386736, 17.861249923706055],
        [83.42457580566412, 17.861249923706055],
        [83.42457580566412, 17.860969543457088],
        [83.4240264892581, 17.860971450805664],
        [83.4240264892581, 17.86069488525385],
        [83.42375183105469, 17.86069488525385],
        [83.42375183105469, 17.860416412353516],
        [83.42346954345732, 17.860420227050838],
        [83.42346954345732, 17.86013984680187],
        [83.42318725585943, 17.86013984680187],
        [83.42318725585943, 17.859859466552734],
        [83.42263793945318, 17.85986137390148],
        [83.42263793945318, 17.85958290100092],
        [83.42236328125017, 17.85958290100092],
        [83.42236328125017, 17.859306335449332],
        [83.42208099365257, 17.859310150146484],
        [83.42208099365257, 17.859029769897518],
        [83.42151641845709, 17.859029769897518],
        [83.42151641845709, 17.85874938964855],
        [83.42124938964861, 17.85874938964855],
        [83.42124938964861, 17.858472824096737],
        [83.42069244384766, 17.858472824096737],
        [83.42069244384766, 17.858194351196346],
        [83.42041778564482, 17.858194351196346],
        [83.42041778564482, 17.857915878296012],
        [83.42014312744146, 17.857919692993164],
        [83.42014312744146, 17.857641220092773],
        [83.41986083984386, 17.857641220092773],
        [83.41986083984386, 17.857362747192383],
        [83.41929626464838, 17.857362747192383],
        [83.41929626464838, 17.857078552246094],
        [83.41902923584007, 17.857084274291992],
        [83.41902923584007, 17.85680580139166],
        [83.41874694824236, 17.85680961608898],
        [83.41874694824236, 17.856250762939453],
        [83.41847229003912, 17.856250762939453],
        [83.41847229003912, 17.85596847534191],
        [83.41819763183611, 17.855972290039062],
        [83.41819763183611, 17.855693817138672],
        [83.41791534423857, 17.855693817138672],
        [83.41791534423857, 17.85514068603527],
        [83.41764068603516, 17.85514068603527],
        [83.41764068603516, 17.854858398437557],
        [83.41707611083984, 17.854858398437557],
        [83.41707611083984, 17.854579925537166],
        [83.41680908203136, 17.854583740234318],
        [83.41680908203136, 17.85375022888195],
        [83.41652679443365, 17.85375022888195],
        [83.41652679443365, 17.853469848632812],
        [83.41625213623041, 17.85347175598156],
        [83.41625213623041, 17.852918624877987],
        [83.41597747802757, 17.852920532226562],
        [83.41597747802757, 17.852640151977596],
        [83.41570281982439, 17.852640151977596],
        [83.41570281982439, 17.85237121582037],
        [83.41513824462908, 17.85237121582037],
        [83.41513824462908, 17.852079391479492],
        [83.41486358642607, 17.852083206176815],
        [83.41486358642607, 17.851804733276424],
        [83.41458129882812, 17.851804733276424],
        [83.41458129882812, 17.851249694824276],
        [83.41430664062528, 17.851249694824276],
        [83.41430664062528, 17.850971221923885],
        [83.41402435302734, 17.85097312927246],
        [83.41402435302734, 17.84986114501953],
        [83.41374969482433, 17.84986114501953],
        [83.41374969482433, 17.849580764770565],
        [83.41347503662115, 17.84958267211914],
        [83.41347503662115, 17.849027633666992],
        [83.41319274902338, 17.84902954101574],
        [83.41319274902338, 17.848751068115348],
        [83.41291046142584, 17.848751068115348],
        [83.41291046142584, 17.848190307617244],
        [83.41263580322283, 17.84819221496582],
        [83.41263580322283, 17.84791755676281],
        [83.41236114501959, 17.847919464111385],
        [83.41236114501959, 17.847640991210994],
        [83.41208648681658, 17.847640991210994],
        [83.41208648681658, 17.84708023071289],
        [83.41180419921903, 17.847082138061637],
        [83.41180419921903, 17.846250534057674],
        [83.41152954101562, 17.846250534057674],
        [83.41152954101562, 17.845972061157283],
        [83.41124725341825, 17.845972061157283],
        [83.41124725341825, 17.845417022705135],
        [83.41097259521484, 17.84542274475109],
        [83.41097259521484, 17.844860076904354],
        [83.41069793701183, 17.844860076904354],
        [83.41069793701183, 17.84430503845215],
        [83.41042327880865, 17.844312667846737],
        [83.41042327880865, 17.843751907348576],
        [83.41014099121088, 17.843751907348576],
        [83.41014099121088, 17.84347152709961],
        [83.40985870361334, 17.84347152709961],
        [83.40985870361334, 17.839860916137752],
        [83.41014099121088, 17.839860916137752],
        [83.41014099121088, 17.839031219482365],
        [83.41042327880865, 17.839031219482365],
        [83.41042327880865, 17.838470458984432],
        [83.41069793701183, 17.838472366333008],
        [83.41069793701183, 17.83763885498047],
        [83.41097259521484, 17.837640762329215],
        [83.41097259521484, 17.83708000183111],
        [83.41124725341825, 17.837083816528263],
        [83.41124725341825, 17.836805343627873],
        [83.41152954101562, 17.836811065673828],
        [83.41152954101562, 17.83653068542486],
        [83.41179656982428, 17.83653068542486],
        [83.41179656982428, 17.836250305175895],
        [83.41236114501959, 17.836250305175895],
        [83.41236114501959, 17.835969924926758],
        [83.41263580322283, 17.835971832275504],
        [83.41263580322283, 17.835693359374943],
        [83.41291046142584, 17.835700988769474],
        [83.41291046142584, 17.835420608520508],
        [83.41541290283209, 17.835420608520508],
        [83.41541290283209, 17.83514022827154],
        [83.41570281982439, 17.83514022827154],
        [83.41570281982439, 17.83374977111822],
        [83.41596984863304, 17.83374977111822],
        [83.41596984863304, 17.830139160156364],
        [83.41570281982439, 17.830139160156364],
        [83.41570281982439, 17.823190689086914],
        [83.41541290283209, 17.823194503784237],
        [83.41541290283209, 17.822639465332088],
        [83.41513824462908, 17.822639465332088],
        [83.41513824462908, 17.82180976867687],
        [83.41485595703153, 17.82180976867687],
        [83.41485595703153, 17.820970535278377],
        [83.41458129882812, 17.820972442626953],
        [83.41458129882812, 17.820417404174805],
        [83.41430664062528, 17.820421218872127],
        [83.41430664062528, 17.819860458374023],
        [83.41401672363281, 17.819860458374023],
        [83.41401672363281, 17.81929969787609],
        [83.41374969482433, 17.819305419921818],
        [83.41374969482433, 17.819026947021484],
        [83.41348266601568, 17.819030761718807],
        [83.41348266601568, 17.81875038146984],
        [83.41319274902338, 17.81875038146984],
        [83.41319274902338, 17.81820106506342],
        [83.41291046142584, 17.81820106506342],
        [83.41291046142584, 17.81792259216303],
        [83.41263580322283, 17.81792259216303],
        [83.41263580322283, 17.817361831665096],
        [83.41236114501959, 17.817361831665096],
        [83.41236114501959, 17.816804885864315],
        [83.41208648681658, 17.816804885864315],
        [83.41208648681658, 17.816251754760742],
        [83.41179656982428, 17.816259384155273],
        [83.41179656982428, 17.815689086914062],
        [83.41152954101562, 17.81569480895996],
        [83.41152954101562, 17.815139770507812],
        [83.41124725341825, 17.815139770507812],
        [83.41124725341825, 17.81430816650402],
        [83.41097259521484, 17.81430816650402],
        [83.41097259521484, 17.81375122070324],
        [83.41069793701183, 17.81375122070324],
        [83.41069793701183, 17.81208038330078],
        [83.41041564941412, 17.812084197998104],
        [83.41041564941412, 17.811807632446346],
        [83.41014099121088, 17.811809539794922],
        [83.41014099121088, 17.810420989990178],
        [83.4098510742188, 17.810420989990178],
        [83.4098510742188, 17.81014060974121],
        [83.40930175781273, 17.81014060974121],
        [83.40930175781273, 17.809860229492244],
        [83.40902709960955, 17.809860229492244],
        [83.40902709960955, 17.809579849243278],
        [83.40875244140653, 17.80958366394043],
        [83.40875244140653, 17.809303283691463],
        [83.40847015380876, 17.809310913085994],
        [83.40847015380876, 17.809030532837028],
        [83.40791320800781, 17.809030532837028],
        [83.40791320800781, 17.80875015258789],
        [83.40763854980497, 17.80875015258789],
        [83.40763854980497, 17.808469772338924],
        [83.40736389160162, 17.8084716796875],
        [83.40736389160162, 17.80819320678711],
        [83.40708160400402, 17.80820083618164],
        [83.40708160400402, 17.807920455932674],
        [83.40651702880854, 17.807920455932674],
        [83.40651702880854, 17.80735969543457],
        [83.40625000000023, 17.807361602783317],
        [83.40625000000023, 17.807083129882756],
        [83.40597534179705, 17.807083129882756],
        [83.40597534179705, 17.806804656982365],
        [83.40569305419928, 17.806804656982365],
        [83.40569305419928, 17.806528091430778],
        [83.40541839599626, 17.806529998779354],
        [83.40541839599626, 17.806249618530387],
        [83.40513610839872, 17.806249618530387],
        [83.40513610839872, 17.805971145629996],
        [83.40486145019531, 17.805971145629996],
        [83.40486145019531, 17.805694580078182],
        [83.40458679199247, 17.805694580078182],
        [83.40458679199247, 17.805416107177848],
        [83.404296875, 17.805419921875],
        [83.404296875, 17.805139541626033],
        [83.40402984619152, 17.805139541626033],
        [83.40402984619152, 17.804861068725643],
        [83.4037475585938, 17.804861068725643],
        [83.4037475585938, 17.804582595825252],
        [83.40347290039057, 17.804582595825252],
        [83.40347290039057, 17.804306030273494],
        [83.40319824218773, 17.80431938171398],
        [83.4031906127932, 17.80402946472168],
        [83.40291595459001, 17.80402946472168],
        [83.40291595459001, 17.803752899169865],
        [83.40235900878923, 17.803752899169865],
        [83.40235900878923, 17.803470611572322],
        [83.40208435058622, 17.8034725189209],
        [83.40208435058622, 17.803194046020508],
        [83.40152740478544, 17.803194046020508],
        [83.40152740478544, 17.802640914917106],
        [83.40097045898449, 17.802640914917106],
        [83.40097045898449, 17.80236053466797],
        [83.4006958007813, 17.80236053466797],
        [83.4006958007813, 17.802078247070426],
        [83.4001388549807, 17.802083969116154],
        [83.4001388549807, 17.801805496215763],
        [83.39985656738298, 17.801809310913143],
        [83.39985656738298, 17.801530838012752],
        [83.39930725097673, 17.801530838012752],
        [83.39930725097673, 17.801261901855526],
        [83.39875030517578, 17.801261901855526],
        [83.39875030517578, 17.80069923400879],
        [83.39848327636747, 17.800689697265682],
        [83.39847564697294, 17.800136566162053],
        [83.398193359375, 17.800140380859432],
        [83.398193359375, 17.799579620361328],
        [83.39791870117199, 17.79958343505865],
        [83.39791870117199, 17.799026489257926],
        [83.39763641357428, 17.799030303955078],
        [83.39763641357428, 17.798469543457145],
        [83.39736175537104, 17.79847145080572],
        [83.39736175537104, 17.79791831970215],
        [83.3970870971682, 17.797920227050895],
        [83.3970870971682, 17.797639846801758],
        [83.39653015136724, 17.797639846801758],
        [83.39653015136724, 17.79735946655279],
        [83.39611053466803, 17.797361373901367],
        [83.39569091796892, 17.797361373901367],
        [83.39569091796892, 17.796804428100586],
        [83.39541625976591, 17.79681015014654],
        [83.39541625976591, 17.796529769897575],
        [83.3948516845706, 17.796529769897575],
        [83.3948516845706, 17.796249389648438],
        [83.39430236816418, 17.796249389648438],
        [83.39430236816418, 17.795970916748047],
        [83.394027709961, 17.795972824096623],
        [83.394027709961, 17.795694351196232],
        [83.39375305175798, 17.795694351196232],
        [83.39375305175798, 17.79541587829584],
        [83.3931961059572, 17.79541969299322],
        [83.3931961059572, 17.79512977600092],
        [83.39291381835943, 17.795139312744254],
        [83.39291381835943, 17.794860839843864],
        [83.39235687255888, 17.794860839843864],
        [83.39235687255888, 17.794580459594727],
        [83.39208221435547, 17.794582366943473],
        [83.39208221435547, 17.794305801391715],
        [83.39180755615263, 17.794309616088867],
        [83.39180755615263, 17.794031143188477],
        [83.39153289794928, 17.794031143188477],
        [83.39153289794928, 17.79375076293951],
        [83.39125061035168, 17.79375076293951],
        [83.39125061035168, 17.792921066284123],
        [83.3909759521485, 17.792921066284123],
        [83.3909759521485, 17.792640686035156],
        [83.3906860351562, 17.792640686035156],
        [83.3906860351562, 17.792068481445312],
        [83.39041900634788, 17.792083740234375],
        [83.39041900634788, 17.791250228881836],
        [83.39013671875017, 17.791250228881836],
        [83.39013671875017, 17.79070281982422],
        [83.38986206054693, 17.79070281982422],
        [83.38986206054693, 17.79042053222662],
        [83.38957977294939, 17.79042053222662],
        [83.38957977294939, 17.790140151977653],
        [83.38931274414091, 17.790140151977653],
        [83.38931274414091, 17.789861679077262],
        [83.38903045654297, 17.789861679077262],
        [83.38903045654297, 17.7895832061767],
        [83.38819122314464, 17.7895832061767],
        [83.38819122314464, 17.788749694824332],
        [83.38791656494146, 17.788749694824332],
        [83.38791656494146, 17.788471221923942],
        [83.38764190673822, 17.788473129272518],
        [83.38764190673822, 17.788194656372127],
        [83.38735961914085, 17.788194656372127],
        [83.38735961914085, 17.787916183471793],
        [83.38707733154314, 17.78791809082037],
        [83.38707733154314, 17.78763961791998],
        [83.3868026733399, 17.78763961791998],
        [83.3868026733399, 17.786809921264762],
        [83.38652801513689, 17.786809921264762],
        [83.38652801513689, 17.78626060485834],
        [83.38625335693388, 17.78626060485834],
        [83.38625335693388, 17.785970687866268],
        [83.38597106933594, 17.785972595214844],
        [83.38597106933594, 17.785415649414062],
        [83.3856964111331, 17.785419464111442],
        [83.3856964111331, 17.784860610961914],
        [83.38540649414062, 17.784860610961914],
        [83.38540649414062, 17.784309387207088],
        [83.38513946533214, 17.784309387207088],
        [83.38513946533214, 17.78375053405773],
        [83.38485717773443, 17.78375053405773],
        [83.38485717773443, 17.78236007690441],
        [83.38513946533214, 17.782361984252987],
        [83.38513946533214, 17.78125],
        [83.38540649414062, 17.78125],
        [83.38540649414062, 17.78096961975109],
        [83.3856964111331, 17.780971527099666],
        [83.3856964111331, 17.780138015747127],
        [83.38597106933594, 17.780138015747127],
        [83.38597106933594, 17.779027938842773],
        [83.3856964111331, 17.77902984619152],
        [83.3856964111331, 17.778472900390568],
        [83.38541412353516, 17.778472900390568],
        [83.38541412353516, 17.777915954589787],
        [83.38513946533214, 17.777919769287166],
        [83.38513946533214, 17.7776393890382],
        [83.38485717773443, 17.7776393890382],
        [83.38485717773443, 17.777362823486385],
        [83.3845825195312, 17.777362823486385],
        [83.3845825195312, 17.77709007263178],
        [83.38403320312517, 17.77709007263178],
        [83.38403320312517, 17.776809692382812],
        [83.3837509155274, 17.776809692382812],
        [83.3837509155274, 17.776529312133846],
        [83.38346862792986, 17.776529312133846],
        [83.38346862792986, 17.776250839233455],
        [83.38318634033209, 17.776250839233455],
        [83.38318634033209, 17.775968551635742],
        [83.38263702392607, 17.775972366333065],
        [83.38263702392607, 17.775693893432674],
        [83.38208007812528, 17.775693893432674],
        [83.38208007812528, 17.775417327880916],
        [83.3815307617187, 17.775419235229492],
        [83.3815307617187, 17.7751407623291],
        [83.38096618652338, 17.7751407623291],
        [83.38096618652338, 17.774860382080135],
        [83.38069152832054, 17.774860382080135],
        [83.38069152832054, 17.774578094482365],
        [83.38014221191435, 17.77458381652832],
        [83.38014221191435, 17.77430534362793],
        [83.37986755371094, 17.774322509765625],
        [83.37986755371094, 17.77403068542492],
        [83.3795928955081, 17.77403068542492],
        [83.3795928955081, 17.77375030517578],
        [83.37902832031278, 17.77375030517578],
        [83.37902832031278, 17.773469924926815],
        [83.37875366210943, 17.77347183227539],
        [83.37875366210943, 17.773195266723576],
        [83.37847137451183, 17.773199081420955],
        [83.37847137451183, 17.772920608520565],
        [83.37790679931635, 17.772920608520565],
        [83.37790679931635, 17.772640228271598],
        [83.37763977050804, 17.772640228271598],
        [83.37763977050804, 17.77235984802246],
        [83.37735748291033, 17.772361755371207],
        [83.37735748291033, 17.772083282470646],
        [83.37680816650408, 17.772083282470646],
        [83.37680816650408, 17.771804809570256],
        [83.37652587890653, 17.771804809570256],
        [83.37652587890653, 17.771526336669865],
        [83.37596893310575, 17.771530151367244],
        [83.37596893310575, 17.771249771118278],
        [83.37568664550781, 17.771249771118278],
        [83.37568664550781, 17.770959854125977],
        [83.37541961669933, 17.770971298217887],
        [83.37541961669933, 17.770696640014705],
        [83.37486267089838, 17.770696640014705],
        [83.37486267089838, 17.770414352416935],
        [83.37458038330101, 17.77042007446289],
        [83.37458038330101, 17.770139694213924],
        [83.37430572509783, 17.770139694213924],
        [83.37430572509783, 17.769861221313533],
        [83.37374114990251, 17.769861221313533],
        [83.37374114990251, 17.769580841064567],
        [83.37347412109403, 17.769582748413143],
        [83.37347412109403, 17.769304275512752],
        [83.37291717529325, 17.769309997558707],
        [83.37291717529325, 17.76902961730957],
        [83.3723602294923, 17.76902961730957],
        [83.3723602294923, 17.76875114440918],
        [83.37180328369135, 17.76875114440918],
        [83.37180328369135, 17.768470764160213],
        [83.37152862548851, 17.76847267150879],
        [83.37152862548851, 17.7681941986084],
        [83.37097167968756, 17.7681941986084],
        [83.37097167968756, 17.76791763305664],
        [83.37040710449224, 17.767919540405387],
        [83.37040710449224, 17.767641067504996],
        [83.36985778808622, 17.767641067504996],
        [83.36985778808622, 17.76736068725586],
        [83.3693084716798, 17.76736068725586],
        [83.3693084716798, 17.767080307006893],
        [83.36875152587885, 17.76708221435547],
        [83.36875152587885, 17.766805648803654],
        [83.36818695068354, 17.766809463501033],
        [83.36818695068354, 17.76653289794922],
        [83.36763763427751, 17.76653289794922],
        [83.36763763427751, 17.766250610351676],
        [83.3673629760745, 17.766250610351676],
        [83.3673629760745, 17.76597023010254],
        [83.36680603027372, 17.765972137451286],
        [83.36680603027372, 17.765693664550724],
        [83.36624908447294, 17.765693664550724],
        [83.36624908447294, 17.765417098999137],
        [83.36569213867199, 17.765422821044865],
        [83.36569213867199, 17.76515007019043],
        [83.36458587646501, 17.76515007019043],
        [83.36458587646501, 17.764860153198356],
        [83.36402893066423, 17.764860153198356],
        [83.36402893066423, 17.76458358764654],
        [83.36374664306669, 17.76458358764654],
        [83.36374664306669, 17.76430511474615],
        [83.36347198486328, 17.76431274414074],
        [83.36347198486328, 17.76403045654297],
        [83.36319732666044, 17.76403045654297],
        [83.36319732666044, 17.763750076294002],
        [83.36264038085949, 17.763750076294002],
        [83.36264038085949, 17.76347160339361],
        [83.36235809326178, 17.76347160339361],
        [83.36235809326178, 17.763195037841797],
        [83.362075805664, 17.763202667236385],
        [83.362075805664, 17.762920379638786],
        [83.36152648925798, 17.762920379638786],
        [83.36152648925798, 17.762641906738224],
        [83.36125183105474, 17.762641906738224],
        [83.36125183105474, 17.762361526489258],
        [83.3609695434572, 17.762361526489258],
        [83.3609695434572, 17.762083053588867],
        [83.36068725585966, 17.76209259033203],
        [83.36068725585966, 17.761808395385856],
        [83.36042022705078, 17.761808395385856],
        [83.36042022705078, 17.761529922485465],
        [83.35986328125006, 17.761529922485465],
        [83.35986328125006, 17.761249542236328],
        [83.35901641845714, 17.761249542236328],
        [83.35901641845714, 17.76096916198736],
        [83.35874938964866, 17.760972976684513],
        [83.35874938964866, 17.760694503784123],
        [83.35819244384771, 17.760694503784123],
        [83.35819244384771, 17.760417938232536],
        [83.3579177856447, 17.76041984558111],
        [83.3579177856447, 17.760139465332145],
        [83.35763549804716, 17.760139465332145],
        [83.35763549804716, 17.759860992431754],
        [83.35708618164091, 17.759860992431754],
        [83.35708618164091, 17.759580612182617],
        [83.35680389404297, 17.759582519531364],
        [83.35680389404297, 17.759307861328182],
        [83.35652923583996, 17.759309768676758],
        [83.35652923583996, 17.759019851684684],
        [83.35624694824224, 17.759025573730412],
        [83.35624694824224, 17.7587509155274],
        [83.355972290039, 17.7587509155274],
        [83.355972290039, 17.758470535278434],
        [83.35569763183616, 17.75847244262701],
        [83.35569763183616, 17.75819396972662],
        [83.35541534423845, 17.75819396972662],
        [83.35541534423845, 17.75791549682623],
        [83.35514068603521, 17.757921218872013],
        [83.35514068603521, 17.757640838623047],
        [83.35485839843767, 17.757640838623047],
        [83.35485839843767, 17.75736045837408],
        [83.3545761108399, 17.75736045837408],
        [83.3545761108399, 17.757080078125114],
        [83.35430908203125, 17.7570858001709],
        [83.35430908203125, 17.755971908569336],
        [83.35402679443388, 17.755971908569336],
        [83.35402679443388, 17.755693435668945],
        [83.35375213623047, 17.755701065063477],
        [83.35375213623047, 17.75542068481451],
        [83.3534698486331, 17.75542068481451],
        [83.3534698486331, 17.754859924316406],
        [83.35319519042974, 17.754861831665153],
        [83.35319519042974, 17.7543048858642],
        [83.35291290283214, 17.7543048858642],
        [83.35291290283214, 17.754028320312614],
        [83.35263824462896, 17.75403022766119],
        [83.35263824462896, 17.753469467163086],
        [83.35236358642595, 17.753471374511832],
        [83.35236358642595, 17.753194808960018],
        [83.35208129882835, 17.753194808960018],
        [83.35208129882835, 17.75263977050787],
        [83.35180664062517, 17.75263977050787],
        [83.35180664062517, 17.752359390258903],
        [83.3515243530274, 17.75236129760748],
        [83.3515243530274, 17.752082824707088],
        [83.35124969482439, 17.752082824707088],
        [83.35124969482439, 17.75180625915533],
        [83.35098266601591, 17.751810073852653],
        [83.35098266601591, 17.751529693603516],
        [83.35069274902344, 17.751529693603516],
        [83.35069274902344, 17.751251220703125],
        [83.3504180908206, 17.751251220703125],
        [83.3504180908206, 17.750972747802734],
        [83.34986114501964, 17.750972747802734],
        [83.34986114501964, 17.750694274902344],
        [83.34902954101585, 17.750694274902344],
        [83.34902954101585, 17.750415802001953],
        [83.34929656982416, 17.750419616699332],
        [83.34929656982416, 17.749860763549805],
        [83.34958648681646, 17.749860763549805],
        [83.34958648681646, 17.749578475952262],
        [83.34986114501964, 17.74958419799799],
        [83.34986114501964, 17.7493057250976],
        [83.3504180908206, 17.74930953979498],
        [83.3504180908206, 17.74875068664562],
        [83.35069274902344, 17.74875068664562],
        [83.35069274902344, 17.748189926147518],
        [83.35097503662138, 17.74819374084467],
        [83.35097503662138, 17.747640609741268],
        [83.35069274902344, 17.747640609741268],
        [83.35069274902344, 17.747079849243164],
        [83.3504180908206, 17.747083663940487],
        [83.3504180908206, 17.746528625488338],
        [83.35013580322271, 17.746530532836914],
        [83.35013580322271, 17.746250152587947],
        [83.34986114501964, 17.746250152587947],
        [83.34986114501964, 17.74596977233898],
        [83.34958648681646, 17.745971679687557],
        [83.34958648681646, 17.745695114135742],
        [83.34929656982416, 17.745700836181697],
        [83.34929656982416, 17.74542045593273],
        [83.34902954101585, 17.74542045593273],
        [83.34902954101585, 17.745140075683594],
        [83.34874725341814, 17.745140075683594],
        [83.34874725341814, 17.744859695434627],
        [83.34735870361357, 17.744861602783203],
        [83.34735870361357, 17.744583129882812],
        [83.34652709960943, 17.744583129882812],
        [83.34652709960943, 17.744304656982422],
        [83.3462524414062, 17.744304656982422],
        [83.3462524414062, 17.743749618530273],
        [83.34597015380882, 17.743749618530273],
        [83.34597015380882, 17.743190765380916],
        [83.34569549560564, 17.743194580078068],
        [83.34569549560564, 17.74263954162609],
        [83.34541320800787, 17.74263954162609],
        [83.34541320800787, 17.7423610687257],
        [83.34513854980486, 17.7423610687257],
        [83.34513854980486, 17.741809844970703],
        [83.34485626220732, 17.741809844970703],
        [83.34485626220732, 17.741250991821346],
        [83.3445816040039, 17.741250991821346],
        [83.3445816040039, 17.740690231323242],
        [83.34430694580107, 17.74069213867199],
        [83.34430694580107, 17.740139007568416],
        [83.3440170288086, 17.740140914916992],
        [83.3440170288086, 17.73958015441906],
        [83.34375000000011, 17.739582061767635],
        [83.34375000000011, 17.739027023315487],
        [83.34348297119146, 17.739032745361385],
        [83.34348297119146, 17.738470077514705],
        [83.34319305419916, 17.738473892211857],
        [83.34319305419916, 17.737916946411133],
        [83.34291839599632, 17.73792266845703],
        [83.34291839599632, 17.737361907959098],
        [83.34263610839861, 17.737361907959098],
        [83.34263610839861, 17.73541641235363],
        [83.34291839599632, 17.73542022705078],
        [83.34291839599632, 17.73389053344738],
        [83.34291839599632, 17.73347282409668],
        [83.34319305419916, 17.73347282409668],
        [83.34319305419916, 17.73263931274414],
        [83.34348297119146, 17.732641220092887],
        [83.34348297119146, 17.731531143188533],
        [83.34375762939464, 17.731531143188533],
        [83.34375762939464, 17.731250762939567],
        [83.34402465820312, 17.731250762939567],
        [83.3440170288086, 17.73042106628418],
        [83.34430694580107, 17.73042106628418],
        [83.34430694580107, 17.729860305786246],
        [83.3445816040039, 17.729860305786246],
        [83.3445816040039, 17.72957992553711],
        [83.34513854980486, 17.729583740234432],
        [83.34513854980486, 17.729303359985465],
        [83.34570312500017, 17.729310989379826],
        [83.34570312500017, 17.728200912475643],
        [83.34541320800787, 17.728200912475643],
        [83.34541320800787, 17.727359771728572],
        [83.34513854980486, 17.72736167907715],
        [83.34513854980486, 17.72652816772461],
        [83.34485626220732, 17.726530075073356],
        [83.34485626220732, 17.725692749023438],
        [83.3445816040039, 17.725694656372013],
        [83.3445816040039, 17.725139617920036],
        [83.34430694580107, 17.725139617920036],
        [83.34430694580107, 17.724861145019645],
        [83.34375000000011, 17.724861145019645],
        [83.34375000000011, 17.724582672119254],
        [83.34319305419916, 17.724582672119254],
        [83.34319305419916, 17.724027633667106],
        [83.34291839599632, 17.724029541015682],
        [83.34291839599632, 17.72375106811529],
        [83.34263610839861, 17.72375106811529],
        [83.34263610839861, 17.723470687866325],
        [83.34208679199236, 17.7234725952149],
        [83.34208679199236, 17.72319412231451],
        [83.34180450439482, 17.72319412231451],
        [83.34180450439482, 17.722917556762752],
        [83.34124755859403, 17.722917556762752],
        [83.34124755859403, 17.72263908386236],
        [83.34069824218761, 17.72263908386236],
        [83.34069824218761, 17.72236061096197],
        [83.3404159545899, 17.72236061096197],
        [83.3404159545899, 17.7220783233642],
        [83.33985900878912, 17.722084045410156],
        [83.33985900878912, 17.721805572509766],
        [83.33930206298851, 17.721809387207145],
        [83.33930206298851, 17.721530914306754],
        [83.33875274658232, 17.721530914306754],
        [83.33875274658232, 17.721250534057617],
        [83.33850097656256, 17.721250534057617],
        [83.33819580078153, 17.721250534057617],
        [83.33819580078153, 17.720968246460075],
        [83.3373641967774, 17.720972061157227],
        [83.3373641967774, 17.720693588256836],
        [83.33680725097662, 17.720693588256836],
        [83.33680725097662, 17.720418930053825],
        [83.33625030517601, 17.7204208374024],
        [83.33625030517601, 17.720140457153434],
        [83.33569335937506, 17.720140457153434],
        [83.33569335937506, 17.719850540161133],
        [83.3351364135745, 17.719861984253043],
        [83.3351364135745, 17.719583511352482],
        [83.33458709716825, 17.719583511352482],
        [83.33458709716825, 17.719306945800895],
        [83.33374786376959, 17.719310760498047],
        [83.33374786376959, 17.71903038024908],
        [83.33319091796898, 17.71903038024908],
        [83.33319091796898, 17.718748092651367],
        [83.33264160156256, 17.718748092651367],
        [83.33264160156256, 17.718469619750977],
        [83.332084655762, 17.718471527099723],
        [83.332084655762, 17.71819686889654],
        [83.33152770996122, 17.718200683593693],
        [83.33152770996122, 17.717920303344727],
        [83.33097076416027, 17.717920303344727],
        [83.33097076416027, 17.71763992309576],
        [83.33040618896496, 17.71763992309576],
        [83.33040618896496, 17.717359542846793],
        [83.3298645019533, 17.71736145019537],
        [83.3298645019533, 17.71708297729498],
        [83.32930755615251, 17.71708297729498],
        [83.32930755615251, 17.716804504394588],
        [83.3287582397461, 17.716810226440543],
        [83.3287582397461, 17.716529846191406],
        [83.32818603515625, 17.716529846191406],
        [83.32818603515625, 17.71624946594244],
        [83.32763671875006, 17.71624946594244],
        [83.32763671875006, 17.71597099304205],
        [83.32707977294928, 17.715972900390625],
        [83.32707977294928, 17.715694427490234],
        [83.32653045654303, 17.715694427490234],
        [83.32653045654303, 17.715414047241268],
        [83.32596588134771, 17.715419769287223],
        [83.32596588134771, 17.715139389038086],
        [83.32541656494169, 17.715139389038086],
        [83.32541656494169, 17.714860916137695],
        [83.32485961914091, 17.714860916137695],
        [83.32485961914091, 17.71458053588873],
        [83.32430267333996, 17.714582443237305],
        [83.32430267333996, 17.71430587768549],
        [83.32375335693376, 17.71430969238287],
        [83.32375335693376, 17.714040756225643],
        [83.32319641113298, 17.714040756225643],
        [83.32319641113298, 17.713750839233512],
        [83.32290649414068, 17.713750839233512],
        [83.32290649414068, 17.713470458984375],
        [83.32236480712919, 17.71347236633312],
        [83.32236480712919, 17.71319389343256],
        [83.32180786132841, 17.71319389343256],
        [83.32180786132841, 17.712917327880973],
        [83.32125091552746, 17.712921142578125],
        [83.32125091552746, 17.71264076232916],
        [83.32096862792974, 17.71264076232916],
        [83.32096862792974, 17.712360382080192],
        [83.32041931152366, 17.712360382080192],
        [83.32041931152366, 17.7120819091798],
        [83.31986236572271, 17.712083816528377],
        [83.31986236572271, 17.711805343627987],
        [83.31931304931669, 17.71181106567377],
        [83.31931304931669, 17.711530685424805],
        [83.31903076171875, 17.711530685424805],
        [83.31903076171875, 17.711250305175838],
        [83.31846618652344, 17.711250305175838],
        [83.31846618652344, 17.710971832275447],
        [83.31791687011724, 17.710971832275447],
        [83.31791687011724, 17.710695266723633],
        [83.31735992431663, 17.71070289611822],
        [83.31735992431663, 17.710422515869197],
        [83.31709289550798, 17.710422515869197],
        [83.31709289550798, 17.710140228271484],
        [83.31652832031267, 17.710140228271484],
        [83.31652832031267, 17.709861755371094],
        [83.31625366210966, 17.709861755371094],
        [83.31625366210966, 17.709583282470703],
        [83.31597137451172, 17.709583282470703],
        [83.31597137451172, 17.709304809570312],
        [83.31541442871094, 17.709304809570312],
        [83.31541442871094, 17.709028244018555],
        [83.31513977050793, 17.7090301513673],
        [83.31513977050793, 17.708749771118164],
        [83.31485748291021, 17.708749771118164],
        [83.31485748291021, 17.708467483520565],
        [83.31458282470697, 17.708471298217773],
        [83.31458282470697, 17.70819473266596],
        [83.31430816650413, 17.70819473266596],
        [83.31430816650413, 17.707916259765568],
        [83.31402587890642, 17.707916259765568],
        [83.31402587890642, 17.70763969421398],
        [83.31346893310564, 17.70763969421398],
        [83.31346893310564, 17.70736122131359],
        [83.31318664550787, 17.70736122131359],
        [83.31318664550787, 17.707080841064453],
        [83.31291961669922, 17.7070827484132],
        [83.31291961669922, 17.706806182861442],
        [83.31263732910185, 17.706808090210018],
        [83.31263732910185, 17.706529617309627],
        [83.31236267089844, 17.706529617309627],
        [83.31236267089844, 17.706251144409237],
        [83.31208038330107, 17.706251144409237],
        [83.31208038330107, 17.70597076416027],
        [83.31153106689447, 17.705972671508846],
        [83.31153106689447, 17.705694198608455],
        [83.31124877929693, 17.705694198608455],
        [83.31124877929693, 17.705417633056697],
        [83.31096649169916, 17.705419540405273],
        [83.31096649169916, 17.705141067504883],
        [83.31069183349632, 17.705141067504883],
        [83.31069183349632, 17.70485115051281],
        [83.31014251709013, 17.704860687255916],
        [83.31014251709013, 17.7045841217041],
        [83.30986022949236, 17.7045841217041],
        [83.30986022949236, 17.704307556152344],
        [83.30959320068388, 17.70430946350109],
        [83.30959320068388, 17.7040309906007],
        [83.30902862548857, 17.7040309906007],
        [83.30902862548857, 17.703750610351562],
        [83.30874633789068, 17.703750610351562],
        [83.30874633789068, 17.703470230102596],
        [83.30847167968761, 17.703472137451172],
        [83.30847167968761, 17.70319366455078],
        [83.30819702148443, 17.70319366455078],
        [83.30819702148443, 17.70291519165039],
        [83.30764770507818, 17.702920913696346],
        [83.30764770507818, 17.70264053344738],
        [83.30735778808611, 17.70264053344738],
        [83.30735778808611, 17.702360153198242],
        [83.30708312988287, 17.70236206054699],
        [83.30708312988287, 17.702083587646428],
        [83.30652618408232, 17.702083587646428],
        [83.30652618408232, 17.70180320739746],
        [83.3062515258789, 17.701810836791992],
        [83.3062515258789, 17.70125007629406],
        [83.30596923828153, 17.70125007629406],
        [83.30596923828153, 17.700420379638672],
        [83.3056869506836, 17.700420379638672],
        [83.3056869506836, 17.698192596435604],
        [83.30596923828153, 17.69819450378418],
        [83.30596923828153, 17.69791603088379],
        [83.3062515258789, 17.69791984558117],
        [83.3062515258789, 17.697362899780217],
        [83.3051376342774, 17.697362899780217],
        [83.3051376342774, 17.69763946533203],
        [83.30486297607416, 17.69763946533203],
        [83.30486297607416, 17.69791984558117],
        [83.30458068847679, 17.69791603088379],
        [83.30458068847679, 17.69819450378418],
        [83.30319213867188, 17.69819450378418],
        [83.30319213867188, 17.69791603088379],
        [83.30264282226568, 17.69791984558117],
        [83.30264282226568, 17.69763946533203],
        [83.30236053466808, 17.69763946533203],
        [83.30236053466808, 17.69681167602539],
        [83.30264282226568, 17.69681167602539],
        [83.30264282226568, 17.696529388427848],
        [83.30291748046903, 17.696529388427848],
        [83.30291748046903, 17.696250915527457],
        [83.30319213867188, 17.696250915527457],
        [83.30319213867188, 17.69597053527832],
        [83.30347442626982, 17.695972442627067],
        [83.30347442626982, 17.695693969726506],
        [83.30374908447283, 17.695693969726506],
        [83.30374908447283, 17.69541740417492],
        [83.30403137207037, 17.695419311523494],
        [83.30403137207037, 17.695140838623104],
        [83.30541992187511, 17.695140838623104],
        [83.30541992187511, 17.695419311523494],
        [83.30569458007818, 17.69541740417492],
        [83.30569458007818, 17.695972442627067],
        [83.30596923828153, 17.69597053527832],
        [83.30596923828153, 17.696250915527457],
        [83.3062515258789, 17.696250915527457],
        [83.3062515258789, 17.696529388427848],
        [83.30652618408232, 17.696529388427848],
        [83.30652618408232, 17.69681167602539],
        [83.30680847167986, 17.696805953979435],
        [83.30680847167986, 17.69708251953125],
        [83.30735778808611, 17.69708251953125],
        [83.30735778808611, 17.697362899780217],
        [83.30764007568382, 17.697362899780217],
        [83.30764007568382, 17.69763946533203],
        [83.30819702148443, 17.69763946533203],
        [83.30819702148443, 17.69681167602539],
        [83.30764770507818, 17.69681167602539],
        [83.30764770507818, 17.696529388427848],
        [83.30735778808611, 17.696529388427848],
        [83.30735778808611, 17.696250915527457],
        [83.30708312988287, 17.696250915527457],
        [83.30708312988287, 17.69568061828619],
        [83.30680847167986, 17.695693969726506],
        [83.30680847167986, 17.695140838623104],
        [83.30652618408232, 17.695140838623104],
        [83.30652618408232, 17.691471099853516],
        [83.30652618408232, 17.69124984741211],
        [83.30680847167986, 17.69124984741211],
        [83.30680847167986, 17.68931007385254],
        [83.30652618408232, 17.68931007385254],
        [83.30652618408232, 17.688190460205078],
        [83.30596923828153, 17.688190460205078],
        [83.30596923828153, 17.68931007385254],
        [83.3062515258789, 17.689304351806584],
        [83.3062515258789, 17.69097137451172],
        [83.30596923828153, 17.69097137451172],
        [83.30596923828153, 17.691230773925838],
        [83.30596923828153, 17.69291877746582],
        [83.3056869506836, 17.69291114807129],
        [83.3056869506836, 17.693199157714957],
        [83.30236053466808, 17.693199157714957],
        [83.30236053466808, 17.693750381469783],
        [83.3020858764649, 17.693750381469783],
        [83.3020858764649, 17.69430923461914],
        [83.30179595947277, 17.69430923461914],
        [83.30179595947277, 17.694860458374137],
        [83.30152130126959, 17.694860458374137],
        [83.30152130126959, 17.695419311523494],
        [83.30124664306658, 17.69541740417492],
        [83.30124664306658, 17.695972442627067],
        [83.30014038085938, 17.695972442627067],
        [83.30014038085938, 17.695693969726506],
        [83.299858093262, 17.695693969726506],
        [83.299858093262, 17.69541740417492],
        [83.29930877685558, 17.695419311523494],
        [83.29930877685558, 17.695140838623104],
        [83.29957580566406, 17.695140838623104],
        [83.29957580566406, 17.694860458374137],
        [83.299858093262, 17.694860458374137],
        [83.299858093262, 17.694578170776367],
        [83.30014038085938, 17.694583892822322],
        [83.30014038085938, 17.694305419921932],
        [83.30042266845732, 17.69430923461914],
        [83.30042266845732, 17.69403076171875],
        [83.30069732666033, 17.69403076171875],
        [83.30069732666033, 17.693750381469783],
        [83.30124664306658, 17.693750381469783],
        [83.30124664306658, 17.693468093872013],
        [83.30152893066429, 17.693471908569393],
        [83.30152893066429, 17.693195343017578],
        [83.30179595947277, 17.693199157714957],
        [83.30179595947277, 17.69291114807129],
        [83.30152893066429, 17.69291877746582],
        [83.30152893066429, 17.69263648986822],
        [83.30124664306658, 17.69264030456543],
        [83.30124664306658, 17.692079544067496],
        [83.30097198486334, 17.69208335876465],
        [83.30097198486334, 17.69236183166504],
        [83.30069732666033, 17.692359924316463],
        [83.30069732666033, 17.69264030456543],
        [83.30041503906278, 17.69263648986822],
        [83.30041503906278, 17.69291877746582],
        [83.299858093262, 17.69291114807129],
        [83.299858093262, 17.693199157714957],
        [83.2995834350586, 17.693195343017578],
        [83.2995834350586, 17.693471908569393],
        [83.29902648925787, 17.693468093872013],
        [83.29902648925787, 17.693750381469783],
        [83.29875183105463, 17.693750381469783],
        [83.29875183105463, 17.69403076171875],
        [83.29846191406256, 17.69403076171875],
        [83.29846191406256, 17.69430923461914],
        [83.2976379394533, 17.69430923461914],
        [83.2976379394533, 17.69403076171875],
        [83.29736328125028, 17.69403076171875],
        [83.29736328125028, 17.693750381469783],
        [83.29708099365251, 17.693750381469783],
        [83.29708099365251, 17.693199157714957],
        [83.29736328125028, 17.693199157714957],
        [83.29736328125028, 17.69291114807129],
        [83.2976379394533, 17.69291877746582],
        [83.2976379394533, 17.69263648986822],
        [83.29818725585932, 17.69264030456543],
        [83.29818725585932, 17.692359924316463],
        [83.29875183105463, 17.69236183166504],
        [83.29875183105463, 17.69208335876465],
        [83.29902648925787, 17.69208335876465],
        [83.29902648925787, 17.69180679321289],
        [83.29930877685558, 17.69180679321289],
        [83.29930877685558, 17.691526412963867],
        [83.29957580566406, 17.691530227661246],
        [83.29957580566406, 17.690420150756893],
        [83.29875183105463, 17.690414428710938],
        [83.29875183105463, 17.690696716308707],
        [83.29818725585932, 17.690690994262752],
        [83.29818725585932, 17.69124984741211],
        [83.29792022705084, 17.69124984741211],
        [83.29792022705084, 17.691530227661246],
        [83.2976379394533, 17.691526412963867],
        [83.2976379394533, 17.69180679321289],
        [83.29708099365251, 17.69180679321289],
        [83.29708099365251, 17.691526412963867],
        [83.2968063354495, 17.691530227661246],
        [83.2968063354495, 17.69124984741211],
        [83.29651641845703, 17.69124984741211],
        [83.29651641845703, 17.690969467163143],
        [83.29624938964872, 17.69097137451172],
        [83.29624938964872, 17.690696716308707],
        [83.29597473144537, 17.690696716308707],
        [83.29597473144537, 17.690414428710938],
        [83.29569244384777, 17.690420150756893],
        [83.29569244384777, 17.690139770507926],
        [83.29541778564459, 17.690139770507926],
        [83.29541778564459, 17.689870834350643],
        [83.29514312744158, 17.689870834350643],
        [83.29514312744158, 17.6895809173584],
        [83.29486083984398, 17.689582824707145],
        [83.29486083984398, 17.689304351806584],
        [83.29402923584001, 17.68931007385254],
        [83.29402923584001, 17.689029693603572],
        [83.29319763183622, 17.689029693603572],
        [83.29319763183622, 17.688751220703182],
        [83.29096984863298, 17.688751220703182],
        [83.29096984863298, 17.688470840454215],
        [83.28958129882824, 17.68847274780279],
        [83.28958129882824, 17.6881942749024],
        [83.28791809082048, 17.6881942749024],
        [83.28791809082048, 17.68791580200201],
        [83.28624725341803, 17.68791580200201],
        [83.28624725341803, 17.6881942749024],
        [83.28597259521479, 17.6881942749024],
        [83.28597259521479, 17.68847274780279],
        [83.28430938720703, 17.688470840454215],
        [83.28430175781267, 17.688751220703182],
        [83.28402709960966, 17.688751220703182],
        [83.28402709960966, 17.689029693603572],
        [83.28346252441435, 17.689029693603572],
        [83.28346252441435, 17.68931007385254],
        [83.28235626220697, 17.68931007385254],
        [83.28235626220697, 17.690139770507926],
        [83.28208160400413, 17.690139770507926],
        [83.28208160400413, 17.69236183166504],
        [83.28235626220697, 17.692359924316463],
        [83.28235626220697, 17.693750381469783],
        [83.28208160400413, 17.693750381469783],
        [83.28208160400413, 17.694583892822322],
        [83.28180694580095, 17.694578170776367],
        [83.28180694580095, 17.694860458374137],
        [83.28041839599638, 17.694860458374137],
        [83.28041839599638, 17.694578170776367],
        [83.27986145019543, 17.694578170776367],
        [83.27986145019543, 17.694860458374137],
        [83.27958679199224, 17.694860458374137],
        [83.27958679199224, 17.695419311523494],
        [83.27930450439447, 17.69541740417492],
        [83.27930450439447, 17.695972442627067],
        [83.27902984619163, 17.69597053527832],
        [83.27902984619163, 17.69681167602539],
        [83.27874755859392, 17.69681167602539],
        [83.27874755859392, 17.69931030273449],
        [83.27847290039068, 17.69931030273449],
        [83.27847290039068, 17.700139999389705],
        [83.27819824218767, 17.700139999389705],
        [83.27819824218767, 17.700420379638672],
        [83.27791595459013, 17.700420379638672],
        [83.27791595459013, 17.70070075988764],
        [83.27764129638672, 17.700693130493278],
        [83.27764129638672, 17.70097160339367],
        [83.27735900878935, 17.700969696044922],
        [83.27735900878935, 17.70125007629406],
        [83.2768020629884, 17.70125007629406],
        [83.2768020629884, 17.702083587646428],
        [83.27652740478521, 17.702083587646428],
        [83.27652740478521, 17.70236206054699],
        [83.27625274658197, 17.702360153198242],
        [83.27625274658197, 17.702920913696346],
        [83.2759704589846, 17.70291519165039],
        [83.2759704589846, 17.70319366455078],
        [83.27569580078142, 17.70319366455078],
        [83.27569580078142, 17.703472137451172],
        [83.27540588378929, 17.703470230102596],
        [83.27540588378929, 17.703750610351562],
        [83.27513885498064, 17.703750610351562],
        [83.27513885498064, 17.7040309906007],
        [83.27458190917969, 17.7040309906007],
        [83.27458190917969, 17.70430946350109],
        [83.27430725097685, 17.704307556152344],
        [83.27430725097685, 17.704860687255916],
        [83.27401733398438, 17.70485115051281],
        [83.27401733398438, 17.705141067504883],
        [83.2737503051759, 17.705141067504883],
        [83.2737503051759, 17.705419540405273],
        [83.27347564697271, 17.705417633056697],
        [83.27347564697271, 17.705972671508846],
        [83.27319335937494, 17.70597076416027],
        [83.27319335937494, 17.706529617309627],
        [83.2729187011721, 17.706529617309627],
        [83.2729187011721, 17.7070827484132],
        [83.27263641357439, 17.707080841064453],
        [83.27263641357439, 17.7090301513673],
        [83.2729187011721, 17.709028244018555],
        [83.2729187011721, 17.709304809570312],
        [83.27319335937494, 17.709299087524357],
        [83.27319335937494, 17.71070289611822],
        [83.2729187011721, 17.71070289611822],
        [83.2729187011721, 17.711250305175838],
        [83.27263641357439, 17.711250305175838],
        [83.27263641357439, 17.71181106567377],
        [83.27236175537115, 17.711805343627987],
        [83.27236175537115, 17.712083816528377],
        [83.27208709716814, 17.7120819091798],
        [83.27208709716814, 17.712360382080192],
        [83.27179718017584, 17.712360382080192],
        [83.27179718017584, 17.71264076232916],
        [83.27069091796903, 17.71264076232916],
        [83.27069091796903, 17.712921142578125],
        [83.27014160156244, 17.712921142578125],
        [83.27014160156244, 17.71264076232916],
        [83.26957702636713, 17.71264076232916],
        [83.26957702636713, 17.712360382080192],
        [83.26930236816429, 17.712360382080192],
        [83.26930236816429, 17.7120819091798],
        [83.26902770996111, 17.712083816528377],
        [83.26902770996111, 17.711805343627987],
        [83.26847839355469, 17.71181106567377],
        [83.26847839355469, 17.711530685424805],
        [83.26819610595732, 17.711530685424805],
        [83.26819610595732, 17.711250305175838],
        [83.26790618896484, 17.711250305175838],
        [83.26790618896484, 17.710971832275447],
        [83.26763916015653, 17.710971832275447],
        [83.26763916015653, 17.710695266723633],
        [83.26735687255865, 17.71070289611822],
        [83.26735687255865, 17.710140228271484],
        [83.2668075561524, 17.710140228271484],
        [83.2668075561524, 17.710422515869197],
        [83.26625061035179, 17.710422515869197],
        [83.26625061035179, 17.71070289611822],
        [83.26569366455084, 17.710695266723633],
        [83.26569366455084, 17.710971832275447],
        [83.26541900634783, 17.710971832275447],
        [83.26541900634783, 17.711250305175838],
        [83.26374816894537, 17.711250305175838],
        [83.26374816894537, 17.710971832275447],
        [83.26347351074213, 17.710971832275447],
        [83.26347351074213, 17.709583282470703],
        [83.26374816894537, 17.709583282470703],
        [83.26374816894537, 17.709028244018555],
        [83.26403045654308, 17.7090301513673],
        [83.26403045654308, 17.708749771118164],
        [83.2645797729495, 17.708749771118164],
        [83.2645797729495, 17.708467483520565],
        [83.26486206054688, 17.708471298217773],
        [83.26486206054688, 17.70819473266596],
        [83.26513671875028, 17.70819473266596],
        [83.26513671875028, 17.707916259765568],
        [83.26625061035179, 17.707916259765568],
        [83.26625061035179, 17.705972671508846],
        [83.26597595214861, 17.705972671508846],
        [83.26597595214861, 17.705694198608455],
        [83.2645797729495, 17.705694198608455],
        [83.2645797729495, 17.705417633056697],
        [83.2634658813476, 17.705419540405273],
        [83.2634658813476, 17.705141067504883],
        [83.26319122314476, 17.705141067504883],
        [83.26319122314476, 17.70430946350109],
        [83.2634658813476, 17.70430946350109],
        [83.2634658813476, 17.7040309906007],
        [83.26431274414068, 17.7040309906007],
        [83.26431274414068, 17.70430946350109],
        [83.26541900634783, 17.704307556152344],
        [83.26541900634783, 17.7045841217041],
        [83.26597595214861, 17.7045841217041],
        [83.26597595214861, 17.704860687255916],
        [83.26930236816429, 17.70485115051281],
        [83.26930236816429, 17.705141067504883],
        [83.27179718017584, 17.705141067504883],
        [83.27179718017584, 17.70458030700695],
        [83.27208709716814, 17.7045841217041],
        [83.27208709716814, 17.704307556152344],
        [83.27236175537115, 17.70430946350109],
        [83.27236175537115, 17.70318984985363],
        [83.27263641357439, 17.70319366455078],
        [83.27263641357439, 17.70291519165039],
        [83.2729187011721, 17.702920913696346],
        [83.2729187011721, 17.702360153198242],
        [83.27319335937494, 17.702360153198242],
        [83.27319335937494, 17.702079772949276],
        [83.27347564697271, 17.702083587646428],
        [83.27347564697271, 17.70180320739746],
        [83.2737503051759, 17.701810836791992],
        [83.2737503051759, 17.70125007629406],
        [83.27401733398438, 17.70125007629406],
        [83.27401733398438, 17.700420379638672],
        [83.27430725097685, 17.700420379638672],
        [83.27430725097685, 17.700139999389705],
        [83.27458190917969, 17.700139999389705],
        [83.27458190917969, 17.699581146240348],
        [83.27486419677763, 17.699581146240348],
        [83.27486419677763, 17.699306488037166],
        [83.27513885498064, 17.699302673339957],
        [83.27513885498064, 17.699028015136776],
        [83.27540588378929, 17.69902992248535],
        [83.27540588378929, 17.698749542236385],
        [83.27569580078142, 17.698749542236385],
        [83.27569580078142, 17.698471069335994],
        [83.2759704589846, 17.698471069335994],
        [83.2759704589846, 17.69819450378418],
        [83.27625274658197, 17.69819450378418],
        [83.27625274658197, 17.69791603088379],
        [83.27652740478521, 17.69791984558117],
        [83.27652740478521, 17.69763946533203],
        [83.2768020629884, 17.69763946533203],
        [83.2768020629884, 17.697362899780217],
        [83.27735900878935, 17.697362899780217],
        [83.27735900878935, 17.69708251953125],
        [83.27764129638672, 17.69708251953125],
        [83.27764129638672, 17.6965274810791],
        [83.27791595459013, 17.696529388427848],
        [83.27791595459013, 17.69597053527832],
        [83.27819061279314, 17.695972442627067],
        [83.27819824218767, 17.69541740417492],
        [83.27847290039068, 17.695419311523494],
        [83.27847290039068, 17.694860458374137],
        [83.27874755859392, 17.694860458374137],
        [83.27874755859392, 17.69430923461914],
        [83.27902984619163, 17.69430923461914],
        [83.27902984619163, 17.69403076171875],
        [83.27847290039068, 17.69403076171875],
        [83.27847290039068, 17.69291114807129],
        [83.27874755859392, 17.69291877746582],
        [83.27874755859392, 17.69236183166504],
        [83.27902984619163, 17.69236183166504],
        [83.27902984619163, 17.69180679321289],
        [83.27930450439447, 17.69180679321289],
        [83.27930450439447, 17.691526412963867],
        [83.27958679199224, 17.691530227661246],
        [83.27958679199224, 17.69124984741211],
        [83.27986145019543, 17.69124984741211],
        [83.27986145019543, 17.690969467163143],
        [83.2801361083985, 17.69097137451172],
        [83.2801361083985, 17.690696716308707],
        [83.28069305419922, 17.690696716308707],
        [83.28069305419922, 17.690414428710938],
        [83.28098297119169, 17.690420150756893],
        [83.28098297119169, 17.690139770507926],
        [83.28125000000017, 17.690139770507926],
        [83.28125000000017, 17.6895809173584],
        [83.28152465820318, 17.689582824707145],
        [83.28152465820318, 17.688749313354606],
        [83.28180694580095, 17.688751220703182],
        [83.28180694580095, 17.688190460205078],
        [83.28208160400413, 17.6881942749024],
        [83.28208160400413, 17.68791580200201],
        [83.28235626220697, 17.68791961669922],
        [83.28235626220697, 17.687641143798828],
        [83.28346252441435, 17.687641143798828],
        [83.28346252441435, 17.68736076354986],
        [83.28485870361345, 17.68736076354986],
        [83.28485870361345, 17.687641143798828],
        [83.28569793701195, 17.687641143798828],
        [83.28569793701195, 17.68736076354986],
        [83.28597259521479, 17.68736076354986],
        [83.28597259521479, 17.687080383300895],
        [83.2881927490235, 17.687080383300895],
        [83.2881927490235, 17.68736076354986],
        [83.29041290283203, 17.68736076354986],
        [83.29041290283203, 17.687641143798828],
        [83.29125213623053, 17.687641143798828],
        [83.29125213623053, 17.68791961669922],
        [83.29235839843756, 17.68791961669922],
        [83.29235839843756, 17.687641143798828],
        [83.29319763183622, 17.687641143798828],
        [83.29319763183622, 17.68736076354986],
        [83.29347229003906, 17.68736076354986],
        [83.29347229003906, 17.687080383300895],
        [83.29374694824247, 17.68708229064947],
        [83.29374694824247, 17.686805725097656],
        [83.29402923584001, 17.686809539795036],
        [83.29402923584001, 17.686531066894645],
        [83.2942962646485, 17.686531066894645],
        [83.2942962646485, 17.68597030639654],
        [83.2945861816408, 17.685972213745117],
        [83.2945861816408, 17.685693740844727],
        [83.29541778564459, 17.685693740844727],
        [83.29541778564459, 17.685972213745117],
        [83.29596710205084, 17.68597030639654],
        [83.29596710205084, 17.686250686645508],
        [83.29624938964872, 17.686250686645508],
        [83.29624938964872, 17.686531066894645],
        [83.29708099365251, 17.686531066894645],
        [83.29708099365251, 17.686809539795036],
        [83.2976379394533, 17.686809539795036],
        [83.2976379394533, 17.686531066894645],
        [83.29804229736328, 17.6865291595459],
        [83.29818725585932, 17.686531066894645],
        [83.29818725585932, 17.685422897338867],
        [83.2968063354495, 17.685422897338867],
        [83.2968063354495, 17.685140609741325],
        [83.29572296142595, 17.685140609741325],
        [83.29569244384777, 17.684860229492188],
        [83.29547882080107, 17.684860229492188],
        [83.2945861816408, 17.684860229492188],
        [83.2945861816408, 17.683940887451286],
        [83.2945861816408, 17.683471679687614],
        [83.29430389404303, 17.683471679687614],
        [83.29430389404303, 17.68236160278326],
        [83.29486083984398, 17.68236160278326],
        [83.29486083984398, 17.68152809143072],
        [83.29514312744158, 17.68152809143072],
        [83.29514312744158, 17.68124961853033],
        [83.29569244384777, 17.68124961853033],
        [83.29569244384777, 17.68097114562994],
        [83.29597473144537, 17.680973052978516],
        [83.29597473144537, 17.679307937622184],
        [83.29569244384777, 17.67930984497076],
        [83.29569244384777, 17.678470611572266],
        [83.29541778564459, 17.678472518921012],
        [83.29541778564459, 17.6751384735108],
        [83.29514312744158, 17.675140380859375],
        [83.29514312744158, 17.674869537353516],
        [83.29486083984398, 17.674869537353516],
        [83.29486083984398, 17.67431068420416],
        [83.2945861816408, 17.67431068420416],
        [83.2945861816408, 17.674030303955192],
        [83.2942962646485, 17.674030303955192],
        [83.2942962646485, 17.673749923706055],
        [83.29402923584001, 17.673749923706055],
        [83.29402923584001, 17.673191070556697],
        [83.29374694824247, 17.67319488525385],
        [83.29374694824247, 17.67291641235346],
        [83.29347229003906, 17.672920227050838],
        [83.29347229003906, 17.672359466552734],
        [83.29291534423834, 17.67236137390148],
        [83.29291534423834, 17.67208290100109],
        [83.29264068603527, 17.67208290100109],
        [83.29264068603527, 17.671806335449332],
        [83.29208374023432, 17.671806335449332],
        [83.29208374023432, 17.671527862548942],
        [83.29180908203148, 17.671529769897518],
        [83.29180908203148, 17.67124938964855],
        [83.29125213623053, 17.67124938964855],
        [83.29125213623053, 17.670972824096737],
        [83.29096984863298, 17.670972824096737],
        [83.29096984863298, 17.670694351196346],
        [83.29041290283203, 17.670694351196346],
        [83.29041290283203, 17.670415878295955],
        [83.29013824462919, 17.670419692993164],
        [83.29013824462919, 17.669860839843807],
        [83.28985595703125, 17.669860839843807],
        [83.28985595703125, 17.669578552246037],
        [83.28930664062506, 17.669584274291992],
        [83.28930664062506, 17.6693058013916],
        [83.28901672363293, 17.66930961608898],
        [83.28901672363293, 17.669029235839844],
        [83.28874969482445, 17.669029235839844],
        [83.28874969482445, 17.668741226196346],
        [83.2881927490235, 17.668750762939453],
        [83.2881927490235, 17.668472290039062],
        [83.28806304931652, 17.668472290039062],
        [83.28791809082048, 17.668472290039062],
        [83.28791809082048, 17.668193817138672],
        [83.28763580322294, 17.668193817138672],
        [83.28763580322294, 17.667917251586914],
        [83.28736114501953, 17.66791915893566],
        [83.28736114501953, 17.66764068603527],
        [83.28679656982422, 17.66764068603527],
        [83.28679656982422, 17.667360305786133],
        [83.28652191162138, 17.667360305786133],
        [83.28652191162138, 17.667079925537166],
        [83.28624725341803, 17.667083740234318],
        [83.28624725341803, 17.666805267333928],
        [83.28597259521479, 17.666809082031307],
        [83.28597259521479, 17.666530609130916],
        [83.28542327880876, 17.666530609130916],
        [83.28542327880876, 17.66625022888195],
        [83.285140991211, 17.66625022888195],
        [83.285140991211, 17.66570091247553],
        [83.28457641601568, 17.66570091247553],
        [83.28457641601568, 17.665420532226562],
        [83.28430175781267, 17.665420532226562],
        [83.28430175781267, 17.665140151977596],
        [83.28402709960966, 17.665140151977596],
        [83.28402709960966, 17.66485977172863],
        [83.28375244140625, 17.664861679077205],
        [83.28375244140625, 17.664583206176815],
        [83.28347015380888, 17.664583206176815],
        [83.28347015380888, 17.663749694824276],
        [83.28375244140625, 17.663749694824276],
        [83.28375244140625, 17.663471221923885],
        [83.28402709960966, 17.66347312927246],
        [83.28402709960966, 17.663196563720703],
        [83.28375244140625, 17.663196563720703],
        [83.28375244140625, 17.662639617919922],
        [83.28346252441435, 17.662639617919922],
        [83.28346252441435, 17.661809921264705],
        [83.28320312500006, 17.661809921264705],
        [83.28320312500006, 17.661251068115348],
        [83.28291320800793, 17.661251068115348],
        [83.28291320800793, 17.660690307617244],
        [83.28263854980474, 17.66069221496582],
        [83.28263854980474, 17.66013908386242],
        [83.28235626220697, 17.66014289855957],
        [83.28235626220697, 17.659860610962028],
        [83.28125000000017, 17.659860610962028],
        [83.28125000000017, 17.65957260131836],
        [83.2801361083985, 17.659582138061637],
        [83.2801361083985, 17.659305572509822],
        [83.27902984619163, 17.65930938720703],
        [83.27902984619163, 17.659032821655217],
        [83.27764129638672, 17.659032821655217],
        [83.27764129638672, 17.658750534057674],
        [83.27735900878935, 17.658750534057674],
        [83.27735900878935, 17.658470153808707],
        [83.27652740478521, 17.658472061157283],
        [83.27652740478521, 17.657917022705135],
        [83.27625274658197, 17.657922744751033],
        [83.27625274658197, 17.65764045715332],
        [83.27569580078142, 17.65764045715332],
        [83.27569580078142, 17.657360076904354],
        [83.27486419677763, 17.657360076904354],
        [83.27486419677763, 17.65708351135254],
        [83.27430725097685, 17.65708351135254],
        [83.27430725097685, 17.65680503845215],
        [83.27347564697271, 17.65680503845215],
        [83.27347564697271, 17.65652847290039],
        [83.2729187011721, 17.656530380249137],
        [83.2729187011721, 17.656251907348746],
        [83.27236175537115, 17.656251907348746],
        [83.27236175537115, 17.65597152709961],
        [83.2718048095706, 17.65597152709961],
        [83.2718048095706, 17.655694961547795],
        [83.27124786376982, 17.655702590942326],
        [83.27124786376982, 17.655420303344783],
        [83.27069091796903, 17.655420303344783],
        [83.27069091796903, 17.655139923095817],
        [83.27014160156244, 17.655139923095817],
        [83.27014160156244, 17.654857635498047],
        [83.26958465576189, 17.654861450195426],
        [83.26958465576189, 17.654582977295036],
        [83.26902770996111, 17.654582977295036],
        [83.26902770996111, 17.654306411743278],
        [83.2687530517581, 17.654308319091854],
        [83.2687530517581, 17.654029846191463],
        [83.26819610595732, 17.654029846191463],
        [83.26819610595732, 17.65376091003418],
        [83.26763916015653, 17.65376091003418],
        [83.26763916015653, 17.65346908569336],
        [83.26708221435558, 17.653472900390682],
        [83.26708221435558, 17.65319442749029],
        [83.26652526855463, 17.65319442749029],
        [83.26652526855463, 17.652917861938533],
        [83.26597595214861, 17.65291976928711],
        [83.26597595214861, 17.652639389038143],
        [83.26541900634783, 17.652639389038143],
        [83.26541900634783, 17.652360916137752],
        [83.26486206054688, 17.652360916137752],
        [83.26486206054688, 17.652080535888786],
        [83.26430511474615, 17.65208244323736],
        [83.26430511474615, 17.65180778503418],
        [83.26374816894537, 17.651809692382926],
        [83.26374816894537, 17.651531219482536],
        [83.26319122314476, 17.651531219482536],
        [83.26319122314476, 17.6512508392334],
        [83.26264190673834, 17.6512508392334],
        [83.26264190673834, 17.650970458984432],
        [83.26180267333984, 17.650972366333008],
        [83.26180267333984, 17.650693893432617],
        [83.261528015137, 17.650699615478572],
        [83.261528015137, 17.650421142578182],
        [83.26125335693365, 17.650421142578182],
        [83.26125335693365, 17.650140762329215],
        [83.26069641113287, 17.650140762329215],
        [83.26069641113287, 17.64903068542486],
        [83.26097106933605, 17.64903068542486],
        [83.26097106933605, 17.648750305175895],
        [83.26125335693365, 17.648750305175895],
        [83.26125335693365, 17.647630691528434],
        [83.26097106933605, 17.647638320922965],
        [83.26097106933605, 17.64736175537115],
        [83.26069641113287, 17.64736175537115],
        [83.26069641113287, 17.64708328247076],
        [83.26013946533226, 17.64708328247076],
        [83.26013946533226, 17.64680480957037],
        [83.25986480712908, 17.64680480957037],
        [83.25986480712908, 17.64597129821783],
        [83.26013946533226, 17.64597129821783],
        [83.26013946533226, 17.645694732666016],
        [83.2604141235351, 17.645694732666016],
        [83.2604141235351, 17.645416259765625],
        [83.26013946533226, 17.645420074463004],
        [83.26013946533226, 17.644029617309684],
        [83.25985717773455, 17.644029617309684],
        [83.25985717773455, 17.643751144409293],
        [83.2593078613283, 17.643751144409293],
        [83.2593078613283, 17.643470764160156],
        [83.25902557373075, 17.643472671508903],
        [83.25902557373075, 17.64319419860834],
        [83.25875091552734, 17.64319419860834],
        [83.25875091552734, 17.642917633056754],
        [83.25791931152355, 17.64291954040533],
        [83.25791931152355, 17.642639160156364],
        [83.2573623657226, 17.642639160156364],
        [83.2573623657226, 17.642360687255973],
        [83.25680541992205, 17.642360687255973],
        [83.25680541992205, 17.64208412170416],
        [83.2565307617188, 17.64208412170416],
        [83.2565307617188, 17.641805648803768],
        [83.25569152832048, 17.641822814941463],
        [83.25569152832048, 17.64152908325201],
        [83.25485992431669, 17.64152908325201],
        [83.25485992431669, 17.64125061035162],
        [83.25458526611334, 17.64125061035162],
        [83.25458526611334, 17.64097213745123],
        [83.25402832031256, 17.64097213745123],
        [83.25402832031256, 17.640693664550838],
        [83.25375366210955, 17.640693664550838],
        [83.25375366210955, 17.64041709899908],
        [83.25319671630876, 17.640420913696403],
        [83.25319671630876, 17.640140533447266],
        [83.25263977050798, 17.640140533447266],
        [83.25263977050798, 17.639858245849723],
        [83.25208282470703, 17.639862060546875],
        [83.25208282470703, 17.639583587646484],
        [83.25180816650419, 17.639583587646484],
        [83.25180816650419, 17.639305114746094],
        [83.25068664550776, 17.63931083679205],
        [83.25068664550776, 17.639030456543082],
        [83.25013732910173, 17.639030456543082],
        [83.25013732910173, 17.638759613037053],
        [83.24958038330095, 17.638759613037053],
        [83.24958038330095, 17.63846969604498],
        [83.24903106689453, 17.638471603393555],
        [83.24903106689453, 17.638196945190543],
        [83.24819183349621, 17.638200759887695],
        [83.24819183349621, 17.63792037963873],
        [83.24764251708979, 17.63792037963873],
        [83.24764251708979, 17.637639999389762],
        [83.24735260009771, 17.637639999389762],
        [83.24735260009771, 17.637359619140625],
        [83.24680328369146, 17.63736152648937],
        [83.24680328369146, 17.63708305358898],
        [83.24624633789091, 17.63708305358898],
        [83.24624633789091, 17.63680458068842],
        [83.2459716796875, 17.636810302734375],
        [83.2459716796875, 17.63652992248541],
        [83.24540710449219, 17.63652992248541],
        [83.24540710449219, 17.636249542236442],
        [83.244857788086, 17.636249542236442],
        [83.244857788086, 17.63597106933605],
        [83.24458312988276, 17.635974884033203],
        [83.24458312988276, 17.635694503784237],
        [83.2440261840822, 17.635694503784237],
        [83.2440261840822, 17.63541412353527],
        [83.242919921875, 17.635419845581055],
        [83.242919921875, 17.635139465332088],
        [83.24263763427763, 17.635139465332088],
        [83.24263763427763, 17.634860992431697],
        [83.24236297607422, 17.634860992431697],
        [83.24236297607422, 17.63458061218273],
        [83.2418060302735, 17.634582519531307],
        [83.2418060302735, 17.634304046630916],
        [83.24124908447271, 17.63430976867687],
        [83.24124908447271, 17.634029388427734],
        [83.24096679687494, 17.634029388427734],
        [83.24096679687494, 17.633750915527344],
        [83.2406921386721, 17.633750915527344],
        [83.2406921386721, 17.633470535278377],
        [83.24013519287115, 17.633472442626953],
        [83.24013519287115, 17.633192062377987],
        [83.23986053466814, 17.633192062377987],
        [83.23986053466814, 17.632638931274414],
        [83.23958587646513, 17.632638931274414],
        [83.23958587646513, 17.632360458374023],
        [83.23929595947266, 17.632360458374023],
        [83.23929595947266, 17.632080078125057],
        [83.23874664306646, 17.63208389282221],
        [83.23874664306646, 17.631805419921818],
        [83.2384719848634, 17.631811141967773],
        [83.2384719848634, 17.631532669067383],
        [83.23819732666021, 17.631532669067383],
        [83.23819732666021, 17.63125038146984],
        [83.23791503906244, 17.63125038146984],
        [83.23791503906244, 17.63097190856945],
        [83.2376403808596, 17.63097190856945],
        [83.2376403808596, 17.630695343017635],
        [83.23735809326189, 17.63070106506342],
        [83.23735809326189, 17.63042259216303],
        [83.23707580566412, 17.63042259216303],
        [83.23707580566412, 17.630140304565487],
        [83.23680877685564, 17.630140304565487],
        [83.23680877685564, 17.629861831665096],
        [83.23625183105469, 17.629861831665096],
        [83.23625183105469, 17.629583358764705],
        [83.23596954345732, 17.62959289550787],
        [83.23596954345732, 17.62929916381836],
        [83.23569488525396, 17.629304885864315],
        [83.23569488525396, 17.629028320312557],
        [83.23541259765636, 17.629030227661133],
        [83.23541259765636, 17.628467559814396],
        [83.23513793945318, 17.628471374511776],
        [83.23513793945318, 17.62819480895996],
        [83.23486328124994, 17.62819480895996],
        [83.23486328124994, 17.627639770507812],
        [83.23458099365257, 17.627639770507812],
        [83.23458099365257, 17.627357482910213],
        [83.23430633544939, 17.627361297607422],
        [83.23430633544939, 17.626806259155217],
        [83.23401641845726, 17.626821517944336],
        [83.23401641845726, 17.62652969360363],
        [83.23374938964861, 17.62652969360363],
        [83.23374938964861, 17.6259708404541],
        [83.2334747314456, 17.625972747802848],
        [83.2334747314456, 17.625694274902287],
        [83.23319244384766, 17.625694274902287],
        [83.23319244384766, 17.62513923645031],
        [83.23291778564482, 17.625141143798885],
        [83.23291778564482, 17.624309539794922],
        [83.23264312744146, 17.624309539794922],
        [83.23264312744146, 17.62264060974121],
        [83.2323684692384, 17.62264060974121],
        [83.2323684692384, 17.61930274963379],
        [83.23263549804688, 17.619304656982365],
        [83.23263549804688, 17.619028091430778],
        [83.23346710205107, 17.619029998779354],
        [83.23346710205107, 17.618749618530387],
        [83.23374938964861, 17.618749618530387],
        [83.23374938964861, 17.617361068725643],
        [83.23346710205107, 17.617361068725643],
        [83.23346710205107, 17.617082595825252],
        [83.23319244384766, 17.617082595825252],
        [83.23319244384766, 17.616806030273438],
        [83.23291778564482, 17.616811752319393],
        [83.23291778564482, 17.61652946472168],
        [83.23264312744146, 17.61652946472168],
        [83.23264312744146, 17.615688323974553],
        [83.23291778564482, 17.615694046020508],
        [83.23291778564482, 17.615140914917106],
        [83.23307800292974, 17.615140914917106],
        [83.23319244384766, 17.615140914917106],
        [83.23319244384766, 17.614591598510685],
        [83.23346710205107, 17.614591598510685],
        [83.23346710205107, 17.614030838012752],
        [83.23374938964861, 17.614030838012752],
        [83.23374938964861, 17.613750457763786],
        [83.23401641845726, 17.613750457763786],
        [83.23401641845726, 17.613468170166016],
        [83.23430633544939, 17.613471984863395],
        [83.23430633544939, 17.613193511963004],
        [83.23486328124994, 17.613193511963004],
        [83.23486328124994, 17.612918853759822],
        [83.23513793945318, 17.6129207611084],
        [83.23513793945318, 17.612640380859432],
        [83.23596954345732, 17.612640380859432],
        [83.23596954345732, 17.6129207611084],
        [83.2365264892581, 17.612918853759822],
        [83.2365264892581, 17.613193511963004],
        [83.23680877685564, 17.613193511963004],
        [83.23680877685564, 17.613471984863395],
        [83.2376403808596, 17.613468170166016],
        [83.2376403808596, 17.613750457763786],
        [83.23819732666021, 17.613750457763786],
        [83.23819732666021, 17.613468170166016],
        [83.2384719848634, 17.613471984863395],
        [83.2384719848634, 17.612636566162053],
        [83.23819732666021, 17.612640380859432],
        [83.23819732666021, 17.611810684204215],
        [83.2379226684572, 17.611810684204215],
        [83.2379226684572, 17.611530303955078],
        [83.23735809326189, 17.611530303955078],
        [83.23735809326189, 17.61124992370611],
        [83.23680877685564, 17.61124992370611],
        [83.23680877685564, 17.610969543457145],
        [83.23596954345732, 17.610969543457145],
        [83.23596954345732, 17.61124992370611],
        [83.23568725585938, 17.61124992370611],
        [83.23568725585938, 17.610969543457145],
        [83.2354202270509, 17.61097145080572],
        [83.2354202270509, 17.61069679260254],
        [83.23486328124994, 17.61069679260254],
        [83.23486328124994, 17.61041450500494],
        [83.23458099365257, 17.610420227050895],
        [83.23458099365257, 17.610139846801758],
        [83.23430633544939, 17.610139846801758],
        [83.23430633544939, 17.60985946655279],
        [83.23402404785162, 17.609861373901367],
        [83.23402404785162, 17.609582901000977],
        [83.2334747314456, 17.609582901000977],
        [83.2334747314456, 17.609304428100586],
        [83.23319244384766, 17.60931015014654],
        [83.23319244384766, 17.609029769897575],
        [83.23291778564482, 17.609029769897575],
        [83.23291778564482, 17.608749389648438],
        [83.23236083984386, 17.608749389648438],
        [83.23236083984386, 17.60847473144537],
        [83.23208618164068, 17.60847473144537],
        [83.23208618164068, 17.608194351196232],
        [83.23180389404291, 17.608194351196232],
        [83.23180389404291, 17.60791587829584],
        [83.23152923584007, 17.60791969299322],
        [83.23152923584007, 17.60764122009283],
        [83.23124694824236, 17.60764122009283],
        [83.23124694824236, 17.607360839843864],
        [83.23097229003912, 17.607360839843864],
        [83.23097229003912, 17.607080459594727],
        [83.23041534423857, 17.607082366943473],
        [83.23041534423857, 17.60680580139166],
        [83.22985839843778, 17.606809616088867],
        [83.22985839843778, 17.606531143188477],
        [83.22957611083984, 17.606531143188477],
        [83.22957611083984, 17.60625076293951],
        [83.22875213623041, 17.60625076293951],
        [83.22875213623041, 17.605970382690543],
        [83.22763824462908, 17.60597229003912],
        [83.22763824462908, 17.60569381713873],
        [83.2270812988283, 17.60569381713873],
        [83.2270812988283, 17.60541725158697],
        [83.22680664062528, 17.605421066284293],
        [83.22680664062528, 17.605140686035156],
        [83.22651672363281, 17.605140686035156],
        [83.22651672363281, 17.60486030578619],
        [83.22597503662115, 17.60486030578619],
        [83.22597503662115, 17.604583740234375],
        [83.22541809082037, 17.604583740234375],
        [83.22541809082037, 17.604305267333984],
        [83.2251358032226, 17.604312896728516],
        [83.2251358032226, 17.604030609130973],
        [83.22486114501976, 17.604030609130973],
        [83.22486114501976, 17.603750228881836],
        [83.22458648681658, 17.603750228881836],
        [83.22458648681658, 17.603471755981445],
        [83.2240295410158, 17.603471755981445],
        [83.2240295410158, 17.60319519042963],
        [83.22347259521484, 17.603202819824162],
        [83.22347259521484, 17.602922439575195],
        [83.223197937012, 17.602922439575195],
        [83.223197937012, 17.60264968872076],
        [83.22292327880865, 17.60264968872076],
        [83.22289276123047, 17.602361679077262],
        [83.22235870361334, 17.602361679077262],
        [83.22235870361334, 17.60208320617687],
        [83.22180175781273, 17.60208320617687],
        [83.22180175781273, 17.60180473327631],
        [83.22097015380876, 17.60180473327631],
        [83.22097015380876, 17.601528167724723],
        [83.22070312500028, 17.601528167724723],
        [83.22070312500028, 17.601249694824332],
        [83.21958160400402, 17.601249694824332],
        [83.21958160400402, 17.600971221923942],
        [83.21902465820307, 17.600973129272518],
        [83.21902465820307, 17.600694656372127],
        [83.21875000000023, 17.600694656372127],
        [83.21875000000023, 17.600416183471737],
        [83.21848297119158, 17.60041809082037],
        [83.21848297119158, 17.60013961791998],
        [83.21791839599626, 17.60013961791998],
        [83.21791839599626, 17.599861145019588],
        [83.21763610839872, 17.599861145019588],
        [83.21763610839872, 17.59958076477062],
        [83.21736145019531, 17.599582672119197],
        [83.21736145019531, 17.599306106567383],
        [83.216796875, 17.599309921264762],
        [83.216796875, 17.599029541015625],
        [83.21652984619152, 17.599029541015625],
        [83.21652984619152, 17.598751068115234],
        [83.2162475585938, 17.598751068115234],
        [83.2162475585938, 17.598470687866268],
        [83.21597290039057, 17.598472595214844],
        [83.21597290039057, 17.598194122314453],
        [83.21569824218773, 17.598194122314453],
        [83.21569061279303, 17.597917556762695],
        [83.21541595459001, 17.597919464111442],
        [83.21541595459001, 17.596809387207088],
        [83.21569061279303, 17.596809387207088],
        [83.21569824218773, 17.596519470214787],
        [83.21597290039057, 17.596525192260742],
        [83.21597290039057, 17.59541511535639],
        [83.21569824218773, 17.595420837402344],
        [83.21569061279303, 17.594030380249023],
        [83.21514129638678, 17.594030380249023],
        [83.21514129638678, 17.59375],
        [83.21485900878923, 17.59375],
        [83.21485900878923, 17.593200683593807],
        [83.21457672119146, 17.593200683593807],
        [83.21457672119146, 17.59292030334484],
        [83.21430206298845, 17.59292030334484],
        [83.21430206298845, 17.592639923095703],
        [83.21402740478544, 17.592639923095703],
        [83.21402740478544, 17.59152984619152],
        [83.21375274658203, 17.59152984619152],
        [83.21375274658203, 17.591249465942383],
        [83.21347045898466, 17.591249465942383],
        [83.21347045898466, 17.59097290039074],
        [83.21291351318371, 17.59097290039074],
        [83.21291351318371, 17.590694427490178],
        [83.21180725097673, 17.590694427490178],
        [83.21180725097673, 17.590415954589787],
        [83.20791625976591, 17.590419769287166],
        [83.20791625976591, 17.5901393890382],
        [83.20735931396513, 17.5901393890382],
        [83.20735931396513, 17.58986091613781],
        [83.20680236816418, 17.58986091613781],
        [83.20680236816418, 17.58957862854004],
        [83.20625305175776, 17.58958244323742],
        [83.20625305175776, 17.589305877685604],
        [83.2056961059572, 17.58931159973139],
        [83.2056961059572, 17.589029312133846],
        [83.20485687255888, 17.589029312133846],
        [83.20485687255888, 17.588750839233455],
        [83.20458221435547, 17.588750839233455],
        [83.20458221435547, 17.58847045898449],
        [83.2034759521485, 17.588472366333065],
        [83.2034759521485, 17.588193893432674],
        [83.20319366455072, 17.588193893432674],
        [83.20319366455072, 17.587917327880916],
        [83.20291900634788, 17.587919235229492],
        [83.20291900634788, 17.5876407623291],
        [83.20263671875017, 17.5876407623291],
        [83.20263671875017, 17.587360382080135],
        [83.20207977294939, 17.587360382080135],
        [83.20207977294939, 17.587078094482365],
        [83.20097351074219, 17.58708381652832],
        [83.20097351074219, 17.58680534362793],
        [83.20041656494146, 17.58680915832531],
        [83.20041656494146, 17.58653068542492],
        [83.19985961914068, 17.58653068542492],
        [83.19985961914068, 17.58625030517578],
        [83.19902801513689, 17.58625030517578],
        [83.19902801513689, 17.585969924926815],
        [83.19875335693388, 17.58597183227539],
        [83.19875335693388, 17.585695266723576],
        [83.19790649414062, 17.585699081420955],
        [83.19790649414062, 17.585420608520565],
        [83.19735717773443, 17.585420608520565],
        [83.19735717773443, 17.585140228271598],
        [83.19708251953136, 17.585140228271598],
        [83.19708251953136, 17.58485984802246],
        [83.19680786132818, 17.584861755371207],
        [83.19680786132818, 17.584583282470817],
        [83.19652557373041, 17.584583282470817],
        [83.19652557373041, 17.58430671691906],
        [83.19596862792986, 17.58430671691906],
        [83.19596862792986, 17.584026336669865],
        [83.19513702392607, 17.584030151367244],
        [83.19513702392607, 17.583749771118278],
        [83.19430541992193, 17.583749771118278],
        [83.19430541992193, 17.583471298217887],
        [83.19374847412115, 17.583471298217887],
        [83.19374847412115, 17.583196640014705],
        [83.19264221191412, 17.583196640014705],
        [83.19264221191412, 17.582914352417106],
        [83.1920928955081, 17.58292007446289],
        [83.1920928955081, 17.582639694213924],
        [83.19180297851562, 17.582639694213924],
        [83.19180297851562, 17.582361221313533],
        [83.19097137451183, 17.582361221313533],
        [83.19097137451183, 17.582080841064567],
        [83.19013977050804, 17.582082748413143],
        [83.19013977050804, 17.581804275512752],
        [83.18958282470709, 17.581809997558707],
        [83.18958282470709, 17.581520080566406],
        [83.18875122070312, 17.581527709960994],
        [83.18875122070312, 17.581249237060604],
        [83.18818664550781, 17.58125114440918],
        [83.18818664550781, 17.580970764160213],
        [83.18736267089838, 17.58097267150879],
        [83.18736267089838, 17.580692291259822],
        [83.18680572509783, 17.580692291259822],
        [83.18680572509783, 17.58041763305664],
        [83.18624877929705, 17.580419540405387],
        [83.18624877929705, 17.580141067504996],
        [83.18569183349626, 17.580141067504996],
        [83.18569183349626, 17.57986068725586],
        [83.18514251708984, 17.57986068725586],
        [83.18514251708984, 17.579580307006893],
        [83.18430328369152, 17.57958221435547],
        [83.18430328369152, 17.579305648803654],
        [83.18374633789057, 17.579309463501033],
        [83.18374633789057, 17.57903289794922],
        [83.18319702148455, 17.57903289794922],
        [83.18319702148455, 17.578750610351676],
        [83.18264007568376, 17.578750610351676],
        [83.18264007568376, 17.578479766845817],
        [83.18208312988281, 17.578479766845817],
        [83.18208312988281, 17.57819175720215],
        [83.18152618408209, 17.578193664550895],
        [83.18152618408209, 17.577917098999137],
        [83.1809692382813, 17.577922821044865],
        [83.1809692382813, 17.577640533447322],
        [83.18041992187523, 17.577640533447322],
        [83.18041992187523, 17.577360153198356],
        [83.17958068847673, 17.577360153198356],
        [83.17958068847673, 17.57708358764654],
        [83.17903137207031, 17.57708358764654],
        [83.17903137207031, 17.57680511474615],
        [83.178466796875, 17.576812744140682],
        [83.178466796875, 17.57653045654297],
        [83.1779174804688, 17.57653045654297],
        [83.1779174804688, 17.576250076294002],
        [83.1773605346682, 17.576250076294002],
        [83.1773605346682, 17.57597160339361],
        [83.17680358886724, 17.57597160339361],
        [83.17680358886724, 17.575695037841797],
        [83.17624664306669, 17.575702667236328],
        [83.17624664306669, 17.575420379638786],
        [83.17569732666044, 17.575420379638786],
        [83.17569732666044, 17.575138092041072],
        [83.17542266845709, 17.575138092041072],
        [83.17542266845709, 17.574859619140682],
        [83.17514038085949, 17.574861526489258],
        [83.17514038085949, 17.574583053588867],
        [83.1743087768557, 17.574583053588867],
        [83.1743087768557, 17.574306488037053],
        [83.17402648925798, 17.574308395385856],
        [83.17402648925798, 17.574029922485465],
        [83.1734695434572, 17.574029922485465],
        [83.1734695434572, 17.573749542236328],
        [83.17292022705078, 17.573749542236328],
        [83.17292022705078, 17.573471069335938],
        [83.17236328125, 17.573472976684684],
        [83.17236328125, 17.573194503784123],
        [83.17208099365263, 17.573194503784123],
        [83.17208099365263, 17.572917938232536],
        [83.17180633544928, 17.57291984558111],
        [83.17180633544928, 17.572639465332145],
        [83.1712493896485, 17.572639465332145],
        [83.1712493896485, 17.572349548339844],
        [83.17097473144548, 17.572360992431754],
        [83.17097473144548, 17.571807861328182],
        [83.17069244384788, 17.571809768676758],
        [83.17069244384788, 17.57152938842779],
        [83.1704177856447, 17.57152938842779],
        [83.1704177856447, 17.5712509155274],
        [83.17013549804693, 17.5712509155274],
        [83.17013549804693, 17.570695877075195],
        [83.16986083984392, 17.570695877075195],
        [83.16986083984392, 17.57013893127447],
        [83.16958618164091, 17.570140838623047],
        [83.16958618164091, 17.567359924316406],
        [83.16930389404297, 17.567361831665153],
        [83.16930389404297, 17.5668048858642],
        [83.16902923584013, 17.5668048858642],
        [83.16902923584013, 17.566249847412223],
        [83.16874694824224, 17.566249847412223],
        [83.16874694824224, 17.565969467163086],
        [83.16847229003918, 17.565971374511832],
        [83.16847229003918, 17.565694808960018],
        [83.168197631836, 17.565694808960018],
        [83.168197631836, 17.565416336059627],
        [83.16764068603538, 17.565420150756836],
        [83.16764068603538, 17.56513977050787],
        [83.16735839843767, 17.56513977050787],
        [83.16735839843767, 17.564859390258903],
        [83.16708374023443, 17.56486129760748],
        [83.16708374023443, 17.564582824707088],
        [83.16680908203142, 17.564582824707088],
        [83.16680908203142, 17.56402778625494],
        [83.16652679443388, 17.564029693603516],
        [83.16652679443388, 17.563751220703125],
        [83.1659698486331, 17.563751220703125],
        [83.1659698486331, 17.563480377197266],
        [83.16570281982428, 17.563480377197266],
        [83.16570281982428, 17.56318855285639],
        [83.16513824462896, 17.563194274902344],
        [83.16513824462896, 17.562915802001953],
        [83.1648559570312, 17.562919616699332],
        [83.1648559570312, 17.562639236450195],
        [83.16430664062517, 17.562639236450195],
        [83.16430664062517, 17.562360763549805],
        [83.16374969482439, 17.562360763549805],
        [83.16374969482439, 17.562078475952205],
        [83.16347503662138, 17.56208419799799],
        [83.16347503662138, 17.5618057250976],
        [83.1629180908206, 17.56180953979498],
        [83.1629180908206, 17.561529159546012],
        [83.16236114501964, 17.561529159546012],
        [83.16236114501964, 17.56125068664562],
        [83.16152954101585, 17.56125068664562],
        [83.16152954101585, 17.56096839904785],
        [83.1609725952149, 17.56097221374523],
        [83.1609725952149, 17.56069374084484],
        [83.15985870361357, 17.56069374084484],
        [83.15985870361357, 17.560417175293082],
        [83.15930175781278, 17.560417175293082],
        [83.15930175781278, 17.560138702392692],
        [83.15902709960943, 17.560140609741268],
        [83.15902709960943, 17.5598602294923],
        [83.15763854980486, 17.5598602294923],
        [83.15763854980486, 17.559579849243164],
        [83.15708160400408, 17.559583663940487],
        [83.15708160400408, 17.559305191040096],
        [83.1565170288086, 17.55931091308605],
        [83.1565170288086, 17.559030532836914],
        [83.15513610839838, 17.559030532836914],
        [83.15513610839838, 17.558750152587947],
        [83.15429687500006, 17.558750152587947],
        [83.15429687500006, 17.55846977233898],
        [83.15319824218778, 17.558471679687557],
        [83.15319061279325, 17.558195114135742],
        [83.15235900878912, 17.558200836181697],
        [83.15235900878912, 17.55792045593273],
        [83.15069580078153, 17.55792045593273],
        [83.15069580078153, 17.557640075683594],
        [83.14985656738281, 17.557640075683594],
        [83.14985656738281, 17.557350158691463],
        [83.14847564697283, 17.557361602783203],
        [83.14847564697283, 17.557083129882812],
        [83.14791870117205, 17.557083129882812],
        [83.14791870117205, 17.556804656982422],
        [83.14708709716825, 17.556804656982422],
        [83.14708709716825, 17.55652618408203],
        [83.14679718017578, 17.55652999877941],
        [83.14679718017578, 17.556249618530273],
        [83.1454162597658, 17.556249618530273],
        [83.1454162597658, 17.555971145629883],
        [83.14485931396501, 17.55597305297863],
        [83.14485931396501, 17.55569648742687],
        [83.14430236816423, 17.55569648742687],
        [83.14430236816423, 17.55597305297863],
        [83.14375305175781, 17.55597305297863],
        [83.14375305175781, 17.55569648742687],
        [83.1426391601563, 17.55569648742687],
        [83.1426391601563, 17.5554141998291],
        [83.14180755615251, 17.555419921875057],
        [83.14180755615251, 17.55513954162609],
        [83.14068603515625, 17.55513954162609],
        [83.14068603515625, 17.5548610687257],
        [83.14013671875006, 17.5548610687257],
        [83.14013671875006, 17.554580688476562],
        [83.13874816894548, 17.55458259582531],
        [83.13874816894548, 17.55430412292492],
        [83.13735961914091, 17.554309844970703],
        [83.13735961914091, 17.554029464721737],
        [83.13652801513678, 17.554029464721737],
        [83.13652801513678, 17.553750991821346],
        [83.13540649414085, 17.553750991821346],
        [83.13540649414085, 17.55347061157238],
        [83.13458251953125, 17.553472518920955],
        [83.13458251953125, 17.55319213867199],
        [83.13375091552746, 17.55319213867199],
        [83.13375091552746, 17.552917480468807],
        [83.13291931152366, 17.552919387817383],
        [83.13291931152366, 17.552642822265568],
        [83.13263702392595, 17.552642822265568],
        [83.13263702392595, 17.552360534668026],
        [83.13236236572271, 17.552360534668026],
        [83.13236236572271, 17.55208015441906],
        [83.13208007812517, 17.55208015441906],
        [83.13208007812517, 17.552360534668026],
        [83.13096618652344, 17.552360534668026],
        [83.13096618652344, 17.55208015441906],
        [83.12958526611345, 17.552082061767635],
        [83.12958526611345, 17.55180549621582],
        [83.1287460327149, 17.551811218261776],
        [83.1287460327149, 17.551540374755916],
        [83.1276397705081, 17.551540374755916],
        [83.1276397705081, 17.551250457763672],
        [83.1265258789062, 17.551250457763672],
        [83.1265258789062, 17.550970077514705],
        [83.12541961669939, 17.550970077514705],
        [83.12541961669939, 17.550695419311467],
        [83.12431335449224, 17.550691604614315],
        [83.12430572509771, 17.550416946411076],
        [83.12346649169916, 17.55042266845703],
        [83.12346649169916, 17.55014038085949],
        [83.1226425170899, 17.55014038085949],
        [83.1226425170899, 17.549861907959098],
        [83.1218032836914, 17.549861907959098],
        [83.1218032836914, 17.549583435058707],
        [83.12124633789062, 17.549583435058707],
        [83.12124633789062, 17.549304962158146],
        [83.1204071044923, 17.549312591552678],
        [83.1204071044923, 17.549030303955135],
        [83.1187515258789, 17.549030303955135],
        [83.1187515258789, 17.548751831054744],
        [83.1176376342774, 17.548751831054744],
        [83.1176376342774, 17.5484676361084],
        [83.11653137207037, 17.548471450805778],
        [83.11653137207037, 17.548194885253963],
        [83.11569213867205, 17.548194885253963],
        [83.11569213867205, 17.547916412353572],
        [83.11485290527372, 17.547918319702205],
        [83.11485290527372, 17.547639846801815],
        [83.11374664306635, 17.547639846801815],
        [83.11374664306635, 17.547357559204045],
        [83.11291503906256, 17.547361373901424],
        [83.11291503906256, 17.547082901001033],
        [83.1120834350586, 17.547082901001033],
        [83.1120834350586, 17.54680633544922],
        [83.11152648925787, 17.54680824279785],
        [83.11152648925787, 17.54652976989746],
        [83.11099243164085, 17.54652976989746],
        [83.11068725585932, 17.54652976989746],
        [83.11068725585932, 17.546249389648494],
        [83.10986328125006, 17.546249389648494],
        [83.10986328125006, 17.545970916748104],
        [83.1093063354495, 17.54597282409668],
        [83.1093063354495, 17.54569435119629],
        [83.10847473144537, 17.54569435119629],
        [83.10847473144537, 17.54541778564453],
        [83.10791778564459, 17.54541778564453],
        [83.10791778564459, 17.54513931274414],
        [83.1070861816408, 17.545141220092887],
        [83.1070861816408, 17.54486083984375],
        [83.10652923584001, 17.54486083984375],
        [83.10652923584001, 17.544580459594783],
        [83.10569763183622, 17.544584274291935],
        [83.10569763183622, 17.544307708740348],
        [83.10514068603527, 17.544309616088924],
        [83.10514068603527, 17.544031143188533],
        [83.10430908203148, 17.544031143188533],
        [83.10430908203148, 17.543750762939567],
        [83.10375213623053, 17.543750762939567],
        [83.10375213623053, 17.54347038269043],
        [83.10319519042997, 17.543472290039176],
        [83.10319519042997, 17.543193817138786],
        [83.10263824462919, 17.543193817138786],
        [83.10263824462919, 17.542915344238224],
        [83.10180664062506, 17.54292106628418],
        [83.10180664062506, 17.542640686035213],
        [83.10124969482428, 17.542640686035213],
        [83.10124969482428, 17.542369842529354],
        [83.10069274902366, 17.542369842529354],
        [83.10069274902366, 17.54207992553711],
        [83.10013580322271, 17.542085647583008],
        [83.10013580322271, 17.541803359985465],
        [83.09929656982422, 17.541810989379996],
        [83.09929656982422, 17.54153060913086],
        [83.09874725341803, 17.54153060913086],
        [83.09874725341803, 17.541250228881893],
        [83.09819793701178, 17.541250228881893],
        [83.09819793701178, 17.540969848632926],
        [83.09764099121116, 17.540971755981502],
        [83.09764099121116, 17.54069328308111],
        [83.09678649902361, 17.540700912475643],
        [83.09678649902361, 17.540420532226676],
        [83.09625244140625, 17.540420532226676],
        [83.09625244140625, 17.54014015197754],
        [83.09570312500006, 17.54014015197754],
        [83.09570312500006, 17.539859771728572],
        [83.09513854980474, 17.53986167907715],
        [83.09513854980474, 17.539583206176758],
        [83.09430694580095, 17.539583206176758],
        [83.09430694580095, 17.539304733276367],
        [83.09152984619163, 17.539310455322322],
        [83.09152984619163, 17.539030075073356],
        [83.09124755859392, 17.539030075073356],
        [83.09124755859392, 17.538192749023438],
        [83.09152984619163, 17.538194656372013],
        [83.09152984619163, 17.537639617920036],
        [83.09124755859392, 17.537639617920036],
        [83.09124755859392, 17.537361145019645],
        [83.09097290039068, 17.537361145019645],
        [83.09097290039068, 17.537082672119254],
        [83.09069824218767, 17.537082672119254],
        [83.09069061279314, 17.536527633667106],
        [83.09059143066412, 17.536527633667106],
        [83.09041595459013, 17.536529541015682],
        [83.09041595459013, 17.53633117675787],
        [83.09041595459013, 17.536249160766715],
        [83.09014129638672, 17.536249160766715],
        [83.09014129638672, 17.536039352416992],
        [83.09014129638672, 17.53569412231451],
        [83.08985900878935, 17.53569412231451],
        [83.08985900878935, 17.53513908386236],
        [83.0895767211914, 17.53513908386236],
        [83.0895767211914, 17.53486061096197],
        [83.08930206298857, 17.53486061096197],
        [83.08930206298857, 17.534309387207145],
        [83.08902740478521, 17.534309387207145],
        [83.08902740478521, 17.534030914306754],
        [83.08875274658197, 17.534030914306754],
        [83.08875274658197, 17.533750534057617],
        [83.08847045898443, 17.533750534057617],
        [83.08847045898443, 17.533468246460018],
        [83.08777618408232, 17.533472061157227],
        [83.08763885498064, 17.533472061157227],
        [83.08763885498064, 17.533193588256836],
        [83.08708190917986, 17.533193588256836],
        [83.08708190917986, 17.532918930053825],
        [83.08625030517607, 17.5329208374024],
        [83.08625030517607, 17.532640457153434],
        [83.08541870117193, 17.532640457153434],
        [83.08541870117193, 17.532358169555664],
        [83.08374786376982, 17.532361984253043],
        [83.08374786376982, 17.532083511352653],
        [83.08291625976568, 17.532083511352653],
        [83.08291625976568, 17.531806945800895],
        [83.08097076416033, 17.531810760498047],
        [83.08097076416033, 17.53153038024908],
        [83.08069610595732, 17.53153038024908],
        [83.08069610595732, 17.531250000000114],
        [83.0798568725586, 17.531250000000114],
        [83.0798568725586, 17.530969619750977],
        [83.07902526855463, 17.530971527099723],
        [83.07902526855463, 17.53069686889654],
        [83.07736206054688, 17.530700683593864],
        [83.07736206054688, 17.530429840087834],
        [83.07681274414068, 17.530429840087834],
        [83.07681274414068, 17.53013992309576],
        [83.07624816894537, 17.53013992309576],
        [83.07624816894537, 17.529859542846793],
        [83.0748596191408, 17.52986145019537],
        [83.0748596191408, 17.52958297729498],
        [83.07430267334001, 17.52958297729498],
        [83.07430267334001, 17.529304504394588],
        [83.07208251953148, 17.529310226440543],
        [83.07208251953148, 17.529029846191406],
        [83.0718078613283, 17.529029846191406],
        [83.0718078613283, 17.52874946594244],
        [83.07125091552751, 17.52874946594244],
        [83.07125091552751, 17.52847099304205],
        [83.07096862792997, 17.5284748077392],
        [83.07096862792997, 17.52819633483881],
        [83.07069396972656, 17.52819633483881],
        [83.07069396972656, 17.527914047241268],
        [83.06986999511713, 17.527919769287223],
        [83.06986999511713, 17.527639389038086],
        [83.06958007812506, 17.527639389038086],
        [83.06958007812506, 17.527370452880803],
        [83.06874847412126, 17.527370452880803],
        [83.06874847412126, 17.52708053588873],
        [83.06791687011747, 17.527082443237305],
        [83.06791687011747, 17.52680587768549],
        [83.06735992431669, 17.52680969238287],
        [83.06735992431669, 17.52653121948248],
        [83.06709289550787, 17.52653121948248],
        [83.06709289550787, 17.526250839233512],
        [83.06680297851574, 17.526250839233512],
        [83.06680297851574, 17.524860382080192],
        [83.06652832031256, 17.524860382080192],
        [83.06652832031256, 17.5245819091798],
        [83.06597137451195, 17.5245819091798],
        [83.06597137451195, 17.524860382080192],
        [83.06569671630876, 17.524860382080192],
        [83.06569671630876, 17.52514076232916],
        [83.06540679931663, 17.52514076232916],
        [83.06540679931663, 17.525421142578125],
        [83.06513977050798, 17.525417327880973],
        [83.06513977050798, 17.52569389343273],
        [83.06430816650419, 17.52569389343273],
        [83.06430816650419, 17.525417327880973],
        [83.06346893310553, 17.525421142578125],
        [83.06346893310553, 17.52514076232916],
        [83.06208038330095, 17.52514076232916],
        [83.06208038330095, 17.524860382080192],
        [83.05930328369163, 17.524860382080192],
        [83.05930328369163, 17.5245819091798],
        [83.05902862548845, 17.524583816528377],
        [83.05902862548845, 17.524305343627987],
        [83.05847167968767, 17.524305343627987],
        [83.05847167968767, 17.524026870727596],
        [83.05790710449219, 17.52403259277338],
        [83.05790710449219, 17.523471832275447],
        [83.05764007568388, 17.523471832275447],
        [83.05764007568388, 17.523195266723633],
        [83.05680847167974, 17.523202896118164],
        [83.05680847167974, 17.522640228271484],
        [83.05652618408197, 17.522640228271484],
        [83.05652618408197, 17.522361755371094],
        [83.05625152587913, 17.522361755371094],
        [83.05625152587913, 17.522083282470703],
        [83.05569458007818, 17.522083282470703],
        [83.05569458007818, 17.521804809570312],
        [83.05486297607422, 17.521804809570312],
        [83.05486297607422, 17.521528244018555],
        [83.05403137207043, 17.521528244018555],
        [83.05403137207043, 17.521240234375],
        [83.0531921386721, 17.521249771118164],
        [83.0531921386721, 17.520971298217773],
        [83.05263519287115, 17.520971298217773],
        [83.05263519287115, 17.52069473266596],
        [83.05180358886719, 17.52069473266596],
        [83.05180358886719, 17.520416259765568],
        [83.0512466430664, 17.52041816711437],
        [83.0512466430664, 17.52013969421398],
        [83.05041503906244, 17.52013969421398],
        [83.05041503906244, 17.51986122131359],
        [83.04957580566429, 17.51986122131359],
        [83.04957580566429, 17.519580841064453],
        [83.0490264892581, 17.5195827484132],
        [83.0490264892581, 17.519306182861385],
        [83.04818725585938, 17.519308090210018],
        [83.04818725585938, 17.519029617309627],
        [83.04736328124994, 17.519029617309627],
        [83.04736328124994, 17.518751144409237],
        [83.04680633544939, 17.518751144409237],
        [83.04680633544939, 17.51847076416027],
        [83.0459747314456, 17.518472671508846],
        [83.0459747314456, 17.518194198608455],
        [83.0451431274414, 17.51819992065441],
        [83.0451431274414, 17.517919540405273],
        [83.04458618164068, 17.517919540405273],
        [83.04458618164068, 17.517641067504883],
        [83.04374694824213, 17.517641067504883],
        [83.04374694824213, 17.517360687255916],
        [83.04291534423834, 17.517360687255916],
        [83.04291534423834, 17.5170841217041],
        [83.04235839843778, 17.5170841217041],
        [83.04235839843778, 17.516807556152344],
        [83.04152679443365, 17.51680946350109],
        [83.04152679443365, 17.5165309906007],
        [83.04096984863287, 17.5165309906007],
        [83.04096984863287, 17.516250610351562],
        [83.04069519042963, 17.516250610351562],
        [83.04069519042963, 17.515972137451172],
        [83.03930664062528, 17.515972137451172],
        [83.03930664062528, 17.51569366455078],
        [83.0387496948245, 17.51569366455078],
        [83.0387496948245, 17.51541519165039],
        [83.03791809082037, 17.515430450439453],
        [83.03791809082037, 17.51514053344738],
        [83.03736114501976, 17.51514053344738],
        [83.03736114501976, 17.514860153198242],
        [83.0368041992188, 17.51486206054699],
        [83.0368041992188, 17.514585494995174],
        [83.03597259521484, 17.514585494995174],
        [83.03597259521484, 17.51430320739746],
        [83.03514099121105, 17.514310836791992],
        [83.03514099121105, 17.514030456543026],
        [83.03430175781256, 17.514030456543026],
        [83.03430175781256, 17.51375007629406],
        [83.03347015380876, 17.51375007629406],
        [83.03347015380876, 17.513469696044922],
        [83.03291320800798, 17.51347160339367],
        [83.03291320800798, 17.513193130493278],
        [83.03263854980497, 17.51320075988781],
        [83.03263854980497, 17.512920379638672],
        [83.03208160400419, 17.512920379638672],
        [83.03208160400419, 17.512639999389705],
        [83.03125, 17.512639999389705],
        [83.03125, 17.51235961914074],
        [83.03069305419945, 17.512361526489315],
        [83.03069305419945, 17.512081146240348],
        [83.0301361083985, 17.512081146240348],
        [83.0301361083985, 17.51180648803711],
        [83.02958679199247, 17.51181030273449],
        [83.02958679199247, 17.51152992248535],
        [83.02902984619169, 17.51152992248535],
        [83.02902984619169, 17.511249542236385],
        [83.02847290039074, 17.511249542236385],
        [83.02847290039074, 17.510971069335994],
        [83.02819824218756, 17.510971069335994],
        [83.02819061279303, 17.51069450378418],
        [83.02764129638695, 17.51069450378418],
        [83.02764129638695, 17.51041603088379],
        [83.02707672119146, 17.510421752929744],
        [83.02707672119146, 17.51013946533203],
        [83.02652740478544, 17.51013946533203],
        [83.02652740478544, 17.509859085083065],
        [83.02597045898466, 17.509859085083065],
        [83.02597045898466, 17.509580612182674],
        [83.02541351318371, 17.50958251953125],
        [83.02541351318371, 17.509305953979435],
        [83.02486419677729, 17.509305953979435],
        [83.02486419677729, 17.509027481079045],
        [83.02430725097673, 17.509029388427848],
        [83.02430725097673, 17.508750915527457],
        [83.02375030517595, 17.508750915527457],
        [83.02375030517595, 17.50847053527832],
        [83.023193359375, 17.508472442627067],
        [83.023193359375, 17.508193969726676],
        [83.02263641357422, 17.508193969726676],
        [83.02263641357422, 17.50791740417492],
        [83.02208709716803, 17.507919311523494],
        [83.02208709716803, 17.507640838623104],
        [83.02153015136741, 17.507640838623104],
        [83.02153015136741, 17.507360458374137],
        [83.02097320556646, 17.507360458374137],
        [83.02097320556646, 17.507078170776367],
        [83.02041625976591, 17.507083892822322],
        [83.02041625976591, 17.506805419921932],
        [83.01985931396513, 17.50680923461914],
        [83.01985931396513, 17.50653076171875],
        [83.01930236816435, 17.50653076171875],
        [83.01930236816435, 17.50625991821289],
        [83.01876068115229, 17.50625991821289],
        [83.01876068115229, 17.505968093872013],
        [83.0181961059572, 17.505971908569393],
        [83.0181961059572, 17.505695343017578],
        [83.01790618896507, 17.505699157714957],
        [83.01790618896507, 17.505420684814567],
        [83.01735687255865, 17.505420684814567],
        [83.01735687255865, 17.50514030456543],
        [83.01680755615263, 17.50514030456543],
        [83.01680755615263, 17.504859924316463],
        [83.01625061035185, 17.50486183166504],
        [83.01625061035185, 17.50458335876465],
        [83.0156936645509, 17.50458335876465],
        [83.0156936645509, 17.504306793212834],
        [83.01513671874994, 17.504306793212834],
        [83.01513671874994, 17.5040283203125],
        [83.01457977294939, 17.504030227661246],
        [83.01457977294939, 17.50374984741211],
        [83.01403045654314, 17.50374984741211],
        [83.01403045654314, 17.503469467163143],
        [83.01347351074219, 17.50347137451172],
        [83.01347351074219, 17.503196716308707],
        [83.01291656494146, 17.50320053100586],
        [83.01291656494146, 17.502920150756893],
        [83.0126266479495, 17.502920150756893],
        [83.0126266479495, 17.502639770507926],
        [83.01207733154291, 17.502639770507926],
        [83.01207733154291, 17.50235939025879],
        [83.01180267334007, 17.502361297607536],
        [83.01180267334007, 17.502082824707145],
        [83.01152801513689, 17.502082824707145],
        [83.01152801513689, 17.501804351806754],
        [83.01125335693365, 17.50181007385254],
        [83.01125335693365, 17.501529693603572],
        [83.0106964111331, 17.501529693603572],
        [83.0106964111331, 17.501251220703182],
        [83.0104064941408, 17.501251220703182],
        [83.0104064941408, 17.500970840454215],
        [83.01013946533232, 17.500974655151367],
        [83.01013946533232, 17.500692367553825],
        [83.00986480712896, 17.500692367553825],
        [83.00986480712896, 17.50041580200201],
        [83.00930786132818, 17.50041961669922],
        [83.00930786132818, 17.500141143798828],
        [83.00903320312494, 17.500141143798828],
        [83.00903320312494, 17.49986076354986],
        [83.00875091552757, 17.49986076354986],
        [83.00875091552757, 17.499580383300895],
        [83.00819396972662, 17.49958229064947],
        [83.00819396972662, 17.499305725097656],
        [83.00791931152361, 17.499309539795036],
        [83.00791931152361, 17.499031066894645],
        [83.00763702392607, 17.499031066894645],
        [83.00763702392607, 17.498750686645508],
        [83.00736236572266, 17.498750686645508],
        [83.00736236572266, 17.49847030639654],
        [83.00708007812528, 17.498472213745117],
        [83.00708007812528, 17.498193740844727],
        [83.00680541992188, 17.498193740844727],
        [83.00680541992188, 17.497638702392578],
        [83.00653076171886, 17.497640609741325],
        [83.00653076171886, 17.497360229492188],
        [83.00624847412115, 17.497360229492188],
        [83.00624847412115, 17.49706840515148],
        [83.00597381591791, 17.497083663940543],
        [83.00597381591791, 17.496528625488395],
        [83.00569152832037, 17.49653053283697],
        [83.00569152832037, 17.496250152588004],
        [83.00541687011736, 17.496250152588004],
        [83.00541687011736, 17.495971679687614],
        [83.00514221191412, 17.495971679687614],
        [83.00514221191412, 17.4956951141358],
        [83.00485992431658, 17.49570274353033],
        [83.00485992431658, 17.494859695434684],
        [83.00458526611357, 17.49486160278326],
        [83.00458526611357, 17.49402809143072],
        [83.0043029785158, 17.49402809143072],
        [83.0043029785158, 17.49347114562994],
        [83.00402832031278, 17.493473052978516],
        [83.00402832031278, 17.492639541625977],
        [83.00374603271484, 17.492639541625977],
        [83.00374603271484, 17.49014091491705],
        [83.00402832031278, 17.49014091491705],
        [83.00402832031278, 17.489580154418945],
        [83.0043029785158, 17.489585876464844],
        [83.0043029785158, 17.48930740356451],
        [83.0044403076173, 17.48930740356451],
        [83.0045928955081, 17.489311218261832],
        [83.0045928955081, 17.489030838012695],
        [83.00485992431658, 17.489030838012695],
        [83.00485992431658, 17.48875045776373],
        [83.00514221191412, 17.48875045776373],
        [83.00514221191412, 17.488470077514762],
        [83.00541687011736, 17.488471984863338],
        [83.00541687011736, 17.487916946411133],
        [83.00514221191412, 17.487920761108512],
        [83.00514221191412, 17.487640380859375],
        [83.0045928955081, 17.487640380859375],
        [83.0045928955081, 17.48736000061041],
        [83.00402832031278, 17.48736381530756],
        [83.00402832031278, 17.487083435058594],
        [83.003471374512, 17.487083435058594],
        [83.003471374512, 17.486803054809627],
        [83.00290679931652, 17.48681068420416],
        [83.00290679931652, 17.486530303955192],
        [83.00263977050787, 17.486530303955192],
        [83.00263977050787, 17.486249923706055],
        [83.0023574829101, 17.486249923706055],
        [83.0023574829101, 17.485969543457088],
        [83.00208282470726, 17.485971450805664],
        [83.00208282470726, 17.48569488525385],
        [83.00180816650408, 17.48569488525385],
        [83.00180816650408, 17.48541641235346],
        [83.0015258789063, 17.485420227050838],
        [83.0015258789063, 17.485130310058537],
        [83.0012512207033, 17.485137939453068],
        [83.0012512207033, 17.48486137390148],
        [83.00069427490234, 17.48486137390148],
        [83.00069427490234, 17.48458290100109],
        [83.0004196166995, 17.48458290100109],
        [83.0004196166995, 17.484306335449276],
        [83.00013732910162, 17.484310150146484],
        [83.00013732910162, 17.484029769897518],
        [82.99986267089855, 17.484029769897518],
        [82.99986267089855, 17.48374938964855],
        [82.99958038330084, 17.48374938964855],
        [82.99958038330084, 17.483472824096737],
        [82.9993057250976, 17.483472824096737],
        [82.9993057250976, 17.483194351196346],
        [82.99903106689476, 17.483194351196346],
        [82.99903106689476, 17.482915878295955],
        [82.99874877929705, 17.482919692993164],
        [82.99874877929705, 17.482639312744197],
        [82.99846649169928, 17.482639312744197],
        [82.99846649169928, 17.482360839843807],
        [82.99791717529325, 17.482360839843807],
        [82.99791717529325, 17.482070922851506],
        [82.99736022949247, 17.482084274291992],
        [82.99736022949247, 17.4818058013916],
        [82.99709320068365, 17.481811523437557],
        [82.99709320068365, 17.481529235839844],
        [82.99682617187494, 17.481529235839844],
        [82.99652862548834, 17.481529235839844],
        [82.99652862548834, 17.481250762939453],
        [82.99597167968773, 17.481250762939453],
        [82.99597167968773, 17.480970382690487],
        [82.99569702148455, 17.480972290039062],
        [82.99569702148455, 17.480693817138672],
        [82.99514007568376, 17.480693817138672],
        [82.99514007568376, 17.480417251586857],
        [82.99485778808622, 17.48041915893566],
        [82.99485778808622, 17.48014068603527],
        [82.99459075927734, 17.48014068603527],
        [82.99459075927734, 17.479860305786133],
        [82.99402618408203, 17.479860305786133],
        [82.99402618408203, 17.479579925537166],
        [82.99375152587902, 17.47958374023449],
        [82.99375152587902, 17.477361679077205],
        [82.99402618408203, 17.477361679077205],
        [82.99402618408203, 17.477083206176815],
        [82.99458312988281, 17.477083206176815],
        [82.99458312988281, 17.476806640625],
        [82.99485778808622, 17.476806640625],
        [82.99485778808622, 17.476526260376033],
        [82.99514007568376, 17.476530075073242],
        [82.99514007568376, 17.475971221923885],
        [82.99541473388678, 17.47597312927246],
        [82.99541473388678, 17.475414276123104],
        [82.99514007568376, 17.47541999816906],
        [82.99514007568376, 17.47486114501953],
        [82.99485778808622, 17.47486114501953],
        [82.99485778808622, 17.474580764770565],
        [82.99430847167997, 17.47458267211914],
        [82.99430847167997, 17.47430419921875],
        [82.99375152587902, 17.474309921264705],
        [82.99375152587902, 17.47402954101574],
        [82.99291992187523, 17.47402954101574],
        [82.99291992187523, 17.473751068115348],
        [82.99263763427751, 17.473751068115348],
        [82.99263763427751, 17.47347068786621],
        [82.99153137207031, 17.473472595214957],
        [82.99153137207031, 17.47319221496582],
        [82.99124908447294, 17.47319221496582],
        [82.99124908447294, 17.47291755676281],
        [82.990966796875, 17.472919464111385],
        [82.990966796875, 17.47264289855957],
        [82.99069213867216, 17.47264289855957],
        [82.99069213867216, 17.472360610962028],
        [82.99013519287121, 17.472360610962028],
        [82.99013519287121, 17.472082138061637],
        [82.98930358886741, 17.472082138061637],
        [82.98930358886741, 17.471805572509822],
        [82.98874664306646, 17.47180938720703],
        [82.98874664306646, 17.471532821655217],
        [82.98819732666044, 17.471532821655217],
        [82.98819732666044, 17.471250534057674],
        [82.98764038085966, 17.471250534057674],
        [82.98764038085966, 17.470970153808707],
        [82.98708343505871, 17.470970153808707],
        [82.98708343505871, 17.470693588256893],
        [82.98652648925776, 17.470693588256893],
        [82.98652648925776, 17.470417022705078],
        [82.98568725585943, 17.470422744751033],
        [82.98568725585943, 17.470130920410156],
        [82.98513793945341, 17.470138549804744],
        [82.98513793945341, 17.469860076904354],
        [82.98430633544928, 17.469860076904354],
        [82.98430633544928, 17.46958351135254],
        [82.98347473144526, 17.46958351135254],
        [82.98347473144526, 17.46930503845215],
        [82.9829177856447, 17.46931266784668],
        [82.9829177856447, 17.469030380249137],
        [82.98208618164091, 17.469030380249137],
        [82.98208618164091, 17.468751907348746],
        [82.98124694824219, 17.468751907348746],
        [82.98124694824219, 17.46847152709961],
        [82.98041534423822, 17.46847152709961],
        [82.98041534423822, 17.468194961547795],
        [82.97985839843767, 17.468202590942326],
        [82.97985839843767, 17.467918395996207],
        [82.97902679443388, 17.467918395996207],
        [82.97902679443388, 17.467639923095817],
        [82.97820281982428, 17.467639923095817],
        [82.97820281982428, 17.46735954284668],
        [82.97763824462896, 17.467361450195426],
        [82.97763824462896, 17.467082977295036],
        [82.97680664062517, 17.467090606689567],
        [82.97680664062517, 17.466808319091854],
        [82.97598266601591, 17.466808319091854],
        [82.97598266601591, 17.466529846191463],
        [82.9754180908206, 17.466529846191463],
        [82.9754180908206, 17.466249465942496],
        [82.97458648681646, 17.466249465942496],
        [82.97458648681646, 17.465970993042106],
        [82.97374725341791, 17.465972900390682],
        [82.97374725341791, 17.46569442749029],
        [82.97291564941412, 17.46569442749029],
        [82.97291564941412, 17.465417861938533],
        [82.97235870361357, 17.46541976928711],
        [82.97235870361357, 17.465139389038143],
        [82.97152709960943, 17.465139389038143],
        [82.97152709960943, 17.464860916137752],
        [82.97069549560541, 17.464860916137752],
        [82.97069549560541, 17.46458244323736],
        [82.96958160400408, 17.46458244323736],
        [82.96958160400408, 17.46430778503418],
        [82.96875000000028, 17.464319229126033],
        [82.96875000000028, 17.464031219482536],
        [82.96763610839838, 17.464031219482536],
        [82.96763610839838, 17.4637508392334],
        [82.96679687500023, 17.4637508392334],
        [82.96679687500023, 17.463470458984432],
        [82.96569824218778, 17.463472366333008],
        [82.96569061279325, 17.463195800781193],
        [82.96485900878912, 17.463195800781193],
        [82.96485900878912, 17.462917327880803],
        [82.96375274658209, 17.462921142578182],
        [82.96375274658209, 17.462640762329215],
        [82.96290588378923, 17.462640762329215],
        [82.96290588378923, 17.462360382080078],
        [82.96151733398449, 17.462360382080078],
        [82.96151733398449, 17.46208000183111],
        [82.96125030517584, 17.46208572387701],
        [82.96125030517584, 17.461803436279297],
        [82.96069335937523, 17.461811065673828],
        [82.96069335937523, 17.46153068542486],
        [82.96041870117205, 17.46153068542486],
        [82.96041870117205, 17.461250305175895],
        [82.96013641357428, 17.461250305175895],
        [82.96013641357428, 17.460960388183594],
        [82.95903015136747, 17.460971832275504],
        [82.95903015136747, 17.460693359375114],
        [82.95791625976557, 17.460700988769645],
        [82.95791625976557, 17.460420608520508],
        [82.95597076416044, 17.460420608520508],
        [82.95597076416044, 17.46014022827154],
        [82.9551391601563, 17.46014022827154],
        [82.9551391601563, 17.459859848022575],
        [82.95402526855474, 17.45986175537115],
        [82.95402526855474, 17.45958328247076],
        [82.95347595214872, 17.45958328247076],
        [82.95347595214872, 17.45930480957037],
        [82.95291900634794, 17.45930480957037],
        [82.95291900634794, 17.45902824401861],
        [82.95263671875, 17.459030151367188],
        [82.95263671875, 17.45874977111822],
        [82.9523468017581, 17.45874977111822],
        [82.9523468017581, 17.458469390869254],
        [82.95097351074224, 17.45847129821783],
        [82.95097351074224, 17.458194732666016],
        [82.9506912231447, 17.458194732666016],
        [82.9506912231447, 17.457916259765625],
        [82.95041656494169, 17.457920074463004],
        [82.95041656494169, 17.457639694213867],
        [82.95014190673828, 17.457639694213867],
        [82.95014190673828, 17.457082748413086],
        [82.94985961914091, 17.457082748413086],
        [82.94985961914091, 17.455972671508903],
        [82.95014190673828, 17.455972671508903],
        [82.95014190673828, 17.454860687255973],
        [82.94985961914091, 17.454860687255973],
        [82.94985961914091, 17.454578399658203],
        [82.9495849609375, 17.45458412170416],
        [82.9495849609375, 17.454305648803768],
        [82.94902801513678, 17.454309463500977],
        [82.94902801513678, 17.45402908325201],
        [82.94875335693354, 17.45402908325201],
        [82.94875335693354, 17.45375061035162],
        [82.948471069336, 17.45375061035162],
        [82.948471069336, 17.45318984985346],
        [82.94819641113298, 17.453193664550838],
        [82.94819641113298, 17.452917098999023],
        [82.94790649414085, 17.452920913696403],
        [82.94790649414085, 17.452640533447266],
        [82.9476394653322, 17.452640533447266],
        [82.9476394653322, 17.4523601531983],
        [82.94708251953142, 17.452362060546875],
        [82.94708251953142, 17.452083587646484],
        [82.94654083251959, 17.452091217041016],
        [82.94654083251959, 17.45181083679205],
        [82.94596862792974, 17.45181083679205],
        [82.94596862792974, 17.451530456543082],
        [82.9454193115235, 17.451530456543082],
        [82.9454193115235, 17.451250076293945],
        [82.94291687011724, 17.451250076293945],
        [82.94291687011724, 17.451530456543082],
        [82.94209289550798, 17.451530456543082],
        [82.94209289550798, 17.451250076293945],
        [82.94152832031267, 17.451250076293945],
        [82.94152832031267, 17.45096969604498],
        [82.94097137451189, 17.450971603393555],
        [82.94097137451189, 17.450696945190543],
        [82.94069671630888, 17.450700759887695],
        [82.94069671630888, 17.45042037963873],
        [82.93985748291016, 17.45042037963873],
        [82.93985748291016, 17.450139999389762],
        [82.9390258789062, 17.450139999389762],
        [82.9390258789062, 17.449859619140625],
        [82.93846893310564, 17.44986152648937],
        [82.93846893310564, 17.44958305358898],
        [82.93791961669939, 17.44958305358898],
        [82.93791961669939, 17.449306488037166],
        [82.93734741210955, 17.449310302734375],
        [82.93734741210955, 17.449020385742244],
        [82.93680572509766, 17.44902610778803],
        [82.93680572509766, 17.448749542236442],
        [82.93624877929693, 17.448749542236442],
        [82.93624877929693, 17.44847106933605],
        [82.93569183349615, 17.448474884033203],
        [82.93569183349615, 17.448196411132812],
        [82.9351425170899, 17.448196411132812],
        [82.9351425170899, 17.44791412353527],
        [82.93431091308594, 17.447919845581055],
        [82.93431091308594, 17.447639465332088],
        [82.93374633789062, 17.447639465332088],
        [82.93374633789062, 17.447360992431697],
        [82.93319702148443, 17.447360992431697],
        [82.93319702148443, 17.44708061218273],
        [82.93264007568365, 17.447082519531307],
        [82.93264007568365, 17.446804046630916],
        [82.93208312988304, 17.44680976867687],
        [82.93208312988304, 17.446529388427734],
        [82.93154144287138, 17.446529388427734],
        [82.93154144287138, 17.446250915527344],
        [82.93096923828153, 17.446250915527344],
        [82.93096923828153, 17.445962905883846],
        [82.93041992187528, 17.445972442626953],
        [82.93041992187528, 17.445692062377987],
        [82.92986297607433, 17.445692062377987],
        [82.92986297607433, 17.445417404174748],
        [82.92958068847662, 17.445421218872127],
        [82.92958068847662, 17.445142745971737],
        [82.92903137207054, 17.445142745971737],
        [82.92903137207054, 17.444860458374023],
        [82.92846679687506, 17.444860458374023],
        [82.92846679687506, 17.444580078125057],
        [82.92791748046903, 17.44458389282238],
        [82.92791748046903, 17.444305419921818],
        [82.92736053466825, 17.444311141967773],
        [82.92736053466825, 17.444032669067383],
        [82.92679595947294, 17.444032669067383],
        [82.92679595947294, 17.44375038146984],
        [82.92624664306635, 17.44375038146984],
        [82.92624664306635, 17.44347190856945],
        [82.92541503906256, 17.44347190856945],
        [82.92541503906256, 17.443195343017635],
        [82.92402648925781, 17.443210601806754],
        [82.92402648925781, 17.44292259216303],
        [82.92318725585949, 17.44292259216303],
        [82.92318725585949, 17.442640304565487],
        [82.92208099365251, 17.442640304565487],
        [82.92208099365251, 17.442361831665096],
        [82.9218063354495, 17.442361831665096],
        [82.9218063354495, 17.442083358764705],
        [82.92069244384794, 17.442083358764705],
        [82.92069244384794, 17.441804885864315],
        [82.92013549804699, 17.441804885864315],
        [82.92013549804699, 17.441528320312557],
        [82.91931152343756, 17.441528320312557],
        [82.91931152343756, 17.441249847412166],
        [82.91847229003923, 17.441249847412166],
        [82.91847229003923, 17.440967559814396],
        [82.91764068603544, 17.440971374511776],
        [82.91764068603544, 17.44069480895996],
        [82.91708374023449, 17.44069480895996],
        [82.91708374023449, 17.44041633605957],
        [82.91623687744146, 17.440418243408203],
        [82.91623687744146, 17.44014930725109],
        [82.9157028198245, 17.44014930725109],
        [82.9157028198245, 17.439857482910213],
        [82.91486358642578, 17.439861297607422],
        [82.91486358642578, 17.43958282470703],
        [82.91430664062506, 17.43958282470703],
        [82.91430664062506, 17.439306259155217],
        [82.9134674072265, 17.43930816650402],
        [82.9134674072265, 17.43902969360363],
        [82.91291809082048, 17.43902969360363],
        [82.91291809082048, 17.43875122070324],
        [82.91208648681669, 17.43875122070324],
        [82.91208648681669, 17.4384708404541],
        [82.91152954101591, 17.438472747802848],
        [82.91152954101591, 17.438194274902457],
        [82.91069793701178, 17.438194274902457],
        [82.91069793701178, 17.4379177093507],
        [82.91014099121116, 17.437919616699276],
        [82.91014099121116, 17.437641143798885],
        [82.90930175781267, 17.437641143798885],
        [82.90930175781267, 17.43736076354992],
        [82.90847015380888, 17.43736076354992],
        [82.90847015380888, 17.43708038330078],
        [82.9079132080081, 17.437084197998104],
        [82.9079132080081, 17.436807632446346],
        [82.90708160400396, 17.436809539794922],
        [82.90708160400396, 17.43653106689453],
        [82.90680694580095, 17.43653106689453],
        [82.90680694580095, 17.436250686645565],
        [82.90625000000017, 17.436250686645565],
        [82.90625000000017, 17.435970306396598],
        [82.90569305419939, 17.435972213745174],
        [82.90569305419939, 17.43569564819336],
        [82.90513610839844, 17.43569564819336],
        [82.90513610839844, 17.435415267944393],
        [82.90429687500011, 17.435420989990348],
        [82.90429687500011, 17.43514060974121],
        [82.9037475585937, 17.43514060974121],
        [82.9037475585937, 17.434860229492244],
        [82.90347290039085, 17.434860229492244],
        [82.90347290039085, 17.434579849243278],
        [82.9029159545899, 17.434585571289006],
        [82.9029159545899, 17.434303283691463],
        [82.90235900878935, 17.434310913085994],
        [82.90235900878935, 17.434022903442496],
        [82.90180206298857, 17.43402862548828],
        [82.90180206298857, 17.43375015258789],
        [82.90123748779325, 17.43375015258789],
        [82.90123748779325, 17.433469772338924],
        [82.9006958007812, 17.4334716796875],
        [82.9006958007812, 17.43319320678711],
        [82.89985656738287, 17.43320083618164],
        [82.89985656738287, 17.432920455932674],
        [82.89930725097685, 17.432920455932674],
        [82.89930725097685, 17.432640075683707],
        [82.89875030517607, 17.432640075683707],
        [82.89875030517607, 17.43235969543457],
        [82.89791870117193, 17.432361602783317],
        [82.89791870117193, 17.43208122253418],
        [82.89736175537132, 17.43208122253418],
        [82.89736175537132, 17.431804656982536],
        [82.89680480957037, 17.431804656982536],
        [82.89680480957037, 17.431528091430778],
        [82.8959732055664, 17.43153190612793],
        [82.8959732055664, 17.431249618530387],
        [82.89541625976562, 17.431249618530387],
        [82.89541625976562, 17.430971145629996],
        [82.8948593139649, 17.430980682373104],
        [82.8948593139649, 17.430692672729606],
        [82.89402770996111, 17.430694580078182],
        [82.89402770996111, 17.43041610717779],
        [82.89347076416033, 17.430421829223746],
        [82.89347076416033, 17.430139541626033],
        [82.89290618896501, 17.430139541626033],
        [82.89290618896501, 17.429861068725643],
        [82.89208221435575, 17.429861068725643],
        [82.89208221435575, 17.429580688476676],
        [82.8915252685548, 17.429582595825252],
        [82.8915252685548, 17.429306030273438],
        [82.89068603515648, 17.429311752319393],
        [82.89068603515648, 17.42902946472168],
        [82.89013671875006, 17.42902946472168],
        [82.89013671875006, 17.428749084472713],
        [82.88929748535156, 17.428749084472713],
        [82.88929748535156, 17.428470611572322],
        [82.88874816894537, 17.4284725189209],
        [82.88874816894537, 17.428194046020508],
        [82.88791656494135, 17.428194046020508],
        [82.88791656494135, 17.427917480468693],
        [82.8873596191408, 17.427919387817496],
        [82.8873596191408, 17.427640914917106],
        [82.886528015137, 17.427640914917106],
        [82.886528015137, 17.42736053466797],
        [82.88569641113287, 17.42736053466797],
        [82.88569641113287, 17.42707824707037],
        [82.88486480712885, 17.427083969116325],
        [82.88486480712885, 17.426805496215763],
        [82.88458251953148, 17.426809310913143],
        [82.88458251953148, 17.426530838012752],
        [82.88403320312506, 17.426530838012752],
        [82.88403320312506, 17.426250457763786],
        [82.88346862792997, 17.426250457763786],
        [82.88346862792997, 17.425968170166016],
        [82.88319396972656, 17.425971984863395],
        [82.88319396972656, 17.42569541931158],
        [82.88291931152372, 17.425689697265625],
        [82.88291931152372, 17.425418853759822],
        [82.88236236572277, 17.4254207611084],
        [82.88236236572277, 17.425140380859432],
        [82.88208007812506, 17.425140380859432],
        [82.88208007812506, 17.42457962036127],
        [82.88180541992182, 17.42458343505865],
        [82.88180541992182, 17.424306869506836],
        [82.88153076171898, 17.424310684204215],
        [82.88153076171898, 17.42374992370611],
        [82.88124847412126, 17.42374992370611],
        [82.88124847412126, 17.423469543457145],
        [82.88097381591803, 17.42347145080572],
        [82.88097381591803, 17.423196792602482],
        [82.88069152832048, 17.423196792602482],
        [82.88069152832048, 17.42291450500494],
        [82.88041687011747, 17.422920227050895],
        [82.88041687011747, 17.422639846801758],
        [82.88014221191406, 17.422639846801758],
        [82.88014221191406, 17.42235946655279],
        [82.87985992431669, 17.422361373901367],
        [82.87985992431669, 17.422084808349553],
        [82.87958526611328, 17.422084808349553],
        [82.87958526611328, 17.42152786254877],
        [82.87930297851591, 17.421529769897575],
        [82.87930297851591, 17.421249389648438],
        [82.87902832031256, 17.421249389648438],
        [82.87902832031256, 17.420970916748047],
        [82.87875366210932, 17.42097473144537],
        [82.87875366210932, 17.420692443847656],
        [82.87847137451178, 17.420692443847656],
        [82.87847137451178, 17.42041587829584],
        [82.87790679931663, 17.42041969299322],
        [82.87790679931663, 17.42014122009283],
        [82.87486267089866, 17.42014122009283],
        [82.87486267089866, 17.42041969299322],
        [82.87430572509771, 17.42041587829584],
        [82.87430572509771, 17.420692443847656],
        [82.87374877929716, 17.420692443847656],
        [82.87374877929716, 17.42097473144537],
        [82.87346649169922, 17.420970916748047],
        [82.87346649169922, 17.421529769897575],
        [82.87319183349638, 17.42152786254877],
        [82.87319183349638, 17.422084808349553],
        [82.87291717529303, 17.4220809936524],
        [82.87291717529303, 17.422920227050895],
        [82.87264251708996, 17.42291450500494],
        [82.87264251708996, 17.42347145080572],
        [82.87291717529303, 17.42347145080572],
        [82.87291717529303, 17.42486190795904],
        [82.87319183349638, 17.42486190795904],
        [82.87319183349638, 17.425693511963004],
        [82.87346649169922, 17.425689697265625],
        [82.87346649169922, 17.42736053466797],
        [82.87319183349638, 17.42736053466797],
        [82.87319183349638, 17.4284725189209],
        [82.87346649169922, 17.428470611572322],
        [82.87346649169922, 17.42902946472168],
        [82.87374877929716, 17.42902946472168],
        [82.87374877929716, 17.429311752319393],
        [82.8740310668947, 17.429306030273438],
        [82.8740310668947, 17.429582595825252],
        [82.87430572509771, 17.429582595825252],
        [82.87430572509771, 17.429861068725643],
        [82.87486267089866, 17.429861068725643],
        [82.87486267089866, 17.430139541626033],
        [82.8751373291015, 17.430139541626033],
        [82.8751373291015, 17.430421829223746],
        [82.87596893310553, 17.43041610717779],
        [82.87596893310553, 17.430694580078182],
        [82.87625122070341, 17.430692672729606],
        [82.87625122070341, 17.430980682373104],
        [82.87680816650419, 17.430980682373104],
        [82.87680816650419, 17.431249618530387],
        [82.87735748291021, 17.431249618530387],
        [82.87735748291021, 17.43153190612793],
        [82.87763977050798, 17.431528091430778],
        [82.87763977050798, 17.43208122253418],
        [82.87791442871116, 17.43208122253418],
        [82.87791442871116, 17.432361602783317],
        [82.87819671630876, 17.43235969543457],
        [82.87819671630876, 17.432920455932674],
        [82.87847137451178, 17.432920455932674],
        [82.87847137451178, 17.43320083618164],
        [82.87875366210932, 17.43319320678711],
        [82.87875366210932, 17.4334716796875],
        [82.87902832031256, 17.433469772338924],
        [82.87902832031256, 17.43375015258789],
        [82.87930297851591, 17.43375015258789],
        [82.87930297851591, 17.434310913085994],
        [82.87958526611328, 17.434303283691463],
        [82.87958526611328, 17.434585571289006],
        [82.87985992431669, 17.434579849243278],
        [82.87985992431669, 17.434860229492244],
        [82.88014221191406, 17.434860229492244],
        [82.88014221191406, 17.435420989990348],
        [82.88041687011747, 17.435420989990348],
        [82.88041687011747, 17.436250686645565],
        [82.87958526611328, 17.436250686645565],
        [82.87958526611328, 17.435972213745174],
        [82.87930297851591, 17.435972213745174],
        [82.87930297851591, 17.435138702392635],
        [82.87902832031256, 17.43514060974121],
        [82.87902832031256, 17.434860229492244],
        [82.87874603271496, 17.434860229492244],
        [82.87874603271496, 17.434579849243278],
        [82.87847137451178, 17.434585571289006],
        [82.87847137451178, 17.434303283691463],
        [82.87819671630876, 17.434310913085994],
        [82.87819671630876, 17.434022903442496],
        [82.87791442871116, 17.43402862548828],
        [82.87791442871116, 17.43375015258789],
        [82.87763977050798, 17.43375015258789],
        [82.87763977050798, 17.433469772338924],
        [82.8770828247072, 17.4334716796875],
        [82.8770828247072, 17.43319320678711],
        [82.87652587890625, 17.43320083618164],
        [82.87652587890625, 17.432920455932674],
        [82.87625122070341, 17.432920455932674],
        [82.87625122070341, 17.432640075683707],
        [82.87568664550793, 17.432640075683707],
        [82.87568664550793, 17.43235969543457],
        [82.87541961669928, 17.432361602783317],
        [82.87541961669928, 17.43208122253418],
        [82.87374877929716, 17.43208122253418],
        [82.87374877929716, 17.431804656982536],
        [82.87347412109375, 17.431804656982536],
        [82.87347412109375, 17.431528091430778],
        [82.87319183349638, 17.43153190612793],
        [82.87319183349638, 17.431249618530387],
        [82.87291717529303, 17.431249618530387],
        [82.87291717529303, 17.430692672729606],
        [82.87264251708996, 17.430694580078182],
        [82.87264251708996, 17.43041610717779],
        [82.87236022949224, 17.430421829223746],
        [82.87236022949224, 17.429861068725643],
        [82.872085571289, 17.429861068725643],
        [82.872085571289, 17.429306030273438],
        [82.87180328369163, 17.429311752319393],
        [82.87180328369163, 17.428470611572322],
        [82.87152862548845, 17.4284725189209],
        [82.87152862548845, 17.425971984863395],
        [82.87124633789068, 17.425971984863395],
        [82.87124633789068, 17.425418853759822],
        [82.87069702148466, 17.4254207611084],
        [82.87069702148466, 17.425140380859432],
        [82.87040710449236, 17.425140380859432],
        [82.87040710449236, 17.42485046386713],
        [82.87014007568388, 17.42486190795904],
        [82.87014007568388, 17.42347145080572],
        [82.86985778808594, 17.42347145080572],
        [82.86985778808594, 17.42291450500494],
        [82.86958312988293, 17.422920227050895],
        [82.86958312988293, 17.422639846801758],
        [82.86902618408197, 17.422639846801758],
        [82.86902618408197, 17.42235946655279],
        [82.86875152587913, 17.422361373901367],
        [82.86875152587913, 17.421804428100586],
        [82.86820220947271, 17.42181015014654],
        [82.86820220947271, 17.42069053649908],
        [82.86791992187517, 17.420692443847656],
        [82.86791992187517, 17.419027328491268],
        [82.86820220947271, 17.419040679931584],
        [82.86820220947271, 17.41875076293951],
        [82.86846923828142, 17.41875076293951],
        [82.86846923828142, 17.418470382690543],
        [82.86902618408197, 17.41847229003912],
        [82.86902618408197, 17.41819381713873],
        [82.87014007568388, 17.41819381713873],
        [82.87014007568388, 17.417917251586914],
        [82.87069702148466, 17.417921066284293],
        [82.87069702148466, 17.417640686035156],
        [82.87097167968767, 17.417640686035156],
        [82.87097167968767, 17.41736030578619],
        [82.87126159667997, 17.41736030578619],
        [82.87124633789068, 17.416528701782227],
        [82.87069702148466, 17.41653251647955],
        [82.87069702148466, 17.416250228881836],
        [82.87014007568388, 17.416250228881836],
        [82.87014007568388, 17.415979385375977],
        [82.86985778808594, 17.415979385375977],
        [82.86985778808594, 17.415702819824162],
        [82.86930847167974, 17.415702819824162],
        [82.86930847167974, 17.415422439575195],
        [82.86791992187517, 17.415422439575195],
        [82.86791992187517, 17.415140151977653],
        [82.86708068847685, 17.415140151977653],
        [82.86708068847685, 17.414859771728516],
        [82.86653137207043, 17.414861679077262],
        [82.86653137207043, 17.41458320617687],
        [82.86569213867193, 17.41458320617687],
        [82.86569213867193, 17.41430473327648],
        [82.86486053466814, 17.41430473327648],
        [82.86486053466814, 17.414028167724723],
        [82.86429595947283, 17.414028167724723],
        [82.86429595947283, 17.413749694824332],
        [82.86291503906261, 17.413749694824332],
        [82.86291503906261, 17.413473129272518],
        [82.86125183105486, 17.413473129272518],
        [82.86125183105486, 17.413194656372127],
        [82.8595809936524, 17.413194656372127],
        [82.8595809936524, 17.412916183471737],
        [82.85874938964861, 17.412916183471737],
        [82.85874938964861, 17.41263961791998],
        [82.85791778564482, 17.41263961791998],
        [82.85791778564482, 17.412361145019588],
        [82.85708618164068, 17.412361145019588],
        [82.85708618164068, 17.41208076477062],
        [82.8565292358399, 17.412082672119197],
        [82.8565292358399, 17.411806106567383],
        [82.85569763183611, 17.411809921264762],
        [82.85569763183611, 17.411529541015625],
        [82.85485839843778, 17.411529541015625],
        [82.85485839843778, 17.411251068115234],
        [82.8540267944336, 17.411251068115234],
        [82.8540267944336, 17.410970687866268],
        [82.85319519042963, 17.410972595214844],
        [82.85319519042963, 17.410694122314453],
        [82.85236358642584, 17.410694122314453],
        [82.85236358642584, 17.41041755676264],
        [82.85180664062528, 17.410419464111442],
        [82.85180664062528, 17.41014099121105],
        [82.85098266601568, 17.41014099121105],
        [82.85098266601568, 17.40985107421875],
        [82.85013580322277, 17.409860610961914],
        [82.85013580322277, 17.409585952758846],
        [82.84930419921898, 17.409585952758846],
        [82.84930419921898, 17.409307479858512],
        [82.84847259521501, 17.409309387207088],
        [82.84847259521501, 17.409030914306697],
        [82.84764099121122, 17.409030914306697],
        [82.84764099121122, 17.40875053405773],
        [82.84708404541027, 17.40875053405773],
        [82.84708404541027, 17.40847206115734],
        [82.84625244140648, 17.40847206115734],
        [82.84625244140648, 17.40819358825695],
        [82.84541320800798, 17.40819358825695],
        [82.84541320800798, 17.40791511535656],
        [82.84458160400419, 17.407920837402344],
        [82.84458160400419, 17.407640457153377],
        [82.84430694580084, 17.407640457153377],
        [82.84430694580084, 17.40736007690441],
        [82.84347534179682, 17.407363891601562],
        [82.84347534179682, 17.407085418701172],
        [82.8426361083985, 17.407085418701172],
        [82.8426361083985, 17.40680313110363],
        [82.84152984619169, 17.40681076049816],
        [82.84152984619169, 17.406530380249023],
        [82.84069824218756, 17.406530380249023],
        [82.84069061279303, 17.40625],
        [82.83957672119163, 17.40625],
        [82.83957672119163, 17.40596961975109],
        [82.83902740478544, 17.405971527099666],
        [82.83902740478544, 17.405693054199276],
        [82.83818817138672, 17.405700683593807],
        [82.83818817138672, 17.40542030334484],
        [82.83763885498053, 17.40542030334484],
        [82.83763885498053, 17.405139923095703],
        [82.83708190917974, 17.405139923095703],
        [82.83708190917974, 17.404859542846737],
        [82.83625030517595, 17.404861450195312],
        [82.83625030517595, 17.404581069946346],
        [82.83569335937517, 17.404581069946346],
        [82.83569335937517, 17.404306411743107],
        [82.83515167236334, 17.404310226440487],
        [82.83515167236334, 17.404039382934627],
        [82.83458709716803, 17.404039382934627],
        [82.83458709716803, 17.403749465942383],
        [82.83374786376947, 17.403749465942383],
        [82.83374786376947, 17.40347290039074],
        [82.83319091796892, 17.40347290039074],
        [82.83319091796892, 17.403194427490348],
        [82.83264160156267, 17.403194427490348],
        [82.83264160156267, 17.402915954589787],
        [82.83180236816435, 17.402921676635742],
        [82.83180236816435, 17.4026393890382],
        [82.83125305175793, 17.4026393890382],
        [82.83125305175793, 17.40236091613781],
        [82.83069610595697, 17.40236091613781],
        [82.83069610595697, 17.402080535888672],
        [82.82986450195318, 17.40208244323742],
        [82.82986450195318, 17.401805877685604],
        [82.82930755615263, 17.40181159973156],
        [82.82930755615263, 17.401529312133846],
        [82.82875061035185, 17.401529312133846],
        [82.82875061035185, 17.401250839233455],
        [82.82791900634771, 17.401250839233455],
        [82.82791900634771, 17.40097045898449],
        [82.8273620605471, 17.400972366333065],
        [82.8273620605471, 17.400693893432674],
        [82.82680511474615, 17.400693893432674],
        [82.82680511474615, 17.40041732788086],
        [82.8259582519533, 17.400419235229492],
        [82.8259582519533, 17.4001407623291],
        [82.8254165649414, 17.4001407623291],
        [82.8254165649414, 17.399860382080135],
        [82.82485961914068, 17.399860382080135],
        [82.82485961914068, 17.399578094482365],
        [82.8243026733399, 17.39958381652832],
        [82.8243026733399, 17.39930534362793],
        [82.82347106933611, 17.39930915832531],
        [82.82347106933611, 17.39903068542492],
        [82.82292175292969, 17.39903068542492],
        [82.82292175292969, 17.39875030517578],
        [82.82235717773438, 17.39875030517578],
        [82.82235717773438, 17.398469924926815],
        [82.82152557373058, 17.39847183227539],
        [82.82152557373058, 17.398195266723576],
        [82.82096862792963, 17.398199081420955],
        [82.82096862792963, 17.397911071777457],
        [82.82041931152361, 17.39791870117199],
        [82.82041931152361, 17.39763641357422],
        [82.81986236572283, 17.397640228271598],
        [82.81986236572283, 17.39735984802246],
        [82.81958007812528, 17.397361755371207],
        [82.81958007812528, 17.397083282470817],
        [82.81874847412115, 17.397083282470817],
        [82.81874847412115, 17.396806716919002],
        [82.81819152832037, 17.396806716919002],
        [82.81819152832037, 17.39652824401867],
        [82.81791687011713, 17.396530151367244],
        [82.81791687011713, 17.396249771118278],
        [82.81735992431658, 17.396249771118278],
        [82.81735992431658, 17.395969390869084],
        [82.8168029785158, 17.395971298217887],
        [82.8168029785158, 17.39569664001465],
        [82.81625366210938, 17.39569664001465],
        [82.81625366210938, 17.395414352417106],
        [82.81569671630865, 17.39542007446289],
        [82.81569671630865, 17.395139694213924],
        [82.8148574829101, 17.395139694213924],
        [82.8148574829101, 17.394849777221793],
        [82.81430816650408, 17.394861221313533],
        [82.81430816650408, 17.394582748413143],
        [82.8137512207033, 17.394582748413143],
        [82.8137512207033, 17.394304275512752],
        [82.81318664550798, 17.394309997558707],
        [82.81318664550798, 17.39402961730957],
        [82.81236267089855, 17.39402961730957],
        [82.81236267089855, 17.39375114440918],
        [82.81181335449213, 17.39375114440918],
        [82.81181335449213, 17.393470764160213],
        [82.81124877929705, 17.393474578857365],
        [82.81124877929705, 17.393192291259822],
        [82.81069183349626, 17.393192291259822],
        [82.81069183349626, 17.392917633056584],
        [82.81014251708984, 17.392919540405387],
        [82.81014251708984, 17.392641067504996],
        [82.80930328369169, 17.392641067504996],
        [82.80930328369169, 17.39236068725586],
        [82.80874633789074, 17.39236068725586],
        [82.80874633789074, 17.392080307006893],
        [82.80819702148455, 17.39208221435547],
        [82.80819702148455, 17.391805648803654],
        [82.80764007568376, 17.391809463501033],
        [82.80764007568376, 17.39153289794922],
        [82.80708312988298, 17.39153289794922],
        [82.80708312988298, 17.391250610351676],
        [82.80625152587919, 17.391250610351676],
        [82.80625152587919, 17.39097023010254],
        [82.80569458007824, 17.39097023010254],
        [82.80569458007824, 17.390693664550895],
        [82.80513763427729, 17.390693664550895],
        [82.80513763427729, 17.39041709899908],
        [82.80458068847673, 17.390422821044865],
        [82.80458068847673, 17.390140533447322],
        [82.80374908447294, 17.390140533447322],
        [82.80374908447294, 17.389860153198356],
        [82.80319213867216, 17.389860153198356],
        [82.80319213867216, 17.38958358764654],
        [82.80263519287121, 17.38958358764654],
        [82.80263519287121, 17.38930511474615],
        [82.80207824707026, 17.389312744140682],
        [82.80207824707026, 17.38903045654297],
        [82.80152893066423, 17.38903045654297],
        [82.80152893066423, 17.388750076294002],
        [82.80069732666044, 17.388750076294002],
        [82.80069732666044, 17.38847160339361],
        [82.80014038085966, 17.38847160339361],
        [82.80014038085966, 17.388195037841797],
        [82.79957580566435, 17.388202667236328],
        [82.79957580566435, 17.38791847229004],
        [82.79904174804705, 17.38791847229004],
        [82.79904174804705, 17.387638092041016],
        [82.7981872558596, 17.387638092041016],
        [82.7981872558596, 17.387359619140682],
        [82.79763793945341, 17.387361526489258],
        [82.79763793945341, 17.387083053588867],
        [82.79708099365263, 17.387083053588867],
        [82.79708099365263, 17.386806488037053],
        [82.79651641845732, 17.386808395385856],
        [82.79651641845732, 17.386529922485465],
        [82.79569244384771, 17.386529922485465],
        [82.79569244384771, 17.386249542236328],
        [82.7951354980471, 17.386249542236328],
        [82.7951354980471, 17.385972976684684],
        [82.79458618164091, 17.385972976684684],
        [82.79458618164091, 17.385694503784293],
        [82.79374694824219, 17.385694503784293],
        [82.79374694824219, 17.385417938232536],
        [82.793197631836, 17.38541984558111],
        [82.793197631836, 17.385139465332145],
        [82.79264068603521, 17.385139465332145],
        [82.79264068603521, 17.384860992431754],
        [82.79180908203142, 17.384860992431754],
        [82.79180908203142, 17.384580612182617],
        [82.79125213623064, 17.384582519531364],
        [82.79125213623064, 17.384307861328182],
        [82.79070281982422, 17.384309768676758],
        [82.79070281982422, 17.38402938842779],
        [82.789848327637, 17.38402938842779],
        [82.789848327637, 17.3837509155274],
        [82.78930664062494, 17.3837509155274],
        [82.78930664062494, 17.383470535278434],
        [82.78874969482439, 17.38347244262701],
        [82.78874969482439, 17.383195877075195],
        [82.78819274902361, 17.383195877075195],
        [82.78819274902361, 17.382917404174805],
        [82.78736114501982, 17.382917404174805],
        [82.78736114501982, 17.38263893127447],
        [82.78678894042997, 17.382640838623047],
        [82.78678894042997, 17.38236045837408],
        [82.78624725341791, 17.38236045837408],
        [82.78624725341791, 17.382080078125114],
        [82.78541564941412, 17.38208580017084],
        [82.78541564941412, 17.3818035125733],
        [82.78485870361357, 17.38181114196783],
        [82.78485870361357, 17.381530761718864],
        [82.78430175781278, 17.381530761718864],
        [82.78430175781278, 17.381250381469727],
        [82.78347015380865, 17.381250381469727],
        [82.78347015380865, 17.38097000122076],
        [82.78291320800787, 17.380971908569336],
        [82.78291320800787, 17.380693435668945],
        [82.78235626220726, 17.380701065063477],
        [82.78235626220726, 17.38042068481451],
        [82.78151702880876, 17.38042068481451],
        [82.78151702880876, 17.380140304565543],
        [82.78096771240234, 17.380140304565543],
        [82.78096771240234, 17.379869461059684],
        [82.78041839599615, 17.379869461059684],
        [82.78041839599615, 17.379579544067383],
        [82.77958679199236, 17.379583358764762],
        [82.77958679199236, 17.37930488586437],
        [82.77902984619158, 17.37930488586437],
        [82.77902984619158, 17.379028320312614],
        [82.7784729003908, 17.37903022766119],
        [82.7784729003908, 17.378749847412223],
        [82.777641296387, 17.378749847412223],
        [82.777641296387, 17.37846946716303],
        [82.77708435058605, 17.378471374511832],
        [82.77708435058605, 17.378194808960018],
        [82.7765274047851, 17.378194808960018],
        [82.7765274047851, 17.377916336059627],
        [82.77597045898455, 17.377920150756836],
        [82.77597045898455, 17.37763977050787],
        [82.77513885498075, 17.37763977050787],
        [82.77513885498075, 17.377359390258903],
        [82.77458190917997, 17.37736129760748],
        [82.77458190917997, 17.377082824707088],
        [82.77402496337902, 17.377082824707088],
        [82.77402496337902, 17.376806259155273],
        [82.77319335937523, 17.376810073852653],
        [82.77319335937523, 17.376529693603516],
        [82.77263641357428, 17.376529693603516],
        [82.77263641357428, 17.37624931335455],
        [82.77210235595732, 17.37624931335455],
        [82.77210235595732, 17.37597084045416],
        [82.77153015136747, 17.375972747802734],
        [82.77153015136747, 17.375694274902344],
        [82.7706909179688, 17.375694274902344],
        [82.7706909179688, 17.375415802001953],
        [82.77014160156273, 17.375419616699332],
        [82.77014160156273, 17.375139236450195],
        [82.76957702636741, 17.375139236450195],
        [82.76957702636741, 17.374860763549805],
        [82.76902770996122, 17.374860763549805],
        [82.76902770996122, 17.374578475952205],
        [82.76819610595703, 17.37458419799816],
        [82.76819610595703, 17.3743057250976],
        [82.7676391601563, 17.374311447143555],
        [82.7676391601563, 17.374029159546012],
        [82.76708221435553, 17.374029159546012],
        [82.76708221435553, 17.373741149902287],
        [82.76625061035173, 17.37375068664562],
        [82.76625061035173, 17.37347221374523],
        [82.76569366455095, 17.37347221374523],
        [82.76569366455095, 17.37319374084484],
        [82.76513671875, 17.37319374084484],
        [82.76513671875, 17.372917175293026],
        [82.76431274414057, 17.37291908264166],
        [82.76431274414057, 17.372640609741268],
        [82.76374816894526, 17.372640609741268],
        [82.76374816894526, 17.3723602294923],
        [82.7631912231447, 17.3723602294923],
        [82.7631912231447, 17.372079849243107],
        [82.76264190673845, 17.372083663940487],
        [82.76264190673845, 17.371805191040096],
        [82.76180267334013, 17.37181091308605],
        [82.76180267334013, 17.371530532836914],
        [82.76125335693371, 17.371530532836914],
        [82.76125335693371, 17.371250152587947],
        [82.76069641113276, 17.371250152587947],
        [82.76069641113276, 17.37096977233898],
        [82.7601394653322, 17.370971679687557],
        [82.7601394653322, 17.370695114135742],
        [82.75930786132841, 17.370700836181697],
        [82.75930786132841, 17.37042045593273],
        [82.75875091552763, 17.37042045593273],
        [82.75875091552763, 17.370140075683594],
        [82.7579193115235, 17.370140075683594],
        [82.7579193115235, 17.369859695434627],
        [82.75736236572288, 17.369861602783203],
        [82.75736236572288, 17.369583129882812],
        [82.75680541992193, 17.369583129882812],
        [82.75680541992193, 17.369306564330998],
        [82.75624847412138, 17.369306564330998],
        [82.75624847412138, 17.369028091430607],
        [82.7556915283206, 17.36902999877941],
        [82.7556915283206, 17.368749618530273],
        [82.75514221191418, 17.368749618530273],
        [82.75514221191418, 17.368471145629883],
        [82.75458526611322, 17.36847305297863],
        [82.75458526611322, 17.368196487426815],
        [82.75402832031267, 17.368196487426815],
        [82.75402832031267, 17.3679141998291],
        [82.75373840332054, 17.367929458618164],
        [82.75373840332054, 17.36763954162609],
        [82.75319671630888, 17.36763954162609],
        [82.75319671630888, 17.3673610687257],
        [82.7526397705081, 17.3673610687257],
        [82.7526397705081, 17.367080688476562],
        [82.75208282470732, 17.36708259582531],
        [82.75208282470732, 17.36680412292492],
        [82.75152587890636, 17.366809844970703],
        [82.75152587890636, 17.366529464721737],
        [82.75096893310541, 17.366529464721737],
        [82.75096893310541, 17.366252899169922],
        [82.75041961669939, 17.366252899169922],
        [82.75041961669939, 17.36597061157238],
        [82.75013732910162, 17.365972518920955],
        [82.75013732910162, 17.36569213867199],
        [82.74958038330107, 17.36569213867199],
        [82.74958038330107, 17.36541748046875],
        [82.74903106689482, 17.365419387817383],
        [82.74903106689482, 17.36514282226574],
        [82.74846649169933, 17.36514282226574],
        [82.74846649169933, 17.364870071411133],
        [82.74819183349615, 17.364870071411133],
        [82.74819183349615, 17.36458015441906],
        [82.74764251709007, 17.364582061767635],
        [82.74764251709007, 17.36430549621582],
        [82.74709320068388, 17.364311218261776],
        [82.74709320068388, 17.364032745361385],
        [82.74624633789062, 17.364032745361385],
        [82.74624633789062, 17.363750457763672],
        [82.74540710449247, 17.363750457763672],
        [82.74540710449247, 17.363470077514705],
        [82.74485778808588, 17.363470077514705],
        [82.74485778808588, 17.363195419311467],
        [82.74430847167986, 17.363191604614315],
        [82.74430847167986, 17.362916946411076],
        [82.74346923828153, 17.36292266845703],
        [82.74346923828153, 17.36264038085949],
        [82.74291992187528, 17.36264038085949],
        [82.74291992187528, 17.362361907959098],
        [82.74208068847662, 17.362361907959098],
        [82.74208068847662, 17.362083435058707],
        [82.74153137207054, 17.362083435058707],
        [82.74153137207054, 17.361804962158317],
        [82.74097442626959, 17.361804962158317],
        [82.74097442626959, 17.36152839660656],
        [82.74014282226562, 17.361530303955135],
        [82.74014282226562, 17.36124992370617],
        [82.73986053466825, 17.36124992370617],
        [82.73986053466825, 17.3609676361084],
        [82.73874664306652, 17.360971450805778],
        [82.73874664306652, 17.360694885253963],
        [82.73847198486334, 17.360694885253963],
        [82.73847198486334, 17.360416412353572],
        [82.73764038085955, 17.360418319702205],
        [82.73764038085955, 17.360139846801815],
        [82.73735809326178, 17.360139846801815],
        [82.73735809326178, 17.359859466552848],
        [82.73708343505876, 17.359861373901424],
        [82.73708343505876, 17.359582901001033],
        [82.73680877685575, 17.359582901001033],
        [82.73680877685575, 17.35930633544922],
        [82.73542022705084, 17.35930824279785],
        [82.73542022705084, 17.35902976989746],
        [82.73486328125023, 17.35902976989746],
        [82.73486328125023, 17.35873985290533],
        [82.73430633544928, 17.358749389648494],
        [82.73430633544928, 17.35847282409668],
        [82.73374938964872, 17.35847282409668],
        [82.73374938964872, 17.35819435119629],
        [82.73347473144531, 17.35819435119629],
        [82.73347473144531, 17.357917785644474],
        [82.73293304443365, 17.357919692993278],
        [82.73293304443365, 17.357641220092887],
        [82.7323608398438, 17.357641220092887],
        [82.7323608398438, 17.35736083984375],
        [82.7317962646485, 17.35736083984375],
        [82.7317962646485, 17.357080459594783],
        [82.73124694824224, 17.357084274292106],
        [82.73124694824224, 17.356807708740348],
        [82.73069763183622, 17.356809616088924],
        [82.73069763183622, 17.356531143188533],
        [82.73014068603544, 17.356531143188533],
        [82.73014068603544, 17.356250762939567],
        [82.7295684814456, 17.356250762939567],
        [82.7295684814456, 17.35597038269043],
        [82.72902679443354, 17.355972290039176],
        [82.72902679443354, 17.35569572448736],
        [82.72846984863298, 17.35569953918457],
        [82.72846984863298, 17.35542106628418],
        [82.7279129028322, 17.35542106628418],
        [82.7279129028322, 17.355140686035213],
        [82.72735595703142, 17.355140686035213],
        [82.72735595703142, 17.354860305786246],
        [82.72679901123047, 17.354860305786246],
        [82.72679901123047, 17.354579925537053],
        [82.72624969482428, 17.354585647583008],
        [82.72624969482428, 17.354303359985465],
        [82.7256927490235, 17.354310989379996],
        [82.7256927490235, 17.35403060913086],
        [82.72513580322288, 17.35403060913086],
        [82.72513580322288, 17.353750228881893],
        [82.72458648681669, 17.353750228881893],
        [82.72458648681669, 17.353469848632926],
        [82.72374725341797, 17.353471755981502],
        [82.72374725341797, 17.35319328308111],
        [82.72319793701178, 17.353200912475643],
        [82.72319793701178, 17.352930068969783],
        [82.722640991211, 17.352930068969783],
        [82.722640991211, 17.35264015197754],
        [82.72207641601585, 17.35264015197754],
        [82.72207641601585, 17.352359771728572],
        [82.72152709960943, 17.35236167907715],
        [82.72152709960943, 17.352081298828182],
        [82.72097015380888, 17.352081298828182],
        [82.72097015380888, 17.351804733276367],
        [82.7204132080081, 17.351804733276367],
        [82.7204132080081, 17.351528167724553],
        [82.71985626220714, 17.351530075073356],
        [82.71985626220714, 17.35124969482422],
        [82.71930694580072, 17.35124969482422],
        [82.71930694580072, 17.350971221923828],
        [82.71875000000017, 17.350971221923828],
        [82.71875000000017, 17.350694656372184],
        [82.71819305419939, 17.350694656372184],
        [82.71819305419939, 17.350416183471623],
        [82.71762847900408, 17.350421905517578],
        [82.71762847900408, 17.350139617920036],
        [82.71708679199224, 17.350139617920036],
        [82.71708679199224, 17.349861145019645],
        [82.71652984619146, 17.349861145019645],
        [82.71652984619146, 17.349571228027344],
        [82.71597290039085, 17.349582672119254],
        [82.71597290039085, 17.34930610656744],
        [82.7154159545899, 17.349311828613395],
        [82.7154159545899, 17.349029541015682],
        [82.71514129638689, 17.349029541015682],
        [82.71514129638689, 17.348749160766715],
        [82.71456909179705, 17.348749160766715],
        [82.71456909179705, 17.348470687866325],
        [82.71402740478516, 17.3484725952149],
        [82.71402740478516, 17.34819412231451],
        [82.71347045898443, 17.34819412231451],
        [82.71347045898443, 17.347917556762695],
        [82.71290588378912, 17.347919464111328],
        [82.71290588378912, 17.34763908386236],
        [82.71235656738304, 17.34763908386236],
        [82.71235656738304, 17.34736061096197],
        [82.71180725097685, 17.34736061096197],
        [82.71180725097685, 17.347084045410156],
        [82.71125030517607, 17.347084045410156],
        [82.71125030517607, 17.346805572509766],
        [82.71069335937528, 17.346805572509766],
        [82.71069335937528, 17.346527099609375],
        [82.71013641357433, 17.346530914306754],
        [82.71013641357433, 17.346250534057617],
        [82.70958709716814, 17.346250534057617],
        [82.70958709716814, 17.345968246460018],
        [82.70903015136736, 17.345972061157227],
        [82.70903015136736, 17.345693588256836],
        [82.70847320556658, 17.345693588256836],
        [82.70847320556658, 17.345418930053825],
        [82.70791625976562, 17.3454208374024],
        [82.70791625976562, 17.345140457153434],
        [82.7073593139649, 17.345140457153434],
        [82.7073593139649, 17.344860076904297],
        [82.70652770996088, 17.344861984253043],
        [82.70652770996088, 17.344583511352653],
        [82.70625305175804, 17.344583511352653],
        [82.70625305175804, 17.344306945800838],
        [82.70597076416033, 17.344310760498047],
        [82.70597076416033, 17.34403038024908],
        [82.70568847656256, 17.34403038024908],
        [82.70568847656256, 17.34375953674322],
        [82.70347595214838, 17.34375953674322],
        [82.70347595214838, 17.34346961975092],
        [82.70291900634783, 17.343471527099723],
        [82.70291900634783, 17.343196868896484],
        [82.70236206054705, 17.343200683593864],
        [82.70236206054705, 17.342920303344727],
        [82.7020797729495, 17.342920303344727],
        [82.7020797729495, 17.34263992309576],
        [82.70153045654325, 17.34263992309576],
        [82.70153045654325, 17.342359542846793],
        [82.7009735107423, 17.34236145019537],
        [82.7009735107423, 17.342084884643555],
        [82.70041656494135, 17.342084884643555],
        [82.70041656494135, 17.341806411743164],
        [82.6998596191408, 17.341810226440543],
        [82.6998596191408, 17.341529846191406],
        [82.69930267334001, 17.341529846191406],
        [82.69930267334001, 17.34124946594244],
        [82.699028015137, 17.34124946594244],
        [82.699028015137, 17.34097099304205],
        [82.69847106933622, 17.3409748077392],
        [82.69847106933622, 17.34069252014166],
        [82.69790649414091, 17.34070014953619],
        [82.69790649414091, 17.340419769287223],
        [82.69735717773449, 17.340419769287223],
        [82.69735717773449, 17.340139389038086],
        [82.6968078613283, 17.340139389038086],
        [82.6968078613283, 17.339860916137695],
        [82.69625091552751, 17.339860916137695],
        [82.69625091552751, 17.33958053588873],
        [82.69569396972673, 17.339582443237305],
        [82.69569396972673, 17.33930587768549],
        [82.69513702392578, 17.33930969238287],
        [82.69513702392578, 17.33903121948248],
        [82.69458007812506, 17.33903121948248],
        [82.69458007812506, 17.338750839233512],
        [82.6940307617188, 17.338750839233512],
        [82.6940307617188, 17.338470458984375],
        [82.6934738159182, 17.33847236633312],
        [82.6934738159182, 17.33819389343273],
        [82.69291687011724, 17.33819389343273],
        [82.69291687011724, 17.337917327880916],
        [82.69264221191423, 17.337921142578125],
        [82.69264221191423, 17.33762931823742],
        [82.69208526611328, 17.337638854980582],
        [82.69208526611328, 17.337360382080192],
        [82.69152832031256, 17.337360382080192],
        [82.69152832031256, 17.3370819091798],
        [82.69097137451178, 17.337083816528377],
        [82.69097137451178, 17.336805343627987],
        [82.69039916992193, 17.336811065673942],
        [82.69039916992193, 17.33653259277355],
        [82.68985748291021, 17.33653259277355],
        [82.68985748291021, 17.336250305175838],
        [82.68930816650419, 17.336250305175838],
        [82.68930816650419, 17.335971832275447],
        [82.68875122070341, 17.335971832275447],
        [82.68875122070341, 17.335695266723633],
        [82.6881866455081, 17.335702896118164],
        [82.6881866455081, 17.335422515869197],
        [82.6876525878908, 17.335422515869197],
        [82.6876525878908, 17.335140228271484],
        [82.68708038330095, 17.335140228271484],
        [82.68708038330095, 17.334861755371094],
        [82.6865310668947, 17.334861755371094],
        [82.6865310668947, 17.334583282470703],
        [82.68597412109375, 17.334583282470703],
        [82.68597412109375, 17.334304809570312],
        [82.68569183349638, 17.334304809570312],
        [82.68569183349638, 17.334028244018498],
        [82.68514251708996, 17.334028244018498],
        [82.68514251708996, 17.333749771118164],
        [82.684585571289, 17.333749771118164],
        [82.684585571289, 17.333471298217773],
        [82.68402862548845, 17.333471298217773],
        [82.68402862548845, 17.33319473266613],
        [82.68347167968767, 17.33319473266613],
        [82.68347167968767, 17.332916259765568],
        [82.68290710449236, 17.33291816711437],
        [82.68290710449236, 17.33263969421398],
        [82.68264007568388, 17.33263969421398],
        [82.68264007568388, 17.33236122131359],
        [82.6820831298831, 17.33236122131359],
        [82.6820831298831, 17.332080841064453],
        [82.68152618408214, 17.3320827484132],
        [82.68152618408214, 17.331806182861385],
        [82.6809692382812, 17.331821441650504],
        [82.6809692382812, 17.331529617309627],
        [82.68041992187517, 17.331529617309627],
        [82.68041992187517, 17.331251144409237],
        [82.67986297607439, 17.331251144409237],
        [82.67986297607439, 17.33097076416027],
        [82.67958068847685, 17.330972671508846],
        [82.67958068847685, 17.330694198608455],
        [82.6790313720706, 17.330694198608455],
        [82.6790313720706, 17.33041763305664],
        [82.67845916748075, 17.330419540405273],
        [82.67845916748075, 17.330141067504883],
        [82.6779174804687, 17.330141067504883],
        [82.6779174804687, 17.329860687255916],
        [82.67736053466814, 17.329860687255916],
        [82.67736053466814, 17.32958030700695],
        [82.67680358886736, 17.3295841217041],
        [82.67680358886736, 17.329307556152287],
        [82.67652893066435, 17.32930946350109],
        [82.67652893066435, 17.3290309906007],
        [82.67597198486357, 17.3290309906007],
        [82.67597198486357, 17.32876014709484],
        [82.67542266845714, 17.32876014709484],
        [82.67542266845714, 17.328470230102596],
        [82.67485809326166, 17.328472137451172],
        [82.67485809326166, 17.328195571899357],
        [82.67430877685564, 17.328195571899357],
        [82.67430877685564, 17.32791519165039],
        [82.67375183105486, 17.327920913696346],
        [82.67375183105486, 17.32764053344738],
        [82.67346954345732, 17.32764053344738],
        [82.67346954345732, 17.327360153198242],
        [82.67292022705107, 17.32736206054699],
        [82.67292022705107, 17.327085494995174],
        [82.67236328125011, 17.327085494995174],
        [82.67236328125011, 17.32680320739746],
        [82.67180633544916, 17.326810836791992],
        [82.67180633544916, 17.326530456543026],
        [82.67151641845709, 17.326530456543026],
        [82.67151641845709, 17.32625007629406],
        [82.67096710205101, 17.32625007629406],
        [82.67096710205101, 17.325969696044865],
        [82.67041778564482, 17.32597160339367],
        [82.67041778564482, 17.325693130493278],
        [82.66986083984403, 17.325693130493278],
        [82.66986083984403, 17.325416564941463],
        [82.6695785522461, 17.325420379638672],
        [82.6695785522461, 17.325139999389705],
        [82.6690292358399, 17.325139999389705],
        [82.6690292358399, 17.32485961914074],
        [82.66847229003912, 17.324861526489315],
        [82.66847229003912, 17.324581146240348],
        [82.66791534423851, 17.324581146240348],
        [82.66791534423851, 17.32430648803711],
        [82.66735839843756, 17.32431030273449],
        [82.66735839843756, 17.324031829834098],
        [82.66707611084001, 17.324031829834098],
        [82.66707611084001, 17.323749542236385],
        [82.66651916503906, 17.323749542236385],
        [82.66651916503906, 17.323471069335994],
        [82.66596984863287, 17.323471069335994],
        [82.66596984863287, 17.32319450378418],
        [82.66541290283209, 17.32319450378418],
        [82.66541290283209, 17.32291603088379],
        [82.66513824462885, 17.322921752929744],
        [82.66513824462885, 17.322629928588867],
        [82.6645812988283, 17.32263946533203],
        [82.6645812988283, 17.322359085083008],
        [82.66401672363298, 17.322359085083008],
        [82.66401672363298, 17.322080612182674],
        [82.6634750366211, 17.32208251953125],
        [82.6634750366211, 17.321805953979435],
        [82.66319274902372, 17.32181167602539],
        [82.66319274902372, 17.321529388427848],
        [82.66263580322277, 17.321529388427848],
        [82.66263580322277, 17.321250915527457],
        [82.66208648681635, 17.321250915527457],
        [82.66208648681635, 17.32097053527832],
        [82.6615295410158, 17.320972442627067],
        [82.6615295410158, 17.320693969726676],
        [82.66124725341803, 17.320693969726676],
        [82.66124725341803, 17.32041740417486],
        [82.66071319580107, 17.320419311523494],
        [82.66071319580107, 17.320140838623104],
        [82.6604232788086, 17.320140838623104],
        [82.6604232788086, 17.319860458374137],
        [82.66014099121122, 17.319860458374137],
        [82.66014099121122, 17.31958770751953],
        [82.65985870361328, 17.31958770751953],
        [82.65985870361328, 17.31930923461914],
        [82.65930175781256, 17.31930923461914],
        [82.65930175781256, 17.31903076171875],
        [82.65875244140648, 17.31903076171875],
        [82.65875244140648, 17.318750381469783],
        [82.65819549560553, 17.318750381469783],
        [82.65819549560553, 17.318471908569393],
        [82.65791320800798, 17.318471908569393],
        [82.65791320800798, 17.318195343017578],
        [82.6573562622072, 17.318201065063533],
        [82.6573562622072, 17.317920684814567],
        [82.65708160400419, 17.317920684814567],
        [82.65708160400419, 17.31764030456543],
        [82.65651702880888, 17.31764030456543],
        [82.65651702880888, 17.317359924316463],
        [82.65597534179682, 17.31736183166504],
        [82.65597534179682, 17.31708335876465],
        [82.65513610839866, 17.31708335876465],
        [82.65513610839866, 17.316806793212834],
        [82.65458679199247, 17.316806793212834],
        [82.65458679199247, 17.316528320312443],
        [82.65428924560564, 17.316530227661246],
        [82.65428924560564, 17.31624984741211],
        [82.65402984619169, 17.31624984741211],
        [82.65402984619169, 17.315969467163086],
        [82.65347290039091, 17.31597137451172],
        [82.65347290039091, 17.31569671630865],
        [82.65291595458996, 17.31569671630865],
        [82.65291595458996, 17.315414428710938],
        [82.65264129638678, 17.315420150756893],
        [82.65264129638678, 17.315139770507926],
        [82.65207672119163, 17.315139770507926],
        [82.65207672119163, 17.314859390258732],
        [82.65152740478521, 17.314861297607536],
        [82.65152740478521, 17.31458473205572],
        [82.65069580078125, 17.31458473205572],
        [82.65069580078125, 17.314304351806754],
        [82.65013885498053, 17.31431007385254],
        [82.65013885498053, 17.314029693603572],
        [82.64985656738293, 17.314029693603572],
        [82.64985656738293, 17.313751220703182],
        [82.64958190917974, 17.313751220703182],
        [82.64958190917974, 17.313470840454215],
        [82.64902496337913, 17.313474655151367],
        [82.64902496337913, 17.313192367553825],
        [82.64875030517595, 17.313192367553825],
        [82.64875030517595, 17.31291580200201],
        [82.64819335937517, 17.31291961669922],
        [82.64819335937517, 17.312641143798828],
        [82.64736175537138, 17.312641143798828],
        [82.64736175537138, 17.31236076354986],
        [82.64653015136724, 17.31236076354986],
        [82.64653015136724, 17.312080383300895],
        [82.64624786376947, 17.31208229064947],
        [82.64624786376947, 17.311805725097656],
        [82.64597320556663, 17.311809539795036],
        [82.64597320556663, 17.311531066894645],
        [82.64569091796892, 17.311531066894645],
        [82.64569091796892, 17.311250686645508],
        [82.64457702636736, 17.311250686645508],
        [82.64457702636736, 17.31097030639654],
        [82.64347076416021, 17.31097030639654],
        [82.64347076416021, 17.310693740844727],
        [82.64319610595697, 17.310693740844727],
        [82.64319610595697, 17.310417175292912],
        [82.64263916015642, 17.310422897338867],
        [82.64263916015642, 17.310140609741325],
        [82.64234924316429, 17.310140609741325],
        [82.64234924316429, 17.309860229492188],
        [82.64152526855486, 17.309860229492188],
        [82.64152526855486, 17.309583663940543],
        [82.64097595214844, 17.309583663940543],
        [82.64097595214844, 17.309305191040153],
        [82.64041900634771, 17.309312820434513],
        [82.64041900634771, 17.309032440185547],
        [82.63931274414068, 17.309032440185547],
        [82.63931274414068, 17.308750152588004],
        [82.63874816894537, 17.308750152588004],
        [82.63874816894537, 17.308471679687614],
        [82.63847351074236, 17.308471679687614],
        [82.63847351074236, 17.3081951141358],
        [82.63819122314482, 17.30820274353033],
        [82.63819122314482, 17.30791854858404],
        [82.6379165649414, 17.30791854858404],
        [82.6379165649414, 17.307649612426758],
        [82.63764190673857, 17.307649612426758],
        [82.63764190673857, 17.307359695434684],
        [82.63708496093761, 17.30736160278326],
        [82.63708496093761, 17.30708312988287],
        [82.6368026733399, 17.30708312988287],
        [82.6368026733399, 17.306528091430664],
        [82.63654327392595, 17.306528091430664],
        [82.63654327392595, 17.30624961853033],
        [82.63597106933611, 17.30624961853033],
        [82.63597106933611, 17.30597114562994],
        [82.63569641113287, 17.305973052978516],
        [82.63569641113287, 17.305694580078125],
        [82.63513946533232, 17.305694580078125],
        [82.63513946533232, 17.305416107177734],
        [82.63485717773438, 17.305419921875114],
        [82.63485717773438, 17.305139541625977],
        [82.63458251953153, 17.305139541625977],
        [82.63458251953153, 17.304861068725586],
        [82.63402557373058, 17.304861068725586],
        [82.63402557373058, 17.304582595825195],
        [82.6337509155274, 17.304590225219727],
        [82.6337509155274, 17.30430984497076],
        [82.63291931152361, 17.30430984497076],
        [82.63291931152361, 17.304029464721793],
        [82.63263702392584, 17.304029464721793],
        [82.63263702392584, 17.303750991821403],
        [82.6318130493164, 17.303750991821403],
        [82.6318130493164, 17.303470611572266],
        [82.6312484741211, 17.303472518921012],
        [82.6312484741211, 17.303195953369197],
        [82.63097381591808, 17.303195953369197],
        [82.63097381591808, 17.302917480468807],
        [82.63040924072277, 17.30291938781744],
        [82.63040924072277, 17.30264091491705],
        [82.63014221191429, 17.30264091491705],
        [82.63014221191429, 17.302360534668082],
        [82.62985992431658, 17.302360534668082],
        [82.62985992431658, 17.30208015441889],
        [82.62958526611334, 17.302085876464844],
        [82.62958526611334, 17.3018035888673],
        [82.628471374512, 17.301811218261832],
        [82.628471374512, 17.301530838012695],
        [82.62790679931669, 17.301530838012695],
        [82.62790679931669, 17.30125045776373],
        [82.62763977050787, 17.30125045776373],
        [82.62763977050787, 17.300970077514762],
        [82.62735748291027, 17.300973892211914],
        [82.62735748291027, 17.300693511962947],
        [82.62596893310553, 17.300689697265568],
        [82.62596893310553, 17.300416946411133],
        [82.6254196166995, 17.300420761108512],
        [82.6254196166995, 17.300140380859375],
        [82.62458038330084, 17.300140380859375],
        [82.62458038330084, 17.29986000061041],
        [82.62430572509777, 17.29986381530773],
        [82.62430572509777, 17.29958534240717],
        [82.62374877929682, 17.29958534240717],
        [82.62374877929682, 17.299303054809627],
        [82.62319183349626, 17.29931068420416],
        [82.62319183349626, 17.299030303955192],
        [82.62291717529303, 17.299030303955192],
        [82.62291717529303, 17.298749923706055],
        [82.62264251709001, 17.298749923706055],
        [82.62264251709001, 17.2984619140625],
        [82.62208557128906, 17.298471450805664],
        [82.62208557128906, 17.29819488525402],
        [82.62180328369169, 17.29819488525402],
        [82.62180328369169, 17.29791641235346],
        [82.62124633789074, 17.297920227050838],
        [82.62124633789074, 17.29763984680187],
        [82.62069702148432, 17.29763984680187],
        [82.62069702148432, 17.297359466552678],
        [82.62041473388695, 17.29736137390148],
        [82.62041473388695, 17.29708290100109],
        [82.62014007568376, 17.29708290100109],
        [82.62014007568376, 17.296806335449276],
        [82.61930847167997, 17.296810150146484],
        [82.61930847167997, 17.296529769897518],
        [82.61902618408203, 17.296529769897518],
        [82.61902618408203, 17.29624938964855],
        [82.61875152587919, 17.29624938964855],
        [82.61875152587919, 17.29597091674816],
        [82.61819458007824, 17.295972824096737],
        [82.61819458007824, 17.295694351196346],
        [82.61791992187506, 17.295694351196346],
        [82.61791992187506, 17.295415878295955],
        [82.61708068847673, 17.29542160034191],
        [82.61708068847673, 17.295139312744197],
        [82.6168060302735, 17.295139312744197],
        [82.6168060302735, 17.294860839843807],
        [82.61653137207048, 17.294860839843807],
        [82.61653137207048, 17.29458045959484],
        [82.61624908447294, 17.294584274291992],
        [82.61624908447294, 17.2943058013916],
        [82.61596679687517, 17.294311523437557],
        [82.61596679687517, 17.294029235839844],
        [82.61540985107422, 17.294029235839844],
        [82.61540985107422, 17.293750762939453],
        [82.61513519287138, 17.293750762939453],
        [82.61513519287138, 17.293472290039062],
        [82.61458587646479, 17.293472290039062],
        [82.61458587646479, 17.293193817138672],
        [82.61374664306663, 17.293193817138672],
        [82.61374664306663, 17.292917251586857],
        [82.61347198486345, 17.29291915893566],
        [82.61347198486345, 17.2926483154298],
        [82.61264038085966, 17.2926483154298],
        [82.61264038085966, 17.292360305786133],
        [82.61235809326172, 17.292360305786133],
        [82.61235809326172, 17.29208374023449],
        [82.61152648925793, 17.29207992553711],
        [82.61152648925793, 17.292360305786133],
        [82.61125183105474, 17.292360305786133],
        [82.61125183105474, 17.293750762939453],
        [82.61096954345697, 17.293750762939453],
        [82.61096954345697, 17.294029235839844],
        [82.61042022705095, 17.294029235839844],
        [82.61042022705095, 17.294311523437557],
        [82.61013793945318, 17.2943058013916],
        [82.61013793945318, 17.294584274291992],
        [82.60986328125017, 17.29458045959484],
        [82.60986328125017, 17.294860839843807],
        [82.60958099365263, 17.294860839843807],
        [82.60958099365263, 17.295139312744197],
        [82.60930633544922, 17.295139312744197],
        [82.60930633544922, 17.29542160034191],
        [82.6087493896485, 17.295415878295955],
        [82.6087493896485, 17.295972824096737],
        [82.6084671020509, 17.29597091674816],
        [82.60847473144543, 17.29624938964855],
        [82.6076354980471, 17.29624938964855],
        [82.6076354980471, 17.295415878295955],
        [82.60791778564447, 17.29542160034191],
        [82.60791778564447, 17.295139312744197],
        [82.60819244384771, 17.295139312744197],
        [82.60819244384771, 17.29458045959484],
        [82.60847473144543, 17.294582366943416],
        [82.60847473144543, 17.293472290039062],
        [82.60902404785185, 17.293472290039062],
        [82.60902404785185, 17.293193817138672],
        [82.60930633544922, 17.293193817138672],
        [82.60930633544922, 17.292638778686467],
        [82.60958099365263, 17.2926483154298],
        [82.60958099365263, 17.291809082031307],
        [82.60986328125017, 17.291809082031307],
        [82.60986328125017, 17.290420532226562],
        [82.60958099365263, 17.290420532226562],
        [82.60958099365263, 17.290140151977596],
        [82.60930633544922, 17.290140151977596],
        [82.60930633544922, 17.28985977172863],
        [82.6087493896485, 17.289861679077205],
        [82.6087493896485, 17.289583206176815],
        [82.60847473144543, 17.289583206176815],
        [82.60847473144543, 17.289306640625],
        [82.60791778564447, 17.289306640625],
        [82.60791778564447, 17.28902816772461],
        [82.60708618164068, 17.289030075073242],
        [82.60708618164068, 17.288749694824276],
        [82.60623931884783, 17.288749694824276],
        [82.60623931884783, 17.288471221923885],
        [82.60597229003935, 17.28847312927246],
        [82.60597229003935, 17.288196563720646],
        [82.605697631836, 17.288196563720646],
        [82.605697631836, 17.287914276123104],
        [82.60514068603521, 17.28791999816906],
        [82.60514068603521, 17.287639617919922],
        [82.60430908203142, 17.287639617919922],
        [82.60430908203142, 17.28736114501953],
        [82.6034698486331, 17.28736114501953],
        [82.6034698486331, 17.287080764770565],
        [82.6023635864259, 17.28708267211914],
        [82.6023635864259, 17.28680419921875],
        [82.60151672363287, 17.286809921264705],
        [82.60151672363287, 17.286521911621207],
        [82.60097503662115, 17.286527633666935],
        [82.60097503662115, 17.286249160766545],
        [82.5992965698245, 17.286251068115348],
        [82.5992965698245, 17.28597068786621],
        [82.59764099121111, 17.28597068786621],
        [82.59764099121111, 17.286251068115348],
        [82.59570312499994, 17.286251068115348],
        [82.59570312499994, 17.28597068786621],
        [82.59541320800787, 17.285974502563533],
        [82.59541320800787, 17.285417556762752],
        [82.59570312499994, 17.285419464111385],
        [82.59570312499994, 17.28514289855957],
        [82.59625244140653, 17.28514289855957],
        [82.59625244140653, 17.284860610962028],
        [82.59652709960938, 17.284860610962028],
        [82.59652709960938, 17.284580230712834],
        [82.59680175781278, 17.284582138061637],
        [82.59680175781278, 17.284305572509822],
        [82.5970764160158, 17.28430938720703],
        [82.5970764160158, 17.284032821655387],
        [82.5973663330081, 17.284032821655387],
        [82.5973663330081, 17.283750534057674],
        [82.59680175781278, 17.283750534057674],
        [82.59680175781278, 17.283460617065543],
        [82.59652709960938, 17.283470153808707],
        [82.59652709960938, 17.283193588256893],
        [82.59625244140653, 17.283193588256893],
        [82.59625244140653, 17.282917022705078],
        [82.59597015380865, 17.282922744751033],
        [82.59597015380865, 17.28264045715332],
        [82.59541320800787, 17.28264045715332],
        [82.59541320800787, 17.282360076904354],
        [82.59430694580084, 17.282360076904354],
        [82.59430694580084, 17.28180503845215],
        [82.59401702880876, 17.28181266784668],
        [82.59401702880876, 17.281530380249137],
        [82.5934829711914, 17.281530380249137],
        [82.5934829711914, 17.281248092651367],
        [82.5931930541995, 17.281248092651367],
        [82.5931930541995, 17.280969619750977],
        [82.59263610839855, 17.28097152709961],
        [82.59263610839855, 17.280694961547965],
        [82.59236145019537, 17.280710220336857],
        [82.59236145019537, 17.280418395996207],
        [82.59179687500006, 17.280418395996207],
        [82.59179687500006, 17.280139923095817],
        [82.59123992919945, 17.280139923095817],
        [82.59123992919945, 17.279859542846623],
        [82.59069824218778, 17.279861450195426],
        [82.59069061279325, 17.279582977295036],
        [82.590141296387, 17.279582977295036],
        [82.590141296387, 17.27930641174322],
        [82.58957672119169, 17.279308319091854],
        [82.58957672119169, 17.279029846191463],
        [82.58930206298834, 17.279029846191463],
        [82.58930206298834, 17.278749465942496],
        [82.58875274658226, 17.278749465942496],
        [82.58875274658226, 17.278470993042106],
        [82.5881958007813, 17.278472900390682],
        [82.5881958007813, 17.277917861938477],
        [82.58763885498075, 17.27791976928711],
        [82.58763885498075, 17.277639389038143],
        [82.58651733398466, 17.277639389038143],
        [82.58651733398466, 17.277360916137752],
        [82.5859756469726, 17.277360916137752],
        [82.5859756469726, 17.27708244323736],
        [82.58569335937506, 17.27708244323736],
        [82.58569335937506, 17.276807785034123],
        [82.58542633056658, 17.276809692382926],
        [82.58542633056658, 17.276531219482536],
        [82.58486175537126, 17.276531219482536],
        [82.58486175537126, 17.2762508392334],
        [82.58429718017595, 17.2762508392334],
        [82.58429718017595, 17.275970458984432],
        [82.58374786376953, 17.275972366333008],
        [82.58374786376953, 17.275695800781193],
        [82.58347320556669, 17.275695800781193],
        [82.58347320556669, 17.275417327880803],
        [82.5831909179688, 17.275421142578182],
        [82.5831909179688, 17.275140762329215],
        [82.58235931396479, 17.275140762329215],
        [82.58235931396479, 17.274860382080078],
        [82.58207702636741, 17.274860382080078],
        [82.58207702636741, 17.274580001831055],
        [82.581527709961, 17.27458572387701],
        [82.581527709961, 17.274303436279297],
        [82.58125305175798, 17.274311065673828],
        [82.58125305175798, 17.27403068542486],
        [82.58097076416044, 17.27403068542486],
        [82.58097076416044, 17.273750305175895],
        [82.57985687255871, 17.273750305175895],
        [82.57985687255871, 17.2734699249267],
        [82.57958221435553, 17.273471832275504],
        [82.57958221435553, 17.273193359375114],
        [82.57903289794945, 17.273200988769645],
        [82.57903289794945, 17.272920608520508],
        [82.5784759521485, 17.272920608520508],
        [82.5784759521485, 17.272359848022575],
        [82.57819366455095, 17.272363662719727],
        [82.57819366455095, 17.272081375122184],
        [82.57791900634794, 17.272081375122184],
        [82.57791900634794, 17.27180480957037],
        [82.57736206054716, 17.27180480957037],
        [82.57736206054716, 17.271528244018555],
        [82.57680511474621, 17.271528244018555],
        [82.57680511474621, 17.27124977111822],
        [82.57653045654303, 17.27124977111822],
        [82.57653045654303, 17.270969390869197],
        [82.57624816894526, 17.27097129821783],
        [82.57624816894526, 17.270694732666016],
        [82.5756912231447, 17.270694732666016],
        [82.5756912231447, 17.270416259765625],
        [82.57485961914091, 17.270420074463004],
        [82.57485961914091, 17.270139694213867],
        [82.57430267334013, 17.270139694213867],
        [82.57430267334013, 17.269861221313477],
        [82.57402801513672, 17.269861221313477],
        [82.57402801513672, 17.26958084106451],
        [82.57375335693371, 17.269582748413086],
        [82.57375335693371, 17.26930618286127],
        [82.57319641113276, 17.269311904907227],
        [82.57319641113276, 17.269029617309684],
        [82.57290649414068, 17.269029617309684],
        [82.57290649414068, 17.26874923706049],
        [82.5726394653322, 17.26874923706049],
        [82.5726394653322, 17.268480300903434],
        [82.57180786132841, 17.268480300903434],
        [82.57180786132841, 17.268188476562557],
        [82.57125091552763, 17.268194198608512],
        [82.57125091552763, 17.267917633056697],
        [82.57096862792969, 17.26791954040533],
        [82.57096862792969, 17.267639160156364],
        [82.57068634033232, 17.267639160156364],
        [82.57068634033232, 17.267360687255973],
        [82.5704193115235, 17.267360687255973],
        [82.5704193115235, 17.267078399658203],
        [82.56986236572271, 17.26708412170416],
        [82.56986236572271, 17.266805648803768],
        [82.56958007812494, 17.266811370849723],
        [82.56958007812494, 17.26625061035162],
        [82.5693054199221, 17.26625061035162],
        [82.5693054199221, 17.26597213745123],
        [82.56791687011719, 17.26597213745123],
        [82.56791687011719, 17.265693664550838],
        [82.56764221191435, 17.265693664550838],
        [82.56764221191435, 17.265417098999023],
        [82.56709289550776, 17.265420913696403],
        [82.56709289550776, 17.265140533447266],
        [82.56680297851568, 17.265140533447266],
        [82.56680297851568, 17.2648601531983],
        [82.56652832031244, 17.264862060546875],
        [82.56652832031244, 17.264583587646484],
        [82.5662536621096, 17.264583587646484],
        [82.5662536621096, 17.264305114746094],
        [82.56597137451189, 17.26431083679205],
        [82.56597137451189, 17.264030456543082],
        [82.56485748291016, 17.264030456543082],
        [82.56485748291016, 17.263750076293945],
        [82.56430816650396, 17.263750076293945],
        [82.56430816650396, 17.263471603393555],
        [82.56375122070318, 17.263471603393555],
        [82.56375122070318, 17.263196945190487],
        [82.56291961669939, 17.263200759887695],
        [82.56291961669939, 17.26292037963873],
        [82.56263732910162, 17.26292037963873],
        [82.56263732910162, 17.262639999389762],
        [82.56208038330107, 17.262639999389762],
        [82.56208038330107, 17.26235008239746],
        [82.56180572509766, 17.26236152648937],
        [82.56180572509766, 17.26208305358898],
        [82.56153106689482, 17.26208305358898],
        [82.56153106689482, 17.261806488037166],
        [82.5609664916995, 17.261810302734375],
        [82.5609664916995, 17.26152992248541],
        [82.56069183349615, 17.26152992248541],
        [82.56069183349615, 17.261249542236442],
        [82.56041717529291, 17.261249542236442],
        [82.56041717529291, 17.26097106933605],
        [82.55986022949236, 17.260974884033203],
        [82.55986022949236, 17.260696411132812],
        [82.55958557128912, 17.260696411132812],
        [82.55958557128912, 17.26041412353527],
        [82.55902862548857, 17.260419845581055],
        [82.55902862548857, 17.260139465332088],
        [82.55847167968778, 17.260139465332088],
        [82.55847167968778, 17.259860992431697],
        [82.55819702148438, 17.259860992431697],
        [82.55819702148438, 17.25958061218273],
        [82.557914733887, 17.259582519531307],
        [82.557914733887, 17.259304046630916],
        [82.55735778808605, 17.25930976867687],
        [82.55735778808605, 17.259029388427734],
        [82.55708312988287, 17.259029388427734],
        [82.55708312988287, 17.25875282287609],
        [82.55625152587908, 17.25875282287609],
        [82.55625152587908, 17.258470535278377],
        [82.5556945800783, 17.258472442626953],
        [82.5556945800783, 17.258192062377987],
        [82.55513763427734, 17.258192062377987],
        [82.55513763427734, 17.257917404174748],
        [82.5548629760745, 17.257921218872127],
        [82.5548629760745, 17.257642745971737],
        [82.55458068847662, 17.257642745971737],
        [82.55458068847662, 17.257360458374023],
        [82.5537490844726, 17.257360458374023],
        [82.5537490844726, 17.257080078125],
        [82.55319213867205, 17.25708389282238],
        [82.55319213867205, 17.25680541992199],
        [82.5529174804688, 17.256811141967773],
        [82.5529174804688, 17.256540298461914],
        [82.55236053466825, 17.256540298461914],
        [82.55236053466825, 17.25625038146984],
        [82.55179595947294, 17.25625038146984],
        [82.55179595947294, 17.25570106506359],
        [82.55124664306652, 17.25570106506359],
        [82.55124664306652, 17.2554225921632],
        [82.55097198486334, 17.2554225921632],
        [82.55097198486334, 17.255140304565487],
        [82.55014038085955, 17.255140304565487],
        [82.55014038085955, 17.254861831665096],
        [82.54985809326178, 17.254861831665096],
        [82.54985809326178, 17.254583358764705],
        [82.54958343505876, 17.254583358764705],
        [82.54958343505876, 17.254304885864315],
        [82.54902648925781, 17.254304885864315],
        [82.54902648925781, 17.253749847412166],
        [82.54846954345703, 17.253749847412166],
        [82.54846954345703, 17.253480911254883],
        [82.54818725585966, 17.253480911254883],
        [82.54818725585966, 17.253189086914006],
        [82.54763793945307, 17.25319480895996],
        [82.54763793945307, 17.25291633605957],
        [82.54736328125023, 17.252918243408146],
        [82.54736328125023, 17.252639770507812],
        [82.54708099365251, 17.252639770507812],
        [82.54708099365251, 17.25235939025879],
        [82.54680633544928, 17.252361297607422],
        [82.54680633544928, 17.25208282470703],
        [82.54652404785173, 17.25208282470703],
        [82.54652404785173, 17.251806259155217],
        [82.54569244384794, 17.25180816650402],
        [82.54569244384794, 17.25152969360363],
        [82.54514312744152, 17.25152969360363],
        [82.54514312744152, 17.25125122070324],
        [82.54458618164057, 17.25125122070324],
        [82.54458618164057, 17.2509708404541],
        [82.54430389404303, 17.250972747802848],
        [82.54430389404303, 17.250417709350643],
        [82.54374694824241, 17.250417709350643],
        [82.54374694824241, 17.25013923645031],
        [82.54292297363281, 17.250141143798885],
        [82.54292297363281, 17.24986076354992],
        [82.54264068603544, 17.24986076354992],
        [82.54264068603544, 17.249580383300724],
        [82.5418090820313, 17.249584197998104],
        [82.5418090820313, 17.24930763244629],
        [82.54152679443371, 17.249309539794922],
        [82.54152679443371, 17.24903106689453],
        [82.54125213623053, 17.24903106689453],
        [82.54125213623053, 17.248750686645565],
        [82.54096984863276, 17.248750686645565],
        [82.54096984863276, 17.248189926147404],
        [82.54069519042991, 17.24819564819336],
        [82.54069519042991, 17.24791717529297],
        [82.54013061523443, 17.247920989990348],
        [82.54013061523443, 17.24764060974121],
        [82.53985595703142, 17.24764060974121],
        [82.53985595703142, 17.24735069274908],
        [82.539306640625, 17.247360229492244],
        [82.539306640625, 17.247085571289006],
        [82.53847503662121, 17.247085571289006],
        [82.53847503662121, 17.246803283691463],
        [82.5381927490235, 17.246810913085994],
        [82.5381927490235, 17.246530532837028],
        [82.53791809082026, 17.246530532837028],
        [82.53791809082026, 17.24625015258789],
        [82.53756713867205, 17.24625015258789],
        [82.5373611450197, 17.24625015258789],
        [82.5373611450197, 17.245969772338867],
        [82.53680419921892, 17.2459716796875],
        [82.53680419921892, 17.24569320678711],
        [82.53624725341797, 17.24570083618164],
        [82.53624725341797, 17.245420455932674],
        [82.53597259521513, 17.245420455932674],
        [82.53597259521513, 17.245140075683707],
        [82.53569793701178, 17.245140075683707],
        [82.53569793701178, 17.244859695434513],
        [82.53485870361322, 17.244861602783317],
        [82.53485870361322, 17.24458122253418],
        [82.53430175781267, 17.24458122253418],
        [82.53430175781267, 17.244304656982536],
        [82.53375244140642, 17.24431037902832],
        [82.53375244140642, 17.24403190612793],
        [82.53347015380888, 17.24403190612793],
        [82.53347015380888, 17.243749618530387],
        [82.5329132080081, 17.243749618530387],
        [82.5329132080081, 17.243471145629996],
        [82.53236389160168, 17.243471145629996],
        [82.53236389160168, 17.243194580078182],
        [82.53208160400396, 17.243194580078182],
        [82.53208160400396, 17.24291610717779],
        [82.53151702880865, 17.242921829223746],
        [82.53151702880865, 17.242639541626033],
        [82.53125000000017, 17.242639541626033],
        [82.53125000000017, 17.24235916137701],
        [82.53097534179693, 17.24235916137701],
        [82.53097534179693, 17.242082595825252],
        [82.53069305419939, 17.242082595825252],
        [82.53069305419939, 17.241806030273438],
        [82.53041839599638, 17.241811752319393],
        [82.53041839599638, 17.241542816162166],
        [82.53013610839861, 17.241542816162166],
        [82.53013610839861, 17.241249084472656],
        [82.5298614501956, 17.241249084472656],
        [82.5298614501956, 17.240688323974723],
        [82.52958679199219, 17.240694046020508],
        [82.52958679199219, 17.240417480468693],
        [82.52929687500028, 17.240419387817496],
        [82.52929687500028, 17.240140914917106],
        [82.52902984619146, 17.240140914917106],
        [82.52902984619146, 17.23986053466797],
        [82.52847290039068, 17.23986053466797],
        [82.52847290039068, 17.23957824707037],
        [82.52791595459007, 17.239583969116325],
        [82.52791595459007, 17.239305496215934],
        [82.52707672119158, 17.23931121826172],
        [82.52707672119158, 17.239030838012752],
        [82.52652740478516, 17.239030838012752],
        [82.52652740478516, 17.238750457763786],
        [82.52625274658232, 17.238750457763786],
        [82.52625274658232, 17.238201141357536],
        [82.52597045898443, 17.238180160522518],
        [82.52597045898443, 17.237918853759822],
        [82.52540588378912, 17.2379207611084],
        [82.52540588378912, 17.237640380859432],
        [82.52485656738304, 17.237640380859432],
        [82.52485656738304, 17.237360000610465],
        [82.52430725097662, 17.23736190795904],
        [82.52430725097662, 17.23708343505865],
        [82.52402496337908, 17.23708343505865],
        [82.52402496337908, 17.236806869506836],
        [82.52375030517607, 17.236810684204215],
        [82.52375030517607, 17.236530303955078],
        [82.52348327636719, 17.236530303955078],
        [82.52348327636719, 17.23624992370611],
        [82.52319335937528, 17.23624992370611],
        [82.52319335937528, 17.235969543457088],
        [82.52263641357433, 17.23597145080572],
        [82.52263641357433, 17.235696792602482],
        [82.52236175537115, 17.235696792602482],
        [82.52236175537115, 17.23541450500494],
        [82.52180480957054, 17.23541450500494],
        [82.52180480957054, 17.235139846801758],
        [82.52124786376959, 17.235139846801758],
        [82.52124786376959, 17.234859466552734],
        [82.52069091796903, 17.234861373901367],
        [82.52069091796903, 17.234584808349723],
        [82.51985931396484, 17.234584808349723],
        [82.51985931396484, 17.234306335449162],
        [82.51930236816412, 17.23431015014654],
        [82.51930236816412, 17.234029769897575],
        [82.51902008056652, 17.234029769897575],
        [82.51902008056652, 17.23374938964838],
        [82.51875305175804, 17.23374938964838],
        [82.51875305175804, 17.233470916748047],
        [82.51847076416033, 17.23347473144537],
        [82.51847076416033, 17.233192443847656],
        [82.51819610595709, 17.233192443847656],
        [82.51819610595709, 17.232915878296012],
        [82.51763916015653, 17.23291969299322],
        [82.51763916015653, 17.23264122009283],
        [82.51735687255876, 17.23264122009283],
        [82.51735687255876, 17.23208045959467],
        [82.51708221435575, 17.232082366943473],
        [82.51708221435575, 17.23180580139166],
        [82.51680755615234, 17.231809616088867],
        [82.51680755615234, 17.231531143188477],
        [82.51653289794933, 17.231531143188477],
        [82.51653289794933, 17.23125076293951],
        [82.5156860351563, 17.23125076293951],
        [82.5156860351563, 17.230970382690543],
        [82.51541900634783, 17.23097229003912],
        [82.51541900634783, 17.23069381713873],
        [82.51457977294928, 17.23069381713873],
        [82.51457977294928, 17.230417251586914],
        [82.51431274414062, 17.230421066284293],
        [82.51431274414062, 17.230142593383903],
        [82.51403045654325, 17.230142593383903],
        [82.51403045654325, 17.22986030578619],
        [82.51374816894531, 17.22986030578619],
        [82.51374816894531, 17.229312896728516],
        [82.51346588134794, 17.229312896728516],
        [82.51346588134794, 17.228750228881836],
        [82.51235961914057, 17.228750228881836],
        [82.51235961914057, 17.228471755981445],
        [82.51180267334001, 17.228471755981445],
        [82.51180267334001, 17.2281951904298],
        [82.51152801513678, 17.228202819824162],
        [82.51152801513678, 17.227922439575195],
        [82.51125335693376, 17.227922439575195],
        [82.51125335693376, 17.22763824462885],
        [82.51097106933622, 17.22763824462885],
        [82.51097106933622, 17.22735977172846],
        [82.51041412353544, 17.227361679077262],
        [82.51041412353544, 17.22708320617687],
        [82.51013946533209, 17.22708320617687],
        [82.51013946533209, 17.22680473327648],
        [82.50986480712902, 17.22680473327648],
        [82.50986480712902, 17.226528167724666],
        [82.50930786132807, 17.226528167724666],
        [82.50930786132807, 17.22624015808117],
        [82.50819396972673, 17.226249694824332],
        [82.50819396972673, 17.225973129272518],
        [82.50791931152372, 17.225973129272518],
        [82.50791931152372, 17.225694656372127],
        [82.50763702392578, 17.225694656372127],
        [82.50763702392578, 17.225416183471737],
        [82.50736236572294, 17.225418090820312],
        [82.50736236572294, 17.22513961791998],
        [82.50680541992199, 17.22513961791998],
        [82.50680541992199, 17.224861145019588],
        [82.5065307617188, 17.224861145019588],
        [82.5065307617188, 17.22458076477062],
        [82.50624847412104, 17.224582672119197],
        [82.50624847412104, 17.224306106567383],
        [82.5059661865235, 17.224309921264762],
        [82.5059661865235, 17.224029541015625],
        [82.50514221191423, 17.224029541015625],
        [82.50514221191423, 17.223751068115234],
        [82.50485992431669, 17.223751068115234],
        [82.50485992431669, 17.223470687866268],
        [82.50430297851591, 17.223472595214844],
        [82.50430297851591, 17.223194122314453],
        [82.5040283203125, 17.223194122314453],
        [82.5040283203125, 17.22291755676264],
        [82.50347137451178, 17.222919464111442],
        [82.50347137451178, 17.22264099121105],
        [82.50290679931646, 17.22264099121105],
        [82.50290679931646, 17.222360610961914],
        [82.50263977050798, 17.222360610961914],
        [82.50263977050798, 17.22208023071289],
        [82.5020828247072, 17.222085952758846],
        [82.5020828247072, 17.221803665161133],
        [82.50180816650419, 17.221809387207088],
        [82.50180816650419, 17.221530914306697],
        [82.50125122070341, 17.221530914306697],
        [82.50125122070341, 17.22125053405773],
        [82.50096893310547, 17.22125053405773],
        [82.50096893310547, 17.22097206115734],
        [82.50041961669928, 17.22097206115734],
        [82.50041961669928, 17.22041511535656],
        [82.50013732910168, 17.22043037414545],
        [82.50013732910168, 17.21986007690441],
        [82.49958038330072, 17.219863891601562],
        [82.49958038330072, 17.219585418701172],
        [82.49874877929693, 17.219585418701172],
        [82.49874877929693, 17.21930313110363],
        [82.49846649169939, 17.21931076049816],
        [82.49846649169939, 17.219030380249023],
        [82.49791717529297, 17.219030380249023],
        [82.49791717529297, 17.21875],
        [82.49764251709013, 17.21875],
        [82.49764251709013, 17.218469619751033],
        [82.49708557128918, 17.218471527099666],
        [82.49708557128918, 17.218193054199276],
        [82.49652862548822, 17.218200683593807],
        [82.49652862548822, 17.21792030334484],
        [82.49624633789085, 17.21792030334484],
        [82.49624633789085, 17.217639923095703],
        [82.49597167968767, 17.217639923095703],
        [82.49597167968767, 17.217372894287166],
        [82.49569702148443, 17.217372894287166],
        [82.49569702148443, 17.217081069946346],
        [82.49514007568388, 17.217081069946346],
        [82.49514007568388, 17.216806411743107],
        [82.4945831298831, 17.216810226440487],
        [82.4945831298831, 17.216531753540096],
        [82.49430847167974, 17.216531753540096],
        [82.49430847167974, 17.216249465942326],
        [82.49402618408214, 17.216249465942326],
        [82.49402618408214, 17.21596908569336],
        [82.4934692382812, 17.21597290039074],
        [82.4934692382812, 17.215694427490348],
        [82.49319458007835, 17.215694427490348],
        [82.49319458007835, 17.215415954589957],
        [82.4926376342774, 17.215421676635742],
        [82.4926376342774, 17.2151393890382],
        [82.49208068847685, 17.2151393890382],
        [82.49208068847685, 17.21486091613781],
        [82.49180603027344, 17.21486091613781],
        [82.49180603027344, 17.214580535888615],
        [82.4915313720706, 17.21458244323742],
        [82.4915313720706, 17.214305877685604],
        [82.49097442626964, 17.214305877685604],
        [82.49097442626964, 17.214027404785213],
        [82.49014282226585, 17.214029312133846],
        [82.49014282226585, 17.213750839233455],
        [82.48986053466814, 17.213750839233455],
        [82.48986053466814, 17.21347045898449],
        [82.4895858764649, 17.213472366333065],
        [82.4895858764649, 17.213193893432674],
        [82.48930358886736, 17.213193893432674],
        [82.48930358886736, 17.21291732788086],
        [82.48903656005888, 17.212919235229492],
        [82.48903656005888, 17.2126407623291],
        [82.48874664306658, 17.2126407623291],
        [82.48874664306658, 17.212360382080135],
        [82.48819732666016, 17.212360382080135],
        [82.48819732666016, 17.21180915832531],
        [82.48792266845714, 17.21180915832531],
        [82.48792266845714, 17.21153068542492],
        [82.48764038085943, 17.21153068542492],
        [82.48764038085943, 17.211240768432617],
        [82.48708343505865, 17.21125030517578],
        [82.48708343505865, 17.21097183227539],
        [82.48652648925804, 17.21097183227539],
        [82.48652648925804, 17.210695266723746],
        [82.48625183105486, 17.21070098876953],
        [82.48625183105486, 17.210140228271598],
        [82.48597717285185, 17.210140228271598],
        [82.48597717285185, 17.209859848022404],
        [82.48542022705107, 17.209861755371207],
        [82.48542022705107, 17.209583282470817],
        [82.48486328125028, 17.209583282470817],
        [82.48486328125028, 17.209306716919002],
        [82.48430633544933, 17.209306716919002],
        [82.48430633544933, 17.20902824401861],
        [82.48401641845709, 17.209030151367244],
        [82.48401641845709, 17.208749771118278],
        [82.48346710205101, 17.208749771118278],
        [82.48346710205101, 17.208469390869084],
        [82.48319244384783, 17.208471298217887],
        [82.48319244384783, 17.20819664001465],
        [82.48291015625006, 17.208200454712028],
        [82.48291015625006, 17.20792007446289],
        [82.48236083984403, 17.20792007446289],
        [82.48236083984403, 17.207639694213924],
        [82.48208618164062, 17.207639694213924],
        [82.48208618164062, 17.207361221313533],
        [82.48179626464872, 17.207361221313533],
        [82.48179626464872, 17.207080841064567],
        [82.4812469482423, 17.20708465576172],
        [82.4812469482423, 17.206804275512752],
        [82.48097229003912, 17.206809997558707],
        [82.48097229003912, 17.20652961730957],
        [82.48042297363304, 17.20652961730957],
        [82.48042297363304, 17.20625114440918],
        [82.48014068603533, 17.20625114440918],
        [82.48014068603533, 17.205970764160213],
        [82.47958374023455, 17.205974578857536],
        [82.47958374023455, 17.205692291259822],
        [82.47930908203153, 17.205692291259822],
        [82.47930908203153, 17.205417633056584],
        [82.47875213623075, 17.20543098449707],
        [82.47875213623075, 17.205141067504996],
        [82.47846984863281, 17.205141067504996],
        [82.47846984863281, 17.20486068725586],
        [82.47763824462885, 17.20486068725586],
        [82.47763824462885, 17.204580307006836],
        [82.47736358642601, 17.20458221435547],
        [82.47736358642601, 17.204305648803825],
        [82.4770812988283, 17.204309463501033],
        [82.4770812988283, 17.20403289794922],
        [82.47680664062506, 17.20403289794922],
        [82.47680664062506, 17.203750610351676],
        [82.47651672363298, 17.203750610351676],
        [82.47651672363298, 17.20319175720215],
        [82.4759750366211, 17.203193664550895],
        [82.4759750366211, 17.20263862609869],
        [82.47569274902372, 17.202640533447322],
        [82.47569274902372, 17.202360153198356],
        [82.47541809082031, 17.202360153198356],
        [82.47541809082031, 17.20208358764654],
        [82.47513580322294, 17.20208358764654],
        [82.47513580322294, 17.20180511474615],
        [82.47486114501959, 17.201812744140682],
        [82.47486114501959, 17.201532363891715],
        [82.47458648681635, 17.201532363891715],
        [82.47458648681635, 17.201250076294002],
        [82.47429656982428, 17.201250076294002],
        [82.47429656982428, 17.20096969604498],
        [82.4740295410158, 17.20097160339361],
        [82.4740295410158, 17.200695037841797],
        [82.47347259521501, 17.200700759887752],
        [82.47347259521501, 17.200418472289982],
        [82.473197937012, 17.200418472289982],
        [82.473197937012, 17.200138092041016],
        [82.4729232788086, 17.200138092041016],
        [82.4729232788086, 17.199859619140625],
        [82.47264099121122, 17.199861526489258],
        [82.47264099121122, 17.199583053588867],
        [82.47235870361328, 17.199583053588867],
        [82.47235870361328, 17.199306488037053],
        [82.47208404541044, 17.199306488037053],
        [82.47208404541044, 17.199029922485465],
        [82.47152709960949, 17.199029922485465],
        [82.47152709960949, 17.19874954223627],
        [82.4712524414063, 17.19874954223627],
        [82.4712524414063, 17.198471069335938],
        [82.47097015380854, 17.198472976684684],
        [82.47097015380854, 17.198194503784293],
        [82.4706954956057, 17.198194503784293],
        [82.4706954956057, 17.19791793823248],
        [82.47013854980474, 17.19791984558111],
        [82.47013854980474, 17.197639465332145],
        [82.4698562622072, 17.197639465332145],
        [82.4698562622072, 17.197360992431754],
        [82.46958160400419, 17.197360992431754],
        [82.46958160400419, 17.19708061218256],
        [82.46930694580078, 17.197082519531364],
        [82.46930694580078, 17.196807861328125],
        [82.46875, 17.196809768676758],
        [82.46875, 17.19652938842779],
        [82.46848297119135, 17.19652938842779],
        [82.46848297119135, 17.196260452270508],
        [82.46819305419928, 17.196260452270508],
        [82.46819305419928, 17.195970535278434],
        [82.46791839599604, 17.19597244262701],
        [82.46791839599604, 17.195695877075195],
        [82.46736145019548, 17.195695877075195],
        [82.46736145019548, 17.195417404174805],
        [82.46708679199224, 17.195421218872184],
        [82.46708679199224, 17.195140838623047],
        [82.46679687500017, 17.195140838623047],
        [82.46679687500017, 17.19486045837408],
        [82.46652984619169, 17.19486045837408],
        [82.46652984619169, 17.194580078125057],
        [82.46597290039091, 17.19458580017084],
        [82.46597290039091, 17.1943035125733],
        [82.46569824218756, 17.19431114196783],
        [82.46569061279297, 17.194030761718864],
        [82.46541595458996, 17.194030761718864],
        [82.46541595458996, 17.193750381469727],
        [82.46514129638678, 17.193750381469727],
        [82.46514129638678, 17.193470001220703],
        [82.46458435058616, 17.193473815918082],
        [82.46458435058616, 17.193193435668945],
        [82.46430206298845, 17.193201065063477],
        [82.46430206298845, 17.19292068481451],
        [82.46402740478521, 17.19292068481451],
        [82.46402740478521, 17.192640304565543],
        [82.4637527465822, 17.192640304565543],
        [82.4637527465822, 17.19235992431635],
        [82.46319580078125, 17.19236373901373],
        [82.46319580078125, 17.192081451416016],
        [82.46291351318388, 17.192081451416016],
        [82.46291351318388, 17.19180488586437],
        [82.46263885498047, 17.19180488586437],
        [82.46263885498047, 17.191528320312557],
        [82.4623565673831, 17.19153022766119],
        [82.4623565673831, 17.191249847412223],
        [82.4618072509765, 17.191249847412223],
        [82.4618072509765, 17.19096946716303],
        [82.46152496337896, 17.190971374511832],
        [82.46152496337896, 17.190694808960018],
        [82.46125030517595, 17.190694808960018],
        [82.46125030517595, 17.190416336059627],
        [82.46098327636724, 17.190420150756836],
        [82.46098327636724, 17.19013977050787],
        [82.46069335937517, 17.19013977050787],
        [82.46069335937517, 17.189859390258846],
        [82.46013641357439, 17.18986129760748],
        [82.46013641357439, 17.189582824707088],
        [82.45986175537138, 17.189582824707088],
        [82.45986175537138, 17.189306259155273],
        [82.45958709716797, 17.189310073852653],
        [82.45958709716797, 17.189029693603516],
        [82.45929718017607, 17.189029693603516],
        [82.45929718017607, 17.188749313354492],
        [82.45903015136724, 17.188749313354492],
        [82.45903015136724, 17.18847084045416],
        [82.45874786376964, 17.188472747802734],
        [82.45874786376964, 17.188194274902344],
        [82.4581909179687, 17.188194274902344],
        [82.4581909179687, 17.187915802001953],
        [82.45791625976585, 17.18792152404791],
        [82.45791625976585, 17.18763923645014],
        [82.45764160156267, 17.18763923645014],
        [82.45764160156267, 17.187360763549805],
        [82.4573593139649, 17.187360763549805],
        [82.4573593139649, 17.187070846557674],
        [82.45708465576189, 17.18708419799816],
        [82.45708465576189, 17.18680572509777],
        [82.45652770996094, 17.186811447143555],
        [82.45652770996094, 17.186529159546012],
        [82.4562530517581, 17.186529159546012],
        [82.4562530517581, 17.18625068664562],
        [82.45597076416021, 17.18625068664562],
        [82.45597076416021, 17.185970306396428],
        [82.45569610595714, 17.18597221374523],
        [82.45569610595714, 17.18569374084484],
        [82.45541381835943, 17.18569374084484],
        [82.45541381835943, 17.185417175293026],
        [82.4551391601562, 17.18541908264166],
        [82.4551391601562, 17.185140609741268],
        [82.45485687255882, 17.185140609741268],
        [82.45485687255882, 17.1848602294923],
        [82.4543075561524, 17.1848602294923],
        [82.4543075561524, 17.184579849243107],
        [82.45402526855486, 17.184583663940487],
        [82.45402526855486, 17.184305191040096],
        [82.45375061035185, 17.18432044982916],
        [82.45375061035185, 17.184030532836914],
        [82.45347595214844, 17.184030532836914],
        [82.45347595214844, 17.183750152587947],
        [82.45318603515653, 17.183750152587947],
        [82.45318603515653, 17.183469772338924],
        [82.45263671875011, 17.183471679687557],
        [82.45263671875011, 17.183195114135742],
        [82.45236206054693, 17.183200836181697],
        [82.45236206054693, 17.18292045593273],
        [82.45181274414085, 17.18292045593273],
        [82.45181274414085, 17.182640075683594],
        [82.45153045654314, 17.182640075683594],
        [82.45153045654314, 17.18235969543457],
        [82.45097351074236, 17.182361602783203],
        [82.45097351074236, 17.182083129882812],
        [82.45069122314482, 17.182083129882812],
        [82.45069122314482, 17.181806564330998],
        [82.45014190673857, 17.181806564330998],
        [82.45014190673857, 17.181528091430607],
        [82.44986724853521, 17.18152999877941],
        [82.44986724853521, 17.181261062622127],
        [82.4493026733399, 17.181261062622127],
        [82.4493026733399, 17.180971145629883],
        [82.44902801513666, 17.18097305297863],
        [82.44902801513666, 17.180696487426815],
        [82.44847106933611, 17.180696487426815],
        [82.44847106933611, 17.1804141998291],
        [82.44819641113287, 17.180419921875057],
        [82.44819641113287, 17.18013954162609],
        [82.4479064941408, 17.18013954162609],
        [82.4479064941408, 17.1798610687257],
        [82.44763946533232, 17.1798610687257],
        [82.44763946533232, 17.179580688476506],
        [82.4473648071289, 17.17958259582531],
        [82.4473648071289, 17.17930412292492],
        [82.44708251953153, 17.179309844970703],
        [82.44708251953153, 17.179029464721737],
        [82.44653320312511, 17.179029464721737],
        [82.44653320312511, 17.178752899169922],
        [82.4462509155274, 17.178752899169922],
        [82.4462509155274, 17.17847061157238],
        [82.4459686279298, 17.17847442626953],
        [82.4459686279298, 17.17819213867199],
        [82.44568634033209, 17.17819976806652],
        [82.44568634033209, 17.177919387817383],
        [82.44541931152361, 17.177919387817383],
        [82.44541931152361, 17.17764282226574],
        [82.44513702392601, 17.17764282226574],
        [82.44513702392601, 17.177360534668026],
        [82.44486236572283, 17.177360534668026],
        [82.44486236572283, 17.177080154419002],
        [82.44458007812506, 17.177082061767635],
        [82.44458007812506, 17.17680549621582],
        [82.4443130493164, 17.176811218261776],
        [82.4443130493164, 17.176532745361385],
        [82.44374084472656, 17.176532745361385],
        [82.44374084472656, 17.176250457763672],
        [82.44346618652372, 17.176250457763672],
        [82.44346618652372, 17.17597007751465],
        [82.44319152832037, 17.17597007751465],
        [82.44319152832037, 17.175695419311637],
        [82.4429168701173, 17.175691604614315],
        [82.4429168701173, 17.175416946411076],
        [82.44264221191412, 17.17542266845703],
        [82.44264221191412, 17.175130844116154],
        [82.44235992431635, 17.175138473510685],
        [82.44235992431635, 17.174861907959098],
        [82.4418029785158, 17.174861907959098],
        [82.4418029785158, 17.174583435058707],
        [82.44152832031256, 17.174583435058707],
        [82.44152832031256, 17.174304962158317],
        [82.44124603271501, 17.1743106842041],
        [82.44124603271501, 17.174028396606502],
        [82.4406967163086, 17.174028396606502],
        [82.4406967163086, 17.17374992370617],
        [82.44013977050787, 17.17374992370617],
        [82.44013977050787, 17.173469543456974],
        [82.43985748291027, 17.173471450805778],
        [82.43985748291027, 17.173194885253963],
        [82.43958282470709, 17.173194885253963],
        [82.43958282470709, 17.172916412353572],
        [82.43930816650385, 17.17291831970215],
        [82.43930816650385, 17.172639846801815],
        [82.43902587890648, 17.172639846801815],
        [82.43902587890648, 17.172069549560547],
        [82.4387512207033, 17.172082901001033],
        [82.4387512207033, 17.171527862548828],
        [82.43846893310553, 17.17152976989746],
        [82.43846893310553, 17.171249389648438],
        [82.4379196166995, 17.171249389648438],
        [82.4379196166995, 17.17097282409668],
        [82.43763732910156, 17.17097282409668],
        [82.43763732910156, 17.170417785644474],
        [82.43736267089872, 17.170419692993278],
        [82.43736267089872, 17.169580459594727],
        [82.43708038330078, 17.169584274292106],
        [82.43708038330078, 17.16930770874029],
        [82.4368133544923, 17.169321060180607],
        [82.4368133544923, 17.169031143188533],
        [82.43653106689459, 17.169031143188533],
        [82.43653106689459, 17.168750762939567],
        [82.43596649169928, 17.168750762939567],
        [82.43596649169928, 17.168470382690373],
        [82.43569183349626, 17.168472290039176],
        [82.43569183349626, 17.16819572448736],
        [82.43514251709001, 17.16819572448736],
        [82.43514251709001, 17.16791725158697],
        [82.4345932006836, 17.16792106628418],
        [82.4345932006836, 17.167640686035213],
        [82.43430328369169, 17.167640686035213],
        [82.43430328369169, 17.167360305786246],
        [82.43402862548828, 17.167360305786246],
        [82.43402862548828, 17.167079925537053],
        [82.43374633789091, 17.167085647583008],
        [82.43374633789091, 17.166803359985465],
        [82.43347167968756, 17.166810989379996],
        [82.43347167968756, 17.16653060913086],
        [82.43264007568376, 17.16653060913086],
        [82.43264007568376, 17.166259765625],
        [82.43235778808616, 17.166259765625],
        [82.43235778808616, 17.16596984863287],
        [82.43208312988298, 17.165971755981502],
        [82.43208312988298, 17.16569328308111],
        [82.4315261840822, 17.165700912475643],
        [82.4315261840822, 17.165420532226676],
        [82.43125152587919, 17.165420532226676],
        [82.43125152587919, 17.16514015197754],
        [82.43096923828125, 17.16514015197754],
        [82.43096923828125, 17.16457939147955],
        [82.43069458007841, 17.164581298828182],
        [82.43069458007841, 17.164304733276367],
        [82.43041992187506, 17.164304733276367],
        [82.43041992187506, 17.164028167724553],
        [82.42986297607428, 17.164030075073356],
        [82.42986297607428, 17.163749694824162],
        [82.4295806884765, 17.163749694824162],
        [82.4295806884765, 17.163471221923828],
        [82.42903137207048, 17.163471221923828],
        [82.42903137207048, 17.163194656372184],
        [82.42874908447271, 17.163194656372184],
        [82.42874908447271, 17.162916183471793],
        [82.42846679687517, 17.162921905517578],
        [82.42846679687517, 17.162639617920036],
        [82.42819213867216, 17.162639617920036],
        [82.42819213867216, 17.16208076477045],
        [82.42763519287138, 17.162082672119254],
        [82.42763519287138, 17.16180610656744],
        [82.42736053466803, 17.161811828613395],
        [82.42736053466803, 17.161529541015682],
        [82.42708587646496, 17.161529541015682],
        [82.42708587646496, 17.16124916076666],
        [82.42679595947271, 17.16124916076666],
        [82.42679595947271, 17.160970687866325],
        [82.426528930664, 17.1609725952149],
        [82.426528930664, 17.16069412231451],
        [82.42624664306663, 17.16069412231451],
        [82.42624664306663, 17.160417556762695],
        [82.42597198486345, 17.160421371460075],
        [82.42597198486345, 17.160129547119197],
        [82.42569732666021, 17.160139083862305],
        [82.42569732666021, 17.15986061096197],
        [82.42541503906267, 17.15986061096197],
        [82.42541503906267, 17.159584045410156],
        [82.42485809326172, 17.159584045410156],
        [82.42485809326172, 17.159305572509766],
        [82.42457580566435, 17.15930747985834],
        [82.42457580566435, 17.159030914306754],
        [82.42430877685553, 17.159030914306754],
        [82.42430877685553, 17.158750534057617],
        [82.42402648925793, 17.158750534057617],
        [82.42402648925793, 17.158470153808594],
        [82.42375183105474, 17.158472061157227],
        [82.42375183105474, 17.158193588256836],
        [82.42346954345697, 17.158193588256836],
        [82.42346954345697, 17.157918930053825],
        [82.42318725585943, 17.1579208374024],
        [82.42318725585943, 17.157640457153434],
        [82.42292785644548, 17.157640457153434],
        [82.42292785644548, 17.15736007690424],
        [82.42263793945318, 17.157361984253043],
        [82.42263793945318, 17.157083511352653],
        [82.42236328125017, 17.157091140747184],
        [82.42236328125017, 17.156810760498047],
        [82.42208099365263, 17.156810760498047],
        [82.42208099365263, 17.15653038024908],
        [82.42180633544922, 17.15653038024908],
        [82.42180633544922, 17.156250000000114],
        [82.42151641845732, 17.156250000000114],
        [82.42151641845732, 17.15596961975092],
        [82.42097473144543, 17.155971527099723],
        [82.42097473144543, 17.155416488647518],
        [82.42069244384771, 17.155420303344727],
        [82.42069244384771, 17.15513992309576],
        [82.42041778564447, 17.15513992309576],
        [82.42041778564447, 17.154859542846737],
        [82.42013549804693, 17.15486145019537],
        [82.42013549804693, 17.154584884643555],
        [82.41986083984392, 17.154584884643555],
        [82.41986083984392, 17.154306411743164],
        [82.41958618164068, 17.15431976318365],
        [82.41958618164068, 17.154029846191406],
        [82.41902923584013, 17.154029846191406],
        [82.41902923584013, 17.15347099304205],
        [82.41874694824236, 17.15347480773937],
        [82.41874694824236, 17.15319252014166],
        [82.41847229003935, 17.15319252014166],
        [82.41847229003935, 17.15263938903803],
        [82.41819763183594, 17.15263938903803],
        [82.41819763183594, 17.152360916137695],
        [82.41791534423857, 17.152360916137695],
        [82.41791534423857, 17.152082443237305],
        [82.41735839843761, 17.152082443237305],
        [82.41735839843761, 17.15180587768566],
        [82.41680145263666, 17.15180969238287],
        [82.41680145263666, 17.15153121948248],
        [82.41652679443382, 17.15153121948248],
        [82.41652679443382, 17.151252746582088],
        [82.41625213623064, 17.151252746582088],
        [82.41625213623064, 17.15096092224121],
        [82.41596984863287, 17.15097236633312],
        [82.41596984863287, 17.15069389343273],
        [82.41569519042986, 17.15069389343273],
        [82.41569519042986, 17.150417327880916],
        [82.41541290283232, 17.150421142578125],
        [82.41541290283232, 17.150142669677734],
        [82.4151382446289, 17.150142669677734],
        [82.4151382446289, 17.149860382080192],
        [82.41485595703153, 17.149860382080192],
        [82.41485595703153, 17.1495819091798],
        [82.4140243530274, 17.149583816528377],
        [82.4140243530274, 17.149026870727596],
        [82.41356658935564, 17.149026870727596],
        [82.41319274902361, 17.14903259277355],
        [82.41319274902361, 17.148750305175838],
        [82.41291809082037, 17.148750305175838],
        [82.41291809082037, 17.148471832275447],
        [82.41263580322283, 17.148471832275447],
        [82.41263580322283, 17.148195266723633],
        [82.41236114501982, 17.148195266723633],
        [82.41236114501982, 17.147916793823242],
        [82.4120864868164, 17.147922515869197],
        [82.4120864868164, 17.14763832092285],
        [82.4117965698245, 17.14763832092285],
        [82.4117965698245, 17.14735984802246],
        [82.41152954101568, 17.147361755371094],
        [82.41152954101568, 17.147083282470703],
        [82.41124725341808, 17.147083282470703],
        [82.41124725341808, 17.146804809570312],
        [82.4109725952149, 17.146804809570312],
        [82.4109725952149, 17.146528244018498],
        [82.41069793701166, 17.146528244018498],
        [82.41069793701166, 17.146249771118107],
        [82.41041564941429, 17.146249771118107],
        [82.41041564941429, 17.145971298217773],
        [82.41014099121111, 17.145971298217773],
        [82.41014099121111, 17.14569473266613],
        [82.40985870361334, 17.14569473266613],
        [82.40985870361334, 17.14541625976574],
        [82.4095764160158, 17.145418167114315],
        [82.4095764160158, 17.145149230957088],
        [82.40930175781278, 17.145149230957088],
        [82.40930175781278, 17.14486122131359],
        [82.40902709960938, 17.14486122131359],
        [82.40902709960938, 17.144580841064396],
        [82.40875244140653, 17.1445827484132],
        [82.40875244140653, 17.144306182861385],
        [82.4084701538086, 17.14430809020996],
        [82.4084701538086, 17.144029617309627],
        [82.40820312500011, 17.144029617309627],
        [82.40820312500011, 17.143751144409237],
        [82.40791320800787, 17.143751144409237],
        [82.40791320800787, 17.14347076416027],
        [82.40763854980463, 17.143472671508846],
        [82.40763854980463, 17.143194198608455],
        [82.40708160400408, 17.143194198608455],
        [82.40708160400408, 17.14291763305664],
        [82.40680694580084, 17.142919540405273],
        [82.40680694580084, 17.142641067504883],
        [82.40651702880876, 17.142641067504883],
        [82.40651702880876, 17.142360687255916],
        [82.40625000000028, 17.142360687255916],
        [82.40625000000028, 17.14180946350109],
        [82.4059829711914, 17.14180946350109],
        [82.4059829711914, 17.1415309906007],
        [82.4056930541995, 17.1415309906007],
        [82.4056930541995, 17.141250610351562],
        [82.4054183959961, 17.141250610351562],
        [82.4054183959961, 17.14097023010254],
        [82.40513610839872, 17.140972137451172],
        [82.40513610839872, 17.140695571899528],
        [82.40486145019537, 17.140695571899528],
        [82.40486145019537, 17.140138626098576],
        [82.40458679199213, 17.14014053344738],
        [82.40458679199213, 17.139860153198185],
        [82.40430450439459, 17.13986206054699],
        [82.40430450439459, 17.139585494995174],
        [82.40374755859398, 17.139585494995174],
        [82.40374755859398, 17.13930320739746],
        [82.4034729003908, 17.139310836791992],
        [82.4034729003908, 17.139022827148494],
        [82.40291595459001, 17.139028549194393],
        [82.40291595459001, 17.13875007629406],
        [82.402641296387, 17.13875007629406],
        [82.402641296387, 17.138469696044865],
        [82.40208435058622, 17.13847160339367],
        [82.40208435058622, 17.138193130493278],
        [82.40180206298834, 17.13820075988781],
        [82.40180206298834, 17.137920379638672],
        [82.40125274658209, 17.137920379638672],
        [82.40125274658209, 17.13764190673828],
        [82.40097045898432, 17.13764190673828],
        [82.40097045898432, 17.137359619140682],
        [82.40041351318376, 17.137361526489315],
        [82.40041351318376, 17.137081146240348],
        [82.40013885498053, 17.137081146240348],
        [82.40013885498053, 17.13680648803711],
        [82.39958190917997, 17.13681030273449],
        [82.39958190917997, 17.136531829834098],
        [82.39930725097656, 17.136531829834098],
        [82.39930725097656, 17.136249542236328],
        [82.39901733398466, 17.136249542236328],
        [82.39901733398466, 17.13569068908697],
        [82.39875030517584, 17.13569450378418],
        [82.39875030517584, 17.13541603088379],
        [82.39848327636713, 17.135421752929744],
        [82.39848327636713, 17.135139465331974],
        [82.39819335937506, 17.135139465331974],
        [82.39819335937506, 17.134859085083008],
        [82.39791870117182, 17.134859085083008],
        [82.39791870117182, 17.13431167602539],
        [82.39763641357445, 17.13431167602539],
        [82.39763641357445, 17.134029388427848],
        [82.39736175537126, 17.134029388427848],
        [82.39736175537126, 17.133750915527457],
        [82.39708709716803, 17.133750915527457],
        [82.39708709716803, 17.133470535278263],
        [82.39680480957048, 17.133472442627067],
        [82.39680480957048, 17.13291740417486],
        [82.39653015136747, 17.132919311523494],
        [82.39653015136747, 17.132640838623104],
        [82.39624786376953, 17.132640838623104],
        [82.39624786376953, 17.132360458374137],
        [82.39597320556669, 17.132360458374137],
        [82.39597320556669, 17.132078170776367],
        [82.39569091796875, 17.132083892822322],
        [82.39569091796875, 17.131805419921932],
        [82.39541625976574, 17.131811141967887],
        [82.39541625976574, 17.13153076171875],
        [82.39514160156256, 17.13153076171875],
        [82.39514160156256, 17.131250381469783],
        [82.39485931396479, 17.131250381469783],
        [82.39485931396479, 17.13097000122076],
        [82.39430236816423, 17.130971908569393],
        [82.39430236816423, 17.130695343017578],
        [82.394027709961, 17.130701065063533],
        [82.394027709961, 17.130420684814567],
        [82.39375305175798, 17.130420684814567],
        [82.39375305175798, 17.130149841308707],
        [82.39347076416044, 17.130149841308707],
        [82.39347076416044, 17.129859924316406],
        [82.39319610595703, 17.12986183166504],
        [82.39319610595703, 17.12958335876465],
        [82.39291381835966, 17.12958335876465],
        [82.39291381835966, 17.129306793212834],
        [82.39263916015625, 17.129306793212834],
        [82.39263916015625, 17.129028320312443],
        [82.39235687255888, 17.129030227661246],
        [82.39235687255888, 17.128749847412053],
        [82.39208221435553, 17.128749847412053],
        [82.39208221435553, 17.128469467163086],
        [82.39180755615229, 17.12847137451172],
        [82.39180755615229, 17.12819671630865],
        [82.39152526855474, 17.12819671630865],
        [82.39152526855474, 17.127914428710938],
        [82.39125061035173, 17.127920150756893],
        [82.39125061035173, 17.127639770507926],
        [82.3909759521485, 17.127639770507926],
        [82.3909759521485, 17.127359390258732],
        [82.39069366455095, 17.127361297607536],
        [82.39069366455095, 17.12708473205572],
        [82.39041900634794, 17.12708473205572],
        [82.39041900634794, 17.12652778625494],
        [82.39013671875017, 17.126529693603572],
        [82.39013671875017, 17.126251220703182],
        [82.38986206054716, 17.126251220703182],
        [82.38986206054716, 17.12569046020502],
        [82.38930511474638, 17.125692367553825],
        [82.38930511474638, 17.12541580200201],
        [82.38903045654303, 17.12541961669922],
        [82.38903045654303, 17.125141143798828],
        [82.38874816894543, 17.125141143798828],
        [82.38874816894543, 17.12486076354986],
        [82.38846588134771, 17.12486076354986],
        [82.38846588134771, 17.124580383300838],
        [82.38819122314447, 17.12458229064947],
        [82.38819122314447, 17.124305725097656],
        [82.38764190673845, 17.124309539795036],
        [82.38764190673845, 17.124019622802734],
        [82.38735961914068, 17.124027252197266],
        [82.38735961914068, 17.123750686645508],
        [82.38707733154314, 17.123750686645508],
        [82.38707733154314, 17.123470306396484],
        [82.38680267334013, 17.123470306396484],
        [82.38680267334013, 17.123193740844727],
        [82.38652801513672, 17.123193740844727],
        [82.38652801513672, 17.122917175292912],
        [82.38625335693388, 17.122922897338867],
        [82.38625335693388, 17.1226425170899],
        [82.385971069336, 17.1226425170899],
        [82.385971069336, 17.12236022949213],
        [82.38540649414068, 17.12236022949213],
        [82.38540649414068, 17.122081756591797],
        [82.38513946533197, 17.122083663940543],
        [82.38513946533197, 17.121805191040153],
        [82.3848571777346, 17.121812820434684],
        [82.3848571777346, 17.121532440185547],
        [82.38458251953142, 17.121532440185547],
        [82.38458251953142, 17.121250152588004],
        [82.38430786132818, 17.121250152588004],
        [82.38430786132818, 17.12098121643072],
        [82.38403320312517, 17.12098121643072],
        [82.38403320312517, 17.120700836181754],
        [82.38375091552763, 17.120700836181754],
        [82.38375091552763, 17.120418548583984],
        [82.38346099853533, 17.120418548583984],
        [82.38346099853533, 17.120138168335018],
        [82.38318634033232, 17.120138168335018],
        [82.38318634033232, 17.119859695434627],
        [82.3829193115235, 17.11986160278326],
        [82.3829193115235, 17.11958312988287],
        [82.3826370239259, 17.11958312988287],
        [82.3826370239259, 17.11930465698248],
        [82.38208007812494, 17.11930465698248],
        [82.38208007812494, 17.119028091430664],
        [82.38181304931663, 17.119028091430664],
        [82.38181304931663, 17.118749618530273],
        [82.38153076171892, 17.118749618530273],
        [82.38153076171892, 17.11847114562994],
        [82.38124847412115, 17.118473052978516],
        [82.38124847412115, 17.118194580078125],
        [82.38097381591814, 17.118194580078125],
        [82.38097381591814, 17.117916107177734],
        [82.3806915283206, 17.117919921875114],
        [82.3806915283206, 17.11763954162592],
        [82.38041687011719, 17.11763954162592],
        [82.38041687011719, 17.117361068725586],
        [82.38014221191435, 17.117361068725586],
        [82.38014221191435, 17.117080688476562],
        [82.3798599243164, 17.117082595825195],
        [82.3798599243164, 17.116807937622127],
        [82.37959289550793, 17.11680984497076],
        [82.37959289550793, 17.116529464721793],
        [82.37930297851568, 17.116529464721793],
        [82.37930297851568, 17.11597061157221],
        [82.37902832031244, 17.115972518921012],
        [82.37902832031244, 17.115695953369197],
        [82.3787536621096, 17.115695953369197],
        [82.3787536621096, 17.115417480468807],
        [82.37847137451189, 17.11541938781744],
        [82.37847137451189, 17.11514091491705],
        [82.37819671630865, 17.11514091491705],
        [82.37819671630865, 17.11485099792492],
        [82.37791442871111, 17.114860534668082],
        [82.37791442871111, 17.114585876464844],
        [82.37735748291033, 17.114585876464844],
        [82.37735748291033, 17.1143035888673],
        [82.37708282470732, 17.114311218261832],
        [82.37708282470732, 17.114030838012695],
        [82.3768081665039, 17.114030838012695],
        [82.3768081665039, 17.11375045776373],
        [82.37652587890653, 17.11375045776373],
        [82.37652587890653, 17.113470077514705],
        [82.37625122070318, 17.113473892211914],
        [82.37625122070318, 17.113195419311523],
        [82.37596893310558, 17.113189697265568],
        [82.37596893310558, 17.112916946411133],
        [82.37568664550787, 17.112920761108512],
        [82.37568664550787, 17.112640380859375],
        [82.37541961669939, 17.112640380859375],
        [82.37541961669939, 17.11236000061035],
        [82.37513732910179, 17.11236381530773],
        [82.37513732910179, 17.112081527710018],
        [82.37486267089861, 17.112081527710018],
        [82.37486267089861, 17.111803054809627],
        [82.3745880126956, 17.11181068420416],
        [82.3745880126956, 17.111530303955192],
        [82.37431335449219, 17.111530303955192],
        [82.37431335449219, 17.111249923705998],
        [82.37403106689482, 17.111249923705998],
        [82.37403106689482, 17.11096954345703],
        [82.37374877929688, 17.110971450805664],
        [82.37374877929688, 17.11069488525402],
        [82.37319183349615, 17.11069488525402],
        [82.37319183349615, 17.11041641235363],
        [82.37291717529308, 17.110420227050838],
        [82.37291717529308, 17.11013984680187],
        [82.3726425170899, 17.11013984680187],
        [82.3726425170899, 17.109859466552678],
        [82.37236022949213, 17.10986137390148],
        [82.37236022949213, 17.10958290100109],
        [82.37208557128929, 17.10958290100109],
        [82.37208557128929, 17.109306335449276],
        [82.37180328369158, 17.109310150146484],
        [82.37180328369158, 17.108749389648494],
        [82.3715209960938, 17.108749389648494],
        [82.3715209960938, 17.10847091674816],
        [82.37097167968778, 17.108472824096737],
        [82.37097167968778, 17.108194351196346],
        [82.370414733887, 17.108194351196346],
        [82.370414733887, 17.107915878295955],
        [82.37014007568365, 17.10792160034191],
        [82.37014007568365, 17.107360839843807],
        [82.36985778808605, 17.107360839843807],
        [82.36985778808605, 17.107080459594783],
        [82.36958312988287, 17.107084274291992],
        [82.36958312988287, 17.1068058013916],
        [82.36902618408226, 17.106811523437557],
        [82.36902618408226, 17.106529235839787],
        [82.36875152587908, 17.106529235839787],
        [82.36875152587908, 17.106250762939453],
        [82.36763763427734, 17.106250762939453],
        [82.36763763427734, 17.105979919433594],
        [82.3673629760745, 17.105979919433594],
        [82.3673629760745, 17.106250762939453],
        [82.36708068847656, 17.106250762939453],
        [82.36708068847656, 17.106529235839787],
        [82.36653137207037, 17.106529235839787],
        [82.36653137207037, 17.106811523437557],
        [82.3662490844726, 17.1068058013916],
        [82.3662490844726, 17.107084274291992],
        [82.36596679687506, 17.107080459594783],
        [82.36596679687506, 17.10763931274414],
        [82.36569213867205, 17.10763931274414],
        [82.36569213867205, 17.108194351196346],
        [82.3654174804688, 17.108194351196346],
        [82.3654174804688, 17.108472824096737],
        [82.3651428222658, 17.10847091674816],
        [82.3651428222658, 17.11013984680187],
        [82.3654174804688, 17.11013984680187],
        [82.3654174804688, 17.110420227050838],
        [82.36597442626976, 17.11041641235363],
        [82.36597442626976, 17.11069488525402],
        [82.3662490844726, 17.11069488525402],
        [82.3662490844726, 17.110971450805664],
        [82.36653137207037, 17.11096954345703],
        [82.36653137207037, 17.111249923705998],
        [82.36680603027355, 17.111249923705998],
        [82.36680603027355, 17.111530303955192],
        [82.36708068847656, 17.111530303955192],
        [82.36708068847656, 17.11181068420416],
        [82.3673629760745, 17.111803054809627],
        [82.3673629760745, 17.112081527710018],
        [82.36763763427734, 17.112081527710018],
        [82.36763763427734, 17.11236381530773],
        [82.36791992187528, 17.11236000061035],
        [82.36791992187528, 17.112920761108512],
        [82.3681945800783, 17.112916946411133],
        [82.3681945800783, 17.113473892211914],
        [82.36846160888695, 17.113470077514705],
        [82.36846160888695, 17.117361068725586],
        [82.36875152587908, 17.117361068725586],
        [82.36875152587908, 17.117919921875114],
        [82.36902618408226, 17.117919921875114],
        [82.36902618408226, 17.118749618530273],
        [82.36846160888695, 17.118749618530273],
        [82.36846160888695, 17.11847114562994],
        [82.3681945800783, 17.118473052978516],
        [82.3681945800783, 17.118194580078125],
        [82.36791992187528, 17.118194580078125],
        [82.36791992187528, 17.117361068725586],
        [82.36763763427734, 17.117361068725586],
        [82.36763763427734, 17.11375045776373],
        [82.3673629760745, 17.11375045776373],
        [82.3673629760745, 17.113470077514705],
        [82.36708068847656, 17.113473892211914],
        [82.36708068847656, 17.113195419311523],
        [82.36680603027355, 17.113189697265568],
        [82.36680603027355, 17.112638473510742],
        [82.36653137207037, 17.112640380859375],
        [82.36653137207037, 17.11236000061035],
        [82.3662490844726, 17.11236381530773],
        [82.3662490844726, 17.112081527710018],
        [82.3654174804688, 17.112081527710018],
        [82.3654174804688, 17.111803054809627],
        [82.3651428222658, 17.11181068420416],
        [82.3651428222658, 17.111530303955192],
        [82.36486053466825, 17.111530303955192],
        [82.36486053466825, 17.111249923705998],
        [82.36458587646484, 17.111249923705998],
        [82.36458587646484, 17.11096954345703],
        [82.36430358886747, 17.110971450805664],
        [82.36430358886747, 17.11069488525402],
        [82.36402893066406, 17.11069488525402],
        [82.36402893066406, 17.11041641235363],
        [82.36374664306669, 17.110420227050838],
        [82.36374664306669, 17.109859466552678],
        [82.36347198486334, 17.10986137390148],
        [82.36347198486334, 17.109306335449276],
        [82.3631973266601, 17.109310150146484],
        [82.3631973266601, 17.10763931274414],
        [82.36347198486334, 17.10763931274414],
        [82.36347198486334, 17.107360839843807],
        [82.36402893066406, 17.107360839843807],
        [82.36402893066406, 17.107080459594783],
        [82.36430358886747, 17.107082366943416],
        [82.36430358886747, 17.1068058013916],
        [82.36458587646484, 17.106811523437557],
        [82.36458587646484, 17.106529235839787],
        [82.36486053466825, 17.106529235839787],
        [82.36486053466825, 17.106250762939453],
        [82.3651428222658, 17.106250762939453],
        [82.36513519287126, 17.105972290039062],
        [82.3654174804688, 17.105979919433594],
        [82.3654174804688, 17.105688095092887],
        [82.36569213867205, 17.105693817138672],
        [82.36569213867205, 17.10458374023449],
        [82.3654174804688, 17.10458374023449],
        [82.3654174804688, 17.104305267334098],
        [82.3651428222658, 17.104309082031307],
        [82.3651428222658, 17.104030609130916],
        [82.36486053466825, 17.104030609130916],
        [82.36486053466825, 17.10375022888195],
        [82.36458587646484, 17.10375022888195],
        [82.36458587646484, 17.103469848632756],
        [82.36430358886747, 17.10347175598156],
        [82.36430358886747, 17.103195190429744],
        [82.36374664306669, 17.1032009124757],
        [82.36374664306669, 17.1029109954834],
        [82.36347198486334, 17.102916717529354],
        [82.36347198486334, 17.102638244628963],
        [82.36292266845726, 17.102640151977596],
        [82.36292266845726, 17.102359771728572],
        [82.36264038085955, 17.102361679077205],
        [82.36264038085955, 17.101806640625],
        [82.36235809326195, 17.101806640625],
        [82.36235809326195, 17.10152816772461],
        [82.36207580566423, 17.101530075073242],
        [82.36207580566423, 17.10124969482422],
        [82.36180877685575, 17.10124969482422],
        [82.36180877685575, 17.100971221923885],
        [82.36152648925798, 17.10097312927246],
        [82.36152648925798, 17.100696563720646],
        [82.36096954345703, 17.100696563720646],
        [82.36096954345703, 17.100414276123104],
        [82.36068725585966, 17.10041999816906],
        [82.36068725585966, 17.09984970092779],
        [82.36042022705084, 17.09986114501953],
        [82.36042022705084, 17.09930419921875],
        [82.36013793945324, 17.099309921264705],
        [82.36013793945324, 17.09902954101574],
        [82.35986328125006, 17.09902954101574],
        [82.35986328125006, 17.098751068115348],
        [82.35958099365229, 17.098751068115348],
        [82.35958099365229, 17.098470687866154],
        [82.35930633544945, 17.098474502563533],
        [82.35930633544945, 17.09819221496582],
        [82.35902404785173, 17.09819221496582],
        [82.35902404785173, 17.097917556762752],
        [82.3587493896485, 17.097919464111385],
        [82.3587493896485, 17.097080230712834],
        [82.35847473144548, 17.097082138061637],
        [82.35847473144548, 17.096805572509822],
        [82.35819244384794, 17.09680938720703],
        [82.35819244384794, 17.096532821655387],
        [82.35791778564453, 17.096532821655387],
        [82.35791778564453, 17.096250534057674],
        [82.35763549804716, 17.096250534057674],
        [82.35763549804716, 17.09597015380865],
        [82.3573608398438, 17.09597015380865],
        [82.3573608398438, 17.095693588256893],
        [82.35708618164074, 17.095693588256893],
        [82.35708618164074, 17.095417022705078],
        [82.3567962646485, 17.095422744751033],
        [82.3567962646485, 17.09514045715332],
        [82.35652160644526, 17.09514045715332],
        [82.35652160644526, 17.094860076904297],
        [82.35624694824241, 17.094860076904297],
        [82.35624694824241, 17.09458351135254],
        [82.35597229003923, 17.09458351135254],
        [82.35597229003923, 17.09430503845215],
        [82.35541534423845, 17.09430503845215],
        [82.35541534423845, 17.09458351135254],
        [82.35514068603544, 17.09458351135254],
        [82.35514068603544, 17.094860076904297],
        [82.3548583984375, 17.094860076904297],
        [82.3548583984375, 17.095693588256893],
        [82.35457611084013, 17.095691680908317],
        [82.35457611084013, 17.096250534057674],
        [82.3543090820313, 17.096250534057674],
        [82.3543090820313, 17.096532821655387],
        [82.35402679443371, 17.096532821655387],
        [82.35402679443371, 17.09680938720703],
        [82.35375213623053, 17.096805572509822],
        [82.35375213623053, 17.097082138061637],
        [82.3534622192384, 17.097080230712834],
        [82.3534622192384, 17.09764289855957],
        [82.35320281982445, 17.09764289855957],
        [82.35320281982445, 17.097919464111385],
        [82.3529129028322, 17.097917556762752],
        [82.3529129028322, 17.09819221496582],
        [82.35152435302763, 17.09819221496582],
        [82.35152435302763, 17.097917556762752],
        [82.35124969482422, 17.097919464111385],
        [82.35124969482422, 17.097360610962028],
        [82.3515167236331, 17.097360610962028],
        [82.3515167236331, 17.097080230712834],
        [82.35208129882841, 17.097082138061637],
        [82.35208129882841, 17.096805572509822],
        [82.35235595703142, 17.09680938720703],
        [82.35235595703142, 17.096532821655387],
        [82.35263824462896, 17.096532821655387],
        [82.35263824462896, 17.096250534057674],
        [82.3529129028322, 17.096250534057674],
        [82.3529129028322, 17.095691680908317],
        [82.35319519042991, 17.095693588256893],
        [82.35319519042991, 17.093748092651367],
        [82.3529129028322, 17.093748092651367],
        [82.3529129028322, 17.09320068359375],
        [82.35263824462896, 17.09320068359375],
        [82.35263824462896, 17.092639923095817],
        [82.35235595703142, 17.092639923095817],
        [82.35235595703142, 17.092359542846623],
        [82.35208129882841, 17.092361450195426],
        [82.35208129882841, 17.092082977295036],
        [82.351806640625, 17.092082977295036],
        [82.351806640625, 17.091529846191463],
        [82.3515167236331, 17.091529846191463],
        [82.3515167236331, 17.09124946594244],
        [82.35124969482422, 17.09124946594244],
        [82.35124969482422, 17.090690612792912],
        [82.35097503662121, 17.09069442749029],
        [82.35097503662121, 17.090139389038086],
        [82.3506927490235, 17.090139389038086],
        [82.3506927490235, 17.089860916137752],
        [82.35013580322271, 17.089860916137752],
        [82.35013580322271, 17.08958053588873],
        [82.3498611450197, 17.08958244323736],
        [82.3498611450197, 17.089307785034123],
        [82.34958648681646, 17.089309692382926],
        [82.34958648681646, 17.089031219482536],
        [82.34929656982439, 17.089031219482536],
        [82.34929656982439, 17.0887508392334],
        [82.34902954101591, 17.0887508392334],
        [82.34902954101591, 17.088470458984375],
        [82.34874725341814, 17.088472366333008],
        [82.34874725341814, 17.088195800781364],
        [82.34819793701172, 17.088195800781364],
        [82.34819793701172, 17.087917327880803],
        [82.34791564941435, 17.087917327880803],
        [82.34791564941435, 17.087638854980412],
        [82.347640991211, 17.087640762329215],
        [82.347640991211, 17.08736038208002],
        [82.3473587036134, 17.08736038208002],
        [82.3473587036134, 17.087080001831055],
        [82.34708404541021, 17.08708572387701],
        [82.34708404541021, 17.086803436279297],
        [82.34680175781244, 17.086811065673828],
        [82.34680175781244, 17.08653068542486],
        [82.3465270996096, 17.08653068542486],
        [82.3465270996096, 17.086250305175895],
        [82.34625244140642, 17.086250305175895],
        [82.34625244140642, 17.0859699249267],
        [82.34597015380865, 17.08597373962408],
        [82.34597015380865, 17.085693359375114],
        [82.3454132080081, 17.085700988769645],
        [82.3454132080081, 17.085420608520508],
        [82.34513854980469, 17.085420608520508],
        [82.34513854980469, 17.08514022827154],
        [82.34485626220732, 17.08514022827154],
        [82.34485626220732, 17.08485031127941],
        [82.34458160400396, 17.084863662719727],
        [82.34458160400396, 17.084581375122184],
        [82.3443069458009, 17.084581375122184],
        [82.3443069458009, 17.08430480957037],
        [82.34402465820318, 17.08430480957037],
        [82.34402465820318, 17.084028244018555],
        [82.34375, 17.084030151367188],
        [82.34375, 17.083749771118164],
        [82.3434753417971, 17.083749771118164],
        [82.3434753417971, 17.08347129821783],
        [82.34319305419939, 17.08347129821783],
        [82.34319305419939, 17.083194732666016],
        [82.34291839599615, 17.083194732666016],
        [82.34291839599615, 17.082916259765625],
        [82.34263610839861, 17.082920074463004],
        [82.34263610839861, 17.08263969421381],
        [82.3423614501956, 17.08263969421381],
        [82.3423614501956, 17.082359313964844],
        [82.34208679199219, 17.082359313964844],
        [82.34208679199219, 17.082080841064453],
        [82.34180450439482, 17.082082748413086],
        [82.34180450439482, 17.081806182861442],
        [82.34152221679688, 17.081811904907227],
        [82.34152221679688, 17.081529617309684],
        [82.34124755859386, 17.081529617309684],
        [82.34124755859386, 17.08124923706049],
        [82.34097290039068, 17.08124923706049],
        [82.34097290039068, 17.0809707641601],
        [82.34069824218744, 17.080972671508903],
        [82.34069061279291, 17.080694198608512],
        [82.34041595459007, 17.080694198608512],
        [82.34041595459007, 17.080417633056697],
        [82.34014129638689, 17.080421447753906],
        [82.34014129638689, 17.080139160156307],
        [82.33985900878912, 17.080139160156307],
        [82.33985900878912, 17.079860687255973],
        [82.33957672119158, 17.079860687255973],
        [82.33957672119158, 17.07958030700678],
        [82.33930206298857, 17.07958412170416],
        [82.33930206298857, 17.079305648803768],
        [82.33902740478516, 17.079307556152344],
        [82.33902740478516, 17.079029083251953],
        [82.33875274658232, 17.079029083251953],
        [82.33875274658232, 17.07875061035162],
        [82.33847045898438, 17.07875061035162],
        [82.33847045898438, 17.07847213745123],
        [82.33819580078136, 17.07847213745123],
        [82.33819580078136, 17.078193664550838],
        [82.33791351318365, 17.078193664550838],
        [82.33791351318365, 17.077917098999023],
        [82.33763885498041, 17.077920913696403],
        [82.33763885498041, 17.077640533447266],
        [82.33735656738287, 17.077640533447266],
        [82.33735656738287, 17.077360153198242],
        [82.33708190917986, 17.077362060546875],
        [82.33708190917986, 17.077083587646484],
        [82.33652496337908, 17.077083587646484],
        [82.33652496337908, 17.076805114746094],
        [82.33625030517607, 17.07681083679205],
        [82.33625030517607, 17.076530456543082],
        [82.33598327636719, 17.076530456543082],
        [82.33598327636719, 17.07625007629389],
        [82.33569335937528, 17.07625007629389],
        [82.33569335937528, 17.075969696044922],
        [82.33541870117188, 17.075971603393555],
        [82.33541870117188, 17.075696945190487],
        [82.3351364135745, 17.075700759887695],
        [82.3351364135745, 17.07542037963873],
        [82.33486175537115, 17.07542037963873],
        [82.33486175537115, 17.074859619140568],
        [82.33458709716791, 17.07486152648937],
        [82.33458709716791, 17.07458305358898],
        [82.33430480957037, 17.07458305358898],
        [82.33430480957037, 17.074306488037166],
        [82.33403015136736, 17.074310302734375],
        [82.33403015136736, 17.07402992248541],
        [82.33374786376976, 17.07402992248541],
        [82.33374786376976, 17.073749542236385],
        [82.3331909179688, 17.073749542236385],
        [82.3331909179688, 17.07347106933605],
        [82.3329162597658, 17.073474884033203],
        [82.3329162597658, 17.07319259643566],
        [82.33264160156278, 17.07319259643566],
        [82.33264160156278, 17.07291412353527],
        [82.33235931396484, 17.07291412353527],
        [82.33235931396484, 17.07263946533203],
        [82.33180236816412, 17.07263946533203],
        [82.33180236816412, 17.072362899780273],
        [82.33152770996105, 17.072362899780273],
        [82.33152770996105, 17.07180976867687],
        [82.33125305175787, 17.07180976867687],
        [82.33125305175787, 17.071529388427678],
        [82.3309707641601, 17.071529388427678],
        [82.3309707641601, 17.07125282287609],
        [82.33069610595726, 17.07125282287609],
        [82.33069610595726, 17.07097053527832],
        [82.33041381835955, 17.0709743499757],
        [82.33041381835955, 17.070692062377987],
        [82.3301391601563, 17.070692062377987],
        [82.3301391601563, 17.070417404174748],
        [82.32985687255876, 17.070421218872127],
        [82.32985687255876, 17.070142745971737],
        [82.32958221435575, 17.070142745971737],
        [82.32958221435575, 17.0698699951173],
        [82.32930755615234, 17.0698699951173],
        [82.32930755615234, 17.069580078125],
        [82.32902526855497, 17.06958389282238],
        [82.32902526855497, 17.06930541992199],
        [82.32875061035162, 17.069311141967773],
        [82.32875061035162, 17.069032669067383],
        [82.32847595214855, 17.069032669067383],
        [82.32847595214855, 17.06875038146984],
        [82.3281860351563, 17.06875038146984],
        [82.3281860351563, 17.06847190856945],
        [82.3279190063476, 17.06847190856945],
        [82.3279190063476, 17.068195343017635],
        [82.32763671875023, 17.06820106506359],
        [82.32763671875023, 17.0679225921632],
        [82.32736206054705, 17.0679225921632],
        [82.32736206054705, 17.067638397216854],
        [82.32707977294928, 17.067638397216854],
        [82.32707977294928, 17.067359924316463],
        [82.32680511474626, 17.067361831665096],
        [82.32680511474626, 17.067083358764705],
        [82.32653045654325, 17.067083358764705],
        [82.32653045654325, 17.066804885864315],
        [82.32624816894531, 17.06681060791027],
        [82.32624816894531, 17.0665283203125],
        [82.32596588134794, 17.0665283203125],
        [82.32596588134794, 17.06624984741211],
        [82.32569122314453, 17.06624984741211],
        [82.32569122314453, 17.065969467163143],
        [82.32541656494152, 17.065971374511776],
        [82.32541656494152, 17.06569480895996],
        [82.32514190673834, 17.06569480895996],
        [82.32514190673834, 17.06541633605957],
        [82.32485961914057, 17.065418243408146],
        [82.32485961914057, 17.065139770507756],
        [82.32457733154303, 17.065139770507756],
        [82.32457733154303, 17.06485939025879],
        [82.32430267334001, 17.064861297607422],
        [82.32430267334001, 17.06458282470703],
        [82.32402801513678, 17.06458282470703],
        [82.32402801513678, 17.064306259155387],
        [82.32375335693376, 17.064308166503963],
        [82.32375335693376, 17.06402969360363],
        [82.32347869873075, 17.06402969360363],
        [82.32347869873075, 17.063739776611328],
        [82.32319641113281, 17.063749313354435],
        [82.32319641113281, 17.063472747802848],
        [82.32291412353544, 17.063472747802848],
        [82.32291412353544, 17.062917709350643],
        [82.32263946533203, 17.062919616699276],
        [82.32263946533203, 17.062641143798885],
        [82.32235717773466, 17.062641143798885],
        [82.32235717773466, 17.06236076354992],
        [82.3220825195313, 17.06236076354992],
        [82.3220825195313, 17.062080383300724],
        [82.32180786132807, 17.062084197998104],
        [82.32180786132807, 17.06180763244629],
        [82.32153320312523, 17.061809539794922],
        [82.32153320312523, 17.06153106689453],
        [82.32125091552751, 17.06153106689453],
        [82.32125091552751, 17.061250686645565],
        [82.32096862792991, 17.061250686645565],
        [82.32096862792991, 17.06097030639654],
        [82.32069396972673, 17.060972213745174],
        [82.32069396972673, 17.06069564819336],
        [82.32041931152372, 17.06069564819336],
        [82.32041931152372, 17.06041717529297],
        [82.32013702392595, 17.060420989990348],
        [82.32013702392595, 17.06014060974121],
        [82.31986236572294, 17.06014060974121],
        [82.31986236572294, 17.059860229492188],
        [82.319580078125, 17.059860229492188],
        [82.319580078125, 17.05957984924322],
        [82.31930541992216, 17.059585571289176],
        [82.31930541992216, 17.059303283691463],
        [82.3190307617188, 17.059310913085994],
        [82.3190307617188, 17.058750152587834],
        [82.31874847412121, 17.058750152587834],
        [82.31874847412121, 17.058469772338867],
        [82.31847381591803, 17.0584716796875],
        [82.31847381591803, 17.05819320678711],
        [82.31819152832026, 17.05820083618164],
        [82.31819152832026, 17.05792999267578],
        [82.31791687011741, 17.05792999267578],
        [82.31791687011741, 17.057640075683707],
        [82.31764221191423, 17.057640075683707],
        [82.31764221191423, 17.057359695434513],
        [82.31735992431646, 17.057363510131893],
        [82.31735992431646, 17.057081222534123],
        [82.31708526611345, 17.057081222534123],
        [82.31708526611345, 17.056804656982536],
        [82.31680297851591, 17.056804656982536],
        [82.31680297851591, 17.05652809143072],
        [82.3165283203125, 17.05653190612793],
        [82.3165283203125, 17.05624961853033],
        [82.31624603271513, 17.05624961853033],
        [82.31624603271513, 17.055971145629996],
        [82.31597137451178, 17.055971145629996],
        [82.31597137451178, 17.055694580078182],
        [82.31569671630871, 17.055694580078182],
        [82.31569671630871, 17.055139541625977],
        [82.31540679931646, 17.055139541625977],
        [82.31540679931646, 17.05487060546875],
        [82.31513977050776, 17.05487060546875],
        [82.31513977050776, 17.05458068847662],
        [82.31485748291038, 17.054582595825252],
        [82.31485748291038, 17.054306030273438],
        [82.3145828247072, 17.054311752319393],
        [82.3145828247072, 17.054029464721623],
        [82.31430816650396, 17.054029464721623],
        [82.31430816650396, 17.053749084472656],
        [82.31402587890642, 17.053749084472656],
        [82.31402587890642, 17.053470611572266],
        [82.31375122070341, 17.0534725189209],
        [82.31375122070341, 17.053194046020508],
        [82.31346893310547, 17.053194046020508],
        [82.31346893310547, 17.052917480468693],
        [82.3131866455081, 17.052921295166072],
        [82.3131866455081, 17.052360534667912],
        [82.31291961669928, 17.052360534667912],
        [82.31291961669928, 17.052080154418945],
        [82.31263732910168, 17.052083969116325],
        [82.31263732910168, 17.051805496215934],
        [82.3123626708985, 17.051805496215934],
        [82.3123626708985, 17.05152893066412],
        [82.31208038330072, 17.051530838012752],
        [82.31208038330072, 17.051250457763786],
        [82.31180572509788, 17.051250457763786],
        [82.31180572509788, 17.050971984863395],
        [82.3115310668947, 17.050971984863395],
        [82.3115310668947, 17.05069541931158],
        [82.31124877929693, 17.050689697265625],
        [82.31124877929693, 17.050418853759766],
        [82.31096649169939, 17.0504207611084],
        [82.31096649169939, 17.050140380859432],
        [82.31069183349638, 17.050140380859432],
        [82.31069183349638, 17.04957962036127],
        [82.31041717529297, 17.04958343505865],
        [82.31041717529297, 17.049306869506836],
        [82.31014251709013, 17.049310684204215],
        [82.31014251709013, 17.049030303955078],
        [82.30986022949219, 17.049030303955078],
        [82.30986022949219, 17.048740386962947],
        [82.30958557128918, 17.048749923706055],
        [82.30958557128918, 17.04847145080572],
        [82.30930328369146, 17.04847145080572],
        [82.30930328369146, 17.048196792602482],
        [82.30902862548822, 17.048196792602482],
        [82.30902862548822, 17.047916412353516],
        [82.30874633789068, 17.047920227050895],
        [82.30874633789068, 17.0476398468017],
        [82.30847167968767, 17.0476398468017],
        [82.30847167968767, 17.047080993652344],
        [82.30819702148443, 17.047084808349723],
        [82.30819702148443, 17.046806335449332],
        [82.30790710449236, 17.04681015014654],
        [82.30790710449236, 17.046529769897575],
        [82.30764007568388, 17.046529769897575],
        [82.30764007568388, 17.04624938964838],
        [82.30735778808611, 17.04624938964838],
        [82.30735778808611, 17.04597091674799],
        [82.3070831298831, 17.04597473144537],
        [82.3070831298831, 17.045692443847656],
        [82.30680847167969, 17.045700073242188],
        [82.30680847167969, 17.04514122009283],
        [82.30652618408232, 17.04514122009283],
        [82.30652618408232, 17.044860839843864],
        [82.30625152587896, 17.044860839843864],
        [82.30625152587896, 17.04458045959467],
        [82.30596923828136, 17.044582366943473],
        [82.30596923828136, 17.04430580139166],
        [82.30568695068365, 17.044309616088867],
        [82.30568695068365, 17.044031143188477],
        [82.30541229248041, 17.044031143188477],
        [82.30541229248041, 17.043470382690487],
        [82.30513763427757, 17.043470382690487],
        [82.30513763427757, 17.04319381713873],
        [82.30486297607439, 17.04319381713873],
        [82.30486297607439, 17.042917251586914],
        [82.30458068847662, 17.0429306030274],
        [82.30458068847662, 17.042642593383903],
        [82.30430603027361, 17.042642593383903],
        [82.30430603027361, 17.042360305786133],
        [82.3040313720706, 17.042360305786133],
        [82.3040313720706, 17.0420818328858],
        [82.30374908447266, 17.042083740234375],
        [82.30374908447266, 17.04152870178217],
        [82.30346679687528, 17.04153251647955],
        [82.30346679687528, 17.04125022888178],
        [82.30319213867193, 17.04125022888178],
        [82.30319213867193, 17.040969848632812],
        [82.30291748046886, 17.040971755981445],
        [82.30291748046886, 17.0406951904298],
        [82.30264282226568, 17.040700912475586],
        [82.30264282226568, 17.04013824462885],
        [82.30236816406267, 17.04013824462885],
        [82.30236816406267, 17.03985977172846],
        [82.30208587646507, 17.039861679077262],
        [82.30208587646507, 17.03930473327648],
        [82.30180358886736, 17.03930473327648],
        [82.30180358886736, 17.038749694824276],
        [82.30152893066412, 17.038749694824276],
        [82.30152893066412, 17.038471221923942],
        [82.30124664306658, 17.038473129272518],
        [82.30124664306658, 17.037916183471737],
        [82.30097198486357, 17.037918090820312],
        [82.30097198486357, 17.037639617919922],
        [82.30069732666016, 17.037639617919922],
        [82.30069732666016, 17.037080764770565],
        [82.30041503906278, 17.037082672119197],
        [82.30041503906278, 17.036527633666992],
        [82.30014038085943, 17.036529541015568],
        [82.30014038085943, 17.036251068115234],
        [82.29985809326183, 17.036251068115234],
        [82.29985809326183, 17.035690307617244],
        [82.29958343505865, 17.035694122314453],
        [82.29958343505865, 17.03541755676264],
        [82.29930877685541, 17.035419464111442],
        [82.29930877685541, 17.034860610961857],
        [82.29902648925804, 17.034860610961857],
        [82.29902648925804, 17.034309387207088],
        [82.29875183105486, 17.034309387207088],
        [82.29875183105486, 17.034030914306697],
        [82.29846954345709, 17.034030914306697],
        [82.29846954345709, 17.033470153808537],
        [82.29819488525408, 17.03347206115734],
        [82.29819488525408, 17.033195495605526],
        [82.29792022705107, 17.033195495605526],
        [82.29792022705107, 17.032917022705135],
        [82.29763793945312, 17.032920837402344],
        [82.29763793945312, 17.03265953063959],
        [82.29763793945312, 17.032363891601562],
        [82.29736328125028, 17.032363891601562],
        [82.29736328125028, 17.03180313110363],
        [82.29708099365234, 17.03181076049816],
        [82.29708099365234, 17.03125],
        [82.29680633544933, 17.03125],
        [82.29680633544933, 17.030969619751033],
        [82.29652404785162, 17.030971527099666],
        [82.29652404785162, 17.03041648864746],
        [82.29624176025402, 17.03042030334484],
        [82.29624176025402, 17.02985954284668],
        [82.29597473144554, 17.029861450195312],
        [82.29597473144554, 17.02958106994629],
        [82.29569244384783, 17.02958106994629],
        [82.29569244384783, 17.029027938842717],
        [82.29541778564459, 17.029031753540096],
        [82.29541778564459, 17.028749465942326],
        [82.29513549804705, 17.028749465942326],
        [82.29513549804705, 17.028194427490348],
        [82.29486083984403, 17.028194427490348],
        [82.29486083984403, 17.027639389038143],
        [82.29458618164062, 17.027639389038143],
        [82.29458618164062, 17.02736091613781],
        [82.29429626464872, 17.02736091613781],
        [82.29429626464872, 17.02681159973156],
        [82.29402923583984, 17.02681159973156],
        [82.29402923583984, 17.026250839233455],
        [82.29374694824247, 17.026250839233455],
        [82.29374694824247, 17.02568817138672],
        [82.29347229003912, 17.025693893432674],
        [82.29347229003912, 17.02513885498047],
        [82.29319763183588, 17.0251407623291],
        [82.29319763183588, 17.024311065673885],
        [82.29292297363304, 17.024311065673885],
        [82.29292297363304, 17.023750305175724],
        [82.29264068603533, 17.023750305175724],
        [82.29264068603533, 17.022920608520565],
        [82.29235839843773, 17.022920608520565],
        [82.29235839843773, 17.022359848022404],
        [82.29208374023455, 17.022361755371207],
        [82.29208374023455, 17.021806716919002],
        [82.29180908203153, 17.021806716919002],
        [82.29180908203153, 17.020971298217887],
        [82.29152679443376, 17.020971298217887],
        [82.29152679443376, 17.020414352417106],
        [82.29125213623075, 17.02042007446289],
        [82.29125213623075, 17.019861221313533],
        [82.29096984863281, 17.019861221313533],
        [82.29096984863281, 17.019309997558707],
        [82.29070281982433, 17.019309997558707],
        [82.29070281982433, 17.018470764160156],
        [82.29041290283209, 17.018474578857536],
        [82.29041290283209, 17.017917633056584],
        [82.29013824462902, 17.017919540405387],
        [82.29013824462902, 17.017641067504996],
        [82.2898559570313, 17.017641067504996],
        [82.2898559570313, 17.017080307006836],
        [82.28958129882807, 17.01708221435547],
        [82.28958129882807, 17.016527175903434],
        [82.28930664062523, 17.01653289794922],
        [82.28930664062523, 17.015970230102482],
        [82.28902435302751, 17.015970230102482],
        [82.28902435302751, 17.01541709899908],
        [82.28874969482428, 17.015422821045036],
        [82.28874969482428, 17.0148601531983],
        [82.28847503662126, 17.0148601531983],
        [82.28847503662126, 17.01458358764654],
        [82.28819274902372, 17.01458358764654],
        [82.28819274902372, 17.014028549194336],
        [82.28791809082031, 17.014028549194336],
        [82.28791809082031, 17.01374816894537],
        [82.28763580322294, 17.01374816894537],
        [82.28763580322294, 17.01346969604498],
        [82.28736114501959, 17.01347160339361],
        [82.28736114501959, 17.012638092041016],
        [82.28708648681652, 17.012638092041016],
        [82.28708648681652, 17.012083053588867],
        [82.2868041992188, 17.012083053588867],
        [82.2868041992188, 17.011529922485465],
        [82.28652954101557, 17.011529922485465],
        [82.28652954101557, 17.01097106933588],
        [82.2862472534182, 17.010972976684684],
        [82.2862472534182, 17.01041793823248],
        [82.28597259521501, 17.01041984558111],
        [82.28597259521501, 17.009860992431754],
        [82.28569793701178, 17.009860992431754],
        [82.28569793701178, 17.009029388427734],
        [82.28542327880876, 17.009029388427734],
        [82.28542327880876, 17.00819015502924],
        [82.28514099121122, 17.008195877075195],
        [82.28514099121122, 17.007360458374023],
        [82.28485870361328, 17.007360458374023],
        [82.28485870361328, 17.006530761718864],
        [82.28457641601591, 17.006530761718864],
        [82.28457641601591, 17.005701065063477],
        [82.2843017578125, 17.005701065063477],
        [82.2843017578125, 17.004579544067383],
        [82.28402709960949, 17.00458145141596],
        [82.28402709960949, 17.003749847412166],
        [82.2837524414063, 17.003749847412166],
        [82.2837524414063, 17.002920150756836],
        [82.28347015380854, 17.002920150756836],
        [82.28347015380854, 17.002359390258846],
        [82.2831954956057, 17.002359390258846],
        [82.2831954956057, 17.001249313354492],
        [82.28291320800798, 17.001249313354492],
        [82.28291320800798, 17.00042152404791],
        [82.28263854980474, 17.00042152404791],
        [82.28263854980474, 17.00013923645014],
        [82.2823562622072, 17.00013923645014],
        [82.2823562622072, 16.999860763549748],
        [82.2823562622072, 16.99958038330078],
        [82.28208160400419, 16.99958419799816],
        [82.28208160400419, 16.99930572509777],
        [82.28180694580078, 16.999307632446346],
        [82.28180694580078, 16.999029159545955],
        [82.28151702880888, 16.999029159545955],
        [82.28151702880888, 16.99875068664562],
        [82.28124237060547, 16.99875068664562],
        [82.28124237060547, 16.998470306396428],
        [82.28097534179699, 16.99847221374523],
        [82.28097534179699, 16.99819374084484],
        [82.28069305419928, 16.99819374084484],
        [82.28069305419928, 16.997917175293026],
        [82.28041839599604, 16.9979190826416],
        [82.28041839599604, 16.9976482391358],
        [82.2801361083985, 16.9976482391358],
        [82.2801361083985, 16.997360229492244],
        [82.27986145019548, 16.997360229492244],
        [82.27986145019548, 16.99625015258789],
        [82.27958679199224, 16.99625015258789],
        [82.27958679199224, 16.995140075683537],
        [82.27929687500017, 16.995140075683537],
        [82.27929687500017, 16.994590759277344],
        [82.27902984619169, 16.994590759277344],
        [82.27902984619169, 16.99402999877941],
        [82.27874755859392, 16.99402999877941],
        [82.27874755859392, 16.99319076538086],
        [82.27847290039091, 16.993192672729492],
        [82.27847290039091, 16.992084503173885],
        [82.2781982421875, 16.992084503173885],
        [82.2781982421875, 16.99093055725109],
        [82.2781982421875, 16.99041748046875],
        [82.27847290039091, 16.990419387817326],
        [82.27847290039091, 16.989311218261776],
        [82.27874755859392, 16.989311218261776],
        [82.27874755859392, 16.989032745361385],
        [82.27929687500017, 16.989032745361385],
        [82.27929687500017, 16.988191604614258],
        [82.27958679199224, 16.98819351196289],
        [82.27958679199224, 16.987916946411246],
        [82.27986145019548, 16.98792266845703],
        [82.27986145019548, 16.987079620361328],
        [82.2801361083985, 16.987083435058707],
        [82.2801361083985, 16.986528396606502],
        [82.28041839599604, 16.986528396606502],
        [82.28041839599604, 16.98624992370611],
        [82.28098297119152, 16.98624992370611],
        [82.28097534179699, 16.985971450805778],
        [82.28125, 16.985971450805778],
        [82.28125, 16.985139846801758],
        [82.28151702880888, 16.985139846801758],
        [82.28151702880888, 16.98485946655279],
        [82.28263854980474, 16.984861373901424],
        [82.28263854980474, 16.984582901001033],
        [82.28347015380854, 16.984582901001033],
        [82.28347015380854, 16.98430633544922],
        [82.2843017578125, 16.98430633544922],
        [82.28430938720709, 16.984582901001033],
        [82.28514099121122, 16.984582901001033],
        [82.28514099121122, 16.98430633544922],
        [82.28542327880876, 16.984308242797795],
        [82.28542327880876, 16.984029769897404],
        [82.28569793701178, 16.984029769897404],
        [82.28569793701178, 16.98319053649908],
        [82.28541564941423, 16.98319435119629],
        [82.28541564941423, 16.982917785644474],
        [82.28514099121122, 16.982919692993278],
        [82.28514099121122, 16.982650756835994],
        [82.28457641601591, 16.982650756835994],
        [82.28457641601591, 16.982360839843693],
        [82.2837524414063, 16.982360839843693],
        [82.2837524414063, 16.982650756835994],
        [82.28291320800798, 16.982650756835994],
        [82.28291320800798, 16.982919692993278],
        [82.28151702880888, 16.982919692993278],
        [82.28151702880888, 16.982650756835994],
        [82.28124237060547, 16.982650756835994],
        [82.28124237060547, 16.982919692993278],
        [82.28069305419928, 16.982917785644474],
        [82.28069305419928, 16.98347282409668],
        [82.27958679199224, 16.983470916748047],
        [82.27958679199224, 16.983749389648438],
        [82.2781982421875, 16.983749389648438],
        [82.2781982421875, 16.983470916748047],
        [82.27764129638678, 16.98347282409668],
        [82.27764129638678, 16.98319435119629],
        [82.27735900878918, 16.98319435119629],
        [82.27735900878918, 16.982917785644474],
        [82.27707672119146, 16.982919692993278],
        [82.27707672119146, 16.982650756835994],
        [82.27680206298822, 16.982650756835994],
        [82.27680206298822, 16.982360839843693],
        [82.27652740478538, 16.982360839843693],
        [82.27652740478538, 16.981809616088924],
        [82.2762527465822, 16.981809616088924],
        [82.2762527465822, 16.980690002441406],
        [82.27597045898443, 16.98069572448736],
        [82.27597045898443, 16.979585647583008],
        [82.27569580078142, 16.979585647583008],
        [82.27569580078142, 16.977081298828125],
        [82.27597045898443, 16.977081298828125],
        [82.27597045898443, 16.976804733276367],
        [82.277084350586, 16.976804733276367],
        [82.277084350586, 16.976528167724553],
        [82.27958679199224, 16.976528167724553],
        [82.27958679199224, 16.976804733276367],
        [82.2801361083985, 16.976804733276367],
        [82.2801361083985, 16.977081298828125],
        [82.28069305419928, 16.977081298828125],
        [82.28069305419928, 16.977363586425895],
        [82.28263854980474, 16.977363586425895],
        [82.28263854980474, 16.977081298828125],
        [82.2837524414063, 16.977081298828125],
        [82.2837524414063, 16.976804733276367],
        [82.28402709960949, 16.976804733276367],
        [82.28402709960949, 16.976528167724553],
        [82.2837524414063, 16.976528167724553],
        [82.2837524414063, 16.975694656372184],
        [82.28402709960949, 16.975694656372184],
        [82.28402709960949, 16.97402763366705],
        [82.2837524414063, 16.974031448364258],
        [82.2837524414063, 16.97346115112316],
        [82.2831954956057, 16.9734725952149],
        [82.2831954956057, 16.97319412231451],
        [82.28347015380854, 16.97319412231451],
        [82.28347015380854, 16.97263717651373],
        [82.2837524414063, 16.972639083862305],
        [82.2837524414063, 16.971530914306754],
        [82.28347015380854, 16.971530914306754],
        [82.28347015380854, 16.97125053405756],
        [82.2831954956057, 16.97125053405756],
        [82.2831954956057, 16.970972061157227],
        [82.27986145019548, 16.970970153808594],
        [82.27986145019548, 16.97125053405756],
        [82.2781982421875, 16.97125053405756],
        [82.2781982421875, 16.970970153808594],
        [82.27680206298822, 16.970970153808594],
        [82.27680206298822, 16.97125053405756],
        [82.27540588378935, 16.97125053405756],
        [82.27540588378935, 16.970689773559627],
        [82.27513885498047, 16.970693588256836],
        [82.27513885498047, 16.970418930053768],
        [82.2748565673831, 16.9704208374024],
        [82.2748565673831, 16.970140457153434],
        [82.27458190917974, 16.970140457153434],
        [82.27458190917974, 16.969579696655273],
        [82.27430725097668, 16.969583511352653],
        [82.27430725097668, 16.969306945800838],
        [82.27401733398443, 16.969310760498047],
        [82.27401733398443, 16.96903038024908],
        [82.27375030517572, 16.96903038024908],
        [82.27375030517572, 16.96846961975092],
        [82.27347564697288, 16.968471527099723],
        [82.27347564697288, 16.967916488647518],
        [82.27319335937517, 16.967920303344727],
        [82.27319335937517, 16.967359542846737],
        [82.27291870117193, 16.96736145019537],
        [82.27291870117193, 16.966806411743164],
        [82.27263641357439, 16.966810226440543],
        [82.27263641357439, 16.966249465942383],
        [82.27236175537138, 16.966249465942383],
        [82.27236175537138, 16.964580535888672],
        [82.27208709716797, 16.964582443237305],
        [82.27208709716797, 16.963752746582088],
        [82.27179718017607, 16.963752746582088],
        [82.27179718017607, 16.962921142578125],
        [82.27153015136724, 16.962921142578125],
        [82.27153015136724, 16.962360382080135],
        [82.27124786376964, 16.962360382080135],
        [82.27124786376964, 16.9620819091798],
        [82.27097320556646, 16.962083816528377],
        [82.27097320556646, 16.961805343627987],
        [82.2706909179687, 16.961811065673942],
        [82.2706909179687, 16.96125030517578],
        [82.27041625976585, 16.96125030517578],
        [82.27041625976585, 16.96013832092285],
        [82.27014160156267, 16.96013832092285],
        [82.27014160156267, 16.959028244018498],
        [82.2698593139649, 16.959028244018498],
        [82.2698593139649, 16.95652961730957],
        [82.26957702636736, 16.95652961730957],
        [82.26957702636736, 16.955970764160213],
        [82.26930236816435, 16.955972671508846],
        [82.26930236816435, 16.95430564880371],
        [82.26957702636736, 16.95430946350109],
        [82.26957702636736, 16.953189849853572],
        [82.26930236816435, 16.953195571899528],
        [82.26930236816435, 16.95180320739746],
        [82.26902770996094, 16.951810836791992],
        [82.26902770996094, 16.950969696044865],
        [82.26930236816435, 16.95097160339367],
        [82.26930236816435, 16.950693130493278],
        [82.26957702636736, 16.95070075988781],
        [82.26957702636736, 16.95014190673828],
        [82.2698593139649, 16.95014190673828],
        [82.2698593139649, 16.94958114624029],
        [82.27014160156267, 16.94958114624029],
        [82.27014160156267, 16.94930648803711],
        [82.27041625976585, 16.94928932189947],
        [82.27041625976585, 16.94902801513672],
        [82.2706909179687, 16.949031829834098],
        [82.2706909179687, 16.948749542236328],
        [82.27097320556646, 16.948749542236328],
        [82.27097320556646, 16.94846916198736],
        [82.27347564697288, 16.948471069335938],
        [82.27347564697288, 16.94791603088379],
        [82.27375030517572, 16.947921752929744],
        [82.27375030517572, 16.947639465331974],
        [82.27319335937517, 16.947639465331974],
        [82.27319335937517, 16.947359085083008],
        [82.27291870117193, 16.947359085083008],
        [82.27291870117193, 16.947080612182617],
        [82.27263641357439, 16.94708251953125],
        [82.27263641357439, 16.946805953979606],
        [82.27236175537138, 16.94681167602539],
        [82.27236175537138, 16.94568824768072],
        [82.27208709716797, 16.945693969726676],
        [82.27208709716797, 16.94486045837408],
        [82.27179718017607, 16.94486045837408],
        [82.27179718017607, 16.94403076171875],
        [82.27153015136724, 16.94403076171875],
        [82.27153015136724, 16.941530227661246],
        [82.27179718017607, 16.941530227661246],
        [82.27179718017607, 16.940969467163086],
        [82.27153015136724, 16.940971374511662],
        [82.27153015136724, 16.940416336059684],
        [82.2706909179687, 16.940416336059684],
        [82.2706909179687, 16.94069671630865],
        [82.26958465576189, 16.94069671630865],
        [82.26958465576189, 16.940416336059684],
        [82.26930236816435, 16.940416336059684],
        [82.26930236816435, 16.94013977050787],
        [82.26902770996094, 16.94013977050787],
        [82.26902770996094, 16.939859390258732],
        [82.2687530517581, 16.939861297607536],
        [82.2687530517581, 16.93958473205572],
        [82.26847076416016, 16.93958473205572],
        [82.26847076416016, 16.93930625915533],
        [82.26819610595714, 16.93931007385254],
        [82.26819610595714, 16.939029693603516],
        [82.26374816894554, 16.939029693603516],
        [82.26374816894554, 16.938751220703182],
        [82.26346588134783, 16.938751220703182],
        [82.26346588134783, 16.93847084045416],
        [82.26319122314459, 16.938474655151367],
        [82.26319122314459, 16.938192367553825],
        [82.26291656494158, 16.938192367553825],
        [82.26291656494158, 16.93791580200201],
        [82.26264190673857, 16.937919616699162],
        [82.26264190673857, 16.937810897827262],
        [82.26264190673857, 16.937639236450195],
        [82.26207733154325, 16.937641143798828],
        [82.26207733154325, 16.93734931945812],
        [82.2618026733399, 16.937360763549805],
        [82.2618026733399, 16.93708229064947],
        [82.26125335693365, 16.93708229064947],
        [82.26125335693365, 16.936805725097656],
        [82.25903320312528, 16.936809539795036],
        [82.25903320312528, 16.936531066894645],
        [82.2587509155274, 16.936531066894645],
        [82.2587509155274, 16.936809539795036],
        [82.25485992431635, 16.936809539795036],
        [82.25485992431635, 16.936531066894645],
        [82.25402832031256, 16.936531066894645],
        [82.25402832031256, 16.936252593994254],
        [82.253471374512, 16.936252593994254],
        [82.253471374512, 16.935970306396484],
        [82.25291442871122, 16.935970306396484],
        [82.25291442871122, 16.935693740844727],
        [82.25235748291044, 16.935693740844727],
        [82.25235748291044, 16.935417175293082],
        [82.25180816650385, 16.935422897338867],
        [82.25180816650385, 16.9351425170899],
        [82.2515258789063, 16.9351425170899],
        [82.2515258789063, 16.93486022949213],
        [82.2509689331057, 16.93486022949213],
        [82.2509689331057, 16.93458175659174],
        [82.25069427490251, 16.934583663940543],
        [82.25069427490251, 16.934028625488338],
        [82.2504196166995, 16.934028625488338],
        [82.2504196166995, 16.933200836181754],
        [82.25013732910173, 16.933200836181754],
        [82.25013732910173, 16.932081222534237],
        [82.24986267089872, 16.93208312988287],
        [82.24986267089872, 16.930973052978516],
        [82.24958038330078, 16.930973052978516],
        [82.24958038330078, 16.930416107177734],
        [82.24903106689459, 16.930419921875114],
        [82.24903106689459, 16.929580688476562],
        [82.24930572509794, 16.929582595825195],
        [82.24930572509794, 16.92902755737316],
        [82.24903106689459, 16.929029464721737],
        [82.24903106689459, 16.92847061157221],
        [82.24930572509794, 16.928472518921012],
        [82.24930572509794, 16.927917480468807],
        [82.24958038330078, 16.927919387817383],
        [82.24958038330078, 16.927360534668026],
        [82.24930572509794, 16.927360534668026],
        [82.24930572509794, 16.924303054809627],
        [82.24958038330078, 16.92431068420416],
        [82.24958038330078, 16.92346954345703],
        [82.24930572509794, 16.923471450805607],
        [82.24930572509794, 16.92319488525402],
        [82.24958038330078, 16.92319488525402],
        [82.24958038330078, 16.92263793945324],
        [82.24986267089872, 16.922641754150447],
        [82.24986267089872, 16.922269821166992],
        [82.24986267089872, 16.92208290100109],
        [82.25013732910173, 16.92208290100109],
        [82.25013732910173, 16.918750762939396],
        [82.24986267089872, 16.918750762939396],
        [82.24986267089872, 16.91764068603527],
        [82.24958038330078, 16.91764068603527],
        [82.24958038330078, 16.915420532226562],
        [82.24986267089872, 16.915420532226562],
        [82.24986267089872, 16.914859771728572],
        [82.25013732910173, 16.914861679077205],
        [82.25013732910173, 16.914306640625],
        [82.2504196166995, 16.914306640625],
        [82.2504196166995, 16.91374969482422],
        [82.25068664550798, 16.91374969482422],
        [82.25068664550798, 16.909309387206974],
        [82.2504196166995, 16.909309387206974],
        [82.2504196166995, 16.909032821655387],
        [82.25013732910173, 16.909032821655387],
        [82.25013732910173, 16.90875244140625],
        [82.24986267089872, 16.90875244140625],
        [82.24986267089872, 16.90819168090826],
        [82.24958038330078, 16.908193588256893],
        [82.24958038330078, 16.907638549804688],
        [82.2493133544923, 16.907642364502067],
        [82.2493133544923, 16.90736961364746],
        [82.25013732910173, 16.90736961364746],
        [82.25013732910173, 16.90707969665533],
        [82.2504196166995, 16.90708351135254],
        [82.2504196166995, 16.906528472900334],
        [82.25068664550798, 16.906528472900334],
        [82.25068664550798, 16.905969619750977],
        [82.2509689331057, 16.905971527099553],
        [82.2509689331057, 16.905694961547965],
        [82.2515258789063, 16.90570068359375],
        [82.2515258789063, 16.90541839599615],
        [82.25208282470709, 16.90541839599615],
        [82.25208282470709, 16.90513992309576],
        [82.25263977050781, 16.90513992309576],
        [82.25263977050781, 16.904859542846623],
        [82.2531967163086, 16.904861450195426],
        [82.2531967163086, 16.904582977295036],
        [82.253471374512, 16.904582977295036],
        [82.253471374512, 16.90430641174322],
        [82.25375366210955, 16.90430641174322],
        [82.25375366210955, 16.90402793884283],
        [82.25402832031256, 16.904029846191406],
        [82.25402832031256, 16.90374946594244],
        [82.2543029785158, 16.90374946594244],
        [82.2543029785158, 16.90347099304205],
        [82.25458526611351, 16.903472900390682],
        [82.25458526611351, 16.90319442749029],
        [82.25485992431635, 16.90319442749029],
        [82.25485992431635, 16.902360916137695],
        [82.25514221191412, 16.902360916137695],
        [82.25514221191412, 16.901531219482536],
        [82.2554168701173, 16.901531219482536],
        [82.2554168701173, 16.90069007873541],
        [82.25569152832031, 16.900695800781364],
        [82.25569152832031, 16.900138854980412],
        [82.25596618652372, 16.900140762329215],
        [82.25596618652372, 16.898750305175838],
        [82.2562484741211, 16.898750305175838],
        [82.2562484741211, 16.897920608520508],
        [82.25653076171903, 16.897920608520508],
        [82.25653076171903, 16.89707946777355],
        [82.25680541992205, 16.897081375122127],
        [82.25680541992205, 16.896249771118164],
        [82.25708007812506, 16.896249771118164],
        [82.2570724487307, 16.895141601562614],
        [82.25736236572283, 16.895141601562614],
        [82.25736236572283, 16.892639160156307],
        [82.25763702392601, 16.892639160156307],
        [82.25763702392601, 16.892360687255916],
        [82.25736236572283, 16.892360687255916],
        [82.25736236572283, 16.891807556152344],
        [82.2570724487307, 16.891807556152344],
        [82.2570724487307, 16.891250610351562],
        [82.25680541992205, 16.891250610351562],
        [82.25680541992205, 16.88902854919428],
        [82.2570724487307, 16.889030456543082],
        [82.2570724487307, 16.888200759887695],
        [82.25681304931658, 16.888200759887695],
        [82.25681304931658, 16.887639999389705],
        [82.25653076171903, 16.887639999389705],
        [82.25653076171903, 16.887359619140568],
        [82.2562484741211, 16.88736152648937],
        [82.2562484741211, 16.886528015136776],
        [82.25680541992205, 16.886528015136776],
        [82.25681304931658, 16.88680076599121],
        [82.25736236572283, 16.886806488037166],
        [82.25736236572283, 16.88708305358898],
        [82.25763702392601, 16.88708305358898],
        [82.25763702392601, 16.88736152648937],
        [82.25791931152338, 16.887359619140568],
        [82.25791931152338, 16.887639999389705],
        [82.25818634033209, 16.887639999389705],
        [82.25818634033209, 16.88792037963873],
        [82.2584686279298, 16.88792037963873],
        [82.2584686279298, 16.888200759887695],
        [82.25958251953153, 16.888196945190487],
        [82.25958251953153, 16.888471603393498],
        [82.26013946533209, 16.888471603393498],
        [82.26013946533209, 16.888196945190487],
        [82.2604064941408, 16.888200759887695],
        [82.2604064941408, 16.887639999389705],
        [82.26069641113304, 16.887639999389705],
        [82.26069641113304, 16.887359619140568],
        [82.26097106933588, 16.88736152648937],
        [82.26097106933588, 16.88708305358898],
        [82.26152801513683, 16.88708305358898],
        [82.26152801513683, 16.886806488037166],
        [82.26207733154325, 16.88680076599121],
        [82.26208496093778, 16.886528015136776],
        [82.26291656494158, 16.88652992248535],
        [82.26291656494158, 16.886260986328125],
        [82.26346588134783, 16.886260986328125],
        [82.26346588134783, 16.885971069335994],
        [82.26403045654314, 16.885974884033203],
        [82.26403045654314, 16.88569259643566],
        [82.26486206054693, 16.88569259643566],
        [82.26486206054693, 16.88541412353527],
        [82.26541900634766, 16.885419845580998],
        [82.26541900634766, 16.88513946533203],
        [82.26568603515653, 16.88513946533203],
        [82.26568603515653, 16.884862899780273],
        [82.26597595214844, 16.884862899780273],
        [82.26597595214844, 16.884580612182674],
        [82.26625061035185, 16.884584426879883],
        [82.26625061035185, 16.884304046630916],
        [82.26653289794939, 16.88430976867687],
        [82.26653289794939, 16.88375282287609],
        [82.2668075561524, 16.88375282287609],
        [82.2668075561524, 16.88347053527832],
        [82.26708221435564, 16.88347053527832],
        [82.26708221435564, 16.88319206237793],
        [82.26735687255865, 16.883199691772518],
        [82.26735687255865, 16.882642745971737],
        [82.2676391601562, 16.882642745971737],
        [82.2676391601562, 16.882360458373967],
        [82.2679061889649, 16.882360458373967],
        [82.2679061889649, 16.882080078125],
        [82.26819610595714, 16.88208389282238],
        [82.26819610595714, 16.881526947021598],
        [82.26847076416016, 16.881532669067383],
        [82.26847076416016, 16.88097190856945],
        [82.2687530517581, 16.88097190856945],
        [82.2687530517581, 16.880416870117244],
        [82.26902770996094, 16.880420684814453],
        [82.26902770996094, 16.879859924316463],
        [82.26930236816435, 16.879861831665096],
        [82.26930236816435, 16.8790283203125],
        [82.26957702636736, 16.8790283203125],
        [82.26957702636736, 16.877918243408146],
        [82.2698593139649, 16.877918243408146],
        [82.2698593139649, 16.875690460205078],
        [82.2706909179687, 16.875694274902457],
        [82.2706909179687, 16.875417709350643],
        [82.27236175537138, 16.875417709350643],
        [82.27236175537138, 16.875694274902457],
        [82.27263641357439, 16.875694274902457],
        [82.27263641357439, 16.875417709350643],
        [82.27375030517572, 16.875417709350643],
        [82.27375030517572, 16.875694274902457],
        [82.27430725097668, 16.875694274902457],
        [82.27430725097668, 16.875972747802848],
        [82.27513885498047, 16.875970840454045],
        [82.27513885498047, 16.87625122070324],
        [82.27569580078142, 16.87625122070324],
        [82.27569580078142, 16.876529693603572],
        [82.2762527465822, 16.876529693603572],
        [82.2762527465822, 16.876808166503963],
        [82.27680206298822, 16.876806259155387],
        [82.27680206298822, 16.87708282470703],
        [82.27764129638678, 16.87708282470703],
        [82.27764129638678, 16.877361297607365],
        [82.2781982421875, 16.87735939025879],
        [82.2781982421875, 16.877639770507756],
        [82.28180694580078, 16.877639770507756],
        [82.28180694580078, 16.87735939025879],
        [82.28208160400419, 16.877361297607365],
        [82.28208160400419, 16.876806259155387],
        [82.2823562622072, 16.876808166503963],
        [82.2823562622072, 16.876529693603572],
        [82.28263854980474, 16.876529693603572],
        [82.28263854980474, 16.87625122070324],
        [82.28291320800798, 16.87625122070324],
        [82.28291320800798, 16.875970840454045],
        [82.2831954956057, 16.875972747802848],
        [82.2831954956057, 16.875694274902457],
        [82.28347015380854, 16.875694274902457],
        [82.28347015380854, 16.875417709350643],
        [82.2837524414063, 16.87541961669922],
        [82.2837524414063, 16.875141143798885],
        [82.28402709960949, 16.875141143798885],
        [82.28402709960949, 16.87486076354986],
        [82.2843017578125, 16.87486076354986],
        [82.2843017578125, 16.874580383300724],
        [82.28485870361328, 16.874584197998104],
        [82.28485870361328, 16.874027252197322],
        [82.28514099121122, 16.87403106689453],
        [82.28514099121122, 16.87347030639654],
        [82.28541564941423, 16.873472213745174],
        [82.28541564941423, 16.872085571289176],
        [82.28514099121122, 16.872085571289176],
        [82.28514099121122, 16.871528625488224],
        [82.28485870361328, 16.871530532837028],
        [82.28485870361328, 16.86903190612793],
        [82.28457641601591, 16.86903190612793],
        [82.28457641601591, 16.868469238281364],
        [82.2843017578125, 16.86847114562994],
        [82.2843017578125, 16.86791610717779],
        [82.28402709960949, 16.867921829223746],
        [82.28402709960949, 16.86735916137701],
        [82.2837524414063, 16.86735916137701],
        [82.2837524414063, 16.86708068847662],
        [82.28347015380854, 16.867082595825252],
        [82.28347015380854, 16.866806030273438],
        [82.28320312500023, 16.866811752319393],
        [82.28320312500023, 16.866249084472656],
        [82.28291320800798, 16.866249084472656],
        [82.28291320800798, 16.865970611572266],
        [82.28263854980474, 16.8659725189209],
        [82.28263854980474, 16.865417480468864],
        [82.2823562622072, 16.865417480468864],
        [82.2823562622072, 16.865129470825195],
        [82.28320312500023, 16.865129470825195],
        [82.28320312500023, 16.865417480468864],
        [82.28347015380854, 16.865417480468864],
        [82.28347015380854, 16.865694046020508],
        [82.2837524414063, 16.8656902313233],
        [82.2837524414063, 16.866249084472656],
        [82.28402709960949, 16.866249084472656],
        [82.28402709960949, 16.866531372070426],
        [82.2843017578125, 16.866531372070426],
        [82.2843017578125, 16.866811752319393],
        [82.28457641601591, 16.866811752319393],
        [82.28457641601591, 16.86735916137701],
        [82.28485870361328, 16.86735916137701],
        [82.28485870361328, 16.867921829223746],
        [82.28514099121122, 16.86791610717779],
        [82.28514099121122, 16.86847114562994],
        [82.28541564941423, 16.86847114562994],
        [82.28541564941423, 16.869304656982536],
        [82.28569793701178, 16.869304656982536],
        [82.28569793701178, 16.869863510131893],
        [82.28597259521501, 16.869859695434513],
        [82.28597259521501, 16.870420455932674],
        [82.2862472534182, 16.870416641235465],
        [82.2862472534182, 16.870973587036246],
        [82.28652954101557, 16.870969772338867],
        [82.28652954101557, 16.871530532837028],
        [82.28679656982428, 16.871530532837028],
        [82.28679656982428, 16.872640609741154],
        [82.28652954101557, 16.872640609741154],
        [82.28652954101557, 16.87403106689453],
        [82.2862472534182, 16.87403106689453],
        [82.2862472534182, 16.87486076354986],
        [82.28597259521501, 16.87486076354986],
        [82.28597259521501, 16.875141143798885],
        [82.28569793701178, 16.875141143798885],
        [82.28569793701178, 16.876808166503963],
        [82.2862472534182, 16.876806259155387],
        [82.2862472534182, 16.87708282470703],
        [82.28652954101557, 16.87708282470703],
        [82.28652954101557, 16.876806259155387],
        [82.28679656982428, 16.876808166503963],
        [82.28679656982428, 16.875970840454045],
        [82.28708648681652, 16.875972747802848],
        [82.28708648681652, 16.875694274902457],
        [82.28763580322294, 16.875694274902457],
        [82.28763580322294, 16.875417709350643],
        [82.28848266601562, 16.87541961669922],
        [82.28848266601562, 16.873750686645508],
        [82.28874969482428, 16.873750686645508],
        [82.28874969482428, 16.872640609741154],
        [82.28901672363298, 16.872640609741154],
        [82.28901672363298, 16.872360229492188],
        [82.28958129882807, 16.872360229492188],
        [82.28958129882807, 16.87207984924322],
        [82.28986358642584, 16.872085571289176],
        [82.28986358642584, 16.871803283691463],
        [82.29041290283209, 16.871810913085994],
        [82.29041290283209, 16.871530532837028],
        [82.29152679443376, 16.871530532837028],
        [82.29152679443376, 16.87125968933117],
        [82.29180908203153, 16.87125968933117],
        [82.29180908203153, 16.870969772338867],
        [82.29208374023455, 16.870973587036246],
        [82.29208374023455, 16.87069320678711],
        [82.29235839843773, 16.87070083618164],
        [82.29235839843773, 16.870420455932674],
        [82.29264068603533, 16.870420455932674],
        [82.29264068603533, 16.87014007568365],
        [82.29292297363304, 16.87014007568365],
        [82.29292297363304, 16.869859695434513],
        [82.29319763183588, 16.869863510131893],
        [82.29319763183588, 16.869581222534123],
        [82.29374694824247, 16.869581222534123],
        [82.29374694824247, 16.869304656982536],
        [82.29402923583984, 16.869304656982536],
        [82.29402923583984, 16.86902809143072],
        [82.29569244384783, 16.86903190612793],
        [82.29569244384783, 16.86874961853033],
        [82.29596710205084, 16.86874961853033],
        [82.29596710205084, 16.868469238281364],
        [82.29624938964838, 16.86847114562994],
        [82.29624938964838, 16.868194580078182],
        [82.29680633544933, 16.868200302124137],
        [82.29680633544933, 16.867921829223746],
        [82.29708099365234, 16.867921829223746],
        [82.29708099365234, 16.867639541625977],
        [82.29736328125028, 16.867639541625977],
        [82.29736328125028, 16.86735916137701],
        [82.29792022705107, 16.86735916137701],
        [82.29792022705107, 16.86708068847662],
        [82.29819488525408, 16.867082595825252],
        [82.29819488525408, 16.866806030273438],
        [82.29875183105486, 16.866811752319393],
        [82.29875183105486, 16.866531372070426],
        [82.29957580566412, 16.866531372070426],
        [82.29957580566412, 16.866249084472656],
        [82.29985809326183, 16.866249084472656],
        [82.29985809326183, 16.8656902313233],
        [82.30014038085943, 16.865694046020508],
        [82.30014038085943, 16.865417480468864],
        [82.30097198486357, 16.865417480468864],
        [82.30097198486357, 16.865129470825195],
        [82.30124664306658, 16.865137100219727],
        [82.30124664306658, 16.864860534667912],
        [82.30152893066412, 16.864860534667912],
        [82.30152893066412, 16.864580154418945],
        [82.30180358886736, 16.864583969116325],
        [82.30180358886736, 16.864305496215934],
        [82.30264282226568, 16.86431121826172],
        [82.30264282226568, 16.86375045776373],
        [82.30291748046886, 16.86375045776373],
        [82.30291748046886, 16.86347007751459],
        [82.30319213867193, 16.863471984863395],
        [82.30319213867193, 16.863193511963004],
        [82.30374908447266, 16.863193511963004],
        [82.30374908447266, 16.862918853759766],
        [82.3040313720706, 16.8629207611084],
        [82.3040313720706, 16.862640380859375],
        [82.30430603027361, 16.862640380859375],
        [82.30430603027361, 16.86236000061041],
        [82.30458068847662, 16.86236190795904],
        [82.30458068847662, 16.861806869506836],
        [82.30486297607439, 16.861810684204215],
        [82.30486297607439, 16.86153030395502],
        [82.30513763427757, 16.86153030395502],
        [82.30513763427757, 16.860969543457088],
        [82.30541992187517, 16.860971450805664],
        [82.30541992187517, 16.860696792602653],
        [82.30596923828136, 16.860696792602653],
        [82.30596923828136, 16.860416412353516],
        [82.30652618408232, 16.860420227050895],
        [82.30652618408232, 16.8601398468017],
        [82.30680847167969, 16.8601398468017],
        [82.30680847167969, 16.859580993652344],
        [82.3070831298831, 16.859584808349723],
        [82.3070831298831, 16.859306335449332],
        [82.30735778808611, 16.85931968688965],
        [82.30735778808611, 16.859029769897518],
        [82.30764007568388, 16.859029769897518],
        [82.30764007568388, 16.85847091674799],
        [82.30791473388689, 16.85847473144537],
        [82.30791473388689, 16.8581924438476],
        [82.30819702148443, 16.8581924438476],
        [82.30819702148443, 16.857915878296012],
        [82.30847167968767, 16.857919692993164],
        [82.30847167968767, 16.85764122009283],
        [82.30902862548822, 16.85764122009283],
        [82.30902862548822, 16.85736274719244],
        [82.30930328369146, 16.85736274719244],
        [82.30930328369146, 16.85708045959467],
        [82.30958557128918, 16.857082366943473],
        [82.30958557128918, 16.85680580139166],
        [82.30986022949219, 16.85680961608881],
        [82.30986022949219, 16.856531143188477],
        [82.31014251709013, 16.856531143188477],
        [82.31014251709013, 16.855970382690487],
        [82.31041717529297, 16.855970382690487],
        [82.31041717529297, 16.85569381713873],
        [82.31069183349638, 16.85569381713873],
        [82.31069183349638, 16.855417251586914],
        [82.31096649169939, 16.855421066284293],
        [82.31096649169939, 16.855142593383903],
        [82.31124877929693, 16.855142593383903],
        [82.31124877929693, 16.854860305786133],
        [82.31153869628923, 16.854860305786133],
        [82.3115310668947, 16.854305267333984],
        [82.31181335449241, 16.854312896728516],
        [82.31181335449241, 16.85403251647955],
        [82.31208038330072, 16.85403251647955],
        [82.31208038330072, 16.853469848632812],
        [82.3123626708985, 16.85347175598139],
        [82.3123626708985, 16.85291671752941],
        [82.31263732910168, 16.85292053222662],
        [82.31263732910168, 16.85235977172846],
        [82.31291961669928, 16.852361679077262],
        [82.31291961669928, 16.85180473327648],
        [82.31319427490263, 16.85180473327648],
        [82.31319427490263, 16.850973129272518],
        [82.31346893310547, 16.850973129272518],
        [82.31346893310547, 16.84986114501953],
        [82.31375122070341, 16.84986114501953],
        [82.31375122070341, 16.849029541015568],
        [82.31402587890642, 16.849029541015568],
        [82.31402587890642, 16.847919464111385],
        [82.31430816650396, 16.847919464111385],
        [82.31430816650396, 16.84680938720703],
        [82.3145828247072, 16.84680938720703],
        [82.3145828247072, 16.844579696655387],
        [82.31430816650396, 16.844581604003963],
        [82.31430816650396, 16.84347152709961],
        [82.31402587890642, 16.84347152709961],
        [82.31402587890642, 16.84291648864746],
        [82.31375122070341, 16.84292030334484],
        [82.31375122070341, 16.84264183044445],
        [82.31346893310547, 16.84264183044445],
        [82.31346893310547, 16.84235954284668],
        [82.31319427490263, 16.84236335754406],
        [82.31319427490263, 16.841806411743278],
        [82.31291961669928, 16.841810226440487],
        [82.31291961669928, 16.841531753540096],
        [82.31263732910168, 16.841531753540096],
        [82.31263732910168, 16.841249465942326],
        [82.3123626708985, 16.841249465942326],
        [82.3123626708985, 16.840959548950252],
        [82.31208038330072, 16.84097290039074],
        [82.31208038330072, 16.840694427490348],
        [82.31180572509788, 16.840694427490348],
        [82.31180572509788, 16.840139389038143],
        [82.31263732910168, 16.840139389038143],
        [82.31263732910168, 16.840421676635742],
        [82.31291961669928, 16.840415954589957],
        [82.31291961669928, 16.840694427490348],
        [82.31346893310547, 16.840694427490348],
        [82.31346893310547, 16.84097290039074],
        [82.31375122070341, 16.840959548950252],
        [82.31375122070341, 16.841249465942326],
        [82.31402587890642, 16.841249465942326],
        [82.31402587890642, 16.841810226440487],
        [82.31430816650396, 16.841806411743278],
        [82.31430816650396, 16.84208106994629],
        [82.3145828247072, 16.84208106994629],
        [82.3145828247072, 16.84236335754406],
        [82.31485748291038, 16.84235954284668],
        [82.31485748291038, 16.84264183044445],
        [82.31513977050776, 16.84264183044445],
        [82.31513977050776, 16.84292030334484],
        [82.315414428711, 16.84291648864746],
        [82.315414428711, 16.84347152709961],
        [82.31569671630871, 16.843469619751033],
        [82.31569671630871, 16.843751907348633],
        [82.31597137451178, 16.843751907348633],
        [82.31597137451178, 16.844030380248967],
        [82.31624603271513, 16.844030380248967],
        [82.31624603271513, 16.84431076049816],
        [82.31680297851591, 16.84430313110363],
        [82.31680297851591, 16.845695495605526],
        [82.31708526611345, 16.845695495605526],
        [82.31708526611345, 16.846250534057674],
        [82.31735229492193, 16.846250534057674],
        [82.31735229492193, 16.847089767456055],
        [82.31764221191423, 16.847085952758846],
        [82.31764221191423, 16.848194122314453],
        [82.31791687011741, 16.848194122314453],
        [82.31791687011741, 16.849582672119197],
        [82.31819152832026, 16.849582672119197],
        [82.31819152832026, 16.850139617919922],
        [82.3184661865235, 16.850130081176815],
        [82.3184661865235, 16.850418090820312],
        [82.31874847412121, 16.850416183471737],
        [82.31874847412121, 16.850694656372127],
        [82.3190307617188, 16.850690841674748],
        [82.3190307617188, 16.851249694824276],
        [82.31931304931652, 16.851249694824276],
        [82.31931304931652, 16.851528167724666],
        [82.319580078125, 16.851528167724666],
        [82.319580078125, 16.85180473327648],
        [82.31986236572294, 16.85180473327648],
        [82.31986236572294, 16.85208320617687],
        [82.32013702392595, 16.85208320617687],
        [82.32013702392595, 16.852361679077262],
        [82.32041168212896, 16.85235977172846],
        [82.32041168212896, 16.85263824462902],
        [82.32096862792991, 16.85263824462902],
        [82.32096862792991, 16.85292053222662],
        [82.32125091552751, 16.85291671752941],
        [82.32125091552751, 16.8531951904298],
        [82.32152557373053, 16.8531951904298],
        [82.32152557373053, 16.85347175598139],
        [82.32180786132807, 16.853469848632812],
        [82.32180786132807, 16.853748321533203],
        [82.3220825195313, 16.853748321533203],
        [82.3220825195313, 16.85403251647955],
        [82.32319641113281, 16.85403251647955],
        [82.32319641113281, 16.854312896728516],
        [82.32402801513678, 16.854305267333984],
        [82.32402801513678, 16.854583740234375],
        [82.32514190673834, 16.854581832885742],
        [82.32514190673834, 16.854860305786133],
        [82.32541656494152, 16.854860305786133],
        [82.32541656494152, 16.855142593383903],
        [82.32569122314453, 16.855142593383903],
        [82.32569122314453, 16.855421066284293],
        [82.33152770996105, 16.855417251586914],
        [82.33152770996105, 16.85569381713873],
        [82.332084655762, 16.85569381713873],
        [82.332084655762, 16.855970382690487],
        [82.33235168457031, 16.855970382690487],
        [82.33235168457031, 16.856260299682617],
        [82.33403015136736, 16.856260299682617],
        [82.33403015136736, 16.856531143188477],
        [82.33458709716791, 16.856531143188477],
        [82.33458709716791, 16.85680961608881],
        [82.33598327636719, 16.85680961608881],
        [82.33598327636719, 16.856531143188477],
        [82.33763885498041, 16.856531143188477],
        [82.33763885498041, 16.856260299682617],
        [82.33819580078136, 16.856260299682617],
        [82.33819580078136, 16.855970382690487],
        [82.33875274658232, 16.855970382690487],
        [82.33875274658232, 16.85569381713873],
        [82.33930206298857, 16.85569381713873],
        [82.33930206298857, 16.855417251586914],
        [82.33957672119158, 16.855421066284293],
        [82.33957672119158, 16.855142593383903],
        [82.33985900878912, 16.855142593383903],
        [82.33985900878912, 16.854860305786133],
        [82.34014129638689, 16.854860305786133],
        [82.34014129638689, 16.854581832885742],
        [82.34097290039068, 16.854583740234375],
        [82.34097290039068, 16.854305267333984],
        [82.34179687500028, 16.854312896728516],
        [82.34179687500028, 16.85403251647955],
        [82.3423614501956, 16.85403251647955],
        [82.3423614501956, 16.853748321533203],
        [82.34291839599615, 16.853748321533203],
        [82.34291839599615, 16.853469848632812],
        [82.34375, 16.85347175598139],
        [82.34375, 16.8531951904298],
        [82.34513854980469, 16.8531951904298],
        [82.34513854980469, 16.85291671752941],
        [82.345703125, 16.85292053222662],
        [82.345703125, 16.85263824462902],
        [82.34625244140642, 16.85263824462902],
        [82.34625244140642, 16.85235977172846],
        [82.34680175781244, 16.852361679077262],
        [82.34680175781244, 16.85208320617687],
        [82.3473587036134, 16.85208320617687],
        [82.3473587036134, 16.85180473327648],
        [82.34791564941435, 16.85180473327648],
        [82.34791564941435, 16.851528167724666],
        [82.34847259521513, 16.851528167724666],
        [82.34847259521513, 16.851249694824276],
        [82.34874725341814, 16.851249694824276],
        [82.34874725341814, 16.850971221923885],
        [82.34958648681646, 16.850971221923885],
        [82.34958648681646, 16.851249694824276],
        [82.35013580322271, 16.851249694824276],
        [82.35013580322271, 16.851528167724666],
        [82.3506927490235, 16.851528167724666],
        [82.3506927490235, 16.85180473327648],
        [82.35236358642595, 16.85180473327648],
        [82.35236358642595, 16.851528167724666],
        [82.3543090820313, 16.851528167724666],
        [82.3543090820313, 16.85180473327648],
        [82.3548583984375, 16.85180473327648],
        [82.3548583984375, 16.85208320617687],
        [82.35514068603544, 16.852079391479492],
        [82.35514068603544, 16.85263824462902],
        [82.35541534423845, 16.85263824462902],
        [82.35541534423845, 16.8531951904298],
        [82.355697631836, 16.85318756103527],
        [82.355697631836, 16.853748321533203],
        [82.35597229003923, 16.853748321533203],
        [82.35597229003923, 16.854583740234375],
        [82.355697631836, 16.854581832885742],
        [82.355697631836, 16.854860305786133],
        [82.35542297363298, 16.854860305786133],
        [82.35542297363298, 16.855421066284293],
        [82.35514068603544, 16.855417251586914],
        [82.35514068603544, 16.85569381713873],
        [82.3548583984375, 16.855691909790096],
        [82.3548583984375, 16.856260299682617],
        [82.35514068603544, 16.856260299682617],
        [82.35514068603544, 16.85736274719244],
        [82.35541534423845, 16.85736274719244],
        [82.35541534423845, 16.85847473144537],
        [82.355697631836, 16.85847091674799],
        [82.355697631836, 16.859029769897518],
        [82.35597229003923, 16.859027862548942],
        [82.35597229003923, 16.85986137390131],
        [82.35624694824241, 16.859859466552734],
        [82.35624694824241, 16.860420227050895],
        [82.35597229003923, 16.860420227050895],
        [82.35597229003923, 16.861249923706055],
        [82.355697631836, 16.861249923706055],
        [82.355697631836, 16.86375045776373],
        [82.35541534423845, 16.86375045776373],
        [82.35541534423845, 16.864583969116325],
        [82.35514068603544, 16.864583969116325],
        [82.35514068603544, 16.865694046020508],
        [82.35542297363298, 16.8656902313233],
        [82.35542297363298, 16.866531372070426],
        [82.35597229003923, 16.866531372070426],
        [82.35597229003923, 16.86874961853033],
        [82.35624694824241, 16.86874961853033],
        [82.35624694824241, 16.86903190612793],
        [82.35652923583979, 16.86902809143072],
        [82.35652923583979, 16.869581222534123],
        [82.3567962646485, 16.869581222534123],
        [82.3567962646485, 16.871530532837028],
        [82.35708618164074, 16.871528625488224],
        [82.35708618164074, 16.872085571289176],
        [82.3573608398438, 16.87207984924322],
        [82.3573608398438, 16.872360229492188],
        [82.35753631591797, 16.872360229492188],
        [82.35764312744169, 16.872360229492188],
        [82.35764312744169, 16.872640609741154],
        [82.35791778564453, 16.872640609741154],
        [82.35791778564453, 16.872920989990348],
        [82.35819244384794, 16.87291717529297],
        [82.35819244384794, 16.87319564819336],
        [82.35847473144548, 16.87319564819336],
        [82.35847473144548, 16.873472213745174],
        [82.3587493896485, 16.87347030639654],
        [82.3587493896485, 16.87403106689453],
        [82.3590164184572, 16.87403106689453],
        [82.3590164184572, 16.87486076354986],
        [82.3587493896485, 16.87486076354986],
        [82.3587493896485, 16.875141143798885],
        [82.35847473144548, 16.875139236450252],
        [82.35847473144548, 16.875694274902457],
        [82.35819244384794, 16.875690460205078],
        [82.35819244384794, 16.876529693603572],
        [82.35847473144548, 16.876527786254996],
        [82.35847473144548, 16.877361297607365],
        [82.3587493896485, 16.87735939025879],
        [82.3587493896485, 16.877918243408146],
        [82.35902404785173, 16.87791633605957],
        [82.35902404785173, 16.87847137451172],
        [82.35930633544945, 16.878469467163143],
        [82.35930633544945, 16.87874984741211],
        [82.35986328125006, 16.87874984741211],
        [82.35986328125006, 16.8790283203125],
        [82.36013793945324, 16.8790283203125],
        [82.36013793945324, 16.879583358764705],
        [82.36042022705084, 16.879583358764705],
        [82.36042022705084, 16.879861831665096],
        [82.36068725585966, 16.879859924316463],
        [82.36068725585966, 16.88070106506359],
        [82.36096954345703, 16.88070106506359],
        [82.36096954345703, 16.881811141967773],
        [82.36125183105497, 16.88180541992199],
        [82.36125183105497, 16.88347053527832],
        [82.36152648925798, 16.88347053527832],
        [82.36152648925798, 16.885419845580998],
        [82.36125183105497, 16.885419845580998],
        [82.36125183105497, 16.88652992248535],
        [82.36152648925798, 16.886528015136776],
        [82.36152648925798, 16.88736152648937],
        [82.36180877685575, 16.887359619140568],
        [82.36180877685575, 16.88792037963873],
        [82.36208343505876, 16.88791656494152],
        [82.36208343505876, 16.893194198608512],
        [82.36180877685575, 16.893190383911133],
        [82.36180877685575, 16.89374923706049],
        [82.36152648925798, 16.89374923706049],
        [82.36152648925798, 16.897363662719727],
        [82.36180877685575, 16.897363662719727],
        [82.36180877685575, 16.900972366333008],
        [82.36208343505876, 16.900972366333008],
        [82.36208343505876, 16.90208244323736],
        [82.36235809326195, 16.90208053588873],
        [82.36235809326195, 16.902639389038086],
        [82.36208343505876, 16.902639389038086],
        [82.36208343505876, 16.90319442749029],
        [82.36235809326195, 16.90319442749029],
        [82.36235809326195, 16.904861450195426],
        [82.36264038085955, 16.904861450195426],
        [82.36264038085955, 16.905971527099553],
        [82.36292266845726, 16.905969619750977],
        [82.36295318603544, 16.906810760498104],
        [82.3631973266601, 16.90680503845215],
        [82.3631973266601, 16.90708351135254],
        [82.36293792724615, 16.90707969665533],
        [82.36292266845726, 16.907642364502067],
        [82.3631973266601, 16.907642364502067],
        [82.3631973266601, 16.909032821655387],
        [82.36347198486334, 16.909032821655387],
        [82.36347198486334, 16.91014289855957],
        [82.3631973266601, 16.91013908386236],
        [82.3631973266601, 16.910974502563533],
        [82.36291503906256, 16.910974502563533],
        [82.36291503906256, 16.912361145019474],
        [82.36264801025408, 16.912361145019474],
        [82.36264801025408, 16.914030075073185],
        [82.36235809326195, 16.914030075073185],
        [82.36235809326195, 16.917360305786076],
        [82.36207580566423, 16.917360305786076],
        [82.36207580566423, 16.922920227050838],
        [82.36180877685575, 16.922920227050838],
        [82.36180877685575, 16.925140380859318],
        [82.36152648925798, 16.925140380859318],
        [82.36152648925798, 16.927360534668026],
        [82.36125183105497, 16.927360534668026],
        [82.36125183105497, 16.92930984497076],
        [82.36096954345703, 16.92930984497076],
        [82.36096954345703, 16.931249618530273],
        [82.36069488525419, 16.931249618530273],
        [82.36069488525419, 16.933471679687614],
        [82.36042022705084, 16.933471679687614],
        [82.36042022705084, 16.935970306396484],
        [82.36013793945324, 16.935970306396484],
        [82.36013793945324, 16.936809539795036],
        [82.35986328125006, 16.936805725097656],
        [82.35986328125006, 16.938474655151367],
        [82.35958099365229, 16.938474655151367],
        [82.35958099365229, 16.940416336059684],
        [82.35930633544945, 16.94041061401373],
        [82.35930633544945, 16.941249847412053],
        [82.3590164184572, 16.941249847412053],
        [82.3590164184572, 16.94403076171875],
        [82.35930633544945, 16.94403076171875],
        [82.35930633544945, 16.944311141967887],
        [82.3587493896485, 16.944305419921932],
        [82.3587493896485, 16.944583892822322],
        [82.35846710205095, 16.944580078124943],
        [82.35846710205095, 16.94681167602539],
        [82.35819244384794, 16.946805953979606],
        [82.35819244384794, 16.94708251953125],
        [82.35791778564453, 16.947080612182617],
        [82.35791778564453, 16.94931030273449],
        [82.35763549804716, 16.94930648803711],
        [82.35763549804716, 16.95097160339367],
        [82.3573608398438, 16.950969696044865],
        [82.3573608398438, 16.951530456543026],
        [82.35708618164074, 16.951528549194393],
        [82.35708618164074, 16.952085494995174],
        [82.35680389404303, 16.952085494995174],
        [82.35680389404303, 16.953195571899528],
        [82.35652160644526, 16.953189849853572],
        [82.35652160644526, 16.953750610351506],
        [82.35624694824241, 16.953750610351506],
        [82.35624694824241, 16.95430946350109],
        [82.35597229003923, 16.95430946350109],
        [82.35597229003923, 16.95486068725586],
        [82.355697631836, 16.95486068725586],
        [82.355697631836, 16.955141067504883],
        [82.35541534423845, 16.95513916015625],
        [82.35541534423845, 16.955694198608455],
        [82.35514068603544, 16.955690383911076],
        [82.35514068603544, 16.956251144409237],
        [82.3548583984375, 16.956251144409237],
        [82.3548583984375, 16.95652961730957],
        [82.35402679443371, 16.95652961730957],
        [82.35402679443371, 16.95680809020996],
        [82.35375213623053, 16.956806182861385],
        [82.35375213623053, 16.9570827484132],
        [82.3534622192384, 16.957080841064396],
        [82.35346984863276, 16.95736122131359],
        [82.35320281982445, 16.95736122131359],
        [82.35320281982445, 16.957918167114315],
        [82.3529129028322, 16.95791625976574],
        [82.3529129028322, 16.95819473266613],
        [82.35263824462896, 16.95819091796875],
        [82.35263824462896, 16.95848083496105],
        [82.35235595703142, 16.95848083496105],
        [82.35235595703142, 16.958749771118107],
        [82.351806640625, 16.958749771118107],
        [82.351806640625, 16.959028244018498],
        [82.35152435302763, 16.959028244018498],
        [82.35152435302763, 16.959304809570312],
        [82.35097503662121, 16.959304809570312],
        [82.35097503662121, 16.959583282470703],
        [82.3506927490235, 16.959583282470703],
        [82.3506927490235, 16.959861755371094],
        [82.35013580322271, 16.95985984802246],
        [82.35013580322271, 16.96013832092285],
        [82.3498611450197, 16.96013832092285],
        [82.3498611450197, 16.96042060852062],
        [82.34958648681646, 16.960416793823242],
        [82.34958648681646, 16.960971832275447],
        [82.34929656982439, 16.960971832275447],
        [82.34929656982439, 16.96125030517578],
        [82.34902954101591, 16.96125030517578],
        [82.34902954101591, 16.961526870727596],
        [82.34847259521513, 16.961519241333065],
        [82.34847259521513, 16.961811065673942],
        [82.3465270996096, 16.961805343627987],
        [82.3465270996096, 16.962083816528377],
        [82.34625244140642, 16.9620819091798],
        [82.34625244140642, 16.962360382080135],
        [82.34597015380865, 16.962360382080135],
        [82.34597015380865, 16.962642669677734],
        [82.3454132080081, 16.962642669677734],
        [82.3454132080081, 16.962921142578125],
        [82.34513854980469, 16.962917327880916],
        [82.34513854980469, 16.96319389343273],
        [82.34485626220732, 16.96319007873535],
        [82.34485626220732, 16.963752746582088],
        [82.34458160400396, 16.963752746582088],
        [82.34458160400396, 16.96403121948248],
        [82.34401702880865, 16.96403121948248],
        [82.34401702880865, 16.96430969238287],
        [82.3434753417971, 16.96430587768566],
        [82.3434753417971, 16.964582443237305],
        [82.34319305419939, 16.964580535888672],
        [82.34319305419939, 16.964862823486442],
        [82.34263610839861, 16.964862823486442],
        [82.34263610839861, 16.96513938903803],
        [82.34208679199219, 16.96513938903803],
        [82.34208679199219, 16.965419769287223],
        [82.34097290039068, 16.965414047241268],
        [82.34097290039068, 16.96569252014166],
        [82.34041595459007, 16.965690612793026],
        [82.34041595459007, 16.966249465942383],
        [82.34014129638689, 16.966249465942383],
        [82.34014129638689, 16.967084884643555],
        [82.33930206298857, 16.967081069946346],
        [82.33930206298857, 16.967639923095703],
        [82.33902740478516, 16.967639923095703],
        [82.33902740478516, 16.96903038024908],
        [82.33875274658232, 16.969028472900447],
        [82.33875274658232, 16.969583511352653],
        [82.33847808837896, 16.969579696655273],
        [82.33847808837896, 16.9704208374024],
        [82.33819580078136, 16.970418930053768],
        [82.33819580078136, 16.970972061157227],
        [82.33790588378912, 16.970970153808594],
        [82.33790588378912, 16.97125053405756],
        [82.33763885498041, 16.97125053405756],
        [82.33763885498041, 16.97180747985834],
        [82.33736419677757, 16.971805572509766],
        [82.33736419677757, 16.972084045410156],
        [82.33708190917986, 16.972080230712947],
        [82.33708190917986, 16.972360610961914],
        [82.33680725097662, 16.972360610961914],
        [82.33680725097662, 16.972639083862305],
        [82.33652496337908, 16.97263717651373],
        [82.33652496337908, 16.97319412231451],
        [82.33625030517607, 16.97319412231451],
        [82.33625030517607, 16.9734725952149],
        [82.33597564697266, 16.9734725952149],
        [82.33598327636719, 16.97485923767084],
        [82.33569335937528, 16.97485923767084],
        [82.33569335937528, 16.975694656372184],
        [82.33541870117188, 16.975694656372184],
        [82.33541870117188, 16.976528167724553],
        [82.3351364135745, 16.976528167724553],
        [82.3351364135745, 16.976804733276367],
        [82.33486175537115, 16.976804733276367],
        [82.33486175537115, 16.977081298828125],
        [82.33458709716791, 16.977081298828125],
        [82.33458709716791, 16.977363586425895],
        [82.33429718017584, 16.977359771728516],
        [82.33429718017584, 16.977640151977482],
        [82.33403015136736, 16.977640151977482],
        [82.33403015136736, 16.977920532226676],
        [82.33374786376976, 16.977916717529297],
        [82.33374786376976, 16.978473663330078],
        [82.33347320556658, 16.97846984863287],
        [82.33347320556658, 16.97903060913086],
        [82.3331909179688, 16.97903060913086],
        [82.3331909179688, 16.97986030578619],
        [82.3329162597658, 16.97986030578619],
        [82.3329162597658, 16.980140686035213],
        [82.33264160156278, 16.980140686035213],
        [82.33264160156278, 16.98042106628418],
        [82.33235931396484, 16.98041725158697],
        [82.33235931396484, 16.98069572448736],
        [82.33207702636747, 16.980690002441406],
        [82.33207702636747, 16.981250762939567],
        [82.33180236816412, 16.981250762939567],
        [82.33180236816412, 16.981531143188533],
        [82.32958221435575, 16.981531143188533],
        [82.32958221435575, 16.981809616088924],
        [82.32902526855497, 16.98180770874029],
        [82.32902526855497, 16.982084274292106],
        [82.32875061035162, 16.982080459594727],
        [82.32875061035162, 16.982360839843693],
        [82.32847595214855, 16.982360839843693],
        [82.32847595214855, 16.982650756835994],
        [82.3279190063476, 16.982650756835994],
        [82.3279190063476, 16.982919692993278],
        [82.32763671875023, 16.982917785644474],
        [82.32763671875023, 16.98319435119629],
        [82.32736206054705, 16.98319435119629],
        [82.32736206054705, 16.98347282409668],
        [82.32707977294928, 16.983470916748047],
        [82.32707977294928, 16.984308242797795],
        [82.32680511474626, 16.98430633544922],
        [82.32680511474626, 16.984861373901424],
        [82.32653808593778, 16.98485946655279],
        [82.32653808593778, 16.985139846801758],
        [82.32624816894531, 16.985139846801758],
        [82.32624816894531, 16.985694885253963],
        [82.32597351074247, 16.985694885253963],
        [82.32597351074247, 16.985971450805778],
        [82.32569122314453, 16.985969543456974],
        [82.32569122314453, 16.98624992370611],
        [82.32541656494152, 16.98624992370611],
        [82.32541656494152, 16.9868106842041],
        [82.32514190673834, 16.986804962158317],
        [82.32514190673834, 16.987083435058707],
        [82.32485961914057, 16.987079620361328],
        [82.32485961914057, 16.987642288208065],
        [82.32458496093773, 16.987638473510685],
        [82.32457733154303, 16.988191604614258],
        [82.32430267334001, 16.988201141357422],
        [82.32430267334001, 16.988750457763615],
        [82.32457733154303, 16.988750457763615],
        [82.32457733154303, 16.989032745361385],
        [82.32485961914057, 16.989032745361385],
        [82.32485961914057, 16.989311218261776],
        [82.32514190673834, 16.98930549621582],
        [82.32514190673834, 16.989582061767635],
        [82.32541656494152, 16.989580154419002],
        [82.32541656494152, 16.99014282226574],
        [82.32569122314453, 16.99014282226574],
        [82.32569122314453, 16.990419387817326],
        [82.32597351074247, 16.99041748046875],
        [82.32597351074247, 16.99069213867199],
        [82.32624816894531, 16.99069213867199],
        [82.32624816894531, 16.99097442626953],
        [82.3279190063476, 16.99097442626953],
        [82.3279190063476, 16.99069213867199],
        [82.32847595214855, 16.99069213867199],
        [82.32847595214855, 16.99097442626953],
        [82.32930755615234, 16.990970611572322],
        [82.32930755615234, 16.991252899169922],
        [82.32958221435575, 16.991252899169922],
        [82.32958221435575, 16.990970611572322],
        [82.33152770996105, 16.99097442626953],
        [82.33152770996105, 16.99069213867199],
        [82.332084655762, 16.99069213867199],
        [82.332084655762, 16.99041748046875],
        [82.33264160156278, 16.990419387817326],
        [82.33264160156278, 16.99014282226574],
        [82.33347320556658, 16.99014282226574],
        [82.33347320556658, 16.98986053466797],
        [82.33403015136736, 16.98986053466797],
        [82.33403015136736, 16.989580154419002],
        [82.33458709716791, 16.989582061767635],
        [82.33458709716791, 16.98930549621582],
        [82.33541870117188, 16.989311218261776],
        [82.33541870117188, 16.989032745361385],
        [82.33569335937528, 16.989032745361385],
        [82.33569335937528, 16.988750457763615],
        [82.33625030517607, 16.988750457763615],
        [82.33625030517607, 16.98846054077154],
        [82.33652496337908, 16.98847007751465],
        [82.33652496337908, 16.98819351196289],
        [82.33708190917986, 16.98819351196289],
        [82.33708190917986, 16.987916946411246],
        [82.33735656738287, 16.98792266845703],
        [82.33735656738287, 16.987642288208065],
        [82.33790588378912, 16.987642288208065],
        [82.33790588378912, 16.98735809326172],
        [82.33819580078136, 16.987361907959098],
        [82.33819580078136, 16.987083435058707],
        [82.33875274658232, 16.987083435058707],
        [82.33875274658232, 16.986804962158317],
        [82.33902740478516, 16.9868106842041],
        [82.33902740478516, 16.986528396606502],
        [82.33957672119158, 16.986528396606502],
        [82.33957672119158, 16.98624992370611],
        [82.33985900878912, 16.98624992370611],
        [82.33985900878912, 16.985969543456974],
        [82.34041595459007, 16.985971450805778],
        [82.34041595459007, 16.985694885253963],
        [82.34069061279291, 16.985694885253963],
        [82.34069824218744, 16.985416412353572],
        [82.34124755859386, 16.98541831970215],
        [82.34124755859386, 16.985139846801758],
        [82.34152221679688, 16.985139846801758],
        [82.34152221679688, 16.98485946655279],
        [82.34180450439482, 16.984861373901424],
        [82.34180450439482, 16.984582901001033],
        [82.3423614501956, 16.984582901001033],
        [82.3423614501956, 16.98430633544922],
        [82.34263610839861, 16.984308242797795],
        [82.34263610839861, 16.984029769897404],
        [82.34291839599615, 16.984029769897404],
        [82.34291839599615, 16.983749389648438],
        [82.34319305419939, 16.983749389648438],
        [82.34319305419939, 16.983470916748047],
        [82.34375, 16.98347282409668],
        [82.34375, 16.98319435119629],
        [82.34402465820318, 16.98319435119629],
        [82.34402465820318, 16.982917785644474],
        [82.3443069458009, 16.982919692993278],
        [82.3443069458009, 16.982650756835994],
        [82.34485626220732, 16.982650756835994],
        [82.34485626220732, 16.982360839843693],
        [82.34513854980469, 16.982360839843693],
        [82.34513854980469, 16.982080459594727],
        [82.3454132080081, 16.98208236694336],
        [82.3454132080081, 16.98180770874029],
        [82.345703125, 16.981809616088924],
        [82.345703125, 16.981531143188533],
        [82.34625244140642, 16.981531143188533],
        [82.34625244140642, 16.981250762939567],
        [82.3465270996096, 16.981250762939567],
        [82.3465270996096, 16.980970382690373],
        [82.34680175781244, 16.980972290039176],
        [82.34680175781244, 16.98069572448736],
        [82.34708404541021, 16.98069572448736],
        [82.34708404541021, 16.98041725158697],
        [82.3473587036134, 16.98042106628418],
        [82.3473587036134, 16.980140686035213],
        [82.347640991211, 16.980140686035213],
        [82.347640991211, 16.979579925537053],
        [82.34791564941435, 16.979585647583008],
        [82.34791564941435, 16.979303359985465],
        [82.34819793701172, 16.979310989379996],
        [82.34819793701172, 16.97903060913086],
        [82.34847259521513, 16.97903060913086],
        [82.34847259521513, 16.978750228881836],
        [82.34874725341814, 16.978750228881836],
        [82.34874725341814, 16.97846984863287],
        [82.34902954101591, 16.978473663330078],
        [82.34902954101591, 16.977916717529297],
        [82.34929656982439, 16.977920532226676],
        [82.34929656982439, 16.977359771728516],
        [82.34958648681646, 16.977363586425895],
        [82.34958648681646, 16.977081298828125],
        [82.3498611450197, 16.977081298828125],
        [82.3498611450197, 16.976528167724553],
        [82.35013580322271, 16.976528167724553],
        [82.35013580322271, 16.976249694824162],
        [82.35041809082026, 16.976249694824162],
        [82.35041809082026, 16.975690841674805],
        [82.3506927490235, 16.975694656372184],
        [82.3506927490235, 16.97513961791998],
        [82.35098266601574, 16.97513961791998],
        [82.35097503662121, 16.97485923767084],
        [82.35124969482422, 16.97485923767084],
        [82.35124969482422, 16.974311828613395],
        [82.3515167236331, 16.974311828613395],
        [82.3515167236331, 16.97374916076666],
        [82.351806640625, 16.97374916076666],
        [82.351806640625, 16.97346115112316],
        [82.35208129882841, 16.9734725952149],
        [82.35208129882841, 16.972917556762695],
        [82.35235595703142, 16.972921371460075],
        [82.35235595703142, 16.972360610961914],
        [82.35263824462896, 16.972360610961914],
        [82.35263824462896, 16.972080230712947],
        [82.3529129028322, 16.972084045410156],
        [82.3529129028322, 16.971527099609375],
        [82.35320281982445, 16.971530914306754],
        [82.35320281982445, 16.97125053405756],
        [82.35346984863276, 16.97125053405756],
        [82.3534622192384, 16.970689773559627],
        [82.35375213623053, 16.970693588256836],
        [82.35375213623053, 16.97013854980463],
        [82.35402679443371, 16.970140457153434],
        [82.35402679443371, 16.96986007690424],
        [82.3543090820313, 16.969861984253043],
        [82.3543090820313, 16.969306945800838],
        [82.35457611084013, 16.969310760498047],
        [82.35457611084013, 16.96903038024908],
        [82.3548583984375, 16.96903038024908],
        [82.3548583984375, 16.96846961975092],
        [82.35514068603544, 16.968471527099723],
        [82.35514068603544, 16.968196868896484],
        [82.35542297363298, 16.968200683593864],
        [82.35542297363298, 16.967639923095703],
        [82.355697631836, 16.967639923095703],
        [82.355697631836, 16.967081069946346],
        [82.35597229003923, 16.967084884643555],
        [82.35597229003923, 16.966806411743164],
        [82.35624694824241, 16.966810226440543],
        [82.35624694824241, 16.966249465942383],
        [82.35652160644526, 16.966249465942383],
        [82.35652160644526, 16.965970993041992],
        [82.35680389404303, 16.96597480773937],
        [82.35680389404303, 16.965414047241268],
        [82.35708618164074, 16.965419769287223],
        [82.35708618164074, 16.96513938903803],
        [82.3573608398438, 16.96513938903803],
        [82.3573608398438, 16.964580535888672],
        [82.35763549804716, 16.964582443237305],
        [82.35763549804716, 16.963752746582088],
        [82.35791778564453, 16.963752746582088],
        [82.35791778564453, 16.962642669677734],
        [82.35819244384794, 16.962642669677734],
        [82.35819244384794, 16.96125030517578],
        [82.35846710205095, 16.96125030517578],
        [82.35846710205095, 16.96013832092285],
        [82.3587493896485, 16.96013832092285],
        [82.3587493896485, 16.95819091796875],
        [82.35902404785173, 16.95819473266613],
        [82.35902404785173, 16.9570827484132],
        [82.35930633544945, 16.9570827484132],
        [82.35930633544945, 16.955972671508846],
        [82.35958099365229, 16.955972671508846],
        [82.35958099365229, 16.95486068725586],
        [82.35986328125006, 16.95486068725586],
        [82.35986328125006, 16.953750610351506],
        [82.36013793945324, 16.953750610351506],
        [82.36013793945324, 16.95014190673828],
        [82.36042022705084, 16.95014190673828],
        [82.36042022705084, 16.94681167602539],
        [82.36068725585966, 16.94681167602539],
        [82.36068725585966, 16.94568824768072],
        [82.36096954345703, 16.945693969726676],
        [82.36096954345703, 16.944583892822322],
        [82.36125183105497, 16.944583892822322],
        [82.36125183105497, 16.943471908569393],
        [82.36152648925798, 16.943479537963924],
        [82.36152648925798, 16.94207954406744],
        [82.36180877685575, 16.94208335876465],
        [82.36180877685575, 16.940416336059684],
        [82.36208343505876, 16.940416336059684],
        [82.36208343505876, 16.93874931335455],
        [82.36235809326195, 16.938751220703182],
        [82.36235809326195, 16.937080383300838],
        [82.36264038085955, 16.93708229064947],
        [82.36264038085955, 16.936527252197266],
        [82.36235809326195, 16.936531066894645],
        [82.36235809326195, 16.936252593994254],
        [82.36264801025408, 16.936252593994254],
        [82.36264038085955, 16.93236160278326],
        [82.36291503906256, 16.93236160278326],
        [82.36291503906256, 16.92986106872553],
        [82.3631973266601, 16.92986106872553],
        [82.3631973266601, 16.928180694580135],
        [82.36347198486334, 16.928195953369197],
        [82.36347198486334, 16.926805496215877],
        [82.36374664306669, 16.926811218261832],
        [82.36374664306669, 16.925140380859318],
        [82.36402893066406, 16.925140380859318],
        [82.36402893066406, 16.922920227050838],
        [82.36429595947294, 16.922920227050838],
        [82.36429595947294, 16.92013931274414],
        [82.36458587646484, 16.92013931274414],
        [82.36458587646484, 16.919029235839787],
        [82.36486053466825, 16.919029235839787],
        [82.36486053466825, 16.916810989379883],
        [82.3651428222658, 16.916810989379883],
        [82.3651428222658, 16.915420532226562],
        [82.3654174804688, 16.915420532226562],
        [82.3654174804688, 16.906248092651367],
        [82.36569213867205, 16.906248092651367],
        [82.36569213867205, 16.90208053588873],
        [82.3654174804688, 16.90208244323736],
        [82.3654174804688, 16.89958572387701],
        [82.36513519287126, 16.89958572387701],
        [82.36513519287126, 16.89680480957037],
        [82.36486053466825, 16.89680480957037],
        [82.36486053466825, 16.894306182861442],
        [82.36458587646484, 16.894311904907227],
        [82.36458587646484, 16.891807556152344],
        [82.36429595947294, 16.891807556152344],
        [82.36429595947294, 16.88929939270031],
        [82.36402893066406, 16.889305114746094],
        [82.36402893066406, 16.886806488037166],
        [82.36374664306669, 16.886810302734375],
        [82.36374664306669, 16.88430976867687],
        [82.36347198486334, 16.88430976867687],
        [82.36347198486334, 16.884862899780273],
        [82.3631973266601, 16.884862899780273],
        [82.3631973266601, 16.885419845580998],
        [82.36264801025408, 16.885419845580998],
        [82.36264801025408, 16.884862899780273],
        [82.36235809326195, 16.884862899780273],
        [82.36235809326195, 16.88430976867687],
        [82.36207580566423, 16.88430976867687],
        [82.36207580566423, 16.882642745971737],
        [82.36180877685575, 16.882642745971737],
        [82.36180877685575, 16.881250381469783],
        [82.36152648925798, 16.881250381469783],
        [82.36152648925798, 16.88070106506359],
        [82.36125183105497, 16.88070106506359],
        [82.36125183105497, 16.878469467163143],
        [82.36096954345703, 16.87847137451172],
        [82.36096954345703, 16.87791633605957],
        [82.36068725585966, 16.877918243408146],
        [82.36068725585966, 16.877639770507756],
        [82.36042022705084, 16.877639770507756],
        [82.36042022705084, 16.877069473266715],
        [82.36013793945324, 16.87708282470703],
        [82.36013793945324, 16.874305725097713],
        [82.35986328125006, 16.874320983886776],
        [82.35986328125006, 16.872640609741154],
        [82.35958099365229, 16.872640609741154],
        [82.35958099365229, 16.871810913085994],
        [82.35930633544945, 16.871810913085994],
        [82.35930633544945, 16.870969772338867],
        [82.35902404785173, 16.870973587036246],
        [82.35902404785173, 16.870138168335075],
        [82.3587493896485, 16.87014007568365],
        [82.3587493896485, 16.868469238281364],
        [82.35902404785173, 16.86847114562994],
        [82.35902404785173, 16.866806030273438],
        [82.3587493896485, 16.866811752319393],
        [82.3587493896485, 16.866249084472656],
        [82.35846710205095, 16.866249084472656],
        [82.35846710205095, 16.8656902313233],
        [82.35819244384794, 16.865694046020508],
        [82.35819244384794, 16.865137100219727],
        [82.35791778564453, 16.865137100219727],
        [82.35791778564453, 16.859584808349723],
        [82.35763549804716, 16.859584808349723],
        [82.35763549804716, 16.856527328491268],
        [82.35745239257841, 16.856527328491268],
        [82.3573608398438, 16.856527328491268],
        [82.3573608398438, 16.855457305908203],
        [82.3573608398438, 16.854305267333984],
        [82.35764312744169, 16.854312896728516],
        [82.35764312744169, 16.85318756103527],
        [82.35791778564453, 16.85318756103527],
        [82.35791778564453, 16.853748321533203],
        [82.35819244384794, 16.853748321533203],
        [82.35819244384794, 16.85403251647955],
        [82.35846710205095, 16.85403251647955],
        [82.35846710205095, 16.853748321533203],
        [82.3587493896485, 16.853748321533203],
        [82.3587493896485, 16.85263824462902],
        [82.3590164184572, 16.85263824462902],
        [82.3590164184572, 16.851249694824276],
        [82.3587493896485, 16.851249694824276],
        [82.3587493896485, 16.850690841674748],
        [82.35847473144548, 16.850694656372127],
        [82.35847473144548, 16.850416183471737],
        [82.3587493896485, 16.850418090820312],
        [82.3587493896485, 16.84986114501953],
        [82.3590164184572, 16.84986114501953],
        [82.3590164184572, 16.848751068115178],
        [82.3587493896485, 16.848751068115178],
        [82.3587493896485, 16.84847068786621],
        [82.35847473144548, 16.848472595214844],
        [82.35847473144548, 16.848194122314453],
        [82.35819244384794, 16.848194122314453],
        [82.35819244384794, 16.847085952758846],
        [82.35791778564453, 16.847089767456055],
        [82.35791778564453, 16.846250534057674],
        [82.35763549804716, 16.846250534057674],
        [82.35763549804716, 16.845695495605526],
        [82.35791778564453, 16.845695495605526],
        [82.35791778564453, 16.845417022705135],
        [82.35819244384794, 16.845420837402344],
        [82.35819244384794, 16.844579696655387],
        [82.35847473144548, 16.844581604003963],
        [82.35847473144548, 16.84430313110363],
        [82.35819244384794, 16.84431076049816],
        [82.35819244384794, 16.843200683593807],
        [82.35791778564453, 16.843200683593807],
        [82.35791778564453, 16.84264183044445],
        [82.35764312744169, 16.84264183044445],
        [82.35764312744169, 16.84235954284668],
        [82.3573608398438, 16.84236335754406],
        [82.3573608398438, 16.841527938842887],
        [82.35708618164074, 16.841531753540096],
        [82.35708618164074, 16.841249465942326],
        [82.3567962646485, 16.841249465942326],
        [82.3567962646485, 16.840959548950252],
        [82.35652923583979, 16.84097290039074],
        [82.35652923583979, 16.840415954589957],
        [82.35624694824241, 16.840421676635742],
        [82.35624694824241, 16.838470458984432],
        [82.35597229003923, 16.838472366333065],
        [82.35597229003923, 16.83763885498047],
        [82.35542297363298, 16.837640762329045],
        [82.35542297363298, 16.837360382080078],
        [82.35514068603544, 16.837360382080078],
        [82.35514068603544, 16.836811065673885],
        [82.3548583984375, 16.836811065673885],
        [82.3548583984375, 16.83570098876953],
        [82.35457611084013, 16.83570098876953],
        [82.35457611084013, 16.83374977111822],
        [82.3543090820313, 16.83374977111822],
        [82.3543090820313, 16.832639694213867],
        [82.35402679443371, 16.832639694213867],
        [82.35402679443371, 16.832090377807674],
        [82.35375213623053, 16.832090377807674],
        [82.35375213623053, 16.831809997558707],
        [82.3534622192384, 16.831809997558707],
        [82.3534622192384, 16.83069038391119],
        [82.35319519042991, 16.830692291259766],
        [82.35319519042991, 16.82763862609869],
        [82.3534622192384, 16.8276424407959],
        [82.3534622192384, 16.826528549194336],
        [82.35320281982445, 16.826528549194336],
        [82.35320281982445, 16.82624816894537],
        [82.3529129028322, 16.82624816894537],
        [82.3529129028322, 16.824310302734432],
        [82.35263824462896, 16.824310302734432],
        [82.35263824462896, 16.82347106933588],
        [82.3529129028322, 16.823472976684684],
        [82.3529129028322, 16.82291793823248],
        [82.35320281982445, 16.822919845581055],
        [82.35320281982445, 16.822360992431697],
        [82.3529129028322, 16.822360992431697],
        [82.3529129028322, 16.82208061218256],
        [82.35208129882841, 16.822082519531364],
        [82.35208129882841, 16.821807861328125],
        [82.351806640625, 16.8218097686767],
        [82.351806640625, 16.820970535278377],
        [82.35208129882841, 16.82097244262701],
        [82.35208129882841, 16.820417404174805],
        [82.35235595703142, 16.820421218872184],
        [82.35235595703142, 16.81931114196783],
        [82.35208129882841, 16.81931114196783],
        [82.35208129882841, 16.818201065063477],
        [82.351806640625, 16.818201065063477],
        [82.351806640625, 16.81792068481451],
        [82.3515167236331, 16.81792068481451],
        [82.3515167236331, 16.817640304565487],
        [82.35124969482422, 16.817640304565487],
        [82.35124969482422, 16.816791534423885],
        [82.35097503662121, 16.81680488586437],
        [82.35097503662121, 16.815971374511776],
        [82.3506927490235, 16.815971374511776],
        [82.3506927490235, 16.815141677856445],
        [82.35041809082026, 16.815141677856445],
        [82.35041809082026, 16.814580917358455],
        [82.35013580322271, 16.814582824707088],
        [82.35013580322271, 16.814027786254883],
        [82.3498611450197, 16.814027786254883],
        [82.3498611450197, 16.813749313354492],
        [82.34958648681646, 16.813749313354492],
        [82.34958648681646, 16.813190460205135],
        [82.34930419921892, 16.813194274902344],
        [82.34930419921892, 16.812360763549748],
        [82.34902954101591, 16.812360763549748],
        [82.34902954101591, 16.811807632446346],
        [82.34929656982439, 16.811807632446346],
        [82.34929656982439, 16.8104190826416],
        [82.34902954101591, 16.8104190826416],
        [82.34902954101591, 16.809579849243278],
        [82.34874725341814, 16.809583663940487],
        [82.34874725341814, 16.809305191040096],
        [82.34847259521513, 16.80931091308605],
        [82.34847259521513, 16.808469772338924],
        [82.34819793701172, 16.8084716796875],
        [82.34819793701172, 16.808195114135742],
        [82.34847259521513, 16.808210372924805],
        [82.34847259521513, 16.80792045593273],
        [82.34874725341814, 16.80792045593273],
        [82.34877014160156, 16.808210372924805],
        [82.34902954101591, 16.808195114135742],
        [82.34902954101591, 16.8084716796875],
        [82.34929656982439, 16.808469772338924],
        [82.34929656982439, 16.80931091308605],
        [82.34958648681646, 16.80931091308605],
        [82.34958648681646, 16.809860229492244],
        [82.34929656982439, 16.809860229492244],
        [82.34929656982439, 16.81014060974121],
        [82.34958648681646, 16.81014060974121],
        [82.34958648681646, 16.8104190826416],
        [82.3498611450197, 16.810417175293026],
        [82.3498611450197, 16.81069374084484],
        [82.35041809082026, 16.81068992614746],
        [82.35041809082026, 16.811250686645565],
        [82.3506927490235, 16.811250686645565],
        [82.3506927490235, 16.81208419799816],
        [82.35098266601574, 16.81208038330078],
        [82.35098266601574, 16.81263923645014],
        [82.35124969482422, 16.81263923645014],
        [82.35124969482422, 16.813749313354492],
        [82.351806640625, 16.813749313354492],
        [82.351806640625, 16.814027786254883],
        [82.3515167236331, 16.814018249511776],
        [82.3515167236331, 16.814310073852653],
        [82.351806640625, 16.814310073852653],
        [82.351806640625, 16.81542015075678],
        [82.35208129882841, 16.815416336059627],
        [82.35208129882841, 16.815971374511776],
        [82.35235595703142, 16.8159694671632],
        [82.35235595703142, 16.816530227661133],
        [82.35263824462896, 16.816528320312557],
        [82.35263824462896, 16.81708145141596],
        [82.3529129028322, 16.817079544067383],
        [82.3529129028322, 16.81792068481451],
        [82.35320281982445, 16.81792068481451],
        [82.35320281982445, 16.818201065063477],
        [82.35346984863276, 16.818195343017692],
        [82.35346984863276, 16.818473815918082],
        [82.35458374023466, 16.818473815918082],
        [82.35458374023466, 16.818195343017692],
        [82.3548583984375, 16.818201065063477],
        [82.3548583984375, 16.817640304565487],
        [82.35514068603544, 16.817640304565487],
        [82.35514068603544, 16.817079544067383],
        [82.35541534423845, 16.81708145141596],
        [82.35541534423845, 16.816368103027457],
        [82.35541534423845, 16.8162517547608],
        [82.355697631836, 16.8162517547608],
        [82.355697631836, 16.815141677856445],
        [82.35597229003923, 16.815141677856445],
        [82.35597229003923, 16.814580917358455],
        [82.355697631836, 16.814582824707088],
        [82.355697631836, 16.81208419799816],
        [82.35541534423845, 16.81208419799816],
        [82.35541534423845, 16.81097221374523],
        [82.35514068603544, 16.810979843139762],
        [82.35514068603544, 16.81014060974121],
        [82.3548583984375, 16.81014060974121],
        [82.3548583984375, 16.808210372924805],
        [82.35457611084013, 16.808210372924805],
        [82.35457611084013, 16.80708122253418],
        [82.3543090820313, 16.807083129882812],
        [82.3543090820313, 16.806528091430778],
        [82.35402679443371, 16.806529998779354],
        [82.35402679443371, 16.806249618530217],
        [82.35375213623053, 16.806249618530217],
        [82.35375213623053, 16.80569076538086],
        [82.35346984863276, 16.80569458007824],
        [82.35346984863276, 16.804584503173885],
        [82.35319519042991, 16.804584503173885],
        [82.35319519042991, 16.804027557373104],
        [82.3534622192384, 16.80402946472168],
        [82.3534622192384, 16.803470611572322],
        [82.35319519042991, 16.803470611572322],
        [82.35319519042991, 16.80236053466797],
        [82.3529129028322, 16.80236053466797],
        [82.3529129028322, 16.801532745361385],
        [82.35263824462896, 16.801532745361385],
        [82.35263824462896, 16.800701141357422],
        [82.35235595703142, 16.800689697265682],
        [82.35236358642595, 16.800138473510856],
        [82.35208129882841, 16.800138473510856],
        [82.35208129882841, 16.799579620361328],
        [82.351806640625, 16.799583435058707],
        [82.351806640625, 16.797639846801758],
        [82.3515167236331, 16.797639846801758],
        [82.3515167236331, 16.796529769897404],
        [82.35124969482422, 16.796529769897404],
        [82.35124969482422, 16.79569053649908],
        [82.35152435302763, 16.79569435119629],
        [82.35152435302763, 16.79263877868658],
        [82.35124969482422, 16.792640686035156],
        [82.35124969482422, 16.791250228881836],
        [82.35097503662121, 16.791250228881836],
        [82.35097503662121, 16.790138244628906],
        [82.3506927490235, 16.790140151977482],
        [82.3506927490235, 16.78957939147955],
        [82.35041809082026, 16.789581298828125],
        [82.35041809082026, 16.789304733276367],
        [82.35013580322271, 16.789304733276367],
        [82.35013580322271, 16.78847122192377],
        [82.3498611450197, 16.78847122192377],
        [82.3498611450197, 16.78764152526861],
        [82.34958648681646, 16.78764152526861],
        [82.34958648681646, 16.787359237671012],
        [82.34929656982439, 16.787359237671012],
        [82.34929656982439, 16.78708076477045],
        [82.34902954101591, 16.787082672119254],
        [82.34902954101591, 16.78624916076666],
        [82.34929656982439, 16.78624916076666],
        [82.34929656982439, 16.785970687866268],
        [82.34958648681646, 16.7859725952149],
        [82.34958648681646, 16.784584045410156],
        [82.34902954101591, 16.784584045410156],
        [82.34902954101591, 16.784305572509766],
        [82.3473587036134, 16.784307479858512],
        [82.3473587036134, 16.78375053405756],
        [82.34707641601568, 16.78375053405756],
        [82.34707641601568, 16.781810760498047],
        [82.34680175781244, 16.781810760498047],
        [82.34680175781244, 16.78125],
        [82.3465270996096, 16.78125],
        [82.3465270996096, 16.78042030334467],
        [82.34625244140642, 16.78042030334467],
        [82.34625244140642, 16.779310226440543],
        [82.34597015380865, 16.779310226440543],
        [82.34597015380865, 16.778190612793026],
        [82.34569549560564, 16.7781925201416],
        [82.34569549560564, 16.77708435058605],
        [82.3454132080081, 16.77708435058605],
        [82.3454132080081, 16.775417327880916],
        [82.34513854980469, 16.775421142578125],
        [82.34513854980469, 16.773199081420955],
        [82.34485626220732, 16.773199081420955],
        [82.34485626220732, 16.77152824401867],
        [82.34458160400396, 16.77152824401867],
        [82.34458160400396, 16.770418167114315],
        [82.3443069458009, 16.770418167114315],
        [82.3443069458009, 16.76736068725586],
        [82.34458160400396, 16.76736068725586],
        [82.34458160400396, 16.767080307006893],
        [82.3443069458009, 16.7670841217041],
        [82.3443069458009, 16.76680564880371],
        [82.34401702880865, 16.766809463501033],
        [82.34401702880865, 16.7665309906007],
        [82.34375, 16.7665309906007],
        [82.34375, 16.766250610351506],
        [82.34291839599615, 16.766250610351506],
        [82.34291839599615, 16.765140533447322],
        [82.34263610839861, 16.765140533447322],
        [82.34263610839861, 16.764310836791992],
        [82.34291839599615, 16.764310836791992],
        [82.34291839599615, 16.76403045654297],
        [82.34348297119146, 16.76403045654297],
        [82.34348297119146, 16.763750076294002],
        [82.34375, 16.763750076294002],
        [82.34375, 16.76320075988781],
        [82.34348297119146, 16.76320075988781],
        [82.34348297119146, 16.762912750244084],
        [82.34319305419939, 16.762916564941463],
        [82.34319305419939, 16.762638092041072],
        [82.34291839599615, 16.76264190673828],
        [82.34291839599615, 16.762079238891715],
        [82.34319305419939, 16.76208114624029],
        [82.34319305419939, 16.761249542236328],
        [82.34348297119146, 16.761249542236328],
        [82.3434753417971, 16.760139465331974],
        [82.34319305419939, 16.760139465331974],
        [82.34319305419939, 16.7587509155274],
        [82.34291839599615, 16.7587509155274],
        [82.34291839599615, 16.75792121887207],
        [82.34263610839861, 16.75792121887207],
        [82.34263610839861, 16.75736045837408],
        [82.3423614501956, 16.75736045837408],
        [82.3423614501956, 16.755701065063533],
        [82.34208679199219, 16.755701065063533],
        [82.34208679199219, 16.755420684814567],
        [82.34179687500028, 16.755420684814567],
        [82.34179687500028, 16.75457954406744],
        [82.34152984619146, 16.75458335876465],
        [82.34152984619146, 16.753471374511662],
        [82.34124755859386, 16.753471374511662],
        [82.34124755859386, 16.75208473205572],
        [82.34152984619146, 16.75208473205572],
        [82.34152984619146, 16.750692367553768],
        [82.34124755859386, 16.750692367553768],
        [82.34124755859386, 16.750139236450195],
        [82.34097290039068, 16.75014114379877],
        [82.34097290039068, 16.749862670898438],
        [82.34124755859386, 16.749862670898438],
        [82.34124755859386, 16.748191833496094],
        [82.34152984619146, 16.748193740844727],
        [82.34152984619146, 16.74624824523937],
        [82.34124755859386, 16.74624824523937],
        [82.34124755859386, 16.74596977233898],
        [82.34097290039068, 16.745971679687557],
        [82.34097290039068, 16.744861602783203],
        [82.34124755859386, 16.74486923217779],
        [82.34124755859386, 16.744581222534237],
        [82.34152984619146, 16.74458312988287],
        [82.34152984619146, 16.74430465698248],
        [82.34180450439482, 16.74430465698248],
        [82.34180450439482, 16.743194580078125],
        [82.34208679199219, 16.743194580078125],
        [82.34208679199219, 16.740417480468807],
        [82.3423614501956, 16.740419387817383],
        [82.3423614501956, 16.739311218261832],
        [82.34263610839861, 16.739311218261832],
        [82.34263610839861, 16.73818969726574],
        [82.34291839599615, 16.738191604614315],
        [82.34291839599615, 16.737916946411133],
        [82.34263610839861, 16.737920761108512],
        [82.34263610839861, 16.737640380859318],
        [82.3423614501956, 16.737640380859318],
        [82.3423614501956, 16.737361907958928],
        [82.34208679199219, 16.73736381530773],
        [82.34208679199219, 16.73680305480957],
        [82.34179687500028, 16.73681068420416],
        [82.34179687500028, 16.7362518310548],
        [82.34152221679688, 16.7362518310548],
        [82.34152221679688, 16.73596954345703],
        [82.33921051025408, 16.73596954345703],
        [82.33625030517607, 16.73597335815441],
        [82.33625030517607, 16.73569488525402],
        [82.33541870117188, 16.735700607299805],
        [82.33541870117188, 16.73542022705078],
        [82.33429718017584, 16.73542022705078],
        [82.33429718017584, 16.735141754150447],
        [82.33264160156278, 16.735141754150447],
        [82.33264160156278, 16.734859466552848],
        [82.33235168457031, 16.734859466552848],
        [82.33235168457031, 16.735141754150447],
        [82.32985687255876, 16.735141754150447],
        [82.32985687255876, 16.734859466552848],
        [82.32763671875023, 16.734859466552848],
        [82.32763671875023, 16.73458290100109],
        [82.3279190063476, 16.73458290100109],
        [82.3279190063476, 16.734306335449276],
        [82.32736206054705, 16.734310150146428],
        [82.32736206054705, 16.734031677246094],
        [82.3268127441408, 16.734031677246094],
        [82.3268127441408, 16.733749389648494],
        [82.32624816894531, 16.733749389648494],
        [82.32624816894531, 16.733470916748104],
        [82.32569122314453, 16.733470916748104],
        [82.32569122314453, 16.733749389648494],
        [82.32541656494152, 16.733749389648494],
        [82.32541656494152, 16.73458290100109],
        [82.32514190673834, 16.73457908630371],
        [82.32514190673834, 16.73542022705078],
        [82.32457733154303, 16.73542022705078],
        [82.32457733154303, 16.735700607299805],
        [82.32402801513678, 16.735700607299805],
        [82.32402801513678, 16.73542022705078],
        [82.32319641113281, 16.73542022705078],
        [82.32319641113281, 16.735141754150447],
        [82.32263946533203, 16.735141754150447],
        [82.32263946533203, 16.734859466552848],
        [82.32236480712902, 16.734859466552848],
        [82.32236480712902, 16.73458290100109],
        [82.32180786132807, 16.73458290100109],
        [82.32180786132807, 16.734306335449276],
        [82.3206863403322, 16.734310150146428],
        [82.3206863403322, 16.734031677246094],
        [82.31986236572294, 16.734031677246094],
        [82.31986236572294, 16.733749389648494],
        [82.31970214843761, 16.733749389648494],
        [82.31874847412121, 16.733749389648494],
        [82.31874847412121, 16.733470916748104],
        [82.31791687011741, 16.733472824096737],
        [82.31791687011741, 16.733194351196346],
        [82.31764221191423, 16.733194351196346],
        [82.31764221191423, 16.732915878295955],
        [82.31735229492193, 16.732931137085018],
        [82.31735229492193, 16.73263931274414],
        [82.3165283203125, 16.73263931274414],
        [82.3165283203125, 16.732080459594783],
        [82.31625366210966, 16.732084274291992],
        [82.31625366210966, 16.7318058013916],
        [82.31485748291038, 16.7318058013916],
        [82.31485748291038, 16.732084274291992],
        [82.31430816650396, 16.732080459594783],
        [82.31430816650396, 16.73236083984375],
        [82.31402587890642, 16.73236083984375],
        [82.31402587890642, 16.73263931274414],
        [82.31291961669928, 16.73263931274414],
        [82.31291961669928, 16.733194351196346],
        [82.31263732910168, 16.733194351196346],
        [82.31263732910168, 16.733472824096737],
        [82.31180572509788, 16.733472824096737],
        [82.31180572509788, 16.732915878295955],
        [82.31153869628923, 16.732931137085018],
        [82.31153869628923, 16.731809616088924],
        [82.31124877929693, 16.731809616088924],
        [82.31124877929693, 16.730688095092887],
        [82.31097412109392, 16.730693817138672],
        [82.31097412109392, 16.729860305786076],
        [82.31124877929693, 16.729860305786076],
        [82.31124877929693, 16.729570388794002],
        [82.3115310668947, 16.72958374023449],
        [82.3115310668947, 16.729028701782283],
        [82.31181335449241, 16.72903060913086],
        [82.31181335449241, 16.728750228881893],
        [82.31208038330072, 16.728750228881893],
        [82.31208038330072, 16.7282009124757],
        [82.3123626708985, 16.7282009124757],
        [82.3123626708985, 16.727359771728572],
        [82.31263732910168, 16.72736167907715],
        [82.31263732910168, 16.72652816772461],
        [82.3123626708985, 16.726530075073185],
        [82.3123626708985, 16.72624969482422],
        [82.31208038330072, 16.72624969482422],
        [82.31208038330072, 16.72569084167486],
        [82.31180572509788, 16.72569465637207],
        [82.31180572509788, 16.725139617919865],
        [82.3115310668947, 16.725139617919865],
        [82.3115310668947, 16.724861145019474],
        [82.31124877929693, 16.724861145019474],
        [82.31124877929693, 16.724580764770508],
        [82.31097412109392, 16.724584579467887],
        [82.31097412109392, 16.724306106567496],
        [82.31069183349638, 16.72430992126465],
        [82.31069183349638, 16.723760604858455],
        [82.31041717529297, 16.723760604858455],
        [82.31041717529297, 16.723470687866154],
        [82.31014251709013, 16.723474502563533],
        [82.31014251709013, 16.723192214965763],
        [82.30986022949219, 16.723192214965763],
        [82.30986022949219, 16.722917556762752],
        [82.30959320068371, 16.722919464111328],
        [82.30959320068371, 16.722362518310604],
        [82.30930328369146, 16.722362518310604],
        [82.30930328369146, 16.722080230713004],
        [82.30902862548822, 16.72208213806158],
        [82.30902862548822, 16.721527099609432],
        [82.30874633789068, 16.721532821655387],
        [82.30874633789068, 16.72097015380865],
        [82.30847167968767, 16.72097015380865],
        [82.30847167968767, 16.720693588256893],
        [82.30819702148443, 16.720701217651424],
        [82.30819702148443, 16.720422744751033],
        [82.30790710449236, 16.720422744751033],
        [82.30790710449236, 16.71985816955572],
        [82.30764007568388, 16.719860076904297],
        [82.30764007568388, 16.71930503845215],
        [82.30735778808611, 16.719310760498104],
        [82.30735778808611, 16.719028472900504],
        [82.30652618408232, 16.719028472900504],
        [82.30652618408232, 16.718469619750977],
        [82.30596923828136, 16.718471527099553],
        [82.30596923828136, 16.718194961547965],
        [82.30568695068365, 16.71820068359375],
        [82.30568695068365, 16.717630386352653],
        [82.30541992187517, 16.71763992309576],
        [82.30541992187517, 16.71736145019537],
        [82.30513763427757, 16.71736145019537],
        [82.30513763427757, 16.717082977295036],
        [82.30486297607439, 16.717082977295036],
        [82.30486297607439, 16.71652793884283],
        [82.30430603027361, 16.716529846191406],
        [82.30430603027361, 16.71624946594244],
        [82.3040313720706, 16.71624946594244],
        [82.3040313720706, 16.71597099304205],
        [82.30347442626982, 16.715972900390682],
        [82.30347442626982, 16.71569442749029],
        [82.30291748046886, 16.71569442749029],
        [82.30291748046886, 16.715417861938477],
        [82.30264282226568, 16.715419769287053],
        [82.30264282226568, 16.715139389038086],
        [82.30152893066412, 16.715139389038086],
        [82.30152893066412, 16.714860916137695],
        [82.30041503906278, 16.714860916137695],
        [82.30041503906278, 16.714582443237305],
        [82.29985809326183, 16.714582443237305],
        [82.29985809326183, 16.714305877685547],
        [82.29951477050798, 16.71433258056652],
      ],
    ],
  ],
};

export const statePolygonsConfig = {
  Maharashtra: { multi: false },
  Karnataka: { multi: true },
  "Andhra Pradesh": { multi: true },
};
