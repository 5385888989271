import firebase, { auth, db } from "../firebase/FirebaseService";

const getUser = (): Promise<firebase.User | null> => {
  return new Promise((res) => {
    auth.onAuthStateChanged((user) => {
      res(user);
    });
  });
};

const listenForAuthChange = (
  callback: (user: firebase.User | null) => void
) => {
  auth.onAuthStateChanged((user) => {
    callback(user);
    if (user) {
      user.getIdToken().then(console.log);
      const scientistRef = db.collection("geospatial_scientists").doc(user.uid);
      scientistRef.get().then((doc) => {
        if (!doc.exists) {
          auth.signOut();
        }
      });
    }
  });
};

const signOut = auth.signOut;

const signIn = async (email: string, password: string) => {
  try {
    await auth.signInWithEmailAndPassword(email, password);

    return {
      status: "success",
    };
  } catch (e) {
    return {
      status: "fail",
      statusCode: (e as any).code,
    };
  }
};

const getAuthToken = (): Promise<string> => {
  return new Promise((res, rej) => {
    if (auth.currentUser) {
      auth.currentUser
        .getIdToken()
        .then((token) => {
          res(token);
        })
        .catch(rej);
    } else {
      setTimeout(() => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            user
              .getIdToken()
              .then((token) => {
                res(token);
              })
              .catch(rej);
          } else {
            rej("No Signed In User!");
          }
        });
      }, 500);
    }
  });
};

export { getUser, listenForAuthChange, signOut, signIn, getAuthToken };
