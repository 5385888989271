import styled from "styled-components";

type PosType = "top" | "left" | "right" | "bottom" | "horizontal" | "vertical";

const getPosition = ({ psn, size }: { psn: PosType; size: number }) => {
  switch (psn) {
    case "horizontal":
      return `margin: 0 ${size > 10 ? size / 10 + "rem" : size + "px"}`;
    case "vertical":
      return `margin: ${size > 10 ? size / 10 + "rem" : size + "px"} 0`;
    default:
      return `margin-${psn}: ${size > 10 ? size / 10 + "rem" : size + "px"}`;
  }
};

const Spacer = styled.div`
  ${getPosition};
`;

export default Spacer;
