import styled from "styled-components";

const Container = styled.div`
  display: flex;

  width: 100vw;
  height: 100vh;
  background-color: #121418;
`;

export default Container;
