import React from "react";
import Container from "./components/Container";
import CoverImage from "./components/CoverImage";
import { LoginForm } from "./components/LoginForm";

const Login = () => {
  return (
    <Container>
      <CoverImage />
      <div>
        <LoginForm />
      </div>
    </Container>
  );
};

export default Login;
