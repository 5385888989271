import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import Spacer from "../../../components/Spacer";

type SubItemType = {
  name: string;
  link: `/${string}`;
  active?: boolean;
};

interface NavItemProps {
  label: string;
  active?: boolean;
  icon: React.ReactChild;
  link?: `/${string}`;
  subItems?: SubItemType[];
}

const NavItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease-out;

  &:not(:last-child) {
    margin-bottom: 3.3rem;
  }
`;

const NavItemText = styled.h3`
  font-family: "Be Vietnam";
  font-weight: 600;
  font-size: 2rem;
  color: ${({ active }: { active: boolean; hover?: boolean }) =>
    active ? "#2F8FFC" : "#fff"};
  transition: font-weight 0.3s;

  &:hover {
    font-weight: ${({ hover = false }: { hover?: boolean; active: boolean }) =>
      hover ? 700 : 600};
  }
`;

const SubItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubNavItemText = styled(NavItemText)`
  font-weight: ${({ active }: { active: boolean }) => (active ? 500 : 400)};
  opacity: 74%;
  font-size: 1.8rem;
  transition: opacity 0.3s;

  &:hover {
    opacity: 90%;
  }
`;

const NavItem = ({
  active = false,
  label,
  icon,
  subItems = [],
  link,
}: NavItemProps) => {
  const history = useHistory();

  return (
    <NavItemContainer
      onClick={subItems.length ? () => null : () => link && history.push(link)}
    >
      {icon}
      <Spacer psn="right" size={24} />
      <SubItemsContainer>
        <NavItemText active={active} hover={!subItems.length}>
          {label}
        </NavItemText>
        {subItems.map((subItem, i) => (
          <SubNavItemText
            active={subItem.active ?? false}
            key={`SubItem__${i}`}
            onClick={() => history.push(subItem.link)}
          >
            {subItem.name}
          </SubNavItemText>
        ))}
      </SubItemsContainer>
    </NavItemContainer>
  );
};

export default NavItem;
