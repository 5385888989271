import { CircularProgress, withStyles } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import Spacer from "../Spacer";

interface PrimaryButtonProps {
  label: string;
  loading?: boolean;
  onClick?: () => void;
  icon?: React.ReactChild;
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
}

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #0677f9;
  opacity: ${({ disabled }: { disabled: boolean }) => (disabled ? 0.5 : 1)};
  padding: 1.3rem 0;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 9px;

  width: 100%;

  font-family: "Be Vietnam";
  font-size: 2rem;
  color: #fff;
  font-weight: 600;

  transition: all 0.2s;

  &:hover {
    background-color: #0768d7;
  }

  &:active {
    opacity: 0.8;
  }
`;

const StyledProgress = withStyles({
  root: {
    color: "#fff",
  },
})(CircularProgress);

const PrimaryButton = ({
  label,
  onClick,
  loading = false,
  icon,
  type,
  disabled,
}: PrimaryButtonProps) => {
  if (loading) {
    return (
      <StyledButton onClick={onClick} type={type} disabled={true}>
        <StyledProgress color="secondary" />
      </StyledButton>
    );
  }
  return (
    <StyledButton onClick={onClick} disabled={!!disabled}>
      {label}
      {label ? " " : ""}
      {icon && (
        <>
          {label && <Spacer psn="left" size={20} />}
          {icon}
        </>
      )}
    </StyledButton>
  );
};

export default PrimaryButton;
