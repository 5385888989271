import React from "react";
import styled from "styled-components";
import { CloseSharp } from "@material-ui/icons";

interface ModalProps {
  title: string;
  onClose: () => void;
  children: React.ReactChild | React.ReactChildren | React.ReactChild[];
}

const Modal = ({ title, onClose, children }: ModalProps) => {
  return (
    <Position>
      <Overlay></Overlay>
      <Container>
        <TopRow>
          <Title>{title}</Title>
          <CloseSharp
            style={{
              color: "#fff",
              fontSize: "2.5rem",
              marginTop: "0.5rem",
              cursor: "pointer",
            }}
            onClick={onClose}
          />
        </TopRow>
        <Content>{children}</Content>
      </Container>
    </Position>
  );
};

const Position = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: #21293022;
  backdrop-filter: blur(2px);
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 50vw;
  max-height: 90vh;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  background-color: #212930;
  border-radius: 2rem;
  z-index: 10;

  padding: 2rem 4rem;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  margin-bottom: 2rem;
`;

const Title = styled.div`
  font-family: "Be Vietnam", sans-serif;
  font-size: 2rem;
  color: #fff;
  font-weight: 600;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Modal;
