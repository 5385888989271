import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { functions } from "../../services/firebase/FirebaseService";
import { getAuthToken } from "../../services/auth/AuthService";

import Map from "../dashboard/components/Map";
import DateSelector from "./components/DateSelector";
import FieldOwnerDetails from "./components/FieldOwnerDetails";
import Analytics from "./components/Analytics";
import FieldModel from "../../models/FieldModel";
import SatelliteViewModel from "../../models/SatelliteViewModel";

const FieldView = () => {
  const { fieldID }: { fieldID: string } = useParams();
  const [activeField, setActiveField] = useState<FieldModel | null>(null);
  const [selectedFields, setSelectedFields] = useState<FieldModel[]>([]);
  const [scenes, setScenes] = useState<SatelliteViewModel[]>(
    JSON.parse(
      localStorage.getItem(
        `SearchResults__${activeField?.user_id}__${activeField}-${
          activeField?.field_id
        }__${new Date().toLocaleDateString("en-IN", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        })}}`
      ) || "[]"
    )
  );
  const [currentSatelliteImage, setCurrentSatelliteImage] = useState<
    string | null
  >(null);
  const [selectedIndex, setSelectedIndex] = useState<
    "NDVI" | "NDMI" | "RECI" | "NDRE" | "MSAVI"
  >("NDVI");
  const [startDate, setStartDate] = useState(
    (() => {
      let lastYear = new Date();
      lastYear.setFullYear(lastYear.getFullYear() - 1);
      return lastYear
        .toLocaleDateString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .split("/")
        .reverse()
        .join("-");
    })()
  );
  const [endDate, setEndDate] = useState(
    new Date()
      .toLocaleDateString("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .split("/")
      .reverse()
      .join("-")
  );

  useEffect(() => {
    const fields = JSON.parse(localStorage.getItem("fields") || "{}");
    setSelectedFields(fields[fieldID]);
  }, [fieldID]);

  useEffect(() => {
    if (selectedFields.length) {
      setActiveField(selectedFields[0]);
      console.log(selectedFields[1]);
    }
  }, [selectedFields]);

  useEffect(() => {
    // const storedScenes = JSON.parse(
    //   localStorage.getItem(
    //     `SearchResults__${activeField?.user_id}__${activeField}-${
    //       activeField?.field_id
    //     }__${new Date().toLocaleDateString("en-IN", {
    //       day: "numeric",
    //       month: "numeric",
    //       year: "numeric",
    //     })}}`
    //   ) || "[]"
    // );
    // if (storedScenes.length > scenes.length) {
    //   setScenes(storedScenes as SatelliteViewModel[]);
    // }

    if (activeField) {
      const { point1, point2, point3, point4 } = activeField;

      const pointToCoords = (point: string) => {
        return point
          .split(",")
          .reverse()
          .map((el) => Number(el));
      };

      functions
        .httpsCallable("searchForScenes")({
          geometry: activeField.geojson
            ? JSON.parse(activeField.geojson).geometry.coordinates
            : [
                [
                  pointToCoords(point1),
                  pointToCoords(point2),
                  pointToCoords(point3),
                  pointToCoords(point4),
                  pointToCoords(point1),
                ],
              ],
          startDate,
          endDate,
        })
        .then((result) => {
          console.log(JSON.stringify(result.data));
          if (result.data.data.results.length) {
            setScenes(result.data.data.results);
            localStorage.setItem(
              `SearchResults__${activeField?.user_id}__${activeField}-${
                activeField?.field_id
              }__${new Date().toLocaleDateString("en-IN", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              })}}`,
              JSON.stringify(result.data.data.results)
            );
          }
        })
        .catch((e) => alert(e.message));
    }
  }, [activeField, startDate, endDate]);

  // useEffect(() => {
  //   alert(startDate);
  // }, [startDate, endDate]);

  useEffect(() => {
    if (scenes.length) {
      localStorage.setItem(
        `SearchResults__${activeField?.user_id}__${activeField}-${
          activeField?.field_id
        }__${new Date().toLocaleDateString("en-IN", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        })}}`,
        JSON.stringify(scenes)
      );
    }
  }, [activeField, scenes]);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "60vw", display: "flex", flexDirection: "column" }}>
        {selectedFields.length && (
          <div style={{ width: "100%", height: "90vh" }}>
            <Map
              fields={selectedFields}
              onClick={(field) => {
                console.log("New active field");
                console.log(field);

                setActiveField(field);
              }}
              // satelliteImage={currentSatelliteImage as string}
              activeField={activeField!}
              // setActiveField={setActiveField}
            />
          </div>
        )}
        <DateSelector
          fields={selectedFields}
          activeField={activeField}
          searchResults={scenes}
          updateSatelliteImage={(satelliteImage: string) => {
            setCurrentSatelliteImage(satelliteImage);
          }}
          index={selectedIndex}
        />
      </div>
      {selectedFields.length && (
        <FieldOwnerDetails fields={selectedFields} activeField={activeField!} />
      )}
    </div>
  );
};

export default FieldView;
