import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  SatelliteOutlined,
  AssessmentOutlined,
  AccountCircleOutlined,
} from "@material-ui/icons";

import Spacer from "../../components/Spacer";
import { AuthContext } from "../../services/auth/AuthProvider";
import { auth } from "../../services/firebase/FirebaseService";
import NavItem from "./components/NavItem";
import SecondaryButton from "../../components/SecondaryButton";

const Container = styled.div`
  width: 20vw;
  height: 100vh;
  background-color: #212930;
  padding: 2rem 1.6rem;
  position: absolute;
  top: 0;
  right: -1px;
`;

const UserCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 1.4rem 0;
  background-color: #0677f9;
  border-radius: 8px;
  font-family: "Be Vietnam";
  color: #fff;

  & h2 {
    font-size: 2.4rem;
    font-weight: 600;
  }

  & h3 {
    font-family: "Be Vietnam";
    font-size: 1.7rem;
    font-weight: 500;
  }
`;

const NavItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type SubItemType = {
  name: string;
  link: `/${string}`;
  active?: boolean;
};

interface SidebarPageModel {
  name: string;
  link?: `/${string}`;
  icon: React.ReactChild;
  activeIcon: React.ReactChild;
  subItems?: SubItemType[];
  activeLinks: RegExp[];
}

const SIDEBAR_PAGES: SidebarPageModel[] = [
  {
    name: "Field Monitoring",
    link: "/",
    icon: (
      <SatelliteOutlined
        style={{
          fontSize: "2.3rem",
          marginTop: "5px",
          color: "#fff",
        }}
      />
    ),
    activeIcon: (
      <SatelliteOutlined
        color="primary"
        style={{
          fontSize: "2.3rem",
          marginTop: "5px",
        }}
      />
    ),
    activeLinks: [new RegExp("^/$"), new RegExp("/fields/(.*)")],
  },
  {
    name: "Signups",
    link: "/signups",
    icon: (
      <AccountCircleOutlined
        style={{
          fontSize: "2.3rem",
          marginTop: "5px",
          color: "#fff",
        }}
      />
    ),
    activeIcon: (
      <AccountCircleOutlined
        color="primary"
        style={{
          fontSize: "2.3rem",
          marginTop: "5px",
        }}
      />
    ),
    activeLinks: [/[/]signups.*/],
  },
  {
    name: "Reports",
    icon: (
      <AssessmentOutlined
        style={{
          fontSize: "2.3rem",
          marginTop: "5px",
          color: "#fff",
        }}
      />
    ),
    activeIcon: (
      <AssessmentOutlined
        color="primary"
        style={{
          fontSize: "2.3rem",
          marginTop: "5px",
        }}
      />
    ),
    subItems: [
      {
        name: "Pending",
        link: "/reports/pending",
      },
      {
        name: "Edit",
        link: "/reports/edit",
      },
    ],
    activeLinks: [new RegExp("/reports/(.*)")],
  },
];

const Sidebar = () => {
  const [currentTime, setCurrentTime] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [signoutLoading, setSignoutLoading] = useState(false);

  const timeInterval = useRef<null | ReturnType<typeof setInterval>>(null);
  const location = useLocation();
  const authContext = useContext(AuthContext);
  let user, handleSignOut: () => void;
  if (authContext) {
    user = authContext.user;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleSignOut = authContext.signOut;
  }

  const getCurrentTime = () => {
    setCurrentTime(
      new Date()
        .toLocaleTimeString("en-IN", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
        .split(" ")
        .map((el) => el.toUpperCase())
        .join(" ")
    );
  };

  useEffect(() => {
    getCurrentTime();
    const seconds = new Date().getSeconds();
    const diff = 60 - seconds;
    setTimeout(() => {
      getCurrentTime();
      timeInterval.current = setInterval(getCurrentTime, 60000);
    }, diff * 1000);
    return () => {
      if (timeInterval.current) {
        clearInterval(timeInterval.current);
      }
    };
  }, []);

  return (
    <Container>
      <UserCard>
        <h2>{user?.displayName}</h2>
        <h3>{currentTime}</h3>
      </UserCard>
      <Spacer psn="bottom" size={52} />
      <NavItemsContainer>
        {SIDEBAR_PAGES.map((sidebarPage, i) => {
          let regexTests = sidebarPage.activeLinks.map((regexp) =>
            regexp.test(location.pathname)
          );
          console.log(regexTests);
          console.log(sidebarPage.activeLinks);
          let active = regexTests.includes(true);
          if (sidebarPage.subItems) {
            const subItems = sidebarPage.subItems.map((subItem) => ({
              ...subItem,
              active: subItem.link === location.pathname,
            }));

            return (
              <NavItem
                label={sidebarPage.name}
                icon={sidebarPage.icon}
                subItems={subItems}
                key={`SidebarPage__${i}`}
              />
            );
          } else {
            return (
              <NavItem
                label={sidebarPage.name}
                icon={active ? sidebarPage.activeIcon : sidebarPage.icon}
                active={active}
                link={sidebarPage.link}
              />
            );
          }
        })}
      </NavItemsContainer>
      <div
        style={{
          position: "absolute",
          bottom: "2rem",
          left: "50%",
          transform: "translateX(-50%)",
          width: "calc(100% - 4rem)",
        }}
      >
        <SecondaryButton
          label="Logout"
          loading={signoutLoading}
          onClick={async () => {
            // // setSignoutLoading(true);
            // await handleSignOut();
            // // setSignoutLoading(false);
            auth.signOut();
          }}
        />
      </div>
    </Container>
  );
};

export default Sidebar;
