import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ArrowBackSharp, AddSharp } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Spacer from "../../../components/Spacer";
import ScrollContainer from "react-indiana-drag-scroll";
import PrimaryButton from "../../../components/PrimaryButton";
import FieldModel from "../../../models/FieldModel";
import WeatherModel, { ForecastDay } from "../../../models/WeatherModel";
import { CircularProgress } from "@material-ui/core";
import { getAuthToken } from "../../../services/auth/AuthService";
import AnalysisModel from "../../../models/AnalysisModel";
import openURL from "../../../utils/openURL";
import Modal from "../../../components/Modal";
import NewReportModal from "./NewReportModal";
import shpwrite from "@fielda/shp-write";
import DeleteFieldModal from "./DeleteFieldModal";
import { db } from "../../../services/firebase/FirebaseService";

const Container = styled.div`
  background-color: #212930;
  width: 20vw;
  height: 100vh;
  overflow: scroll;
  position: relative;
`;

const FieldOwnerName = styled.h2`
  font-weight: 600;
  font-size: 1.9rem;
  color: #fff;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
`;

const FieldOwnerPhone = styled.h3`
  font-weight: 600;
  color: #fff;
  font-size: 1.6rem;
  text-align: right;
`;

const FieldAveragePosition = styled.h4`
  font-weight: 500;
  font-size: 1.4rem;
  color: #8799ab;
  text-align: right;
`;

const FieldsCount = styled.span`
  background-color: #0677f9;
  border-radius: 2.5rem;
  padding: 1.5rem;
  color: #fff;
  font-family: "Be Vietnam";
  font-weight: 700;
  font-size: 3.2rem;
`;

const Text = styled.h3`
  font-size: ${({
    fontSize,
  }: {
    fontSize: number;
    fontWeight: number;
    style?: any;
  }) => fontSize / 10}rem;
  color: #fff;
  font-weight: ${({
    fontWeight,
  }: {
    fontWeight: number;
    fontSize: number;
    style?: any;
  }) => fontWeight};
`;

const WeatherDetail = ({ label, value }: { label: string; value: string }) => {
  return (
    <div style={{ flex: 1 }}>
      <Text
        fontSize={14}
        fontWeight={500}
        style={{ textAlign: "center", opacity: 0.83 }}
      >
        {label}
      </Text>
      <Text fontSize={16} fontWeight={400} style={{ textAlign: "center" }}>
        {value}
      </Text>
    </div>
  );
};

const ReportThumbnailImage = styled.img`
  width: 19rem;
  border-radius: 7px;
  overflow-y: hidden;
  z-index: 10;
`;

const ReportThumbnail = ({
  date,
  thumbnailURL,
  reportURL,
}: {
  date: Date;
  thumbnailURL: string;
  reportURL: string;
}) => {
  let formattedDate: string | string[] = date
    .toLocaleDateString("en-IN", {
      month: "long",
      day: "numeric",
      year: "numeric",
    })
    .split(" ");
  formattedDate = `${formattedDate[1]} ${formattedDate[0]}, ${formattedDate[2]}`;

  return (
    <div
      style={{ marginRight: "1.6rem", cursor: "pointer" }}
      onClick={() => {
        alert(reportURL);
        openURL(reportURL); // https://firebasestorage.googleapis.com/v0/b/greensat-9087a.appspot.com/o/crop_analysis_reports%2FSatya%20Narayan%20Crop%20Analysis%20(13-01-2021)%20(1)%20(2).pdf?alt=media&token=cc2bcef6-1ef2-4ca5-9395-2a6c4606a659
      }}
    >
      {/* <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "19rem",
          height: "10.7rem",
          zIndex: 12,
          cursor: "pointer",
        }}
      ></div> */}
      <ReportThumbnailImage
        src={thumbnailURL}
        // onClick={() => {
        //   openURL(thumbnailURL);
        // }}
      />
      <Spacer psn="bottom" size={10} />
      <Text fontSize={13} fontWeight={600} style={{ textAlign: "center" }}>
        {formattedDate}
      </Text>
    </div>
  );
};

const FieldOwnerDetails = ({
  fields,
  activeField,
}: {
  fields: FieldModel[];
  activeField: FieldModel;
}) => {
  const [weather, setWeather] = useState<WeatherModel>(
    JSON.parse(
      localStorage.getItem(
        `Weather-${fields[0].user_id}-${new Date()
          .toLocaleDateString("en-IN", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          })
          .replace(new RegExp("/", "g"), "-")}`
      ) || "{}"
    )
  );
  const [analysis, setAnalysis] = useState<AnalysisModel | null>(null);
  const [fieldsGeojson, setFieldsGeojson] = useState("");
  const [user, setUser] = useState<Record<string, any> | null>(null);

  const [showModal, setShowModal] = useState<boolean | string>(false);

  const history = useHistory();

  const getData = (key: string) => fields[0][key];

  const roundToAcc = (num: number, dp: number = 0) =>
    Math.round(num * 10 ** dp) / 10 ** dp;

  const getAveragePosition = (positionType: "lat" | "long") =>
    roundToAcc(
      fields
        .map((field) =>
          Number(field.position.split(",")[positionType === "lat" ? 0 : 1])
        )
        .reduce((acc, num) => acc + num) / fields.length,
      3
    );

  useEffect(() => {
    const pointToCoords = (point: string): [number, number] => {
      return point
        .split(",")
        .reverse()
        .map((el) => Number(el)) as [number, number];
    };

    const fieldsGeojson = JSON.stringify({
      type: "FeatureCollection",
      features: fields.map((field) => ({
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              pointToCoords(field.point1),
              pointToCoords(field.point2),
              pointToCoords(field.point3),
              pointToCoords(field.point4),
              pointToCoords(field.point1),
            ],
          ],
        },
      })),
    });

    setFieldsGeojson(fieldsGeojson);
  }, [fields]);

  useEffect(() => {
    function appendStatistics(
      analysis: AnalysisModel,
      index: keyof typeof analysis.values.indexes
    ) {
      const { q1, q3, max, min, p10, p90, std, median, average, variance } =
        analysis.values.indexes[index];

      analysis.statisticsCsv += `${index},${q1},${q3},${min},${max},${p10},${p90},${std},${median},${average},${variance}\n`;
    }

    (async function () {
      try {
        const cachedIndexAnalysisStr = localStorage.getItem(
          `${fields[0].user_id}-${
            activeField?.field_id || fields[0].field_id
          }-Index_Analysis-${new Date().toDateString()}`
        );

        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        let analysis: AnalysisModel;

        if (cachedIndexAnalysisStr) {
          analysis = JSON.parse(cachedIndexAnalysisStr) as AnalysisModel;
        } else {
          console.log("calling fn");
          setAnalysis(null);

          console.log({
            token: await getAuthToken(),
            password: "GenerateIndexAnalysis@GreenSat123$",
            user_id: fields[0].user_id,
            field_id: activeField?.field_id || fields[0].field_id,
            index: ["NDVI", "NDMI", "(B08-B05)/(B08+B05)"],
          });

          const indexAnalysisData = JSON.stringify({
            token: await getAuthToken(),
            password: "GenerateIndexAnalysis@GreenSat123$",
            user_id: fields[0].user_id,
            field_id: activeField?.field_id || fields[0].field_id,
            index: ["NDVI", "NDMI", "(B08-B05)/(B08+B05)"],
          });

          const response = await fetch(
            "https://us-central1-greensat-9087a.cloudfunctions.net/generateIndexAnalysisHTTP",
            {
              method: "POST",
              headers,
              body: indexAnalysisData,
              redirect: "follow",
            }
          );
          const result = await response.json();
          console.log(result);

          if (!result) return;

          console.log(result);
          if (result.status !== "success") {
            alert(result.message);
            return;
          }
          analysis = result.data as AnalysisModel;

          localStorage.setItem(
            `${fields[0].user_id}-${
              activeField?.field_id || fields[0].field_id
            }-Index_Analysis-${new Date().toDateString()}`,
            JSON.stringify(analysis)
          );
        }

        analysis.statisticsCsv = `Index,Lower Quartile,Upper Quartile,Minimum,Maximum,10th Percentile,90th Percentile,Standard Deviation,Median,Average,Variance\n`;

        appendStatistics(analysis, "NDVI");
        appendStatistics(analysis, "NDRE");
        appendStatistics(analysis, "NDMI");

        if (analysis) {
          setAnalysis(analysis);
        }
      } catch (e: any) {
        alert(e.message || e);
      }
    })();
  }, [weather, fields, activeField]);

  useEffect(() => {
    (async function () {
      if (!user && activeField) {
        const user = await db
          .collection("users")
          .doc(activeField.user_id)
          .get();

        setUser(user.data() as any);
      }
    })();
  }, [user, activeField]);

  console.log(user);

  const generateReport = useCallback(async () => {
    if (!analysis) return alert("No analysis available!");

    const field = activeField || fields[0];

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const crops = fields
      .map((field) =>
        field.crop && field.sowing_date?.seconds && field.growth_stage
          ? {
              name: field.crop,
              sowing_date: field.sowing_date?.seconds,
              growth_stage: field.growth_stage,
            }
          : null
      )
      .filter((crop) => crop);

    const user = await db.collection("users").doc(field.user_id).get();
    let lang: string = "en";
    if (user.exists && user.data()!.lang) {
      lang = user.data()!.lang.split(",")[0];
    } else {
      lang = prompt("Enter language (en, mr, hi, te, ta, kn): ") || "en";
    }

    var raw = JSON.stringify({
      crop_analysis: {
        crop_score: analysis!.analyses[0].indexInterpretation!.score,
        water_stress_score: analysis!.analyses[1].indexInterpretation!.score,
        nitrogen_score: analysis!.analyses[2].indexInterpretation!.score,
      },
      user: {
        name: field.user_name || prompt("Enter farmer's name: "),
        phone:
          field.phone_number ||
          (field as any).user_phone ||
          prompt("Enter farmer's phone: "),
        crops,
      },
      index_values: {
        NDVI: analysis!.values.indexes.NDVI.average,
        NDRE: analysis!.values.indexes.NDRE.average,
        NDMI: analysis!.values.indexes.NDMI.average,
      },
      historical_values: [],
      language: lang,
    });
    console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://greensat-report-generator-tp45dve65a-uc.a.run.app/cropAnalysisReport",
      requestOptions as any
    );
    const result = await response.blob();

    const blobUrl = URL.createObjectURL(result);

    const el = document.createElement("a");
    el.href = blobUrl;
    el.download = `${field.user_name}_${
      field.field_id
    }_${new Date().toDateString()}.pptx`;

    el.click();
  }, [activeField, fields, analysis]);

  type ForecastKey = keyof ForecastDay;

  type WeatherParamType<T extends ForecastKey> = ForecastDay[T];

  function getWeatherParam<T extends ForecastKey>(
    propertyName: T
  ): WeatherParamType<T> {
    const value = weather.forecasts[0].forecast[0][propertyName];
    return value;
  }

  return (
    <Container className="FieldOwnerDetailsContainer">
      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          title={showModal === "new_report" ? "New Report" : "Delete Field"}
        >
          {showModal === "new_report" ? (
            <NewReportModal
              userId={fields[0].user_id || activeField.user_id || ""}
              fieldId={activeField.field_id}
              onClose={() => setShowModal(false)}
            />
          ) : (
            <DeleteFieldModal
              onClose={() => setShowModal(false)}
              activeField={activeField}
            />
          )}
        </Modal>
      )}
      <div style={{ display: "flex", padding: "2.2rem" }}>
        <div
          onClick={() => {
            history.push("/");
          }}
          style={{ marginRight: "3rem" }}
        >
          <ArrowBackSharp
            style={{ color: "#8296AA", fontSize: "3rem", cursor: "pointer" }}
          />
        </div>
        <FieldOwnerName>{getData("user_name")}</FieldOwnerName>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2.2rem",
        }}
      >
        <FieldsCount>{fields.length}</FieldsCount>
        <div>
          <FieldOwnerPhone>{getData("phone_number")?.slice(3)}</FieldOwnerPhone>
          {fields.map((field, i) => (
            <FieldAveragePosition
              key={`FieldAveragePosition__${field.field_id}__${i}`}
            >
              Field {i + 1} - {field.position.split(",").join(", ")}
            </FieldAveragePosition>
          ))}
        </div>
      </div>
      <div
        style={{ width: "100%", textAlign: "center", marginBottom: "1.5rem" }}
      >
        <a
          href={encodeURI(
            `data:application/json;charset=utf8,${fieldsGeojson}`
          )}
          download={`${fields[0].user_name} Fields.json`}
          style={{
            color: "white",
            textDecoration: "underline",
            fontSize: "1.5rem",
            cursor: "pointer",
          }}
        >
          Download GeoJSON
        </a>
        <br />
        <button
          onClick={() => {
            shpwrite.download(JSON.parse(fieldsGeojson), {
              folder: `${fields[0].user_name} Fields`,
              types: {
                polygon: `${fields[0].user_name} Fields`,
              },
            });
          }}
          style={{
            color: "white",
            textDecoration: "underline",
            fontSize: "1.5rem",
            cursor: "pointer",
            background: "transparent",
            outline: "none",
            border: "none",
          }}
        >
          Download SHP
        </button>
        <br />
        <button
          onClick={() => {
            setShowModal("delete_field");
          }}
          style={{
            color: "white",
            textDecoration: "underline",
            fontSize: "1.5rem",
            cursor: "pointer",
            background: "transparent",
            outline: "none",
            border: "none",
          }}
        >
          Delete Selected Field
        </button>
        <br />
        <button
          onClick={generateReport}
          style={{
            color: "white",
            textDecoration: "underline",
            fontSize: "1.5rem",
            cursor: "pointer",
            background: "transparent",
            outline: "none",
            border: "none",
          }}
        >
          Generate Report
        </button>
      </div>
      <hr />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2.2rem",
          borderBottom: "2px solid #111316",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Text fontSize={17} fontWeight={600}>
            Survey Number
          </Text>
          <Text
            fontSize={16}
            fontWeight={400}
            style={{
              marginRight: "4rem",
            }}
          >
            {activeField?.survey_number || "NA"}
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Text fontSize={17} fontWeight={600}>
            Field Photos
          </Text>
          <Text
            fontSize={16}
            fontWeight={400}
            style={{
              marginRight: "4rem",
            }}
          >
            <a
              href={activeField?.field_image}
              style={{
                color: "white",
                textDecoration: "underline",
                fontSize: "1.5rem",
                cursor: activeField?.field_image ? "pointer" : "not-allowed",
              }}
            >
              Open
            </a>
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Text fontSize={17} fontWeight={600}>
            Crop
          </Text>
          <Text
            fontSize={16}
            fontWeight={400}
            style={{
              marginRight: "4rem",
            }}
          >
            {activeField?.crop || activeField?.crop_name || "NA"}
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Text fontSize={17} fontWeight={600}>
            Sowing Date
          </Text>
          <Text
            fontSize={16}
            fontWeight={400}
            style={{
              marginRight: "4rem",
            }}
          >
            {activeField?.sowing_date
              ? new Date(activeField.sowing_date.seconds * 1000).toDateString()
              : "NA"}
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Text fontSize={17} fontWeight={600}>
            Growth Stage
          </Text>
          <Text
            fontSize={16}
            fontWeight={400}
            style={{
              marginRight: "4rem",
            }}
          >
            {activeField?.growth_stage || "NA"}
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Text fontSize={17} fontWeight={600}>
            Field Marked On
          </Text>
          <Text
            fontSize={16}
            fontWeight={400}
            style={{
              marginRight: "4rem",
            }}
          >
            {activeField?.timestamp?.seconds
              ? new Date(activeField?.timestamp?.seconds * 1000).toISOString()
              : "NA"}
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Text fontSize={17} fontWeight={600}>
            Field Marked By
          </Text>
          <Text
            fontSize={16}
            fontWeight={400}
            style={{
              marginRight: "4rem",
            }}
          >
            {user?.created_by || user?.referral_code || "NA"}
          </Text>
        </div>
      </div>
      <hr />
      {!analysis ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "10rem 0",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "2.2rem",
              borderBottom: "2px solid #111316",
            }}
          >
            <div style={{ overflow: "auto" }}>
              <Text fontSize={17} fontWeight={600}>
                Crop Analysis
              </Text>
              <Text
                fontSize={16}
                fontWeight={400}
                style={{
                  marginRight: "4rem",
                }}
              >
                {analysis.analyses[0].analysis}
              </Text>
            </div>
            <Text fontSize={16} fontWeight={600}>
              {`${
                analysis.analyses[1].indexInterpretation?.improvement
                  ? "Soil Nitrogen and "
                  : ""
              }${
                analysis.analyses[2].indexInterpretation?.improvement
                  ? "Soil Moisture"
                  : ""
              } can be improved!`}
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              padding: "2.2rem",
              borderBottom: "2px solid #111316",
            }}
          >
            <WeatherDetail
              label="Soil Nitrogen"
              value={
                analysis.analyses[2].indexInterpretation?.improvement
                  ? "Can be improved"
                  : "Perfect"
              }
            />
            <WeatherDetail
              label="Soil Moisture"
              value={
                analysis.analyses[1].indexInterpretation?.improvement
                  ? "Can be improved"
                  : "Perfect"
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              padding: "2.2rem",
            }}
          >
            <WeatherDetail
              label="NDVI (average)"
              value={String(analysis.values.indexes.NDVI.average)}
            />
            <WeatherDetail
              label="NDRE (average)"
              value={String(analysis.values.indexes.NDRE.average)}
            />
            <WeatherDetail
              label="NDMI (average)"
              value={String(analysis.values.indexes.NDMI.average)}
            />
            <br />
            <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <a
                href={encodeURI(
                  `data:text/csv;charset=utf-8,${analysis.statisticsCsv}`
                )}
                download={`${
                  activeField?.user_name
                } Statistics Export ${new Date().toISOString()}.csv`}
              >
                <Text
                  fontSize={16}
                  fontWeight={600}
                  style={{ textDecoration: "underline" }}
                >
                  Download Statistics
                </Text>
              </a>
            </div>
          </div>
        </>
      )}
      {Object.entries(weather).length && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "2.2rem",
              borderBottom: "2px solid #111316",
            }}
          >
            <div style={{ overflow: "auto" }}>
              <Text fontSize={17} fontWeight={600}>
                Weather Today
              </Text>
              <Text
                fontSize={16}
                fontWeight={400}
                style={{
                  marginRight: "4rem",
                }}
              >
                {getWeatherParam("conditionsText")}
              </Text>
            </div>
            <Text fontSize={29} fontWeight={600}>
              {roundToAcc(
                (getWeatherParam("temperatures").min +
                  getWeatherParam("temperatures").max) /
                  2
              )}
              °
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              padding: "2.2rem",
              borderBottom: "2px solid #111316",
            }}
          >
            <WeatherDetail
              label="Wind"
              value={`${roundToAcc(getWeatherParam("wind").average)} m/s`}
            />
            <WeatherDetail
              label="Humidity"
              value={`${roundToAcc(
                getWeatherParam("relativeHumidity").average
              )} %`}
            />
            <WeatherDetail
              label="Clouds"
              value={`${roundToAcc(getWeatherParam("sky").cloudCover, 1)} %`}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              padding: "2.2rem",
            }}
          >
            <WeatherDetail
              label="Precipitation"
              value={`${roundToAcc(
                getWeatherParam("precipitation").chance
              )}% chance of ${roundToAcc(
                getWeatherParam("precipitation").amount,
                2
              )} mm`}
            />
          </div>
        </>
      )}
      <hr />
      <div style={{ padding: "2.2rem", marginBottom: "7rem" }}>
        <Text fontSize={17} fontWeight={600}>
          Previous Reports
        </Text>
        <Spacer psn="bottom" size={15} />
        <ScrollContainer
          style={{ display: "flex", overflow: "scroll" }}
          horizontal
        >
          {(activeField || fields[0]).reports?.map((report) => (
            <ReportThumbnail
              date={new Date(report.date.seconds * 1000)}
              thumbnailURL={report.thumbnail}
              reportURL={report.url}
            />
          ))}
        </ScrollContainer>
      </div>

      <div
        style={{
          padding: "2.2rem",
          paddingTop: "3rem",
          paddingBottom: "2rem",
          width: "20vw",
          position: "fixed",
          bottom: 0,
          left: "calc(60vw)",
          backgroundImage: `linear-gradient(
            to top,
            rgba(17, 19, 22, 0.5),
            rgba(17, 19, 22, 0)
          )`,
        }}
        className="AddReportContainer"
      >
        <PrimaryButton
          label="New Report"
          icon={
            <AddSharp
              style={{ color: "#fff", fontSize: "2.5rem", marginTop: "0.5rem" }}
            />
          }
          onClick={() => setShowModal("new_report")}
        />
      </div>
    </Container>
  );
};

export default FieldOwnerDetails;
