import React, { useCallback, useState } from "react";
import PrimaryButton from "../../../components/PrimaryButton";
import Spacer from "../../../components/Spacer";
import FieldModel from "../../../models/FieldModel";
import { db } from "../../../services/firebase/FirebaseService";

const DeleteFieldModal = ({
  activeField,
  onClose,
}: {
  activeField: FieldModel;
  onClose: () => void;
}) => {
  const [loading, setLoading] = useState(false);

  const deleteActiveField = useCallback(async () => {
    if (!activeField) return;

    setLoading(true);
    try {
      await db
        .collection("fields")
        .doc(activeField.user_id)
        .collection("fields")
        .doc(activeField.field_id)
        .delete();

      onClose();
    } catch (e: any) {
      alert(`There was an error: ${e.message}`);
    } finally {
      setLoading(false);
    }
  }, [activeField, onClose]);

  return (
    <>
      <p
        style={{
          fontSize: "1.5rem",
          color: "#fff",
          fontFamily: '"Be Vietnam", sans-serif',
        }}
      >
        Are you sure you want to delete this field?
      </p>
      <Spacer psn="bottom" size={30} />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <PrimaryButton
          label="Delete"
          onClick={deleteActiveField}
          loading={loading}
        />
      </div>
    </>
  );
};

export default DeleteFieldModal;
