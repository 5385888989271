import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ClearIcon from "@material-ui/icons/Clear";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@material-ui/core";

import Input from "../../../components/Input";
import FieldModel from "../../../models/FieldModel";
import Spacer from "../../../components/Spacer";
import { useHistory } from "react-router-dom";
import PrimaryButton from "../../../components/PrimaryButton";
import { CloudDownloadSharp, ReplaySharp } from "@material-ui/icons";
import { ListSubheader } from "@material-ui/core";
import Select from "../../../components/Select";
import { statePolygons, statePolygonsConfig } from "./StatePolygons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  width: 20vw;
  height: 100vh;
  background-color: #212930;
  padding: 2rem 0;
`;

const Title = styled.h3`
  font-family: "Be Vietnam";
  font-weight: 600;
  color: #fff;
  font-size: 2rem;
  text-align: center;
`;

interface FieldProps {
  farmerName: string;
  farmerPhone: `+91${string}`;
  fieldsCount: number;
  goToFieldView: () => void;
}

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1.7rem;
  border-top: 2px solid #111316;
  border-bottom: 2px solid #111316;
  cursor: pointer;
`;

const FarmerName = styled.h3`
  font-family: "Be Vietnam";
  font-size: 1.9rem;
  color: #fff;
  font-weight: 600;
`;

const FarmerPhone = styled(FarmerName)`
  font-size: 1.7rem;
  font-weight: 500;
`;

const FieldsCount = styled.span`
  background-color: #0677f9;
  border-radius: 2.5rem;
  padding: 1.5rem;
  color: #fff;
  font-family: "Be Vietnam";
  font-weight: 700;
  font-size: 3.2rem;
`;

const AddFieldContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2rem;
  width: 100%;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
`;

const Field = ({
  farmerName,
  fieldsCount,
  farmerPhone,
  goToFieldView,
}: FieldProps) => {
  return (
    <FieldContainer onClick={goToFieldView}>
      <FieldsCount>{fieldsCount}</FieldsCount>
      <div>
        <FarmerName>{farmerName}</FarmerName>
        <FarmerPhone>{farmerPhone}</FarmerPhone>
      </div>
    </FieldContainer>
  );
};

interface FieldMapping {
  [user_id: string]: FieldModel[];
}

interface FieldsListProps {
  fields: FieldMapping;
  userPhones: {
    [user_id: string]: `+91${string}`;
  };
  reloading: boolean;
  reloadFields: () => Promise<void>;
}

const StyledIconButton = styled(IconButton)`
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
`;

const checkInsidePolygon = (
  point: [number, number],
  vs: number[][] | number[][][][],
  config: { multi: boolean }
): boolean => {
  if (!vs) return true;

  let x = point[0],
    y = point[1];

  let inside = false;

  if (config.multi) {
    for (const [polygon] of vs) {
      inside =
        inside ||
        checkInsidePolygon(point, polygon as number[][], { multi: false });

      if (inside) return true;
    }
  } else {
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      let xi = vs[i][0] as number,
        yi = vs[i][1] as number;
      let xj = vs[j][0] as number,
        yj = vs[j][1] as number;

      let intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
  }

  return inside;
};

const FieldsList = ({
  fields,
  userPhones,
  reloading,
  reloadFields,
}: FieldsListProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedState, setSelectedState] = useState<
    keyof typeof statePolygons
  >(
    (localStorage.getItem("selectedState") as keyof typeof statePolygons) ||
      "Maharashtra"
  );
  const history = useHistory();
  const [filteredFields, setFilteredFields] = useState<FieldModel[][]>([]);

  useEffect(() => {
    const filteredFields: FieldModel[][] = [];
    for (const [userId, userFields] of Object.entries(fields)) {
      if (searchQuery) {
        if (userFields.length) {
          if (
            !userPhones[userId].includes(searchQuery) &&
            !userFields[0].user_name
              ?.toLowerCase()
              .includes(searchQuery.toLowerCase())
          )
            continue;
        } else if (!userPhones[userId].includes(searchQuery)) continue;
      }

      if (selectedState) {
        let oneInState = false;

        for (const field of userFields) {
          if (field.position) {
            let inside = checkInsidePolygon(
              [+field.position.split(",")[1], +field.position.split(",")[0]],
              statePolygons[selectedState],
              statePolygonsConfig[selectedState]
            );
            if (inside) {
              oneInState = true;
              break;
            }
          }
        }

        if (!oneInState) continue;
      }

      if (!userFields.length) continue;
      else filteredFields.push(userFields);

      setFilteredFields(filteredFields);
    }
  }, [fields, history, searchQuery, selectedState, userPhones]);

  console.log(
    JSON.stringify(
      filteredFields.map((fields) => ({
        user_id: fields[0].user_id,
        field_id: fields[0].field_id,
        task_id: Math.random().toString(36).split(".")[1],
      }))
    )
  );

  return (
    <Container>
      <Title style={{ margin: "0 1.7rem" }}>Users List</Title>
      <div>
        <div style={{ margin: "0 1.7rem" }}>
          <Input
            label="Search Field"
            onChange={({ target: { value } }) => setSearchQuery(value)}
            value={searchQuery}
            endAdornment={
              searchQuery ? (
                <>
                  <InputAdornment position="end">
                    <StyledIconButton
                      aria-label="toggle password visibility"
                      onClick={() => setSearchQuery("")}
                      color="secondary"
                    >
                      <ClearIcon
                        color="secondary"
                        style={{
                          width: "2rem",
                        }}
                        fontSize="large"
                      />
                    </StyledIconButton>
                  </InputAdornment>
                </>
              ) : null
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
            padding: "0 0.5rem",
          }}
        >
          <Select
            label="State"
            value={selectedState}
            onChange={(event) => {
              setSelectedState(event.target.value);
              localStorage.setItem("selectedState", event.target.value);
            }}
            options={[
              <ListSubheader
                style={{
                  color: "#8296AA",
                  fontFamily: "Be Vietnam",
                  fontSize: "1.4rem",
                  fontWeight: 500,
                }}
              >
                States
              </ListSubheader>,
              "Maharashtra",
              "Karnataka",
              "Andhra Pradesh",
            ]}
          />
          <div
            style={{
              width: "6rem",
              height: "3rem",
              marginLeft: "0.5rem",
            }}
          >
            <PrimaryButton
              label=""
              loading={reloading}
              icon={
                <ReplaySharp
                  style={{
                    color: "#fff",
                    fontSize: "2.5rem",
                    marginTop: "0.5rem",
                  }}
                />
              }
              onClick={reloadFields}
            />
          </div>
        </div>
        <br />
        <br />
        <h2
          style={{
            fontFamily: "'Be Vietnam', sans-serif",
            fontSize: "1.4rem",
            color: "#fff",
          }}
        >
          Total Users with Fields Marked: {filteredFields.length}
        </h2>
        <PrimaryButton
          label=""
          loading={reloading}
          icon={
            <CloudDownloadSharp
              style={{
                color: "#fff",
                fontSize: "2.5rem",
                marginTop: "0.5rem",
              }}
            />
          }
          onClick={() => {
            // let csvData = `Name,Phone Number,Number of Fields,Crops,Sowing Dates,Growth Stages,Field Photos\n`;
            let csvData = `Sr. No,Name,Mobile Number,Village,State,Fields Marked On,Representative Code,Crops,Sowing Dates,Field Areas (Acres)\n`;

            const userVillages =
              JSON.parse(localStorage.getItem("userVillages") || "{}") || {};

            filteredFields.forEach((field, i) => {
              csvData += `${i + 1},${
                field[0].user_name
              },${field[0].user_phone?.replace("+91", "")},${
                userVillages[field[0].user_id!] || "-"
              },${selectedState},${new Date(
                field[0].timestamp?.seconds * 1000
              )},${
                field[0].representative_code ??
                field.map((f) => f.representative_code).filter((r) => r)[0] ??
                "NA"
              },${field
                .map((field) => field.crop_name || field.crop)
                .join(";")},${field
                .map((field) =>
                  field.sowing_date?.seconds
                    ? new Date(field.sowing_date.seconds * 1000)
                    : field.sowing_date || "-"
                )
                .join(";")},${field
                .map((field) => field.area || "-")
                .join(";")}\n`;
            });

            // for (const field of filteredFields) {
            //   csvData += `${field[0].user_name},${field[0].user_phone?.replace(
            //     "+91",
            //     ""
            //   )},${field.length},${field
            //     .map((el) => el.crop || el.crop_name || "-")
            //     .join(";")},${field
            //     .map((el) =>
            //       el.sowing_date ? new Date(el.sowing_date.seconds * 1000) : "-"
            //     )
            //     .join(";")},${field
            //     .map((el) => el.growth_stage || "-")
            //     .join(";")},${field
            //     .map((el) => el.field_image || "-")
            //     .join(";")}\n`;
            // }

            const a = document.createElement("a");
            a.href = `data:text/csv;charset=utf-8,${csvData}`;
            a.download = "Fields Export.csv";
            a.target = "_blank";
            a.rel = "noopener";

            a.click();
          }}
        />
        <Spacer psn="bottom" size={29} />
        {Object.keys(fields).length ? (
          <div style={{ overflowY: "scroll", maxHeight: "80vh" }}>
            {filteredFields.map((fields) => (
              <Field
                farmerName={fields[0].user_name || ""}
                fieldsCount={fields.length}
                farmerPhone={userPhones[fields[0].user_id!] || ""}
                goToFieldView={() =>
                  history.push(`/fields/${fields[0].user_id}`)
                }
              />
            ))}
          </div>
        ) : (
          <div
            style={{
              flexGrow: 1,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <CircularProgress size={50} color="primary" />
          </div>
        )}
      </div>
      {/* <AddFieldContainer>
        <PrimaryButton
          label="Add Field"
          icon={
            <AddSharp
              style={{ color: "#fff", fontSize: "2.5rem", marginTop: "0.5rem" }}
            />
          }
        />
      </AddFieldContainer> */}
    </Container>
  );
};

export default FieldsList;
