import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

// Screen imports
import Login from "../views/login";
import Dashboard from "../views/dashboard";

// Sidebar import
import Sidebar from "../layout/Sidebar";
import FieldView from "../views/fieldview/FieldView";
import { auth } from "../services/firebase/FirebaseService";
import Signups from "../views/signups";

const WithSidebar = ({ children }: { children: React.ReactChild }) => {
  return (
    <div style={{ display: "flex" }}>
      {children}
      <Sidebar />
    </div>
  );
};

const Logout = () => {
  useEffect(() => {
    auth.signOut();
  }, []);

  return <></>;
};

const Routes = () => {
  return (
    <div style={{ display: "flex" }}>
      <Switch>
        <Route
          path="/fields/:fieldID"
          component={() => (
            <WithSidebar>
              <FieldView />
            </WithSidebar>
          )}
        />
        <Route
          path="/signups"
          component={() => (
            <WithSidebar>
              <Signups />
            </WithSidebar>
          )}
        />
        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />
        <Route
          path="/"
          component={() => (
            <WithSidebar>
              <Dashboard />
            </WithSidebar>
          )}
        />
      </Switch>
    </div>
  );
};

export default Routes;
