import React, { ReactChild, useState } from "react";
import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  withStyles,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import styled from "styled-components";

import Spacer from "../Spacer";

interface InputProps {
  label: string;
  onChange?: (event: { target: { value: string } }) => void;
  value?: string;
  type?: "text" | "email" | "password" | "date" | "tel";
  error?: boolean;
  errorText?: string;
  endAdornment?: ReactChild | ReactChild[] | null;
  startAdornment?: ReactChild | ReactChild[] | null;
  focused?: boolean;
  ref?: any;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const TextInput = withStyles({
  root: {
    backgroundColor: "#080A0B",
    color: "red",
  },
})(TextField);

const useStyles = makeStyles(() => ({
  root: {
    // paddingLeft: "0.75rem",
    padding: "0.25rem 0.75rem",
  },
  label: {
    color: "#fff",
    opacity: 0.64,
    fontFamily: "Be Vietnam",
    fontSize: "1.5rem",
    marginLeft: "0.75rem",
  },
  multilineColor: {
    color: "#fff",
    fontFamily: "Be Vietnam",
    fontSize: "1.5rem",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  helperText: {
    marginBottom: "0.75rem",
    paddingLeft: "0.75rem",
    marginTop: "-0.25rem",
    fontSize: "1.1rem",
  },
}));

const Input = ({
  label,
  onChange,
  value,
  type = "text",
  error = false,
  errorText,
  endAdornment,
  startAdornment,
  focused = false,
  ref,
}: InputProps) => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Container>
      {/* <Label>{label}</Label> */}
      <Spacer psn="bottom" size={18} />
      <TextInput
        label={label}
        {...(ref ? { inputRef: ref } : {})}
        autoFocus={focused}
        variant="filled"
        style={{
          width: "100%",
          borderRadius: "1.2rem",
        }}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        InputProps={{
          classes: {
            input: classes.multilineColor,
            underline: classes.underline,
            root: classes.root,
          },
          endAdornment: endAdornment ? (
            endAdornment
          ) : type === "password" ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword((curVal) => !curVal);
                }}
                color="secondary"
              >
                <Visibility
                  color="secondary"
                  style={{
                    width: "2rem",
                  }}
                  fontSize="large"
                />
              </IconButton>
            </InputAdornment>
          ) : null,
          startAdornment: startAdornment || null,
        }}
        FormHelperTextProps={{
          classes: {
            root: classes.helperText,
          },
        }}
        color="primary"
        onChange={onChange}
        type={showPassword ? "text" : type}
        error={error}
        helperText={errorText}
        {...(value === null || value === undefined ? {} : { value })}
      />
    </Container>
  );
};

export default Input;
