import React, { useState } from "react";
import { compose, withProps } from "recompose";
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Polygon,
  Marker,
} from "react-google-maps";
import { SearchSharp } from "@material-ui/icons";

import Input from "../../../components/Input";
import styled from "styled-components";
import FieldModel from "../../../models/FieldModel";
import { useHistory } from "react-router-dom";
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";

const InputContainer = styled.div`
  position: absolute;
  top: 2rem;
  left: 2rem;
  width: 25%;
`;

interface MapViewProps {
  fields: FieldModel[];
  onClick?: (field: FieldModel) => void;
  onAddField?: (field: any) => void;
  activeField: FieldModel;
}

// @BUG switch to Mapbox
const MapView = ({
  fields,
  onClick,
  onAddField,
  activeField,
}: MapViewProps) => {
  const ComposedMapView = compose(
    withProps({
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyD75_eFwFtsAQNU8OhPQoG1I1cOeyJazuo",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: "100vh", width: "60vw" }} />,
      mapElement: <div style={{ height: `100%` }} />,
      options: (map: any) => ({ mapTypeId: map.MapTypeId.SATELLITE }),
    }),
    withScriptjs,
    withGoogleMap
  )((props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const history = useHistory();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [defaultCenter, setDefaultCenter] = useState({
      lng: Number((activeField || fields[0])?.position?.split(",")[1] || "72"),
      lat: Number((activeField || fields[0])?.position?.split(",")[0] || "19"),
    });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [defaultZoom, setDefaultZoom] = useState(16);

    const handleInputChange = ({
      target: { value },
    }: {
      target: { value: string };
    }) => {
      const parts = value.split(",").map((el: string) => el.trim());
      const allNumbers = parts.map((el) => Number(el));
      if (parts.length > 1 && !allNumbers.includes(NaN)) {
        setDefaultCenter({
          lat: Number(parts[0]),
          lng: Number(parts[1]),
        });
      }
    };

    // alert(fields.length);

    return (
      <GoogleMap
        zoom={defaultZoom}
        mapTypeId="satellite"
        center={defaultCenter}
        // defaultCenter={defaultCenter}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
        }}
      >
        {fields.map((field) => {
          let coords: {
            lat: string | number;
            lng: string | number;
          }[] = [];
          if (field.geojson) {
            const geojson = JSON.parse(field.geojson);
            for (const [lng, lat] of geojson.geometry.coordinates[0]) {
              coords.push({ lat, lng });
            }
          } else {
            Array.from({ length: 4 }, (_, i) => i + 1).forEach((pointNo) => {
              if (`point${pointNo}` in field) {
                coords.push({
                  lat: field[`point${pointNo}`].split(",")[0],
                  lng: field[`point${pointNo}`].split(",")[1],
                });
              }
            });
          }
          coords = coords.map((point) => ({
            lat: Number(point.lat),
            lng: Number(point.lng),
          }));
          return (
            <>
              <Polygon
                paths={coords}
                visible={true}
                options={{
                  strokeColor: "#0677F9",
                  fillColor:
                    field.field_id === activeField?.field_id
                      ? "#0677F9"
                      : "rgba(6, 119, 249, 0.1)",
                }}
                onClick={() => {
                  if (onClick) onClick(field);
                  else history.push(`/fields/${field.user_id}`);

                  setDefaultCenter({
                    lat: Number(field.position.split(",")[0]),
                    lng: Number(field.position.split(",")[1]),
                  });
                }}
              />
              <Marker
                position={{
                  lat: Number(field.position.split(",")[0]),
                  lng: Number(field.position.split(",")[1]),
                }}
                title={field.user_name}
                label={{
                  text: field.user_name,
                  fontFamily: "Be Vietnam",
                  fontWeight: "700",
                  color: "#fff",
                  fontSize: "1.6rem",
                }}
                onClick={() => {
                  setDefaultCenter({
                    lat: Number(field.position.split(",")[0]),
                    lng: Number(field.position.split(",")[1]),
                  });
                  setDefaultZoom(16);
                }}
              />
            </>
          );
        })}
        <Marker
          position={{
            lat: defaultCenter.lat,
            lng: defaultCenter.lng,
          }}
          title={`${defaultCenter.lat}, ${defaultCenter.lng}`}
          label={{
            text: `${defaultCenter.lat}, ${defaultCenter.lng}`,
            fontFamily: "Be Vietnam",
            fontWeight: "700",
            color: "#fff",
            fontSize: "1.6rem",
          }}
          onClick={() => {
            setDefaultCenter({
              lat: defaultCenter.lat,
              lng: defaultCenter.lng,
            });
            setDefaultZoom(16);
          }}
        />
        <InputContainer>
          <Input
            label="Enter Coordinates"
            onChange={handleInputChange}
            endAdornment={
              <SearchSharp
                color="secondary"
                style={{
                  fontSize: "2.1rem",
                  marginRight: "1.5rem",
                }}
              />
            }
          />
        </InputContainer>
        <DrawingManager
          defaultDrawingMode="polygon"
          defaultOptions={{
            drawingControl: true,
            drawingControlOptions: {
              position: 3, // Top-center
              drawingModes: ["polygon"],
            },
          }}
          onPolygonComplete={onAddField}
        />
      </GoogleMap>
    );
  });

  return <ComposedMapView />;
};

interface MapProps {
  fields: FieldModel[];
  onClick?: (field: FieldModel) => void;
  onAddField?: (field: any) => void;
  activeField: FieldModel | null;
}

const Map = ({ fields, onClick, onAddField, activeField }: MapProps) => {
  return (
    <MapView
      fields={fields}
      onClick={onClick}
      onAddField={onAddField}
      activeField={activeField!}
    />
  );
};

export default Map;
