import React, { useCallback, useEffect, useState } from "react";

import { db, functions } from "../../services/firebase/FirebaseService";
import FieldModel from "../../models/FieldModel";
import FieldsList from "./components/FieldsList";
import Map from "./components/Map";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Spacer from "../../components/Spacer";
import PrimaryButton from "../../components/PrimaryButton";
import { getUser } from "../../services/auth/AuthService";

interface FieldMapping {
  [user_id: string]: FieldModel[];
}

const NewFieldModal = ({
  setShowModal,
  points,
  newPolygon,
}: {
  setShowModal: (show: boolean) => void;
  points: string[];
  newPolygon: string;
}) => {
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [surveyNumber, setSurveyNumber] = useState("");
  const [crop, setCrop] = useState("");
  const [growthStage, setGrowthStage] = useState("");
  const [sowingDate, setSowingDate] = useState("");
  const [errors, setErrors] = useState({
    phone: "",
    surveyNumber: "",
    crop: "",
    growthStage: "",
    sowingDate: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (setFn: (value: string) => void) => {
    return (event: { target: { value: string } }) => {
      setFn(event.target.value);
    };
  };

  const handleSubmit = useCallback(async () => {
    const newErrors: typeof errors = { ...errors };
    for (const type in newErrors)
      newErrors[type as keyof typeof newErrors] = "";

    let isValid = true;

    if (!phone) {
      newErrors.phone = "Please enter a phone number!";
      isValid = false;
    } else if (!phone.match(/\d{10}/)) {
      newErrors.phone = "Please enter a valid phone number!";
      isValid = false;
    }

    // if (!surveyNumber) {
    //   newErrors.surveyNumber = "Please enter a survey number!";
    //   isValid = false;
    // }

    // if (!crop) {
    //   newErrors.crop = "Please enter a crop!";
    //   isValid = false;
    // }

    // if (!growthStage) {
    //   newErrors.growthStage = "Please enter a growth stage!";
    //   isValid = false;
    // }

    // if (!sowingDate) {
    //   newErrors.sowingDate = "Please enter a sowing date!";
    //   isValid = false;
    // } else if (!new Date(sowingDate)) {
    //   newErrors.sowingDate = "Please enter a valid sowing date!";
    //   isValid = false;
    // }

    setErrors(newErrors);

    if (!isValid) return;

    setLoading(true);

    const formatPoint = (point: string) => {
      const [lat, lng] = point.split(",");

      return [Number(lng), Number(lat)];
    };

    try {
      const result = await functions.httpsCallable("createField")({
        field: Math.random().toString(36),
        point1: points[0],
        point2: points[1],
        point3: points[2],
        point4: points[3],
        position: points[0],
        crop: crop,
        sowing_date: new Date(sowingDate),
        survey_number: surveyNumber,
        growth_stage: growthStage,
        phone: phone.startsWith("+91") ? phone : `+91${phone}`,
        withPhone: true,
        user_name: name,
        rep_id: (await getUser())?.uid || "NA",
        // geojson: {
        //   type: "Feature",
        //   properties: {},
        //   geometry: {
        //     type: "Polygon",
        //     coordinates: [
        //       [
        //         formatPoint(points[0]),
        //         formatPoint(points[1]),
        //         formatPoint(points[2]),
        //         formatPoint(points[3]),
        //         formatPoint(points[0]),
        //       ],
        //     ],
        //   },
        // },
        geojson: JSON.parse(newPolygon),
      });
      if (result.data.error) {
        throw new Error("There was an error: " + result.data.error);
      }

      setShowModal(false);
    } catch (e: any) {
      alert(`There was an error: ${e.message}`);
    } finally {
      setLoading(false);
    }
  }, [
    crop,
    errors,
    growthStage,
    name,
    newPolygon,
    phone,
    points,
    setShowModal,
    sowingDate,
    surveyNumber,
  ]);

  return (
    <Modal title="Add Field" onClose={() => setShowModal(false)}>
      <Input
        type="tel"
        label="Phone Number"
        value={phone}
        onChange={handleChange(setPhone)}
        error={!!errors.phone}
        errorText={errors.phone}
      />
      <Input
        type="text"
        label="Name"
        value={name}
        onChange={handleChange(setName)}
        error={!!errors.phone}
        errorText={errors.phone}
      />
      <Input
        type="text"
        label="Survey Number"
        value={surveyNumber}
        onChange={handleChange(setSurveyNumber)}
        error={!!errors.surveyNumber}
        errorText={errors.surveyNumber}
      />
      <Input
        type="text"
        label="Crop"
        value={crop}
        onChange={handleChange(setCrop)}
        error={!!errors.crop}
        errorText={errors.crop}
      />
      <Input
        type="text"
        label="Growth Stage"
        value={growthStage}
        onChange={handleChange(setGrowthStage)}
        error={!!errors.growthStage}
        errorText={errors.growthStage}
      />
      <Input
        type="date"
        label="Sowing Date"
        value={sowingDate}
        onChange={handleChange(setSowingDate)}
        error={!!errors.sowingDate}
        errorText={errors.sowingDate}
      />
      <Spacer psn="bottom" size={20} />
      <PrimaryButton label="Add" onClick={handleSubmit} loading={loading} />
      <Spacer psn="bottom" size={10} />
    </Modal>
  );
};

const Dashboard = () => {
  const [fields, setFields] = useState<FieldMapping>(
    JSON.parse(localStorage.getItem("fields") || "{}")
  );
  const [userPhones, setUserPhones] = useState<{
    [user_id: string]: `+91${string}`;
  }>(JSON.parse(localStorage.getItem("userPhones") || "{}"));

  const [userVillages, setUserVillages] = useState<{
    [user_id: string]: string;
  }>(JSON.parse(localStorage.getItem("userVillages") || "{}"));
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [newPolygonPoints, setNewPolygonPoints] = useState<string[]>([]);
  const [newPolygon, setNewPolygon] = useState("");

  useEffect(() => {
    localStorage.setItem("fields", JSON.stringify(fields));
  }, [fields]);

  useEffect(() => {
    if (Object.entries(userPhones).length) {
      localStorage.setItem("userPhones", JSON.stringify(userPhones));
    }
  }, [userPhones]);

  useEffect(() => {
    console.log(userVillages);
    if (Object.entries(userVillages).length) {
      localStorage.setItem("userVillages", JSON.stringify(userVillages));
    }
  }, [userVillages]);

  const reloadFields = useCallback(async () => {
    setLoading(true);
    const fieldsRef = db.collectionGroup("fields");
    const fields = await fieldsRef.get();
    const fieldAllocation: any = {};
    const userPhones: { [user_id: string]: `+91${string}` } = {};
    const userVillages: { [user_id: string]: string } = {};
    fields.docs.forEach((fieldDoc) => {
      const field = fieldDoc.data() as FieldModel;
      if (!field.position && !field.point1 && field.user_id) {
        fieldAllocation[field.user_id] = [];
        if (field.phone_number) {
          userPhones[field.user_id] = field.phone_number as `+91${string}`;
        }
        if (field.village_id) {
          userVillages[field.user_id] = field.village_id;
        }
      } else {
        if (field.user_id && field.user_id in fieldAllocation) {
          fieldAllocation[field.user_id].push(field);
        }
      }
    });

    setFields(fieldAllocation);
    setUserPhones(userPhones);
    setUserVillages(userVillages);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!Object.entries(fields).length) {
      reloadFields();
    }
  }, [fields, reloadFields]);

  return (
    <div
      style={{
        display: "flex",
        borderRight: "1px solid #fff",
        position: "relative",
      }}
    >
      {showModal && (
        <NewFieldModal
          setShowModal={setShowModal}
          points={newPolygonPoints}
          newPolygon={newPolygon}
        />
      )}

      <Map
        fields={(() => {
          const fieldsList = [];
          for (const [, userFields] of Object.entries(fields)) {
            fieldsList.push(...userFields);
          }
          return fieldsList;
        })()}
        onAddField={(field: any) => {
          const paths = field.getPaths().getAt(0);
          const length = paths.getLength();

          const getCoords = (number: number) => {
            console.log(paths.getAt(number));

            const coords = paths.getAt(number);

            return `${coords.lat()},${coords.lng()}`;
          };

          const points = [
            getCoords(0),
            getCoords(length - 3),
            getCoords(length - 2),
            getCoords(length - 1),
          ];
          console.log(points);

          const geojsonPoints: number[][] = [];
          paths.forEach((coords: any) => {
            geojsonPoints.push([coords.lng(), coords.lat()]);
          });
          geojsonPoints.push(geojsonPoints[0]);

          setShowModal(true);
          setNewPolygonPoints(points);
          setNewPolygon(
            JSON.stringify({
              type: "Feature",
              properties: {},
              geometry: {
                type: "Polygon",
                coordinates: [geojsonPoints],
              },
            })
          );
        }}
        activeField={null}
      />
      <FieldsList
        userPhones={userPhones}
        fields={fields}
        reloading={loading}
        reloadFields={reloadFields}
      />
    </div>
  );
};

export default Dashboard;
