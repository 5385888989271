import firebase from "../firebase/FirebaseService";
import React, { createContext, useEffect, useRef, useState } from "react";
import {
  getAuthToken,
  getUser,
  listenForAuthChange,
  signOut,
} from "./AuthService";
import { useHistory, useLocation } from "react-router-dom";

interface AuthProviderProps {
  children: React.ReactChild | React.ReactChild[];
}

interface AuthContextModel {
  user: firebase.User | null;
  token: string | null;
  signOut: () => void;
}

type User = firebase.User;

const AuthContext = createContext<null | AuthContextModel>(null);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [currentUser, setCurrentUser] = useState<null | User>(null);
  const [userLoaded, setUserLoaded] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  let firstLoad = useRef(true);
  let userPresent = false;
  let cookies: string | string[] = document.cookie.split(";");
  cookies = cookies.filter(
    (cookie) => cookie.split("=")[0].trim() === "user_present"
  );
  if (cookies.length) {
    userPresent = JSON.parse(cookies[0].split("=")[1]);
  }

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    (async function () {
      const user = await getUser();
      const token = await getAuthToken();
      setCurrentUser(user);
      setUserLoaded(true);
      setToken(token);
    })();

    listenForAuthChange((user) => {
      setCurrentUser(user);
      setUserLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (userLoaded && firstLoad.current) {
      sessionStorage.setItem("user_present", JSON.stringify(!!currentUser));
      document.cookie = `user_present=${!!currentUser}`;

      let userPresentNew = userPresent;
      let cookies: string | string[] = document.cookie.split(";");
      cookies = cookies.filter(
        (cookie) => cookie.split("=")[0].trim() === "user_present"
      );
      if (cookies.length) {
        userPresentNew = JSON.parse(cookies[0].split("=")[1]);
      }

      if ((currentUser || userPresentNew) && location.pathname === "/login") {
        history.push("/");
      } else if (
        !currentUser &&
        !userPresentNew &&
        location.pathname !== "/login"
      ) {
        history.push("/login");
      }
      firstLoad.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoaded]);

  useEffect(() => {
    if (userLoaded) {
      sessionStorage.setItem("user_present", JSON.stringify(!!currentUser));
      document.cookie = `user_present=${!!currentUser}`;

      let userPresentNew = userPresent;
      let cookies: string | string[] = document.cookie.split(";");
      cookies = cookies.filter(
        (cookie) => cookie.split("=")[0].trim() === "user_present"
      );
      if (cookies.length) {
        userPresentNew = JSON.parse(cookies[0].split("=")[1]);
      }

      if ((currentUser || userPresentNew) && location.pathname === "/login") {
        history.push("/");
      } else if (
        !currentUser &&
        !userPresentNew &&
        location.pathname !== "/login"
      ) {
        history.push("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, history, location]);

  return (
    <AuthContext.Provider
      value={{
        user: currentUser,
        signOut,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext };
export default AuthProvider;
