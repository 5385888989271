import firebase from "firebase";

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyDRU0Q-Ii-ITRtI9jGkyR2D_N5foMpEoFA",
    authDomain: "greensat-9087a.firebaseapp.com",
    databaseURL: "https://greensat-9087a.firebaseio.com",
    projectId: "greensat-9087a",
    storageBucket: "greensat-9087a.appspot.com",
    // messagingSenderId: "905326805668",
    appId: "1:905326805668:web:bd8f2f3032d4a8727adc31",
    measurementId: "G-B7E5MZ33VK",
  });
}

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.functions();
export default firebase;
