/*
  EOS COLORMAPS - 
    NDMI - 7060d24161c3cd2e9d552292c2085e81
    NDVI - 2b0040e4100279573a41138c8a30c1f2
    NDRE - edbc410e82ae06c3e9b6104b46e04cd7
    RECI - 782cf79d12fc75427aff25daf35d6fb3
*/

import React, { useContext, useEffect, useState } from "react";
import {
  ChevronLeftSharp,
  ChevronRightSharp,
  CloudSharp,
} from "@material-ui/icons";
import styled from "styled-components";
import { CircularProgress, Tooltip } from "@material-ui/core";
import ScrollContainer from "react-indiana-drag-scroll";

import "./DateSelector.css";
import SatelliteViewModel from "../../../models/SatelliteViewModel";
import { functions } from "../../../services/firebase/FirebaseService";
import { AuthContext } from "../../../services/auth/AuthProvider";
import FieldModel from "../../../models/FieldModel";

const Container = styled.div`
  display: flex;

  background-color: #111316;
  width: 100%;
  padding: 0 2.6rem;
  cursor: grab;
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 60vw;
`;

const NavigationArrowStyle = {
  color: "rgba(130,150,170,0.82)",
  fontSize: "2.8rem",
  margin: "1.6rem 0",
};

const DateContainer = styled.div`
  padding: 5px 2rem;
  color: ${({ active }: { active: boolean }) => (active ? "#2F8FFC" : "#fff")};
  font-family: "Be Vietnam";
  font-size: 1.3rem;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 21rem;
  height: 100%;

  cursor: pointer;

  background-color: ${({ active }: { active: boolean }) =>
    active ? "rgba(43,143,252,0.25)" : "transparent"};

  transition: background-color 0.3s;

  &:hover {
    ${({ active }: { active: boolean }) =>
      !active ? "background-color: rgba(64, 64, 64, 0.44);" : ""}
  }

  & h3 {
    font-weight: 500;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const getSatelliteImage = (
  view_id: string,
  geometry: [
    [
      [number, number],
      [number, number],
      [number, number],
      [number, number],
      [number, number]
    ]
  ],
  index: "NDVI" | "NDRE" | "RECI" | "NDMI" | "MSAVI" = "NDVI",
  token: string | null
) => {
  // alert("GETTING SATELLITE IMAGE!");
  if (typeof token !== "string" || token === "") {
    console.log("!token");
  }
  const requestHeaders = new Headers();
  requestHeaders.append("Content-Type", "application/json");
  const rawRequest = JSON.stringify({
    token,
    viewId: view_id,
    coords: geometry,
    index,
    zoom: 16,
  });
  console.log({
    token,
    viewId: view_id,
    coords: geometry,
    index,
  });

  return new Promise((res, rej) => {
    res(null);
    // fetch(
    //   "https://us-central1-greensat-9087a.cloudfunctions.net/getSatelliteTile",
    //   {
    //     method: "POST",
    //     headers: requestHeaders,
    //     body: rawRequest,
    //     redirect: "follow",
    //   }
    // )
    //   .then((response) => {
    //     if (response.ok) {
    //       return response.blob();
    //     } else {
    //       rej("There was an unexpected error!");
    //     }
    //   })
    //   .then((result) => {
    //     if (result) {
    //       const blobURL = URL.createObjectURL(result);
    //       window.open(blobURL);
    //       localStorage.setItem(`${view_id}__${index}`, blobURL);
    //       res(blobURL);
    //     }
    //   });
  });
};

const DateCard = ({
  date,
  cloud,
  active = false,
  index,
  onClick,
}: {
  date: Date;
  cloud?: boolean;
  active?: boolean;
  index: number;
  onClick?: (index: number) => void;
}) => {
  if (cloud) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 2rem",
        }}
      >
        <Tooltip
          title={`${date
            .toLocaleDateString("en-IN", {
              month: "long",
              day: "numeric",
            })
            .split(" ")
            .reverse()
            .join(
              " "
            )}, a satellite image was made on this date but due to a high cloud level, vegetation indices could not be calculated.`}
          style={{
            color: "red",
            fontFamily: "Be Vietnam",
            fontSize: "15rem",
          }}
          classes={{
            tooltip: "tooltip--transform",
            arrow: "tooltip__arrow",
          }}
          placement="top"
          arrow
        >
          <CloudSharp
            style={{ color: "#fff", fontSize: "2rem", cursor: "default" }}
          />
        </Tooltip>
      </div>
    );
  }
  const dateParts = date
    .toLocaleDateString("en-IN", {
      month: "long",
      day: "numeric",
      year: "numeric",
    })
    .split(" ");

  return (
    // <Tooltip
    //   title={<img src="/img/dummyNDVIImage.png" alt="Dummy NDVI" />}
    //   style={{
    //     transform: "translateY(2rem) !important",
    //   }}
    //   classes={{
    //     tooltip: "tooltip",
    //     arrow: "tooltip__arrow",
    //   }}
    //   placement="top"
    //   arrow
    // >
    <DateContainer active={active}>
      <svg
        style={{
          width: "1.75rem",
          height: "1.75rem",
          fill: active ? "#2F8FFC" : "#fff",
          marginRight: "1.1rem",
        }}
      >
        <use xlinkHref="/img/satellite_icon.svg#icon" />
      </svg>
      <h3>{`${dateParts[1]} ${dateParts[0]}, ${dateParts[2]}`}</h3>
    </DateContainer>
    // </Tooltip>
  );
};

interface DateSelectorProps {
  fields: FieldModel[];
  activeField: FieldModel | null;
  searchResults: SatelliteViewModel[];
  updateSatelliteImage: (satelliteImage: string) => void;
  index: "NDVI" | "NDMI" | "RECI" | "NDRE" | "MSAVI";
}

const DateSelector = ({
  fields,
  activeField,
  searchResults,
  updateSatelliteImage,
  index,
}: DateSelectorProps) => {
  const [activeDate, setActiveDate] = useState<SatelliteViewModel | null>(null);
  const [satelliteImages, setSatelliteImages] = useState<{
    [view_id: string]: `blob:${"http" | "https"}://${string}`;
  }>({});

  const getPoint = (point: "1" | "2" | "3" | "4") =>
    activeField?.[`point${point}`]
      .split(",")
      .map((el: string) => Number(el))
      .reverse() as [number, number];

  const authContext = useContext(AuthContext);
  let token: string | null;
  if (authContext) token = authContext.token;

  const handleGetImage = async (view_id: string) => {
    const satelliteImageURL = await getSatelliteImage(
      view_id,
      [
        [
          getPoint("1"),
          getPoint("2"),
          getPoint("3"),
          getPoint("4"),
          getPoint("1"),
        ],
      ],
      index,
      token
    );
    updateSatelliteImage(satelliteImageURL as string);
    setSatelliteImages((currentSatelliteImages = {}) => ({
      ...currentSatelliteImages,
      [view_id]: satelliteImageURL as `blob:${"http" | "https"}://${string}`,
    }));
  };

  const handleDateClick = (index: number, view_id: string) => {
    setActiveDate(searchResults[index]);
    handleGetImage(view_id);
  };

  useEffect(() => {
    if (searchResults.length) {
      setActiveDate(searchResults[0]);
      handleGetImage(searchResults[0].view_id);
    }
  }, [searchResults]);

  useEffect(() => {
    localStorage.setItem("satelliteImages", JSON.stringify(satelliteImages));
  }, [satelliteImages]);

  return (
    <Container>
      <div
        style={{
          borderRight: "2px solid rgba(130,150,170,0.59)",
          paddingRight: "1rem",
        }}
      >
        <ChevronLeftSharp style={NavigationArrowStyle} />
      </div>
      {/* <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row-reverse",
          overflow: "scroll",
        }}
      > */}
      <ScrollContainer
        className="scroll-container"
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row-reverse",
          // overflow: "scroll",
        }}
        hideScrollbars={false}
        horizontal
      >
        {(function () {
          return searchResults.map((dateView, i) => {
            return (
              <DateCard
                date={new Date(dateView.date)}
                active={dateView.view_id === activeDate?.view_id}
                cloud={dateView.cloudCoverage > 15}
                key={`${dateView.view_id}__${i}`}
                index={i}
                onClick={() => handleDateClick(i, dateView.view_id)}
              />
            );
          });
        })()}
        {!searchResults.length && (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
        )}
      </ScrollContainer>
      {/* </div> */}
      <div
        style={{
          borderLeft: "2px solid rgba(130,150,170,0.59)",
          paddingLeft: "1rem",
        }}
      >
        <ChevronRightSharp style={NavigationArrowStyle} />
      </div>
    </Container>
  );
};

export default DateSelector;
