import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { db } from "../../services/firebase/FirebaseService";

const Signups = () => {
  const [signups, setSignups] = useState<
    {
      name: string;
      phone_number: string;
      created_by: string;
      referral_code: string;
    }[]
  >([]);
  const [representativeSignups, setRepresentativeSignups] = useState<
    {
      code: string;
      signups: number;
    }[]
  >([]);
  const [allTimeStatistics, setAllTimeStatistics] = useState<
    { code: string; signups: number }[]
  >([]);
  const [selectedRep, setSelectedRep] = useState<string | null>(null);

  useEffect(() => {
    (async function () {
      const date = new Date();
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);

      const snap = await db
        .collection("users")
        .where("timestamp", ">=", date)
        .get();

      const users = snap.docs.map((user) => user.data()) as typeof signups;

      setSignups(users);

      const representativeSignupsMap = users.reduce(
        (acc: any, b) => ({
          ...acc,
          [b.created_by ?? b.referral_code]: [
            ...(acc[b.created_by ?? b.referral_code] || []),
            b,
          ],
        }),
        {}
      );

      const representativeSignups = Object.entries(
        representativeSignupsMap
      ).map(([code, signups]) => {
        return {
          code,
          signups: (signups as any).length,
        };
      });

      setRepresentativeSignups(representativeSignups as any);
    })();
  }, []);

  const getAllTimeStatistics = useCallback(async () => {
    alert("Getting statistics!");

    const snap = await db
      .collection("users")
      .where("created_by", "!=", "")
      .get();

    const users = snap.docs.map((doc: any) => doc.data());

    const allTimeStatisticsMap = users.reduce(
      (acc: any, user: any) => ({
        ...acc,
        [user.created_by ?? user.referral_code]:
          (acc[user.created_by ?? user.referral_code] ?? 0) + 1,
      }),
      {}
    );

    const allTimeStatistics = Object.entries(allTimeStatisticsMap).map(
      ([code, signups]) => ({ code, signups: signups as number })
    );

    setAllTimeStatistics(allTimeStatistics);
  }, [setAllTimeStatistics]);

  return (
    <div style={{ padding: "2rem" }}>
      <h1 style={{ fontSize: "3rem" }}>Today's Signups</h1>
      <div style={{ display: "flex" }}>
        <Table>
          <tr>
            <th>Sr. No.</th>
            <th>Representative Code</th>
            <th>Number of Signups Today</th>
          </tr>
          {representativeSignups.map((rep, i) => (
            <tr>
              <td>{i + 1}</td>
              <td>{rep.code}</td>
              <td>{rep.signups}</td>
            </tr>
          ))}
        </Table>
        <a
          style={{ fontSize: "1.5rem" }}
          href={
            `data:text/csv;charset=utf-8,Sr. No, Representative Code, Number of Signups Today\n` +
            representativeSignups
              .map((rep, i) => `${i + 1},${rep.code},${rep.signups}`)
              .join("\n")
          }
          download="SignupsByCode.csv"
        >
          Download CSV
        </a>
      </div>
      <br />
      <br />
      <select
        onChange={(e) => {
          setSelectedRep(e.target.value);
        }}
      >
        {representativeSignups.map((rep) => (
          <option value={rep.code}>{rep.code}</option>
        ))}
      </select>
      <div style={{ display: "flex" }}>
        <Table>
          <tr>
            <th>Sr. No.</th>
            <th>Name</th>
            <th>Mobile No</th>
            <th>Representative Code</th>
          </tr>
          {(selectedRep
            ? signups.filter(
                (signup) =>
                  (signup.created_by || signup.referral_code) === selectedRep
              )
            : signups
          ).map((signup, i) => (
            <tr>
              <td>{i + 1}</td>
              <td>{signup.name}</td>
              <td>{signup.phone_number}</td>
              <td>{signup.created_by || signup.referral_code}</td>
            </tr>
          ))}
        </Table>
        <a
          style={{ fontSize: "1.5rem" }}
          href={
            `data:text/csv;charset=utf-8,Sr. No, Name, Phone Number, Representative Code\n` +
            signups
              .map(
                (user, i) =>
                  `${i + 1},${user.name},${user.phone_number.replace(
                    "+91",
                    ""
                  )},${user.created_by || user.referral_code}`
              )
              .join("\n")
          }
          download="SignupsToday.csv"
        >
          Download CSV
        </a>
      </div>
      <h1 style={{ fontSize: "3rem" }}>All Time Statistics</h1>
      {allTimeStatistics?.length ? (
        <Table>
          <tr>
            <th>Sr. No.</th>
            <th>Representative Code</th>
            <th>Total Signups</th>
          </tr>
          {allTimeStatistics.map(({ code, signups }, i) => (
            <tr key={`AllTime__${code}`}>
              <td>{i + 1}</td>
              <td>{code}</td>
              <td>{signups}</td>
            </tr>
          ))}
        </Table>
      ) : (
        <button onClick={getAllTimeStatistics}>Get All Time Statistics</button>
      )}
    </div>
  );
};

const Table = styled.table`
  & td,
  th {
    border: 1px solid #000;
    padding: 0.5rem;
    margin: 0;
    font-size: 1.5rem;
  }
`;

export default Signups;
