import { Checkbox } from "@material-ui/core";
import firebase from "firebase";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import PrimaryButton from "../../../components/PrimaryButton";
import Spacer from "../../../components/Spacer";
import {
  db,
  functions,
  storage,
} from "../../../services/firebase/FirebaseService";

const NewReportModal = ({
  userId,
  fieldId,
  onClose,
}: {
  userId: string;
  fieldId: string;
  onClose: () => void;
}) => {
  const [report, setReport] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [sendToFarmer, setSendToFarmer] = useState(true);

  const reportFile = useRef<File | null>(null);

  const onUpload = async () => {
    try {
      setLoading(true);

      if (!reportFile.current) return alert("Please upload a report!");

      const reportId = new Date().toISOString();

      const ref = firebase
        .storage()
        .refFromURL("gs://crop_analysis_reports")
        .child(userId)
        .child(`${reportId}.pdf`);
      const snapshot = await ref.put(reportFile.current);

      const publicURL = `https://storage.googleapis.com/crop_analysis_reports/${userId}/${reportId}.pdf`;
      const { fullPath } = snapshot.metadata;

      await db
        .collection("fields")
        .doc(userId)
        .collection("fields")
        .doc(fieldId)
        .update({
          reports: firebase.firestore.FieldValue.arrayUnion({
            id: reportId,
            url: publicURL,
            path: fullPath,
            date: new Date(),
            thumbnail: publicURL,
          }),
        });

      if (sendToFarmer) {
        try {
          await functions.httpsCallable("sendReportToFarmer")({
            userId,
            fieldId,
            reportId,
          });
        } catch (e: any) {
          alert(`There was an error: ${e.message}`);
        }
      }

      onClose();
    } catch (e: any) {
      alert(`There was an error: ${e.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {report ? (
        <iframe src={report} height="500px" title="Report"></iframe>
      ) : (
        <UploadReportText>No report uploaded</UploadReportText>
      )}
      <Label htmlFor="report">Upload</Label>
      <input
        type="file"
        id="report"
        onChange={async ({ target: { files } }) => {
          if (!files) return;

          const file = files[0];

          if (!file) return;

          setReport(URL.createObjectURL(file));
          reportFile.current = file;
        }}
        style={{ color: "#fff" }}
      />
      <Spacer psn="bottom" size={15} />
      <Label htmlFor="sendToFarmer">Send to farmer</Label>
      <input
        type="checkbox"
        id="sendToFarmer"
        checked={sendToFarmer}
        onChange={({ target: { checked } }) => {
          setSendToFarmer(checked);
        }}
      />
      <Spacer psn="bottom" size={30} />
      <PrimaryButton
        label="Upload"
        onClick={onUpload}
        disabled={!report}
        loading={loading}
      />
    </>
  );
};

const UploadReportText = styled.h3`
  font-family: "Be Vietnam", sans-serif;
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 3rem;
`;

const Label = styled.label`
  font-family: "Be Vietnam", sans-serif;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 500;
`;

export default NewReportModal;
