import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Input from "../../../components/Input";
import PrimaryButton from "../../../components/PrimaryButton";
import Spacer from "../../../components/Spacer";
import { signIn } from "../../../services/auth/AuthService";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50vw;
  height: 100vh;
  padding: 0 7rem;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 5rem 5rem;

  background-color: #323d48;
  width: 100%;
  border-radius: 2.3rem;
`;

const FormTitle = styled.h2`
  font-family: "Be Vietnam";
  font-size: 3.5rem;
  color: #ffffff;
  font-weight: 600;
`;

// eslint-disable-next-line no-useless-escape
const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

const validateEmail = (email: string) => {
  if (!email) return "Please enter an email!";
  if (!emailRegex.test(email)) return "Please enter a valid email address!";

  return "";
};

export const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogin = useCallback(async () => {
    console.log("Handling login");

    if (!attemptedSubmit) setAttemptedSubmit(true);

    const emailError = validateEmail(email);
    if (emailError) {
      setEmailError(emailError);
      return;
    }

    setLoading(true);
    const signInResult = await signIn(email, password);
    setLoading(false);
    if (signInResult.status !== "success") {
      if (signInResult.statusCode === "auth/wrong-password") {
        setPasswordError("Incorrect password!");
      } else if (signInResult.statusCode === "auth/user-not-found") {
        alert("USER NOT FOUND!");
      } else {
        alert("UNEXPECTED ERROR!");
      }
    }
  }, [attemptedSubmit, email, password]);

  return (
    <Container>
      <FormContainer onSubmit={handleLogin} action="#" method="">
        <FormTitle>Sign In</FormTitle>
        <Spacer psn="bottom" size={28} />
        <Input
          label="Email"
          onChange={({ target: { value } }) => {
            if (attemptedSubmit) {
              const emailError = validateEmail(value);
              setEmailError(emailError);
            }

            setEmail(value);
          }}
          value={email}
          type="email"
          error={!!emailError}
          errorText={emailError}
        />
        <Spacer psn="bottom" size={19} />
        <Input
          label="Password"
          onChange={({ target: { value } }) => {
            setPassword(value);
          }}
          value={password}
          type="password"
          error={!!passwordError}
          errorText={passwordError}
        />
        <Spacer psn="bottom" size={34} />
        <PrimaryButton
          label="Sign In"
          // onClick={handleLogin}
          type="submit"
          loading={loading}
        />
      </FormContainer>
    </Container>
  );
};
