import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  width: 50vw;
  height: 100vh;
  background-image: linear-gradient(
      180deg,
      rgba(41, 45, 53, 0) 0%,
      rgba(18, 20, 24, 0.48) 100%
    ),
    url("https://storage.googleapis.com/planet-gallery-uploader/posts/iowa-derecho/derecho_madrid_boone_iowa_20200809_193052_0f4c_rgb_geo/derecho_madrid_boone_iowa_20200809_193052_0f4c_rgb_geo-2-web.jpg");
  background-size: contain;
`;

const Logo = styled.img`
  width: 35%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Title = styled.h1`
  font-family: "Be Vietnam";
  font-weight: 700;
  font-size: 4rem;
  color: #ffffff;
  width: 75%;
  text-align: center;

  margin-bottom: 3rem;
`;

const CoverImage = () => {
  return (
    <Container>
      <Logo src={process.env.PUBLIC_URL + "/img/logo_box.svg"} />
      <Title>Geospatial Monitoring Dashboard</Title>
    </Container>
  );
};

export default CoverImage;
